import { isSpacingPreset, SpacingPreset } from "@/ui/GlobalStyles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";

const StyledHR = styled("hr", {
	shouldForwardProp: (prop) => !["marginX", "marginY", "noMargin", "noMarginBottom", "noMarginTop"].includes(prop)
})<HRProps>`
	${({ marginX, marginY, noMargin, noMarginBottom, noMarginTop }) => {
		marginX = isSpacingPreset(marginX) ? `var(--spacing-${marginX})` : marginX;
		marginY = isSpacingPreset(marginY) ? `var(--spacing-${marginY})` : marginY;

		return css`
			${marginX ? `margin-left: ${marginX}; margin-right: ${marginX};` : ""}
			${marginY ? `margin-top: ${marginY}; margin-bottom: ${marginY};` : ""}
			${noMargin ? `margin: 0;` : ""}
			${noMarginBottom ? `margin-bottom: 0;` : ""}
			${noMarginTop ? `margin-top: 0;` : ""}
			border-color: var(--border-color2);
			width: 100%;
	}`;
	}}
`;

type HRProps = {
	marginX?: SpacingPreset | (string & {});
	marginY?: SpacingPreset | (string & {});
	noMargin?: boolean;
	noMarginBottom?: boolean;
	noMarginTop?: boolean;
};

export const HR: FC<HRProps> = ({ marginX, marginY, noMarginTop, noMarginBottom, noMargin }) => {
	return (
		<StyledHR
			marginX={marginX}
			marginY={marginY}
			noMargin={noMargin}
			noMarginBottom={noMarginBottom}
			noMarginTop={noMarginTop}
		/>
	);
};
