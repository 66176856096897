import { BuyTicketsOrHelp } from "@/auth/components/BuyTicketsOrHelp";
import { TwoColumnPageWrap } from "@/auth/components/TwoColumnPageWrap";
import { H1 } from "@/components/text/Text";
import { useAuth } from "@/hooks/useAuth";
import { Form, Input, notification } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { UserOutlined } from "@ant-design/icons";
import { FC } from "react";
import { Link, useSearchParams } from "react-router-dom";

export const ResetPassword: FC = () => {
	const [searchParams] = useSearchParams();
	const { resetPassword } = useAuth();

	const resetCode = searchParams?.get("oobCode");
	const [resetPasswordForm] = Form.useForm();

	const handleSubmit = async () => {
		const formValues = resetPasswordForm.getFieldsValue(true);

		if (!resetCode) {
			notification.error({ message: "Error", description: "Reset code not found" });
			return;
		}

		return await resetPassword(resetCode, formValues.newPassword);
	};

	return (
		<TwoColumnPageWrap>
			<H1>Reset password</H1>

			<Form
				autoComplete="off"
				form={resetPasswordForm}
				layout="vertical"
				style={{ width: "100%", marginTop: 24, marginBottom: 48 }}
				onFinish={handleSubmit}
			>
				<Form.Item
					name="newPassword"
					label={<b>New password</b>}
					required={false}
					rules={[{ min: 1, required: true, type: "string" }]}
				>
					<Input prefix={<UserOutlined />} placeholder="New password" type="password" />
				</Form.Item>

				<div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
					<span>
						<span>Or </span>
						<Link style={{ marginTop: 8, color: "#196097" }} to="/signin">
							Log in
						</Link>
					</span>
				</div>

				<Button variant="primary" htmlType="submit" fullWidth isLoading={false} aria-label="Submit">
					Submit
				</Button>
			</Form>

			<BuyTicketsOrHelp />
		</TwoColumnPageWrap>
	);
};
