import type { Location } from "@hx/locations";

// Calculates a rough estimate of fee value that Humanitix may capture,
// based on expected gross annual ticket sales multiplied by the fees applicable in the given location
export function estimateAnnualFeeValue(
	annualEventFrequency: number,
	ticketPrice: number,
	ticketsSoldPerEvent: number,
	location: Location
) {
	// This function shouldn't be called with falsey values for any of these - this check is purely defensive coding
	if (!annualEventFrequency || !ticketPrice || !ticketsSoldPerEvent) {
		return 0;
	}

	const annualGrossVolume = annualEventFrequency * ticketPrice * ticketsSoldPerEvent;

	const feeDecimal =
		feeDecimalByLocation[location as keyof typeof feeDecimalByLocation] ?? feeDecimalByLocation["default"];

	return annualGrossVolume * feeDecimal;
}

const feeDecimalByLocation = {
	AU: 0.04,
	US: 0.05,
	NZ: 0.05,
	default: 0.021
};
