import loadScript from "load-script";
import { useEffect } from "react";
import { getConfig } from "@/config";

const MarkerIO = () => {
	useEffect(() => {
		const NODE_ENV = getConfig("NODE_ENV");
		const show = NODE_ENV !== "production";
		if (show) {
			loadScript("/frontend/markerio.js");
		}
	}, []);

	return <div></div>;
};

export default MarkerIO;
