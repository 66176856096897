import { css } from "@emotion/css";
import { ReactNode } from "react";

interface IH4Props {
	children: ReactNode;
	style?: Record<string, unknown>;
	bold?: boolean;
}

const H4 = ({ bold = true, children, style = {} }: IH4Props) => {
	const finalStyle = css({
		fontWeight: bold ? 600 : 400,
		fontSize: 16,
		margin: 0,
		marginBottom: 8,
		lineHeight: "22px",
		"@media(max-width: 600px)": {
			fontSize: 16,
			marginBottom: 8
		},
		...style
	});
	return <h4 className={finalStyle}>{children}</h4>;
};

export default H4;
