import { Info, MultiSelect, Pan, UndoRedo, UndoRedoProps, Zoom } from "./utilityActions";

export const UtilityBar = (props: UndoRedoProps) => {
	const utilities = [<UndoRedo {...props} />, <Pan />, <MultiSelect />, <Zoom />, <Info />];
	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			{utilities.map((util, i) => {
				return (
					<div key={`utilitybar_${i}`} style={{ marginRight: 12 }}>
						{util}
					</div>
				);
			})}
		</div>
	);
};
