import { getConfig } from "@/config";
import { Button } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock, LoadingSpinner } from "../../components/AppBlocks";
import { H2, P } from "../../components/text/Text";
import { disconnect, testConnection } from "../../state/actions/salesforceActions";

class TestSalesforceConnection extends Component {
	componentDidMount() {
		const { testConnection, userId } = this.props;
		testConnection(userId);
	}

	render() {
		const { userId, salesforce, disconnect, testConnection } = this.props;
		const managedPackageLink = getConfig("SALESFORCE_MANAGED_PACKAGE");
		const url = `https://login.salesforce.com${managedPackageLink}`;

		return (
			<ContentBlock>
				{salesforce.test.error ? (
					<div>
						<H2>Error connecting to Salesforce</H2>
						<P>{salesforce.test.error}</P>
						<P>Please make sure you have the correct access level.</P>
						<P>
							You will need to install our{" "}
							<a href={url} target="_blank" rel="noopener noreferrer" aria-label="Salesforce Managed Package">
								Salesforce Managed Package
							</a>
							.
						</P>
						<Button onClick={() => disconnect(userId)} style={{ marginRight: 12 }} ariaLabel="Disconnect Salesforce">
							Disconnect Salesforce
						</Button>
						<Button onClick={() => testConnection(userId)} ariaLabel="Retest">
							Retest
						</Button>
					</div>
				) : (
					<div>
						<H2>Testing your salesforce connection</H2>
						<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
					</div>
				)}
			</ContentBlock>
		);
	}
}

export default connect(
	(state) => {
		return {
			salesforce: state.salesforce
		};
	},
	{ testConnection, disconnect }
)(TestSalesforceConnection);
