import loadScript from "load-script";

export const loadScripts = (scripts) => {
	// TODO: cleanup no-async-promise-executor error below
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve) => {
		try {
			for (let i = 0; i < scripts.length; i++) {
				const scriptPath = scripts[i];
				await load(scriptPath);
			}
			resolve();
		} catch (err) {
			resolve(err);
		}
	});
};

export const load = (scriptPath) => {
	return new Promise((resolve, reject) => {
		loadScript(scriptPath, (err) => {
			if (err) {
				reject(err);
			}
			resolve();
		});
	});
};
