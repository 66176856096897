import { ContentBlock, LoadingSpinner } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import TemplateModal from "@/events/event/info/TemplateModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import withSaveCheck from "@/hoc/withSaveCheck";
import { deleteAdditionalQuestion, previewAdditionalQuestions, saveEvent } from "@/state/actions/eventActions";
import { findAnsweredAdditionalQuestions } from "@/state/actions/orderActions";
import { Button, Spacer } from "@/ui";
import { Alert, Flex, Modal, notification } from "@/ui/antd";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FieldArray, change, formValueSelector, reduxForm } from "redux-form";
import QuestionRows from "./QuestionRows";

const formName = "eventAdditionalQuestions";
const ticketingURL = getConfig("TICKETING_URL");

const defaultQuestions = [
	{
		type: "firstName",
		inputType: "text",
		question: "First Name",
		required: true,
		perOrder: true,
		defaultOrderQuestion: true
	},
	{
		type: "lastName",
		inputType: "text",
		question: "Last Name",
		required: true,
		perOrder: true,
		defaultOrderQuestion: true
	},
	{
		type: "email",
		inputType: "email",
		question: "Email",
		required: true,
		perOrder: true,
		defaultOrderQuestion: true
	},
	{
		type: "mobile",
		inputType: "text",
		question: "Mobile",
		required: true,
		perOrder: true,
		defaultOrderQuestion: true
	}
];

const iframeWrapper = css({
	width: "100%",
	border: "none",
	height: "60vh"
});

class AdditionalQuestions extends Component {
	constructor(props) {
		super(props);
		this.state = { showModal: false, showPreview: false };
		this.save = this.save.bind(this);
		this.onTemplateChosen = this.onTemplateChosen.bind(this);
		this.showTemplateModal = this.showTemplateModal.bind(this);
		this.hideTemplateModal = this.hideTemplateModal.bind(this);

		this.changeFieldValue = this.changeFieldValue.bind(this);
	}
	componentWillMount() {
		const { currentEvent, findAnsweredAdditionalQuestions } = this.props;
		const eventId = currentEvent.event._id;
		findAnsweredAdditionalQuestions(eventId);
		if (window) {
			window.getAdditionalQuestionsDebug = () => this.debugCommand();
		}
	}

	debugCommand = () => {
		console.log(JSON.stringify(this.props.formState));
		console.log(this.props.formState);
	};

	save(values) {
		const { params, saveEvent } = this.props;
		const eventId = params?.eventId;
		// map per ticket to the database per order
		const additionalQuestionsConverted = values.additionalQuestions
			.filter((a) => !a.defaultOrderQuestion)
			.map((a) => {
				const defaultValue =
					a.defaultValue && Array.isArray(a.defaultValue)
						? a.defaultValue.join("{|}")
						: a.defaultValue
						? a.defaultValue
						: null;
				const newValue = {
					...a,
					perOrder: !a.perTicket,
					conditions: [],
					defaultValue
				};
				if (a.conditions && Array.isArray(a.conditions)) {
					newValue.conditions = a.conditions.map((condition) => {
						const newCondition =
							condition.value && Array.isArray(condition.value)
								? { ...condition, value: condition.value.join("{|}") }
								: { ...condition };
						return newCondition;
					});
				}
				return newValue;
			});
		saveEvent(eventId, { additionalQuestions: additionalQuestionsConverted });
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	removeConditions = (field, value) => {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	};

	showTemplateModal() {
		this.setState({ showModal: true });
	}
	hideTemplateModal() {
		this.setState({ showModal: false });
	}
	onTemplateChosen(templateData) {
		const {
			//https://redux-form.com/8.2.2/docs/api/fieldarray.md/
			array: { push },
			additionalQuestions
		} = this.props;
		templateData.forEach((templateQuestion) => {
			//Stop importiting duplicate questions
			const index = additionalQuestions.findIndex((existingQuestion) => existingQuestion._id === templateQuestion._id);
			if (index > -1) {
				notification.warn({
					message: "Question already exists",
					description: templateQuestion.question
				});
			} else {
				if (typeof templateQuestion.perOrder === "boolean") {
					templateQuestion.perTicket = !templateQuestion.perOrder;
				}
				push("additionalQuestions", templateQuestion);
			}
		});

		this.setState({ showModal: false });
	}

	togglePreview = (id, additionalQuestions) => {
		const { showPreview } = this.state;
		const { previewAdditionalQuestions } = this.props;
		if (!showPreview) {
			previewAdditionalQuestions(id, additionalQuestions);
		}
		this.setState({ showPreview: !showPreview });
	};

	render() {
		const { handleSubmit, currentEvent, additionalQuestionsOnTicket, deleteAdditionalQuestion, dispatch } = this.props;
		const { showModal, showPreview } = this.state;
		const { loading, error } = currentEvent;
		let { additionalQuestions = [] } = this.props;
		additionalQuestions.forEach((question) => {
			if (question.defaultValue) {
				question.defaultValue =
					question.defaultValue.indexOf("{|}") !== -1 ? question.defaultValue.split("{|}") : question.defaultValue;
			}
			if (question.conditions && question.conditions.length) {
				question.conditions.forEach((condition) => {
					condition.value =
						condition.value && condition.value.indexOf("{|}") !== -1 ? condition.value.split("{|}") : condition.value;
				});
			}
		});

		return (
			<div>
				<PageHeading
					title="Checkout questions"
					helpButton={{
						link: HelpIds.additionalQuestions,
						title: "Help - Checkout questions"
					}}
				/>
				<LoadErrorView loading={loading} error={error} retryAction={this.load}>
					<Modal title="Preview" open={showPreview} onCancel={this.togglePreview} footer={false} destroyOnClose>
						{currentEvent.cache.loading ? (
							<div style={{ textAlign: "center" }}>
								<LoadingSpinner />
							</div>
						) : (
							<iframe
								title="Checkout questions Preview?"
								src={`${ticketingURL}/${currentEvent.event.slug}/additional-questions-preview?w&token=additionalQuestionPreview`}
								className={iframeWrapper}
							/>
						)}
					</Modal>
					<Alert
						style={{ marginBottom: 12 }}
						showIcon
						message={
							<span>
								Find answers to checkout questions under Manage Attendees {">>"}{" "}
								<Link to={`/console/my-events/${currentEvent.event._id}/attendees`}>Attendees.</Link>
							</span>
						}
						type="info"
					/>
					<form onSubmit={handleSubmit(this.save)}>
						<ContentBlock>
							<Flex justify="space-between">
								<div>
									<P>By default the buyer is always asked</P>
									<ul>
										{defaultQuestions.map((question) => (
											<li key={question.type}>
												{question.question}
												{question.required ? <>*</> : null}
											</li>
										))}
									</ul>
								</div>

								<Button aria-label="Import template" onClick={this.showTemplateModal} variant="ghost">
									Import template
								</Button>
							</Flex>
							<Spacer size="sm" />
							<div style={{ marginTop: "24px" }}>
								<FieldArray
									name="additionalQuestions"
									component={QuestionRows}
									additionalQuestions={additionalQuestions}
									currentEvent={currentEvent}
									changeFieldValue={this.changeFieldValue}
									additionalQuestionsOnTicket={additionalQuestionsOnTicket}
									removeConditions={this.removeConditions}
									deleteQuestion={deleteAdditionalQuestion}
									formName={formName}
									dispatch={dispatch}
								/>
							</div>
						</ContentBlock>
						<FormBar>
							<Flex justify="flex-end">
								<Button aria-label="Save" dataCy="Save" htmlType="submit" size="large" variant="primary">
									Save
								</Button>
							</Flex>
						</FormBar>
					</form>
				</LoadErrorView>
				<TemplateModal
					onTemplateChosen={this.onTemplateChosen}
					handleCancel={this.hideTemplateModal}
					visible={showModal}
					templateType="additionalQuestions"
				/>
			</div>
		);
	}
}

AdditionalQuestions = reduxForm({
	form: formName,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(AdditionalQuestions);

const selector = formValueSelector(formName);
export default connect(
	(state) => {
		const answeredQuestions = state.order.answeredQuestions;
		let additionalQuestionsOnTicket = [];
		if (answeredQuestions) {
			answeredQuestions.forEach((question) => {
				additionalQuestionsOnTicket.push(question._id);
			});
		}

		const mobileRequired = state?.currentEvent?.event?.settings?.mobileRequired;
		const defaultMobileIndex = defaultQuestions.findIndex((q) => q.question === "Mobile");
		defaultQuestions[defaultMobileIndex].required = mobileRequired;

		// map per order to the ui value per ticket
		const additionalQuestions = state.currentEvent.event.additionalQuestions.map((a) => {
			a.perTicket = !a.perOrder;
			return a;
		});
		const initialValues = {
			additionalQuestions
		};

		return {
			initialValues,
			formState: state.form[formName],
			currentEvent: state.currentEvent,
			additionalQuestionsOnTicket,
			additionalQuestions: selector(state, "additionalQuestions")
		};
	},
	{
		saveEvent,
		previewAdditionalQuestions,
		findAnsweredAdditionalQuestions,
		deleteAdditionalQuestion
	}
)(withSaveCheck(withRouterHooks(AdditionalQuestions), formName));
