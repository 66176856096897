import { css } from "@emotion/css";
import { CSSProperties, ReactNode, useState } from "react";
import iconClose from "@/assets/ic_close_large.svg";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { ContentBlock } from "@/components/AppBlocks";

const itemPrefix = "banner-";
const itemPostfix = "-dismissed";

/**
 * Stateful wrapper to make your Console banner dismissable
 * stores visibility in browser local storage
 */

const styles = {
	contentBlock: css({
		paddingTop: "2em"
	}),
	buttonClose: css({
		background: "transparent",
		border: "0 none",
		padding: 0,
		position: "absolute",
		right: "1em",
		top: "1em",
		"&:hover": {
			cursor: "pointer"
		}
	})
};

interface IBannerProps {
	children: ReactNode;
	bannerId: string;
	dismissable?: boolean;
	style?: CSSProperties;
}

export const Banner = ({ children, bannerId, dismissable = true, style }: IBannerProps) => {
	const [closed, setClosed] = useLocalStorage(`${itemPrefix}${bannerId}${itemPostfix}`, false);
	const [{ visible }, updateState] = useState({ visible: !closed });

	const close = () => {
		updateState({ visible: false });
		setClosed(true);
	};

	if (!visible) {
		return null;
	}

	return dismissable ? (
		<ContentBlock style={style}>
			{children}
			<button onClick={close} alt-text="dismiss this message" className={styles.buttonClose}>
				<img src={iconClose} aria-hidden="true" alt="close" />
			</button>
		</ContentBlock>
	) : (
		<ContentBlock style={style}>{children}</ContentBlock>
	);
};
