import { getConfig } from "@/config";
import { trpc } from "@/trpc";
import { notification } from "@/ui/antd";
import { connect, create, disconnect, getAccounts, load, remove, update } from "../../services/FacebookService";
import { loadScripts } from "../../utils/Load";
import { setUser } from "../actions/authActions";
import { SAVE_EVENT_SUCCESS } from "../actions/eventActions";

export const LOAD_FACEBOOK_SDK_REQUEST = "LOAD_FACEBOOK_SDK_REQUEST";
export const LOAD_FACEBOOK_SDK_SUCCESS = "LOAD_FACEBOOK_SDK_SUCCESS";
export const LOAD_FACEBOOK_SDK_ERROR = "LOAD_FACEBOOK_SDK_ERROR";

export const CHECK_FACEBOOK_CONTENTION_REQUEST = "CHECK_FACEBOOK_CONTENTION_REQUEST";
export const CHECK_FACEBOOK_CONTENTION_SUCCESS = "CHECK_FACEBOOK_CONTENTION_SUCCESS";
export const CHECK_FACEBOOK_CONTENTION_ERROR = "CHECK_FACEBOOK_CONTENTION_ERROR";

export const CONNECT_FACEBOOK_REQUEST = "CONNECT_FACEBOOK_REQUEST";
export const CONNECT_FACEBOOK_SUCCESS = "CONNECT_FACEBOOK_SUCCESS";
export const CONNECT_FACEBOOK_ERROR = "CONNECT_FACEBOOK_ERROR";

export const LOAD_FACEBOOK_EVENT_REQUEST = "LOAD_FACEBOOK_EVENT_REQUEST";
export const LOAD_FACEBOOK_EVENT_SUCCESS = "LOAD_FACEBOOK_EVENT_SUCCESS";
export const LOAD_FACEBOOK_EVENT_ERROR = "LOAD_FACEBOOK_EVENT_ERROR";

export const DISCONNECT_FACEBOOK_REQUEST = "DISCONNECT_FACEBOOK_REQUEST";
export const DISCONNECT_FACEBOOK_SUCCESS = "DISCONNECT_FACEBOOK_SUCCESS";
export const DISCONNECT_FACEBOOK_ERROR = "DISCONNECT_FACEBOOK_ERROR";

export const FACEBOOK_ACCOUNT_REQUEST = "FACEBOOK_ACCOUNT_REQUEST";
export const FACEBOOK_ACCOUNT_SUCCESS = "FACEBOOK_ACCOUNT_SUCCESS";
export const FACEBOOK_ACCOUNT_ERROR = "FACEBOOK_ACCOUNT_ERROR";

export const POST_FACEBOOK_REQUEST = "POST_FACEBOOK_REQUEST";
export const POST_FACEBOOK_SUCCESS = "POST_FACEBOOK_SUCCESS";
export const POST_FACEBOOK_ERROR = "POST_FACEBOOK_ERROR";
export const CLEAR_POST_FACEBOOK_ERROR = "CLEAR_POST_FACEBOOK_ERROR";

export const DELETE_FACEBOOK_REQUEST = "DELETE_FACEBOOK_REQUEST";
export const DELETE_FACEBOOK_SUCCESS = "DELETE_FACEBOOK_SUCCESS";
export const DELETE_FACEBOOK_ERROR = "DELETE_FACEBOOK_ERROR";

export const FACEBOOK_ACCOUNT_RESET = "FACEBOOK_ACCOUNT_RESET";

export const clearPostErrors = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_POST_FACEBOOK_ERROR });
	};
};

export const createEvent = (facebookEvent, eventId, navigate) => {
	return (dispatch, getState) => {
		dispatch({ type: POST_FACEBOOK_REQUEST });
		const state = getState();
		const pages = state.facebook.account.pages;
		create(facebookEvent, eventId, pages)
			.then((result) => {
				dispatch({ type: SAVE_EVENT_SUCCESS, event: result.event });
				dispatch({ type: POST_FACEBOOK_SUCCESS });
				notification.success({
					message: "Success",
					description: `Your Facebook event has now been created`
				});
				navigate(`/console/my-events/${eventId}/facebook-event/${result.facebookEvent.facebookId}`);
			})
			.catch((err) => {
				console.log("Failed to create your event", err);
				const message = err?.response?.data ? err.response.data : "Failed to create your event";
				notification.error({
					message: "Error",
					description: message
				});
				dispatch({
					type: POST_FACEBOOK_ERROR,
					error: true
				});
			});
	};
};

const loadEvent = (facebookEvent) => {
	return (dispatch, getState) => {
		dispatch({ type: LOAD_FACEBOOK_EVENT_REQUEST });
		const state = getState();
		const pages = state.facebook.account.pages;
		load(facebookEvent, pages)
			.then((fbEvent) => {
				dispatch({ type: LOAD_FACEBOOK_EVENT_SUCCESS, fbEvent });
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Your event failed to load `
				});
				dispatch({
					type: LOAD_FACEBOOK_EVENT_ERROR,
					error: true
				});
			});
	};
};

export const updateEvent = (facebookEvent, eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: POST_FACEBOOK_REQUEST });
		const state = getState();
		const pages = state.facebook.account.pages;
		update(facebookEvent, eventId, pages)
			.then((updatedEvent) => {
				dispatch({ type: SAVE_EVENT_SUCCESS, event: updatedEvent });
				dispatch({ type: POST_FACEBOOK_SUCCESS });
				notification.success({
					message: "Success",
					description: `Your Facebook event has now been updated`
				});
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Your event failed to update `
				});
				dispatch({
					type: POST_FACEBOOK_ERROR,
					error: true
				});
			});
	};
};

export const deleteEvent = (facebookEvent, eventId, navigate) => {
	return (dispatch, getState) => {
		const state = getState();
		const pages = state.facebook.account.pages;
		dispatch({ type: DELETE_FACEBOOK_REQUEST });
		remove(facebookEvent, eventId, pages)
			.then((updatedEvent) => {
				dispatch({ type: SAVE_EVENT_SUCCESS, event: updatedEvent });
				dispatch({ type: DELETE_FACEBOOK_SUCCESS });
				notification.success({
					message: "Success",
					description: `Your Facebook event has now been removed`
				});
				navigate(`/console/my-events/${eventId}/facebook-events`);
			})
			.catch(() => {
				dispatch({ type: DELETE_FACEBOOK_ERROR });
				notification.error({
					message: "Error",
					description: `Failed to delete your event`
				});
			});
	};
};

export const loadSDK = () => {
	return (dispatch) => {
		const FB_CLIENT_ID = getConfig("FB_CLIENT_ID");
		dispatch({ type: LOAD_FACEBOOK_SDK_REQUEST });
		if (!window.fbAsyncInit) {
			window.fbAsyncInit = () => {
				window.FB.init({
					appId: FB_CLIENT_ID,
					autoLogAppEvents: true,
					xfbml: true,
					version: "v16.0"
				});
				dispatch({ type: LOAD_FACEBOOK_SDK_SUCCESS });
			};
			loadScripts(["https://connect.facebook.net/en_US/sdk.js"]).catch(() => {
				dispatch({ type: LOAD_FACEBOOK_SDK_ERROR });
			});
		} else {
			dispatch({ type: LOAD_FACEBOOK_SDK_SUCCESS });
		}
	};
};

export const connectFacebook = (userId) => {
	return async (dispatch) => {
		dispatch({ type: DISCONNECT_FACEBOOK_REQUEST });
		try {
			// login to facebook request
			await connect(userId);
			const { data } = await trpc.users.getCurrentUser();
			const userData = data?.user;
			setUser(userData)(dispatch);
			dispatch({ type: CONNECT_FACEBOOK_SUCCESS });
		} catch (err) {
			dispatch({
				type: CONNECT_FACEBOOK_ERROR,
				error: "Login cancelled or not fully authorized."
			});
		}
	};
};

export const disconnectFacebook = (userId) => {
	return async (dispatch) => {
		dispatch({ type: DISCONNECT_FACEBOOK_REQUEST });
		try {
			// login to facebook request
			await disconnect(userId);
			const { data } = await trpc.users.getCurrentUser();
			const userData = data?.user;
			setUser(userData)(dispatch);
			dispatch({ type: DISCONNECT_FACEBOOK_SUCCESS });
		} catch (err) {
			dispatch({
				type: DISCONNECT_FACEBOOK_ERROR,
				error: "disconnect failed."
			});
		}
	};
};

export const loadAccounts = (userId, facebookEvent) => {
	return (dispatch) => {
		dispatch({ type: FACEBOOK_ACCOUNT_REQUEST });
		getAccounts(userId)
			.then((pages) => {
				if (!pages.length) {
					dispatch({
						type: FACEBOOK_ACCOUNT_ERROR,
						error: "Not pages found."
					});
				}
				dispatch({ type: FACEBOOK_ACCOUNT_SUCCESS, pages });
				if (facebookEvent.facebookId) {
					dispatch(loadEvent(facebookEvent));
				}
			})
			.catch(() => {
				dispatch({
					type: FACEBOOK_ACCOUNT_ERROR,
					error: "Failed to get account"
				});
			});
	};
};

export const clearAccounts = () => {
	return (dispatch) => {
		dispatch({ type: FACEBOOK_ACCOUNT_RESET });
	};
};
