import { css } from "@emotion/css";
import { Alert, Modal } from "@/ui/antd";
import React from "react";
import { connect } from "react-redux";
import { toggleApplyToAllModal } from "../state/actions/applyToAllActions";
import type { ReduxState as GlobalReduxState } from "../state/store";
import Icon from "./Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import P from "./text/P";

const headingWrapper = css({
	display: "flex"
});

const pickDataFromState = (state: Partial<GlobalReduxState>) => ({
	isModalOpen: state.applyToAll?.modalOpen
});

interface ReduxActions {
	toggleApplyToAllModal: (isOpen: boolean) => void;
}
type LocalisedReduxState = ReturnType<typeof pickDataFromState>;

type ReduxInput = LocalisedReduxState & ReduxActions;

interface IApplyToAllSaveProps {
	buttonText?: string;
	onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
type ApplyToAllSaveInput = IApplyToAllSaveProps & ReduxInput;

const ApplyToAllSave = ({ isModalOpen, toggleApplyToAllModal, onOk, buttonText = "Save" }: ApplyToAllSaveInput) => {
	return (
		<>
			<Modal
				open={isModalOpen}
				title={
					<div className={headingWrapper}>
						<Icon icon="success-circle" style={{ marginRight: 12 }} />
						<span>Saved! New events will have these settings</span>
					</div>
				}
				okText="Apply to all"
				cancelText="Not now"
				onCancel={() => toggleApplyToAllModal(false)}
				onOk={onOk}
			>
				<P>
					<b>Would you like to apply changes to all your existing events?</b>
				</P>
				<P>“Apply to all”, will apply to all new and existing events you create. </P>
				<Alert
					message="IMPORTANT: Any current settings will be replaced by the new settings. Check your event to make sure before you proceed."
					type="warning"
					showIcon
				/>
			</Modal>
			<LegacyButton
				style={{
					float: "right"
				}}
				type="primary"
				htmlType="submit"
				ariaLabel={buttonText}
				size="large"
			>
				{buttonText}
			</LegacyButton>
		</>
	);
};

export default connect(pickDataFromState, { toggleApplyToAllModal })(ApplyToAllSave);
