import { getConfig } from "@/config";
import { useCollectionURL } from "@/hooks/useCollectionURL";
import { trpc } from "@/trpc";
import { FormLabel } from "@/ui";
import { Alert, Form, FormInstance, Row } from "@/ui/antd";
import { parseTRPCErrors } from "@/utils/trpcHelpers";
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import Input from "antd/lib/input/Input";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";

const collectionsRootUrl = `${getConfig("COLLECTIONS_URL")}/`;

type CollectionSlugFieldProps = {
	form: FormInstance;
};

export const CollectionSlugField: FC<CollectionSlugFieldProps> = ({ form }) => {
	const formValues = useWatch<Collection>([], form);
	const [slug, setSlug] = useState<string>("");
	const [shouldCheck, setShouldCheck] = useState<boolean>(true); // Used to delay the check - i.e doesnt get set until debounce is done
	const [isCheckRequired, setIsCheckRequired] = useState<boolean>(true); // Used to indicate current validation is invalid
	const [editMode, setEditMode] = useState<boolean>(false);
	const collectionURL = useCollectionURL({
		collectionSlug: formValues?.info.slug,
		isPreview: !formValues?.published
	});

	const { isFetching, error, isError } = trpc.collections.isSlugAvailable.useQuery(
		{
			currentCollectionId: form.getFieldsValue(true)?._id ?? null,
			slug
		},
		{
			enabled: shouldCheck && formValues?.info.slug !== undefined && slug.length > 0,
			networkMode: "always",
			retry: false
		}
	);

	const errors = parseTRPCErrors(error);
	const isSlugGood = !errors?.length;

	const updateForm = () => {
		form.setFieldsValue({ info: { slug } });
		form.validateFields();
	};

	useEffect(() => {
		if (formValues?.info?.slug.length > 0 && formValues?.info?.slug !== slug) {
			setSlug(formValues?.info.slug);
		}
	}, [formValues]);

	useEffect(() => {
		if (!isCheckRequired && !isFetching && slug.length > 0) {
			updateForm();
		}
	}, [shouldCheck, isCheckRequired]);

	useEffect(() => {
		if (!isFetching) {
			// Timeout so it doesn't flicker and feels to the user that is actually being checked
			setTimeout(() => {
				setShouldCheck(false);
				setIsCheckRequired(false);
			}, 200);
		}
	}, [isFetching]);

	const triggerSlugCheck = useCallback(
		debounce(700, () => setShouldCheck(true)),
		[]
	);

	const handleSlugChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.toLowerCase();
		if (newValue !== slug) {
			setSlug(e.target.value.toLowerCase());
			setIsCheckRequired(true);
			triggerSlugCheck();
		}
	};

	const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
		// Force lowercase; only allow letters, numbers, dashes; preserve cursor position
		const target = e.target;
		const start = target.selectionStart;
		const end = target.selectionEnd;
		target.value = target.value
			.toLowerCase()
			.replace(/[^a-z0-9-]/g, "")
			.replace(/--/g, "-");
		target.setSelectionRange(start, end);
	};

	return (
		<>
			<FormLabel>Collection URL</FormLabel>
			<Alert
				collapsed={!editMode}
				description="Updating your collections URL will break any existing link you may have shared."
				message="Warning"
				showIcon
				style={{ marginBottom: "var(--spacing-sm)" }}
				type="warning"
			/>
			<Form.Item
				name={["info", "slug"]}
				rules={[
					{
						message: errors?.map((err: any) => (
							<span key={err}>
								{err}
								<br />
							</span>
						)),
						validator: async () => (isError ? Promise.reject() : Promise.resolve())
					},
					{ message: "URL is required", required: true }
				]}
				style={{ marginBottom: "4px" }}
			>
				<Input
					onChange={handleSlugChange}
					onInput={handleOnInput}
					maxLength={80}
					value={slug}
					onFocus={() => setEditMode(true)}
					onBlur={() => setEditMode(false)}
					suffix={
						isCheckRequired ? (
							<LoadingOutlined style={{ color: "#1890ff", marginRight: "5px" }} />
						) : isSlugGood ? (
							<CheckCircleFilled style={{ color: "#007349", marginRight: "5px" }} />
						) : (
							<CloseCircleFilled style={{ color: "#ff4d4f", marginRight: "5px" }} />
						)
					}
				/>
			</Form.Item>
			<Row wrap={false} align="top">
				<a style={{ maxWidth: "95%" }} href={collectionURL} target="_blank" rel="noreferrer">
					{collectionsRootUrl}
					<strong>{slug}</strong>
				</a>
			</Row>
		</>
	);
};
