import { Radio } from "@/ui/antd";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const eventsPageStyle = css({
	paddingTop: "24px",
	paddingLeft: "calc((100% - 850px - 238px - 12px) / 2)",
	paddingRight: "calc((100% - 850px - 238px - 12px) / 2)",
	position: "relative",
	display: "block",
	margin: "0 auto",
	width: "100%",
	"@media (max-width: 1100px)": {
		paddingLeft: "calc((100% - 1000px) / 2)",
		paddingRight: "calc((100% - 1000px) / 2)"
	},
	"@media (max-width: 1000px)": {
		paddingLeft: "14px",
		paddingRight: "14px"
	}
});

export const ColumnWrapper = styled.div`
	display: flex;
`;

export const EventContainer = styled.div`
	display: block;
	width: 850px;
	max-width: 850px;
	margin-bottom: 25px;
	@media (max-width: 1100px) {
		width: 100%;
		max-width: 1000px;
	}
	@media (max-width: 868px) {
		width: 100%;
	}
`;

export const SearchAndFilterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	height: 36px;
`;

export const SearchRow = styled.div`
	flex-grow: 1;
	height: 36px;
	margin-right: 12px;
	@media (max-width: 868px) {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`;

export const SearchBarWrapper = styled.div`
	height: 36px;
	max-width: unset;
	vertical-align: middle;
	width: 100%;
	@media (max-width: 868px) {
		margin-bottom: 12px;
		margin-right: 12px;
	}
	@media (max-width: 600px) {
		min-width: 194px;
		margin-bottom: 12px;
		margin-right: 12px;
	}
`;

export const StyledRadio = styled(Radio)`
	display: flex;
	align-items: center;
	height: 30px;
	line-height: 30px;
`;

export const OptionsRow = styled.div`
	display: flex;
	@media (max-width: 868px) {
		display: none;
	}
	.ant-select {
		min-width: 150px;
	}
`;

export const StyledRadioGroup = styled(Radio.Group)`
	float: left;
	text-align: center;
	margin-right: 12px;
	@media (max-width: 868px) {
		display: none;
	}
`;

export const SearchDesktopWrapper = styled.div`
	@media (max-width: 868px) {
		display: none;
	}
`;

export const sortOptions = css({
	fontSize: "14px !important",
	height: 36,
	width: 140
});

export const RecentUpdateDesktop = styled.div`
	display: block;
	margin-left: 12px;
	max-width: 238px;
	width: 238px;
`;

export const RecentUpdateDesktopTitle = styled.div`
	color: #323232;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.37px;
	line-height: normal;
	text-align: center;
`;
