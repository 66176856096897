import EventsService from "@/services/EventsService";
import { AppDispatch } from "@/state/store";
import { notification } from "@/ui/antd";
import { toggleModal as toggleApplyToAllModal } from "@/state/slices/applyToAll";

type ApplyToAllEventsSettingsWithLocation = {
	settingsToApply: "defaultPaymentOptions" | "defaultGateways" | "defaultAlerts";
	isLocationNeeded: true;
};

type ApplyToAllEventsSettingsWithoutLocation = {
	settingsToApply: "defaultSettings" | "defaultIntegrations";
	isLocationNeeded: false;
};

type ApplyToAllEventsSettingsWithTheme = {
	settingsToApply: "styleTheme";
	themeId: string;
	isLocationNeeded: false;
};

type ApplyToAllEventsSettings =
	| ApplyToAllEventsSettingsWithLocation
	| ApplyToAllEventsSettingsWithoutLocation
	| ApplyToAllEventsSettingsWithTheme;

export const applyToAllEvents =
	(applyToAllEventsSettings: ApplyToAllEventsSettings, userId?: string) => async (dispatch: AppDispatch) => {
		try {
			dispatch(toggleApplyToAllModal({ stateToApplyToModal: false }));

			// type casting to for now as the EventService is in javascript
			await (EventsService as any)?.applyToAllEvents(applyToAllEventsSettings, userId);
			notification.success({
				message: "Applied!",
				description: "Successfully applied to all events!"
			});
		} catch (err: any) {
			notification.error({
				message: "Unable to apply to all events",
				description: "Please try again later"
			});
		}
	};

export const applyDefaultSettings = (userId?: string) => {
	return async (dispatch: AppDispatch) => {
		applyToAllEvents(
			{
				settingsToApply: "defaultSettings",
				isLocationNeeded: false
			},
			userId
		)(dispatch);
	};
};

export const applyDefaultPaymentOptions = (userId?: string) => {
	return async (dispatch: AppDispatch) => {
		applyToAllEvents(
			{
				settingsToApply: "defaultPaymentOptions",
				isLocationNeeded: true
			},
			userId
		)(dispatch);
	};
};

export const applyDefaultGateways = (userId?: string) => {
	return async (dispatch: AppDispatch) => {
		applyToAllEvents(
			{
				settingsToApply: "defaultGateways",
				isLocationNeeded: true
			},
			userId
		)(dispatch);
	};
};

export const applyDefaultAlerts = (userId?: string) => {
	return async (dispatch: AppDispatch) => {
		applyToAllEvents(
			{
				settingsToApply: "defaultAlerts",
				isLocationNeeded: true
			},
			userId
		)(dispatch);
	};
};

export const applyDefaultIntegrations = (userId?: string) => {
	return async (dispatch: AppDispatch) => {
		applyToAllEvents(
			{
				settingsToApply: "defaultIntegrations",
				isLocationNeeded: false
			},
			userId
		)(dispatch);
	};
};

export const applyStyleTheme = (themeId: string, userId?: string) => {
	return async (dispatch: AppDispatch) => {
		applyToAllEvents(
			{
				settingsToApply: "styleTheme",
				themeId,
				isLocationNeeded: false
			},
			userId
		)(dispatch);
	};
};

export { toggleApplyToAllModal };
