import { notification } from "@/ui/antd";
import SalesforceService from "../../services/SalesforceService";
import { GET_USER_SUCCESS } from "../actions/userActions";
import { getUser } from "./userActions";

export const SALESFORCE_TEST_REQUEST = "SALESFORCE_TEST_REQUEST";
export const SALESFORCE_TEST_SUCCESS = "SALESFORCE_TEST_SUCCESS";
export const SALESFORCE_TEST_ERROR = "SALESFORCE_TEST_ERROR";

export const testConnection = (userId) => {
	return (dispatch) => {
		dispatch({ type: SALESFORCE_TEST_REQUEST });
		SalesforceService.testConnection(userId)
			.then((user) => {
				dispatch({ type: SALESFORCE_TEST_SUCCESS });
				dispatch({ type: GET_USER_SUCCESS, user });
				notification.success({
					message: "Connection Successful",
					description: "Humanitix has been able to connect to your salesforce account"
				});
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to sync events";
				dispatch({ type: SALESFORCE_TEST_ERROR, error: message });
				notification.error({
					message: "Connection Error",
					description: message
				});
			});
	};
};

export const manualSync = (userId) => {
	return (dispatch) => {
		SalesforceService.manualSync(userId)
			.then(() => {
				notification.success({
					message: "Manual Sync Started",
					description: "Your events are now syncing with salesforce"
				});
				dispatch(getUser(userId));
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to sync events";
				notification.error({
					message: "Manual Sync Error",
					description: message
				});
			});
	};
};
export const disconnect = (userId) => {
	return (dispatch) => {
		SalesforceService.disconnect(userId)
			.then((user) => {
				dispatch({ type: GET_USER_SUCCESS, user });
				notification.success({
					message: "Disconnect complete",
					description: "Your events will no longer sync with salesforce"
				});
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to disconnect";
				notification.error({
					message: "Disconnect Error",
					description: message
				});
			});
	};
};

export const authenticate = (userId, code, isSandboxAccount, navigate) => {
	return () => {
		SalesforceService.authenticate(userId, code, isSandboxAccount)
			.then(() => {
				notification.success({
					message: "Authenticated",
					description: "Your account is connected with salesforce"
				});
				navigate(`/console/account/advanced/integrations/salesforce`);
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to connect with Salesforce";
				notification.error({
					message: "Authenticate Error",
					description: message
				});
				navigate(`/console/account/advanced/integrations/salesforce`);
			});
	};
};
