import { ContentBlock } from "@/components/AppBlocks";
import { CollectionListItemActions } from "@/features/collections/CollectionListItemActions";
import { Tag } from "@/ui";
import { Flex, Space } from "@/ui/antd";
import { PictureOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { CollectionSummary } from "@hx/console";
import { EyeSlash } from "@phosphor-icons/react";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router";

const StyledBlock = styled(ContentBlock)`
	cursor: pointer;
`;

const ImageContainer = styled.div`
	border-radius: 4px;
	display: flex;
	flex: 1 0 120px;
	height: 100px;
	overflow: hidden;
	position: relative;

	@media (min-width: 768px) {
		flex: 1 0 170px;
	}
`;

const BlurImage = styled.img`
	filter: blur(20px);
	height: 100%;
	object-fit: cover;
	position: absolute;
	width: 100%;
`;

const StyledPlaceholderImage = styled.div`
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 4px;
	display: flex;
	flex: 1 0 120px;
	height: 100px;
	justify-content: center;

	@media (min-width: 768px) {
		flex: 1 0 170px;
	}

	:hover {
		background-color: #e8e8e8;
	}
`;

const StyledName = styled.span`
	display: block;
	font-size: 18px;
	font-weight: 700;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

type CollectionListItemProps = {
	collection: CollectionSummary;
};

export const CollectionListItem: FC<CollectionListItemProps> = ({ collection }) => {
	const navigate = useNavigate();
	const locationParam = collection.location?.toLowerCase();

	const handleEditClicked = (e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		navigate(`${locationParam}/${collection._id}/events`);
	};

	const handleEditImageClicked = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		navigate(`${locationParam}/${collection._id}/info`);
	};

	const isPng = collection.info.image?.name.endsWith(".png");

	return (
		<StyledBlock onClick={handleEditClicked}>
			<Flex gap="lg">
				{collection.info.image ? (
					<ImageContainer>
						{isPng && (
							<BlurImage alt={collection.info.image.alt} height={100} src={collection.info.image.url} width={200} />
						)}
						<img
							alt={collection.info.image.alt}
							height={100}
							src={collection.info.image.url}
							style={{
								height: "100%",
								objectFit: isPng ? "contain" : "cover",
								position: "absolute",
								width: "100%"
							}}
							width={200}
						/>
					</ImageContainer>
				) : (
					<StyledPlaceholderImage onClick={handleEditImageClicked}>
						<PictureOutlined style={{ color: "#C6C5C5", fontSize: "32px" }} />
					</StyledPlaceholderImage>
				)}
				<Flex vertical wide style={{ overflow: "hidden" }}>
					<Flex align="start" justify="space-between">
						<Flex align="center" gap="xs" wrap style={{ height: "100%" }}>
							{!collection.public && (
								<Tag variant="neutral" icon={<EyeSlash size={14} />}>
									Private
								</Tag>
							)}
							{collection.published ? <Tag variant="success">Published</Tag> : <Tag variant="warning">Draft</Tag>}
						</Flex>
						<CollectionListItemActions collection={collection} />
					</Flex>
					<Space direction="vertical" size={4} style={{ overflow: "hidden", width: "100%" }}>
						<StyledName>{collection.name}</StyledName>
					</Space>
				</Flex>
			</Flex>
		</StyledBlock>
	);
};
