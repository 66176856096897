import { css } from "@emotion/css";
import iconReference from "../services/IconReference";
import { LoadingSpinner } from "./AppBlocks";

const exportImage = iconReference["export_bar"];

const button = css`
	display: block;
	height: 80px;
	margin: 0 auto;
	appearance: none;
	border: none;
	background-color: #fff;
	color: #353337;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
`;

interface IExportBarProps {
	buttonText: string;
	buttonClick: () => void;
	loading: boolean;
}

const ExportBar = ({ buttonText, buttonClick, loading }: IExportBarProps) => {
	const bar = css`
		width: 100%;
		height: 80px;
		background-color: #fff;
		margin-top: 24px;
		${loading ? "text-align: center;" : ""}
	`;

	return (
		<div className={bar}>
			{loading ? (
				<LoadingSpinner size={30} color="#353337" style={{ marginTop: 25 }} />
			) : (
				<button className={button} onClick={buttonClick} aria-label={buttonText}>
					<img alt="Export Icon" src={String(exportImage)} style={{ marginRight: 4 }} />
					{buttonText}
				</button>
			)}
		</div>
	);
};

export default ExportBar;
