import { Alert } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { CheckboxField, InputNumberField, RadioField, SelectField, SwitchField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { P } from "@/components/text/Text";
import { saveEvent } from "@/state/actions/eventActions";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";

export const formName = "waitlistSettings";
const triggersValidator = (...[, , allValues]) => {
	if (!allValues.triggers || (!allValues.triggers.ticketTypesSellout && !allValues.triggers.capacityReached)) {
		return "At least one trigger must be selected";
	}
	return undefined;
};

let WaitlistSettings = ({ handleSubmit, currentEvent, enabled, triggers, releaseStrategy, saveSettings }) => {
	const tickets = currentEvent.event.ticketTypes.concat(currentEvent.event.packagedTickets);
	const ticketOptions = tickets
		.filter((t) => !t.deleted && !t.isDonation)
		.map((ticketType) => {
			return {
				label: ticketType.name,
				value: ticketType._id
			};
		});
	return (
		<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={saveSettings}>
			<form onSubmit={handleSubmit(saveSettings)}>
				<P>Allow attendees to join a waitlist if the ticket type or event sells out.</P>
				<Field name="enabled" label="Enable waitlist" component={SwitchField} />
				<hr />
				{enabled ? (
					<div>
						<InputLabel label="Turn waitlist on when" />
						<Field
							name="triggers.capacityReached"
							label="Total event capacity has been reached"
							component={CheckboxField}
							inline
							labelAfter
							validate={triggersValidator}
						/>
						<br />
						<Field
							name="triggers.ticketTypesSellout"
							label="Ticket type has sold out"
							component={CheckboxField}
							inline
							labelAfter
							validate={triggersValidator}
						/>
						{triggers && triggers.ticketTypesSellout ? (
							<Field
								name="ticketTypes"
								label="Select ticket type(s)"
								description="Leave blank if you would like to have a waitlist for all ticket types"
								component={SelectField}
								mode="multiple"
								options={ticketOptions}
								placeholder="All ticket types"
							/>
						) : null}
						<hr />
						<Field
							name="sizeLimit"
							label="Maximum waitlist size"
							description="Leave blank for unlimited size"
							placeholder="Unlimited"
							component={InputNumberField}
							allowsNullValue
						/>
						<div>
							<hr />
							<Field
								name="releaseStrategy"
								label="Offer tickets"
								component={RadioField}
								button
								options={[
									{
										label: "Automatically",
										value: "automatic"
									},
									{
										label: "Manually",
										value: "manual"
									}
								]}
							/>
							{releaseStrategy === "automatic" ? (
								<div>
									<Alert
										showIcon
										type="info"
										message={
											<span>
												{" "}
												When tickets become available the first guest on the waitlist will automatically be notified and
												offered tickets. Every 30 minutes the next guest in line will be offered tickets until
												allocation is exhausted.
												<a onClick={OpenHelpHandler(HelpIds.waitlist)}> Learn more</a>
											</span>
										}
									/>
									<Field
										name="releaseWhen"
										label="Tickets offer email trigger"
										description="Set the number of tickets that need to be available before being offered to people on the waitlist"
										tooltip="Tickets release email is an email sent to the attendees who joined the waitlist. Through
                   that email attendees can claim their tickets."
										tooltipIcon
										component={InputNumberField}
									/>
								</div>
							) : null}
						</div>
					</div>
				) : null}
			</form>
		</LoadErrorView>
	);
};

WaitlistSettings = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(WaitlistSettings);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		return {
			initialValues: state.currentEvent.event.waitlistSettings,
			currentEvent: state.currentEvent,
			enabled: selector(state, "enabled"),
			triggers: selector(state, "triggers"),
			releaseStrategy: selector(state, "releaseStrategy")
		};
	},
	{ saveEvent }
)(WaitlistSettings);
