import { Table, notification } from "@/ui/antd";
import { useEffect } from "react";
import withReportProvider from "../../hoc/withReportProvider";

import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { ContentBlock, ReportContent } from "../../components/AppBlocks";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { goToPathWithSearchState } from "../../utils/Urls";
import ReportsFilter from "../ReportsFilter";

interface BaseMetric {
	count: number;
	sales: string;
}

interface EventDateMetric extends BaseMetric {
	_id: string;
	name: string;
}

interface EventDateTicketMetric extends EventDateMetric {
	capacity: number;
}

interface EventDate {
	_id: string;
	display?: {
		combined: string;
	};
	ticketTypes: EventDateTicketMetric[];
	addOns: EventDateMetric[];
	donations: EventDateMetric;
}

interface EventTotalMetric extends BaseMetric {
	range: {
		count: number;
		change: number;
	};
}

interface EventTotalTicketMetric extends EventTotalMetric {
	capacity: number;
}

interface EventTotals {
	tickets: EventTotalTicketMetric;
	addOns: EventTotalMetric;
	donations: EventTotalMetric;
}

interface EventSalesUpdateType {
	name: string;
	slug: string;
	eventLocation: string;
	totals: EventTotals;
	eventDates: EventDate[];
}

const cellStyle = css({
	maxWidth: "250px"
});

const actionBarWrapper = css({
	width: "100%",
	display: "flex",
	justifyContent: "flex-end",
	padding: 26
});

const headerStyle = css({
	maxWidth: "unset"
});

const accentCss = (isIncrease: boolean) =>
	css({
		color: isIncrease ? "#157e77" : "#c0212c"
	});

const renderPercentageChange = (range: any) => {
	const displayPercentageChange = !!range.change;

	const directionalArrow =
		range.change > 0 ? <CaretUpOutlined style={{ fontSize: 12 }} /> : <CaretDownOutlined style={{ fontSize: 12 }} />;

	if (displayPercentageChange) {
		return (
			<span className={accentCss(range.change > 0)}>
				{" "}
				({Math.abs(Math.round(range.change))}%) {directionalArrow}
			</span>
		);
	}
	return <></>;
};

const getTotalsColumns = () => {
	return [
		{
			title: "Types",
			dataIndex: "types",
			key: "types",
			render: (
				_: unknown,
				data: (EventTotalTicketMetric | EventTotalMetric) & {
					type: string;
				}
			) => {
				const { type, range } = data;
				const label = type === "tickets" ? "Tickets" : type === "addOns" ? "Add-ons" : "Donations";
				return (
					<div>
						<p>
							<b>{label}</b>
						</p>
						<div className={cellStyle}>
							<p>
								Last 24 hrs: {range.count}
								{renderPercentageChange(range)}
							</p>
						</div>
					</div>
				);
			}
		},
		{
			title: "Sold",
			dataIndex: "sold",
			key: "sold",
			width: 200,
			render: (
				_: unknown,
				data: (EventTotalTicketMetric | EventTotalMetric) & {
					type: string;
					capacity?: number;
				}
			) => {
				const { type, count, capacity } = data;
				const hasCapacity = type === "tickets";

				return (
					<div className={cellStyle}>
						<p>
							{count}
							{hasCapacity ? `/${capacity}` : ""}
						</p>
					</div>
				);
			}
		},
		{
			title: "Sales",
			fixed: "right" as boolean | "left" | "right",
			dataIndex: "sales",
			key: "sales",
			width: 100
		}
	];
};

const getGenericEventDateColumns = () => {
	return [
		{
			title: "Sold",
			dataIndex: "count",
			key: "count",
			width: 200
		},
		{
			title: "Sales",
			fixed: "right" as boolean | "left" | "right",
			dataIndex: "sales",
			key: "sales",
			width: 100
		}
	];
};

const EventSalesUpdate = ({ reportState, load }: { reportState: any; load: () => void }) => {
	const { results: events = [], loading, error } = reportState;

	useEffect(() => {
		if (error) {
			notification.error({ message: error.message });
		}
	}, [error]);

	const totalsColumns = getTotalsColumns();
	const genericEventDateColumns = getGenericEventDateColumns();

	return (
		<>
			<ContentBlock style={{ padding: 0 }}>
				<ReportsFilter
					showSearchField={false}
					eventSearchPlaceholder="My events"
					loadReport={load}
					header={
						<p className={headerStyle}>
							Generate a report of sales made in the last 24 hours for your live events. Schedule updates to be emailed
							daily or weekly to a list of recipients.
						</p>
					}
				/>

				{events.length > 0 && (
					<div className={actionBarWrapper}>
						<LegacyButton
							ariaLabel="schedule email"
							onClick={() => goToPathWithSearchState("scheduled/new")}
							type="action"
						>
							Schedule email
						</LegacyButton>
					</div>
				)}
			</ContentBlock>

			{events?.map((event: EventSalesUpdateType) => {
				const eventTotals: EventTotals = event.totals || {};
				const totals = Object.keys(eventTotals).map((type: string) => {
					return {
						...eventTotals[type as keyof EventTotals],
						type
					};
				});

				const { name, eventDates = [] } = event;

				return (
					<div key={event.slug} style={{ marginBottom: 32 }}>
						<ContentBlock style={{ padding: 0 }}>
							<ReportContent>
								<h2>{name}</h2>
								<Table
									columns={totalsColumns}
									dataSource={totals}
									loading={loading}
									locale={{ emptyText: "No results" }}
									rowKey="type"
									pagination={false}
								/>
							</ReportContent>
						</ContentBlock>

						{eventDates?.map((eventDate: EventDate) => {
							const { display, ticketTypes = [], addOns = [], donations } = eventDate;
							return (
								<div key={eventDate._id}>
									<ContentBlock style={{ padding: 0 }}>
										<ReportContent>
											<p>{display?.combined}</p>
											<Table
												columns={[
													{
														title: "Tickets",
														dataIndex: "name",
														key: "name"
													},
													{
														title: "Sold",
														dataIndex: "count",
														key: "count",
														width: 200,
														render: (_: unknown, data: EventDateTicketMetric) => {
															const { count, capacity } = data;
															return (
																<div className={cellStyle}>
																	{count}/{capacity}
																</div>
															);
														}
													},
													{
														title: "Sales",
														fixed: "right" as boolean | "left" | "right",
														dataIndex: "sales",
														key: "sales",
														width: 100
													}
												]}
												dataSource={ticketTypes}
												loading={loading}
												locale={{ emptyText: "No results" }}
												rowKey="_id"
												pagination={false}
											/>

											{!!addOns.length && (
												<Table
													style={{ marginTop: 24 }}
													columns={[
														{
															title: "Add-ons",
															dataIndex: "name",
															key: "name"
														}
													].concat(genericEventDateColumns)}
													dataSource={addOns}
													loading={loading}
													locale={{ emptyText: "No results" }}
													rowKey="name"
													pagination={false}
												/>
											)}

											{!!donations?.count && (
												<Table
													style={{ marginTop: 24 }}
													columns={[
														{
															title: "Donations",
															dataIndex: "name",
															key: "name"
														}
													].concat(genericEventDateColumns)}
													dataSource={[donations]}
													loading={loading}
													locale={{ emptyText: "No results" }}
													rowKey="name"
													pagination={false}
												/>
											)}
										</ReportContent>
									</ContentBlock>
								</div>
							);
						})}
					</div>
				);
			})}
		</>
	);
};

export default withReportProvider(EventSalesUpdate, "Event sales update", "event-sales-update");
