import { Utils } from "@hx/seating-map";
import * as React from "react";
import { IconButton } from "../components/IconButton";
import ToolTip from "../components/ToolTip";
import { IconNames } from "../icons";
const { ToolBarInterface } = Utils;

class Zoom extends React.Component<{}, { zoomPercent: number }> {
	constructor(props: {}) {
		super(props);
		this.state = {
			zoomPercent: ToolBarInterface.getZoom()
		};
		ToolBarInterface.onZoom((zoomPercent) => {
			this.setState({ zoomPercent });
		});
	}

	render() {
		const { zoomPercent } = this.state;
		return (
			<div
				style={{
					boxShadow: "0 0 1px 0 #f1f1f1, 0 2px 3px 0 rgba(32, 7, 64, 0.13)",
					borderRadius: 4,
					display: "flex",
					backgroundColor: "white"
				}}
			>
				<div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
					<ToolTip message="Zoom out">
						<div
							style={{
								width: 36,
								height: 36
							}}
						>
							<IconButton
								onClick={() => {
									ToolBarInterface.zoom(-0.1);
								}}
								toggleAble={true}
								icon={IconNames.zoomOut}
							/>
						</div>
					</ToolTip>
				</div>
				<div style={{ width: 1, height: 36, borderLeft: "solid 1px #dedede" }} />
				<div
					style={{
						fontSize: 14,
						fontWeight: 600,
						margin: "auto",
						textAlign: "center",
						minWidth: 60,
						color: "#5a626c",
						paddingTop: 8,
						paddingBottom: 8
					}}
				>
					{`${(zoomPercent * 100).toFixed(0)}%`}
				</div>
				<div style={{ width: 1, height: 36, borderLeft: "solid 1px #dedede" }} />
				<div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
					<ToolTip message="Zoom in">
						<div
							style={{
								width: 36,
								height: 36
							}}
						>
							<IconButton
								onClick={() => {
									ToolBarInterface.zoom(0.1);
								}}
								toggleAble={true}
								icon={IconNames.zoomIn}
							/>
						</div>
					</ToolTip>
				</div>
			</div>
		);
	}
}

export default Zoom;
