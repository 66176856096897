import axios from "./AxiosWrapper";

const TagService = {
	async save(tag) {
		const response = await axios.post(`tags/save/`, {
			params: { tag }
		});
		return response.data;
	},

	async findTags(status) {
		const response = await axios.get(`tags/find/`, {
			params: { status }
		});
		return response.data;
	},

	async findTag(id) {
		const response = await axios.get(`tags/find/${id}`, {});
		return response.data;
	},

	async delete(id) {
		const response = await axios.post(`tags/delete/${id}`, {});
		return response.data;
	}
};
export default TagService;
