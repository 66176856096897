import { notification } from "@/ui/antd";
import EmailCampaignsService from "../../services/EmailCampaignsService";
import { getLocationFromState } from "./locationActions";

export const GET_CAMPAIGN_REQUEST = "GET_CAMPAIGN_REQUEST";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_ERROR = "GET_CAMPAIGN_ERROR";

export const SAVE_DRAFT_CAMPAIGN_REQUEST = "SAVE_DRAFT_CAMPAIGN_REQUEST";
export const SAVE_DRAFT_CAMPAIGN_SUCCESS = "SAVE_DRAFT_CAMPAIGN_SUCCESS";
export const SAVE_DRAFT_CAMPAIGN_ERROR = "SAVE_DRAFT_CAMPAIGN_ERROR";

export const SAVE_ENQUEUE_CAMPAIGN_REQUEST = "SAVE_ENQUEUE_CAMPAIGN_REQUEST";
export const SAVE_ENQUEUE_CAMPAIGN_SUCCESS = "SAVE_ENQUEUE_CAMPAIGN_SUCCESS";
export const SAVE_ENQUEUE_CAMPAIGN_ERROR = "SAVE_ENQUEUE_CAMPAIGN_ERROR";

export const APPROVE_CHANGE_REQUEST = "APPROVE_CHANGE_REQUEST";
export const APPROVE_CHANGE_SUCCESS = "APPROVE_CHANGE_SUCCESS";
export const APPROVE_CHANGE_ERROR = "APPROVE_CHANGE_ERROR";

export const LIST_EMAIL_REQUEST = "LIST_EMAIL_REQUEST";
export const LIST_EMAIL_SUCCESS = "LIST_EMAIL_SUCCESS";
export const LIST_EMAIL_ERROR = "LIST_EMAIL_ERROR";

export const DELETE_EMAIL_REQUEST = "DELETE_EMAIL_REQUEST";
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS";
export const DELETE_EMAIL_ERROR = "DELETE_EMAIL_ERROR";

export const SEND_TEST_EMAIL_REQUEST = "SEND_TEST_EMAIL_REQUEST";
export const SEND_TEST_EMAIL_SUCCESS = "SEND_TEST_EMAIL_SUCCESS";
export const SEND_TEST_EMAIL_ERROR = "SEND_TEST_EMAIL_ERROR";

export const CAMPAIGN_SUMMARY_REQUEST = "CAMPAIGN_SUMMARY_REQUEST";
export const CAMPAIGN_SUMMARY_SUCCESS = "CAMPAIGN_SUMMARY_SUCCESS";
export const CAMPAIGN_SUMMARY_ERROR = "CAMPAIGN_SUMMARY_ERROR";

export const SHOW_TEST_EMAIL_MODAL = "SHOW_TEST_EMAIL_MODAL";
export const HIDE_TEST_EMAIL_MODAL = "HIDE_TEST_EMAIL_MODAL";

export const SHOW_PREVIEW_EMAIL_MODAL_REQUEST = "SHOW_PREVIEW_EMAIL_MODAL_REQUEST";
export const SHOW_PREVIEW_EMAIL_MODAL_SUCCESS = "SHOW_PREVIEW_EMAIL_MODAL_SUCCESS";
export const SHOW_PREVIEW_EMAIL_MODAL_ERROR = "SHOW_PREVIEW_EMAIL_MODAL_ERROR";
export const HIDE_PREVIEW_EMAIL_MODAL = "HIDE_PREVIEW_EMAIL_MODAL";

export const GET_SOCIAL_IMPACT_EMAIL_REQUEST = "GET_SOCIAL_IMPACT_EMAIL_REQUEST";
export const GET_SOCIAL_IMPACT_EMAIL_SUCCESS = "GET_SOCIAL_IMPACT_EMAIL_SUCCESS";
export const GET_SOCIAL_IMPACT_EMAIL_ERROR = "GET_SOCIAL_IMPACT_EMAIL_ERROR";

export const GET_UNSUBSCRIBERS_REQUEST = "GET_UNSUBSCRIBERS_REQUEST";
export const GET_UNSUBSCRIBERS_SUCCESS = "GET_UNSUBSCRIBERS_SUCCESS";
export const GET_UNSUBSCRIBERS_ERROR = "GET_UNSUBSCRIBERS_ERROR";

export const showTestEmail = (campaign) => {
	return (dispatch) => {
		dispatch({ type: SHOW_TEST_EMAIL_MODAL, campaign });
	};
};

export const hideTestEmail = () => {
	return (dispatch) => {
		dispatch({ type: HIDE_TEST_EMAIL_MODAL });
	};
};

export const showPreviewEmail = (campaign) => {
	return (dispatch) => {
		dispatch({ type: SHOW_PREVIEW_EMAIL_MODAL_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.getPreview(campaign, location)
			.then((html) => {
				dispatch({ type: SHOW_PREVIEW_EMAIL_MODAL_SUCCESS, html });
			})
			.catch((err) => {
				dispatch({ type: SHOW_PREVIEW_EMAIL_MODAL_ERROR, error: err });
			});
	};
};

export const hidePreviewEmail = () => {
	return (dispatch) => {
		dispatch({ type: HIDE_PREVIEW_EMAIL_MODAL });
	};
};

export const sendTestEmail = (campaign) => {
	return (dispatch) => {
		dispatch({ type: SEND_TEST_EMAIL_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.sendTestEmail(campaign, location)
			.then((error) => {
				if (error) {
					dispatch({ type: SEND_TEST_EMAIL_ERROR, error });
					notification.error({
						message: "Error",
						description: error
					});
				} else {
					dispatch({ type: SEND_TEST_EMAIL_SUCCESS });
					notification.success({
						message: "Sent",
						description: `A test email has been sent`
					});
				}
				dispatch(hideTestEmail());
			})
			.catch((err) => {
				dispatch({ type: SEND_TEST_EMAIL_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to send test email`
				});
				dispatch(hideTestEmail());
			});
	};
};

export const getCampaign = (eventId, id, template) => {
	return (dispatch) => {
		dispatch({ type: GET_CAMPAIGN_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.get(eventId, id, template, location)
			.then((campaign) => {
				dispatch({ type: GET_CAMPAIGN_SUCCESS, campaign });
			})
			.catch((err) => {
				dispatch({ type: GET_CAMPAIGN_ERROR, error: err });
			});
	};
};

export const saveDraft = (eventId, campaign) => {
	return (dispatch) => {
		dispatch({ type: SAVE_DRAFT_CAMPAIGN_REQUEST, campaign });
		const location = getLocationFromState(true);
		EmailCampaignsService.saveDraft(eventId, campaign, location)
			.then((campaign) => {
				dispatch({ type: SAVE_DRAFT_CAMPAIGN_SUCCESS, campaign });
				notification.success({
					message: "Draft saved",
					description: "Your campaign has been saved, but will not be sent until it is scheduled"
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_DRAFT_CAMPAIGN_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to save draft campaign`
				});
			});
	};
};
export const saveAndEnqueue = (eventId, campaign, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_ENQUEUE_CAMPAIGN_REQUEST, campaign });
		const location = getLocationFromState(true);
		EmailCampaignsService.saveAndEnqueue(eventId, campaign, location)
			.then((campaign) => {
				dispatch({ type: SAVE_DRAFT_CAMPAIGN_SUCCESS, campaign });
				navigate(`/console/comms/email-campaigns`);
				notification.success({
					message: "Saved",
					description: "Your campaign has been saved and will be sent on the selected schedule"
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_DRAFT_CAMPAIGN_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to save and send campaign`
				});
			});
	};
};

export const approveCampaign = (campaignId) => {
	return (dispatch) => {
		dispatch({ type: APPROVE_CHANGE_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.approve(campaignId, location)
			.then((campaign) => {
				dispatch({ type: APPROVE_CHANGE_SUCCESS, campaign });
				notification.success({
					message: "Approved",
					description: "The campaign has been approved and will be sent on the selected schedule"
				});
			})
			.catch((err) => {
				dispatch({ type: APPROVE_CHANGE_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to approve the campaign`
				});
			});
	};
};

export const disapproveCampaign = (campaignId) => {
	return (dispatch) => {
		dispatch({ type: APPROVE_CHANGE_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.disapprove(campaignId, location)
			.then((campaign) => {
				dispatch({ type: APPROVE_CHANGE_SUCCESS, campaign });
				notification.success({
					message: "Disapproved",
					description: "The campaign has been disapproved and will not be sent on the selected schedule"
				});
			})
			.catch((err) => {
				dispatch({ type: APPROVE_CHANGE_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to disapprove the campaign`
				});
			});
	};
};

export const listCampaigns = (userId, page, status, query, eventIds) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: LIST_EMAIL_REQUEST });
		EmailCampaignsService.list(userId, page, status, query, eventIds, location)
			.then((results) => {
				dispatch({
					type: LIST_EMAIL_SUCCESS,
					campaigns: results.campaigns,
					count: results.count
				});
			})
			.catch((err) => {
				dispatch({ type: LIST_EMAIL_ERROR, error: err });
			});
	};
};

export const deleteCampaign = (eventId, id) => {
	return (dispatch) => {
		dispatch({ type: DELETE_EMAIL_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.remove(eventId, id, location)
			.then((emails) => {
				dispatch({ type: DELETE_EMAIL_SUCCESS, emails });
				notification.success({
					message: "Deleted",
					description: `Campaign has been deleted`
				});
				dispatch(listCampaigns(eventId));
			})
			.catch((err) => {
				dispatch({ type: DELETE_EMAIL_ERROR, error: err });
			});
	};
};

export const cancelScheduledEmails = (userId, emailCampaignId) => {
	return () => {
		const location = getLocationFromState(true);
		EmailCampaignsService.cancelScheduledEmails(userId, emailCampaignId, location)
			.then(() => {
				notification.success({
					message: "Cancelled",
					description: `Scheduled emails have been cancelled`
				});
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Failed to cancel scheduled emails`
				});
			});
	};
};

export const getCampaignSummary = (userId, campaign) => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGN_SUMMARY_REQUEST });
		const location = getLocationFromState(true);
		EmailCampaignsService.getCampaignSummary(userId, campaign, location)
			.then((result) => {
				if (result.error) {
					dispatch({ type: CAMPAIGN_SUMMARY_ERROR, error: result.error });
				} else {
					dispatch({ type: CAMPAIGN_SUMMARY_SUCCESS, result });
				}
			})
			.catch((err) => {
				dispatch({ type: CAMPAIGN_SUMMARY_ERROR, error: err });
			});
	};
};

export const getUnsubscribers = (userId, page, query, eventIds) => {
	return (dispatch) => {
		dispatch({ type: GET_UNSUBSCRIBERS_REQUEST });
		EmailCampaignsService.getUnsubscribers(userId, page, query, eventIds)
			.then((result) => {
				dispatch({ type: GET_UNSUBSCRIBERS_SUCCESS, result });
			})
			.catch((error) => {
				dispatch({ type: GET_UNSUBSCRIBERS_ERROR, error });
			});
	};
};
