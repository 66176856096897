import { getConfig } from "@/config";
import { css } from "@emotion/css";
import { Alert } from "@/ui/antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import connectToHubspot from "@/assets/integrations/connect-to-hubspot.svg";
import { ContentBlock, LoadingSpinner } from "@/components/AppBlocks";
import Breadcrumb from "@/components/Breadcrumb";
import { RadioField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2, P } from "@/components/text/Text";
import { getUserEvents } from "@/state/actions/eventActions";
import { disconnectHubspot, getUser, saveHubspotSettings } from "@/state/actions/userActions";
import { lastOfType, max } from "@/utils/CssUtils";

import { HelpIds, OpenHelpHandler } from "@/utils/Help";

const wrapper = css({
	display: "flex",
	marginTop: 8,
	div: {
		maxWidth: "50%",
		[lastOfType()]: {
			display: "flex",
			width: "100%",
			justifyContent: "center",
			alignItems: "flex-start"
		}
	},
	img: {
		padding: 8,
		maxWidth: 300
	},
	[max(600)]: {
		flexDirection: "column-reverse",
		div: {
			maxWidth: "100%"
		}
	}
});

let Hubspot = ({
	currentUser,
	userId,
	getUser,
	disconnectHubspot,
	syncAll,
	userEvents,
	handleSubmit,
	saveHubspotSettings,
	getUserEvents
}) => {
	useEffect(() => {
		getUser(userId);
	}, []);

	useEffect(() => {
		if (!syncAll) {
			getUserEvents(userId, "name", true);
		}
	}, [syncAll]);

	const connectHubspot = () => {
		const clientId = getConfig("HUBSPOT_CLIENT_ID");
		const redirectUri = `${window.location.origin}/connect-hubspot`;
		window.open(
			`https://app.hubspot.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.marketing_events.read%20crm.objects.marketing_events.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read`,
			"_self"
		);
	};
	const isHubspotConnected = currentUser?.user?.hubspotSettings?.refreshToken;
	return (
		<LoadErrorView
			loading={currentUser.loading}
			error={currentUser.error}
			retryAction={() => {
				getUser(userId);
			}}
		>
			<Breadcrumb prevPath="/console/account/advanced/integrations" prevTitle="Integrations" currTitle="HubSpot" />
			<ContentBlock>
				<H2>HubSpot</H2>
				{isHubspotConnected ? (
					<form>
						<Alert
							type="info"
							showIcon
							message="Get the most out of your integration."
							description={
								<>
									Ensure you're requesting the ticket holder's name and email by creating a ticket level{" "}
									<a onClick={OpenHelpHandler(HelpIds.additionalQuestions)}>checkout question.</a>
								</>
							}
							style={{ marginBottom: 15 }}
						/>

						<Field
							label="Sync Events"
							description="Which events will be synced to HubSpot. Only owned events are able to be synced."
							id="syncAll"
							name="syncAll"
							vertical
							component={RadioField}
							options={[
								{
									label: "All",
									value: true
								},
								{
									label: "Selected Events",
									value: false
								}
							]}
						/>
						{syncAll === false ? (
							(!currentUser.user && userEvents.loading) || !userEvents.events ? (
								<div style={{ marginBottom: 12 }}>
									<LoadingSpinner />
								</div>
							) : (
								<Field
									name="selectedEvents"
									label="Select Events"
									component={SelectField}
									showSearch
									options={userEvents.events.map((e) => {
										return { value: e._id, label: e.name };
									})}
									mode="multiple"
								/>
							)
						) : null}

						<Field
							name="contactRule"
							label="Contact Settings"
							inline={false}
							vertical
							description="How will contacts be updated? Contacts are matched by email."
							component={RadioField}
							options={[
								{
									value: "preserve",
									label: "Match existing contact but don't update"
								},
								{
									value: "update",
									label: "Match existing contact and update"
								}
							]}
						/>
					</form>
				) : (
					<div className={wrapper}>
						<div>
							<P>Connect Humanitix to your HubSpot account to sync your orders, tickets and events.</P>
							<P>This will allow you to contact your attendees through your existing CRM platform!</P>
							<P>
								Read more about our integration with HubSpot <a onClick={OpenHelpHandler(HelpIds.hubspotMore)}>here</a>.
							</P>
							<LegacyButton onClick={connectHubspot}>Connect to HubSpot</LegacyButton>
						</div>
						<div>
							<img src={connectToHubspot} alt="" />
						</div>
					</div>
				)}
			</ContentBlock>
			{isHubspotConnected && (
				<FormBar>
					<LegacyButton
						style={{ float: "right" }}
						type="primary"
						htmlType="submit"
						size="large"
						ariaLabel="Save"
						onClick={handleSubmit(saveHubspotSettings)}
					>
						Save
					</LegacyButton>

					<LegacyButton
						htmlType="button"
						onClick={() => {
							disconnectHubspot(userId);
						}}
						size="large"
						ariaLabel="Disconnect HubSpot"
					>
						Disconnect HubSpot
					</LegacyButton>
				</FormBar>
			)}
		</LoadErrorView>
	);
};

const formName = "hubspotSettings";
const selector = formValueSelector(formName);
Hubspot = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Hubspot);

export default connect(
	(state) => {
		const initialValues = {
			contactRule: "preserve",
			...(state.currentUser?.user?.hubspotSettings || {})
		};
		return {
			initialValues,
			currentUser: state.currentUser,
			userEvents: state.userEvents,
			syncAll: selector(state, "syncAll")
		};
	},
	{ getUser, getUserEvents, disconnectHubspot, saveHubspotSettings }
)(Hubspot);
