import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import csvExampleFile from "@/assets/csv/discount-codes-example.csv";
import { ContentBlock } from "@/components/AppBlocks";
import {
	CountryField,
	FileUploadField,
	LegacyInputField,
	InputNumberField,
	RadioField,
	SelectField,
	SwitchField
} from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import { getConfig } from "@/config";
import FileService from "@/services/FileService";
import { getDiscountCodes, saveDiscountCodes } from "@/state/actions/discountCodesActions";
import { saveGlobalDiscountCodes } from "@/state/actions/globalDiscountCodesActions";
import { getCurrencySymbol } from "@/utils/Format";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { getTicketDisplayName } from "@/utils/Ticket";
import { mustBeGreaterThanZero, mustBeLessThanOrEqualTo, mustBeUnique, required } from "@/utils/Validators";
import DiscountCodesDateRange from "@/events/event/discountCodes/DiscountCodesDateRange";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "discountCode";
const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	}),
	codeField: css({
		width: 480
	}),
	appliesToField: css({
		maxWidth: 480
	})
};

class DiscountCodePage extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.cancel = this.cancel.bind(this);
		this.load = this.load.bind(this);
	}

	componentWillMount() {
		this.load();
	}

	load(page = 1) {
		const { getDiscountCodes, params } = this.props;
		const eventId = params?.eventId;
		getDiscountCodes(eventId, page);
	}

	/**
	 * @param values: values of all the Fields of the form
	 *
	 *
	 */

	save(values) {
		const { saveDiscountCodes, currentEvent, isGlobal, saveGlobalDiscountCodes, currentUser, navigate } = this.props;
		if (isGlobal) {
			saveGlobalDiscountCodes(currentUser.user.id, values);
			navigate(`/console/my-events/${currentEvent.event._id}/discounts/codes`);
		} else {
			saveDiscountCodes(currentEvent.event._id, values, navigate);
			document.getElementById("pageContent").scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
	}

	/**
	 * @param
	 *
	 * Discard all changes and withought checking for required fields push the history to discounts/automatic page
	 */
	cancel() {
		const { currentEvent, navigate } = this.props;
		navigate(`/console/my-events/${currentEvent.event._id}/discounts/codes`);
	}

	downloadFile = () => {
		FileService.downloadFile(csvExampleFile, "discount-codes-example.csv");
	};

	codeRequired = required("Code required");
	codeMustBeUnique = mustBeUnique(
		"Code with this name exists",
		this.props.params?.discountCodeId,
		this.props.discountCodes.codes
	);
	discountAmountRequired = required("Discount amount required");
	quantityRequired = required("Quantity required");
	percentageLessThanOneHundred = mustBeLessThanOrEqualTo(100);

	render() {
		const { currentEvent, discountCodes, type, touch, handleSubmit, isGlobal, userEvents } = this.props;
		const tickets = isGlobal ? userEvents.events : currentEvent.event.ticketTypes;
		const ticketOptions = [{ value: "all", label: "All Ticket Types" }].concat(
			tickets
				.filter((t) => !t.isDonation && !t.deleted)
				.map((ticket) => {
					return { value: ticket._id, label: getTicketDisplayName(ticket) };
				})
		);
		const timezone = currentEvent.event.timezone ? currentEvent.event.timezone : getConfig("TIMEZONE_DEFAULT");

		return (
			<form onSubmit={handleSubmit(this.save)}>
				<LoadErrorView
					loading={discountCodes.getRequest.loading || discountCodes.saveRequest.loading}
					retryAction={this.save}
				>
					<H1>Discount Code</H1>
					<ContentBlock>
						<Field
							name="type"
							label="Code type"
							component={RadioField}
							button
							options={[
								{
									label: "Single Code",
									value: "single"
								},
								{
									label: "CSV Upload",
									value: "upload"
								}
							]}
						/>
						{type === "single" ? (
							<Field
								name="code"
								required
								placeholder="Code"
								label="Code"
								component={LegacyInputField}
								dataCy="code"
								validate={[this.codeMustBeUnique, this.codeRequired]}
								style={{
									maxWidth: 480
								}}
							/>
						) : (
							<div>
								<div style={{ marginBottom: 24 }}>
									CSV example file: {/*eslint-disable-next-line*/}
									<a onClick={this.downloadFile} download="discount-codes-example">
										Download
									</a>
								</div>
								<Field
									name="csvFile"
									label="Upload a CSV"
									description="File must be a csv or txt file containing one code per line, duplicate codes will be ignored"
									component={FileUploadField}
									validate={this.codeRequired}
								/>
							</div>
						)}
						<div
							className={css({
								position: "relative",
								".ant-row": {
									margin: "0 !important"
								}
							})}
						>
							<Field
								label="Discount per ticket"
								required
								name="discount"
								component={InputNumberField}
								placeholder="0"
								dataCy="discount"
								precision={2}
								min={0}
								max={this.props.discountType === "percent" ? 100 : undefined}
								style={{ width: 70 }}
								validate={[
									this.discountAmountRequired,
									mustBeGreaterThanZero,
									...((this.props.discountType === "percent" && [this.percentageLessThanOneHundred]) || [])
								]}
							/>
							<Field
								inline
								name="discountType"
								component={RadioField}
								button
								placeholder="discountType"
								style={{
									position: "absolute",
									top: 23,
									left: 76
								}}
								options={[
									{ value: "percent", label: "%" },
									{ value: "amount", label: getCurrencySymbol() }
								]}
							/>
						</div>
						<div className={styles.appliesToField}>
							{isGlobal ? <CountryField /> : null}
							<Field
								mode="multiple"
								name="appliesTo"
								label="Applies to"
								placeholder="Please Select"
								component={SelectField}
								options={ticketOptions}
								style={{ width: "100%" }}
								required
							/>
						</div>
						<Field
							label="Quantity"
							name="quantity"
							dataCy="quantity"
							required
							description="Total amount of tickets that can be discounted"
							component={InputNumberField}
							validate={this.quantityRequired}
							min={0}
						/>
						<Field
							label="Maximum use per order"
							name="maximumUsePerOrder"
							dataCy="maximumUsePerOrder"
							description="Amount of tickets that can be discounted per order"
							component={InputNumberField}
							allowsNullValue
							min={0}
						/>
						<DiscountCodesDateRange touch={touch} timezone={timezone} formName={formName} />
						<Field name="enabled" label="Enabled" component={SwitchField} style={{ marginBottom: 24 }} />
					</ContentBlock>
				</LoadErrorView>

				<FormBar>
					<LegacyButton
						style={{
							float: "right",
							width: "100px"
						}}
						onClick={handleSubmit(this.save)}
						type="primary"
						disabled={currentEvent.save.loading || discountCodes.saveRequest.loading}
						ariaLabel="Save"
						htmlType="submit"
						dataCy="saveCode"
					>
						Save
					</LegacyButton>
					<LegacyButton
						style={{
							float: "left",
							width: "100px"
						}}
						onClick={this.cancel}
						disabled={currentEvent.save.loading || discountCodes.saveRequest.loading}
						ariaLabel="Cancel"
						htmlType="button"
					>
						Cancel
					</LegacyButton>
				</FormBar>
			</form>
		);
	}
}

DiscountCodePage = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(DiscountCodePage);

const selector = formValueSelector(formName);
export default withRouterHooks(
	connect(
		(state, ownProps) => {
			const { params } = ownProps;
			const discountCodeId = params?.discountCodeId;
			let initialData = ownProps.isGlobal
				? {
						userId: state.auth.user?.id,
						enabled: true,
						isGlobal: true,
						discountType: "percent",
						type: "single",
						location: state.auth.user?.location
				  }
				: {
						eventId: state.currentEvent.event._id,
						enabled: true,
						discountType: "percent",
						type: "single",
						location: state.currentEvent.event.location
				  };

			let initialValues;
			if (discountCodeId === "new") {
				initialValues = initialData;
			} else {
				//when the app is redirected from discount/code
				if (state.discountCodes.editingCode) {
					initialValues = state.discountCodes.editingCode;
					initialValues.type = "single";
					//when the page is reloaded on the editting page
				} else if (discountCodeId !== "new" && state.discountCodes.codes) {
					const discountCode = state.discountCodes.codes.find((discount) => discount._id === discountCodeId);
					initialValues = discountCode;
					initialValues.type = "single";
				}
			}

			return {
				initialValues,
				currentEvent: state.currentEvent,
				currentUser: state.auth,
				userEvents: state.userEvents,
				discountCodes: state.discountCodes,
				type: selector(state, "type"),
				discountType: selector(state, "discountType")
			};
		},
		{
			saveDiscountCodes,
			saveGlobalDiscountCodes,
			getDiscountCodes
		}
	)(DiscountCodePage)
);
