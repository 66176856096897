import { notification } from "@/ui/antd";
import ReferralService from "../../services/ReferralService";
import { getLocationFromState } from "./locationActions";

export const GET_USER_REFERRAL_REQUEST = "GET_USER_REFERRAL_REQUEST";
export const GET_USER_REFERRAL_SUCCESS = "GET_USER_REFERRAL_SUCCESS";
export const GET_USER_REFERRAL_ERROR = "GET_USER_REFERRAL_ERROR";

export const ASSOCIATE_USER_REFERRAL_REQUEST = "ASSOCIATE_USER_REFERRAL_REQUEST";
const ASSOCIATE_USER_REFERRAL_SUCCESS = "ASSOCIATE_USER_REFERRAL_SUCCESS";
export const ASSOCIATE_USER_REFERRAL_ERROR = "ASSOCIATE_USER_REFERRAL_ERROR";

export const UPDATE_USER_REFERRAL_REQUEST = "UPDATE_USER_REFERRAL_REQUEST";
export const UPDATE_USER_REFERRAL_SUCCESS = "UPDATE_USER_REFERRAL_SUCCESS";
export const UPDATE_USER_REFERRAL_ERROR = "UPDATE_USER_REFERRAL_ERROR";

export const USER_REFERRAL_PAYMENT_REQUEST = "USER_REFERRAL_PAYMENT_REQUEST";
export const USER_REFERRAL_PAYMENT_SUCCESS = "USER_REFERRAL_PAYMENT_SUCCESS";
export const USER_REFERRAL_PAYMENT_ERROR = "USER_REFERRAL_PAYMENT_ERROR";

export const DELETE_USER_REFERRAL_PAYMENT_REQUEST = "DELETE_USER_REFERRAL_PAYMENT_REQUEST";
export const DELETE_USER_REFERRAL_PAYMENT_SUCCESS = "DELETE_USER_REFERRAL_PAYMENT_SUCCESS";
export const DELETE_USER_REFERRAL_PAYMENT_ERROR = "DELETE_USER_REFERRAL_PAYMENT_ERROR";

export const getUserReferral = (userId) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_USER_REFERRAL_REQUEST });
		ReferralService.getUserReferral(userId, location)
			.then((referral) => {
				dispatch({ type: GET_USER_REFERRAL_SUCCESS, referral });
			})
			.catch((err) => {
				dispatch({ type: GET_USER_REFERRAL_ERROR, error: err });
			});
	};
};

export const updateUserReferralBankAccount = (userId, values) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_REFERRAL_REQUEST });
		ReferralService.updateUserReferralBankAccount(userId, values)
			.then((referral) => {
				notification.success({ message: "Settings updated" });
				dispatch({ type: UPDATE_USER_REFERRAL_SUCCESS, referral });
			})
			.catch((err) => {
				notification.error({
					message: "Issue updating settings, try again soon"
				});
				dispatch({ type: UPDATE_USER_REFERRAL_ERROR, error: err });
			});
	};
};

export const updateUserReferralDetails = (userId, referralObj) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_REFERRAL_REQUEST });
		ReferralService.updateUserReferralDetails(userId, referralObj)
			.then((referral) => {
				notification.success({ message: "Settings updated" });
				dispatch({ type: UPDATE_USER_REFERRAL_SUCCESS, referral });
			})
			.catch((err) => {
				notification.error({
					message: "Issue updating settings, try again soon"
				});
				dispatch({ type: UPDATE_USER_REFERRAL_ERROR, error: err });
			});
	};
};

export const managePayment = (userId, paymentObj) => {
	const location = getLocationFromState(true);
	return (dispatch) => {
		dispatch({ type: USER_REFERRAL_PAYMENT_REQUEST });
		ReferralService.managePayment(userId, paymentObj, location)
			.then((referral) => {
				dispatch({ type: USER_REFERRAL_PAYMENT_SUCCESS, referral });
			})
			.catch((err) => {
				dispatch({ type: USER_REFERRAL_PAYMENT_ERROR, error: err });
			});
	};
};

export const removePayment = (userId, paymentObj) => {
	const location = getLocationFromState(true);
	return (dispatch) => {
		dispatch({ type: DELETE_USER_REFERRAL_PAYMENT_REQUEST });
		ReferralService.removePayment(userId, paymentObj, location)
			.then((referral) => {
				dispatch({
					type: DELETE_USER_REFERRAL_PAYMENT_SUCCESS,
					referral
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_USER_REFERRAL_PAYMENT_ERROR, error: err });
			});
	};
};

export const associateUserToReferral = (userIdOfReferrer) => {
	return async (dispatch, getState) => {
		const state = getState();
		dispatch({ type: ASSOCIATE_USER_REFERRAL_REQUEST });
		try {
			const currentUserId = state.currentUser.user.id;
			dispatch({ type: ASSOCIATE_USER_REFERRAL_SUCCESS });
			await ReferralService.associateUserToReferral(currentUserId, userIdOfReferrer);
			notification.success({ message: "User was assigned to referral" });
			dispatch(getUserReferral(currentUserId));
		} catch (err) {
			dispatch({ type: ASSOCIATE_USER_REFERRAL_ERROR });
			notification.error({ message: "User was not able to be assigned referral" });
		}
	};
};
