import { Alert } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import { InputNumberField } from "../components/Fields";
import FormBar from "../components/FormBar";
import LoadErrorView from "../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2 } from "../components/text/Text";
import withSaveCheck from "../hoc/withSaveCheck";
import { getUserReferral, updateUserReferralDetails } from "../state/actions/referralActions";
import { required } from "../utils/Validators";
import ReferralLinkerModal from "./ReferralLinkerModal";

import { getUser } from "../state/actions/userActions";
class ReferralSettings extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.state = {
			isModalOpen: false
		};
	}
	earningRequired = required("A referral earning is required");
	expiryRequired = required("A expiry duration is required");

	componentDidMount() {
		const { userId, getUserReferral, getUser } = this.props;

		getUser(userId);
		getUserReferral(userId);
	}
	save(values) {
		const { userId, updateUserReferralDetails } = this.props;
		updateUserReferralDetails(userId, values);
	}

	toggleModal = () => {
		this.setState({ isModalOpen: !this.state.isModalOpen });
	};

	render() {
		const { handleSubmit, userReferrals } = this.props;
		const { isModalOpen } = this.state;
		const { referral } = userReferrals;
		const { referrerId } = referral;
		return (
			<LoadErrorView loading={userReferrals.loading} error={userReferrals.error} retryAction={() => this.loadUser()}>
				<ReferralLinkerModal isOpen={isModalOpen} toggleModal={this.toggleModal} />
				{referrerId && (
					<Alert
						title="User is assigned to someone"
						description={
							<>
								User is currently assigned to <Link to={`/console/admin/users/${referrerId}`}>this user.</Link>
							</>
						}
						showIcon
					/>
				)}
				<form onSubmit={handleSubmit(this.save)}>
					<ContentBlock>
						<H2>Referral Settings</H2>

						<div style={{ width: 225 }}>
							<Field
								name="percentageEarned"
								label="Referral Earnings Per Event"
								component={InputNumberField}
								validate={this.earningRequired}
								placeholder={5}
							/>
							<Field
								name="duration"
								label="Referral Expiry Duration"
								component={InputNumberField}
								validate={this.expiryRequired}
								placeholder={12}
							/>
						</div>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "left"
							}}
							type="action"
							ariaLabel="Update"
							size="large"
							onClick={this.toggleModal}
						>
							Link to referrer
						</LegacyButton>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							ariaLabel="Update"
							size="large"
						>
							Update
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "referralSettings";
// Decorate with redux-form
ReferralSettings = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(ReferralSettings);

export default connect(
	(state) => {
		const initState = state.userReferrals && state.userReferrals.referral ? state.userReferrals.referral : null;

		return {
			userReferrals: state.userReferrals,
			initialValues: initState
		};
	},
	{ updateUserReferralDetails, getUserReferral, getUser }
)(withSaveCheck(ReferralSettings, formName));
