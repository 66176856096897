import axios from "./AxiosWrapper";

const FollowerService = {
	async find(query, page, organiserId) {
		const response = await axios.get(`organisers/followers/${organiserId}`, {
			params: {
				query: query,
				page: page
			}
		});
		return response.data;
	},
	async download(query, organiserId) {
		const response = await axios.get(`organisers/followers/${organiserId}/csv`, {
			params: { query }
		});
		return response.data;
	},
	async count(organiserId) {
		const response = await axios.get(`organisers/followers/${organiserId}/count`);
		return response.data;
	},
	async update(organiserId, organiserData) {
		const response = await axios.post(`organisers/${organiserId}`, organiserData);
		return response.data;
	},
	async findAssociatedEvents(organiserId) {
		const response = await axios.get(`organisers/associatedEvents/${organiserId}`);
		return response.data;
	},
	async remove(organiserId, followerId) {
		const response = await axios.delete(`organisers/followers/${organiserId}/${followerId}`);
		return response.data;
	},
	async upload(organiserId, followers) {
		let fd = new FormData();
		fd.append("file", followers[0]);
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const response = await axios.put(`organisers/followers/${organiserId}/upload`, fd, config);
		return response.data;
	}
};
export default FollowerService;
