import { Transfer } from "@/ui/antd";
import { Component } from "react";
import InputWrapper from "./helpers/InputWrapper";

class TransferField extends Component {
	constructor(props) {
		super(props);
		const { targetKeys } = this.props;
		let initTargetKeys = targetKeys ? targetKeys : [];
		this.state = {
			targetKeys: initTargetKeys
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (nextTargetKeys) => {
		const { input } = this.props;
		this.setState({ targetKeys: nextTargetKeys });
		input.onChange(nextTargetKeys);
	};

	render() {
		const {
			input,
			label,
			description,
			inline,
			required,
			disabled,
			listStyle,
			style,
			titles,
			size = "default",
			dataSource,
			selectedKeys,
			tooltip,
			tooltipIcon = false,
			tooltipIconOnClick,
			meta: { touched, error }
		} = this.props;
		const { targetKeys } = this.state;
		return (
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				id={input.name}
				inline={inline}
				required={required}
				tooltip={tooltip}
				tooltipIcon={tooltipIcon}
				tooltipIconOnClick={tooltipIconOnClick}
			>
				<Transfer
					id={input.name}
					{...input}
					style={style}
					listStyle={listStyle}
					titles={titles}
					disabled={disabled}
					size={size}
					dataSource={dataSource}
					targetKeys={targetKeys}
					selectedKeys={selectedKeys}
					render={(item) => item.title}
					onChange={this.handleChange}
				/>
			</InputWrapper>
		);
	}
}

export default TransferField;
