import axios from "./AxiosWrapper";

const GlobalDiscountCodesService = {
	async find(userId, page, query, location) {
		const response = await axios.get(`global/global-discount-codes/${userId}`, {
			params: { page, query },
			headers: {
				"x-override-location": location
			}
		});
		return response.data;
	},

	async get(userId, discountCodeId, location) {
		const response = await axios.get(`global/global-discount-codes/${userId}/${discountCodeId}`, {
			headers: {
				"x-override-location": location
			}
		});
		return response.data;
	},
	async save(userId, discountCode) {
		if (discountCode._id) {
			return this.update(userId, discountCode);
		}
		return this.create(userId, discountCode);
	},

	async create(userId, discountCode) {
		if (discountCode.type === "single") {
			const response = await axios.put(`global/global-discount-codes/${userId}`, discountCode);
			return response.data;
		}
		// upload a CSV
		let fd = new FormData();
		fd.append("file", discountCode.csvFile[0]);
		fd.append("appliesTo", discountCode.appliesTo);
		fd.append("enabled", discountCode.enabled);
		fd.append("quantity", discountCode.quantity);
		fd.append("maximumUsePerOrder", discountCode.maximumUsePerOrder);
		fd.append("discount", discountCode.discount);
		fd.append("discountType", discountCode.discountType);
		fd.append("startDate", discountCode.startDate);
		fd.append("endDate", discountCode.endDate);
		fd.append("location", discountCode.location);
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const response = await axios.put(`global/global-discount-codes/upload/${userId}`, fd, config);
		return response.data;
	},
	async update(userId, discountCode) {
		const response = await axios.post(`global/global-discount-codes/${userId}`, discountCode);
		return response.data;
	},
	async delete(userId, discountCode) {
		const response = await axios.delete(`global/global-discount-codes/${userId}/${discountCode._id}`, discountCode);
		return response.data;
	},
	async deleteAll(userId) {
		const response = await axios.delete(`global/global-discount-codes/${userId}/all`);
		return response.data;
	}
};
export default GlobalDiscountCodesService;
