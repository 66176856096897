import {
	CharityImpact,
	EventSalesOverview,
	ResoldTicketsCard,
	SalesChart,
	TicketSalesByDate,
	TicketSalesTable,
	WaitlistStatusCard
} from "@/components/Charts";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import ReportingGroups from "@/components/widgets/ReportingGroups";
import ShareEvent from "@/events/event/ShareEvent";
import Warnings from "@/events/event/messages/Warnings";
import TicketsStatusModal from "@/events/event/ticketStatus/TicketsStatusModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { getEventMetrics, getHistoricalSales, getSales } from "@/state/actions/metricActions";
import { Grid } from "@/ui";
import { getCurrencySymbol } from "@/utils/Format";
import { Component } from "react";
import { connect } from "react-redux";

const ticketLines = [
	{ label: "Tickets", key: "tickets", stroke: "#353337" },
	{ label: "Orders", key: "orders", stroke: "#21c0b5" },
	{ label: "Cancellations", key: "cancellations", stroke: "#e6a081" },
	{ label: "Resales", key: "resales", stroke: "#b18fff" }
];
const revenueLines = [
	{ label: "Net Sales", key: "netSales", stroke: "#353337" },
	{ label: "Gross Sales", key: "grossSales", stroke: "#21c0b5" },
	{ label: "Refunds", key: "refunds", stroke: "#e6a081" }
];

class Overview extends Component {
	constructor(props) {
		super(props);
		this.handleHistoricalSalesChange = this.handleHistoricalSalesChange.bind(this);
		this.handleSalesChange = this.handleSalesChange.bind(this);
		this.handleNavigationToWaitlist = this.handleNavigationToWaitlist.bind(this);
	}

	componentWillMount() {
		const eventId = this.props.currentEvent.event._id;
		this.props.getEventMetrics(eventId, "today", "all");
	}

	handleTimeScaleSelect(value) {
		const eventId = this.props.currentEvent.event._id;
		this.props.getDashboardMetrics(eventId, value);
	}

	handleHistoricalSalesChange(value) {
		const eventId = this.props.currentEvent.event._id;
		this.props.getHistoricalSales(value, eventId);
	}
	handleSalesChange(value) {
		const eventId = this.props.currentEvent.event._id;
		this.props.getSales(value, eventId);
	}
	handleNavigationToWaitlist() {
		const { currentEvent, navigate } = this.props;
		const eventId = currentEvent.event._id;
		navigate(`/console/my-events/${eventId}/waitlist`);
	}

	render() {
		const { currentEvent, currentUser } = this.props;
		const { loading, error } = this.props.metrics;
		return (
			<>
				<H1>Overview</H1>
				<LoadErrorView loading={loading} error={error} retryAction={this.props.getDashboardMetrics}>
					{!loading ? (
						<Grid columnMin="100%">
							<Warnings event={currentEvent.event} currentUser={currentUser} />
							<TicketsStatusModal />
							<ShareEvent event={currentEvent.event} />
							<WaitlistStatusCard
								event={currentEvent.event}
								handleNavigationToWaitlist={this.handleNavigationToWaitlist}
							/>
							<EventSalesOverview metricState="eventMetrics" style={{ minHeight: 382 }} />
							<ResoldTicketsCard />
							<ReportingGroups
								reportingGroupsExist={!!currentEvent?.event?.reportingGroups?.length}
								eventId={this.props.currentEvent.event._id}
								eventDates={this.props.currentEvent.event.dates}
								location={this.props.currentEvent.event.location}
							/>
							{currentEvent.event.dates.length > 1 ? (
								<TicketSalesByDate style={{ maxHeight: 402, overflowY: "auto" }} metricState="eventMetrics" />
							) : null}
							<TicketSalesTable metricState="eventMetrics" />
							<CharityImpact />
							<Grid>
								<SalesChart
									title="Historical orders chart"
									lines={ticketLines}
									data="sales"
									metricState="eventMetrics"
									style={{ minHeight: 434 }}
									onChange={this.handleHistoricalSalesChange}
								/>
								<SalesChart
									title="Historical sales chart"
									prefix={getCurrencySymbol()}
									data="revenueSales"
									lines={revenueLines}
									metricState="eventMetrics"
									style={{ minHeight: 434 }}
									chartHeight={250}
									onChange={this.handleSalesChange}
								/>
							</Grid>
						</Grid>
					) : null}
				</LoadErrorView>
			</>
		);
	}
}
export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		metrics: state.eventMetrics,
		currentUser: state?.auth?.user
	}),
	{ getEventMetrics, getHistoricalSales, getSales }
)(withRouterHooks(Overview));
