import { css } from "@emotion/css";
import { ContentBlock } from "@/components/AppBlocks";
import H2 from "@/components/text/H2";
import FileDownload from "@/utils/FileDownload";

import fileIcon from "@/assets/ic_file.svg";

const iframeStyling = css({
	width: "100%",
	minHeight: 600,
	border: "1px solid #dedede",
	borderRadius: 4
});

const attachmentsStyling = css({
	marginTop: 12,
	button: {
		height: 120,
		backgroundColor: "transparent",
		backgroundImage: `url(${fileIcon})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "80px 80px",
		backgroundPosition: "50% 20%",
		border: "2px solid #B9B0C4",
		borderRadius: 4,
		display: "flex",
		flexDirection: "column-reverse",
		cursor: "pointer"
	}
});

const Preview = ({ template }) => {
	const attachments = [];
	return (
		<ContentBlock>
			<H2>Preview</H2>
			<iframe srcDoc={template.html} className={iframeStyling} title="Email preview" />
			{attachments.length ? (
				<div className={attachmentsStyling}>
					<H2>Attachments</H2>
					{attachments.map((attachment) => (
						<button
							key={attachment.filename}
							onClick={() =>
								FileDownload.saveAs(`data:application/pdf;base64,${attachment.content}`, attachment.filename)
							}
						>
							<div>{attachment.filename}</div>
						</button>
					))}
				</div>
			) : null}
		</ContentBlock>
	);
};

export default Preview;
