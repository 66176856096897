const getUserMenu = (userId) => {
	return [
		{
			label: "Details",
			path: `/console/admin/users/${userId}`
		},
		{
			label: "Fees",
			path: `/console/admin/users/${userId}/fees`
		},
		{
			label: "Default Settings",
			path: `/console/admin/users/${userId}/default-settings`
		},
		{
			label: "Default Gateways",
			path: `/console/admin/users/${userId}/default-gateways`
		},
		{
			label: "Bank Accounts",
			path: `/console/admin/users/${userId}/bank-accounts`
		},
		{
			label: "User Events",
			path: `/console/admin/users/${userId}/user-events`
		},
		{
			label: "User Hosts",
			path: `/console/admin/users/${userId}/user-hosts`
		},
		{
			label: "Referral Payout",
			path: `/console/admin/users/${userId}/referral-payout`
		},
		{
			label: "Referral Settings",
			path: `/console/admin/users/${userId}/referral-settings`
		},

		{
			label: "Admin permissions",
			path: `/console/admin/users/${userId}/admin-permissions`
		},
		{
			label: "Self-serve payouts",
			path: `/console/admin/users/${userId}/self-serve-payout-settings`
		},
		{
			label: "Permissions",
			path: `/console/admin/users/${userId}/permissions`
		}
	];
};

export default getUserMenu;
