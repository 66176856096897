import {
	ADJUSTMENTS_DETAILS_REPORT_ERROR,
	ADJUSTMENTS_DETAILS_REPORT_REQUEST,
	ADJUSTMENTS_DETAILS_REPORT_RESET,
	ADJUSTMENTS_DETAILS_REPORT_SUCCESS,
	ADJUSTMENTS_REPORT_ERROR,
	ADJUSTMENTS_REPORT_REQUEST,
	ADJUSTMENTS_REPORT_RESET,
	ADJUSTMENTS_REPORT_SUCCESS,
	BOARD_REPORT_ERROR,
	BOARD_REPORT_REQUEST,
	BOARD_REPORT_RESET,
	BOARD_REPORT_SUCCESS,
	CONTROL_REPORT_ERROR,
	CONTROL_REPORT_REQUEST,
	CONTROL_REPORT_RESET,
	CONTROL_REPORT_SUCCESS,
	FEES_CRYSTALISATION_REPORT_ERROR,
	FEES_CRYSTALISATION_REPORT_REQUEST,
	FEES_CRYSTALISATION_REPORT_RESET,
	FEES_CRYSTALISATION_REPORT_SUCCESS,
	FEES_DETAILS_CSV_REPORT_ERROR,
	FEES_DETAILS_CSV_REPORT_REQUEST,
	FEES_DETAILS_CSV_REPORT_SUCCESS,
	FEES_DETAILS_REPORT_ERROR,
	FEES_DETAILS_REPORT_REQUEST,
	FEES_DETAILS_REPORT_RESET,
	FEES_DETAILS_REPORT_SUCCESS,
	FEES_REPORT_ERROR,
	FEES_REPORT_REQUEST,
	FEES_REPORT_RESET,
	FEES_REPORT_SUCCESS,
	GIFT_CARDS_HELD_CSV_REPORT_ERROR,
	GIFT_CARDS_HELD_CSV_REPORT_REQUEST,
	GIFT_CARDS_HELD_CSV_REPORT_SUCCESS,
	GIFT_CARDS_HELD_REPORT_ERROR,
	GIFT_CARDS_HELD_REPORT_REQUEST,
	GIFT_CARDS_HELD_REPORT_RESET,
	GIFT_CARDS_HELD_REPORT_SUCCESS,
	MONEY_HELD_CSV_REPORT_ERROR,
	MONEY_HELD_CSV_REPORT_REQUEST,
	MONEY_HELD_CSV_REPORT_SUCCESS,
	MONEY_HELD_REPORT_ERROR,
	MONEY_HELD_REPORT_REQUEST,
	MONEY_HELD_REPORT_RESET,
	MONEY_HELD_REPORT_SUCCESS,
	PAYOUTS_DETAILS_REPORT_ERROR,
	PAYOUTS_DETAILS_REPORT_REQUEST,
	PAYOUTS_DETAILS_REPORT_RESET,
	PAYOUTS_DETAILS_REPORT_SUCCESS,
	PAYOUTS_REPORT_ERROR,
	PAYOUTS_REPORT_REQUEST,
	PAYOUTS_REPORT_RESET,
	PAYOUTS_REPORT_SUCCESS,
	PAYOUTS_REVERSED_REPORT_ERROR,
	PAYOUTS_REVERSED_REPORT_REQUEST,
	PAYOUTS_REVERSED_REPORT_RESET,
	PAYOUTS_REVERSED_REPORT_SUCCESS,
	REFERRAL_PAYOUT_REPORT_ERROR,
	REFERRAL_PAYOUT_REPORT_REQUEST,
	REFERRAL_PAYOUT_REPORT_SUCCESS,
	REFUNDS_REPORT_ERROR,
	REFUNDS_REPORT_REQUEST,
	REFUNDS_REPORT_RESET,
	REFUNDS_REPORT_SUCCESS,
	SALES_SUMMARY_ERROR,
	SALES_SUMMARY_REQUEST,
	SALES_SUMMARY_RESET,
	SALES_SUMMARY_SUCCESS
} from "../../actions/adminReports/financeActions";

const initialState = {
	boardReport: {
		data: null,
		loading: false,
		error: false
	},
	moneyHeldReport: {
		data: null,
		loading: false,
		error: false,
		page: 1,
		count: null
	},
	moneyHeldCvsReport: {
		loading: false,
		error: false
	},
	giftCardsHeldReport: {
		data: null,
		loading: false,
		error: false,
		page: 1,
		count: null
	},
	giftCardsHeldCvsReport: {
		loading: false,
		error: false
	},
	controlReport: {
		data: null,
		loading: false,
		error: false
	},
	ticketSalesSummary: {
		data: null,
		loading: false,
		error: false
	},
	refundsReport: {
		data: null,
		loading: false,
		error: false
	},
	charityDonationsCsvReport: {
		loading: false,
		error: false
	},
	feesReport: {
		data: null,
		loading: false,
		error: false
	},
	feesDetailsReport: {
		data: null,
		loading: false,
		error: false
	},
	feeCrystalisationReport: {
		data: null,
		loading: false,
		error: false
	},
	feesDetailsCsvReport: {
		loading: false,
		error: false
	},
	adjustmentsReport: {
		data: null,
		loading: false,
		error: false
	},
	adjustmentsDetailsReport: {
		data: null,
		loading: false,
		error: false
	},
	payoutsReport: {
		data: null,
		loading: false,
		error: false
	},
	payoutsDetailsReport: {
		data: null,
		loading: false,
		error: false
	},
	payoutsReversedReport: {
		data: null,
		loading: false,
		error: false
	},
	referralPayoutReport: {
		data: null,
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case MONEY_HELD_REPORT_RESET: {
			return {
				...state,
				moneyHeldReport: {
					data: null,
					loading: false,
					error: false,
					page: 1,
					count: null
				}
			};
		}
		case MONEY_HELD_REPORT_REQUEST:
			return {
				...state,
				moneyHeldReport: {
					data: null,
					loading: true,
					error: false,
					page: action.page
				}
			};
		case MONEY_HELD_REPORT_SUCCESS:
			return {
				...state,
				moneyHeldReport: {
					...state.moneyHeldReport,
					data: action.data.results,
					count: action.data.count,
					loading: false,
					error: false
				}
			};
		case MONEY_HELD_REPORT_ERROR:
			return {
				...state,
				moneyHeldReport: {
					...state.moneyHeldReport,
					data: null,
					loading: false,
					error: action.error
				}
			};

		case MONEY_HELD_CSV_REPORT_REQUEST: {
			return {
				...state,
				moneyHeldCvsReport: {
					loading: true,
					error: false
				}
			};
		}

		case MONEY_HELD_CSV_REPORT_SUCCESS:
			return {
				...state,
				moneyHeldCvsReport: {
					loading: false,
					error: false
				}
			};
		case MONEY_HELD_CSV_REPORT_ERROR:
			return {
				...state,
				moneyHeldCvsReport: {
					loading: false,
					error: action.error
				}
			};

		case GIFT_CARDS_HELD_REPORT_RESET: {
			return {
				...state,
				giftCardsHeldReport: {
					data: null,
					loading: false,
					error: false,
					page: 1,
					count: null
				}
			};
		}
		case GIFT_CARDS_HELD_REPORT_REQUEST:
			return {
				...state,
				giftCardsHeldReport: {
					data: null,
					loading: true,
					error: false,
					page: action.page
				}
			};
		case GIFT_CARDS_HELD_REPORT_SUCCESS:
			return {
				...state,
				giftCardsHeldReport: {
					...state.giftCardsHeldReport,
					data: action.data.results,
					count: action.data.count,
					loading: false,
					error: false
				}
			};
		case GIFT_CARDS_HELD_REPORT_ERROR:
			return {
				...state,
				giftCardsHeldReport: {
					...state.giftCardsHeldReport,
					data: null,
					loading: false,
					error: action.error
				}
			};

		case GIFT_CARDS_HELD_CSV_REPORT_REQUEST: {
			return {
				...state,
				giftCardsHeldCvsReport: {
					loading: true,
					error: false
				}
			};
		}

		case GIFT_CARDS_HELD_CSV_REPORT_SUCCESS:
			return {
				...state,
				giftCardsHeldCvsReport: {
					loading: false,
					error: false
				}
			};
		case GIFT_CARDS_HELD_CSV_REPORT_ERROR:
			return {
				...state,
				giftCardsHeldCvsReport: {
					loading: false,
					error: action.error
				}
			};

		case BOARD_REPORT_RESET: {
			return {
				...state,
				boardReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case BOARD_REPORT_REQUEST:
			return {
				...state,
				boardReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case BOARD_REPORT_SUCCESS:
			return {
				...state,
				boardReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case BOARD_REPORT_ERROR:
			return {
				...state,
				boardReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		case CONTROL_REPORT_RESET: {
			return {
				...state,
				controlReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case CONTROL_REPORT_REQUEST:
			return {
				...state,
				controlReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case CONTROL_REPORT_SUCCESS:
			return {
				...state,
				controlReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case CONTROL_REPORT_ERROR:
			return {
				...state,
				controlReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		case SALES_SUMMARY_RESET: {
			return {
				...state,
				ticketSalesSummary: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case SALES_SUMMARY_REQUEST:
			return {
				...state,
				ticketSalesSummary: {
					data: null,
					loading: true,
					error: false
				}
			};
		case SALES_SUMMARY_SUCCESS:
			return {
				...state,
				ticketSalesSummary: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case SALES_SUMMARY_ERROR:
			return {
				...state,
				ticketSalesSummary: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		case REFUNDS_REPORT_RESET: {
			return {
				...state,
				refundsReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case REFUNDS_REPORT_REQUEST:
			return {
				...state,
				refundsReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case REFUNDS_REPORT_SUCCESS:
			return {
				...state,
				refundsReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case REFUNDS_REPORT_ERROR:
			return {
				...state,
				refundsReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};

		case FEES_CRYSTALISATION_REPORT_RESET: {
			return {
				...state,
				feeCrystalisationReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case FEES_CRYSTALISATION_REPORT_REQUEST:
			return {
				...state,
				feeCrystalisationReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case FEES_CRYSTALISATION_REPORT_SUCCESS:
			return {
				...state,
				feeCrystalisationReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case FEES_CRYSTALISATION_REPORT_ERROR:
			return {
				...state,
				feeCrystalisationReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};

		case FEES_REPORT_RESET: {
			return {
				...state,
				feesReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case FEES_REPORT_REQUEST:
			return {
				...state,
				feesReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case FEES_REPORT_SUCCESS:
			return {
				...state,
				feesReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case FEES_REPORT_ERROR:
			return {
				...state,
				feesReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		case FEES_DETAILS_REPORT_RESET: {
			return {
				...state,
				feesDetailsReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case FEES_DETAILS_REPORT_REQUEST:
			return {
				...state,
				feesDetailsReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case FEES_DETAILS_REPORT_SUCCESS:
			return {
				...state,
				feesDetailsReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case FEES_DETAILS_REPORT_ERROR:
			return {
				...state,
				feesDetailsReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};

		case FEES_DETAILS_CSV_REPORT_REQUEST:
			return {
				...state,
				feesDetailsCsvReport: {
					loading: true,
					error: false
				}
			};
		case FEES_DETAILS_CSV_REPORT_SUCCESS:
			return {
				...state,
				feesDetailsCsvReport: {
					loading: false,
					error: false
				}
			};
		case FEES_DETAILS_CSV_REPORT_ERROR:
			return {
				...state,
				feesDetailsCsvReport: {
					loading: false,
					error: action.error
				}
			};
		case ADJUSTMENTS_REPORT_RESET: {
			return {
				...state,
				adjustmentsReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case ADJUSTMENTS_REPORT_REQUEST:
			return {
				...state,
				adjustmentsReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case ADJUSTMENTS_REPORT_SUCCESS:
			return {
				...state,
				adjustmentsReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case ADJUSTMENTS_REPORT_ERROR:
			return {
				...state,
				adjustmentsReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		case ADJUSTMENTS_DETAILS_REPORT_RESET: {
			return {
				...state,
				adjustmentsDetailsReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case ADJUSTMENTS_DETAILS_REPORT_REQUEST:
			return {
				...state,
				adjustmentsDetailsReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case ADJUSTMENTS_DETAILS_REPORT_SUCCESS:
			return {
				...state,
				adjustmentsDetailsReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case ADJUSTMENTS_DETAILS_REPORT_ERROR:
			return {
				...state,
				adjustmentsDetailsReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};

		case PAYOUTS_REPORT_RESET: {
			return {
				...state,
				payoutsReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case PAYOUTS_REPORT_REQUEST:
			return {
				...state,
				payoutsReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case PAYOUTS_REPORT_SUCCESS:
			return {
				...state,
				payoutsReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case PAYOUTS_REPORT_ERROR:
			return {
				...state,
				payoutsReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		case PAYOUTS_DETAILS_REPORT_RESET: {
			return {
				...state,
				payoutsDetailsReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case PAYOUTS_DETAILS_REPORT_REQUEST:
			return {
				...state,
				payoutsDetailsReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case PAYOUTS_DETAILS_REPORT_SUCCESS:
			return {
				...state,
				payoutsDetailsReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case PAYOUTS_DETAILS_REPORT_ERROR:
			return {
				...state,
				payoutsDetailsReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};

		case PAYOUTS_REVERSED_REPORT_RESET: {
			return {
				...state,
				payoutsReversedReport: {
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case PAYOUTS_REVERSED_REPORT_REQUEST:
			return {
				...state,
				payoutsReversedReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case PAYOUTS_REVERSED_REPORT_SUCCESS:
			return {
				...state,
				payoutsReversedReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case PAYOUTS_REVERSED_REPORT_ERROR:
			return {
				...state,
				payoutsReversedReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};

		case REFERRAL_PAYOUT_REPORT_REQUEST:
			return {
				...state,
				referralPayoutReport: {
					data: null,
					loading: true,
					error: false
				}
			};
		case REFERRAL_PAYOUT_REPORT_SUCCESS:
			return {
				...state,
				referralPayoutReport: {
					data: action.data,
					loading: false,
					error: false
				}
			};
		case REFERRAL_PAYOUT_REPORT_ERROR:
			return {
				...state,
				referralPayoutReport: {
					data: null,
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
