import { ReactNode } from "react";

interface ILinkButtonProps {
	onClick: (e: any) => void;
	children: ReactNode;
	ariaLabel: string;
	style?: any;
	wide?: boolean;
}

const LinkButton = ({ onClick, children, ariaLabel, style, wide }: ILinkButtonProps) => {
	return (
		<button
			onClick={onClick}
			type="button"
			style={{
				...style,
				cursor: "pointer",
				border: "none",
				background: "none",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				width: wide ? "100%" : ""
			}}
			aria-label={ariaLabel}
		>
			{children}
		</button>
	);
};
export default LinkButton;
