import { css } from "@emotion/css";
import { Field, FieldArray } from "redux-form";
import { LegacyInputField, SelectField, SwitchField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { Label } from "@/components/text/Text";
import { required } from "@/utils/Validators";

/**
 * Builds field array of condition columns.
 *
 * @param {object, string, string} { additionalQuestions, questionIndex, conditionIndex }
 * @returns field array of condition columns.
 */
const Conditions = ({ additionalQuestions, questionIndex, conditionIndex }) => {
	additionalQuestions[questionIndex].conditions = additionalQuestions[questionIndex].conditions || [];
	if (additionalQuestions[questionIndex].conditions.length === 0) {
		additionalQuestions[questionIndex].conditions.push({
			additionalQuestionId: "",
			value: "",
			condition: "isEqualTo"
		});
	} else {
		additionalQuestions[questionIndex] &&
			additionalQuestions[questionIndex].conditions &&
			additionalQuestions[questionIndex].conditions.length &&
			additionalQuestions[questionIndex].conditions.forEach((condition) => {
				const isExist = condition.conditionOptions
					? condition.conditionOptions.find((option) => option.value === condition.condition)
					: false;
				const conditionOptionsSet = condition.conditionOptions && condition.conditionOptions.length;
				if (!isExist && conditionOptionsSet) {
					condition.condition = condition.conditionOptions[0].value ? condition.conditionOptions[0].value : "";
				}
			});
	}
	let questionOptions = additionalQuestions
		.filter((q, index) => {
			return index !== parseInt(questionIndex) && q.disabled !== true && q.deleted !== true;
		})
		.map((question) => {
			return { value: question._id, label: question.question };
		});

	return (
		<FieldArray
			name={`additionalQuestions[${questionIndex}].conditions`}
			component={Columns}
			conditionIndex={conditionIndex}
			questionOptions={questionOptions}
			questionIndex={questionIndex}
			additionalQuestions={additionalQuestions}
		/>
	);
};
/**
 * Builds columns of condition options
 *
 * @param {object, array, string, object} { fields, questionOptions, questionIndex, additionalQuestions }
 * @returns columns of condition options
 */
const Columns = ({ fields, questionOptions, questionIndex, additionalQuestions }) => {
	let conditionValueField = LegacyInputField;
	const addNewCondition = () => {
		fields.push({
			additionalQuestionId: "",
			condition: "",
			value: "",
			conditionOptions: []
		});
	};
	const removeCondition = (index) => {
		fields.remove(index);
	};
	const findCondition = (value, questionId) => {
		const question = additionalQuestions.find((question) => {
			return question._id === questionId;
		});
		return createConditionOptions(question);
	};
	const createConditionOptions = (question) => {
		let conditionOptions = [],
			conditionValueOptions,
			mode = null;
		let questionType = question && question.type ? question.type : "";
		questionType = question && question.inputType && question.inputType === "number" ? "number" : questionType;
		switch (questionType) {
			case "checkbox":
				conditionOptions.push({ value: "checkboxEqual", label: "is" });
				conditionOptions.push({ value: "checkboxNotEqual", label: "is not" });
				conditionValueField = SelectField;
				conditionValueOptions = [
					{ value: "true", label: "True" },
					{ value: "false", label: "False" }
				];
				break;
			case "options":
				conditionOptions.push({ value: "isEqualTo", label: "is equal to" });
				conditionOptions.push({
					value: "isNotEqualTo",
					label: "it not equal to"
				});
				conditionValueField = SelectField;
				conditionValueOptions = question.options.map((option) => {
					return { label: option.value, value: option.value };
				});
				mode = "single";
				break;
			case "multiSelect":
				conditionOptions.push({ value: "contains", label: "contains" });
				conditionOptions.push({
					value: "notContain",
					label: "doesn't contain"
				});
				conditionValueField = SelectField;
				conditionValueOptions = question.options.map((option) => {
					return { label: option.value, value: option.value };
				});
				mode = "multiple";
				break;
			case "number":
				conditionOptions.push({ value: "===", label: "is equal to" });
				conditionOptions.push({ value: "!==", label: "is not equal to" });
				conditionOptions.push({ value: "<", label: "is lower than" });
				conditionOptions.push({
					value: "<=",
					label: "is lower than or equal to"
				});
				conditionOptions.push({ value: ">", label: "is greater than" });
				conditionOptions.push({
					value: ">=",
					label: "is greater than or equal to"
				});
				conditionValueField = LegacyInputField;
				break;
			default:
				conditionOptions.push({ value: "isEqualTo", label: "is equal to" });
				conditionOptions.push({
					value: "isNotEqualTo",
					label: "is not equal to"
				});
				conditionOptions.push({ value: "beginsWith", label: "begins with" });
				conditionOptions.push({ value: "endsWith", label: "ends with" });
				conditionOptions.push({ value: "contains", label: "contains" });
				conditionOptions.push({
					value: "notContain",
					label: "doesn't contain"
				});
				conditionValueField = LegacyInputField;
		}

		return {
			conditionOptions,
			conditionValueField,
			conditionValueOptions,
			mode
		};
	};
	const newFields = fields.map((f, index) => {
		const field = fields.get(index);
		const conditionAndValue =
			field.additionalQuestionId && field.additionalQuestionId !== ""
				? findCondition("", field.additionalQuestionId)
				: createConditionOptions({});
		field.conditionOptions = conditionAndValue.conditionOptions;
		field.conditionValueField = conditionAndValue.conditionValueField;
		field.conditionValueOptions = conditionAndValue.conditionValueOptions;
		field.mode = conditionAndValue.mode;
		return field;
	});
	const optionsInCondition = [
		{ value: "&&", label: "And" },
		{ value: "||", label: "Or" }
	];
	const questionRequired = required("A question name is required");
	const equationRequired = required("A equation is required");
	const valueRequired = required("A value is required");
	return (
		<div>
			<div
				className={css({
					maxWidth: 585,
					background: "#fff",
					borderRadius: 4,
					padding: "16px 12px",
					marginBottom: 10,
					"@media(max-width: 600px)": {
						display: "none"
					}
				})}
			>
				{fields.map((condition, index) => {
					return (
						<div key={index} style={{ marginTop: 10 }}>
							<div style={{ width: "100%", display: "flex" }}>
								<div style={{ width: 50, paddingTop: 30, marginRight: 12 }}>Show if</div>
								<div style={{ marginRight: 12, width: "100%", maxWidth: 230 }}>
									<Field
										name={`additionalQuestions[${questionIndex}].conditions[${index}].additionalQuestionId`}
										label="Question"
										component={SelectField}
										onChange={findCondition}
										options={questionOptions}
										style={{
											marginBottom: 0,
											minWidth: "100%",
											width: "100%",
											maxWidth: 230
										}}
										validate={questionRequired}
										placeholder="Select question"
									/>
								</div>
								<div style={{ marginRight: 12, width: "100%", maxWidth: 230 }}>
									<Field
										name={`additionalQuestions[${questionIndex}].conditions[${index}].condition`}
										label="Equation"
										component={SelectField}
										options={newFields[index].conditionOptions}
										style={{
											marginBottom: 0,
											minWidth: "100%",
											width: "100%",
											maxWidth: 230
										}}
										validate={equationRequired}
									/>
								</div>
							</div>
							<div style={{ width: "100%", display: "flex" }}>
								<div
									className={css({
										width: 50,
										marginRight: 12,
										"@media(max-width: 600px)": {
											width: 0
										}
									})}
								/>
								<div style={{ marginRight: 12, width: "100%", maxWidth: 230 }}>
									<Field
										name={`additionalQuestions[${questionIndex}].conditions[${index}].value`}
										label="Value"
										component={newFields[index].conditionValueField}
										options={newFields[index].conditionValueOptions}
										mode={newFields[index].mode}
										style={{ minWidth: "100%", width: "100%", maxWidth: 230 }}
										validate={valueRequired}
									/>
								</div>
								<div style={{ position: "relative" }}>
									{fields.length > 1 && fields.length !== index + 1 && (
										<div style={{ display: "inline-block", width: 80 }}>
											<Field
												name={`additionalQuestions[${questionIndex}].conditions[${index}].options`}
												label="Options"
												component={SelectField}
												options={optionsInCondition}
												style={{ width: "100%", minWidth: 80 }}
											/>
										</div>
									)}
									<IconButton
										icon="delete"
										onClick={() => removeCondition(index)}
										tooltip="Delete"
										showTooltip
										style={{
											display: fields.length > 1 ? "block" : "none",
											position: "absolute",
											top: 23,
											left: fields.length > 1 && fields.length !== index + 1 ? 90 : 5
										}}
										ariaLabel="Delete"
									/>
									<LegacyButton
										style={{
											position: "absolute",
											borderColor: "#6F7782",
											color: "rgb(111, 119, 130)",
											top: 25,
											left: fields.length > 1 && fields.length !== index + 1 ? 122 : 37
										}}
										type="action"
										icon={{ name: "plus", left: true }}
										ariaLabel="Add New Condition"
										onClick={() => addNewCondition()}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>

			<div
				className={css({
					display: "none",
					"@media(max-width:600px)": {
						display: "block"
					}
				})}
			>
				{fields.map((condition, index) => {
					return (
						<div key={index}>
							<div
								className={css`
									&:after {
										clear: both;
										display: block;
										content: "";
									}
								`}
							>
								<b
									style={{
										float: "left",
										maxWidth: "15vw",
										marginRight: index === 0 ? "4vw" : 0,
										paddingTop: 8
									}}
								>
									{index === 0 ? <Label>Show If</Label> : ""}
								</b>
								<div style={{ float: "left", maxWidth: "80vw" }}>
									<Field
										name={`additionalQuestions[${questionIndex}].conditions[${index}].additionalQuestionId`}
										component={SelectField}
										onChange={findCondition}
										options={questionOptions}
										style={{ marginBottom: 0 }}
									/>
								</div>
								<Field
									name={`additionalQuestions[${questionIndex}].conditions[${index}].condition`}
									component={SelectField}
									options={newFields[index].conditionOptions}
									style={{ marginBottom: 0 }}
								/>
								<Field
									name={`additionalQuestions[${questionIndex}].conditions[${index}].value`}
									component={newFields[index].conditionValueField}
									options={newFields[index].conditionValueOptions}
									placeholder="Value"
									mode={newFields[index].mode}
								/>

								{fields.length > 1 && fields.length !== index + 1 && (
									<div style={{ float: "left", width: 80 }}>
										<Field
											name={`additionalQuestions[${questionIndex}].conditions[${index}].options`}
											label="Options"
											component={SelectField}
											options={optionsInCondition}
											style={{ width: "100%", minWidth: 30, maxWidth: 50 }}
										/>
									</div>
								)}
								<div
									style={{
										float: "left",
										marginTop: fields.length > 1 && fields.length !== index + 1 ? 22 : 0
									}}
								>
									<IconButton
										icon="delete"
										onClick={() => removeCondition(index)}
										tooltip="Delete"
										showTooltip
										style={{
											display: fields.length > 1 ? "block" : "none",
											float: "left"
										}}
										ariaLabel="Delete"
									/>
									<LegacyButton
										style={{
											float: "left"
										}}
										icon={{ name: "plus", left: true }}
										onClick={() => addNewCondition()}
										ariaLabel="Add new"
									/>
								</div>
								<div />
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

/**
 * Main component.
 * Used in additional question.
 *
 * @returns options for question condition
 */
const ConditionalQuestions = ({ additionalQuestions, questionIndex, fields, removeConditions }) => {
	const styles = {
		container: {
			marginTop: 12,
			label: {
				marginBottom: 0
			},
			button: {
				paddingTop: 0
			}
		},
		description: {
			marginBottom: 0
		}
	};
	const onChange = (e, value) => {
		let orgValue = fields.get(questionIndex);
		delete orgValue.conditions;
		if (!value) removeConditions(`additionalQuestions[${questionIndex}]`, orgValue);
	};
	const tooltipMessage = `Create questions that will only appear if specific conditions from the previous questions are met.`;
	return (
		<div className={css(styles.container)}>
			<Label>Show If</Label>
			<div style={styles.description}>Show this question only when specific answer is selected</div>
			<Field
				name={`additionalQuestions[${questionIndex}].showConditions`}
				type="text"
				component={SwitchField}
				onChange={onChange}
				tooltip={tooltipMessage}
				showTooltip
			/>
			{additionalQuestions[questionIndex].showConditions && (
				<Conditions additionalQuestions={additionalQuestions} questionIndex={questionIndex} />
			)}
		</div>
	);
};

export default ConditionalQuestions;
