import { Button, ButtonProps } from "@/ui";
import { Clipboard } from "@phosphor-icons/react";
import copy from "copy-text-to-clipboard";
import { forwardRef, useState } from "react";

type CopyButtonProps = Partial<ButtonProps> & {
	noIcon?: boolean;
	toCopy: string;
};

const fallbackButtonText = "Copy to clipboard";
const fallbackArialLabel = fallbackButtonText;
const copiedMessage = "Copied!";

export const CopyButton = forwardRef<HTMLButtonElement, CopyButtonProps>(
	({ children, noIcon, toCopy, variant, ...props }, ref) => {
		const [showCopyMessage, setShowCopyMessage] = useState(false);

		const handleClick = () => {
			copy(toCopy);
			setShowCopyMessage(true);
			setTimeout(() => setShowCopyMessage(false), 2000);
		};

		return (
			<Button
				aria-label={props["aria-label"] ?? fallbackArialLabel}
				iconLeft={!noIcon && !showCopyMessage && <Clipboard size={18} />}
				onClick={handleClick}
				ref={ref}
				variant={variant ?? "tertiary"}
				{...props}
			>
				{showCopyMessage ? copiedMessage : children ?? fallbackButtonText}
			</Button>
		);
	}
);
