import * as payoutService from "@/services/newPayoutService";
import { AppDispatch, ReduxState } from "@/state/store";
import FileDownload from "@/utils/FileDownload";
import { notification } from "@/ui/antd";

export const GET_EVENT_PAYOUT_PAGE_REQUEST = "GET_EVENT_PAYOUT_PAGE_REQUEST";
export const GET_EVENT_PAYOUT_PAGE_SUCCESS = "GET_EVENT_PAYOUT_PAGE_SUCCESS";

export const TOGGLE_AUTO_PAYOUT_FOR_EVENT_REQUEST = "TOGGLE_AUTO_PAYOUT_FOR_EVENT_REQUEST";
export const TOGGLE_AUTO_PAYOUT_FOR_EVENT_SUCCESS = "TOGGLE_AUTO_PAYOUT_FOR_EVENT_SUCCESS";

export const TOGGLE_FLAGGED_FOR_EVENT_REQUEST = "TOGGLE_FLAGGED_FOR_EVENT_REQUEST";
export const TOGGLE_FLAGGED_FOR_EVENT_SUCCESS = "TOGGLE_FLAGGED_FOR_EVENT_SUCCESS";

export const ADD_NOTE_REQUEST = "ADD_NOTE_REQUEST";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";

export const DELETE_NOTE_REQUEST = "DELETE_NOTE_REQUEST";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";

export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";

export const ADD_PAYMENTS_REQUEST = "ADD_PAYMENTS_REQUEST";
export const ADD_PAYMENTS_SUCCESS = "ADD_PAYMENTS_SUCCESS";

export const ADD_CREDIT_REQUEST = "ADD_CREDIT_REQUEST";
export const ADD_CREDIT_SUCCESS = "ADD_CREDIT_SUCCESS";

export const ADD_ADJUSTMENT_REQUEST = "ADD_ADJUSTMENT_REQUEST";
export const ADD_ADJUSTMENT_SUCCESS = "ADD_ADJUSTMENT_SUCCESS";

export const EDIT_PAYMENT_REQUEST = "EDIT_PAYMENT_REQUEST";
export const EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS";

export const DELETE_PAYMENT_REQUEST = "DELETE_PAYMENT_REQUEST";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";

export const TOGGLE_HIDE_NIL_PAYOUTS = "TOGGLE_HIDE_NIL_PAYOUTS";

export const PAYOUT_ERROR = "PAYOUT_ERROR";

export const GET_PAYMENT_RECEIPT_SUCCESS = "GET_PAYMENT_RECEIPT_SUCCESS";
export const GET_PAYMENT_RECEIPT_REQUEST = "GET_PAYMENT_RECEIPT_REQUEST";

export const TOGGLE_FRAUD_CHECK_REQUEST = "TOGGLE_FRAUD_CHECK_REQUEST";
export const TOGGLE_FRAUD_CHECK_SUCCESS = "TOGGLE_FRAUD_CHECK_SUCCESS";

export function getEventPayoutPage(eventId: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: GET_EVENT_PAYOUT_PAGE_REQUEST });
			const result = await payoutService.loadEventPayoutPage(eventId);
			dispatch({ type: GET_EVENT_PAYOUT_PAGE_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function toggleAutoPayoutForEvent(eventId: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: TOGGLE_AUTO_PAYOUT_FOR_EVENT_REQUEST });
			const result = await payoutService.toggleAutoPayoutForEvent(eventId);
			dispatch({ type: TOGGLE_AUTO_PAYOUT_FOR_EVENT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function toggleFlaggedForEvent(eventId: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: TOGGLE_FLAGGED_FOR_EVENT_REQUEST });
			const result = await payoutService.toggleFlaggedForEvent(eventId);
			dispatch({ type: TOGGLE_FLAGGED_FOR_EVENT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function toggleHideNilPayouts() {
	return (dispatch: AppDispatch, getState: () => ReduxState) => {
		const hideNilPayouts = getState().payouts.filters.hideNilPayouts;
		dispatch({ type: TOGGLE_HIDE_NIL_PAYOUTS, result: !hideNilPayouts });
	};
}

export function addNote(eventId: string, note: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: ADD_NOTE_REQUEST });
			const result = await payoutService.addNote(eventId, note);
			dispatch({ type: ADD_NOTE_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function deleteNote(eventId: string, noteId: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: DELETE_NOTE_REQUEST });
			const result = await payoutService.deleteNote(eventId, noteId);
			dispatch({ type: DELETE_NOTE_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function updateStatus(eventId: string, eventDateId: string, status: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: UPDATE_STATUS_REQUEST });
			const result = await payoutService.updateStatus(eventId, eventDateId, status);
			dispatch({ type: UPDATE_STATUS_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export interface PaymentsData {
	payments: Record<
		string,
		{
			enabled: boolean;
			amount: number;
		}
	>;
	payNow: boolean;
	transactionId?: string;
	notes?: string;
	shouldSendEmail: boolean;
}

export function addPayments(eventId: string, paymentsData: PaymentsData) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: ADD_PAYMENTS_REQUEST });
			const result = await payoutService.addPayments(eventId, paymentsData);
			if (result.errorMsg) {
				dispatch({ type: PAYOUT_ERROR, error: { message: result.errorMsg } });
			}
			dispatch({ type: ADD_PAYMENTS_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export interface SelfServicePayoutsData {
	[key: string]: number | undefined;
}

export function addSelfServePayments(eventId: string, paymentsData: SelfServicePayoutsData) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: ADD_PAYMENTS_REQUEST });
			const result = await payoutService.addSelfServePayments(eventId, paymentsData);
			notification.success({
				message: "Success",
				description: "Payment has been requested."
			});
			dispatch({ type: ADD_PAYMENTS_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export interface CreditData {
	eventDateId?: string;
	amount: number;
	transactionId?: string;
	notes?: string;
}

export function addCredit(eventId: string, creditData: CreditData) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: ADD_CREDIT_REQUEST });
			const result = await payoutService.addCredit(eventId, creditData);
			dispatch({ type: ADD_CREDIT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export interface AdjustmentData {
	eventDateId?: string;
	amount: number;
	notes?: string;
}
export function addAdjustment(eventId: string, adjustmentData: AdjustmentData) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: ADD_ADJUSTMENT_REQUEST });
			const result = await payoutService.addAdjustment(eventId, adjustmentData);
			dispatch({ type: ADD_ADJUSTMENT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

interface EditPaymentData {
	amount: number;
	transactionId?: string;
	payNow: boolean;
	shouldSendEmail: boolean;
	notes?: string;
	eventDateId?: string;
}

//payment is ambiguous here, however credits, adjustments and payments are all under
//the payments schema, beware if you edit lmao
export function editPayment(
	eventId: string,
	paymentId: string,
	paymentData: AdjustmentData | CreditData | EditPaymentData
) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: EDIT_PAYMENT_REQUEST });
			const result = await payoutService.editPayment(eventId, paymentId, paymentData);
			dispatch({ type: EDIT_PAYMENT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export interface ReverseData {
	reversed: boolean;
	reversedAt?: Date;
}

export function deletePayment(eventId: string, paymentId: string, reverseData: ReverseData) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: DELETE_PAYMENT_REQUEST });
			const result = await payoutService.deletePayment(eventId, paymentId, reverseData);
			dispatch({ type: DELETE_PAYMENT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function downloadPaymentReceipt(
	eventId: string,
	{ _id, eventDateId, payoutReference }: { _id?: string; eventDateId?: string; payoutReference?: string }
) {
	return async (dispatch: AppDispatch) => {
		try {
			if (!_id || !eventDateId || !payoutReference) throw new Error("Missing payment data");
			dispatch({ type: GET_PAYMENT_RECEIPT_REQUEST });
			const pdfFile = await payoutService.downloadPaymentReceipt(eventId, eventDateId, _id);
			dispatch({ type: GET_PAYMENT_RECEIPT_SUCCESS });
			FileDownload.saveAs(pdfFile, `receipt_${payoutReference}.pdf`);
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function toggleFraudCheck(eventId: string, key: string, setTo: boolean) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: TOGGLE_FRAUD_CHECK_REQUEST });
			const result = await payoutService.toggleFraudCheck(eventId, key, setTo);
			dispatch({ type: TOGGLE_FRAUD_CHECK_SUCCESS, result });
			notification.success({
				message: "Fraud check toggled",
				description: "Fraud check has been toggled"
			});
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
			notification.error({
				message: "Fraud check toggle failed",
				description: "Fraud check has not been toggled"
			});
		}
	};
}

export function calculateTrackRecord(eventId: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: GET_EVENT_PAYOUT_PAGE_REQUEST });
			const result = await payoutService.calculateTrackRecord(eventId);
			dispatch({ type: GET_EVENT_PAYOUT_PAGE_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}

export function calculateFraudCheck(eventId: string) {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch({ type: GET_EVENT_PAYOUT_PAGE_REQUEST });
			const result = await payoutService.calculateFraudCheck(eventId);
			dispatch({ type: GET_EVENT_PAYOUT_PAGE_SUCCESS, result });
		} catch (err) {
			dispatch({ type: PAYOUT_ERROR, error: err });
		}
	};
}
