const personalisations = {
	AddToCalendar: {
		item: "@AddToCalendar",
		replacement: "@AddToCalendar",
		info: "Link to add to calendar page."
	},
	EventName: {
		item: "@EventName",
		replacement: "@EventName",
		info: "Displays the name of the event."
	},
	OrganiserName: {
		item: "@OrganiserName",
		replacement: "@OrganiserName",
		info: "Displays the name of organizer."
	},
	OrganiserLogo: {
		item: "@OrganiserLogo",
		replacement: "@OrganiserLogo",
		info: "Displays the logo of organizer."
	},
	FirstName: {
		item: "@FirstName",
		replacement: "@FirstName",
		info: "Displays the users first name."
	},
	LastName: {
		item: "@LastName",
		replacement: "@LastName",
		info: "Displays the users last name."
	},
	Email: {
		item: "@Email",
		replacement: "@Email",
		info: "Displays the users last email."
	},
	OrderNumber: {
		item: "@OrderNumber",
		replacement: "@OrderNumber",
		info: "Displays the order number."
	},
	EditOrderLink: {
		item: "@EditOrderLink",
		replacement: "@EditOrderLink",
		info: "Adds a link which allows users to edit their order."
	},
	EventDate: {
		item: "@EventDate",
		replacement: "@EventDate",
		info: "The date of the event."
	},
	EventShortDate: {
		item: "@EventShortDate",
		replacement: "@EventShortDate",
		info: "The short date of the event."
	},
	EventLocation: {
		item: "@EventLocation",
		replacement: "@EventLocation",
		info: "The location of the event."
	},
	ViewTickets: {
		item: "@ViewTickets",
		replacement: "@ViewTickets",
		info: "Adds a link which allows users to view their tickets."
	},
	ViewSeats: {
		item: "@ViewSeats",
		replacement: "@ViewSeats",
		info: "Link to seating location for events with ticket locations."
	},
	ViewVirtualEventHub: {
		item: "@ViewVirtualEventHub",
		replacement: "@ViewVirtualEventHub",
		info: "Link to virtual event hub if applicable."
	}
};

const getPersonalisations = (personalisationKeys, excludeKeys = []) => {
	return personalisationKeys
		.filter((p) => !excludeKeys.includes(p))
		.reduce((obj, key) => {
			obj[key] = personalisations[key];
			return obj;
		}, {});
};

export const getEmailPersonalisations = () => {
	return getPersonalisations([
		"AddToCalendar",
		"EventName",
		"OrganiserName",
		"OrganiserLogo",
		"FirstName",
		"LastName",
		"OrderNumber",
		"EditOrderLink",
		"EventDate",
		"EventShortDate",
		"EventLocation",
		"ViewTickets",
		"ViewSeats",
		"ViewVirtualEventHub"
	]);
};

export const getMessagePersonalisations = () => {
	return getPersonalisations(["EventName", "FirstName", "LastName", "Email"]);
};

export const getTicketEmailPersonalisations = () => {
	return getPersonalisations([
		"AddToCalendar",
		"EventName",
		"OrganiserName",
		"OrganiserLogo",
		"FirstName",
		"LastName",
		"OrderNumber",
		"EventDate",
		"EventShortDate",
		"EventLocation",
		"ViewVirtualEventHub",
		"ViewSeats",
		"ViewTickets"
	]);
};

export const getNoOrderPersonalisations = () => {
	return getPersonalisations(["EventName", "OrganiserName", "EventLocation"]);
};

export const getOrderPersonalisations = (excludeKeys = []) => {
	return getPersonalisations(
		[
			"AddToCalendar",
			"EventName",
			"OrganiserName",
			"OrganiserLogo",
			"FirstName",
			"LastName",
			"OrderNumber",
			"EditOrderLink",
			"EventDate",
			"EventShortDate",
			"EventLocation",
			"ViewTickets",
			"ViewSeats",
			"ViewVirtualEventHub"
		],
		excludeKeys
	);
};

export const getTextMessagePersonalisations = (excludeKeys = []) => {
	return getPersonalisations(
		[
			"EventName",
			"OrganiserName",
			"FirstName",
			"LastName",
			"OrderNumber",
			"EventDate",
			"EventShortDate",
			"EventLocation"
		],
		excludeKeys
	);
};
