import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { P } from "@/components/text/Text";
import { cancelDeleteEvent, deleteEvent } from "@/state/actions/eventActions";
import { required } from "@/utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class DeleteModal extends Component {
	constructor(props) {
		super(props);
		this.nameMatch = this.nameMatch.bind(this);
		this.deleteEvent = this.deleteEvent.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	nameRequired = required("Please enter the keyword DELETE");
	nameMatch = (value) => {
		const enteredName = value.trim();
		return enteredName.toLowerCase() === "delete" ? undefined : `Please type "DELETE"`;
	};

	deleteEvent() {
		const { deleteEvent, currentEvent, navigate } = this.props;
		deleteEvent(currentEvent.event._id, navigate);
	}

	cancel() {
		const { cancelDeleteEvent, handleCancel } = this.props;
		cancelDeleteEvent();
		handleCancel();
	}

	render() {
		const { visible, handleSubmit, deletingEvent, currentEvent } = this.props;
		const hasFacebookEvents = currentEvent.event.facebookEvents && currentEvent.event.facebookEvents.length;
		if (hasFacebookEvents) {
			return (
				<Modal open={visible} title="Delete Event" onCancel={this.cancel} destroyOnClose>
					<P>
						Please <Link to={`/console/my-events/${currentEvent.event._id}/facebook-event`}>delete</Link> your facebook
						events first
					</P>
				</Modal>
			);
		}

		return (
			<Modal
				open={visible}
				title="Delete Event"
				onCancel={this.cancel}
				destroyOnClose
				footer={
					<ButtonsBar footer>
						<LegacyButton key="cancel" onClick={this.cancel} ariaLabel="Cancel">
							Cancel
						</LegacyButton>
						<LegacyButton
							key="submit"
							type="warning"
							loading={deletingEvent.loading}
							onClick={handleSubmit(this.deleteEvent)}
							ariaLabel="Delete"
						>
							Delete
						</LegacyButton>
					</ButtonsBar>
				}
			>
				<LoadErrorView
					loading={deletingEvent.loading}
					error={deletingEvent.error}
					retryAction={() => this.deleteEvent()}
				>
					<form onSubmit={handleSubmit(this.deleteEvent)}>
						<Field
							name="name"
							label="Confirmation"
							description={`Type "DELETE" into the field below to confirm deletion of your event (${currentEvent.event.name})`}
							component={LegacyInputField}
							validate={[this.nameRequired, this.nameMatch]}
						/>
					</form>
				</LoadErrorView>
			</Modal>
		);
	}
}

// Decorate with redux-form
const formName = "deleteEvent";
DeleteModal = reduxForm({
	form: formName
})(DeleteModal);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		deletingEvent: state.deletingEvent
	}),
	{ deleteEvent, cancelDeleteEvent }
)(withRouterHooks(DeleteModal));
