import { Input, Select, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import EventDatePicker from "@/components/EventDatePicker";
import LoadErrorView from "@/components/LoadErrorView";
import { findOrders, sendOrder } from "@/state/actions/orderActions";

class SelectOrders extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	state = {};

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(preProps) {
		const { eventDateIds, currentEvent } = this.props;
		if (preProps.eventDateIds !== eventDateIds || (preProps.currentEvent.event === null && currentEvent.event)) {
			this.load();
		}
	}

	load(page = 1) {
		this.setState({ error: false, loading: true });
		const { currentEvent, eventDateIds, findOrders } = this.props;
		if (!currentEvent.event) return;
		const status = {};
		status.query = this.state.query;
		status.eventId = currentEvent.event._id;
		status.eventDateIds = eventDateIds;
		status.eventDateId = this.state.eventDateId;
		status.status = "complete";
		findOrders(status, page);
	}

	handleDateChange(id) {
		this.setState({ eventDateId: id }, this.load);
	}

	handleSearchChange(e) {
		const query = e.target.value;
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => this.setState({ query }, this.load), 500);
	}

	getDateSelection() {
		const dates = this.props.currentEvent.event.dates;
		const datesUseCalendar = this.props.currentEvent.event.datesUseCalendar;
		if (datesUseCalendar) {
			return <EventDatePicker dates={dates} onDatePicked={this.handleDateChange} />;
		}
		return (
			<div style={{ display: "inline", paddingLeft: 20 }}>
				<Select
					showSearch
					style={{ paddingBottom: 10 }}
					defaultValue={dates[0]._id}
					onChange={this.handleDateChange}
					options={dates.map((date) => {
						const dateStr = `${date.display.date}, ${date.display.time}`;
						return { value: date._id, label: dateStr };
					})}
				/>
			</div>
		);
	}

	render() {
		const { selectedOrderIds, orderSelected } = this.props;
		const { orders, loading, error, count, page } = this.props.orders;
		const messages = loading ? { emptyText: "Loading" } : { emptyText: "No Orders" };
		let columns = [
			{
				title: "Id",
				dataIndex: "orderName",
				key: "orderName"
			},
			{
				title: "Name",
				dataIndex: "fullName",
				key: "fullName"
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Date",
				dataIndex: "createdAtDisplay",
				key: "createdAtDisplay"
			},
			{
				title: "Type",
				dataIndex: "type",
				key: "type"
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status"
			}
		];

		const rowSelection = {
			preserveSelectedRowKeys: true,
			selectedRowKeys: selectedOrderIds,
			onChange: orderSelected
		};
		return (
			<LoadErrorView loading={loading} error={error} retryAction={this.load}>
				<Input.Search style={{ marginBottom: 12 }} onChange={this.handleSearchChange} />
				<Table
					rowSelection={rowSelection}
					columns={columns}
					dataSource={orders}
					rowKey="_id"
					locale={messages}
					scroll={{ x: 800 }}
					pagination={{
						showSizeChanger: false,
						total: count,
						current: page,
						onChange: this.load
					}}
				/>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		orders: state.orders
	}),
	{
		findOrders,
		sendOrder
	}
)(SelectOrders);
