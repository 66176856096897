import { EventOverviewTable, OverviewSummary, OverviewTable, SalesChart, TicketsSoldList } from "@/components/Charts";
import FBTracking from "@/components/FBTracking";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import Page from "@/components/Page";
import H1 from "@/components/text/H1";
import { Welcome } from "@/dashboard/Welcome";
import CreateFirstEvent from "@/dashboard/components/CreateFirstEvent";
import DashboardArticle from "@/dashboard/components/DashboardArticle";
import DownloadApp from "@/dashboard/components/DownloadApp";
import FeaturedHelpResource from "@/dashboard/components/FeaturedHelpResource";
import FeeAppliedModal from "@/dashboard/components/FeeAppliedModal";
import OrganiserTips from "@/dashboard/components/OrganiserTips";
import BigBanner from "@/events/event/appBanners/BigBanner";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { getDashboardMetrics, getHistoricalSales, getSales, getUsersChart } from "@/state/actions/metricActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Grid } from "@/ui";
import { Col, Row } from "@/ui/antd";
import { getCurrencySymbol } from "@/utils/Format";
import { getUrlParameter } from "@/utils/Urls";
import { css } from "@emotion/css";
import React, { useCallback, useEffect, useMemo } from "react";

const userCol = css({
	borderTop: "1px solid #dedede",
	paddingTop: "1rem"
});

const pageStyle = css({
	paddingTop: "24px",
	padding: "24px calc(50% - 550px) 33px",
	position: "relative",
	display: "block",
	margin: "0 auto",
	"@media (max-width: 1128px)": {
		paddingLeft: "14px",
		paddingRight: "14px"
	}
});

const SalesCharts = ({
	isAdmin,
	handleHistoricalSalesChange,
	handleSalesChange
}: {
	isAdmin?: boolean;
	handleHistoricalSalesChange: (value: string) => void;
	handleSalesChange: (value: string) => void;
}) => {
	return (
		<>
			<SalesChart
				key="sales"
				title="Historical orders chart"
				data="sales"
				lines={[
					{ label: "Tickets", key: "tickets", stroke: "#353337" },
					{ label: "Orders", key: "orders", stroke: "#57cfc7" },
					{ label: "Cancellations", key: "cancellations", stroke: "#e6a081" }
				]}
				metricState="dashboardMetrics"
				style={{ minHeight: 300 }}
				onChange={handleHistoricalSalesChange}
			/>
			{isAdmin && (
				<SalesChart
					key="revenueSales"
					title="Historical sales chart"
					data="revenueSales"
					prefix={getCurrencySymbol()}
					lines={[
						{ label: "Net Sales", key: "netSales", stroke: "#353337" },
						{ label: "Gross Sales", key: "grossSales", stroke: "#57cfc7" },
						{ label: "Refunds", key: "refunds", stroke: "#e6a081" }
					]}
					metricState="dashboardMetrics"
					style={{ minHeight: 300 }}
					onChange={handleSalesChange}
				/>
			)}
		</>
	);
};

export const Dashboard: React.FC = () => {
	const dispatch = useAppDispatch();

	const metrics = useAppSelector((state) => state.dashboardMetrics);
	const { user, isLoading: isUserLoading, isAdmin } = useCurrentUser();

	const getMetrics = useCallback(() => {
		if (user && !isUserLoading) {
			dispatch(getDashboardMetrics("today", "all"));
		}
	}, [dispatch, getDashboardMetrics, user, isUserLoading]);

	const onLocationChange = useCallback(() => {
		getMetrics();
	}, [getMetrics]);

	const handleHistoricalSalesChange = useCallback(
		(value: string) => {
			dispatch(getHistoricalSales(value));
		},
		[dispatch]
	);

	const handleSalesChange = useCallback(
		(value: string) => {
			dispatch(getSales(value));
		},
		[dispatch]
	);

	const handleUsersChange = useCallback(
		(value: string) => {
			dispatch(getUsersChart(value));
		},
		[dispatch]
	);

	useEffect(() => {
		getMetrics();
	}, [user]);

	const permissions = user?.permissions || [];
	const appAccess = useMemo(
		() =>
			permissions.some(
				(permission) =>
					permission?.accepted && permission.eventAccess?.access?.app && permission.eventAccess.access.checkin
			),
		[permissions]
	);

	const showTips = metrics.totalEvents === 0 && user?.purpose === "organiser";
	const emptyDashboardAction = showTips ? (
		<OrganiserTips eventTypes={user?.organiserOnboardingInfo?.eventTypes} />
	) : (
		<CreateFirstEvent totalEvents={metrics.totalEvents} />
	);

	const callToAction = appAccess ? <DownloadApp /> : emptyDashboardAction;

	if (!user) {
		return null;
	}

	return (
		<Page
			pageName={showTips ? undefined : isAdmin ? <H1>Dashboard</H1> : <Welcome />}
			sibling={<LocationSelection defaultLevel="user" onLocationChange={onLocationChange} />}
			pageStyle={pageStyle}
		>
			<LoadErrorView
				loading={metrics.loading || !user}
				error={metrics.error}
				retryAction={() => dispatch(getDashboardMetrics())}
			>
				{isAdmin ? (
					<Grid columnMin="100%">
						<Grid columnMin="400px">
							<OverviewTable title="Overview Today" />
							<EventOverviewTable metricState="dashboardMetrics" />
						</Grid>
						<SalesCharts
							isAdmin
							handleHistoricalSalesChange={handleHistoricalSalesChange}
							handleSalesChange={handleSalesChange}
						/>
						<Row>
							<Col span={24}>
								<SalesChart
									title="Users"
									data="users"
									lines={[{ label: "Users", key: "users", stroke: "#e6a081" }]}
									metricState="dashboardMetrics"
									style={{ minHeight: 250 }}
									onChange={handleUsersChange}
								/>
							</Col>
						</Row>
					</Grid>
				) : metrics.totalEvents === 0 || metrics.totalPublishedEvents === 0 ? (
					<>
						{callToAction}
						{getUrlParameter("newuser") === "true" && <FBTracking pageName="signedup" />}
						<FeeAppliedModal />
					</>
				) : (
					<div className={userCol}>
						<Row gutter={12}>
							<Col xs={24} sm={24} md={16}>
								<OverviewSummary />
								<OverviewTable />
								<TicketsSoldList metricState="dashboardMetrics" />
								<SalesCharts
									handleHistoricalSalesChange={handleHistoricalSalesChange}
									handleSalesChange={handleSalesChange}
								/>
							</Col>
							<Col xs={24} sm={24} md={8}>
								<DashboardArticle articleType="featuredArticle" />
								<DashboardArticle articleType="featuredHelpResource" />
								<FeaturedHelpResource />
							</Col>
						</Row>
						<BigBanner />
					</div>
				)}
			</LoadErrorView>
		</Page>
	);
};
