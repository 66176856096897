import { FC } from "react";
import { Tag as AntdTag, TagProps as AntdTagProps } from "antd";
import styled from "@emotion/styled";

export type TagVariant = "success" | "warning" | "danger" | "info" | "neutral";

type TagProps = AntdTagProps & {
	variant: TagVariant;
};

const styles = {
	success: {
		bgColor: "var(--success-subtle)",
		color: "var(--success)"
	},
	warning: {
		bgColor: "var(--warning-subtle)",
		color: "var(--warning)"
	},
	danger: {
		bgColor: "var(--error-subtle)",
		color: "var(--error)"
	},
	info: {
		bgColor: "var(--info-subtle)",
		color: "var(--info)"
	},
	neutral: {
		bgColor: "var(--surface3)",
		color: "var(--on-surface1)"
	}
};

const StyledTag = styled(AntdTag, {
	shouldForwardProp: (prop) => !["variant"].includes(prop)
})<TagProps>((props) => {
	const tagStyles = styles[props.variant];
	return `
		align-items: center;
		background-color: ${tagStyles.bgColor ?? "transparent"};
		color:  ${tagStyles.color ?? "var(--on-surface1)"};
		border-radius: 4px;
		border: none;
		display: inline-flex;
		font-size: 14px;
		font-weight: 500;
		gap: 4px;
		height: 21px;
		margin-right: 0;
	`;
});

export const Tag: FC<TagProps> = ({ variant, ...props }) => {
	return <StyledTag variant={variant} {...props} />;
};
