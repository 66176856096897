import {
	MAILCHIMP_GET_AUDIENCES_ERROR,
	MAILCHIMP_GET_AUDIENCES_REQUEST,
	MAILCHIMP_GET_AUDIENCES_SUCCESS,
	MAILCHIMP_TEST_ERROR,
	MAILCHIMP_TEST_REQUEST,
	MAILCHIMP_TEST_SUCCESS
} from "../actions/mailchimpActions";

const initialState = {
	audienceLoading: false,
	audienceError: false,
	connectionTesting: false,
	connectionFailed: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case MAILCHIMP_TEST_REQUEST:
			return {
				...state,
				connectionTesting: true,
				connectionFailed: false
			};
		case MAILCHIMP_TEST_SUCCESS:
			return {
				...state,
				connectionTesting: false,
				connectionFailed: false
			};
		case MAILCHIMP_TEST_ERROR:
			return {
				...state,
				connectionTesting: false,
				connectionFailed: true
			};
		case MAILCHIMP_GET_AUDIENCES_REQUEST:
			return {
				...state,
				audienceLoading: true,
				audienceError: false
			};
		case MAILCHIMP_GET_AUDIENCES_SUCCESS:
			return {
				...state,
				audienceLoading: false,
				audienceError: false
			};
		case MAILCHIMP_GET_AUDIENCES_ERROR:
			return {
				...state,
				audienceLoading: false,
				audienceError: true
			};
		default:
			return state;
	}
};
