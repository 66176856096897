import styled from "@emotion/styled";
import { Flex } from "antd";
import { FC, ReactNode } from "react";

const StyledMenu = styled.nav`
	border-right: 1px solid rgba(21, 20, 19, 0.14);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 64px;
`;

const StyledMenuItem = styled.button<{ selected: boolean }>`
	align-items: center;
	background-color: ${({ selected }) => (selected ? "rgba(21, 20, 19, 0.06)" : "transparent")};
	border: none;
	border-right: ${({ selected }) => (selected ? "3px solid rgba(21, 20, 19, 0.31)" : "3px solid transparent")};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 12px;
	gap: 6px;
	height: 64px;
	justify-content: center;
	min-height: 69px;
	outline: none;
	padding: 12px 4px;
	transition: all 0.2s ease-in-out;
	width: 100%;

	&:focus-visible {
		background-color: rgba(21, 20, 19, 0.2);
	}

	&:hover {
		background-color: rgba(21, 20, 19, 0.1);
	}
`;

type NavigationMenuItem = {
	icon: ReactNode;
	key: string;
	label: string;
	onClick?: () => void;
};

type IconNavigationMenuProps = {
	items: NavigationMenuItem[];
	onSelect: (key: string) => void;
	selected: string;
	endItems?: NavigationMenuItem[];
};

export const IconNavigationMenu: FC<IconNavigationMenuProps> = ({ items, onSelect, selected, endItems }) => {
	return (
		<StyledMenu>
			<Flex vertical>
				{items.map((item) => (
					<StyledMenuItem
						aria-label={item.label}
						key={item.key}
						onClick={() => onSelect(item.key)}
						selected={selected === item.key}
						tabIndex={0}
					>
						<span>{item.icon}</span>
						<span>{item.label}</span>
					</StyledMenuItem>
				))}
			</Flex>
			{endItems && (
				<Flex vertical>
					{endItems.map((item) => (
						<StyledMenuItem
							aria-label={item.label}
							key={item.key}
							onClick={() => (item.onClick ? item.onClick() : onSelect(item.key))}
							selected={selected === item.key}
							tabIndex={0}
						>
							<span>{item.icon}</span>
							<span>{item.label}</span>
						</StyledMenuItem>
					))}
				</Flex>
			)}
		</StyledMenu>
	);
};
