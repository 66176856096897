import axios from "./AxiosWrapper";

const DiscountCodesService = {
	async find(eventId, page, query) {
		const response = await axios.get(`events/discount-codes/${eventId}`, {
			params: { page, query }
		});
		return response.data;
	},

	async duplicate(eventId, discountCode) {
		const response = await axios.put(`events/discount-codes/duplicate/${eventId}`, discountCode);
		return response.data;
	},

	async save(eventId, discountCode) {
		if (discountCode._id) {
			return this.update(eventId, discountCode);
		}
		return this.create(eventId, discountCode);
	},

	async create(eventId, discountCode) {
		if (discountCode.type === "single") {
			const response = await axios.put(`events/discount-codes/${eventId}`, discountCode);
			return response.data;
		}
		// upload a CSV
		let fd = new FormData();
		fd.append("file", discountCode.csvFile[0]);
		fd.append("appliesTo", discountCode.appliesTo);
		fd.append("enabled", discountCode.enabled);
		fd.append("quantity", discountCode.quantity);
		fd.append("maximumUsePerOrder", discountCode.maximumUsePerOrder);
		fd.append("discount", discountCode.discount);
		fd.append("discountType", discountCode.discountType);
		fd.append("startDate", discountCode.startDate);
		fd.append("endDate", discountCode.endDate);
		fd.append("location", discountCode.location);
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const response = await axios.put(`events/discount-codes/upload/${eventId}`, fd, config);
		return response.data;
	},
	async update(eventId, discountCode) {
		const response = await axios.post(`events/discount-codes/${eventId}`, discountCode);
		return response.data;
	},
	async delete(eventId, discountCode) {
		const response = await axios.delete(`events/discount-codes/${eventId}/${discountCode._id}`, discountCode);
		return response.data;
	},
	async deleteAll(eventId) {
		const response = await axios.delete(`events/discount-codes/${eventId}/all`);
		return response.data;
	}
};
export default DiscountCodesService;
