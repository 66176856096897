import { css } from "@emotion/css";
import { DateFormats, HumaniDate, TimeFormats } from "@hx/dates";
import { Link } from "react-router-dom";
import LocationService from "../services/LocationService";
import { getEventSearchLink } from "../utils/Events";

const underlinedTitle = css({
	span: {
		textDecoration: "underline",
		color: "#323232"
	}
});

const wrapper = css({
	":hover,:focus": underlinedTitle,
	paddingBottom: "12px"
});

const eventName = css`
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.33px;
	color: #323232;
`;

const date = css`
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.28px;
	color: #323232;
`;

type EventCreationSteps = {
	basics?: boolean;
	schedule?: boolean;
	details?: boolean;
	ticketTypes?: boolean;
	projects?: boolean;
};

interface IEventRecentlyUpdatedRowProps {
	_id: string;
	name: string;
	updatedAt: string;
	eventCreationSteps: EventCreationSteps;
}

const EventRecentlyUpdatedRow = ({ _id, name, updatedAt, eventCreationSteps }: IEventRecentlyUpdatedRowProps) => {
	const updatedAtDayjs = new HumaniDate(updatedAt, "guess", LocationService.getLocation(true));
	return (
		<Link key={_id} to={getEventSearchLink(_id, eventCreationSteps)}>
			<div className={wrapper}>
				<span className={eventName}>{name}</span>
				<div className={date}>
					{`${updatedAtDayjs.formatting.date(DateFormats.Pretty)} at ${updatedAtDayjs.formatting.time(
						TimeFormats.Short
					)}`}
				</div>
			</div>
		</Link>
	);
};

export default EventRecentlyUpdatedRow;
