import CanvaIcon from "@/assets/icons/icon-canva.svg";
import { getConfig } from "@/config";
import { Button } from "@/ui";
import { Svg } from "@/ui";
import { FC, useEffect, useState } from "react";

type DesignOnCanvaButtonProps = {
	canvaId?: string;
	disabled?: boolean;
	height?: number;
	instanceId: string;
	isLoading?: boolean;
	onDesignPublish: (url: string, designId: string, title: string) => void;
	width?: number;
};

export const DesignOnCanvaButton: FC<DesignOnCanvaButtonProps> = ({
	canvaId,
	disabled,
	height,
	instanceId,
	isLoading,
	onDesignPublish,
	width
}) => {
	const [canvaApiLoading, setCanvaApiLoading] = useState(true);

	useEffect(() => {
		const loadCanvaScript = async () => {
			if (!window.Canva && !isScriptLoaded()) {
				const script = document.createElement("script");
				script.src = "https://sdk.canva.com/designbutton/v2/api.js";
				script.onload = async () => {
					await initializeCanvaApi();
				};
				script.onerror = () => console.error("Canva API script loading error");
				document.body.appendChild(script);
			} else if (window.Canva) {
				await initializeCanvaApi();
			}
		};

		const isScriptLoaded = () => {
			return !!document.querySelector('script[src="https://sdk.canva.com/designbutton/v2/api.js"]');
		};

		const initializeCanvaApi = async () => {
			const CANVA_API_KEY = getConfig("CANVA_API_KEY");
			if (window.Canva?.DesignButton) {
				try {
					const api = await window.Canva.DesignButton.initialize({ apiKey: CANVA_API_KEY });
					window.canvaApi = api;
					setCanvaApiLoading(false);
				} catch {
					// Canva api is limited with telling you if it is initialised. No actions needed here.
				}

				window[`canvaCallback${instanceId}`] = (result: {
					designId: string;
					designTitle?: string;
					exportUrl: string;
				}) => {
					const { designId, designTitle, exportUrl } = result;
					onDesignPublish(exportUrl, designId, designTitle || "Canva design");
				};
			}
		};
		setCanvaApiLoading(false);

		loadCanvaScript();
	}, [instanceId, onDesignPublish]);

	const handleCreateDesign = () => {
		if (window.canvaApi) {
			window.canvaApi.createDesign({
				design: { dimensions: { height, width } },
				onDesignPublish: window[`canvaCallback${instanceId}`]
			});
		}
	};

	const handleEditDesign = () => {
		if (window.canvaApi) {
			window.canvaApi.editDesign({
				design: { id: canvaId },
				onDesignPublish: window[`canvaCallback${instanceId}`]
			});
		}
	};

	return (
		<Button
			disabled={canvaApiLoading || disabled}
			iconLeft={<Svg size="14px" src={CanvaIcon} />}
			isLoading={canvaApiLoading || isLoading}
			onClick={canvaId ? handleEditDesign : handleCreateDesign}
			variant="secondary"
		>
			{canvaId ? "Edit on Canva" : "Design on Canva"}
		</Button>
	);
};
