import { css } from "@emotion/css";
import { Progress } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import soldOutIcon from "../../assets/ic_ticket_sold_out.svg";
import { H4 } from "../text/Text";

export default class TicketsSoldCard extends Component {
	render() {
		const { name, ticketsSold, capacity, eventId, eventDates } = this.props;
		const date = eventDates ? eventDates[0] : "";
		const dateStr = date && date.display ? `${date.display.date}, ${date.display.time}` : "";

		const columnLeft = css({
			maxWidth: 400,
			width: "100%",
			display: "inline-block",
			"@media(max-width: 800px)": {
				display: "block",
				float: "none",
				maxWidth: "none"
			},
			".ant-progress-outer": {
				paddingRight: "0px !important"
			},
			".ant-progress-text": {
				position: "absolute",
				bottom: "-17px",
				color: "#323232"
			}
		});
		const columnRight = css({
			maxWidth: 270,
			width: "100%",
			display: "inline-block",
			height: 75,
			float: "right",
			position: "relative",
			"@media(max-width: 800px)": {
				display: "block",
				float: "none",
				maxWidth: "none",
				maxHeight: 20
			},
			"@media(max-width:400px)": {
				textAlign: "center",
				paddingTop: 12,
				height: 30
			}
		});

		const imageStyling = css({
			position: "absolute",
			left: 0,
			top: 20,
			width: 50,
			height: 47,
			"@media(max-width:400px)": {
				display: "none"
			}
		});

		const textStyling = css({
			position: "absolute",
			left: 52,
			top: 36,
			color: "#5A626C",
			fontSize: 16,
			"@media(max-width:400px)": {
				position: "relative",
				left: "auto",
				top: "auto"
			}
		});

		const wrapper = css({
			padding: 15,
			borderTop: "var(--border1)",
			"@media(max-width: 800px)": {
				padding: "14px 0px"
			}
		});

		const percentSold = Math.floor((ticketsSold / capacity) * 100);

		return (
			<div className={wrapper}>
				<div>
					<div className={columnLeft}>
						{eventId ? (
							<div>
								<Link to={`/console/my-events/${eventId}`}>
									<H4 style={{ color: "#323232" }}>{name}</H4>
								</Link>
								{dateStr} {eventDates.length > 1 ? <span>+ {eventDates.length - 1} more</span> : ""}{" "}
							</div>
						) : (
							<H4 style={{ color: "#323232" }}>{name}</H4>
						)}
						<Progress strokeWidth={15} percent={capacity < 1 ? 0 : percentSold} />

						<div className="label" style={{ float: "left" }}>{`${ticketsSold} / ${capacity}`}</div>
					</div>
					<div className={columnRight}>
						{ticketsSold - capacity >= 0 && (
							<div>
								<img className={imageStyling} src={soldOutIcon} alt="tickets_sold_out" />
								<div className={textStyling}>Your event has sold out!</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
