import TourService from "@/services/TourService";
import { Tour } from "@/state/reducers/tourReducer";
import { AppDispatch } from "@/state/store";
import { notification } from "@/ui/antd";
import { NavigateFunction } from "react-router";
import { destroy } from "redux-form";

const wait = (seconds: number) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export enum ToursActionTypes {
	GET_TOURS_REQUEST = "GET_TOURS_REQUEST",
	GET_TOURS_SUCCESS = "GET_TOURS_SUCCESS",
	GET_TOURS_ERROR = "GET_TOURS_ERROR",

	GET_TOUR_REQUEST = "GET_TOUR_REQUEST",
	GET_TOUR_SUCCESS = "GET_TOUR_SUCCESS",
	GET_TOUR_ERROR = "GET_TOUR_ERROR",

	SAVE_TOUR_REQUEST = "SAVE_TOUR_REQUEST",
	SAVE_TOUR_SUCCESS = "SAVE_TOUR_SUCCESS",
	SAVE_TOUR_ERROR = "SAVE_TOUR_ERROR",

	UPDATE_TOUR_REQUEST = "UPDATE_TOUR_REQUEST",
	UPDATE_TOUR_SUCCESS = "UPDATE_TOUR_SUCCESS",
	UPDATE_TOUR_ERROR = "UPDATE_TOUR_ERROR",

	DELETE_TOUR_REQUEST = "DELETE_TOUR_REQUEST",
	DELETE_TOUR_SUCCESS = "DELETE_TOUR_SUCCESS",
	DELETE_TOUR_ERROR = "DELETE_TOUR_ERROR",

	DELETE_TOUR_MODAL_OPEN = "DELETE_TOUR_MODAL_OPEN",
	DELETE_TOUR_MODAL_CLOSE = "DELETE_TOUR_MODAL_CLOSE",

	PUBLISH_OR_UNPUBLISH_REQUEST = "PUBLISH_OR_UNPUBLISH_REQUEST",
	PUBLISH_OR_UNPUBLISH_SUCCESS = "PUBLISH_OR_UNPUBLISH_SUCCESS",
	PUBLISH_OR_UNPUBLISH_ERROR = "PUBLISH_OR_UNPUBLISH_ERROR"
}

export const getTours = (userId: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: ToursActionTypes.GET_TOURS_REQUEST });
		TourService.getTours(userId)
			.then((tours) => {
				dispatch({ type: ToursActionTypes.GET_TOURS_SUCCESS, tours });
			})
			.catch((err) => {
				const message = err.response?.data?.message ?? "Failed to get tour pages";
				dispatch({ type: ToursActionTypes.GET_TOURS_ERROR, error: message });
			});
	};
};

export const getTour = (tourId: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: ToursActionTypes.GET_TOUR_REQUEST });
		TourService.getTour(tourId)
			.then((tour) => {
				dispatch({ type: ToursActionTypes.GET_TOUR_SUCCESS, tour });
			})
			.catch((err) => {
				const message = err.response?.data?.message ?? "Failed to get tour page";
				dispatch({ type: ToursActionTypes.GET_TOUR_ERROR, error: message });
			});
	};
};

export const saveTour = (values: Partial<Tour>, navigate: NavigateFunction) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: ToursActionTypes.SAVE_TOUR_REQUEST });
		TourService.saveTour(values)
			.then(() => {
				dispatch(destroy("tour"));
				dispatch({ type: ToursActionTypes.SAVE_TOUR_SUCCESS });
				notification.success({
					message: "Saved",
					description: "Your tour has been saved"
				});

				// hack to get around the redux-form still being marked as isDirty after submitting.
				// Waiting before navigating the user away stops the withSaveCheck HOC from blocking the navigation.
				wait(0.01).then(() => navigate("/console/promote/tours"));
			})
			.catch((err) => {
				const message = err.response?.data?.message ?? "Failed to save the tour page";
				dispatch({ type: ToursActionTypes.SAVE_TOUR_ERROR, error: err });
				notification.error({
					message: "Error",
					description: message
				});
			});
	};
};

export const updateTour = (tourId: string, values: Partial<Tour>) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: ToursActionTypes.UPDATE_TOUR_REQUEST });
		TourService.updateTour(tourId, values)
			.then((tour) => {
				dispatch({ type: ToursActionTypes.UPDATE_TOUR_SUCCESS, tour });
				notification.success({
					message: "Updated",
					description: "Your tour has been updated"
				});
			})
			.catch((err) => {
				const message = err.response?.data?.message ?? "Failed to update the tour page";
				dispatch({ type: ToursActionTypes.UPDATE_TOUR_ERROR, error: err });
				notification.error({
					message: "Error",
					description: message
				});
			});
	};
};

export const deleteTour = (tourId: string, navigate: NavigateFunction) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: ToursActionTypes.DELETE_TOUR_REQUEST });
		TourService.deleteTour(tourId)
			.then((tour) => {
				dispatch({ type: ToursActionTypes.DELETE_TOUR_SUCCESS, tour });
				notification.success({
					message: "Deleted",
					description: `Tour has been deleted`
				});
				navigate("/console/promote/tours");
			})
			.catch((err) => {
				const message = err.response?.data?.message ?? "Failed to delete the tour page";
				dispatch({ type: ToursActionTypes.DELETE_TOUR_ERROR, error: message });
			});
	};
};

export const openDeleteModal = () => (dispatch: AppDispatch) =>
	dispatch({ type: ToursActionTypes.DELETE_TOUR_MODAL_OPEN });

export const closeDeleteModal = () => (dispatch: AppDispatch) =>
	dispatch({ type: ToursActionTypes.DELETE_TOUR_MODAL_CLOSE });

export const publishOrUnpublish = (tourId: string, published = false) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: ToursActionTypes.PUBLISH_OR_UNPUBLISH_REQUEST });
		const action = published ? TourService.unpublish : TourService.publish;
		action(tourId)
			.then((tour) => {
				dispatch({ type: ToursActionTypes.PUBLISH_OR_UNPUBLISH_SUCCESS, tour });
				notification.success({
					message: published ? "Unpublished" : "Published",
					description: published ? "Your tour has been unpublished" : "Your tour has been published"
				});
			})
			.catch((err: any) => {
				const message =
					err.response?.data?.message ?? published
						? "Failed to unpublish the tour page"
						: "Failed to publish the tour page";
				dispatch({ type: ToursActionTypes.PUBLISH_OR_UNPUBLISH_ERROR, error: err });
				notification.error({
					message: "Error",
					description: message
				});
			});
	};
};
