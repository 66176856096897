import H4 from "@/components/text/H4";
import { dayjs } from "@/libs/dayjs";
import { loadDates } from "@/state/actions/schedulerActions";
import { Flex, Select } from "@/ui/antd";
import { ArrowLeftOutlined, ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Button } from "../../../ui";

const CalendarHeaderStyle = css({
	order: 4,
	display: "flex",
	"@media(max-width:600px)": {
		order: 3
	},
	H4: {
		marginBottom: "0px !important"
	}
});

const TimezoneStyle = css({
	"@media(max-width:600px)": {
		display: "none"
	}
});

class CalenderHeader extends Component {
	calendarApi;

	constructor(props) {
		super(props);
		this.calendarApi = props.calendarApi;
	}

	goToToday = () => {
		this.calendarApi.today();
		this.forceUpdate();
		this.props.loadDates();
	};

	next = () => {
		this.calendarApi.next();
		this.forceUpdate();
		this.props.loadDates();
	};

	prev = () => {
		this.calendarApi.prev();
		this.forceUpdate();
		this.props.loadDates();
	};

	changeTimeSetting = (time) => {
		this.calendarApi.changeView(time);
		this.forceUpdate();
		this.props.loadDates();
	};

	getDisplayDate = () => {
		const timeZone = this.props.currentEvent.event.timezone;
		const selectedDate = dayjs(this.calendarApi.getDate()).tz(timeZone);
		const timezoneAbbr = selectedDate.format("z");

		switch (this.calendarApi.view.type) {
			case "dayGridMonth":
				return (
					<div>
						{`${selectedDate.format("MMMM YYYY")}`}
						<span className={TimezoneStyle}>{`, ${timezoneAbbr}`}</span>
					</div>
				);
			case "timeGridWeek":
				return (
					<div>
						{`${dayjs(selectedDate).startOf("week").format("MMMM D")} - ${dayjs(selectedDate)
							.endOf("week")
							.format("D, YYYY")}`}
						<span className={TimezoneStyle}>{`, ${timezoneAbbr}`}</span>
					</div>
				);
			case "timeGridDay":
				return (
					<div>
						{`${selectedDate.format("MMMM D, YYYY")}`}
						<span className={TimezoneStyle}>{`, ${timezoneAbbr}`}</span>
					</div>
				);
			case "listYear":
				return <div>{`${selectedDate.format("YYYY")}`}</div>;
			default:
				return <div>{`${selectedDate.format("YYYY")}`}</div>;
		}
	};

	render() {
		const { onNewSchedule, currentEvent } = this.props;
		const timezone = currentEvent.event.timezone;
		return (
			<Flex gap="sm" wrap justify="space-between">
				<Flex gap="sm">
					{this.calendarApi.view.type !== "listYear" && (
						<Button variant="tertiary" onClick={this.goToToday}>
							Today
						</Button>
					)}
					<Button aria-label="Previous date" iconOnly onClick={this.prev} variant="text">
						<ArrowLeftOutlined />
					</Button>
					<Button aria-label="Next date" iconOnly onClick={this.next} variant="text">
						<ArrowRightOutlined />
					</Button>
					<div className={CalendarHeaderStyle}>
						<H4
							style={{
								marginBottom: 0,
								marginLeft: 0,
								display: "flex",
								alignItems: "center"
							}}
						>
							{this.getDisplayDate()}
						</H4>
					</div>
				</Flex>
				<Flex gap="sm">
					<Select
						options={[
							{ value: "dayGridMonth", label: "Month" },
							{ value: "timeGridWeek", label: "Week" },
							{ value: "timeGridDay", label: "Day" },
							{ value: "listYear", label: "List view" }
						]}
						value={this.calendarApi.view.type}
						style={{ width: 120 }}
						onChange={this.changeTimeSetting}
					/>
					<Button
						dataCy="add-dates"
						iconLeft={<PlusOutlined />}
						onClick={() => ({
							dateStr: onNewSchedule({
								dateStr: dayjs().tz(timezone, true).add(7, "days").format("YYYY-MM-DD")
							})
						})}
						variant="tertiary"
					>
						Add dates
					</Button>
				</Flex>
			</Flex>
		);
	}
}

export default CalenderHeader = connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			scheduler: state.scheduler //adding scheduler to props to enable rerendering when this changes (for the date read out at the top)
		};
	},
	{ loadDates }
)(CalenderHeader);
