import { Button, ResponsiveModal } from "@/ui";
import { Form, Input, InputRef } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, useEffect, useRef } from "react";

const StyledNameModalFooter = styled.div`
	display: flex;
	justify-content: flex-end;
`;

type ReameCollectionModalProps = {
	defaultName?: string;
	onClose: () => void;
	onSubmit: (name: string) => void;
	open: boolean;
};

export const ReameCollectionModal: FC<ReameCollectionModalProps> = ({ defaultName, onClose, onSubmit, open }) => {
	const [nameCollectionForm] = Form.useForm();
	const inputRef = useRef<InputRef>(null);

	useEffect(() => {
		if (open) {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	}, [open]);

	useEffect(() => {
		nameCollectionForm.setFieldsValue({ collectionName: defaultName ?? "" });
	}, [defaultName]);

	const handleSubmitCollectionName = async () => {
		const formValues = await nameCollectionForm.validateFields();
		if (formValues) {
			onSubmit(formValues.collectionName);
			nameCollectionForm.resetFields();
			onClose();
		}
	};

	return (
		<ResponsiveModal
			footer={
				<StyledNameModalFooter>
					<Button onClick={handleSubmitCollectionName} variant="secondary">
						Rename Collection
					</Button>
				</StyledNameModalFooter>
			}
			onCancel={onClose}
			open={open}
			padding="24px"
			header="Name your collection"
			closable
			width="672px"
		>
			<Form
				autoComplete="off"
				form={nameCollectionForm}
				initialValues={{ collectionName: defaultName }}
				layout="vertical"
			>
				<Form.Item
					name="collectionName"
					label={<b>Collection Name</b>}
					rules={[
						{ message: "Please enter a collection name", min: 1, required: true, type: "string" },
						{ max: 80, message: "Collection name must be less than 80 characters" }
					]}
				>
					<Input
						ref={inputRef}
						maxLength={80}
						showCount
						onBlur={(e) => {
							const value = e.target.value;
							if (value.length > 0) {
								nameCollectionForm.setFieldsValue({ collectionName: value.trim() });
							}
						}}
					/>
				</Form.Item>
			</Form>
		</ResponsiveModal>
	);
};
