import { FC } from "react";
import { Route, Routes } from "react-router";
import MenuLayout from "../components/MenuLayout";
import Page from "../components/Page";
import { AdminMenu } from "./components/AdminMenu";
import GeneratedReports from "./reports/GeneratedReports";
import AdjustmentsDetailsReport from "./reports/finance/types/AdjustmentsDetailsReport";
import AdjustmentsReport from "./reports/finance/types/AdjustmentsReport";
import BoardReport from "./reports/finance/types/BoardReport";
import ComparePayments from "./reports/finance/types/ComparePayments/ComparePayments";
import ControlReport from "./reports/finance/types/ControlReport";
import FeeCrystalisationReport from "./reports/finance/types/FeeCrystalisationReport";
import FeesDetailsReport from "./reports/finance/types/FeesDetailsReport";
import FeesReport from "./reports/finance/types/FeesReport";
import PayoutsDetailsReport from "./reports/finance/types/PayoutsDetailsReport";
import PayoutsReport from "./reports/finance/types/PayoutsReport";
import PayoutsReversedReport from "./reports/finance/types/PayoutsReversedReport";
import ReferralPayoutReport from "./reports/finance/types/ReferralPayoutReport";
import RefundsReport from "./reports/finance/types/RefundsReport";
import TicketSalesSummary from "./reports/finance/types/TicketSalesSummary";
import TopPurchasers from "./reports/attendees/TopPurchasers";
import Activity from "./reports/users/Activity";
import TopUsers from "./reports/users/TopUsers";
import ForecastedTopEvents from "./reports/events/ForecastedTopEvents";
import SalesByEventLocation from "./reports/events/SalesByEventLocation";
import TopEvents from "./reports/events/TopEvents";
import GiftCardsHeldReport from "./reports/finance/types/GiftCardsHeldReport";
import MoneyHeld from "./reports/finance/types/MoneyHeld";
import MailchimpReport from "./reports/integrations/MailchimpReport";
import BoxOfficeTerminals from "./BoxOfficeTerminals";
import MassPayouts from "./payouts/MassPayouts";
import AuditLogs from "./utilities/AuditLogs/AuditLogs";
import DND from "./utilities/DND";
import EmailSignatureGenerator from "./utilities/EmailSignatureGenerator";
import EmailInspect from "./utilities/Emails/EmailInspect";
import EmailLogs from "./utilities/Emails/EmailLogs";
import Utilities from "./utilities/Utilities";
import MassPayoutDetails from "./payouts/MassPayoutDetails";
import PayoutsSearch from "./payouts/payouts/Payouts";
import PaymentsSearch from "./payouts/payments/Payments";

export const Admin: FC = () => {
	return (
		<div style={{ height: "100%" }}>
			<MenuLayout>
				<AdminMenu />
				<Page type="details">
					<Routes>
						<Route path="reports/*">
							<Route path="board" element={<BoardReport />} />
							<Route path="control" element={<ControlReport />} />
							<Route path="ticket-sales-summary" element={<TicketSalesSummary />} />
							<Route path="ticket-sales-by-event" element={<TicketSalesSummary />} />
							<Route path="refunds" element={<RefundsReport />} />

							<Route path="fees/*">
								<Route path="details" element={<FeesDetailsReport />} />
								<Route path="" element={<FeesReport />} />
							</Route>
							<Route path="fees-crystalisation" element={<FeeCrystalisationReport />} />

							<Route path="payouts/*">
								<Route path="details" element={<PayoutsDetailsReport />} />
								<Route path="reversed" element={<PayoutsReversedReport />} />
								<Route path="" element={<PayoutsReport />} />
							</Route>

							<Route path="adjustments/*">
								<Route path="details" element={<AdjustmentsDetailsReport />} />
								<Route path="" element={<AdjustmentsReport />} />
							</Route>

							<Route path="money-held" element={<MoneyHeld />} />
							<Route path="gift-cards-held" element={<GiftCardsHeldReport />} />
							<Route path="attendees/top-purchasers" element={<TopPurchasers />} />

							<Route path="users/*">
								<Route path="activity" element={<Activity />} />
								<Route path="top" element={<TopUsers />} />
							</Route>

							<Route path="events/*">
								<Route path="sales-by-event-location" element={<SalesByEventLocation />} />
								<Route path="top" element={<TopEvents />} />
								<Route path="forecasted" element={<ForecastedTopEvents />} />
							</Route>

							<Route path="referral-payout-report" element={<ReferralPayoutReport />} />
							<Route path="compare-payments" element={<ComparePayments />} />
							<Route path="integrations/mailchimp" element={<MailchimpReport />} />
							<Route path="generated" element={<GeneratedReports />} />
						</Route>

						<Route path="mass-payouts" element={<MassPayouts />} />

						<Route path="payouts/*">
							<Route path="" element={<PayoutsSearch />} />
							<Route path="payments" element={<PaymentsSearch />} />
						</Route>

						<Route path="utilities/*">
							<Route path="payouts" element={<Utilities />} />
							<Route path="dnd" element={<DND />} />
							<Route path="email-signature-generator" element={<EmailSignatureGenerator />} />
						</Route>

						<Route path="email-logs/*">
							<Route path="inspect/:emailId/:emailType" element={<EmailInspect />} />
							<Route path="" element={<EmailLogs />} />
						</Route>

						<Route path="audit-logs" element={<AuditLogs />} />
						<Route path="mass-payout/:massPaymentId" element={<MassPayoutDetails />} />
						<Route path="box-office/terminals" element={<BoxOfficeTerminals />} />
					</Routes>
				</Page>
			</MenuLayout>
		</div>
	);
};
