import { Modal, Select } from "@/ui/antd";
import { Component } from "react";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { P } from "@/components/text/Text";

export default class EventSelectionModal extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.state = { chosenEvent: "", chosen: false };
	}

	onChange(e) {
		this.setState({ chosenEvent: e, chosen: true });
	}

	render() {
		const { visible, handleCancel, handleSubmit, userEvents } = this.props;
		const { chosen, chosenEvent } = this.state;
		const eventOptions = userEvents.map((event) => {
			return { value: event._id, label: event.name };
		});
		return (
			<Modal
				open={visible}
				title="Select event"
				destroyOnClose
				onCancel={handleCancel}
				footer={
					<ButtonsBar footer>
						<LegacyButton
							style={{
								float: "left"
							}}
							key="cancel"
							onClick={handleCancel}
							htmlType="button"
							ariaLabel="Cancel"
						>
							Cancel
						</LegacyButton>
						<LegacyButton
							onClick={() => handleSubmit(chosenEvent)}
							disabled={!chosen}
							key="submit"
							type="primary"
							htmlType="submit"
							ariaLabel="Continue"
						>
							Continue
						</LegacyButton>
					</ButtonsBar>
				}
			>
				<P>Create new template by using this event.</P>
				<Select
					placeholder="Select an Event"
					style={{ width: "100%" }}
					onChange={this.onChange}
					popupMatchSelectWidth={false}
					options={eventOptions}
				/>
			</Modal>
		);
	}
}
