import {
	GET_DASHBOARD_METRICS_ERROR,
	GET_DASHBOARD_METRICS_REQUEST,
	GET_DASHBOARD_METRICS_SUCCESS,
	GET_DASHBOARD_OVERVIEW_ERROR,
	GET_DASHBOARD_OVERVIEW_REQUEST,
	GET_DASHBOARD_OVERVIEW_SUCCESS,
	GET_DASHBOARD_SUMMARY_ERROR,
	GET_DASHBOARD_SUMMARY_REQUEST,
	GET_DASHBOARD_SUMMARY_SUCCESS,
	GET_HISTORICAL_SALES_ERROR,
	GET_HISTORICAL_SALES_REQUEST,
	GET_HISTORICAL_SALES_SUCCESS,
	GET_SALES_CHART_ERROR,
	GET_SALES_CHART_REQUEST,
	GET_SALES_CHART_SUCCESS,
	GET_USERS_CHART_ERROR,
	GET_USERS_CHART_REQUEST,
	GET_USERS_CHART_SUCCESS,
	UPDATE_DASHBOARD_CHANNEL,
	UPDATE_DASHBOARD_CUSTOM_DATE_RANGE,
	UPDATE_DASHBOARD_CUSTOM_DATE_RANGE_PICKED,
	UPDATE_DASHBOARD_PERIOD
} from "../actions/metricActions";

const initialState = {
	todaySales: 0,
	sales: { error: false, loading: false, data: [], totals: [] },
	revenueSales: { error: false, loading: false, data: [], totals: [] },
	users: { data: [], totals: [] },
	overview: {},
	summary: {},
	overviewByDate: { error: false, loading: false, overview: {} },
	charityDonation: [],
	eventOverview: {},
	loading: true,
	error: false,
	timeScale: "Today",
	period: "today",
	channel: "all",
	customDateRange: [],
	isCustomRangePicked: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_DASHBOARD_PERIOD: {
			return {
				...state,
				period: action.data.period
			};
		}

		case UPDATE_DASHBOARD_CHANNEL: {
			return {
				...state,
				channel: action.data.channel
			};
		}

		case UPDATE_DASHBOARD_CUSTOM_DATE_RANGE: {
			return {
				...state,
				customDateRange: action.data.customDateRange
			};
		}

		case UPDATE_DASHBOARD_CUSTOM_DATE_RANGE_PICKED: {
			return {
				...state,
				isCustomRangePicked: action.data.isCustomRangePicked
			};
		}

		case GET_DASHBOARD_SUMMARY_REQUEST: {
			return {
				...state,
				summary: {
					...state.summary,
					loading: true,
					error: false
				}
			};
		}

		case GET_DASHBOARD_SUMMARY_SUCCESS: {
			return {
				...state,
				summary: {
					loading: false,
					...action.data
				}
			};
		}

		case GET_DASHBOARD_SUMMARY_ERROR: {
			return {
				...state,
				summary: {
					loading: false,
					error: action.error
				}
			};
		}

		case GET_DASHBOARD_METRICS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_DASHBOARD_METRICS_SUCCESS:
			return {
				...state,
				loading: false,
				todaySales:
					action.data.sales && action.data.sales.totals && action.data.sales.totals.length > 0
						? action.data.sales.totals[0].value
						: 0,
				sales: { data: action.data.sales },
				revenueSales: { data: action.data.revenueSales },
				users: { data: action.data.users },
				charityDonation: action.data.charityDonation,
				eventOverview: action.data.eventOverview,
				overviewByDate: {
					overview: action.data.overviewByDate
				},
				totalEvents: action.data.totalEvents,
				totalPublishedEvents: action.data.totalPublishedEvents,
				totalFacebookEvents: action.data.totalFacebookEvents,
				totalPromotingProgresses: action.data.totalPromotingProgresses
			};
		case GET_DASHBOARD_METRICS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case GET_HISTORICAL_SALES_REQUEST:
			if (action.dashboard) {
				return {
					...state,
					sales: {
						loading: true,
						error: false
					}
				};
			}
			return state;

		case GET_HISTORICAL_SALES_SUCCESS:
			if (action.dashboard) {
				return {
					...state,
					sales: {
						loading: false,
						data: action.data
					}
				};
			}
			return state;

		case GET_HISTORICAL_SALES_ERROR:
			if (action.dashboard) {
				return {
					...state,
					sales: {
						loading: false,
						error: action.error
					}
				};
			}
			return state;

		case GET_USERS_CHART_REQUEST:
			return {
				...state,
				users: {
					loading: true,
					error: false
				}
			};
		case GET_USERS_CHART_SUCCESS:
			return {
				...state,
				users: {
					loading: false,
					data: action.data
				}
			};
		case GET_USERS_CHART_ERROR:
			return {
				...state,
				users: {
					loading: false,
					error: action.error
				}
			};

		case GET_SALES_CHART_REQUEST:
			if (action.dashboard) {
				return {
					...state,
					revenueSales: {
						loading: true,
						error: false
					}
				};
			}
			return state;

		case GET_SALES_CHART_SUCCESS:
			if (action.dashboard) {
				return {
					...state,
					revenueSales: {
						loading: false,
						data: action.data
					}
				};
			}
			return state;

		case GET_SALES_CHART_ERROR:
			if (action.dashboard) {
				return {
					...state,
					revenueSales: {
						loading: false,
						error: action.error
					}
				};
			}
			return state;

		case GET_DASHBOARD_OVERVIEW_REQUEST: {
			return {
				...state,
				overviewByDate: {
					loading: true,
					error: false
				}
			};
		}
		case GET_DASHBOARD_OVERVIEW_SUCCESS: {
			return {
				...state,
				overviewByDate: {
					loading: false,
					overview: action.data.overview
				}
			};
		}

		case GET_DASHBOARD_OVERVIEW_ERROR: {
			return {
				...state,
				overviewByDate: {
					loading: false,
					error: action.error
				}
			};
		}
		default:
			return state;
	}
};
