import Breadcrumb from "@/components/Breadcrumb";
import LoadErrorView from "@/components/LoadErrorView";
import Page from "@/components/Page";
import { getEventsById } from "@/state/actions/eventActions";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { notification } from "@/ui/antd";
import type { ScheduledReportDisplay } from "@hx/console/scheduledReports";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ScheduledReportForm } from "./Form";
import { SendTestEmailModal } from "./SendTestEmailModal";

export type SendModalData = Pick<ScheduledReportDisplay, "_id" | "eventIds" | "interval">;

export const ScheduledReport: FC = () => {
	const navigate = useNavigate();
	const params = useParams();

	const scheduledId = params?.scheduledId ?? "";
	const isNewSchedule = !!(scheduledId === "new");

	const disableQuery = isNewSchedule || !scheduledId;

	const { data, error, isLoading, refetch } = trpc.scheduledReports.getReport.useQuery(scheduledId, {
		enabled: !disableQuery
	});

	if (error) {
		notification.error({
			message: "Error",
			description: error.message ?? "Failed to load scheduled report"
		});
	}

	const title = isNewSchedule ? "New scheduled report" : "Edit scheduled report";

	const { scheduledReport } = data ?? {
		scheduledReport: { eventIds: [], interval: "daily", day: "1", hour: "16", emails: [""] }
	};
	const { eventIds } = scheduledReport ?? {};

	useEffect(() => {
		if (eventIds?.length) {
			getEventsById(eventIds, true);
		}
	}, [eventIds]);

	const [modalData, setModalData] = useState<SendModalData | null>(null);
	const [sendModalOpen, setSendModalOpen] = useState(false);

	const userEmail = useAppSelector((state) => state?.auth?.user?.email);

	return (
		<Page
			pageName={title}
			type="details"
			breadcrumb={<Breadcrumb prevPath="/console/reports/scheduled" prevTitle="Scheduled reports" currTitle={title} />}
		>
			<LoadErrorView
				loading={isLoading && !disableQuery}
				error={error ? { title: "Oops", message: error.message } : null}
				renderChildren={false}
				retryAction={refetch}
			>
				<ScheduledReportForm
					initialValues={scheduledReport}
					onSendModalOpened={({ _id, eventIds, interval }: SendModalData) => {
						setModalData({ _id, eventIds, interval });
						setSendModalOpen(true);
					}}
					onBackClicked={() => navigate("/console/reports/scheduled")}
					scheduledId={scheduledId}
				/>
			</LoadErrorView>
			<SendTestEmailModal
				isOpen={sendModalOpen}
				initialValues={{
					_id: isNewSchedule ? undefined : modalData?._id,
					eventIds: modalData?.eventIds,
					interval: modalData?.interval,
					email: userEmail
				}}
				onClose={() => setSendModalOpen(false)}
			/>
		</Page>
	);
};
