import { setUser as setUserInRedux } from "@/state/slices/auth";
import { notification } from "@/ui/antd";
import { CurrentUserSchema } from "@hx/console";
import { NavigateFunction } from "react-router";
import { AppDispatch } from "../store";
import { onUserLocationSet } from "./locationActions";

export const CLEAR_STATE = "CLEAR_STATE";

export const afterLoginActions = (navigate: NavigateFunction, from?: string | null): void => {
	const path = from && from !== "/console" ? from : "/console/dashboard";

	// allowing time for data-fetching and setting in redux state before redirecting
	setTimeout(() => navigate(path), 500);
};

export const afterSignupActions = (
	createdUser: CurrentUserSchema,
	dispatch: AppDispatch,
	navigate: NavigateFunction
): void => {
	notification.success({
		message: "Welcome",
		description: "Welcome to Humanitix!"
	});
	setUser(createdUser)(dispatch);
	setTimeout(() => {
		createdUser.purpose === "organiser"
			? navigate("/onboarding-organiser/1?newuser=true")
			: navigate("/console/dashboard?newuser=true");
	}, 100);
};

export const setUser = (user: CurrentUserSchema) => {
	return (dispatch: AppDispatch): void => {
		onUserLocationSet(user.location)(dispatch);
		dispatch(setUserInRedux(user));
	};
};
