import GlobalDiscountCodesService from "../../services/GlobalDiscountCodeService";
import { getLocationFromState } from "./locationActions";

import { notification } from "@/ui/antd";

const GET_DISCOUNT_CODES_REQUEST = "GET_DISCOUNT_CODES_REQUEST";
const GET_DISCOUNT_CODES_SUCCESS = "GET_DISCOUNT_CODES_SUCCESS";
const GET_DISCOUNT_CODES_ERROR = "GET_DISCOUNT_CODES_ERROR";

const SAVE_DISCOUNT_CODES_REQUEST = "SAVE_DISCOUNT_CODES_REQUEST";
const SAVE_DISCOUNT_CODES_SUCCESS = "SAVE_DISCOUNT_CODES_SUCCESS";
const SAVE_DISCOUNT_CODES_ERROR = "SAVE_DISCOUNT_CODES_ERROR";

const DELETE_DISCOUNT_CODES_REQUEST = "DELETE_DISCOUNT_CODES_REQUEST";
const DELETE_DISCOUNT_CODES_SUCCESS = "DELETE_DISCOUNT_CODES_SUCCESS";
const DELETE_DISCOUNT_CODES_ERROR = "DELETE_DISCOUNT_CODES_ERROR";

export const GET_DISCOUNT_CODE_REQUEST = "GET_DISCOUNT_CODE_REQUEST";
export const GET_DISCOUNT_CODE_SUCCESS = "GET_DISCOUNT_CODE_SUCCESS";
export const GET_DISCOUNT_CODE_ERROR = "GET_DISCOUNT_CODE_ERROR";

const DELETE_ALL_DISCOUNT_CODES_REQUEST = "DELETE_ALL_DISCOUNT_CODES_REQUEST";
const DELETE_ALL_DISCOUNT_CODES_SUCCESS = "DELETE_ALL_DISCOUNT_CODES_SUCCESS";
const DELETE_ALL_DISCOUNT_CODES_ERROR = "DELETE_ALL_DISCOUNT_CODES_ERROR";

export const EDIT_GLOBAL_DISCOUNT_CODE = "EDIT_GLOBAL_DISCOUNT_CODE";

export const editGlobalDiscountCode = (discountCode) => {
	return (dispatch) => {
		dispatch({ type: EDIT_GLOBAL_DISCOUNT_CODE, discountCode });
	};
};

export const getGlobalDiscountCodes = (userId, page) => {
	return (dispatch, getState) => {
		dispatch({ type: GET_DISCOUNT_CODES_REQUEST, page });
		const state = getState();
		const location = getLocationFromState(true);
		GlobalDiscountCodesService.find(userId, page, state.search.query, location)
			.then((result) => {
				dispatch({
					type: GET_DISCOUNT_CODES_SUCCESS,
					discountCodes: result.discountCodes,
					count: result.count
				});
			})
			.catch((err) => {
				dispatch({ type: GET_DISCOUNT_CODES_ERROR, error: err });
			});
	};
};

export const getGlobalDiscountCode = (userId, discountCodeId, location) => {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_DISCOUNT_CODE_REQUEST });
			const globalDiscountCode = await GlobalDiscountCodesService.get(userId, discountCodeId, location);
			dispatch({ type: GET_DISCOUNT_CODE_SUCCESS, globalDiscountCode });
		} catch (err) {
			dispatch({ type: GET_DISCOUNT_CODE_ERROR, error: err });
		}
	};
};

export const saveGlobalDiscountCodes = (userId, discountCode) => {
	return (dispatch, getState) => {
		dispatch({ type: SAVE_DISCOUNT_CODES_REQUEST });
		GlobalDiscountCodesService.save(userId, discountCode)
			.then((data) => {
				const state = getState();
				dispatch({ type: SAVE_DISCOUNT_CODES_SUCCESS });
				dispatch(getGlobalDiscountCodes(userId, state.discountCodes.page));
				let message = "Your discount code has been saved";
				if (!isNaN(data.count)) {
					message = `${data.count} discount codes have been added`;
				}
				notification.success({ message: "Success", description: message });
			})
			.catch((err) => {
				dispatch({ type: SAVE_DISCOUNT_CODES_ERROR, error: err });
				const message =
					err.response && err.response.status === 409 ? "Discount code already exists" : "Failed to save discount code";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const deleteGlobalDiscountCodes = (userId, discountCode) => {
	return (dispatch) => {
		dispatch({ type: DELETE_DISCOUNT_CODES_REQUEST });
		GlobalDiscountCodesService.delete(userId, discountCode)
			.then((discountCode) => {
				dispatch({
					type: DELETE_DISCOUNT_CODES_SUCCESS,
					discountCode
				});
				notification.success({
					message: "Success",
					description: "Your discount code has been deleted"
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_DISCOUNT_CODES_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to delete discount code`
				});
			});
	};
};

export const deleteAllGlobalDiscountCodes = (userId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELETE_ALL_DISCOUNT_CODES_REQUEST });
		GlobalDiscountCodesService.deleteAll(userId)
			.then(() => {
				dispatch({ type: DELETE_ALL_DISCOUNT_CODES_SUCCESS });
				const state = getState();
				dispatch(getGlobalDiscountCodes(userId, state.accessCodes.page));
				notification.success({
					message: "Success",
					description: "Your discount codes have been deleted"
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_ALL_DISCOUNT_CODES_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to delete all discount code`
				});
			});
	};
};
