import { connect } from "react-redux";
import { Field, FieldArray, change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, TextAreaField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { saveEvent } from "@/state/actions/eventActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import CustomInfo from "./CustomInfo";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "accessibilityInformation";

let Information = ({ handleSubmit, currentEvent, params, saveEvent, customInfo, dispatch }) => {
	const save = (values) => {
		const eventId = params?.eventId;
		saveEvent(eventId, { accessibility: values.accessibility });
	};
	const changeFieldValue = (field, value) => {
		dispatch(change(formName, field, value));
	};

	return (
		<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={save}>
			<H1>Accessibility Information</H1>
			<form onSubmit={handleSubmit(save)}>
				<ContentBlock>
					<Field
						name="accessibility.contactName"
						label="Contact Person"
						description="Who is best to contact on the day?"
						placeholder="Jim Smith"
						component={LegacyInputField}
						style={{ width: "50%", marginBottom: 12 }}
						rows={3}
					/>

					<Field
						name="accessibility.contactNumber"
						label="Contact Number"
						description="Best contact number on the day"
						placeholder="0498000000"
						component={LegacyInputField}
						style={{ width: "50%", marginBottom: 12 }}
						rows={3}
					/>

					<Field
						name="accessibility.travelInstructions"
						label="Travel Instructions"
						description="Describe how to get to the event by car and public transport"
						placeholder="Bus: The closet stop is on Park St. Bus stop number 6775, Train: We are 500 meters south on Park St from Part Station. Car: Please Drop off guests on the River Street Entry."
						component={TextAreaField}
						style={{ width: "100%", marginBottom: 12 }}
						rows={3}
					/>

					<Field
						name="accessibility.entryInstructions"
						label="Entry Instructions"
						description="Describe how to enter the event in detail"
						placeholder="Enter via the the large door next to seven eleven on the northern entry that has no stairs"
						component={TextAreaField}
						rows={3}
						style={{ marginBottom: 12 }}
					/>

					<Field
						name="accessibility.afterEntryInstructions"
						label="After Entry Instructions"
						description="Where do I go after I enter?"
						placeholder="Down the hall to the boardroom A on the left"
						component={TextAreaField}
						rows={3}
						style={{ marginBottom: 12 }}
					/>

					<Field
						name="accessibility.hazards"
						label="Hazards"
						description="What hazards could people with disabilities encounter at your event?"
						placeholder="Revolving doors, steps, building works, trip hazards"
						component={TextAreaField}
						rows={3}
						style={{ marginBottom: 12 }}
					/>

					<Field
						name="accessibility.toiletLocation"
						label="Toilet Directions"
						description="Directions to accessible toilets at your event"
						placeholder="On the right after entry by the cafe"
						component={TextAreaField}
						rows={3}
						style={{ marginBottom: 12 }}
					/>

					<Field
						name="accessibility.disabledParking"
						label="Accessible Parking"
						description="What accessible parking options are available and where are they"
						placeholder="5 spaces available in the north east car park"
						component={TextAreaField}
						rows={3}
						style={{ marginBottom: 12 }}
					/>
					<FieldArray
						name="accessibility.customInfo"
						customInfo={customInfo}
						component={CustomInfo}
						currentEvent={currentEvent}
						changeFieldValue={changeFieldValue}
					/>
				</ContentBlock>

				<FormBar formLayout="horizontal">
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						ariaLabel="Save"
						size="large"
					>
						Save
					</LegacyButton>
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

Information = reduxForm({
	enableReinitialize: true,
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(Information);

const selector = formValueSelector(formName);

export default connect(
	(state) => ({
		initialValues: state.currentEvent.event,
		currentEvent: state.currentEvent,
		customInfo: selector(state, "accessibility.customInfo")
	}),
	{ saveEvent }
)(withSaveCheck(withRouterHooks(Information), formName));
