import { getConfig } from "@/config";
import { dayjs } from "@/libs/dayjs";
import LocationService from "@/services/LocationService";
import { DatePicker } from "@/ui/antd";
import { HumaniDate } from "@hx/dates";
import InputWrapper from "./helpers/InputWrapper";

const customProps = ({ input: { onChange, value }, ...props }) => {
	if (value !== "") {
		value = dayjs(value);
	} else {
		value = null;
	}
	return {
		onChange: (date) => {
			const previousValue = (value ? dayjs(value) : dayjs()).tz(props.timezone || getConfig("TIMEZONE_DEFAULT"));
			let changeValue = "";

			if (date) {
				// If a new date is set, we want to keep the time the same as the previous value
				changeValue = dayjs(date)
					.tz(props.timezone || getConfig("TIMEZONE_DEFAULT"))
					.set("hour", previousValue.hour())
					.set("minute", previousValue.minute())
					.toISOString();
			}
			onChange(changeValue);
		},
		value,
		format: HumaniDate.formats.datePicker(LocationService.getLocation(true))
	};
};

const DateField = (props) => {
	props = {
		...props,
		...customProps(props)
	};

	const {
		input,
		label,
		description,
		inline,
		required,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		meta: { touched, error },
		style = {},
		onChange,
		timezone,
		value: _value,
		...rest
	} = props;

	const value = input.value ? dayjs(input.value) : null;

	const onDateChange = (date) => {
		if (date) {
			// Ant will return a date in the local timezone, so we need to convert it to the correct timezone
			// Eg, the event is in GMT-0300, click the 24th and ant will return midnight in the users local timezone
			// So we take that and convert it to GMT-0300. The time in UTC should be 24th 03:00 because that is midnight in GMT-0300
			const dateInEventTimezone = dayjs(date).tz(timezone || getConfig("TIMEZONE_DEFAULT"), true);
			onChange(dateInEventTimezone.toISOString());
		} else {
			onChange(null);
		}
	};

	const displayValue = value ? dayjs(value).tz(timezone || getConfig("TIMEZONE_DEFAULT")) : null;

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<DatePicker
				id={input.name}
				value={displayValue}
				onChange={onDateChange}
				{...rest}
				style={{ minWidth: 140, ...style }}
			/>
		</InputWrapper>
	);
};

export default DateField;
