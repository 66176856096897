import { Alert } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import Page from "@/components/Page";
import { getQrCodeAccess } from "@/state/actions/authorizationActions";

class AppAccess extends Component {
	componentWillMount() {
		const { currentEvent, getQrCodeAccess } = this.props;
		getQrCodeAccess(currentEvent.event._id);
	}

	render() {
		const { authorization } = this.props;
		const { error, loading, qr } = authorization;
		return (
			<Page pageName="App Access">
				<ContentBlock style={{ background: "#fff", padding: 14, margin: 0 }}>
					<LoadErrorView error={error} loading={loading}>
						<div>
							<img src={qr} style={{ margin: "0 auto", display: "block" }} alt="Quick Access QR Code" />
							<Alert
								message="Quick Access QR Code"
								description="Get your helpers to scan this QR code using the Humanitix App to gain quick access to scan attendees in for your event."
								type="info"
								showIcon
							/>
						</div>
					</LoadErrorView>
				</ContentBlock>
			</Page>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		authorization: state.authorization
	}),
	{ getQrCodeAccess }
)(AppAccess);
