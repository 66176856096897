import { notification } from "@/ui/antd";
import VirtualEventHubService from "../../services/VirtualEventHubService";

export const GET_VIRTUAL_EVENT_REQUEST = "GET_VIRTUAL_EVENT_REQUEST";
export const GET_VIRTUAL_EVENT_SUCCESS = "GET_VIRTUAL_EVENT_SUCCESS";
export const GET_VIRTUAL_EVENT_ERROR = "GET_VIRTUAL_EVENT_ERROR";

export const CREATE_VIRTUAL_EVENT_REQUEST = "CREATE_VIRTUAL_EVENT_REQUEST";
export const CREATE_VIRTUAL_EVENT_SUCCESS = "CREATE_VIRTUAL_EVENT_SUCCESS";
export const CREATE_VIRTUAL_EVENT_ERROR = "CREATE_VIRTUAL_EVENT_ERROR";

export const SAVE_VIRTUAL_EVENT_REQUEST = "SAVE_VIRTUAL_EVENT_REQUEST";
export const SAVE_VIRTUAL_EVENT_SUCCESS = "SAVE_VIRTUAL_EVENT_SUCCESS";
export const SAVE_VIRTUAL_EVENT_ERROR = "SAVE_VIRTUAL_EVENT_ERROR";

const GET_VIRTUAL_EVENT_PREVIEW_REQUEST = "GET_VIRTUAL_EVENT_PREVIEW_REQUEST";
const GET_VIRTUAL_EVENT_PREVIEW_SUCCESS = "GET_VIRTUAL_EVENT_PREVIEW_SUCCESS";
const GET_VIRTUAL_EVENT_PREVIEW_ERROR = "GET_VIRTUAL_EVENT_PREVIEW_ERROR";

export const OPEN_PREVIEW_MODAL = "OPEN_PREVIEW_MODAL";
export const CLOSE_PREVIEW_MODAL = "CLOSE_PREVIEW_MODAL";

export const OPEN_ENABLED_MODAL = "OPEN_ENABLED_MODAL";
export const CLOSE_ENABLED_MODAL = "CLOSE_ENABLED_MODAL";

export const CLEAR_VIRTUAL_EVENT = "CLEAR_VIRTUAL_EVENT";

export const clearVirtualEventHub = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_VIRTUAL_EVENT
		});
	};
};
export const openPreviewModal = () => {
	return (dispatch) => {
		dispatch({
			type: OPEN_PREVIEW_MODAL
		});
	};
};

export const closePreviewModal = () => {
	return (dispatch) => {
		dispatch({
			type: CLOSE_PREVIEW_MODAL
		});
	};
};

export const closeEnabledModal = () => {
	return (dispatch) => {
		dispatch({
			type: CLOSE_ENABLED_MODAL
		});
	};
};

export const previewVirtualEventHub = (eventId, data, location) => {
	return (dispatch) => {
		dispatch({ type: GET_VIRTUAL_EVENT_PREVIEW_REQUEST });
		VirtualEventHubService.savePreview(eventId, data)
			.then(({ previewId, appUrl, token }) => {
				dispatch({
					type: GET_VIRTUAL_EVENT_PREVIEW_SUCCESS
				});
				window.open(`${appUrl}${location.toLowerCase()}/preview/${previewId}?token=${token}`);
				dispatch(this.closePreviewModal);
			})
			.catch((err) => {
				dispatch({ type: GET_VIRTUAL_EVENT_PREVIEW_ERROR, error: err });
			});
	};
};

export const getVirtualEventHub = (eventId) => {
	return (dispatch) => {
		dispatch({ type: GET_VIRTUAL_EVENT_REQUEST });
		VirtualEventHubService.get(eventId)
			.then((virtualEventHub) => {
				dispatch({
					type: GET_VIRTUAL_EVENT_SUCCESS,
					virtualEventHub
				});
			})
			.catch((err) => {
				dispatch({ type: GET_VIRTUAL_EVENT_ERROR, error: err });
			});
	};
};

export const createVirtualEventHub = (eventId) => {
	return (dispatch) => {
		dispatch({ type: CREATE_VIRTUAL_EVENT_REQUEST });
		VirtualEventHubService.create(eventId)
			.then((virtualEventHub) => {
				dispatch({
					type: CREATE_VIRTUAL_EVENT_SUCCESS,
					virtualEventHub
				});
			})
			.catch((err) => {
				dispatch({ type: CREATE_VIRTUAL_EVENT_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to create virtual event hub`
				});
			});
	};
};

export const enableVirtualEventHub = (eventId) => {
	return (dispatch) => {
		dispatch({
			type: SAVE_VIRTUAL_EVENT_REQUEST
		});
		VirtualEventHubService.update(eventId, { enabled: true })
			.then((virtualEventHub) => {
				dispatch({
					type: SAVE_VIRTUAL_EVENT_SUCCESS,
					virtualEventHub
				});
				dispatch({
					type: OPEN_ENABLED_MODAL
				});
				notification.success({
					message: "Saved",
					description: `Virtual event hub has been enabled`
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_VIRTUAL_EVENT_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to enabled virtual event hub`
				});
			});
	};
};

export const saveVirtualEventHub = (eventId, virtualEventHubData) => {
	return (dispatch) => {
		dispatch({
			type: SAVE_VIRTUAL_EVENT_REQUEST,
			virtualEventHub: virtualEventHubData
		});
		VirtualEventHubService.update(eventId, virtualEventHubData)
			.then((virtualEventHub) => {
				dispatch({
					type: SAVE_VIRTUAL_EVENT_SUCCESS,
					virtualEventHub
				});
				notification.success({
					message: "Saved",
					description: `Virtual event hub has been updated`
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_VIRTUAL_EVENT_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to update virtual event hub`
				});
			});
	};
};
