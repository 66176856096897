const types = {
	selected: "#57cfc7",
	default: "#67717e"
};

/**
 * @param {{type: ("selected"|"default") }} config config for seating element svg
 */
const SeatingElement = ({ type = "default" }) => {
	const fill = types[type];
	return (
		<svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<desc>Created with Sketch.</desc>
			<defs />
			<g id="1.-Venue-map-builder_section_seating" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Show-ticket-types" transform="translate(-958.000000, -143.000000)">
					<g id="Seating" transform="translate(930.000000, 126.000000)">
						<g id="Group-9" transform="translate(26.000000, 14.000000)">
							<rect id="Rectangle-7" x="0" y="0" width="28" height="28" />
							<g id="ele-id-fill" transform="translate(2.000000, 3.000000)" fill={fill}>
								<g id="Icon-24px">
									<path
										d="M2.66666667,18 L2.66666667,21.5 L6.16666667,21.5 L6.16666667,18 L17.8333333,18 L17.8333333,21.5 L21.3333333,21.5 L21.3333333,14.5 L2.66666667,14.5 L2.66666667,18 Z M20.1666667,8.66666667 L23.6666667,8.66666667 L23.6666667,12.1666667 L20.1666667,12.1666667 L20.1666667,8.66666667 Z M0.333333333,8.66666667 L3.83333333,8.66666667 L3.83333333,12.1666667 L0.333333333,12.1666667 L0.333333333,8.66666667 Z M17.8333333,12.1666667 L6.16666667,12.1666667 L6.16666667,2.83333333 C6.16666667,1.55 7.21666667,0.5 8.5,0.5 L15.5,0.5 C16.7833333,0.5 17.8333333,1.55 17.8333333,2.83333333 L17.8333333,12.1666667 L17.8333333,12.1666667 Z"
										id="Shape"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default SeatingElement;
