import { CLOSE_PACKAGE_DETAILS, OPEN_PACKAGE_DETAILS } from "../actions/packagedTicketsActions";

const initialState = {
	detailsOpen: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case OPEN_PACKAGE_DETAILS: {
			return {
				...state,
				detailsOpen: true
			};
		}
		case CLOSE_PACKAGE_DETAILS: {
			return {
				...state,
				detailsOpen: false
			};
		}
		default:
			return state;
	}
};
