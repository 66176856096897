import React from "react";
import { SeatingMap } from "@hx/seating-map";
import { truncateString } from "@/utils/Format";

type TicketType = { _id: string; name: string; deleted: boolean; disabled: boolean; isDonation: boolean };

type TicketTypeProps = {
	event: {
		ticketTypes: TicketType[];
		packagedTickets: TicketType[];
	};
	display: boolean;
};
const convertToHexString = (num: number): string => {
	return ("000000" + num.toString(16)).substr(-6);
};

export default class TicketTypeToggle extends React.Component<TicketTypeProps, any> {
	constructor(props: TicketTypeProps) {
		super(props);
		this.state = {
			enabled: false
		};
	}
	getTicketTypeMap() {
		return this.props.event.ticketTypes
			.concat(this.props.event.packagedTickets)
			.filter((ticketType) => {
				return !ticketType.disabled && !ticketType.deleted && !ticketType.isDonation;
			})
			.reduce((map, ticketType) => {
				map[String(ticketType._id)] = truncateString(ticketType.name, 25);
				return map;
			}, {} as any);
	}

	render() {
		const display = this.props.display;
		return (
			<div
				style={{
					position: "absolute",
					right: 50,
					top: 25,
					display: display ? "flex" : "none",
					flexDirection: "column",
					flexWrap: "wrap-reverse"
				}}
			>
				<Legend
					ticketTypeColors={SeatingMap.getCurrentMap()?.getTicketTypeColorMap()}
					ticketTypes={this.getTicketTypeMap()}
				/>
			</div>
		);
	}
}

type BasicStringMatch = { [key: string]: string };
type BasicNumberMatch = { [key: string]: number };
type ColorRow = {
	name: string;
	color: number;
};
const Legend = ({
	ticketTypeColors,
	ticketTypes
}: {
	ticketTypeColors: BasicNumberMatch;
	ticketTypes: BasicStringMatch;
}) => {
	const colorRow: ColorRow[] = [];
	for (const key in ticketTypes) {
		const name = ticketTypes[key];
		const color = ticketTypeColors[key];
		colorRow.push({ name, color });
	}
	return (
		<ul
			style={{
				listStyleType: "none",
				background: "white",
				padding: 14,
				maxWidth: 300,
				marginTop: 8,
				borderRadius: 4,
				boxShadow: "0 1px 10px 0 rgba(32, 7, 64, 0.13)"
			}}
		>
			{colorRow.map(({ name, color }, i) => {
				return (
					<li
						style={{
							marginBottom: i === colorRow.length - 1 ? 0 : 12,
							display: "flex"
						}}
						key={`legend_${i}`}
					>
						<div
							style={{
								marginRight: 8,
								paddingTop: 2
							}}
						>
							<div
								style={{
									width: 14,
									height: 14,
									background: `#${convertToHexString(color)}`
								}}
							/>
						</div>
						<div style={{ fontSize: 12 }}>{name}</div>
					</li>
				);
			})}
		</ul>
	);
};
