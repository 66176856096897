import InfoTitle from "../components/table/InfoTitle";
import iconReference from "../services/IconReference";
import { currency } from "./Format";
const cog = iconReference["settings_white"];

export const getPriceRangeDisplay = (priceRange) => {
	if (!priceRange) {
		return "";
	}
	if (!priceRange.max && !priceRange.min) {
		return (
			<InfoTitle
				title="Any Amount"
				tooltip={
					<span>
						Customers will be able to enter the amount they wish to pay for this ticket. To restrict pricing options,
						click <img src={cog} alt="Cog Icon" />.
					</span>
				}
			/>
		);
	}
	if (!priceRange.min) {
		return `Up to ${currency(priceRange.max)}`;
	}
	if (!priceRange.max) {
		return `More than ${currency(priceRange.min)}`;
	}
	return `${currency(priceRange.min)} - ${currency(priceRange.max)}`;
};

export const getPriceOptionsDisplay = (priceOptions) => {
	if (!priceOptions) {
		return "";
	}
	const options = priceOptions.options || [];
	const values = options.map((opt) => Number(opt.value));
	const min = Math.min(...values);
	const max = Math.max(...values);
	if (max === min) {
		return `${currency(min)}`;
	}
	return `${currency(min)} - ${currency(max)}`;
};

export const getTicketDisplayName = (ticket) => {
	if (!ticket || !ticket.name) return "";
	if (ticket.disabled) return `Disabled: ${ticket.name}`;
	return ticket.name;
};
