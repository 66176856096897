import { css } from "@emotion/css";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import getVerifiedNotPublishedIcon from "@/assets/get_verified_not_published.svg";
import getVerifiedPublishedIcon from "@/assets/get_verified_published.svg";
import { Button } from "@/ui";
import { H2, P } from "@/components/text/Text";
import { closeVerifyAccountModal } from "@/state/actions/eventActions";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";

const wrapper = css({
	padding: "48px 60px",
	textAlign: "center",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	img: {
		marginBottom: 16
	},
	p: {
		marginBottom: 24
	},
	"@media(max-width: 758px)": {
		padding: "32px 12px",
		br: {
			display: "none"
		}
	}
});

class VerifyAccountModal extends Component {
	handleCloseModal = () => {
		this.props.closeVerifyAccountModal();
	};

	render() {
		const { visible, publishedLastOne } = this.props.verifyAccountModalOpen;

		const headerTextLine1 = publishedLastOne
			? "Well done on publishing another event!"
			: "Oops! Before you can publish more events, we need to verify you.";
		const headerTextLine2 = publishedLastOne ? "Please make sure to verify your account." : "";
		const description = publishedLastOne
			? "To continue publishing more events you will need to be verified by our team."
			: "Unfortunately, you won’t be able to publish any more events until we can verify your account.";
		return (
			<Modal open={visible} onCancel={this.handleCloseModal} footer={null} bodyStyle={{ padding: 0 }}>
				<div className={wrapper}>
					{publishedLastOne ? (
						<img src={getVerifiedPublishedIcon} alt="letter with a lock icon" />
					) : (
						<img src={getVerifiedNotPublishedIcon} alt="Lock with an exclamation mark" />
					)}

					<H2>
						{headerTextLine1}
						<br />
						{headerTextLine2}
					</H2>
					<P>{description}</P>
					<Button ariaLabel="Open Chat" type="secondary" onClick={OpenHelpHandler(HelpIds.verifyToPublish)}>
						Get verified
					</Button>
				</div>
			</Modal>
		);
	}
}

export default connect(
	(state) => ({
		verifyAccountModalOpen: state.currentEvent.verifyAccountModalOpen
	}),
	{
		closeVerifyAccountModal
	}
)(VerifyAccountModal);
