import { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Select } from "@/ui/antd";
import { getOrganiserMap, exitSeatingMap, changeDate } from "../state/actions/seatingMapActions";
import EditableName from "./EditableName";
import TabSwitch from "./TabSwitch";
import SeatingMapCapacityIssue from "../events/event/seatingMap/SeatingMapCapacityIssue";
import SaveCheckModal from "./SaveCheckModal";
import { formValueSelector } from "redux-form";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const TopBar = ({
	loading,
	onSwitch,
	view,
	seatingMapId,
	navigate,
	location,
	seatingConstructionInstance,
	seatingMap,
	currentEvent,
	seatingMapUndo,
	getOrganiserMap,
	exitSeatingMap,
	changeDate,
	openManageCapacityModal,
	handleSavingSeatingMap,
	setSeatingMapCapacity,
	setShowSaveCheckModal,
	seatingMapCapacity,
	isSaveCheckModalVisible,
	manageCapacityFormValues
}) => {
	const { shouldShowManageCapacity } = location?.state ?? {};

	const isManagerView = view === "managerView";

	useEffect(() => {
		if (!loading) {
			seatingConstructionInstance.listenOnCapacityChange(onCapacityChange);
			setSeatingMapCapacity(seatingConstructionInstance.capacity);
			if (shouldShowManageCapacity) {
				openManageCapacityModal();
			}
		}
	}, [seatingConstructionInstance]);

	const onCapacityChange = (amount) => {
		setSeatingMapCapacity(amount);
	};

	const onExit = () => {
		exitSeatingMap(String(currentEvent.event._id), navigate);
	};

	const handleDiscardChanges = () => {
		while (seatingMapUndo[seatingMapUndo.view].undoActions.length() > 0) {
			seatingMapUndo[seatingMapUndo.view].undoActions.pop();
		}
		onExit();
	};

	const onClose = () => {
		const hasUnsavedChanges = seatingMapUndo[seatingMapUndo.view].undoActions.length() > 0;
		if (hasUnsavedChanges) {
			setShowSaveCheckModal(true);
		} else {
			onExit();
		}
	};

	const loadOrg = () => {
		const date = seatingMap.organiser.date;
		const validDates = currentEvent.event.dates.filter((date) => !date.disabled && !date.deleted);
		getOrganiserMap(currentEvent.event._id, validDates[date]._id, seatingMapId);
	};

	const getDatePicker = () => {
		const validDates = currentEvent.event.dates.filter((date) => !date.disabled && !date.deleted);
		const dateOptions = validDates.map((date, i) => {
			return {
				label: date?.display?.combined,
				value: i
			};
		});
		if (dateOptions.length === 1) {
			return null;
		}
		return (
			<Select
				defaultValue={0}
				popupMatchSelectWidth={false}
				style={{ width: 200, float: "right", marginRight: 50 }}
				onChange={(val) => changeDate(val, validDates, currentEvent.event._id, seatingMap.seatingMap._id)}
				options={dateOptions}
			/>
		);
	};

	return (
		<div
			style={{
				zIndex: 2,
				width: "100%",
				minHeight: 119,
				background: "white",
				unselectable: "on",
				verticalAlign: "top",
				WebkitTouchCallout: "none",
				WebkitUserSelect: "none",
				KhtmlUserSelect: "none",
				MozUserSelect: "none",
				MsUserSelect: "none",
				userSelect: "none",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<div style={{ display: "flex" }}>
				<div style={{ paddingLeft: 50, paddingTop: 20, flexGrow: 1 }}>
					{!loading && seatingMap ? (
						<div style={{ display: "flex" }}>
							<EditableName />
							<SeatingMapCapacityIssue
								ticketTypes={manageCapacityFormValues.ticketTypes.filter((t) => t.mappedSeats > 0)}
								packagedTickets={manageCapacityFormValues.packagedTickets.filter((t) => t.mappedTables > 0)}
								isThereAnyUnMappedSeats={isThereAnyUnmappedSeatInSeatingMap(
									seatingMap?.seatingMap?.seatingMapData?.seatingConstruction?.sections ?? []
								)}
								style={{ alignItems: "center" }}
								onShowManageCapacity={() => openManageCapacityModal()}
								onNavigateToMappingTab={() => {
									//todo - take to mapping tab
								}}
							/>
						</div>
					) : null}
					<div
						style={{
							float: "left",
							display: "block",
							overflow: "auto",
							paddingTop: 10
						}}
					>
						<h4>{`Total Event Capacity: ${currentEvent.event.totalCapacity} / Total Map Capacity: ${seatingMapCapacity}`}</h4>
					</div>
				</div>
				<div style={{ display: "flex", marginTop: 28, marginRight: 50 }}>
					<Button
						style={{
							width: 100,
							height: 40,
							marginRight: 8
						}}
						type="secondary"
						size="large"
						onClick={onClose}
						ariaLabel="Close"
					>
						Close
					</Button>
					{!isManagerView ? (
						<Button
							style={{
								width: 100,
								height: 40
							}}
							type="primary"
							size="large"
							loading={seatingMap.loading}
							onClick={handleSavingSeatingMap}
							ariaLabel="Save"
						>
							Save
						</Button>
					) : null}
				</div>
				{isManagerView ? getDatePicker() : null}
				<SaveCheckModal
					visible={isSaveCheckModalVisible}
					loading={loading}
					handleOk={handleSavingSeatingMap}
					handleDiscard={handleDiscardChanges}
					handleCancel={() => setShowSaveCheckModal(false)}
				/>
			</div>
			<div
				style={{
					background: "#f9f9fa",
					height: 40,
					width: "100%",
					display: "block",
					boxShadow: "0 0 1px 0 #F1F1F1, 0 2px 3px 0 rgba(32, 7, 64, 0.13)"
				}}
			>
				<TabSwitch
					saving={seatingMap.loading}
					error={seatingMap.error}
					loading={loading}
					onSwitch={onSwitch}
					seatingMapCapacity={seatingMapCapacity}
					onSave={handleSavingSeatingMap}
					loadOrg={loadOrg}
					seatingMapUndo={seatingMapUndo}
					location={location}
					view={view}
					isManagerView={isManagerView}
				/>
			</div>
		</div>
	);
};

function isThereAnyUnmappedSeatInSeatingMap(seatingSections) {
	for (const section of seatingSections) {
		for (const table of section.tables) {
			const seatFound = table.seats.some((seat) => {
				if (!seat.disabled && !seat.disableInteraction && seat.ticketIds.length === 0) {
					return true;
				}
				return false;
			});
			if (seatFound) {
				return true;
			}
		}
	}
	return false;
}

const formName = "manageMappedCapacity";
const selector = formValueSelector(formName);

export default connect(
	(state) => ({
		seatingMap: state.seatingMap,
		currentEvent: state.currentEvent,
		seatingMapUndo: state.seatingMapUndo,
		manageCapacityFormValues: selector(state, "ticketTypes", "packagedTickets")
	}),
	{
		getOrganiserMap,
		exitSeatingMap,
		changeDate
	}
)(withRouterHooks(TopBar));
