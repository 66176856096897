import { DateFormats, HumaniDate } from "@hx/dates";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import { CheckboxField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import LocationService from "@/services/LocationService";
import { getUserActivity, getUserActivityCsv, resetUserActivity } from "@/state/actions/adminReports/userActions";
import DatesSelector from "../DatesSelector";

const formName = "userActivity";

class Activity extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getUserActivity } = this.props;
		getUserActivity(values.startDate, values.endDate, values.published, 1);
	}

	loadPage(page) {
		const { getUserActivity, startDate, endDate, published } = this.props;
		getUserActivity(startDate, endDate, published, page);
	}

	retry() {
		const { resetUserActivity } = this.props;
		resetUserActivity();
	}

	downloadCsv() {
		const { startDate, endDate, published, getUserActivityCsv } = this.props;
		if (!startDate || !endDate) {
			return;
		}
		getUserActivityCsv(startDate, endDate, published);
	}
	componentDidUpdate(preProps) {
		const props = ["startDate", "endDate", "published"];
		const changed = props.reduce((changed, prop) => {
			if (this.props[prop] !== preProps[prop]) {
				return true;
			}
			return changed;
		}, false);
		if (changed) {
			this.runLoad();
		}
	}

	runLoad = () => {
		const { startDate, endDate, published } = this.props;
		this.load({ startDate, endDate, published });
	};

	render() {
		const { touch, handleSubmit, userActivity } = this.props;
		const columns = [
			{
				title: "User",
				dataIndex: "id",
				key: "id",
				render: (id, record) => (
					<Link to={`/console/admin/users/${id}`}>
						{record.firstName} {record.lastName}
					</Link>
				)
			},
			{
				title: "Created at",
				dataIndex: "createdAt",
				key: "createdAt",
				render: (date) =>
					new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.date(DateFormats.Full)
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Mobile",
				dataIndex: "mobile",
				key: "mobile"
			},
			{
				title: "Published",
				dataIndex: "publishedCount",
				key: "publishedCount"
			}
		];
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Users Activity">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<div>
					<LoadErrorView loading={userActivity.loading} error={userActivity.error} retryAction={this.retry}>
						<ReportFilters>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<form>
									<div style={{ display: "flex" }}>
										<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />

										<Field
											label="Has published events"
											name="published"
											id="published"
											component={CheckboxField}
											type="checkbox"
										/>
									</div>
								</form>
								<div style={{ width: 60 }}>
									{userActivity.data ? (
										<IconButton
											icon="export"
											type="background"
											showTooltip
											tooltip="Export"
											onClick={this.downloadCsv}
											ariaLabel="Export"
										/>
									) : null}
								</div>
							</div>
						</ReportFilters>
						<ReportContent>
							{userActivity.data ? (
								<div>
									<Table
										columns={columns}
										dataSource={userActivity.data}
										rowKey="_id"
										scroll={{ x: 800 }}
										pagination={{
											showSizeChanger: false,
											pageSize: 30,
											total: userActivity.count,
											current: userActivity.page,
											onChange: (page) => this.loadPage(page)
										}}
									/>
									<ExportBar buttonText="Export" buttonClick={this.downloadCsv} />
								</div>
							) : null}
						</ReportContent>
					</LoadErrorView>
				</div>
			</form>
		);
	}
}

const selector = formValueSelector(formName);
Activity = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(Activity);

export default connect(
	(state) => ({
		userActivity: state.adminReports.users.userActivity,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		published: selector(state, "published")
	}),
	{ getUserActivity, resetUserActivity, getUserActivityCsv }
)(Activity);
