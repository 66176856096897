import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import TableActions from "@/components/table/TableActions";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { saveEvent } from "@/state/actions/eventActions";
import { Button, CopyField } from "@/ui";
import { Flex, Table, Tooltip } from "@/ui/antd";
import { urlSafe } from "@/utils/Format";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	})
};

class AffiliateTracking extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}

	save(values) {
		const { params, saveEvent } = this.props;
		const eventId = params?.eventId;
		saveEvent(eventId, { affiliateCodes: values.affiliateCodes });
	}

	render() {
		const { handleSubmit, currentEvent, affiliateCodes } = this.props;
		return (
			<form onSubmit={handleSubmit(this.save)}>
				<PageHeading
					title="Affiliate tracking"
					helpButton={{
						link: HelpIds.affiliateTracking,
						title: "Help - Affiliate tracking"
					}}
				/>
				<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
					<ContentBlock>
						<P>
							You can now track where your orders are coming from using affiliate tracking codes. Create unique URL
							links below and simply pass it to your promoters & affiliates for them to plug on their website, facebook
							pages, email campaigns and anywhere else they are advertising your event. Every time an order is processed
							using the affiliate link the code will show in your reporting so you can accurately track the promoters
							contribution.
						</P>
						<FieldArray
							name="affiliateCodes"
							component={CodeTable}
							affiliateCodes={affiliateCodes}
							currentEvent={currentEvent}
						/>
					</ContentBlock>
					<FormBar formLayout="horizontal">
						<Flex justify="flex-end">
							<Button aria-label="Save" htmlType="submit" variant="primary">
								Save
							</Button>
						</Flex>
					</FormBar>
				</LoadErrorView>
			</form>
		);
	}
}

class CodeTable extends Component {
	codeRequired = required("Code required");

	render() {
		const { fields, affiliateCodes, currentEvent } = this.props;
		let codes = affiliateCodes ? affiliateCodes : [];

		// add a code index
		codes = codes.map((c, index) => {
			c.key = index;
			return c;
		});
		const TICKETING_URL = getConfig("TICKETING_URL");
		const columns = [
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Code <span className={styles.required}> (required)</span>
					</span>
				),
				dataIndex: "code",
				key: "code",
				width: 150,
				render: (item, data, index) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									minWidth: "30vw",
									marginRight: 10,
									verticalAlign: "top"
								}
							})}
						>
							<Field
								name={`affiliateCodes[${index}].code`}
								placeholder="Code"
								component={LegacyInputField}
								validate={this.codeRequired}
								style={{ borderRadius: "var(--rounded-sm)", maxWidth: 150, width: 150 }}
								normalize={urlSafe}
							/>
						</div>
					);
				}
			},
			{
				title: "Link",
				dataIndex: "link",
				key: "link",
				render: (item, data, index) => {
					const affiliateCode = currentEvent.event.affiliateCodes[index];
					const code = data.code ? data.code.toLowerCase() : false;
					const hasLink = affiliateCode && affiliateCode._id;
					const link = hasLink ? `${TICKETING_URL}/${currentEvent.event.slug}?c=${code}` : "Created on save";

					return <CopyField disabled={!hasLink} displayValue={link} />;
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				width: 80,
				fixed: "right",
				render: (item, data, index) => (
					<TableActions>
						<Tooltip title="Delete">
							<Button iconOnly onClick={() => fields.remove(index)} variant="text">
								<DeleteOutlined />
							</Button>
						</Tooltip>
					</TableActions>
				)
			}
		];

		return (
			<If condition={!codes || !codes.length}>
				<Then>
					<NoResults
						title="You have no affiliate codes"
						message=""
						action={() => fields.push({ code: "" })}
						actionTxt="Create code"
						inverse
					/>
				</Then>
				<Else>
					<div style={{ overflowX: "auto" }}>
						<Table
							className={css({ ".ant-table-cell": { verticalAlign: "top" } })}
							columns={columns}
							dataSource={codes}
							pagination={false}
							rowKey="key"
							scroll={{ x: 800 }}
						/>
						<ButtonsBar>
							<Button iconLeft={<PlusOutlined />} onClick={() => fields.push({ code: "" })} variant="tertiary">
								Add code
							</Button>
						</ButtonsBar>
					</div>
				</Else>
			</If>
		);
	}
}
const formName = "affiliateCodes";

AffiliateTracking = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(AffiliateTracking);

const selector = formValueSelector(formName);
export default connect(
	(state) => {
		const event = state.currentEvent.event;
		event.affiliateCodes = event.affiliateCodes ? event.affiliateCodes : [];
		return {
			initialValues: event,
			currentEvent: state.currentEvent,
			affiliateCodes: selector(state, "affiliateCodes")
		};
	},
	{ saveEvent }
)(withRouterHooks(AffiliateTracking));
