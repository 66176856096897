import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import AccessCodeModal from "@/events/event/accessCodes/AccessCodeModal";
import {
	closeAccessModal,
	deleteAllAccessCodes,
	getAccessCodes,
	getAccessCodesCsv,
	openAccessModal
} from "@/state/actions/accessCodesActions";
import { updateSearch } from "@/state/actions/searchActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { confirm } from "@/ui/antd";
import { HelpIds } from "@/utils/Help";
import { useParams } from "react-router";
import { AccessCodesTable } from "./AccessCodesTable";

export const AccessCodes = () => {
	const params = useParams();
	const accessCodes = useAppSelector((state) => state.accessCodes);
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const dispatch = useAppDispatch();

	const eventId = params?.eventId;

	const load = (page = 1) => {
		dispatch(getAccessCodes(eventId, page));
	};

	const loadPage = (page: number) => {
		dispatch(updateSearch({ page }, true));
	};

	const closeModal = () => {
		dispatch(closeAccessModal());
	};

	const confirmDeleteAll = () => {
		confirm({
			title: "Delete All?",
			content: "Are you sure you wish to delete all your access codes?",
			onOk: () => {
				if (!currentEvent.event) return null;
				dispatch(deleteAllAccessCodes(currentEvent.event._id));
			},
			onCancel: () => null
		});
	};

	const downloadCsv = () => {
		if (!currentEvent.event) return null;
		dispatch(getAccessCodesCsv(currentEvent.event._id));
	};

	const updateRefinements = (sortOrder: string) => {
		dispatch(updateSearch({ sortOrder }, true));
	};

	const createCode = () => {
		dispatch(openAccessModal());
	};

	const modalOpen = (accessCodes as any).modalOpen;

	return (
		<LoadErrorView error={accessCodes.getRequest.error} loading={false}>
			<PageHeading
				title="Access codes"
				helpButton={{
					link: HelpIds.accessCodes,
					title: "Help - Access codes"
				}}
			/>
			<ContentBlock>
				<AccessCodesTable
					confirmDeleteAll={confirmDeleteAll}
					createCode={createCode}
					downloadCsv={downloadCsv}
					load={load}
					loadPage={loadPage}
					updateRefinements={updateRefinements}
				/>
			</ContentBlock>
			{modalOpen ? <AccessCodeModal visible={modalOpen} handleCancel={closeModal} /> : null}
		</LoadErrorView>
	);
};
