import { css } from "@emotion/css";
import { Table } from "@/ui/antd";
import { Field } from "redux-form";
import { InputNumberField } from "@/components/Fields";
import { H3 } from "@/components/text/Text";
import { currency } from "@/utils/Format";

const tableWrapper = css`
	margin-bottom: 12px;
	.ant-form-item {
		margin-bottom: 0 !important;
	}
`;

const DonationTable = ({
	clientDonationV2,
	clientDonationTicketType,
	fundsAvailable,
	clientDonationRefund,
	maximumRefund
}) => {
	if (!clientDonationV2) return null;
	const columns = [
		{
			title: "Donation name",
			dataIndex: "name",
			key: "donationName",
			fixed: "left",
			render: () => clientDonationTicketType?.name
		},
		{
			title: "Remaining",
			key: "merchandisePrice",
			fixed: "right",
			render: () => currency(fundsAvailable - clientDonationRefund)
		},
		{
			title: "Refund amount",
			fixed: "right",
			key: "refundAmount",
			render: () => {
				return (
					<Field
						id="clientDonation"
						name="clientDonation"
						component={InputNumberField}
						precision={2}
						min={0}
						max={Math.min(fundsAvailable, maximumRefund + clientDonationRefund) || 0}
						disabled={fundsAvailable === 0}
					/>
				);
			}
		}
	];
	return (
		<div className={tableWrapper}>
			<H3>Donation</H3>
			<Table dataSource={[{ name: "N/A" }]} columns={columns} pagination={false} />
		</div>
	);
};

export default DonationTable;
