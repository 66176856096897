import { fullName } from "@/utils/Format";
import { onConversationsAPIReady } from "@/utils/Hubspot";
import { ConsoleAction, identify, trackConsoleAction } from "@hx/analytics";

export const initUserGuiding = ({
	id,
	createdAt,
	location
}: {
	id: string;
	createdAt: string;
	location: string;
}): void => {
	try {
		window.userGuiding.identify(id, {
			created_at: createdAt,
			location: location
		});
	} catch (err) {
		return;
	}
};

export const initIntercom = ({
	assumingIdentity,
	firstName,
	lastName,
	email,
	intercomHash,
	createdAt,
	verified
}: {
	assumingIdentity?: boolean;
	firstName: string;
	lastName: string;
	email: string;
	intercomHash: string;
	createdAt: string;
	verified: boolean;
}): void => {
	try {
		if (assumingIdentity) {
			window.Intercom("shutdown");
		} else {
			window.Intercom("boot", {
				api_base: "https://api-iam.intercom.io",
				app_id: "uj6ijwgj",
				name: fullName(firstName, lastName),
				email: email,
				user_hash: intercomHash,
				created_at: createdAt,
				"User Verified": verified
			});
		}
	} catch (err) {
		return;
	}
};

export const trackLogin = ({ id, email, location }: { id: string; email: string; location: string }): void => {
	// re identify the user so events are linked
	identify({
		email: email,
		location: location.toUpperCase()
	});
	trackConsoleAction(ConsoleAction.hostLogin, {
		userId: id,
		location: location.toUpperCase()
	});
};

export const initConversationsApi = ({ email }: { email: string }): void => onConversationsAPIReady(email);
