import styled from "@emotion/styled";
import { Row, RowProps } from "@/ui/antd";
import { FC, ReactNode } from "react";

const StyledRow = styled(Row, {
	shouldForwardProp: (prop) => prop !== "noMargin"
})<{
	noMargin?: boolean;
}>`
	gap: 32px;
	margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 16)}px;
`;

type FormItemRowProps = RowProps & {
	children: ReactNode;
	noMargin?: boolean;
};

export const FormItemRow: FC<FormItemRowProps> = ({ children, noMargin = false, ...props }) => {
	return (
		<StyledRow align="middle" justify="space-between" noMargin={noMargin} wrap={false} {...props}>
			{children}
		</StyledRow>
	);
};
