import humanitixLogo from "@/assets/humanitix_logo.svg";

import { LegacyButton } from "@/components/buttons/LegacyButton";
import PaymentInfo from "@/events/event/payments/components/gateways/PaymentInfo";
import { FC } from "react";

type HumanitixPaymentsProps = {
	allowed: boolean;
	gatewaysLocked: boolean;
	paymentProcessed: boolean;
	selectPaymentMethod: (method: string) => void;
	selected: string;
};

const HumanitixPayments: FC<HumanitixPaymentsProps> = ({
	selected,
	allowed,
	gatewaysLocked,
	paymentProcessed,
	selectPaymentMethod
}) => {
	if (!allowed) {
		return null;
	}

	return (
		<PaymentInfo
			description={
				<div>
					Humanitix merchant account allows you to process payments instantly. Funds will be transferred to your account
					5 days after your event.
				</div>
			}
			id="humanitix"
			image={humanitixLogo}
			imageSubtitle="payments"
			name="Humanitix Payments Processing"
			selected={selected}
		>
			{selected === "humanitix" ? null : (
				<LegacyButton
					ariaLabel="Select Humanitix Payments"
					disabled={selected === "humanitix" || paymentProcessed || gatewaysLocked}
					onClick={() => selectPaymentMethod("humanitix")}
					style={{ margin: "auto", maxWidth: "240px", width: "100%" }}
					type="primary"
				>
					Select Humanitix payments
				</LegacyButton>
			)}
		</PaymentInfo>
	);
};

export default HumanitixPayments;
