import { css } from "@emotion/css";
import { Col, Row } from "@/ui/antd";
import { Field, change } from "redux-form";
import { ColourField, ImageUploadField, RadioField, SelectField, SliderField } from "../../components/Fields";
import InfoIcon from "../../components/InfoIcon";
import { getTextColor } from "../../utils/Colours";
import { getLocalisedText, sentenceCase } from "../../utils/Format";
import MiniPreview from "./MiniPreview";

const styles = {
	colorPickerWithLessMarginWrapper: css`
		margin-bottom: 7px;
		& div {
			margin-bottom: 0px !important;
		}
	`,
	hiddenHr: css`
		display: none;
		@media (max-width: 600px) {
			display: block;
		}
	`
};
/**
 * Provides custom theme setup for users.
 *
 * @param {object} props
 * @returns custom theme options
 */
const Custom = (props) => {
	const { previewTheme, dispatch, formName } = props;
	const colourText = getLocalisedText("colour");
	const updatePrimaryTextColor = (e, primaryColor) => {
		const primaryTextColor = getTextColor(primaryColor);
		dispatch(change(formName, "theme.primaryTextColor", primaryTextColor));
	};
	const updateBackgroundTextColor = (e, backgroundColor) => {
		const backgroundTextColor = getTextColor(backgroundColor);
		dispatch(change(formName, "theme.backgroundTextColor", backgroundTextColor));
	};
	const updateFooterTextColor = (e, footerColor) => {
		const footerTextColor = getTextColor(footerColor);
		const footerLogoColor = footerTextColor === "#FFFFFF" ? "white" : "black";
		dispatch(change(formName, "theme.footerTextColor", footerTextColor));
		dispatch(change(formName, "theme.footerLogoColor", footerLogoColor));
	};
	const selectEventCardColor = (e) => {
		const color = e.target.value;
		const titleTextColor = color === "#2d0e54" || color === "#1d1d1d" ? "#ffffff" : "#323232";
		dispatch(change(formName, "theme.titleTextColor", titleTextColor));
		dispatch(change(formName, `theme.titleBackgroundColor`, color));
		dispatch(change(formName, `theme.eventCardBackgroundColor`, color));
	};
	const leftCol = css``;
	const RadioOptions = [
		{
			value: "image",
			label: "Image"
		},
		{
			value: "color",
			label: sentenceCase(colourText)
		},
		{
			value: "pattern",
			label: "Pattern"
		}
	];
	const ticketButtonTextOptions = [
		{
			value: "Get Tickets",
			label: "Get Tickets"
		},
		{
			value: "Register",
			label: "Register"
		},
		{
			value: "Buy Tickets",
			label: "Buy Tickets"
		}
	];
	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className={leftCol}>
					<div style={{ marginTop: 16, marginBottom: 16 }}>
						<Field
							label={`Primary ${colourText}`}
							description={`Primary ${colourText}s change the ${colourText} of the buttons & navigation`}
							name="theme.primaryColor"
							component={ColourField}
							marginTop={4}
							onChange={updatePrimaryTextColor}
							style={{ width: 60, height: 36 }}
						/>
					</div>
					<div className={styles.colorPickerWithLessMarginWrapper}>
						<Field
							label={`Footer ${colourText}`}
							description={`Footer ${colourText}s  change the footer ${colourText}`}
							name="theme.footerColor"
							component={ColourField}
							marginTop={4}
							onChange={updateFooterTextColor}
						/>
					</div>
					<div style={{ marginBottom: 16 }}>
						<Field
							label={
								<div>
									Sponsors logos box background {colourText}
									<InfoIcon
										tooltip={`If you have uploaded sponsors logos on Settings page, you can customise background ${colourText} of the box`}
										height={28}
									/>
								</div>
							}
							description={`Choose background ${colourText} for sponsors logos box`}
							name="theme.sponsorBoxBackgroundColor"
							component={ColourField}
							marginTop={4}
						/>
					</div>
					<div style={{ marginBottom: 12 }}>
						<Field
							label="Page Background Style"
							description="Choose one of three options for page background styling"
							name="theme.background.type"
							component={RadioField}
							button
							options={RadioOptions}
						/>
					</div>
					{previewTheme &&
					previewTheme.background &&
					(previewTheme.background.type === "image" || previewTheme.background.type === "pattern") ? (
						<Field
							description="Choose image to"
							name="theme.background.image"
							uploadDescription="Used for background image"
							component={ImageUploadField}
							aspectRatio={1}
						/>
					) : (
						<Field
							description={`Choose the ${colourText}`}
							name="theme.background.color"
							component={ColourField}
							onChange={updateBackgroundTextColor}
						/>
					)}
					<hr style={{ borderBottom: "1px solid #dedede" }} />
					<div style={{ marginBottom: 16 }}>
						<Field
							label="Ticket Button Text"
							description="Primary button text for your events"
							name="theme.ticketButtonText"
							component={SelectField}
							options={ticketButtonTextOptions}
							style={{ maxWidth: 300, width: "100%" }}
						/>
					</div>
					<div>
						<Field
							label="Ticket Button Display"
							name="theme.ticketButtonDisplay"
							component={RadioField}
							vertical
							defaultValue="filled"
							options={[
								{
									label: "Filled button",
									value: "filled"
								},
								{
									label: "Outlined button",
									value: "outlined"
								}
							]}
							size="default"
						/>
					</div>
					<hr style={{ borderBottom: "1px solid #dedede" }} />
					<Field
						label={`Event cards & bars  ${colourText}`}
						name="theme.eventCardBackgroundColor"
						description={`Choose one of three  ${colourText}s for event cards, filter and navigation bar and tour title box`}
						component={RadioField}
						onChange={selectEventCardColor}
						vertical
						defaultValue="filled"
						options={[
							{
								label: (
									<div style={{ display: "inline-block", position: "absolute" }}>
										White (Default){" "}
										<div
											style={{
												display: "inline-block",
												width: 20,
												height: 20,
												borderRadius: 4,
												background: "#ffffff",
												border: "solid 1px #dedede",
												position: "absolute",
												right: -30,
												top: 5
											}}
										/>
									</div>
								),
								value: "#ffffff"
							},
							{
								label: (
									<div style={{ display: "inline-block", position: "absolute" }}>
										Black (The Dark Knight){" "}
										<div
											style={{
												display: "inline-block",
												width: 20,
												height: 20,
												borderRadius: 4,
												background: "#1d1d1d",
												position: "absolute",
												right: -30,
												top: 5
											}}
										/>
									</div>
								),
								value: "#1d1d1d"
							},
							{
								label: (
									<div style={{ display: "inline-block", position: "absolute" }}>
										Purple (Funky Plum){" "}
										<div
											style={{
												display: "inline-block",
												width: 20,
												height: 20,
												borderRadius: 4,
												background: "#2d0e54",
												position: "absolute",
												right: -30,
												top: 5
											}}
										/>
									</div>
								),
								value: "#2d0e54"
							}
						]}
						size="default"
					/>
					<Field
						label={
							<div>
								Event cards & bars opacity
								<InfoIcon tooltip="This feature is not 100% accessible because readability is reduced." height={28} />
							</div>
						}
						name="theme.cardAndBarOpacity"
						description="Add transparency to tour page, make event cards and bars see through"
						component={SliderField}
						min={50}
					/>
					<hr className={styles.hiddenHr} />
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<MiniPreview theme={previewTheme} />
				</Col>
			</Row>
		</div>
	);
};

export default Custom;
