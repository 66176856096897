import { InputNumberField, TextAreaField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { addSelfServicePayoutThreshold as addSelfServicePayoutThresholdToEvent } from "@/state/actions/eventActions";
import { addSelfServicePayoutThreshold as addSelfServicePayoutThresholdToUser } from "@/state/actions/userActions";
import { ReduxState } from "@/state/store";
import { Alert, Modal, Tooltip } from "@/ui/antd";
import { currencyFormat, currencyParse, percentFormat, percentParse } from "@/utils/InputFormatters";
import { required } from "@/utils/Validators";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

const FORM_NAME = "newSelfServiceThreshold";

type ICombinedProps = IProps & IInjectedFunctions & IInjectedProps;

const percentRequired = required("A percent is required");
const noteRequired = required("A note is required");
const nonNegativePercent = (value: number) => (value < 0 ? "Percent must be non-negative" : undefined);
const percentLessThan100 = (value: number) => (value > 100 ? "Percent must be less than 100" : undefined);
const amountNotLessThanZero = (value: number) => (value < 0 ? "Amount must be non-negative" : undefined);

const NewThreshold: React.FunctionComponent<ICombinedProps & InjectedFormProps<ThresholdData, ICombinedProps>> = (
	props
) => {
	const { handleSubmit, addSelfServicePayoutThresholdToUser, addSelfServicePayoutThresholdToEvent, userLocation } =
		props;

	const [isOpen, setIsOpen] = useState(false);
	const { hasAdminPermission } = useCurrentUser();

	const addNewThreshold = (values: ThresholdData) => {
		switch (props.level) {
			case "event":
				addSelfServicePayoutThresholdToEvent(props.eventId, values);
				break;
			case "user":
				addSelfServicePayoutThresholdToUser(props.userId, values, userLocation);
				break;
		}
		setIsOpen(false);
	};

	const hasSelfServePayoutPermissions = hasAdminPermission("selfServicePayouts");

	const newThresholdButton = (
		<LegacyButton type="primary" onClick={() => setIsOpen(true)} disabled={!hasSelfServePayoutPermissions}>
			New Threshold
		</LegacyButton>
	);

	return (
		<>
			{hasSelfServePayoutPermissions ? (
				newThresholdButton
			) : (
				<Tooltip title="You do not have permission to add a new threshold">
					<div>{newThresholdButton}</div>
				</Tooltip>
			)}
			<Modal
				open={isOpen}
				title={`Add a self-serve payout to this user’s ${props.level === "event" ? "event" : "account"}`}
				onCancel={() => setIsOpen(false)}
				onOk={handleSubmit((values) => addNewThreshold(values))}
				destroyOnClose
			>
				<Alert
					message="The existing threshold will be cancelled and overridden"
					showIcon
					type="info"
					style={{ margin: "0 0 12px 0" }}
				/>
				<form>
					<Field
						required
						label="Payout percent"
						name="percent"
						description="The percentage of earnings per event occurrence the user can self-serve payout"
						component={InputNumberField}
						precision={2}
						min={0}
						max={100}
						validate={[percentRequired, nonNegativePercent, percentLessThan100]}
						formatter={percentFormat}
						parser={percentParse}
					/>

					<Field
						label="Payout cap (optional)"
						description="Applies to the earnings on each event occurrence"
						name="cap"
						component={InputNumberField}
						precision={2}
						min={0}
						formatter={currencyFormat}
						parser={currencyParse}
						validate={amountNotLessThanZero}
					/>

					<Field name="note" label="Notes" component={TextAreaField} required validate={noteRequired} />
				</form>
			</Modal>
		</>
	);
};

interface ThresholdData {
	percent: number;
	cap?: number;
	note: string;
}

interface IEventLevelProps {
	level: "event";
	eventId: string;
}

interface IUserLevelProps {
	level: "user";
	userId: string;
}

type IProps = IUserLevelProps | IEventLevelProps;

interface IInjectedProps {
	userLocation: string;
}

interface IInjectedFunctions {
	addSelfServicePayoutThresholdToUser: (id: string, data: ThresholdData, location: string) => void;
	addSelfServicePayoutThresholdToEvent: (id: string, data: ThresholdData) => void;
}

export default connect(
	(state: Partial<ReduxState>) => ({
		userLocation: state.currentUser?.user?.location
	}),
	{ addSelfServicePayoutThresholdToUser, addSelfServicePayoutThresholdToEvent }
)(
	reduxForm<ThresholdData, ICombinedProps>({
		form: FORM_NAME,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(NewThreshold)
) as unknown as React.FunctionComponent<IProps>;
