import { connect } from "react-redux";
import { Field } from "redux-form";
import { TimeField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import { checkEndDate, checkStartDate, futureDateRequired } from "../dateFormUtils";
import DateRange from "./DateRange";

const startDateRequired = required("Start date is required");
const endDateRequired = required("End date is required");

const TimeRange = ({ touch, timezone, validatorDisable = [] }) => {
	const disabled = false;

	const startTimeRequiredChecks = [
		{
			key: "required",
			check: startDateRequired
		},
		{
			key: "checkDate",
			check: checkStartDate(touch)
		},
		{
			key: "future",
			check: futureDateRequired
		}
	]
		.filter(({ key }) => !validatorDisable.includes(key))
		.map(({ check }) => check);

	const endTimeRequiredChecks = [
		{
			key: "required",
			check: endDateRequired
		},
		{
			key: "checkDate",
			check: checkEndDate
		},
		{
			key: "future",
			check: futureDateRequired
		}
	]
		.filter(({ key }) => !validatorDisable.includes(key))
		.map(({ check }) => check);
	return (
		<div style={{ display: "flex", flexDirection: "row" }}>
			<DateRange touch={touch} validatorDisable={validatorDisable} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					minWidth: "calc(50% - 4px)"
				}}
			>
				<Field
					label="Start time"
					name="startDate"
					type="text"
					component={TimeField}
					validate={startTimeRequiredChecks}
					placeholder="Start Time"
					disabled={disabled}
					timezone={timezone}
					displayFormat="HH:mm"
					required
					style={{ minWidth: "100%" }}
				/>

				<Field
					label="End time"
					name="endDate"
					type="text"
					component={TimeField}
					validate={endTimeRequiredChecks}
					placeholder="End Time"
					disabled={disabled}
					timezone={timezone}
					displayFormat="HH:mm"
					required
					style={{ minWidth: "100%" }}
				/>
			</div>
		</div>
	);
};

export default connect((state) => {
	return {
		timezone: state.currentEvent.event.timezone
	};
}, {})(TimeRange);
