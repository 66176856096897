export const validate = (hub, ticketTypes, eventDates) => {
	// check availableFor
	if (!hub || !hub.settings || (!hub.settings.availableFor?.length && !hub.settings.datesAvailableFor?.length)) {
		return { valid: true, errors: [] };
	}
	const partsToCheck = hub.body.concat(hub.files).concat(hub.gallery);
	let errors = partsToCheck.reduce((errors, part) => {
		if (part) {
			const error = getAvailableForErrors(part.name, part.settings, hub.settings.availableFor, ticketTypes);
			if (error) {
				errors.push(error);
			}
			const datesError = getDatesAvailableForErrors(
				part.name,
				part.settings,
				hub.settings.datesAvailableFor,
				eventDates
			);
			if (datesError) {
				errors.push(datesError);
			}
		}
		return errors;
	}, []);
	return { valid: !errors.length, errors };
};

const getAvailableForErrors = (name, settings, hubAvailableFor, ticketTypes) => {
	const ticketTypesLookUp = ticketTypes.reduce((lookup, ticketType) => {
		lookup[ticketType._id] = ticketType;
		return lookup;
	}, {});
	const ticketErrors = settings.availableFor
		? settings.availableFor.reduce((ticketErrors, ticketId) => {
				if (hubAvailableFor?.length && hubAvailableFor.indexOf(ticketId) === -1) {
					const ticketType = ticketTypesLookUp[ticketId];
					ticketErrors.push(ticketType.name);
				}
				return ticketErrors;
		  }, [])
		: [];

	const pural = ticketErrors.length > 1 ? "s" : "";
	return ticketErrors.length
		? `${name} allows ticket${pural} ${ticketErrors.join(", ")} which are not allowed on the Virtual Event`
		: null;
};

const getDatesAvailableForErrors = (name, settings, hubDatesAvailableFor, eventDates) => {
	const datesLookUp = eventDates.reduce((lookup, date) => {
		lookup[date._id] = date;
		return lookup;
	}, {});
	const dateErrors = settings.datesAvailableFor
		? settings.datesAvailableFor.reduce((errors, eventDateId) => {
				if (hubDatesAvailableFor?.length && hubDatesAvailableFor.indexOf(eventDateId) === -1) {
					const date = datesLookUp[eventDateId];
					errors.push(date.display.combined);
				}
				return errors;
		  }, [])
		: [];

	const pural = dateErrors.length > 1 ? "s" : "";
	return dateErrors.length
		? `${name} allows ticket${pural} ${dateErrors.join(", ")} which are not allowed on the Virtual Event`
		: null;
};
