import { Modal } from "@/ui/antd";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import P from "@/components/text/P";
import Label from "@/components/text/Label";
import LinkButton from "@/components/buttons/LinkButton";

interface MappingErrorModalProps {
	showNotFullyMappedError: boolean;
	showMismatchError: boolean;
	isOpen: boolean;
	onConfirm: () => void;
	onNavigateToManageCapacity: () => void;
	onNavigateToMappingTab: () => void;
}

const MappingErrorModal = ({
	showNotFullyMappedError,
	showMismatchError,
	isOpen,
	onConfirm,
	onNavigateToManageCapacity,
	onNavigateToMappingTab
}: MappingErrorModalProps) => {
	return (
		<Modal
			open={isOpen}
			title="Mapping status"
			destroyOnClose
			onCancel={onConfirm}
			footer={
				<ButtonsBar footer style={{ flexDirection: "row-reverse" }}>
					<LegacyButton key="cancel" onClick={onConfirm} htmlType="button" ariaLabel="Got it">
						Got it
					</LegacyButton>
				</ButtonsBar>
			}
		>
			<>
				<P desktopMarginBottom={22}>
					The below issue{showNotFullyMappedError && showMismatchError ? "s" : ""} could lead to underselling your
					event:
				</P>

				<ul>
					{showNotFullyMappedError ? (
						<li>
							<Label>Not every seat is mapped to at least one ticket type.</Label>
							<P>
								{" "}
								<LinkButton
									ariaLabel="manage capacity link"
									onClick={onNavigateToMappingTab}
									style={{ textDecoration: "underline", padding: 0 }}
								>
									Review all seats
								</LinkButton>{" "}
								on map to ensure they aren't empty
							</P>
						</li>
					) : null}

					{showMismatchError ? (
						<li>
							<Label>Mapped seats don't align with ticket capacity settings. </Label>
							<P>
								Update in{" "}
								<LinkButton
									ariaLabel="manage capacity link"
									onClick={onNavigateToManageCapacity}
									style={{ textDecoration: "underline", padding: 0 }}
								>
									Manage capacity
								</LinkButton>
								.
							</P>
						</li>
					) : null}
				</ul>
			</>
		</Modal>
	);
};

export default MappingErrorModal;
