import { useAppSelector } from "@/state/hooks";
import { Form, FormInstance } from "@/ui/antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext, Location, NavigationType } from "react-router-dom";
import { isDirty } from "redux-form";

type NavigationTransaction = {
	action: NavigationType;
	location: Location;
	retry: () => void;
};

const useBlocker = (blocker: (tx: NavigationTransaction) => void, when = true) => {
	const { navigator } = useContext(NavigationContext);

	useEffect(() => {
		if (!when) return;
		const unblock = (navigator as any).block((tx: NavigationTransaction) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				}
			};
			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
};

export const usePromptRedux = (message: string, formName: string) => {
	const formHasChanged = useAppSelector((state) => isDirty(formName)(state));

	const blocker = useCallback(
		(tx: NavigationTransaction) => {
			if (window.confirm(message)) {
				tx.retry();
			}
		},
		[message]
	);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (formHasChanged) {
				event.returnValue = message; // For most browsers
				return message; // For some older browsers
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [formHasChanged]);

	useBlocker(blocker, formHasChanged);
};

export const usePrompt = (message: string, form: FormInstance) => {
	const [isFormDirty, setIsFormDirty] = useState(false);
	const fields = Form.useWatch([], form);

	useEffect(() => {
		const isFieldsTouched = form.isFieldsTouched(); // Check if any field has been touched
		setIsFormDirty(isFieldsTouched);
	}, [fields]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isFormDirty) {
				event.returnValue = message; // For most browsers
				return message; // For some older browsers
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [isFormDirty, message]);

	const blocker = useCallback(
		(tx: any) => {
			if (window.confirm(message)) {
				tx.retry();
			}
		},
		[message]
	);

	// Use the blocker to prevent navigation if form fields have been touched
	useBlocker(blocker, isFormDirty);

	// Provide a method to reset dirty state after form submission
	const resetFormDirty = () => {
		setIsFormDirty(false);
	};

	return { resetFormDirty };
};
