import { notification } from "@/ui/antd";
import EmailLogsService from "../../services/EmailLogsService";

export const EMAIL_SEARCH_REQUEST = "EMAIL_SEARCH_REQUEST";
export const EMAIL_SEARCH_SUCCESS = "EMAIL_SEARCH_SUCCESS";
export const EMAIL_SEARCH_ERROR = "EMAIL_SEARCH_ERROR";

export const EMAIL_FIND_REQUEST = "EMAIL_FIND_REQUEST";
export const EMAIL_FIND_SUCCESS = "EMAIL_FIND_SUCCESS";
export const EMAIL_FIND_ERROR = "EMAIL_FIND_ERROR";

export const EMAIL_RESEND_REQUEST = "EMAIL_RESEND_REQUEST";
export const EMAIL_RESEND_SUCCESS = "EMAIL_RESEND_SUCCESS";
export const EMAIL_RESEND_ERROR = "EMAIL_RESEND_ERROR";

export const EMAIL_LIST_RESEND_REQUEST = "EMAIL_LIST_RESEND_REQUEST";
export const EMAIL_LIST_RESEND_SUCCESS = "EMAIL_LIST_RESEND_SUCCESS";
export const EMAIL_LIST_RESEND_ERROR = "EMAIL_LIST_RESEND_ERROR";

export const getEmails = (page) => {
	return (dispatch, getState) => {
		const search = getState().search;
		dispatch({ type: EMAIL_SEARCH_REQUEST, page });
		EmailLogsService.get(search, page)
			.then((response) => {
				if (response && response.data && response.data.error) {
					dispatch({ type: EMAIL_SEARCH_ERROR, error: response.data.details });
				} else {
					dispatch({ type: EMAIL_SEARCH_SUCCESS, data: response.data });
				}
			})
			.catch(() => {
				dispatch({ type: EMAIL_SEARCH_ERROR });
			});
	};
};

export const getEmail = (id, emailType) => {
	return (dispatch) => {
		dispatch({ type: EMAIL_FIND_REQUEST });
		EmailLogsService.find(id, emailType)
			.then((response) => {
				dispatch({
					type: EMAIL_FIND_SUCCESS,
					log: response.data.log,
					email: response.data.email,
					template: response.data.template
				});
			})
			.catch(() => {
				dispatch({ type: EMAIL_FIND_ERROR });
			});
	};
};

export const resendListEmail = (id, emailType) => {
	return (dispatch) => {
		dispatch({ type: EMAIL_LIST_RESEND_REQUEST, id });
		EmailLogsService.resend(id, emailType)
			.then((response) => {
				console.log(response.data.resendResult);
				if (response.data.resendResult) {
					notification.success({
						message: "Success"
					});
				} else {
					notification.error({
						message: "Error",
						description: "Resend failed"
					});
				}
				dispatch({
					type: EMAIL_LIST_RESEND_SUCCESS,
					email: response.data.email
				});
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: "Something is broken"
				});
				dispatch({ type: EMAIL_LIST_RESEND_ERROR });
			});
	};
};

export const resendEmail = (id, emailType) => {
	return (dispatch) => {
		dispatch({ type: EMAIL_RESEND_REQUEST });
		EmailLogsService.resend(id, emailType)
			.then((response) => {
				if (response.data.resendResult) {
					notification.success({
						message: "Success"
					});
				} else {
					notification.error({
						message: "Error",
						description: "Resend failed"
					});
				}
				dispatch({ type: EMAIL_RESEND_SUCCESS, email: response.data.email });
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: "Somethings broken"
				});
				dispatch({ type: EMAIL_RESEND_ERROR });
			});
	};
};
