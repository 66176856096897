import * as React from "react";
import Label from "../components/Label";
import Slider from "../components/Slider";
import Store from "../utils/Store";

export default class RowModifier extends React.Component<{}, { active: boolean }> {
	constructor(props: {}) {
		super(props);
		this.state = {
			active: false
		};
	}

	render() {
		const curveScaleFactor = Store.getInteractionManager().getCurveScaleFactor();
		const skewScaleFactor = Store.getInteractionManager().getSkewScaleFactor();
		return (
			<div style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
				<div
					style={{
						padding: "8px 12px",
						backgroundColor: "#4e4757",
						borderRadius: 4,
						boxShadow: "0 1px 10px 0 rgba(9, 30, 66, 0.13)",
						display: "flex"
					}}
				>
					<div style={{ width: 80, height: 37, display: "flex", flexDirection: "column", marginRight: 16 }}>
						<Label style={{ marginBottom: 12, justifyContent: "center", textAlign: "center" }}>Skew</Label>
						<Slider
							min={-4}
							max={4}
							sliderPercent={skewScaleFactor}
							disableTooltip={true}
							styleType={Slider.styleKey.LIGHT}
							onChange={Store.getInteractionManager().setSkewScaleFactor}
						/>
					</div>
					<div style={{ width: 80, height: 37, display: "flex", flexDirection: "column" }}>
						<Label style={{ marginBottom: 12, justifyContent: "center", textAlign: "center" }}>Curve</Label>
						<Slider
							min={-12}
							max={12}
							sliderPercent={curveScaleFactor}
							disableTooltip={true}
							styleType={Slider.styleKey.LIGHT}
							onChange={Store.getInteractionManager().setCurveScaleFactor}
						/>
					</div>
				</div>
			</div>
		);
	}
}
