// interface Props{
//   color?:string
// }
const Bin: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path
					fill={color}
					d="M4.588 22.237a1.32 1.32 0 0 1-.1-.1c.036.035.066.071.1.1zm-.216-.097c-.034-.032-.068-.064-.1-.1.032.036.066.068.1.1zM21.766 4.76a.818.818 0 0 0-.585-.25h-4.108V3.158c0-.915-.703-1.658-1.568-1.658H8.493c-.865 0-1.569.743-1.569 1.658v1.351H2.797c-.439 0-.797.377-.797.842 0 .466.356.842.797.842H3.5v14.65c0 .914.703 1.657 1.569 1.657h14.085c.866 0 1.569-.743 1.569-1.658V6.192h.458a.84.84 0 0 0 .819-.84.855.855 0 0 0-.234-.593zM8.491 3.162h7.007v1.362H8.491V3.162zM19.15 20.84H5.07V6.193h14.083V20.84zM11.99 9.014c-.443 0-.801.379-.801.847v7.852c0 .468.358.847.8.847.444 0 .802-.379.802-.847V9.861c0-.468-.358-.847-.801-.847zm-4.34 0c-.442 0-.8.379-.8.847v7.852c0 .468.358.847.8.847.444 0 .802-.379.802-.847V9.861c0-.468-.358-.847-.801-.847zm8.634 0c-.442 0-.8.379-.8.847v7.852c0 .468.358.847.8.847.443 0 .802-.379.802-.847V9.861c0-.468-.359-.847-.802-.847z"
				/>
				<path d="M0 0h24v24H0z" />
			</g>
		</svg>
	);
};

export default Bin;
