import { FC } from "react";
import SideMenu from "../components/SideMenu";
import SideMenuBg from "../components/SideMenuBg";
import type { MenuItem } from "../services/menuService/MenuService";

type UserMenuProps = {
	menuItems: MenuItem[];
	title: string;
};

const UserMenu: FC<UserMenuProps> = ({ menuItems, title = "" }) => {
	return (
		<SideMenuBg>
			<SideMenu title={title} menuItems={menuItems} />
		</SideMenuBg>
	);
};

export default UserMenu;
