import { Alert, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LoadingSpinner } from "@/components/AppBlocks";
import { LegacyInputField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import P from "@/components/text/P";
import { duplicateEvent } from "@/state/actions/eventActions";
import { required } from "@/utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";

class DuplicateModal extends Component {
	constructor(props) {
		super(props);
		this.cancel = this.cancel.bind(this);
	}

	cancel() {
		const { handleCancel } = this.props;
		handleCancel();
	}

	duplicate = (values) => {
		const { duplicateEvent, navigate, currentEvent } = this.props;
		duplicateEvent(currentEvent.event._id, values.name, navigate);
	};

	nameRequired = required("Please enter your event name");

	render() {
		const { visible, currentEvent, handleSubmit } = this.props;
		let footerBtns = (
			<ButtonsBar footer>
				<LegacyButton key="cancel" onClick={this.cancel} disabled={currentEvent.duplicate.loading} ariaLabel="Cancel">
					Cancel
				</LegacyButton>
				<LegacyButton
					key="submit"
					disabled={currentEvent.duplicate.loading}
					onClick={handleSubmit(this.duplicate)}
					ariaLabel="Duplicate event"
					type="primary"
				>
					Duplicate event
				</LegacyButton>
			</ButtonsBar>
		);
		return (
			<Modal open={visible} title="Duplicate event" onCancel={this.cancel} destroyOnClose footer={footerBtns}>
				{!currentEvent.duplicate.loading ? (
					<div>
						<P>You are about to duplicate the event {currentEvent.event?.name}</P>

						<form onSubmit={handleSubmit(this.duplicate)}>
							<Field
								name="name"
								label="New Event Name"
								component={LegacyInputField}
								required
								validate={this.nameRequired}
							/>
						</form>

						<Alert
							message={
								<>
									<a onClick={OpenHelpHandler(HelpIds.duplicateEvent)}>Learn more</a> about what event settings will and
									won’t be duplicated. Ensure you review your duplicated event before publishing.
								</>
							}
							type="warning"
							style={{ margin: "12px 0 12px 0", maxWidth: 700 }}
						/>
					</div>
				) : (
					<LoadingSpinner style={{ margin: "auto", display: "block" }} />
				)}
			</Modal>
		);
	}
}
// Decorate with redux-form
const formName = "duplicateEvent";
DuplicateModal = reduxForm({
	form: formName
})(DuplicateModal);

// Connect to form state
export default connect(
	(state, ownProps) => {
		if (ownProps.currentEvent) {
			return {
				currentEvent: {
					...ownProps.currentEvent,
					duplicate: state.currentEvent.duplicate
				},
				initialValues: { name: `Copy of ${ownProps.currentEvent.event?.name}` }
			};
		}
		return {
			currentEvent: state.currentEvent,
			initialValues: { name: `Copy of ${state.currentEvent.event?.name}` }
		};
	},
	{ duplicateEvent }
)(withRouterHooks(DuplicateModal));
