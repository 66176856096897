import { Alert, Button } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { Note } from "@/components/text/Text";
import { getBoardReport, resetBoardReport } from "@/state/actions/adminReports/financeActions";
import { currency } from "@/utils/Format";
import DatesSelector from "../../DatesSelector";

class BoardReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getBoardReport } = this.props;
		getBoardReport(values.startDate, values.endDate);
	}
	retry() {
		const { resetBoardReport } = this.props;
		resetBoardReport();
	}

	runLoad = () => {
		const { startDate, endDate, sortOn } = this.props;
		this.load({ startDate, endDate, sortOn });
	};

	render() {
		const { touch, handleSubmit, boardReport, startDate, endDate } = this.props;
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Board Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>

				<LoadErrorView loading={boardReport.loading} error={boardReport.error} retryAction={this.retry}>
					<ReportFilters>
						<Alert
							message="Only counts orders where the money was processed by us"
							type="info"
							showIcon
							style={{ marginBottom: 12 }}
						/>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
							</div>
						</div>
						<Button type="primary" onClick={this.runLoad} disabled={!startDate || !endDate} ariaLabel="Load">
							Request
						</Button>
					</ReportFilters>
					<ReportContent>
						{boardReport.data ? (
							<div>
								<table style={{ marginBottom: 26, fontSize: 16 }} className="full-width-table">
									<tr>
										<th>Net Ticket Sales</th>
										<td>{currency(boardReport.data.totals.netTicketSales)}</td>
									</tr>
									<tr>
										<th>Gift cards</th>
										<td>{currency(boardReport.data.totals.giftCards)}</td>
									</tr>
									<tr>
										<th>Client Donations</th>
										<td>{currency(boardReport.data.totals.clientDonation)}</td>
									</tr>
									<tr>
										<th>Donations</th>
										<td>{currency(boardReport.data.totals.donations)}</td>
									</tr>
									<tr>
										<th>Fees</th>
										<td>{currency(boardReport.data.totals.bookingFees)}</td>
									</tr>
									<tr>
										<th>Rebates</th>
										<td>{currency(boardReport.data.totals.rebates)}</td>
									</tr>
									<tr>
										<th>Booking Taxes</th>
										<td>{currency(boardReport.data.totals.bookingTaxes)}</td>
									</tr>
									<tr>
										<th>
											Net Fees <Note>(Fees - Rebates)</Note>
										</th>
										<td>{currency(boardReport.data.totals.netBookingFees)}</td>
									</tr>
									<tr>
										<th>Total transaction value (TTV)</th>
										<td>{currency(boardReport.data.totals.total)}</td>
									</tr>
									<tr>
										<th>Donations% of Net fees</th>
										<td>{boardReport.data.totals.donationsPercent}%</td>
									</tr>
									<tr>
										<th>Net Fees % of TTV</th>
										<td>{boardReport.data.totals.feesPercent}%</td>
									</tr>
									<tr>
										<th>Orders</th>
										<td>{boardReport.data.salesCounts.orders}</td>
									</tr>
									<tr>
										<th>Paid Tickets</th>
										<td>{boardReport.data.salesCounts.paidTickets}</td>
									</tr>
									<tr>
										<th>Free Tickets</th>
										<td>{boardReport.data.salesCounts.freeTickets}</td>
									</tr>
									<tr>
										<th>Tickets / Orders</th>
										<td>{boardReport.data.salesCounts.ticketsPerOrder}</td>
									</tr>
									<tr>
										<th>Paid Tickets / Paid Orders</th>
										<td>{boardReport.data.salesCounts.paidTicketsPerOrder}</td>
									</tr>
									<tr>
										<th>Average Paid Order</th>
										<td>{currency(boardReport.data.averages.order)}</td>
									</tr>
									<tr>
										<th>Average Paid Tickets</th>
										<td>{currency(boardReport.data.averages.ticket)}</td>
									</tr>
									<tr>
										<th>Events Created</th>
										<td>{boardReport.data.eventsCreated}</td>
									</tr>
									<tr>
										<th>New Users</th>
										<td>{boardReport.data.newUsers}</td>
									</tr>
									<tr>
										<th>Active Users (event created past 12 months)</th>
										<td>{boardReport.data.activeUsers}</td>
									</tr>

									<tr>
										<th>20 Largest clients past 12 moths % of TTV</th>
										<td>{boardReport.data.top20UserPercentOfTTV}%</td>
									</tr>
								</table>
							</div>
						) : null}
					</ReportContent>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "boardReport";
const selector = formValueSelector(formName);
BoardReport = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(BoardReport);

export default connect(
	(state) => ({
		initialValues: {
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		boardReport: state.adminReports.finance.boardReport,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate")
	}),
	{ getBoardReport, resetBoardReport }
)(BoardReport);
