import React, { FC } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";
import { CodeBlock, HR } from "@/ui";
import { Alert, Button, Flex } from "@/ui/antd";
import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import { ReduxState } from "@/state/store";

type FormData = {
	datetimeDenver: string;
	datetimeUser: string;
	dateDenver: string;
	dateUser: string;
};

const ReduxDateTimePickerPageComponent: FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
	const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";

	// Use formValueSelector to access specific form values
	const selector = formValueSelector("dateTimePickerForm"); // Use the form name
	const formValues = useSelector<ReduxState>((state) =>
		selector(state, "datetimeDenver", "datetimeUser", "dateDenver", "dateUser")
	);

	const onSubmit = (values: FormData) => {
		console.log("Form submitted with values:", values);
	};

	return (
		<Flex align="start" gap="md" vertical wide>
			<p>
				The <code>ReduxDateTimePicker</code> is for our legacy code that still uses Redux Form. It attempts to keep the
				date and time consistent across different components.
			</p>
			<HR />
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* Example 1: Date and Time with Denver Timezone */}
				<Field
					name="datetimeDenver"
					component={ReduxDateTimePicker}
					label="Select Date and Time (America/Denver)"
					timezone="America/Denver"
					showTime
				/>
				{/* Example 2: Date and Time with User's Timezone */}
				<Field
					name="datetimeUser"
					component={ReduxDateTimePicker}
					label={`Select Date and Time - Your Timezone: (${userTimezone})`}
					timezone={userTimezone}
					showTime
				/>
				{/* Example 3: Date only with Denver Timezone */}
				<Field
					name="dateDenver"
					component={ReduxDateTimePicker}
					label="Select Date (America/Denver)"
					timezone="America/Denver"
					showTime={false}
				/>
				{/* Example 4: Date only with User's Timezone */}
				<Field
					name="dateUser"
					component={ReduxDateTimePicker}
					label={`Select Date - Your Timezone: (${userTimezone})`}
					timezone={userTimezone}
					showTime={false}
				/>
				<Button type="primary" htmlType="submit" style={{ marginTop: 16 }}>
					Submit
				</Button>
			</form>
			<HR />
			<Alert
				message={<pre style={{ margin: 0 }}>{`Form values:\n${JSON.stringify(formValues, null, 2)}`}</pre>}
				type="info"
				showIcon
				style={{ marginTop: 16 }}
			/>
			<HR />
			<CodeBlock
				codeString={`
import React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";
import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";

type FormData = {
  datetimeDenver: string;
  datetimeUser: string;
  dateDenver: string;
  dateUser: string;
};

const MyForm: React.FC<InjectedFormProps<FormData>> = ({ handleSubmit }) => {
  const onSubmit = (values: FormData) => {
    console.log("Form submitted with values:", values);
  };

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
  const selector = formValueSelector("dateTimePickerForm"); // Use the form name
  const formValues = useSelector((state) => selector(state, "datetimeDenver", "datetimeUser", "dateDenver", "dateUser"));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="datetimeDenver"
        component={ReduxDateTimePicker}
        label="Select Date and Time (America/Denver)"
        timezone="America/Denver"
        showTime
      />
      <Field
        name="datetimeUser"
        component={ReduxDateTimePicker}
        label={\`Select Date and Time (\${userTimezone})\`}
        timezone={userTimezone}
        showTime
      />
      <Field
        name="dateDenver"
        component={ReduxDateTimePicker}
        label="Select Date (America/Denver)"
        timezone="America/Denver"
        showTime={false}
      />
      <Field
        name="dateUser"
        component={ReduxDateTimePicker}
        label={\`Select Date (\${userTimezone})\`}
        timezone={userTimezone}
        showTime={false}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default reduxForm<FormData>({ form: "myForm" })(MyForm);
        `}
				fullWidth
			/>
		</Flex>
	);
};

const ConnectedDateTimePickerPage = reduxForm<FormData>({
	form: "dateTimePickerForm"
})(ReduxDateTimePickerPageComponent);

export const ReduxDateTimePickerPage = ConnectedDateTimePickerPage;
