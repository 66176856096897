import { useEffect } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { RadioField } from "@/components/Fields";
import DateRange from "./components/DateRange";

export const formName = "deleteScheduleForm";

let DeleteScheduleForm = ({ touch, onSubmit, occurrenceType, eventDate, dispatch, handleSubmit }) => {
	useEffect(() => {
		dispatch(change(formName, "dateId", occurrenceType === "SINGLE" ? eventDate._id : null));
		dispatch(change(formName, "date", occurrenceType === "FUTURE" ? eventDate.startDate : null));
	}, [occurrenceType]);
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Field
				label="Apply changes to"
				name="type"
				component={RadioField}
				vertical
				options={[
					{
						label: "This occurrence",
						value: "SINGLE"
					},
					{
						label: "All occurrences",
						value: "ALL"
					},
					{
						label: "This and all future occurrences",
						value: "FUTURE"
					},
					{
						label: "Custom range",
						value: "CUSTOM"
					}
				]}
			/>
			{occurrenceType === "CUSTOM" ? (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<DateRange touch={touch} startDateName="dateRange.start" endDateName="dateRange.end" vertical={false} />
				</div>
			) : null}
		</form>
	);
};

// Decorate with redux-form
DeleteScheduleForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: false,
	enableReinitialize: false,
	destroyOnUnmount: true,
	onSubmitFail: (errors) => {
		console.log("Event errors", errors);
		// scrollToErrors();
	}
})(DeleteScheduleForm);

export default connect((state, props) => {
	const selector = formValueSelector(formName);
	const initialValues = {
		type: "SINGLE",
		dateId: props.eventDate._id,
		date: props.eventDate.startDate,
		scheduleId: props.eventDate.scheduleId
	};
	return {
		initialValues,
		occurrenceType: selector(state, "type")
	};
})(DeleteScheduleForm);
