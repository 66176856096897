import { Table } from "@/ui/antd";
import React from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import { getAffiliateCodesCsv, getAffiliateReport } from "@/state/actions/reportActions";
import { currency } from "@/utils/Format";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class AffiliateCodes extends React.Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		const { params, getAffiliateReport } = this.props;
		const eventId = params?.eventId;
		getAffiliateReport(eventId);
	}
	downloadCsv() {
		const { params, getAffiliateCodesCsv } = this.props;
		const { event } = this.props.currentEvent;
		const eventId = params?.eventId;
		getAffiliateCodesCsv(eventId, event);
	}

	render() {
		const { loading, error, data } = this.props.affiliateCodes;
		const { reportRequests } = this.props.reports;
		const cols = [
			{
				title: "Code",
				dataIndex: "_id",
				key: "_id"
			},
			{
				title: "Orders",
				dataIndex: "orders",
				key: "orders"
			},
			{
				title: "Earnings",
				dataIndex: "earnings",
				key: "earnings",
				render: (earnings) => currency(earnings)
			}
		];
		return (
			<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
				<H1>Affiliate tracking</H1>
				<ContentBlock>
					<div style={{ marginBottom: 50 }}>
						<IconButton
							icon="export"
							type="background"
							showTooltip
							tooltip="Export report"
							onClick={reportRequests.affiliateCSV.loading ? null : this.downloadCsv}
							style={{ float: "right" }}
							loading={reportRequests.affiliateCSV.loading}
							ariaLabel="Export"
						/>
					</div>
					<Table pagination={false} columns={cols} dataSource={data} rowKey="code" />
				</ContentBlock>
				<ExportBar
					buttonText="Export Orders"
					buttonClick={this.downloadCsv}
					loading={reportRequests.affiliateCSV.loading}
				/>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		affiliateCodes: state.reports.affiliateCodes,
		currentEvent: state.currentEvent,
		reports: state.reports
	}),
	{ getAffiliateReport, getAffiliateCodesCsv }
)(withRouterHooks(AffiliateCodes));
