import { CodeBlock, CopyField } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type CopyFieldProps = {
	dropdownOptions?: { label: string; value: string }[];
	displayValue: string;
};`;

const sample = `import { CopyField } from "@/ui";

<CopyField displayValue="https://www.humanitix.com" />

<CopyField
  displayValue="https://www.humanitix.com"
  dropdownOptions={[
    {
      label: "Humanitix Website",
      value: "https://www.humanitix.com"
    },
    {
      label: "Humanitix Ticketing",
      value: "https://events.humanitix.com"
    }
  ]}
/>
`;

export const CopyFieldPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<CopyField displayValue="https://www.humanitix.com" />
			<b>Options</b>
			<CopyField
				displayValue="https://www.humanitix.com"
				dropdownOptions={[
					{
						label: "Humanitix Website",
						value: "https://www.humanitix.com"
					},
					{
						label: "Humanitix Ticketing",
						value: "https://events.humanitix.com"
					}
				]}
			/>
			<b>Disabled</b>
			<CopyField
				disabled
				displayValue="https://www.humanitix.com"
				dropdownOptions={[{ label: "Humanitix Website", value: "https://www.humanitix.com" }]}
			/>
			<textarea
				style={{
					border: "2px solid #e8e8e8",
					borderRadius: "4px",
					height: "100px",
					marginBottom: "16px",
					padding: "8px",
					width: "100%"
				}}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
