import { css } from "@emotion/css";
import { Table, Tag } from "@/ui/antd";
import { Field } from "redux-form";
import { CheckboxField, InputNumberField } from "@/components/Fields";
import { H3, P } from "@/components/text/Text";
import { currency } from "@/utils/Format";
import { round } from "@/utils/InputFormatters";

const tableWrapper = css`
	margin-bottom: 12px;
	.ant-form-item {
		margin-bottom: 0 !important;
	}
`;

const GiftCardTable = ({
	giftCardsToCancel,
	allGiftCardsSelected,
	selectAllGiftCards,
	globalFundsAvailable,
	setRefundAmount
}) => {
	if (!giftCardsToCancel) return null;
	const giftCards = Object.values(giftCardsToCancel);
	if (!giftCards.length) return null;
	const columns = [
		{
			title: (
				<CheckboxField
					id="giftCardsCancelAll"
					name="giftCardsCancelAll"
					label="Cancel all"
					labelAfter
					input={{ value: allGiftCardsSelected, onChange: selectAllGiftCards, name: "giftCardsCancelAll" }}
					meta={{}}
					inline
				/>
			),
			key: "refundTicket",
			fixed: "right",
			render: (_, giftCard) => {
				if (giftCard.status === "cancelled") return <Tag color="#5A626C">Cancelled</Tag>;
				return (
					<Field
						id={`giftCardsToCancel[${giftCard._id}].cancel`}
						name={`giftCardsToCancel[${giftCard._id}].cancel`}
						component={CheckboxField}
						label="Cancel"
						inline
						labelAfter
						onChange={(_, checked) => {
							setRefundAmount("giftCardsToCancel", giftCard._id, checked, globalFundsAvailable[giftCard._id]);
						}}
					/>
				);
			}
		},
		{
			title: "Gift Card",
			key: "giftCardDetails",
			fixed: "left",
			render: (_, giftCard) => {
				return (
					<P>
						{giftCard.giftCardTypeName}: {giftCard.firstName} {giftCard.lastName}
					</P>
				);
			}
		},
		{
			title: "Remaining",
			key: "giftCardPrice",
			dataIndex: ["totalsV2", "netSales"],
			fixed: "right",
			render: (price, giftCard) => currency(globalFundsAvailable[giftCard._id] - giftCard.amount)
		},
		{
			title: "Refund amount",
			fixed: "right",
			key: "refundAmount",
			render: (_, giftCard) => {
				return (
					<Field
						id={`giftCardsToCancel[${giftCard._id}].amount`}
						name={`giftCardsToCancel[${giftCard._id}].amount`}
						component={InputNumberField}
						normalize={round}
						min={0}
						max={globalFundsAvailable[giftCard._id]}
						disabled={globalFundsAvailable[giftCard._id] === 0}
					/>
				);
			}
		}
	];
	return (
		<div className={tableWrapper}>
			<H3>Gift cards</H3>
			<Table dataSource={giftCards} columns={columns} pagination={false} />
		</div>
	);
};

export default GiftCardTable;
