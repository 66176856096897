import {
	FIND_EVENT_ERROR,
	FIND_EVENT_REQUEST,
	FIND_EVENT_SUCCESS,
	GET_RECENTLY_UPDATED_EVENT_ERROR,
	GET_RECENTLY_UPDATED_EVENT_REQUEST,
	GET_RECENTLY_UPDATED_EVENT_SUCCESS
} from "../actions/eventActions";

const initialState = {
	events: [],
	count: 0,
	page: 1,
	loading: false,
	error: null,
	recentlyUpdatedEvents: {
		events: [],
		loading: false,
		loaded: false,
		error: false
	}
};

export type EventListReducerState = typeof initialState;

type EventListReducerAction = {
	type:
		| typeof FIND_EVENT_REQUEST
		| typeof FIND_EVENT_SUCCESS
		| typeof FIND_EVENT_ERROR
		| typeof GET_RECENTLY_UPDATED_EVENT_REQUEST
		| typeof GET_RECENTLY_UPDATED_EVENT_SUCCESS
		| typeof GET_RECENTLY_UPDATED_EVENT_ERROR;
	events?: any[];
	count?: number;
	page?: number;
	error?: Error;
	toAppend?: boolean;
};

export default (state = initialState, action: EventListReducerAction) => {
	switch (action.type) {
		case FIND_EVENT_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
				page: action.page
			};
		case FIND_EVENT_SUCCESS:
			return {
				...state,
				events:
					action.toAppend && action.events?.length
						? [...new Set([...state.events, ...action.events])].filter(
								(v, i, a) => a.findIndex((t) => t._id === v._id) === i
						  )
						: action.events,
				count: action.count,
				loading: false
			};
		case FIND_EVENT_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case GET_RECENTLY_UPDATED_EVENT_REQUEST:
			return {
				...state,
				recentlyUpdatedEvents: {
					...state.recentlyUpdatedEvents,
					loading: true,
					error: null
				}
			};
		case GET_RECENTLY_UPDATED_EVENT_SUCCESS:
			return {
				...state,
				recentlyUpdatedEvents: {
					...state.recentlyUpdatedEvents,
					loading: false,
					loaded: true,
					events: action.events
				}
			};
		case GET_RECENTLY_UPDATED_EVENT_ERROR:
			return {
				...state,
				recentlyUpdatedEvents: {
					...state.recentlyUpdatedEvents,
					loading: false,
					loaded: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
