import {
	ADD_TO_WAITLIST_ERROR,
	ADD_TO_WAITLIST_REQUEST,
	ADD_TO_WAITLIST_SUCCESS,
	CLOSE_OFFER_MODAL,
	FIND_WAITLIST_ERROR,
	FIND_WAITLIST_REQUEST,
	FIND_WAITLIST_SUCCESS,
	GET_WAITLIST_CSV_ERROR,
	GET_WAITLIST_CSV_REQUEST,
	GET_WAITLIST_CSV_SUCCESS,
	OFFER_TICKETS_ERROR,
	OFFER_TICKETS_REQUEST,
	OFFER_TICKETS_SUCCESS,
	OPEN_OFFER_MODAL,
	SET_WAITLIST_SELECTED,
	UPDATE_SHOW_SETTINGS_TAB_ONLY
} from "../actions/waitlistActions";

const initialState = {
	offerModalOpen: false,
	showSettingsTabOnly: false,
	list: {
		page: 1,
		total: 0,
		data: [],
		loading: false,
		error: false
	},
	download: {
		loading: false,
		error: false
	},
	offer: {
		loading: false,
		error: false
	},
	add: {
		loading: false,
		error: false,
		added: false
	},
	selectedRowKeys: []
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_WAITLIST_CSV_REQUEST:
			return {
				...state,
				download: {
					...state.download,
					loading: true,
					error: false
				}
			};
		case GET_WAITLIST_CSV_SUCCESS:
			return {
				...state,
				download: {
					...state.download,
					loading: false,
					error: false
				}
			};
		case GET_WAITLIST_CSV_ERROR:
			return {
				...state,
				download: {
					...state.download,
					loading: false,
					error: true
				}
			};
		case OPEN_OFFER_MODAL: {
			return {
				...state,
				offerModalOpen: true
			};
		}
		case CLOSE_OFFER_MODAL: {
			return {
				...state,
				offerModalOpen: false
			};
		}
		case OFFER_TICKETS_REQUEST:
			return {
				...state,
				offer: {
					...state.offer,
					loading: true,
					error: false
				}
			};
		case OFFER_TICKETS_SUCCESS:
			return {
				...state,
				offer: {
					...state.offer,
					loading: false,
					error: false
				}
			};
		case OFFER_TICKETS_ERROR:
			return {
				...state,
				offer: {
					...state.offer,
					loading: false,
					error: true
				}
			};
		case ADD_TO_WAITLIST_REQUEST:
			return {
				...state,
				add: {
					...state.add,
					loading: true,
					error: false
				}
			};
		case ADD_TO_WAITLIST_SUCCESS:
			return {
				...state,
				add: {
					...state.add,
					added: true,
					loading: false,
					error: false
				}
			};
		case ADD_TO_WAITLIST_ERROR:
			return {
				...state,
				add: {
					...state.add,
					added: false,
					loading: false,
					error: true
				}
			};
		case FIND_WAITLIST_REQUEST:
			return {
				...state,
				list: {
					...state.list,
					loading: true,
					error: false,
					page: action.page
				},
				selectedRowKeys: []
			};
		case FIND_WAITLIST_SUCCESS:
			return {
				...state,
				showSettingsTabOnly: action.showSettingsTabOnly,
				list: {
					...state.list,
					loading: false,
					error: false,
					data: action.list,
					total: action.total
				}
			};

		case FIND_WAITLIST_ERROR:
			return {
				...state,
				list: {
					...state.list,
					loading: false,
					error: action.error
				}
			};

		case SET_WAITLIST_SELECTED:
			return {
				...state,
				selectedRowKeys: action.selectedRowKeys
			};
		case UPDATE_SHOW_SETTINGS_TAB_ONLY:
			return { ...state, showSettingsTabOnly: action.showSettingsTabOnly };
		default:
			return state;
	}
};
