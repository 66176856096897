import { css } from "@emotion/css";
import { ResponsiveModal } from "@/ui";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, getFormValues, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import arrowRightIcon from "@/assets/ic_arrow_right.svg";
import { CheckboxField, SelectField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { Button } from "@/ui";
import { H4, H5, Label, P } from "@/components/text/Text";
import { Flex } from "@/ui/antd";
import { findEvents } from "@/state/actions/eventActions";
import {
	clearEventDateCapacity,
	clearSwap,
	closeSwapModal,
	getEventDateCapacity,
	swapTicket
} from "@/state/actions/orderActions";
import { currency, fullName } from "@/utils/Format";
import { required } from "@/utils/Validators";

const formName = "swap";
const tableWrapper = css({
	width: "100%",
	marginBottom: 16
});

const styles = {
	thead: css({
		background: "rgb(249, 249, 250)"
	}),
	nonFieldTd: css({
		paddingBottom: 21
	}),
	icon: css({
		fontSize: "16px",
		color: "#08c"
	}),
	note: css({
		fontSize: "12px",
		fontStyle: "italic"
	})
};

const TicketFields = ({ tickets, ticketOptions = [] }) => {
	if (!tickets || !tickets.length) {
		return <div>No tickets available</div>;
	}
	const ticketFields = tickets.map((ticket) => {
		return (
			<tr key={ticket._id}>
				<td className={styles.nonFieldTd}>
					{ticket.ticketTypeName} {currency(ticket.price)}
					<br />
					<i>{fullName(ticket.firstName, ticket.lastName)}</i>
					{ticket.seatingLocation && ticket.seatingLocation.name ? (
						<>
							<br /> <i>{ticket.seatingLocation.name}</i>
						</>
					) : (
						""
					)}
				</td>
				<td className={styles.nonFieldTd}>
					<img src={arrowRightIcon} width="24" height="24" alt="Arrow right" />
				</td>
				<td>
					<Field
						name={`tickets.${ticket._id}`}
						component={SelectField}
						options={ticketOptions}
						required
						dropdownMatchSelectWidth={false}
						disabled={ticket.hasResaleListing}
						style={{ maxWidth: "240px" }}
					/>
					{ticket.hasResaleListing && (
						<p className={styles.note}>This ticket is up for resale and cannot be swapped.</p>
					)}
				</td>
			</tr>
		);
	});
	return (
		<table className={tableWrapper}>
			<thead className={styles.thead}>
				<tr>
					<th width="40%">Current Ticket</th>
					<th />
					<th>Swap to Ticket</th>
				</tr>
			</thead>
			<tbody>{ticketFields}</tbody>
		</table>
	);
};

const MerchandiseFields = ({ merch }) => {
	if (!merch || !merch.length) return null;

	const merchFields = merch
		.filter((merch) => merch.status === "complete")
		.map((merchandise) => {
			return (
				<tr key={merchandise._id}>
					<td className={styles.nonFieldTd}>
						{merchandise.merchandiseTypeName} {currency(merch.price)}
					</td>
					<td className={styles.nonFieldTd}>
						<img src={arrowRightIcon} width="24" height="24" alt="Arrow right" />
					</td>
					<td>
						<Field
							name={`merchandise.${merchandise._id}`}
							component={SelectField}
							options={[
								{ value: true, label: "Swap" },
								{ value: false, label: "Don't Swap" }
							]}
							dropdownMatchSelectWidth={false}
						/>
					</td>
				</tr>
			);
		});

	return (
		<>
			<Label>Select add-ons</Label>
			<table className={tableWrapper}>
				<thead className={styles.thead}>
					<tr>
						<th width="40%">Add-on</th>
						<th />
						<th></th>
					</tr>
				</thead>
				<tbody>{merchFields}</tbody>
			</table>
		</>
	);
};

class SwapModal extends Component {
	constructor(props) {
		super(props);
		this.selectEventName = this.selectEventName.bind(this);
		this.debouncedSearchEventNames = debounce(200, (query) =>
			this.props.findEvents(1, {
				query,
				sortOrder: "relevant",
				filter: "all"
			})
		);
	}

	state = {
		selectedEventId: undefined
	};

	getDateOptions() {
		const {
			currentEvent,
			searchEvents: { events }
		} = this.props;

		const selectedEventId = this.state.selectedEventId || currentEvent.event._id;

		const event = events.concat(currentEvent.event).find((e) => e._id === selectedEventId);
		if (!event) return [];
		const { dates } = event;
		if (!dates || !dates.length) {
			return [];
		}
		const dateOptions = dates.map((date) => {
			return {
				value: date._id,
				label: date.display.combined
			};
		});
		return dateOptions;
	}

	getTicketOptions() {
		const {
			currentEvent,
			searchEvents: { events }
		} = this.props;

		const selectedEventId = this.state.selectedEventId || currentEvent.event._id;

		const event = events.concat(currentEvent.event).find((e) => e._id === selectedEventId);
		if (!event) return [];
		const { ticketTypes } = event;
		if (!ticketTypes || !ticketTypes.length) {
			return [];
		}
		const ticketOptions = ticketTypes
			.filter((ticket) => !ticket.disabled && !ticket.deleted && !ticket.isDonation)
			.map((ticket) => {
				return {
					value: ticket._id,
					label: `${ticket.name} ${currency(ticket.price)}`
				};
			});
		return ticketOptions;
	}

	componentDidMount() {
		this.props.findEvents(1, {
			query: "",
			sortOrder: "relevant",
			filter: "all"
		});
		this.props.clearEventDateCapacity();
	}

	handleEventSearch = (searchString) => {
		if (searchString.length > 2) {
			this.debouncedSearchEventNames(searchString);
		}
	};

	selectEventName(e, selectedEventId) {
		this.setState({ selectedEventId }, () => {
			this.props.dispatch(change(formName, "date", ""));
			this.props.dispatch(change(formName, "tickets", ""));
		});
	}

	selectEventDate = (_e, selectedDateId) => {
		const { eventId, getEventDateCapacity } = this.props;
		getEventDateCapacity(eventId, selectedDateId);
	};

	cancel = () => {
		const { closeSwapModal, findEvents } = this.props;
		closeSwapModal();
		findEvents(1, {
			query: "",
			sortOrder: "relevant",
			filter: "all"
		});
	};

	getSelectOptions = () => {
		const dateOptions = this.getDateOptions();
		const ticketOptions = this.getTicketOptions();
		return { dateOptions, ticketOptions };
	};

	save = (values) => {
		const { swapTicket, order } = this.props;
		swapTicket(order.order._id, values, formName);
	};

	nameRequired = required("Event name is required");
	dateRequired = required("Event date is required");

	render() {
		const { currentEvent, order, handleSubmit, clearSwap, visible, searchEvents, defaultEventOptions, formValues } =
			this.props;
		const eventOptions = defaultEventOptions.concat(
			searchEvents.events
				.filter((e) => e._id !== defaultEventOptions[0].value)
				.map((event) => {
					return { value: event._id, label: event.name };
				})
		);

		const { dateOptions, ticketOptions } = this.getSelectOptions();

		ticketOptions.unshift({ value: "", label: "Don't Swap" });
		let tickets = order.order && order.order.tickets && order.order.tickets.length ? order.order.tickets : [];
		let merch = order.order && order.order.merchandise && order.order.merchandise.length ? order.order.merchandise : [];
		tickets = tickets.filter((ticket) => ticket.status === "complete");

		const eventDateCapacity = order?.swapState?.eventDateCapacity;
		const { merchandise: merchandiseSelected = {}, tickets: ticketsSelected = {} } = formValues;

		const submitEnabled =
			Object.values(merchandiseSelected).filter((selected) => selected).length || Object.keys(ticketsSelected).length;

		return (
			<ResponsiveModal
				open={visible}
				header={<H4 style={{ marginBottom: 0 }}>Swap</H4>}
				onCancel={this.cancel}
				width="620px"
				footer={
					!order.swapState.error ? (
						<Flex justify="space-between" wide>
							<Button ariaLabel="Cancel" variant="tertiary" onClick={this.cancel}>
								Cancel
							</Button>
							<Button
								ariaLabel="Swap"
								variant="primary"
								onClick={handleSubmit(this.save)}
								loading={order.swapState.loading}
								disabled={!tickets.length || !submitEnabled}
							>
								Swap
							</Button>
						</Flex>
					) : null
				}
			>
				<form>
					<LoadErrorView loading={order.swapState.loading} error={order.swapState.error} retryAction={clearSwap}>
						<div style={{ overflow: "auto" }}>
							<H5>From: {currentEvent.event.name}</H5>
							{order.order ? <P>{order.order.eventDisplayDate}</P> : null}
							<Field
								name="event"
								label="To:"
								placeholder="Type to start searching"
								component={SelectField}
								options={eventOptions}
								mode="default"
								filterOption={false}
								onChange={this.selectEventName}
								onSearch={this.handleEventSearch}
								showSearch
								notFoundContentLoading={searchEvents.loading}
								style={{ width: "100%" }}
								require
								validate={this.nameRequired}
							/>
							<Field
								name="date"
								label="Select Date"
								placeholder="Event Date"
								component={SelectField}
								onChange={this.selectEventDate}
								options={dateOptions}
								require
								notFoundContentLoading
								validate={this.dateRequired}
								style={{ width: "100%" }}
							/>
							{eventDateCapacity ? (
								<P>
									Current event date capacity: {eventDateCapacity.ticketsSold} / {eventDateCapacity.eventDateCapacity}
								</P>
							) : null}
							<Label>Select ticket</Label>

							<TicketFields tickets={tickets} ticketOptions={ticketOptions} />
							<MerchandiseFields merch={merch} />
							<Field
								label="Send order confirmation email to the ticket buyer"
								name="sendConfirmationEmails"
								component={CheckboxField}
								inline
								labelAfter
							/>
						</div>
					</LoadErrorView>
				</form>
			</ResponsiveModal>
		);
	}
}

SwapModal = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(SwapModal);

const selector = formValueSelector(formName);

export default connect(
	(state) => ({
		initialValues: {
			event: state.currentEvent.event._id,
			date: state.order && state.order.order && state.order.eventDateId ? state.order.order.eventDateId : null
		},
		defaultEventOptions: [
			{
				value: state.currentEvent.event._id,
				label: state.currentEvent.event.name
			}
		],
		order: state.order,
		searchEvents: state.events,
		currentEvent: state.currentEvent,
		eventId: selector(state, "event"),
		formValues: getFormValues(formName)(state) || {}
	}),
	{
		closeSwapModal,
		swapTicket,
		clearSwap,
		findEvents,
		getEventDateCapacity,
		clearEventDateCapacity
	}
)(SwapModal);
