import { Slider } from "@/ui/antd";
import InputLabel from "../../../components/InputLabel";

const getTypeConfig = (type) => {
	if (type === "text") {
		return {
			title: "Text size",
			smallIndicator: (
				<img
					src="/frontend/seatingMap/small_a.svg"
					style={{
						marginTop: "15px"
					}}
					alt="small a"
				/>
			),
			largeIndicator: (
				<img
					src="/frontend/seatingMap/big_a.svg"
					style={{
						marginTop: "9px"
					}}
					alt="big a"
				/>
			),
			min: 6,
			max: 96,
			action: "setFontSize",
			value: "fontSize",
			step: 3
		};
	}
	return {
		title: "Icon size",
		smallIndicator: (
			<div
				style={{
					width: "24px",
					height: "24px",
					borderRadius: "4px",
					backgroundColor: "#b6c1d0",
					marginTop: "8px"
				}}
			/>
		),
		largeIndicator: (
			<div
				style={{
					width: "40px",
					height: "40px",
					borderRadius: "4px",
					backgroundColor: "#b6c1d0"
				}}
			/>
		),
		min: 0,
		max: 5,
		action: "setScale",
		value: "scale.x",
		step: 0.1
	};
};

export default ({ type, element, performAction }) => {
	const { title, smallIndicator, largeIndicator, min, max, action, value, step } = getTypeConfig(type);

	let currentValue = element;

	value.split(".").forEach((key) => {
		currentValue = currentValue[key];
	});

	return (
		<div
			style={{
				overflow: "auto",
				paddingTop: 16,
				display: "inline-block",
				width: "100%"
			}}
		>
			<InputLabel>{title}</InputLabel>
			<div style={{ display: "flex" }}>
				<div style={{ float: "left" }}>{smallIndicator}</div>
				<Slider
					style={{ flex: 1 }}
					min={min}
					max={max}
					onChange={(val) => performAction([element[action]], [[val]])}
					value={currentValue}
					step={step}
				/>
				<div style={{ float: "left" }}>{largeIndicator}</div>
			</div>
		</div>
	);
};
