import { withRouterHooks } from "@/hoc/withRouterHooks";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button, Collapse, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import {
	CheckboxField,
	ImageUploadField,
	LegacyInputField,
	InputNumberField,
	SelectField,
	TextAreaField
} from "../components/Fields";
import FormBar from "../components/FormBar";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import ButtonsBar from "../components/buttons/ButtonsBar";
import { H1, Label, P } from "../components/text/Text";
import { createMerch, getEventTicketTypes, getUserEvents, updateMerch } from "../state/actions/merchandiseActions";
import { required } from "../utils/Validators";

const Panel = Collapse.Panel;
const formName = "merchandiseForm";

const itemNameRequired = required("Please input a item name");
const taxCodeRequired = required("Please select a tax category");
const descriptionRequired = required("Please input a description name");
const optionNameRequired = required("Please input a option name");
const priceRequired = required("Please input a option price");
const quantityRequired = required("Please input a option quantity");
const taxCodeValidator = (value) => (!value ? "Please accept the sales tax declaration" : undefined);

const imageFieldWrapper = css({
	maxWidth: 280,
	marginTop: 18,
	marginBottom: 40,
	"@media(max-width: 600px)": { marginBottom: 24 }
});

//TODO move to editable config
const usTaxCategories = [
	{
		label: "Beverage",
		options: [
			{ label: "Bottled water", value: "40060" },
			{ label: "Alcoholic beer/malt beverages", value: "50202201A0000" },
			{ label: "Alcoholic spirits", value: "50202206A0000" },
			{ label: "Alcoholic wine", value: "50202203A0000" },
			{ label: "Coffee, coffee substitutes, tea", value: "50202250201700A000003A0000" },
			{ label: "Milk and milk products", value: "50131700A0000" },
			{ label: "Non-alcoholic beer/wine", value: "50202300A0001" },
			{ label: "Soft drink", value: "40050" },
			{ label: "Non-carbonated 100% fruit/vegetable juice", value: "50202304A0000" }
		]
	},
	{
		label: "Cash items",
		options: [
			{ label: "Cash donation & Fundraising", value: "14111803A0002" },
			{ label: "Gift cards", value: "14111803A0001" },
			{ label: "Raffle tickets", value: "19003" }
		]
	},
	{
		label: "Food",
		options: [
			{ label: "Snack foods", value: "50192100A0000" },
			{ label: "Prepared foods", value: "41000" }
		]
	},
	{
		label: "Merchandise",
		options: [
			{ label: "Bath towels", value: "52121700A0000" },
			{ label: "Books", value: "81100" },
			{ label: "Bubble bath, bath salts/oils/crystals", value: "53131612A0001" },
			{ label: "Clothing", value: "20010" },
			{ label: "Cosmetics", value: "53131619A0001" },
			{ label: "Handbags, purses", value: "53121600A0000" },
			{ label: "Hats", value: "53102503A0000" },
			{ label: "Jewelry", value: "54100000A0000" },
			{ label: "Portable light sources", value: "39111610A0000" },
			{ label: "Printed paper", value: "14111507A0000" },
			{ label: "School supplies", value: "44121600A0001" },
			{ label: "Shoes", value: "53111600A0000" },
			{ label: "Sunglasses - non-Rx", value: "42142905A0001" }
		]
	},
	{
		label: "Services and rentals",
		options: [
			{
				label: "Advertising services",
				value: "19001"
			},
			{
				label: "General services",
				value: "19000"
			},
			{
				label: "Parking services",
				value: "19002"
			},
			{
				label: "Spa services",
				value: "72990201A0000"
			},
			{
				label: "Temporary help services",
				value: "73630103A0000"
			}
		]
	},
	{
		label: "Tech goods",
		options: [
			{
				label: "Computer storage media",
				value: "43202000A0000"
			},
			{
				label: "Digital goods",
				value: "31000"
			},
			{
				label: "Headphones/earbuds",
				value: "52161514A0000"
			},
			{
				label: "Mobile phone charging device/cord",
				value: "43191501A0002"
			}
		]
	},
	{
		label: "Other",
		options: [
			{
				label: "Other Taxable Product or Service",
				value: "other"
			}
		]
	}
];

class MerchandiseForm extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.onModalOpen = this.onModalOpen.bind(this);
		this.onModalClose = this.onModalClose.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.state = { modalOpen: false };
		this.isUpdate = props.location.pathname?.includes("/update");
	}
	componentWillMount() {
		this.loadUserEvents();
	}

	loadUserEvents = () => {
		const { currentUser, locations } = this.props;
		const userId = currentUser.user.id;
		this.props.getUserEvents(userId, locations.overrideLocation);
	};

	save(data) {
		const { createMerch, updateMerch, currentUser, params, navigate } = this.props;
		const userId = currentUser.user.id;
		if (this.isUpdate) {
			const merchId = params?.id;
			updateMerch(userId, merchId, data, navigate);
		} else {
			createMerch(userId, data, navigate);
		}
	}

	onModalOpen() {
		const { eventIds, currentUser, locations } = this.props;
		const userId = currentUser.user.id;
		this.props.getEventTicketTypes(
			userId,
			eventIds.filter((eventId) => eventId !== "all"),
			locations.overrideLocation
		);
		this.setState({ modalOpen: true });
	}

	onModalClose() {
		this.setState({ modalOpen: false });
	}

	onCancel() {
		this.props.navigate("/console/promote/add-ons");
	}

	render() {
		const { modalOpen } = this.state;
		const { merchandise, userEvents, eventIds, currentTicketTypes, merchOpts, overrideLocation, params, location } =
			this.props;
		const { loading, error, ticketTypes } = merchandise;
		const eventOptions = [{ value: "all", label: "All events" }].concat(
			(userEvents.events || [])
				.filter((event) => !event.settings?.isSecondaryTicketsEnabled)
				.map((event) => {
					return { value: event._id, label: event.name };
				})
		);
		return (
			<div>
				<H1>Add-ons</H1>
				<Form
					loading={loading || userEvents.loading}
					error={error || userEvents.error}
					save={this.save}
					eventOptions={eventOptions}
					onModalOpen={this.onModalOpen}
					onModalClose={this.onModalClose}
					modalOpen={modalOpen}
					ticketTypes={ticketTypes}
					eventIds={eventIds}
					currentTicketTypes={currentTicketTypes}
					merchOpts={merchOpts}
					onCancel={this.onCancel}
					overrideLocation={overrideLocation}
					params={params}
					location={location}
				/>
			</div>
		);
	}
}

class Form extends Component {
	constructor(props) {
		super(props);
		this.changeFieldValue = this.changeFieldValue.bind(this);
		this.onSelectEventChange = this.onSelectEventChange.bind(this);
		this.deleteOpt = this.deleteOpt.bind(this);
		this.addUSTaxCategory = this.addUSTaxCategory.bind(this);
		this.state = {
			modalOpen: false
		};
	}
	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	getArrayOfObject(event) {
		const arr = [];
		let foundEnd = false;
		let i = 0;
		while (!foundEnd) {
			if (event[i]) {
				arr.push(event[i]);
			} else {
				foundEnd = true;
			}
			i++;
		}
		return arr;
	}

	onSelectEventChange(event) {
		const newEventIds = this.getArrayOfObject(event);
		const currentEventIds = this.props.eventIds || [];
		const currentTicketTypes = this.props.currentTicketTypes;

		const addedEventIds = newEventIds.filter((newId) => {
			return !currentEventIds.includes(newId);
		});
		const removedEventIds = currentEventIds.filter((currentId) => {
			return !newEventIds.includes(currentId);
		});
		addedEventIds.forEach((eventId) => this.changeFieldValue(`ticketTypes.${eventId}`, {}));
		removedEventIds.forEach((eventId) => {
			delete currentTicketTypes[eventId];
			this.changeFieldValue(`ticketTypes`, currentTicketTypes);
		});
	}

	deleteOpt(opt, index, fields) {
		if (!opt._id) {
			fields.remove(index);
		} else {
			this.changeFieldValue(`merchandiseOptions[${index}].deleted`, !opt.deleted);
		}
	}

	addUSTaxCategory(_e, value) {
		const category = usTaxCategories
			.map((taxGroup) => taxGroup.options)
			.flat()
			.find((taxCategory) => taxCategory.value === value).label;
		this.changeFieldValue("taxCode.category", category);
	}

	render() {
		const {
			save,
			handleSubmit,
			eventOptions,
			onModalOpen,
			modalOpen,
			ticketTypes,
			onModalClose,
			merchOpts,
			loading,
			error,
			onCancel,
			overrideLocation
		} = this.props;
		return (
			<LoadErrorView loading={loading} error={error}>
				<form onSubmit={handleSubmit(save)} id={formName}>
					<ContentBlock>
						<div>
							<Field name="itemName" label="Item Name" component={LegacyInputField} validate={itemNameRequired} />
							<Field
								name="description"
								label="Description"
								description="Describe add-on item, description will appear on the checkout"
								component={TextAreaField}
								validate={descriptionRequired}
							/>
							<hr />
							<Label>Add-on options</Label>
							<FieldArray
								name="merchandiseOptions"
								component={MerchandiseOptionRow}
								deleteOpt={this.deleteOpt}
								merchOpts={merchOpts || []}
								changeFieldValue={this.changeFieldValue}
							/>
							<div className={imageFieldWrapper}>
								<FieldArray
									name="itemImages"
									component={() => (
										<Field
											name="itemImages[0]"
											label="Item Image"
											component={ImageUploadField}
											uploadLabel="Upload image"
											aspectRatio={2}
										/>
									)}
								/>
							</div>
							<hr />
							<Field
								label="Select Events"
								description="Which events will this Add-ons apply to?"
								mode="multiple"
								name="eventIds"
								placeholder="Please select"
								required
								component={SelectField}
								options={eventOptions}
								onChange={this.onSelectEventChange}
							/>
							{/*eslint-disable-next-line*/}
							<a onClick={onModalOpen}>Select events ticket types</a>
							{overrideLocation === "US" ? (
								<>
									<hr />
									<Field
										label="Tax category"
										name="taxCode.code"
										component={SelectField}
										required
										validate={taxCodeRequired}
										options={usTaxCategories}
										onChange={this.addUSTaxCategory}
									/>
									<Field
										name="taxCode.accepted"
										component={CheckboxField}
										required
										label={
											<>
												I have read and agree to the{" "}
												<a onClick={() => this.setState({ modalOpen: true })}>sales tax declaration</a>
											</>
										}
										validate={taxCodeValidator}
										labelAfter
										inline
									/>
									<Modal
										open={this.state.modalOpen}
										title="Sales tax declaration"
										onCancel={() => this.setState({ modalOpen: false })}
										destroyOnClose
										footer={false}
									>
										<>
											<P>
												Event Hosts (Marketplace Sellers) are responsible for providing Humanitix USA Limited
												(Marketplace Facilitator) with true and accurate information about the taxability for Event
												Tickets and other Add Ons sold to Ticket Purchasers (Marketplace Buyers) via{" "}
												<a href="https://www.humanitix.com" target="_blank" rel="noreferrer">
													www.humanitix.com
												</a>{" "}
												(Marketplace Platform).{" "}
											</P>{" "}
											<P>
												Humanitix is relieved of liability for tax errors to the extent that the error was due to
												incorrect or untrue information given to Humanitix (facilitator) by the Host (seller).
											</P>
											<P>
												A Host (seller) shall not furnish any information relating to an Event or Add On if the Host
												(seller) knows or has reasonable cause to believe that the information is inaccurate or untrue.
												The Host (seller) has a duty to verify, correct and update information if they determine that
												taxes calculated on Event Tickets or Add On items sold via Humanitix (marketplace) are
												inaccurate or untrue. Event hosts are responsible for all compliance and tax obligations in
												areas where the event is taking place.
											</P>
											<P>
												<b>Humanitix cannot give tax advice.</b> Please consult a tax professional. This is general
												guidance and is not legal advice. Nothing in the Humanitix platform overrides an individual
												state’s laws. For specific state guidance, please contact the state.
											</P>
											<P>
												See Help Centre Topic{" "}
												<a href="https://humanitix.atlassian.net/l/cp/dcN0EnJ1" target="_blank" rel="noreferrer">
													About US State Sales and Use Taxes
												</a>{" "}
												to learn more.
											</P>
										</>
									</Modal>
								</>
							) : null}
						</div>
						<TicketTypeModal onClose={onModalClose} visible={modalOpen} {...ticketTypes} />
					</ContentBlock>
					<FormBar>
						<Button
							style={{
								float: "left"
							}}
							type="secondary"
							id="cancelButton"
							size="large"
							onClick={onCancel}
							ariaLabel="Cancel"
						>
							Cancel
						</Button>
						<Button
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							size="large"
							ariaLabel="Save"
						>
							Save
						</Button>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const MerchandiseOptionRow = ({ fields, merchOpts, changeFieldValue }) => {
	const showName = merchOpts.length > 1;
	return (
		<div>
			{merchOpts.map((opts, i) => {
				return (
					<div style={{ display: "flex", flexDirection: "row" }}>
						{showName ? (
							<div style={{ paddingRight: 8 }}>
								<Field
									name={`merchandiseOptions[${i}].optionName`}
									label="Option Name"
									component={LegacyInputField}
									style={{ flex: 1 }}
									disabled={opts.removed}
									validate={optionNameRequired}
								/>
							</div>
						) : null}
						<Field
							name={`merchandiseOptions[${i}].price`}
							label="Price"
							component={InputNumberField}
							style={{ flex: 1, marginRight: 8 }}
							disabled={opts.removed}
							validate={priceRequired}
							precision={2}
							min={0}
						/>
						<Field
							name={`merchandiseOptions[${i}].quantity`}
							label="Quantity"
							component={InputNumberField}
							style={{ flex: 1, marginRight: 8 }}
							disabled={opts.removed}
							validate={quantityRequired}
							min={0}
						/>
						<Field
							name={`merchandiseOptions[${i}].sku`}
							label="SKU"
							component={LegacyInputField}
							style={{ flex: 1 }}
							disabled={opts.removed}
						/>
						{showName ? (
							<div style={{ flex: 1, paddingTop: 21, paddingLeft: 8 }}>
								<IconButton
									icon={opts.removed ? "undo" : "delete"}
									onClick={() => changeFieldValue(`merchandiseOptions[${i}].removed`, !opts.removed)}
									tooltip="Delete"
									showTooltip
									ariaLabel="Delete"
								/>
							</div>
						) : null}
					</div>
				);
			})}
			<Button
				onClick={() => {
					fields.push({
						price: 0,
						quantity: 0,
						sku: ""
					});
				}}
				icon={<PlusOutlined />}
				ariaLabel="Add option"
			>
				Add option
			</Button>
		</div>
	);
};

const TicketTypeModal = ({ loading, error, data, onClose, visible, onTicketTypeChange }) => {
	return (
		<Modal
			open={visible}
			title="Choose which ticket types form individual event will include Add-ons items."
			destroyOnClose={false}
			onCancel={onClose}
			onOk={onClose}
			footer={
				<ButtonsBar footer>
					<Button
						style={{
							float: "left"
						}}
						key="cancel"
						onClick={onClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Cancel
					</Button>
					<Button onClick={onClose} key="done" type="primary" htmlType="button" ariaLabel="Done">
						Done
					</Button>
				</ButtonsBar>
			}
		>
			<LoadErrorView loading={loading} error={error}>
				<Collapse bordered={false} defaultActiveKey={["1"]}>
					{data.map((event, i) => {
						return (
							<Panel header={event.name} key={i}>
								{event.ticketTypes
									.filter((tt) => !tt.isSecondary)
									.map((ticketType, i) => {
										return (
											<div>
												<Field
													name={`ticketTypes.${event._id}.${ticketType._id}`}
													label={ticketType.name}
													key={i}
													component={CheckboxField}
													style={{ flex: 1, marginRight: 8 }}
													inline
													labelAfter
													onChange={onTicketTypeChange}
												/>
											</div>
										);
									})}
							</Panel>
						);
					})}
				</Collapse>
			</LoadErrorView>
		</Modal>
	);
};

// Decorate with redux-form
Form = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Form);
const selector = formValueSelector(formName);

Form = connect((state, props) => {
	const isUpdate = props.location?.pathname?.includes("/update");
	const initialValues =
		state.merchandise && state.merchandise.merchData && isUpdate
			? state.merchandise.merchData
			: {
					merchandiseOptions: [
						{
							price: 0,
							quantity: 0,
							sku: ""
						}
					],
					location: state.locations.overrideLocation
			  };
	return {
		initialValues
	};
})(Form);

export default connect(
	(state) => ({
		eventIds: selector(state, "eventIds"),
		currentTicketTypes: selector(state, "ticketTypes"),
		merchOpts: selector(state, "merchandiseOptions"),
		merchandise: state.merchandise,
		userEvents: state.userEvents,
		currentUser: state.auth,
		locations: state.locations,
		overrideLocation: state.locations.overrideLocation
	}),
	{
		updateMerch,
		createMerch,
		getUserEvents,
		getEventTicketTypes
	}
)(withRouterHooks(MerchandiseForm));
