import { Button, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getTicketSalesSummary,
	getTicketSalesSummaryCsv,
	resetTicketSalesSummary
} from "@/state/actions/adminReports/financeActions";
import DatesSelector from "../../DatesSelector";
import DayEndsAt from "../DayEndsAt";
import FundLocationFilters from "../FundLocationFilters";

class TicketSalesSummary extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getTicketSalesSummary } = this.props;
		getTicketSalesSummary(values.startDate, values.endDate, values.dayEndsAt, values.fundsLocation);
	}
	retry() {
		const { resetTicketSalesSummary } = this.props;
		resetTicketSalesSummary();
	}

	downloadCsv() {
		const { startDate, endDate, fundsLocation, dayEndsAt, getTicketSalesSummaryCsv } = this.props;
		getTicketSalesSummaryCsv(startDate, endDate, dayEndsAt, fundsLocation);
	}

	runLoad = () => {
		const { startDate, endDate, dayEndsAt, fundsLocation } = this.props;
		this.load({ startDate, endDate, dayEndsAt, fundsLocation });
	};

	render() {
		const { touch, handleSubmit, ticketSalesSummary, startDate, endDate, dayEndsAt } = this.props;

		let columns = [];
		if (ticketSalesSummary.data) {
			const totalsRow = ticketSalesSummary.data.byDate[ticketSalesSummary.data.byDate.length - 1];
			for (var gatewayKey in totalsRow) {
				const gatewayResults = totalsRow[gatewayKey];
				if (gatewayResults.total) {
					columns.push({
						title: gatewayKey,
						dataIndex: gatewayKey,
						key: gatewayKey,
						render: (value) => {
							return <span>${value.total}</span>;
						}
					});
				}
			}
			columns = columns.sort((a, b) => a.title.localeCompare(b.title));
			columns.unshift({
				title: "Date",
				dataIndex: "_id",
				key: "_id"
			});
			columns.push({
				title: "total",
				dataIndex: "total",
				key: "total",
				render: (value) => {
					return <span>${value}</span>;
				}
			});
		}

		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Ticket Sales Summary">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView loading={ticketSalesSummary.loading} error={ticketSalesSummary.error} retryAction={this.retry}>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								<DayEndsAt />
							</div>

							<div style={{ width: 60 }}>
								{ticketSalesSummary.data ? (
									<IconButton
										icon="export"
										type="background"
										showTooltip
										tooltip="Export"
										onClick={this.downloadCsv}
										ariaLabel="Export"
									/>
								) : null}
							</div>
						</div>
						<FundLocationFilters />
						<Button
							type="primary"
							onClick={this.runLoad}
							disabled={!startDate || !endDate || !dayEndsAt}
							ariaLabel="Load"
						>
							Load
						</Button>
					</ReportFilters>

					<ReportContent>
						{ticketSalesSummary.data ? (
							<div>
								<Table columns={columns} dataSource={ticketSalesSummary.data.byDate} rowKey="_id" pagination={false} />
								<ExportBar buttonText="Export" buttonClick={this.downloadCsv} />
							</div>
						) : null}
					</ReportContent>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "ticketSalesSummary";
const selector = formValueSelector(formName);
TicketSalesSummary = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(TicketSalesSummary);

export default connect(
	(state) => ({
		initialValues: {
			fundsLocation: "all",
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		ticketSalesSummary: state.adminReports.finance.ticketSalesSummary,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		fundsLocation: selector(state, "fundsLocation"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{ getTicketSalesSummary, getTicketSalesSummaryCsv, resetTicketSalesSummary }
)(TicketSalesSummary);
