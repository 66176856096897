import "url-search-params-polyfill";
import history from "../history";

const splitListParams = ["financialStatus"];

function getUrlParameter(name) {
	const params = getAllUrlParameters();
	return params.get(name) || undefined;
}

function getAllUrlParameters() {
	return new URLSearchParams(window.location.search);
}

function getSearchStateQuerystring(search) {
	const queryString = Object.keys(search)
		.filter((key) => !search[key] || search[key].length !== 0)
		.map((key) => `${key}=${encodeURIComponent(search[key])}`)
		.join("&");
	return `?${encodeURI(queryString)}`;
}

function pushSearchStateIntoQuerystring(search) {
	const queryString = getSearchStateQuerystring(search);
	history.push({ search: queryString });
}

function getSearchStateFromUrl(search = {}) {
	const newSearch = { ...search };
	for (let key of getAllUrlParameters().keys()) {
		const param = getUrlParameter(key);
		const value = param ? decodeURIComponent(param) : param;
		if (value) {
			newSearch[key] = key.endsWith("Ids") || key.endsWith("Dates") ? [...value.split(",")] : value;
		}
		if (value && splitListParams.includes(key)) {
			newSearch[key] = [...value.split(",")];
		}
	}
	return newSearch;
}

function goToPathWithSearchState(pathname, initialSearch = {}) {
	const search = getSearchStateFromUrl(initialSearch);
	const queryString = getSearchStateQuerystring(search);
	history.push({ pathname, search: queryString });
}

export { getSearchStateFromUrl, getUrlParameter, goToPathWithSearchState, pushSearchStateIntoQuerystring };
