import { SocialLinkModal } from "@/features/collections/configPanel/infoTab//SocialLinkModal";
import { SocialLinkRowItem } from "@/features/collections/configPanel/infoTab//SocialLinkRowItem";
import { Button } from "@/ui";
import { Form, FormInstance } from "@/ui/antd";
import { PlusOutlined } from "@ant-design/icons";
import { Collection } from "@hx/console";
import { FC, useState } from "react";

type SocialLinkListProps = {
	form: FormInstance;
};

export const SocialLinkList: FC<SocialLinkListProps> = ({ form }) => {
	const [newLinkModalOpen, setNewLinkModalOpen] = useState(false);
	const formValues = Form.useWatch<Collection>([], form);

	const handleNewSocialLink = () => setNewLinkModalOpen(true);

	const handleCloseNewLinkModal = () => setNewLinkModalOpen(false);

	const handleFinishNewLink = (values: Collection["info"]["socialLinks"][0]) => {
		const existingInfo = form.getFieldValue("info") as Collection["info"];
		form.setFieldsValue({
			info: {
				socialLinks: [...existingInfo.socialLinks, values]
			}
		});
		handleCloseNewLinkModal();
	};

	const handleResetSocialLink = (index: number) => {
		const existingInfo = form.getFieldValue("info") as Collection["info"];
		form.setFieldsValue({
			info: {
				socialLinks: existingInfo.socialLinks.map((item, i) => (i === index ? { ...item, url: "" } : item))
			}
		});
	};

	return (
		<>
			<Form.List name={["info", "socialLinks"]}>
				{(fields, { remove }) => {
					return (
						<>
							{fields.map((field, index) => {
								return (
									<Form.Item key={field.key} style={{ marginBottom: 0 }}>
										<SocialLinkRowItem
											form={form}
											index={index}
											onDelete={() => {
												const linkItem = formValues?.info.socialLinks[index];
												linkItem?.type === "custom" ? remove(index) : handleResetSocialLink(index);
											}}
										/>
									</Form.Item>
								);
							})}

							<br />
							<Button onClick={handleNewSocialLink} iconLeft={<PlusOutlined />} variant="tertiary">
								Custom link
							</Button>
						</>
					);
				}}
			</Form.List>

			<SocialLinkModal
				initialValues={{ name: "", url: "", type: "custom" }}
				onCancel={handleCloseNewLinkModal}
				onSubmit={handleFinishNewLink}
				open={newLinkModalOpen}
			/>
		</>
	);
};
