import { Steps } from "@/ui/antd";
import { Component } from "react";

const Step = Steps.Step;

class BuldUploadSteps extends Component {
	constructor(props) {
		super(props);
		let desktopVersion = this.getDesktopVersion();
		this.state = {
			desktopVersion
		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		const isBrowser = typeof window !== "undefined";
		if (isBrowser) {
			window.addEventListener("resize", this.handleResize, { passive: true });
		}
		this.handleResize();
	}

	componentWillUnmount() {
		const isBrowser = typeof window !== "undefined";
		if (isBrowser) {
			window.removeEventListener("resize", this.handleResize);
		}
	}

	getDesktopVersion() {
		const isBrowser = typeof window !== "undefined";
		const windowWidth = isBrowser ? window.innerWidth : 1200;
		return windowWidth > 700;
	}

	handleResize() {
		const { desktopVersion } = this.state;
		let newDesktopVersion = this.getDesktopVersion();
		if (newDesktopVersion !== desktopVersion) {
			this.setState({
				desktopVersion: newDesktopVersion
			});
		}
	}

	render() {
		const { desktopVersion } = this.state;
		const { currentStep } = this.props;
		return (
			<Steps
				current={currentStep}
				style={{ margin: "0 auto 12px auto", maxWidth: 600 }}
				labelPlacement={desktopVersion ? "horizontal" : "vertical"}
			>
				<Step title="Load CSV" />
				<Step title="Map Fields" />
				<Step title="Confirm" />
			</Steps>
		);
	}
}

export default BuldUploadSteps;
