import { css } from "@emotion/css";
import { DateFormats, HumaniDate } from "@hx/dates";
import { Format } from "@hx/utilities";
import { Col, Row, Table, Tooltip } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import crossIcon from "@/assets/ic_cancel.svg";
import tickIcon from "@/assets/ic_success-circle.svg";
import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import OrderStatus from "@/components/OrderStatus";
import PageActions from "@/components/PageActions";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, H2, H3, H5, P } from "@/components/text/Text";
import { getOrder, openGiftCardsRefundsModal, sendOrder } from "@/state/actions/orderActions";
import { currency, fullName, sentenceCase } from "@/utils/Format";
import { subtract } from "@/utils/Math";
import InvoiceStatus from "./InvoiceStatus";
import OrderActions from "./OrderActions";
import { ResendOrderModal } from "./ResendOrderModal";
import SwapModal from "./SwapModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const { gateway } = Format;

const valueCel = {
	textAlign: "middle"
};

const OrderFinancialStatus = {
	Paid: "paid",
	PartiallyRefunded: "partiallyRefunded",
	Refunded: "refunded",
	Free: "free",
	NotApplicable: "notApplicable",
	RequiresPayment: "requires payment"
};

class OrderWrapper extends Component {
	getEventOrderActions = () => {
		const { currentEvent } = this.props;
		const { order } = this.props.order;
		return (
			<OrderActions
				eventId={currentEvent.event._id}
				event={currentEvent.event}
				order={order}
				swapModalPresent
				useButton
				hideView
			/>
		);
	};

	getGlobalOrderActions = () => {
		const { currentEvent } = this.props;
		const { order } = this.props.order;

		return (
			<OrderActions
				eventId={currentEvent._id}
				event={currentEvent.event}
				order={order}
				refundModalAction={this.globalShowRefundsModel}
				swapModalPresent={false}
				useButton
				hideView
				hideEdit
			/>
		);
	};

	determineIsGlobal = () => {
		return this.props.location.pathname.includes("gift-cards");
	};

	getGiftCardTable = () => {
		const { order } = this.props.order;
		const hasGiftCard = order && order.giftCards && order.giftCards.length > 0;
		if (hasGiftCard) {
			return order.giftCards.map((giftCard, i) => {
				const { _id, sales, amountUsed, giftCardCode, price } = giftCard;
				const balance = subtract(price, amountUsed);
				return (
					<div key={_id}>
						<h3>{`${i + 1}.${giftCard.giftCardTypeName} - Code: ${giftCardCode}`}</h3>
						<GiftCardOrderTable giftCardSales={sales} balance={balance} location={order.location} />
					</div>
				);
			});
		}
		return null;
	};

	render() {
		const isGlobal = this.determineIsGlobal();
		let conditionalProps = {};
		if (isGlobal) {
			conditionalProps = {
				getOrderActions: this.getGlobalOrderActions,
				getExtraData: this.getGiftCardTable
			};
		} else {
			conditionalProps = {
				getOrderActions: this.getEventOrderActions,
				getExtraData: () => {
					null;
				}
			};
		}
		return <Order {...this.props} {...conditionalProps} isGlobal={isGlobal} />;
	}
}

class Order extends Component {
	componentDidMount() {
		this.load();
	}

	load() {
		const { params } = this.props;
		const orderId = params?.orderId;
		this.props.getOrder(orderId);
	}

	goToRefunds = () => {
		const { navigate, location } = this.props;
		navigate(`${location.pathname.replace(/\/$/, "")}/refund`);
	};

	componentDidUpdate(preProps) {
		const { params } = this.props;
		const orderId = params?.orderId;
		const preOrderId = preProps.params?.orderId;
		if (orderId && preOrderId !== orderId) {
			this.load();
		}
	}

	getFinancialStatusColor = (financialStatus) => {
		switch (financialStatus) {
			case OrderFinancialStatus.Paid:
				return "#157e77";
			case OrderFinancialStatus.Free:
				return "#157e77";
			case OrderFinancialStatus.RequiresPayment:
				return "#f5222d";
			default:
				return "#5a626c";
		}
	};

	render() {
		const { getOrderActions, getExtraData, currentEvent, isAdmin } = this.props;
		const { order, loading, error, swapModalOpen } = this.props.order;

		const ticketsMap = new Map();
		if (order && order.tickets) {
			order.tickets.forEach((t) => {
				ticketsMap.set(t._id, t);
			});
		}
		const hasTickets = order && order.tickets && order.tickets.length > 0;
		const hasMerch = order && order.merchandise && order.merchandise.length > 0;
		const hasGiftCard = order && order.giftCards && order.giftCards.length > 0;

		const styleOverwrites = css({
			h3: {
				fontSize: 14
			},
			th: {
				fontSize: 14
			},
			td: {
				fontSize: 14
			}
		});
		const hubspotEventData = currentEvent?.event?.metadata?.hubspot;
		const hubspotOrderData = order?.metadata?.hubspot;
		const includedCustomTaxRefundsSuffix = currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.name
			? ` (${currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.name} included)`
			: "";

		return (
			<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
				{order ? (
					<div>
						{order.totals && (
							<>
								{currentEvent?.event ? <SwapModal visible={swapModalOpen.open} /> : null}

								<PageActions>
									<div style={{ float: "right" }} />
								</PageActions>
							</>
						)}
						<H1>
							{order.type} Order: {order.orderName} <InvoiceStatus order={order} />
						</H1>
						<div className={styleOverwrites}>
							<ContentBlock>
								<Row>
									<Col span={12}>
										<H3>
											Order Date: {new HumaniDate(order.createdAt, "guess", order.location).formatting.dateTime()}
										</H3>
										{order.eventDate ? <H3>Event Date: {order.eventDisplayDate}</H3> : null}
										<H3>
											Gateway:{" "}
											{isAdmin
												? `${gateway(order.paymentGateway)} (${order.paymentGateway})`
												: gateway(order.paymentGateway)}
										</H3>
										{isAdmin && order.chargeBackAt ? (
											<H3 style={{ color: "#c0212c" }}>
												Charged Back On:{" "}
												{new HumaniDate(order.chargeBackAt, "guess", order.location).formatting.date(
													DateFormats.Pretty
												)}
											</H3>
										) : null}
									</Col>
									<Col span={12} style={{ textAlign: "right" }}>
										<H5>
											Status: <OrderStatus status={order.status} />
										</H5>
										<H5>
											Financial status:{" "}
											<span
												style={{
													color: this.getFinancialStatusColor(order.financialStatus)
												}}
											>
												{sentenceCase(order.financialStatus)}
											</span>
										</H5>
										{(hubspotEventData?.enabled || hubspotOrderData?.enabled) && (
											<H5>
												HubSpot synced:
												<Tooltip
													title={`${
														hubspotEventData?.lastSync
															? `Event synced to HubSpot at: ${new HumaniDate(
																	hubspotEventData.lastSync,
																	"guess",
																	order.location
															  ).formatting.dateTime()}.`
															: "Event has not yet been synced to HubSpot."
													}
                          ${
														hubspotOrderData?.lastSync
															? `Order synced to HubSpot at: ${new HumaniDate(
																	hubspotOrderData.lastSync,
																	"guess",
																	order.location
															  ).formatting.dateTime()}.`
															: "Order has not yet been synced to HubSpot."
													}`}
												>
													<img
														src={hubspotEventData?.lastSync && hubspotOrderData?.lastSync ? tickIcon : crossIcon}
														width="24"
														height="24"
														style={{ marginLeft: 6 }}
														alt="success"
													/>
												</Tooltip>
											</H5>
										)}
										{order.totals && getOrderActions()}
										<ResendOrderModal />
									</Col>
								</Row>

								{order.paymentType ? <H3>Payment Type: {order.paymentType}</H3> : null}
								<Row>
									<Col span={12}>
										<H3>
											Customer
											<br />
											{order.fullName}
											<br />
											{order.email}
											<br />
											{order.mobile}
										</H3>
									</Col>
									<Col span={12}>
										<H3>Notes</H3>
										<P>{order.notes ? order.notes : "-"}</P>
									</Col>
								</Row>
								<div style={{ overflowX: "auto", minWidth: 234, width: "100%" }}>
									{hasTickets ? (
										<div style={{ marginBottom: 16 }}>
											<H2>Tickets</H2>
											<TicketsTable tickets={order.tickets} />
										</div>
									) : null}

									{hasMerch ? (
										<div style={{ marginBottom: 16 }}>
											<H2>Add-ons</H2>
											<MerchandiseTable merchandise={order.merchandise} />
										</div>
									) : null}
									{hasGiftCard ? (
										<div style={{ marginBottom: 16 }}>
											<H2>Gift cards</H2>
											<GiftCardTable giftCards={order.giftCards} />
										</div>
									) : null}
									{getExtraData()}
								</div>
								{order.status === "complete" || (order.totals && isAdmin) ? (
									<table style={{ marginBottom: 12, fontSize: 16 }} className="full-width-table">
										<tbody>
											<tr style={{ background: "#f9f9fa" }}>
												<th colSpan="4">Totals</th>
											</tr>
											{order.totals.clientDonation ? (
												<tr className="border">
													<td colSpan="3">Donation</td>
													<td style={valueCel}>{currency(order.purchaseTotals.clientDonation)}</td>
												</tr>
											) : null}
											<tr className="border">
												<td colSpan="3">Subtotal</td>
												<td style={valueCel}>{currency(order.purchaseTotals.subtotal)}</td>
											</tr>
											{order.totals.discounts ? (
												<tr>
													<td colSpan="3">Discounts</td>
													<td style={valueCel}>{currency(order.purchaseTotals.discounts)}</td>
												</tr>
											) : null}
											{order.purchaseTotals.giftCardCredit ? (
												<tr>
													<td colSpan="3">Gift card credit used</td>
													<td style={valueCel}>{currency(order.purchaseTotals.giftCardCredit)}</td>
												</tr>
											) : null}
											{order.purchaseTotals.credit ? (
												<tr>
													<td colSpan="3">Credit used</td>
													<td style={valueCel}>{currency(order.purchaseTotals.credit)}</td>
												</tr>
											) : null}
											{order.vouchers
												? order.vouchers.map((voucher) => {
														return (
															<tr>
																<td colSpan="3">
																	Voucher used ({voucher.type} {voucher.code})
																</td>
																<td style={valueCel}>{currency(order.purchaseTotals.voucher)}</td>
															</tr>
														);
												  })
												: null}
											{order.totals.taxes ? (
												<tr>
													<td colSpan="3">Taxes (Included)</td>
													<td style={valueCel}>{currency(order.purchaseTotals.taxes)}</td>
												</tr>
											) : null}
											<tr>
												<td colSpan="3">Booking Fee Taxes (Included)</td>
												<td style={valueCel}>{currency(order.purchaseTotals.bookingTaxes)}</td>
											</tr>
											<tr>
												<td colSpan="3">
													Humanitix fees{" "}
													{order.purchaseTotals.feesIncluded ? (
														<span>
															(Included
															{order.purchaseTotals.amexFee ? ", Amex surcharge is added on" : null})
														</span>
													) : (
														""
													)}
												</td>
												<td style={valueCel}>{currency(order.purchaseTotals.humanitixFee)}</td>
											</tr>
											{order.purchaseTotals.amexFee ? (
												<tr>
													<td colSpan="3">Amex Surcharge</td>
													<td style={valueCel}>{currency(order.purchaseTotals.amexFee)}</td>
												</tr>
											) : null}
											{order.purchaseTotals.zipFee ? (
												<tr>
													<td colSpan="3">Zip fees</td>
													<td style={valueCel}>{currency(order.purchaseTotals.zipFee)}</td>
												</tr>
											) : null}
											{order.totalsV2.fees.additionalFee.afterpayFee.totalFee.total.afterTax ? (
												<tr>
													<td colSpan="3">Afterpay fees</td>
													<td style={valueCel}>
														{currency(order.totalsV2.fees.additionalFee.afterpayFee.totalFee.total.afterTax)}
													</td>
												</tr>
											) : null}
											{order.refunds ? (
												<tr>
													<td colSpan="3">Refunds{includedCustomTaxRefundsSuffix}</td>
													<td style={valueCel}>{currency(order.totals.refunds)}</td>
												</tr>
											) : null}
											{order.totals.rebates ? (
												<tr>
													<td colSpan="3">Rebates</td>
													<td style={valueCel}>{currency(order.totals.rebates)}</td>
												</tr>
											) : null}
											{order.refunds ? (
												<tr>
													<td colSpan="3">Refunded Taxes (Included)</td>
													<td style={valueCel}>
														{currency(order.purchaseTotals.totalTaxes - order.totals.totalTaxes)}
													</td>
												</tr>
											) : null}
											{order.refunds ? (
												<tr>
													<td colSpan="3">Refunded Fees</td>
													<td style={valueCel}>
														{currency(order.purchaseTotals.bookingFee - order.totals.bookingFee)}
													</td>
												</tr>
											) : null}
											{order?.xCover?.status === "purchased" || order?.xCover?.status === "accepted" ? (
												<tr>
													<td colSpan="3">
														{isAdmin ? (
															<a
																href={`https://partners.xcover.com/bookings/${order.xCover.ins}`}
																target="_blank"
																rel="noreferrer"
															>
																Refund protection
															</a>
														) : (
															"Refund protection"
														)}
													</td>
													<td style={valueCel}>{currency(order.xCover.totalsV2.total)}</td>
												</tr>
											) : null}

											{order.refunds && order.totals.clientDonation ? (
												<tr>
													<td colSpan="3">Refunded Donations</td>
													<td style={valueCel}>
														{currency(order.purchaseTotals.clientDonation - order.totals.clientDonation)}
													</td>
												</tr>
											) : null}
											{order.refunds && order.purchaseTotals.giftCardCredit ? (
												<tr>
													<td colSpan="3">Remaining gift card credit</td>
													<td style={valueCel}>{currency(order.totals.giftCardCredit)}</td>
												</tr>
											) : null}
											{order.refunds && order.purchaseTotals.credit ? (
												<tr>
													<td colSpan="3">Refunded credit</td>
													<td style={valueCel}>
														{currency(subtract(order.purchaseTotals.credit, order.totals.credit))}
													</td>
												</tr>
											) : null}
											{order?.totalsV2?.customSalesTax?.passOn ? (
												<tr>
													<td colSpan="3">
														{currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.name || "Custom sales tax"}
													</td>
													<td style={valueCel}>{currency(order.totalsV2.customSalesTax.passOn)}</td>
												</tr>
											) : null}
											<tr>
												<td colSpan="3">Net Sales</td>
												<td style={valueCel}>{currency(order.totals.netSales)}</td>
											</tr>

											<tr className="border" style={{ backgroundColor: "#f9f9fa" }}>
												<td colSpan="3">
													<b>Total</b>
												</td>
												<td style={valueCel}>
													<b>{currency(order.totals.total)}</b>
												</td>
											</tr>
										</tbody>
									</table>
								) : null}
							</ContentBlock>
						</div>
						{order.totals && (
							<FormBar>
								<LegacyButton
									type="warning"
									ariaLabel="Cancel/refund..."
									size="large"
									style={{ float: "right" }}
									onClick={this.goToRefunds}
								>
									Cancel/refund...
								</LegacyButton>
							</FormBar>
						)}
						{order.refunds && order.refunds.length ? (
							<ContentBlock>
								<H2>Refunds</H2>
								{order.refunds.map((r) => {
									return (
										<div key={r._id}>
											<b>Created At:</b> {r.createdAtDisplay}
											<br />
											<b>Amount:</b> {currency(r.amount)}
											<br />
											{r.clientDonation ? (
												<span>
													<b>Donation:</b> {currency(r.clientDonation)}
													<br />
												</span>
											) : (
												""
											)}
											<b>Fee:</b> {currency(r.fee)}
											<br />
											<b>Total:</b> {currency(r.total)}
											<br />
											{r.reason ? (
												<span>
													<b>Reason:</b> {r.reason}
													<br />
												</span>
											) : null}
											<hr />
										</div>
									);
								})}
							</ContentBlock>
						) : null}
					</div>
				) : (
					""
				)}
			</LoadErrorView>
		);
	}
}

const TicketsTable = ({ tickets }) => {
	const resoldTo = tickets.some((ticket) => ticket.resoldToInfo)
		? [
				{
					title: "Resold To",
					key: "resoldToInfo",
					dataIndex: "resoldToInfo",
					render: (resoldToInfo) => {
						if (!resoldToInfo) {
							return null;
						}
						return <Link to={resoldToInfo.url}>{resoldToInfo.orderName}</Link>;
					}
				}
		  ]
		: [];
	const resoldFrom = tickets.some((ticket) => ticket.resoldFromInfo)
		? [
				{
					title: "Resold From",
					key: "resoldFromInfo",
					dataIndex: "resoldFromInfo",
					render: (resoldFromInfo) => {
						if (!resoldFromInfo) {
							return null;
						}
						return <Link to={resoldFromInfo.url}>{resoldFromInfo.orderName}</Link>;
					}
				}
		  ]
		: [];
	const swappedTo = tickets.some((ticket) => ticket.swappedToInfo)
		? [
				{
					title: "Swapped To",
					key: "swappedToInfo",
					dataIndex: "swappedToInfo",
					render: (swappedToInfo) => {
						if (!swappedToInfo) {
							return null;
						}
						return <Link to={swappedToInfo.url}>{swappedToInfo.orderName}</Link>;
					}
				}
		  ]
		: [];
	const swappedFrom = tickets.some((ticket) => ticket.swappedFromInfo)
		? [
				{
					title: "Swapped From",
					key: "swappedFromInfo",
					dataIndex: "swappedFromInfo",
					render: (swappedFromInfo) => {
						if (!swappedFromInfo) {
							return null;
						}
						return <Link to={swappedFromInfo.url}>{swappedFromInfo.orderName}</Link>;
					}
				}
		  ]
		: [];
	const seatInfo = tickets.some((ticket) => ticket.seatingLocation && ticket.seatingLocation.name)
		? [
				{
					title: "Seat",
					key: "seatingLocation.name",
					dataIndex: ["seatingLocation", "name"]
				}
		  ]
		: [];

	const refundStatus = tickets.some((ticket) => ticket.status === "resold" && ticket.total - ticket.fee > 0)
		? [
				{
					title: "Refund status",
					key: "refundStatus",
					dataIndex: "refundStatus",
					render: (_, ticket) =>
						ticket.status === "resold" && ticket.total - ticket.fee > 0 ? "Resold - not refunded" : ""
				}
		  ]
		: [];

	const columns = [
		{
			title: "Ticket",
			key: "ticketTypeName",
			dataIndex: "ticketTypeName"
		},
		{
			title: "Name",
			key: "buyerName",
			dataIndex: "buyerName",
			render: (_, ticket) => fullName(ticket.firstName, ticket.lastName)
		},
		...seatInfo,
		{
			title: "Status",
			key: "status",
			dataIndex: "status"
		},
		...resoldFrom,
		...resoldTo,
		...refundStatus,
		...swappedFrom,
		...swappedTo,
		{
			title: "Discount",
			key: "totalsV2.discount",
			dataIndex: ["totalsV2", "discount"],
			render: (discount) => currency(discount)
		},
		{
			title: "Net price",
			key: "totalsV2.netSales",
			dataIndex: ["totalsV2", "netSales"],
			render: (netSales) => currency(netSales)
		},
		{
			title: "Fee",
			key: "totalsV2.fees.totalFees.totalFee.total.afterTax",
			dataIndex: ["totalsV2", "fees", "totalFees", "totalFee", "total", "afterTax"],
			render: (fee) => currency(fee)
		},
		{
			title: "Total",
			key: "totalsV2.total",
			dataIndex: ["totalsV2", "total"],
			render: (total) => currency(total)
		}
	];

	return <Table columns={columns} dataSource={tickets} pagination={false} />;
};

const MerchandiseTable = ({ merchandise }) => {
	const swappedTo = merchandise.some((ticket) => ticket.swappedToInfo)
		? [
				{
					title: "Swapped To",
					key: "swappedToInfo",
					dataIndex: "swappedToInfo",
					render: (swappedToInfo) => {
						if (!swappedToInfo) {
							return null;
						}
						return <Link to={swappedToInfo.url}>{swappedToInfo.orderName}</Link>;
					}
				}
		  ]
		: [];
	const swappedFrom = merchandise.some((ticket) => ticket.swappedFromInfo)
		? [
				{
					title: "Swapped From",
					key: "swappedFromInfo",
					dataIndex: "swappedFromInfo",
					render: (swappedFromInfo) => {
						if (!swappedFromInfo) {
							return null;
						}
						return <Link to={swappedFromInfo.url}>{swappedFromInfo.orderName}</Link>;
					}
				}
		  ]
		: [];
	const columns = [
		{
			title: "Add-ons",
			key: "merchandiseTypeName",
			dataIndex: "merchandiseTypeName",
			render: (merchandiseTypeName, record) => {
				const parts = [merchandiseTypeName];
				if (record.merchandiseOptionName) {
					parts.push(`(${record.merchandiseOptionName})`);
				}
				return parts.join(" ");
			}
		},
		{
			title: "Status",
			key: "status",
			dataIndex: "status"
		},
		...swappedFrom,
		...swappedTo,
		{
			title: "Discount",
			key: "totalsV2.discount",
			dataIndex: ["totalsV2", "discount"],
			render: (discount) => currency(discount)
		},
		{
			title: "Net price",
			key: "totalsV2.netSales",
			dataIndex: ["totalsV2", "netSales"],
			render: (netSales) => currency(netSales)
		},
		{
			title: "Fee",
			key: "totalsV2.fees.totalFees.totalFee.total.afterTax",
			dataIndex: ["totalsV2", "fees", "totalFees", "totalFee", "total", "afterTax"],
			render: (fee) => currency(fee)
		},
		{
			title: "Total",
			key: "totalsV2.total",
			dataIndex: ["totalsV2", "total"],
			render: (total) => currency(total)
		}
	];

	return <Table columns={columns} dataSource={merchandise} pagination={false} />;
};

const GiftCardOrderTable = ({ giftCardSales = [], balance, location }) => {
	return (
		<table style={{ marginBottom: 26, fontSize: 16 }} className="full-width-table">
			{giftCardSales.length ? (
				<thead style={{ background: "#fafafa" }}>
					<tr>
						<th>Used at</th>
						<th>Order ID</th>
						<th>Date used</th>
						<th>Value used</th>
					</tr>
				</thead>
			) : null}
			{giftCardSales.map((sale, i) => {
				const { eventName, orderName, totals, completedAt } = sale;
				return (
					<tbody key={i}>
						<tr>
							<td>{eventName}</td>
							<td style={valueCel}>{`${orderName}`}</td>
							<td style={valueCel}>
								{new HumaniDate(completedAt, "guess", location).formatting.date(DateFormats.Pretty)}
							</td>
							<td style={valueCel}>{`${currency(totals.giftCardCredit || totals.credit)}`}</td>
						</tr>
					</tbody>
				);
			})}
			<tfoot>
				<tr style={{ background: "#fafafa" }}>
					<th>Balance</th>
					<th />
					<th />
					<th>{`${currency(balance)}`}</th>
				</tr>
			</tfoot>
		</table>
	);
};

const GiftCardTable = ({ giftCards, valueCel }) => {
	return (
		<table style={{ marginBottom: 26, fontSize: 16 }} className="full-width-table">
			<thead style={{ background: "#F9F9FA" }}>
				<tr>
					<th>Gift cards</th>
					<th>Status</th>
					<th>Discount</th>
					<th>Net Price</th>
					<th>Fee</th>
					<th>Total</th>
				</tr>
			</thead>
			{giftCards.map((giftCard) => {
				const name = giftCard.giftCardTypeName;
				return (
					<tbody key={giftCard._id}>
						<tr>
							<td>
								{name} {currency(giftCard.price)}
							</td>
							<td style={valueCel}>{`${giftCard.status}`}</td>
							<td style={valueCel}>{`${currency(giftCard.discount)}`}</td>
							<td style={valueCel}>{`${currency(giftCard.netPrice)}`}</td>
							<td style={valueCel}>{`${currency(giftCard.fee)}`}</td>
							<td style={valueCel}>{`${currency(giftCard.total)}`}</td>
						</tr>
					</tbody>
				);
			})}
		</table>
	);
};

export default connect(
	(state) => ({
		order: state.order,
		currentEvent: state.currentEvent,
		isAdmin: state.auth.user?.isAdmin
	}),
	{
		getOrder,
		sendOrder,
		openGiftCardsRefundsModal
	}
)(withRouterHooks(OrderWrapper));
