import { css } from "@emotion/css";
import React, { FC, ReactNode } from "react";
import PageHeading from "@/components/PageHeading";

const defaultPageStyle = (type: string, pagePadding: string) =>
	css({
		padding: pagePadding,
		margin: "0 auto",
		display: "block",
		width: "100%",
		position: "relative",
		maxWidth: type === "details" ? "70vw" : "1800px",
		"@media (max-width: 868px)": {
			padding: 14,
			maxWidth: "100vw"
		}
	});

type PageProps = {
	breadcrumb?: ReactNode;
	children: ReactNode;
	help?: { link: string; title: string };
	pageName?: string | ReactNode;
	pageStyle?: string;
	sibling?: React.ReactNode;
	type?: string | null;
};

const Page: FC<PageProps> = ({ breadcrumb, children, help, pageName, pageStyle, sibling, type }) => {
	let pagePadding = type === "details" ? "24px 0" : "24px 0";
	pagePadding = breadcrumb ? "16px 0 24px" : pagePadding;

	const styles = pageStyle ? pageStyle : defaultPageStyle(type ? type : "default", pagePadding);

	return (
		<div className={styles}>
			{breadcrumb ? breadcrumb : null}
			{pageName ? (
				<PageHeading title={pageName} helpButton={help}>
					{sibling}
				</PageHeading>
			) : null}
			<div>{children}</div>
		</div>
	);
};
export default Page;
