const Theme = (props) => {
	const { primaryColor, footerColor, eventCardBackgroundColor, background } = props;

	return (
		<svg width="100%" height="100%" viewBox="0 0 162 220" version="1.1">
			<g id="Tour-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Tour-styling-themes" transform="translate(-634.000000, -437.000000)">
					<g id="Group-19" transform="translate(635.000000, 438.000000)">
						<rect
							id="Rectangle-4-Copy"
							stroke="#DEDEDE"
							strokeWidth="1.0858916"
							fill="#FFFFFF"
							x="0"
							y="-1.42108547e-14"
							width="160"
							height="218.222222"
							rx="1.77777778"
						/>
						<rect
							id="Rectangle-4"
							stroke="#DEDEDE"
							strokeWidth="0.5"
							fill={background.color}
							x="4.69444444"
							y="4.69444444"
							width="150.611111"
							height="208.833333"
						/>
						<g id="Group-4" transform="translate(22.222222, 62.222222)">
							<rect
								id="Rectangle"
								fill={eventCardBackgroundColor}
								x="-3.97903932e-13"
								y="1.5187851e-13"
								width="115.555556"
								height="10.6666667"
							/>
							<rect
								id="Rectangle-26-Copy-20"
								fill="#D8D8D8"
								x="42.2222222"
								y="4.44444444"
								width="31.1111111"
								height="1.77777778"
								rx="0.888888889"
							/>
						</g>
						<g id="Group-7" transform="translate(88.888889, 158.222222)">
							<rect
								id="Rectangle-3-Copy-20"
								stroke={primaryColor}
								strokeWidth="0.5"
								fill={primaryColor}
								x="2.985581"
								y="2.13162821e-13"
								width="3.39270568"
								height="3.39270568"
								rx="0.542832909"
							/>
							<rect
								id="Rectangle-3-Copy-21"
								x="7.19253605"
								y="2.13162821e-13"
								width="3.39270568"
								height="3.39270568"
								rx="0.542832909"
							/>
							<rect
								id="Rectangle-3-Copy-22"
								x="11.3994911"
								y="2.13162821e-13"
								width="3.39270568"
								height="3.39270568"
								rx="0.542832909"
							/>
							<path
								d="M17.6261428,0.970331815 L16.7267057,1.69382901 C16.7048458,1.71144422 16.692112,1.73797316 16.692112,1.76619995 C16.692112,1.79421451 16.7048458,1.82095568 16.7267057,1.83835866 L17.6223226,2.55803568 C17.6622222,2.59008264 17.7205858,2.58371569 17.7526328,2.54381617 C17.7846797,2.50391665 17.7783128,2.44555299 17.7384133,2.41350603 L16.9327825,1.76619995 L17.7424457,1.11486147 C17.7823452,1.08281451 17.7887121,1.02445085 17.7566652,0.984551326 C17.738201,0.961842555 17.7114599,0.949957591 17.6842942,0.949957591 C17.66392,0.949957591 17.6433336,0.956748999 17.6261428,0.970331815 Z"
								id="Shape"
								fill="#323232"
								transform="translate(17.234599, 1.764207) scale(-1, 1) translate(-17.234599, -1.764207) "
							/>
							<text
								id="1-copy-7"
								fontFamily="OpenSans-Regular, Open Sans"
								fontSize="1.62666667"
								fontWeight="normal"
								line-spacing="2.44274809"
								fill="#FFFFFF"
							>
								<tspan x="4.21688827" y="2.54283291">
									1
								</tspan>
							</text>
							<text
								id="2-copy-7"
								fontFamily="OpenSans-Regular, Open Sans"
								fontSize="1.62849873"
								fontWeight="normal"
								line-spacing="2.44274809"
								fill="#323232"
							>
								<tspan x="8.42331955" y="2.54283291">
									2
								</tspan>
							</text>
							<text
								id="3-copy-6"
								fontFamily="OpenSans-Regular, Open Sans"
								fontSize="1.62849873"
								fontWeight="normal"
								line-spacing="2.44274809"
								fill="#323232"
							>
								<tspan x="12.6302746" y="2.54283291">
									3
								</tspan>
							</text>
							<path
								d="M0.934030841,0.970331815 L0.0345937348,1.69382901 C0.0127338901,1.71144422 3.91950124e-13,1.73797316 3.91950124e-13,1.76619995 C3.91950124e-13,1.79421451 0.0127338901,1.82095568 0.0345937348,1.83835866 L0.930210673,2.55803568 C0.970110196,2.59008264 1.02847386,2.58371569 1.06052082,2.54381617 C1.09256777,2.50391665 1.08620083,2.44555299 1.04630131,2.41350603 L0.240670523,1.76619995 L1.0503337,1.11486147 C1.09023323,1.08281451 1.09660017,1.02445085 1.06455321,0.984551326 C1.04608907,0.961842555 1.0193479,0.949957591 0.992182272,0.949957591 C0.971808048,0.949957591 0.951221592,0.956748999 0.934030841,0.970331815 Z"
								id="Shape"
								fill="#DEDEDE"
								transform="translate(0.542487, 1.764207) scale(1, -1) translate(-0.542487, -1.764207) "
							/>
						</g>
						<g id="Group-15" transform="translate(22.222222, 4.444444)">
							<rect id="Rectangle-5" fill="#E1ECFF" x="0" y="0" width="115.555556" height="57.7777778" />
							<g id="duplicate_1_original_Icon-24px_88373301" transform="translate(48.000000, 19.111111)">
								<circle id="Oval" fill="#D7DEE6" cx="9.77302436" cy="9.77302436" r="2.60613983" />
								<path
									d="M7.32976827,1.62883739 L5.83938206,3.25767479 L3.25767479,3.25767479 C2.36181422,3.25767479 1.62883739,3.99065161 1.62883739,4.88651218 L1.62883739,14.6595365 C1.62883739,15.5553971 2.36181422,16.2883739 3.25767479,16.2883739 L16.2883739,16.2883739 C17.1842345,16.2883739 17.9172113,15.5553971 17.9172113,14.6595365 L17.9172113,4.88651218 C17.9172113,3.99065161 17.1842345,3.25767479 16.2883739,3.25767479 L13.7066667,3.25767479 L12.2162805,1.62883739 L7.32976827,1.62883739 L7.32976827,1.62883739 Z M9.77302436,13.8451178 C7.52522876,13.8451178 5.70093088,12.02082 5.70093088,9.77302436 C5.70093088,7.52522876 7.52522876,5.70093088 9.77302436,5.70093088 C12.02082,5.70093088 13.8451178,7.52522876 13.8451178,9.77302436 C13.8451178,12.02082 12.02082,13.8451178 9.77302436,13.8451178 L9.77302436,13.8451178 Z"
									id="Shape"
									fill="#D7DEE6"
								/>
								<polygon id="Shape" points="0 0 19.5460487 0 19.5460487 19.5460487 0 19.5460487" />
							</g>
						</g>
						<g id="Group-18" transform="translate(4.888889, 170.222222)">
							<rect
								id="Rectangle-5-Copy-3"
								fill="#FFFFFF"
								x="6.11066753e-13"
								y="2.48689958e-14"
								width="150.666667"
								height="17.7777778"
							/>
							<g id="Group-16" transform="translate(36.000000, 3.555556)">
								<rect
									id="Rectangle"
									fill="#D8D8D8"
									x="0"
									y="0"
									width="17.7777778"
									height="10.6666667"
									rx="1.77777778"
								/>
								<text
									id="logo"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="6.66666667" y="6">
										logo
									</tspan>
								</text>
							</g>
							<g id="Group-17" transform="translate(56.000000, 3.555556)">
								<rect
									id="Rectangle-Copy-9"
									fill="#D8D8D8"
									x="0"
									y="0"
									width="17.7777778"
									height="10.6666667"
									rx="1.77777778"
								/>
								<text
									id="logo-copy"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="6.66666667" y="6">
										logo
									</tspan>
								</text>
							</g>
							<g id="Group-16-Copy" transform="translate(76.000000, 3.555556)">
								<rect
									id="Rectangle"
									fill="#D8D8D8"
									x="0"
									y="0"
									width="17.7777778"
									height="10.6666667"
									rx="1.77777778"
								/>
								<text
									id="logo"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="6.66666667" y="6">
										logo
									</tspan>
								</text>
							</g>
							<g id="Group-17-Copy" transform="translate(96.000000, 3.555556)">
								<rect
									id="Rectangle-Copy-9"
									fill="#D8D8D8"
									x="0"
									y="0"
									width="17.7777778"
									height="10.6666667"
									rx="1.77777778"
								/>
								<text
									id="logo-copy"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="6.66666667" y="6">
										logo
									</tspan>
								</text>
							</g>
						</g>
						<g id="Group-11" transform="translate(22.222222, 72.888889)">
							<path
								d="M0,0 L115.555556,0 L115.555556,3.55555556 C115.555556,4.53739511 114.759617,5.33333333 113.777778,5.33333333 L1.77777778,5.33333333 C0.795938223,5.33333333 1.00841909e-15,4.53739511 8.8817842e-16,3.55555556 L0,0 Z"
								id="Rectangle"
								fill={eventCardBackgroundColor}
							/>
							<rect
								id="Rectangle-26-Copy-13"
								fill="#D8D8D8"
								x="3.11111111"
								y="2.22222222"
								width="8.68713277"
								height="1.0858916"
								rx="0.542945798"
							/>
							<rect
								id="Rectangle-26-Copy-15"
								fill={primaryColor}
								x="2.66666667"
								y="4.88888889"
								width="9.57602178"
								height="1"
								rx="0.5"
							/>
							<rect
								id="Rectangle-26-Copy-14"
								fill="#D8D8D8"
								x="15.5555556"
								y="2.22222222"
								width="8.68713277"
								height="1.0858916"
								rx="0.542945798"
							/>
						</g>
						<g id="Group-12" transform="translate(22.222222, 84.000000)">
							<rect
								id="Rectangle"
								fill={eventCardBackgroundColor}
								x="0"
								y="0"
								width="115"
								height="6.22222222"
								rx="1.77777778"
							/>
							<rect
								id="Rectangle-26-Copy-16"
								fill="#D8D8D8"
								x="2.66666667"
								y="2.22222222"
								width="12.6871329"
								height="1.97478044"
								rx="0.987390222"
							/>
							<rect
								id="Rectangle-26-Copy-17"
								fill="#D8D8D8"
								x="17.7777778"
								y="2.22222222"
								width="10.9093551"
								height="1.97478044"
								rx="0.987390222"
							/>
						</g>
						<g id="Group-2" transform="translate(22.222222, 93.777778)">
							<rect
								id="Rectangle-Copy-8"
								fill={eventCardBackgroundColor}
								x="-5.04485342e-13"
								y="-9.9475983e-14"
								width="84.4444444"
								height="60.8888889"
								rx="1.77777778"
							/>
							<rect
								id="Rectangle-26-Copy-13"
								fill="#D8D8D8"
								x="2.66666667"
								y="3.55555556"
								width="17.7777778"
								height="1.0858916"
								rx="0.542945798"
							/>
							<rect
								id="Rectangle-26-Copy-20"
								fill="#D8D8D8"
								x="21.7777778"
								y="10.2222222"
								width="17.7777778"
								height="1.77777778"
								rx="0.888888889"
							/>
							<g id="Group-5" transform="translate(67.111111, 12.000000)">
								<rect
									id="Rectangle"
									fill={primaryColor}
									x="0"
									y="0"
									width="14.6666667"
									height="4.44444444"
									rx="0.888888889"
								/>
								<text
									id="Get-tickets"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="2.22222222" y="2.88888889">
										Get tickets
									</tspan>
								</text>
							</g>
							<rect
								id="Rectangle-26-Copy-21"
								fill="#D8D8D8"
								x="21.7777778"
								y="14.2222222"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<rect
								id="Rectangle-26-Copy-22"
								fill="#D8D8D8"
								x="21.7777778"
								y="16.8888889"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<rect
								id="Rectangle-26-Copy-27"
								fill="#D8D8D8"
								x="21.7777778"
								y="23.5555556"
								width="17.7777778"
								height="1.77777778"
								rx="0.888888889"
							/>
							<rect
								id="Rectangle-26-Copy-26"
								fill="#D8D8D8"
								x="21.7777778"
								y="27.5555556"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<rect
								id="Rectangle-26-Copy-25"
								fill="#D8D8D8"
								x="21.7777778"
								y="30.2222222"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<rect
								id="Rectangle-26-Copy-18"
								fill="#D8D8D8"
								x="2.66666667"
								y="12.8888889"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<rect
								id="Rectangle-26-Copy-19"
								fill="#D8D8D8"
								x="2.66666667"
								y="15.5555556"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<g id="Group-5-Copy" transform="translate(67.111111, 25.333333)">
								<rect
									id="Rectangle"
									fill={primaryColor}
									x="0"
									y="0"
									width="14.6666667"
									height="4.44444444"
									rx="0.888888889"
								/>
								<text
									id="Get-tickets"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="2.22222222" y="2.88888889">
										Get tickets
									</tspan>
								</text>
							</g>
							<rect
								id="Rectangle-26-Copy-24"
								fill="#D8D8D8"
								x="2.66666667"
								y="26.2222222"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<rect
								id="Rectangle-26-Copy-23"
								fill="#D8D8D8"
								x="2.66666667"
								y="28.8888889"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<path
								d="M0.444444444,7.55555556 L84,7.55555556"
								id="Line-2"
								stroke="#DEDEDE"
								strokeWidth="0.5"
								strokeLinecap="square"
							/>
							<path
								d="M0.444444444,20.8888889 L84,20.8888889"
								id="Line-2-Copy-2"
								stroke="#DEDEDE"
								strokeWidth="0.5"
								strokeLinecap="square"
							/>
							<path
								d="M0.444444444,34.2222222 L84,34.2222222"
								id="Line-2-Copy-3"
								stroke="#DEDEDE"
								strokeWidth="0.5"
								strokeLinecap="square"
							/>
							<rect
								id="Rectangle-26-Copy-32"
								fill="#D8D8D8"
								x="21.7777778"
								y="36.8888889"
								width="17.7777778"
								height="1.77777778"
								rx="0.888888889"
							/>
							<rect
								id="Rectangle-26-Copy-31"
								fill="#D8D8D8"
								x="21.7777778"
								y="40.8888889"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<rect
								id="Rectangle-26-Copy-30"
								fill="#D8D8D8"
								x="21.7777778"
								y="43.5555556"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<g id="Group-5-Copy-2" transform="translate(67.111111, 38.666667)">
								<rect
									id="Rectangle"
									fill={primaryColor}
									x="0"
									y="0"
									width="14.6666667"
									height="4.44444444"
									rx="0.888888889"
								/>
								<text
									id="Get-tickets"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="2.22222222" y="2.88888889">
										Get tickets
									</tspan>
								</text>
							</g>
							<rect
								id="Rectangle-26-Copy-29"
								fill="#D8D8D8"
								x="2.66666667"
								y="39.5555556"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<rect
								id="Rectangle-26-Copy-28"
								fill="#D8D8D8"
								x="2.66666667"
								y="42.2222222"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<path
								d="M0.444444444,47.5555556 L84,47.5555556"
								id="Line-2-Copy-4"
								stroke="#DEDEDE"
								strokeWidth="0.5"
								strokeLinecap="square"
							/>
							<rect
								id="Rectangle-26-Copy-37"
								fill="#D8D8D8"
								x="21.7777778"
								y="50.2222222"
								width="17.7777778"
								height="1.77777778"
								rx="0.888888889"
							/>
							<rect
								id="Rectangle-26-Copy-36"
								fill="#D8D8D8"
								x="21.7777778"
								y="54.2222222"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<rect
								id="Rectangle-26-Copy-35"
								fill="#D8D8D8"
								x="21.7777778"
								y="56.8888889"
								width="24.4444444"
								height="1.33333333"
								rx="0.666666667"
							/>
							<g id="Group-5-Copy-3" transform="translate(67.111111, 52.000000)">
								<rect
									id="Rectangle"
									fill={primaryColor}
									x="0"
									y="0"
									width="14.6666667"
									height="4.44444444"
									rx="0.888888889"
								/>
								<text
									id="Get-tickets"
									fontFamily="OpenSans-Regular, Open Sans"
									fontSize="2.22222222"
									fontWeight="normal"
									letterSpacing="-0.0518518653"
									fill="#FFFFFF"
								>
									<tspan x="2.22222222" y="2.88888889">
										Get tickets
									</tspan>
								</text>
							</g>
							<rect
								id="Rectangle-26-Copy-34"
								fill="#D8D8D8"
								x="2.66666667"
								y="52.8888889"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
							<rect
								id="Rectangle-26-Copy-33"
								fill="#D8D8D8"
								x="2.66666667"
								y="55.5555556"
								width="8.88888889"
								height="1"
								rx="0.5"
							/>
						</g>
						<g id="Group-14" transform="translate(110.222222, 93.777778)">
							<rect
								id="Rectangle-Copy-7"
								fill={eventCardBackgroundColor}
								x="0"
								y="0"
								width="27.5555556"
								height="19.1111111"
								rx="1.77777778"
							/>
							<circle id="Oval-2" fill="#D8D8D8" cx="4.39400541" cy="11.949561" r="2.17178319" />
							<circle id="Oval-2-Copy" fill="#D8D8D8" cx="10.4649105" cy="11.949561" r="2.17178319" />
							<circle id="Oval-2-Copy-2" fill="#D8D8D8" cx="16.5358157" cy="11.949561" r="2.17178319" />
							<circle id="Oval-2-Copy-4" fill="#D8D8D8" cx="22.6067208" cy="11.949561" r="2.17178319" />
							<rect
								id="Rectangle-26-Copy-13"
								fill="#D8D8D8"
								x="2.22222222"
								y="4"
								width="8.68713277"
								height="1.0858916"
								rx="0.542945798"
							/>
						</g>
						<rect
							id="Rectangle-5-Copy-2"
							fill={footerColor}
							x="4.44444444"
							y="188"
							width="151.111111"
							height="25.7777778"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Theme;
