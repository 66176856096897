import { css } from "@emotion/css";
import { Popconfirm, Switch } from "@/ui/antd";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import { ContentBlock } from "../components/AppBlocks";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import LocationSelection from "../components/LocationSelection";
import Page from "../components/Page";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import LinkButton from "../components/buttons/LinkButton";
import NoResult from "../components/noResults/NoResults";
import ResponsiveTable from "../components/table/ResponsiveTable";
import TableActions from "../components/table/TableActions";
import { P } from "../components/text/Text";
import {
	createMerch,
	getMerch,
	getMerchList,
	moveMerchInList,
	saveMerchOrder,
	updateMerch
} from "../state/actions/merchandiseActions";
import { updateSearch } from "../state/actions/searchActions";
import { HelpIds } from "../utils/Help";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const headerStyle = css({
	display: "flex",
	marginBottom: 24,
	"@media(max-width: 600px)": {
		display: "block",
		paddingBottom: 16,
		borderBottom: "1px solid",
		borderColor: "#dedede",
		marginBottom: 0
	}
});
const buttonStyle = css({
	"@media(max-width: 600px)": {
		height: 36
	}
});

class Merchandise extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.onCreate = this.onCreate.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onDisable = this.onDisable.bind(this);
		this.loadExistingMerch = this.loadExistingMerch.bind(this);
		this.onLocationChange = this.onLocationChange.bind(this);
		this.debouncedSave = debounce(500, props.saveMerchOrder);
	}

	componentDidMount() {
		this.load();
	}

	load() {
		const { getMerchList, currentUser } = this.props;
		getMerchList(currentUser.user.id);
	}

	componentDidUpdate(prevProps) {
		const locationChanged = this.props.location.search !== prevProps.location.search;
		if (locationChanged) {
			this.load();
		}
	}

	onCreate() {
		const { navigate } = this.props;
		navigate("/console/promote/add-ons/new");
	}

	onRemove(merchData) {
		const { updateMerch, currentUser } = this.props;
		const userId = currentUser.user.id;
		const merchId = merchData._id;
		updateMerch(userId, merchId, { deleted: true });
	}

	onDisable(disabled, merchData) {
		const { updateMerch, currentUser } = this.props;
		const userId = currentUser.user.id;
		const merchId = merchData._id;
		updateMerch(userId, merchId, { disabled });
	}

	loadExistingMerch(merchData) {
		const merchId = merchData._id;
		const { navigate } = this.props;
		const { getMerch, currentUser } = this.props;
		getMerch(currentUser.user.id, merchId, navigate);
	}

	onLocationChange(loc) {
		const { updateSearch } = this.props;
		updateSearch({ loc }, true);
	}

	changeOrder = (fromIndex, toIndex) => {
		this.props.moveMerchInList(fromIndex, toIndex);
		this.debouncedSave();
	};

	render() {
		const { merchandise } = this.props;
		const { loading, error, merchList } = merchandise;
		const sibling = (
			<LocationSelection componentName="merchandise" defaultLevel="user" onLocationChange={this.onLocationChange} />
		);

		const pageContent = (
			<LoadErrorView loading={loading} error={error} retryAction={this.save}>
				<ContentBlock>
					{!loading ? (
						<div>
							<MerchandiseTable
								merchList={merchList}
								onCreate={this.onCreate}
								onEdit={this.loadExistingMerch}
								onRemove={this.onRemove}
								onDisable={this.onDisable}
								changeOrder={this.changeOrder}
							/>
						</div>
					) : null}
				</ContentBlock>
			</LoadErrorView>
		);

		return (
			<Page
				pageName="Add-ons"
				sibling={sibling}
				help={{
					title: "Add-ons",
					link: HelpIds.addons
				}}
			>
				{pageContent}
			</Page>
		);
	}
}

class MerchandiseTable extends Component {
	render() {
		const { merchList, onEdit, onRemove, onCreate, onDisable, changeOrder } = this.props;
		const subTableStyling = {
			paddingTop: 8,
			textAlign: "center"
		};
		const firstElementInTable = {
			textAlign: "center"
		};
		const columns = [
			{
				title: "Item",
				dataIndex: "itemName",
				key: "itemName",
				render: (value, merch) => {
					return (
						<LinkButton
							style={{ color: "#353337" }}
							onClick={() => {
								onEdit(merch);
							}}
							ariaLabel={value}
						>
							{value}
						</LinkButton>
					);
				}
			},
			{
				title: "Options",
				dataIndex: "merchandiseOptions",
				key: "merchandiseOptions",
				render: (value, _, index) => {
					return (
						<div style={index > 0 ? subTableStyling : firstElementInTable}>
							{value.map((opt) => {
								return <div key={opt._id}>{opt.optionName}</div>;
							})}
						</div>
					);
				}
			},
			{
				title: "Price",
				dataIndex: "merchandiseOptions",
				key: "Price",
				render: (value, _, index) => {
					return (
						<div style={index > 0 ? subTableStyling : firstElementInTable}>
							{value.map((opt) => {
								return <div>{opt.price}</div>;
							})}
						</div>
					);
				}
			},
			{
				title: "Quantity",
				dataIndex: "merchandiseOptions",
				key: "quantity",
				render: (value, _, index) => {
					return (
						<div style={index > 0 ? subTableStyling : firstElementInTable}>
							{value.map((opt) => {
								return <div>{opt.quantity}</div>;
							})}
						</div>
					);
				}
			},
			{
				title: "Sold",
				dataIndex: "merchandiseOptions",
				key: "sold",
				render: (value, _, index) => {
					return (
						<div style={index > 0 ? subTableStyling : firstElementInTable}>
							{value.map((opt) => {
								return <div>{opt.sold}</div>;
							})}
						</div>
					);
				}
			},
			{
				title: "Applies to",
				dataIndex: "eventIds",
				key: "eventIds",
				render: (value) => {
					if (value.length === 0) {
						return <div>All Events</div>;
					}
					return <div>{`${value.length} events`}</div>;
				}
			},
			{
				title: "Enabled",
				dataIndex: "disabled",
				key: "disabled",
				render: (value, data) => {
					return <Switch checked={!value} onChange={(disabled) => onDisable(!disabled, data)} />;
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, merchData) => (
					<TableActions>
						<IconButton icon="edit" onClick={() => onEdit(merchData)} tooltip="Edit" showTooltip ariaLabel="Edit" />
						<Popconfirm
							title="Are you sure you want to delete this add-on?"
							onConfirm={() => onRemove(merchData)}
							okText="Yes"
							cancelText="No"
						>
							<IconButton icon="delete" tooltip="Delete" showTooltip ariaLabel="Delete" />
						</Popconfirm>
					</TableActions>
				)
			}
		];
		return (
			<div>
				<div className={headerStyle}>
					<div style={{ flexGrow: 1 }}>
						<P style={{ marginBottom: 12 }}>
							Give your fans the full experience. Offer additional items (think T-shirts, camping spots, parking passes
							and more) using Add-ons.
						</P>
					</div>
					{merchList.length ? (
						<div className={buttonStyle}>
							<ButtonsBar>
								<LegacyButton onClick={() => onCreate()} type="action" ariaLabel="Create add-on">
									Create add-on
								</LegacyButton>
							</ButtonsBar>
						</div>
					) : null}
				</div>
				<If condition={merchList.length === 0}>
					<Then>
						<NoResult noTitle message="" action={() => onCreate()} actionTxt="Create add-on" inverse />
					</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<ResponsiveTable
									columns={columns}
									scroll={{ x: 800 }}
									dataSource={merchList}
									rowKey={(merch) => merch._id}
									pagination={false}
									draggable={{ customAction: changeOrder }}
									onExpand={() => null}
									expandedRowKeys={[]}
								/>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			merchandise: state.merchandise,
			userEvents: state.userEvents,
			currentUser: state.auth
		};
	},
	{
		updateSearch,
		updateMerch,
		createMerch,
		getMerch,
		getMerchList,
		moveMerchInList,
		saveMerchOrder
	}
)(withRouterHooks(Merchandise));
