import LoadErrorView from "@/components/LoadErrorView";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import { P } from "@/components/text/Text";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { saveEvent } from "@/state/actions/eventActions";
import { Button } from "@/ui";
import { Flex, Popconfirm, Table, Tooltip } from "@/ui/antd";
import { CheckCircleTwoTone, CloseCircleTwoTone, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";

class AutomaticDiscounts extends Component {
	render() {
		const { currentEvent, navigate, saveEvent } = this.props;
		return (
			<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.loading}>
				<AutoDiscountsTable currentEvent={currentEvent} navigate={navigate} saveEvent={saveEvent} />
			</LoadErrorView>
		);
	}
}

class AutoDiscountsTable extends Component {
	constructor(props) {
		super(props);
		this.deleteAutoDiscount = this.deleteAutoDiscount.bind(this);
		this.createNew = this.createNew.bind(this);
		this.edit = this.edit.bind(this);
	}

	deleteAutoDiscount(eventId, deleteDiscount) {
		const { saveEvent, currentEvent } = this.props;
		if (currentEvent.event && currentEvent.event.autoDiscounts) {
			const autoDiscounts = currentEvent.event.autoDiscounts.filter((discount) => {
				return discount._id !== deleteDiscount._id;
			});
			saveEvent(eventId, { autoDiscounts });
		}
	}

	createNew() {
		const { navigate, currentEvent } = this.props;
		navigate(`/console/my-events/${currentEvent.event._id}/discounts/automatic/new`);
	}

	edit(id) {
		const { navigate, currentEvent } = this.props;
		navigate(`/console/my-events/${currentEvent.event._id}/discounts/automatic/${id}`);
	}

	render() {
		const { currentEvent } = this.props;

		const columns = [
			{
				title: "Name",
				dataIndex: "code",
				key: "code",
				render: (item, data) => (
					<a ariaLabel={item} onClick={() => this.edit(data._id)}>
						{item}
					</a>
				)
			},

			{
				title: "Enabled",
				dataIndex: "enabled",
				key: "enabled",
				width: 120,
				render: (value) => (
					<div style={{ marginLeft: "16px" }}>
						{value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f70e0e" />}
					</div>
				)
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, data) => (
					<Flex>
						<Tooltip title="Edit">
							<Button aria-label="Edit" iconOnly onClick={() => this.edit(data._id)} variant="text">
								<EditOutlined style={{ fontSize: 18 }} />
							</Button>
						</Tooltip>

						<Popconfirm
							title="Are you sure you want to delete this automatic discount code?"
							onConfirm={() => this.deleteAutoDiscount(currentEvent.event._id, data)}
							okText="Yes"
							cancelText="No"
						>
							<Tooltip title="Delete">
								<Button aria-label="Delete" iconOnly variant="text">
									<DeleteOutlined style={{ fontSize: 18 }} />
								</Button>
							</Tooltip>
						</Popconfirm>
					</Flex>
				)
			}
		];
		return (
			<div>
				<P>
					Trigger discounts when {currentEvent?.event?.settings?.isSecondaryTicketsEnabled ? "primary" : ""} tickets are
					bought
				</P>
				<If condition={!currentEvent.event.autoDiscounts || !currentEvent.event.autoDiscounts.length}>
					<Then>
						<NoResults
							title="You have no automatic discounts"
							message=""
							action={this.createNew}
							actionTxt="Create Automatic Discount"
							inverse
						/>
					</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<Table
									columns={columns}
									dataSource={currentEvent.event.autoDiscounts}
									rowKey="key"
									scroll={{ x: 800 }}
								/>
								<ButtonsBar label="" stackButtons>
									<Button
										aria-label="Create auto discount"
										iconLeft={<PlusOutlined />}
										onClick={this.createNew}
										variant="tertiary"
									>
										Create auto discount
									</Button>
								</ButtonsBar>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		autoDiscounts: state.autoDiscounts
	}),
	{
		saveEvent
	}
)(withRouterHooks(AutomaticDiscounts));
