import React, { FC } from "react";
import "@lottiefiles/lottie-player";

const animations = {
	wink: "https://static.humanitix.com/website/animations/wink-lottie-animation-black-on-transparent.json"
};

type LottiePlayerProps = {
	animationKey: keyof typeof animations;
	width?: string;
	height?: string;
	/** "1" is default */
	speed?: string;
};

export const LottiePlayer: FC<LottiePlayerProps> = ({ animationKey, width = "100%", height = "100%", speed = "1" }) => {
	return (
		<div className="lottie-wrapper" style={{ width, height }}>
			<lottie-player
				autoplay
				background="transparent"
				direction="1"
				loop
				speed={speed}
				src={animations[animationKey]}
				style={{ width: "100%", height: "100%" }}
			/>
		</div>
	);
};
