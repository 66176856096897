import MobileMenu from "@/components/MobileMenu";
import AppHeader from "@/components/header/Header";
import TrackingService, { UserActiveEvent } from "@/services/TrackingService";
import MenuService from "@/services/menuService/MenuService";
import { useAppSelector } from "@/state/hooks";
import { css } from "@emotion/css";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Sidebar, { SidebarStyles } from "react-sidebar";
import Routes from "./Routes";

const SideBar: SidebarStyles = {
	sidebar: {
		background: "#fff",
		width: "280px",
		zIndex: "1000"
	}
};

const styles = {
	main: css({
		display: "flex",
		flexDirection: "column",
		height: "100svh",
		overflow: "hidden"
	}),
	sidebar: SideBar,
	overlay: css({
		zIndex: 103
	})
};

const Console: FC = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const location = useLocation();

	const user = useAppSelector((state) => state.auth?.user);

	const _onSetSidebarStatus = (status: boolean) => setSidebarOpen(status);
	const _openSideBar = () => setSidebarOpen(true);
	const _closeSideBar = () => setSidebarOpen(false);

	useEffect(() => {
		if (user) {
			TrackingService.track(new UserActiveEvent(user.category));
		}
	}, []);

	if (!user) {
		return null;
	}

	const pathEndPoint = location.pathname.split("/").slice(-1).pop() ?? "";
	const sidebarContent = <MobileMenu navigated={_closeSideBar} />;

	const menu = MenuService.getUserMenu();

	if (["seatingmap", "preview"].includes(pathEndPoint)) {
		return <Routes />;
	}

	return (
		<Sidebar
			styles={styles.sidebar}
			sidebar={sidebarContent}
			overlayClassName={styles.overlay}
			open={sidebarOpen}
			onSetOpen={_onSetSidebarStatus}
		>
			<div className={styles.main}>
				<AppHeader openSideBar={_openSideBar} menu={menu} />
				<Routes />
			</div>
		</Sidebar>
	);
};

export default Console;
