import { Alert, Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField } from "../components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { associateUserToReferral } from "../state/actions/referralActions";
import { required } from "../utils/Validators";

const formName = "referralLinkerModal";

const userIdRequired = required("User id required");

let ReferralLinkerModal = ({ isOpen, toggleModal, handleSubmit, associateUserToReferral }) => {
	const linkReferrer = (values) => {
		toggleModal();
		associateUserToReferral(values.associateId);
	};
	return (
		<form>
			<Modal
				title="Link to referrer"
				open={isOpen}
				onCancel={toggleModal}
				footer={[
					<>
						<LegacyButton
							key="cancel"
							onClick={toggleModal}
							htmlType="button"
							ariaLabel="Cancel"
							style={{ float: "left" }}
						>
							Cancel
						</LegacyButton>
						<LegacyButton
							style={{ float: "right" }}
							key="submit"
							type="secondary"
							htmlType="submit"
							ariaLabel="Save"
							onClick={handleSubmit(linkReferrer)}
						>
							Save
						</LegacyButton>
					</>
				]}
			>
				<Alert
					type="info"
					description={
						<>
							Pasting a user id will associate the current account with the referral of the entered user, for more
							information see{" "}
							<a href="https://humanitix.atlassian.net/wiki/spaces/engineering/pages/1470726145/Ambassador+program#How-manually-referring-works">
								here
							</a>
						</>
					}
					showIcon
				/>
				<Field
					name="associateId"
					label="User Id to link to"
					required
					component={LegacyInputField}
					validate={userIdRequired}
				/>
			</Modal>
		</form>
	);
};

ReferralLinkerModal = reduxForm({ form: formName, touchOnChange: true, touchOnBlur: true, enableReinitialize: true })(
	ReferralLinkerModal
);

export default connect(
	() => {
		return {};
	},
	{ associateUserToReferral }
)(ReferralLinkerModal);
