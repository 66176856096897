import AdminReportService from "@/services/AdminReportService";
import FileDownload from "@/utils/FileDownload";
import { getLocationFromState } from "../locationActions";
import { REPORT_DATE_CHANGE } from "./datesActions";

export const USER_ACTIVITY_REQUEST = "USER_ACTIVITY_REQUEST";
export const USER_ACTIVITY_SUCCESS = "USER_ACTIVITY_SUCCESS";
export const USER_ACTIVITY_ERROR = "USER_ACTIVITY_ERROR";
export const USER_ACTIVITY_RESET = "USER_ACTIVITY_RESET";

export const TOP_USERS_REQUEST = "TOP_USERS_REQUEST";
export const TOP_USERS_SUCCESS = "TOP_USERS_SUCCESS";
export const TOP_USERS_ERROR = "TOP_USERS_ERROR";
export const TOP_USERS_RESET = "TOP_USERS_RESET";

export const resetUserActivity = () => {
	return (dispatch) => {
		dispatch({ type: USER_ACTIVITY_RESET });
	};
};

export const getUserActivity = (startDate, endDate, published, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({
			type: REPORT_DATE_CHANGE,
			startDate,
			endDate
		});
		dispatch({ type: USER_ACTIVITY_REQUEST, page });
		AdminReportService.getUserActivity(startDate, endDate, published, page, location)
			.then((data) => {
				dispatch({
					type: USER_ACTIVITY_SUCCESS,
					results: data.results,
					count: data.count
				});
			})
			.catch((err) => {
				dispatch({ type: USER_ACTIVITY_RESET, error: err });
			});
	};
};

export const getUserActivityCsv = (startDate, endDate, published) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `user-activity-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}-${
			published ? "published" : "not-published"
		}.csv`;
		AdminReportService.getUserActivityCsv(startDate, endDate, published, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetTopUsers = () => {
	return (dispatch) => {
		dispatch({ type: TOP_USERS_RESET });
	};
};

export const getTopUsers = (startDate, endDate, sortOn, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({
			type: REPORT_DATE_CHANGE,
			startDate,
			endDate
		});
		dispatch({ type: TOP_USERS_REQUEST, page });
		AdminReportService.getTopUsers(startDate, endDate, sortOn, page, location)
			.then((data) => {
				dispatch({
					type: TOP_USERS_SUCCESS,
					results: data.results,
					count: data.count
				});
			})
			.catch((err) => {
				dispatch({ type: TOP_USERS_RESET, error: err });
			});
	};
};

export const getTopUsersCsv = (startDate, endDate, sortOn) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `top-users-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getTopUsersCsv(startDate, endDate, sortOn, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};
