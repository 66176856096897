import { withRouterHooks } from "@/hoc/withRouterHooks";
import { Component } from "react";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import UserMenu from "../users/UserMenu";
import getUserMenu from "../users/UserMenuItems";
import SplitLevelMenu from "./SplitLevelMenu";
import { H2 } from "./text/Text";

class MobileMenu extends Component {
	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) {
			this.props.navigated();
		}
	}

	render() {
		const { location, navigate } = this.props;

		const userSelected = matchPath({ path: "/console/admin/users/:userId", end: false }, location.pathname);
		const userId = userSelected ? userSelected.params?.userId : null;
		const userMenuItems = getUserMenu(userId);

		if (userSelected) {
			return (
				<div>
					<H2 style={{ padding: 24 }}>
						<Link to="/console/admin/users">Users</Link>
					</H2>

					<UserMenu title="" menuItems={userMenuItems} />
				</div>
			);
		}

		return <SplitLevelMenu location={this.props.location} navigated={this.props.navigated} navigate={navigate} />;
	}
}

const MobileMenuWithRouter = withRouterHooks(MobileMenu);
export default MobileMenuWithRouter;
