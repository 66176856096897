import { useCurrentUser } from "@/hooks/useCurrentUser";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import hubspotIcon from "../../assets/integrations/hubspotlogo-web-color.svg";
import salesforceIcon from "../../assets/integrations/logo_salesforce@2x.png";
import zapierIcon from "../../assets/integrations/logo_zapier.svg";
import mailchimpIcon from "../../assets/integrations/mailchimp@2x.png";
import trackingIdsIcon from "../../assets/integrations/tracking_ids@2x.png";
import PageHeading from "../../components/PageHeading";
import { H3, P } from "../../components/text/Text";
import { max } from "../../utils/CssUtils";
import { HelpIds } from "../../utils/Help";

const cardStyle = {
	wrapper: css({
		background: "#ffffff",
		borderRadius: 8,
		border: "var(--border1)",
		marginRight: 20,
		marginBottom: 20,
		cursor: "pointer",
		transition: "border 0.2s ease-in-out",
		"&:hover": {
			border: "var(--border1-hover)"
		},
		[max(600)]: {
			width: "100%",
			marginRight: 0
		}
	}),
	body: css({
		width: 270,
		height: 176,
		padding: "24px 20px",
		img: {
			height: 50,
			marginBottom: 8
		},
		h3: {
			marginBottom: 8
		},
		p: { letterSpacing: "-0.33px" }
	})
};

const pageStyle = {
	wrapper: css({
		display: "flex",
		flexWrap: "wrap"
	})
};

const Card = ({ title, description, icon, path }) => {
	return (
		<div className={cardStyle.wrapper}>
			<Link to={path} tabIndex={0} role="button">
				<div className={cardStyle.body}>
					<img src={icon} style={{ maxWidth: "120px" }} alt="" />
					<H3>{title}</H3>
					<P>{description}</P>
				</div>
			</Link>
		</div>
	);
};

const Items = [
	{
		title: "Default tracking IDs",
		description: "Set up default tracking IDs that automatically apply to all your events",
		icon: trackingIdsIcon,
		path: "/console/account/advanced/integrations/default-tracking-ids"
	},
	{
		title: "Mailchimp",
		description: "Build lasting connections with your community through email.",
		icon: mailchimpIcon,
		path: "/console/account/advanced/integrations/mailchimp"
	},
	{
		title: "Zapier",
		description: "Connect 1500+ apps to our platform in just 2 minutes.",
		icon: zapierIcon,
		path: "/console/account/advanced/integrations/zapier"
	},
	{
		title: "Salesforce",
		description: "Connect Humanitix to your Salesforce account to sync data.",
		icon: salesforceIcon,
		path: "/console/account/advanced/integrations/salesforce"
	},
	{
		title: "HubSpot",
		description: "Connect Humanitix to your HubSpot account to sync data.",
		icon: hubspotIcon,
		path: "/console/account/advanced/integrations/hubspot"
	}
];

const adminIntegrations = [];

const IntegrationLandingPage = () => {
	const { isAdmin } = useCurrentUser();
	const integrations = isAdmin ? Items.concat(adminIntegrations) : Items;
	return (
		<div>
			<PageHeading
				title="Integrations"
				helpButton={{
					link: HelpIds.integrations,
					title: "Help - Integrations"
				}}
			/>
			<div className={pageStyle.wrapper}>
				{integrations.map((item, index) => (
					<Card {...item} key={`integration_card_${index}`} />
				))}
			</div>
		</div>
	);
};

export default IntegrationLandingPage;
