import * as React from "react";
import Slider from "../components/Slider";
import { IconNames, iconMap } from "../icons";
import { BasicTab } from "./BasicTab";
import { TabProps } from "./index";

export class SliderTab extends React.Component<TabProps, { selected: boolean; sliderPercent: number }> {
	constructor(props: TabProps) {
		super(props);
		this.state = {
			selected: false,
			sliderPercent: 100
		};
	}

	toggle = () => {
		this.setState({
			selected: !this.state.selected
		});
	};

	onChange = (e: any) => {
		this.setState({
			sliderPercent: e.target.value
		});
	};

	slider = () => {
		const { selected, sliderPercent } = this.state;
		return (
			<div style={{ height: 40, marginLeft: 8, display: !selected ? "none" : "inherit" }}>
				<div
					style={{
						backgroundColor: "white",
						display: "flex",
						width: 216,
						height: 40,
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Slider styleType={Slider.styleKey.DARK} sliderPercent={sliderPercent} onChange={this.onChange} />
					<div style={{ marginTop: 4, marginLeft: 16, marginRight: 12, cursor: "pointer" }} onClick={this.toggle}>
						{iconMap[IconNames.cross]({})}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { selected } = this.state;
		return (
			<div style={{ display: "flex" }}>
				<BasicTab {...this.props} onClick={this.toggle} selected={selected} last={this.props.last && !selected} />
				{this.slider()}
			</div>
		);
	}
}
