import { css } from "@emotion/css";
import { Input, InputNumber } from "@/ui/antd";
import { getCurrencySymbol } from "../utils/Format";

const labelStyle = css({
	width: 300,
	fontWeight: "bold",
	marginRight: 20,
	display: "block",
	overflow: "hidden"
});
const numberInputStyle = css({
	".ant-input-group": {
		float: "left",
		width: "auto",
		marginTop: 4,
		marginRight: 12,
		"&:last-of-type": {
			marginRight: 0
		}
	}
});

export const NumberRange = ({ filter, search, handleFilterChange }) => {
	const casedKey = filter.key.replace(/^./, (c) => c.toUpperCase());
	const startKey = `min${casedKey}`;
	const endKey = `max${casedKey}`;
	const currencySymbol = getCurrencySymbol();
	return (
		<label className={labelStyle}>
			{filter.label}
			<div className={numberInputStyle}>
				<Input.Group>
					<div className="ant-input-group-addon">{currencySymbol}</div>
					<InputNumber
						onChange={(value) => {
							handleFilterChange(startKey, value);
						}}
						min={0}
						value={search[startKey]}
					/>
				</Input.Group>
				<Input.Group>
					<div className="ant-input-group-addon">{currencySymbol}</div>
					<InputNumber
						onChange={(value) => {
							handleFilterChange(endKey, value);
						}}
						min={0}
						value={search[endKey]}
					/>
				</Input.Group>
			</div>
		</label>
	);
};
