import { css } from "@emotion/css";
import { SeatingMap, Views } from "@hx/seating-map";
import { Component } from "react";
import { Area, RectangleTable, RoundTable, SeatingRows } from "@/assets/seatingMap";
import ElementButton from "../../components/ElementButton";
const { SeatingConstruction } = Views;

const tables = [
	{
		id: "circle",
		image: RoundTable,
		disabled: false,
		displayName: "Table",
		defaultSeatNum: 10
	},
	{
		id: "squareTable",
		image: RectangleTable,
		displayName: "Table",
		defaultSeatNum: 8
	},
	{
		id: "row",
		image: SeatingRows,
		displayName: "Seating",
		defaultSeatNum: 100
	},
	{ id: "area", image: Area, displayName: "Area", defaultSeatNum: 10 }
];

const tableElementStyle = css({
	marginBottom: 16,
	"&:last-child": {
		marginBottom: 0
	},
	"div:hover": {
		background: "#4e4757",
		borderRadius: "3px"
	}
});

export default class TableSelection extends Component {
	constructor(props) {
		super(props);
		this.seatingMap = SeatingMap.getCurrentMap();
		this.tableMap = SeatingConstruction.getInstance();
		this.state = {
			tableDragging: false,
			tableId: 0,
			rowId: 0,
			colId: 0
		};
		document.body.addEventListener("drop", this.onMouseUp, true);
	}

	addTable = (index) => {
		const table = tables[index];
		const type = table.id;
		const defaultSeatNum = table.defaultSeatNum;
		const seats = [];
		for (let i = 0; i < defaultSeatNum; i++) {
			seats.push({
				disabled: false,
				reserved: false,
				seatId: i,
				locked: false
			});
		}
		const tableData = { type, seats };
		SeatingConstruction.getInstance().addTable(tableData);
	};

	render() {
		return (
			<div style={{ width: "100%", padding: "20px 24px" }}>
				{tables.map(({ image, displayName }, i) => (
					<div className={tableElementStyle}>
						<ElementButton
							name={displayName}
							elementImage={image}
							onClick={() => this.addTable(i)}
							isSelected={false}
						/>
					</div>
				))}
			</div>
		);
	}
}
