import { Button } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { ReportFilters } from "@/components/AppBlocks";
import { DateField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getMoneyHeldNowReport,
	getMoneyHeldReport,
	resetMoneyHeldReport
} from "@/state/actions/adminReports/financeActions";
import DayEndsAt from "../DayEndsAt";

class MoneyHeld extends Component {
	constructor(props) {
		super(props);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.downloadMoneyHeldNowCsv = this.downloadMoneyHeldNowCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	downloadCsv() {
		const { soldBefore, heldAt, getMoneyHeldReport, dayEndsAt } = this.props;
		getMoneyHeldReport(soldBefore, heldAt, dayEndsAt);
	}

	downloadMoneyHeldNowCsv() {
		const { getMoneyHeldNowReport } = this.props;
		getMoneyHeldNowReport();
	}

	render() {
		const { handleSubmit, moneyHeldCvsReport, soldBefore, heldAt } = this.props;

		return (
			<form onSubmit={handleSubmit(() => null)}>
				<PageHeading title="Money Held">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView
					loading={moneyHeldCvsReport.loading}
					error={moneyHeldCvsReport.error}
					retryAction={this.downloadCsv}
				>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<div style={{ marginRight: 12 }}>
									<Field
										label="Sold before"
										name="soldBefore"
										component={DateField}
										placeholder="Sold before"
										displayFormat="Do MMM YYYY"
									/>
								</div>
								<div style={{ marginRight: 12 }}>
									<Field
										label="Held at"
										name="heldAt"
										component={DateField}
										placeholder="Held at"
										displayFormat="Do MMM YYYY"
									/>
								</div>
								<DayEndsAt />
							</div>
						</div>
						<Button
							style={{ float: "right" }}
							type="secondary"
							onClick={this.downloadMoneyHeldNowCsv}
							ariaLabel="Export Money Held Now"
						>
							Request Money Held Now Report
						</Button>
						<Button type="primary" onClick={this.downloadCsv} disabled={!soldBefore || !heldAt} ariaLabel="Export">
							Request
						</Button>
					</ReportFilters>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "moneyHeldReport";
const selector = formValueSelector(formName);
MoneyHeld = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(MoneyHeld);

export default connect(
	(state) => ({
		moneyHeldCvsReport: state.adminReports.finance.moneyHeldCvsReport,
		moneyHeldReport: state.adminReports.finance.moneyHeldReport,
		soldBefore: selector(state, "soldBefore"),
		heldAt: selector(state, "heldAt"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{ getMoneyHeldReport, getMoneyHeldNowReport, resetMoneyHeldReport }
)(MoneyHeld);
