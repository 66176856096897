import axios from "./AxiosWrapper";

class ScheduleService {
	static instance = null;

	static createInstance() {
		let object = new ScheduleService();
		return object;
	}

	static getInstance() {
		if (!ScheduleService.instance) {
			ScheduleService.instance = ScheduleService.createInstance();
		}
		return ScheduleService.instance;
	}

	async createSchedule(eventId, scheduleData) {
		const response = await axios.put(`/recurring-dates/schedule/${eventId}`, {
			scheduleData
		});
		return response.data;
	}

	async loadDates(eventId, date, viewType, jumpToUpcoming) {
		const response = await axios.get(`/recurring-dates/schedule/${eventId}`, {
			params: {
				date,
				viewType,
				jumpToUpcoming
			}
		});
		return response.data;
	}

	async reschedule(eventId, scheduleId, rescheduleData) {
		const response = await axios.post(`/recurring-dates/reschedule/${eventId}/${scheduleId || "single"}`, {
			...rescheduleData
		});
		return response.data;
	}

	async deleteSchedule(eventId, scheduleId, deleteScheduleData) {
		const response = await axios.post(`/recurring-dates/delete/${eventId}/${scheduleId || "single"}`, {
			...deleteScheduleData
		});
		return response.data;
	}
}

export default ScheduleService.getInstance();
