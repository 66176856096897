import axios from "./AxiosWrapper";

class ReportService {
	static instance = null;

	static createInstance() {
		let object = new ReportService();
		return object;
	}

	static getInstance() {
		if (!ReportService.instance) {
			ReportService.instance = ReportService.createInstance();
		}
		return ReportService.instance;
	}

	async discount(id) {
		const response = await axios.get(`reports/discount/${id}`);
		return response.data;
	}

	async affiliateCodes(id) {
		const response = await axios.get(`reports/affiliate-codes/${id}`);
		return response.data;
	}

	async getAffiliateCodesCsv(id, queryBy) {
		const response = await axios.get(`reports/affiliate-codes/${id}/csv`, {
			responseType: "blob",
			params: { queryBy }
		});
		return response.data;
	}

	async getDiscountCodesCsv(id, queryBy) {
		const response = await axios.get(`reports/discount/${id}/csv`, {
			responseType: "blob",
			params: { queryBy }
		});
		return response.data;
	}

	async getAccessCodeReport(id) {
		const response = await axios.get(`reports/access-codes/${id}`);
		return response.data;
	}

	async megaSearch(query) {
		const response = await axios.get(`reports/mega/${query}`);
		return response.data;
	}

	async getAccessCodesCsv(id, queryBy) {
		const response = await axios.get(`reports/access-codes/${id}/csv`, {
			responseType: "blob",
			params: { queryBy }
		});
		return response.data;
	}
}
export default ReportService.getInstance();
