import { Modal, Switch } from "@/ui/antd";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import Page from "@/components/Page";
import { Label, P } from "@/components/text/Text";
import { changeSetting, newSeatingMap, removeSeatingMap } from "@/state/actions/seatingMapActions";
import { HelpIds } from "@/utils/Help";
import ExistingMapPicker from "./ExistingMapPicker";
import MapCard from "./MapCard";
import SeatingMapCapacityIssue from "./SeatingMapCapacityIssue";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const seatingMapDesc =
	"Create interactive seating maps. Allow attendees to reserve their seat, table or section when buying tickets to your event. Seating maps can be reused for future events.";

class MapSelector extends Component {
	constructor(props) {
		super(props);
		this.mapSelected = this.mapSelected.bind(this);
		this.getSeatingMaps = this.getSeatingMaps.bind(this);
		this.newMap = this.newMap.bind(this);
		this.showConfirm = this.showConfirm.bind(this);
	}

	mapSelected() {
		return null;
	}

	/**
	 * TODO create new map skeleton before loading
	 */
	newMap() {
		const { newSeatingMap, currentEvent, navigate } = this.props;
		const { event } = currentEvent;
		newSeatingMap(event._id, navigate);
	}

	navigateToSeatingMapPage(id, tabName = "seatingView") {
		const {
			navigate,
			currentEvent: { event }
		} = this.props;

		navigate(`/console/my-events/${String(event._id)}/seatingmap/${id}/construction?view=${tabName}`, {
			state: { newMap: false, mapId: id }
		});
	}

	showConfirm(eventId, _id) {
		const props = this.props;
		Modal.confirm({
			title: "Do you really wish to delete this seating map?",
			content: "This seating map will be removed from your event, along with all existing seating allocations.",
			icon: null,
			okText: "Yes",
			okType: "danger",
			onOk() {
				props.removeSeatingMap(eventId, _id);
			},
			onCancel() {
				return null;
			}
		});
	}

	getSeatingMaps() {
		const { changeSetting, navigate } = this.props;
		const event = this.props.currentEvent.event;
		const { _id: eventId, totalCapacity } = event;

		return (event.seatingMaps || []).map((seatingMapData, i) => {
			const {
				_id,
				id,
				thumbnail,
				name,
				ticketTypeSet,
				capacity,
				attendeeSearch,
				allowChangeAfterOrder,
				public: isSeatingMapPublic
			} = seatingMapData;

			return (
				<ContentBlock key={`seatingMap-${i}`}>
					{i === 0 ? (
						<div style={{ width: "100%", marginBottom: 24 }}>
							<div style={{ width: "53%", marginBottom: 24 }}>{seatingMapDesc}</div>
							<div
								style={{
									width: "calc(100%-48px)",
									borderBottom: "solid 1px",
									borderColor: "#dedede"
								}}
							/>
						</div>
					) : null}
					<div style={{ display: "flex" }}>
						<div
							style={{
								flex: 1,
								borderRadius: 4,
								border: "solid 1px",
								borderColor: "#dedede"
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									flexWrap: "wrap"
								}}
							>
								<div style={{ padding: "18px 12px" }}>
									<div style={{ float: "left", display: "flex" }}>
										<MapCard
											{...{
												thumbnail,
												name,
												ticketTypeSet,
												capacity,
												totalCapacity
											}}
										/>
										<div style={{ marginLeft: 12 }}>
											<SeatingMapCapacityIssue
												ticketTypes={event.ticketTypes}
												packagedTickets={event.packagedTickets}
												isThereAnyUnMappedSeats={
													seatingMapData.capacity ? seatingMapData.capacity > seatingMapData.mappedQuantity : false
												}
												onShowManageCapacity={() => {
													navigate(
														`/console/my-events/${String(eventId)}/seatingmap/${seatingMapData.id}/construction`,
														{
															state: {
																shouldShowManageCapacity: true
															}
														}
													);
												}}
												onNavigateToMappingTab={() => {
													//todo - take to mapping tab

													navigate(
														`/console/my-events/${String(eventId)}/seatingmap/${seatingMapData.id}/construction`
													);
												}}
											/>
										</div>
									</div>
									<div style={{ float: "right" }}>
										<div style={{ display: "flex" }}>
											<LegacyButton
												onClick={() => this.navigateToSeatingMapPage(id, "managerView")}
												ariaLabel="Edit"
												type="primary"
												style={{ marginRight: 8 }}
											>
												Manage attendees
											</LegacyButton>
											<LegacyButton onClick={() => this.navigateToSeatingMapPage(id, "seatingView")} ariaLabel="Edit">
												Edit map
											</LegacyButton>
										</div>
									</div>
								</div>
								<div style={{ padding: "0px 12px 12px 12px" }}>
									<div
										style={{
											width: "100%",
											height: 1,
											borderBottom: "solid 1px",
											borderColor: "#dedede",
											marginBottom: 12
										}}
									/>
									<div>
										<Label>Allow buyers to select their seats during checkout</Label>
										<P style={{ maxWidth: 1200 }}>Attendees can pick their own seats.</P>
										<Switch
											checked={!!isSeatingMapPublic}
											onChange={(val) => changeSetting(eventId, _id, "public", val)}
										/>
									</div>

									<div style={{ marginTop: 16 }}>
										<Label>Allow buyers to edit their seating location after purchase</Label>
										<P style={{ maxWidth: 1200 }}>
											Buyers can edit their seating location via the "Manage order" link in their confirmation email.
										</P>
										<Switch
											checked={allowChangeAfterOrder}
											onChange={(val) => changeSetting(eventId, _id, "changeAfterOrder", val)}
										/>
									</div>
									<div style={{ marginTop: 16 }}>
										<Label>Allow buyers to search for existing attendees by name</Label>
										<P style={{ maxWidth: 600 }}>
											Buyers will be able to see the seating location of the attendee they have searched for.
										</P>
										<Switch checked={attendeeSearch} onChange={(val) => changeSetting(eventId, _id, "search", val)} />
									</div>
								</div>
								<div
									style={{
										flex: 1,
										backgroundColor: "#fafafa",
										paddingBottom: 8,
										paddingTop: 8,
										paddingLeft: 12,
										paddingRight: 12,
										borderBottomLeftRadius: 4,
										borderBottomRightRadius: 4
									}}
								>
									<LegacyButton
										onClick={() => this.showConfirm(eventId, _id)}
										style={{ float: "left" }}
										ariaLabel="delete"
										key="delete"
										htmlType="button"
									>
										Remove
									</LegacyButton>
								</div>
							</div>
						</div>
					</div>
				</ContentBlock>
			);
		});
	}

	determineView(eventCards) {
		const hasSeatingMap = eventCards.length > 0;
		const { seatingMap } = this.props;
		const { loading, error } = seatingMap;
		if (!hasSeatingMap) {
			return (
				<ContentBlock>
					<LoadErrorView loading={loading} error={error}>
						<div style={{ width: "53%" }}>{seatingMapDesc}</div>
						<LegacyButton
							onClick={this.newMap}
							type="primary"
							style={{
								marginTop: 24
							}}
							ariaLabel="Create new map"
						>
							Create new map
						</LegacyButton>
						<ExistingMapPicker />
					</LoadErrorView>
				</ContentBlock>
			);
		}
		return (
			<div>
				<LoadErrorView loading={loading} error={error}>
					{eventCards}
				</LoadErrorView>
			</div>
		);
	}

	render() {
		const eventCards = this.getSeatingMaps();
		return (
			<Page
				pageName="Seating maps"
				help={{
					link: HelpIds.seatingMaps,
					title: "Help"
				}}
			>
				{this.determineView(eventCards)}
			</Page>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		seatingMap: state.seatingMap
	}),
	{
		changeSetting,
		removeSeatingMap,
		newSeatingMap
	}
)(withRouterHooks(MapSelector));
