import { Button, ImageUploaderResult } from "@/ui";
import { Row, Space, Typography } from "@/ui/antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { FC } from "react";

const StyledButton = styled(Button)`
	min-height: 30px;
	min-width: 30px;
	padding: 8px;
`;

const StyledImage = styled.img`
	border-radius: 4px;
	overflow: hidden;
	object-fit: cover;
	margin-right: 8px;
`;

type EventGroupItemRowProps = {
	image: ImageUploaderResult | null;
	onDelete: () => void;
	onEdit: () => void;
	title: string;
};

export const EventGroupItemRow: FC<EventGroupItemRowProps> = ({ image, onDelete, onEdit, title }) => {
	return (
		<Row style={{ marginBottom: "12px", width: "100%" }}>
			<Row align="middle" style={{ flexGrow: 1 }}>
				{image && <StyledImage alt={image.alt} height={30} src={image.url} width={50} />}
				<Typography.Text style={{ fontWeight: 400 }}>{title}</Typography.Text>
			</Row>
			<Space size={8}>
				<StyledButton iconOnly onClick={onEdit} variant="text">
					<EditOutlined />
				</StyledButton>
				<StyledButton iconOnly onClick={onDelete} variant="text">
					<DeleteOutlined />
				</StyledButton>
			</Space>
		</Row>
	);
};
