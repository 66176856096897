import { CaretDownOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table } from "@/ui/antd";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import Breadcrumb from "@/components/Breadcrumb";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import LinkButton from "@/components/buttons/LinkButton";
import TableActions from "@/components/table/TableActions";
import { H1, P } from "@/components/text/Text";
import { getAdditionalQuestionsTemplates, removeAdditionalQuestionsTemplate } from "@/state/actions/templateActions";
import EventSelectionModal from "../EventSelectionModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class AdditionalQuestionsTemplates extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.createAdditionalQuestionsTemplate = this.createAdditionalQuestionsTemplate.bind(this);
		this.deleteAdditionalQuestionsTemplate = this.deleteAdditionalQuestionsTemplate.bind(this);
		this.editAdditionalQuestionTemplate = this.editAdditionalQuestionTemplate.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.eventSelected = this.eventSelected.bind(this);
		this.state = {
			visible: false
		};
	}

	componentDidMount() {
		this.load();
	}

	load() {
		const { getAdditionalQuestionsTemplates, currentUser } = this.props;
		getAdditionalQuestionsTemplates(currentUser.user.id, true);
	}

	createAdditionalQuestionsTemplate(evt) {
		const { navigate } = this.props;
		if (evt.key === "new") {
			navigate(`/console/account/advanced/templates/additional-questions-templates/new`), { state: { isNew: true } };
		} else {
			this.setState({ visible: true });
		}
	}

	deleteAdditionalQuestionsTemplate(userId, templateAdditionalQuestion) {
		this.props.removeAdditionalQuestionsTemplate(userId, templateAdditionalQuestion._id);
	}

	editAdditionalQuestionTemplate(templateAdditionalQuestion) {
		this.props.navigate(
			`/console/account/advanced/templates/additional-questions-templates/${templateAdditionalQuestion._id}`,
			{ state: { isExisting: true } }
		);
	}

	openModal() {
		this.setState({ visible: true });
	}

	closeModal() {
		this.setState({ visible: false });
	}

	eventSelected(event) {
		const { navigate } = this.props;
		navigate(`/console/account/advanced/templates/additional-questions-templates/${event}`, {
			state: { isFromEvent: true }
		});
	}

	render() {
		const { currentTemplates, currentUser, userEvents } = this.props;
		const { loading, error, templates } = currentTemplates;
		const title = "Checkout questions template";
		return (
			<LoadErrorView loading={loading} error={error}>
				<Breadcrumb prevPath="/console/account/advanced/templates" prevTitle="Templates" currTitle={title} />
				<H1>{title}</H1>
				<ContentBlock>
					{!loading ? (
						<div>
							<AdditionalQuestionTemplateTable
								additionalQuestionTemplate={templates}
								createAdditionalQuestionsTemplate={this.createAdditionalQuestionsTemplate}
								currentUser={currentUser}
								deleteAdditionalQuestionsTemplate={this.deleteAdditionalQuestionsTemplate}
								editTemplateAdditionalQuestion={this.editAdditionalQuestionTemplate}
								load={this.load}
							/>
							<EventSelectionModal
								visible={this.state.visible}
								handleCancel={this.closeModal}
								handleSubmit={this.eventSelected}
								userEvents={userEvents.events || []}
							/>
						</div>
					) : null}
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

class AdditionalQuestionTemplateTable extends Component {
	render() {
		const {
			additionalQuestionTemplate,
			createAdditionalQuestionsTemplate,
			currentUser,
			deleteAdditionalQuestionsTemplate,
			editTemplateAdditionalQuestion
		} = this.props;

		const dropdownMenu = {
			items: [
				{ key: "new", label: "New Checkout questions template" },
				{ key: "From Event", label: "From Existing Event" }
			],
			onClick: createAdditionalQuestionsTemplate
		};
		const columns = [
			{
				title: "Template Name",
				dataIndex: "name",
				key: "name",
				render: (value, templateAdditionalQuestion) => {
					return (
						<LinkButton
							style={{ color: "#353337" }}
							onClick={() => {
								editTemplateAdditionalQuestion(templateAdditionalQuestion);
							}}
							ariaLabel={value}
						>
							{value}
						</LinkButton>
					);
				}
			},
			{
				title: "Number of Checkout questions",
				render: (_, record) => {
					return <span>{record.additionalQuestions?.length}</span>;
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, templateAdditionalQuestion) => (
					<TableActions>
						<IconButton
							icon="edit"
							onClick={() => editTemplateAdditionalQuestion(templateAdditionalQuestion)}
							tooltip="Edit"
							showTooltip
							ariaLabel="Edit"
						/>

						<IconButton
							icon="delete"
							onClick={() => deleteAdditionalQuestionsTemplate(currentUser.user.id, templateAdditionalQuestion)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				)
			}
		];
		return (
			<div>
				<P>
					Save time when building future events by creating a template for your checkout questions! Collect customer
					data faster. When creating your next event simply click 'import template' in the checkout questions section.
				</P>
				<If condition={!additionalQuestionTemplate || !additionalQuestionTemplate.length}>
					<Then>
						<Dropdown menu={dropdownMenu}>
							<Button
								style={{
									border: "1px solid #353337",
									color: "#353337"
								}}
								icon={<PlusOutlined />}
								aria-label="New Template"
							>
								New template
								<CaretDownOutlined style={{ fontSize: 12 }} />
							</Button>
						</Dropdown>
					</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<Table
									columns={columns}
									scroll={{ x: 800 }}
									dataSource={additionalQuestionTemplate}
									rowKey="_id"
									pagination={false}
								/>
								<ButtonsBar label="" stackButtons>
									<Dropdown menu={dropdownMenu}>
										<Button
											style={{
												border: "1px solid #353337",
												color: "#353337"
											}}
											icon={<PlusOutlined />}
											aria-label="New Template"
										>
											New template
											<CaretDownOutlined style={{ fontSize: 12 }} />
										</Button>
									</Dropdown>
								</ButtonsBar>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.auth,
		userEvents: state.userEvents,
		currentTemplates: state.templates
	}),
	{
		getAdditionalQuestionsTemplates,
		removeAdditionalQuestionsTemplate
	}
)(withRouterHooks(AdditionalQuestionsTemplates));
