import { Views } from "@hx/seating-map";
import { Component } from "react";
import { connect } from "react-redux";
import { searchTickets } from "@/state/actions/seatingMapActions";
const { SeatingManagement } = Views;
class TicketSearch extends Component {
	constructor(props) {
		super(props);
		this.onHover = this.onHover.bind(this);
		this.onHoverOff = this.onHoverOff.bind(this);
		this.state = {
			hoverId: -1,
			loaded: false
		};
	}

	componentDidMount() {
		if (!this.seatingManagement) {
			this.seatingManagement = SeatingManagement.getInstance();
			this.setState({ loaded: true });
		}
	}

	onHover(id, seatingLocation) {
		const { section, table, seat } = seatingLocation;
		const ids = {
			sectionId: section.id,
			tableId: table.id,
			seatId: seat.id
		};
		this.seatingManagement.highlight(true, ids);
		this.setState({ hoverId: id });
	}

	onHoverOff(seatingLocation) {
		const { section, table, seat } = seatingLocation;
		const ids = {
			sectionId: section.id,
			tableId: table.id,
			seatId: seat.id
		};
		this.seatingManagement.highlight(false, ids);
		this.setState({ hoverId: -1 });
	}

	render() {
		const { seatingMap } = this.props;
		const { hoverId } = this.state;
		const tickets = seatingMap.seatingMap && seatingMap.seatingMap.tickets ? seatingMap.seatingMap.tickets : undefined;
		return (
			<div style={{ paddingTop: 0 }}>
				<div>
					{this.seatingManagement && tickets
						? tickets
								.filter((ticket) => ticket.seatingLocation)
								.map((ticket, i) => {
									const { section, table, seat } = ticket.seatingLocation;
									const names = this.seatingManagement.getNames({
										sectionId: section.id,
										tableId: table.id,
										seatId: seat.id
									});
									return (
										<TicketCard
											id={i}
											highlight={hoverId === i}
											onHover={this.onHover}
											onHoverOff={this.onHoverOff}
											{...ticket}
											names={names}
										/>
									);
								})
						: null}
				</div>
			</div>
		);
	}
}

const TicketCard = ({
	id,
	highlight,
	seatingLocation,
	firstName,
	lastName,
	ticketTypeName,
	names,
	onHover,
	onHoverOff
}) => {
	return (
		<div
			onMouseOver={() => onHover(id, seatingLocation)}
			onMouseOut={() => onHoverOff(seatingLocation)}
			style={{
				background: highlight ? "#f9f9fa" : "#FFFFFF",
				border: "var(--border1)",
				paddingTop: 10,
				paddingLeft: 10,
				paddingBottom: 10,
				marginTop: -1,
				height: 109
			}}
		>
			<div style={{ float: "left" }}>
				<div style={{ fontSize: 16, color: "#323232", letterSpacing: -0.37 }}>{`${firstName} ${lastName}`}</div>
				<div style={{ fontSize: 14, color: "#5A626C", letterSpacing: -0.33 }}>{ticketTypeName}</div>
				{names ? (
					<div>
						<div style={{ fontSize: 14, color: "#5A626C", letterSpacing: -0.33 }}>{`${names.sectionName}`}</div>
						<div style={{ fontSize: 14, color: "#5A626C", letterSpacing: -0.33 }}>
							{`${names.tableName} Seat ${names.seatName}`}
						</div>
					</div>
				) : (
					<div style={{ fontSize: 14, color: "#5A626C", letterSpacing: -0.33 }}>Unassigned</div>
				)}
			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		seatingMap: state.seatingMap
	}),
	{ searchTickets }
)(TicketSearch);
