import DatesSelector from "@/admin/reports/DatesSelector";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import {
	getEventSalesByLocation,
	getEventSalesByLocationCsv,
	resetEventSalesByLocation
} from "@/state/actions/adminReports/eventsActions";
import { Component } from "react";
import ReactGoogleMapLoader from "react-google-maps-loader";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";

class SalesByEventLocation extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.checkHeatMapUpdate = this.checkHeatMapUpdate.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getEventSalesByLocation } = this.props;
		getEventSalesByLocation(values.startDate, values.endDate);
	}

	retry() {
		const { resetEventSalesByLocation } = this.props;
		resetEventSalesByLocation();
	}

	downloadCsv() {
		const { startDate, endDate, getEventSalesByLocationCsv } = this.props;
		if (!startDate || !endDate) {
			return;
		}
		getEventSalesByLocationCsv(startDate, endDate);
	}
	componentDidUpdate(preProps) {
		const { startDate, endDate } = this.props;
		const props = [" startDate", "endDate"];
		const changed = props.reduce((changed, prop) => {
			if (this.props[prop] !== preProps[prop]) {
				return true;
			}
			return changed;
		}, false);
		if (changed) {
			this.load({ startDate, endDate });
		} else {
			this.checkHeatMapUpdate(preProps);
		}
	}

	runLoad = () => {
		const { startDate, endDate } = this.props;
		this.load({ startDate, endDate });
	};

	checkHeatMapUpdate(preProps) {
		const { eventSalesByLocation, googleMaps } = this.props;
		if (preProps.eventSalesByLocation.loading && !eventSalesByLocation.loading && eventSalesByLocation.data) {
			const bounds = new googleMaps.LatLngBounds();
			const heatMapData = eventSalesByLocation.data
				.filter((l) => l.lat)
				.map((location) => {
					const loc = new googleMaps.LatLng(location.lat, location.lng);
					bounds.extend(loc);
					return {
						location: new googleMaps.LatLng(location.lat, location.lng),
						weight: location.total / 1000
					};
				});
			if (this.heatMap) {
				this.heatMap.setMap(null);
			}

			this.heatMap = new googleMaps.visualization.HeatmapLayer({
				data: heatMapData,
				map: this.map
			});

			this.map.fitBounds(bounds);
			this.map.panToBounds(bounds);
		}
	}

	componentDidMount() {
		const { googleMaps } = this.props;
		const map = new googleMaps.Map(document.getElementById("map"), {
			zoom: 10,
			center: { lat: -33.86882, lng: 151.2093 },
			mapTypeId: "satellite"
		});
		this.map = map;
	}

	render() {
		const { touch, handleSubmit, eventSalesByLocation } = this.props;

		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Sales by event location">
					<LocationSelection componentName="adminReportEvents" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<div>
					<LoadErrorView
						loading={eventSalesByLocation.loading}
						error={eventSalesByLocation.error}
						retryAction={this.retry}
					>
						<ReportFilters>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div style={{ display: "flex" }}>
									<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								</div>
								<div style={{ width: 60 }}>
									{eventSalesByLocation.data ? (
										<IconButton
											icon="export"
											type="background"
											showTooltip
											tooltip="Export"
											onClick={this.downloadCsv}
											ariaLabel="Export"
										/>
									) : null}
								</div>
							</div>
						</ReportFilters>
						<ReportContent>
							<div id="map" style={{ width: "100%", height: "600px" }} />
							{eventSalesByLocation.data ? <ExportBar buttonText="Export" buttonClick={this.downloadCsv} /> : null}
						</ReportContent>
					</LoadErrorView>
				</div>
			</form>
		);
	}
}
const formName = "eventSalesByLocation";
const selector = formValueSelector(formName);
SalesByEventLocation = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(SalesByEventLocation);

SalesByEventLocation = connect(
	(state) => ({
		eventSalesByLocation: state.adminReports.events.eventSalesByLocation,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate")
	}),
	{
		getEventSalesByLocation,
		resetEventSalesByLocation,
		getEventSalesByLocationCsv
	}
)(SalesByEventLocation);

const SalesByEventLocationMaps = (props) => {
	return (
		<ReactGoogleMapLoader
			params={{
				key: getConfig("GOOGLE_API_KEY"), // Define your api key here
				libraries: "visualization"
			}}
			render={(googleMaps, error) =>
				googleMaps ? (
					<div>
						{/*Show a custom error if SDK Authentication Error. See N/B 2 below.*/}
						{error ? error : <SalesByEventLocation {...props} googleMaps={googleMaps} />}
					</div>
				) : (
					<div>
						{/*Check for network error so loading state ends if user lost connection.*/}
						{error === "Network Error" ? <P>{error}</P> : <P>isLoading...</P>}
					</div>
				)
			}
		/>
	);
};

export default SalesByEventLocationMaps;
