import { reduxForm } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { formName } from "./WaitlistSettings";

let SaveWaitlistSettingsButton = ({ handleSubmit, saveSettings }) => {
	return (
		<LegacyButton
			style={{
				float: "right"
			}}
			form={formName}
			key="save"
			type="primary"
			htmlType="submit"
			ariaLabel="save"
			onClick={handleSubmit(saveSettings)}
		>
			Save
		</LegacyButton>
	);
};

SaveWaitlistSettingsButton = reduxForm({
	form: formName,
	//WaitlistSettings is responsible for detroy on unmount
	destroyOnUnmount: false
})(SaveWaitlistSettingsButton);

export default SaveWaitlistSettingsButton;
