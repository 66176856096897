import { FC } from "react";
import { Flex as AntdFlex, FlexProps as AntdFlexProps } from "antd";
import { spacingTokens, SpacingPreset } from "@/ui";
import styled from "@emotion/styled";

type FlexProps = AntdFlexProps & {
	/** The gap between children */
	gap?: SpacingPreset | (string & {});
	/** Should the flex container take up the full width of its parent */
	wide?: boolean;
};

const StyledFlex = styled(AntdFlex, {
	shouldForwardProp: (prop) => prop !== "wide"
})<FlexProps>`
	${({ wide }) => wide && "width: 100%;"}
`;

export const Flex: FC<FlexProps> = ({ gap, wide, ...props }) => {
	if (spacingTokens[gap as SpacingPreset]) {
		const token = spacingTokens[gap as SpacingPreset];
		gap = `var(${token})`;
	}

	return <StyledFlex gap={gap} wide={wide} {...props} />;
};
