import { Card } from "@/ui";
import { FC, ReactNode } from "react";
import { Heading } from "@/components/AppBlocks";

type WidgetProps = {
	children: ReactNode;
	customTitle?: React.ReactNode;
	overwriteTimeScale?: React.ReactNode;
	title?: string;
};

const Widget: FC<WidgetProps> = ({ children, customTitle, overwriteTimeScale, title }) => {
	const heading = title ? <Heading title={title} inline /> : null;

	return (
		<Card>
			{overwriteTimeScale && <div style={{ float: "right" }}>{overwriteTimeScale}</div>}
			{customTitle ? customTitle : heading}
			{children}
		</Card>
	);
};

export default Widget;
