import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Table } from "@/ui/antd";
import { Component } from "react";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import LocationService from "@/services/LocationService";
import { listLogs } from "@/state/actions/auditLogsActions";
import { updateSearch } from "@/state/actions/searchActions";

const searchWrapper = css({
	".ant-radio-group": {
		marginLeft: 16
	}
});

class AuditLogs extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.debounceLoad = debounce(500, this.load);
	}

	defaultSearch = { status: "all", collection: "all", userType: "all" };

	load(page = 1) {
		const { listLogs } = this.props;
		listLogs(page);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	updateRefinements = (value) => {
		const { updateSearch } = this.props;
		updateSearch({ status: value }, true);
	};

	render() {
		const { auditLogs, search } = this.props;
		const { loading, error, logs } = auditLogs;
		let columns = [
			{
				title: "_id",
				dataIndex: "documentId",
				key: "documentId"
			},
			{
				title: "Collection",
				dataIndex: "coll",
				key: "coll"
			},
			{
				title: "User",
				dataIndex: "auditData",
				key: "auditData",
				render: ({ user }) => {
					if (user.kind === "user") {
						return `${user.firstName} ${user.lastName}`;
					}
					return user.kind;
				}
			},
			{
				title: "Email",
				dataIndex: "auditData",
				key: "user",
				render: ({ user }) => {
					if (user.kind === "user") {
						return user.email;
					}
					return "";
				}
			},
			{
				title: "Changed At",
				dataIndex: "changedAt",
				key: "changedAt",
				render: (changedAt) => {
					const formattedChangedAtDateTime = new HumaniDate(
						changedAt,
						"guess",
						LocationService.getLocation(true)
					).formatting.dateTime();

					return formattedChangedAtDateTime;
				}
			},
			{
				title: "Operation",
				dataIndex: "operationType",
				key: "operationType"
			}
		];
		return (
			<>
				<PageHeading title="Audit logs" />
				<LoadErrorView loading={loading} error={error} retryAction={this.load}>
					<ContentBlock>
						<div className={searchWrapper}>
							<SearchRefinements
								load={this.load}
								defaultSearch={this.defaultSearch}
								showRefinements={true}
								placeholder="Document Id or email address"
								style={{ float: "left" }}
								dropdownId="auditSearch"
								filterOptions={[
									{
										key: "userType",
										label: "User type",
										options: [
											{ value: "all", name: "All" },
											{ value: "user", name: "User" },
											{ value: "anonymous", name: "Anonymous" }
										]
									},
									{
										key: "status",
										label: "Operation Type",
										options: [
											{ value: "all", name: "All" },
											{ value: "update", name: "Update" },
											{ value: "delete", name: "Delete" },
											{ value: "replace", name: "Replace" },
											{ value: "insert", name: "Insert" }
										]
									},
									{
										key: "collection",
										label: "Collection",
										options: [
											{ value: "all", name: "All" },
											{ value: "events", name: "Events" },
											{ value: "users", name: "Users" },
											{ value: "payouts", name: "Payouts" }
										]
									},
									{
										key: "pathRefinementInput",
										label: "Path refinement",
										kind: "text"
									},
									{
										key: "valueInput",
										label: "Value",
										kind: "text"
									},
									{
										key: "oldValueInput",
										label: "Old Value",
										kind: "text"
									}
								]}
							/>
						</div>
					</ContentBlock>
					{logs && logs.length ? (
						<ContentBlock>
							<Table
								dataSource={logs}
								rowKey="_id"
								columns={columns}
								scroll={{ x: "max-content" }}
								pagination={false}
								expandable={{
									expandRowByClick: true,
									expandIcon: ({ expanded }) => (expanded ? <DownOutlined /> : <RightOutlined />),
									expandedRowRender: (auditLog) => {
										return <JSONPretty id="json-pretty" data={auditLog.changes} />;
									}
								}}
							/>
							<SimplePagination
								style={{ float: "right", marginTop: 24 }}
								pageAction={this.loadPage}
								pageSize={20}
								currentPage={Number(search.page || 1)}
							/>
							<div style={{ clear: "both" }} />
						</ContentBlock>
					) : null}
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search,
		auditLogs: state.auditLogs
	}),
	{
		listLogs,
		updateSearch
	}
)(AuditLogs);
