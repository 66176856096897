import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import { Tabs } from "@/ui/antd";

import FormBar from "@/components/FormBar";
import { scrollToErrors } from "@/utils/ScrollToErrors";

import { SwitchField } from "@/components/Fields";
import { saveEvent } from "@/state/actions/eventActions";

import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";

import withSaveCheck from "@/hoc/withSaveCheck";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import TicketOrderTable from "./TicketOrderTable";

const formName = "ticketOrder";

/**
 * You can set up ticket orders of all tickets
 *
 * @class TicketOrder
 * @extends {Component}
 */
class TicketOrder extends Component {
	save = (values) => {
		const { params, saveEvent } = this.props;
		const eventId = params?.eventId;
		saveEvent(eventId, {
			ticketOrder: (values.primaryTicketOrder || []).concat(values.secondaryTicketOrder || []),
			ticketOrderOn: values.ticketOrderOn
		});
	};

	render() {
		const { currentEvent, handleSubmit, touch, ticketOrderOn, dispatch } = this.props;
		let { primaryTicketOrder = [], secondaryTicketOrder = [] } = this.props;

		const hasSecondary = !!currentEvent?.event?.settings?.isSecondaryTicketsEnabled && secondaryTicketOrder.length > 0;

		const primaryTicketOrderComponent = (
			<FieldArray
				name="primaryTicketOrder"
				fieldName="primaryTicketOrder"
				component={TicketOrderTable}
				ticketOrder={primaryTicketOrder}
				currentEvent={currentEvent}
				touch={touch}
				dispatch={dispatch}
				formName={formName}
			/>
		);

		const secondaryTicketOrderComponent = (
			<FieldArray
				name="secondaryTicketOrder"
				fieldName="secondaryTicketOrder"
				component={TicketOrderTable}
				ticketOrder={secondaryTicketOrder}
				currentEvent={currentEvent}
				touch={touch}
				dispatch={dispatch}
				formName={formName}
			/>
		);

		const displayComponent = hasSecondary ? (
			<Tabs
				items={[
					{ label: "Primary Order", key: "primary-order", children: primaryTicketOrderComponent },
					{
						label: "Secondary Order",
						key: "secondary-order",
						children: secondaryTicketOrderComponent
					}
				]}
			/>
		) : (
			primaryTicketOrderComponent
		);

		//pre render row
		if (primaryTicketOrder) {
			primaryTicketOrder.forEach((ticket, index) => {
				ticket.key = index;
			});
		}

		if (secondaryTicketOrder) {
			secondaryTicketOrder.forEach((ticket, index) => {
				ticket.key = index;
			});
		}

		return (
			<form onSubmit={handleSubmit(this.save)}>
				<H1>Ticket ordering</H1>
				<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
					<ContentBlock>
						<Field
							name="ticketOrderOn"
							label="Enable ticket order"
							description="Select the order in which you would like your tickets to appear"
							component={SwitchField}
						/>

						{ticketOrderOn && displayComponent}
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							disabled={currentEvent.save.loading}
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
					</FormBar>
				</LoadErrorView>
			</form>
		);
	}
}

TicketOrder = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(TicketOrder);

const selector = formValueSelector(formName);

// filter deleted or disabled ticket and add ticketOrderType field
const getNewFilteredTickets = (tickets, ticketOrderType) => {
	if (tickets.length === 0) {
		return [];
	}
	let newList = [];
	for (let i = 0; i < tickets.length; i++) {
		const ticket = tickets[i];
		if (!ticket.deleted && !ticket.disabled && !ticket.isDonation) {
			ticket.ticketOrderType = ticketOrderType;
			newList.push(ticket);
		}
	}
	return newList;
};

// filter any ticket or package belong to group
const filterTicketsInGroup = (tickets, groups) => {
	for (let i = 0; i < groups.length; i++) {
		const group = groups[i];
		if (group.tickets && group.tickets.length) {
			for (let j = 0; j < group.tickets.length; j++) {
				const ticketId = group.tickets[j];
				tickets = tickets.filter((t) => t._id !== ticketId);
			}
		}
	}
	return tickets;
};

// collect all tickets and return combined array
const getAllTickets = (event) => {
	let allTicketTypes = getNewFilteredTickets(event.ticketTypes, "individual");
	const allTicketGroups = getNewFilteredTickets(event.ticketGroups, "group");
	let allPackageTickets = getNewFilteredTickets(event.packagedTickets, "package");
	allTicketTypes = filterTicketsInGroup(allTicketTypes, allTicketGroups);
	allPackageTickets = filterTicketsInGroup(allPackageTickets, allTicketGroups);
	const allTickets = [].concat(allTicketTypes).concat(allTicketGroups).concat(allPackageTickets);
	return allTickets;
};

// sort all tickets same as ticket orders
const sortAllTickets = (allTickets, ticketOrder) => {
	let returnTickets = [];
	let remainingTickets = [];

	for (let i = 0; i < allTickets.length; i++) {
		const ticket = allTickets[i];
		let isExist = false;
		for (let j = 0; j < ticketOrder.length; j++) {
			const order = ticketOrder[j];
			if (ticket._id === order._id) {
				returnTickets[j] = ticket;
				isExist = true;
				break;
			}
		}
		if (!isExist) {
			remainingTickets.push(ticket);
		}
	}
	returnTickets = returnTickets.filter((t) => t);
	returnTickets = returnTickets.concat(remainingTickets);
	return returnTickets;
};

// return initialValues
const getInitialValues = (event) => {
	let ticketOrder;

	let allTickets = getAllTickets(event);

	if (event.ticketOrder && event.ticketOrder.length > 0) {
		allTickets = sortAllTickets(allTickets, event.ticketOrder);
		ticketOrder = allTickets;
	} else {
		ticketOrder = allTickets;
	}
	return {
		...event,
		primaryTicketOrder: ticketOrder.filter((t) => !t.isSecondary),
		secondaryTicketOrder: ticketOrder.filter((t) => t.isSecondary)
	};
};

export default withRouterHooks(
	connect(
		(state) => {
			let initialValues = getInitialValues(state.currentEvent.event);

			return {
				initialValues,
				currentEvent: state.currentEvent,
				primaryTicketOrder: selector(state, "primaryTicketOrder"),
				secondaryTicketOrder: selector(state, "secondaryTicketOrder"),
				ticketOrderOn: selector(state, "ticketOrderOn")
			};
		},
		{ saveEvent }
	)(withSaveCheck(TicketOrder, formName))
);
