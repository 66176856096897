import { css } from "@emotion/css";
import { max } from "../../utils/CssUtils";
import { H4, H6 } from "../text/Text";

const buttonWrapper = css({
	width: 265,
	height: 74,
	border: "var(--border1)",
	borderRadius: "var(--rounded-sm)",
	display: "flex",
	padding: "var(--spacing-md)",
	":hover": {
		border: "var(--border1-hover)",
		h4: {
			textDecoration: "underline",
			textDecorationColor: "#323232"
		}
	},
	":focus": {
		outline: "none",
		boxShadow: `0 0 0 2px #323232`
	},
	[max(640)]: {
		width: "100%"
	}
});

const iconWrapper = css({
	backgroundColor: "#5D5C5F",
	width: 50,
	height: 50,
	borderRadius: "var(--rounded-sm)",
	justifyContent: "center",
	alignItems: "center",
	display: "flex"
});

const textWrapper = css({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	marginLeft: 10
});

interface AnchorButtonProps {
	title: string;
	description?: string;
	icon?: string;
	link?: string;
	onClick?: () => void;
}

const AnchorButton = ({ description, title, link, icon, onClick }: AnchorButtonProps) => {
	const linkProps = link ? { href: link, target: "_blank", rel: "noopener noreferrer" } : { onClick };
	return (
		<a className={buttonWrapper} tabIndex={0} {...linkProps}>
			{icon ? (
				<div className={iconWrapper}>
					<img src={icon} alt="" />
				</div>
			) : null}
			<div className={textWrapper}>
				{description ? (
					<H6 bold={false} style={{ marginBottom: 0 }}>
						{description.toUpperCase()}
					</H6>
				) : null}
				<H4 bold={false} style={{ marginBottom: 0 }}>
					{title}
				</H4>
			</div>
		</a>
	);
};

export default AnchorButton;
