import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField, LegacyInputField, RichTextAreaField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, H4, P } from "@/components/text/Text";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import LocationService from "@/services/LocationService";
import {
	clearRefundRequest,
	getGlobalRefundFundsAvailable,
	getOrder,
	openGiftCardsRefundsModal,
	refundGlobalOrder,
	sendOrder
} from "@/state/actions/orderActions";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector, isDirty, reduxForm } from "redux-form";
import GiftCardTable from "./GiftCardTable";
import RefundConfirmationModal from "./RefundConfirmationModal";

const formName = "orderRefund";

const detailsWrapper = css`
	display: flex;
	justify-content: space-between;
	div {
		display: flex;
		flex-direction: column;
		P {
			margin-bottom: 2px;
		}
	}
`;

let Refund = (props) => {
	const {
		totalRefundAmount,
		sendEmails,
		dispatch,
		change,
		giftCardsToCancel,
		allGiftCardsSelected,
		globalFundsAvailable,
		handleSubmit,
		navigate,
		isDirty,
		refundGlobalOrder
	} = props;
	const { order } = props.order;
	const { loading, error } = props.order.refund;

	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const { isAdmin } = useCurrentUser();

	const load = () => {
		const { params } = props;
		const { orderId } = params;
		props.clearRefundRequest();
		props.getOrder(orderId);
		props.getGlobalRefundFundsAvailable(orderId);
	};

	const refund = (values) => {
		setConfirmationModalOpen(false);
		refundGlobalOrder(order._id, values);
	};

	const selectAllGiftCards = () => {
		dispatch(
			change(
				"giftCardsToCancel",
				Object.keys(giftCardsToCancel).reduce((giftCards, key) => {
					giftCards[key] = {
						...giftCardsToCancel[key],
						cancel: !allGiftCardsSelected,
						amount: !allGiftCardsSelected ? globalFundsAvailable[key] : giftCardsToCancel[key].amount
					};
					return giftCards;
				}, {})
			)
		);
	};

	const setRefundAmount = (itemType, id, isCancelChecked, maxAvailable) => {
		if (isCancelChecked) {
			dispatch(change(`${itemType}.${id}.amount`, maxAvailable));
		} else if (maxAvailable === props[itemType][id].amount) {
			dispatch(change(`${itemType}.${id}.amount`, 0));
		}
	};

	useEffect(() => {
		load();
	}, []);
	if (!order) return null;

	return (
		<LoadErrorView loading={loading} error={error} retryAction={load}>
			<form>
				<H1>{order.type} Order Refund</H1>
				<RefundConfirmationModal
					confirmationModalOpen={confirmationModalOpen}
					setConfirmationModalOpen={setConfirmationModalOpen}
					submit={handleSubmit(refund)}
					giftCardsToCancel={giftCardsToCancel}
					totalRefundAmount={totalRefundAmount}
					sendEmails={sendEmails}
				/>
				<ContentBlock>
					<div className={detailsWrapper}>
						<div>
							<P>
								<b>Order number:</b>{" "}
								<Link to={`/console/my-events/${order.eventId}/orders/${order._id}`}>{order.orderName}</Link>
							</P>
							<P>
								<b>Customer:</b> {order.firstName || ""} {order.lastName || ""}
							</P>
							<P>{order.email}</P>
							<P>{order.mobile}</P>
						</div>
						<div>
							<P>
								<b>Order date:</b>{" "}
								{new HumaniDate(order.completedAt, "guess", LocationService.getLocation(true)).formatting.dateTime()}
							</P>
						</div>
					</div>
					<hr />
					<P>
						Enter your desired refund amount and select which ticket you would like to cancel. Unselected tickets will
						not be cancelled.
					</P>
					<GiftCardTable
						giftCardsToCancel={giftCardsToCancel}
						selectAllGiftCards={selectAllGiftCards}
						allGiftCardsSelected={allGiftCardsSelected}
						globalFundsAvailable={globalFundsAvailable}
						setRefundAmount={setRefundAmount}
					/>
					<div style={{ textAlign: "right" }}>
						<H4>Total to refund: {currency(totalRefundAmount)}</H4>
					</div>
					<Field
						name="reason"
						label="Reason for refund (optional)"
						placeholder="Reason"
						component={LegacyInputField}
						labelAfter
					/>
					<Field name="sendEmails" label="Send refund confirmation email" component={CheckboxField} inline labelAfter />
					{sendEmails ? (
						<Field
							name="customMessage"
							label="Send a custom refund message in the refund email"
							component={RichTextAreaField}
							basicEditor
						/>
					) : null}
					{isAdmin ? (
						<>
							<br />
							<Field
								name="manualRefund"
								label="Funds have been returned to user (eg. chargeback)."
								component={CheckboxField}
								defaultChecked
								inline
								labelAfter
							/>
						</>
					) : null}
				</ContentBlock>
				<FormBar>
					<LegacyButton
						style={{
							float: "right",
							width: "100px"
						}}
						type="warning"
						htmlType="button"
						onClick={() => setConfirmationModalOpen(true)}
						ariaLabel="Refund"
						disabled={!isDirty}
					>
						Refund
					</LegacyButton>
					<LegacyButton
						style={{
							float: "left",
							width: "100px"
						}}
						ariaLabel="Go back"
						onClick={() => {
							navigate(`/console/promote/gift-cards/order/${props.params?.orderId}`);
						}}
					>
						Go back
					</LegacyButton>
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

Refund = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Refund);

export default connect(
	(state) => {
		const initialValues = { sendEmails: false };
		const order = state?.order?.order;
		if (order) {
			const { giftCards } = order;
			if (giftCards && giftCards.length) {
				initialValues.giftCardsToCancel = giftCards.reduce((giftCards, giftCard) => {
					giftCards[giftCard._id] = {
						amount: 0,
						cancel: false,
						totalsV2: giftCard.totalsV2,
						_id: giftCard._id,
						firstName: giftCard.firstName,
						lastName: giftCard.lastName,
						giftCardTypeName: giftCard.giftCardTypeName,
						status: giftCard.status
					};
					return giftCards;
				}, {});
			}
		}
		const selector = formValueSelector(formName);
		const sendEmails = selector(state, "sendEmails");

		const giftCardsToCancel = selector(state, "giftCardsToCancel");
		const allGiftCardsSelected = giftCardsToCancel
			? Object.keys(giftCardsToCancel).every((key) => giftCardsToCancel[key].cancel)
			: false;

		const totalValueOfGiftCardsToRefund = giftCardsToCancel
			? Object.keys(giftCardsToCancel).reduce((giftCardValue, key) => giftCardValue + giftCardsToCancel[key].amount, 0)
			: 0;
		const totalRefundAmount = totalValueOfGiftCardsToRefund;
		const totalAmountAvailableToRefund = state?.payments?.totalRemainingIncludingRebates || 0;

		return {
			order: state.order,
			initialValues,
			sendEmails,
			giftCardsToCancel,
			allGiftCardsSelected,
			totalRefundAmount,
			totalAmountAvailableToRefund,
			globalFundsAvailable: state.order?.globalFundsAvailable,
			isDirty: isDirty(formName)(state)
		};
	},
	{
		getOrder,
		getGlobalRefundFundsAvailable,
		sendOrder,
		openGiftCardsRefundsModal,
		refundGlobalOrder,
		clearRefundRequest
	}
)(withRouterHooks(Refund));
