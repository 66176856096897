import TrackingEvent from "./trackingEvent";

type UserActiveEventInternalProps = Record<string, never>;

type UserActiveEventDataLayerMessage = {
	event: string;
};

export class UserActiveEvent extends TrackingEvent<UserActiveEventInternalProps> {
	constructor(userCategory: "charity" | "conference" | "festival" | "education" | "business") {
		// This event has historically used the user's category as its GTM DataLayer event name, but the user's category is not guaranteed to have a value.
		// We protect against it being undefined in `toDataLayerMessage`, but this shouldn't be standard practice. Going forward, all events meant for GTM should have their own static event name.
		// I've checked with Frank / Darren, and they're unsure what this event is for. So it's purely a port of existing behaviour. Expect it will be reworked later.
		super("User Active", userCategory);
	}

	public toDataLayerMessage(): UserActiveEventDataLayerMessage | Record<string, never> {
		if (!this.dataLayerEventName) {
			return {};
		}

		return {
			event: this.dataLayerEventName
		};
	}
}
