import { CodeBlock, IconNavigationMenu } from "@/ui";
import { Flex } from "@/ui/antd";
import { HomeOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { FC, useState } from "react";

const props = `type IconNavigationMenuProps = {
  items: NavigationMenuItem[];
  onSelect: (key: string) => void;
  selected: string;
};
`;

const sample = `import { IconNavigationMenu } from "@/ui";
import { HomeOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";

const [selected, setSelected] = useState("1");

<IconNavigationMenu
  items={[
    {
      key: "1",
      icon: <HomeOutlined />,
      label: "Home"
    },
    {
      key: "2",
      icon: <SettingOutlined />,
      label: "Setting"
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "User"
    }
  ]}
  onSelect={(key) => setSelected(key)}
  selected={selected}
/>
`;

export const IconNavigationMenuPage: FC = () => {
	const [selected, setSelected] = useState("1");

	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<IconNavigationMenu
				items={[
					{
						key: "1",
						icon: <HomeOutlined />,
						label: "Home"
					},
					{
						key: "2",
						icon: <SettingOutlined />,
						label: "Setting"
					},
					{
						key: "3",
						icon: <UserOutlined />,
						label: "User"
					}
				]}
				onSelect={(key) => setSelected(key)}
				selected={selected}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
