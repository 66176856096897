import { Component } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { LegacyInputField, RichTextAreaField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { H1 } from "@/components/text/Text";
import { clearAppMessage, createAppMessage, updateAppMessage } from "@/state/actions/scanningMessageActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { getTicketDisplayName } from "@/utils/Ticket";
import { required } from "@/utils/Validators";
import SelectAttendees from "./SelectAttendees";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "scanningMessage";

class ScanningMessageForm extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.attendeeSelected = this.attendeeSelected.bind(this);
	}
	save(values) {
		const { params, createAppMessage, updateAppMessage, navigate } = this.props;
		const eventId = params?.eventId;
		const messageId = params?.messageId;
		if (messageId) {
			updateAppMessage(messageId, values);
		} else {
			createAppMessage(eventId, values);
		}
		navigate(`/console/my-events/${eventId}/app/messages`);
	}

	componentWillUnmount() {
		this.props.clearAppMessage();
	}

	attendeeSelected(evt) {
		const { dispatch } = this.props;
		dispatch(change(formName, "appliesTo.ticketIds", evt));
	}

	titleRequired = required("A name is required");
	subjectRequired = required("A subject is required");
	templateRequired = required("A message is required");
	scheduleDateRequired = required("A date is required");
	appliesToRequired = required("Apply to is required");

	render() {
		const { handleSubmit, currentEvent, appliesTo, attendeeIds, eventDateIds, scanningMessage } = this.props;
		const type = appliesTo ? appliesTo.type : "";

		const dates = this.props.currentEvent.event.dates;
		const dateOptions = [];
		dates.forEach((date) => {
			const dateStr = `${date.display.date}, ${date.display.time}`;
			dateOptions.push({
				value: date._id,
				label: dateStr
			});
		});

		const toOptions = [
			{
				value: "all",
				label: "All Attendees"
			},
			{
				value: "ticketType",
				label: "By Ticket Type"
			},
			{
				value: "custom",
				label: "Select"
			}
		];

		const ticketTypeOptions = currentEvent.event.ticketTypes.map((ticket) => {
			return { value: ticket._id, label: getTicketDisplayName(ticket) };
		});

		return (
			<div>
				<form onSubmit={handleSubmit(this.save)}>
					<H1>Scanning App Message</H1>
					<ContentBlock style={{ background: "#fff", padding: 24, margin: 0 }}>
						<Field
							name="header"
							label="Heading"
							description="What is the title of your Message?"
							component={LegacyInputField}
							validate={this.titleRequired}
							required
						/>
						<Field
							name="eventDateIds"
							label="Event Date"
							description="For which event date?"
							component={SelectField}
							options={dateOptions}
							style={{ width: "50%" }}
							mode="multiple"
						/>
						<Field
							name="appliesTo.type"
							label="Applies To"
							placeholder="Applies To"
							description="Who does this message apply to?"
							component={SelectField}
							style={{ width: "50%" }}
							options={toOptions}
							validate={this.appliesToRequired}
							required
						/>
						{type === "ticketType" ? (
							<Field
								label="Select Ticket Types"
								description="Which ticket types will be sent this message?"
								mode="multiple"
								name="appliesTo.ticketTypeIds"
								placeholder="All Ticket Types"
								component={SelectField}
								options={ticketTypeOptions}
							/>
						) : null}
						{type === "custom" ? (
							<SelectAttendees
								selectedAttendeeIds={attendeeIds}
								attendeeSelected={this.attendeeSelected}
								eventDateIds={eventDateIds}
							/>
						) : null}

						<Field
							label="Message"
							id="message"
							name="message"
							mentionOptions={["firstName", "lastName", "email", "editLink"]}
							component={RichTextAreaField}
							validate={this.templateRequired}
							rows={10}
							placeholder="This is the best event ever because ..."
							required
						/>
					</ContentBlock>

					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							disabled={scanningMessage.loading}
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</div>
		);
	}
}

// Decorate with redux-form
ScanningMessageForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(ScanningMessageForm);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		return {
			initialValues: state.scanningMessage.appMessage ? state.scanningMessage.appMessage : {},
			currentEvent: state.currentEvent,
			scanningMessage: state.scanningMessage,
			appliesTo: selector(state, "appliesTo"),
			attendeeIds: selector(state, "appliesTo.ticketIds")
		};
	},
	{ createAppMessage, updateAppMessage, clearAppMessage }
)(withRouterHooks(ScanningMessageForm));
