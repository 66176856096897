import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import AutomaticDiscounts from "@/events/event/automaticDiscounts/AutomaticDiscounts";
import { DiscountCodeTable } from "@/events/event/discountCodes/DiscountCodeTable";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { Tabs } from "@/ui/antd";
import { HelpIds } from "@/utils/Help";
import { Component } from "react";
import { connect } from "react-redux";

class DiscountCodesPage extends Component {
	constructor(props) {
		super(props);
		this.state = { key: "1" };
	}

	/**
	 * tabChange changes tabs
	 * @param value tab index
	 */
	tabChange = (value) => {
		//update
		const { navigate } = this.props;
		navigate(value);
	};

	render() {
		const { discountCodes, currentEvent, location } = this.props;
		const key = location.pathname;
		const eventId = currentEvent.event._id;
		return (
			<LoadErrorView error={discountCodes.getRequest.error} retryAction={this.save}>
				<PageHeading
					title="Discounts"
					helpButton={{
						link: HelpIds.discountCodes,
						title: "Help - Discounts"
					}}
				/>
				<ContentBlock>
					<Tabs
						activeKey={key}
						onChange={this.tabChange}
						items={[
							{
								children: <DiscountCodeTable />,
								key: `/console/my-events/${eventId}/discounts/codes`,
								label: "Discount Codes"
							},
							{
								children: <AutomaticDiscounts />,
								key: `/console/my-events/${eventId}/discounts/automatic`,
								label: "Automatic Discounts"
							}
						]}
					/>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search,
		discountCodes: state.discountCodes,
		currentEvent: state.currentEvent
	}),
	{}
)(withRouterHooks(DiscountCodesPage));
