import { Field } from "redux-form";
import { SelectField } from "@/components/Fields";

const FundLocationFilters = () => {
	return (
		<Field
			style={{ width: 100 }}
			name="fundsLocation"
			label="Funds"
			component={SelectField}
			options={[
				{ value: "all", label: "All" },
				{ value: "held", label: "Process by Humanitix" },
				{ value: "braintree", label: "Braintree" },
				{ value: "stripe", label: "Stripe" },
				{ value: "zipmoney", label: "Zip Money" },
				{ value: "after", label: "After Pay" },
				{ value: "invoice", label: "Invoices" },
				{ value: "till", label: "Till" },
				{ value: "worldpay", label: "Worldpay" },
				{ value: "bpoint", label: "BPoint" },
				{ value: "stripe-payments", label: "Stripe Payments" },
				{ value: "discover-nsw", label: "Discover NSW" },
				{ value: "credit", label: "Credit" },
				{ value: "gift-card", label: "Gift card" },
				{
					value: "invoice-humanitix",
					label: "Invoices collected by Humanitix"
				},
				{ value: "invoice-client", label: "Invoice collected by Client" },
				{ value: "manual", label: "Manual orders" },
				{
					value: "manual-humanitix",
					label: "Manual orders collected by Humanitix"
				},
				{ value: "manual-client", label: "Invoice orders collected by Client" },
				{ value: "vouchers", label: "Vouchers" }
			]}
		/>
	);
};

export default FundLocationFilters;
