import InputLabel from "@/components/fields/helpers/InputLabel";
import { Spacer } from "@/ui";
import styled from "@emotion/styled";
import { CheckCircle } from "@phosphor-icons/react";
import { Input } from "@/ui/antd";
import copy from "copy-text-to-clipboard";
import { FC, useState } from "react";

const Container = styled.div<{
	peachButton?: boolean;
	fullWidth: boolean;
}>`
	position: relative;
	${(props) => (props.fullWidth ? `width: 100%;` : "")}

	input {
		cursor: pointer;
	}

	.ant-input-search {
		max-width: unset;
	}

	.ant-input {
		text-overflow: ellipsis;
	}

	#copyLink {
		color: #b5b5b5;
	}

	.ant-input-search-button {
		${(props) =>
			props.peachButton
				? `
					background-color: #ffb18f;
					color: black;

					&:hover {
						background-color: #ffb18f;
						filter: brightness(90%);
					}
					`
				: ""}
	}
`;

const Alert = styled.div`
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 1px 4px 0 rgba(9, 30, 66, 0.13);
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 4px 8px;
	position: absolute;
	right: 0;
	top: 0;
	transform: translateY(-110%);
	transition: opacity 3s ease-in-out, width 0.5s ease-in-out;
	white-space: nowrap;
	width: 84px;

	img {
		height: 20px;
		width: 20px;
	}
`;

type CopyFieldProps = {
	disabled?: boolean;
	fullWidth?: boolean;
	peachButton?: boolean;
	label?: string;
	value: string;
};

export const CopyField: FC<CopyFieldProps> = ({ disabled, fullWidth = false, peachButton, label, value }) => {
	const [show, setShow] = useState(false);

	const handleCopy = () => {
		copy(value);
		setShow(true);
		setTimeout(() => setShow(false), 2000);
	};

	return (
		<Container peachButton={peachButton} fullWidth={fullWidth}>
			{show && (
				<Alert>
					<CheckCircle size={16} color="#008257" weight="fill" />
					<Spacer horizontal size="var(--spacing-xs)" />
					<span>Copied</span>
				</Alert>
			)}
			{label ? <InputLabel label={label} id="copyLink" /> : null}
			<Input.Search
				disabled={disabled}
				enterButton="Copy"
				id="copyLink"
				onClick={handleCopy}
				onSearch={handleCopy}
				readOnly
				value={value}
			/>
		</Container>
	);
};
