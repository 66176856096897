import { Flex, Select } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "../components/AppBlocks";
import LoadErrorView from "../components/LoadErrorView";
import Page from "../components/Page";
import SearchRefinements from "../components/SearchRefinements";
import SimplePagination from "../components/SimplePagination";
import NoResults from "../components/noResults/NoResults";
import { H3, P } from "../components/text/Text";
import { updateSearch } from "../state/actions/searchActions";
import { find } from "../state/actions/userActions";

class Users extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
	}

	defaultSearch = {
		sortBy: "relevant"
	};

	load(page = 1) {
		this.props.find(this.props.search, page);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	handleSortChange(value) {
		this.props.updateSearch({ sortBy: value }, true);
	}

	render() {
		const { search } = this.props;
		const { page } = search;
		const { results, loading, error } = this.props.users;
		const hasResults = results.users && results.users.length;
		const noResults = results.users && results.users.length === 0 && !loading;

		return (
			<Page pageName="Users" type="details">
				<LoadErrorView loading={loading} error={error} retryAction={this.load}>
					<ContentBlock>
						<div>
							<Flex justify="space-between" align="start">
								<SearchRefinements load={this.load} defaultSearch={this.defaultSearch} wrapperMarginBottom={0} />
								<Select
									size="large"
									style={{ width: 250, display: "inline" }}
									defaultValue="relevant"
									value={search.sortBy || "relevant"}
									onChange={this.handleSortChange}
									options={[
										{ value: "relevant", label: "Most Relevant" },
										{ value: "firstName", label: "First name" },
										{ value: "lastName", label: "Last name" },
										{ value: "newest", label: "Most Recent" },
										{ value: "oldest", label: "Least Recent" }
									]}
								/>
							</Flex>

							{noResults ? <NoResults title="No users found" /> : null}
							{hasResults ? (
								<div>
									{results.users.map((user) => (
										<Link key={user._id} to={`/console/admin/users/${user.id}`}>
											<UserRow {...user} />
										</Link>
									))}
									<div style={{ textAlign: "right", marginTop: 10 }}>
										<SimplePagination
											currentPage={page}
											pageAction={this.loadPage}
											style={{ textAlign: "right", margin: 0 }}
										/>
									</div>
								</div>
							) : null}
						</div>
					</ContentBlock>
				</LoadErrorView>
			</Page>
		);
	}
}

const UserRow = ({ firstName, lastName, email, mobile, organization }) => {
	return (
		<div style={{ display: "flex", borderTop: "1px solid #ccc", padding: 6 }}>
			<div style={{ padding: "6px 12px" }}>
				<H3>
					{firstName} {lastName}
				</H3>
				<P>
					{email}
					<br />
					{mobile}
					{organization ? (
						<span>
							<br />
							{organization}
						</span>
					) : null}
				</P>
			</div>
		</div>
	);
};
export default connect(
	(state) => ({
		users: state.users,
		search: state.search
	}),
	{
		find,
		updateSearch
	}
)(Users);
