export const GET_SEATING_MAP_REQUEST = "GET_SEATING_MAP_REQUEST";
export const GET_SEATING_MAP_SUCCESS = "GET_SEATING_MAP_SUCCESS";
export const GET_SEATING_MAP_ERROR = "GET_SEATING_MAP_ERROR";

export const GET_SEATING_MAPS_REQUEST = "GET_SEATING_MAPS_REQUEST";
export const GET_SEATING_MAPS_SUCCESS = "GET_SEATING_MAPS_SUCCESS";
export const GET_SEATING_MAPS_ERROR = "GET_SEATING_MAPS_ERROR";

export const SAVE_TICKET_MAPPING_REQUEST = "SAVE_TICKET_MAPPING_REQUEST";
export const SAVE_TICKET_MAPPING_SUCCESS = "SAVE_TICKET_MAPPING_SUCCESS";
export const SAVE_TICKET_MAPPING_ERROR = "SAVE_TICKET_MAPPING_ERROR";
export const SAVE_TICKET_MAPPING_CONFLICT = "SAVE_TICKET_MAPPING_CONFLICT";
