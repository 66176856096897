import { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { LoadingSpinner } from "@/components/AppBlocks";
import { SelectField } from "@/components/Fields";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { findTags } from "@/state/actions/tagActions";

class Tags extends Component {
	componentDidMount() {
		this.props.findTags({});
	}

	render() {
		const { tags } = this.props;
		const label = "Tags";
		const description = "Select the tags that apply to your event";
		const tagOptions =
			tags && tags.tags
				? tags.tags.map((tag) => {
						return {
							value: tag._id,
							label: tag.name
						};
				  })
				: [];

		if (tags.loading) {
			return (
				<div style={{ minHeight: 98 }}>
					<InputLabel label={label} description={description} />
					<LoadingSpinner size={30} style={{ marginRight: 12, marginTop: 6 }} />
				</div>
			);
		}

		return (
			<Field
				name="tagIds"
				label={label}
				description={description}
				component={SelectField}
				style={{ maxWidth: 300, width: "100%" }}
				mode="multiple"
				options={tagOptions}
			/>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		tags: state.tags
	}),
	{ findTags }
)(Tags);
