import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3, P } from "./text/Text";

const style = {
	color: "red",
	padding: 24
};

interface IErrorMsgProps {
	title: string;
	message: string;
	retryAction?: () => void;
}

const ErrorMsg = ({ title, message, retryAction }: IErrorMsgProps) => {
	return (
		<div style={style}>
			<H3>{title}</H3>
			<P>{message}</P>
			{retryAction ? (
				<LegacyButton ariaLabel="Retry" onClick={retryAction}>
					Retry
				</LegacyButton>
			) : (
				""
			)}
		</div>
	);
};

export default ErrorMsg;
