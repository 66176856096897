import { ContentBlock } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import FormBar from "@/components/FormBar";
import PageHeading from "@/components/PageHeading";
import { saveEvent } from "@/state/actions/eventActions";
import { updateSearch } from "@/state/actions/searchActions";
import { find, getCsv, updateShowSettingsTabOnly } from "@/state/actions/waitlistActions";
import { HelpIds } from "@/utils/Help";
import { getUrlParameter } from "@/utils/Urls";
import { Tabs } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import ManageWaitlist from "./ManageWaitlist";
import SaveWaitlistSettingsButton from "./SaveWaitlistSettingsButton";
import WaitlistSettings from "./WaitlistSettings";

const TAB = {
	Manage: "manage",
	Settings: "settings"
};
const Waitlist = ({
	saveEvent,
	currentEvent,
	find,
	getCsv,
	match,
	waitlist,
	updateSearch,
	updateShowSettingsTabOnly
}) => {
	const navigate = useNavigate();
	const urlTab = getUrlParameter("tab");

	const defaultTab = urlTab || TAB.Manage;
	const [tab, updateState] = useState(waitlist.showSettingsTabOnly ? TAB.Settings : defaultTab);

	const defaultSearch = {
		page: 1,
		eventId: currentEvent.event._id,
		query: ""
	};

	useEffect(() => {
		loadWaitlist();
	}, []);

	/*
  //this is for a specific case:
  step 1. waitlist is disabled but not empty.
  step 2. they remove all the waitlist.
  final step. we should switch to settings tab and update the url. as the manage tab will disapear. 
  */
	useEffect(() => {
		if (waitlist.showSettingsTabOnly) tabChange(TAB.Settings);
	}, [waitlist.showSettingsTabOnly]);

	const tabChange = (tab) => {
		updateState(tab);

		navigate(`?tab=${tab}`);
	};

	const saveSettings = (values) => {
		saveEvent(currentEvent.event._id, { waitlistSettings: values });
		updateShowSettingsTabOnly(values.enabled);
	};

	const loadWaitlist = () => {
		find(currentEvent.event._id);
	};

	const loadPage = (page) => {
		updateSearch({ page }, true);
	};

	const downloadWaitlistCsv = () => {
		getCsv(currentEvent.event._id);
	};

	const ActionBar =
		waitlist.showSettingsTabOnly || tab === TAB.Settings ? (
			<FormBar formLayout="horizontal">
				<SaveWaitlistSettingsButton saveSettings={saveSettings} />
			</FormBar>
		) : (
			<ExportBar buttonText="Export Waitlist" buttonClick={downloadWaitlistCsv} loading={waitlist.download.loading} />
		);
	return (
		<>
			<PageHeading
				title="Waitlist"
				helpButton={{
					link: HelpIds.waitlist,
					title: "Help - Waitlist"
				}}
			/>
			<ContentBlock>
				{waitlist.showSettingsTabOnly ? (
					<WaitlistSettings match={match} saveSettings={saveSettings} />
				) : (
					<Tabs
						activeKey={tab}
						onChange={tabChange}
						items={[
							{
								children: (
									<ManageWaitlist
										loadWaitlist={loadWaitlist}
										loadPage={loadPage}
										downloadWaitlistCsv={downloadWaitlistCsv}
										defaultSearch={defaultSearch}
										tabChange={tabChange}
									/>
								),
								key: TAB.Manage,
								label: "Manage waitlist"
							},
							{
								children: <WaitlistSettings match={match} saveSettings={saveSettings} />,
								key: TAB.Settings,
								label: "Settings"
							}
						]}
					/>
				)}
			</ContentBlock>
			{ActionBar}
		</>
	);
};

export default connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			waitlist: state.waitlist,
			search: state.search
		};
	},
	{ find, getCsv, updateShowSettingsTabOnly, saveEvent, updateSearch }
)(Waitlist);
