import { default as axios } from "axios";

const PublicService = {
	async attemptAuthorization(authorizationId: string, email: string) {
		const response = await axios.post("/public-api/authorize", {
			params: { token: authorizationId, email }
		});
		return response.data;
	}
};

export default PublicService;
