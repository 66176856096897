import { Button, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getPayoutsReversedReport,
	getPayoutsReversedReportCsv,
	resetPayoutsReversedReport
} from "@/state/actions/adminReports/financeActions";
import { currency } from "@/utils/Format";
import DatesSelector from "../../DatesSelector";
import DayEndsAt from "../DayEndsAt";

class PayoutsReversedReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getPayoutsReversedReport } = this.props;
		getPayoutsReversedReport(values.startDate, values.endDate, values.dayEndsAt);
	}
	retry() {
		const { resetPayoutsReversedReport } = this.props;
		resetPayoutsReversedReport();
	}

	downloadCsv() {
		const { startDate, endDate, getPayoutsReversedReportCsv } = this.props;
		getPayoutsReversedReportCsv(startDate, endDate);
	}

	runLoad = () => {
		const { startDate, endDate, dayEndsAt } = this.props;
		this.load({ startDate, endDate, dayEndsAt });
	};

	render() {
		const { touch, handleSubmit, payoutsReversedReport, startDate, endDate, dayEndsAt } = this.props;
		let columns = [
			{
				title: "Event",
				dataIndex: "eventName",
				width: 200,
				render: (eventName, record) => {
					return <Link to={`/console/my-events/${record.eventId}/payments/payouts`}>{eventName}</Link>;
				}
			},
			{
				title: "Transaction Id",
				dataIndex: "transactionId"
			},
			{
				title: "Amount",
				dataIndex: "amount",
				render: (value) => currency(value)
			},
			{
				title: "Paid By",
				dataIndex: "userName"
			},
			{
				title: "Date Paid",
				dataIndex: "date"
			},
			{
				title: "Reversed",
				dataIndex: "reversedAt"
			},
			{
				title: "Notes",
				dataIndex: "notes"
			}
		];

		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Reversed Payouts Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView
					loading={payoutsReversedReport.loading}
					error={payoutsReversedReport.error}
					retryAction={this.retry}
				>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								<DayEndsAt />
							</div>
							<div style={{ width: 60 }}>
								{payoutsReversedReport.data ? (
									<IconButton
										icon="export"
										type="background"
										showTooltip
										tooltip="Export"
										onClick={this.downloadCsv}
										ariaLabel="Export"
									/>
								) : null}
							</div>
						</div>
						<Button
							type="primary"
							onClick={this.runLoad}
							disabled={!startDate || !endDate || !dayEndsAt}
							ariaLabel="Load"
						>
							Load
						</Button>
					</ReportFilters>
					<ReportContent>
						{payoutsReversedReport.data ? (
							<div>
								<Table columns={columns} dataSource={payoutsReversedReport.data} rowKey="_id" pagination={false} />
								<ExportBar buttonText="Export" buttonClick={this.downloadCsv} />
							</div>
						) : null}
					</ReportContent>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "payoutsReversedReport";
const selector = formValueSelector(formName);
PayoutsReversedReport = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(PayoutsReversedReport);

export default connect(
	(state) => ({
		initialValues: {
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		payoutsReversedReport: state.adminReports.finance.payoutsReversedReport,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{
		getPayoutsReversedReport,
		getPayoutsReversedReportCsv,
		resetPayoutsReversedReport
	}
)(PayoutsReversedReport);
