import { Alert, Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { CheckboxField, TextAreaField } from "../components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import { H4, P } from "../components/text/Text";
import { closeLockBankAccountModal } from "../state/actions/bankAccountsActions";
import { saveUser } from "../state/actions/userActions";

let LockBankAccountModal = ({
	closeLockBankAccountModal,
	currentUser,
	bankAccounts,
	saveUser,
	handleSubmit,
	formValues
}) => {
	const { showLockBankAccountModal } = bankAccounts;
	const { bankAccountLockedByAdmin, unlockBankAccountProcedure } = currentUser.user ? currentUser.user : {};

	const toggledLocked = (values = {}) => {
		if (values.procedureText) {
			saveUser(currentUser.user.id, {
				bankAccountLockedByAdmin: !bankAccountLockedByAdmin,
				unlockBankAccountProcedure: values.procedureText
			});
		} else {
			saveUser(currentUser.user.id, {
				bankAccountLockedByAdmin: !bankAccountLockedByAdmin
			});
		}
		closeLockBankAccountModal();
	};

	const disableWhenUnlocking = unlockBankAccountProcedure && !formValues.confirmed;

	const disableWhenLocking = unlockBankAccountProcedure ? false : !formValues.procedureText;

	let modalContent;
	if (bankAccountLockedByAdmin && unlockBankAccountProcedure) {
		modalContent = (
			<>
				<Alert
					description={
						<>
							<H4>Procedures to unlock bank account given by user</H4>
							{unlockBankAccountProcedure.split("\n").map((line, index) => (
								<P key={`line_${index}`}>{line}</P>
							))}
						</>
					}
					type="warning"
					showIcon
					style={{ margin: "0 0 12px 0", maxWidth: 700 }}
				/>
				<form>
					<Field
						label="Confirmed the procedure is met."
						id="confirmed"
						name="confirmed"
						key="confirmed"
						component={CheckboxField}
						inline
						labelAfter
					/>
				</form>
			</>
		);
	} else if (bankAccountLockedByAdmin && !unlockBankAccountProcedure) {
		modalContent = (
			<Alert
				description={
					<>
						<H4>No procedure is given by the host. Safe to unlock.</H4>
					</>
				}
				type="success"
				showIcon
				style={{ margin: "0 0 12px 0", width: "100%" }}
			/>
		);
	} else {
		modalContent = (
			<>
				<Alert
					description={
						<>
							<H4>
								After locking your bank accounts you will need Humanitix to email and call the account owner to edit or
								add new bank accounts.{" "}
							</H4>
						</>
					}
					type="info"
					showIcon
					style={{ margin: "0 0 12px 0", width: "100%" }}
				/>
				<form>
					<Field
						label="Please add any additional steps you would like our team to implement
        before unlocking your bank account in the future."
						id="procedureText"
						name="procedureText"
						key="procedureText"
						component={TextAreaField}
						required
						rows={6}
					/>
				</form>
			</>
		);
	}

	return (
		<Modal
			open={showLockBankAccountModal}
			destroyOnClose
			onCancel={closeLockBankAccountModal}
			footer={[
				<ButtonsBar footer>
					<LegacyButton key="cancel" onClick={closeLockBankAccountModal} htmlType="button" ariaLabel="Cancel">
						Cancel
					</LegacyButton>
					<LegacyButton
						onClick={handleSubmit(toggledLocked)}
						disabled={bankAccountLockedByAdmin ? disableWhenUnlocking : disableWhenLocking}
						key="submit"
						type="warning"
						htmlType="submit"
						ariaLabel="confirm"
					>
						{bankAccountLockedByAdmin ? "Unlock" : "Lock"}
					</LegacyButton>
				</ButtonsBar>
			]}
			width={600}
			height={202}
			bodyStyle={{
				paddingTop: 38,
				paddingBottom: 32,
				margin: "0px auto"
			}}
		>
			{modalContent}
		</Modal>
	);
};

const formName = "unlockingBankAccountProcedure";
const selector = formValueSelector(formName);
LockBankAccountModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true
})(LockBankAccountModal);

export default connect(
	(state) => {
		return {
			initialValues: {
				procedureText: state.currentUser.user.unlockBankAccountProcedure
			},
			currentUser: state.currentUser,
			bankAccounts: state.bankAccounts,
			formValues: selector(state, "procedureText", "confirmed")
		};
	},
	{ saveUser, closeLockBankAccountModal }
)(LockBankAccountModal);
