const ZoomOut: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<rect width="14" height="2" x="5" y="11" fill={color} rx="1" />
				<path d="M0 0H24V24H0z" />
			</g>
		</svg>
	);
};

export default ZoomOut;
