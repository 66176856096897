import { usePromptRedux } from "@/hooks/usePrompt";
import { ComponentType, FC, ReactElement } from "react";

type WrappedComponentProps = {
	children: ReactElement;
	formName: string;
	message: string;
};

const WrappedComponent: FC<WrappedComponentProps> = ({ children, formName, message }) => {
	usePromptRedux(message, formName);
	return children;
};

const withSaveCheck = <P extends object>(
	ChildComponent: ComponentType<P>,
	formName: string,
	message = "You have unsaved changes. Are you sure you want to leave?"
) => {
	return (props: P) => (
		<WrappedComponent formName={formName} message={message}>
			<ChildComponent {...props} />
		</WrappedComponent>
	);
};

export default withSaveCheck;
