import ReferralMain from "@/assets/referral/referral_main.svg";
import { ContentBlock, Heading } from "@/components/AppBlocks";
import { CopyField } from "@/components/CopyField";
import InfoIcon from "@/components/InfoIcon";
import LoadErrorView from "@/components/LoadErrorView";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { getUserReferral } from "@/state/actions/referralActions";
import { Col, Row } from "@/ui/antd";
import { firstOfType, max } from "@/utils/CssUtils";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Component } from "react";
import { connect } from "react-redux";

const breakdownWrapper = css({
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "space-between"
});

const breakdownBox = css({
	maxWidth: 120,
	backgroundColor: "#fafafa",
	padding: "12px 12px"
});

const breakdownText = css({
	fontWeight: 600
});

const referralImageWrapper = css({
	width: "100%",
	maxWidth: 220,
	display: "inline-block"
});

const breakdownValue = css({
	fontWeight: 600,
	fontSize: 20
});

const additionalBoxesWrapper = css({
	display: "flex",
	div: {
		[firstOfType()]: {
			marginRight: 16,
			display: "inline-table"
		}
	},
	[max(768)]: {
		display: "block",
		div: {
			[firstOfType()]: {
				marginRight: 0,
				display: "block"
			}
		}
	}
});

const emailWrapper = css({
	borderRadius: 4,
	border: "solid 1px #DEDEDE",
	padding: 8
});

/**
 * Referral program page.
 *
 * @class ReferralProgram
 * @extends {Component}
 */
class ReferralProgram extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
	}

	componentWillMount() {
		this.load();
	}

	load() {
		const { getUserReferral, userId } = this.props;
		getUserReferral(userId);
	}

	render() {
		const { userReferrals, overrideLocation } = this.props;
		const { loading = true, error, referral } = userReferrals;
		const styles = {
			topLeftCol: css`
				order: 1;
				@media (max-width: 576px) {
					order: 2;
				}
			`,
			topRightCol: css`
				order: 2;
				text-align: center;
				@media (max-width: 576px) {
					order: 1;
					text-align: center;
					margin-bottom: 24px;
				}
			`,
			infoBoxContainer: css`
				display: flex;
				justify-content: center;
				-webkit-flex-flow: row wrap;
			`,
			infoBox: css`
				width: 130px;
				padding-right: 12px;
				height: 98px;
				background: #fafafa;
				padding-left: 12px;
				margin: 6px;
			`,
			infoBoxText: css`
				font-weight: 500;
				margin-top: 12px;
				margin-bottom: 4px;
			`,
			number: css`
				font-size: 20px;
				font-weight: 600;
			`
		};

		const referralCode = referral.code || "";

		const referralLink = `${getConfig("WEBSITE_URL")}/${
			overrideLocation && overrideLocation.toLowerCase() ? overrideLocation.toLowerCase() : "au"
		}/?referralcode=${referralCode}`;

		const numberOfAllReferrals = referral?.referredUsers?.length || 0;
		const numberOfActiveReferrals =
			referral?.referredUsers?.filter((user) => !new HumaniDate(user.validTo).isPast)?.length || 0;

		const numberOfEvents = referral?.numberOfEvents || 0;

		const earningsPerEvent = referral?.percentage || 0;

		const earnedAmount = referral?.amountEarned ? currency(referral?.amountEarned) : currency(0);

		const earnedAmountHelp =
			"This is the amount you have earnt through our ambassador program. You can choose to be paid this money or donate it to one of our charity partners.";
		const allReferralHelp = "This is the number of accounts you have referred via the program.";
		const activeReferralHelp = "This is the number of your referrals that are not expired.";
		const numberOfEventsHelp = "This is the total number of events published by your referrals.";
		const earningsPerEventHelp =
			" This is the commission structure your account is set to. Note: earnings are based on ticket fees not ticket sales.";

		return (
			<div>
				<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
					<ContentBlock>
						<Row type="flex" gutter={24}>
							<Col xs={24} sm={24} md={12} lg={13} xl={13} className={styles.topLeftCol}>
								<P>
									Earn money, unlock rewards and make a social impact with Humanitix. It’s simple. Just invite people
									with your referral link below.
								</P>
								<P>We’ll handle the rest.</P>
								<CopyField value={referralLink} label="Unique referral link" />
							</Col>
							<Col xs={24} sm={24} md={12} lg={11} xl={11} className={styles.topRightCol}>
								<div className={referralImageWrapper}>
									<img src={ReferralMain} alt="Ambassador program" />
								</div>
							</Col>
						</Row>
					</ContentBlock>

					<ContentBlock>
						<div className={breakdownWrapper}>
							<div className={breakdownBox}>
								<div className={breakdownText}>
									All Referrals
									<InfoIcon tooltip={allReferralHelp} />
								</div>
								<div className={breakdownValue} data-cy="all-referrals">
									{numberOfAllReferrals}
								</div>
							</div>
							<div className={breakdownBox}>
								<div className={breakdownText}>
									Active Referrals
									<InfoIcon tooltip={activeReferralHelp} />
								</div>
								<div className={breakdownValue}>{numberOfActiveReferrals}</div>
							</div>
							<div className={breakdownBox}>
								<div className={breakdownText}>
									# of Events
									<InfoIcon tooltip={numberOfEventsHelp} />
								</div>
								<div className={breakdownValue} data-cy="number-of-referred-events">
									{numberOfEvents}
								</div>
							</div>
							<div className={breakdownBox}>
								<div className={breakdownText}>
									Earnings per Event
									<InfoIcon tooltip={earningsPerEventHelp} />
								</div>
								<div className={breakdownValue}>{earningsPerEvent} %</div>
							</div>
							<div className={breakdownBox}>
								<div className={breakdownText}>
									Earned Amount
									<InfoIcon tooltip={earnedAmountHelp} />
								</div>
								<div className={breakdownValue}>{earnedAmount}</div>
							</div>
						</div>
					</ContentBlock>

					<div className={additionalBoxesWrapper}>
						<ContentBlock>
							<Heading title="Reward System" />
							<P>
								Earn money from each event organised by your network. You can either choose to keep or donate your
								earnings.
							</P>
							<P>Check to see the impact you, your network and the wider Humanitix community are creating.</P>
						</ContentBlock>
						<ContentBlock>
							<Heading title="Email Template" />
							<P>
								We want to make your experience as smooth as possible. When sharing your unique referral link, use this
								friendly email template and begin spreading the good word!
							</P>
							<div className={emailWrapper}>
								<P>Hey @FirstName,</P>
								<P>
									I recently partnered with Humanitix for my @EventName event, and I couldn’t recommend them enough.
								</P>
								<P>
									Humanitix is an ethical ticketing platform. They’re the first not-for-profit to use booking fees to
									fund education projects for the most disadvantaged kids around Australia and abroad. The best part is
									that I got to choose the project and my community loved giving back at no extra cost!
								</P>
								<P>
									The platform is easy to use and they’re doing amazing work. You should check them out for your next
									event. Find out more about them at [insert shortened referral link].
								</P>
								<P>Best Wishes,</P>
							</div>
						</ContentBlock>
					</div>
				</LoadErrorView>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		userReferrals: state.userReferrals,
		overrideLocation: state.locations.overrideLocation,
		userId: state.auth.user?.id
	}),
	{
		getUserReferral
	}
)(ReferralProgram);
