import { useAuth } from "@/hooks/useAuth";
import { useAppSelector } from "@/state/hooks";
import { Dropdown, Tooltip } from "@/ui/antd";
import { css } from "@emotion/css";
import { FC } from "react";
import { Link } from "react-router-dom";
import iconReference from "../services/IconReference";
import UsersService from "../services/UsersService";
import { HelpUrls } from "../utils/Help";
import LinkButton from "./buttons/LinkButton";
import { H4 } from "./text/Text";

const icLifebouy = iconReference["lifebouy"];

const IdentityMsg = css({
	color: "#fff",
	padding: "0px 12px",
	textAlign: "right",
	h4: {
		color: "#fff",
		padding: 0,
		margin: 0
	},
	h5: {
		color: "#fff",
		padding: 0,
		margin: 0
	},
	"@media(max-width:868px)": {
		display: "none"
	}
});

const bubbleWrapper = css({
	borderRadius: "50%",
	backgroundColor: "#ffffff",
	color: "#323232",
	height: 30,
	width: 30,
	fontSize: 14,
	textAlign: "center",
	paddingTop: 4,
	margin: "3px 8px 0 8px",
	cursor: "pointer",
	"@media(max-width: 868px)": {
		backgroundColor: "#fafafa",
		border: "1px solid #dedede"
	}
});

const helpLinkWrapper = css({
	marginTop: 3,
	"@media(max-width: 868px)": {
		display: "none"
	},
	a: {
		minWidth: 70,
		color: "#fff",
		textDecoration: "none",
		fontSize: 14,
		fontWeight: 600,
		margin: "0",
		borderRadius: 4,
		padding: 4,
		display: "block"
	},
	"a:hover": {
		color: "#fff",
		background: "rgba(255, 255, 255, 0.1)"
	}
});

const UserDisplay: FC = () => {
	const currentUser = useAppSelector((state) => state.auth);
	const { assumingIdentity, discardIdentity, signOut } = useAuth();

	const userInitials = UsersService.getUserInitials(currentUser.user);
	const menuItems = [
		{
			key: "0",
			label: (
				<Tooltip
					placement="left"
					title={`${currentUser.user?.email}${currentUser.user?.role === "admin" ? " (Admin)" : ""}`}
					overlayStyle={{ whiteSpace: "nowrap", maxWidth: "inherit" }}
				>
					<Link to="/console/account">Profile</Link>
				</Tooltip>
			)
		},
		{
			key: "2",
			label: (
				<LinkButton onClick={() => signOut()} ariaLabel="Logout">
					Logout
				</LinkButton>
			)
		},
		assumingIdentity
			? {
					key: "3",
					label: (
						<LinkButton onClick={() => discardIdentity()} ariaLabel="Discard Identity">
							Discard Identity
						</LinkButton>
					)
			  }
			: null
	].filter((item) => !!item);

	return (
		<>
			{assumingIdentity && currentUser?.user ? (
				<div className={IdentityMsg}>
					<h5>Assuming Identity of</h5>
					<H4>
						{currentUser.user.firstName} {currentUser.user.lastName}
					</H4>
				</div>
			) : null}

			<div className={helpLinkWrapper}>
				<a href={HelpUrls.faqs} target="_blank" rel="noopener noreferrer">
					<img src={icLifebouy} alt="Help Icon" style={{ height: 20, width: 20, marginRight: 8, marginTop: -3 }} />
					Help
				</a>
			</div>
			<div>
				<Dropdown
					menu={{
						items: menuItems,
						style: {
							width: 100
						}
					}}
					trigger={["click"]}
					arrow={{ pointAtCenter: true }}
				>
					<div className={bubbleWrapper} data-cy="profile" data-user={currentUser?.user?.email}>
						{userInitials}
					</div>
				</Dropdown>
			</div>
		</>
	);
};

export default UserDisplay;
