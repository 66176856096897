import { Component } from "react";
import { connect } from "react-redux";
import { FieldArray, change, formValueSelector, reduxForm } from "redux-form";
import TicketFeeRows from "./TicketFeeRows";

import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import { H1 } from "@/components/text/Text";

import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";

import withSaveCheck from "@/hoc/withSaveCheck";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { adminSaveEvent } from "@/state/actions/eventActions";
import getTicketPriceBounding from "@/utils/RebateTicketPriceBounds";
import { scrollToErrors } from "@/utils/ScrollToErrors";

class EventFees extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.deleteFees = this.deleteFees.bind(this);
	}
	save(values) {
		const { params, adminSaveEvent } = this.props;
		const eventId = params?.eventId;
		adminSaveEvent(eventId, { ticketFees: values.ticketFees });
	}

	deleteFees() {
		const { params, adminSaveEvent } = this.props;
		const eventId = params?.eventId;
		adminSaveEvent(eventId, { ticketFees: null });
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	render() {
		const { currentEvent, handleSubmit, ticketFees } = this.props;
		return (
			<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
				<form onSubmit={handleSubmit(this.save)}>
					<H1>Ticket Fees</H1>

					<ContentBlock>
						<FieldArray
							ticketFees={ticketFees}
							name="ticketFees"
							currentEvent={currentEvent}
							component={TicketFeeRows}
							changeFieldValue={this.changeFieldValue}
						/>
					</ContentBlock>

					<FormBar>
						<ButtonsBar footer>
							<LegacyButton onClick={this.deleteFees} type="warning" ariaLabel="Delete Fees" size="large">
								Delete fees
							</LegacyButton>
							<LegacyButton type="primary" htmlType="submit" id="submitButton" ariaLabel="Save" size="large">
								Save
							</LegacyButton>
						</ButtonsBar>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "ticketFees";

// Decorate with redux-form
EventFees = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(EventFees);

const selector = formValueSelector(formName);
EventFees = connect((state) => {
	return {
		initialValues: state.currentEvent.event,
		ticketFees: selector(state, "ticketFees"),
		ticketPriceBounds: getTicketPriceBounding(selector(state, "fees"))
	};
})(EventFees);

export default withRouterHooks(
	connect(
		(state) => ({
			currentEvent: state.currentEvent
		}),
		{ adminSaveEvent }
	)(withSaveCheck(EventFees, formName))
);
