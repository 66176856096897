import { Field } from "redux-form";
import FeeDisplay from "@/components/FeeDisplay";
import { CheckboxField, RadioField, TransferField } from "@/components/Fields";
import PerEventFeeSettings from "./components/PerEventFeeSettings";

const BookingFeesForm = ({ feeChargeSettings, changeFieldValue, currentEvent }) => {
	const { feesSetByTicketType } = feeChargeSettings ? feeChargeSettings : {};

	const packagedTickets = currentEvent.event.packagedTickets ? currentEvent.event.packagedTickets : [];
	const allTickets = packagedTickets.concat(currentEvent.event.ticketTypes);
	const fees =
		currentEvent && currentEvent.feeStructure && currentEvent.feeStructure.data ? currentEvent.feeStructure.data : null;
	return (
		<div style={{ position: "relative" }}>
			<FeeDisplay fees={fees} />
			<Field
				label="Booking fees"
				description="There are no fees for free tickets."
				name="feeChargeSettings.feesSetByTicketType"
				component={RadioField}
				button
				options={[
					{
						label: "Per event",
						value: false
					},
					{
						label: "By ticket type",
						value: true
					}
				]}
			/>

			{!feesSetByTicketType ? (
				<PerEventFeeSettings
					feeStructure={currentEvent.feeStructure.data}
					feeChargeSettings={feeChargeSettings}
					changeFieldValue={changeFieldValue}
				/>
			) : (
				<div>
					<Field
						name="feeChargeSettings.absorbFeesByTicketType"
						component={TransferField}
						button
						titles={["Pass on fees", "Absorb Fees"]}
						targetKeys={currentEvent.event.paymentOptions.feeChargeSettings.absorbFeesByTicketType}
						dataSource={allTickets
							.filter((t) => !t.isDonation && !t.disabled && !t.deleted)
							.map((t) => {
								return {
									key: t._id,
									title: t.name
								};
							})}
						listStyle={{
							width: 300,
							height: 300
						}}
					/>
				</div>
			)}

			<Field name="feeChargeSettings.setByTicketType" component={CheckboxField} style={{ display: "none" }} />
		</div>
	);
};
export default BookingFeesForm;
