import { DownOutlined } from "@ant-design/icons";
import { HumaniDate } from "@hx/dates";
import { Alert, Button, Dropdown, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock, LoadingSpinner } from "@/components/AppBlocks";
import { RadioField, SelectField, SwitchField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton as HumaniButton } from "@/components/buttons/LegacyButton";
import LinkButton from "@/components/buttons/LinkButton";
import { H2, P } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import LocationService from "@/services/LocationService";
import { getUserEvents } from "@/state/actions/eventActions";
import { disconnect, manualSync } from "@/state/actions/salesforceActions";
import { getUser, saveUser } from "@/state/actions/userActions";

class SalesforceSettings extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.loadUser = this.loadUser.bind(this);
		this.confirmDisconnect = this.confirmDisconnect.bind(this);
		this.disconnectApproved = this.disconnectApproved.bind(this);
		this.disconnectCancelled = this.disconnectCancelled.bind(this);
		this.state = {
			disconnectConfirmOpen: false
		};
	}

	save(values) {
		const { userId, saveUser } = this.props;
		saveUser(userId, { salesforceSettings: values });
	}

	componentDidMount() {
		const { userId, syncAll, userEvents, getUserEvents } = this.props;
		if (!syncAll && !userEvents.events) {
			getUserEvents(userId, "name");
		}
		this.updateInterval = setInterval(this.loadUser, 1000 * 20);
	}

	componentWillUnmount() {
		clearInterval(this.updateInterval);
	}

	loadUser() {
		const { userId, getUser } = this.props;
		getUser(userId);
	}

	componentDidUpdate(prevProps) {
		const { userId, syncAll, userEvents, getUserEvents } = this.props;
		if (!syncAll && prevProps.syncAll && !userEvents.events) {
			getUserEvents(userId);
		}
	}

	confirmDisconnect() {
		this.setState({ disconnectConfirmOpen: true });
	}

	disconnectApproved() {
		const { userId, disconnect } = this.props;
		this.setState({ disconnectConfirmOpen: false });
		disconnect(userId);
	}
	disconnectCancelled() {
		this.setState({ disconnectConfirmOpen: false });
	}

	render() {
		const { userId, handleSubmit, syncAll, currentUser, userEvents, manualSync, dirty } = this.props;
		const { disconnectConfirmOpen } = this.state;
		const salesforceSettings = currentUser && currentUser.user ? currentUser.user.salesforceSettings : false;
		const lastSyncHasErrors = !!(salesforceSettings.lastSyncErrorLog && salesforceSettings.lastSyncErrorLog.length > 0);
		const lastSyncDate =
			salesforceSettings && salesforceSettings.syncEndedAt
				? new HumaniDate(
						salesforceSettings.syncEndedAt,
						"guess",
						LocationService.getLocation(true)
				  ).formatting.dateTime()
				: null;
		const menuItems = [
			{
				key: "0",
				label: (
					<LinkButton onClick={() => manualSync(userId)} ariaLabel="Manual Sync">
						Manual Sync
					</LinkButton>
				)
			},
			{
				key: "1",
				label: (
					<LinkButton onClick={this.confirmDisconnect} ariaLabel="Disconnect Salesforce">
						Disconnect Salesforce
					</LinkButton>
				)
			}
		];

		return (
			<LoadErrorView
				loading={!currentUser.user && currentUser.loading}
				error={currentUser.error}
				retryAction={() => this.loadUser()}
			>
				<form onSubmit={handleSubmit(this.save)}>
					<ContentBlock>
						<Modal
							title="Confirm Disconnect"
							open={disconnectConfirmOpen}
							onOk={this.disconnectApproved}
							onCancel={this.disconnectCancelled}
						>
							<P>Are you sure you wish to disconnect your account from salesforce</P>
						</Modal>
						<Dropdown menu={{ items: menuItems }} trigger={["click"]} style={{ float: "right" }}>
							<Button size="large" className="ant-dropdown-link" style={{ float: "right" }} ariaLabel="Action">
								Actions
								<DownOutlined style={{ marginLeft: 4 }} />
							</Button>
						</Dropdown>
						<H2>Salesforce Settings</H2>
						<P>Connected to {currentUser.user.salesforceSettings.instanceUrl}</P>

						{salesforceSettings.syncing ? (
							<Alert
								type="info"
								message="Salesforce Syncing"
								description={<LoadingSpinner />}
								style={{ marginBottom: 12 }}
							/>
						) : null}

						{lastSyncDate ? (
							lastSyncHasErrors ? (
								<Alert
									message={`Last Sync Errors at ${lastSyncDate}`}
									description={salesforceSettings.lastSyncErrorLog.join(", ")}
									type="error"
									showIcon
									style={{ marginBottom: 12 }}
								/>
							) : (
								<Alert
									message={`Last Sync Successful at ${lastSyncDate}`}
									type="success"
									showIcon
									style={{ marginBottom: 12 }}
								/>
							)
						) : null}

						<Field
							label="Sync Events"
							description="Which events would you like to be synced to your salesforce account"
							id="syncAll"
							name="syncAll"
							vertical
							component={RadioField}
							options={[
								{
									label: "All",
									value: true
								},
								{
									label: "Selected Events",
									value: false
								}
							]}
						/>
						{!syncAll ? (
							(!currentUser.user && userEvents.loading) || !userEvents.events ? (
								<div style={{ marginBottom: 12 }}>
									<LoadingSpinner />{" "}
								</div>
							) : (
								<Field
									name="selectedEvents"
									label="Select Events"
									component={SelectField}
									showSearch
									options={userEvents.events.map((e) => {
										return { value: e._id, label: e.name };
									})}
									mode="multiple"
								/>
							)
						) : null}

						<Field
							name="contactRule"
							label="Contact Settings"
							inline={false}
							vertical
							description="How would you like your contacts to be created in salesforce"
							component={RadioField}
							options={[
								{
									value: "matchAndDontUpdate",
									label: "Match existing contact but don't update"
								},
								{
									value: "matchAndUpdate",
									label: "Match existing contact and update"
								},
								{ value: "alwaysNew", label: "Always create new contacts" }
							]}
						/>

						<Field
							name="createAccount"
							label="Create Account"
							inline={false}
							vertical
							description="Would you like us to create an account for all contacts per event? ( Account name will be: Humanitix - Event Name )"
							component={SwitchField}
						/>

						<Field
							name="addAttendeesToCampaign"
							label="Add Attendees to Campaign"
							inline={false}
							vertical
							description="Would you like attendees to be added to the campaign. Note you will need to be collecting first name, last name and either email or phone number per ticket."
							component={SwitchField}
						/>

						<Field
							name="syncFrequency"
							label="Update Frequency"
							description="How often would you like your data push to Salesforce"
							component={RadioField}
							vertical
							options={[
								{ value: "daily", label: "Daily" },
								{ value: "hourly", label: "Hourly" },
								{ value: "onRequest", label: "On Request" }
							]}
						/>
						{/*
         <Field
          name="apiLimit"
          label="API Limit"
          description="Would you like us to limit the amount of API call we make in one sync"
          component={InputNumberField}
          min={1000}
        /> */}
					</ContentBlock>
					<FormBar>
						<HumaniButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							size="large"
							ariaLabel="Save"
						>
							Save
						</HumaniButton>

						<Button
							htmlType="button"
							onClick={() => manualSync(userId)}
							disabled={dirty}
							size="large"
							ariaLabel="Manual sync"
						>
							Manual sync
						</Button>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "salesforceSettings";
const selector = formValueSelector(formName);
SalesforceSettings = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(SalesforceSettings);

export default connect(
	(state) => {
		const initState =
			state.currentUser && state.currentUser.user && state.currentUser.user.salesforceSettings
				? state.currentUser.user.salesforceSettings
				: {
						syncAll: true,
						contactRule: "matchAndDontUpdate",
						syncFrequency: "daily",
						apiLimit: 10000
				  };
		return {
			initialValues: initState,
			currentUser: state.currentUser,
			userEvents: state.userEvents,
			syncAll: selector(state, "syncAll")
		};
	},
	{ saveUser, getUserEvents, manualSync, disconnect, getUser }
)(withSaveCheck(SalesforceSettings, formName));
