import { css } from "@emotion/css";
import { Field } from "redux-form";
import { LegacyInputField, SelectField, TextAreaField } from "@/components/Fields";

type IDefaultValuesProps = {
	additionalQuestions: any[];
	questionIndex: number;
	mode?: "multiSelect" | "multiple" | null;
};

/**
 * Adds default value to additional questions
 *
 * @param {object, string} { additionalQuestions, questionIndex }
 * @returns default value field
 */
const DefaultValues = ({ additionalQuestions, questionIndex, mode }: IDefaultValuesProps) => {
	let defaultComponent, defaultValueOptions;
	const question = additionalQuestions[questionIndex];
	if (question.type && question.type === "checkbox") {
		defaultComponent = SelectField;
		defaultValueOptions = [
			{ label: "Select default value", value: "" },
			{ label: "Selected", value: "true" },
			{ label: "Unselected", value: "false" }
		];
	} else if (question.type && (question.type === "options" || question.type === "multiSelect")) {
		defaultComponent = SelectField;
		const newOptions =
			question && question.options
				? question.options.map((option: any) => {
						return { label: option.value, value: option.value };
				  })
				: [];
		newOptions.unshift({ value: "", label: "Select default value" });
		defaultValueOptions = newOptions;
	} else if (question.type && question.type === "textarea") {
		defaultComponent = TextAreaField;
	} else {
		defaultComponent = LegacyInputField;
	}

	const styles = {
		fieldContainer: css`
			width: 100%;
			margin-top: 24px;
			max-width: 247px;
			@media (max-width: 576px) {
				max-width: 100%;
			}
		`
	};
	return (
		<div className={styles.fieldContainer}>
			<Field
				name={`additionalQuestions[${questionIndex}].defaultValue`}
				label="Default Value"
				component={defaultComponent}
				options={defaultValueOptions}
				style={{ width: "100%" }}
				size="small"
				mode={mode}
			/>
		</div>
	);
};

export default DefaultValues;
