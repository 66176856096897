import { Tooltip } from "@/ui/antd";

const EventDateTooltip = ({ isEventDateLocked, children }) => {
	if (isEventDateLocked) {
		return <Tooltip title="This event date has passed and can no longer be changed">{children}</Tooltip>;
	}
	return children;
};

export default EventDateTooltip;
