import { Tag, TagVariant, CodeBlock } from "@/ui";
import { Flex } from "@/ui/antd";
import { EyeSlash } from "@phosphor-icons/react/dist/ssr";
import { FC } from "react";

const variants: TagVariant[] = ["success", "warning", "danger", "info", "neutral"];

const titleCase = (str: string) => str[0].toUpperCase() + str.slice(1);

const props = `type TagProps = AntdTagProps & {
	variant?: ${variants.join(" | ")};
};`;

const sample = `import { Tag } from "@/ui";

<Tag variant="success">Success</Tag>
<Tag variant="warning">Warning</Tag>
<Tag variant="danger">Danger</Tag>
<Tag variant="info">Info</Tag>
<Tag variant="neutral">Neutral</Tag>`;

export const TagsPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			{variants.map((type) => (
				<div key={type}>
					<h2>{titleCase(type)}</h2>
					<Flex gap="sm" wrap>
						<Tag variant={type}>{titleCase(type)}</Tag>{" "}
						<Tag icon={<EyeSlash />} variant={type}>
							{titleCase(type)}
						</Tag>
					</Flex>
				</div>
			))}
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
