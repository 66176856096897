import { css } from "@emotion/css";
import OrganiserImage from "./OrganiserImage";

const wrapperClass = css({
	display: "flex",
	alignItems: "center"
});

const textClass = css({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: "0 0 0 15px",
	p: {
		margin: 0
	}
});

const imageClass = css({
	background: "white",
	borderRadius: 80,
	padding: 4
});

const OrganiserHeader = ({ organiser }) => {
	if (!organiser) return null;

	const { followerCount, name, logo } = organiser;

	return (
		<div className={wrapperClass}>
			<div className={imageClass}>
				<OrganiserImage name={name} logo={logo} />
			</div>

			<div className={textClass}>
				{organiser.name}
				{followerCount ? <p>Total followers: {followerCount}</p> : null}
			</div>
		</div>
	);
};

export default OrganiserHeader;
