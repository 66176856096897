import { ReactElement, FC } from "react";
import { useAppSelector, createAppSelector } from "@/state/hooks";
import { Checkbox, Form, FormInstance, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import styled from "@emotion/styled";

type OrderTicket = {
	_id: string;
	email: string;
	ticketTypeId: string;
	firstName: string;
	lastName: string;
	ticketTypeName: string;
};

type TicketType = {
	_id: string;
	sendTicket: boolean;
};

type ResendToAttendeesFormProps = {
	form: FormInstance;
};

const NO_SENDABLE_TICKETS_TOOLTIP_MESSAGE =
	"Tickets cannot be sent to attendees for this order. No email address has been recorded for any ticket in the order, or sending tickets is disabled for every type of ticket in the order.";

// Without this, the checkbox items are rendered in a row
const CheckboxGroupColumn = styled(Checkbox.Group)`
	.ant-checkbox-group-item {
		display: flex;
	}
`;

const ConditionalWrap = ({
	condition,
	wrap,
	children
}: {
	condition: boolean;
	wrap: (c: ReactElement) => ReactElement;
	children: ReactElement;
}) => (condition ? wrap(children) : children);

// Tickets that have an email and a ticket type ID with `sendTicket` enabled are sendable.
// The backend's email sending logic will NOT send anything to an attendee unless `sendTicket` is true (see `send` in `libs/emails/src/orders/ticketEmails.ts`)
// To avoid confusing hosts, we should only show attendees that can receive an email in the list.
const selectEmailableTickets = createAppSelector(
	[(state) => state.order?.order, (state) => state.currentEvent.event?.ticketTypes],
	(order, eventTicketTypes) => {
		const orderTickets = order?.tickets || [];
		const sendableTicketTypeIds: string[] | undefined = eventTicketTypes
			?.filter((ticket: TicketType) => ticket.sendTicket)
			.map((ticket: TicketType) => ticket._id);
		const emailableTickets = orderTickets?.filter(
			(ticket: OrderTicket) => !!ticket.email && sendableTicketTypeIds?.includes(ticket.ticketTypeId)
		);
		return emailableTickets ?? [];
	}
);

export const ResendToAttendeesPartialForm: FC<ResendToAttendeesFormProps> = ({ form }) => {
	const emailableAttendeeTickets: OrderTicket[] = useAppSelector(selectEmailableTickets);

	const ticketIdsValue = Form.useWatch("ticketIds", form);

	const selectAllAttendees: boolean =
		emailableAttendeeTickets.length > 0 && emailableAttendeeTickets.length === ticketIdsValue?.length;
	const handleSelectAllAttendeesChange = (e: CheckboxChangeEvent) => {
		let emailableAttendeeTicketIds: string[];
		if (e.target.checked) {
			emailableAttendeeTicketIds = emailableAttendeeTickets.map((ticket: { _id: string }) => ticket._id);
		} else {
			emailableAttendeeTicketIds = [];
		}

		form.setFieldValue("ticketIds", emailableAttendeeTicketIds);
	};

	return (
		<>
			<p>Resend tickets to attendees?</p>

			<ConditionalWrap
				condition={emailableAttendeeTickets.length === 0}
				wrap={(wrappedChildren) => <Tooltip title={NO_SENDABLE_TICKETS_TOOLTIP_MESSAGE}>{wrappedChildren}</Tooltip>}
			>
				<Checkbox
					onChange={handleSelectAllAttendeesChange}
					checked={selectAllAttendees}
					disabled={emailableAttendeeTickets.length === 0}
				>
					<strong>Select all</strong>
				</Checkbox>
			</ConditionalWrap>

			{emailableAttendeeTickets.length > 0 && (
				<Form.Item name="ticketIds" initialValue={[]}>
					<CheckboxGroupColumn
						options={emailableAttendeeTickets.map((ticket: OrderTicket) => ({
							label: `${ticket.firstName} ${ticket.lastName}, ${ticket.email}, ${ticket.ticketTypeName}`,
							value: ticket._id
						}))}
						style={{ overflowY: "auto", maxHeight: "320px", display: "block" }}
					/>
				</Form.Item>
			)}
		</>
	);
};
