import OrdersService from "../../services/OrdersService";
export const BULK_UPLOAD_RESET = "BULK_UPLOAD_RESET";
export const BULK_UPLOAD_REQUEST = "BULK_UPLOAD_REQUEST";
export const BULK_UPLOAD_SUCCESS = "BULK_UPLOAD_SUCCESS";
export const BULK_UPLOAD_ERROR = "BULK_UPLOAD_ERROR";

export const reset = () => {
	return (dispatch) => {
		dispatch({ type: BULK_UPLOAD_RESET });
	};
};

export const bulkUpload = (eventId, eventDateId, emailOrder, csv, fields) => {
	return (dispatch) => {
		dispatch({ type: BULK_UPLOAD_REQUEST });
		OrdersService.bulkUpload(eventId, eventDateId, emailOrder, csv, fields)
			.then((result) => {
				if (result.success) {
					dispatch({ type: BULK_UPLOAD_SUCCESS });
				} else {
					dispatch({ type: BULK_UPLOAD_ERROR, error: result.error });
				}
			})
			.catch((err) => {
				dispatch({ type: BULK_UPLOAD_ERROR, error: err });
			});
	};
};
