import TicketSaleStatusService from "../../services/TicketSaleStatusService";

export const GET_TICKET_SALE_STATUS_REQUEST = "GET_TICKET_SALE_STATUS_REQUEST";
export const GET_TICKET_SALE_STATUS_SUCCESS = "GET_TICKET_SALE_STATUS_SUCCESS";
export const GET_TICKET_SALE_STATUS_ERROR = "GET_TICKET_SALE_STATUS_ERROR";
export const TICKET_SALE_STATUS_MODAL_OPEN = "TICKET_SALE_STATUS_MODAL_OPEN";
export const TICKET_SALE_STATUS_MODAL_CLOSE = "TICKET_SALE_STATUS_MODAL_CLOSE";

export const openTicketSaleStatusModal = () => {
	return (dispatch) => dispatch({ type: TICKET_SALE_STATUS_MODAL_OPEN });
};

export const closeTicketSaleStatusModal = () => {
	return (dispatch) => dispatch({ type: TICKET_SALE_STATUS_MODAL_CLOSE });
};

export const getTicketsSaleStatus = (eventId, eventDateId) => {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_TICKET_SALE_STATUS_REQUEST });
			const ticketSaleStatus = await TicketSaleStatusService.get(eventId, eventDateId);
			dispatch({ type: GET_TICKET_SALE_STATUS_SUCCESS, ticketSaleStatus });
		} catch (error) {
			dispatch({ type: GET_TICKET_SALE_STATUS_ERROR, error });
		}
	};
};
