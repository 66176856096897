import { Alert, Table } from "@/ui/antd";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import { SwitchField } from "./Fields";
import P from "./text/P";

const columns = [
	{
		title: "Field",
		dataIndex: "field",
		key: "field"
	},
	{
		title: "Description",
		dataIndex: "description",
		key: "description"
	},
	{
		title: "Enabled",
		dataIndex: "enabled",
		key: "enabled"
	}
];

const GetWarningMessage = ({ uniqueAdditionalQuestionAnswerFields, currentEvent }) => {
	if (!uniqueAdditionalQuestionAnswerFields.enabled) return null;
	const additionalQuestionTypes = currentEvent?.event?.additionalQuestions?.reduce((questionTypes, question) => {
		if (!question.perOrder) questionTypes.push(question.type);
		return questionTypes;
	}, []);
	const neededAdditionalQuestions = [];
	if (uniqueAdditionalQuestionAnswerFields.fullName && !additionalQuestionTypes.includes("firstName")) {
		neededAdditionalQuestions.push("- First name");
	}
	if (uniqueAdditionalQuestionAnswerFields.fullName && !additionalQuestionTypes.includes("lastName")) {
		neededAdditionalQuestions.push("- Last name");
	}
	if (uniqueAdditionalQuestionAnswerFields.email && !additionalQuestionTypes.includes("email")) {
		neededAdditionalQuestions.push("- Email");
	}
	if (uniqueAdditionalQuestionAnswerFields.mobile && !additionalQuestionTypes.includes("mobile")) {
		neededAdditionalQuestions.push("- Mobile");
	}
	if (!neededAdditionalQuestions.length) return null;
	return (
		<Alert
			message="Some checkout questions are missing"
			type="warning"
			showIcon
			style={{ marginBottom: 8 }}
			description={
				<>
					<P>
						Please add the following questions per ticket{" "}
						<Link to={`/console/my-events/${currentEvent.event._id}/additional-questions`}>here</Link>:
					</P>
					{neededAdditionalQuestions.map((question) => (
						<p style={{ marginBottom: 2 }}>{question}</p>
					))}
				</>
			}
		/>
	);
};

const UniqueAdditionalQuestionAnswers = ({ path, uniqueAdditionalQuestionAnswerFields, currentEvent }) => {
	return (
		<>
			<Field
				name={`${path}.uniqueAdditionalQuestionAnswerFields.enabled`}
				label="Enable unique ticket answers"
				description="Set which fields combine to make sure an order is unique"
				component={SwitchField}
			/>
			{uniqueAdditionalQuestionAnswerFields?.enabled ? (
				<>
					<GetWarningMessage
						uniqueAdditionalQuestionAnswerFields={uniqueAdditionalQuestionAnswerFields}
						currentEvent={currentEvent}
					/>
					<Table
						pagination={false}
						columns={columns}
						dataSource={[
							{
								field: "Full name",
								description: "A combination of the first name and last name questions",
								enabled: (
									<Field name={`${path}.uniqueAdditionalQuestionAnswerFields.fullName`} component={SwitchField} />
								)
							},
							{
								field: "Email",
								description: "The ticket holders email",
								enabled: <Field name={`${path}.uniqueAdditionalQuestionAnswerFields.email`} component={SwitchField} />
							},
							{
								field: "Mobile",
								description: "The ticket holders mobile",
								enabled: <Field name={`${path}.uniqueAdditionalQuestionAnswerFields.mobile`} component={SwitchField} />
							}
						]}
					/>
				</>
			) : null}
		</>
	);
};

export default UniqueAdditionalQuestionAnswers;
