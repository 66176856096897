import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Flex, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import icCheck from "@/assets/payoutsSearch/ic_check.svg";
import icCross from "@/assets/payoutsSearch/ic_cross.svg";
import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import SearchRefinements from "@/components/SearchRefinements";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import TableActions from "@/components/table/TableActions";
import LocationService from "@/services/LocationService";
import { getPaymentCsvReport, searchPayments } from "@/state/actions/payoutActions";
import { updateSearch } from "@/state/actions/searchActions";
import { currency } from "@/utils/Format";

const eventNameColumnWidth = 200;

const eventNameStyling = css({
	maxWidth: eventNameColumnWidth,
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	display: "block"
});

const searchWrapper = css({
	display: "flex",
	justifyContent: "space-between"
});

class Payments extends Component {
	load = (page = 1) => {
		const { searchPayments } = this.props;
		searchPayments(page);
	};

	loadPage = (page) => {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	};

	csvDownload = () => {
		const { getPaymentCsvReport } = this.props;
		getPaymentCsvReport();
	};

	filterOptions = [
		{
			key: "payment",
			label: "Date range",
			kind: "dateRange"
		},
		{
			key: "amount",
			label: "Amount range",
			kind: "numberRange"
		}
	];

	render() {
		const { payouts, search } = this.props;
		const columns = [
			{
				title: "Event",
				dataIndex: ["event", "name"],
				key: "eventName",
				fixed: "left",
				width: eventNameColumnWidth,
				render: (value, record, index) => {
					return (
						<Link
							to={`/console/my-events/${record.event._id}/payments/payouts`}
							className={eventNameStyling}
							id={`eventName-${index}`}
						>
							{value}
						</Link>
					);
				}
			},
			{
				title: "End date",
				dataIndex: "event",
				key: "endDate",
				width: 120,
				render: (event) => {
					return new HumaniDate(
						event?.date?.endDate,
						event?.timezone || "guess",
						LocationService.getLocation(true)
					).formatting.dateTime();
				}
			},
			{
				title: "Payout reference",
				dataIndex: ["payments", "payoutReference"],
				key: "payoutReference"
			},
			{
				title: "Transaction Id",
				dataIndex: ["payments", "transactionId"],
				key: "transactionId"
			},
			{
				title: "Mass payment",
				dataIndex: ["payments", "massPaymentId"],
				key: "massPaymentId",
				render: (value) => {
					return value ? (
						<Link to={`/console/admin/mass-payout/${value}`}>
							<img src={icCheck} alt="Has mass payout" /> View
						</Link>
					) : (
						<img src={icCross} alt="No mass payout" />
					);
				}
			},
			{
				title: "Amount",
				dataIndex: "",
				key: "amount",
				render: (value) => {
					return `${currency(value.payments.amount)} ${value.payments.credit ? "(Cr)" : ""}`;
				}
			},
			{
				title: "Paid by",
				dataIndex: ["payments", "userName"],
				key: "paidBy"
			},
			{
				title: "Date paid",
				dataIndex: ["payments", "createdAt"],
				key: "datePaid",
				render: (value) => new HumaniDate(value, "guess", LocationService.getLocation(true)).formatting.dateTime()
			},
			{
				title: "Reversed",
				dataIndex: ["payments", "reversedAt"],
				key: "reversed",
				render: (value) => {
					if (!value) {
						return null;
					}
					return new HumaniDate(value, "guess", LocationService.getLocation(true)).formatting.dateTime();
				}
			},
			{
				title: "Paid to account",
				dataIndex: ["payments", "bankAccount"],
				width: 150,
				render: (bankAccount) => {
					return bankAccount ? bankAccount.anonymisedAccount.join("-") : "-";
				}
			},
			{
				title: "Notes",
				dataIndex: ["payments", "notes"],
				key: "notes"
			},
			{
				title: "Actions",
				dataIndex: "",
				key: "actions",
				fixed: "right",
				render: (value) => (
					<TableActions>
						<Link to={`/console/my-events/${value.eventId}/payments/payouts`}>
							<IconButton ariaLabel="view" icon="preview" />
						</Link>
					</TableActions>
				)
			}
		];
		return (
			<>
				<PageHeading title="Payments">
					<LocationSelection componentName="payments-search" defaultLevel="user" onLocationChange={this.load} />
				</PageHeading>
				<ContentBlock>
					<div className={searchWrapper}>
						<Flex>
							<SearchRefinements
								load={this.load}
								placeholder="Transaction Id"
								dropdownId="payoutFilters"
								filterOptions={this.filterOptions}
								showRefinements
							/>
						</Flex>
						<div style={{ float: "right" }}>
							<LegacyButton
								ariaLabel="Export"
								icon={{ name: "export", left: true }}
								type="import"
								onClick={this.csvDownload}
							>
								Export
							</LegacyButton>
						</div>
					</div>
					<LoadErrorView loading={payouts.loading} retryAction={this.load}>
						<div style={{ minHeight: 400 }}>
							<Table
								columns={columns}
								dataSource={payouts.payments}
								rowKey={(o) => o.payments._id + o._id}
								pagination={{
									showSizeChanger: false,
									total: payouts.count,
									current: search.page,
									pageSize: 100,
									onChange: this.loadPage
								}}
								scroll={{ x: "max-content" }}
							/>
						</div>
					</LoadErrorView>
				</ContentBlock>
			</>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search,
		payouts: state.payments
	}),
	{
		searchPayments,
		updateSearch,
		getPaymentCsvReport
	}
)(Payments);
