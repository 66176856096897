import { Button } from "@/ui";
import { ImportOutlined } from "@ant-design/icons";
import { Flex, Tooltip } from "@/ui/antd";

const Actions = ({
	handleCreateEventClicked,
	handleImportEventClicked,
	showCreateEvent,
	showImportEvent
}: {
	handleCreateEventClicked: () => void;
	handleImportEventClicked: () => void;
	showCreateEvent: boolean;
	showImportEvent?: boolean;
}) => {
	return (
		<Flex align="center" gap="md">
			{showCreateEvent && (
				<Button aria-label="Create event" variant="secondary" onClick={handleCreateEventClicked}>
					Create event
				</Button>
			)}
			{showImportEvent && (
				<Tooltip title="Import Event">
					<Button aria-label="Import Event" iconOnly onClick={handleImportEventClicked} variant="ghost">
						<ImportOutlined style={{ fontSize: 16 }} />
					</Button>
				</Tooltip>
			)}
		</Flex>
	);
};

export default Actions;
