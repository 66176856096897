import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import React from "react";
import InfoTitle from "../../table/InfoTitle";
import { Label } from "../../text/Text";
import RequiredAsterisk from "./RequiredAsterisk";

interface IInputLabelProps {
	label: string;
	description?: string;
	id?: string;
	inline?: boolean;
	required?: boolean;
	tooltip?: React.ReactNode;
	labelAfter?: boolean;
	tooltipIcon?: any;
	tooltipIconOnClick?: () => void;
	bold?: boolean;
}

const InputLabel = ({
	label,
	description,
	id,
	inline,
	required,
	tooltip,
	labelAfter,
	tooltipIcon,
	tooltipIconOnClick,
	bold
}: IInputLabelProps) => {
	if (!label && !description) {
		return null;
	}
	const customStyles = {
		wrapper: css({
			display: inline ? "inline-block" : "block",
			marginRight: inline && !labelAfter ? 8 : 0,
			marginLeft: inline && labelAfter ? 8 : 0,
			label: {
				display: inline ? "inline-block" : "block"
			}
		})
	};
	const newLabel = (
		<span>
			{required ? <RequiredAsterisk /> : null}
			{label}
		</span>
	);
	const contents = tooltipIcon ? (
		<InfoTitle title={newLabel} tooltip={tooltip} onClick={tooltipIconOnClick} bold={bold} />
	) : (
		newLabel
	);
	return (
		<div className={customStyles.wrapper}>
			<Label htmlFor={id}>
				<Tooltip title={tooltip}>{contents}</Tooltip>
			</Label>
			{description ? <Label bold={false}>{description}</Label> : null}
		</div>
	);
};
export default InputLabel;
