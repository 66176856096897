import { Radio } from "@/ui/antd";
import { SeatingArea, StandingArea } from "../../../../assets/seatingMap";
import InputLabel from "../../../components/InputLabel";
const config = [
	{
		label: "Seating",
		icon: SeatingArea,
		id: "seating"
	},
	{
		label: "Standing",
		icon: StandingArea,
		id: "standing"
	}
];
export default ({ element, performAction }) => {
	return (
		<div style={{ overflow: "auto", paddingTop: 20 }}>
			<InputLabel>Type</InputLabel>
			<div style={{ paddingTop: 12 }}>
				<Radio.Group
					onChange={(e) => {
						performAction([element.setAreaType], [[e.target.value]]);
					}}
					value={element.areaType}
				>
					<div style={{ display: "flex" }}>
						{config.map(({ label, icon, id }) => {
							return (
								<Radio style={{ display: "flex" }} value={id}>
									<div style={{ display: "flex" }}>
										<div>{icon({})}</div>
										<div style={{ fontSize: 16, marginLeft: 4, marginTop: -3 }}>{label}</div>
									</div>
								</Radio>
							);
						})}
					</div>
				</Radio.Group>
			</div>
		</div>
	);
};
