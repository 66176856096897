import { ContentBlock } from "@/components/AppBlocks";
import EventRecentlyUpdatedRow from "@/components/EventRecentlyUpdatedRow";
import EventRow from "@/components/EventRow";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import Page from "@/components/Page";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import {
	ColumnWrapper,
	EventContainer,
	OptionsRow,
	RecentUpdateDesktop,
	RecentUpdateDesktopTitle,
	SearchAndFilterWrapper,
	SearchBarWrapper,
	SearchDesktopWrapper,
	SearchRow,
	StyledRadio,
	StyledRadioGroup,
	eventsPageStyle,
	sortOptions
} from "@/events/EventsStyledComponents";
import NoEventsFound from "@/events/NoEventsFound";
import ArchiveEventModal from "@/events/event/header/ArchiveEventModal";
import DuplicateModal from "@/events/event/header/DuplicateModal";
import TransferEventModal from "@/events/event/header/TransferEventModal";
import { InjectedRouterHooksProps, withRouterHooks } from "@/hoc/withRouterHooks";
import {
	duplicateEvent,
	findEvents,
	getRecentlyUpdatedEvents,
	openArchiveModal,
	openTransferEventModal
} from "@/state/actions/eventActions";
import { updateSearch } from "@/state/actions/searchActions";
import { EventReducerState } from "@/state/reducers/eventReducer";
import { ReduxState } from "@/state/store";
import { Button, Dropdown, Responsive } from "@/ui";
import { Flex, Modal, Radio, Select } from "@/ui/antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { SlidersHorizontal } from "@phosphor-icons/react";
import { Component } from "react";
import { connect } from "react-redux";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

type EventsProps = {
	archive: EventReducerState["archive"];
	duplicateEvent: typeof duplicateEvent;
	findEvents: typeof findEvents;
	getRecentlyUpdatedEvents: typeof getRecentlyUpdatedEvents;
	openArchiveModal: typeof openArchiveModal;
	openTransferEventModal: typeof openTransferEventModal;
	search: ReduxState["search"];
	transferEventState: EventReducerState["transferEventState"];
	updateSearch: typeof updateSearch;
	user: Record<string, any>;
	userEvents: ReduxState["events"];
} & InjectedRouterHooksProps;

class Events extends Component<EventsProps> {
	constructor(props: EventsProps) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.createEvent = this.createEvent.bind(this);
		this.toggleMobileRecentUpdatedEvents = this.toggleMobileRecentUpdatedEvents.bind(this);
		this.handleOnLocationChange = this.handleOnLocationChange.bind(this);
	}

	state = {
		waitingToLoad: false,
		recentlyUpdatedModalOpen: false,
		duplicateOpen: false,
		currentEvent: false
	};

	defaultSearch = {
		sortOrder: "relevant",
		filter: "all"
	};

	componentDidMount() {
		const { getRecentlyUpdatedEvents } = this.props;
		getRecentlyUpdatedEvents();
	}

	load(page = 1) {
		const { findEvents } = this.props;
		findEvents(page);
		this.setState({ waitingToLoad: false });
	}

	loadPage(page: number) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	createEvent() {
		this.props.navigate("/console/my-events/new/basics");
	}

	toggleMobileRecentUpdatedEvents() {
		const { recentlyUpdatedModalOpen } = this.state;
		this.setState({
			recentlyUpdatedModalOpen: !recentlyUpdatedModalOpen
		});
	}

	handleOnLocationChange(loc: string) {
		this.props.updateSearch({ loc, page: 1 }, true);
	}

	toggleDuplicate = (name: string, _id: string) => {
		const { duplicateOpen } = this.state;

		this.setState({
			duplicateOpen: !duplicateOpen,
			currentEvent: {
				event: { name, _id }
			}
		});
	};

	toggleArchive = ({
		_id,
		endDate,
		isArchived,
		name,
		published
	}: {
		_id: string;
		endDate: Date;
		isArchived: boolean;
		name: string;
		published: boolean;
	}) => {
		this.setState(
			{
				currentEvent: {
					event: {
						_id,
						name,
						isArchived,
						published,
						endDate
					}
				}
			},
			() => {
				this.props.openArchiveModal();
			}
		);
	};

	render() {
		const { userEvents, navigate, user, updateSearch, search, openTransferEventModal, transferEventState, archive } =
			this.props;

		const { events, loading, error, page, recentlyUpdatedEvents } = userEvents;
		const isAdmin = user?.isAdmin;
		const pageName = isAdmin ? "Events" : "My events";
		const { waitingToLoad, recentlyUpdatedModalOpen, currentEvent, duplicateOpen } = this.state;

		const locationSelection = (
			<LocationSelection
				componentName="eventSearch"
				defaultLevel="user"
				onLocationChange={this.handleOnLocationChange}
			/>
		);

		const recentUpdateMobile = (
			<Responsive
				mobile={
					<Button aria-label="recently updated" onClick={this.toggleMobileRecentUpdatedEvents} variant="text" iconOnly>
						<ClockCircleOutlined style={{ fontSize: 16 }} />
					</Button>
				}
			/>
		);

		const sibling = (
			<Flex gap="sm">
				{locationSelection}
				{recentUpdateMobile}
			</Flex>
		);

		const sortOptionItems = [
			{ value: "relevant", label: "Most Relevant" },
			{ value: "upcoming", label: "Upcoming" },
			{ value: "newest", label: "Newest" },
			{ value: "oldest", label: "Oldest" },
			{ value: "az", label: "A - Z" },
			{ value: "za", label: "Z - A" },
			{ value: "shared", label: "Shared events" },
			{ value: "owned", label: "Owned events" }
		];

		return (
			<Page pageName={pageName} sibling={sibling} pageStyle={eventsPageStyle}>
				<LoadErrorView loading={loading} error={error} retryAction={this.load}>
					{!loading && !waitingToLoad && events.length === null && search.query === "" && search.filter === "all" ? (
						<NoEventsFound
							title="You have no events yet. Create your first event!"
							createEvent={this.createEvent}
							locationSelection={locationSelection}
						/>
					) : (
						<ColumnWrapper>
							<EventContainer>
								<ContentBlock>
									<SearchAndFilterWrapper>
										<SearchRow>
											<SearchBarWrapper>
												<SearchRefinements
													defaultSearch={this.defaultSearch}
													load={this.load}
													localStorageSearchKey="eventsSearchFilterKey"
													fullWidth
												/>
											</SearchBarWrapper>
											<Responsive
												desktop={null}
												mobile={
													<Dropdown
														title="Filters"
														items={
															<>
																<RadioGroup
																	buttonStyle="solid"
																	defaultValue="all"
																	onChange={(e) => updateSearch({ filter: e.target.value }, true)}
																	value={search.filter}
																>
																	<StyledRadio value="all">All</StyledRadio>
																	<StyledRadio value="published">Live</StyledRadio>
																	<StyledRadio value="unpublished">Draft</StyledRadio>
																	<StyledRadio value="past">Past</StyledRadio>
																	<StyledRadio value="archived">Archived</StyledRadio>
																</RadioGroup>
																<hr />
																<RadioGroup
																	buttonStyle="solid"
																	defaultValue="relevant"
																	onChange={(e) => updateSearch({ sortOrder: e.target.value }, true)}
																	value={search.sortOrder}
																>
																	{sortOptionItems.map((item) => (
																		<StyledRadio key={item.value} value={item.value}>
																			{item.label}
																		</StyledRadio>
																	))}
																</RadioGroup>
															</>
														}
													>
														<Button variant="text" aria-label="filter" iconOnly>
															<SlidersHorizontal size={18} />
														</Button>
													</Dropdown>
												}
											/>
										</SearchRow>
										<OptionsRow>
											<StyledRadioGroup
												buttonStyle="solid"
												defaultValue="all"
												onChange={(e) => updateSearch({ filter: e.target.value }, true)}
												value={search.filter}
											>
												<RadioButton value="all">All</RadioButton>
												<RadioButton value="published">Live</RadioButton>
												<RadioButton value="unpublished">Draft</RadioButton>
												<RadioButton value="past">Past</RadioButton>
												<RadioButton value="archived">Archived</RadioButton>
											</StyledRadioGroup>
											<SearchDesktopWrapper>
												{search.filter !== "payout" ? (
													<Select
														className={sortOptions}
														defaultValue="relevant"
														onChange={(value) => updateSearch({ sortOrder: value }, true)}
														style={{ fontSize: 14 }}
														value={search.sortOrder}
														options={sortOptionItems.map((item) => {
															return { value: item.value, label: item.label };
														})}
													/>
												) : null}
											</SearchDesktopWrapper>
										</OptionsRow>
									</SearchAndFilterWrapper>
								</ContentBlock>
								{!events.length ? (
									!loading ? (
										<NoEventsFound
											createEvent={this.createEvent}
											locationSelection={locationSelection}
											title="No events found. Create an Event!"
										/>
									) : (
										""
									)
								) : (
									events.map((event: any) => {
										return (
											<EventRow
												key={event._id}
												{...event}
												isPrivate={!event.public}
												openTransferEventModal={openTransferEventModal}
												toggleArchive={this.toggleArchive}
												toggleDuplicate={this.toggleDuplicate}
												upcoming={search.sortOrder === "upcoming"}
												user={user}
											/>
										);
									})
								)}

								<SimplePagination
									currentPage={page}
									pageAction={this.loadPage}
									style={{ float: "right", marginTop: 24 }}
								/>
								<div style={{ clear: "both" }} />
							</EventContainer>

							<Responsive
								desktop={
									<RecentUpdateDesktop>
										<ContentBlock>
											<RecentUpdateDesktopTitle>Recently updated events</RecentUpdateDesktopTitle>
											<div
												style={{
													borderBottom: "1px solid #dedede ",
													marginBottom: "15.5px",
													marginTop: "7.5px"
												}}
											/>
											{!recentlyUpdatedEvents.events
												? null
												: recentlyUpdatedEvents.events.map((event: any) => (
														<EventRecentlyUpdatedRow {...event} key={`recentlyUpdated_${event._id}`} />
												  ))}
										</ContentBlock>
									</RecentUpdateDesktop>
								}
							/>
							{recentlyUpdatedModalOpen ? (
								<Modal
									footer={null}
									onCancel={this.toggleMobileRecentUpdatedEvents}
									open={recentlyUpdatedModalOpen}
									title="Recently updated events"
								>
									{!recentlyUpdatedEvents.events
										? null
										: recentlyUpdatedEvents.events.map((event: any) => <EventRecentlyUpdatedRow {...event} />)}
								</Modal>
							) : null}
							{duplicateOpen ? (
								<DuplicateModal
									currentEvent={currentEvent}
									handleCancel={this.toggleDuplicate}
									navigate={navigate}
									visible={duplicateOpen}
								/>
							) : null}
							{transferEventState.modalOpen ? <TransferEventModal /> : null}
							{archive.modalVisible ? (
								<ArchiveEventModal event={(currentEvent as any).event} shouldReloadEventList />
							) : null}
						</ColumnWrapper>
					)}
				</LoadErrorView>
			</Page>
		);
	}
}

const mapStateToProps = (state: ReduxState) => ({
	archive: state.currentEvent.archive,
	search: state.search,
	transferEventState: state.currentEvent.transferEventState,
	user: state.auth.user,
	userEvents: state.events
});

const mapDispatchToProps = {
	duplicateEvent,
	findEvents,
	getRecentlyUpdatedEvents,
	openArchiveModal,
	openTransferEventModal,
	updateSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHooks(Events as any));
