import { ContentBlock } from "@/components/AppBlocks";
import Page from "@/components/Page";
import P from "@/components/text/P";
import EventListing from "@/events/event/widgets/legacy-widgets/EventListing";
import EventSearch from "@/events/event/widgets/legacy-widgets/EventSearch";
import { Tabs } from "@/ui/antd";
import { FC, useState } from "react";

const GlobalWidgets: FC = () => {
	const [activeTab, setActiveTab] = useState("eventListing");

	return (
		<Page pageName="Global embedded widgets">
			<ContentBlock>
				<P>
					Embed our widgets to your website and give your attendees the ability to directly book tickets from your
					website. Easily copy and paste provided code to your website to begin.
				</P>
				<Tabs
					activeKey={activeTab}
					onChange={(key) => setActiveTab(key)}
					items={[
						{
							children: <EventListing />,
							key: "eventListing",
							label: "Event Listing"
						},
						{
							children: <EventSearch />,
							key: "customSearch",
							label: "Custom Search"
						}
					]}
				/>
			</ContentBlock>
		</Page>
	);
};

export default GlobalWidgets;
