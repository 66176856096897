import { getConfig } from "@/config";
import { objToQueryString } from "../utils/Format";
import axios from "./AxiosWrapper";

const SalesforceService = {
	async authoriseApp(userId, isSandbox) {
		const consoleUrl = getConfig("CONSOLE_URL");
		const params = {
			response_type: "code",
			client_id: "3MVG9YDQS5WtC11qOf8VpZwEWTS6..XHGRyBZsz9Sy5H4_qPruCoKhsLjRBLWborHH6nwtxL7UtyPd1RiB_Ms",
			redirect_uri: `${consoleUrl}/connect-salesforce`,
			state: `${userId}:${isSandbox}`
		};
		const queryStr = objToQueryString(params);
		const url = isSandbox ? "https://test.salesforce.com" : "https://login.salesforce.com";
		window.location = `${url}/services/oauth2/authorize${queryStr}`;
	},

	async authenticate(userId, code, isSandboxAccount) {
		const response = await axios.get(`salesforce/authenticate/${userId}/${code}/${isSandboxAccount}`);
		return response.data;
	},

	async manualSync(userId) {
		const response = await axios.get(`salesforce/manual-sync/${userId}`);
		return response.data;
	},

	async testConnection(userId) {
		const response = await axios.get(`salesforce/test-connection/${userId}`);
		return response.data;
	},

	async disconnect(userId) {
		const response = await axios.get(`salesforce/disconnect/${userId}`);
		return response.data;
	}
};
export default SalesforceService;
