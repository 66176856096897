import { FC } from "React";
import { ResendToAttendeesPartialForm } from "./ResendToAttendeesPartialForm";
import { sendAttendeeTickets, sendOrder } from "@/state/actions/orderActions";
import { useAppDispatch } from "@/state/hooks";
import { Button } from "@/ui";
import { Form, Input } from "@/ui/antd";
import { email } from "@/utils/Validators";
import { UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";

const ActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: var(--spacing-md);
	margin-top: var(--spacing-md);
`;

const validEmail = email("Please enter a valid email");

type ResendOrderFormProps = {
	orderId: string;
	orderEmail: string;
	onClose: () => void;
};

export const ResendOrderForm: FC<ResendOrderFormProps> = ({ orderId, orderEmail, onClose }) => {
	const [resendOrderForm] = Form.useForm();

	const dispatch = useAppDispatch();

	const handleSubmit = () => {
		const values = resendOrderForm.getFieldsValue(true);
		dispatch(sendOrder(orderId, false, values.email));
		if (values.ticketIds?.length) {
			dispatch(sendAttendeeTickets(orderId, values.ticketIds));
		}
		resendOrderForm.resetFields();
		onClose();
	};

	const handleClose = () => {
		resendOrderForm.resetFields();
		onClose();
	};

	return (
		<Form form={resendOrderForm} layout="vertical" initialValues={{ email: orderEmail }}>
			<Form.Item
				name="email"
				label={<b>Email Address</b>}
				required
				rules={[
					{ min: 1, required: true, type: "email", message: "Please input an email" },
					{
						validator: (_, value) => {
							const isError = validEmail(value);
							if (isError) {
								return Promise.reject(isError);
							}

							return Promise.resolve();
						}
					}
				]}
			>
				<Input prefix={<UserOutlined />} placeholder="Email address" />
			</Form.Item>

			<ResendToAttendeesPartialForm form={resendOrderForm} />

			<ActionsContainer>
				<Button onClick={handleClose} variant="ghost" fullWidth isLoading={false} aria-label="Cancel">
					Cancel
				</Button>

				<Button onClick={handleSubmit} variant="primary" fullWidth isLoading={false} aria-label="Submit" type="submit">
					Send
				</Button>
			</ActionsContainer>
		</Form>
	);
};
