import { getUrlParameter } from "../../utils/Urls.js";

import { EVENT_LOCATION_SET, OVERRIDE_LOCATION_SET, USER_LOCATION_SET } from "../actions/locationActions";

const initialState = {
	eventLevelLocation: undefined,
	userLevelLocation: undefined,
	overrideLocation: getUrlParameter("loc")
};
export default (state = initialState, action) => {
	switch (action.type) {
		case EVENT_LOCATION_SET:
			return {
				...state,
				eventLevelLocation: action.location
			};
		case USER_LOCATION_SET:
			return {
				...state,
				userLevelLocation: action.location
			};
		case OVERRIDE_LOCATION_SET:
			return {
				...state,
				overrideLocation: action.location
			};
		default:
			return state;
	}
};
