import { Switch } from "@/ui/antd";
import InputLabel from "../../../components/InputLabel";

export default ({ element, performAction }) => {
	let disabled = false;
	element.forEach((element) => (disabled = disabled || element.isDisabled()));
	return (
		<div style={{ overflow: "auto", paddingTop: 20 }}>
			<InputLabel style={{ float: "left" }}>Remove seat</InputLabel>
			<Switch
				checked={disabled}
				onChange={(val) => {
					performAction(
						element.map((ele) => ele.disable),
						element.map(() => [val])
					);
				}}
				style={{ float: "right" }}
			/>
		</div>
	);
};
