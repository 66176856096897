import { Checkbox } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";

const CheckboxField = ({
	input,
	label,
	description,
	inline,
	noPadding,
	required,
	style,
	fieldChild,
	disabled,
	defaultChecked,
	labelAfter,
	noMargin,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error }
}) => {
	const checked = input.value === "true" ? true : input.value === "false" ? false : !!input.value;
	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			noPadding={noPadding}
			id={input.name}
			inline={inline}
			required={required}
			labelAfter={labelAfter}
			noMargin={noMargin}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<Checkbox
				id={input.name}
				{...input}
				checked={checked}
				style={style}
				disabled={disabled}
				defaultChecked={defaultChecked}
			>
				{fieldChild}
			</Checkbox>
		</InputWrapper>
	);
};

export default CheckboxField;
