import { getConfig } from "@/config";
import styled from "@emotion/styled";

const BuyTicketsWrapper = styled.div`
	text-align: center;
	a {
		color: #000;
		text-decoration: underline;
		font-weight: 400;

		:hover {
			color: #000;
		}
	}
`;

export const BuyTicketsOrHelp = () => {
	const WEBSITE_URL = getConfig("WEBSITE_URL");
	return (
		<BuyTicketsWrapper>
			<span>Ticket buyer?</span>{" "}
			<a href={`${WEBSITE_URL}/search`} target="_blank" rel="noreferrer">
				Discover events
			</a>
			<span> or </span>
			<a href={`${WEBSITE_URL}/contact-us/buyer-support`} target="_blank" rel="noreferrer">
				get help with your order
			</a>
		</BuyTicketsWrapper>
	);
};
