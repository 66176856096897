import CrunchingGif from "@/assets//crunching.gif";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { notification } from "@/ui/antd";
import { FC, useState } from "react";
import MassRefundConfirmationModal from "./MassRefundConfirmationModal";
import { MassRefundPreviewResult } from "./MassRefundPreviewResult";
import { SharedProps } from "./MassRefundSteps";

const ITEM_STATUS_COMPLETE = "complete";
const ITEM_STATUS_CANCELLED = "cancelled";

type MassRefundReviewProps = {
	onNextStep: () => void;
	onPreviousStep: () => void;
	step: number;
} & SharedProps;

export const MassRefundReview: FC<MassRefundReviewProps> = ({ isLoading, massRefundData, onPreviousStep }) => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

	const { result, isAsync, _id, options } = massRefundData ?? {};
	const { ordersAffected } = result ?? {};

	const eventId = currentEvent.event?._id ?? "";

	const { mutate } = trpc.massRefunds.deleteMassRefundPreview.useMutation({
		onError: (error) => {
			notification.error({
				description: error.message ?? "Failed to preview refund",
				message: "Error"
			});
		}
	});

	const handleGetItemsError = (error: any) => {
		notification.error({
			description: error.message ?? "An error occurred while fetching mass refund data.",
			message: "Error"
		});
	};

	const { data: ticketData, isLoading: isItemsLoading } = trpc.massRefunds.getTicketsAffected.useQuery(
		{
			cancel: options?.ticketTypes.cancel,
			eventDateIds: options?.eventDateIds.length ? options.eventDateIds : undefined,
			eventId,
			fees: options?.ticketTypes.fees,
			itemStatus: [ITEM_STATUS_COMPLETE, ...(options?.ticketTypes.includeCancelled ? [ITEM_STATUS_CANCELLED] : [])],
			itemTypeIds: options?.ticketTypes.ids.length ? options.ticketTypes.ids : undefined,
			paymentGateways: options?.gateways?.length ? options.gateways : undefined
		},
		{
			enabled: !!options && !!massRefundData,
			onError: handleGetItemsError
		}
	);

	const { data: addOnData } = trpc.massRefunds.getAddOnsAffected.useQuery(
		{
			eventDateIds: options?.eventDateIds.length ? options.eventDateIds : undefined,
			eventId,
			fees: options?.merchandise.fees,
			itemStatus: [ITEM_STATUS_COMPLETE, ...(options?.merchandise.includeCancelled ? [ITEM_STATUS_CANCELLED] : [])],
			paymentGateways: options?.gateways?.length ? options.gateways : undefined
		},
		{
			enabled: !!options && !!massRefundData,
			onError: handleGetItemsError
		}
	);

	const { data: donationData } = trpc.massRefunds.getDonationsAffected.useQuery(
		{
			eventDateIds: options?.eventDateIds.length ? options.eventDateIds : undefined,
			eventId,
			fees: options?.donations.fees,
			itemStatus: [ITEM_STATUS_COMPLETE, ...(options?.donations.includeCancelled ? [ITEM_STATUS_CANCELLED] : [])],
			paymentGateways: options?.gateways?.length ? options.gateways : undefined
		},
		{
			enabled: !!options && !!massRefundData,
			onError: handleGetItemsError
		}
	);

	const ticketCount = ticketData?.responseData?.count ?? 0;
	const addOnCount = addOnData?.responseData?.count ?? 0;
	const donationCount = donationData?.responseData?.count ?? 0;

	const handleCancelOrEditRefundPreview = () => {
		if (isAsync && _id) {
			mutate({ massRefundPreviewId: _id });
		}
		onPreviousStep();
	};

	const isGeneratingEstimation = isAsync && !result;
	const isSuccessfullyGenerated = !!result;

	const totalNumberOfItemsAffected =
		(options?.ticketTypes.refund ? ticketCount || 0 : 0) +
		(options?.merchandise.refund ? addOnCount || 0 : 0) +
		(options?.donations.refund ? donationCount || 0 : 0);

	if (!massRefundData || isLoading) {
		return (
			<div style={{ minHeight: "350px" }}>
				<LoadErrorView loading />
			</div>
		);
	}

	return (
		<>
			{isGeneratingEstimation && (
				<div
					style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 40, paddingBottom: 40 }}
				>
					<img src={CrunchingGif} alt="crunching numbers" height={120} width={120} />
					<P>
						<b>We're just punching in the numbers,</b>
					</P>
					<P style={{ color: "grey" }}>It may take up to 10 minutes to generate the preview.</P>
					<P style={{ color: "grey" }}>We'll let you know by email when it's done.</P>
				</div>
			)}
			{isSuccessfullyGenerated && (
				<>
					<MassRefundConfirmationModal
						isModalOpen={isConfirmationModalOpen}
						close={() => {
							setIsConfirmationModalOpen(false);
						}}
						totalNumberOfItemsAffected={totalNumberOfItemsAffected}
						massRefundData={massRefundData}
					/>
					<MassRefundPreviewResult
						cancelOrEditRefundPreview={handleCancelOrEditRefundPreview}
						isItemsLoading={isItemsLoading}
						massRefundData={massRefundData}
						numberOfAddOnsAffectedResult={addOnCount}
						numberOfDonationsAffectedResult={donationCount}
						numberOfTicketsAffectedResult={ticketCount}
						totalNumberOfItemsAffected={totalNumberOfItemsAffected}
					/>
					<LegacyButton
						ariaLabel={isAsync ? "Cancel" : "Edit"}
						style={{ float: "left" }}
						onClick={handleCancelOrEditRefundPreview}
					>
						{isAsync ? "Cancel" : "Edit"}
					</LegacyButton>
					<LegacyButton
						ariaLabel="Process Refunds"
						disabled={ordersAffected === 0}
						onClick={() => setIsConfirmationModalOpen(true)}
						style={{ float: "right", ...(totalNumberOfItemsAffected === 0 && { pointerEvents: "none" }) }}
						type="primary"
					>
						Confirm refund...
					</LegacyButton>
				</>
			)}
		</>
	);
};
