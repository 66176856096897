"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var utilities_exports = {};
__export(utilities_exports, {
  BankAccounts: () => BankAccounts_exports,
  Format: () => Format_exports,
  Messages: () => Messages_exports,
  SalesTaxConfig: () => config2,
  StringUtils: () => StringUtils_exports,
  TaxCalculations: () => TaxCalculations_exports,
  TaxNames: () => TaxNames_exports,
  TaxSettings: () => TaxSettings_exports
});
module.exports = __toCommonJS(utilities_exports);

// Format.ts
var Format_exports = {};
__export(Format_exports, {
  camelCaseToSentenceCase: () => camelCaseToSentenceCase,
  currency: () => currency,
  gateway: () => gateway,
  getCurrencyFromLocation: () => getCurrencyFromLocation,
  getCurrencySymbol: () => getCurrencySymbol,
  kebabCaseToSentenceCase: () => kebabCaseToSentenceCase
});
var import_locations = require("@hx/locations");
function gateway(gateway2) {
  if (gateway2 === "till" || gateway2 === "bpoint" || gateway2 === "worldpay" || gateway2 === "stripe-payments" || gateway2 === "braintree" || !gateway2) {
    return "Humanitix Payments";
  } else if (gateway2 === "volt-direct-pay") {
    return "Invoice pay link";
  } else {
    return kebabCaseToSentenceCase(gateway2);
  }
}
__name(gateway, "gateway");
function kebabCaseToSentenceCase(str) {
  if ((typeof str === "string" || str instanceof String) && str.length > 1) {
    const strWithSpaces = str.split("-").join(" ");
    return strWithSpaces.charAt(0).toUpperCase() + strWithSpaces.slice(1);
  }
  return `${str}`;
}
__name(kebabCaseToSentenceCase, "kebabCaseToSentenceCase");
function camelCaseToSentenceCase(str) {
  if ((typeof str === "string" || str instanceof String) && str.length > 1) {
    return str.replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`).replace(/^./, (match) => match.toUpperCase());
  }
  return `${str}`;
}
__name(camelCaseToSentenceCase, "camelCaseToSentenceCase");
var getCurrencySymbol = /* @__PURE__ */ __name((currencyCode) => {
  return import_locations.locationDataByCurrency[currencyCode].symbol;
}, "getCurrencySymbol");
var getCurrencyFromLocation = /* @__PURE__ */ __name((locale) => {
  return import_locations.locationDataByCountryCode[locale].currency;
}, "getCurrencyFromLocation");
var currency = /* @__PURE__ */ __name((number, options = {}) => {
  const { currencyCode = import_locations.Currency.AUD, prefix = false, hideCentsIfWholeNumber = false } = options;
  if (number === null || number === void 0 || isNaN(number)) {
    return number;
  }
  const symbol = getCurrencySymbol(currencyCode);
  let numberStr = number.toFixed(2);
  if (hideCentsIfWholeNumber) numberStr = numberStr.replace(".00", "");
  let str = `${symbol}${numberStr}`;
  if (prefix) {
    str = `${currencyCode} ${str}`;
  }
  return str;
}, "currency");

// BankAccounts.ts
var BankAccounts_exports = {};
__export(BankAccounts_exports, {
  cleanBSB: () => cleanBSB,
  formatAccountNumber: () => formatAccountNumber,
  formatBSB: () => formatBSB,
  getDisplayParts: () => getDisplayParts,
  getRules: () => getRules,
  isValid: () => isValid,
  validate: () => validate
});
function formatBSB(rawBSB, location = "AU") {
  const bankAccountRules = getRules(location);
  const bsb = cleanBSB(rawBSB);
  if (bsb.length > bankAccountRules.bsb.max || bsb.length < bankAccountRules.bsb.min) {
    return false;
  }
  const onlyNumbers = new RegExp(/^\d*$/);
  if (!onlyNumbers.test(bsb)) {
    return false;
  }
  if (location === "US") {
    return String(bsb);
  }
  return `${bsb.substring(0, 3)}-${bsb.substring(3, 6)}`;
}
__name(formatBSB, "formatBSB");
function cleanBSB(bsb) {
  if (!bsb) return "";
  return String(bsb).replace(/\s|-/g, "");
}
__name(cleanBSB, "cleanBSB");
function formatAccountNumber(accountNum) {
  if (!accountNum) return "";
  return String(accountNum).replace(/\s|-/g, "");
}
__name(formatAccountNumber, "formatAccountNumber");
function isValid(rawBSB, rawAccount, location) {
  try {
    return validate(rawBSB, rawAccount, location);
  } catch (err) {
    return false;
  }
}
__name(isValid, "isValid");
function validate(rawBSB, rawAccount, location) {
  const bsb = cleanBSB(rawBSB);
  const account = formatAccountNumber(rawAccount);
  const rules = config[`BANK_FORMAT_${location}`];
  if (bsb.length > rules.bsb.max || bsb.length < rules.bsb.min) {
    const lengthErr = rules.bsb.min === rules.bsb.max ? `${rules.bsb.max} digits` : `between ${rules.bsb.min} and ${rules.bsb.max} digits`;
    throw `${rules.bsb.name} should be ${lengthErr}`;
  }
  if (account.length > rules.account.max || account.length < rules.account.min) {
    const lengthErr = rules.account.min === rules.account.max ? `${rules.account.max} digits` : `between ${rules.account.min} and ${rules.account.max} digits`;
    throw `${rules.account.name} should be ${lengthErr}`;
  }
  return true;
}
__name(validate, "validate");
function getDisplayParts(bsb, account, location, name, bankName, swiftCode, IBAN) {
  const rules = config[`BANK_FORMAT_${location}`];
  const displayParts = [];
  if (name) {
    displayParts.push(`Name: ${name}`);
  }
  if (rules.bsb.min !== 0) {
    displayParts.push(`${rules.bsb.name}: ${bsb}`);
  }
  displayParts.push(`${rules.account.name}: ${account}`);
  if (bankName) {
    displayParts.push(`Bank: ${bankName}`);
  }
  if (swiftCode) {
    displayParts.push(`Swift code: ${swiftCode}`);
  }
  if (IBAN) {
    displayParts.push(`IBAN: ${IBAN}`);
  }
  return displayParts;
}
__name(getDisplayParts, "getDisplayParts");
function getRules(location) {
  return config[`BANK_FORMAT_${location}`];
}
__name(getRules, "getRules");
var config = {
  BANK_FORMAT_AU: {
    bsb: {
      name: "BSB",
      min: 6,
      max: 6
    },
    account: {
      name: "Account",
      min: 4,
      // LMAO 10 digit account length means its an offset account
      max: 9
    }
  },
  BANK_FORMAT_NZ: {
    bsb: {
      name: "",
      min: 0,
      max: 0
    },
    account: {
      name: "Account",
      min: 15,
      max: 16
    }
  },
  BANK_FORMAT_GB: {
    bsb: {
      name: "Sort code",
      min: 6,
      max: 6
    },
    account: {
      name: "Account",
      min: 4,
      max: 8
    }
  },
  BANK_FORMAT_US: {
    bsb: {
      name: "Routing number",
      min: 9,
      max: 9
    },
    account: {
      name: "Account",
      min: 6,
      max: 17
    }
  },
  BANK_FORMAT_CA: {
    bsb: {
      name: "Routing number",
      min: 9,
      max: 9
    },
    account: {
      name: "Account",
      min: 8,
      max: 12
    }
  },
  BANK_FORMAT_MY: {
    bsb: {
      name: "BSB",
      min: 9,
      max: 9
    },
    account: {
      name: "Account",
      min: 8,
      max: 12
    }
  },
  BANK_FORMAT_MX: {
    bsb: {
      name: "",
      min: 0,
      max: 0
    },
    account: {
      name: "CLABE",
      min: 18,
      max: 18
    }
  },
  BANK_FORMAT_DE: {
    bsb: {
      name: "",
      min: 0,
      max: 0
    },
    account: {
      name: "",
      min: 0,
      max: 0
    }
  },
  BANK_FORMAT_SG: {
    bsb: {
      name: "",
      min: 0,
      max: 0
    },
    account: {
      name: "",
      min: 0,
      max: 0
    }
  },
  BANK_FORMAT_FJ: {
    bsb: {
      name: "",
      min: 0,
      max: 0
    },
    account: {
      name: "",
      min: 0,
      max: 0
    }
  }
};

// StringUtils.ts
var StringUtils_exports = {};
__export(StringUtils_exports, {
  reverseCamelize: () => reverseCamelize
});
function reverseCamelize(stringToReverse) {
  const text = stringToReverse;
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}
__name(reverseCamelize, "reverseCamelize");

// Messages.ts
var Messages_exports = {};
__export(Messages_exports, {
  getCalendarDescription: () => getCalendarDescription,
  getValidTicketMessagesForOrder: () => getValidTicketMessagesForOrder,
  getValidTicketMessagesForTicket: () => getValidTicketMessagesForTicket
});
var import_sanitisation = require("@hx/sanitisation");
function getCalendarDescription(event, order) {
  if (order && event?.messages?.customAttendeeCalendarMessage?.length) {
    return event.messages.customAttendeeCalendarMessage;
  }
  if (event?.messages?.customVisitorCalendarMessage?.length) {
    return event.messages.customVisitorCalendarMessage;
  }
  let description = [
    event.name
  ];
  if (event?.eventLocation?.type === "online" && event?.eventLocation?.onlineUrl) {
    description.push(event.eventLocation.onlineUrl);
  }
  const { sendTickets } = event.settings;
  if (sendTickets && order?.link) {
    const ticketsLength = order.tickets ? order.tickets.length : 2;
    const ticketsTxt = ticketsLength > 1 ? "tickets" : "ticket";
    description.push(`
Your digital ${ticketsTxt}
${order.link}
`);
  }
  if (event?.accessibility) {
    const infoItems = [
      "contactName",
      "contactNumber",
      "travelInstructions",
      "entryInstructions",
      "afterEntryInstructions",
      "hazards",
      "toiletLocation",
      "disabledParking"
    ];
    let accessibilityPoints = infoItems.reduce((points, key) => {
      if (event?.accessibility?.[key]) {
        points.push(`${reverseCamelize(key)}: ${event.accessibility[key]}`);
      }
      return points;
    }, []);
    if (event.accessibility.features) {
      const features = event.accessibility.features;
      const featuresKeys = Object.keys(features);
      const accessibilityFeatures = [];
      for (const key of featuresKeys) {
        if (features[key]) {
          accessibilityFeatures.push(` - ${reverseCamelize(key)}`);
        }
      }
      if (accessibilityFeatures.length) {
        accessibilityPoints.push(`
Accessibility Features`);
        accessibilityPoints = accessibilityPoints.concat(accessibilityFeatures);
      }
    }
    if (accessibilityPoints.length) {
      description.push(`
Accessibility`);
      description = description.concat(accessibilityPoints);
    }
  }
  return description.join("\n");
}
__name(getCalendarDescription, "getCalendarDescription");
function getMessages(messagesByTicketType, ticketTypes, order) {
  const ticketTypeLookup = order.tickets?.reduce((acc, ticket) => {
    acc[String(ticket.ticketTypeId)] = ticket.ticketTypeName;
    if (ticket.packageId) {
      acc[String(ticket.packageId)] = ticket.packageName;
    }
    return acc;
  }, {}) || {};
  if (order.clientDonationV2?.totalsV2?.netSales) {
    const donationTicketType = ticketTypes.find((ticketType) => ticketType.isDonation);
    if (donationTicketType) ticketTypeLookup[String(donationTicketType._id)] = donationTicketType?.name;
  }
  if (!Object.keys(ticketTypeLookup).length) return [];
  return messagesByTicketType.reduce((messages, messageByTicketType) => {
    const matchedTicketIds = messageByTicketType.ticketTypeIds?.filter((id) => Object.keys(ticketTypeLookup).includes(String(id))) || [];
    if (matchedTicketIds.length) {
      const ticketNames = matchedTicketIds.map((id) => ticketTypeLookup[String(id)]).join(", ");
      messages.push({
        ticketNames,
        message: (0, import_sanitisation.sanitize)(messageByTicketType.message)
      });
    }
    return messages;
  }, []);
}
__name(getMessages, "getMessages");
function getValidTicketMessagesForOrder(event, order) {
  if (!event?.messages?.orderMessagesByTicketType?.length) {
    return [];
  }
  return getMessages(event.messages.orderMessagesByTicketType, event.ticketTypes, order);
}
__name(getValidTicketMessagesForOrder, "getValidTicketMessagesForOrder");
function getValidTicketMessagesForTicket(event, order) {
  if (!event?.messages?.ticketMessagesByTicketType?.length) {
    return [];
  }
  return getMessages(event.messages.ticketMessagesByTicketType, event.ticketTypes, order);
}
__name(getValidTicketMessagesForTicket, "getValidTicketMessagesForTicket");

// TaxCalculations.ts
var TaxCalculations_exports = {};
__export(TaxCalculations_exports, {
  addTax: () => addTax,
  calculateTaxes: () => calculateTaxes
});
var import_big = __toESM(require("big.js"));
function calculateTaxes(amount, taxRate, taxesIncluded = true) {
  const typedTaxRate = typeof taxRate === "string" ? Number(taxRate) : taxRate;
  const divideBy = taxesIncluded ? new import_big.default(100).add(typedTaxRate) : 100;
  const onePercent = new import_big.default(amount).div(divideBy);
  return new import_big.default(onePercent).mul(typedTaxRate).round().toNumber();
}
__name(calculateTaxes, "calculateTaxes");
function addTax(amount, taxRate) {
  const tax = calculateTaxes(amount, taxRate, false);
  return new import_big.default(tax).add(amount).toNumber();
}
__name(addTax, "addTax");

// TaxSettings.ts
var TaxSettings_exports = {};
__export(TaxSettings_exports, {
  getEventTaxInfo: () => getEventTaxInfo,
  getGlobalTaxInfo: () => getGlobalTaxInfo,
  isPassingOnTax: () => isPassingOnTax
});

// TaxNames.ts
var TaxNames_exports = {};
__export(TaxNames_exports, {
  getTaxNameFromLocation: () => getTaxNameFromLocation
});
function getTaxNameFromLocation(location) {
  switch (location) {
    case "AU":
      return "GST";
    case "NZ":
      return "GST";
    case "US":
      return "Sales tax";
    case "GB":
      return "VAT";
    default:
      return "GST";
  }
}
__name(getTaxNameFromLocation, "getTaxNameFromLocation");

// SalesTaxConfig.ts
var config2 = {
  SALES_TAX: {
    rate: 10,
    chargeOnFees: true,
    name: getTaxNameFromLocation("AU"),
    locked: false,
    taxIdName: "ABN"
  },
  SALES_TAX_US: {
    rate: 0,
    chargeOnFees: false,
    name: "Sales tax",
    tabName: "Tax",
    locked: false,
    taxIdName: "Sales tax Id"
  },
  SALES_TAX_AU: {
    rate: 10,
    chargeOnFees: true,
    name: "GST",
    locked: true,
    taxIdName: "ABN"
  },
  SALES_TAX_NZ: {
    rate: 15,
    chargeOnFees: true,
    name: "GST",
    locked: true,
    taxIdName: "GST Number"
  },
  SALES_TAX_GB: {
    rate: 20,
    chargeOnFees: false,
    name: "VAT",
    locked: true,
    taxIdName: "VAT Number"
  }
};

// TaxSettings.ts
function getGlobalTaxInfo(location) {
  const loc = location ? `_${location.toUpperCase()}` : "";
  const value = config2[`SALES_TAX${loc}`];
  return value ?? config2[`SALES_TAX`];
}
__name(getGlobalTaxInfo, "getGlobalTaxInfo");
function getEventTaxInfo(salesTaxSettings, location = "AU") {
  const globalInfo = getGlobalTaxInfo(location) || getGlobalTaxInfo();
  const locked = globalInfo?.locked;
  if (locked) {
    return globalInfo;
  }
  const name = salesTaxSettings?.name && salesTaxSettings.name.length ? salesTaxSettings.name : globalInfo.name;
  const rate = salesTaxSettings?.rate ? salesTaxSettings.rate : globalInfo.rate;
  const taxIdName = globalInfo.taxIdName;
  return {
    locked,
    name,
    rate,
    taxIdName,
    tabName: globalInfo.tabName
  };
}
__name(getEventTaxInfo, "getEventTaxInfo");
function isPassingOnTax(event) {
  if (event?.paymentOptions?.salesTaxSettings?.enabled && event?.paymentOptions?.salesTaxSettings?.passOn) {
    return true;
  }
  return false;
}
__name(isPassingOnTax, "isPassingOnTax");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  BankAccounts,
  Format,
  Messages,
  SalesTaxConfig,
  StringUtils,
  TaxCalculations,
  TaxNames,
  TaxSettings
});
