import store from "../state/store";
import axios from "./AxiosWrapper";

const eventLocationHeader = "x-event-level-location";
const userLocationHeader = "x-user-level-location";

const LocationService = {
	setUserLocation(userLocation) {
		axios.defaults.headers.common[userLocationHeader] = userLocation;
		axios.defaults.headers.post[userLocationHeader] = userLocation;
	},

	setEventLocation(eventLocation) {
		axios.defaults.headers.common[eventLocationHeader] = eventLocation;
		axios.defaults.headers.post[eventLocationHeader] = eventLocation;
	},

	getLocation(isDefaultUser) {
		const { locations, auth } = store.getState();
		const locationLevel = isDefaultUser ? "userLevelLocation" : "eventLevelLocation";
		if (!locations.overrideLocation) {
			const location = locations[locationLevel] || auth.user?.location;
			if (!location) {
				console.warn("Defaulting location to AU");
				return "AU";
			}
			return location;
		}
		return locations.overrideLocation;
	}
};

export default LocationService;
