import { CodeBlock, PreviewMockSite, PreviewMode } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC, useState } from "react";

const props = `type PreviewMockSiteProps = {
  banner?: ReactNode | null;
  children: ReactNode;
  darkBackground?: boolean;
  isMobile?: boolean;
  linkOut?: string;
  noGutters?: boolean; // Remove the side padding from the window
  previewMode: PreviewMode;
  setPreviewMode: (mode: PreviewMode) => void;
  noScroll?: boolean;
};`;

const sample = `import { PreviewMockSite } from "@/ui";

const [mode, setMode] = useState<PreviewMode>("desktop");

<PreviewMockSite
  noGutters={mode === "phone"}
  previewMode={mode}
  setPreviewMode={setMode}
  linkOut="https://humanitix.com"
>
  <div>
    <h1>Blue Art</h1>
  </div>
</PreviewMockSite>
`;

export const PreviewMockSitePage: FC = () => {
	const [mode, setMode] = useState<PreviewMode>("desktop");

	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<PreviewMockSite
				noGutters={mode === "phone"}
				previewMode={mode}
				setPreviewMode={setMode}
				linkOut="https://humanitix.com"
			>
				<div
					style={{
						alignItems: "center",
						background: "royalblue",
						borderRadius: 10,
						color: "white",
						margin: 20,
						display: "flex",
						height: 1000,
						justifyContent: "center"
					}}
				>
					<h1>Blue Art</h1>
				</div>
			</PreviewMockSite>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
