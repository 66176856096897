import React, { useEffect, useLayoutEffect } from "react";
import { css } from "@emotion/css";
import { FunnelGraph } from "@trutoo/funnel-graph";
import "@trutoo/funnel-graph/dist/index.css";
import H2 from "@/components/text/H2";

const funnel = css({
	".fg-label__value": {
		color: "rgba(0, 0, 0, 0.85)"
	},
	".fg-label__title": {
		color: "rgba(0, 0, 0, 0.85)",
		fontWeight: "normal"
	},
	".fg-label__percentage": {
		color: "#ffa179"
	},

	".fg:not(.fg--vertical) .fg-label:not(:first-child)": {
		borderLeft: "1px solid #e0e0e0"
	}
});

interface FunnelStep {
	step: string;
	label: string;
	count: number;
}

interface FunnelProps {
	funnelSteps: FunnelStep[];
}

const Funnel = ({ funnelSteps }: FunnelProps) => {
	const drawFunnel = () => {
		try {
			const labels = funnelSteps.map((step: any) => step.label);
			const values = funnelSteps.map((step: any) => step.count);

			const graph = new FunnelGraph({
				container: "#funnel",
				gradientDirection: "horizontal",
				data: {
					labels: labels,
					colors: ["#f8c8b4", "#ffa179"],
					values: values
				},
				displayPercent: true,
				direction: "horizontal"
			});
			graph.draw();
		} catch {
			/* catch me if you can */
		}
	};
	useEffect(() => {
		drawFunnel();
	}, []);

	useLayoutEffect(() => {
		window.addEventListener("resize", drawFunnel);
		return () => window.removeEventListener("resize", drawFunnel);
	}, []);

	return (
		<>
			<H2>Checkout funnel</H2>
			<div id="funnel" className={funnel} style={{ height: 400, width: "100%" }}></div>
		</>
	);
};

export default Funnel;
