import { Button, CodeBlock, ResponsiveModal } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC, useState } from "react";

const props = `type ResponsiveModalProps = {
	closable?: boolean;
	closeButtonStyle?: "sm" | "lg";
	fullscreen?: boolean;
	header?: ReactNode;
	onCancel?: () => void;
	open: boolean;
	padding?: string | false;
	primaryButtonAction?: () => void;
	primaryButtonText?: string;
	secondaryButtonAction?: () => void;
	secondaryButtonText?: string;
	footer?: ReactNode | false;
	top?: string;
	width?: string;
};`;

const sample = `const [isOpen, setIsOpen] = useState(false);

<Button onClick={() => setIsOpen(true)}>Open Modal</Button>
<ResponsiveModal
	onCancel={() => setIsOpen(false)}
	open={isOpen}
	primaryButtonAction={() => setIsOpen(false)}
	primaryButtonText="Ok"
	secondaryButtonText="Cancel"
	width="600px"
>
	Da ting go skirrrrrt
</ResponsiveModal>
`;

export const ResponsiveModalPage: FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Flex gap="lg" vertical>
			<h2>Default</h2>
			<Button onClick={() => setIsOpen(true)} variant="tertiary">
				Open Modal
			</Button>
			<ResponsiveModal
				onCancel={() => setIsOpen(false)}
				open={isOpen}
				primaryButtonAction={() => setIsOpen(false)}
				primaryButtonText="Ok"
				secondaryButtonText="Cancel"
				secondaryButtonAction={() => setIsOpen(false)}
				width="600px"
			>
				<video
					autoPlay
					loop
					style={{ width: "100%", height: "100%" }}
					src="https://static.humanitix.com/website/videos/ree.mp4"
				/>
			</ResponsiveModal>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
