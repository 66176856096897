import iconReference from "../services/IconReference";

interface IIconProps {
	icon: keyof typeof iconReference;
	style?: any;
}

const Icon = ({ icon, style = {} }: IIconProps) => {
	const iconSVG = iconReference[icon];
	if (!iconSVG) {
		console.error(`${icon} not found`);
		return null;
	}
	return (
		<div style={{ display: "inline-block", ...style }}>
			<img src={String(iconSVG)} aria-hidden="true" />
		</div>
	);
};
export default Icon;
