import QRCode from "@/components/QRCode";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { getEventUrls } from "@/utils/Events";
import { css } from "@emotion/css";
import { LinkTrackingType, addLinkTracking } from "@hx/analytics";
import { Modal, Radio } from "@/ui/antd";
import { useState } from "react";
import { connect } from "react-redux";
import { DesktopView } from "./components/DesktopView";
import { MobileView } from "./components/MobileView";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const pageWrapperStyle = css({
	height: "100%",
	display: "flex",
	flexFlow: "column nowrap"
});

const deviceContainerWrapper = css({
	display: "flex",
	gap: 12,
	flex: 1
});

const tabViewContent = css({
	margin: "auto",
	marginBottom: 24,
	textAlign: "center"
});

const textCenter = css({
	textAlign: "center"
});

type ReduxInput = Partial<GlobalReduxState>;
type EventPreviewInput = ReduxInput;

const squareStyle = {
	minWidth: 202,
	height: "auto",
	marginBottom: 8,
	width: 202
};

const options = [
	{
		label: "For event page",
		value: "event"
	},
	{
		label: "For select ticket page",
		value: "tickets"
	}
];

const EventPreview = connect(
	(state: Partial<GlobalReduxState>) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(({ currentEvent }: EventPreviewInput) => {
	const [showQRCodes, setShowQRCodes] = useState(false);
	const [selectedTab, setSelectedTab] = useState("event");
	const { eventPage, selectTicketsPage } = getEventUrls(currentEvent?.event);

	const isPublished = !!currentEvent?.event?.published;
	const isEventTab = selectedTab === "event";

	const urls = {
		scan: isEventTab
			? addLinkTracking(LinkTrackingType.eventQRCode, eventPage)
			: addLinkTracking(LinkTrackingType.eventTicketsQRCode, selectTicketsPage),
		download: isEventTab
			? addLinkTracking(LinkTrackingType.eventQRCode, getEventUrls(currentEvent?.event, true).eventPage)
			: addLinkTracking(LinkTrackingType.eventTicketsQRCode, getEventUrls(currentEvent?.event, true).selectTicketsPage)
	};

	return (
		<div className={pageWrapperStyle}>
			<div className={deviceContainerWrapper}>
				<DesktopView url={eventPage} />
				<MobileView url={eventPage} onShowQRCodesClicked={() => setShowQRCodes(true)} />
			</div>

			<Modal title="View on mobile" open={showQRCodes} onCancel={() => setShowQRCodes(false)} footer={null}>
				<div className={textCenter}>
					<RadioGroup
						buttonStyle="solid"
						onBlur={() => null}
						onChange={(event) => setSelectedTab(event.target.value)}
						value={selectedTab}
					>
						{options.map((option, idx) => {
							return (
								<RadioButton
									key={`radio-option-${idx}`}
									style={{
										...squareStyle,
										padding: 6,
										lineHeight: 1.5715
									}}
									value={option.value}
								>
									<span>{option.label}</span>
								</RadioButton>
							);
						})}
					</RadioGroup>
					<div className={tabViewContent}>
						<h4>
							Scan the QR code with your phone camera to view on mobile.{" "}
							{!isPublished ? "When the event is published, the QR code will include an image." : ""}
						</h4>
						<QRCode scanUrl={urls.scan} showLogo={isPublished} allowDownload={isPublished} enlarge={!isPublished} />
					</div>
				</div>
			</Modal>
		</div>
	);
});

export { EventPreview };
