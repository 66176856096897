import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";

const Wrapper = styled.div`
	display: flex;
	height: 100%;
	overflow: hidden;
`;

const Menu = styled.div`
	min-width: 220px;
	overflow: auto;
	@media (max-width: 868px) {
		display: none;
	}
`;

const content = css({
	flex: 1,
	height: "100%",
	width: "calc(100% - 220px)",
	overflow: "auto"
});

interface IMenuLayoutProps {
	children: ReactNode;
}

const MenuLayout = ({ children }: IMenuLayoutProps) => {
	const firstChild = React.Children.toArray(children)[0];
	const secondChild = React.Children.toArray(children)[1];
	return (
		<Wrapper>
			<Menu>{firstChild}</Menu>
			<div id="pageContent" className={content + " scrollEl"}>
				{secondChild}
			</div>
		</Wrapper>
	);
};
export default MenuLayout;
