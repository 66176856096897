import { css } from "@emotion/css";
import { Component } from "react";
import { Field } from "redux-form";
import { InputNumberField, SelectField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { required } from "@/utils/Validators";

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	})
};

class TicketsPurchasedTrigger extends Component {
	ticketTypeRequired = required("Ticket Type required");
	quantityRequired = required("Quantity required");

	render() {
		const { fields, purchased, ticketOptions, addTicket, dispatch, formName } = this.props;

		const columns = [
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Ticket <span className={styles.required}> (required)</span>
					</span>
				),
				dataIndex: "ticketId",
				key: "ticketId",
				render: (_, __, index) => {
					return (
						<div>
							<Field
								name={`trigger.purchased[${index}].ticketId`}
								placeholder="Please select"
								component={SelectField}
								options={ticketOptions}
								validate={this.ticketTypeRequired}
							/>
						</div>
					);
				}
			},
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Quantity <span className={styles.required}> (required)</span>
					</span>
				),
				dataIndex: "quantity",
				key: "quantity",
				render: (_, __, index) => {
					return (
						<div>
							<Field
								name={`trigger.purchased[${index}].quantity`}
								component={InputNumberField}
								validate={this.quantityRequired}
								min={0}
							/>
						</div>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				render: (_, __, index) => (
					<TableActions>
						<IconButton
							icon="delete"
							onClick={() => fields.remove(index)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				)
			}
		];

		return (
			<div>
				<ResponsiveTable
					pagination={false}
					draggable={{ dispatch, formName, fieldName: "trigger.purchased" }}
					columns={columns}
					dataSource={purchased}
					rowKey="key"
					expandedRowKeys={[]}
					rowClassName={(record) => {
						return record.ticketId ? record.ticketId : "";
					}}
				/>
				<ButtonsBar label="" stackButtons>
					<LegacyButton
						onClick={() => addTicket(fields, purchased)}
						icon={{ name: "plus", left: true }}
						ariaLabel="Add code"
						type="action"
					>
						Add ticket
					</LegacyButton>
				</ButtonsBar>
			</div>
		);
	}
}

export default TicketsPurchasedTrigger;
