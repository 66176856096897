import LoadErrorView from "@/components/LoadErrorView";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import LinkButton from "@/components/buttons/LinkButton";
import { P } from "@/components/text/Text";
import EventsService from "@/services/EventsService";
import LocationService from "@/services/LocationService";
import { openTicketSaleStatusModal } from "@/state/actions/ticketSaleStatusActions";
import { openOfferModal, putBackOnSale, remove, setSelected } from "@/state/actions/waitlistActions";
import { Alert, Modal, Table, Tooltip } from "@/ui/antd";
import { max } from "@/utils/CssUtils";
import { getTicketDisplayName } from "@/utils/Ticket";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import TicketsStatusModal from "@/events/event/ticketStatus/TicketsStatusModal";
import AddToWaitlistModal from "@/events/event/waitlist/AddToWaitlistModal";
import ManualOfferModal from "@/events/event/waitlist/ManualOfferModal";
import WaitlistStatus from "@/events/event/waitlist/WaitlistStatus";

const quickInfoBox = css({
	display: "flex",
	backgroundColor: "#f9f9fa",
	marginBottom: 16,
	height: 40,
	padding: "10px 16px",
	p: { height: 20, padding: 0 },
	span: {
		borderRight: "1px solid #dedede",
		marginLeft: 35,
		marginRight: 16
	},
	[max(460)]: {
		flexDirection: "column",
		height: "auto",
		padding: 10,
		span: {
			borderRight: "none",
			borderTop: "1px solid #dedede",
			margin: "5px 0px 8px"
		},
		p: { textAlign: "center" }
	}
});

const ManageWaitlist = ({
	currentEvent,
	waitlist,
	search,
	downloadWaitlistCsv,
	loadWaitlist,
	remove,
	setSelected,
	openOfferModal,
	putBackOnSale,
	defaultSearch,
	loadPage,
	openTicketSaleStatusModal,
	tabChange
}) => {
	const {
		event: { _id: eventId, dates, datesUseCalendar, ticketTypes, totalPeopleOnWaitlist, waitlistSettings }
	} = currentEvent;
	const { selectedRowKeys } = waitlist;

	const [addModelOpen, updateAddModel] = useState(false);

	const openAddModel = () => {
		updateAddModel(true);
	};
	const closeAddModel = () => {
		updateAddModel(false);
		loadWaitlist();
	};

	useEffect(() => {
		// returned function will be called on component unmount
		//https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
		return () => {
			setSelected([]);
		};
	}, []);

	const getFilterOptions = () => {
		return [
			{
				key: "ticketIds",
				label: "Select ticket types",
				mode: "multiple",
				placeholder: "All Ticket Types",
				options: ticketTypes
					.filter((tt) => !(tt.disabled || tt.deleted || tt.donation))
					.map((tt) => ({
						value: tt._id,
						name: getTicketDisplayName(tt)
					}))
			},
			{
				key: "eventDateIds",
				label: "Event Dates",
				mode: "multiple",
				kind: datesUseCalendar ? "eventDatePicker" : "select",
				placeholder: "All dates",
				options: dates.map((date) => ({
					...(datesUseCalendar ? date : {}),
					value: date._id,
					name: `${date.display.date}, ${date.display.time}`
				}))
			},
			{
				key: "status",
				label: "Status",
				kind: "radio",
				options: [
					{ value: undefined, name: "All" },
					{ value: "waiting", name: "Waiting" },
					{ value: "notified", name: "Notified" },
					{ value: "interested", name: "Interested" },
					{ value: "passed", name: "Passed" },
					{ value: "purchased", name: "Purchased" }
				]
			}
		];
	};

	const handleOpenOfferModal = () => {
		openOfferModal();
	};

	const confirmRemove = () => {
		Modal.confirm({
			title: "Remove from waitlist",
			content: `Remove ${selectedRowKeys.length} ${
				selectedRowKeys.length > 1 ? "people" : "person"
			} from the waitlist?`,
			okText: "Remove",
			onOk() {
				remove(eventId, selectedRowKeys, {
					eventDateIds: search.eventDateIds,
					ticketIds: search.ticketIds,
					query: search.query,
					page: search.page
				});
				setSelected([]);
			},
			onCancel() {
				null;
			}
		});
	};

	const confirmPutBackOnSale = () => {
		Modal.confirm({
			title: "Put all remaining tickets back on sale",
			content: "Are you sure you want to put back on sale remaining available tickets?",
			okText: "Put back on sale",
			onOk() {
				putBackOnSale(eventId);
			},
			onCancel() {
				null;
			}
		});
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelected(selectedRowKeys);
	};

	const hasPreviouslySoldOut = dates.some((date) => date && date.hasPreviouslySoldOut);

	const ticketsLookUp = EventsService.getTicketsLookup(currentEvent.event);
	const dateNameLookUp = dates.reduce((lookup, date) => {
		lookup[date._id] = `${date.display.date}, ${date.display.time}`;
		return lookup;
	}, {});
	const columns = [
		{
			title: "First Name",
			dataIndex: "firstName",
			key: "firstName"
		},
		{
			title: "Last Name",
			dataIndex: "lastName",
			key: "lastName"
		},
		{
			title: "Date Added",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value) => {
				return new HumaniDate(value, "guess", LocationService.getLocation(true)).formatting.dateTime();
			}
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email"
		},
		{
			title: "Mobile",
			dataIndex: "mobile",
			key: "mobile"
		},
		{
			title: "Ticket Types",
			dataIndex: "ticketIds",
			key: "ticketIds",
			render: (ids) => {
				if (!ids) {
					return " - ";
				}
				const names = ids.reduce((names, id) => {
					const ticket = ticketsLookUp[id];
					if (ticket && ticket.name) {
						names.push(ticket.name);
					}
					return names;
				}, []);
				return names.join(", ");
			}
		},
		{
			title: "Tickets requested",
			dataIndex: "ticketsRequested",
			key: "ticketsRequested"
		},
		{
			title: "Event dates",
			dataIndex: "eventDateIds",
			key: "eventDateIds",
			render: (ids) => {
				if (!ids) {
					return " - ";
				}
				const names = ids.reduce((names, id) => {
					const name = dateNameLookUp[id];
					if (name) {
						names.push(name);
					}
					return names;
				}, []);
				return names.join(", ");
			}
		},
		{
			title: "Marketing Opt-in",
			dataIndex: "organiserMailListOptIn",
			key: "organiserMailListOptIn",
			render: (value) => {
				return value ? "Yes" : "No";
			}
		},
		{
			title: "Status",
			fixed: "right",
			dataIndex: "status",
			key: "status",
			render: (value, person) => <WaitlistStatus status={value} person={person} />
		}
	];
	const rowSelection = {
		fixed: true,
		selectedRowKeys,
		preserveSelectedRowKeys: true,
		onChange: onSelectChange
	};
	const peopleSelected = selectedRowKeys && selectedRowKeys.length;
	const filterOptions = getFilterOptions();
	const warningMessage = (
		<>
			Your waitlist is disabled. However, you can still offer tickets to the remaining waitlisted people. To enable
			waitlist go to waitlist{" "}
			<LinkButton onClick={() => tabChange("settings")} style={{ color: "#196097", padding: 0 }}>
				settings
			</LinkButton>{" "}
			tab.
		</>
	);

	const currentPage = Number(waitlist.list.page || 1);
	const maxPageSize = 20;

	return (
		<LoadErrorView loading={waitlist.list.loading} error={waitlist.list.error} retryAction={loadWaitlist}>
			<>
				<SearchRefinements
					defaultSearch={defaultSearch}
					load={loadWaitlist}
					tooltip="Filter waitlist"
					filterOptions={filterOptions}
					showRefinements
					dropdownId="waitlistFilter"
					download={{
						download: downloadWaitlistCsv,
						loading: waitlist.download.loading,
						tooltip: "Export waitlist"
					}}
					extras={
						<LegacyButton
							icon={{ name: "plus", left: true }}
							type="action"
							flavor="minimal"
							aria-label=" Add attendees to waitlist"
							onClick={openAddModel}
						>
							<span style={{ whiteSpace: "nowrap" }}>Add attendees to waitlist</span>
						</LegacyButton>
					}
					wrapperMarginBottom={3}
				/>
				{!waitlistSettings?.enabled ? (
					<Alert showIcon message={warningMessage} type="warning" style={{ marginBottom: 16 }} />
				) : null}
				<div className={quickInfoBox}>
					<P>
						<b>People waiting for tickets:</b> {totalPeopleOnWaitlist}
					</P>
					<span />
					<LinkButton
						onClick={openTicketSaleStatusModal}
						style={{
							color: "#196097"
						}}
					>
						Check ticket status
					</LinkButton>
				</div>
				{!waitlist.list.loading && (
					<>
						<Table
							columns={columns}
							dataSource={waitlist.list.data}
							rowKey="_id"
							rowSelection={rowSelection}
							scroll={{ x: 1200 }}
							pagination={false}
						/>
						<SimplePagination
							pageAction={loadPage}
							currentPage={currentPage}
							totalPages={Math.ceil(waitlist.list.total / maxPageSize)}
							style={{ float: "right", marginTop: 18 }}
							pageSize={maxPageSize}
						/>
					</>
				)}
				<div style={{ marginTop: 16 }}>
					<Tooltip title="Any available tickets will now be on sale for the general public">
						<LegacyButton
							size="large"
							style={{
								float: "right"
							}}
							disabled={!hasPreviouslySoldOut}
							aria-label="Put back on sale"
							onClick={confirmPutBackOnSale}
						>
							Put back on sale
						</LegacyButton>
					</Tooltip>
					<Tooltip title={peopleSelected ? "Notify selected users from waitlist" : "Select people to notify"}>
						<LegacyButton
							size="large"
							style={{
								float: "left",
								marginRight: 8
							}}
							type="primary"
							disabled={!peopleSelected}
							aria-label="Offer tickets"
							onClick={handleOpenOfferModal}
						>
							Offer tickets
						</LegacyButton>
					</Tooltip>
					<Tooltip
						title={peopleSelected ? "Remove selected users from waitlist" : "Select people to remove from the waitlist"}
					>
						<LegacyButton
							size="large"
							onClick={confirmRemove}
							disabled={!peopleSelected}
							aria-label="remove from waitlist"
						>
							Remove
						</LegacyButton>
					</Tooltip>
				</div>
			</>
			<ManualOfferModal
				offerTo={selectedRowKeys}
				searchParams={{
					eventDateIds: search.eventDateIds,
					ticketIds: search.ticketIds,
					query: search.query,
					page: search.page
				}}
			/>
			<AddToWaitlistModal visible={addModelOpen} handleClose={closeAddModel} />
			<TicketsStatusModal />
		</LoadErrorView>
	);
};

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		waitlist: state.waitlist,
		search: state.search,
		overview: state.eventMetrics.overview
	}),
	{
		remove,
		openOfferModal,
		setSelected,
		putBackOnSale,
		openTicketSaleStatusModal
	}
)(ManageWaitlist);
