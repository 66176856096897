import Duplicate from "@/assets/ic_duplicate.svg";
import { Svg } from "@/components/Svg";
import copy from "copy-text-to-clipboard";
import { CSSProperties, FC, useRef, useState } from "react";
import { LegacyButton, ButtonProps } from "@/components/buttons/LegacyButton";

type CopyButtonProps = {
	ariaLabel: string;
	buttonText?: string;
	showIcon?: boolean;
	style?: CSSProperties;
	toCopy: string;
	type?: ButtonProps["type"];
};

const CopyButton: FC<CopyButtonProps> = ({
	ariaLabel,
	buttonText = "Copy to clipboard",
	showIcon,
	style = {},
	toCopy,
	type = "action"
}) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [displayText, setDisplayText] = useState(buttonText);

	const handleClick = () => {
		copy(toCopy);
		setDisplayText("Copied!");
		setTimeout(() => setDisplayText(buttonText), 2000);
	};

	return (
		<LegacyButton ariaLabel={ariaLabel} onClick={handleClick} innerRef={buttonRef as any} style={style} type={type}>
			{showIcon && displayText !== "Copied!" && <Svg margin="0 8px 0 0" src={Duplicate} />}
			<span>{displayText}</span>
		</LegacyButton>
	);
};

export default CopyButton;
