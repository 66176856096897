const Text = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0L24 0 24 24 0 24z" />
				<path id="ele-id-fill" fill="#67717E" d="M9 4v3h5v12h3V7h5V4H9zm-6 8h3v7h3v-7h3V9H3v3z" />
			</g>
		</svg>
	);
};

export default Text;
