import { useState } from "react";
import { ContentBlock } from "../../components/AppBlocks";
import PageHeading from "../../components/PageHeading";

//https://thenounproject.com/lonniusmax/
import d10 from "../../assets/dice/d10.svg";
import d12 from "../../assets/dice/d12.svg";
import d20 from "../../assets/dice/d20.svg";
import d4 from "../../assets/dice/d4.svg";
import d6 from "../../assets/dice/d6.svg";
import d8 from "../../assets/dice/d8.svg";

import { css } from "@emotion/css";
import { Input } from "@/ui/antd";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import H1 from "../../components/text/H1";
import { lastOfType } from "../../utils/CssUtils";

const diceIconLookup = {
	4: d4,
	6: d6,
	8: d8,
	10: d10,
	12: d12,
	20: d20
};

const diceIconStyle = css({
	width: 60,
	height: 60
});

const numberOfDiceInputStyle = css({
	maxWidth: 30,
	marginRight: 8
});

const diceWrapper = css({
	display: "flex",
	justifyContent: "left",
	alignItems: "center"
});

const rollHistoryItemWrapper = css({
	borderBottom: "1px solid #000000",
	margin: 0,
	padding: 2,
	[lastOfType()]: {
		borderBottom: "none"
	}
});

const mainWrapper = css({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: 8
});

const lastRollWrapper = css({
	backgroundColor: "#f1f1f3",
	border: "1px solid #dedede",
	borderRadius: 4,
	flexGrow: 2,
	marginLeft: 28,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center"
});

const LastRoll = ({ roll }) => {
	if (!roll) return null;
	const additionalInfo =
		roll.values.length !== 1 ? (
			<>
				<p>[{roll.values.join(",")}]</p>
				<p>Lowest: {roll.min}</p>
				<p>Highest: {roll.max}</p>
			</>
		) : null;

	return (
		<>
			<H1>You rolled: {roll.value}</H1>
			{additionalInfo}
			<img src={diceIconLookup[roll.sides]} className={diceIconStyle} alt="" />
		</>
	);
};

const RollHistoryItem = ({ roll }) => {
	const additionalInfo =
		roll.values.length !== 1 ? (
			<>
				, highest: {roll.max}, lowest: {roll.min}
				,values: [{roll.values.join(",")}]
			</>
		) : null;
	return (
		<p className={rollHistoryItemWrapper}>
			You rolled {roll.numberOfDice}xD{roll.sides}, and got <b>{roll.value}</b>
			{additionalInfo}
		</p>
	);
};

const Die = ({ roll, sides }) => {
	const [numberOfDice, setNumberOfDice] = useState(1);
	return (
		<div className={diceWrapper}>
			<img src={diceIconLookup[sides]} className={diceIconStyle} alt="" />
			<Input
				onChange={(e) => setNumberOfDice(Number(e.target.value))}
				type="number"
				min={1}
				defaultValue={1}
				className={numberOfDiceInputStyle}
			/>
			<LegacyButton onClick={() => roll(sides, numberOfDice)}>Roll!</LegacyButton>
		</div>
	);
};

const DND = () => {
	const [pastRolls, addPastRoll] = useState([]);

	const roll = (sides, numberOfDice) => {
		const result = {
			sides,
			numberOfDice,
			values: []
		};
		for (let i = 0; i < numberOfDice; i++) {
			result.values.push(Math.floor(Math.random() * sides) + 1);
		}
		result.min = Math.min(...result.values);
		result.max = Math.max(...result.values);
		result.value = result.values.reduce((partialSum, a) => partialSum + a, 0);
		addPastRoll([...pastRolls, result]);
	};

	return (
		<>
			<PageHeading title="Dungeons and Dragons - HumaniDice" />
			<ContentBlock>
				<div className={mainWrapper}>
					<div>
						<Die roll={roll} sides={4} />
						<Die roll={roll} sides={6} />
						<Die roll={roll} sides={8} />
						<Die roll={roll} sides={10} />
						<Die roll={roll} sides={12} />
						<Die roll={roll} sides={20} />
					</div>
					<div className={lastRollWrapper}>
						<LastRoll roll={pastRolls[pastRolls.length - 1]} />
					</div>
				</div>
				{pastRolls
					.slice()
					.reverse()
					.map((roll) => (
						<RollHistoryItem roll={roll} />
					))}
			</ContentBlock>
		</>
	);
};

export default DND;
