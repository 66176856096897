import { getConfig } from "@/config";
import { css } from "@emotion/css";
import { Button, Tooltip } from "@/ui/antd";
import { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import AddField from "../../components/AddField";
import { CopyInputField, LegacyInputField, RichTextAreaField } from "../../components/Fields";
import { H4 } from "../../components/text/Text";
import { checkLength, required } from "../../utils/Validators";
import LinkedInProfileTypes from "./LinkedInProfileTypes";
import { formatField } from "./utils";

const organiserNameRequired = required("A host name is required");
const organiserNameMaxLength = checkLength("Host name should be 60 characters or less", 60, "<=");

interface HiddenField {
	name: string;
	label: string;
	placeholder: string;
	addOnBefore?: string;
}

const supportedHiddenFields: HiddenField[] = [
	{
		name: "website",
		label: "Website",
		placeholder: "www.yourcompany.com"
	},
	{
		name: "facebook",
		label: "Facebook",
		placeholder: "username",
		addOnBefore: "https://facebook.com/"
	},
	{
		name: "twitter",
		label: "Twitter",
		placeholder: "username",
		addOnBefore: "https://twitter.com/"
	},
	{
		name: "instagram",
		label: "Instagram",
		placeholder: "username",
		addOnBefore: "https://instagram.com/"
	},
	{
		name: "linkedIn",
		label: "LinkedIn",
		placeholder: "username"
	},
	{
		name: "spotify",
		label: "Spotify",
		placeholder: "link",
		addOnBefore: "https://open.spotify.com/"
	},
	{
		name: "soundCloud",
		label: "SoundCloud",
		placeholder: "www.soundcloud.com/...",
		addOnBefore: "https://soundcloud.com/"
	},
	{
		name: "tikTok",
		label: "TikTok",
		placeholder: "@username",
		addOnBefore: "https://tiktok.com/"
	}
];

const formFieldWrapper = css({
	".ant-input-group-addon": {
		width: "200px !important",
		textAlign: "left",
		".ant-select-selection-selected-value": {
			marginRight: "8px"
		}
	}
});

const flexRows = css({
	display: "flex",
	flexDirection: "row"
});

const hostUrlWrapper = css({
	maxWidth: "100%",
	width: "100%",
	flex: 1
});

const fullWidth = css({
	maxWidth: "100%",
	width: "100%"
});

const generateButtonStyle = css({
	alignSelf: "flex-end",
	marginBottom: 16
});

const OrganiserFormFields = ({
	slug = "",
	onNameChange,
	onGenerate,
	slugError,
	linkedInProfileType,
	onLinkedProfileTypeChange,
	values
}: {
	slug: string;
	onNameChange?: () => void;
	onGenerate: () => void;
	slugError?: string;
	linkedInProfileType: string;
	onLinkedProfileTypeChange: (value: string) => void;
	name?: string;
	values: { [key: string]: string };
}) => {
	const fullUrl = `${getConfig("TICKETING_URL")}/host/${slug}`;

	const [displayedFields, setDisplayedFields] = useState(supportedHiddenFields.filter((field) => values?.[field.name]));
	const [hiddenFields, setHiddenFields] = useState(supportedHiddenFields.filter((field) => !values?.[field.name]));

	const _handleAddFieldClicked = useCallback(
		(field: HiddenField) => {
			setDisplayedFields((curr) => [...curr, ...[field]]);
			setHiddenFields((curr) => curr.filter((item) => item.name !== field.name));
		},
		[setDisplayedFields, setHiddenFields]
	);

	return (
		<div className={formFieldWrapper}>
			<Field
				name="name"
				label="Name"
				component={LegacyInputField}
				required
				validate={[organiserNameRequired, organiserNameMaxLength]}
				onChange={onNameChange}
			/>
			<div className={flexRows}>
				<div className={hostUrlWrapper}>
					<Field
						label="Host URL"
						name="slug"
						className={fullWidth}
						fullUrl={fullUrl}
						component={CopyInputField}
						addonBefore={`${getConfig("TICKETING_URL")}/host/`}
					/>
					{slugError && (
						<>
							{slugError.split(".").map((sentence) => (
								<div className="ant-form-explain">{sentence}</div>
							))}
						</>
					)}
				</div>
				{onGenerate ? (
					<Tooltip title="Generate host URL from host name">
						<Button className={generateButtonStyle} onClick={onGenerate}>
							Generate
						</Button>
					</Tooltip>
				) : null}
			</div>
			<br />
			<Field name="description" label="Description" component={RichTextAreaField} />

			<br />

			<H4>Add links to your host profile</H4>
			{displayedFields?.map((field) => (
				<Field
					key={field.name}
					name={field.name}
					label={field.label}
					component={LegacyInputField}
					placeholder={field.placeholder}
					addonBefore={
						field.name === "linkedIn" ? (
							<LinkedInProfileTypes
								key={linkedInProfileType}
								selected={linkedInProfileType}
								callback={onLinkedProfileTypeChange}
							/>
						) : (
							field.addOnBefore
						)
					}
					format={formatField(field.name)}
				/>
			))}
			{hiddenFields?.map((field) => (
				<AddField key={field.name} label={field.label} onClickCallback={() => _handleAddFieldClicked(field)} />
			))}
			<br />
		</div>
	);
};

// using any here because Redux store is not typed yet
export default connect((state: any) => {
	return {
		values: state.organisers.selected
	};
})(OrganiserFormFields);
