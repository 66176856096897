import { useEffect } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { SelectField } from "@/components/Fields";
import { findOrganisers } from "@/state/actions/organiserActions";
let searchTimeout;

const Hosts = ({ setIsOrganiserModalOpen, isOrganiserModalOpen, organisers, findOrganisers, event }) => {
	const debouncedLoad = (query) => {
		clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			findOrganisers(query, null, null, event?.userId);
		}, 200);
	};

	useEffect(() => {
		findOrganisers(null, null, null, event?.userId);
	}, []);

	const organisersData =
		organisers?.organisers?.map((organiser) => {
			return {
				value: organiser._id,
				label: organiser.name
			};
		}) || [];

	const noHostOption = {
		value: "",
		label: <b>No host profile</b>
	};

	const createNewHostOption = {
		label: <b>Create new host</b>,
		value: "CREATE_NEW_HOST"
	};

	const options = organisers.loading ? [] : organisersData.concat([noHostOption, createNewHostOption]);

	return (
		<>
			<hr />
			<Field
				name="organiserId"
				label="Host profile"
				description="Let your guests know who's running the event."
				component={SelectField}
				onSearch={(query) => debouncedLoad(query)}
				showSearch
				options={options}
				onSelect={(evt) => {
					if (evt === "CREATE_NEW_HOST") setIsOrganiserModalOpen();
				}}
				style={{
					display: isOrganiserModalOpen ? "none" : null,
					maxWidth: 300,
					width: "100%"
				}}
				notFoundContentLoading={organisers.loading}
			/>
		</>
	);
};

export default connect(
	(state) => {
		const event = state?.currentEvent?.event || { dates: [] };
		return {
			event,
			organisers: state.organisers
		};
	},
	{ findOrganisers }
)(Hosts);
