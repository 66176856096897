import { useQRDownload } from "@/hooks/useQRDownload";
import { Button, CopyField, Grid, ResponsiveModal, Spacer } from "@/ui";
import { Flex } from "@/ui/antd";
import { QrcodeOutlined } from "@ant-design/icons";
import { CheckCircle } from "@phosphor-icons/react";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";

type ShareCollectionModalProps = {
	collectionURL: string;
	onCloseClicked: () => void;
	open: boolean;
	showYourCollectionIsLive?: boolean;
};

export const ShareCollectionModal: FC<ShareCollectionModalProps> = ({
	collectionURL,
	onCloseClicked,
	open,
	showYourCollectionIsLive = false
}) => {
	const { collectionId } = useParams();
	const navigate = useNavigate();

	const qrCode = useQRDownload({
		fileName: "humanitix-collection-qr",
		size: 1000,
		url: collectionURL
	});

	const handleGotoWidgetsClicked = () => {
		onCloseClicked();
		navigate(`../${collectionId}/widget`);
	};

	return (
		<>
			<ResponsiveModal
				aria-label="Share collection modal"
				closable
				compactHeader
				footer={null}
				header={showYourCollectionIsLive ? "" : "Share"}
				onCancel={onCloseClicked}
				open={open}
				width="524px"
			>
				{showYourCollectionIsLive && (
					<Flex vertical align="center">
						<CheckCircle size={48} color="#008257" weight="fill" />
						<Spacer size="10px" />
						<h1 style={{ margin: 0, fontSize: 20, fontWeight: 700 }}>Your collection is live!</h1>
						<Spacer size="24px" />
					</Flex>
				)}
				<CopyField displayValue={collectionURL} />
				<Spacer size="24px" />
				<Grid gap="16px" rowMin="0">
					<Button iconLeft={<QrcodeOutlined />} onClick={qrCode.download} variant="tertiary">
						Download QR
					</Button>
					<Button onClick={handleGotoWidgetsClicked} variant="tertiary">
						Generate widget code
					</Button>
				</Grid>
			</ResponsiveModal>
			{qrCode.renderElement}
		</>
	);
};
