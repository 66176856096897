import { Field } from "redux-form";
import { LegacyInputField, TextAreaField } from "@/components/Fields";
import { url } from "@/utils/Validators";

const onlineUrl = url("A must be a valid url address");
const OnlineInfo = () => {
	return (
		<>
			<Field
				name="eventLocation.onlineUrl"
				label="Online meeting URL (Public)"
				description="A publicly available link where attendees can watch/join your event. Leave blank if you require attendees to register before they get access."
				component={LegacyInputField}
				validate={onlineUrl}
			/>
			<Field
				name="eventLocation.instructions"
				label="Instructions"
				description="For example: Online event is held via Zoom. Access link will be delivered via email one day prior to the event."
				component={TextAreaField}
				hinttext="Instructions"
			/>
		</>
	);
};

export default OnlineInfo;
