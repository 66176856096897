import { CodeBlock, RadioGroup } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC, useState } from "react";

const props = `type RadioGroupProps<T extends string | number> = {
  buttonStyle?: "outline" | "solid";
  defaultValue?: T;
  onChange?: (value: T) => void;
  options: { label: string; value: T; disabled?: boolean }[];
  style?: CSSProperties;
  value?: T;
};`;

const sample = `import { RadioGroup } from "@/ui";

const [selected, setSelected] = useState("1");

<RadioGroup
  defaultValue="1"
  onChange={(value) => setSelected(value)}
  value={selected}
  options={[
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" }
  ]}
/>
`;

export const RadioGroupPage: FC = () => {
	const [selected, setSelected] = useState("1");

	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<RadioGroup
				defaultValue="1"
				onChange={(value) => setSelected(value)}
				value={selected}
				options={[
					{ label: "Option 1", value: "1" },
					{ label: "Option 2", value: "2" }
				]}
			/>
			<b>Outline</b>
			<RadioGroup
				buttonStyle="outline"
				defaultValue="1"
				onChange={(value) => setSelected(value)}
				value={selected}
				options={[
					{ label: "Option 1", value: "1" },
					{ label: "Option 2", value: "2" }
				]}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
