import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";

const plusMore = css`
	font-weight: 600;
	color: #196097;
`;

interface DatesListDisplayProps {
	dates: {
		startDate: string | Date;
		disabled?: boolean;
	}[];
	upcoming?: boolean;
	onDateLinkClicked?: () => void;
	timezone: string;
	location?: string;
}

const DatesListDisplay = ({ dates, upcoming, onDateLinkClicked, timezone, location }: DatesListDisplayProps) => {
	if (!dates) {
		return null;
	}
	let activeDates = dates.filter((date) => !date.disabled);
	let datesInOrder;
	let date;
	if (!upcoming) {
		datesInOrder = activeDates.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
		date = datesInOrder[0];
	} else {
		activeDates = activeDates.filter((date) => date.startDate > new Date().toISOString());
		datesInOrder = activeDates.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
		date = datesInOrder[0];
	}
	if (!date) {
		date = activeDates.length ? activeDates[0] : dates[0];
	}

	const dateStr = date ? new HumaniDate(date.startDate, timezone, location).formatting.dateTime() : "";

	let dateCount;
	if (datesInOrder.length > 1) {
		dateCount = onDateLinkClicked ? (
			<a onClick={onDateLinkClicked} className={plusMore}>
				+ {datesInOrder.length - 1} more
			</a>
		) : (
			<span className={plusMore}>+ {datesInOrder.length - 1} more</span>
		);
	}
	return (
		<span data-cy="event-header-date-str">
			{dateStr} {dateCount}
		</span>
	);
};
export default DatesListDisplay;
