export const TIME_EXPIRED = "TIME_EXPIRED";

export const GET_TICKETS_REQUEST = "GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_ERROR = "GET_TICKETS_ERROR";

export const GET_ORDER_REQ_REQUEST = "GET_ORDER_REQ_REQUEST";
export const GET_ORDER_REQ_SUCCESS = "GET_ORDER_REQ_SUCCESS";
export const GET_ORDER_REQ_ERROR = "GET_ORDER_REQ_ERROR";

export const SAVE_ORDER_DATA_REQUEST = "SAVE_ORDER_DATA_REQUEST";
export const SAVE_ORDER_DATA_SUCCESS = "SAVE_ORDER_DATA_SUCCESS";
export const SAVE_ORDER_DATA_ERROR = "SAVE_ORDER_DATA_ERROR";

export const ORDER_CONTAINS_SEATING_MAP = "ORDER_CONTAINS_SEATING_MAP";
