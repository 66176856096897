import { Accordion, FormItemRow, FormLabel, HR, RadioGroup, Spacer } from "@/ui";
import { Alert, Form, FormInstance, Input, Select } from "@/ui/antd";
import { HelpIds, OpenHelp } from "@/utils/Help";
import styled from "@emotion/styled";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import { FC } from "react";

const StyledAlert = styled(Alert)<{ open?: boolean }>`
	&.ant-alert {
		align-items: flex-start;
		box-sizing: border-box;
		${(props) => `
			max-height: ${props.open ? "200px" : "0px"};
			padding-top: ${props.open ? "" : "0px"};
			padding-bottom: ${props.open ? "" : "0px"};
			overflow: hidden;
			transition: all ease-in-out 0.3s;
		`}
	}

	.ant-alert-icon {
		margin-top: 4px;
	}
`;

type ConfigPanelSettingsProps = {
	form: FormInstance;
	open: boolean;
};

export const ConfigPanelSettings: FC<ConfigPanelSettingsProps> = ({ form, open }) => {
	const formValues = useWatch<Collection>([], form);

	const hasBothGtmAndGa = !!formValues?.analytics?.googleAnalyticsId && !!formValues?.analytics?.googleTagManagerId;

	return (
		<div style={{ display: open ? "block" : "none" }}>
			<FormLabel size={16}>Privacy</FormLabel>
			{/* PRIVACY */}
			<Form.Item name={["public"]}>
				<RadioGroup
					options={[
						{ label: "Public", value: true },
						{ label: "Private", value: false }
					]}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					value={formValues?.public as any}
				/>
			</Form.Item>
			{formValues?.public ? (
				<p>
					Your collection <strong>will</strong> be indexed. This means users can find it via{" "}
					<a href="https://humanitix.com" target="_blank" rel="noreferrer">
						Humanitix.com
					</a>{" "}
					and search engines like Google.
				</p>
			) : (
				<p>
					Your collection <strong>will not</strong> be indexed. This means it will not be visible via{" "}
					<a href="https://humanitix.com" target="_blank" rel="noreferrer">
						Humanitix.com
					</a>{" "}
					and search engines like Google.
				</p>
			)}
			<Spacer size="sm" />
			{/* PRIVACY LEVEL */}
			<FormItemRow>
				<FormLabel tooltip="Include public and/or private events in your collection. If you select events that do not satisfy this setting, they will not be displayed.">
					Events privacy level
				</FormLabel>
				<Form.Item name={["events", "privacyLevel"]} noStyle style={{ display: "flex", flexDirection: "column" }}>
					<Select
						style={{ minWidth: "140px" /* , flexShrink: 1 */ }}
						value={formValues?.events.privacyLevel}
						options={[
							{ value: "public", label: "Public" },
							{ value: "private", label: "Private" },
							{ value: "all", label: "All" }
						]}
					/>
				</Form.Item>
			</FormItemRow>
			<HR noMargin />
			<Accordion
				defaultOpen={true}
				panels={[
					// ANALYTICS INTEGRATIONS
					{
						header: <FormLabel size={16}>Analytics integrations</FormLabel>,
						key: "1",
						children: (
							<>
								<StyledAlert
									open
									message="Need help?"
									description={
										<>
											See our <a onClick={() => OpenHelp(HelpIds.analysingEventTraffic)}>Analytics Integrations</a> and{" "}
											<a onClick={() => OpenHelp(HelpIds.advanceWidgetGuide)}>Advanced Widget Guides.</a>
										</>
									}
									showIcon
									type="info"
								/>
								<br />
								{/* GOOGLE ANALYTICS */}
								<FormLabel>Google Analytics ID</FormLabel>
								<p>Track your event through Google Analytics</p>
								<Form.Item name={["analytics", "googleAnalyticsId"]} style={{ marginBottom: "4px" }}>
									<Input placeholder="G-XXXXXXXXXX" maxLength={30} />
								</Form.Item>
								<br />
								{/* GOOGLE TAG MANAGER */}
								<FormLabel>Google Tag Manager ID</FormLabel>
								<p>Track your event using Google Tag Manager</p>
								<Form.Item name={["analytics", "googleTagManagerId"]} style={{ marginBottom: "8px" }}>
									<Input placeholder="GTM-XXXX" maxLength={30} />
								</Form.Item>
								<StyledAlert
									message={
										<>
											<strong>Are you sure?</strong>
											<br />
											Streamline your Google Analytics Tag integration through Google Tag Manager instead. Remove your
											Google Analytics ID to prevent duplicate events.
										</>
									}
									open={hasBothGtmAndGa}
									showIcon
									type="warning"
								/>

								<br />
								{/* FACEBOOK PIXEL */}
								<FormLabel>Meta Pixel ID</FormLabel>
								<p>Track your event using Meta Pixel</p>
								<Form.Item name={["analytics", "facebookPixelId"]} style={{ marginBottom: "4px" }}>
									<Input placeholder="XXXXXXXXXXXXXXX" maxLength={30} />
								</Form.Item>
							</>
						)
					}
				]}
			/>
		</div>
	);
};
