import { css } from "@emotion/css";
import { Field } from "redux-form";
import { InputNumberField } from "@/components/Fields";
import { P } from "@/components/text/Text";
import { percentFormat, percentParse } from "@/utils/InputFormatters";

const splitOption = css({
	background: "#f9f9fa",
	padding: "12px 12px 12px 12px",
	marginRight: 12,
	display: "inline-block"
});

const TipOptions = ({ path }) => {
	return (
		<>
			<P>Give your attendees the choice to cover your fees</P>
			<div className={splitOption}>
				<Field
					name={`${path}.tipPercent`}
					component={InputNumberField}
					formatter={percentFormat}
					parser={percentParse}
					label="Percent"
					description={
						<>
							How much of your fees would you like to offer
							<br />
							to your attendees to cover
						</>
					}
					style={{ marginRight: 12 }}
				/>
			</div>
		</>
	);
};

export default TipOptions;
