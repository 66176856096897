import accessCodesIcon from "@/assets/reports/ic-access-codes.svg";
import addOnsIcon from "@/assets/reports/ic-add-ons.svg";
import additionalDonationIcon from "@/assets/reports/ic-additional-donations.svg";
import guestListIcon from "@/assets/reports/ic-attendee-door-list.svg";
import attendeeIcon from "@/assets/reports/ic-attendee-summary.svg";
import discountIcon from "@/assets/reports/ic-discount-report.svg";
import eventSalesUpdateIcon from "@/assets/reports/ic-event-sales-update.svg";
import eventIcon from "@/assets/reports/ic-event-summary.svg";
import giftCardIcon from "@/assets/reports/ic-gift-card.svg";
import invoiceIcon from "@/assets/reports/ic-invoice.svg";
import orderIcon from "@/assets/reports/ic-order-report.svg";
import payoutIcon from "@/assets/reports/ic-payouts-report.svg";
import earningsTicketIcon from "@/assets/reports/ic-sales-by-ticket-type.svg";
import topPurchasersIcon from "@/assets/reports/ic-top-purchaser.svg";
import Page from "@/components/Page";
import { css } from "@emotion/css";
import { Flex } from "@/ui/antd";
import { Link } from "react-router-dom";

const styles = {
	items: css({
		alignItems: "center",
		backgroundColor: "var(--surface1)",
		borderRadius: 8,
		display: "flex",
		flex: "1 0 40%",
		height: 58,
		minWidth: "280px",
		padding: "5px 16px 8px",
		textAlign: "left",
		transition: "border 0.2s",
		"&:hover": {
			outline: "var(--border1-hover)"
		},
		"@media (max-width: 600px)": {
			maxWidth: "100%"
		}
	}),
	itemTitle: css({
		fontWeight: 600,
		color: "#323232"
	}),
	icons: css({
		width: 30,
		height: 30,
		marginRight: 25,
		"@media(max-width: 600px)": {
			marginRight: 17
		}
	})
};

const items = [
	{
		alt: "Attendee details icon",
		icon: attendeeIcon,
		link: "/console/reports/attendee-details",
		title: "Attendee details"
	},
	{
		alt: "Orders icon",
		icon: orderIcon,
		link: "/console/reports/orders",
		title: "Orders"
	},
	{
		alt: "Event summary icon",
		icon: eventIcon,
		link: "/console/reports/events",
		title: "Event summary"
	},
	{
		alt: "Earnings by ticket type icon",
		icon: earningsTicketIcon,
		link: "/console/reports/earnings-by-ticket-type",
		title: "Earnings by ticket type"
	},
	{
		alt: "Discounts icon",
		icon: discountIcon,
		link: "/console/reports/discounts",
		title: "Discounts"
	},
	{
		alt: "access-codes icon",
		icon: accessCodesIcon,
		link: "/console/reports/access-codes",
		title: "Access codes"
	},
	{
		alt: "top-purchasers icon",
		icon: topPurchasersIcon,
		link: "/console/reports/top-purchasers",
		title: "Top purchasers"
	},
	{
		alt: "payouts icon",
		icon: payoutIcon,
		link: "/console/reports/payouts",
		title: "Payouts"
	},
	{
		alt: "event-sales-update icon",
		icon: eventSalesUpdateIcon,
		link: "/console/reports/event-sales-update",
		title: "Event sales update"
	},
	{
		alt: "guest-list icon",
		icon: guestListIcon,
		link: "/console/reports/guest-list",
		title: "Guest list"
	},
	{
		alt: "Paid by invoice icon",
		icon: invoiceIcon,
		link: "/console/reports/paid-by-invoice",
		title: "Paid by invoice"
	},
	{
		alt: "Gift cards icon",
		icon: giftCardIcon,
		link: "/console/reports/gift-cards",
		title: "Gift cards"
	},
	{
		alt: "add-ons icons",
		icon: addOnsIcon,
		link: "/console/reports/add-ons",
		title: "Add-ons"
	},
	{
		alt: "additional-donations icon",
		icon: additionalDonationIcon,
		link: "/console/reports/additional-donations",
		title: "Additional donations"
	}
];

const ReportsList = () => {
	return (
		<Page pageName="Reports" type="details">
			<p>Choose a report to view and export.</p>
			<Flex gap="sm" wrap>
				{items.map((item) => (
					<Link key={item.title} className={styles.items} to={item.link}>
						<img alt={item.alt} src={item.icon} className={styles.icons} />
						<div className={styles.itemTitle}>{item.title}</div>
					</Link>
				))}
			</Flex>
		</Page>
	);
};

export default ReportsList;
