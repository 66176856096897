import filenamify from "filenamify/browser";

const FileNameService = {
	generateFileName(prefix, fileExtension, event, selectedDate) {
		if (!event) {
			let fileName = prefix + fileExtension;
			fileName = filenamify(fileName, { replacement: "" });
			fileName = fileName.replace(/\s/g, "");
			return fileName;
		}
		const eventName = event.name;
		let fileName = prefix ? `${prefix}-${eventName}` : eventName;
		if (selectedDate) {
			event.dates.forEach((date) => {
				if (selectedDate === date._id) {
					fileName = `${fileName}-${date.display.date}-${date.display.time}`;
				}
			});
		}
		fileName = filenamify(fileName, { replacement: "" });
		fileName = fileName.replace(/\s/g, "");
		fileName = `${fileName}${fileExtension}`;
		return fileName;
	}
};

export default FileNameService;
