const ArrowDownTail = ({ rotate = 0 }) => {
	return (
		<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
			<g
				id="Map-builder_assigned-seating"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				transform={`rotate(${rotate} 11 11)`}
			>
				<g id="Change-seat-(2)" transform="translate(-1004.000000, -829.000000)">
					<g id="Seat-info-changed" transform="translate(830.000000, 598.000000)">
						<g id="ic_arrow_downward" transform="translate(169.000000, 226.000000)">
							<g id="Icon-24px">
								<polygon id="Shape" points="0 0 32 0 32 32 0 32" />
								<polygon
									id="Shape"
									fill="#353337"
									points="26.6666667 16 24.7866667 14.12 17.3333333 21.56 17.3333333 5.33333333 14.6666667 5.33333333 14.6666667 21.56 7.22666667 14.1066667 5.33333333 16 16 26.6666667"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ArrowDownTail;
