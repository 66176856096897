import { LegacyButton } from "@/components/buttons/LegacyButton";
import { withAuthHooks } from "@/hoc/withAuthHooks";
import { Modal, Radio, Select } from "@/ui/antd";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../components/AppBlocks";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import PageHeading from "../components/PageHeading";
import TableActions from "../components/table/TableActions";
import { H4, P } from "../components/text/Text";
import {
	clearAuthorization,
	deleteAuthorization,
	getAuthorizations,
	getAuthorizationsForUser,
	loadAuthorization,
	resendEmail
} from "../state/actions/authorizationActions";
import { max } from "../utils/CssUtils";
import { HelpIds } from "../utils/Help";
import AuthorizationModal from "./AuthorizationModal";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const confirm = Modal.confirm;

const statusOptions = css`
	.ant-btn {
		padding-bottom: 5px !important;
		display: flex;
		margin: 24px 0px !important;
		@media (max-width: 600px) {
			float: none;
			margin: 16px 12px 16px 0;
			height: 36px !important;
		}
	}
`;

const buttonWrapper = css({
	[max(768)]: {
		width: "100%"
	}
});

class Authorization extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.load = this.load.bind(this);
		this.handleSharePermissions = this.handleSharePermissions.bind(this);
		this.searchUpdate = this.searchUpdate.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.permissionSelect = this.permissionSelect.bind(this);
		this.statusUpdate = this.statusUpdate.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.attemptDelete = this.attemptDelete.bind(this);
		this.deleteCancelled = this.deleteCancelled.bind(this);
		this.reissueEmail = this.reissueEmail.bind(this);
		this.onPermissionListingChange = this.onPermissionListingChange.bind(this);
		this.state = {
			modalVisible: false,
			issuedAuths: true,
			verifyDelete: false,
			idToDelete: "",
			unregistered: false,
			permissionListingFor: "self"
		};
	}

	componentDidMount() {
		this.load();
	}
	componentWillUnmount() {
		this.props.clearAuthorization();
	}

	componentWillReceiveProps(nextProps) {
		const { authorization } = this.props;
		if (nextProps.authorization.deleted && !authorization.deleted) {
			this.load();
		}
	}

	save(values) {
		const { updateUser, isAdmin } = this.props;
		if (isAdmin) return;
		updateUser(values);
	}

	load(issued) {
		const issuedAuths = issued !== undefined ? issued : this.state.issuedAuths;
		const { permissionListingFor } = this.state;
		const { isAdmin, getAuthorizations, getAuthorizationsForUser, userId, userLocation } = this.props;

		isAdmin
			? getAuthorizationsForUser(userId, userLocation, issuedAuths, issuedAuths ? permissionListingFor : "self")
			: getAuthorizations(issuedAuths, issuedAuths ? permissionListingFor : "self");
	}

	searchUpdate() {
		null;
	}

	onDelete() {
		const { isAdmin, deleteAuthorization } = this.props;
		const { idToDelete, unregistered } = this.state;
		if (isAdmin) return;
		deleteAuthorization(idToDelete, unregistered);
	}

	attemptDelete(id, unregistered = false) {
		const { isAdmin } = this.props;
		if (isAdmin) return;
		this.setState({
			idToDelete: id,
			verifyDelete: true,
			unregistered
		});
		confirm({
			title: "Delete permission?",
			content: "Are you sure you want to delete this permission?",
			onOk: this.onDelete,
			onCancel: this.deleteCancelled
		});
	}

	reissueEmail(id) {
		const { isAdmin, resendEmail } = this.props;
		if (isAdmin) return;
		resendEmail(id);
	}

	deleteCancelled() {
		this.setState({ idToDelete: "", verifyDelete: false, unregistered: false });
	}

	permissionSelect(id, unregistered) {
		this.props.loadAuthorization(id, unregistered);
		this.setState({ modalVisible: true });
	}

	handleSharePermissions() {
		this.setState({ modalVisible: true });
	}
	handleCancel() {
		this.setState({ modalVisible: false });
		this.props.clearAuthorization();
		this.load();
	}

	statusUpdate() {
		const issuedAuths = !this.state.issuedAuths;
		this.setState({ issuedAuths });
		this.load(issuedAuths);
	}

	onPermissionListingChange(val) {
		const issuedAuths = this.state.issuedAuths;
		this.setState({ permissionListingFor: val }, () => this.load(issuedAuths));
	}

	render() {
		const { isAdmin } = this.props;
		const { loading, error, authorizations, sharedAccounts } = this.props.authorization;
		const { issuedAuths, modalVisible, permissionListingFor } = this.state;
		const options = [{ value: "self", label: "Yourself" }].concat(
			sharedAccounts.map((account) => {
				return { value: account.id, label: `${account.firstName} ${account.lastName}` };
			})
		);
		return (
			<div>
				<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
					<PageHeading
						title="Permissions"
						helpButton={{
							link: HelpIds.myTeam,
							title: "Help - Permissions"
						}}
					/>
					{!isAdmin && (
						<AuthorizationModal
							visible={modalVisible && !isAdmin}
							handleCancel={this.handleCancel}
							permissionListingFor={permissionListingFor}
						/>
					)}
					<ContentBlock style={{ position: "relative" }}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								flexWrap: "wrap"
							}}
						>
							<P style={{ maxWidth: 435 }}>
								Share and receive permissions with new or existing users to help manage your event without sharing your
								login information. When sharing you have full control over the permissions you give to other users.
							</P>
							<div className={buttonWrapper}>
								<LegacyButton
									onClick={this.handleSharePermissions}
									type="action"
									ariaLabel="Share permissions"
									style={{ marginBottom: 12, width: "100%" }}
									disabled={isAdmin}
								>
									Share permissions
								</LegacyButton>
							</div>
						</div>
						<div style={{ marginBottom: 12 }}>
							<RadioGroup
								buttonStyle="solid"
								defaultValue="issued"
								onChange={this.statusUpdate}
								className={statusOptions}
							>
								<RadioButton value="issued">Shared</RadioButton>
								<RadioButton value="received">Received</RadioButton>
							</RadioGroup>
						</div>
						{options.length > 1 && issuedAuths ? (
							<div style={{ marginBottom: 12 }}>
								<div>View permissions shared by...</div>
								<Select
									style={{ width: 200 }}
									defaultValue="self"
									onChange={this.onPermissionListingChange}
									options={options}
								/>
							</div>
						) : null}
						{issuedAuths
							? (authorizations || []).map((auth) => {
									return issuerCard({
										onClick: this.permissionSelect,
										onDelete: this.attemptDelete,
										onReissueEmail: this.reissueEmail,
										...auth,
										isAdmin
									});
							  })
							: (authorizations || []).map((auth) => {
									return providedCard({
										...auth,
										onDelete: this.attemptDelete,
										isAdmin
									});
							  })}
					</ContentBlock>
				</LoadErrorView>
			</div>
		);
	}
}

const issuerCard = (data) => {
	const { firstName, lastName, email, onClick, permissions, onDelete, onReissueEmail, unregistered, isAdmin } = data;
	const { fullAccess, eventAccess, _id } = permissions;
	const wrapper = css({
		h4: {
			display: "inline-block",
			margin: 0
		},
		p: {
			display: "inline-block",
			margin: 0,
			paddingLeft: 8
		},
		paddingTop: 24,
		borderTop: "1px solid #dedede",
		position: "relative"
	});
	const nameWrapper = css({
		marginBottom: 12,
		p: {
			color: "#000"
		}
	});
	const infoWrapper = css({
		marginBottom: 8,
		h4: {
			verticalAlign: "top"
		}
	});
	const status = css({
		span: {
			color: unregistered ? "#eaa200" : "#157e77"
		},
		marginBottom: 60,
		"@media(max-width: 750px)": {
			marginBottom: 16
		}
	});
	const statusCol = css({
		position: "absolute",
		top: 24,
		right: 0,
		"@media(max-width: 750px)": {
			position: "relative",
			top: "auto",
			right: "auto",
			marginTop: 20
		}
	});
	const actions = css({
		display: isAdmin ? "none" : "inline-block",
		float: "right",
		"@media(max-width: 750px)": {
			position: "absolute",
			bottom: -6,
			right: -8
		}
	});
	return (
		<div key={_id} className={wrapper}>
			<div>
				<div className={nameWrapper}>
					<H4>Shared with:</H4>
					<P>
						{firstName} {lastName}
					</P>
				</div>
				<div className={infoWrapper}>
					<H4>Email:</H4>
					<P>{email}</P>
				</div>
				<div className={infoWrapper}>
					<H4>Access:</H4>
					<P>{fullAccess ? "Full Access" : eventAccess.access.join(", ")}</P>
				</div>
				<div className={infoWrapper}>
					<H4>Events:</H4>
					<P>
						{fullAccess
							? "All events"
							: eventAccess.events.length > 0
							? eventAccess.events.map((eventName) => eventName.name).join(", ")
							: "All Events"}
					</P>
				</div>
			</div>
			<div className={statusCol}>
				<div className={status}>
					Status: <span>{unregistered ? "Pending" : "Accepted"}</span>
				</div>
				<div className={actions}>
					{unregistered ? (
						<IconButton
							icon="refresh"
							onClick={() =>
								onReissueEmail(_id, {
									...permissions,
									email
								})
							}
							tooltip="Resend Email"
							showTooltip
							ariaLabel="Resend email"
						/>
					) : null}
					<IconButton
						icon="edit"
						onClick={() => onClick(_id, unregistered)}
						tooltip="Edit"
						showTooltip
						ariaLabel="Edit"
					/>
					<IconButton
						icon="delete"
						onClick={() => onDelete(_id, unregistered)}
						tooltip="Delete"
						showTooltip
						ariaLabel="Delete"
					/>
				</div>
			</div>
		</div>
	);
};

const providedCard = ({ issuerName, email, eventAccess, fullAccess, _id, onDelete, isAdmin }) => {
	const wrapper = css({
		h4: {
			display: "inline-block",
			margin: 0
		},
		p: {
			display: "inline-block",
			margin: 0,
			paddingLeft: 8
		},
		paddingTop: 24,
		borderTop: "1px solid #dedede",
		position: "relative"
	});
	const nameWrapper = css({
		marginBottom: 12,
		p: {
			color: "#000"
		}
	});
	const infoWrapper = css({
		marginBottom: 8
	});
	const status = css({
		marginBottom: 80,
		"@media(max-width: 750px)": {
			marginBottom: 16
		}
	});
	const statusCol = css({
		position: "absolute",
		top: 24,
		right: 0,
		"@media(max-width: 750px)": {
			position: "relative",
			top: "auto",
			right: "auto"
		}
	});
	const actions = css({
		display: isAdmin ? "none" : "inline-block",
		"@media(max-width: 750px)": {
			position: "absolute",
			bottom: 0,
			right: 0
		}
	});
	return (
		<div key={_id} className={wrapper}>
			<div>
				<div className={nameWrapper}>
					<H4>Received from:</H4>
					<P>{issuerName}</P>
				</div>
				<div className={infoWrapper}>
					<H4>Email:</H4>
					<P>{email}</P>
				</div>
				<div className={infoWrapper}>
					<H4>Access:</H4>
					<P>{fullAccess ? "Full Access" : eventAccess.access.join(", ")}</P>
				</div>
				<div className={infoWrapper}>
					<H4>Events:</H4>
					<P>
						{fullAccess
							? "All events"
							: eventAccess.events.length > 0
							? eventAccess.events.map((eventName) => eventName.name).join(", ")
							: "All Events"}
					</P>
				</div>
			</div>
			<div className={statusCol}>
				<div className={status} />
				<div className={actions}>
					<TableActions>
						<IconButton
							icon="delete"
							onClick={() => onDelete(_id, onDelete)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				</div>
			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		auth: state.auth,
		search: state.search,
		authorization: state.authorization
	}),
	{
		getAuthorizations,
		loadAuthorization,
		deleteAuthorization,
		clearAuthorization,
		resendEmail,
		getAuthorizationsForUser
	}
)(withAuthHooks(Authorization));
