import { convertValueToArray } from "@/utils/Format";

// check additional question quantity left
export const addQuestionQuantityCheck = (question) => (value, allValues) => {
	const values = convertValueToArray(value, "{|}");
	const isPerOrder = question.perOrder;
	let returnValue = "";
	let returnQuantity = 0;
	let pass = true;

	if (question.trackQuantity) {
		const options = [].concat(question.options) || [];

		// check values if the option has enough quantity
		values.forEach((value) => {
			let answeredQuantity = 0;

			// if isPerOrder, we don't have to check how many answers since it's only one question per order
			if (isPerOrder) {
				answeredQuantity++;
			} else {
				// create question value array (remove ticket ids and question ids)
				const ticketAdditionalFields = Object.keys(allValues.tickets).reduce((allAnswers, ticketId) => {
					const additionalFields = allValues.tickets[ticketId];
					allAnswers.push(additionalFields[question._id]);
					return allAnswers;
				}, []);

				ticketAdditionalFields.forEach((answer) => {
					const answers = convertValueToArray(answer, "{|}");
					answers.forEach((answer) => {
						if (String(value) === String(answer)) {
							answeredQuantity++;
						}
					});
				});
			}
			options.forEach((option) => {
				const optionValue = question.type === "multiSelect" ? option.label : option.value;
				if (String(value) === String(optionValue)) {
					const totalLeftQuantity = option.quantityLeft - answeredQuantity;
					if (totalLeftQuantity < 0) {
						returnQuantity = option.quantityLeft;
						returnValue = value;
						pass = false;
					}
				}
			});
		});
		return pass ? undefined : `${returnValue} (${returnQuantity} available)`;
	}
};
