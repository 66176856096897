import NotFound from "@/NotFound";
import LoadErrorView from "@/components/LoadErrorView";
import MenuLayout from "@/components/MenuLayout";
import Page from "@/components/Page";
import Steps from "@/components/Steps";
import { getFeatureFlags } from "@/config";
import AffiliateTracking from "@/events/event/AffiliateTracking";
import Attendees from "@/events/event/Attendees";
import EventMenu from "@/events/event/EventMenu";
import GroupedCapacity from "@/events/event/GroupedCapacity";
import Integrations from "@/events/event/Integrations";
import Overview from "@/events/event/Overview";
import Settings from "@/events/event/Settings";
import { AccessCodes } from "@/events/event/accessCodes/AccessCodes";
import { Features } from "@/events/event/accessibility/Features";
import Information from "@/events/event/accessibility/Information";
import Tips from "@/events/event/accessibility/Tips";
import AdditionalQuestions from "@/events/event/additionalQuestions/AdditionalQuestions";
import Analytics from "@/events/event/analytics/Analytics";
import AppAccess from "@/events/event/appAccess/AppAccess";
import AutomaticDiscount from "@/events/event/automaticDiscounts/AutomaticDiscount";
import ScanningMessage from "@/events/event/customScanningMessage/ScanningMessage";
import ScanningMessages from "@/events/event/customScanningMessage/ScanningMessages";
import Styling from "@/events/event/design/Styling";
import OrderMessages from "@/events/event/design/messages/Messages";
import DiscountCodePage from "@/events/event/discountCodes/DiscountCodePage";
import DiscountCodesPage from "@/events/event/discountCodes/DiscountCodesPage";
import Basics from "@/events/event/eventCreation/basics/Basics";
import Details from "@/events/event/eventCreation/details/Details";
import { checkInWizard, getStepsForEventCreation } from "@/events/event/eventCreation/utils/eventCreationUtils";
import FacebookEvent from "@/events/event/facebook/FacebookEvent";
import FacebookEvents from "@/events/event/facebook/FacebookEvents";
import { EventHeader } from "@/events/event/header/EventHeader";
import LinkedInEvent from "@/events/event/linkedIn/LinkedInEvent";
import EditOrder from "@/events/event/order/EditOrder";
import Invoices from "@/events/event/order/Invoices";
import Order from "@/events/event/order/Order";
import Orders from "@/events/event/order/Orders";
import BulkUpload from "@/events/event/order/bulkUpload/BulkUpload";
import { MassRefundFailureReport } from "@/events/event/order/massRefunds/MassRefundFailureReport";
import { MassRefundHome } from "@/events/event/order/massRefunds/MassRefundHome";
import Refund from "@/events/event/order/refunds/Refund";
import Fees from "@/events/event/payments/Fees";
import Gateways from "@/events/event/payments/Gateways";
import PaymentSettings from "@/events/event/payments/PaymentSettings";
import Payouts from "@/events/event/payments/Payouts";
import TicketFees from "@/events/event/payments/TicketFees";
import { PaymentPlansPage } from "@/events/event/payments/components/gateways/paymentPlans/page";
import RecurringDates from "@/events/event/recurringDates/RecurringDates";
import ReportingGroups from "@/events/event/reports/ReportingGroups";
import { AffiliateCodes } from "@/events/event/reports/Reports";
import Resale from "@/events/event/resale/Resale";
import ScanningCount from "@/events/event/scanningCount/ScanningCount";
import MapSelector from "@/events/event/seatingMap/MapSelector";
import TicketOrder from "@/events/event/ticketOrder/TicketOrder";
import TicketGroups from "@/events/event/tickets/TicketGroups";
import PackagedTickets from "@/events/event/tickets/packagedTickets/PackagedTickets";
import TicketTypes from "@/events/event/tickets/ticketTypes/TicketTypes";
import VirtualEventHub from "@/events/event/virtualEventHub/VirtualEventHub";
import Waitlist from "@/events/event/waitlist/Waitlist";
import { WidgetsSection } from "@/events/event/widgets/WidgetsSection";
import { LegacyWidgetsSection } from "@/events/event/widgets/legacy-widgets/LegacyWidgetsSection";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { EventAccessCodesReport } from "@/reports/components/AccessCodes";
import { EventDiscountCodesReport } from "@/reports/components/Discounts";
import SeatMap from "@/seatingMap/SeatingMap";
import { getEvent, resetEvent } from "@/state/actions/eventActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import Alerts from "@/users/Alerts";
import SelfServePayoutSettings from "@/users/selfServePayouts/SelfServePayoutSettings";
import { CATEGORIES, PermissionWrapper } from "@/utils/Permission";
import { FC, useEffect } from "react";
import { Route, Routes, useLocation, useParams } from "react-router";
import { Spacer } from "../../ui/Spacer";

const { FINANCIAL_AND_REPORTING, EVENT_INFORMATION, ATTENDEES, ORDERS } = CATEGORIES;

export const Event: FC = () => {
	const params = useParams<{ eventId?: string }>();
	const location = useLocation();
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const dispatch = useAppDispatch();
	const { isAdmin, user } = useCurrentUser();

	const eventId = params.eventId;

	const isResaleAllowed = user?.isResaleAllowed;
	const { massRefunds } = getFeatureFlags(isAdmin);

	useEffect(() => {
		load();
		return () => {
			dispatch(resetEvent());
		};
	}, [eventId]);

	const load = () => {
		if (
			eventId &&
			eventId !== "new" &&
			!currentEvent.get.loading &&
			(!currentEvent.event || currentEvent.event?._id !== eventId)
		) {
			dispatch(getEvent(eventId));
		}
	};

	const routes = () => {
		return (
			<div style={{ height: "100%" }}>
				<Routes>
					<Route
						index
						path="/"
						element={
							<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
								<Overview />
							</PermissionWrapper>
						}
					/>

					<Route path="orders/*">
						<Route
							path=""
							element={
								<PermissionWrapper eventId={eventId} category={ORDERS}>
									<Orders />
								</PermissionWrapper>
							}
						/>

						<Route
							path="edit/:orderId"
							element={
								<PermissionWrapper eventId={eventId} category={ORDERS}>
									<EditOrder />
								</PermissionWrapper>
							}
						/>

						<Route path=":orderId/*">
							<Route
								path=""
								element={
									<PermissionWrapper eventId={eventId} category={ORDERS}>
										<Order />
									</PermissionWrapper>
								}
							/>

							<Route
								path="refund"
								element={
									<PermissionWrapper eventId={eventId} category={ORDERS}>
										<Refund />
									</PermissionWrapper>
								}
							/>
						</Route>
					</Route>

					<Route
						path="paid-by-invoice"
						element={
							<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
								<Invoices />
							</PermissionWrapper>
						}
					/>

					{massRefunds ? (
						<Route path="mass-refunds/*">
							<Route
								path="refunds"
								element={
									<PermissionWrapper eventId={eventId} category={ORDERS}>
										<MassRefundHome />
									</PermissionWrapper>
								}
							/>

							<Route
								path="refund-history"
								element={
									<PermissionWrapper eventId={eventId} category={ORDERS}>
										<MassRefundHome />
									</PermissionWrapper>
								}
							/>

							<Route
								path="failure-report/:massRefundId"
								element={
									<PermissionWrapper eventId={eventId} category={ORDERS}>
										<MassRefundFailureReport />
									</PermissionWrapper>
								}
							/>
						</Route>
					) : null}

					<Route
						path="attendees"
						element={
							<PermissionWrapper eventId={eventId} category={ATTENDEES}>
								<Attendees />
							</PermissionWrapper>
						}
					/>

					<Route
						path="waitlist"
						element={
							<PermissionWrapper eventId={eventId} category={ORDERS}>
								<Waitlist />
							</PermissionWrapper>
						}
					/>
					<Route path="mass-refunds/*">
						<Route
							path="refunds"
							element={
								<PermissionWrapper eventId={eventId} category={ORDERS}>
									<MassRefundHome />
								</PermissionWrapper>
							}
						/>

						<Route
							path="refund-history"
							element={
								<PermissionWrapper eventId={eventId} category={ORDERS}>
									<MassRefundHome />
								</PermissionWrapper>
							}
						/>

						<Route
							path="failure-report/:massRefundId"
							element={
								<PermissionWrapper eventId={eventId} category={ORDERS}>
									<MassRefundFailureReport />
								</PermissionWrapper>
							}
						/>
					</Route>

					{isResaleAllowed && (
						<Route
							path="resale"
							element={
								<PermissionWrapper eventId={eventId} category={ORDERS}>
									<Resale />
								</PermissionWrapper>
							}
						/>
					)}

					<Route
						path="bulk-upload"
						element={
							<PermissionWrapper eventId={eventId} category={ORDERS}>
								<BulkUpload />
							</PermissionWrapper>
						}
					/>

					<Route path="app/*">
						<Route path="messages/*">
							<Route
								path=""
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<ScanningMessages />
									</PermissionWrapper>
								}
							/>

							<Route
								path="new"
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<ScanningMessage />
									</PermissionWrapper>
								}
							/>

							<Route
								path=":messageId"
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<ScanningMessage />
									</PermissionWrapper>
								}
							/>
						</Route>

						<Route
							path="scanning-count"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<ScanningCount />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route path="reports/*">
						<Route
							path="analytics"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
									<Analytics />
								</PermissionWrapper>
							}
						/>

						<Route
							path="discounts"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
									<EventDiscountCodesReport />
								</PermissionWrapper>
							}
						/>

						<Route
							path="affiliate-codes"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
									<AffiliateCodes />
								</PermissionWrapper>
							}
						/>

						<Route
							path="access-codes"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
									<EventAccessCodesReport />
								</PermissionWrapper>
							}
						/>

						<Route
							path="groups"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING}>
									<ReportingGroups />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route path="info/*">
						<Route
							path="basics"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Steps steps={getStepsForEventCreation()} />
									<Basics />
								</PermissionWrapper>
							}
						/>

						<Route
							path="details"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Steps steps={getStepsForEventCreation()} />
									<Details />
								</PermissionWrapper>
							}
						/>

						<Route
							path="ticket-types"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Steps steps={getStepsForEventCreation()} />
									<TicketTypes />
								</PermissionWrapper>
							}
						/>

						<Route
							path="recurring-dates"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<RecurringDates />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route
						path="additional-questions"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<AdditionalQuestions />
							</PermissionWrapper>
						}
					/>

					<Route
						path="tickets"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<TicketTypes />
							</PermissionWrapper>
						}
					/>

					<Route
						path="ticket-groups"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<TicketGroups />
							</PermissionWrapper>
						}
					/>

					<Route
						path="packaged-tickets"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<PackagedTickets />
							</PermissionWrapper>
						}
					/>

					<Route
						path="ticket-order"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<TicketOrder />
							</PermissionWrapper>
						}
					/>

					<Route
						path="grouped-capacity"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<GroupedCapacity />
							</PermissionWrapper>
						}
					/>

					<Route path="discounts/*">
						<Route path="automatic/*">
							<Route
								path=""
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<DiscountCodesPage />
									</PermissionWrapper>
								}
							/>
							<Route
								path=":autoDiscountId"
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<AutomaticDiscount />
									</PermissionWrapper>
								}
							/>
						</Route>

						<Route path="codes/*">
							<Route
								path=""
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<DiscountCodesPage />
									</PermissionWrapper>
								}
							/>

							<Route
								path=":discountCodeId"
								element={
									<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
										<DiscountCodePage />
									</PermissionWrapper>
								}
							/>
						</Route>
					</Route>

					<Route
						path="access-codes"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<AccessCodes />
							</PermissionWrapper>
						}
					/>

					<Route
						path="facebook-events"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<FacebookEvents />
							</PermissionWrapper>
						}
					/>

					<Route path="facebook-event/*">
						<Route
							path=""
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<FacebookEvent />
								</PermissionWrapper>
							}
						/>

						<Route
							path=":facebookId"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<FacebookEvent />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route
						path="virtual-event-hub"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<VirtualEventHub />
							</PermissionWrapper>
						}
					/>

					<Route
						path="affiliate-tracking"
						element={
							<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
								<AffiliateTracking />
							</PermissionWrapper>
						}
					/>

					<Route
						path="linked-in-event"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<LinkedInEvent />
							</PermissionWrapper>
						}
					/>

					<Route path="seatingmap/*">
						<Route
							path="selection"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<MapSelector />
								</PermissionWrapper>
							}
						/>

						<Route
							path=":seatingMapId/construction"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<SeatMap />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route path="payments/*">
						<Route path="settings/*">
							<Route
								path=""
								element={
									<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
										<PaymentSettings />
									</PermissionWrapper>
								}
							/>
							<Route
								path=":tab"
								element={
									<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
										<PaymentSettings />
									</PermissionWrapper>
								}
							/>
						</Route>

						<Route path="gateways">
							<Route
								path=""
								element={
									<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
										<Gateways />
									</PermissionWrapper>
								}
							/>
							<Route
								path="payment-plans"
								element={
									<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
										<PaymentPlansPage eventId={eventId} />
									</PermissionWrapper>
								}
							/>
						</Route>

						<Route
							path="payouts"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
									<Payouts />
								</PermissionWrapper>
							}
						/>

						<Route
							path="fees"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
									<Fees />
								</PermissionWrapper>
							}
						/>

						<Route
							path="ticket-fees"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq={isAdmin}>
									<TicketFees />
								</PermissionWrapper>
							}
						/>

						<Route
							path="self-serve-payouts"
							element={
								<PermissionWrapper eventId={eventId} category={FINANCIAL_AND_REPORTING} adminReq>
									<SelfServePayoutSettings level="event" eventId={eventId} />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route path="styling/*">
						<Route
							path=""
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Styling />
								</PermissionWrapper>
							}
						/>

						<Route
							path=":tab"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Styling />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route
						path="messages"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<OrderMessages />
							</PermissionWrapper>
						}
					/>

					<Route
						path="widgets"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<WidgetsSection />
							</PermissionWrapper>
						}
					/>

					<Route
						path="legacy-widgets"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<LegacyWidgetsSection />
							</PermissionWrapper>
						}
					/>

					<Route path="accessibility/*">
						<Route
							path="information"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Information />
								</PermissionWrapper>
							}
						/>

						<Route
							path="features"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Features />
								</PermissionWrapper>
							}
						/>

						<Route
							path="tips"
							element={
								<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
									<Tips />
								</PermissionWrapper>
							}
						/>
					</Route>

					<Route
						path="settings"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<Settings />
							</PermissionWrapper>
						}
					/>

					<Route
						path="integrations"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<Integrations />
							</PermissionWrapper>
						}
					/>

					<Route
						path="alerts"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<Alerts eventLevel={true} />
							</PermissionWrapper>
						}
					/>

					<Route
						path="appaccess"
						element={
							<PermissionWrapper eventId={eventId} category={EVENT_INFORMATION}>
								<AppAccess />
							</PermissionWrapper>
						}
					/>

					<Route path="*" element={<NotFound />} />
				</Routes>
			</div>
		);
	};

	if (currentEvent.get?.loading || !currentEvent.event || currentEvent.error) {
		return <LoadErrorView loading={currentEvent.get.loading} error={currentEvent.get.error} retryAction={load} />;
	}

	const inWizard = checkInWizard(currentEvent.event);
	const pathEndPoint = location.pathname.split("/").slice(-1).pop() ?? "";
	const isSeatMapConstruction = ["construction"].includes(pathEndPoint);
	const routesContent = routes();

	if (isSeatMapConstruction || inWizard) {
		return routesContent;
	}

	return (
		<MenuLayout>
			<EventMenu eventId={eventId} />
			<Page type="details">
				<EventHeader />
				<Spacer size="var(--spacing-xl)" />
				{routesContent}
			</Page>
		</MenuLayout>
	);
};
