import {
	RUN_FRAUD_CHECK_FOR_EVENT_ERROR,
	RUN_FRAUD_CHECK_FOR_EVENT_REQUEST,
	RUN_FRAUD_CHECK_FOR_EVENT_SUCCESS
} from "../actions/adminUtilityActions";

const initialState = {
	loading: false,
	error: false,
	fraudChecks: {
		loading: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case RUN_FRAUD_CHECK_FOR_EVENT_REQUEST:
			return {
				...state,
				fraudChecks: {
					loading: true
				}
			};

		case RUN_FRAUD_CHECK_FOR_EVENT_SUCCESS:
			return {
				...state,
				fraudChecks: {
					loading: false
				}
			};

		case RUN_FRAUD_CHECK_FOR_EVENT_ERROR:
			return {
				...state,
				fraudChecks: {
					loading: false
				}
			};

		default:
			return state;
	}
};
