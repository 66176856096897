import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { currency } from "../../utils/Format";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

class Events extends Component {
	constructor(props) {
		super(props);
		this.getFilterOptions = this.getFilterOptions.bind(this);
	}

	getFilterOptions() {
		const { filters = {} } = this.props.reportState;
		const { organisers = [] } = filters;
		return [
			{
				key: "organiserIds",
				mode: "multiple",
				placeholder: "All",
				label: "Select organisers",
				options: organisers.map((o) => ({
					value: o._id,
					name: o.name
				}))
			}
		];
	}

	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;
		const { load, loadPage, downloadCsv, search } = this.props;

		const { transactionDates } = this.props.search;
		const showAvailable = transactionDates === undefined || transactionDates.length === 0;

		const columns = [
			{
				title: "Event ID",
				dataIndex: "eventReference",
				key: "eventReference"
			},
			{
				title: "Event Name",
				dataIndex: "eventName",
				key: "eventName",
				render: (eventName, record) => {
					return <Link to={`/console/my-events/${record.eventId}`}>{eventName}</Link>;
				}
			},
			{
				title: "Date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "Time",
				dataIndex: "time",
				key: "time"
			},
			{
				title: "Host",
				dataIndex: "organiserName",
				key: "organiserName"
			},
			{
				title: "Location",
				dataIndex: "location",
				key: "location"
			},
			{
				title: "Sold",
				dataIndex: "sold",
				key: "sold"
			},
			{
				title: "Checked-in",
				dataIndex: "checkedInCount",
				key: "checkedInCount"
			},
			{
				title: "Capacity",
				dataIndex: "totalCapacity",
				key: "totalCapacity"
			}
		];

		if (showAvailable) {
			columns.push({
				title: "Available",
				dataIndex: "available",
				key: "available"
			});
		}

		columns.push(
			...[
				{
					title: "Percentage sold",
					dataIndex: "percentageSold",
					key: "percentageSold",
					render: (value) => `${value}`
				},
				{
					title: "Ticket earnings",
					dataIndex: "ticketEarnings",
					key: "ticketEarnings",
					render: (value) => currency(value)
				},
				{
					title: "Donation earnings",
					dataIndex: "donationEarnings",
					key: "donationEarnings",
					render: (value) => currency(value)
				},
				{
					title: "Add-on earnings",
					dataIndex: "addOnEarnings",
					key: "addOnEarnings",
					render: (value) => currency(value)
				},
				{
					title: "Absorbed fees",
					dataIndex: "absorbedFees",
					key: "absorbedFees",
					render: (value) => currency(value)
				},
				{
					title: "Total earnings",
					dataIndex: "totalEarnings",
					key: "totalEarnings",
					render: (value) => currency(value)
				},
				...(search.loc === "US"
					? [
							{
								title: "Total custom tax",
								dataIndex: "customTax",
								key: "customTax",
								render: (value) => currency(value)
							}
					  ]
					: []),
				{
					title: "Rebates",
					dataIndex: "rebates",
					key: "rebates",
					render: (value) => currency(value)
				}
			]
		);

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						firstDateName="eventDates"
						firstDateLabel="Event date range"
						secondDateName="transactionDates"
						secondDateLabel="Transaction date range"
						filterOptions={this.getFilterOptions()}
						showSearchField={false}
						loadReport={load}
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							{results.length > 0 && (
								<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
									{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
								</LegacyButton>
							)}
						</div>
						<Table
							columns={columns}
							dataSource={results}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey={(o) => o.eventDateId + o.eventDateId}
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default withReportProvider(Events, "Event summary", "events");
