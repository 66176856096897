import { Input } from "@/ui/antd";
import copy from "copy-text-to-clipboard";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../components/AppBlocks";
import LoadErrorView from "../components/LoadErrorView";
import PageHeading from "../components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { createNewApiKey } from "../state/actions/userActions";

const Search = Input.Search;

class ApiKey extends Component {
	render() {
		const {
			currentUser: { apiKey },
			putApiKey,
			userId,
			auth
		} = this.props;
		const { user = {} } = auth;
		const key = user.apiKey ? user.apiKey.key : undefined;

		return (
			<LoadErrorView loading={apiKey.loading} error={apiKey.error} retryAction={() => this.load()}>
				<PageHeading title="Public Api key" />
				<ContentBlock>
					<div style={{ float: "right" }}>
						<LegacyButton onClick={() => putApiKey(userId)} ariaLabel="New Public Api key" style={{ float: "right" }}>
							New Public Api key
						</LegacyButton>
					</div>
					<Search value={key} enterButton="Copy" onChange={() => null} readOnly onSearch={(value) => copy(value)} />
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentUser: state.currentUser,
			auth: state.auth
		};
	},
	{ putApiKey: createNewApiKey }
)(ApiKey);
