import { LegacyInputField, InputNumberField, SelectField, TextAreaField } from "@/components/Fields";
import { CreditData, addCredit, editPayment } from "@/state/actions/newPayoutActions";
import { DateBreakdown } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { mustNotBeZero, required } from "@/utils/Validators";
import { Modal } from "@/ui/antd";
import React from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

const transactionIdRequired = required("A transaction id is required");
const amountRequired = required("An amount is required");

type ICombinedProps = IProps & IInjectedProps & IInjectedFunctions;

const CreditModal: React.FunctionComponent<ICombinedProps & InjectedFormProps<CreditData, ICombinedProps>> = ({
	isOpen,
	handleSubmit,
	addCredit,
	eventId,
	breakdown,
	isEdit,
	creditId,
	editPayment,
	close
}) => {
	const save = (values: CreditData) => {
		isEdit ? editPayment(eventId!, creditId!, values) : addCredit(eventId!, values);
		close();
	};

	const titleAndButtonText = `${isEdit ? "Edit" : "Add"} credit`;

	return (
		<Modal
			open={isOpen}
			destroyOnClose={true}
			onCancel={close}
			okText={titleAndButtonText}
			title={titleAndButtonText}
			onOk={handleSubmit(save)}
		>
			<form>
				<Field
					name="eventDateId"
					label="Select date"
					component={SelectField}
					options={breakdown.map((date) => ({ label: date.displayDate, value: date.dateId }))}
				/>
				<Field
					name="transactionId"
					label="Transaction Id"
					component={LegacyInputField}
					required
					validate={transactionIdRequired}
				/>
				<Field
					name="amount"
					label="Amount"
					component={InputNumberField}
					precision={2}
					min={0.01}
					required
					validate={[amountRequired, mustNotBeZero]}
				/>
				<Field name="notes" label="Notes" component={TextAreaField} />
			</form>
		</Modal>
	);
};

const formName = "credit";

interface IProps {
	isEdit?: boolean;
	focusedDate?: string | null;
	creditId?: string | null;
	isOpen: boolean;
	close: () => void;
}

interface IInjectedProps {
	initialValues: CreditData;
	eventId?: string;
	breakdown: DateBreakdown[];
}

interface IInjectedFunctions {
	addCredit: (eventId: string, values: CreditData) => void;
	editPayment: (eventId: string, creditId: string, values: CreditData) => void;
}

export default connect(
	(state: ReduxState, ownProps: IProps) => {
		const breakdown = state.payouts.eventPayout.payoutInformation?.datesBreakdown || [];
		const creditsOrAdjustments = state.payouts.eventPayout.payoutInformation?.creditsOrAdjustments || [];
		const editingCredit = creditsOrAdjustments.find((credit) => credit._id === ownProps.creditId);
		const initialValues =
			ownProps.isEdit && editingCredit
				? {
						eventDateId: editingCredit.eventDateId,
						amount: editingCredit.amount,
						transactionId: editingCredit.transactionId,
						notes: editingCredit.notes
				  }
				: {
						eventDateId: ownProps.focusedDate || undefined,
						amount: 0,
						transactionId: undefined,
						notes: undefined
				  };
		return {
			initialValues,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId,
			breakdown
		};
	},
	{ addCredit, editPayment }
)(
	reduxForm<CreditData, ICombinedProps>({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(CreditModal)
) as unknown as React.FunctionComponent<IProps>;
