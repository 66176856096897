import { change } from "redux-form";
import EventsService from "../../services/EventsService";
import { getEventMetrics } from "./metricActions";
import UsersService from "../../services/UsersService";
import { onEventLocationSet, onOverrideLocationSet, getLocationFromState } from "./locationActions";
import { getOrganiser } from "./organiserActions";
import { Modal, notification } from "@/ui/antd";

import LinkButton from "../../components/buttons/LinkButton";
import { goToNextStep } from "../../events/event/eventCreation/utils/eventCreationUtils";
import history from "@/history";
import { setUser } from "./authActions";
import { ConsoleAction, trackConsoleAction } from "@hx/analytics";
import { Utils as SeatingMapUtils } from "@hx/seating-map";

export const IMPORT_REQUEST = "IMPORT_REQUEST";
export const IMPORT_SUCCESS = "IMPORT_SUCCESS";
export const IMPORT_ERROR = "IMPORT_ERROR";
export const IMPORT_OPEN = "IMPORT_OPEN";
export const IMPORT_CLOSE = "IMPORT_CLOSE";
export const IMPORT_CLEAR = "IMPORT_CLEAR";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const GET_RECENTLY_UPDATED_EVENT_REQUEST = "GET_RECENTLY_UPDATED_EVENT_REQUEST";
export const GET_RECENTLY_UPDATED_EVENT_SUCCESS = "GET_RECENTLY_UPDATED_EVENT_SUCCESS";
export const GET_RECENTLY_UPDATED_EVENT_ERROR = "GET_RECENTLY_UPDATED_EVENT_ERROR";

export const GET_EVENT_ORDER_EDIT_REQUEST = "GET_EVENT_ORDER_EDIT_REQUEST";
export const GET_EVENT_ORDER_EDIT_SUCCESS = "GET_EVENT_ORDER_EDIT_SUCCESS ";
export const GET_EVENT_ORDER_EDIT_ERROR = "GET_EVENT_ORDER_EDIT_ERROR";

export const GET_EVENT_USER_REQUEST = "GET_EVENT_USER_REQUEST";
export const GET_EVENT_USER_SUCCESS = "GET_EVENT_USER_SUCCESS";
export const GET_EVENT_USER_ERROR = "GET_EVENT_USER_ERROR";

export const GET_USER_EVENTS_REQUEST = "GET_USER_EVENTS_REQUEST";
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS";
export const GET_USER_EVENTS_ERROR = "GET_USER_EVENTS_ERROR";
export const CLEAR_USER_EVENTS = "CLEAR_USER_EVENTS";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";
export const DELETE_EVENT_CANCEL = "DELETE_EVENT_CANCEL";

export const DUPLICATE_EVENT_REQUEST = "DUPLICATE_EVENT_REQUEST";
export const DUPLICATE_EVENT_SUCCESS = "DUPLICATE_EVENT_SUCCESS";
export const DUPLICATE_EVENT_ERROR = "DUPLICATE_EVENT_ERROR";

export const ARCHIVE_EVENT_REQUEST = "ARCHIVE_EVENT_REQUEST";
export const ARCHIVE_EVENT_SUCCESS = "ARCHIVE_EVENT_SUCCESS";
export const ARCHIVE_EVENT_ERROR = "ARCHIVE_EVENT_ERROR";
export const ARCHIVE_MODAL_OPEN = "ARCHIVE_MODAL_OPEN";
export const ARCHIVE_MODAL_CLOSE = "ARCHIVE_MODAL_CLOSE";

export const NOTIFY_FOLLOWERS_REQUEST = "NOTIFY_FOLLOWERS_REQUEST";
export const NOTIFY_FOLLOWERS_SUCCESS = "NOTIFY_FOLLOWERS_SUCCESS";
export const NOTIFY_FOLLOWERS_ERROR = "NOTIFY_FOLLOWERS_ERROR";
export const NOTIFY_FOLLOWERS_MODAL_OPEN = "NOTIFY_FOLLOWERS_MODAL_OPEN";
export const NOTIFY_FOLLOWERS_MODAL_CLOSE = "NOTIFY_FOLLOWERS_MODAL_CLOSE";

export const FIND_EVENT_REQUEST = "FIND_EVENT_REQUEST";
export const FIND_EVENT_SUCCESS = "FIND_EVENT_SUCCESS";
export const FIND_EVENT_ERROR = "FIND_EVENT_ERROR";

export const SAVE_EVENT_REQUEST = "SAVE_EVENT_REQUEST";
export const SAVE_EVENT_SUCCESS = "SAVE_EVENT_SUCCESS";
export const SAVE_EVENT_ERROR = "SAVE_EVENT_ERROR";
export const UNPUBLISH_EVENT_ERROR = "UNPUBLISH_EVENT_ERROR";

export const UPDATE_SLUG_REQUEST = "UPDATE_SLUG_REQUEST";
export const UPDATE_SLUG_SUCCESS = "UPDATE_SLUG_SUCCESS";
export const UPDATE_SLUG_ERROR = "UPDATE_SLUG_ERROR";

export const PREVIEW_ADDITIONAL_QUESTIONS_REQUEST = "PREVIEW_ADDITIONAL_QUESTIONS_REQUEST";
export const PREVIEW_ADDITIONAL_QUESTIONS_SUCCESS = "PREVIEW_ADDITIONAL_QUESTIONS_SUCCESS";
export const PREVIEW_ADDITIONAL_QUESTIONS_ERROR = "PREVIEW_ADDITIONAL_QUESTIONS_ERROR";

const DELETE_ADDITIONAL_QUESTION_REQUEST = "DELETE_ADDITIONAL_QUESTION_REQUEST";
export const DELETE_ADDITIONAL_QUESTION_SUCCESS = "DELETE_ADDITIONAL_QUESTION_SUCCESS";
const DELETE_ADDITIONAL_QUESTION_ERROR = "DELETE_ADDITIONAL_QUESTION_ERROR";

const PREVIEW_COMPLETE_MESSAGE_REQUEST = "PREVIEW_COMPLETE_MESSAGE_REQUEST";
const PREVIEW_COMPLETE_MESSAGE_SUCCESS = "PREVIEW_COMPLETE_MESSAGE_SUCCESS";
const PREVIEW_COMPLETE_MESSAGE_ERROR = "PREVIEW_COMPLETE_MESSAGE_ERROR";

export const RESET_EVENT = "RESET_EVENT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const PUBLISH_CHECK_REQUEST = "PUBLISH_CHECK_REQUEST";
export const PUBLISH_CHECK_SUCCESS = "PUBLISH_CHECK_SUCCESS";
export const PUBLISH_CHECK_ERROR = "PUBLISH_CHECK_ERROR";

export const PUBLISH_MODAL_CLOSE = "PUBLISH_MODAL_CLOSE";

export const PUBLISHED_MODAL_OPEN = "PUBLISHED_MODAL_OPEN";
export const PUBLISHED_MODAL_CLOSE = "PUBLISHED_MODAL_CLOSE";

export const ONLINE_MODAL_OPEN = "ONLINE_MODAL_OPEN";
export const ONLINE_MODAL_CLOSE = "ONLINE_MODAL_CLOSE";

export const PRICE_PREVIEW_REQUEST = "PRICE_PREVIEW_REQUEST";
export const PRICE_PREVIEW_SUCCESS = "PRICE_PREVIEW_SUCCESS";
export const PRICE_PREVIEW_ERROR = "PRICE_PREVIEW_ERROR";

export const GET_FEE_STRUCTURE_REQUEST = "GET_FEE_STRUCTURE_REQUEST";
export const GET_FEE_STRUCTURE_SUCCESS = "GET_FEE_STRUCTURE_SUCCESS";
export const GET_FEE_STRUCTURE_ERROR = "GET_FEE_STRUCTURE_ERROR";

export const VERIFY_ACCOUNT_MODAL_OPEN = "VERIFY_ACCOUNT_MODAL_OPEN";
export const VERIFY_ACCOUNT_MODAL_CLOSE = "VERIFY_ACCOUNT_MODAL_CLOSE";

export const TRANSFER_EVENT_MODAL_OPEN = "TRANSFER_EVENT_MODAL_OPEN";
export const TRANSFER_EVENT_MODAL_CLOSE = "TRANSFER_EVENT_MODAL_CLOSE";

export const TRANSFER_EVENT_REQUEST = "TRANSFER_EVENT_REQUEST";
export const TRANSFER_EVENT_SUCCESS = "TRANSFER_EVENT_SUCCESS";
export const TRANSFER_EVENT_ERROR = "TRANSFER_EVENT_ERROR";

const HUBSPOT_SYNC_REQUEST = "HUBSPOT_SYNC_REQUEST";
const HUBSPOT_SYNC_SUCCESS = "HUBSPOT_SYNC_SUCCESS";
const HUBSPOT_SYNC_ERROR = "HUBSPOT_SYNC_ERROR";

const wait = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

export const resetEvent = () => {
	return (dispatch) => dispatch({ type: RESET_EVENT });
};

export const openImport = () => {
	return (dispatch) => dispatch({ type: IMPORT_OPEN });
};

export const closeImport = () => {
	return (dispatch) => dispatch({ type: IMPORT_CLOSE });
};

export const clearImport = () => {
	return (dispatch) => dispatch({ type: IMPORT_CLEAR });
};

export const importEvent = (eventUrl, navigate) => {
	return (dispatch) => {
		dispatch({ type: IMPORT_REQUEST });
		EventsService.scrape(eventUrl)
			.then((data) => {
				dispatch({ type: RESET_EVENT });
				dispatch({ type: IMPORT_SUCCESS, data });
				navigate(`/console/my-events/new/basics`);
				dispatch({ type: IMPORT_CLOSE });
				notification.success({
					message: "Imported",
					description: "Your event has been imported"
				});
			})
			.catch(() => {
				dispatch({ type: IMPORT_ERROR });
				notification.error({
					message: "Error",
					description: "Your event failed import"
				});
			});
	};
};

const clearUnsavedData = (key) => {
	try {
		localStorage.setItem(key, null);
	} catch (err) {
		// ignore save errors
		return;
	}
};

export const findEvents = (page, search, toAppend = false, location) => {
	return (dispatch, getState) => {
		const queryBy = search || getState().search;
		page = page || queryBy.page;
		location = location || getLocationFromState(true);
		dispatch({ type: FIND_EVENT_REQUEST, page });

		EventsService.find(queryBy, page, location)
			.then((results) => {
				dispatch({
					type: FIND_EVENT_SUCCESS,
					events: results.events,
					count: results.count,
					totalPublished: results.totalPublished,
					toAppend
				});
			})
			.catch((err) => {
				dispatch({ type: FIND_EVENT_ERROR, error: err });
			});
	};
};

export const getEventsById = (eventIds, toAppend = false) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FIND_EVENT_REQUEST, page: 1 });
		EventsService.getEventsById(eventIds, location)
			.then((events) => {
				dispatch({
					type: FIND_EVENT_SUCCESS,
					toAppend,
					events,
					count: events.length
				});
			})
			.catch((err) => {
				dispatch({ type: FIND_EVENT_ERROR, error: err });
			});
	};
};

export const getRecentlyUpdatedEvents = (number = 3) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_RECENTLY_UPDATED_EVENT_REQUEST });
		EventsService.getRecentlyUpdated(number, null, location)
			.then((results) => {
				dispatch({
					type: GET_RECENTLY_UPDATED_EVENT_SUCCESS,
					events: results.events,
					count: results.count
				});
			})
			.catch((err) => {
				dispatch({ type: GET_RECENTLY_UPDATED_EVENT_ERROR, error: err });
			});
	};
};

export const getEvent = (id, orderId, token) => {
	return (dispatch) => {
		dispatch({ type: GET_EVENT_REQUEST });
		EventsService.get(id, orderId, token)
			.then((event) => {
				onEventLocationSet(event.location)(dispatch);
				onOverrideLocationSet(event.location)(dispatch);
				dispatch(getEventUser(event.userId));
				dispatch({ type: GET_EVENT_SUCCESS, event });
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to load event";
				dispatch({ type: GET_EVENT_ERROR, error: message });
			});
	};
};

export const getEventForOrderEdit = (eventId, orderId) => {
	return async (dispatch) => {
		dispatch({ type: GET_EVENT_ORDER_EDIT_REQUEST });
		try {
			const eventWithOrderData = await EventsService.get(eventId, orderId);
			dispatch({
				type: GET_EVENT_ORDER_EDIT_SUCCESS,
				event: eventWithOrderData
			});
		} catch (err) {
			const message =
				err && err.response && err.response.data && err.response.data.error
					? err.response.data.error
					: "Failed to load event";
			dispatch({ type: GET_EVENT_ORDER_EDIT_ERROR, error: message });
		}
	};
};

const getEventUser = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_EVENT_USER_REQUEST });
		UsersService.get(id)
			.then((user) => {
				dispatch({ type: GET_EVENT_USER_SUCCESS, user });
			})
			.catch((err) => {
				dispatch({ type: GET_EVENT_USER_ERROR, error: err });
			});
	};
};

export const getFeeStructure = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_FEE_STRUCTURE_REQUEST });
		EventsService.getFeeStructure(id)
			.then((feeStructure) => {
				dispatch({ type: GET_FEE_STRUCTURE_SUCCESS, feeStructure });
			})
			.catch((error) => {
				dispatch({ type: GET_FEE_STRUCTURE_ERROR, error });
			});
	};
};

export const getUserEvents = (id, fields, userSpecific = false, location) => {
	return (dispatch) => {
		dispatch({ type: GET_USER_EVENTS_REQUEST });
		EventsService.getUserEvents(id, fields, userSpecific, location)
			.then((events) => {
				dispatch({ type: GET_USER_EVENTS_SUCCESS, events });
			})
			.catch((err) => {
				dispatch({ type: GET_USER_EVENTS_ERROR, error: err });
			});
	};
};

export const cancelDeleteEvent = () => {
	return (dispatch) => {
		dispatch({ type: DELETE_EVENT_CANCEL });
	};
};

export const deleteEvent = (id, navigate) => {
	return (dispatch) => {
		dispatch({ type: DELETE_EVENT_REQUEST });
		EventsService.delete(id)
			.then((event) => {
				notification.success({
					message: "Deleted",
					description: "Your event has been deleted"
				});
				dispatch({ type: DELETE_EVENT_SUCCESS, event });
				navigate(`/console/my-events/`);
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to delete event";
				dispatch({ type: DELETE_EVENT_ERROR, error: message });
			});
	};
};

export const displayVirtualEventHubModal = () => (dispatch) => {
	dispatch({ type: ONLINE_MODAL_OPEN });
};

export const createEvent = (eventData) => {
	return (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST, event: eventData });
		EventsService.create(eventData)
			.then((event) => {
				onEventLocationSet(event.location)(dispatch);
				UsersService.getUser().then((userData) => {
					trackConsoleAction(ConsoleAction.eventCreated, {
						userId: userData.data.id,
						location: event.location,
						eventId: event._id
					});
					dispatch({ type: SAVE_EVENT_SUCCESS, event });
					dispatch({ type: GET_EVENT_USER_SUCCESS, user: userData.data });
					clearUnsavedData("new-event");
					notification.success({
						message: "Success",
						description: "Your event basics have been saved"
					});
					setUser(userData.data)(dispatch);

					// hack to get around the redux-form still being marked as isDirty after submitting.
					// Waiting before navigating the user away stops the withSaveCheck HOC from blocking the navigation.
					wait(0.01).then(() => goToNextStep());
				});
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Your event failed to save"
				});
				dispatch({ type: SAVE_EVENT_ERROR, error: err });
			});
	};
};

export const duplicateEvent = (id, newEventName, navigate) => {
	return (dispatch) => {
		dispatch({ type: DUPLICATE_EVENT_REQUEST });
		EventsService.duplicate(id, newEventName)
			.then((event) => {
				onEventLocationSet(event.location)(dispatch);
				dispatch({ type: GET_EVENT_SUCCESS, event });
				dispatch({ type: DUPLICATE_EVENT_SUCCESS });
				getEventMetrics(id, "today", "all");
				navigate(`/console/my-events/${event._id}`);
			})
			.catch(() => {
				dispatch({
					type: DUPLICATE_EVENT_ERROR,
					error: "Failed to duplicate event"
				});
			});
	};
};

export const openArchiveModal = () => {
	return (dispatch) => dispatch({ type: ARCHIVE_MODAL_OPEN });
};

export const closeArchiveModal = () => {
	return (dispatch) => dispatch({ type: ARCHIVE_MODAL_CLOSE });
};

export const archiveEvent = (eventId, shouldReloadEventList) => {
	return async (dispatch) => {
		dispatch({ type: ARCHIVE_EVENT_REQUEST });
		try {
			const event = await EventsService.archiveEvent(eventId);
			notification.success({
				message: "Success",
				description: "Your event has been archived successfully"
			});
			dispatch({ type: ARCHIVE_EVENT_SUCCESS, event });
			if (shouldReloadEventList) {
				dispatch(findEvents());
			}
		} catch (err) {
			const errorMessage = "Failed to archive event";
			notification.error({
				message: "Error",
				description: errorMessage
			});
			dispatch({
				type: ARCHIVE_EVENT_ERROR,
				error: errorMessage
			});
		}
	};
};

export const unarchiveEvent = (eventId, shouldReloadEventList) => {
	return async (dispatch) => {
		dispatch({ type: ARCHIVE_EVENT_REQUEST });
		try {
			const event = await EventsService.unarchiveEvent(eventId);
			notification.success({
				message: "Success",
				description: "Your event has been unarchived successfully"
			});
			dispatch({ type: ARCHIVE_EVENT_SUCCESS, event });
			if (shouldReloadEventList) {
				dispatch(findEvents());
			}
		} catch (err) {
			const errorMessage = "Failed to unarchive event";
			notification.error({
				message: "Error",
				description: errorMessage
			});
			dispatch({
				type: ARCHIVE_EVENT_ERROR,
				error: errorMessage
			});
		}
	};
};

export const openNotifyFollowersModal = () => {
	return (dispatch) => {
		dispatch({ type: NOTIFY_FOLLOWERS_MODAL_OPEN });
	};
};

export const closeNotifyFollowersModal = () => {
	return (dispatch) => dispatch({ type: NOTIFY_FOLLOWERS_MODAL_CLOSE });
};

export const savePaymentOptions = (id, paymentOptions) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		try {
			const event = await EventsService.updatePaymentOptions(id, paymentOptions);
			notification.success({
				message: "Saved",
				description: "Your payment settings have been saved"
			});
			dispatch({ type: SAVE_EVENT_SUCCESS, event });
		} catch (err) {
			const message =
				err && err.response && err.response.data && err.response.data.error
					? err.response.data.error
					: "Your event failed to saved";
			notification.error({
				message: "Error",
				description: message
			});
			dispatch({
				type: SAVE_EVENT_ERROR,
				error: {
					title: "Error",
					message
				}
			});
		}
	};
};

export const saveEvent = (id, eventData, navigate, redirect, showNotification = true) => {
	return async (dispatch) => {
		try {
			dispatch({ type: SAVE_EVENT_REQUEST, event: eventData });
			const event = await EventsService.update(id, eventData);
			onEventLocationSet(event.location)(dispatch);
			if (showNotification) {
				notification.success({
					message: "Saved",
					description: "Your event has been saved"
				});
			}
			dispatch({ type: SAVE_EVENT_SUCCESS, event });

			const userData = await UsersService.getUser();
			setUser(userData.data)(dispatch);
			if (navigate && redirect) {
				navigate(redirect);
			}
			const storeName = eventData._id ? `event-${eventData._id}` : "new-event";
			clearUnsavedData(storeName);
		} catch (err) {
			const message =
				err && err.response && err.response.data && err.response.data.error
					? err.response.data.error
					: "Your event failed to saved";
			notification.error({
				message: "Error",
				description: message
			});
			dispatch({
				type: SAVE_EVENT_ERROR,
				error: {
					title: "Error",
					message
				}
			});
		}
	};
};

export const updateSlug = (id, slug) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_SLUG_REQUEST, slug });
		EventsService.updateSlug(id, slug)
			.then((result) => {
				if (result.error) {
					notification.error({
						message: "Error",
						description: result.error
					});
					dispatch({ type: UPDATE_SLUG_ERROR, error: result.error });
					return;
				}
				notification.success({
					message: "Saved",
					description: "Your event url has been updated"
				});
				dispatch({ type: UPDATE_SLUG_SUCCESS, slug });
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Your event url failed to save"
				});
				dispatch({ type: UPDATE_SLUG_ERROR, error: err });
			});
	};
};

export const adminSaveEvent = (id, eventData) => {
	return (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST, event: eventData });
		EventsService.updateAdmin(id, eventData)
			.then((event) => {
				notification.success({
					message: "Saved",
					description: "Your event has been saved"
				});
				dispatch({ type: SAVE_EVENT_SUCCESS, event });
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Your event failed to saved"
				});
				dispatch({ type: SAVE_EVENT_ERROR, error: err });
			});
	};
};

export const closeOnlineModal = () => {
	return (dispatch) => {
		dispatch({ type: ONLINE_MODAL_CLOSE });
	};
};

export const publishToggle = (currentEvent) => {
	return (dispatch) => {
		if (currentEvent.event.stripeConnectRequired) {
			notification.error({
				message: "Error",
				description: (
					<div>
						Please connect your Stripe account to publish your event.
						<br />
						You can connect in
						<LinkButton
							onClick={() => {
								history.push({ pathname: `/console/my-events/${currentEvent.event._id}/payments/gateways` });
							}}
							style={{ textDecoration: "underline" }}
						>
							Payment Gateways
						</LinkButton>
					</div>
				)
			});
			return;
		}
		if (currentEvent.event.published && currentEvent.event.facebookEvents && currentEvent.event.facebookEvents.length) {
			notification.error({
				message: "Error",
				description: "Please delete your facebook event first"
			});
			return;
		}
		if (!currentEvent.event.published && typeof window !== "undefined" && typeof window.ga !== "undefined") {
			window.ga("gtm1.send", "event", "EventActions", "publish");
		}

		currentEvent.event.published ? dispatch(unpublish(currentEvent.event._id)) : dispatch(checkPublish(currentEvent));
	};
};

export const closePublish = () => {
	return (dispatch) => {
		dispatch({ type: PUBLISH_MODAL_CLOSE });
	};
};

export const closePublishedModal = () => {
	return (dispatch) => {
		dispatch({ type: PUBLISHED_MODAL_CLOSE });
	};
};

const checkPublish = (currentEvent) => {
	return (dispatch) => {
		if (currentEvent.event.organiserId) {
			dispatch(getOrganiser(currentEvent.event.organiserId));
		}
		dispatch({ type: PUBLISH_CHECK_REQUEST });
		EventsService.publishCheck(currentEvent.event._id)
			.then((publishVerification) => {
				dispatch({ type: PUBLISH_CHECK_SUCCESS, publishVerification });
				if (publishVerification.result === "verificationRequired") {
					dispatch({
						type: VERIFY_ACCOUNT_MODAL_OPEN,
						publishedLastOne: false
					});
				}
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Failed to check publish status"
				});
				dispatch({ type: PUBLISH_CHECK_ERROR, error: err });
			});
	};
};

export const publish = (id, scheduledPublishDate, notifyFollowers, recaptchaVerificationCode) => {
	return (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		EventsService.publish(id, scheduledPublishDate, notifyFollowers, recaptchaVerificationCode)
			.then((result) => {
				if (result.event.published) {
					!result.verification.remaining || result.verification.remaining > 0
						? dispatch({ type: PUBLISHED_MODAL_OPEN })
						: dispatch({
								type: VERIFY_ACCOUNT_MODAL_OPEN,
								publishedLastOne: true
						  });
					dispatch({ type: SAVE_EVENT_SUCCESS, event: result.event });
				} else if (result.event.scheduledPublishDate) {
					notification.success({
						message: "Scheduled",
						description: "Your event has been scheduled to be published"
					});
					dispatch({ type: SAVE_EVENT_SUCCESS, event: result.event });
				} else {
					dispatch({
						type: VERIFY_ACCOUNT_MODAL_OPEN,
						publishedLastOne: false
					});
				}
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Your event failed to published"
				});
				dispatch({ type: SAVE_EVENT_ERROR, error: err });
			});
	};
};

export const unpublish = (id) => {
	return (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		EventsService.unpublish(id)
			.then((event) => {
				notification.success({
					message: "Unpublished",
					description: "Your event has been unpublished"
				});
				dispatch({ type: SAVE_EVENT_SUCCESS, event });
			})
			.catch((err) => {
				dispatch({ type: UNPUBLISH_EVENT_ERROR, error: err });
				if (err.response.status === 423) {
					Modal.error({
						title: "Error",
						content: "Your event can not be unpublished since you have sold tickets"
					});
					return;
				}
				notification.error({
					message: "Error",
					description: "Your event failed to unpublished"
				});
			});
	};
};

export const handleNotifyFollowers = (id) => {
	return async (dispatch) => {
		dispatch({ type: NOTIFY_FOLLOWERS_REQUEST });
		try {
			const { notifyFollowers } = await EventsService.notifyFollowers(id);
			if (notifyFollowers) {
				dispatch({ type: NOTIFY_FOLLOWERS_MODAL_CLOSE });
				notification.success({
					message: "Notified",
					description: "Your followers have been notified"
				});
				dispatch({ type: NOTIFY_FOLLOWERS_SUCCESS, notifyFollowers });
			} else {
				Modal.error({
					title: "Error",
					content: "Your followers have already been notified"
				});
			}
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Your followers were failed to be notified"
			});
			dispatch({ type: NOTIFY_FOLLOWERS_ERROR, error: err });
		}
	};
};

export const previewAdditionalQuestions = (id, additionalQuestions) => {
	return (dispatch) => {
		dispatch({ type: PREVIEW_ADDITIONAL_QUESTIONS_REQUEST });
		EventsService.cacheAdditionalQuestions(id, additionalQuestions)
			.then(() => {
				dispatch({ type: PREVIEW_ADDITIONAL_QUESTIONS_SUCCESS });
				return;
			})
			.catch(() => {
				dispatch({ type: PREVIEW_ADDITIONAL_QUESTIONS_ERROR });
				return;
			});
	};
};

export const deleteAdditionalQuestion = (eventId, additionalQuestionId) => {
	return (dispatch) => {
		dispatch({ type: DELETE_ADDITIONAL_QUESTION_REQUEST });
		EventsService.deleteAdditionalQuestion(eventId, additionalQuestionId)
			.then((event) => {
				notification.success({
					message: "Deleted",
					description: "Question has been successfully deleted."
				});
				dispatch({ type: DELETE_ADDITIONAL_QUESTION_SUCCESS, event });
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: "Failed to delete the question."
				});
				dispatch({ type: DELETE_ADDITIONAL_QUESTION_ERROR });
			});
	};
};

export const previewCompleteMessage = (id, completeMessage) => {
	return (dispatch) => {
		dispatch({ type: PREVIEW_COMPLETE_MESSAGE_REQUEST });
		EventsService.cacheCompleteMessage(id, completeMessage)
			.then(() => {
				dispatch({ type: PREVIEW_COMPLETE_MESSAGE_SUCCESS });
				return;
			})
			.catch(() => {
				dispatch({ type: PREVIEW_COMPLETE_MESSAGE_ERROR });
				return;
			});
	};
};

export const pricePreview = (eventId) => {
	return (dispatch) => {
		dispatch({ type: PRICE_PREVIEW_REQUEST });
		EventsService.pricePreview(eventId)
			.then((result) => {
				dispatch({ type: PRICE_PREVIEW_SUCCESS, tableData: result });
			})
			.catch((e) => dispatch({ type: PRICE_PREVIEW_ERROR, error: e.message }));
	};
};

export const closeVerifyAccountModal = () => {
	return (dispatch) => {
		dispatch({ type: VERIFY_ACCOUNT_MODAL_CLOSE });
	};
};

export const openTransferEventModal = (_id) => {
	return (dispatch) => {
		dispatch({ type: TRANSFER_EVENT_MODAL_OPEN, eventId: _id });
	};
};

export const closeTransferEventModal = () => {
	return (dispatch) => {
		dispatch({ type: TRANSFER_EVENT_MODAL_CLOSE });
	};
};

export const transferEvent = (eventId, eventLocation, toUserId, toUserLocation) => {
	return (dispatch) => {
		dispatch({ type: TRANSFER_EVENT_REQUEST });
		EventsService.transferEvent(eventId, eventLocation, toUserId, toUserLocation)
			.then((result) => {
				dispatch({ type: TRANSFER_EVENT_SUCCESS, result });
				notification.success({
					message: "Success",
					description: `Event has been transferred to userId ${result.userId}`
				});
			})
			.catch(() => {
				dispatch({ type: TRANSFER_EVENT_ERROR });
				notification.error({
					message: "Error",
					description: `Failed to transfer event`
				});
			});
	};
};

export const UPDATE_EVENT_STEP_REQUEST = "UPDATE_EVENT_STEP_REQUEST";
export const UPDATE_EVENT_STEP_SUCCESS = "UPDATE_EVENT_STEP_SUCCESS";
export const UPDATE_EVENT_STEP_ERROR = "UPDATE_EVENT_STEP_ERROR";

export const updateEventBasics = (eventId, eventData) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_EVENT_STEP_REQUEST });
		try {
			const { spamCheck, event } = await EventsService.updateEventBasics(eventId, eventData);

			if (spamCheck.result === "verificationRequired") {
				dispatch({ type: UPDATE_EVENT_STEP_ERROR });
				dispatch({
					type: VERIFY_ACCOUNT_MODAL_OPEN,
					publishedLastOne: false
				});
			} else {
				dispatch({ type: UPDATE_EVENT_STEP_SUCCESS, event });
				onEventLocationSet(event.location)(dispatch);
				onOverrideLocationSet(event.location)(dispatch);
				dispatch(getEventUser(event.userId));
				notification.success({
					message: "Success",
					description: "Your event basics have been updated!"
				});

				// hack to get around the redux-form still being marked as isDirty after submitting.
				// Waiting before navigating the user away stops the withSaveCheck HOC from blocking the navigation.
				await wait(0.01);

				if (eventData.finishLater) {
					history.push("/console/dashboard");
				} else {
					goToNextStep();
				}
			}
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Your event failed to update"
			});
			dispatch({ type: UPDATE_EVENT_STEP_ERROR, error: err });
		}
	};
};

export const updateEventDetails = (eventId, eventData) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_EVENT_STEP_REQUEST });
		try {
			const { spamCheck, event } = await EventsService.updateEventDetails(eventId, eventData);
			if (spamCheck.result === "verificationRequired") {
				dispatch({ type: UPDATE_EVENT_STEP_ERROR });
				dispatch({
					type: VERIFY_ACCOUNT_MODAL_OPEN,
					publishedLastOne: false
				});
			} else {
				dispatch({ type: UPDATE_EVENT_STEP_SUCCESS, event });
				notification.success({
					message: "Success",
					description: "Your event details have been saved!"
				});

				// hack to get around the redux-form still being marked as isDirty after submitting.
				// Waiting before navigating the user away stops the withSaveCheck HOC from blocking the navigation.
				await wait(0.01);

				if (eventData.finishLater) {
					history.push("/console/dashboard");
				} else {
					goToNextStep();
				}
			}
		} catch (err) {
			dispatch({ type: UPDATE_EVENT_STEP_ERROR, error: err });
		}
	};
};

export const updateEventTicketTypes = (eventId, eventData, isPartOfSteps) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_EVENT_STEP_REQUEST });
		try {
			const event = await EventsService.updateEventTicketTypes(eventId, eventData);
			dispatch({ type: UPDATE_EVENT_STEP_SUCCESS, event });
			notification.success({
				message: "Success",
				description: "Your ticket types have been updated!"
			});
			if (isPartOfSteps) {
				// hack to get around the redux-form still being marked as isDirty after submitting.
				// Waiting before navigating the user away stops the withSaveCheck HOC from blocking the navigation.
				await wait(0.01);

				if (eventData.finishLater) {
					history.push("/console/dashboard");
				} else {
					history.push(`/console/my-events/${eventId}/info/basics`);
				}
			}
		} catch (err) {
			dispatch({ type: UPDATE_EVENT_STEP_ERROR, error: err });
		}
	};
};

export const completeEventSchedule = (eventId, finishLater) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_EVENT_STEP_REQUEST });
		try {
			const result = await EventsService.completeEventSchedule(eventId);
			dispatch({ type: UPDATE_EVENT_STEP_SUCCESS, event: result });
			notification.success({
				message: "Success",
				description: "Your dates have been updated!"
			});
			if (finishLater) {
				history.push("/console/dashboard");
			} else {
				goToNextStep();
			}
		} catch (err) {
			dispatch({ type: UPDATE_EVENT_STEP_ERROR, error: err });
		}
	};
};

export const updateEventSettings = (eventId, eventData) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		try {
			const result = await EventsService.updateEventSettings(eventId, eventData);
			dispatch({ type: SAVE_EVENT_SUCCESS, event: result });
			notification.success({
				message: "Success",
				description: "Your event settings have been updated!"
			});
		} catch (err) {
			dispatch({ type: SAVE_EVENT_ERROR, error: err });
		}
	};
};

export const updateEventLinkedInSettings = (eventId, eventData) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		try {
			const result = await EventsService.updateEventLinkedInSettings(eventId, eventData);
			dispatch({ type: SAVE_EVENT_SUCCESS, event: result });
			notification.success({
				message: "Success",
				description: "Your LinkedIn settings have been updated!"
			});
		} catch (err) {
			dispatch({ type: SAVE_EVENT_ERROR, error: err });
		}
	};
};

export const hubspotSync = (eventId) => {
	return async (dispatch) => {
		dispatch({ type: HUBSPOT_SYNC_REQUEST });
		try {
			const result = await EventsService.hubspotSync(eventId);
			dispatch({ type: HUBSPOT_SYNC_SUCCESS, event: result });
			notification.success({
				message: "Success",
				description: "Your event has been synced with HubSpot"
			});
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Your event was unable to be synced to HubSpot, please try again later or contact support"
			});
			dispatch({ type: HUBSPOT_SYNC_ERROR, error: err });
		}
	};
};

export const addSelfServicePayoutThreshold = (id, values) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		try {
			const event = await EventsService.addSelfServicePayoutThreshold(id, values);
			dispatch({ type: SAVE_EVENT_SUCCESS, event });
			notification.success({
				message: "Success",
				description: "Self-serve payout threshold set"
			});
		} catch (err) {
			dispatch({ type: SAVE_EVENT_ERROR, error: err?.message });
		}
	};
};

export const updateManageCapacityForm = (formName, ticketOrPackageId, updateType) => {
	return (dispatch, getState) => {
		const currentEvent = getState().currentEvent;

		const relevantTicketTypes = currentEvent.event.ticketTypes?.filter(
			(tt) => !tt.deleted && !tt.disabled && !tt.isDonation
		);
		const relevantPackagedTickets = currentEvent.event.packagedTickets?.filter((pt) => !pt.deleted && !pt.disabled);

		switch (updateType) {
			case "TOGGLE_MATCH_AUTOMATICALLY_FOR_PACKAGED_TICKETS": {
				const manageMappedCapacityForm = getState().form.manageMappedCapacity?.values;
				const index = relevantPackagedTickets.findIndex((tt) => tt._id === ticketOrPackageId);
				const formPackage = manageMappedCapacityForm?.packagedTickets?.[index];

				const newToggleValue = !formPackage.isMappedToSeatingMap;
				dispatch(change(formName, `packagedTickets[${index}].isMappedToSeatingMap`, newToggleValue));
				if (newToggleValue) {
					dispatch(change(formName, `packagedTickets[${index}].quantity`, formPackage?.mappedTables || 0));
					/**
					 * Updating underlying ticket types quantity (to its mappedSeats count) and isMappedToSeatingMap
					 */
					relevantPackagedTickets[index].tickets.forEach((underlyingTicket) => {
						const ticketIndex = relevantTicketTypes.findIndex(
							(t) => String(t._id) === String(underlyingTicket.ticketTypeId)
						);
						if (ticketIndex > -1) {
							dispatch(change(formName, `ticketTypes[${ticketIndex}].isMappedToSeatingMap`, newToggleValue));
							dispatch(
								change(
									formName,
									`ticketTypes[${ticketIndex}].quantity`,
									getState().form?.manageMappedCapacity?.values?.ticketTypes?.[ticketIndex]?.mappedSeats
								)
							);
						}
					});
				}

				//Note: have to get most recent form values again because the form values have changed
				const mostRecentFormPackagedTickets = getState().form.manageMappedCapacity?.values?.packagedTickets;
				const isAllPackagesAutoMatching = mostRecentFormPackagedTickets
					.filter((p) => p.mappedTables > 0)
					.every((p) => p.isMappedToSeatingMap);
				dispatch(change(formName, "select_all_packaged_tickets", isAllPackagesAutoMatching));

				//Note: have to get most recent form values again because the form values have changed
				const mostRecentFormTicketTypes = getState().form.manageMappedCapacity?.values?.ticketTypes;
				const isAllTicketsAutoMatch = mostRecentFormTicketTypes
					.filter((t) => t.mappedSeats > 0)
					.every((ticketType) => ticketType.isMappedToSeatingMap);
				dispatch(change(formName, "select_all_ticket_types", isAllTicketsAutoMatch));

				break;
			}

			case "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_PACKAGED_TICKETS": {
				const manageMappedCapacityForm = getState().form.manageMappedCapacity?.values;
				const newSelectAllPackagesValue = !manageMappedCapacityForm?.select_all_packaged_tickets;
				manageMappedCapacityForm.packagedTickets?.forEach((p, index) => {
					if (p.mappedTables > 0) {
						dispatch(change(formName, `packagedTickets[${index}].isMappedToSeatingMap`, newSelectAllPackagesValue));
						if (newSelectAllPackagesValue) {
							dispatch(
								change(
									formName,
									`packagedTickets[${index}].quantity`,
									manageMappedCapacityForm?.packagedTickets?.[index]?.mappedTables || 0
								)
							);
						}
					}
				});

				dispatch(change(formName, "select_all_packaged_tickets", newSelectAllPackagesValue));

				//Note: have to get most recent form values again because the form values have changed
				const mostRecentFormTicketTypes = getState().form.manageMappedCapacity?.values?.ticketTypes;
				const isAllTicketsAutoMatch = mostRecentFormTicketTypes
					.filter((t) => t.mappedSeats > 0)
					.every((ticketType) => ticketType.isMappedToSeatingMap);
				dispatch(change(formName, "select_all_ticket_types", isAllTicketsAutoMatch));

				break;
			}

			case "TOGGLE_MATCH_AUTOMATICALLY_FOR_TICKET_TYPES": {
				const manageMappedCapacityForm = getState().form.manageMappedCapacity?.values;
				const index = relevantTicketTypes.findIndex((t) => String(t._id) === String(ticketOrPackageId));
				const formTicketType = manageMappedCapacityForm?.ticketTypes?.[index];
				const newToggleValue = !formTicketType?.isMappedToSeatingMap;

				dispatch(change(formName, `ticketTypes[${index}].isMappedToSeatingMap`, newToggleValue));
				if (newToggleValue) {
					dispatch(change(formName, `ticketTypes[${index}].quantity`, formTicketType?.mappedSeats || 0));
				}

				//Note: have to get most recent form values again because the form values have changed
				const mostRecentFormTicketTypes = getState().form.manageMappedCapacity?.values?.ticketTypes;
				const isAllTicketsAutoMatch = mostRecentFormTicketTypes
					.filter((t) => t.mappedSeats > 0)
					.every((ticketType) => ticketType.isMappedToSeatingMap);

				dispatch(change(formName, "select_all_ticket_types", isAllTicketsAutoMatch));
				break;
			}

			case "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_TICKET_TYPES": {
				const manageMappedCapacityForm = getState().form.manageMappedCapacity?.values;
				const newSelectAllTicketsValue = !manageMappedCapacityForm?.select_all_ticket_types;
				manageMappedCapacityForm.ticketTypes?.forEach((currTicket, index) => {
					if (currTicket.mappedSeats > 0) {
						dispatch(change(formName, `ticketTypes[${index}].isMappedToSeatingMap`, newSelectAllTicketsValue));
						if (newSelectAllTicketsValue) {
							dispatch(
								change(
									formName,
									`ticketTypes[${index}].quantity`,
									manageMappedCapacityForm?.ticketTypes?.[index]?.mappedSeats || 0
								)
							);
						}
					}
				});

				dispatch(change(formName, "select_all_ticket_types", newSelectAllTicketsValue));
				break;
			}
		}
	};
};

export const updateManageCapacityFormOnPerformAction = (params) => {
	const ticketOrPackageIds = params.map((p) => String(p));
	return async (dispatch, getState) => {
		const currentEvent = getState().currentEvent;
		const currentForm = getState().form;
		const formName = "manageMappedCapacity";

		const relevantTicketTypes = currentEvent.event.ticketTypes?.filter(
			(tt) => !tt.deleted && !tt.disabled && !tt.isDonation
		);

		relevantTicketTypes?.forEach((ticketType) => {
			if (ticketOrPackageIds.includes(String(ticketType._id))) {
				const assignedSeatsCount = getMappedSeatCountByTicketId(ticketType, currentEvent);
				const ticketTypeIndex = relevantTicketTypes.findIndex((tt) => String(tt._id) === String(ticketType._id));
				updateTicketValuesInManageCapacityForm({
					ticketTypeIndex,
					assignedSeatsCount,
					dispatch,
					formName,
					currentForm
				});
			}
		});

		const relevantPackagedTickets = currentEvent.event.packagedTickets?.filter((pt) => !pt.deleted && !pt.disabled);
		relevantPackagedTickets?.forEach((packagedTicket) => {
			if (ticketOrPackageIds.includes(String(packagedTicket._id))) {
				const { assignedSeatsCount, assignedTableCount } = getMappedTableCountByPackageId(packagedTicket);
				const index = relevantPackagedTickets.findIndex((tt) => String(tt._id) === String(packagedTicket._id));

				/**
				 * isMappedToSeatingMap?: boolean;
				 * mappedTables?: number;
				 * mappedSeats?: number;
				 * mappedLockedSeats?: number;
				 */
				if (assignedTableCount) {
					dispatch(change(formName, `packagedTickets[${index}].mappedTables`, assignedTableCount));
					dispatch(change(formName, `packagedTickets[${index}].mappedSeats`, assignedSeatsCount));

					if (currentForm?.manageMappedCapacity?.values?.packagedTickets?.[index]?.isMappedToSeatingMap) {
						dispatch(change(formName, `packagedTickets[${index}].quantity`, assignedTableCount));
					}
				} else {
					dispatch(change(formName, `packagedTickets[${index}].mappedTables`, 0));
					dispatch(change(formName, `packagedTickets[${index}].isMappedToSeatingMap`, false));
					dispatch(change(formName, `packagedTickets[${index}].mappedSeats`, assignedSeatsCount));
				}

				//find underlying ticketTypes and update them:
				packagedTicket.tickets?.forEach((ticket) => {
					//ut = underlying ticket
					const utIndex = relevantTicketTypes.findIndex((t) => String(t._id) === String(ticket.ticketTypeId));
					if (utIndex > -1) {
						const utTicketType = relevantTicketTypes[utIndex];
						const utAssignedSeatsCount = getMappedSeatCountByTicketId(utTicketType, currentEvent);

						updateTicketValuesInManageCapacityForm({
							ticketTypeIndex: utIndex,
							assignedSeatsCount: utAssignedSeatsCount,
							dispatch,
							formName,
							currentForm
						});
					}
				});
			}
		});

		//Note: have to get most recent form values again because the form values have changed
		const mostRecentFormTicketTypes = getState().form.manageMappedCapacity?.values?.ticketTypes;
		const isAllTicketsAutoMatch = mostRecentFormTicketTypes
			.filter((t) => t.mappedSeats > 0)
			.every((ticketType) => ticketType.isMappedToSeatingMap);
		dispatch(change(formName, "select_all_ticket_types", isAllTicketsAutoMatch));

		const mostRecentFormPackagedTickets = getState().form.manageMappedCapacity?.values?.packagedTickets;
		const isAllPackagesAutoMatching = mostRecentFormPackagedTickets
			.filter((p) => p.mappedTables > 0)
			.every((p) => p.isMappedToSeatingMap);
		dispatch(change(formName, "select_all_packaged_tickets", isAllPackagesAutoMatching));
	};
};

function updateTicketValuesInManageCapacityForm({
	ticketTypeIndex,
	assignedSeatsCount,
	dispatch,
	formName,
	currentForm
}) {
	if (assignedSeatsCount > 0) {
		/**
		 * (means currently mapped)
		 * 		1. update form's mappedSeats to freshly fetched assignedSeatsCount
		 * 		2. update form's quantity to assignedSeatsCount
		 */
		dispatch(change(formName, `ticketTypes[${ticketTypeIndex}].mappedSeats`, assignedSeatsCount));
		if (currentForm?.manageMappedCapacity?.values?.ticketTypes?.[ticketTypeIndex]?.isMappedToSeatingMap) {
			dispatch(change(formName, `ticketTypes[${ticketTypeIndex}].quantity`, assignedSeatsCount));
		}
	} else {
		/**
		 * (means currently not mapped)
		 * 		update form's mappedSeats to 0
		 * 		update form's isMappedToSeatingMap to false
		 * 		(leave the quantity as is)
		 */
		dispatch(change(formName, `ticketTypes[${ticketTypeIndex}].mappedSeats`, 0));
		dispatch(change(formName, `ticketTypes[${ticketTypeIndex}].isMappedToSeatingMap`, false));
	}
}

function getMappedSeatCountByTicketId(ticketType, currentEvent) {
	const assignedSeatsCount = SeatingMapUtils.Config.ticketTypeIdCount[String(ticketType._id)] || 0;
	let assignedSeatGuessCount = assignedSeatsCount;
	//Guesstimate mapped seats for ticketType: to get accurate seat count, we need to also calculate seat counts in a table mapped to packages.
	const eventPackagedTickets = currentEvent.event.packagedTickets.filter((pt) => !pt.deleted && !pt.disabled);

	eventPackagedTickets.forEach((packageTicket) => {
		const underlyingTicket = packageTicket.tickets?.find((t) => String(t.ticketTypeId) === String(ticketType._id));
		if (underlyingTicket) {
			const totalQtyOfUnderlyingTickets = packageTicket.tickets.reduce((acc, t) => acc + t.quantity, 0);
			const ticketRatio = underlyingTicket.quantity / totalQtyOfUnderlyingTickets;
			const currPackageSeatCount = SeatingMapUtils.Config.ticketTypeIdCount[String(packageTicket._id)] || 0;
			const shareOfThisPackage = Math.ceil(currPackageSeatCount * ticketRatio);
			assignedSeatGuessCount += shareOfThisPackage;
		}
	});

	return assignedSeatGuessCount;
}

function getMappedTableCountByPackageId(packageTicket) {
	/**
	 *
	 * This below hacky guess work is to have a accurate count of
	 * ticketTypes that are a) mapped individually, and b) are part of a mapped package.
	 *
	 * We are naming these guesses because
	 * We do not have a SeatingMapUtils.Config.packageIdCount() in seatingMap yet.
	 * So count of tables(Assuming only packages can be mapped to tables) is only a division
	 * of total number of seats mapped to this packageId by sum of underlying ticket types in the same package.
	 *
	 * i.e.
	 * Family package has 4 tickets, 2 adult and 2 child tickets.
	 * And this packageId is mapped to 20 seats then:
	 * 20 / 4 = 5 tables are guessed to be mapped to this package.
	 *
	 * This is not perfect because we are assuming user accurately mapped their packages to correct number of seats.
	 */

	const assignedSeatsCount = SeatingMapUtils.Config.ticketTypeIdCount[String(packageTicket._id)] || 0;
	//Guesstimate mapped tables to this package: Assuming number of seats in each table is matching the number of underlying ticket types,
	const ticketsCountInPackage = packageTicket.tickets?.reduce((acc, tt) => {
		return acc + tt.quantity || 0;
	}, 0);
	const assignedTableCount = Math.ceil(assignedSeatsCount / ticketsCountInPackage);

	return {
		assignedSeatsCount,
		assignedTableCount
	};
}

export const updateResaleSettings = (id, newSettingData) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_EVENT_REQUEST });
		try {
			const event = await EventsService.updateResaleSetting(id, newSettingData);
			dispatch({ type: SAVE_EVENT_SUCCESS, event });
			notification.success({
				message: "Success",
				description: "Your resale settings have been updated!"
			});
		} catch (err) {
			dispatch({ type: SAVE_EVENT_ERROR, error: err?.message });
		}
	};
};
