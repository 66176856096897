import { notification } from "@/ui/antd";
import EventsService from "../../services/EventsService";
import TemplateService from "../../services/TemplateService";
import { toggleApplyToAllModal } from "./applyToAllActions";
import { GET_USER_EVENTS_SUCCESS } from "./eventActions";

export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR";

export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_ERROR = "GET_TEMPLATE_ERROR";

export const SAVE_TEMPLATE_REQUEST = "SAVE_TEMPLATE_REQUEST";
export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_ERROR = "SAVE_TEMPLATE_ERROR";

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_ERROR = "UPDATE_TEMPLATE_ERROR";

export const REMOVE_TEMPLATE_REQUEST = "REMOVE_TEMPLATE_REQUEST";
export const REMOVE_TEMPLATE_SUCCESS = "REMOVE_TEMPLATE_SUCCESS";
export const REMOVE_TEMPLATE_ERROR = "REMOVE_TEMPLATE_ERROR";

export const getTicketTypeTemplates = (userId, loadUserEvents) => {
	return (dispatch) => {
		dispatch({ type: GET_TEMPLATES_REQUEST });
		TemplateService.getTicketTypeTemplates(userId)
			.then((ticketTypes) => {
				if (loadUserEvents) {
					EventsService.getUserEvents(userId, "name _id", true)
						.then((userEvents) => {
							dispatch({ type: GET_USER_EVENTS_SUCCESS, events: userEvents });
							dispatch({
								type: GET_TEMPLATES_SUCCESS,
								templates: ticketTypes
							});
						})
						.catch((err) => {
							dispatch({ type: GET_TEMPLATES_ERROR, error: err });
						});
				} else {
					dispatch({
						type: GET_TEMPLATES_SUCCESS,
						templates: ticketTypes
					});
				}
			})
			.catch((error) => {
				dispatch({ type: GET_TEMPLATES_ERROR, error });
			});
	};
};
export const getTicketTypeTemplate = (userId, templateId) => {
	return (dispatch) => {
		dispatch({ type: GET_TEMPLATE_REQUEST });
		TemplateService.getTicketTypeTemplate(userId, templateId)
			.then((ticketType) => {
				dispatch({
					type: GET_TEMPLATE_SUCCESS,
					template: ticketType
				});
			})
			.catch((error) => {
				dispatch({ type: GET_TEMPLATE_ERROR, error });
			});
	};
};
export const updateTicketTypeTemplate = (userId, templateId, ticketTypeTemplateData, navigate) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_TEMPLATE_REQUEST });
		TemplateService.updateTicketTypeTemplate(userId, templateId, ticketTypeTemplateData)
			.then(() => {
				dispatch({ type: UPDATE_TEMPLATE_SUCCESS });
				navigate("/console/account/advanced/templates/ticket-type-templates");
			})
			.catch((error) => {
				dispatch({ type: UPDATE_TEMPLATE_ERROR, error });
			});
	};
};
export const saveTicketTypeTemplate = (userId, ticketTypeTemplateData, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_TEMPLATE_REQUEST });
		TemplateService.saveTicketTypeTemplate(userId, ticketTypeTemplateData)
			.then((ticketTypes) => {
				dispatch({
					type: SAVE_TEMPLATE_SUCCESS,
					templates: ticketTypes
				});
				navigate("/console/account/advanced/templates/ticket-type-templates");
			})
			.catch((error) => {
				dispatch({ type: SAVE_TEMPLATE_ERROR, error });
			});
	};
};
export const removeTicketTypeTemplate = (userId, templateId) => {
	return (dispatch) => {
		dispatch({ type: REMOVE_TEMPLATE_REQUEST });
		TemplateService.removeTicketTypeTemplate(userId, templateId)
			.then((ticketTypes) => {
				dispatch({
					type: REMOVE_TEMPLATE_SUCCESS,
					templates: ticketTypes
				});
			})
			.catch((error) => {
				dispatch({ type: REMOVE_TEMPLATE_ERROR, error });
			});
	};
};

export const getAdditionalQuestionsTemplates = (userId, loadUserEvents) => {
	return (dispatch) => {
		dispatch({ type: GET_TEMPLATES_REQUEST });
		TemplateService.getAdditionalQuestionsTemplates(userId)
			.then((additionalQuestions) => {
				if (loadUserEvents) {
					EventsService.getUserEvents(userId, "name _id")
						.then((userEvents) => {
							dispatch({ type: GET_USER_EVENTS_SUCCESS, events: userEvents });
							dispatch({
								type: GET_TEMPLATES_SUCCESS,
								templates: additionalQuestions
							});
						})
						.catch((err) => {
							dispatch({ type: GET_TEMPLATES_ERROR, error: err });
						});
				} else {
					dispatch({
						type: GET_TEMPLATES_SUCCESS,
						templates: additionalQuestions
					});
				}
			})
			.catch((error) => {
				dispatch({ type: GET_TEMPLATES_ERROR, error });
			});
	};
};
export const getAdditionalQuestionsTemplate = (userId, templateId) => {
	return (dispatch) => {
		dispatch({ type: GET_TEMPLATE_REQUEST });
		TemplateService.getAdditionalQuestionsTemplate(userId, templateId)
			.then((additionalQuestions) => {
				dispatch({
					type: GET_TEMPLATE_SUCCESS,
					template: additionalQuestions
				});
			})
			.catch((error) => {
				dispatch({ type: GET_TEMPLATE_ERROR, error });
			});
	};
};
export const updateAdditionalQuestionsTemplate = (userId, templateId, additionalQuestionsTemplateData) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_TEMPLATE_REQUEST });
		TemplateService.updateAdditionalQuestionsTemplate(userId, templateId, additionalQuestionsTemplateData)
			.then(() => {
				dispatch({ type: UPDATE_TEMPLATE_SUCCESS });
				notification.success({
					message: "Updated",
					description: "Checkout question template has been updated."
				});
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: "Failed to update the checkout question template."
				});
				dispatch({ type: UPDATE_TEMPLATE_ERROR, error });
			});
	};
};
export const saveAdditionalQuestionsTemplate = (userId, additionalQuestionsTemplateData, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_TEMPLATE_REQUEST });
		TemplateService.saveAdditionalQuestionsTemplate(userId, additionalQuestionsTemplateData)
			.then((additionalQuestions) => {
				dispatch({
					type: SAVE_TEMPLATE_SUCCESS,
					templates: additionalQuestions
				});
				notification.success({
					message: "Saved",
					description: "Checkout question template has been saved."
				});
				navigate("/console/account/advanced/templates/additional-questions-templates");
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: "Failed to save the checkout question template."
				});
				dispatch({ type: SAVE_TEMPLATE_ERROR, error });
			});
	};
};
export const removeAdditionalQuestionsTemplate = (userId, templateId) => {
	return (dispatch) => {
		dispatch({ type: REMOVE_TEMPLATE_REQUEST });
		TemplateService.removeAdditionalQuestionsTemplate(userId, templateId)
			.then((additionalQuestions) => {
				dispatch({
					type: REMOVE_TEMPLATE_SUCCESS,
					templates: additionalQuestions
				});
				notification.success({
					message: "Deleted",
					description: `Checkout question template has been deleted.`
				});
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: "Failed to delete the checkout question template."
				});
				dispatch({ type: REMOVE_TEMPLATE_ERROR, error });
			});
	};
};

export const getStylingTemplates = (userId, loadUserEvents) => {
	return (dispatch) => {
		dispatch({ type: GET_TEMPLATES_REQUEST });
		TemplateService.getStylingTemplates(userId)
			.then((stylings) => {
				if (loadUserEvents) {
					EventsService.getUserEvents(userId, "name _id", true)
						.then((userEvents) => {
							dispatch({ type: GET_USER_EVENTS_SUCCESS, events: userEvents });
							dispatch({
								type: GET_TEMPLATES_SUCCESS,
								templates: stylings
							});
						})
						.catch((err) => {
							dispatch({ type: GET_TEMPLATES_ERROR, error: err });
						});
				} else {
					dispatch({
						type: GET_TEMPLATES_SUCCESS,
						templates: stylings
					});
				}
			})
			.catch((error) => {
				dispatch({ type: GET_TEMPLATES_ERROR, error });
			});
	};
};

export const getStylingTemplate = (userId, templateId) => {
	return (dispatch) => {
		dispatch({ type: GET_TEMPLATE_REQUEST });
		TemplateService.getStylingTemplate(userId, templateId)
			.then((styling) => {
				dispatch({
					type: GET_TEMPLATE_SUCCESS,
					template: styling
				});
			})
			.catch((error) => {
				dispatch({ type: GET_TEMPLATE_ERROR, error });
			});
	};
};

export const updateStylingTemplate = (userId, templateId, stylingTemplateData) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_TEMPLATE_REQUEST });
		TemplateService.updateStylingTemplate(userId, templateId, stylingTemplateData)
			.then(() => {
				dispatch({ type: UPDATE_TEMPLATE_SUCCESS });
				dispatch(toggleApplyToAllModal({ stateToApplyToModal: true }));
			})
			.catch((error) => {
				dispatch({ type: UPDATE_TEMPLATE_ERROR, error });
			});
	};
};

export const saveStylingTemplate = (userId, stylingTemplateData, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_TEMPLATE_REQUEST });
		TemplateService.saveStylingTemplate(userId, stylingTemplateData)
			.then((stylings) => {
				dispatch({
					type: SAVE_TEMPLATE_SUCCESS,
					templates: stylings
				});

				navigate("/console/account/advanced/templates/styling-templates");
			})
			.catch((error) => {
				dispatch({ type: SAVE_TEMPLATE_ERROR, error });
			});
	};
};

export const removeStylingTemplate = (userId, templateId) => {
	return (dispatch) => {
		dispatch({ type: REMOVE_TEMPLATE_REQUEST });
		TemplateService.removeStylingTemplate(userId, templateId)
			.then((stylings) => {
				dispatch({
					type: REMOVE_TEMPLATE_SUCCESS,
					templates: stylings
				});
			})
			.catch((error) => {
				dispatch({ type: REMOVE_TEMPLATE_ERROR, error });
			});
	};
};
