import { Field } from "redux-form";
import { LegacyInputField } from "@/components/Fields";
import { useViewport } from "@/hooks/useViewport";

type Subfield = {
	name: string;
	label: string;
	value: string;
	required: boolean;
};

export const SubFields = ({ children = [] }: { children: Subfield[] }) => {
	const { isMobile } = useViewport();
	if (!children.length) {
		return null;
	}

	return (
		<div style={{ maxWidth: 265 }}>
			{children.map((child) => (
				<Field
					key={child.name}
					name={child.name}
					label={child.label}
					placeholder={child.value}
					component={LegacyInputField}
					style={{ margin: 0 }}
					required={child.required}
					size={isMobile ? "large" : "default"}
				/>
			))}
		</div>
	);
};
