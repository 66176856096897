import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { currency } from "../../utils/Format";
import Widget from "./Widget";

const ticketCols = [
	{
		title: "Event Date/Time",
		dataIndex: "date",
		key: "date"
	},
	{
		title: "Earnings",
		dataIndex: "earnings",
		key: "earnings",
		render: (value) => currency(value)
	},
	{
		title: "Sold",
		dataIndex: "sold",
		render: (sold, record) => `${sold}/${record.available}`
	}
];

class TicketSalesTable extends Component {
	render() {
		let { metrics } = this.props;
		return (
			<Widget title="Event occurrences" excludeTimeScale {...this.props}>
				<Table
					pagination={false}
					columns={ticketCols}
					dataSource={metrics}
					rowKey="startDate"
					scroll={{ x: "max-content" }}
				/>
			</Widget>
		);
	}
}

export default connect((state, ownProps) => ({
	metrics: state[ownProps.metricState].ticketsSoldByDate
}))(TicketSalesTable);
