import { RadioField, SwitchField } from "@/components/Fields";
import { dayjs } from "@/libs/dayjs";
import { Alert } from "@/ui/antd";
import { css } from "@emotion/css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import DateRange from "./components/DateRange";
import TimeRange from "./components/TimeRange";

export const formName = "editScheduleForm";

const disableStyle = css({
	".ant-switch": {
		margin: "0px !important"
	}
});

const Separator = () => (
	<div
		style={{
			borderBottom: "solid 1px #dedede",
			width: "100%",
			marginBottom: 16
		}}
	/>
);

const formatResult = (eventDate, onSubmit) => (rescheduleData) => {
	const startDateIncrease = dayjs(eventDate.startDate).diff(dayjs(rescheduleData.startDate));
	const endDateIncrease = dayjs(eventDate.endDate).diff(dayjs(rescheduleData.startDate));
	onSubmit({
		...rescheduleData,
		startDateIncrease,
		endDateIncrease
	});
};

let EditScheduleForm = ({ touch, onSubmit, occurrenceType, eventDate, dispatch, formExplanation }) => {
	useEffect(() => {
		dispatch(change(formName, "occurrenceRange.dateId", occurrenceType === "SINGLE" ? eventDate._id : null));
		dispatch(change(formName, "occurrenceRange.date", occurrenceType === "FUTURE" ? eventDate.startDate : null));
	}, [occurrenceType]);
	return (
		<form onSubmit={formatResult(eventDate, onSubmit)}>
			<TimeRange touch={touch} validatorDisable={["future"]} />
			<div className={disableStyle}>
				<Field label="Disable" name="disabled" inline component={SwitchField} />
			</div>
			<Separator />
			<Field
				label="Apply changes to"
				name="occurrenceRange.type"
				component={RadioField}
				vertical
				disabled={!eventDate.scheduleId}
				options={[
					{
						label: "This occurrence",
						value: "SINGLE"
					},
					{
						label: "All occurrences",
						value: "ALL"
					},
					{
						label: "This and all future occurrences",
						value: "FUTURE"
					},
					{
						label: "Custom range",
						value: "CUSTOM"
					}
				]}
			/>
			{occurrenceType === "CUSTOM" ? (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<DateRange
						touch={touch}
						startDateName="occurrenceRange.dateRange.start"
						endDateName="occurrenceRange.dateRange.end"
						vertical={false}
						validatorDisable={["future"]}
					/>
				</div>
			) : null}
			{formExplanation ? <Alert type="info" description={formExplanation} showIcon /> : null}
		</form>
	);
};

// Decorate with redux-form
EditScheduleForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: false,
	enableReinitialize: true,
	destroyOnUnmount: true,
	onSubmitFail: (errors) => {
		console.log("Event errors", errors);
		// scrollToErrors();
	}
})(EditScheduleForm);

export default connect((state, props) => {
	const selector = formValueSelector(formName);
	const initialValues = {
		occurrenceRange: {
			type: "SINGLE",
			dateId: props.eventDate._id,
			date: props.eventDate.startDate
		},
		...props.eventDate
	};
	const occurrenceType = selector(state, "occurrenceRange.type");
	return {
		initialValues,
		occurrenceType: selector(state, "occurrenceRange.type"),
		formExplanation: getFormExplanation({
			initialDates: props.eventDate,
			newDates: {
				startDate: selector(state, "startDate"),
				endDate: selector(state, "endDate")
			},
			occurrenceType,
			disabled: selector(state, "disabled"),
			dateRange: selector(state, "occurrenceRange.dateRange")
		})
	};
})(EditScheduleForm);

const getFormExplanation = ({ initialDates, newDates, occurrenceType, dateRange }) => {
	const startDatePrompt = getDurationString(newDates.startDate, initialDates.startDate);
	const endDatePrompt = getDurationString(newDates.endDate, initialDates.endDate);
	let occurrenceDescription = "";
	switch (occurrenceType) {
		case "ALL":
			occurrenceDescription = "All occurrences";
			break;
		case "FUTURE":
			occurrenceDescription = "This and all future occurrences";
			break;
		case "CUSTOM":
			if (!dateRange || !dateRange.start || !dateRange.end) {
				return "";
			}
			occurrenceDescription = `All occurrences between ${dayjs(dateRange.start).format("LLL")} and ${dayjs(
				dateRange.end
			).format("LLL")}`;
			break;
		default:
			occurrenceDescription = "This occurrence";
			break;
	}
	if (startDatePrompt && !endDatePrompt) {
		return `${occurrenceDescription} will now start ${startDatePrompt}.`;
	}
	if (endDatePrompt && !startDatePrompt) {
		return `${occurrenceDescription} will now finish ${endDatePrompt}.`;
	}
	if (startDatePrompt && endDatePrompt) {
		return `${occurrenceDescription} will now start ${startDatePrompt} and finish ${endDatePrompt}.`;
	}
	return "";
};

const getDurationString = (newDate, initialDate) => {
	const startDateDiff = dayjs(newDate).diff(initialDate);
	const startDateChangeAbs = Math.abs(startDateDiff);
	const backwards = startDateDiff < -1;

	const days = Math.floor(dayjs.duration(startDateChangeAbs, "milliseconds").asDays());
	const daysPlural = days > 1 ? "s" : "";

	const hours = Math.floor(dayjs.duration(startDateChangeAbs, "milliseconds").asHours() % 24);
	const hoursPlural = hours > 1 ? "s" : "";

	const minutes = Math.floor(dayjs.duration(startDateChangeAbs, "milliseconds").asMinutes() % 60);
	const minutesPlural = minutes > 1 ? "s" : "";

	if (!days && !hours && !minutes) {
		return "";
	}

	let directionString = backwards ? "earlier" : "later";
	let timeChangeList = [
		`${days ? `${days} day${daysPlural}` : ""}`,
		`${hours ? `${hours} hour${hoursPlural}` : ""}`,
		`${minutes ? `${minutes} minute${minutesPlural}` : ""}`
	]
		.filter((t) => t)
		.join(", ");
	const ind = timeChangeList.lastIndexOf(",");
	if (ind > -1) {
		timeChangeList = `${timeChangeList.substring(0, ind)}  and${timeChangeList.substring(ind + 1)}`;
	}
	return `${timeChangeList} ${directionString}`;
};
