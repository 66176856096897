import { ConsoleAction, identify, trackConsoleAction } from "@hx/analytics";
import store from "../state/store";
import axios from "./AxiosWrapper";

const getHeaders = () => {
	const { locations } = store.getState();
	return {
		headers: {
			"x-override-location": locations.overrideLocation
		}
	};
};

const UsersService = {
	async create(id, user) {
		const response = await axios.put(`users/sign-up`, user);
		identify({
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			mobile: user.mobile,
			location: user.location.toUpperCase(),
			host: true,
			humanitixMailListOptIn: true
		});
		trackConsoleAction(ConsoleAction.hostSignUp, {
			email: user.email,
			location: user.location.toUpperCase()
		});
		return response.data;
	},

	getUserInitials(userObject) {
		if (!userObject || !userObject.firstName) {
			return "";
		}
		const firstName = userObject.firstName;
		const lastName = userObject.lastName;
		const firstNameInitial = firstName.charAt(0);
		const lastNameInitial = lastName.charAt(0);
		return (firstNameInitial + lastNameInitial).toUpperCase();
	},

	async delete(id) {
		return await axios.delete(`users/user/${id}`);
	},

	async getUser() {
		return await axios.get(`users/user`);
	},

	async find(search, page) {
		const response = await axios.get(`admin/users/`, {
			params: { search, page }
		});
		return response.data;
	},

	async transferAccount(fromUserId, toUserId) {
		const response = await axios.post(`admin/users/transfer-account`, {
			fromUserId,
			toUserId
		});
		return response.data;
	},

	async get(id) {
		const response = await axios.get(`users/user/${id}`, { ...getHeaders() });
		return response.data;
	},

	async getFeeStructure(id, location) {
		const response = await axios.get(`users/user/${id}/fee-structure/${location}`);
		return response.data;
	},

	async disableUser(id, reason) {
		const response = await axios.post(`users/user/disable/${id}`, { reason });
		return response.data;
	},

	async enableUser(id, reason) {
		const response = await axios.post(`users/user/enable/${id}`, { reason });
		return response.data;
	},

	async update(id, user, location) {
		let options = false;
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}

		const response = await axios.post(`users/user/${id}`, user, options);
		return response.data;
	},

	async updateUserOnboardingData(id, organiserOnboardingInfo, location) {
		let options = false;
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}
		const response = await axios.post(
			`users/organiser-onboarding/${id}`,
			{ organiserOnboardingInfo, location },
			options
		);
		return response.data;
	},

	async userUpdate(user) {
		const response = await axios.post(`users/`, user);
		return response.data;
	},

	async manuallyVerifyEmail(id) {
		const response = await axios.post(`admin/users/verify-email/${id}`);
		return response.data;
	},

	async createNewApiKey(id) {
		const response = await axios.post(`api-key/new/${id}`);
		return response.data;
	},

	async saveBankAccounts(id, bankAccount) {
		const response = await axios.post(`users/user/bankaccounts/${id}`, {
			bankAccount
		});
		return response.data;
	},

	async getConfirmationToDeleteBankAccount(id, bankAccountId) {
		const response = await axios.get(`users/user/${id}/delete-bank-account-request/${bankAccountId}`, {
			params: { bankAccountId }
		});
		return response.data;
	},

	async deleteBankAccount(id, bankAccountId) {
		const response = await axios.delete(`users/user/${id}/delete-bank-account/${bankAccountId}`, {
			data: { bankAccountId }
		});
		return response.data;
	},

	async requestOwner(ownerId, location) {
		let options = {};
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}
		const response = await axios.get(`users/owner/${ownerId}`, options);
		return response.data;
	},

	async connectCallBack(code, service) {
		await axios.post(`users/connect-callback`, { code, service });
	},

	async disconnectHubspot() {
		const response = await axios.post(`users/hubspot/disconnect`);
		return response.data;
	},

	async saveHubspotSettings(values) {
		const response = await axios.post(`users/hubspot/settings`, values);
		return response.data;
	},

	async updateAdminPermiissions(id, newPermissions) {
		const response = await axios.post(`admin/users/${id}/update-admin-permissions`, {
			newPermissions
		});
		return response.data;
	},

	async addSelfServicePayoutThreshold(id, threshold, location) {
		const response = await axios.post(
			`admin/users/${id}/add-self-service-payout-threshold`,
			{
				threshold
			},
			{
				headers: {
					"x-override-location": location
				}
			}
		);
		return response.data;
	}
};
export default UsersService;
