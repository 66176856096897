import { css } from "@emotion/css";
import { Table } from "@/ui/antd";
import { useState } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import PageActions from "@/components/PageActions";
import SearchRefinements from "@/components/SearchRefinements";
import { H1 } from "@/components/text/Text";
import { getScanningCount } from "@/state/actions/checkInActions";
import { getTicketDisplayName } from "@/utils/Ticket";

const actionsWrapper = css({
	display: "flex",
	justifyContent: "space-between"
});

const groupByUserColumns = [
	{
		title: "User",
		dataIndex: "",
		key: "userName",
		render: (_, scanningBreakdown) => `${scanningBreakdown.firstName} ${scanningBreakdown.lastName}`
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email"
	}
];

const groupByDeviceColumns = [
	{
		title: "Device Name",
		dataIndex: "deviceName",
		key: "deviceName",
		render: (name) => name || "Console"
	},
	{
		title: "Device Id",
		dataIndex: "deviceId",
		key: "deviceId"
	}
];

const columns = [
	{
		title: "Checked In",
		dataIndex: "checkIn",
		key: "CheckedIn"
	},
	{
		title: "Checked Out",
		dataIndex: "checkOut",
		key: "CheckedOut"
	},
	{
		title: "Total",
		dataIndex: "count",
		key: "total"
	}
];

const ScanningCount = ({ getScanningCount, currentEvent, scanningCount, loading, error, groupByUser }) => {
	const { event } = currentEvent;
	const { _id, dates, datesUseCalendar, ticketTypes } = event;

	const load = () => getScanningCount(_id);

	useState(() => {
		load();
	}, []);

	const columnsForTable = groupByUser ? groupByUserColumns.concat(columns) : groupByDeviceColumns.concat(columns);
	return (
		<>
			<PageActions />
			<H1>Scanning count</H1>
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock>
					<div className={actionsWrapper}>
						<SearchRefinements
							load={load}
							dropdownId="scanningCount"
							openDirection="left"
							filterOptions={[
								{
									key: "eventDateIds",
									label: "Event Dates",
									mode: "multiple",
									kind: datesUseCalendar ? "eventDatePicker" : "select",
									placeholder: "All dates",
									options: dates.map((date) => ({
										...(datesUseCalendar ? date : {}),
										value: date._id,
										name: `${date.display.date}, ${date.display.time}`
									}))
								},
								{
									key: "ticketIds",
									label: "Select ticket types",
									mode: "multiple",
									placeholder: "All Ticket Types",
									options: ticketTypes.map((tt) => ({
										value: tt._id,
										name: getTicketDisplayName(tt)
									}))
								},
								{
									key: "groupBy",
									label: "Group by:",
									options: [
										{ value: "device", name: "Device" },
										{ value: "userId", name: "User" }
									]
								}
							]}
							defaultSearch={{}}
							hideSearch
							showRefinements
						/>
						<IconButton icon="refresh" onClick={load} tooltip="Refresh" ariaLabel="Refresh" />
					</div>
					<Table dataSource={scanningCount} columns={columnsForTable} pagination={false} />
				</ContentBlock>
			</LoadErrorView>
		</>
	);
};

export default connect(
	(state) => {
		const currentEvent = state.currentEvent;
		const scanningCount = state.checkIn?.scanningCount;
		const loading = state.checkIn?.loading;
		const error = state.checkIn?.error;
		const groupByUser = state.search?.groupBy === "userId";
		return { currentEvent, scanningCount, loading, error, groupByUser };
	},
	{ getScanningCount }
)(ScanningCount);
