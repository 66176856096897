import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import IconButton from "@/components/IconButton";
import EventSearch from "@/components/fields/EventSearchField";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { dayjs } from "@/libs/dayjs";
import { getLocationFromState } from "@/state/actions/locationActions";
import { useAppSelector } from "@/state/hooks";
import { AppDispatch } from "@/state/store";
import { trpc } from "@/trpc";
import { Alert, Button, notification } from "@/ui/antd";
import { email, required } from "@/utils/Validators";
import { css } from "@emotion/css";
import type { CreateScheduledReportInput, UpdateScheduledReportUpdatesInput } from "@hx/console/scheduledReports";
import { useNavigate } from "react-router";
import { Field, FieldArray, InjectedFormProps, change, formValueSelector, reduxForm } from "redux-form";
import { SendModalData } from "./Report";
import { convertDay, convertHour, dayOptions, getFormattedEventString, hourOptions, intervalOptions } from "./utils";

const formName = "scheduledReportsForm";

const emailRequired = required("Please enter an email address");
const emailValid = email("Please input a valid email");
const intervalRequired = required("Please select send interval");
const dayRequired = required("Please select a day");
const hourRequired = required("Please select an hour");

const columns = css({
	display: "flex",
	flexDirection: "row",
	div: {
		marginRight: 1.5
	},
	"@media(max-width: 575px)": {
		flexDirection: "column"
	}
});

const alertStyles = css({
	marginBottom: 16
});

const titleCss = css({
	maxWidth: "unset"
});

const Recipients = ({ fields }: { fields: any }) => (
	<>
		{fields.map((field: any, index: number) => (
			<div key={index} className={columns}>
				<div style={{ flex: 1 }}>
					<Field name={field} placeholder="Email" component={LegacyInputField} validate={[emailRequired, emailValid]} />
				</div>
				{fields.length === 1 ? null : (
					<IconButton
						icon="delete"
						onClick={() => fields.remove(index)}
						tooltip="Delete"
						showTooltip
						ariaLabel="Delete"
						disabled={undefined}
						small={undefined}
						medium={undefined}
						loading={undefined}
						tableIcon={undefined}
					/>
				)}
			</div>
		))}
		<Button onClick={() => fields.push("")}>Add email</Button>
	</>
);

type ScheduledReportFormProps = InjectedFormProps<unknown, unknown, string> & {
	dispatch: AppDispatch;
} & {
	onSendModalOpened: (data: SendModalData) => void;
	onBackClicked: () => void;
	scheduledId: string;
};

const selector = formValueSelector(formName);

const ScheduledReportFormComponent = ({
	handleSubmit,
	dispatch,
	onSendModalOpened,
	onBackClicked,
	scheduledId
}: ScheduledReportFormProps) => {
	const navigate = useNavigate();

	const eventIds = useAppSelector((state) => selector(state, "eventIds"));
	const interval = useAppSelector((state) => selector(state, "interval"));
	const day = useAppSelector((state) => selector(state, "day"));
	const hour = useAppSelector((state) => selector(state, "hour"));

	const createMutation = trpc.scheduledReports.createSchedule.useMutation({
		onSuccess: (response) => {
			notification.success({
				message: "Success",
				description: response
			});
			navigate(`/console/reports/scheduled`);
		},
		onError: (error) => {
			notification.error({
				message: "Error",
				description: error.message ?? "Failed to delete scheduled report"
			});
		}
	});

	const updateMutation = trpc.scheduledReports.updateSchedule.useMutation({
		onSuccess: (response) => {
			notification.success({
				message: "Success",
				description: response
			});
			navigate(`/console/reports/scheduled`);
		},
		onError: (error) => {
			notification.error({
				message: "Error",
				description: error.message ?? "Failed to delete scheduled report"
			});
		}
	});

	const isNewSchedule = !!(scheduledId === "new");

	const save = (values: any) => {
		const schedule = {
			...values,
			hour: Number(values.hour),
			day: Number(values.day)
		};
		const action = isNewSchedule ? create : update;
		action(schedule);
	};

	const create = (values: any) => {
		const timezone = dayjs.tz.guess();
		const schedule: CreateScheduledReportInput = {
			...values,
			timezone,
			location: getLocationFromState(true)
		};

		createMutation.mutate(schedule);
	};

	const update = (schedule: UpdateScheduledReportUpdatesInput) => {
		updateMutation.mutate({
			_id: scheduledId,
			updates: schedule
		});
	};

	const handleEventOnChange = (newEventIds: string[]) => {
		dispatch(change(formName, "eventIds", newEventIds));
	};

	const hourFormatted = convertHour(hour);
	const dayFormatted = convertDay(day);
	const eventsFormatted = getFormattedEventString(eventIds);

	return (
		<form onSubmit={handleSubmit(save)}>
			<ContentBlock>
				<p className={titleCss}>
					Schedule an Event sales update to be emailed daily or weekly to a list of recipients.
				</p>
				<div
					style={{
						marginBottom: 12
					}}
				>
					<EventSearch
						label="Select live event(s)"
						description="Which events will this report include?"
						handleEventOnChange={handleEventOnChange}
						placeholder="All events"
						selectedValues={eventIds}
						location={getLocationFromState(true)}
						input={{ name: "eventIds" }}
						styleOverride={{ width: "100%", minWidth: "none", float: "none" }}
					/>
					<Alert
						type="info"
						showIcon
						message="You can select events that are live and that ended less than 2 days ago."
					/>
				</div>

				<InputLabel label="Send at" description="How often would you like to receive this report?" />
				<div className={columns}>
					<Field name="interval" component={SelectField} options={intervalOptions} validate={intervalRequired} />
					{interval === "weekly" ? (
						<Field
							name="day"
							component={SelectField}
							options={dayOptions}
							validate={dayRequired}
							format={(input: number | string) => {
								return typeof input === "number" ? convertDay(input) : input;
							}}
						/>
					) : null}
					<Field
						name="hour"
						component={SelectField}
						options={hourOptions}
						validate={hourRequired}
						format={(input: number | string) => {
							return typeof input === "number" ? convertHour(input) : input;
						}}
					/>
				</div>

				<InputLabel label="Send to" description="Who would you like to send the report to" />
				<FieldArray name="emails" component={Recipients} />

				<ContentBlock style={{ marginTop: 26 }}>
					<p>Scheduled report summary</p>
					<div className={alertStyles}>
						<Alert
							type="info"
							showIcon
							message=""
							description={
								<div>
									This report contains {eventsFormatted}, and will be sent {interval}{" "}
									{interval === "weekly" && ` on ${dayFormatted}`} at {hourFormatted}.
								</div>
							}
						/>
					</div>
					<Button aria-label="Save" onClick={() => onSendModalOpened({ _id: scheduledId, eventIds, interval })}>
						Send test email
					</Button>
				</ContentBlock>
			</ContentBlock>
			<FormBar>
				<Button
					style={{
						float: "right"
					}}
					type="primary"
					htmlType="submit"
					size="large"
				>
					Save
				</Button>
				<Button aria-label="Cancel" onClick={onBackClicked}>
					Cancel
				</Button>
			</FormBar>
		</form>
	);
};

const ScheduledReportForm = reduxForm<any, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(ScheduledReportFormComponent);

export { ScheduledReportForm };
