import { FC } from "react";
import { Select as AntdSelect, SelectProps as AntdSelectProps } from "antd";
import styled from "@emotion/styled";

const StyledSelect = styled(AntdSelect)`
	max-width: 100%;
	min-height: 36px;

	.ant-select-selector {
		border-radius: var(--rounded-sm);
	}
`;

export const Select: FC<AntdSelectProps> = (props) => {
	return <StyledSelect {...props} />;
};
