import { Field } from "redux-form";
import { FileUploadFieldHosted, LegacyInputField, RadioField } from "@/components/Fields";
import { required, url } from "@/utils/Validators";
import ElementHeader from "../ElementHeader";

const nameRequired = required("a name is required");
const linkRequired = required("a link is required");
const fileRequired = required("A file is required");
const urlValid = url("link must be a valid url");
const File = (props) => {
	const { fieldPath, file } = props;
	return (
		<>
			<ElementHeader {...props} name="File" />
			<div>
				<Field name={`${fieldPath}.name`} label="Name" component={LegacyInputField} required validate={nameRequired} />
				<Field
					name={`${fieldPath}.type`}
					label="Upload or Link"
					component={RadioField}
					button
					defaultValue="upload"
					options={[
						{
							label: "Upload",
							value: "file"
						},
						{
							label: "Link",
							value: "link"
						}
					]}
				/>
				{file.type === "link" ? (
					<Field
						name={`${fieldPath}.link`}
						label="Link"
						component={LegacyInputField}
						required
						validate={[linkRequired, urlValid]}
					/>
				) : (
					<Field
						name={`${fieldPath}.file`}
						label="File"
						component={FileUploadFieldHosted}
						required
						validate={[fileRequired]}
					/>
				)}
			</div>
			<hr />
		</>
	);
};

export default File;
