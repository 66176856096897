/*
import   FullCalendar from "@fullcalendar/react";
import   dayGridPlugin from "@fullcalendar/daygrid";
import   interactionPlugin from "@fullcalendar/interaction";
import   listPlugin from "@fullcalendar/list";
import   momentTimezonePlugin from "@fullcalendar/moment-timezone";
import   timeGridPlugin from "@fullcalendar/timegrid";

yo needs to be in the order dont change it or it breaks lmao
the vscode auto formatter for imports will also cuck it so
dont do that either ily
*/

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
//end important ordering

import React, { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { completeEventSchedule, displayVirtualEventHubModal } from "@/state/actions/eventActions";
import {
	createSchedule,
	deleteSchedule,
	linkCalendarApi,
	loadDates,
	reschedule
} from "@/state/actions/schedulerActions";
import FinishLater from "../eventCreation/FinishLater";
import StepBackButton from "../eventCreation/StepBackButton";
import { wrapperMenu, wrapperNoMenu } from "../eventCreation/utils/commonStyles";
import { checkInWizard } from "../eventCreation/utils/eventCreationUtils";
import CalenderHeader from "./CalendarHeader";
import { RecurringDatesOverrides } from "./RecurringDatesOverrides";
import DeleteResultModal from "./modals/DeleteResultModal";
import EditEventModal from "./modals/EditEventModal";
import EventDateModal from "./modals/EventDateModal";
import NewScheduleModal from "./modals/NewScheduleModal";
import RemoveDateModal from "./modals/RemoveDateModal";
import RescheduleResultModal from "./modals/RescheduleResultModal";
import { dayjs } from "@/libs/dayjs";

const eventDateConversion = (timezone) => (dates) => {
	return dates.map((date) => {
		const isInPast = dayjs(date.startDate).isBefore(dayjs());
		return {
			id: date._id,
			start: dayjs(date.startDate).tz(timezone).format(),
			end: dayjs(date.endDate).tz(timezone).format(),
			title: `${dayjs(date.startDate).tz(timezone).format("h:mm a")} - ${dayjs(date.endDate)
				.tz(timezone)
				.format("h:mm a")}`,
			allDay: false,
			editable: !isInPast,
			extendedProps: {
				dbDate: date
			},
			startEditable: false
		};
	});
};

const defaultState = {
	newModal: {
		open: false,
		date: new Date()
	},
	eventDateModal: {
		open: false,
		eventDate: null
	},
	editDateModal: {
		open: false,
		eventDate: null
	},
	deleteDateModal: {
		open: false,
		eventDate: null
	}
};

class RecurringDates extends Component {
	calendarRef = React.createRef();
	calendarApi;

	constructor() {
		super();
		this.state = { ...defaultState };
	}

	componentDidMount() {
		this.calendarApi = this.calendarRef.current.getApi();
		this.forceUpdate();
		linkCalendarApi(this.calendarApi);
		this.props.loadDates(true);
	}

	switchTo = () => {
		this.calendarApi.changeView("timeGridDay");
	};

	handleDateClick = ({ dateStr }) => {
		this.setState({
			...defaultState,
			newModal: {
				open: true,
				date: dateStr
			}
		});
	};

	onDeleteClick = (date) => {
		this.setState({
			...defaultState,
			deleteDateModal: { open: true, eventDate: date }
		});
	};

	onNewSchedule = (schedule) => {
		this.props.createSchedule(schedule);
		this.setState({
			...defaultState
		});
	};

	onDateClick = (eventClickArg) => {
		const { isAdmin } = this.props;
		const eventDate = eventClickArg.event.extendedProps.dbDate;
		if (dayjs(eventDate.startDate).isAfter(dayjs()) || !this.props.currentEvent.event.ticketSold || isAdmin) {
			this.setState({
				...defaultState,
				eventDateModal: {
					open: true,
					eventDate: eventClickArg.event.extendedProps.dbDate
				}
			});
		}
	};

	onDateEdit = (date) => {
		this.setState({
			...defaultState,
			editDateModal: {
				open: true,
				eventDate: date
			}
		});
	};

	onEditSchedule = (rescheduleData) => {
		this.props.reschedule(rescheduleData);
		this.setState({
			...defaultState
		});
	};

	onDeleteSchedule = (deleteScheduleData) => {
		this.props.deleteSchedule(deleteScheduleData);
		this.setState({
			...defaultState
		});
	};

	onModalClose = () => {
		this.setState({
			...defaultState
		});
	};

	render() {
		const { scheduler, currentEvent, completeEventSchedule } = this.props;

		const inWizard = checkInWizard(currentEvent.event);
		const { newModal, eventDateModal, editDateModal, deleteDateModal } = this.state;
		const onDayGridMonth = !this.calendarApi || this.calendarApi.view.type === "dayGridMonth";
		const { timezone, _id } = currentEvent.event;
		return (
			<div className={inWizard ? wrapperNoMenu : wrapperMenu}>
				<ContentBlock allowOverflow>
					<div className={RecurringDatesOverrides}>
						{this.calendarApi ? (
							<CalenderHeader calendarApi={this.calendarApi} onNewSchedule={this.handleDateClick} />
						) : null}
						<br />
						<FullCalendar
							selectable
							ref={this.calendarRef}
							slotDuration={{ hours: 1 }}
							events={eventDateConversion(timezone)(scheduler.dates)}
							dateClick={this.handleDateClick}
							plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
							timeZone={timezone}
							eventClick={this.onDateClick}
							initialView="dayGridMonth"
							aspectRatio={window.innerWidth <= 600 ? 0.46 : 1.52}
							headerToolbar={false}
							allDaySlot={false}
							titleFormat={{
								year: "numeric",
								month: "short",
								day: "numeric"
							}}
							eventLimit={true}
							dayMaxEventRows={2}
							eventStartEditable={false}
							contentHeight="auto"
						/>
					</div>
					<NewScheduleModal
						onDayGridMonth={onDayGridMonth}
						open={newModal.open}
						date={newModal.date}
						onNewSchedule={this.onNewSchedule}
						onModalClose={this.onModalClose}
					/>
					<EventDateModal
						open={eventDateModal.open}
						eventDate={eventDateModal.eventDate}
						onDateEdit={this.onDateEdit}
						onDeleteClick={this.onDeleteClick}
						timezone={currentEvent.event.timezone}
						onModalClose={this.onModalClose}
					/>
					<EditEventModal
						open={editDateModal.open}
						eventDate={editDateModal.eventDate}
						onEditSchedule={this.onEditSchedule}
						onModalClose={this.onModalClose}
					/>
					<RemoveDateModal
						open={deleteDateModal.open}
						eventDate={deleteDateModal.eventDate}
						onDeleteSchedule={this.onDeleteSchedule}
						onModalClose={this.onModalClose}
					/>
					<DeleteResultModal />
					<RescheduleResultModal />
				</ContentBlock>
				<FormBar>
					<FinishLater saveFunction={() => completeEventSchedule(_id, true)} />
					<StepBackButton inWizard={inWizard} />
					<LegacyButton
						style={{
							float: "right"
						}}
						dataCy="next-step"
						type="primary"
						htmlType="submit"
						id="submitButton"
						size="large"
						onClick={() => completeEventSchedule(_id, false)}
					>
						Save & continue
					</LegacyButton>
				</FormBar>
			</div>
		);
	}
}

export default RecurringDates = connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			scheduler: state.scheduler,
			isAdmin: state.auth?.user?.isAdmin
		};
	},
	{
		createSchedule,
		loadDates,
		reschedule,
		deleteSchedule,
		displayVirtualEventHubModal,
		completeEventSchedule
	}
)(RecurringDates);
