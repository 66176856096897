import mapMarker from "@/assets/map-marker.svg";

const style = {
	width: "100%",
	height: "240px",
	lineHeight: "240px",
	background: "rgb(232, 232, 232)"
};
const styleInner = {
	textAlign: "center",
	textTransform: "uppercase",
	fontWeight: "bold",
	verticalAlign: "middle",
	display: "inline-block",
	lineHeight: "14px",
	width: "100%"
};
const imgStyles = {
	display: "block",
	margin: "auto auto 20px",
	height: "80px"
};

const NoLocation = () => {
	return (
		<div style={style}>
			<div style={styleInner}>
				<img style={imgStyles} alt="no map" src={mapMarker} />
				No Map
			</div>
		</div>
	);
};

export default NoLocation;
