import ImageUpload from "../ImageUpload";
import InputWrapper from "./helpers/InputWrapper";

interface IImageUploadFieldProps {
	input: any;
	label: string;
	description: string;
	inline: boolean;
	required: boolean;
	tooltip?: string;
	tooltipIcon?: boolean;
	tooltipIconOnClick?: () => void;
	meta?: {
		touched?: boolean;
		error?: any;
	};
}

const ImageUploadField = (props: IImageUploadFieldProps) => {
	const {
		input,
		label,
		description,
		inline,
		required,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		meta,
		...rest
	} = props;

	const { touched, error } = meta ?? {};
	return (
		<InputWrapper
			label={label}
			description={description}
			inline={inline}
			touched={touched}
			error={error}
			id={input.name}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<ImageUpload {...input} {...rest} />
		</InputWrapper>
	);
};

export default ImageUploadField;
