import { css } from "@emotion/css";
import { ReactNode } from "react";

interface IH6Props {
	children: ReactNode;
	style?: Record<string, unknown>;
	bold?: boolean;
}

const H6 = ({ bold = true, children, style = {} }: IH6Props) => {
	const customStyle = css({
		fontWeight: bold ? 600 : 400,
		fontSize: 10,
		margin: 0,
		marginBottom: 4,
		...style
	});
	return <h6 className={customStyle}>{children}</h6>;
};

export default H6;
