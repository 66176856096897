import { CodeBlock, Grid } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type GridProps = {
	children: ReactNode;
	columnMax?: string; // default: 1fr
	columnMin?: string; // default: 220px
	gap?: string; // default: 16px
	padding?: string; // default: 0
	rowMax?: string; // default: auto
	rowMin?: string; // default: 0px
};`;

const sample = `import { Grid } from "@/ui";

<Grid>
  <div>Item 1</div>
  <div>Item 2</div>
  <div>Item 3</div>
  <div>Item 4</div>
  <div>Item 5</div>
</Grid>`;

const gridItemStyles = (backgroundUrl = "https://ca.slack-edge.com/T78SH188M-U04JU23PXLN-0bc12e3c2bb7-512") => ({
	backgroundClip: "padding-box",
	backgroundImage: `url(${backgroundUrl})`,
	backgroundPosition: "center",
	backgroundSize: "cover",
	border: "4px dashed purple",
	borderRadius: "8px",
	overflow: "hidden"
});

export const GridPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<Grid>
				<div style={gridItemStyles("https://ca.slack-edge.com/T78SH188M-U04JU23PXLN-0bc12e3c2bb7-512")}></div>
				<div style={gridItemStyles("https://ca.slack-edge.com/T78SH188M-UPDHBP000-87e0ecadc86a-512")}></div>
				<div style={gridItemStyles("https://ca.slack-edge.com/T78SH188M-US9G6ERE0-bdb52408f826-512")}></div>
				<div style={gridItemStyles("https://ca.slack-edge.com/T78SH188M-U054FMNQRBP-81c0895cc952-512")}></div>
				<div style={gridItemStyles("https://ca.slack-edge.com/T78SH188M-U8XNVV0P8-a0f686b5c06d-512")}></div>
			</Grid>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
