import { useCurrentUser } from "@/hooks/useCurrentUser";
import { CurrentUserSchema } from "@hx/console";
import { ComponentType, FC, ReactElement, cloneElement } from "react";

export type InjectedAuthHooksProps = {
	isAdmin: boolean;
	user: CurrentUserSchema;
	isUserLoading: boolean;
};

type WrappedComponentProps = {
	children: ReactElement;
};

const WrappedComponent: FC<WrappedComponentProps> = ({ children }) => {
	const { isAdmin, user, isLoading } = useCurrentUser();

	// Create a unique key that changes when user or isAdmin changes
	const key = `${isAdmin}-${user?.id}-${isLoading}`;

	return cloneElement(children, { key, isAdmin, user, isUserLoading: isLoading });
};

export const withAuthHooks = <P extends InjectedAuthHooksProps>(ChildComponent: ComponentType<P>) => {
	return (props: Omit<P, keyof InjectedAuthHooksProps>) => (
		<WrappedComponent>
			<ChildComponent {...(props as P)} />
		</WrappedComponent>
	);
};
