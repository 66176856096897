import { Alert } from "@/ui/antd";
import loadScript from "load-script";
import { useEffect, useState } from "react";
import LoadErrorView from "./LoadErrorView";

const divId = "zapierInt";
const Zapier = () => {
	const url = `https://zapier.com/apps/embed/widget.js?services=humanitix&html_id=${divId}`;
	const [error, setError] = useState<Error>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadScript(url, (err: Error | null) => {
			setLoading(false);
			err && setError(err);
		});
	}, []);

	return (
		<div style={{ minHeight: 550 }}>
			<Alert
				showIcon
				type="info"
				message="Only events created under your account will be synced via Zapier. Events shared with you will NOT work with Zapier."
				style={{ marginBottom: "20px" }}
			/>
			<LoadErrorView error={error} loading={loading}>
				<div id={divId} />
			</LoadErrorView>
		</div>
	);
};

export default Zapier;
