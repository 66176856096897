import GlobalReportService from "../../services/GlobalReportService";
import FileDownload from "../../utils/FileDownload";
import { getLocationFromState } from "./locationActions";
export const RESET_GLOBAL_REPORTS = "RESET_GLOBAL_REPORTS";
export const UPDATE_REPORT = "UPDATE_REPORT";

export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";
export const GET_REPORT_CSV_REQUEST = "GET_REPORT_CSV_REQUEST";
export const GET_REPORT_CSV_SUCCESS = "GET_REPORT_CSV_SUCCESS";
export const GET_REPORT_CSV_ERROR = "GET_REPORT_CSV_ERROR";
export const GET_REPORT_PDF_REQUEST = "GET_REPORT_PDF_REQUEST";
export const GET_REPORT_PDF_SUCCESS = "GET_REPORT_PDF_SUCCESS";
export const GET_REPORT_PDF_ERROR = "GET_REPORT_PDF_ERROR";

export const resetGlobalReports = () => {
	return (dispatch) => {
		dispatch({ type: RESET_GLOBAL_REPORTS });
	};
};

export const getReport = (reportName, page = 1) => {
	return (dispatch, getState) => {
		const state = getState();
		const location = getLocationFromState(true);
		const queryBy = state.search;

		dispatch({ type: GET_REPORT_REQUEST });
		GlobalReportService.getReport(reportName, queryBy, page, location)
			.then((result) => {
				dispatch({
					type: GET_REPORT_SUCCESS,
					result,
					page
				});
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: `Failed to load report`;
				dispatch({ type: GET_REPORT_ERROR, error: message });
			});
	};
};

export const getReportCsv = (reportName) => {
	return async (dispatch, getState) => {
		const state = getState();
		const location = getLocationFromState(true);
		const queryBy = state.search;

		dispatch({ type: GET_REPORT_CSV_REQUEST });
		await GlobalReportService.getReportCsv(reportName, queryBy, location);
		dispatch({ type: GET_REPORT_CSV_SUCCESS });
	};
};

export const getReportPdf = (reportName) => {
	const filename = `${reportName}.pdf`;
	return (dispatch, getState) => {
		const state = getState();
		const location = getLocationFromState(true);
		const queryBy = state.search;

		dispatch({ type: GET_REPORT_PDF_REQUEST });
		GlobalReportService.getReportPdf(reportName, queryBy, location)
			.then((data) => {
				dispatch({ type: GET_REPORT_PDF_SUCCESS });
				FileDownload.saveAs(data, filename);
			})
			.catch(() => {
				dispatch({ type: GET_REPORT_PDF_ERROR, error: "Unable to generate PDF" });
			});
	};
};
