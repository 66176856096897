import { css } from "@emotion/css";
import { ColourV3Functions } from "@hx/colours";
import { Col, Row } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ColourField, ImageUploadField, RadioField } from "../components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { openDeleteModel, updateOrganiser } from "../state/actions/organiserActions";
import { getLocalisedText } from "../utils/Format";
import { HelpIds, OpenHelpHandler } from "../utils/Help";
import { OrganiserSkeleton } from "./OrganiserSkeleton";

const { generateCssString } = ColourV3Functions;

const formName = "organiserStyles";
const previewCss = css`
	& {
		padding: 10px;
	}
	& > div {
		box-shadow: 0 0 5px #0000007f;
	}
`;

const colorSchemeOptions = [
	{
		value: "light",
		label: "Light"
	},
	{
		value: "dark",
		label: "Dark"
	},
	{
		value: "auto",
		label: "Adaptive"
	}
];

let OrganiserStyles = ({ organisers, handleSubmit, updateOrganiser, openDeleteModel, organiser }) => {
	const colourText = getLocalisedText("colour");
	const [primaryColor, setPrimaryColor] = useState(organiser.primaryColor || organiser.bannerBackground);
	const [colorScheme, setColorScheme] = useState(organiser.colorScheme || "light");
	const [cssVariables, setCssVariables] = useState(
		generateCssString(primaryColor, { namespace: "colorv3ConsoleHostStylingPreview", withRelative: false })
	);

	useEffect(() => {
		setCssVariables(
			generateCssString(primaryColor, { namespace: "colorv3ConsoleHostStylingPreview", withRelative: false })
		);

		setColorScheme(colorScheme);
	}, [colorScheme, primaryColor]);

	const handleSave = (organiserData) => {
		if (organisers?.selected?._id) {
			updateOrganiser(organisers.selected._id, organiserData);
		}
	};
	return (
		<form id={formName} style={{ margin: 4 }}>
			<Row>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Field
						name="logo"
						label="Logo"
						uploadDescription=""
						description="Use an image with a square ratio (e.g. 500px by 500px), legible when displayed in small size"
						component={ImageUploadField}
						aspectRatio={1}
						imageStyleOverride={{
							width: "100%",
							maxHeight: "230px",
							margin: "auto",
							paddingTop: undefined,
							aspectRatio: "2"
						}}
					/>

					<Field
						name="bannerImage"
						label="Banner"
						uploadDescription=""
						description={
							<>
								Use an image with a 2:1 ratio (e.g. 3200px by 1600px).{" "}
								<a onClick={OpenHelpHandler(HelpIds.bannerImage)}>Learn more</a>
							</>
						}
						component={ImageUploadField}
						aspectRatio={2}
						style={{ maxWidth: 300 }}
						imageStyleOverride={{
							width: "100%",
							maxHeight: "230px",
							margin: "auto",
							paddingTop: undefined,
							aspectRatio: "2"
						}}
					/>
					<Field
						name="colorScheme"
						label="Light / Dark mode"
						description="Choose Your Look: Light, Dark, or Adaptive Mode (Adapts to visitor's device preference)"
						component={RadioField}
						button
						style={{ width: "100%", minWidth: "none" }}
						options={colorSchemeOptions}
						onChange={(e) => setColorScheme(e.target.value)}
					/>
					<Field
						name="primaryColor"
						label={`Primary ${colourText}`}
						description={`Choose a primary ${colourText} for your event host profile`}
						component={ColourField}
						onChange={(e) => setPrimaryColor([e[0], e[1], e[2], e[3], e[4], e[5], e[6]].join(""))}
					/>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<div data-color-scheme={colorScheme} className={previewCss}>
						<style>{cssVariables}</style>
						<div>
							<OrganiserSkeleton />
						</div>
					</div>
				</Col>
			</Row>

			<div>
				<LegacyButton
					style={{
						float: "right"
					}}
					onClick={handleSubmit(handleSave)}
					type="primary"
					htmlType="submit"
					ariaLabel="Update"
					size="large"
				>
					{organisers?.selected?._id ? "Update" : "Create host"}
				</LegacyButton>
				<LegacyButton
					type="warning"
					ariaLabel="Delete host"
					size="large"
					onClick={() => openDeleteModel(organisers.selected._id)}
				>
					Delete
				</LegacyButton>
			</div>
		</form>
	);
};

OrganiserStyles = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(OrganiserStyles);

export default connect(
	(state) => {
		let initialValues = state.organisers.selected;
		const defaultTheme = {
			ticketBackgroundColor: "#FFA680",
			background: { type: "color" },
			primaryColor: "#ffb18f",
			baseColor: "#353337",
			colorScheme: "light"
		};
		initialValues = {
			...defaultTheme,
			...initialValues
		};
		return {
			initialValues: initialValues,
			organisers: state.organisers,
			organiser: initialValues
		};
	},
	{
		updateOrganiser,
		openDeleteModel
	}
)(OrganiserStyles);
