import { History } from "history";
import { FC, memo, useLayoutEffect, useState } from "react";
import { BrowserRouterProps as NativeBrowserRouterProps, Router } from "react-router-dom";

export interface BrowserRouterProps extends Omit<NativeBrowserRouterProps, "window"> {
	history: History;
}

export const BrowserRouter: FC<BrowserRouterProps> = memo((props) => {
	const { history, ...restProps } = props;

	const [state, setState] = useState({
		action: history.action,
		location: history.location
	});

	useLayoutEffect(() => {
		const unlisten = history.listen(({ location, action }) => {
			setState({ location, action });
		});
		return unlisten;
	}, [history]);

	return <Router {...restProps} location={state.location} navigationType={state.action} navigator={history} />;
});
