import type { PreviewMassRefundResponse } from "@hx/console/massRefunds";
import { Steps } from "@/ui/antd";
import { FC, useEffect, useState } from "react";
import CreateMassRefundForm from "./CreateMassRefundForm";
import { MassRefundReview } from "./MassRefundReview";

export type SharedProps = {
	isLoading: boolean;
	massRefundData?: PreviewMassRefundResponse | null;
	setIsLoading: (isLoading: boolean) => void;
	setMassRefundData: (data: PreviewMassRefundResponse) => void;
};

type MassRefundStepsProps = {
	skipToReviewEstimation?: boolean;
};

export const MassRefundSteps: FC<MassRefundStepsProps & SharedProps> = ({
	isLoading,
	massRefundData,
	setIsLoading,
	setMassRefundData,
	skipToReviewEstimation
}) => {
	const [step, setStep] = useState(0);

	useEffect(() => {
		if (skipToReviewEstimation) {
			setStep(1);
		}
	}, [skipToReviewEstimation]);

	const onPreviousStep = () => {
		const newStep = Math.max(step - 1, 0);
		setStep(newStep);
	};

	const onNextStep = () => {
		const newStep = step + 1;
		setStep(newStep);
	};

	const showPreview = step === 1;

	return (
		<>
			<Steps
				current={step}
				style={{ margin: "24px auto", maxWidth: 350 }}
				items={[{ title: "Setup" }, { title: "Preview" }, { title: "Confirm" }]}
				size="small"
				responsive={false}
			/>
			{showPreview && (
				<MassRefundReview
					isLoading={isLoading}
					massRefundData={massRefundData}
					onNextStep={onNextStep}
					onPreviousStep={onPreviousStep}
					setIsLoading={setIsLoading}
					setMassRefundData={setMassRefundData}
					step={step}
				/>
			)}
			{!showPreview && (
				<CreateMassRefundForm
					history={history}
					isLoading={isLoading}
					massRefundData={massRefundData}
					onNextStep={onNextStep}
					onPreviousStep={onPreviousStep}
					setIsLoading={setIsLoading}
					setMassRefundData={setMassRefundData}
					step={step}
				/>
			)}
		</>
	);
};
