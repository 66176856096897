import { PreviewMockSite, PreviewMode } from "@/ui";
import { ResponsiveModal } from "@/ui";
import styled from "@emotion/styled";
import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

const GridContainer = styled.div`
	column-gap: 64px;
	display: grid;
	grid-auto-rows: minmax(100px, auto);
	grid-template-columns: 1fr 2fr;
	min-height: 100%;
	row-gap: 32px;
	width: 100%;

	@media (max-width: 968px) {
		grid-template-columns: 1fr;
	}
`;

const Heading = styled.h3`
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	margin-bottom: 16px;
`;

const FlexColumn = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
`;

type WidgetBuilderModalProps = {
	configContents: ReactNode;
	isOpen: boolean;
	noGutters?: boolean;
	onCancel: () => void;
	previewContents: ReactNode;
	previewMode: PreviewMode;
	setPreviewMode: (mode: PreviewMode) => void;
	showSkeletons?: boolean;
	title: string;
};

export const WidgetBuilderModal: FC<WidgetBuilderModalProps> = ({
	configContents,
	isOpen,
	noGutters,
	onCancel,
	previewContents,
	previewMode,
	setPreviewMode,
	title
}) => {
	return createPortal(
		<ResponsiveModal
			closable
			closeButtonStyle="lg"
			footer={false}
			fullscreen
			onCancel={onCancel}
			open={isOpen}
			header={title}
		>
			<GridContainer>
				<FlexColumn>{configContents}</FlexColumn>
				<FlexColumn>
					<Heading>Preview</Heading>
					<PreviewMockSite noGutters={noGutters} previewMode={previewMode} setPreviewMode={setPreviewMode}>
						{previewContents}
					</PreviewMockSite>
				</FlexColumn>
			</GridContainer>
		</ResponsiveModal>,
		document.body
	);
};
