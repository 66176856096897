import { css } from "@emotion/css";
import { isDatePast } from "../utils/Dates";

const status = css`
	display: inline-block;
`;

const dotSpan = css`
	height: 8px;
	width: 8px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 4px;
`;

const statusLabelAndColor = {
	published: {
		label: "Published",
		color: "#157e77"
	},
	archived: {
		label: "Archived",
		color: "#5a626c"
	},
	draft: {
		label: "Draft",
		color: "#eaa200"
	},
	salesEnded: {
		label: "Sales ended",
		color: "#5a626c"
	}
};

const getStatesInfo = (endDate: string, published: boolean, isArchived: boolean) => {
	const isSaleEnded = isDatePast(endDate);
	if (isArchived) return statusLabelAndColor.archived;
	if (!published) return statusLabelAndColor.draft;
	if (isSaleEnded) return statusLabelAndColor.salesEnded;
	return statusLabelAndColor.published;
};

interface IEventStatusProps {
	endDate: string;
	published: boolean;
	isArchived: boolean;
}

const EventStatus = ({ endDate, published, isArchived }: IEventStatusProps) => {
	const statusInfo = getStatesInfo(endDate, published, isArchived);
	return (
		<div className={status} style={{ color: statusInfo.color }}>
			<span className={dotSpan} style={{ backgroundColor: statusInfo.color }} />
			{statusInfo.label}
		</div>
	);
};

export default EventStatus;
