import { css } from "@emotion/css";
import { Select, Spin } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { findEvents, getEventsById } from "@/state/actions/eventActions";
import { ReduxState as GlobalReduxState } from "@/state/store";

const styles = {
	descriptionLabel: {
		fontWeight: 300,
		display: "block"
	},
	eventsField: {
		float: "left",
		width: 300,
		fontWeight: "bold",
		marginRight: 20,
		display: "block",
		".ant-select": {
			marginTop: 4,
			fontWeight: "normal ",
			display: "block"
		}
	}
};

let searchEventTimeout: any;

interface IEventSearchFieldProps {
	findEvents?: (page: any, search: any, toAppend: boolean, location: string) => void;
	getEventsById?: any;
	eventSearchState?: any;
	label?: string;
	description?: string;
	handleEventOnChange: any;
	placeholder: string;
	styleOverride?: any;
	selectedValues?: any;
	input: any;
	location: string;
}

const EventSearchField = ({
	findEvents,
	getEventsById,
	eventSearchState,
	label,
	description,
	handleEventOnChange,
	placeholder,
	styleOverride,
	selectedValues,
	input,
	location
}: IEventSearchFieldProps) => {
	const [loading, setLoading] = useState(false);
	const debouncedFindEvents = (page = 1, query?: string, toAppend = false) => {
		clearTimeout(searchEventTimeout);
		searchEventTimeout = setTimeout(() => {
			findEvents &&
				findEvents(
					page,
					{
						query,
						sortOrder: "relevant",
						filter: "all"
					},
					toAppend,
					location
				);
			setLoading(false);
		}, 200);
	};

	const load = () => {
		setLoading(true);
		if (selectedValues && selectedValues.length) {
			getEventsById(selectedValues);
		} else {
			debouncedFindEvents();
		}
	};

	useEffect(() => {
		eventSearchState.events.unshift({ _id: "all", name: "All events" });
		load();
	}, []);

	const handleEventOnSearch = (query: string) => {
		if (query.trim().length > 2) {
			debouncedFindEvents(1, query);
		}
	};

	const handleEventsOnBlur = () => {
		debouncedFindEvents(1, "");
	};

	const handleEventOnScroll = (e: any) => {
		const { page } = eventSearchState;
		const target = e.target;
		if (!loading && Math.round(target.scrollTop + target.offsetHeight) >= target.scrollHeight) {
			const nextPage = Math.round(parseInt(page) + 1);
			debouncedFindEvents(nextPage, "", true);
			target.scrollTo(0, target.scrollHeight);
		}
	};

	if (!eventSearchState.events) {
		eventSearchState.events = [];
	}

	const selectEventChildren = eventSearchState.events.map((item: { _id: string; name: string }) => {
		return {
			_id: item._id,
			value: item._id,
			name: item.name,
			label: item.name,
			"data-cy": item.name
		};
	});

	const getOptions = () => {
		if (eventSearchState.loading) {
			return [
				...selectEventChildren,
				{
					label: (
						<>
							<Spin size="small" /> &nbsp;&nbsp;
							<span style={{ color: "#323232" }}>Loading...</span>
						</>
					)
				}
			];
		}

		return selectEventChildren;
	};

	const dropdownOptions = getOptions();

	return (
		<div className={css({ ...styles.eventsField, ...styleOverride })}>
			<label>{label}</label>
			<label style={{ fontWeight: "300", display: "block" }}>{description}</label>

			<Select
				id={input.name}
				onChange={handleEventOnChange}
				onSearch={handleEventOnSearch}
				onBlur={handleEventsOnBlur}
				onPopupScroll={handleEventOnScroll}
				filterOption={false}
				mode="multiple"
				notFoundContent={eventSearchState.loading ? <Spin size="small" /> : false}
				loading={eventSearchState.loading}
				value={selectedValues}
				placeholder={placeholder}
				options={dropdownOptions}
			/>
		</div>
	);
};

export default connect(
	(state: GlobalReduxState) => {
		return {
			eventSearchState: state.events,
			search: state.search
		};
	},
	{
		findEvents,
		getEventsById
	}
)(EventSearchField);
