import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import privateSvg from "../assets/ic-private-event.svg";

const spanStyling = css({
	color: "#fff",
	":hover": {
		textDecoration: "underline"
	}
});

const privateImg = css({
	height: 20,
	padding: 0,
	display: "inline-block",
	marginRight: 4
});

const PrivateIndicator = () => {
	return (
		<Tooltip
			title={
				<span className={spanStyling}>Private event - Event is not listed on Humanitix.com or in embedded widgets</span>
			}
		>
			<img className={privateImg} src={privateSvg} alt="private event" />
		</Tooltip>
	);
};

export default PrivateIndicator;
