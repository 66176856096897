import { css } from "@emotion/css";
import { Component } from "react";
import { Field } from "redux-form";
import { InputNumberField, SelectField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { currencyFormat, currencyParse, percentFormat, percentParse } from "@/utils/InputFormatters";
import { getTicketDisplayName } from "@/utils/Ticket";
import { checkLength, required } from "@/utils/Validators";

const fixedRequired = required("A fixed fee is required");
const percentRequired = required("A percent fee is required");
const paymentRequired = required("A payment fee is required");
const ticketsRequired = checkLength("One ticket type is required", 0, ">");

const appliesToIsUnique = (appliesTo, allValues, _form, key) => {
	const { ticketFees } = allValues;
	const index = Number(key.split(".")[1]);
	if (ticketFees.length < 2 || !appliesTo) {
		return undefined;
	}
	let result;
	for (let i = 0; i < ticketFees.length; i++) {
		const ticketFee = ticketFees[i];
		if (i !== index && ticketFee.appliesTo) {
			const overlap = appliesTo.filter((value) => ticketFee.appliesTo.includes(value));
			if (overlap && overlap.length) {
				result = "Ticket types can only be in one group";
			}
		}
	}
	return result;
};

export default class TicketFeeRows extends Component {
	addFees = () => {
		const { fields } = this.props;
		fields.push({ name: "hello" });
	};
	removeFees = (index) => {
		const { fields } = this.props;
		fields.remove(index);
	};

	render() {
		const { ticketFees, currentEvent } = this.props;

		const ticketOptions = currentEvent.event.ticketTypes
			.filter((t) => !t.isDonation && !t.deleted)
			.map((ticket) => {
				return { value: ticket._id, label: getTicketDisplayName(ticket) };
			});

		const columns = [
			{
				title: "Fees",
				dataIndex: "fees",
				key: "fees",
				render: (_, __, index) => {
					return (
						<>
							<Field
								label="Booking fee fixed"
								description="The fixed fee charged per ticket"
								name={`ticketFees.${index}.fixed`}
								component={InputNumberField}
								min={0}
								precision={2}
								formatter={currencyFormat}
								parser={currencyParse}
								validate={fixedRequired}
							/>

							<Field
								label="Booking fee percent"
								name={`ticketFees.${index}.percent`}
								description="The percent fee charged per ticket"
								component={InputNumberField}
								precision={2}
								min={0}
								max={100}
								formatter={percentFormat}
								parser={percentParse}
								validate={percentRequired}
							/>

							<Field
								label="Payment fee percent"
								description="A payment fee which is uncapped"
								name={`ticketFees.${index}.paymentFeePercent`}
								component={InputNumberField}
								precision={2}
								allowsNullValue
								formatter={percentFormat}
								parser={percentParse}
								min={0}
								max={100}
								validate={paymentRequired}
							/>
						</>
					);
				}
			},
			{
				title: "Rebates",
				dataIndex: "Rebates",
				key: "Rebates",
				render: (_, __, index) => {
					return (
						<>
							<Field
								label="Rebate fixed"
								description="A fixed rebate added on top of the fixed fee"
								name={`ticketFees.${index}.rebates.fixed`}
								component={InputNumberField}
								precision={2}
								allowsNullValue
								formatter={currencyFormat}
								parser={currencyParse}
								min={0}
							/>

							<Field
								label="Rebate percent"
								name={`ticketFees.${index}.rebates.percent`}
								description="The percent charged on top of the fee percentage"
								component={InputNumberField}
								allowsNullValue
								formatter={percentFormat}
								precision={2}
								parser={percentParse}
								min={0}
								max={100}
							/>
						</>
					);
				}
			},
			{
				title: "Tickets",
				dataIndex: "tickets",
				key: "tickets",
				render: (_, __, index) => {
					return (
						<Field
							mode="multiple"
							name={`ticketFees.${index}.appliesTo`}
							label="Applies to"
							placeholder="Please select"
							required
							component={SelectField}
							options={ticketOptions}
							style={{ width: "100%" }}
							validate={[ticketsRequired, appliesToIsUnique]}
						/>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				width: 140,
				render: (_, __, index) => {
					return (
						<TableActions containerStyle={{ position: "relative" }}>
							<IconButton
								icon="delete"
								onClick={() => this.removeFees(index)}
								tooltip="Delete"
								showTooltip
								ariaLabel="Delete"
							/>
						</TableActions>
					);
				}
			}
		];
		return (
			<div>
				<ResponsiveTable
					pagination={false}
					columns={columns}
					locale={{ emptyText: "No ticket fees" }}
					dataSource={ticketFees}
					rowKey="_id"
					changeRow={this.changeRow}
				/>

				<div
					className={css({
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: 12,
						"@media(max-width: 600px)": {
							display: "block",
							".ant-btn": {
								display: "block",
								margin: "0 auto",
								marginBottom: 12,
								width: "100%",
								":last-child": {
									marginBottom: 0
								}
							}
						}
					})}
				>
					<ButtonsBar>
						<LegacyButton
							onClick={() => this.addFees()}
							icon={{ name: "plus", left: true }}
							type="action"
							ariaLabel="Add fees"
						>
							Add fees
						</LegacyButton>
					</ButtonsBar>
				</div>
			</div>
		);
	}
}
