import { useAppDispatch } from "@/state/hooks";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { FullPageWrap, LoadingSpinner } from "../../components/AppBlocks";
import { H2 } from "../../components/text/Text";
import { connectCallBack } from "../../state/actions/userActions";
import { getUrlParameter } from "../../utils/Urls";

const ConnectHubspot: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const code = getUrlParameter("code");
		dispatch(connectCallBack(code, "hubspot", navigate));
	}, []);

	return (
		<FullPageWrap>
			<div>
				<H2 style={{ textAlign: "center" }}>Connecting HubSpot</H2>
				<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
			</div>
		</FullPageWrap>
	);
};

export default ConnectHubspot;
