import { FC } from "react";
import humanitixLogo from "@/assets/humanitix_logo.svg";
import { Button } from "@/ui/Button";
import PaymentInfo from "@/events/event/payments/components/gateways/PaymentInfo";
import { useNavigate } from "react-router";
import { GatewayEnabledWrapper } from "../GatewayComponents";

type PaymentPlanProps = {
	allowed: boolean;
	eventId: string;
	enabled: boolean;
};

const PaymentPlansCard: FC<PaymentPlanProps> = ({ allowed, eventId, enabled }) => {
	if (!allowed) {
		return null;
	}

	const navigate = useNavigate();

	return (
		<PaymentInfo
			id="payment-plans"
			name="Humanitix payment plans"
			image={humanitixLogo}
			imageSubtitle="payment plans"
			description={
				<div>
					Offer buyers the ability to pay off their order in instalments. Hosts have the flexibility to control deposit
					amounts, instalment frequencies, due dates, cancellation terms etc.
				</div>
			}
			// --- Empty string is intentionally passed as selected ---
			selected=""
		>
			{enabled ? (
				<GatewayEnabledWrapper>
					<div>Payment plan enabled</div>
				</GatewayEnabledWrapper>
			) : null}

			<Button
				variant="tertiary"
				aria-label="Select Humanitix payment plans"
				onClick={() => navigate(`/console/my-events/${eventId}/payments/gateways/payment-plans`)}
				style={{ margin: "auto", maxWidth: "240px", width: "100%" }}
			>
				Set up
			</Button>
		</PaymentInfo>
	);
};

export default PaymentPlansCard;
