import { Table } from "@/ui/antd";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { HumaniDate } from "@hx/dates";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton as HButton } from "@/components/buttons/LegacyButton";
import LocationService from "../../services/LocationService";
import { currency, sentenceCase } from "../../utils/Format";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

const columns = [
	{
		title: "Order name",
		dataIndex: "orderName",
		width: 120,
		key: "orderName",
		render: (orderName, record) => {
			const link = `/console/promote/gift-cards/order/${record.orderId}`;
			return <Link to={link}>{orderName}</Link>;
		}
	},
	{
		title: "Gift card name",
		dataIndex: "giftCardTypeName",
		key: "giftCardTypeName",
		render: (name) => {
			return <span>{name}</span>;
		}
	},
	{
		title: "Gift card code",
		dataIndex: "giftCardCode",
		key: "giftCardCode"
	},
	{
		title: "Buyer name",
		dataIndex: "buyerFullName",
		key: "buyerFullName"
	},
	{
		title: "Buyer email",
		dataIndex: "buyerEmail",
		key: "buyerEmail"
	},
	{
		title: "Date",
		dataIndex: "createdAtDisplay",
		key: "createdAtDisplay",
		render: (date) => new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.dateTime()
	},
	{
		title: "Value",
		dataIndex: "price",
		key: "price",
		render: (value) => {
			return value ? currency(value) : "$0.00";
		}
	},
	{
		title: "Used",
		dataIndex: "totalUsedCredit",
		key: "totalUsedCredit",
		render: (value) => {
			return value ? currency(value) : "$0.00";
		}
	},
	{
		title: "Remaining",
		dataIndex: "remaining",
		key: "remaining",
		render: (value) => {
			return value ? currency(value) : "$0.00";
		}
	},
	{
		title: "Recipient name",
		dataIndex: "recipientName",
		key: "recipientName"
	},
	{
		title: "Recipient email",
		dataIndex: "recipientEmail",
		key: "recipientEmail"
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (value) => {
			return sentenceCase(value);
		}
	}
];

const GiftCards = ({ load, loadPage, downloadCsv, reportState }) => {
	const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = reportState;
	return (
		<LoadErrorView loading={loading} error={error} retryAction={load}>
			<ContentBlock style={{ padding: 0 }}>
				<ReportsFilter loadReport={load} selectEvent={false} />
				<ReportContent>
					<div
						style={{
							marginBottom: 16,
							textAlign: "right",
							display: "flex",
							justifyContent: "flex-end"
						}}
					>
						{results?.length > 0 && (
							<HButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
								{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
							</HButton>
						)}
					</div>

					<Table
						columns={columns}
						dataSource={results}
						loading={loading}
						locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
						rowKey="_id"
						scroll={{ x: 1500 }}
						pagination={{
							showSizeChanger: false,
							total: count,
							current: page,
							onChange: loadPage
						}}
					/>
				</ReportContent>
			</ContentBlock>
		</LoadErrorView>
	);
};

export default withReportProvider(GiftCards, "Gift cards", "gift-card");
