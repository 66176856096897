import { Input } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";
import { FC, useEffect, useState } from "react";

const { TextArea } = Input;

type TextAreaFieldProps = {
	description?: string;
	disabled?: boolean;
	inline?: boolean;
	input: any;
	label: string;
	meta: {
		error: string;
		touched: boolean;
	};
	placeholder?: string;
	required?: boolean;
	rows: number;
	style?: any;
	tooltip?: string;
	tooltipIcon?: boolean;
	tooltipIconOnClick?: () => void;
};

const TextAreaField: FC<TextAreaFieldProps> = ({
	description,
	disabled,
	inline,
	input,
	label,
	meta: { touched, error },
	placeholder,
	required,
	rows,
	style,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick
}) => {
	// Manage the internal state because antd and redux form don't play nice together
	const [value, setValue] = useState(input.value);

	useEffect(() => {
		if (input.value !== value) {
			setValue(input.value);
		}
	}, [input.value]);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
		input.onChange(e);
	};

	return (
		<InputWrapper
			description={description}
			error={error}
			id={input.name}
			inline={inline}
			label={label}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			touched={touched}
		>
			<TextArea
				id={input.name}
				{...input}
				disabled={disabled}
				onChange={handleChange}
				placeholder={placeholder}
				rows={rows}
				style={style}
				value={value}
			/>
		</InputWrapper>
	);
};

export default TextAreaField;
