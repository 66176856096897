import { TaxSettings } from "@hx/utilities";
import { Component } from "react";
import { Field } from "redux-form";
import { LegacyInputField, InputNumberField, RadioField } from "@/components/Fields";
import { required } from "@/utils/Validators";

class DGRSettings extends Component {
	nameRequired = required("A charity name is required");
	donationPercentRequired = required("A donation percent is required");

	render() {
		const { dgrEnabled, location, fromPath = "" } = this.props;
		const taxIdName = TaxSettings.getGlobalTaxInfo(location).taxIdName;
		this.taxIdRequired = required(`An charity ${taxIdName} is required`);

		return (
			<div>
				<Field
					name={`${fromPath}dgrInfo.enabled`}
					label="Issue DGR receipts"
					description="Would you like to issue DGR (tax-deductible receipt) for your event"
					component={RadioField}
					button
					options={[
						{
							label: "No",
							value: false
						},
						{
							label: "Yes",
							value: true
						}
					]}
				/>
				{dgrEnabled ? (
					<div>
						<Field
							name={`${fromPath}dgrInfo.charityName`}
							label="Charity Name"
							component={LegacyInputField}
							validate={this.nameRequired}
						/>
						<Field
							name={`${fromPath}dgrInfo.taxId`}
							label={`Charity ${taxIdName}`}
							component={LegacyInputField}
							validate={this.taxIdRequired}
						/>
						<Field name={`${fromPath}dgrInfo.description`} label="DGR description" component={LegacyInputField} />
						<Field
							name={`${fromPath}dgrInfo.donationPercent`}
							label="Splitting tickets into partial DGR receipts"
							description="Please note this will affect all tickets not just donations,  for example if you have a $300 ticket and want 75% to be tax-deductible put 75% below. Please note that this will affect all tickets in this event if you have multiple ticket options."
							component={InputNumberField}
							min={0}
							max={100}
							validate={this.donationPercentRequired}
						/>
					</div>
				) : null}
			</div>
		);
	}
}

export default DGRSettings;
