import { getConfig } from "@/config";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Alert, Button, Dropdown, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { MailChimpConnectImage } from "../../assets/integrations/connect-to-mailchimp";
import { ContentBlock } from "../../components/AppBlocks";
import Breadcrumb from "../../components/Breadcrumb";
import LoadErrorView from "../../components/LoadErrorView";
import PageHeading from "../../components/PageHeading";
import LinkButton from "../../components/buttons/LinkButton";
import { P } from "../../components/text/Text";
import { getAudiences, testConnection } from "../../state/actions/mailchimpActions";
import { getUser, saveUser } from "../../state/actions/userActions";
import { HelpIds } from "../../utils/Help";
import MailchimpSettings from "./MailchimpSettings";

const style = {
	row: css({
		display: "flex",
		"@media (max-width: 1200px)": {
			flexDirection: "column-reverse"
		}
	}),
	colStyle: css({
		display: "flex",
		flexWrap: "wrap",
		flexGrow: 1,
		justifyContent: "center",
		alignContent: "center"
	}),
	imgStyle: css({
		width: 360,
		height: 120,
		"@media (max-width: 575px)": {
			marginTop: 20
		},
		"@media (max-width: 420px)": {
			width: 240,
			height: 80
		}
	}),
	button: css({
		marginTop: 10,
		border: "1px solid #353337 !important",
		color: "#353337",
		fontWeight: "bold",
		"&:hover": {
			color: "#FFF",
			backgroundColor: "#FFA680",
			border: "1px solid #FFA680 !important"
		}
	})
};

class Mailchimp extends Component {
	constructor(props) {
		super(props);
		const client_id = getConfig("MAILCHIMP_CLIENT_ID");
		const redirect_uri = encodeURIComponent(
			`${getConfig("CONSOLE_URL").replace("localhost", "127.0.0.1")}/connect-mailchimp`
		);
		this.connectUrl = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}`;
		this.state = {
			disconnectConfirmOpen: false
		};
	}

	testConnection = () => {
		const { userId, testConnection } = this.props;
		testConnection(userId);
	};

	confirmDisconnect = () => {
		this.setState({ disconnectConfirmOpen: true });
	};

	disconnectApproved = () => {
		const { userId, saveUser } = this.props;
		this.setState({ disconnectConfirmOpen: false });
		saveUser(userId, {
			mailchimpSettings: {
				...this.props.currentUser.user.mailchimpSettings,
				accessToken: undefined
			}
		});
	};
	disconnectCancelled = () => {
		this.setState({ disconnectConfirmOpen: false });
	};

	loadUser = () => {
		const { userId, getUser } = this.props;
		getUser(userId);
	};

	componentDidMount() {
		this.loadUser();
	}

	render() {
		const { currentUser, userId } = this.props;

		const { disconnectConfirmOpen } = this.state;

		const isConnected =
			currentUser.user && currentUser.user.mailchimpSettings && currentUser.user.mailchimpSettings.accessToken;

		const accountName = isConnected ? currentUser.user.mailchimpSettings.accountName : "your account";

		const connectionFailed = this.props.mailchimp.connectionFailed;

		const connectionTesting = this.props.mailchimp.connectionTesting;

		const showConnection = isConnected && !connectionFailed && !connectionTesting;

		const showConnectButton = !isConnected && !currentUser.loading;

		const showSettings = isConnected && !currentUser.loading;
		const menuItems = [
			{
				key: "reconnect",
				label: (
					<a href={this.connectUrl} aria-label="Reconnect to Mailchimp">
						Reconnect
					</a>
				)
			},
			{
				key: "disconnect",
				label: (
					<LinkButton onClick={this.confirmDisconnect} ariaLabel="Disconnect Mailchimp">
						Disconnect
					</LinkButton>
				)
			}
		];

		return (
			<LoadErrorView
				loading={!currentUser.user && currentUser.loading}
				error={currentUser.error}
				retryAction={() => this.loadUser()}
			>
				<Breadcrumb prevPath="/console/account/advanced/integrations" prevTitle="Integrations" currTitle="Mailchimp" />
				<PageHeading
					title="Mailchimp"
					helpButton={{
						link: HelpIds.mailchimp,
						title: "Help - Mailchimp"
					}}
				/>
				<ContentBlock>
					<Modal
						title="Confirm Disconnect"
						open={disconnectConfirmOpen}
						okText="Yes, disconnect"
						onOk={this.disconnectApproved}
						onCancel={this.disconnectCancelled}
					>
						<P>Are you sure you wish to disconnect your account from Mailchimp</P>
					</Modal>
					{isConnected ? (
						<Dropdown menu={{ items: menuItems }} trigger={["click"]} style={{ float: "right" }}>
							<Button size="large" className="ant-dropdown-link" style={{ float: "right" }} aria-label="Action">
								Actions
								<DownOutlined style={{ marginLeft: 4 }} />
							</Button>
						</Dropdown>
					) : null}

					{showConnection && (
						<div>
							Connected to <strong>{accountName}</strong>
						</div>
					)}
					{showConnectButton && (
						<>
							<div className={style.row}>
								<div>
									<P>Build lasting connections with your community through email.</P>
									<P>
										Connect your Mailchimp account, and choose an audience to populate with order details of your
										Humanitix events.
									</P>
									<Button aria-label="Connect to mail" href={this.connectUrl} className={style.button}>
										Connect Mailchimp
									</Button>
								</div>
								<div className={style.colStyle}>
									<MailChimpConnectImage className={style.imgStyle} />
								</div>
							</div>
						</>
					)}

					{connectionTesting && (
						<div>
							<LoadingOutlined style={{ color: "#353337", margin: "0 8px" }} /> Testing connection...
						</div>
					)}
					{connectionFailed && (
						<div style={{ maxWidth: 600 }}>
							<Alert
								type="error"
								message="Connection test failed"
								description={`Looks like there's a problem connecting to ${accountName}.`}
							/>
							<div>
								<Button
									style={{ margin: "8px 8px 8px 0" }}
									onClick={this.testConnection}
									aria-label="Test connection to Mailchimp"
								>
									Test again
								</Button>
								<Button style={{ margin: "8px 8px 8px 0" }} href={this.connectUrl} aria-label="Reconnect to Mailchimp">
									Reconnect
								</Button>
							</div>
						</div>
					)}
				</ContentBlock>
				{showSettings && <MailchimpSettings userId={userId} />}
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentUser: state.currentUser,
			mailchimp: state.mailchimp
		};
	},
	{ getUser, saveUser, getAudiences, testConnection }
)(Mailchimp);
