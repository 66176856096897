import { LegacyInputField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { closeImport, importEvent } from "@/state/actions/eventActions";
import { required } from "@/utils/Validators";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "import";

class ImportModal extends Component {
	constructor(props) {
		super(props);
		this.checkEventNameQuery = debounce(800, this.checkEventNameQuery);
	}

	eventUrlRequired = required("Event url name is required");

	cancel = () => {
		const { closeImport } = this.props;
		closeImport();
	};

	save = (values) => {
		const { importEvent, navigate } = this.props;
		importEvent(values.eventUrl, navigate);
	};

	render() {
		const { event, handleSubmit } = this.props;

		return (
			<Modal
				open={event?.import?.open}
				title="Import event"
				onCancel={this.cancel}
				destroyOnClose
				footer={
					<ButtonsBar footer>
						<LegacyButton ariaLabel="Cancel" onClick={this.cancel}>
							Cancel
						</LegacyButton>
						<LegacyButton
							ariaLabel="Import"
							type="primary"
							onClick={handleSubmit(this.save)}
							loading={event?.import?.loading}
						>
							Import
						</LegacyButton>
					</ButtonsBar>
				}
			>
				<form>
					<LoadErrorView loading={event?.import?.loading}>
						<div>
							<Field
								name="eventUrl"
								label="Event url"
								placeholder=""
								component={LegacyInputField}
								style={{ width: "100%" }}
								required
								validate={this.eventUrlRequired}
							/>
						</div>
					</LoadErrorView>
				</form>
			</Modal>
		);
	}
}

ImportModal = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(ImportModal);

export default connect(
	(state) => ({
		event: state.currentEvent
	}),
	{ closeImport, importEvent }
)(withRouterHooks(ImportModal));
