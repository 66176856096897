import { Link } from "react-router-dom";
import { gatewayRequiresPayout } from "../config";
import LinkButton from "../components/buttons/LinkButton";
import { isEventInNexusState } from "./Tax";

export const getPublishWarnings = (event, closeModal, navigate) => {
	const isTaxRateEnabled = event?.paymentOptions?.salesTaxSettings?.enabled;
	const isUSEvent = event?.location === "US";
	const salesTaxRate = event?.paymentOptions?.salesTaxSettings?.rate;
	const requiresPayout = gatewayRequiresPayout(event?.paymentOptions?.paymentId);

	const publishWarnings = [];
	if (!event?.paymentOptions?.refundSettings?.refundPolicy) {
		publishWarnings.push({
			warning: (
				<>
					Event does not have a{" "}
					<Link
						to={`/console/my-events/${event._id}/payments/settings/refunds`}
						onClick={closeModal}
						style={{ textDecoration: "underline", color: "#1890FF" }}
					>
						refund policy selected
					</Link>
					.
				</>
			),
			possibleRisk: <div style={{ color: "#5A626C" }}>This will increase chargeback risk</div>
		});
	}

	if (!event?.paymentOptions?.userBankAccountId && requiresPayout) {
		publishWarnings.push({
			warning: (
				<>
					You have not selected a{" "}
					<Link
						to={`/console/my-events/${event._id}/payments/settings/payouts`}
						onClick={closeModal}
						style={{ textDecoration: "underline", color: "#1890FF" }}
					>
						selected bank account
					</Link>
					.
				</>
			),
			possibleRisk: <div style={{ color: "#5A626C" }}>This will delay your payout</div>
		});
	}

	if (isTaxRateEnabled && isUSEvent && !salesTaxRate && !isEventInNexusState(event.eventLocation)) {
		publishWarnings.push({
			warning: (
				<>
					You have not yet confirmed your{" "}
					<Link
						to={`/console/my-events/${event._id}/payments/settings/tax`}
						onClick={closeModal}
						style={{ textDecoration: "underline", color: "#1890FF" }}
					>
						sales tax settings
					</Link>
					.
				</>
			),
			possibleRisk: <div style={{ color: "#5A626C" }}>This will affect your customer’s invoice</div>
		});
	}

	const hasUnmappedSeats =
		event.seatingMaps?.[0]?.capacity > 0 && event.seatingMaps?.[0]?.mappedQuantity < event.seatingMaps?.[0]?.capacity;

	if (hasUnmappedSeats) {
		publishWarnings.push({
			warning: (
				<>
					Not every seat is{" "}
					<LinkButton
						style={{ padding: 0, textDecoration: "underline", color: "#1890FF" }}
						onClick={() => {
							closeModal();
							navigate(`/console/my-events/${String(event._id)}/seatingmap/${event.seatingMaps?.[0]?.id}/construction`);
						}}
					>
						mapped
					</LinkButton>{" "}
					to at least one ticket type.
				</>
			),

			possibleRisk: <div style={{ color: "#5A626C" }}>This could lead to underselling your event</div>
		});
	}

	const hasMismatchInTickets = event.ticketTypes
		?.filter((tt) => !tt.deleted && !tt.disabled && !tt.isDonation && tt.mappedSeats > 0)
		?.some((tt) => tt.mappedSeats !== tt.quantity);

	const hasMismatchInPackages = event.packagedTickets
		?.filter((pt) => !pt.deleted && !pt.disabled && pt.mappedTables > 0)
		?.some((pt) => pt.mappedTables !== pt.quantity);

	const hasMismatch = hasMismatchInTickets || hasMismatchInPackages;

	if (hasMismatch) {
		publishWarnings.push({
			warning: (
				<>
					Mapped seats don't align with ticket
					<LinkButton
						onClick={() => {
							closeModal();
							navigate(
								`/console/my-events/${String(event._id)}/seatingmap/${event.seatingMaps?.[0]?.id}/construction`,
								{
									state: {
										shouldShowManageCapacity: true
									}
								}
							);
						}}
						style={{ textDecoration: "underline", color: "#1890FF" }}
					>
						capacity settings
					</LinkButton>
					.
				</>
			),
			possibleRisk: <div style={{ color: "#5A626C" }}>This could lead to underselling your event</div>
		});
	}

	return publishWarnings;
};
