import BoxOfficeService from "../../services/BoxOfficeService";
export const GET_BOXOFFICE_TERMINALS_REQUEST = "GET_BOXOFFICE_TERMINALS_REQUEST";
export const GET_BOXOFFICE_TERMINALS_SUCCESS = "GET_BOXOFFICE_TERMINALS_SUCCESS";
export const GET_BOXOFFICE_TERMINALS_ERROR = "GET_BOXOFFICE_TERMINALS_ERROR";
export const DELETE_BOXOFFICE_TERMINAL_REQUEST = "DELETE_BOXOFFICE_TERMINAL_REQUEST";
export const DELETE_BOXOFFICE_TERMINAL_SUCCESS = "DELETE_BOXOFFICE_TERMINAL_SUCCESS";
export const DELETE_BOXOFFICE_TERMINAL_ERROR = "DELETE_BOXOFFICE_TERMINAL_ERROR";

export const getTerminals = () => {
	const page = 1;
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const search = state.search;
			dispatch({ type: GET_BOXOFFICE_TERMINALS_REQUEST, page });

			const result = await BoxOfficeService.getTerminals({ search, page });
			dispatch({ type: GET_BOXOFFICE_TERMINALS_SUCCESS, data: result.data });
		} catch (err) {
			dispatch({ type: GET_BOXOFFICE_TERMINALS_ERROR, error: err });
		}
	};
};

export const deleteTerminal = (terminalDocId) => {
	return async (dispatch) => {
		try {
			dispatch({ type: DELETE_BOXOFFICE_TERMINAL_REQUEST });
			await BoxOfficeService.deleteTerminal(terminalDocId);
			dispatch({ type: DELETE_BOXOFFICE_TERMINAL_SUCCESS });
		} catch (err) {
			dispatch({ type: DELETE_BOXOFFICE_TERMINAL_ERROR, error: err });
		}
	};
};

export const deleteManyTerminals = (terminalDocIds) => {
	return async (dispatch) => {
		try {
			dispatch({ type: DELETE_BOXOFFICE_TERMINAL_REQUEST });
			await BoxOfficeService.deleteManyTerminals(terminalDocIds);
			dispatch({ type: DELETE_BOXOFFICE_TERMINAL_SUCCESS });
		} catch (err) {
			dispatch({ type: DELETE_BOXOFFICE_TERMINAL_ERROR, error: err });
		}
	};
};
