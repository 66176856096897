import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import iconInfoHover from "../assets/ic_info_hover.svg";

interface IInfoIconProps {
	disabled?: boolean;
	height?: number;
	tooltip?: string | JSX.Element;
	dataCy?: string;
	ariaLabel?: string;
}
const InfoIcon = ({
	disabled,
	height = 16,
	tooltip = "default tooltip",
	dataCy = "default data CY",
	ariaLabel = "default aria Label"
}: IInfoIconProps) => {
	const buttonStyling = css`
		border: none;
		background: url(${iconInfoHover}) no-repeat center;
		position: relative;
		width: 16px;
		height: ${height}px;
		margin: 0px;
		padding: 0;
		&:focus {
			outline: 0;
		}
	`;

	return (
		<Tooltip title={tooltip}>
			<button type="button" disabled={disabled} className={buttonStyling} data-cy={dataCy} aria-label={ariaLabel} />
		</Tooltip>
	);
};

export default InfoIcon;
