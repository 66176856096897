import { SettingOutlined } from "@ant-design/icons";
import { FC } from "react";
import { Button } from "@/ui";
import { Dropdown } from "@/ui";
import Settings from "./Settings";

type HubSettingsProps = {
	formName: string;
	settings: any;
};

export const HubSettings: FC<HubSettingsProps> = ({ settings, formName }) => {
	return (
		<Dropdown
			title="Hub Settings"
			persist
			items={
				<Settings fieldPath="settings" visible style={{ background: "#fff" }} settings={settings} formName={formName} />
			}
		>
			<Button aria-label="Settings" iconOnly variant="ghost">
				<SettingOutlined style={{ fontSize: "18px" }} />
			</Button>
		</Dropdown>
	);
};
