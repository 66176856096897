import { css, cx } from "@emotion/css";
import { MenuItem } from ".";
import type { MenuItem as MenuItemType } from "@/services/menuService/MenuService";

const headerWrapper = css`
	display: flex;
	div {
		margin-bottom: 0.5em;
		h2 {
			margin: 0;
			font-size: 16px;
			font-weight: bold;
		}
		p {
			color: #727073;
			margin: 0;
		}
	}
`;

const menuStyles = {
	menuBlock: css`
		padding: 36px;
		min-width: 650px;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			li {
				margin: 0;
			}
		}
	`,
	menuHighlight: css`
		background: #f7f7f7;
	`,
	menuHeader: css`
		display: flex;
		div {
			margin-bottom: 0.5em;
			h2 {
				margin: 0;
				font-size: 16px;
				font-weight: bold;
			}
			p {
				color: #727073;
				margin: 0;
			}
		}
	`,
	menuLink: css`
		padding: 6px 9px;
		color: #323232;
		&:hover {
			background: #f9f9fa;
		}
	`
};

const MenuItemGroup = ({
	heading,
	icon,
	items,
	columns = 2,
	highlight
}: {
	heading: string;
	icon?: string;
	items: MenuItemType[];
	columns?: number;
	highlight?: boolean;
}) => {
	return (
		<div className={highlight ? cx(menuStyles.menuBlock, menuStyles.menuHighlight) : menuStyles.menuBlock}>
			<div className={headerWrapper}>
				<div style={{ width: 26 }}>{icon ? <img src={icon} alt={heading?.toString()} /> : null}</div>
				<div>
					<h2>{heading}</h2>
				</div>
			</div>

			<ul style={{ columns, paddingLeft: 16 }}>
				{items.map((child) => (
					<li key={child.key}>
						<MenuItem item={child} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default MenuItemGroup;
