import { LegacyButton } from "@/components/buttons/LegacyButton";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { usePromptRedux } from "@/hooks/usePrompt";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { notification, Table } from "@/ui/antd";
import { css } from "@emotion/css";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import ApplyToAllSave from "../components/ApplyToAllSave";
import { CheckboxField, LegacyInputField } from "../components/Fields";
import FormBar from "../components/FormBar";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import LocationSelection from "../components/LocationSelection";
import PageHeading from "../components/PageHeading";
import TableActions from "../components/table/TableActions";
import { P } from "../components/text/Text";
import { applyDefaultAlerts } from "../state/actions/applyToAllActions";
import { saveEvent } from "../state/actions/eventActions";
import { email, required } from "../utils/Validators";

const emailRequired = required("Please input your email!");
const validEmail = email("A valid email is required");

const formName = "alerts";

const buttonWrapper = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	paddingTop: 12
});

const newEventColumn = {
	align: "center",
	title: "New event",
	dataIndex: "newEvent",
	key: "newEvent",
	render: (_, __, index) => {
		return <Field name={`emailAlerts[${index}].newEvent`} component={CheckboxField} />;
	}
};

const AlertsTable = ({ emailAlerts, fields, eventLevel = false }) => {
	const removeEmail = (index) => {
		fields.remove(index);
	};

	const columns = [
		{
			align: "center",
			title: "Recipient email",
			dataIndex: "email",
			key: "email",
			render: (_, emailAlertObj, index) => {
				return (
					<Field
						name={`emailAlerts[${index}].email`}
						placeholder="Email"
						component={LegacyInputField}
						validate={[emailRequired, validEmail]}
						disabled={!!(emailAlertObj.primary || emailAlertObj.eventOwner)}
					/>
				);
			}
		},
		{
			align: "center",
			title: "Attendee enquiries",
			dataIndex: "organiserComms",
			key: "organiserComms",
			render: (_, emailAlertObj, index) => {
				return (
					<Field
						name={`emailAlerts[${index}].organiserComms`}
						component={CheckboxField}
						disabled={!!(emailAlertObj.primary || emailAlertObj.eventOwner)}
					/>
				);
			}
		},
		{
			align: "center",
			title: "Payouts",
			dataIndex: "payouts",
			key: "payouts",
			render: (_, emailAlertObj, index) => {
				return (
					<Field
						name={`emailAlerts[${index}].payouts`}
						component={CheckboxField}
						disabled={emailAlertObj.primary || emailAlertObj.eventOwner}
					/>
				);
			}
		},
		{
			align: "center",
			title: "Order notifications",
			dataIndex: "bccOrder",
			key: "bccOrder",
			render: (_, __, index) => {
				return <Field name={`emailAlerts[${index}].bccOrder`} component={CheckboxField} />;
			}
		}
	];

	const deleteAction = {
		title: "Actions",
		dataIndex: "",
		key: "actions",
		render: (_, emailAlertObj, index) => {
			return (
				<TableActions>
					<IconButton
						icon="delete"
						onClick={() => removeEmail(index)}
						tooltip="Delete"
						ariaLabel="Delete"
						disabled={emailAlertObj.primary || emailAlertObj.eventOwner}
					/>
				</TableActions>
			);
		}
	};

	return (
		<>
			<Table
				columns={eventLevel ? columns.concat([deleteAction]) : columns.concat([newEventColumn, deleteAction])}
				scroll={{ x: 800 }}
				dataSource={emailAlerts}
				rowKey="key"
				pagination={false}
			/>
			<div className={buttonWrapper}>
				<LegacyButton
					onClick={() => {
						fields.push({
							email: "",
							organiserComms: true,
							payouts: true,
							bccOrder: true,
							newEvent: true
						});
					}}
					icon={{ name: "plus", left: true }}
					type="action"
					ariaLabel="Add recipient"
				>
					Add recipient
				</LegacyButton>
			</div>
		</>
	);
};

const AlertsComponent = ({ handleSubmit, eventLevel }) => {
	const userId = useAppSelector((state) => state.auth.user?.id);
	const loading = useAppSelector((state) => state.auth?.loading);
	const emailAlerts = useAppSelector((state) => selector(state, "emailAlerts"));
	const eventId = useAppSelector((state) => state.currentEvent?.event?._id);
	const locale = useAppSelector((state) => state.locations.overrideLocation || "AU");
	const dispatch = useAppDispatch();
	const { updateUser } = useCurrentUser();
	usePromptRedux("You have unsaved changes. Are you sure you want to leave?", formName);

	const handleSave = async (values) => {
		if (eventLevel) {
			dispatch(saveEvent(eventId, { emailAlerts: values.emailAlerts }));
		} else {
			await updateUser({
				applyToAllRequired: true,
				[`emailAlerts.${locale}`]: values.emailAlerts
			});
			notification.success({
				message: "Success",
				description: "Updated successfully"
			});
		}
	};
	return (
		<LoadErrorView loading={loading}>
			<form onSubmit={handleSubmit(handleSave)}>
				<PageHeading title="Default notifications">
					{eventLevel ? null : <LocationSelection componentName="Alerts" defaultLevel="user" />}
				</PageHeading>
				<ContentBlock>
					<P>Add additional recipients for alert emails and set which types of notifications they’ll be receiving. </P>
					<FieldArray name="emailAlerts" component={AlertsTable} emailAlerts={emailAlerts} eventLevel={eventLevel} />
				</ContentBlock>

				<FormBar>
					<ApplyToAllSave onOk={() => dispatch(applyDefaultAlerts(userId))} />
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

// Decorate with redux-form
const Alerts = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(AlertsComponent);

const selector = formValueSelector(formName);

export default connect((state, ownProps) => {
	let alerts = [];
	if (ownProps.eventLevel) {
		alerts = state.currentEvent.event.emailAlerts || [];
	} else {
		alerts = state.auth.user?.emailAlerts[state.locations.overrideLocation || "AU"] || [
			{
				primary: true,
				eventOwner: false,
				organiserComms: true,
				payouts: true,
				bccOrder: false,
				newEvent: true,
				email: state.auth.user?.email
			}
		];
	}

	return {
		initialValues: {
			emailAlerts: alerts
		}
	};
})(Alerts, formName);
