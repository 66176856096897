import { notification } from "@/ui/antd";
import EventsService from "../../services/EventsService";

export const STRIPE_CONNECT_REQUEST = "STRIPE_CONNECT_REQUEST";
export const STRIPE_CONNECT_SUCCESS = "STRIPE_CONNECT_SUCCESS";
export const STRIPE_CONNECT_ERROR = "STRIPE_CONNECT_ERROR";

export const connectStripe = (state, authCode, navigate) => {
	return (dispatch) => {
		dispatch({ type: STRIPE_CONNECT_REQUEST });
		const stateParts = state.split("-");
		const type = stateParts[0];
		const id = stateParts[1];
		const location = stateParts[2];

		EventsService.connectStripe(type, id, authCode, location)
			.then((result) => {
				dispatch({ type: STRIPE_CONNECT_SUCCESS, result });
				notification.success({
					message: "Success",
					description: "Stripe Account Connected"
				});
				if (type === "event") {
					navigate(`/console/my-events/${id}/payments/gateways`);
				} else {
					navigate(`/console/account/profile/default-gateways`);
				}
			})
			.catch((err) => {
				console.error("err", err.response);
				dispatch({ type: STRIPE_CONNECT_ERROR, error: err });
				notification.error({
					message: "Error",
					description: err && err.response && err.response.data ? err.response.data : "Failed to connect Stripe"
				});
				if (type === "event") {
					navigate(`/console/my-events/${id}/payments/gateways`);
				} else {
					navigate(`/console/account/profile/default-gateways`);
				}
			});
	};
};
