import { CodeBlock, Responsive } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type ResponsiveProps = {
	breakpoint?: number;
	debounceTime?: number;
	desktop: ReactNode;
	mobile: ReactNode;
};`;

const sample = `import { Responsive } from "@/ui";

<Responsive
  desktop={
    <section style={sectionStyles}>
      <h2>Screen width is Desktop</h2>
      <p>Now descrease the width of the window</p>
    </section>
  }
  mobile={
    <section style={sectionStyles}>
      <h2>Screen width is Mobile</h2>
      <p>Now increase the width of the window</p>
    </section>
  }
/>`;

const sectionStyles = {
	border: "1px solid grey",
	borderRadius: "8px",
	padding: "8px 16px"
};

export const ResponsivePage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<h2>Default</h2>
			<Responsive
				desktop={
					<section style={sectionStyles}>
						<h2>Screen width is Desktop</h2>
						<p>Now descrease the width of the window</p>
					</section>
				}
				mobile={
					<section style={sectionStyles}>
						<h2>Screen width is Mobile</h2>
						<p>Now increase the width of the window</p>
					</section>
				}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
