import InputLabel from "../../../components/InputLabel";

export default ({ elementLength }) => {
	return (
		<div style={{ display: "flex" }}>
			<InputLabel style={{ flexGrow: 1 }}>Multiple elements selected</InputLabel>
			<InputLabel> {elementLength}</InputLabel>
		</div>
	);
};
