import {
	TOP_USERS_ERROR,
	TOP_USERS_REQUEST,
	TOP_USERS_RESET,
	TOP_USERS_SUCCESS,
	USER_ACTIVITY_ERROR,
	USER_ACTIVITY_REQUEST,
	USER_ACTIVITY_RESET,
	USER_ACTIVITY_SUCCESS
} from "../../actions/adminReports/userActions";

const initialState = {
	userActivity: {
		data: null,
		page: 1,
		count: null,
		loading: false,
		error: false
	},
	topUsers: {
		data: null,
		page: 1,
		count: null,
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case USER_ACTIVITY_RESET: {
			return {
				...state,
				userActivity: {
					...state.userActivity,
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case USER_ACTIVITY_REQUEST:
			return {
				...state,
				userActivity: {
					data: null,
					page: action.page,
					loading: true,
					error: false
				}
			};
		case USER_ACTIVITY_SUCCESS:
			return {
				...state,
				userActivity: {
					...state.userActivity,
					data: action.results,
					count: action.count,
					loading: false,
					error: false
				}
			};
		case USER_ACTIVITY_ERROR:
			return {
				...state,
				userActivity: {
					...state.userActivity,
					data: null,
					loading: false,
					error: action.error
				}
			};
		case TOP_USERS_RESET: {
			return {
				...state,
				topUsers: {
					...state.topUsers,
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case TOP_USERS_REQUEST:
			return {
				...state,
				topUsers: {
					data: null,
					page: action.page,
					loading: true,
					error: false
				}
			};
		case TOP_USERS_SUCCESS:
			return {
				...state,
				topUsers: {
					...state.topUsers,
					data: action.results,
					count: action.count,
					loading: false,
					error: false
				}
			};
		case TOP_USERS_ERROR:
			return {
				...state,
				topUsers: {
					...state.topUsers,
					data: null,
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
