import { Radio, RadioGroupProps, Tooltip } from "@/ui/antd";
import { css } from "@emotion/css";
import { CSSProperties, FC } from "react";
import { WrappedFieldProps } from "redux-form";
import InputWrapper from "./helpers/InputWrapper";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const getOptionStyle = ({
	isSelected,
	bordered,
	vertical,
	square
}: {
	isSelected: boolean;
	bordered: boolean;
	vertical: boolean;
	square: boolean;
}) => {
	const radioStyle = vertical
		? {
				display: "block",
				height: "30px",
				lineHeight: "22px"
		  }
		: {};

	const squareStyle = square
		? {
				height: 132,
				width: 130,
				marginBottom: 16,
				display: "inline-block"
		  }
		: {
				minWidth: 202,
				height: "auto",
				marginBottom: 8,
				width: 202
		  };

	return bordered
		? {
				...radioStyle,
				...squareStyle,
				border: isSelected ? "solid 1px #353337" : "solid 1px #dedede",
				borderRadius: 4,
				padding: 6
		  }
		: { ...radioStyle };
};

type RadioFieldOption = {
	value: string;
	label: string;
	icon?: string;
	tooltip?: string;
	disabled?: boolean;
};

type RadioFieldProps = WrappedFieldProps &
	RadioGroupProps & {
		style?: CSSProperties;
		label?: string;
		description?: string;
		inline?: boolean;
		required?: boolean;
		options: RadioFieldOption[];
		square?: boolean;
		button?: boolean;
		vertical?: boolean;
		tooltip?: string;
		tooltipIcon?: boolean;
		tooltipIconOnClick?: () => void;
		bold?: boolean;
		border?: boolean;
		disabled?: boolean;
	};

export const RadioField: FC<RadioFieldProps> = ({
	style = {},
	input,
	label,
	description,
	inline,
	required,
	defaultValue,
	options,
	square = false,
	size,
	button,
	vertical = false,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error },
	bold,
	border = false,
	disabled
}) => {
	const Option = button ? RadioButton : Radio;

	const wrapper = css({
		...style
	});

	const content = square
		? css({
				display: "flex",
				flexDirection: "column",
				wordWrap: "break-word",
				whiteSpace: "normal",
				textAlign: "center",
				marginTop: -15,
				img: {
					marginBottom: 10
				}
		  })
		: css({
				img: {
					paddingRight: 8
				}
		  });

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			bold={bold}
		>
			<div className={wrapper}>
				<RadioGroup
					id={input.name?.toLowerCase().split(".").join("-")}
					{...input}
					defaultValue={defaultValue}
					size={size}
					buttonStyle="solid"
					disabled={disabled}
					onBlur={() => null}
				>
					{options.map((option: RadioFieldOption) => {
						return (
							<Tooltip title={option.tooltip} key={`tooltip_${option.value}`}>
								<Option
									style={getOptionStyle({
										isSelected: input.value === option.value,
										bordered: border,
										vertical,
										square
									})}
									key={option.value}
									value={option.value}
									id={`${input.name}-${option.value}`}
									data-cy={`${input.name}-${option.value}`}
									disabled={option.disabled}
								>
									<span className={content}>
										{option.icon ? <img src={option.icon} alt={option.value} /> : null}
										{option.label}
									</span>
								</Option>
							</Tooltip>
						);
					})}
				</RadioGroup>
			</div>
		</InputWrapper>
	);
};
