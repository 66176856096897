import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm, reset } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { closeCreateModal, createOrganiser, organiserReset } from "../state/actions/organiserActions";
import { urlSafe } from "../utils/Format";
import { findLinkedInBaseUrl } from "../utils/socials/linkedIn";
import OrganiserFormFields from "./organiserDetails/FormFields";
import { prepareFormData } from "./organiserDetails/utils";

const formName = "createOrganiser";
class OrganiserModal extends Component {
	constructor(props) {
		super(props);
		this.handleSave = this.handleSave.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleAfterClose = this.handleAfterClose.bind(this);
	}

	handleSave(organiserData) {
		this.props.createOrganiser(prepareFormData(organiserData));
	}

	handleCancel() {
		this.props.closeCreateModal();
	}

	handleAfterClose() {
		const { forEvent, changeFieldValue, organisers } = this.props;
		if (forEvent && organisers.selected) {
			changeFieldValue("organiserId", organisers.selected._id);
		}
		this.props.organiserReset();
		this.props.dispatch(reset(formName));
	}

	render() {
		const { slug, visible, organisers, handleSubmit, dispatch, linkedInProfileType } = this.props;
		const { create } = organisers;

		const button = (
			<LegacyButton
				key="organiser"
				htmlType="button"
				onClick={handleSubmit(this.handleSave)}
				form="organiserForm"
				value="submit"
				type="primary"
				ariaLabel="Add"
				style={{ float: "right" }}
			>
				Add
			</LegacyButton>
		);
		return (
			<Modal
				open={visible}
				title="Add host"
				onCancel={this.handleCancel}
				afterClose={this.handleAfterClose}
				destroyOnClose
				footer={[button]}
			>
				<form id={formName}>
					<OrganiserFormFields
						slugError={create.error}
						slug={slug}
						onNameChange={(e, name) => {
							const newSlug = urlSafe(name);
							dispatch(change(formName, "slug", newSlug));
						}}
						linkedInProfileType={linkedInProfileType}
						onLinkedProfileTypeChange={(profileType) => {
							dispatch(change(formName, "linkedInProfileType", profileType));
						}}
					/>
				</form>
			</Modal>
		);
	}
}

OrganiserModal = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(OrganiserModal);

const selector = formValueSelector(formName);

export default connect(
	(state) => ({
		organisers: state.organisers,
		selectedEvent: state.selectedEvent,
		slug: selector(state, "slug"),
		linkedInProfileType: findLinkedInBaseUrl(selector(state, "linkedIn"))
	}),
	{ createOrganiser, organiserReset, closeCreateModal }
)(OrganiserModal);
