import {
	CHANGE_AUTO_PAYOUT_ERROR,
	CHANGE_AUTO_PAYOUT_REQUEST,
	CHANGE_AUTO_PAYOUT_SUCCESS,
	CHANGE_PAYOUT_FLAG_ERROR,
	CHANGE_PAYOUT_FLAG_REQUEST,
	CHANGE_PAYOUT_FLAG_SUCCESS,
	CHANGE_PAYOUT_STATUS_ERROR,
	CHANGE_PAYOUT_STATUS_REQUEST,
	CHANGE_PAYOUT_STATUS_SUCCESS,
	CSV_PAYOUT_ERROR,
	CSV_PAYOUT_REQUEST,
	CSV_PAYOUT_SUCCESS,
	DELETE_NOTE_ERROR,
	DELETE_NOTE_REQUEST,
	DELETE_NOTE_SUCCESS,
	GET_PAYMENT_ERROR,
	GET_PAYMENT_REQUEST,
	GET_PAYMENT_SUCCESS,
	GET_PAYOUT_INVOICE_ERROR,
	GET_PAYOUT_INVOICE_REQUEST,
	GET_PAYOUT_INVOICE_SUCCESS,
	PUSH_NOTE_ERROR,
	PUSH_NOTE_REQUEST,
	PUSH_NOTE_SUCCESS,
	PUSH_PAYMENT_ERROR,
	PUSH_PAYMENT_REQUEST,
	PUSH_PAYMENT_SUCCESS,
	REMOVE_PAYMENT_ERROR,
	REMOVE_PAYMENT_REQUEST,
	REMOVE_PAYMENT_SUCCESS,
	SEARCH_PAYMENTS_ERROR,
	SEARCH_PAYMENTS_REQUEST,
	SEARCH_PAYMENTS_SUCCESS,
	SEARCH_PAYOUT_ERROR,
	SEARCH_PAYOUT_REQUEST,
	SEARCH_PAYOUT_SUCCESS,
	UPDATE_PAYMENT_ERROR,
	UPDATE_PAYMENT_REQUEST,
	UPDATE_PAYMENT_SUCCESS
} from "../actions/payoutActions";

const initialState = {
	count: 0,
	loading: false,
	error: false,
	saved: false,
	payments: null,
	invoiceLoading: false,
	data: null,
	csv: {
		loading: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data
			};

		case REMOVE_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case PUSH_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case PUSH_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data
			};
		case PUSH_PAYMENT_ERROR:
			return {
				...state,
				loading: false
			};
		case UPDATE_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case UPDATE_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data
			};
		case UPDATE_PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case REMOVE_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data
			};

		case REMOVE_PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case GET_PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case PUSH_NOTE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case PUSH_NOTE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data
			};
		case PUSH_NOTE_ERROR:
			return {
				...state,
				error: action.error
			};
		case DELETE_NOTE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case DELETE_NOTE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.data
			};
		case DELETE_NOTE_ERROR:
			return {
				...state,
				loading: false
			};
		case SEARCH_PAYOUT_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				count: 0
			};
		case SEARCH_PAYOUT_SUCCESS:
			return {
				...state,
				loading: false,
				payouts: action.payouts,
				count: action.count
			};
		case SEARCH_PAYOUT_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				count: 0
			};
		case SEARCH_PAYMENTS_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				count: 0
			};
		case SEARCH_PAYMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				payments: action.payments,
				count: action.count
			};
		case SEARCH_PAYMENTS_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				count: 0
			};
		case CSV_PAYOUT_REQUEST:
			return {
				...state,
				csv: {
					loading: true
				}
			};
		case CSV_PAYOUT_SUCCESS:
			return {
				...state,
				csv: {
					loading: false
				}
			};
		case CSV_PAYOUT_ERROR:
			return {
				...state,
				csv: {
					loading: false
				}
			};
		case CHANGE_PAYOUT_STATUS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case CHANGE_PAYOUT_STATUS_SUCCESS:
			return {
				...state,
				loading: false
			};
		case CHANGE_PAYOUT_STATUS_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case CHANGE_PAYOUT_FLAG_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case CHANGE_PAYOUT_FLAG_SUCCESS:
			return {
				...state,
				loading: false
			};
		case CHANGE_AUTO_PAYOUT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case CHANGE_AUTO_PAYOUT_SUCCESS:
			return {
				...state,
				loading: false
			};
		case CHANGE_AUTO_PAYOUT_ERROR:
			return {
				...state,
				loading: false
			};
		case CHANGE_PAYOUT_FLAG_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case GET_PAYOUT_INVOICE_REQUEST:
			return {
				...state,
				invoiceLoading: true
			};
		case GET_PAYOUT_INVOICE_ERROR:
		case GET_PAYOUT_INVOICE_SUCCESS:
			return {
				...state,
				invoiceLoading: false
			};
		default:
			return state;
	}
};
