import iconReference from "../services/IconReference";

const iconImage = iconReference["image"];

interface IBorderRadiiParams {
	topLeft: number;
	topRight: number;
	bottomLeft: number;
	bottomRight: number;
}

interface INoImageProps {
	width?: string;
	height?: string;
	borderRadii?: IBorderRadiiParams;
	paddingTop?: string;
}

const NoImage = ({
	width = "100%",
	height = "100%",
	borderRadii = { topLeft: 4, topRight: 4, bottomLeft: 4, bottomRight: 4 },
	paddingTop = "0px"
}: INoImageProps) => {
	const style = {
		width,
		height,
		minWidth: width,
		maxWidth: width,
		verticalAlign: "middle",
		display: "inline-block",
		paddingTop,
		backgroundColor: "rgb(245, 245, 245)",
		backgroundImage: `url(${iconImage})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		borderRadius: `${borderRadii.topLeft}px ${borderRadii.topRight}px ${borderRadii.bottomRight}px ${borderRadii.bottomLeft}px`
	};
	return <div style={style} />;
};
export default NoImage;
