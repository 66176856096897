import axios from "./AxiosWrapper";
import LocationService from "./LocationService";

const getLocationHeader = () => {
	return { "x-override-location": LocationService.getLocation(true) };
};

class GiftCardService {
	static instance = null;

	static createInstance() {
		let object = new GiftCardService();
		return object;
	}

	static getInstance() {
		if (!GiftCardService.instance) {
			GiftCardService.instance = GiftCardService.createInstance();
		}
		return GiftCardService.instance;
	}

	async find(userId, location) {
		let options = false;
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}
		const response = await axios.get(`gift-card/card/${userId}`, options);
		return response.data;
	}

	async create(userId, data) {
		const response = await axios.post(`gift-card/card/${userId}`, data);
		return response.data;
	}

	async update(userId, giftCardId, data) {
		const response = await axios.post(`gift-card/card/${userId}/${giftCardId}`, data, {
			headers: getLocationHeader()
		});
		return response.data;
	}

	async get(userId, giftCardId) {
		const response = await axios.get(`gift-card/card/${userId}/${giftCardId}`, {
			headers: getLocationHeader()
		});
		return response.data;
	}

	async getPage(userId) {
		const response = await axios.get(`gift-card/page/${userId}`);
		return response.data;
	}

	async updatePage(userId, giftCardPageId, data) {
		const response = await axios.post(`gift-card/page/${userId}/${!giftCardPageId ? "new" : giftCardPageId}`, data);
		return response.data;
	}

	async getReport(userId, eventIds, eventDateId, query, page) {
		const params = {
			params: {
				eventIds,
				eventDateId,
				query,
				page
			}
		};
		const response = await axios.get(`gift-card/card/${userId}/report/local`, params);
		return response.data;
	}
	async getReportCsv(userId, eventIds, eventDateId, query) {
		const params = {
			responseType: "blob",
			params: { eventIds, eventDateId, query }
		};
		const response = await axios.get(`gift-card/card/${userId}/report/csv`, params);
		return response.data;
	}
	async findGiftCards(queryBy, page, location) {
		const query = queryBy.query;
		delete queryBy.query;
		const response = await axios.get(`gift-card/`, {
			params: { query, status: queryBy, page },
			headers: {
				"x-override-location": location
			}
		});
		const orders = response.data;
		return orders;
	}

	async exportGiftCards(queryBy, location) {
		const query = queryBy.query;
		delete queryBy.query;
		const response = await axios.get(`gift-card/csv`, {
			params: { query, status: queryBy },
			responseType: "blob",
			headers: {
				"x-override-location": location
			}
		});
		const orders = response.data;
		return orders;
	}

	async bulkUpload(giftCardDetails, emailOrder, csv, location) {
		const response = await axios.post(`gift-card/bulk-upload`, {
			giftCardDetails,
			emailOrder,
			csv,
			location
		});
		return response.data;
	}
}

export default GiftCardService.getInstance();
