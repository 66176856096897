import SideMenu from "../components/SideMenu";
import SideMenuBg from "../components/SideMenuBg";
import MenuService from "../services/menuService/MenuService";

const AccountMenu = () => {
	const menuItems = MenuService.getItemChildren("account") ?? [];

	return (
		<SideMenuBg>
			<SideMenu menuItems={menuItems} />
		</SideMenuBg>
	);
};

export default AccountMenu;
