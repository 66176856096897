import { Component } from "react";
import { Field, change } from "redux-form";

import {
	CopyInputField,
	ImageUploadField,
	LegacyInputField,
	MultipleImageUploadField,
	RichTextAreaField,
	SelectField
} from "../components/Fields";
import InfoIcon from "../components/InfoIcon";
import EventSearch from "../components/fields/EventSearchField";
import { H3, Label } from "../components/text/Text";
import { getConfig } from "@/config";
import { urlSafe } from "../utils/Format";
import { required } from "../utils/Validators";

/**
 * Create tour slug based on the tour name or you can customize it
 *
 * @class TourURLField
 * @extends {Component}
 */
const TourURLField = (props) => {
	const { tourName = "", slug } = props;
	const newTourSlug = slug ? slug : urlSafe(tourName.toLowerCase());

	const legacyTicketingUrl = `${getConfig("TICKETING_URL")}`.replace("events.humanitix", "ticketing.humanitix");
	const fullTourUrl = `${legacyTicketingUrl}/tours/${newTourSlug}`;

	return (
		<Field
			label="Tour URL"
			name="slug"
			style={{ maxWidth: "100%", width: "100%" }}
			fullUrl={fullTourUrl}
			component={CopyInputField}
			addonBefore={`${legacyTicketingUrl}/tours/`}
		/>
	);
};

/**
 * Tour page form
 *
 * @class TourPage
 * @extends {Component}
 */
class TourPage extends Component {
	updateSlug = (_, value) => {
		const { dispatch, formName, tourId } = this.props;
		if (tourId !== "new") {
			return false;
		}
		dispatch(change(formName, "slug", urlSafe(value.toLowerCase())));
	};

	tourNameRequired = required("Tour name is required");
	eventsRequired = required("Tour events are required");

	render() {
		const { slug, userEvents = false, tourName, events, dispatch, formName } = this.props;
		const eventsOptions = userEvents
			? userEvents.map((event) => {
					return {
						label: event.name,
						value: event._id
					};
			  })
			: [];
		const featuredEventsOptions =
			events && eventsOptions.length && events.length
				? eventsOptions.filter((event) => {
						const tourEvent = events.find((tourEventId) => String(event.value) === String(tourEventId));
						return !!tourEvent;
				  })
				: [];

		return (
			<div>
				<H3>Let’s start with the basic information</H3>
				<Field
					name="name"
					label="Tour Name"
					component={LegacyInputField}
					style={{ width: "100%", maxWidth: 435 }}
					required
					validate={this.tourNameRequired}
					onChange={this.updateSlug}
				/>
				<TourURLField toggleEdit={this.toggleEdit} slug={slug} tourName={tourName} />
				<Field
					name="bannerImage"
					label="Tour Page Banner Image"
					description="If you don’t upload banner image we will use banner image of the first event on the tour"
					component={ImageUploadField}
					uploadDescription="Recommended image size: ratio 2:1, max 10mb."
					uploadOutsideDescription="Recommended image size: ratio 2:1, max 10mb."
					aspectRatio={2}
				/>
				<div className="ant-row ant-form-item" style={{ marginBottom: "16px" }}>
					<Field
						name="events"
						label="Tour Events"
						description="Select events that you want to include in the tour"
						required
						component={EventSearch}
						handleEventOnChange={(eventIds) => dispatch(change(formName, "events", eventIds))}
						placeholder="Please select"
						validate={this.eventsRequired}
						selectedValues={events}
						styleOverride={{ width: "100%", maxWidth: 435 }}
						giveOptionForAllEvents={false}
					/>
				</div>
				<Field
					name="featuredEvents"
					label={
						<div>
							Featured Events
							<InfoIcon tooltip="Featured events will be displayed first at the top of tour pages." height={28} />
						</div>
					}
					description="Does your tour have featured events? Select featured events"
					component={SelectField}
					mode="multiple"
					style={{ width: "100%", maxWidth: 300 }}
					options={featuredEventsOptions}
				/>
				<Field id="description" name="description" label="Description" component={RichTextAreaField} rows={10} />
				<div style={{ marginBottom: 12 }}>
					<Label>Connect your social media pages to your tour page</Label>
				</div>
				<Field
					name="social.facebook"
					label="Facebook"
					component={LegacyInputField}
					style={{ width: "100%", maxWidth: 435 }}
				/>
				<Field
					name="social.instagram"
					label="Instagram"
					component={LegacyInputField}
					style={{ width: "100%", maxWidth: 435 }}
				/>
				<Field
					name="social.linkedIn"
					label="LinkedIn"
					component={LegacyInputField}
					style={{ width: "100%", maxWidth: 435 }}
				/>
				<Field
					name="social.twitter"
					label="Twitter"
					component={LegacyInputField}
					style={{ width: "100%", maxWidth: 435 }}
				/>
				<Field
					name="sponsorsLogos"
					label="Add Sponsors Logos"
					description="Upload individual logos of sponsors of your event, if multiple logos are uploaded they will create a grid. Sponsors will be shown at the bottom of the page."
					component={MultipleImageUploadField}
					uploadDescription="Recommended upload logo size 140px by 80px"
					uploadLabel="Upload logo"
					aspectRatio={2}
					maxWidth="340px"
					height="260px"
				/>
			</div>
		);
	}
}

export default TourPage;
