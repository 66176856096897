import { locationDataByCountryCode } from "@hx/locations";
import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "../components/LoadErrorView";
import LocationSelection from "../components/LocationSelection";
import PageHeading from "../components/PageHeading";
import { GatewayOptions } from "../events/event/payments/components/gateways/GatewayOptions";
import { updateGateways } from "../state/actions/gatewayActions";
import { getUser } from "../state/actions/userActions";

const allLocations = Object.values(locationDataByCountryCode).map((option) => option.location);

class DefaultGateways extends Component {
	componentWillMount() {
		this.load();
	}

	save = (updateObj) => {
		const { updateGateways, userId, currentUser, locations } = this.props;
		const { user = {} } = currentUser;
		const { defaultPaymentOptions = {} } = user;
		const paymentOptionsForLocation = {
			...updateObj.defaultPaymentOptions
		};
		allLocations.forEach((location) => {
			delete paymentOptionsForLocation[location];
		});

		const updatedPaymentOptions = {};
		if (defaultPaymentOptions) {
			allLocations.forEach((location) => {
				if (defaultPaymentOptions[location]) {
					updatedPaymentOptions[location] = defaultPaymentOptions[location];
				}
			});
		}
		updatedPaymentOptions[locations.overrideLocation] = paymentOptionsForLocation;

		updateGateways("user", userId, updatedPaymentOptions, locations.overrideLocation);
	};

	load = () => {
		const { getUser, userId } = this.props;
		getUser(userId);
	};

	render() {
		const {
			userId,
			currentUser,
			gateways,
			locations: { overrideLocation }
		} = this.props;
		const orgPaymentOptions = currentUser.user ? currentUser.user.defaultPaymentOptions : null;
		const selectedLocationsPaymentOptions =
			overrideLocation && orgPaymentOptions && orgPaymentOptions[overrideLocation]
				? orgPaymentOptions[overrideLocation]
				: null;
		return (
			<LoadErrorView
				loading={currentUser.loading || gateways.loading}
				error={currentUser.error || gateways.error}
				retryAction={() => this.load(userId)}
				renderChildren={false}
			>
				<PageHeading title="Default gateways">
					<LocationSelection componentName="defaultGateway" defaultLevel="user" onLocationChange={this.load} />
				</PageHeading>
				{currentUser.user && (
					<GatewayOptions
						type="user"
						id={userId}
						location={overrideLocation || "AU"}
						save={this.save}
						orgPaymentOptions={selectedLocationsPaymentOptions}
						paymentOptionsStr="defaultPaymentOptions"
						paymentGatewayOptions={currentUser.user.paymentGatewayOptions}
					/>
				)}
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.currentUser,
		locations: state.locations,
		gateways: state.gateways
	}),
	{ getUser, updateGateways }
)(DefaultGateways);
