import { ColourField, ImageUploadField, LegacyInputField, RadioField, SelectField } from "@/components/Fields";
import FilestackPrecache from "@/components/FilestackPrecache";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { getLocalisedText } from "@/utils/Format";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { required } from "@/utils/Validators";
import styled from "@emotion/styled";
import { ColourV3Functions } from "@hx/colours";
import { Flex } from "@/ui/antd";
import { useEffect, useState } from "react";
import { Field } from "redux-form";
import { EventSkeleton } from "./EventSkeleton";

const { generateCssString } = ColourV3Functions;
const colourText = getLocalisedText("colour");
const primaryColorRequired = required(`A primary ${colourText} is required`);
const customTextRequired = required("A custom ticket message is required");

const ConfigContainer = styled.div`
	flex: 1 0 50%;
	width: 100%;
`;

const PreviewPanel = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 0 40%;
`;

const PreviewContainer = styled.div`
	border-radius: 8px;
	border: 2px solid #dedede;
	padding: 5px;
	width: 100%;

	& > div {
		border: 1px solid #eee;
	}
`;

const ticketButtonTextOptions = [
	{ label: "Get Tickets", value: "Get Tickets" },
	{ label: "Register", value: "Register" },
	{ label: "Buy Tickets", value: "Buy Tickets" },
	{ label: "Donate", value: "Donate" },
	{ label: "Buy Now", value: "Buy Now" },
	{ label: "RSVP", value: "RSVP" },
	{ label: "Custom", value: "Custom" }
];

const colorSchemeOptions = [
	{ label: "Light", value: "light" },
	{ label: "Dark", value: "dark" },
	{ label: "Adaptive", value: "auto" }
];

type EventTheme = {
	primaryColor: string;
	baseColor: string;
	ticketButtonText: string;
	colorScheme: "light" | "dark" | "auto";
	customTicketButtonText?: string;
};

type IEventStylingProps = {
	theme: EventTheme;
} & GlobalReduxState;

const EventStyling = ({ currentEvent, theme }: IEventStylingProps) => {
	const [cssVariables, setCssVariables] = useState(
		generateCssString(theme.primaryColor, { namespace: "colorv3ConsoleEventStylingPreview", withRelative: false })
	);

	useEffect(() => {
		setCssVariables(
			generateCssString(theme.primaryColor, { namespace: "colorv3ConsoleEventStylingPreview", withRelative: false })
		);
	}, [theme]);

	const updatePrimaryColor = (e: any) =>
		setCssVariables(
			generateCssString([e[0], e[1], e[2], e[3], e[4], e[5], e[6]].join(""), {
				namespace: "colorv3ConsoleEventStylingPreview",
				withRelative: false
			})
		);

	return (
		<Flex align="start" wrap gap={8}>
			<ConfigContainer>
				<FilestackPrecache imageHandle={currentEvent?.event?.bannerImage?.handle ?? null} />

				{currentEvent ? (
					<>
						<Field
							name="bannerImage"
							label="Banner"
							description={
								<>
									Use an image with a 2:1 ratio (e.g. 3200px by 1600px).{" "}
									<a onClick={OpenHelpHandler(HelpIds.images)}>Learn more</a>
								</>
							}
							component={ImageUploadField}
							aspectRatio={2}
							imageStyleOverride={{
								width: "100%",
								maxHeight: "230px",
								margin: "auto",
								paddingTop: undefined,
								aspectRatio: "2"
							}}
						/>
					</>
				) : null}
				<Field
					name="logoImage"
					label="Logo"
					description="Brand the event page with your logo"
					component={ImageUploadField}
					imageStyleOverride={{
						width: "100%",
						maxHeight: "230px",
						margin: "auto",
						paddingTop: undefined,
						aspectRatio: "2"
					}}
				/>
				<Field
					name="theme.colorScheme"
					label="Light / Dark mode"
					description="Choose Your Look: Light, Dark, or Adaptive Mode (Adapts to visitor's device preference)"
					component={RadioField}
					button
					style={{ width: "100%", minWidth: "none" }}
					options={colorSchemeOptions}
				/>
				<Field
					name="theme.primaryColor"
					label={`Primary ${colourText}`}
					description={`Changes ${colourText} of all buttons and links on the event page`}
					component={ColourField}
					onChange={updatePrimaryColor}
					validate={primaryColorRequired}
				/>
				<Field
					label="Ticket button text"
					description="Primary button text for your event page"
					name="theme.ticketButtonText"
					component={SelectField}
					options={ticketButtonTextOptions}
					style={{ maxWidth: 223, width: "100%" }}
				/>
				{theme.ticketButtonText === "Custom" && (
					<Field
						label="Custom ticket button text"
						name="theme.customTicketButtonText"
						value="theme.customTicketButtonText"
						component={LegacyInputField}
						style={{ maxWidth: 415, width: "100%" }}
						validate={customTextRequired}
					/>
				)}
			</ConfigContainer>
			<PreviewPanel>
				<span style={{ marginBottom: "8px" }}>
					<b>Preview</b>
				</span>
				<PreviewContainer data-color-scheme={theme.colorScheme}>
					<style>{cssVariables}</style>
					<EventSkeleton />
				</PreviewContainer>
			</PreviewPanel>
		</Flex>
	);
};

export default EventStyling;
