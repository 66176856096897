import { List, Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../components/AppBlocks";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H4, P } from "../components/text/Text";
import { closeDeleteModel, removeOrganiser } from "../state/actions/organiserActions";

const OrganiserDeletionModal = ({ organisers, removeOrganiser, closeDeleteModel }) => {
	const navigate = useNavigate();

	const handleDelete = () => {
		removeOrganiser(organisers.selected._id, navigate);
	};

	let content = null;

	let footer = [
		<>
			<LegacyButton
				key="delete"
				htmlType="button"
				onClick={handleDelete}
				type="warning"
				ariaLabel="Delete"
				style={{ float: "right" }}
			>
				Delete
			</LegacyButton>
			<LegacyButton onClick={closeDeleteModel} htmlType="button" ariaLabel="Cancel" size="large">
				Cancel
			</LegacyButton>
		</>
	];

	if (organisers.delete.loading) {
		content = <LoadingSpinner />;
		footer = [];
	} else if (organisers.delete.events?.length) {
		content = (
			<>
				<P>The following events will have their host selection removed:</P>
				<List
					split={false}
					itemLayout="horizontal"
					dataSource={organisers.delete.events}
					renderItem={(item) => (
						<List.Item>
							<Link to={`/console/my-events/${item._id}`}>{item.name}</Link>
						</List.Item>
					)}
				/>
			</>
		);
	}

	return (
		<Modal open={organisers.delete.open} title="Delete host" destroyOnClose onCancel={closeDeleteModel} footer={footer}>
			<H4>Are you sure you would like to delete the host {organisers.selected.name}?</H4>
			{content}
		</Modal>
	);
};

export default connect(
	(state) => ({
		organisers: state.organisers
	}),
	{ removeOrganiser, closeDeleteModel }
)(OrganiserDeletionModal);
