"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ColourV3Functions: () => ColourV3Functions_exports
});
module.exports = __toCommonJS(src_exports);

// src/ColourV3Functions.ts
var ColourV3Functions_exports = {};
__export(ColourV3Functions_exports, {
  APCAcontrast: () => APCAcontrast,
  L: () => L,
  calcAPCA: () => calcAPCA,
  formatHex8: () => formatHex8,
  generateColourObject: () => generateColourObject,
  generateCssString: () => generateCssString,
  getAlpha: () => getAlpha,
  getBaseForegroundColour: () => getBaseForegroundColour,
  getDirection: () => getDirection,
  getSafeColour: () => getSafeColour,
  interpolateY: () => interpolateY,
  parse: () => parse_default,
  randomHex: () => randomHex,
  sRGBtoY: () => sRGBtoY,
  tuneLightness: () => tuneLightness,
  unAlpha: () => unAlpha
});

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/parseNumber.js
var parseNumber = /* @__PURE__ */ __name((color, len) => {
  if (typeof color !== "number") return;
  if (len === 3) {
    return {
      mode: "rgb",
      r: (color >> 8 & 15 | color >> 4 & 240) / 255,
      g: (color >> 4 & 15 | color & 240) / 255,
      b: (color & 15 | color << 4 & 240) / 255
    };
  }
  if (len === 4) {
    return {
      mode: "rgb",
      r: (color >> 12 & 15 | color >> 8 & 240) / 255,
      g: (color >> 8 & 15 | color >> 4 & 240) / 255,
      b: (color >> 4 & 15 | color & 240) / 255,
      alpha: (color & 15 | color << 4 & 240) / 255
    };
  }
  if (len === 6) {
    return {
      mode: "rgb",
      r: (color >> 16 & 255) / 255,
      g: (color >> 8 & 255) / 255,
      b: (color & 255) / 255
    };
  }
  if (len === 8) {
    return {
      mode: "rgb",
      r: (color >> 24 & 255) / 255,
      g: (color >> 16 & 255) / 255,
      b: (color >> 8 & 255) / 255,
      alpha: (color & 255) / 255
    };
  }
}, "parseNumber");
var parseNumber_default = parseNumber;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/colors/named.js
var named = {
  aliceblue: 15792383,
  antiquewhite: 16444375,
  aqua: 65535,
  aquamarine: 8388564,
  azure: 15794175,
  beige: 16119260,
  bisque: 16770244,
  black: 0,
  blanchedalmond: 16772045,
  blue: 255,
  blueviolet: 9055202,
  brown: 10824234,
  burlywood: 14596231,
  cadetblue: 6266528,
  chartreuse: 8388352,
  chocolate: 13789470,
  coral: 16744272,
  cornflowerblue: 6591981,
  cornsilk: 16775388,
  crimson: 14423100,
  cyan: 65535,
  darkblue: 139,
  darkcyan: 35723,
  darkgoldenrod: 12092939,
  darkgray: 11119017,
  darkgreen: 25600,
  darkgrey: 11119017,
  darkkhaki: 12433259,
  darkmagenta: 9109643,
  darkolivegreen: 5597999,
  darkorange: 16747520,
  darkorchid: 10040012,
  darkred: 9109504,
  darksalmon: 15308410,
  darkseagreen: 9419919,
  darkslateblue: 4734347,
  darkslategray: 3100495,
  darkslategrey: 3100495,
  darkturquoise: 52945,
  darkviolet: 9699539,
  deeppink: 16716947,
  deepskyblue: 49151,
  dimgray: 6908265,
  dimgrey: 6908265,
  dodgerblue: 2003199,
  firebrick: 11674146,
  floralwhite: 16775920,
  forestgreen: 2263842,
  fuchsia: 16711935,
  gainsboro: 14474460,
  ghostwhite: 16316671,
  gold: 16766720,
  goldenrod: 14329120,
  gray: 8421504,
  green: 32768,
  greenyellow: 11403055,
  grey: 8421504,
  honeydew: 15794160,
  hotpink: 16738740,
  indianred: 13458524,
  indigo: 4915330,
  ivory: 16777200,
  khaki: 15787660,
  lavender: 15132410,
  lavenderblush: 16773365,
  lawngreen: 8190976,
  lemonchiffon: 16775885,
  lightblue: 11393254,
  lightcoral: 15761536,
  lightcyan: 14745599,
  lightgoldenrodyellow: 16448210,
  lightgray: 13882323,
  lightgreen: 9498256,
  lightgrey: 13882323,
  lightpink: 16758465,
  lightsalmon: 16752762,
  lightseagreen: 2142890,
  lightskyblue: 8900346,
  lightslategray: 7833753,
  lightslategrey: 7833753,
  lightsteelblue: 11584734,
  lightyellow: 16777184,
  lime: 65280,
  limegreen: 3329330,
  linen: 16445670,
  magenta: 16711935,
  maroon: 8388608,
  mediumaquamarine: 6737322,
  mediumblue: 205,
  mediumorchid: 12211667,
  mediumpurple: 9662683,
  mediumseagreen: 3978097,
  mediumslateblue: 8087790,
  mediumspringgreen: 64154,
  mediumturquoise: 4772300,
  mediumvioletred: 13047173,
  midnightblue: 1644912,
  mintcream: 16121850,
  mistyrose: 16770273,
  moccasin: 16770229,
  navajowhite: 16768685,
  navy: 128,
  oldlace: 16643558,
  olive: 8421376,
  olivedrab: 7048739,
  orange: 16753920,
  orangered: 16729344,
  orchid: 14315734,
  palegoldenrod: 15657130,
  palegreen: 10025880,
  paleturquoise: 11529966,
  palevioletred: 14381203,
  papayawhip: 16773077,
  peachpuff: 16767673,
  peru: 13468991,
  pink: 16761035,
  plum: 14524637,
  powderblue: 11591910,
  purple: 8388736,
  // Added in CSS Colors Level 4:
  // https://drafts.csswg.org/css-color/#changes-from-3
  rebeccapurple: 6697881,
  red: 16711680,
  rosybrown: 12357519,
  royalblue: 4286945,
  saddlebrown: 9127187,
  salmon: 16416882,
  sandybrown: 16032864,
  seagreen: 3050327,
  seashell: 16774638,
  sienna: 10506797,
  silver: 12632256,
  skyblue: 8900331,
  slateblue: 6970061,
  slategray: 7372944,
  slategrey: 7372944,
  snow: 16775930,
  springgreen: 65407,
  steelblue: 4620980,
  tan: 13808780,
  teal: 32896,
  thistle: 14204888,
  tomato: 16737095,
  turquoise: 4251856,
  violet: 15631086,
  wheat: 16113331,
  white: 16777215,
  whitesmoke: 16119285,
  yellow: 16776960,
  yellowgreen: 10145074
};
var named_default = named;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/parseNamed.js
var parseNamed = /* @__PURE__ */ __name((color) => {
  return parseNumber_default(named_default[color.toLowerCase()], 6);
}, "parseNamed");
var parseNamed_default = parseNamed;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/parseHex.js
var hex = /^#?([0-9a-f]{8}|[0-9a-f]{6}|[0-9a-f]{4}|[0-9a-f]{3})$/i;
var parseHex = /* @__PURE__ */ __name((color) => {
  let match;
  return (match = color.match(hex)) ? parseNumber_default(parseInt(match[1], 16), match[1].length) : void 0;
}, "parseHex");
var parseHex_default = parseHex;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/util/regex.js
var num = "([+-]?\\d*\\.?\\d+(?:[eE][+-]?\\d+)?)";
var num_none = `(?:${num}|none)`;
var per = `${num}%`;
var per_none = `(?:${num}%|none)`;
var num_per = `(?:${num}%|${num})`;
var num_per_none = `(?:${num}%|${num}|none)`;
var hue = `(?:${num}(deg|grad|rad|turn)|${num})`;
var hue_none = `(?:${num}(deg|grad|rad|turn)|${num}|none)`;
var c = `\\s*,\\s*`;
var rx_num_per_none = new RegExp("^" + num_per_none + "$");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/parseRgbLegacy.js
var rgb_num_old = new RegExp(`^rgba?\\(\\s*${num}${c}${num}${c}${num}\\s*(?:,\\s*${num_per}\\s*)?\\)$`);
var rgb_per_old = new RegExp(`^rgba?\\(\\s*${per}${c}${per}${c}${per}\\s*(?:,\\s*${num_per}\\s*)?\\)$`);
var parseRgbLegacy = /* @__PURE__ */ __name((color) => {
  let res = {
    mode: "rgb"
  };
  let match;
  if (match = color.match(rgb_num_old)) {
    if (match[1] !== void 0) {
      res.r = match[1] / 255;
    }
    if (match[2] !== void 0) {
      res.g = match[2] / 255;
    }
    if (match[3] !== void 0) {
      res.b = match[3] / 255;
    }
  } else if (match = color.match(rgb_per_old)) {
    if (match[1] !== void 0) {
      res.r = match[1] / 100;
    }
    if (match[2] !== void 0) {
      res.g = match[2] / 100;
    }
    if (match[3] !== void 0) {
      res.b = match[3] / 100;
    }
  } else {
    return void 0;
  }
  if (match[4] !== void 0) {
    res.alpha = match[4] / 100;
  } else if (match[5] !== void 0) {
    res.alpha = +match[5];
  }
  return res;
}, "parseRgbLegacy");
var parseRgbLegacy_default = parseRgbLegacy;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/_prepare.js
var prepare = /* @__PURE__ */ __name((color, mode) => color === void 0 ? void 0 : typeof color !== "object" ? parse_default(color) : color.mode !== void 0 ? color : mode ? {
  ...color,
  mode
} : void 0, "prepare");
var prepare_default = prepare;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/converter.js
var converter = /* @__PURE__ */ __name((target_mode = "rgb") => (color) => (color = prepare_default(color, target_mode)) !== void 0 ? color.mode === target_mode ? color : (
  // converter for the target mode
  converters[color.mode][target_mode] ? converters[color.mode][target_mode](color) : (
    // if the target mode is RGB...
    target_mode === "rgb" ? converters[color.mode].rgb(color) : converters.rgb[target_mode](converters[color.mode].rgb(color))
  )
) : void 0, "converter");
var converter_default = converter;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/modes.js
var converters = {};
var modes = {};
var parsers = [];
var colorProfiles = {};
var identity = /* @__PURE__ */ __name((v) => v, "identity");
var useMode = /* @__PURE__ */ __name((definition28) => {
  converters[definition28.mode] = {
    ...converters[definition28.mode],
    ...definition28.toMode
  };
  Object.keys(definition28.fromMode || {}).forEach((k4) => {
    if (!converters[k4]) {
      converters[k4] = {};
    }
    converters[k4][definition28.mode] = definition28.fromMode[k4];
  });
  if (!definition28.ranges) {
    definition28.ranges = {};
  }
  if (!definition28.difference) {
    definition28.difference = {};
  }
  definition28.channels.forEach((channel) => {
    if (definition28.ranges[channel] === void 0) {
      definition28.ranges[channel] = [
        0,
        1
      ];
    }
    if (!definition28.interpolate[channel]) {
      throw new Error(`Missing interpolator for: ${channel}`);
    }
    if (typeof definition28.interpolate[channel] === "function") {
      definition28.interpolate[channel] = {
        use: definition28.interpolate[channel]
      };
    }
    if (!definition28.interpolate[channel].fixup) {
      definition28.interpolate[channel].fixup = identity;
    }
  });
  modes[definition28.mode] = definition28;
  (definition28.parse || []).forEach((parser) => {
    useParser(parser, definition28.mode);
  });
  return converter_default(definition28.mode);
}, "useMode");
var getMode = /* @__PURE__ */ __name((mode) => modes[mode], "getMode");
var useParser = /* @__PURE__ */ __name((parser, mode) => {
  if (typeof parser === "string") {
    if (!mode) {
      throw new Error(`'mode' required when 'parser' is a string`);
    }
    colorProfiles[parser] = mode;
  } else if (typeof parser === "function") {
    if (parsers.indexOf(parser) < 0) {
      parsers.push(parser);
    }
  }
}, "useParser");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/parse.js
var IdentStartCodePoint = /[^\x00-\x7F]|[a-zA-Z_]/;
var IdentCodePoint = /[^\x00-\x7F]|[-\w]/;
var Tok = {
  Function: "function",
  Ident: "ident",
  Number: "number",
  Percentage: "percentage",
  ParenClose: ")",
  None: "none",
  Hue: "hue",
  Alpha: "alpha"
};
var _i = 0;
function is_num(chars) {
  let ch = chars[_i];
  let ch1 = chars[_i + 1];
  if (ch === "-" || ch === "+") {
    return /\d/.test(ch1) || ch1 === "." && /\d/.test(chars[_i + 2]);
  }
  if (ch === ".") {
    return /\d/.test(ch1);
  }
  return /\d/.test(ch);
}
__name(is_num, "is_num");
function is_ident(chars) {
  if (_i >= chars.length) {
    return false;
  }
  let ch = chars[_i];
  if (IdentStartCodePoint.test(ch)) {
    return true;
  }
  if (ch === "-") {
    if (chars.length - _i < 2) {
      return false;
    }
    let ch1 = chars[_i + 1];
    if (ch1 === "-" || IdentStartCodePoint.test(ch1)) {
      return true;
    }
    return false;
  }
  return false;
}
__name(is_ident, "is_ident");
var huenits = {
  deg: 1,
  rad: 180 / Math.PI,
  grad: 9 / 10,
  turn: 360
};
function num2(chars) {
  let value = "";
  if (chars[_i] === "-" || chars[_i] === "+") {
    value += chars[_i++];
  }
  value += digits(chars);
  if (chars[_i] === "." && /\d/.test(chars[_i + 1])) {
    value += chars[_i++] + digits(chars);
  }
  if (chars[_i] === "e" || chars[_i] === "E") {
    if ((chars[_i + 1] === "-" || chars[_i + 1] === "+") && /\d/.test(chars[_i + 2])) {
      value += chars[_i++] + chars[_i++] + digits(chars);
    } else if (/\d/.test(chars[_i + 1])) {
      value += chars[_i++] + digits(chars);
    }
  }
  if (is_ident(chars)) {
    let id = ident(chars);
    if (id === "deg" || id === "rad" || id === "turn" || id === "grad") {
      return {
        type: Tok.Hue,
        value: value * huenits[id]
      };
    }
    return void 0;
  }
  if (chars[_i] === "%") {
    _i++;
    return {
      type: Tok.Percentage,
      value: +value
    };
  }
  return {
    type: Tok.Number,
    value: +value
  };
}
__name(num2, "num");
function digits(chars) {
  let v = "";
  while (/\d/.test(chars[_i])) {
    v += chars[_i++];
  }
  return v;
}
__name(digits, "digits");
function ident(chars) {
  let v = "";
  while (_i < chars.length && IdentCodePoint.test(chars[_i])) {
    v += chars[_i++];
  }
  return v;
}
__name(ident, "ident");
function identlike(chars) {
  let v = ident(chars);
  if (chars[_i] === "(") {
    _i++;
    return {
      type: Tok.Function,
      value: v
    };
  }
  if (v === "none") {
    return {
      type: Tok.None,
      value: void 0
    };
  }
  return {
    type: Tok.Ident,
    value: v
  };
}
__name(identlike, "identlike");
function tokenize(str = "") {
  let chars = str.trim();
  let tokens = [];
  let ch;
  _i = 0;
  while (_i < chars.length) {
    ch = chars[_i++];
    if (ch === "\n" || ch === "	" || ch === " ") {
      while (_i < chars.length && (chars[_i] === "\n" || chars[_i] === "	" || chars[_i] === " ")) {
        _i++;
      }
      continue;
    }
    if (ch === ",") {
      return void 0;
    }
    if (ch === ")") {
      tokens.push({
        type: Tok.ParenClose
      });
      continue;
    }
    if (ch === "+") {
      if (is_num(chars)) {
        _i--;
        tokens.push(num2(chars));
        continue;
      }
      return void 0;
    }
    if (ch === "-") {
      if (is_num(chars)) {
        _i--;
        tokens.push(num2(chars));
        continue;
      } else if (is_ident(chars)) {
        _i--;
        tokens.push({
          type: Tok.Ident,
          value: ident(chars)
        });
        continue;
      }
      return void 0;
    }
    if (ch === ".") {
      if (is_num(chars)) {
        _i--;
        tokens.push(num2(chars));
        continue;
      }
      return void 0;
    }
    if (ch === "/") {
      while (_i < chars.length && (chars[_i] === "\n" || chars[_i] === "	" || chars[_i] === " ")) {
        _i++;
      }
      let alpha;
      if (is_num(chars)) {
        alpha = num2(chars);
        if (alpha.type !== Tok.Hue) {
          tokens.push({
            type: Tok.Alpha,
            value: alpha
          });
          continue;
        }
      }
      if (is_ident(chars)) {
        if (ident(chars) === "none") {
          tokens.push({
            type: Tok.Alpha,
            value: {
              type: Tok.None,
              value: void 0
            }
          });
          continue;
        }
      }
      return void 0;
    }
    if (/\d/.test(ch)) {
      _i--;
      tokens.push(num2(chars));
      continue;
    }
    if (IdentStartCodePoint.test(ch)) {
      _i--;
      tokens.push(identlike(chars));
      continue;
    }
    return void 0;
  }
  return tokens;
}
__name(tokenize, "tokenize");
function parseColorSyntax(tokens) {
  tokens._i = 0;
  let token = tokens[tokens._i++];
  if (!token || token.type !== Tok.Function || token.value !== "color") {
    return void 0;
  }
  token = tokens[tokens._i++];
  if (token.type !== Tok.Ident) {
    return void 0;
  }
  const mode = colorProfiles[token.value];
  if (!mode) {
    return void 0;
  }
  const res = {
    mode
  };
  const coords = consumeCoords(tokens, false);
  if (!coords) {
    return void 0;
  }
  const channels = getMode(mode).channels;
  for (let ii = 0, c4; ii < channels.length; ii++) {
    c4 = coords[ii];
    if (c4.type !== Tok.None) {
      res[channels[ii]] = c4.type === Tok.Number ? c4.value : c4.value / 100;
    }
  }
  return res;
}
__name(parseColorSyntax, "parseColorSyntax");
function consumeCoords(tokens, includeHue) {
  const coords = [];
  let token;
  while (tokens._i < tokens.length) {
    token = tokens[tokens._i++];
    if (token.type === Tok.None || token.type === Tok.Number || token.type === Tok.Alpha || token.type === Tok.Percentage || includeHue && token.type === Tok.Hue) {
      coords.push(token);
      continue;
    }
    if (token.type === Tok.ParenClose) {
      if (tokens._i < tokens.length) {
        return void 0;
      }
      continue;
    }
    return void 0;
  }
  if (coords.length < 3 || coords.length > 4) {
    return void 0;
  }
  if (coords.length === 4) {
    if (coords[3].type !== Tok.Alpha) {
      return void 0;
    }
    coords[3] = coords[3].value;
  }
  if (coords.length === 3) {
    coords.push({
      type: Tok.None,
      value: void 0
    });
  }
  return coords.every((c4) => c4.type !== Tok.Alpha) ? coords : void 0;
}
__name(consumeCoords, "consumeCoords");
function parseModernSyntax(tokens, includeHue) {
  tokens._i = 0;
  let token = tokens[tokens._i++];
  if (!token || token.type !== Tok.Function) {
    return void 0;
  }
  let coords = consumeCoords(tokens, includeHue);
  if (!coords) {
    return void 0;
  }
  coords.unshift(token.value);
  return coords;
}
__name(parseModernSyntax, "parseModernSyntax");
var parse = /* @__PURE__ */ __name((color) => {
  if (typeof color !== "string") {
    return void 0;
  }
  const tokens = tokenize(color);
  const parsed = tokens ? parseModernSyntax(tokens, true) : void 0;
  let result = void 0;
  let i = 0;
  let len = parsers.length;
  while (i < len) {
    if ((result = parsers[i++](color, parsed)) !== void 0) {
      return result;
    }
  }
  return tokens ? parseColorSyntax(tokens) : void 0;
}, "parse");
var parse_default = parse;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/parseRgb.js
function parseRgb(color, parsed) {
  if (!parsed || parsed[0] !== "rgb" && parsed[0] !== "rgba") {
    return void 0;
  }
  const res = {
    mode: "rgb"
  };
  const [, r2, g, b, alpha] = parsed;
  if (r2.type === Tok.Hue || g.type === Tok.Hue || b.type === Tok.Hue) {
    return void 0;
  }
  if (r2.type !== Tok.None) {
    res.r = r2.type === Tok.Number ? r2.value / 255 : r2.value / 100;
  }
  if (g.type !== Tok.None) {
    res.g = g.type === Tok.Number ? g.value / 255 : g.value / 100;
  }
  if (b.type !== Tok.None) {
    res.b = b.type === Tok.Number ? b.value / 255 : b.value / 100;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(parseRgb, "parseRgb");
var parseRgb_default = parseRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/parseTransparent.js
var parseTransparent = /* @__PURE__ */ __name((c4) => c4 === "transparent" ? {
  mode: "rgb",
  r: 0,
  g: 0,
  b: 0,
  alpha: 0
} : void 0, "parseTransparent");
var parseTransparent_default = parseTransparent;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/interpolate/lerp.js
var lerp = /* @__PURE__ */ __name((a, b, t) => a + t * (b - a), "lerp");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/interpolate/piecewise.js
var get_classes = /* @__PURE__ */ __name((arr) => {
  let classes = [];
  for (let i = 0; i < arr.length - 1; i++) {
    let a = arr[i];
    let b = arr[i + 1];
    if (a === void 0 && b === void 0) {
      classes.push(void 0);
    } else if (a !== void 0 && b !== void 0) {
      classes.push([
        a,
        b
      ]);
    } else {
      classes.push(a !== void 0 ? [
        a,
        a
      ] : [
        b,
        b
      ]);
    }
  }
  return classes;
}, "get_classes");
var interpolatorPiecewise = /* @__PURE__ */ __name((interpolator) => (arr) => {
  let classes = get_classes(arr);
  return (t) => {
    let cls = t * classes.length;
    let idx = t >= 1 ? classes.length - 1 : Math.max(Math.floor(cls), 0);
    let pair = classes[idx];
    return pair === void 0 ? void 0 : interpolator(pair[0], pair[1], cls - idx);
  };
}, "interpolatorPiecewise");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/interpolate/linear.js
var interpolatorLinear = interpolatorPiecewise(lerp);

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/fixup/alpha.js
var fixupAlpha = /* @__PURE__ */ __name((arr) => {
  let some_defined = false;
  let res = arr.map((v) => {
    if (v !== void 0) {
      some_defined = true;
      return v;
    }
    return 1;
  });
  return some_defined ? res : arr;
}, "fixupAlpha");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rgb/definition.js
var definition = {
  mode: "rgb",
  channels: [
    "r",
    "g",
    "b",
    "alpha"
  ],
  parse: [
    parseRgb_default,
    parseHex_default,
    parseRgbLegacy_default,
    parseNamed_default,
    parseTransparent_default,
    "srgb"
  ],
  serialize: "srgb",
  interpolate: {
    r: interpolatorLinear,
    g: interpolatorLinear,
    b: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default = definition;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/a98/convertA98ToXyz65.js
var linearize = /* @__PURE__ */ __name((v) => Math.pow(Math.abs(v), 563 / 256) * Math.sign(v), "linearize");
var convertA98ToXyz65 = /* @__PURE__ */ __name((a982) => {
  let r2 = linearize(a982.r);
  let g = linearize(a982.g);
  let b = linearize(a982.b);
  let res = {
    mode: "xyz65",
    x: 0.5766690429101305 * r2 + 0.1855582379065463 * g + 0.1882286462349947 * b,
    y: 0.297344975250536 * r2 + 0.6273635662554661 * g + 0.0752914584939979 * b,
    z: 0.0270313613864123 * r2 + 0.0706888525358272 * g + 0.9913375368376386 * b
  };
  if (a982.alpha !== void 0) {
    res.alpha = a982.alpha;
  }
  return res;
}, "convertA98ToXyz65");
var convertA98ToXyz65_default = convertA98ToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/a98/convertXyz65ToA98.js
var gamma = /* @__PURE__ */ __name((v) => Math.pow(Math.abs(v), 256 / 563) * Math.sign(v), "gamma");
var convertXyz65ToA98 = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let res = {
    mode: "a98",
    r: gamma(x * 2.0415879038107465 - y * 0.5650069742788597 - 0.3447313507783297 * z),
    g: gamma(x * -0.9692436362808798 + y * 1.8759675015077206 + 0.0415550574071756 * z),
    b: gamma(x * 0.0134442806320312 - y * 0.1183623922310184 + 1.0151749943912058 * z)
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToA98");
var convertXyz65ToA98_default = convertXyz65ToA98;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lrgb/convertRgbToLrgb.js
var fn = /* @__PURE__ */ __name((c4) => {
  const abs2 = Math.abs(c4);
  if (abs2 < 0.04045) {
    return c4 / 12.92;
  }
  return (Math.sign(c4) || 1) * Math.pow((abs2 + 0.055) / 1.055, 2.4);
}, "fn");
var convertRgbToLrgb = /* @__PURE__ */ __name(({ r: r2, g, b, alpha }) => {
  let res = {
    mode: "lrgb",
    r: fn(r2),
    g: fn(g),
    b: fn(b)
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertRgbToLrgb");
var convertRgbToLrgb_default = convertRgbToLrgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz65/convertRgbToXyz65.js
var convertRgbToXyz65 = /* @__PURE__ */ __name((rgb3) => {
  let { r: r2, g, b, alpha } = convertRgbToLrgb_default(rgb3);
  let res = {
    mode: "xyz65",
    x: 0.4123907992659593 * r2 + 0.357584339383878 * g + 0.1804807884018343 * b,
    y: 0.2126390058715102 * r2 + 0.715168678767756 * g + 0.0721923153607337 * b,
    z: 0.0193308187155918 * r2 + 0.119194779794626 * g + 0.9505321522496607 * b
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertRgbToXyz65");
var convertRgbToXyz65_default = convertRgbToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lrgb/convertLrgbToRgb.js
var fn2 = /* @__PURE__ */ __name((c4) => {
  const abs2 = Math.abs(c4);
  if (abs2 > 31308e-7) {
    return (Math.sign(c4) || 1) * (1.055 * Math.pow(abs2, 1 / 2.4) - 0.055);
  }
  return c4 * 12.92;
}, "fn");
var convertLrgbToRgb = /* @__PURE__ */ __name(({ r: r2, g, b, alpha }, mode = "rgb") => {
  let res = {
    mode,
    r: fn2(r2),
    g: fn2(g),
    b: fn2(b)
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertLrgbToRgb");
var convertLrgbToRgb_default = convertLrgbToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz65/convertXyz65ToRgb.js
var convertXyz65ToRgb = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let res = convertLrgbToRgb_default({
    r: x * 3.2409699419045226 - y * 1.537383177570094 - 0.4986107602930034 * z,
    g: x * -0.9692436362808796 + y * 1.8759675015077204 + 0.0415550574071756 * z,
    b: x * 0.0556300796969936 - y * 0.2039769588889765 + 1.0569715142428784 * z
  });
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToRgb");
var convertXyz65ToRgb_default = convertXyz65ToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/a98/definition.js
var definition2 = {
  ...definition_default,
  mode: "a98",
  parse: [
    "a98-rgb"
  ],
  serialize: "a98-rgb",
  fromMode: {
    rgb: /* @__PURE__ */ __name((color) => convertXyz65ToA98_default(convertRgbToXyz65_default(color)), "rgb"),
    xyz65: convertXyz65ToA98_default
  },
  toMode: {
    rgb: /* @__PURE__ */ __name((color) => convertXyz65ToRgb_default(convertA98ToXyz65_default(color)), "rgb"),
    xyz65: convertA98ToXyz65_default
  }
};
var definition_default2 = definition2;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/util/normalizeHue.js
var normalizeHue = /* @__PURE__ */ __name((hue3) => (hue3 = hue3 % 360) < 0 ? hue3 + 360 : hue3, "normalizeHue");
var normalizeHue_default = normalizeHue;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/fixup/hue.js
var hue2 = /* @__PURE__ */ __name((hues, fn5) => {
  return hues.map((hue3, idx, arr) => {
    if (hue3 === void 0) {
      return hue3;
    }
    let normalized = normalizeHue_default(hue3);
    if (idx === 0 || hues[idx - 1] === void 0) {
      return normalized;
    }
    return fn5(normalized - normalizeHue_default(arr[idx - 1]));
  }).reduce((acc, curr) => {
    if (!acc.length || curr === void 0 || acc[acc.length - 1] === void 0) {
      acc.push(curr);
      return acc;
    }
    acc.push(curr + acc[acc.length - 1]);
    return acc;
  }, []);
}, "hue");
var fixupHueShorter = /* @__PURE__ */ __name((arr) => hue2(arr, (d) => Math.abs(d) <= 180 ? d : d - 360 * Math.sign(d)), "fixupHueShorter");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/cubehelix/constants.js
var M = [
  -0.14861,
  1.78277,
  -0.29227,
  -0.90649,
  1.97294,
  0
];
var degToRad = Math.PI / 180;
var radToDeg = 180 / Math.PI;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/cubehelix/convertRgbToCubehelix.js
var DE = M[3] * M[4];
var BE = M[1] * M[4];
var BCAD = M[1] * M[2] - M[0] * M[3];
var convertRgbToCubehelix = /* @__PURE__ */ __name(({ r: r2, g, b, alpha }) => {
  let l = (BCAD * b + r2 * DE - g * BE) / (BCAD + DE - BE);
  let x = b - l;
  let y = (M[4] * (g - l) - M[2] * x) / M[3];
  let res = {
    mode: "cubehelix",
    l,
    s: l === 0 || l === 1 ? void 0 : Math.sqrt(x * x + y * y) / (M[4] * l * (1 - l))
  };
  if (res.s) res.h = Math.atan2(y, x) * radToDeg - 120;
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertRgbToCubehelix");
var convertRgbToCubehelix_default = convertRgbToCubehelix;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/cubehelix/convertCubehelixToRgb.js
var convertCubehelixToRgb = /* @__PURE__ */ __name(({ h, s, l, alpha }) => {
  let res = {
    mode: "rgb"
  };
  h = (h === void 0 ? 0 : h + 120) * degToRad;
  let amp = s === void 0 ? 0 : s * l * (1 - l);
  let cosh = Math.cos(h);
  let sinh = Math.sin(h);
  res.r = l + amp * (M[0] * cosh + M[1] * sinh);
  res.g = l + amp * (M[2] * cosh + M[3] * sinh);
  res.b = l + amp * (M[4] * cosh + M[5] * sinh);
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertCubehelixToRgb");
var convertCubehelixToRgb_default = convertCubehelixToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/difference.js
var differenceHueSaturation = /* @__PURE__ */ __name((std, smp) => {
  if (std.h === void 0 || smp.h === void 0 || !std.s || !smp.s) {
    return 0;
  }
  let std_h = normalizeHue_default(std.h);
  let smp_h = normalizeHue_default(smp.h);
  let dH = Math.sin((smp_h - std_h + 360) / 2 * Math.PI / 180);
  return 2 * Math.sqrt(std.s * smp.s) * dH;
}, "differenceHueSaturation");
var differenceHueNaive = /* @__PURE__ */ __name((std, smp) => {
  if (std.h === void 0 || smp.h === void 0) {
    return 0;
  }
  let std_h = normalizeHue_default(std.h);
  let smp_h = normalizeHue_default(smp.h);
  if (Math.abs(smp_h - std_h) > 180) {
    return std_h - (smp_h - 360 * Math.sign(smp_h - std_h));
  }
  return smp_h - std_h;
}, "differenceHueNaive");
var differenceHueChroma = /* @__PURE__ */ __name((std, smp) => {
  if (std.h === void 0 || smp.h === void 0 || !std.c || !smp.c) {
    return 0;
  }
  let std_h = normalizeHue_default(std.h);
  let smp_h = normalizeHue_default(smp.h);
  let dH = Math.sin((smp_h - std_h + 360) / 2 * Math.PI / 180);
  return 2 * Math.sqrt(std.c * smp.c) * dH;
}, "differenceHueChroma");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/average.js
var averageAngle = /* @__PURE__ */ __name((val) => {
  let sum = val.reduce((sum2, val2) => {
    if (val2 !== void 0) {
      let rad = val2 * Math.PI / 180;
      sum2.sin += Math.sin(rad);
      sum2.cos += Math.cos(rad);
    }
    return sum2;
  }, {
    sin: 0,
    cos: 0
  });
  return Math.atan2(sum.sin, sum.cos) * 180 / Math.PI;
}, "averageAngle");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/cubehelix/definition.js
var definition3 = {
  mode: "cubehelix",
  channels: [
    "h",
    "s",
    "l",
    "alpha"
  ],
  parse: [
    "--cubehelix"
  ],
  serialize: "--cubehelix",
  ranges: {
    h: [
      0,
      360
    ],
    s: [
      0,
      4.614
    ],
    l: [
      0,
      1
    ]
  },
  fromMode: {
    rgb: convertRgbToCubehelix_default
  },
  toMode: {
    rgb: convertCubehelixToRgb_default
  },
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    s: interpolatorLinear,
    l: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueSaturation
  },
  average: {
    h: averageAngle
  }
};
var definition_default3 = definition3;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lch/convertLabToLch.js
var convertLabToLch = /* @__PURE__ */ __name(({ l, a, b, alpha }, mode = "lch") => {
  let c4 = Math.sqrt(a * a + b * b);
  let res = {
    mode,
    l,
    c: c4
  };
  if (c4) res.h = normalizeHue_default(Math.atan2(b, a) * 180 / Math.PI);
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertLabToLch");
var convertLabToLch_default = convertLabToLch;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lch/convertLchToLab.js
var convertLchToLab = /* @__PURE__ */ __name(({ l, c: c4, h, alpha }, mode = "lab") => {
  let res = {
    mode,
    l,
    a: c4 ? c4 * Math.cos(h / 180 * Math.PI) : 0,
    b: c4 ? c4 * Math.sin(h / 180 * Math.PI) : 0
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertLchToLab");
var convertLchToLab_default = convertLchToLab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz65/constants.js
var k = Math.pow(29, 3) / Math.pow(3, 3);
var e = Math.pow(6, 3) / Math.pow(29, 3);

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/constants.js
var D50 = {
  X: 0.3457 / 0.3585,
  Y: 1,
  Z: (1 - 0.3457 - 0.3585) / 0.3585
};
var D65 = {
  X: 0.3127 / 0.329,
  Y: 1,
  Z: (1 - 0.3127 - 0.329) / 0.329
};
var k2 = Math.pow(29, 3) / Math.pow(3, 3);
var e2 = Math.pow(6, 3) / Math.pow(29, 3);

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab65/convertLab65ToXyz65.js
var fn3 = /* @__PURE__ */ __name((v) => Math.pow(v, 3) > e ? Math.pow(v, 3) : (116 * v - 16) / k, "fn");
var convertLab65ToXyz65 = /* @__PURE__ */ __name(({ l, a, b, alpha }) => {
  let fy = (l + 16) / 116;
  let fx = a / 500 + fy;
  let fz = fy - b / 200;
  let res = {
    mode: "xyz65",
    x: fn3(fx) * D65.X,
    y: fn3(fy) * D65.Y,
    z: fn3(fz) * D65.Z
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertLab65ToXyz65");
var convertLab65ToXyz65_default = convertLab65ToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab65/convertLab65ToRgb.js
var convertLab65ToRgb = /* @__PURE__ */ __name((lab2) => convertXyz65ToRgb_default(convertLab65ToXyz65_default(lab2)), "convertLab65ToRgb");
var convertLab65ToRgb_default = convertLab65ToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab65/convertXyz65ToLab65.js
var f = /* @__PURE__ */ __name((value) => value > e ? Math.cbrt(value) : (k * value + 16) / 116, "f");
var convertXyz65ToLab65 = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let f0 = f(x / D65.X);
  let f1 = f(y / D65.Y);
  let f22 = f(z / D65.Z);
  let res = {
    mode: "lab65",
    l: 116 * f1 - 16,
    a: 500 * (f0 - f1),
    b: 200 * (f1 - f22)
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToLab65");
var convertXyz65ToLab65_default = convertXyz65ToLab65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab65/convertRgbToLab65.js
var convertRgbToLab65 = /* @__PURE__ */ __name((rgb3) => {
  let res = convertXyz65ToLab65_default(convertRgbToXyz65_default(rgb3));
  if (rgb3.r === rgb3.b && rgb3.b === rgb3.g) {
    res.a = res.b = 0;
  }
  return res;
}, "convertRgbToLab65");
var convertRgbToLab65_default = convertRgbToLab65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/dlch/constants.js
var kE = 1;
var kCH = 1;
var \u03B8 = 26 / 180 * Math.PI;
var cos\u03B8 = Math.cos(\u03B8);
var sin\u03B8 = Math.sin(\u03B8);
var factor = 100 / Math.log(139 / 100);

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/dlch/convertDlchToLab65.js
var convertDlchToLab65 = /* @__PURE__ */ __name(({ l, c: c4, h, alpha }) => {
  let res = {
    mode: "lab65",
    l: (Math.exp(l * kE / factor) - 1) / 39e-4
  };
  if (h === void 0) {
    res.a = res.b = 0;
  } else {
    let G = (Math.exp(0.0435 * c4 * kCH * kE) - 1) / 0.075;
    let e4 = G * Math.cos(h / 180 * Math.PI - \u03B8);
    let f3 = G * Math.sin(h / 180 * Math.PI - \u03B8);
    res.a = e4 * cos\u03B8 - f3 / 0.83 * sin\u03B8;
    res.b = e4 * sin\u03B8 + f3 / 0.83 * cos\u03B8;
  }
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertDlchToLab65");
var convertDlchToLab65_default = convertDlchToLab65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/dlch/convertLab65ToDlch.js
var convertLab65ToDlch = /* @__PURE__ */ __name(({ l, a, b, alpha }) => {
  let e4 = a * cos\u03B8 + b * sin\u03B8;
  let f3 = 0.83 * (b * cos\u03B8 - a * sin\u03B8);
  let G = Math.sqrt(e4 * e4 + f3 * f3);
  let res = {
    mode: "dlch",
    l: factor / kE * Math.log(1 + 39e-4 * l),
    c: Math.log(1 + 0.075 * G) / (0.0435 * kCH * kE)
  };
  if (res.c) {
    res.h = normalizeHue_default((Math.atan2(f3, e4) + \u03B8) / Math.PI * 180);
  }
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertLab65ToDlch");
var convertLab65ToDlch_default = convertLab65ToDlch;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/dlab/definition.js
var convertDlabToLab65 = /* @__PURE__ */ __name((c4) => convertDlchToLab65_default(convertLabToLch_default(c4, "dlch")), "convertDlabToLab65");
var convertLab65ToDlab = /* @__PURE__ */ __name((c4) => convertLchToLab_default(convertLab65ToDlch_default(c4), "dlab"), "convertLab65ToDlab");
var definition4 = {
  mode: "dlab",
  parse: [
    "--din99o-lab"
  ],
  serialize: "--din99o-lab",
  toMode: {
    lab65: convertDlabToLab65,
    rgb: /* @__PURE__ */ __name((c4) => convertLab65ToRgb_default(convertDlabToLab65(c4)), "rgb")
  },
  fromMode: {
    lab65: convertLab65ToDlab,
    rgb: /* @__PURE__ */ __name((c4) => convertLab65ToDlab(convertRgbToLab65_default(c4)), "rgb")
  },
  channels: [
    "l",
    "a",
    "b",
    "alpha"
  ],
  ranges: {
    l: [
      0,
      100
    ],
    a: [
      -40.09,
      45.501
    ],
    b: [
      -40.469,
      44.344
    ]
  },
  interpolate: {
    l: interpolatorLinear,
    a: interpolatorLinear,
    b: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default4 = definition4;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/dlch/definition.js
var definition5 = {
  mode: "dlch",
  parse: [
    "--din99o-lch"
  ],
  serialize: "--din99o-lch",
  toMode: {
    lab65: convertDlchToLab65_default,
    dlab: /* @__PURE__ */ __name((c4) => convertLchToLab_default(c4, "dlab"), "dlab"),
    rgb: /* @__PURE__ */ __name((c4) => convertLab65ToRgb_default(convertDlchToLab65_default(c4)), "rgb")
  },
  fromMode: {
    lab65: convertLab65ToDlch_default,
    dlab: /* @__PURE__ */ __name((c4) => convertLabToLch_default(c4, "dlch"), "dlab"),
    rgb: /* @__PURE__ */ __name((c4) => convertLab65ToDlch_default(convertRgbToLab65_default(c4)), "rgb")
  },
  channels: [
    "l",
    "c",
    "h",
    "alpha"
  ],
  ranges: {
    l: [
      0,
      100
    ],
    c: [
      0,
      51.484
    ],
    h: [
      0,
      360
    ]
  },
  interpolate: {
    l: interpolatorLinear,
    c: interpolatorLinear,
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueChroma
  },
  average: {
    h: averageAngle
  }
};
var definition_default5 = definition5;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsi/convertHsiToRgb.js
function convertHsiToRgb({ h, s, i, alpha }) {
  h = normalizeHue_default(h);
  let f3 = Math.abs(h / 60 % 2 - 1);
  let res;
  switch (Math.floor(h / 60)) {
    case 0:
      res = {
        r: i * (1 + s * (3 / (2 - f3) - 1)),
        g: i * (1 + s * (3 * (1 - f3) / (2 - f3) - 1)),
        b: i * (1 - s)
      };
      break;
    case 1:
      res = {
        r: i * (1 + s * (3 * (1 - f3) / (2 - f3) - 1)),
        g: i * (1 + s * (3 / (2 - f3) - 1)),
        b: i * (1 - s)
      };
      break;
    case 2:
      res = {
        r: i * (1 - s),
        g: i * (1 + s * (3 / (2 - f3) - 1)),
        b: i * (1 + s * (3 * (1 - f3) / (2 - f3) - 1))
      };
      break;
    case 3:
      res = {
        r: i * (1 - s),
        g: i * (1 + s * (3 * (1 - f3) / (2 - f3) - 1)),
        b: i * (1 + s * (3 / (2 - f3) - 1))
      };
      break;
    case 4:
      res = {
        r: i * (1 + s * (3 * (1 - f3) / (2 - f3) - 1)),
        g: i * (1 - s),
        b: i * (1 + s * (3 / (2 - f3) - 1))
      };
      break;
    case 5:
      res = {
        r: i * (1 + s * (3 / (2 - f3) - 1)),
        g: i * (1 - s),
        b: i * (1 + s * (3 * (1 - f3) / (2 - f3) - 1))
      };
      break;
    default:
      res = {
        r: i * (1 - s),
        g: i * (1 - s),
        b: i * (1 - s)
      };
  }
  res.mode = "rgb";
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}
__name(convertHsiToRgb, "convertHsiToRgb");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsi/convertRgbToHsi.js
function convertRgbToHsi({ r: r2, g, b, alpha }) {
  let M2 = Math.max(r2, g, b), m = Math.min(r2, g, b);
  let res = {
    mode: "hsi",
    s: r2 + g + b === 0 ? 0 : 1 - 3 * m / (r2 + g + b),
    i: (r2 + g + b) / 3
  };
  if (M2 - m !== 0) res.h = (M2 === r2 ? (g - b) / (M2 - m) + (g < b) * 6 : M2 === g ? (b - r2) / (M2 - m) + 2 : (r2 - g) / (M2 - m) + 4) * 60;
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}
__name(convertRgbToHsi, "convertRgbToHsi");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsi/definition.js
var definition6 = {
  mode: "hsi",
  toMode: {
    rgb: convertHsiToRgb
  },
  parse: [
    "--hsi"
  ],
  serialize: "--hsi",
  fromMode: {
    rgb: convertRgbToHsi
  },
  channels: [
    "h",
    "s",
    "i",
    "alpha"
  ],
  ranges: {
    h: [
      0,
      360
    ]
  },
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    s: interpolatorLinear,
    i: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueSaturation
  },
  average: {
    h: averageAngle
  }
};
var definition_default6 = definition6;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsl/convertHslToRgb.js
function convertHslToRgb({ h, s, l, alpha }) {
  h = normalizeHue_default(h);
  let m1 = l + s * (l < 0.5 ? l : 1 - l);
  let m2 = m1 - (m1 - l) * 2 * Math.abs(h / 60 % 2 - 1);
  let res;
  switch (Math.floor(h / 60)) {
    case 0:
      res = {
        r: m1,
        g: m2,
        b: 2 * l - m1
      };
      break;
    case 1:
      res = {
        r: m2,
        g: m1,
        b: 2 * l - m1
      };
      break;
    case 2:
      res = {
        r: 2 * l - m1,
        g: m1,
        b: m2
      };
      break;
    case 3:
      res = {
        r: 2 * l - m1,
        g: m2,
        b: m1
      };
      break;
    case 4:
      res = {
        r: m2,
        g: 2 * l - m1,
        b: m1
      };
      break;
    case 5:
      res = {
        r: m1,
        g: 2 * l - m1,
        b: m2
      };
      break;
    default:
      res = {
        r: 2 * l - m1,
        g: 2 * l - m1,
        b: 2 * l - m1
      };
  }
  res.mode = "rgb";
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}
__name(convertHslToRgb, "convertHslToRgb");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsl/convertRgbToHsl.js
function convertRgbToHsl({ r: r2, g, b, alpha }) {
  let M2 = Math.max(r2, g, b), m = Math.min(r2, g, b);
  let res = {
    mode: "hsl",
    s: M2 === m ? 0 : (M2 - m) / (1 - Math.abs(M2 + m - 1)),
    l: 0.5 * (M2 + m)
  };
  if (M2 - m !== 0) res.h = (M2 === r2 ? (g - b) / (M2 - m) + (g < b) * 6 : M2 === g ? (b - r2) / (M2 - m) + 2 : (r2 - g) / (M2 - m) + 4) * 60;
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}
__name(convertRgbToHsl, "convertRgbToHsl");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/util/hue.js
var hueToDeg = /* @__PURE__ */ __name((val, unit) => {
  switch (unit) {
    case "deg":
      return +val;
    case "rad":
      return val / Math.PI * 180;
    case "grad":
      return val / 10 * 9;
    case "turn":
      return val * 360;
  }
}, "hueToDeg");
var hue_default = hueToDeg;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsl/parseHslLegacy.js
var hsl_old = new RegExp(`^hsla?\\(\\s*${hue}${c}${per}${c}${per}\\s*(?:,\\s*${num_per}\\s*)?\\)$`);
var parseHslLegacy = /* @__PURE__ */ __name((color) => {
  let match = color.match(hsl_old);
  if (!match) return;
  let res = {
    mode: "hsl"
  };
  if (match[3] !== void 0) {
    res.h = +match[3];
  } else if (match[1] !== void 0 && match[2] !== void 0) {
    res.h = hue_default(match[1], match[2]);
  }
  if (match[4] !== void 0) {
    res.s = Math.min(Math.max(0, match[4] / 100), 1);
  }
  if (match[5] !== void 0) {
    res.l = Math.min(Math.max(0, match[5] / 100), 1);
  }
  if (match[6] !== void 0) {
    res.alpha = match[6] / 100;
  } else if (match[7] !== void 0) {
    res.alpha = +match[7];
  }
  return res;
}, "parseHslLegacy");
var parseHslLegacy_default = parseHslLegacy;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsl/parseHsl.js
function parseHsl(color, parsed) {
  if (!parsed || parsed[0] !== "hsl" && parsed[0] !== "hsla") {
    return void 0;
  }
  const res = {
    mode: "hsl"
  };
  const [, h, s, l, alpha] = parsed;
  if (h.type !== Tok.None) {
    if (h.type === Tok.Percentage) {
      return void 0;
    }
    res.h = h.value;
  }
  if (s.type !== Tok.None) {
    if (s.type === Tok.Hue) {
      return void 0;
    }
    res.s = s.type === Tok.Number ? s.value : s.value / 100;
  }
  if (l.type !== Tok.None) {
    if (l.type === Tok.Hue) {
      return void 0;
    }
    res.l = l.type === Tok.Number ? l.value : l.value / 100;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(parseHsl, "parseHsl");
var parseHsl_default = parseHsl;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsl/definition.js
var definition7 = {
  mode: "hsl",
  toMode: {
    rgb: convertHslToRgb
  },
  fromMode: {
    rgb: convertRgbToHsl
  },
  channels: [
    "h",
    "s",
    "l",
    "alpha"
  ],
  ranges: {
    h: [
      0,
      360
    ]
  },
  parse: [
    parseHsl_default,
    parseHslLegacy_default
  ],
  serialize: /* @__PURE__ */ __name((c4) => `hsl(${c4.h || 0} ${c4.s !== void 0 ? c4.s * 100 + "%" : "none"} ${c4.l !== void 0 ? c4.l * 100 + "%" : "none"}${c4.alpha < 1 ? ` / ${c4.alpha}` : ""})`, "serialize"),
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    s: interpolatorLinear,
    l: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueSaturation
  },
  average: {
    h: averageAngle
  }
};
var definition_default7 = definition7;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsv/convertHsvToRgb.js
function convertHsvToRgb({ h, s, v, alpha }) {
  h = normalizeHue_default(h);
  let f3 = Math.abs(h / 60 % 2 - 1);
  let res;
  switch (Math.floor(h / 60)) {
    case 0:
      res = {
        r: v,
        g: v * (1 - s * f3),
        b: v * (1 - s)
      };
      break;
    case 1:
      res = {
        r: v * (1 - s * f3),
        g: v,
        b: v * (1 - s)
      };
      break;
    case 2:
      res = {
        r: v * (1 - s),
        g: v,
        b: v * (1 - s * f3)
      };
      break;
    case 3:
      res = {
        r: v * (1 - s),
        g: v * (1 - s * f3),
        b: v
      };
      break;
    case 4:
      res = {
        r: v * (1 - s * f3),
        g: v * (1 - s),
        b: v
      };
      break;
    case 5:
      res = {
        r: v,
        g: v * (1 - s),
        b: v * (1 - s * f3)
      };
      break;
    default:
      res = {
        r: v * (1 - s),
        g: v * (1 - s),
        b: v * (1 - s)
      };
  }
  res.mode = "rgb";
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}
__name(convertHsvToRgb, "convertHsvToRgb");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsv/convertRgbToHsv.js
function convertRgbToHsv({ r: r2, g, b, alpha }) {
  let M2 = Math.max(r2, g, b), m = Math.min(r2, g, b);
  let res = {
    mode: "hsv",
    s: M2 === 0 ? 0 : 1 - m / M2,
    v: M2
  };
  if (M2 - m !== 0) res.h = (M2 === r2 ? (g - b) / (M2 - m) + (g < b) * 6 : M2 === g ? (b - r2) / (M2 - m) + 2 : (r2 - g) / (M2 - m) + 4) * 60;
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}
__name(convertRgbToHsv, "convertRgbToHsv");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hsv/definition.js
var definition8 = {
  mode: "hsv",
  toMode: {
    rgb: convertHsvToRgb
  },
  parse: [
    "--hsv"
  ],
  serialize: "--hsv",
  fromMode: {
    rgb: convertRgbToHsv
  },
  channels: [
    "h",
    "s",
    "v",
    "alpha"
  ],
  ranges: {
    h: [
      0,
      360
    ]
  },
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    s: interpolatorLinear,
    v: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueSaturation
  },
  average: {
    h: averageAngle
  }
};
var definition_default8 = definition8;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hwb/convertHwbToRgb.js
function convertHwbToRgb({ h, w, b, alpha }) {
  if (w + b > 1) {
    let s = w + b;
    w /= s;
    b /= s;
  }
  return convertHsvToRgb({
    h,
    s: b === 1 ? 1 : 1 - w / (1 - b),
    v: 1 - b,
    alpha
  });
}
__name(convertHwbToRgb, "convertHwbToRgb");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hwb/convertRgbToHwb.js
function convertRgbToHwb(rgba) {
  let hsv2 = convertRgbToHsv(rgba);
  if (hsv2 === void 0) return void 0;
  let res = {
    mode: "hwb",
    w: (1 - hsv2.s) * hsv2.v,
    b: 1 - hsv2.v
  };
  if (hsv2.h !== void 0) res.h = hsv2.h;
  if (hsv2.alpha !== void 0) res.alpha = hsv2.alpha;
  return res;
}
__name(convertRgbToHwb, "convertRgbToHwb");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hwb/parseHwb.js
function ParseHwb(color, parsed) {
  if (!parsed || parsed[0] !== "hwb") {
    return void 0;
  }
  const res = {
    mode: "hwb"
  };
  const [, h, w, b, alpha] = parsed;
  if (h.type !== Tok.None) {
    if (h.type === Tok.Percentage) {
      return void 0;
    }
    res.h = h.value;
  }
  if (w.type !== Tok.None) {
    if (w.type === Tok.Hue) {
      return void 0;
    }
    res.w = w.type === Tok.Number ? w.value : w.value / 100;
  }
  if (b.type !== Tok.None) {
    if (b.type === Tok.Hue) {
      return void 0;
    }
    res.b = b.type === Tok.Number ? b.value : b.value / 100;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(ParseHwb, "ParseHwb");
var parseHwb_default = ParseHwb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/hwb/definition.js
var definition9 = {
  mode: "hwb",
  toMode: {
    rgb: convertHwbToRgb
  },
  fromMode: {
    rgb: convertRgbToHwb
  },
  channels: [
    "h",
    "w",
    "b",
    "alpha"
  ],
  ranges: {
    h: [
      0,
      360
    ]
  },
  parse: [
    parseHwb_default
  ],
  serialize: /* @__PURE__ */ __name((c4) => `hwb(${c4.h || 0} ${c4.w * 100}% ${c4.b * 100}%${c4.alpha < 1 ? ` / ${c4.alpha}` : ""})`, "serialize"),
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    w: interpolatorLinear,
    b: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueNaive
  },
  average: {
    h: averageAngle
  }
};
var definition_default9 = definition9;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jab/convertXyz65ToJab.js
var n = 0.1593017578125;
var p = 134.03437499999998;
var c1 = 0.8359375;
var c2 = 18.8515625;
var c3 = 18.6875;
var d0 = 16295499532821565e-27;
var pq = /* @__PURE__ */ __name((v) => {
  let vn3 = Math.pow(v / 1e4, n);
  return Math.pow((c1 + c2 * vn3) / (1 + c3 * vn3), p) || 0;
}, "pq");
var abs = /* @__PURE__ */ __name((v) => Math.max(v * 203, 0), "abs");
var convertXyz65ToJab = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  x = abs(x);
  y = abs(y);
  z = abs(z);
  let xp = 1.15 * x - 0.15 * z;
  let yp = 0.66 * y + 0.34 * x;
  let l = pq(0.41478972 * xp + 0.579999 * yp + 0.014648 * z);
  let m = pq(-0.20151 * xp + 1.120649 * yp + 0.0531008 * z);
  let s = pq(-0.0166008 * xp + 0.2648 * yp + 0.6684799 * z);
  let i = (l + m) / 2;
  let res = {
    mode: "jab",
    j: 0.44 * i / (1 - 0.56 * i) - d0,
    a: 3.524 * l - 4.066708 * m + 0.542708 * s,
    b: 0.199076 * l + 1.096799 * m - 1.295875 * s
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToJab");
var convertXyz65ToJab_default = convertXyz65ToJab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jab/convertJabToXyz65.js
var n2 = 0.1593017578125;
var p2 = 134.03437499999998;
var c12 = 0.8359375;
var c22 = 18.8515625;
var c32 = 18.6875;
var d02 = 16295499532821565e-27;
var pq_inv = /* @__PURE__ */ __name((v) => {
  let vp = Math.pow(v, 1 / p2);
  return 1e4 * Math.pow((c12 - vp) / (c32 * vp - c22), 1 / n2) || 0;
}, "pq_inv");
var rel = /* @__PURE__ */ __name((v) => v / 203, "rel");
var convertJabToXyz65 = /* @__PURE__ */ __name(({ j, a, b, alpha }) => {
  let i = (j + d02) / (0.44 + 0.56 * (j + d02));
  let l = pq_inv(i + 0.13860504 * a + 0.058047316 * b);
  let m = pq_inv(i - 0.13860504 * a - 0.058047316 * b);
  let s = pq_inv(i - 0.096019242 * a - 0.8118919 * b);
  let res = {
    mode: "xyz65",
    x: rel(1.661373024652174 * l - 0.914523081304348 * m + 0.23136208173913045 * s),
    y: rel(-0.3250758611844533 * l + 1.571847026732543 * m - 0.21825383453227928 * s),
    z: rel(-0.090982811 * l - 0.31272829 * m + 1.5227666 * s)
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertJabToXyz65");
var convertJabToXyz65_default = convertJabToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jab/convertRgbToJab.js
var convertRgbToJab = /* @__PURE__ */ __name((rgb3) => {
  let res = convertXyz65ToJab_default(convertRgbToXyz65_default(rgb3));
  if (rgb3.r === rgb3.b && rgb3.b === rgb3.g) {
    res.a = res.b = 0;
  }
  return res;
}, "convertRgbToJab");
var convertRgbToJab_default = convertRgbToJab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jab/convertJabToRgb.js
var convertJabToRgb = /* @__PURE__ */ __name((color) => convertXyz65ToRgb_default(convertJabToXyz65_default(color)), "convertJabToRgb");
var convertJabToRgb_default = convertJabToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jab/definition.js
var definition10 = {
  mode: "jab",
  channels: [
    "j",
    "a",
    "b",
    "alpha"
  ],
  parse: [
    "--jzazbz"
  ],
  serialize: "--jzazbz",
  fromMode: {
    rgb: convertRgbToJab_default,
    xyz65: convertXyz65ToJab_default
  },
  toMode: {
    rgb: convertJabToRgb_default,
    xyz65: convertJabToXyz65_default
  },
  ranges: {
    j: [
      0,
      0.222
    ],
    a: [
      -0.109,
      0.129
    ],
    b: [
      -0.185,
      0.134
    ]
  },
  interpolate: {
    j: interpolatorLinear,
    a: interpolatorLinear,
    b: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default10 = definition10;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jch/convertJabToJch.js
var convertJabToJch = /* @__PURE__ */ __name(({ j, a, b, alpha }) => {
  let c4 = Math.sqrt(a * a + b * b);
  let res = {
    mode: "jch",
    j,
    c: c4
  };
  if (c4) {
    res.h = normalizeHue_default(Math.atan2(b, a) * 180 / Math.PI);
  }
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertJabToJch");
var convertJabToJch_default = convertJabToJch;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jch/convertJchToJab.js
var convertJchToJab = /* @__PURE__ */ __name(({ j, c: c4, h, alpha }) => {
  let res = {
    mode: "jab",
    j,
    a: c4 ? c4 * Math.cos(h / 180 * Math.PI) : 0,
    b: c4 ? c4 * Math.sin(h / 180 * Math.PI) : 0
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertJchToJab");
var convertJchToJab_default = convertJchToJab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/jch/definition.js
var definition11 = {
  mode: "jch",
  parse: [
    "--jzczhz"
  ],
  serialize: "--jzczhz",
  toMode: {
    jab: convertJchToJab_default,
    rgb: /* @__PURE__ */ __name((c4) => convertJabToRgb_default(convertJchToJab_default(c4)), "rgb")
  },
  fromMode: {
    rgb: /* @__PURE__ */ __name((c4) => convertJabToJch_default(convertRgbToJab_default(c4)), "rgb"),
    jab: convertJabToJch_default
  },
  channels: [
    "j",
    "c",
    "h",
    "alpha"
  ],
  ranges: {
    j: [
      0,
      0.221
    ],
    c: [
      0,
      0.19
    ],
    h: [
      0,
      360
    ]
  },
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    c: interpolatorLinear,
    j: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueChroma
  },
  average: {
    h: averageAngle
  }
};
var definition_default11 = definition11;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz50/constants.js
var k3 = Math.pow(29, 3) / Math.pow(3, 3);
var e3 = Math.pow(6, 3) / Math.pow(29, 3);

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab/convertLabToXyz50.js
var fn4 = /* @__PURE__ */ __name((v) => Math.pow(v, 3) > e3 ? Math.pow(v, 3) : (116 * v - 16) / k3, "fn");
var convertLabToXyz50 = /* @__PURE__ */ __name(({ l, a, b, alpha }) => {
  let fy = (l + 16) / 116;
  let fx = a / 500 + fy;
  let fz = fy - b / 200;
  let res = {
    mode: "xyz50",
    x: fn4(fx) * D50.X,
    y: fn4(fy) * D50.Y,
    z: fn4(fz) * D50.Z
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertLabToXyz50");
var convertLabToXyz50_default = convertLabToXyz50;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz50/convertXyz50ToRgb.js
var convertXyz50ToRgb = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let res = convertLrgbToRgb_default({
    r: x * 3.1341359569958707 - y * 1.6173863321612538 - 0.4906619460083532 * z,
    g: x * -0.978795502912089 + y * 1.916254567259524 + 0.03344273116131949 * z,
    b: x * 0.07195537988411677 - y * 0.2289768264158322 + 1.405386058324125 * z
  });
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz50ToRgb");
var convertXyz50ToRgb_default = convertXyz50ToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab/convertLabToRgb.js
var convertLabToRgb = /* @__PURE__ */ __name((lab2) => convertXyz50ToRgb_default(convertLabToXyz50_default(lab2)), "convertLabToRgb");
var convertLabToRgb_default = convertLabToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz50/convertRgbToXyz50.js
var convertRgbToXyz50 = /* @__PURE__ */ __name((rgb3) => {
  let { r: r2, g, b, alpha } = convertRgbToLrgb_default(rgb3);
  let res = {
    mode: "xyz50",
    x: 0.436065742824811 * r2 + 0.3851514688337912 * g + 0.14307845442264197 * b,
    y: 0.22249319175623702 * r2 + 0.7168870538238823 * g + 0.06061979053616537 * b,
    z: 0.013923904500943465 * r2 + 0.09708128566574634 * g + 0.7140993584005155 * b
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertRgbToXyz50");
var convertRgbToXyz50_default = convertRgbToXyz50;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab/convertXyz50ToLab.js
var f2 = /* @__PURE__ */ __name((value) => value > e3 ? Math.cbrt(value) : (k3 * value + 16) / 116, "f");
var convertXyz50ToLab = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let f0 = f2(x / D50.X);
  let f1 = f2(y / D50.Y);
  let f22 = f2(z / D50.Z);
  let res = {
    mode: "lab",
    l: 116 * f1 - 16,
    a: 500 * (f0 - f1),
    b: 200 * (f1 - f22)
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz50ToLab");
var convertXyz50ToLab_default = convertXyz50ToLab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab/convertRgbToLab.js
var convertRgbToLab = /* @__PURE__ */ __name((rgb3) => {
  let res = convertXyz50ToLab_default(convertRgbToXyz50_default(rgb3));
  if (rgb3.r === rgb3.b && rgb3.b === rgb3.g) {
    res.a = res.b = 0;
  }
  return res;
}, "convertRgbToLab");
var convertRgbToLab_default = convertRgbToLab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab/parseLab.js
function parseLab(color, parsed) {
  if (!parsed || parsed[0] !== "lab") {
    return void 0;
  }
  const res = {
    mode: "lab"
  };
  const [, l, a, b, alpha] = parsed;
  if (l.type === Tok.Hue || a.type === Tok.Hue || b.type === Tok.Hue) {
    return void 0;
  }
  if (l.type !== Tok.None) {
    res.l = l.value;
  }
  if (a.type !== Tok.None) {
    res.a = a.type === Tok.Number ? a.value : a.value * 125 / 100;
  }
  if (b.type !== Tok.None) {
    res.b = b.type === Tok.Number ? b.value : b.value * 125 / 100;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(parseLab, "parseLab");
var parseLab_default = parseLab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab/definition.js
var definition12 = {
  mode: "lab",
  toMode: {
    xyz50: convertLabToXyz50_default,
    rgb: convertLabToRgb_default
  },
  fromMode: {
    xyz50: convertXyz50ToLab_default,
    rgb: convertRgbToLab_default
  },
  channels: [
    "l",
    "a",
    "b",
    "alpha"
  ],
  ranges: {
    l: [
      0,
      100
    ],
    a: [
      -100,
      100
    ],
    b: [
      -100,
      100
    ]
  },
  parse: [
    parseLab_default
  ],
  serialize: /* @__PURE__ */ __name((c4) => `lab(${c4.l !== void 0 ? c4.l : "none"} ${c4.a !== void 0 ? c4.a : "none"} ${c4.b !== void 0 ? c4.b : "none"}${c4.alpha < 1 ? ` / ${c4.alpha}` : ""})`, "serialize"),
  interpolate: {
    l: interpolatorLinear,
    a: interpolatorLinear,
    b: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default12 = definition12;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lab65/definition.js
var definition13 = {
  ...definition_default12,
  mode: "lab65",
  parse: [
    "--lab-d65"
  ],
  serialize: "--lab-d65",
  toMode: {
    xyz65: convertLab65ToXyz65_default,
    rgb: convertLab65ToRgb_default
  },
  fromMode: {
    xyz65: convertXyz65ToLab65_default,
    rgb: convertRgbToLab65_default
  },
  ranges: {
    l: [
      0,
      100
    ],
    a: [
      -86.182,
      98.234
    ],
    b: [
      -107.86,
      94.477
    ]
  }
};
var definition_default13 = definition13;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lch/parseLch.js
function parseLch(color, parsed) {
  if (!parsed || parsed[0] !== "lch") {
    return void 0;
  }
  const res = {
    mode: "lch"
  };
  const [, l, c4, h, alpha] = parsed;
  if (l.type !== Tok.None) {
    if (l.type === Tok.Hue) {
      return void 0;
    }
    res.l = l.value;
  }
  if (c4.type !== Tok.None) {
    res.c = Math.max(0, c4.type === Tok.Number ? c4.value : c4.value * 150 / 100);
  }
  if (h.type !== Tok.None) {
    if (h.type === Tok.Percentage) {
      return void 0;
    }
    res.h = h.value;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(parseLch, "parseLch");
var parseLch_default = parseLch;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lch/definition.js
var definition14 = {
  mode: "lch",
  toMode: {
    lab: convertLchToLab_default,
    rgb: /* @__PURE__ */ __name((c4) => convertLabToRgb_default(convertLchToLab_default(c4)), "rgb")
  },
  fromMode: {
    rgb: /* @__PURE__ */ __name((c4) => convertLabToLch_default(convertRgbToLab_default(c4)), "rgb"),
    lab: convertLabToLch_default
  },
  channels: [
    "l",
    "c",
    "h",
    "alpha"
  ],
  ranges: {
    l: [
      0,
      100
    ],
    c: [
      0,
      150
    ],
    h: [
      0,
      360
    ]
  },
  parse: [
    parseLch_default
  ],
  serialize: /* @__PURE__ */ __name((c4) => `lch(${c4.l !== void 0 ? c4.l : "none"} ${c4.c !== void 0 ? c4.c : "none"} ${c4.h || 0}${c4.alpha < 1 ? ` / ${c4.alpha}` : ""})`, "serialize"),
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    c: interpolatorLinear,
    l: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueChroma
  },
  average: {
    h: averageAngle
  }
};
var definition_default14 = definition14;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lch65/definition.js
var definition15 = {
  ...definition_default14,
  mode: "lch65",
  parse: [
    "--lch-d65"
  ],
  serialize: "--lch-d65",
  toMode: {
    lab65: /* @__PURE__ */ __name((c4) => convertLchToLab_default(c4, "lab65"), "lab65"),
    rgb: /* @__PURE__ */ __name((c4) => convertLab65ToRgb_default(convertLchToLab_default(c4, "lab65")), "rgb")
  },
  fromMode: {
    rgb: /* @__PURE__ */ __name((c4) => convertLabToLch_default(convertRgbToLab65_default(c4), "lch65"), "rgb"),
    lab65: /* @__PURE__ */ __name((c4) => convertLabToLch_default(c4, "lch65"), "lab65")
  },
  ranges: {
    l: [
      0,
      100
    ],
    c: [
      0,
      133.807
    ],
    h: [
      0,
      360
    ]
  }
};
var definition_default15 = definition15;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lchuv/convertLuvToLchuv.js
var convertLuvToLchuv = /* @__PURE__ */ __name(({ l, u, v, alpha }) => {
  let c4 = Math.sqrt(u * u + v * v);
  let res = {
    mode: "lchuv",
    l,
    c: c4
  };
  if (c4) {
    res.h = normalizeHue_default(Math.atan2(v, u) * 180 / Math.PI);
  }
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertLuvToLchuv");
var convertLuvToLchuv_default = convertLuvToLchuv;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lchuv/convertLchuvToLuv.js
var convertLchuvToLuv = /* @__PURE__ */ __name(({ l, c: c4, h, alpha }) => {
  let res = {
    mode: "luv",
    l,
    u: c4 ? c4 * Math.cos(h / 180 * Math.PI) : 0,
    v: c4 ? c4 * Math.sin(h / 180 * Math.PI) : 0
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertLchuvToLuv");
var convertLchuvToLuv_default = convertLchuvToLuv;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/luv/convertXyz50ToLuv.js
var u_fn = /* @__PURE__ */ __name((x, y, z) => 4 * x / (x + 15 * y + 3 * z), "u_fn");
var v_fn = /* @__PURE__ */ __name((x, y, z) => 9 * y / (x + 15 * y + 3 * z), "v_fn");
var un = u_fn(D50.X, D50.Y, D50.Z);
var vn = v_fn(D50.X, D50.Y, D50.Z);
var l_fn = /* @__PURE__ */ __name((value) => value <= e3 ? k3 * value : 116 * Math.cbrt(value) - 16, "l_fn");
var convertXyz50ToLuv = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let l = l_fn(y / D50.Y);
  let u = u_fn(x, y, z);
  let v = v_fn(x, y, z);
  if (!isFinite(u) || !isFinite(v)) {
    l = u = v = 0;
  } else {
    u = 13 * l * (u - un);
    v = 13 * l * (v - vn);
  }
  let res = {
    mode: "luv",
    l,
    u,
    v
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz50ToLuv");
var convertXyz50ToLuv_default = convertXyz50ToLuv;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/luv/convertLuvToXyz50.js
var u_fn2 = /* @__PURE__ */ __name((x, y, z) => 4 * x / (x + 15 * y + 3 * z), "u_fn");
var v_fn2 = /* @__PURE__ */ __name((x, y, z) => 9 * y / (x + 15 * y + 3 * z), "v_fn");
var un2 = u_fn2(D50.X, D50.Y, D50.Z);
var vn2 = v_fn2(D50.X, D50.Y, D50.Z);
var convertLuvToXyz50 = /* @__PURE__ */ __name(({ l, u, v, alpha }) => {
  let up = u / (13 * l) + un2;
  let vp = v / (13 * l) + vn2;
  let y = D50.Y * (l <= 8 ? l / k3 : Math.pow((l + 16) / 116, 3));
  let x = y * (9 * up) / (4 * vp);
  let z = y * (12 - 3 * up - 20 * vp) / (4 * vp);
  let res = {
    mode: "xyz50",
    x,
    y,
    z
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertLuvToXyz50");
var convertLuvToXyz50_default = convertLuvToXyz50;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lchuv/definition.js
var convertRgbToLchuv = /* @__PURE__ */ __name((rgb3) => convertLuvToLchuv_default(convertXyz50ToLuv_default(convertRgbToXyz50_default(rgb3))), "convertRgbToLchuv");
var convertLchuvToRgb = /* @__PURE__ */ __name((lchuv2) => convertXyz50ToRgb_default(convertLuvToXyz50_default(convertLchuvToLuv_default(lchuv2))), "convertLchuvToRgb");
var definition16 = {
  mode: "lchuv",
  toMode: {
    luv: convertLchuvToLuv_default,
    rgb: convertLchuvToRgb
  },
  fromMode: {
    rgb: convertRgbToLchuv,
    luv: convertLuvToLchuv_default
  },
  channels: [
    "l",
    "c",
    "h",
    "alpha"
  ],
  parse: [
    "--lchuv"
  ],
  serialize: "--lchuv",
  ranges: {
    l: [
      0,
      100
    ],
    c: [
      0,
      176.956
    ],
    h: [
      0,
      360
    ]
  },
  interpolate: {
    h: {
      use: interpolatorLinear,
      fixup: fixupHueShorter
    },
    c: interpolatorLinear,
    l: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  },
  difference: {
    h: differenceHueChroma
  },
  average: {
    h: averageAngle
  }
};
var definition_default16 = definition16;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/lrgb/definition.js
var definition17 = {
  ...definition_default,
  mode: "lrgb",
  toMode: {
    rgb: convertLrgbToRgb_default
  },
  fromMode: {
    rgb: convertRgbToLrgb_default
  },
  parse: [
    "srgb-linear"
  ],
  serialize: "srgb-linear"
};
var definition_default17 = definition17;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/luv/definition.js
var definition18 = {
  mode: "luv",
  toMode: {
    xyz50: convertLuvToXyz50_default,
    rgb: /* @__PURE__ */ __name((luv2) => convertXyz50ToRgb_default(convertLuvToXyz50_default(luv2)), "rgb")
  },
  fromMode: {
    xyz50: convertXyz50ToLuv_default,
    rgb: /* @__PURE__ */ __name((rgb3) => convertXyz50ToLuv_default(convertRgbToXyz50_default(rgb3)), "rgb")
  },
  channels: [
    "l",
    "u",
    "v",
    "alpha"
  ],
  parse: [
    "--luv"
  ],
  serialize: "--luv",
  ranges: {
    l: [
      0,
      100
    ],
    u: [
      -84.936,
      175.042
    ],
    v: [
      -125.882,
      87.243
    ]
  },
  interpolate: {
    l: interpolatorLinear,
    u: interpolatorLinear,
    v: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default18 = definition18;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklab/convertLrgbToOklab.js
var convertLrgbToOklab = /* @__PURE__ */ __name(({ r: r2, g, b, alpha }) => {
  let L2 = Math.cbrt(0.41222147079999993 * r2 + 0.5363325363 * g + 0.0514459929 * b);
  let M2 = Math.cbrt(0.2119034981999999 * r2 + 0.6806995450999999 * g + 0.1073969566 * b);
  let S = Math.cbrt(0.08830246189999998 * r2 + 0.2817188376 * g + 0.6299787005000002 * b);
  let res = {
    mode: "oklab",
    l: 0.2104542553 * L2 + 0.793617785 * M2 - 0.0040720468 * S,
    a: 1.9779984951 * L2 - 2.428592205 * M2 + 0.4505937099 * S,
    b: 0.0259040371 * L2 + 0.7827717662 * M2 - 0.808675766 * S
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertLrgbToOklab");
var convertLrgbToOklab_default = convertLrgbToOklab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklab/convertRgbToOklab.js
var convertRgbToOklab = /* @__PURE__ */ __name((rgb3) => {
  let res = convertLrgbToOklab_default(convertRgbToLrgb_default(rgb3));
  if (rgb3.r === rgb3.b && rgb3.b === rgb3.g) {
    res.a = res.b = 0;
  }
  return res;
}, "convertRgbToOklab");
var convertRgbToOklab_default = convertRgbToOklab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklab/convertOklabToLrgb.js
var convertOklabToLrgb = /* @__PURE__ */ __name(({ l, a, b, alpha }) => {
  let L2 = Math.pow(l * 0.9999999984505198 + 0.39633779217376786 * a + 0.2158037580607588 * b, 3);
  let M2 = Math.pow(l * 1.0000000088817609 - 0.10556134232365635 * a - 0.06385417477170591 * b, 3);
  let S = Math.pow(l * 1.0000000546724108 - 0.08948418209496575 * a - 1.2914855378640917 * b, 3);
  let res = {
    mode: "lrgb",
    r: 4.076741661347994 * L2 - 3.307711590408193 * M2 + 0.230969928729428 * S,
    g: -1.2684380040921763 * L2 + 2.6097574006633715 * M2 - 0.3413193963102197 * S,
    b: -0.004196086541837188 * L2 - 0.7034186144594493 * M2 + 1.7076147009309444 * S
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertOklabToLrgb");
var convertOklabToLrgb_default = convertOklabToLrgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklab/convertOklabToRgb.js
var convertOklabToRgb = /* @__PURE__ */ __name((c4) => convertLrgbToRgb_default(convertOklabToLrgb_default(c4)), "convertOklabToRgb");
var convertOklabToRgb_default = convertOklabToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsl/helpers.js
function toe(x) {
  const k_1 = 0.206;
  const k_2 = 0.03;
  const k_3 = (1 + k_1) / (1 + k_2);
  return 0.5 * (k_3 * x - k_1 + Math.sqrt((k_3 * x - k_1) * (k_3 * x - k_1) + 4 * k_2 * k_3 * x));
}
__name(toe, "toe");
function toe_inv(x) {
  const k_1 = 0.206;
  const k_2 = 0.03;
  const k_3 = (1 + k_1) / (1 + k_2);
  return (x * x + k_1 * x) / (k_3 * (x + k_2));
}
__name(toe_inv, "toe_inv");
function compute_max_saturation(a, b) {
  let k0, k1, k22, k32, k4, wl, wm, ws;
  if (-1.88170328 * a - 0.80936493 * b > 1) {
    k0 = 1.19086277;
    k1 = 1.76576728;
    k22 = 0.59662641;
    k32 = 0.75515197;
    k4 = 0.56771245;
    wl = 4.0767416621;
    wm = -3.3077115913;
    ws = 0.2309699292;
  } else if (1.81444104 * a - 1.19445276 * b > 1) {
    k0 = 0.73956515;
    k1 = -0.45954404;
    k22 = 0.08285427;
    k32 = 0.1254107;
    k4 = 0.14503204;
    wl = -1.2684380046;
    wm = 2.6097574011;
    ws = -0.3413193965;
  } else {
    k0 = 1.35733652;
    k1 = -915799e-8;
    k22 = -1.1513021;
    k32 = -0.50559606;
    k4 = 692167e-8;
    wl = -0.0041960863;
    wm = -0.7034186147;
    ws = 1.707614701;
  }
  let S = k0 + k1 * a + k22 * b + k32 * a * a + k4 * a * b;
  let k_l = 0.3963377774 * a + 0.2158037573 * b;
  let k_m = -0.1055613458 * a - 0.0638541728 * b;
  let k_s = -0.0894841775 * a - 1.291485548 * b;
  {
    let l_ = 1 + S * k_l;
    let m_ = 1 + S * k_m;
    let s_ = 1 + S * k_s;
    let l = l_ * l_ * l_;
    let m = m_ * m_ * m_;
    let s = s_ * s_ * s_;
    let l_dS = 3 * k_l * l_ * l_;
    let m_dS = 3 * k_m * m_ * m_;
    let s_dS = 3 * k_s * s_ * s_;
    let l_dS2 = 6 * k_l * k_l * l_;
    let m_dS2 = 6 * k_m * k_m * m_;
    let s_dS2 = 6 * k_s * k_s * s_;
    let f3 = wl * l + wm * m + ws * s;
    let f1 = wl * l_dS + wm * m_dS + ws * s_dS;
    let f22 = wl * l_dS2 + wm * m_dS2 + ws * s_dS2;
    S = S - f3 * f1 / (f1 * f1 - 0.5 * f3 * f22);
  }
  return S;
}
__name(compute_max_saturation, "compute_max_saturation");
function find_cusp(a, b) {
  let S_cusp = compute_max_saturation(a, b);
  let rgb3 = convertOklabToLrgb_default({
    l: 1,
    a: S_cusp * a,
    b: S_cusp * b
  });
  let L_cusp = Math.cbrt(1 / Math.max(rgb3.r, rgb3.g, rgb3.b));
  let C_cusp = L_cusp * S_cusp;
  return [
    L_cusp,
    C_cusp
  ];
}
__name(find_cusp, "find_cusp");
function find_gamut_intersection(a, b, L1, C1, L0, cusp = null) {
  if (!cusp) {
    cusp = find_cusp(a, b);
  }
  let t;
  if ((L1 - L0) * cusp[1] - (cusp[0] - L0) * C1 <= 0) {
    t = cusp[1] * L0 / (C1 * cusp[0] + cusp[1] * (L0 - L1));
  } else {
    t = cusp[1] * (L0 - 1) / (C1 * (cusp[0] - 1) + cusp[1] * (L0 - L1));
    {
      let dL = L1 - L0;
      let dC = C1;
      let k_l = 0.3963377774 * a + 0.2158037573 * b;
      let k_m = -0.1055613458 * a - 0.0638541728 * b;
      let k_s = -0.0894841775 * a - 1.291485548 * b;
      let l_dt = dL + dC * k_l;
      let m_dt = dL + dC * k_m;
      let s_dt = dL + dC * k_s;
      {
        let L2 = L0 * (1 - t) + t * L1;
        let C = t * C1;
        let l_ = L2 + C * k_l;
        let m_ = L2 + C * k_m;
        let s_ = L2 + C * k_s;
        let l = l_ * l_ * l_;
        let m = m_ * m_ * m_;
        let s = s_ * s_ * s_;
        let ldt = 3 * l_dt * l_ * l_;
        let mdt = 3 * m_dt * m_ * m_;
        let sdt = 3 * s_dt * s_ * s_;
        let ldt2 = 6 * l_dt * l_dt * l_;
        let mdt2 = 6 * m_dt * m_dt * m_;
        let sdt2 = 6 * s_dt * s_dt * s_;
        let r2 = 4.0767416621 * l - 3.3077115913 * m + 0.2309699292 * s - 1;
        let r1 = 4.0767416621 * ldt - 3.3077115913 * mdt + 0.2309699292 * sdt;
        let r22 = 4.0767416621 * ldt2 - 3.3077115913 * mdt2 + 0.2309699292 * sdt2;
        let u_r = r1 / (r1 * r1 - 0.5 * r2 * r22);
        let t_r = -r2 * u_r;
        let g = -1.2684380046 * l + 2.6097574011 * m - 0.3413193965 * s - 1;
        let g1 = -1.2684380046 * ldt + 2.6097574011 * mdt - 0.3413193965 * sdt;
        let g2 = -1.2684380046 * ldt2 + 2.6097574011 * mdt2 - 0.3413193965 * sdt2;
        let u_g = g1 / (g1 * g1 - 0.5 * g * g2);
        let t_g = -g * u_g;
        let b2 = -0.0041960863 * l - 0.7034186147 * m + 1.707614701 * s - 1;
        let b1 = -0.0041960863 * ldt - 0.7034186147 * mdt + 1.707614701 * sdt;
        let b22 = -0.0041960863 * ldt2 - 0.7034186147 * mdt2 + 1.707614701 * sdt2;
        let u_b = b1 / (b1 * b1 - 0.5 * b2 * b22);
        let t_b = -b2 * u_b;
        t_r = u_r >= 0 ? t_r : 1e6;
        t_g = u_g >= 0 ? t_g : 1e6;
        t_b = u_b >= 0 ? t_b : 1e6;
        t += Math.min(t_r, Math.min(t_g, t_b));
      }
    }
  }
  return t;
}
__name(find_gamut_intersection, "find_gamut_intersection");
function get_ST_max(a_, b_, cusp = null) {
  if (!cusp) {
    cusp = find_cusp(a_, b_);
  }
  let L2 = cusp[0];
  let C = cusp[1];
  return [
    C / L2,
    C / (1 - L2)
  ];
}
__name(get_ST_max, "get_ST_max");
function get_Cs(L2, a_, b_) {
  let cusp = find_cusp(a_, b_);
  let C_max = find_gamut_intersection(a_, b_, L2, 1, L2, cusp);
  let ST_max = get_ST_max(a_, b_, cusp);
  let S_mid = 0.11516993 + 1 / (7.4477897 + 4.1590124 * b_ + a_ * (-2.19557347 + 1.75198401 * b_ + a_ * (-2.13704948 - 10.02301043 * b_ + a_ * (-4.24894561 + 5.38770819 * b_ + 4.69891013 * a_))));
  let T_mid = 0.11239642 + 1 / (1.6132032 - 0.68124379 * b_ + a_ * (0.40370612 + 0.90148123 * b_ + a_ * (-0.27087943 + 0.6122399 * b_ + a_ * (299215e-8 - 0.45399568 * b_ - 0.14661872 * a_))));
  let k4 = C_max / Math.min(L2 * ST_max[0], (1 - L2) * ST_max[1]);
  let C_a = L2 * S_mid;
  let C_b = (1 - L2) * T_mid;
  let C_mid = 0.9 * k4 * Math.sqrt(Math.sqrt(1 / (1 / (C_a * C_a * C_a * C_a) + 1 / (C_b * C_b * C_b * C_b))));
  C_a = L2 * 0.4;
  C_b = (1 - L2) * 0.8;
  let C_0 = Math.sqrt(1 / (1 / (C_a * C_a) + 1 / (C_b * C_b)));
  return [
    C_0,
    C_mid,
    C_max
  ];
}
__name(get_Cs, "get_Cs");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsl/convertOklabToOkhsl.js
function convertOklabToOkhsl(lab2) {
  const ret = {
    mode: "okhsl",
    l: toe(lab2.l)
  };
  if (lab2.alpha !== void 0) {
    ret.alpha = lab2.alpha;
  }
  let c4 = Math.sqrt(lab2.a * lab2.a + lab2.b * lab2.b);
  if (!c4) {
    ret.s = 0;
    return ret;
  }
  let [C_0, C_mid, C_max] = get_Cs(lab2.l, lab2.a / c4, lab2.b / c4);
  let s;
  if (c4 < C_mid) {
    let k_0 = 0;
    let k_1 = 0.8 * C_0;
    let k_2 = 1 - k_1 / C_mid;
    let t = (c4 - k_0) / (k_1 + k_2 * (c4 - k_0));
    s = t * 0.8;
  } else {
    let k_0 = C_mid;
    let k_1 = 0.2 * C_mid * C_mid * 1.25 * 1.25 / C_0;
    let k_2 = 1 - k_1 / (C_max - C_mid);
    let t = (c4 - k_0) / (k_1 + k_2 * (c4 - k_0));
    s = 0.8 + 0.2 * t;
  }
  if (s) {
    ret.s = s;
    ret.h = normalizeHue_default(Math.atan2(lab2.b, lab2.a) * 180 / Math.PI);
  }
  return ret;
}
__name(convertOklabToOkhsl, "convertOklabToOkhsl");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsl/convertOkhslToOklab.js
function convertOkhslToOklab(hsl2) {
  let l = toe_inv(hsl2.l);
  const ret = {
    mode: "oklab",
    l
  };
  if (hsl2.alpha !== void 0) {
    ret.alpha = hsl2.alpha;
  }
  if (!hsl2.s || hsl2.l === 1) {
    ret.a = ret.b = 0;
    return ret;
  }
  let a_ = Math.cos(hsl2.h / 180 * Math.PI);
  let b_ = Math.sin(hsl2.h / 180 * Math.PI);
  let [C_0, C_mid, C_max] = get_Cs(l, a_, b_);
  let t, k_0, k_1, k_2;
  if (hsl2.s < 0.8) {
    t = 1.25 * hsl2.s;
    k_0 = 0;
    k_1 = 0.8 * C_0;
    k_2 = 1 - k_1 / C_mid;
  } else {
    t = 5 * (hsl2.s - 0.8);
    k_0 = C_mid;
    k_1 = 0.2 * C_mid * C_mid * 1.25 * 1.25 / C_0;
    k_2 = 1 - k_1 / (C_max - C_mid);
  }
  let C = k_0 + t * k_1 / (1 - k_2 * t);
  ret.a = C * a_;
  ret.b = C * b_;
  return ret;
}
__name(convertOkhslToOklab, "convertOkhslToOklab");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsl/modeOkhsl.js
var modeOkhsl = {
  ...definition_default7,
  mode: "okhsl",
  channels: [
    "h",
    "s",
    "l",
    "alpha"
  ],
  parse: [
    "--okhsl"
  ],
  serialize: "--okhsl",
  fromMode: {
    oklab: convertOklabToOkhsl,
    rgb: /* @__PURE__ */ __name((c4) => convertOklabToOkhsl(convertRgbToOklab_default(c4)), "rgb")
  },
  toMode: {
    oklab: convertOkhslToOklab,
    rgb: /* @__PURE__ */ __name((c4) => convertOklabToRgb_default(convertOkhslToOklab(c4)), "rgb")
  }
};
var modeOkhsl_default = modeOkhsl;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsv/convertOklabToOkhsv.js
function convertOklabToOkhsv(lab2) {
  let c4 = Math.sqrt(lab2.a * lab2.a + lab2.b * lab2.b);
  let l = lab2.l;
  let a_ = c4 ? lab2.a / c4 : 1;
  let b_ = c4 ? lab2.b / c4 : 1;
  let [S_max, T] = get_ST_max(a_, b_);
  let S_0 = 0.5;
  let k4 = 1 - S_0 / S_max;
  let t = T / (c4 + l * T);
  let L_v = t * l;
  let C_v = t * c4;
  let L_vt = toe_inv(L_v);
  let C_vt = C_v * L_vt / L_v;
  let rgb_scale = convertOklabToLrgb_default({
    l: L_vt,
    a: a_ * C_vt,
    b: b_ * C_vt
  });
  let scale_L = Math.cbrt(1 / Math.max(rgb_scale.r, rgb_scale.g, rgb_scale.b, 0));
  l = l / scale_L;
  c4 = c4 / scale_L * toe(l) / l;
  l = toe(l);
  const ret = {
    mode: "okhsv",
    s: c4 ? (S_0 + T) * C_v / (T * S_0 + T * k4 * C_v) : 0,
    v: l ? l / L_v : 0
  };
  if (ret.s) {
    ret.h = normalizeHue_default(Math.atan2(lab2.b, lab2.a) * 180 / Math.PI);
  }
  if (lab2.alpha !== void 0) {
    ret.alpha = lab2.alpha;
  }
  return ret;
}
__name(convertOklabToOkhsv, "convertOklabToOkhsv");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsv/convertOkhsvToOklab.js
function convertOkhsvToOklab(hsv2) {
  const ret = {
    mode: "oklab"
  };
  if (hsv2.alpha !== void 0) {
    ret.alpha = hsv2.alpha;
  }
  const h = hsv2.h || 0;
  const a_ = Math.cos(h / 180 * Math.PI);
  const b_ = Math.sin(h / 180 * Math.PI);
  const [S_max, T] = get_ST_max(a_, b_);
  const S_0 = 0.5;
  const k4 = 1 - S_0 / S_max;
  const L_v = 1 - hsv2.s * S_0 / (S_0 + T - T * k4 * hsv2.s);
  const C_v = hsv2.s * T * S_0 / (S_0 + T - T * k4 * hsv2.s);
  const L_vt = toe_inv(L_v);
  const C_vt = C_v * L_vt / L_v;
  const rgb_scale = convertOklabToLrgb_default({
    l: L_vt,
    a: a_ * C_vt,
    b: b_ * C_vt
  });
  const scale_L = Math.cbrt(1 / Math.max(rgb_scale.r, rgb_scale.g, rgb_scale.b, 0));
  const L_new = toe_inv(hsv2.v * L_v);
  const C = C_v * L_new / L_v;
  ret.l = L_new * scale_L;
  ret.a = C * a_ * scale_L;
  ret.b = C * b_ * scale_L;
  return ret;
}
__name(convertOkhsvToOklab, "convertOkhsvToOklab");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/okhsv/modeOkhsv.js
var modeOkhsv = {
  ...definition_default8,
  mode: "okhsv",
  channels: [
    "h",
    "s",
    "v",
    "alpha"
  ],
  parse: [
    "--okhsv"
  ],
  serialize: "--okhsv",
  fromMode: {
    oklab: convertOklabToOkhsv,
    rgb: /* @__PURE__ */ __name((c4) => convertOklabToOkhsv(convertRgbToOklab_default(c4)), "rgb")
  },
  toMode: {
    oklab: convertOkhsvToOklab,
    rgb: /* @__PURE__ */ __name((c4) => convertOklabToRgb_default(convertOkhsvToOklab(c4)), "rgb")
  }
};
var modeOkhsv_default = modeOkhsv;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklab/parseOklab.js
function parseOklab(color, parsed) {
  if (!parsed || parsed[0] !== "oklab") {
    return void 0;
  }
  const res = {
    mode: "oklab"
  };
  const [, l, a, b, alpha] = parsed;
  if (l.type === Tok.Hue || a.type === Tok.Hue || b.type === Tok.Hue) {
    return void 0;
  }
  if (l.type !== Tok.None) {
    res.l = l.type === Tok.Number ? l.value : l.value / 100;
  }
  if (a.type !== Tok.None) {
    res.a = a.type === Tok.Number ? a.value : a.value * 0.4 / 100;
  }
  if (b.type !== Tok.None) {
    res.b = b.type === Tok.Number ? b.value : b.value * 0.4 / 100;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(parseOklab, "parseOklab");
var parseOklab_default = parseOklab;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklab/definition.js
var definition19 = {
  ...definition_default12,
  mode: "oklab",
  toMode: {
    lrgb: convertOklabToLrgb_default,
    rgb: convertOklabToRgb_default
  },
  fromMode: {
    lrgb: convertLrgbToOklab_default,
    rgb: convertRgbToOklab_default
  },
  ranges: {
    l: [
      0,
      1
    ],
    a: [
      -0.4,
      0.4
    ],
    b: [
      -0.4,
      0.4
    ]
  },
  parse: [
    parseOklab_default
  ],
  serialize: /* @__PURE__ */ __name((c4) => `oklab(${c4.l !== void 0 ? c4.l : "none"} ${c4.a !== void 0 ? c4.a : "none"} ${c4.b !== void 0 ? c4.b : "none"}${c4.alpha < 1 ? ` / ${c4.alpha}` : ""})`, "serialize")
};
var definition_default19 = definition19;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklch/parseOklch.js
function parseOklch(color, parsed) {
  if (!parsed || parsed[0] !== "oklch") {
    return void 0;
  }
  const res = {
    mode: "oklch"
  };
  const [, l, c4, h, alpha] = parsed;
  if (l.type !== Tok.None) {
    if (l.type === Tok.Hue) {
      return void 0;
    }
    res.l = l.type === Tok.Number ? l.value : l.value / 100;
  }
  if (c4.type !== Tok.None) {
    res.c = Math.max(0, c4.type === Tok.Number ? c4.value : c4.value * 0.4 / 100);
  }
  if (h.type !== Tok.None) {
    if (h.type === Tok.Percentage) {
      return void 0;
    }
    res.h = h.value;
  }
  if (alpha.type !== Tok.None) {
    res.alpha = alpha.type === Tok.Number ? alpha.value : alpha.value / 100;
  }
  return res;
}
__name(parseOklch, "parseOklch");
var parseOklch_default = parseOklch;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/oklch/definition.js
var definition20 = {
  ...definition_default14,
  mode: "oklch",
  toMode: {
    oklab: /* @__PURE__ */ __name((c4) => convertLchToLab_default(c4, "oklab"), "oklab"),
    rgb: /* @__PURE__ */ __name((c4) => convertOklabToRgb_default(convertLchToLab_default(c4, "oklab")), "rgb")
  },
  fromMode: {
    rgb: /* @__PURE__ */ __name((c4) => convertLabToLch_default(convertRgbToOklab_default(c4), "oklch"), "rgb"),
    oklab: /* @__PURE__ */ __name((c4) => convertLabToLch_default(c4, "oklch"), "oklab")
  },
  parse: [
    parseOklch_default
  ],
  serialize: /* @__PURE__ */ __name((c4) => `oklch(${c4.l !== void 0 ? c4.l : "none"} ${c4.c !== void 0 ? c4.c : "none"} ${c4.h || 0}${c4.alpha < 1 ? ` / ${c4.alpha}` : ""})`, "serialize"),
  ranges: {
    l: [
      0,
      1
    ],
    c: [
      0,
      0.4
    ],
    h: [
      0,
      360
    ]
  }
};
var definition_default20 = definition20;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/p3/convertP3ToXyz65.js
var convertP3ToXyz65 = /* @__PURE__ */ __name((rgb3) => {
  let { r: r2, g, b, alpha } = convertRgbToLrgb_default(rgb3);
  let res = {
    mode: "xyz65",
    x: 0.486570948648216 * r2 + 0.265667693169093 * g + 0.1982172852343625 * b,
    y: 0.2289745640697487 * r2 + 0.6917385218365062 * g + 0.079286914093745 * b,
    z: 0 * r2 + 0.0451133818589026 * g + 1.043944368900976 * b
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertP3ToXyz65");
var convertP3ToXyz65_default = convertP3ToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/p3/convertXyz65ToP3.js
var convertXyz65ToP3 = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let res = convertLrgbToRgb_default({
    r: x * 2.4934969119414263 - y * 0.9313836179191242 - 0.402710784450717 * z,
    g: x * -0.8294889695615749 + y * 1.7626640603183465 + 0.0236246858419436 * z,
    b: x * 0.0358458302437845 - y * 0.0761723892680418 + 0.9568845240076871 * z
  }, "p3");
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToP3");
var convertXyz65ToP3_default = convertXyz65ToP3;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/p3/definition.js
var definition21 = {
  ...definition_default,
  mode: "p3",
  parse: [
    "display-p3"
  ],
  serialize: "display-p3",
  fromMode: {
    rgb: /* @__PURE__ */ __name((color) => convertXyz65ToP3_default(convertRgbToXyz65_default(color)), "rgb"),
    xyz65: convertXyz65ToP3_default
  },
  toMode: {
    rgb: /* @__PURE__ */ __name((color) => convertXyz65ToRgb_default(convertP3ToXyz65_default(color)), "rgb"),
    xyz65: convertP3ToXyz65_default
  }
};
var definition_default21 = definition21;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/prophoto/convertXyz50ToProphoto.js
var gamma2 = /* @__PURE__ */ __name((v) => {
  let abs2 = Math.abs(v);
  if (abs2 >= 1 / 512) {
    return Math.sign(v) * Math.pow(abs2, 1 / 1.8);
  }
  return 16 * v;
}, "gamma");
var convertXyz50ToProphoto = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let res = {
    mode: "prophoto",
    r: gamma2(x * 1.3457868816471585 - y * 0.2555720873797946 - 0.0511018649755453 * z),
    g: gamma2(x * -0.5446307051249019 + y * 1.5082477428451466 + 0.0205274474364214 * z),
    b: gamma2(x * 0 + y * 0 + 1.2119675456389452 * z)
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz50ToProphoto");
var convertXyz50ToProphoto_default = convertXyz50ToProphoto;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/prophoto/convertProphotoToXyz50.js
var linearize2 = /* @__PURE__ */ __name((v) => {
  let abs2 = Math.abs(v);
  if (abs2 >= 16 / 512) {
    return Math.sign(v) * Math.pow(abs2, 1.8);
  }
  return v / 16;
}, "linearize");
var convertProphotoToXyz50 = /* @__PURE__ */ __name((prophoto2) => {
  let r2 = linearize2(prophoto2.r);
  let g = linearize2(prophoto2.g);
  let b = linearize2(prophoto2.b);
  let res = {
    mode: "xyz50",
    x: 0.7977666449006423 * r2 + 0.1351812974005331 * g + 0.0313477341283922 * b,
    y: 0.2880748288194013 * r2 + 0.7118352342418731 * g + 899369387256e-16 * b,
    z: 0 * r2 + 0 * g + 0.8251046025104602 * b
  };
  if (prophoto2.alpha !== void 0) {
    res.alpha = prophoto2.alpha;
  }
  return res;
}, "convertProphotoToXyz50");
var convertProphotoToXyz50_default = convertProphotoToXyz50;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/prophoto/definition.js
var definition22 = {
  ...definition_default,
  mode: "prophoto",
  parse: [
    "prophoto-rgb"
  ],
  serialize: "prophoto-rgb",
  fromMode: {
    xyz50: convertXyz50ToProphoto_default,
    rgb: /* @__PURE__ */ __name((color) => convertXyz50ToProphoto_default(convertRgbToXyz50_default(color)), "rgb")
  },
  toMode: {
    xyz50: convertProphotoToXyz50_default,
    rgb: /* @__PURE__ */ __name((color) => convertXyz50ToRgb_default(convertProphotoToXyz50_default(color)), "rgb")
  }
};
var definition_default22 = definition22;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rec2020/convertXyz65ToRec2020.js
var \u03B1 = 1.09929682680944;
var \u03B2 = 0.018053968510807;
var gamma3 = /* @__PURE__ */ __name((v) => {
  const abs2 = Math.abs(v);
  if (abs2 > \u03B2) {
    return (Math.sign(v) || 1) * (\u03B1 * Math.pow(abs2, 0.45) - (\u03B1 - 1));
  }
  return 4.5 * v;
}, "gamma");
var convertXyz65ToRec2020 = /* @__PURE__ */ __name(({ x, y, z, alpha }) => {
  let res = {
    mode: "rec2020",
    r: gamma3(x * 1.7166511879712683 - y * 0.3556707837763925 - 0.2533662813736599 * z),
    g: gamma3(x * -0.6666843518324893 + y * 1.6164812366349395 + 0.0157685458139111 * z),
    b: gamma3(x * 0.0176398574453108 - y * 0.0427706132578085 + 0.9421031212354739 * z)
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToRec2020");
var convertXyz65ToRec2020_default = convertXyz65ToRec2020;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rec2020/convertRec2020ToXyz65.js
var \u03B12 = 1.09929682680944;
var \u03B22 = 0.018053968510807;
var linearize3 = /* @__PURE__ */ __name((v) => {
  let abs2 = Math.abs(v);
  if (abs2 < \u03B22 * 4.5) {
    return v / 4.5;
  }
  return (Math.sign(v) || 1) * Math.pow((abs2 + \u03B12 - 1) / \u03B12, 1 / 0.45);
}, "linearize");
var convertRec2020ToXyz65 = /* @__PURE__ */ __name((rec20202) => {
  let r2 = linearize3(rec20202.r);
  let g = linearize3(rec20202.g);
  let b = linearize3(rec20202.b);
  let res = {
    mode: "xyz65",
    x: 0.6369580483012911 * r2 + 0.1446169035862083 * g + 0.1688809751641721 * b,
    y: 0.262700212011267 * r2 + 0.6779980715188708 * g + 0.059301716469862 * b,
    z: 0 * r2 + 0.0280726930490874 * g + 1.0609850577107909 * b
  };
  if (rec20202.alpha !== void 0) {
    res.alpha = rec20202.alpha;
  }
  return res;
}, "convertRec2020ToXyz65");
var convertRec2020ToXyz65_default = convertRec2020ToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/rec2020/definition.js
var definition23 = {
  ...definition_default,
  mode: "rec2020",
  fromMode: {
    xyz65: convertXyz65ToRec2020_default,
    rgb: /* @__PURE__ */ __name((color) => convertXyz65ToRec2020_default(convertRgbToXyz65_default(color)), "rgb")
  },
  toMode: {
    xyz65: convertRec2020ToXyz65_default,
    rgb: /* @__PURE__ */ __name((color) => convertXyz65ToRgb_default(convertRec2020ToXyz65_default(color)), "rgb")
  },
  parse: [
    "rec2020"
  ],
  serialize: "rec2020"
};
var definition_default23 = definition23;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyb/constants.js
var bias = 0.0037930732552754493;
var bias_cbrt = Math.cbrt(bias);

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyb/convertRgbToXyb.js
var transfer = /* @__PURE__ */ __name((v) => Math.cbrt(v) - bias_cbrt, "transfer");
var convertRgbToXyb = /* @__PURE__ */ __name((color) => {
  const { r: r2, g, b, alpha } = convertRgbToLrgb_default(color);
  const l = transfer(0.3 * r2 + 0.622 * g + 0.078 * b + bias);
  const m = transfer(0.23 * r2 + 0.692 * g + 0.078 * b + bias);
  const s = transfer(0.2434226892454782 * r2 + 0.2047674442449682 * g + 0.5518098665095535 * b + bias);
  const res = {
    mode: "xyb",
    x: (l - m) / 2,
    y: (l + m) / 2,
    /* Apply default chroma from luma (subtract Y from B) */
    b: s - (l + m) / 2
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertRgbToXyb");
var convertRgbToXyb_default = convertRgbToXyb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyb/convertXybToRgb.js
var transfer2 = /* @__PURE__ */ __name((v) => Math.pow(v + bias_cbrt, 3), "transfer");
var convertXybToRgb = /* @__PURE__ */ __name(({ x, y, b, alpha }) => {
  const l = transfer2(x + y) - bias;
  const m = transfer2(y - x) - bias;
  const s = transfer2(b + y) - bias;
  const res = convertLrgbToRgb_default({
    r: 11.031566904639861 * l - 9.866943908131562 * m - 0.16462299650829934 * s,
    g: -3.2541473810744237 * l + 4.418770377582723 * m - 0.16462299650829934 * s,
    b: -3.6588512867136815 * l + 2.7129230459360922 * m + 1.9459282407775895 * s
  });
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertXybToRgb");
var convertXybToRgb_default = convertXybToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyb/definition.js
var definition24 = {
  mode: "xyb",
  channels: [
    "x",
    "y",
    "b",
    "alpha"
  ],
  parse: [
    "--xyb"
  ],
  serialize: "--xyb",
  toMode: {
    rgb: convertXybToRgb_default
  },
  fromMode: {
    rgb: convertRgbToXyb_default
  },
  ranges: {
    x: [
      -0.0154,
      0.0281
    ],
    y: [
      0,
      0.8453
    ],
    b: [
      -0.2778,
      0.388
    ]
  },
  interpolate: {
    x: interpolatorLinear,
    y: interpolatorLinear,
    b: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default24 = definition24;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz50/definition.js
var definition25 = {
  mode: "xyz50",
  parse: [
    "xyz-d50"
  ],
  serialize: "xyz-d50",
  toMode: {
    rgb: convertXyz50ToRgb_default,
    lab: convertXyz50ToLab_default
  },
  fromMode: {
    rgb: convertRgbToXyz50_default,
    lab: convertLabToXyz50_default
  },
  channels: [
    "x",
    "y",
    "z",
    "alpha"
  ],
  ranges: {
    x: [
      0,
      0.964
    ],
    y: [
      0,
      0.999
    ],
    z: [
      0,
      0.825
    ]
  },
  interpolate: {
    x: interpolatorLinear,
    y: interpolatorLinear,
    z: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default25 = definition25;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz65/convertXyz65ToXyz50.js
var convertXyz65ToXyz50 = /* @__PURE__ */ __name((xyz652) => {
  let { x, y, z, alpha } = xyz652;
  let res = {
    mode: "xyz50",
    x: 1.0479298208405488 * x + 0.0229467933410191 * y - 0.0501922295431356 * z,
    y: 0.0296278156881593 * x + 0.990434484573249 * y - 0.0170738250293851 * z,
    z: -0.0092430581525912 * x + 0.0150551448965779 * y + 0.7518742899580008 * z
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz65ToXyz50");
var convertXyz65ToXyz50_default = convertXyz65ToXyz50;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz65/convertXyz50ToXyz65.js
var convertXyz50ToXyz65 = /* @__PURE__ */ __name((xyz502) => {
  let { x, y, z, alpha } = xyz502;
  let res = {
    mode: "xyz65",
    x: 0.9554734527042182 * x - 0.0230985368742614 * y + 0.0632593086610217 * z,
    y: -0.0283697069632081 * x + 1.0099954580058226 * y + 0.021041398966943 * z,
    z: 0.0123140016883199 * x - 0.0205076964334779 * y + 1.3303659366080753 * z
  };
  if (alpha !== void 0) {
    res.alpha = alpha;
  }
  return res;
}, "convertXyz50ToXyz65");
var convertXyz50ToXyz65_default = convertXyz50ToXyz65;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/xyz65/definition.js
var definition26 = {
  mode: "xyz65",
  toMode: {
    rgb: convertXyz65ToRgb_default,
    xyz50: convertXyz65ToXyz50_default
  },
  fromMode: {
    rgb: convertRgbToXyz65_default,
    xyz50: convertXyz50ToXyz65_default
  },
  ranges: {
    x: [
      0,
      0.95
    ],
    y: [
      0,
      1
    ],
    z: [
      0,
      1.088
    ]
  },
  channels: [
    "x",
    "y",
    "z",
    "alpha"
  ],
  parse: [
    "xyz",
    "xyz-d65"
  ],
  serialize: "xyz-d65",
  interpolate: {
    x: interpolatorLinear,
    y: interpolatorLinear,
    z: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default26 = definition26;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/yiq/convertRgbToYiq.js
var convertRgbToYiq = /* @__PURE__ */ __name(({ r: r2, g, b, alpha }) => {
  const res = {
    mode: "yiq",
    y: 0.29889531 * r2 + 0.58662247 * g + 0.11448223 * b,
    i: 0.59597799 * r2 - 0.2741761 * g - 0.32180189 * b,
    q: 0.21147017 * r2 - 0.52261711 * g + 0.31114694 * b
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertRgbToYiq");
var convertRgbToYiq_default = convertRgbToYiq;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/yiq/convertYiqToRgb.js
var convertYiqToRgb = /* @__PURE__ */ __name(({ y, i, q, alpha }) => {
  const res = {
    mode: "rgb",
    r: y + 0.95608445 * i + 0.6208885 * q,
    g: y - 0.27137664 * i - 0.6486059 * q,
    b: y - 1.10561724 * i + 1.70250126 * q
  };
  if (alpha !== void 0) res.alpha = alpha;
  return res;
}, "convertYiqToRgb");
var convertYiqToRgb_default = convertYiqToRgb;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/yiq/definition.js
var definition27 = {
  mode: "yiq",
  toMode: {
    rgb: convertYiqToRgb_default
  },
  fromMode: {
    rgb: convertRgbToYiq_default
  },
  channels: [
    "y",
    "i",
    "q",
    "alpha"
  ],
  parse: [
    "--yiq"
  ],
  serialize: "--yiq",
  ranges: {
    i: [
      -0.595,
      0.595
    ],
    q: [
      -0.522,
      0.522
    ]
  },
  interpolate: {
    y: interpolatorLinear,
    i: interpolatorLinear,
    q: interpolatorLinear,
    alpha: {
      use: interpolatorLinear,
      fixup: fixupAlpha
    }
  }
};
var definition_default27 = definition27;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/round.js
var r = /* @__PURE__ */ __name((value, precision) => Math.round(value * (precision = Math.pow(10, precision))) / precision, "r");
var round = /* @__PURE__ */ __name((precision = 4) => (value) => typeof value === "number" ? r(value, precision) : value, "round");
var round_default = round;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/formatter.js
var twoDecimals = round_default(2);
var clamp = /* @__PURE__ */ __name((value) => Math.max(0, Math.min(1, value)), "clamp");
var fixup = /* @__PURE__ */ __name((value) => Math.round(clamp(value) * 255), "fixup");
var serializeHex = /* @__PURE__ */ __name((color) => {
  if (color === void 0) {
    return void 0;
  }
  let r2 = fixup(color.r);
  let g = fixup(color.g);
  let b = fixup(color.b);
  return "#" + (1 << 24 | r2 << 16 | g << 8 | b).toString(16).slice(1);
}, "serializeHex");
var serializeHex8 = /* @__PURE__ */ __name((color) => {
  if (color === void 0) {
    return void 0;
  }
  let a = fixup(color.alpha !== void 0 ? color.alpha : 1);
  return serializeHex(color) + (1 << 8 | a).toString(16).slice(1);
}, "serializeHex8");
var formatHex = /* @__PURE__ */ __name((c4) => serializeHex(converter_default("rgb")(c4)), "formatHex");
var formatHex8 = /* @__PURE__ */ __name((c4) => serializeHex8(converter_default("rgb")(c4)), "formatHex8");

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/random.js
var rand = /* @__PURE__ */ __name(([min, max]) => min + Math.random() * (max - min), "rand");
var to_intervals = /* @__PURE__ */ __name((constraints) => Object.keys(constraints).reduce((o, k4) => {
  let v = constraints[k4];
  o[k4] = Array.isArray(v) ? v : [
    v,
    v
  ];
  return o;
}, {}), "to_intervals");
var random = /* @__PURE__ */ __name((mode = "rgb", constraints = {}) => {
  let def = getMode(mode);
  let limits = to_intervals(constraints);
  return def.channels.reduce((res, ch) => {
    if (limits.alpha || ch !== "alpha") {
      res[ch] = rand(limits[ch] || def.ranges[ch]);
    }
    return res;
  }, {
    mode
  });
}, "random");
var random_default = random;

// ../../node_modules/.pnpm/culori@3.0.1/node_modules/culori/src/index.js
var a98 = useMode(definition_default2);
var cubehelix = useMode(definition_default3);
var dlab = useMode(definition_default4);
var dlch = useMode(definition_default5);
var hsi = useMode(definition_default6);
var hsl = useMode(definition_default7);
var hsv = useMode(definition_default8);
var hwb = useMode(definition_default9);
var jab = useMode(definition_default10);
var jch = useMode(definition_default11);
var lab = useMode(definition_default12);
var lab65 = useMode(definition_default13);
var lch = useMode(definition_default14);
var lch65 = useMode(definition_default15);
var lchuv = useMode(definition_default16);
var lrgb = useMode(definition_default17);
var luv = useMode(definition_default18);
var okhsl = useMode(modeOkhsl_default);
var okhsv = useMode(modeOkhsv_default);
var oklab = useMode(definition_default19);
var oklch = useMode(definition_default20);
var p3 = useMode(definition_default21);
var prophoto = useMode(definition_default22);
var rec2020 = useMode(definition_default23);
var rgb = useMode(definition_default);
var xyb = useMode(definition_default24);
var xyz50 = useMode(definition_default25);
var xyz65 = useMode(definition_default26);
var yiq = useMode(definition_default27);

// src/ColourV3Functions.ts
var dText = "#000000";
var lText = "#FFFFFF";
var oklch2 = converter_default("oklch");
var rgb2 = converter_default("rgb");
var mR2 = /* @__PURE__ */ __name((x) => parseFloat((Math.round(x * 100) / 100).toFixed(2)), "mR2");
var getAPCA = /* @__PURE__ */ __name((f3, b) => mR2(calcAPCA(f3, b)), "getAPCA");
var randomHex = /* @__PURE__ */ __name(() => formatHex(random_default()), "randomHex");
var getDirection = /* @__PURE__ */ __name((bg) => {
  const l = Math.abs(getAPCA("#ffffff", bg));
  const d = Math.abs(getAPCA("#000000", bg));
  return l >= d ? "light" : "dark";
}, "getDirection");
var L = /* @__PURE__ */ __name((hex2, dL) => {
  const test = oklch2(hex2);
  if (!test) return "#FACADE";
  if (Math.abs(test.l) <= 0.04) dL = dL * 6;
  const newOklch = {
    ...test,
    l: test.l + dL
  };
  if (!test.h) newOklch.h = 0;
  if (!test.c) newOklch.c = 0;
  return formatHex(newOklch);
}, "L");
var getBaseForegroundColour = /* @__PURE__ */ __name((colour) => getDirection(colour) === "light" ? lText : dText, "getBaseForegroundColour");
var percentageToHex = /* @__PURE__ */ __name((percentage) => {
  const decimalNumber = percentage / 100;
  const valueInDecimal = Math.round(decimalNumber * 255);
  return valueInDecimal.toString(16);
}, "percentageToHex");
function interpolateY(x) {
  const minX = 0;
  const maxX = 1;
  const minY = 50;
  const maxY = 20;
  const P = (x - minX) / (maxX - minX);
  const Y = minY + P * (maxY - minY);
  return Y;
}
__name(interpolateY, "interpolateY");
var tuneLightness = /* @__PURE__ */ __name((oklchColour, intensity) => {
  if (!oklchColour) return void 0;
  const tunedL = oklchColour.l <= 0.4 ? oklchColour.l + interpolateY(oklchColour.l) * oklchColour.l / 100 * intensity : oklchColour.l - interpolateY(oklchColour.l) * oklchColour.l / 100 * intensity;
  return {
    ...oklchColour,
    l: tunedL
  };
}, "tuneLightness");
var getAlpha = /* @__PURE__ */ __name((hex2, alpha = 100) => hex2.length != 7 ? hex2 : hex2 + percentageToHex(alpha), "getAlpha");
var unAlpha = /* @__PURE__ */ __name((FG, BG = "#FFFFFF") => {
  const rgbBG = rgb2(BG);
  const rgbFG = rgb2(FG);
  if (!rgbFG || !rgbBG) return "#FACADE";
  const alpha = rgbFG.alpha || 1;
  return formatHex({
    ...rgbFG,
    r: (1 - alpha) * rgbBG.r + alpha * rgbFG.r,
    g: (1 - alpha) * rgbBG.g + alpha * rgbFG.g,
    b: (1 - alpha) * rgbBG.b + alpha * rgbFG.b
  });
}, "unAlpha");
var getSafeColour = /* @__PURE__ */ __name((fg, bg, threshold = 75) => {
  let newL = oklch2(fg)?.l || 0;
  const oklchFg = oklch2(fg);
  if (!oklchFg || !bg) return "#FACADE";
  const direction = getDirection(bg);
  if (direction == "light") {
    while (Math.abs(getAPCA(fg, bg)) < threshold && newL < 1) {
      fg = L(fg, 0.01);
      newL = oklch2(fg)?.l || 1;
    }
  } else {
    while (Math.abs(getAPCA(fg, bg)) < threshold && oklch2(fg) && newL >= 0) {
      fg = L(fg, -0.01);
      newL = oklch2(fg)?.l || 0;
    }
  }
  return fg;
}, "getSafeColour");
var generateColourObject = /* @__PURE__ */ __name((primary, secondary = void 0) => {
  const oklchPrimary = oklch2(primary);
  if (!oklchPrimary) return void 0;
  const oklchSecondary = oklch2(secondary) || oklchPrimary;
  const oklchaccent = secondary ? oklchSecondary : oklchPrimary;
  const oklchaccentHue = oklchaccent.h || 0;
  const lBackground = "#ffffff";
  const lRaised = "#f9f9f9";
  const lSunken = "#F5F4F4";
  const lLightnessFactorForHover = 0.2;
  const lLightnessFactorForActive = 0.3;
  const accentPassthroughForDarkMode = oklchaccentHue ? 0.01 : 0;
  const dBackground = formatHex({
    ...oklch2("#1C181E"),
    h: oklchaccentHue,
    c: accentPassthroughForDarkMode
  });
  const dRaised = formatHex({
    ...oklch2("#241F26"),
    h: oklchaccentHue,
    c: accentPassthroughForDarkMode
  });
  const dSunken = formatHex({
    ...oklch2("#060507"),
    h: oklchaccentHue,
    c: accentPassthroughForDarkMode
  });
  const dLightnessFactorForHover = 0.6;
  const dLightnessFactorForActive = 0.8;
  return {
    light: {
      background: {
        base: lBackground,
        hover: formatHex(tuneLightness(oklch2(lBackground), lLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklch2(lBackground), lLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(lBackground), 10), lBackground),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 95), lBackground),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 60), lBackground),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 50), lBackground),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 40), lBackground),
        "text-primary": getSafeColour(primary, lBackground),
        "text-success": "#005B35",
        "text-error": "#8D0013",
        "text-warning": L("#9D7D07", -0.07),
        "text-info": "#243F93"
      },
      raised: {
        base: lRaised,
        hover: formatHex(tuneLightness(oklch2(lRaised), lLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklch2(lRaised), lLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(lBackground), 14), lRaised),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 95), lRaised),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 60), lRaised),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 50), lRaised),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 40), lRaised),
        "text-primary": getSafeColour(primary, lRaised),
        "text-success": "#005B35",
        "text-error": "#8D0013",
        "text-warning": L("#9D7D07", -0.07),
        "text-info": "#243F93"
      },
      sunken: {
        base: lSunken,
        hover: formatHex(tuneLightness(oklch2(lSunken), lLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklch2(lSunken), lLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(lBackground), 20), lSunken),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 95), lSunken),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 60), lSunken),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 50), lSunken),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(lBackground), 40), lSunken),
        "text-primary": getSafeColour(primary, lSunken),
        "text-success": "#005B35",
        "text-error": "#8D0013",
        "text-warning": L("#9D7D07", -0.12),
        "text-info": "#243F93"
      },
      primary: {
        base: primary,
        hover: formatHex(tuneLightness(oklchPrimary, lLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklchPrimary, lLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(primary), 25), primary),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(primary), 100), primary),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(primary), 75), primary),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(primary), 55), primary),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(primary), 45), primary)
      }
    },
    dark: {
      background: {
        base: dBackground,
        hover: formatHex(tuneLightness(oklch2(dBackground), dLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklch2(dBackground), dLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(dBackground), 25), dBackground),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 95), dBackground),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 60), dBackground),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 50), dBackground),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 45), dBackground),
        "text-primary": getSafeColour(primary, dBackground),
        "text-success": L("#8ECEB1", 0.04),
        "text-error": L("#FAA39D", 0.08),
        "text-warning": "#FBE9A8",
        "text-info": L("#A3BDF7", 0.06)
      },
      raised: {
        base: dRaised,
        hover: formatHex(tuneLightness(oklch2(dRaised), dLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklch2(dRaised), dLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(dBackground), 25), dRaised),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 95), dRaised),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 60), dRaised),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 50), dRaised),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 45), dRaised),
        "text-primary": getSafeColour(primary, dRaised),
        "text-success": L("#8ECEB1", 0.05),
        "text-error": L("#FAA39D", 0.09),
        "text-warning": "#FBE9A8",
        "text-info": L("#A3BDF7", 0.06)
      },
      sunken: {
        base: dSunken,
        hover: formatHex(tuneLightness(oklch2(dSunken), dLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklch2(dSunken), dLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(dBackground), 26), dSunken),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 95), dSunken),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 60), dSunken),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 50), dSunken),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(dBackground), 46), dSunken),
        "text-primary": getSafeColour(primary, dSunken),
        "text-success": L("#8ECEB1", 0.04),
        "text-error": L("#FAA39D", 0.08),
        "text-warning": "#FBE9A8",
        "text-info": L("#A3BDF7", 0.06)
      },
      primary: {
        base: primary,
        hover: formatHex(tuneLightness(oklchPrimary, dLightnessFactorForHover)),
        active: formatHex(tuneLightness(oklchPrimary, dLightnessFactorForActive)),
        border: unAlpha(getAlpha(getBaseForegroundColour(primary), 20), primary),
        "text-high": unAlpha(getAlpha(getBaseForegroundColour(primary), 100), primary),
        "text-medium": unAlpha(getAlpha(getBaseForegroundColour(primary), 75), primary),
        "text-low": unAlpha(getAlpha(getBaseForegroundColour(primary), 50), primary),
        "text-disabled": unAlpha(getAlpha(getBaseForegroundColour(primary), 40), primary)
      }
    }
  };
}, "generateColourObject");
var generateCSSVariables = /* @__PURE__ */ __name((coloursObject, namespace = "colorv3", prefix = "") => {
  let css = "";
  for (const key in coloursObject) {
    const child = coloursObject[key];
    if (typeof child === "object") {
      if (prefix === "light-") prefix = "LIGHT-";
      if (prefix === "dark-") prefix = "DARK-";
      css += generateCSSVariables(child, namespace, prefix + key + "-");
    } else {
      css += `--${namespace}-${prefix}${key}: ${child};`;
    }
  }
  return css;
}, "generateCSSVariables");
var generateCSSVariablesMapping = /* @__PURE__ */ __name((coloursObject, scheme = "light", namespace = "colorv3", prefix = "") => {
  let css = "";
  const schemePrefix = scheme === "light" ? `--${namespace}-LIGHT-` : `--${namespace}-DARK-`;
  for (const key in coloursObject) {
    const child = coloursObject[key];
    if (typeof child === "object") {
      css += generateCSSVariablesMapping(child, scheme, namespace, prefix + key + "-");
    } else {
      css += `--${namespace}-${prefix}${key}: var(${schemePrefix}${prefix}${key});`;
    }
  }
  return css;
}, "generateCSSVariablesMapping");
var generateSystemForSurface = /* @__PURE__ */ __name((_colours, surface, namespace = "colorv3") => {
  const css = `
			[data-surface*="${surface}"]{
				--${namespace}-current-base:var(--${namespace}-${surface}-base);
				--${namespace}-current-hover:var(--${namespace}-${surface}-hover);
				--${namespace}-current-active:var(--${namespace}-${surface}-active);
				--${namespace}-current-text-high:var(--${namespace}-${surface}-text-high);
				--${namespace}-current-text-medium:var(--${namespace}-${surface}-text-medium);
				--${namespace}-current-text-low:var(--${namespace}-${surface}-text-low);
				--${namespace}-current-text-disabled:var(--${namespace}-${surface}-text-disabled);
				--${namespace}-current-text-primary:var(--${namespace}-${surface}-text-primary);
				--${namespace}-current-text-success:var(--${namespace}-${surface}-text-success);
				--${namespace}-current-text-error:var(--${namespace}-${surface}-text-error);
				--${namespace}-current-text-warning:var(--${namespace}-${surface}-text-warning);
				--${namespace}-current-text-info:var(--${namespace}-${surface}-text-info);
				--${namespace}-current-border:var(--${namespace}-${surface}-border);
				background:var(--${namespace}-${surface}-base);color:var(--${namespace}-current-text-high);
			}
			[data-surface*="${surface}"][data-surface*="interactive"]:hover{background:var(--${namespace}-${surface}-hover)}
			[data-surface*="${surface}"][data-surface*="interactive"]:active{background:var(--${namespace}-${surface}-active)}
	`;
  return css;
}, "generateSystemForSurface");
var generateCssString = /* @__PURE__ */ __name((primary, options = {
  namespace: "colorv3",
  withRelative: true
}) => {
  const colours = generateColourObject(primary);
  if (!colours) return "/* generateCssString() encountered an error while generating the ColourObject */";
  let cssString = `
:root{
	${generateCSSVariables(colours, options.namespace)}
}
[data-color-scheme="light"] {
	${generateCSSVariablesMapping(colours.light, "light", options.namespace)}
}
[data-color-scheme="dark"] {
	${generateCSSVariablesMapping(colours.dark, "dark", options.namespace)}
}
@media (prefers-color-scheme: light) {
	[data-color-scheme="auto"] {
		${generateCSSVariablesMapping(colours.light, "light", options.namespace)}
	}
}
@media (prefers-color-scheme: dark) {
	[data-color-scheme="auto"] {
		${generateCSSVariablesMapping(colours.dark, "dark", options.namespace)}
	}
}
`;
  if (options.withRelative) {
    cssString += `
	${generateSystemForSurface(colours, "primary", options.namespace)}
	${generateSystemForSurface(colours, "background", options.namespace)}
	${generateSystemForSurface(colours, "raised", options.namespace)}
	${generateSystemForSurface(colours, "sunken", options.namespace)}
	${generateSystemForSurface(colours, "success", options.namespace)}
	${generateSystemForSurface(colours, "error", options.namespace)}
	${generateSystemForSurface(colours, "warning", options.namespace)}
	${generateSystemForSurface(colours, "info", options.namespace)}

	[data-text="high"]{color:var(--${options.namespace}-current-text-high)}
	[data-text="medium"]{color:var(--${options.namespace}-current-text-medium)}
	[data-text="low"]{color:var(--${options.namespace}-current-text-low)}
	[data-text="disabled"]{color:var(--${options.namespace}-current-text-disabled)}
	[data-text="primary"]{color:var(--${options.namespace}-current-text-primary)}
	[data-text="success"]{color:var(--${options.namespace}-current-text-success)}
	[data-text="error"]{color:var(--${options.namespace}-current-text-error)}
	[data-text="warning"]{color:var(--${options.namespace}-current-text-warning)}
	[data-text="info"]{color:var(--${options.namespace}-current-text-info)}`;
  }
  return `
        @layer colours{
            ${cssString}
        }
    `;
}, "generateCssString");
function calcAPCA(fgHex, bgHex) {
  if (fgHex.length != 7 || bgHex.length != 7) return 0;
  const fgRgb = [
    parseInt(`${fgHex[1]}${fgHex[2]}`, 16),
    parseInt(`${fgHex[3]}${fgHex[4]}`, 16),
    parseInt(`${fgHex[5]}${fgHex[6]}`, 16)
  ];
  const bgRgb = [
    parseInt(`${bgHex[1]}${bgHex[2]}`, 16),
    parseInt(`${bgHex[3]}${bgHex[4]}`, 16),
    parseInt(`${bgHex[5]}${bgHex[6]}`, 16)
  ];
  if (!fgRgb || !bgRgb) return 0;
  return APCAcontrast(sRGBtoY(fgRgb), sRGBtoY(bgRgb));
}
__name(calcAPCA, "calcAPCA");
function APCAcontrast(txtY, bgY) {
  const icp = [
    0,
    1.1
  ];
  if (isNaN(txtY) || isNaN(bgY) || Math.min(txtY, bgY) < icp[0] || Math.max(txtY, bgY) > icp[1]) {
    return 0;
  }
  let SAPC = 0;
  let outputContrast = 0;
  txtY = txtY > SA98G.blkThrs ? txtY : txtY + Math.pow(SA98G.blkThrs - txtY, SA98G.blkClmp);
  bgY = bgY > SA98G.blkThrs ? bgY : bgY + Math.pow(SA98G.blkThrs - bgY, SA98G.blkClmp);
  if (Math.abs(bgY - txtY) < SA98G.deltaYmin) {
    return 0;
  }
  if (bgY > txtY) {
    SAPC = (Math.pow(bgY, SA98G.normBG) - Math.pow(txtY, SA98G.normTXT)) * SA98G.scaleBoW;
    outputContrast = SAPC < SA98G.loClip ? 0 : SAPC - SA98G.loBoWoffset;
  } else {
    SAPC = (Math.pow(bgY, SA98G.revBG) - Math.pow(txtY, SA98G.revTXT)) * SA98G.scaleWoB;
    outputContrast = SAPC > -SA98G.loClip ? 0 : SAPC + SA98G.loWoBoffset;
  }
  return outputContrast * 100;
}
__name(APCAcontrast, "APCAcontrast");
function sRGBtoY(rgb3 = [
  0,
  0,
  0
]) {
  function simpleExp(chan) {
    return Math.pow(chan / 255, SA98G.mainTRC);
  }
  __name(simpleExp, "simpleExp");
  return SA98G.sRco * simpleExp(rgb3[0]) + SA98G.sGco * simpleExp(rgb3[1]) + SA98G.sBco * simpleExp(rgb3[2]);
}
__name(sRGBtoY, "sRGBtoY");
var SA98G = {
  mainTRC: 2.4,
  // For reverseAPCA
  get mainTRCencode() {
    return 1 / this.mainTRC;
  },
  // sRGB coefficients
  sRco: 0.2126729,
  sGco: 0.7151522,
  sBco: 0.072175,
  // G-4g constants for use with 2.4 exponent
  normBG: 0.56,
  normTXT: 0.57,
  revTXT: 0.62,
  revBG: 0.65,
  // G-4g Clamps and Scalers
  blkThrs: 0.022,
  blkClmp: 1.414,
  scaleBoW: 1.14,
  scaleWoB: 1.14,
  loBoWoffset: 0.027,
  loWoBoffset: 0.027,
  deltaYmin: 5e-4,
  loClip: 0.1,
  ///// MAGIC NUMBERS for UNCLAMP, for use with 0.022 & 1.414 /////
  // Magic Numbers for reverseAPCA
  mFactor: 1.9468554433171,
  get mFactInv() {
    return 1 / this.mFactor;
  },
  mOffsetIn: 0.0387393816571401,
  mExpAdj: 0.283343396420869,
  get mExp() {
    return this.mExpAdj / this.blkClmp;
  },
  mOffsetOut: 0.312865795870758
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ColourV3Functions
});
