import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import { Link } from "react-router-dom";
import SharedIndicator from "../components/SharedIndicator";
import { H4 } from "../components/text/Text";
import iconReference from "../services/IconReference";
import OrganiserImage from "./OrganiserImage";

const icon = iconReference["warning"];

const wrapper = css({
	backgroundColor: "#fff",
	borderRadius: 8,
	flex: "1 0 336px",
	width: "100%",
	display: "block",
	transition: "border 0.2s",
	"&:hover": {
		outline: "var(--border1-hover)"
	}
});

const content = css({
	display: "flex",
	position: "relative",
	alignItems: "center",
	padding: "20px",
	h4: {
		marginLeft: 12,
		wordBreak: "break-all"
	}
});

const sharedWrapper = css({
	position: "absolute",
	top: 20,
	right: 20
});

const OrganiserCard = ({ _id, name, logo, userId, currentUserId, disabled }) => {
	return (
		<Link className={wrapper} to={`/console/account/hosts/${_id}/details`}>
			<div className={content}>
				<OrganiserImage name={name} logo={logo} />
				<H4>{name}</H4>
				<div className={sharedWrapper}>
					<SharedIndicator userId={currentUserId} ownerId={userId} sharedObjectName="host" />
					{disabled === true ? (
						<Tooltip title="Content flagged">
							<img src={String(icon)} style={{ marginRight: 8 }} width="20" alt="Content flagged" />
						</Tooltip>
					) : null}
				</div>
			</div>
		</Link>
	);
};

export default OrganiserCard;
