import { Component } from "react";
import { Field } from "redux-form";
import { TextAreaField } from "@/components/Fields";
import PersonalisationInformation from "@/components/PersonalisationInformation";
import { getNoOrderPersonalisations, getOrderPersonalisations } from "@/utils/Personalisations";

const disallowedPersonalisations = ["AddToCalendar", "OrganiserLogo"];
const attendeePersonalisations = getOrderPersonalisations(disallowedPersonalisations);
const visitorPersonalisations = getNoOrderPersonalisations(disallowedPersonalisations);

class CalendarMessage extends Component {
	render() {
		return (
			<>
				<Field
					label="Visitor “Add to calendar” custom description"
					description="Add your message to the Add to Calendar link available on the Event Page."
					name="messages.customVisitorCalendarMessage"
					placeholder="Thank you for purchasing tickets! See you at the Event!"
					component={TextAreaField}
					rows={3}
					personalisations={visitorPersonalisations}
				/>
				<PersonalisationInformation personalisationList={visitorPersonalisations} />
				<Field
					label="Attendee “Add to calendar” custom description"
					description="Add your message to the Add to Calendar link attendees access on the Confirmation Page, Confirmation Email, and Email Campaign shortcut."
					name="messages.customAttendeeCalendarMessage"
					placeholder="Thank you for purchasing tickets! See you at the Event!"
					component={TextAreaField}
					rows={3}
					personalisations={attendeePersonalisations}
				/>
				<PersonalisationInformation personalisationList={attendeePersonalisations} />
			</>
		);
	}
}

export default CalendarMessage;
