import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField } from "../components/Fields";
import LoadErrorView from "../components/LoadErrorView";
import { required } from "../utils/Validators";

let tagRequired = required("A tag is required");
const formName = "tagForm";

class TagModal extends Component {
	componentWillReceiveProps() {
		null;
	}

	saveTag = (values) => {
		const { save } = this.props;
		save(values);
	};

	clearForm = () => {
		this.props.reset();
	};

	render() {
		const { visible, handleCancel, handleSubmit, tags } = this.props;
		return (
			<form id="tagForm">
				<Modal
					open={visible}
					title="Add Tag"
					onCancel={handleCancel}
					destroyOnClose
					onOk={handleSubmit(this.saveTag)}
					afterClose={this.clearForm}
				>
					<LoadErrorView loading={tags.loading} error={tags.error}>
						<div>
							<Field name="name" label="Tag" component={LegacyInputField} validate={tagRequired} />
						</div>
					</LoadErrorView>
				</Modal>
			</form>
		);
	}
}

TagModal = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(TagModal);

export default connect(
	(state) => ({
		initialValues: state.tags.tag,
		tags: state.tags
	}),
	{}
)(TagModal);
