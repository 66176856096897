import { Loading } from "@/components/AppBlocks";
import ManageCapacityModal from "@/events/event/seatingMap/ManageCapacityModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { updateManageCapacityForm } from "@/state/actions/eventActions";
import {
	clearSeatingMapState,
	getSeatingMap,
	leftManagerView,
	newSeatingMap,
	saveSeatingMap,
	startLoading
} from "@/state/actions/seatingMapActions";
import { getUrlParameter } from "@/utils/Urls";
import { SeatingMap, Views } from "@hx/seating-map";
import { TicketTypeLegend, Toolbar, UtilityBar } from "@hx/seating-map-toolbar";
import { Alert } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { addUndoAction, redo, resetUndoRedo, undo } from "../state/actions/seatingMapUndoActions";
import { addHubspot, removeHubspot } from "../utils/Hubspot";
import TopBar from "./TopBar";
import ControlPanel from "./controlPanel/ControlPanel";
import withSaveCheck from "@/hoc/withSaveCheck";

const { SeatingConstruction } = Views;

class SeatMap extends Component {
	constructor(props) {
		super(props);
		const urlView = getUrlParameter("view");
		this.state = {
			newMap: false,
			view: urlView === "managerView" ? "managerView" : "seatingView",
			data: {
				selected: false
			},
			isManageCapacityModalVisible: false,
			seatingMapCapacity: 0,
			isSaveCheckModalVisible: false
		};
		this.removeSelected = this.removeSelected.bind(this);
		this.onElementClicked = this.onElementClicked.bind(this);
		this.clearElements = this.clearElements.bind(this);
		this.onSwitch = this.onSwitch.bind(this);
		this.consoleHeader = document.getElementById("console_header");
		this.headerDisplay = this.consoleHeader.style.display;
		this.consoleHeader.style.setProperty("display", "none");
		this.toggleManageCapacityModal = this.toggleManageCapacityModal.bind(this);
		this.handleAutoMatchingCapacities = this.handleAutoMatchingCapacities.bind(this);
		this.setSeatingMapCapacity = this.setSeatingMapCapacity.bind(this);
		this.setShowSaveCheckModal = this.setShowSaveCheckModal.bind(this);
		this.handleSavingSeatingMap = this.handleSavingSeatingMap.bind(this);
	}

	setSeatingMapCapacity = (newCapacity) => {
		this.setState({ seatingMapCapacity: newCapacity });
	};

	setShowSaveCheckModal = (newValue) => {
		this.setState({ isSaveCheckModalVisible: newValue });
	};

	onElementClicked(elementData) {
		const data = {
			...elementData,
			selected: true
		};
		this.setState({ data });
	}

	clearElements() {
		this.setState({
			data: {
				selected: false
			}
		});
	}

	componentDidMount() {
		removeHubspot();
		const { undo, redo, addUndoAction, params, getSeatingMap } = this.props;
		const { seatingMapId } = params;
		const actions = this.actions();
		getSeatingMap({
			seatingMapId,
			undo,
			redo,
			addUndoAction,
			actions,
			view: this.state.view
		});
	}

	componentWillUnmount() {
		this.consoleHeader.style.setProperty("display", "block");
		SeatingMap.removeSeatingMap();
		this.props.clearSeatingMapState();
		addHubspot();
	}

	actions() {
		return {
			onTableMoved: this.onElementClicked,
			onElementClicked: this.onElementClicked,
			clearElements: this.clearElements
		};
	}

	removeSelected = () => {
		this.setState({
			data: {
				selected: false
			}
		});
	};

	onSwitch = (viewTo) => {
		const { view: currentView } = this.state;
		const { leftManagerView, resetUndoRedo, navigate } = this.props;

		if (currentView === "managerView" && viewTo !== "managerView") {
			leftManagerView();
		}
		resetUndoRedo(viewTo);

		this.setState({ view: viewTo });

		navigate({
			search: `?view=${viewTo}`
		});
	};

	toggleManageCapacityModal = (newValue) => {
		this.setState({ isManageCapacityModalVisible: newValue });
	};

	/**
	 * @param {*} ticketOrPackageId: string 
	 * @param {*} type : 
	  	| "TOGGLE_MATCH_AUTOMATICALLY_FOR_TICKET_TYPES"
			| "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_TICKET_TYPES"
			| "TOGGLE_MATCH_AUTOMATICALLY_FOR_PACKAGED_TICKETS"
			| "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_PACKAGED_TICKETS"
	 */
	handleAutoMatchingCapacities = (ticketOrPackageId, type) => {
		this.props.updateManageCapacityForm(manageCapacityFormName, ticketOrPackageId, type);
	};

	handleSavingSeatingMap = (view) => {
		const {
			currentEvent: { event },
			saveSeatingMap,
			params: { seatingMapId },
			navigate,
			handleSubmit
		} = this.props;
		const { seatingMapCapacity, isSaveCheckModalVisible } = this.state;
		const eventId = String(event._id);
		const seatingMapInstance = SeatingMap.getCurrentMap();
		const seatingMapData = seatingMapInstance.toJSON();

		handleSubmit((formValues) => {
			const eventCapacityData = {
				ticketTypes: formValues?.ticketTypes || [],
				packagedTickets: formValues?.packagedTickets || []
			};

			saveSeatingMap({
				eventId,
				seatingMapId,
				seatingMapData,
				capacity: seatingMapCapacity,
				view,
				navigate,
				exitSeatingMapAfterSave: isSaveCheckModalVisible,
				eventCapacityData
			});
		})();

		if (isSaveCheckModalVisible) {
			this.setShowSaveCheckModal(false);
		}
	};

	render() {
		const { data, view, isManageCapacityModalVisible, seatingMapCapacity } = this.state;
		const {
			addUndoAction,
			seatingMapUndo,
			currentEvent,
			seatingMap,
			params: { seatingMapId },
			undo,
			redo,
			dirty: isFormDirty,
			atleastOneSeatIsMapped
		} = this.props;
		const { loading } = seatingMap;
		const { undoActions, redoActions } = seatingMapUndo[seatingMapUndo.view];
		const seatingMapInstance = SeatingMap.getCurrentMap();
		const seatingConstructionInstance = SeatingConstruction.getInstance();

		return (
			<div
				style={{
					width: "100%",
					overflow: "auto",
					display: "flex",
					flexFlow: "column",
					height: "100%",
					justifyContent: "space-between",
					overflowY: "hidden",
					position: "relative",
					flexGrow: 1
				}}
			>
				{seatingMapInstance && seatingConstructionInstance ? (
					<TopBar
						loading={loading}
						onSwitch={this.onSwitch}
						view={view}
						seatingMapId={seatingMapId}
						seatingMapInstance={seatingMapInstance}
						seatingConstructionInstance={seatingConstructionInstance}
						openManageCapacityModal={() => this.toggleManageCapacityModal(true)}
						handleSavingSeatingMap={this.handleSavingSeatingMap}
						setSeatingMapCapacity={this.setSeatingMapCapacity}
						setShowSaveCheckModal={this.setShowSaveCheckModal}
						seatingMapCapacity={this.state.seatingMapCapacity}
						isSaveCheckModalVisible={this.state.isSaveCheckModalVisible}
					/>
				) : null}
				<div style={{ display: "flex", flexGrow: 1 }}>
					<div
						id="controlPanel"
						style={{
							unselectable: "on",
							backgroud: "green",
							verticalAlign: "top",
							minWidth: 300,
							position: "relative",
							WebkitTouchCallout: "none",
							WebkitUserSelect: "none",
							KhtmlUserSelect: "none",
							MozUserSelect: "none",
							MsUserSelect: "none",
							userSelect: "none",
							zIndex: 1,
							boxShadow: "0 1px 10px 0 rgba(32, 7, 64, 0.13)"
						}}
					>
						{!loading && SeatingMap.constructionInitialised ? (
							<ControlPanel
								view={view}
								unselectable="on"
								data={data.elements || []}
								type={data.type}
								removeSelected={this.removeSelected}
								addUndoAction={addUndoAction}
								openManageCapacityModal={() => this.toggleManageCapacityModal(true)}
							/>
						) : null}
					</div>
					<div id="seatingMap" style={{ position: "absolute", top: 120, right: 0, bottom: 0, left: 300 }}>
						{isFormDirty && (
							<div style={{ position: "absolute", top: 25, right: 0, left: 0 }}>
								<Alert
									description={
										<p style={{ marginBottom: 0, marginLeft: -7, marginTop: 1, maxWidth: 700 }}>
											Your revisions have <strong>not</strong> been saved. Kindly ensure you save them before closing
										</p>
									}
									type="info"
									showIcon
								/>
							</div>
						)}
						{!atleastOneSeatIsMapped && seatingMapCapacity ? (
							<div style={{ position: "absolute", top: 25, right: 0, left: 0 }}>
								<Alert
									description={
										<p style={{ marginBottom: 0, marginLeft: -7, marginTop: 4, maxWidth: 700 }}>
											Use the 'Mapping' tab to configure seat availability
										</p>
									}
									type="info"
									showIcon
								/>
							</div>
						) : null}
						{!loading && view === "ticketMapping" ? (
							<TicketTypeLegend event={currentEvent.event} display={view === "ticketMapping"} />
						) : null}
						<Toolbar />
						<div style={{ position: "absolute", right: 55, bottom: 25 }}>
							{!loading && SeatingMap.constructionInitialised ? (
								<UtilityBar
									onRedo={redo}
									onUndo={undo}
									hasUndo={undoActions.length() > 0}
									hasRedo={redoActions.length() > 0}
								/>
							) : null}
						</div>
						{loading ? (
							<div
								style={{
									position: "absolute",
									width: "100%",
									height: "100%",
									zIndex: 0,
									display: "contents"
								}}
							>
								<Loading backgroundColor="#f9f9fa" />
							</div>
						) : null}
					</div>
					{isManageCapacityModalVisible && (
						<ManageCapacityModal
							isOpen={isManageCapacityModalVisible}
							handleModalClose={() => this.toggleManageCapacityModal(false)}
							handleAutoMatchingCapacities={this.handleAutoMatchingCapacities}
						/>
					)}
				</div>
			</div>
		);
	}
}

const manageCapacityFormName = "manageMappedCapacity";
const selector = formValueSelector(manageCapacityFormName);

const mapStateToProps = (state) => {
	const { currentEvent, seatingMap, seatingMapUndo } = state;
	const event = currentEvent.event || {};
	const ticketTypes = event.ticketTypes?.filter((tt) => !tt.deleted && !tt.disabled && !tt.isDonation) || [];
	const packagedTickets = event.packagedTickets?.filter((pt) => !pt.deleted && !pt.disabled) || [];

	const formInitialValues = {
		ticketTypes,
		select_all_ticket_types: ticketTypes.every((tt) => tt.isMappedToSeatingMap),
		packagedTickets,
		select_all_packaged_tickets: packagedTickets.every((pt) => pt.isMappedToSeatingMap)
	};

	const { ticketTypes: ticketTypesFormValue, packagedTickets: packagedTicketsFormValues } = selector(
		state,
		"ticketTypes",
		"packagedTickets"
	);

	const atleastOneSeatIsMapped =
		ticketTypesFormValue?.some((tt) => tt.mappedSeats > 0) ||
		packagedTicketsFormValues?.some((pt) => pt.mappedSeats > 0);

	return {
		seatingMap,
		currentEvent,
		seatingMapUndo,
		initialValues: formInitialValues,
		atleastOneSeatIsMapped
	};
};

const mapDispatchToProps = {
	getSeatingMap,
	newSeatingMap,
	leftManagerView,
	clearSeatingMapState,
	undo,
	redo,
	addUndoAction,
	resetUndoRedo,
	startLoading,
	updateManageCapacityForm,
	saveSeatingMap
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: manageCapacityFormName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(withSaveCheck(withRouterHooks(SeatMap), manageCapacityFormName))
);
