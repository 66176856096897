import { Card } from "@/ui/antd";
import { CSSProperties, FC, useState } from "react";
import arrowRightIcon from "@/assets/ic_arrow_right_black.svg";
import styled from "@emotion/styled";
import { Button } from "@/ui";

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`;

const cardStyle = (isRedirectCard = false): CSSProperties => ({
	backgroundColor: isRedirectCard ? "#FFEAC6" : "",
	borderColor: "rgba(21, 20, 19, 0.31)",
	borderRadius: 16,
	display: "flex",
	flexDirection: "column",
	overflow: "hidden",
	width: "100%"
});

const cardBody: CSSProperties = {
	display: "flex",
	flexDirection: "column",
	flexGrow: "1",
	justifyContent: "space-between"
};

const metaStyle: CSSProperties = {
	alignItems: "flex-start",
	flexGrow: "1",
	marginBottom: "10px"
};

const redirectCardMetaStyle: CSSProperties = {
	alignItems: "center",
	flexGrow: "1",
	marginBottom: "10px",
	textAlign: "center"
};

// const buttonStyle: CSSProperties = {
// 	alignItems: "center",
// 	display: "flex",
// 	justifyContent: "center",
// 	textAlign: "start"
// };

type WidgetCardProps = {
	buttonText: string;
	description: string;
	imgSrc?: string;
	imgSrcOnHover?: string;
	onCTAClick: () => void;
	title?: string;
};

export const WidgetCard: FC<WidgetCardProps> = ({
	buttonText,
	description,
	imgSrc,
	imgSrcOnHover,
	onCTAClick,
	title
}) => {
	const isRedirectCard = !imgSrc;
	const [isHovered, setIsHovered] = useState(false);

	const imageSource = isHovered && imgSrcOnHover ? imgSrcOnHover : imgSrc;

	return isRedirectCard ? (
		<Card
			bodyStyle={cardBody}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={cardStyle(isRedirectCard)}
		>
			<FlexColumn>
				<Card.Meta description={description} style={redirectCardMetaStyle} />
				<Button aria-label={buttonText} onClick={onCTAClick} variant="primary">
					{buttonText}
					<img src={arrowRightIcon} />
				</Button>
			</FlexColumn>
		</Card>
	) : (
		<Card
			bodyStyle={cardBody}
			cover={<img alt={`${title} widget example`} src={imageSource} />}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={cardStyle(isRedirectCard)}
		>
			<FlexColumn>
				<Card.Meta description={description} title={title} style={metaStyle} />
				<Button aria-label={buttonText} onClick={onCTAClick} variant="primary">
					{buttonText}
				</Button>
			</FlexColumn>
		</Card>
	);
};
