import humanitixPaymentsLogo from "@/assets/logo-pin.png";
import { LegacyInputField, PasswordField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { Label, P } from "@/components/text/Text";
import { getConfig } from "@/config";
import PaymentInfo from "@/events/event/payments/components/gateways/PaymentInfo";
import { ReduxState } from "@/state/store";
import { trim } from "@/utils/Format";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { Alert, Modal } from "@/ui/antd";
import { FC, useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

const secretAPIKeyRequired = required("Secret API key is required");
const publishableAPIKeyRequired = required("Publishable API Key is required");

type PinProps = {
	allowed: boolean;
	gatewaysLocked: boolean;
	orgPaymentOptions: any;
	paymentOptionsStr: string;
	paymentProcessed: boolean;
	save: (data: any) => void;
	selected: string;
} & InjectedFormProps;

const Pin: FC<PinProps> = ({
	save,
	selected,
	allowed,
	gatewaysLocked,
	paymentProcessed,
	orgPaymentOptions,
	paymentOptionsStr,
	handleSubmit
}) => {
	const [modalOpen, toggleModal] = useState(false);

	if (!allowed) return null;

	const saveForm = (values: any) => {
		const updateData: any = {};
		updateData[paymentOptionsStr] = {
			...orgPaymentOptions,
			pinInfo: values,
			name: "pin",
			paymentId: "pin",
			gatewaysLocked: true
		};
		save(updateData);
		toggleModal(false);
	};
	const label = selected === "pin" ? "Settings" : "Set up";
	const buttonType = selected === "pin" ? "secondary" : "primary";
	const production = getConfig("NODE_ENV") === "production";
	const isApplePayConnected = !!orgPaymentOptions?.pinInfo?.isApplePayConnected;

	return (
		<PaymentInfo
			description={
				<div>
					Pin Payments is a global merchant account provider. Connect your Pin Payments account to start accepting
					payments directly into your account.
				</div>
			}
			id="pin"
			image={humanitixPaymentsLogo}
			name="Pin Payments"
			selected={selected}
		>
			<LegacyButton
				ariaLabel={label}
				disabled={paymentProcessed || gatewaysLocked}
				onClick={() => toggleModal(true)}
				style={{ width: "100%", margin: "auto", maxWidth: 240 }}
				type={buttonType}
			>
				{label}
			</LegacyButton>
			<Modal
				footer={
					<LegacyButton
						ariaLabel="Save"
						form="pinForm"
						htmlType="submit"
						onClick={handleSubmit(saveForm)}
						style={{ float: "right" }}
						type="primary"
					>
						Save
					</LegacyButton>
				}
				onCancel={() => toggleModal(false)}
				open={modalOpen}
				title="Pin Payments settings"
			>
				<form>
					<Alert
						showIcon
						message={
							<span>
								Verify these are the {production ? "live" : "test"} keys, not the {production ? "test" : "live"} keys.
							</span>
						}
						type="info"
						style={{ marginBottom: "10px" }}
					/>
					<Field
						component={PasswordField}
						label="Secret API key"
						name="secretApiKey"
						normalize={trim}
						required
						validate={secretAPIKeyRequired}
					/>
					<Field
						component={LegacyInputField}
						label="Publishable API key"
						name="publishableApiKey"
						normalize={trim}
						required
						validate={publishableAPIKeyRequired}
					/>
					<Field
						name="googlePayMerchantId"
						label="Google Pay Merchant ID"
						component={LegacyInputField}
						normalize={trim}
					/>
					<div>
						<Label>Apple Pay Status:</Label>{" "}
						<P style={{ color: isApplePayConnected ? "green" : "red" }}>
							{isApplePayConnected ? "Connected" : "Failed to connect (will retry on save)"}
						</P>
					</div>
				</form>
			</Modal>
		</PaymentInfo>
	);
};

const PinWithReduxForm = reduxForm({
	form: "pinForm",
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => scrollToErrors()
})(Pin as any);

const mapStateToProps = (
	_: ReduxState,
	ownProps: {
		orgPaymentOptions: any;
	}
) => {
	const initialValues = ownProps.orgPaymentOptions?.pinInfo || {};

	return { initialValues };
};

export default connect(mapStateToProps)(PinWithReduxForm);
