import { TaxSettings } from "@hx/utilities";
import { Tabs } from "@/ui/antd";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { getFeeStructure, savePaymentOptions } from "@/state/actions/eventActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import AutoSelfServicePayoutSetting from "./AutoSelfServicePayoutSetting";
import BookingFeesForm from "./BookingFeesForm";
import DGRSettings from "./DGRSettings";
import { EventBankAccount } from "./EventBankAccount";
import PayoutDelayForm from "./PayoutDelayForm";
import RefundSettingsForm from "./RefundSettingsForm";
import TaxSettingsForm from "./TaxSettingsForm";
import { useNavigate, useParams } from "react-router";

const formName = "payment-info";

let PaymentSettings = ({
	currentEvent,
	currentUser,
	feeChargeSettings,
	salesTaxSettings,
	dgrEnabled,
	refundSettings,
	handleSubmit,
	dispatch,
	getFeeStructure,
	savePaymentOptions,
	customSalesTaxSettings,
	autoSelfServe
}) => {
	const params = useParams();
	const navigate = useNavigate();
	const tab = params?.tab || "payouts";

	useEffect(() => {
		// mounted
		const eventId = params?.eventId;
		getFeeStructure(eventId);
	}, []);

	const save = (values) => {
		const eventId = params?.eventId;
		savePaymentOptions(eventId, values);
	};

	const changeFieldValue = (field, value) => {
		dispatch(change(formName, field, value));
	};

	if (!currentEvent || !currentEvent.event) {
		return null;
	}

	const showSelfServicePayout =
		!!currentEvent?.event?.selfServicePayoutsSettings?.currentSettings ||
		!!currentUser?.user?.selfServicePayoutsSettings?.currentSettings;

	const location = currentEvent.event.location;
	const taxInfo = TaxSettings.getEventTaxInfo(salesTaxSettings, location);
	return (
		<div>
			<H1>Payment Settings</H1>
			<LoadErrorView
				loading={currentEvent.save.loading || currentEvent.feeStructure.loading}
				error={currentEvent.save.error || currentEvent.feeStructure.error}
				retryAction={save}
				renderChildren={true}
			>
				<form onSubmit={handleSubmit(save)} onError={(err) => console.log(err)}>
					<ContentBlock>
						<Tabs
							activeKey={tab}
							onChange={(activeKey) => {
								navigate(`/console/my-events/${currentEvent.event._id}/payments/settings/${activeKey}`);
							}}
							items={[
								{
									children: (
										<Fragment>
											<EventBankAccount changeFieldValue={changeFieldValue} />
											<hr />
											<Field
												name="invoiceCode"
												label="Note on Payout"
												description="Automatically include your GL code on the payout receipt sent with your payout confirmation email to help your finance team know where to allocate the payment. "
												component={LegacyInputField}
											/>{" "}
											<hr />
											<PayoutDelayForm />
											<AutoSelfServicePayoutSetting isVisible={showSelfServicePayout} autoSelfServe={autoSelfServe} />
										</Fragment>
									),
									key: "payouts",
									label: "Payouts"
								},
								{
									children: (
										<BookingFeesForm
											feeChargeSettings={feeChargeSettings}
											changeFieldValue={changeFieldValue}
											currentEvent={currentEvent}
										/>
									),
									key: "fees",
									label: "Booking Fees"
								},
								{
									children: (
										<TaxSettingsForm
											salesTaxSettings={salesTaxSettings}
											location={location}
											customSalesTaxSettings={customSalesTaxSettings}
											tickets={currentEvent.event.ticketTypes}
											eventLocation={currentEvent.event?.eventLocation}
										/>
									),
									key: "tax",
									label: taxInfo.tabName || taxInfo.name
								},
								{
									children: <RefundSettingsForm refundSettings={refundSettings} changeFieldValue={changeFieldValue} />,
									key: "refunds",
									label: "Refunds"
								},
								{
									children: <DGRSettings dgrEnabled={dgrEnabled} location={location} />,
									key: "DGRReceipts",
									label: "DGR Receipts"
								}
							]}
						/>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		</div>
	);
};

// Decorate with redux-form
PaymentSettings = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(PaymentSettings);

const selector = formValueSelector(formName);
PaymentSettings = connect((state) => {
	const initValues = state.currentEvent.event.paymentOptions;
	if (!initValues.refundSettings) {
		initValues.refundSettings = { refundPolicy: null };
	}
	if (!initValues.dgrInfo) {
		initValues.dgrInfo = { enabled: false, donationPercent: 0 };
	}
	if (initValues.dgrInfo && !initValues.dgrInfo.donationPercent) {
		initValues.dgrInfo.donationPercent = 0;
	}
	if (!initValues.salesTaxSettings) {
		initValues.salesTaxSettings = { enabled: false, passOn: false };
	}
	if (!initValues.customSalesTaxSettings) {
		initValues.customSalesTaxSettings = { enabled: false, distribution: "passOn", appliesToAll: true };
	}
	if (!initValues.autoSelfServe) {
		initValues.autoSelfServe = "disabled";
	}
	return {
		initialValues: initValues
	};
})(PaymentSettings);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		currentUser: state.currentUser,
		salesTaxSettings: selector(state, "salesTaxSettings"),
		refundSettings: selector(state, "refundSettings"),
		feesSetByTicketType: selector(state, "feeChargeSettings.feesSetByTicketType"),
		dgrEnabled: selector(state, "dgrInfo.enabled"),
		feeChargeSettings: selector(state, "feeChargeSettings"),
		autoSelfServe: selector(state, "autoSelfServe"),
		customSalesTaxSettings: selector(state, "customSalesTaxSettings")
	}),
	{ savePaymentOptions, getFeeStructure }
)(withSaveCheck(PaymentSettings, formName));
