import { trpc } from "@/trpc";
import { useEffect, useState } from "react";

export type HostProfileProps = { label: string; value: string };

export const useHostProfiles = (initialHostProfileIds: string[] = []) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedHostProfiles, setSelectedHostProfiles] = useState<HostProfileProps[]>([]);
	const [initialHostProfiles, setInitialHostProfiles] = useState<HostProfileProps[]>([]);
	const [filteredHostProfiles, setFilteredHostProfiles] = useState<HostProfileProps[]>([]);
	const [defaultLimit, setDefaultLimit] = useState(10);
	const [pendingSearch, setPendingSearch] = useState(false); // Use this pattern so no race conditions occur

	const searchHostProfilesQuery = trpc.hostProfiles.searchHostProfiles.useQuery(
		{
			pageSize: defaultLimit,
			searchTerm: searchTerm.length > 1 ? searchTerm : ""
		},
		{ enabled: true }
	);

	useEffect(() => {
		if (searchHostProfilesQuery.data) {
			const hostProfiles = searchHostProfilesQuery.data.map((hostProfile) => ({
				label: hostProfile.name,
				value: hostProfile._id
			}));
			setFilteredHostProfiles(hostProfiles);
		}
	}, [searchHostProfilesQuery.data]);

	const getHostProfilesQuery = trpc.hostProfiles.getHostProfilesByIds.useQuery(
		{ ids: initialHostProfileIds },
		{ enabled: initialHostProfileIds.length > 0 }
	);

	useEffect(() => {
		if (getHostProfilesQuery.data) {
			const hostProfiles = getHostProfilesQuery.data.map((hostProfile) => ({
				label: hostProfile.name,
				value: hostProfile._id
			}));
			setInitialHostProfiles(hostProfiles);
			setSelectedHostProfiles(hostProfiles);
		}
	}, [getHostProfilesQuery.data]);

	useEffect(() => {
		if (pendingSearch) {
			searchHostProfilesQuery.refetch().finally(() => {
				setPendingSearch(false);
			});
		}
	}, [pendingSearch]);

	const handleSearchHostProfiles = (term: string, limit?: null | number, force = false) => {
		setSearchTerm(term);
		setDefaultLimit(limit ?? defaultLimit);
		if (force || term.length > 1) {
			setPendingSearch(true);
		}
	};

	const addSelectedHostProfile = (hostProfile: HostProfileProps) =>
		setSelectedHostProfiles((prev) => [...prev, hostProfile]);

	const removeSelectedHostProfile = (hostProfileId: string) =>
		setSelectedHostProfiles((prev) => prev.filter((hostProfile) => hostProfile.value !== hostProfileId));

	const handleHostProfileChange = async (values: string[]) => {
		const existingHostProfilesToAdd = filteredHostProfiles.filter((hostProfile) => values.includes(hostProfile.value));
		existingHostProfilesToAdd.forEach((hostProfile) => addSelectedHostProfile(hostProfile));

		const removedHostProfiles = selectedHostProfiles.filter(
			(selectedHostProfile) => !values.includes(selectedHostProfile.value)
		);
		removedHostProfiles.forEach((hostProfile) => removeSelectedHostProfile(hostProfile.value));

		handleSearchHostProfiles("", null, true);
	};

	const handleReset = () => {
		setSearchTerm("");
		setSelectedHostProfiles([]);
		setInitialHostProfiles([]);
		setFilteredHostProfiles([]);
	};

	const combinedHostProfiles: HostProfileProps[] = [
		...selectedHostProfiles,
		...filteredHostProfiles,
		...initialHostProfiles
	].filter((hostProfile, index, self) => index === self.findIndex((t) => t.value === hostProfile.value));

	const noContent = searchTerm.length > 0 ? "No results" : "Start typing to search";

	return {
		handleChange: handleHostProfileChange,
		options: combinedHostProfiles,
		noContent,
		reset: handleReset,
		search: handleSearchHostProfiles,
		selectedHostProfiles
	};
};
