const IcCalendar = ({ width = 24, height = 24, color = "#67717E" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<g stroke={color} transform="translate(2.5 2.5)">
					<rect width="17.613" height="16.613" x=".693" y="1.693" strokeWidth="1.387" rx="2.08" />
					<path strokeLinecap="round" strokeWidth="1.387" d="M5 0v3M14 0v3" />
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.387" d="M1 7h17" />
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth=".693"
						d="M10.785 11.302h1.079M14.02 11.302h1.08M10.785 14.513h1.079M7.55 14.513h1.078M4.314 14.513h1.079M7.55 11.302h1.078"
					/>
				</g>
				<path d="M0 0h24v24H0z" />
			</g>
		</svg>
	);
};

export default IcCalendar;
