import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { cloneSeatingMap, getCloneableMaps } from "@/state/actions/seatingMapActions";
import MapCard from "./MapCard";
import { withRouterHooks } from "@/hoc/withRouterHooks";
const confirm = Modal.confirm;

class ExistingMapPicker extends Component {
	componentWillMount() {
		this.props.getCloneableMaps();
	}

	confirmClone = ({ id, name, capacity, eventId }) => {
		const { navigate, cloneSeatingMap } = this.props;
		confirm({
			title: `Copy ${name}?`,
			content: `Are you sure you wish to copy ${name}?`,
			onOk() {
				cloneSeatingMap(eventId, capacity, id, navigate);
			}
		});
	};

	render() {
		const { cloneableMaps } = this.props.seatingMap;
		const separator = (
			<div
				style={{
					borderTop: "solid 1px #dedede",
					flexGrow: 1,
					height: 1,
					margin: "auto"
				}}
			/>
		);
		return (
			<div style={{ marginTop: 16 }}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					{separator}
					<p style={{ margin: "0px 12px 16px 12px" }}>Or use an existing venue map template</p>
					{separator}
				</div>
				<div style={{ display: "flex", flexWrap: "wrap" }}>
					{cloneableMaps.map((map, i) => {
						return (
							<div
								onClick={() => this.confirmClone(map)}
								style={{
									cursor: "pointer",
									padding: "16px 12px",
									border: "solid 1px #dedede",
									borderRadius: 4,
									width: "calc(50% - 10px)",
									marginBottom: 12,
									marginRight: i % 2 === 0 ? 20 : 0
								}}
							>
								<MapCard {...map} size="small" />
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		seatingMap: state.seatingMap
	}),
	{
		getCloneableMaps,
		cloneSeatingMap
	}
)(withRouterHooks(ExistingMapPicker));
