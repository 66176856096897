export const minsToDaysHoursMins = (mins: number) => {
	mins = !mins || mins < 0 ? 0 : mins;
	return {
		days: Math.floor(mins / 24 / 60),
		hours: Math.floor((mins / 60) % 24),
		minutes: mins % 60
	};
};

export const daysHoursMinsToMins = (days: number, hours: number, mins: number) => {
	return days * 24 * 60 + hours * 60 + mins;
};

export const isDatePast = (date: string | Date) => {
	return new Date(date) < new Date(new Date());
};
