const FilestackPrecache = ({ imageHandle }: { imageHandle: string | null }) => {
	if (!imageHandle) return null;
	const filestackBaseUrl = "https://cdn.filestackcontent.com/compress/output=format:webp/cache=expiry:max";
	return (
		<details style={{ display: "none" }}>
			<img src={`${filestackBaseUrl}/resize=width:250/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:500/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:750/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:900/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:1250/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:1440/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:1600/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:2000/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:2400/${imageHandle}`} alt="e" />
			<img src={`${filestackBaseUrl}/resize=width:3200/${imageHandle}`} alt="e" />
		</details>
	);
};

export default FilestackPrecache;
