import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from "antd";
import { ReactNode } from "react";

export type TooltipProps = AntTooltipProps & {
	/** Conditionally include the tooltip */
	hidden?: boolean;
	children: ReactNode;
};

export const Tooltip = ({ hidden, ...props }: TooltipProps): JSX.Element => {
	return hidden ? (props.children as JSX.Element) : <AntTooltip {...props} />;
};
