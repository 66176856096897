import InputLabel from "../../../components/InputLabel";
import StepInput from "../../../components/StepInput";

export default ({ element, performAction }) => {
	const { seatsPerRow, changeSeatsPerRow } = element;
	return (
		<div style={{ overflow: "auto", paddingTop: 12 }}>
			<InputLabel style={{ float: "left" }}>Seats per row</InputLabel>
			<StepInput
				min={1}
				value={seatsPerRow}
				onChange={(value) => performAction([changeSeatsPerRow], [[value, false, false, true]])}
				style={{ float: "right" }}
			/>
		</div>
	);
};
