import { CodeBlock, ImageUploader, ImageUploaderResult } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC, useState } from "react";

const props = `type ImageUploaderResult = {
	canvaDesignId?: string;
	handle?: string;
	height?: string;
	name: string;
	size?: number;
	source?: string;
	url: string;
	width?: string;
};

type ImageUploaderProps = {
	aspectRatio?: number;
	canva?: boolean;
	defaultImage?: ImageUploaderResult;
	description?: ReactNode;
	onChange?: (image: ImageUploaderResult) => void;
	small?: boolean;
	uploadedImageWidth?: number;
	uploaderMinHeight?: string;
};`;

const sample = `import { ImageUploader } from "@/ui";
import { ImageUploaderResult } from "@/ui/ImageUploader";
import { FC, useState } from "react";

const [response, setResponse] = useState<ImageUploaderResult | null>();

<ImageUploader canva onChange={(result) => setResponse(result)} />`;

export const ImageUploaderPage: FC = () => {
	const [response, setResponse] = useState<ImageUploaderResult | null>();

	return (
		<Flex gap="lg" vertical>
			<h2>Small</h2>
			<ImageUploader canva onChange={(result) => setResponse(result)} small />
			<h2>Large</h2>
			<ImageUploader canva onChange={(result) => setResponse(result)} />
			<h2>Response from onChange</h2>
			<textarea
				style={{
					border: "2px solid #e8e8e8",
					borderRadius: "4px",
					marginBottom: "16px",
					minHeight: "300px",
					padding: "8px",
					width: "100%"
				}}
				value={JSON.stringify(response, null, 2)}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
