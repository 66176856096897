"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  categories: () => categories,
  categorySlugArray: () => categorySlugArray,
  categorySlugs: () => categorySlugs,
  checkCategoryMayHaveArtists: () => checkCategoryMayHaveArtists,
  getCategories: () => getCategories,
  getSubCategoriesByCategory: () => getSubCategoriesByCategory,
  getSubcategories: () => getSubcategories,
  getTypes: () => getTypes,
  subcategorySlugArray: () => subcategorySlugArray,
  subcategorySlugs: () => subcategorySlugs,
  typeSlugArray: () => typeSlugArray,
  typeSlugs: () => typeSlugs,
  types: () => types
});
module.exports = __toCommonJS(src_exports);

// src/categories.ts
var types = {
  appearanceOrSigning: "Appearance or Signing",
  attraction: "Attraction",
  campTripOrRetreat: "Camp, Trip or Retreat",
  classTrainingOrWorkshop: "Class, Training or Workshop",
  concertOrPerformance: "Concert or Performance",
  conference: "Conference",
  convention: "Convention",
  dinnerOrGala: "Dinner or Gala",
  festivalOrFair: "Festival or Fair",
  gameOrCompetition: "Game or Competition",
  meetingOrNetworkingEvent: "Meeting or Networking Event",
  partyOrSocialGathering: "Party or Social Gathering",
  raceOrEnduranceEvent: "Race or Endurance Event",
  rally: "Rally",
  screening: "Screening",
  seminarOrTalk: "Seminar or Talk",
  tour: "Tour",
  tournament: "Tournament",
  tradeShowConsumerShowOrExpo: "Trade Show, Consumer Show or Expo",
  other: "Other"
};
var categories = {
  autoBoatAndAir: {
    label: "Auto, Boat & Air",
    subcategories: {
      air: "Air",
      auto: "Auto",
      boat: "Boat",
      motorcycle: "Motorcycle",
      other: "Other"
    }
  },
  businessAndProfessional: {
    label: "Business & Professional",
    subcategories: {
      career: "Career",
      design: "Design",
      educators: "Educators",
      environmentAndSustainability: "Environment & Sustainability",
      finance: "Finance",
      investment: "Investment",
      media: "Media",
      nonProfitNGO: "Non-profit & NGOs",
      realEstate: "Real Estate",
      salesAndMarketing: "Sales & Marketing",
      startupsAndBusiness: "Startups & Small Business",
      other: "Other"
    }
  },
  charityAndCauses: {
    label: "Charity & Causes",
    subcategories: {
      animalWelfare: "Animal Welfare",
      disasterRelief: "Disaster Relief",
      education: "Education",
      environment: "Environment",
      healthcare: "Healthcare",
      humanRights: "Human Rights",
      internationalAid: "International Aid",
      poverty: "Poverty",
      other: "Other"
    }
  },
  communityAndCulture: {
    label: "Community & Culture",
    subcategories: {
      cityTown: "City/Town",
      county: "County",
      heritage: "Heritage",
      historic: "Historic",
      lgbt: "LGBT",
      language: "Language",
      medieval: "Medieval",
      nationality: "Nationality",
      renaissance: "Renaissance",
      state: "State",
      other: "Other"
    }
  },
  familyAndEducation: {
    label: "Family & Education",
    subcategories: {
      alumni: "Alumni",
      baby: "Baby",
      childrenAndYouth: "Children & Youth",
      education: "Education",
      parenting: "Parenting",
      parentsAssociation: "Parents Association",
      reunion: "Reunion",
      seniorCitizen: "Senior Citizen",
      other: "Other"
    }
  },
  fashionAndBeauty: {
    label: "Fashion & Beauty",
    subcategories: {
      accessories: "Accessories",
      beauty: "Beauty",
      bridal: "Bridal",
      fashion: "Fashion",
      other: "Other"
    }
  },
  filmMediaAndEntertainment: {
    label: "Film, Media & Entertainment",
    subcategories: {
      adult: "Adult",
      anime: "Anime",
      comedy: "Comedy",
      comics: "Comics",
      film: "Film",
      gaming: "Gaming",
      tv: "TV",
      other: "Other"
    }
  },
  foodAndDrink: {
    label: "Food & Drink",
    subcategories: {
      beer: "Beer",
      food: "Food",
      spirits: "Spirits",
      wine: "Wine",
      other: "Other"
    }
  },
  governmentAndPolitics: {
    label: "Government & Politics",
    subcategories: {
      countyMunicipalGovernment: "County/Municipal Government",
      democraticParty: "Democratic Party",
      federalGovernment: "Federal Government",
      internationalAffairs: "International Affairs",
      military: "Military",
      nationalSecurity: "National Security",
      nonPartisan: "Non-partisan",
      otherParty: "Other Party",
      republicanParty: "Republican Party",
      stateGovernment: "State Government",
      other: "Other"
    }
  },
  healthAndWellness: {
    label: "Health & Wellness",
    subcategories: {
      medical: "Medical",
      mentalHealth: "Mental Health",
      personalHealth: "Personal Health",
      spa: "Spa",
      yoga: "Yoga",
      other: "Other"
    }
  },
  hobbiesAndSpecialInterest: {
    label: "Hobbies & Special Interest",
    subcategories: {
      adult: "Adult",
      animeComics: "Anime/Comics",
      books: "Books",
      diy: "DIY",
      drawingAndPainting: "Drawing & Painting",
      gaming: "Gaming",
      knitting: "Knitting",
      photography: "Photography",
      other: "Other"
    }
  },
  homeAndLifestyle: {
    label: "Home & Lifestyle",
    subcategories: {
      dating: "Dating",
      homeAndGarden: "Home & Garden",
      petsAndAnimals: "Pets & Animals",
      other: "Other"
    }
  },
  music: {
    label: "Music",
    subcategories: {
      acoustic: "Acoustic",
      alternative: "Alternative",
      americana: "Americana",
      bluegrass: "Bluegrass",
      blues: "Blues",
      bluesAndJazz: "Blues & Jazz",
      classical: "Classical",
      country: "Country",
      cultural: "Cultural",
      djDance: "DJ/Dance",
      edm: "EDM",
      edmElectronic: "EDM/Electronic",
      electronic: "Electronic",
      experimental: "Experimental",
      folk: "Folk",
      hipHopRap: "Hip Hop/Rap",
      indie: "Indie",
      jazz: "Jazz",
      latin: "Latin",
      metal: "Metal",
      opera: "Opera",
      pop: "Pop",
      psychedelic: "Psychedelic",
      punkHardcore: "Punk/Hardcore",
      rAndB: "R&B",
      reggae: "Reggae",
      religiousSpiritual: "Religious/Spiritual",
      rock: "Rock",
      singerSongwriter: "Singer/Songwriter",
      top40: "Top 40",
      world: "World",
      other: "Other"
    }
  },
  performingAndVisualArts: {
    label: "Performing & Visual Arts",
    subcategories: {
      ballet: "Ballet",
      comedy: "Comedy",
      craft: "Craft",
      dance: "Dance",
      design: "Design",
      fineArt: "Fine Art",
      jewelry: "Jewelry",
      literaryArts: "Literary Arts",
      musical: "Musical",
      opera: "Opera",
      orchestra: "Orchestra",
      painting: "Painting",
      sculpture: "Sculpture",
      theatre: "Theatre",
      other: "Other"
    }
  },
  religionAndSpirituality: {
    label: "Religion & Spirituality",
    subcategories: {
      agnosticism: "Agnosticism",
      atheism: "Atheism",
      buddhism: "Buddhism",
      christianity: "Christianity",
      easternReligion: "Eastern Religion",
      folkReligions: "Folk Religions",
      hinduism: "Hinduism",
      islam: "Islam",
      judaism: "Judaism",
      mormonism: "Mormonism",
      mysticismAndOccult: "Mysticism & Occult",
      newAge: "New Age",
      shintoism: "Shintoism",
      sikhism: "Sikhism",
      unaffiliated: "Unaffiliated",
      other: "Other"
    }
  },
  schoolActivities: {
    label: "School Activities",
    subcategories: {
      afterSchoolCare: "After School Care",
      dinner: "Dinner",
      fundRaiser: "Fund Raiser",
      parking: "Parking",
      publicSpeaker: "Public Speaker",
      raffle: "Raffle",
      other: "Other"
    }
  },
  scienceAndTechnology: {
    label: "Science & Technology",
    subcategories: {
      biotech: "Biotech",
      highTech: "High Tech",
      medicine: "Medicine",
      mobile: "Mobile",
      robotics: "Robotics",
      science: "Science",
      socialMedia: "Social Media",
      other: "Other"
    }
  },
  seasonalAndHoliday: {
    label: "Seasonal & Holiday",
    subcategories: {
      autumnEvents: "Autumn Events",
      christmas: "Christmas",
      easter: "Easter",
      halloweenHaunt: "Halloween/Haunt",
      hanukkah: "Hanukkah",
      independenceDay: "Independence Day",
      newYearsEve: "New Year's Eve",
      stPatricksDay: "St. Patrick's Day",
      thanksgiving: "Thanksgiving",
      other: "Other"
    }
  },
  sportsAndFitness: {
    label: "Sports & Fitness",
    subcategories: {
      americanFootball: "American Football",
      baseball: "Baseball",
      basketball: "Basketball",
      camps: "Camps",
      cheer: "Cheer",
      cycling: "Cycling",
      exercise: "Exercise",
      fightingMartialArts: "Fighting & Martial Arts",
      football: "Football",
      golf: "Golf",
      hockey: "Hockey",
      lacrosse: "Lacrosse",
      motorsports: "Motorsports",
      mountainBiking: "Mountain Biking",
      obstacles: "Obstacles",
      rugby: "Rugby",
      running: "Running",
      snowSports: "Snow Sports",
      softball: "Softball",
      swimmingWaterSports: "Swimming & Water Sports",
      tennis: "Tennis",
      trackField: "Track & Field",
      volleyball: "Volleyball",
      walking: "Walking",
      weightlifting: "Weightlifting",
      wrestling: "Wrestling",
      yoga: "Yoga",
      other: "Other"
    }
  },
  travelAndOutdoor: {
    label: "Travel & Outdoor",
    subcategories: {
      canoeing: "Canoeing",
      climbing: "Climbing",
      hiking: "Hiking",
      kayaking: "Kayaking",
      rafting: "Rafting",
      travel: "Travel",
      other: "Other"
    }
  },
  other: {
    label: "Other",
    subcategories: {}
  }
};

// src/format.ts
var typeSlugArray = Object.keys(types);
var categorySlugArray = Object.keys(categories);
var typeSlugs = typeSlugArray.reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});
var categorySlugs = categorySlugArray.reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});
var subcategorySlugs = categorySlugArray.reduce((acc, key) => {
  const subcats = categories[key].subcategories;
  const subcategoriesArray = Object.keys(subcats);
  return subcategoriesArray.reduce((subAcc, subcat) => {
    subAcc[subcat] = subcat;
    return subAcc;
  }, acc);
}, {});
var subcategorySlugArray = Object.keys(subcategorySlugs);
var getTypes = /* @__PURE__ */ __name(() => {
  return typeSlugArray.map((type) => ({
    label: types[type],
    value: type
  }));
}, "getTypes");
var getCategories = /* @__PURE__ */ __name(() => {
  return categorySlugArray.map((category) => ({
    label: categories[category]?.label,
    value: category
  }));
}, "getCategories");
var getSubcategories = /* @__PURE__ */ __name(() => {
  return Object.keys(categories).reduce((acc, key) => {
    return acc.concat(getSubCategoriesByCategory(key));
  }, []);
}, "getSubcategories");
var getSubCategoriesByCategory = /* @__PURE__ */ __name((category) => {
  const found = categories[category]?.subcategories;
  return Object.keys(found).map((subcategory) => {
    const key = subcategory;
    return {
      label: found[key],
      value: subcategory
    };
  });
}, "getSubCategoriesByCategory");

// src/utils.ts
var checkCategoryMayHaveArtists = /* @__PURE__ */ __name((category) => [
  categorySlugs.music,
  categorySlugs.charityAndCauses,
  categorySlugs.fashionAndBeauty,
  categorySlugs.filmMediaAndEntertainment,
  categorySlugs.foodAndDrink,
  categorySlugs.performingAndVisualArts,
  categorySlugs.seasonalAndHoliday
].includes(category), "checkCategoryMayHaveArtists");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  categories,
  categorySlugArray,
  categorySlugs,
  checkCategoryMayHaveArtists,
  getCategories,
  getSubCategoriesByCategory,
  getSubcategories,
  getTypes,
  subcategorySlugArray,
  subcategorySlugs,
  typeSlugArray,
  typeSlugs,
  types
});
