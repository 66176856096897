import { FC } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import InlineSVG from "react-inlinesvg";

const StyledIconContainer = styled.div<StyledSvgProps>`
	${({ $fill, $height, $margin, $opacity, $padding, $strokeColour, $strokeWidth, $width }) => css`
		fill: ${$fill};
		height: ${$height};
		padding: ${$padding};
		margin: ${$margin};
		opacity: ${$opacity};
		width: ${$width};

		path {
			stroke: ${$strokeColour};
			stroke-width: ${$strokeWidth ?? 0.1};
		}
	`}
`;

type StyledSvgProps = {
	$fill?: string;
	$height?: string;
	$margin?: string;
	$opacity?: string;
	$padding?: string;
	$strokeColour?: string;
	$strokeWidth?: string;
	$width?: string;
};

type SvgProps = {
	fill?: string;
	height?: string;
	margin?: string;
	opacity?: string;
	padding?: string;
	src?: any;
	strokeColour?: string;
	strokeWidth?: string;
	width?: string;
};

export const Svg: FC<SvgProps> = (props) => (
	<StyledIconContainer
		$fill={props.fill}
		$height={props.height ?? "24px"}
		$margin={props.margin}
		$opacity={props.opacity}
		$padding={props.padding}
		$strokeColour={props.strokeColour}
		$strokeWidth={props.strokeWidth}
		$width={props.width ?? "24px"}
	>
		<InlineSVG src={String(props.src)} />
	</StyledIconContainer>
);
