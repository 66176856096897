import { H2 } from "@/components/text/Text";
import TicketInfo from "./TicketInfo";

const TicketsQuestions = ({
	tickets,
	ticketTypes,
	additionalQuestions,
	ticketsAdditionalQuestions,
	changeFieldValue,
	buyerData
}) => {
	const getTicketQuestions = (ticketTypeId, additionalQuestions) => {
		return additionalQuestions.filter((question) => {
			if (question.disabled || question.deleted) {
				return false;
			}
			if (question.perOrder) {
				return false;
			}
			if (question.appliesTo && question.appliesTo.length && question.appliesTo.indexOf(ticketTypeId) === -1) {
				return false;
			}
			return question;
		});
	};

	const ticketTypeCounts = {};
	tickets = tickets.map((t) => {
		if (!ticketTypeCounts[t.ticketTypeName]) {
			ticketTypeCounts[t.ticketTypeName] = 0;
		}
		ticketTypeCounts[t.ticketTypeName]++;
		t.ticketTypeIndex = ticketTypeCounts[t.ticketTypeName];
		t.label = `${ticketTypeCounts[t.ticketTypeName]} ${t.ticketTypeName}${
			t.status !== "pending" ? ` | ${t.status}` : ""
		}`;
		// add ticketOrder to sort selected ticket
		let ticketOrder = -1;
		ticketTypes.some((type, i) => {
			if (type._id === t.ticketTypeId) {
				ticketOrder = i;
				return true;
			}
		});
		t.ticketOrder = ticketOrder;
		return t;
	});

	// sort selected ticket by ticket order from ticketTypes
	tickets.sort((a, b) => a.ticketOrder - b.ticketOrder);

	const ticketWithQuestions = tickets
		.map((ticket) => {
			return {
				ticket,
				questions: getTicketQuestions(ticket.ticketTypeId, additionalQuestions)
			};
		})
		.filter(({ questions }) => questions.length);

	const ticketsQ = ticketWithQuestions.map((ticketQ, index) => (
		<TicketInfo
			key={index}
			index={index}
			ticket={ticketQ.ticket}
			questions={ticketQ.questions}
			ticketsAdditionalQuestions={ticketsAdditionalQuestions}
			changeFieldValue={changeFieldValue}
			buyerData={buyerData}
		/>
	));

	if (!ticketsQ.length) {
		return null;
	}

	return (
		<div>
			<H2 className="sub-header">Ticket Information</H2>
			{ticketsQ}
		</div>
	);
};

export default TicketsQuestions;
