import { Button, ButtonProps } from "@/ui/Button";
import styled from "@emotion/styled";
import { FC } from "react";

const StyledDropdownItem = styled(Button)`
	a {
		color: var(--on-surface1);
		text-decoration: none;

		&:hover {
			color: var(--on-surface1);
			text-decoration: none;
		}
	}
`;

type DropdownItemProps = Partial<ButtonProps> & {
	spaceBetween?: boolean;
};

export const DropdownItem: FC<DropdownItemProps> = (props) => {
	return (
		<StyledDropdownItem
			justify={props.spaceBetween ? "space-between" : "start"}
			variant={props.variant ?? "text"}
			{...props}
		/>
	);
};
