import { css } from "@emotion/css";
import { MailChimpConnectImage } from "@/assets/integrations/connect-to-mailchimp";
import { Banner } from "@/components/Banner";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";

const style = {
	link: css({
		textDecoration: "underline",
		"&:focus, &:hover": {
			textDecoration: "none"
		}
	}),
	row: css({
		display: "flex",
		"@media (max-width: 1200px)": {
			flexDirection: "column-reverse"
		}
	}),
	colImage: css({
		display: "flex",
		justifyContent: "center",
		marginBottom: "2em",
		marginTop: "2.5em",
		"@media (min-width: 768px)": {
			marginTop: 0
		},
		"@media (min-width: 1200px)": {
			alignItems: "center",
			justifyContent: "flex-end",
			marginBottom: 0,
			padding: "0 1.5em"
		}
	}),
	colContent: css({
		p: {
			maxWidth: "none"
		}
	}),
	image: css({
		width: 360,
		height: 120,
		"@media (max-width: 575px)": {
			marginTop: 20
		},
		"@media (max-width: 420px)": {
			width: 240,
			height: 80
		}
	})
};

const BannerMailchimp = () => {
	return (
		<Banner bannerId="mailchimp" style={{ marginBottom: "3em" }}>
			<div className={style.row}>
				<div className={style.colContent}>
					<h3 style={{ fontSize: "16px", fontWeight: 600 }}>Go bananas and swing into our new Mailchimp integration</h3>
					<p>
						Save time by automatically creating and/or updating your Mailchimp contacts with enriched data from your
						Humanitix orders.
					</p>
					<p>
						<a onClick={OpenHelpHandler(HelpIds.mailchimp)} className={style.link}>
							Find out more
						</a>
					</p>
				</div>
				<div className={style.colImage}>
					<MailChimpConnectImage className={style.image} />
				</div>
			</div>
		</Banner>
	);
};

export default BannerMailchimp;
