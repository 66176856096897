import { useAppSelector } from "@/state/hooks";
import styled from "@emotion/styled";
import { H1, P } from "../components/text/Text";

const Wrapper = styled.div`
	display: flex;
`;

const Content = styled.div`
	flex: 1;
	@media (max-width: 600px) {
		padding: 12px;
	}
`;

const Heading = styled(H1)`
	@media (max-width: 600px) {
		margin: 12px 0 4px 0 !important;
	}
`;

export const Welcome = () => {
	const user = useAppSelector((state) => state.auth.user);

	return (
		<Wrapper>
			<Content>
				<Heading>Hi {user?.firstName || ""} &#128075;</Heading>
				<P marginBottom={0}>Here's the lowdown on your events.</P>
			</Content>
		</Wrapper>
	);
};
