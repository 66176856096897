// const STREET_NUMBER = ["street_number", "premise"];
// const STREET = ["route", "sublocality"];
// const STREET2 = ["sublocality_level_1"];
// const STREET3 = ["sublocality_level_2", "sublocality_level_3", "sublocality_level_4", "sublocality_level_5"];
// const CITY = "administrative_area_level_2";

const SUBURB = "locality";
const STATE = "administrative_area_level_1";
const COUNTRY = "country";
const POSTCODE = "postal_code";
const UNKNOWN = "";

type AddressComponent = {
	types: string[];
	short_name: string;
};

const getAddressComponent =
	(type: string) =>
	(addressComponents: AddressComponent[]): string | undefined => {
		let result: string | undefined = UNKNOWN;
		if (addressComponents && addressComponents.length) {
			const match = addressComponents.find((address) => address.types.find((addressType) => addressType === type));
			result = match?.short_name ? match.short_name : undefined;
		}
		return result;
	};

export const getSuburb = getAddressComponent(SUBURB);
export const getState = getAddressComponent(STATE);
export const getCountry = getAddressComponent(COUNTRY);
export const getPostalCode = getAddressComponent(POSTCODE);
