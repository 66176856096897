import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import { Link } from "react-router-dom";
import { gatewayRequiresPayout } from "../config";

import iconReference from "../services/IconReference";

const icon = iconReference["warning"];

const spanStyling = css({
	color: "#fff",
	":hover": {
		textDecoration: "underline"
	}
});

type TicketType = {
	disabled?: boolean;
	deleted?: boolean;
	price?: number;
	isDonation?: boolean;
};

interface IMissingBankAccountIndicator {
	eventId: string;
	paymentOptions: {
		userBankAccountId?: string;
		paymentId?: string;
	};
	ticketTypes?: TicketType[];
}

const MissingBankAccountIndicator = ({ eventId, paymentOptions, ticketTypes }: IMissingBankAccountIndicator) => {
	const hasLinkedBankAccount = !!paymentOptions.userBankAccountId;
	if (hasLinkedBankAccount) return null;

	const requiresPayout = paymentOptions.paymentId && gatewayRequiresPayout(paymentOptions.paymentId);
	if (!requiresPayout) return null;

	const isPaidEvent = ticketTypes?.some((ticket) => {
		return !ticket.disabled && !ticket.deleted && ((ticket.price && ticket.price > 0) || ticket.isDonation);
	});

	if (!isPaidEvent) return null;

	return (
		<Tooltip
			title={
				<Link to={`/console/my-events/${eventId}/payments/settings`}>
					<span className={spanStyling}>Missing bank account for payouts</span>
				</Link>
			}
		>
			<img src={String(icon)} style={{ marginRight: 8 }} width="20" alt="Missing bank account for payouts" />
		</Tooltip>
	);
};

export default MissingBankAccountIndicator;
