import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
const defaultStyling = {
	cursor: "pointer",
	background: "#f9f9fa",
	boxShadow: "0 1px 4px 0 rgba(9,30,66,0.13)",
	borderRadius: "3px",
	width: 72,
	height: 72
};

const elementOptionStyling = css({
	...defaultStyling,
	"&:hover": {
		background: "#70697a",
		h4: {
			color: "#FFFFFF"
		},
		"#ele-id-fill": {
			fill: "#FFFFFF"
		},
		"#ele-id-stroke": {
			stroke: "#FFFFFF"
		}
	},
	"&:active": {
		background: "#70697a",
		h4: {
			color: "#FFFFFF"
		},
		"#ele-id-fill": {
			fill: "#57cfc7"
		},
		"#ele-id-stroke": {
			stroke: "#57cfc7"
		}
	},
	h4: {
		color: "#5a626c"
	}
});

const elementOptionSelected = css({
	...defaultStyling,
	"#ele-id-fill": {
		fill: "#57cfc7"
	},
	"#ele-id-stroke": {
		stroke: "#57cfc7"
	},
	h4: {
		color: "#FFFFFF"
	},
	background: "#70697a",
	boxShadow: "none"
});

const Option = ({ name, elementImage, onClick, isSelected, toolTip }) => {
	return (
		<Tooltip placement="right" title={toolTip} align={{ offset: [19, 0] }}>
			<div className={isSelected ? elementOptionSelected : elementOptionStyling} onClick={onClick}>
				<div style={{ paddingTop: 14 }}>
					<div style={{ display: "block", margin: "auto", width: 24, height: 22 }}>{elementImage({})}</div>
					<h4
						style={{
							textAlign: "center",
							fontSize: 14,
							fontWeight: 600
						}}
					>
						{name}
					</h4>
				</div>
			</div>
		</Tooltip>
	);
};

export default Option;
