import logoHumanitix from "@/assets/logos/logo-peach.svg";
import { Responsive } from "@/ui";
import { MenuOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import MegaSearch from "../../megaSearch/MegaSearch";
import UserDisplay from "../../UserDisplay";

const styles = {
	topBarStyle: css`
		display: flex;
		padding: 0px 56px;
		height: 56px;
		justify-content: space-between;
		gap: 8px;
		@media (max-width: 1024px) {
			padding: 0;
		}
	`,
	humanitixLogo: css`
		background: url(${logoHumanitix});
		width: 140px;
		height: 24px;
		display: block;
		background-repeat: no-repeat;
		background-position: left center;
		margin: 20px 0 12px 0;
		background-size: contain;
	`,
	megaSearch: css`
		max-width: 600px;
		margin-right: 8px;
		@media (max-width: 585px) {
			width: 100%;
		}
	`,
	controls: css`
		display: flex;
		align-items: center;
		@media (max-width: 585px) {
			flex: 1;
		}
	`
};

const Topbar = ({ assumingIdentity, openSideBar }: { assumingIdentity: boolean; openSideBar: () => void }) => (
	<div className={styles.topBarStyle} style={{ backgroundColor: assumingIdentity ? "#218262" : "#353337" }}>
		<div>
			<Responsive
				mobile={
					<div onClick={openSideBar} style={{ margin: "auto", padding: 16 }}>
						<MenuOutlined style={{ fontSize: 16, color: "white" }} />
					</div>
				}
				desktop={<Link to="/console/dashboard" className={styles.humanitixLogo} />}
			/>
		</div>
		<div className={styles.controls}>
			<div className={styles.megaSearch}>
				<MegaSearch />
			</div>
			<UserDisplay />
		</div>
	</div>
);

export default Topbar;
