const RoundTable = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path
					id="ele-id-stroke"
					stroke="#67717E"
					strokeWidth="1.122"
					d="M12 19.133c.93 0 1.684.753 1.684 1.683S12.93 22.5 12 22.5c-.93 0-1.684-.754-1.684-1.684 0-.93.754-1.683 1.684-1.683zm5.044-2.09c.657-.657 1.723-.657 2.38 0 .658.658.658 1.724 0 2.382-.657.657-1.723.657-2.38 0-.658-.658-.658-1.724 0-2.381zm-12.469 0c.658-.657 1.724-.657 2.381 0 .658.658.658 1.724 0 2.382-.657.657-1.723.657-2.38 0-.658-.658-.658-1.724 0-2.381zm7.401-10.68c3.1 0 5.612 2.514 5.612 5.613 0 3.1-2.512 5.612-5.612 5.612s-5.612-2.512-5.612-5.612 2.513-5.612 5.612-5.612zm-8.792 3.953c.93 0 1.683.754 1.683 1.684 0 .93-.753 1.684-1.683 1.684S1.5 12.93 1.5 12c0-.93.754-1.684 1.684-1.684zm17.632 0c.93 0 1.684.754 1.684 1.684 0 .93-.754 1.684-1.684 1.684-.93 0-1.683-.754-1.683-1.684 0-.93.753-1.684 1.683-1.684zm-3.772-5.74c.657-.658 1.723-.658 2.38 0 .658.657.658 1.723 0 2.38-.657.658-1.723.658-2.38 0-.658-.657-.658-1.723 0-2.38zm-12.469 0c.658-.658 1.724-.658 2.381 0 .658.657.658 1.723 0 2.38-.657.658-1.723.658-2.38 0-.658-.657-.658-1.723 0-2.38zM12 1.5c.93 0 1.684.754 1.684 1.684 0 .93-.754 1.683-1.684 1.683-.93 0-1.684-.753-1.684-1.683S11.07 1.5 12 1.5z"
				/>
				<path d="M0 0H24V24H0z" />
			</g>
		</svg>
	);
};

export default RoundTable;
