import Bin from "./Bin";
import Cross from "./Cross";
import Duplicate from "./Duplicate";
import Expand from "./Expand";
import Info from "./Info";
import MultiSelect from "./MultiSelect";
import Opacity from "./Opacity";
import Pan from "./Pan";
import Redo from "./Redo";
import Rotate from "./Rotate";
import Search from "./Search";
import Undo from "./Undo";
import ZoomIn from "./ZoomIn";
import ZoomOut from "./ZoomOut";

enum IconNames {
	bin = "bin",
	duplicate = "duplicate",
	rotate = "rotate",
	opacity = "opacity",
	cross = "cross",
	info = "info",
	multiSelect = "multiSelect",
	pan = "pan",
	redo = "redo",
	undo = "undo",
	zoomIn = "zoomIn",
	zoomOut = "zoomOut",
	search = "search",
	expand = "expand"
}

const iconMap = {
	[IconNames.bin]: Bin,
	[IconNames.duplicate]: Duplicate,
	[IconNames.rotate]: Rotate,
	[IconNames.opacity]: Opacity,
	[IconNames.cross]: Cross,
	[IconNames.info]: Info,
	[IconNames.multiSelect]: MultiSelect,
	[IconNames.pan]: Pan,
	[IconNames.redo]: Redo,
	[IconNames.undo]: Undo,
	[IconNames.zoomIn]: ZoomIn,
	[IconNames.zoomOut]: ZoomOut,
	[IconNames.search]: Search,
	[IconNames.expand]: Expand
};

export { IconNames, iconMap };
