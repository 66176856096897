import { qRCodeStyle } from "@/components/QRCode";
import { EventDetails } from "@/state/reducers/eventReducer";
import { Button } from "@/ui";
import { getEventUrls } from "@/utils/Events";
import { CaretDownOutlined, QrcodeOutlined } from "@ant-design/icons";
import { LinkTrackingType, addLinkTracking } from "@hx/analytics";
import { Dropdown, message } from "@/ui/antd";
import QRCodeStyling from "qr-code-styling";
import { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const qrCodeDownloadable = new QRCodeStyling(qRCodeStyle);

type DownloadQRCodeProps = {
	event: EventDetails;
};

export const DownloadQRCode: FC<DownloadQRCodeProps> = ({ event }) => {
	const downloadableRef = useRef(null);
	let { eventPage, selectTicketsPage } = getEventUrls(event, true);
	eventPage = addLinkTracking(LinkTrackingType.eventQRCode, eventPage);
	selectTicketsPage = addLinkTracking(LinkTrackingType.eventTicketsQRCode, selectTicketsPage);

	useEffect(() => {
		if (downloadableRef.current) {
			qrCodeDownloadable.append(downloadableRef.current);
		}
	}, [downloadableRef.current]);

	const handleDownloadClicked = async () => {
		await qrCodeDownloadable.download({ name: "humanitix-qr", extension: "png" });
	};

	const handleCopyToClipboardClicked = ({ key }: { key: string }) => {
		switch (key) {
			case "download-event-page-qr": {
				qrCodeDownloadable.update({
					data: eventPage
				});

				handleDownloadClicked();
				break;
			}
			case "download-tickets-page-qr": {
				qrCodeDownloadable.update({
					data: selectTicketsPage
				});

				handleDownloadClicked();
				break;
			}
			default: {
				message.error("Unable to download. Unrecognized selection");
				return;
			}
		}
		message.success("QR code downloaded");
	};

	return (
		<>
			<Dropdown
				trigger={["click"]}
				menu={{
					items: [
						{ key: "download-event-page-qr", label: "For event page" },
						{ key: "download-tickets-page-qr", label: "For select tickets page" }
					],
					onClick: handleCopyToClipboardClicked
				}}
			>
				<Button
					aria-label="Download QR Code"
					iconLeft={<QrcodeOutlined style={{ marginRight: 8 }} />}
					iconRight={<CaretDownOutlined />}
					variant="secondary"
				>
					Download QR
				</Button>
			</Dropdown>

			{createPortal(
				<div
					ref={downloadableRef}
					id="download-qr-code-portal"
					style={{
						position: "absolute",
						top: -99999
					}}
				/>,
				document.body
			)}
		</>
	);
};
