import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getSharedAccounts, sendAuthorization, updateAuthorization } from "../state/actions/authorizationActions";
import AuthorizationForm from "./AuthorizationForm";

class AuthorizationModal extends Component {
	constructor(props) {
		super(props);
		this.handleSave = this.handleSave.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.state = {
			submit: false,
			missingPermission: false
		};
	}

	componentWillMount() {
		this.props.getSharedAccounts();
	}

	componentWillReceiveProps(props) {
		const { visible, handleCancel, authorization } = props;
		if (authorization.saved && visible) {
			this.setState({ submit: false });
			handleCancel();
		}
	}

	handleAdd() {
		const { form } = this.props;
		if (form.authorization && form.authorization.values && !this.checkIfPermissionSelected(form.authorization.values)) {
			this.setState({
				submit: true
			});
		} else {
			this.setState({ missingPermission: true });
		}
	}

	checkIfPermissionSelected(authorizationData) {
		const { eventManagement, ordersAndAttendees, financialAndReports, app, fullAccess } = authorizationData;
		return !fullAccess && !eventManagement && !ordersAndAttendees && !financialAndReports && !app;
	}

	handleSave(authorizationData) {
		authorizationData.email = authorizationData.email.toLowerCase();
		const { authorization, sendAuthorization, updateAuthorization, permissionListingFor } = this.props;
		if (authorization.selectedAuthorization) {
			updateAuthorization(authorization.selectedAuthorization.id, authorizationData, permissionListingFor);
		} else {
			sendAuthorization(authorizationData);
		}
		this.setState({ submit: true, missingPermission: false });
	}

	render() {
		const { visible, handleCancel, authorization } = this.props;
		const { missingPermission, submit } = this.state;
		const { loading, error } = authorization;
		const button = (
			<LegacyButton
				key="authorization"
				htmlType="button"
				form="authorizationForm"
				onClick={this.handleAdd}
				value="submit"
				type="default"
				ariaLabel={authorization.selectedAuthorization ? "Update permissions" : "Share permissions"}
				style={{ float: "right" }}
			>
				{authorization.selectedAuthorization ? "Update permissions" : "Share permissions"}
			</LegacyButton>
		);
		return (
			<Modal
				open={visible}
				title={authorization.selectedAuthorization ? "Update permissions" : "Share permissions"}
				onCancel={handleCancel}
				destroyOnClose
				footer={[button]}
			>
				<LoadErrorView loading={loading} error={error}>
					{!loading && !error ? (
						<AuthorizationForm
							showUserSelect={!authorization.selectedAuthorization}
							missingPermission={missingPermission}
							save={this.handleSave}
							submit={submit}
						/>
					) : null}
				</LoadErrorView>
			</Modal>
		);
	}
}

export default connect(
	(state) => ({
		authorization: state.authorization,
		form: state.form
	}),
	{ sendAuthorization, updateAuthorization, getSharedAccounts }
)(AuthorizationModal);
