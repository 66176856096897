import { Button, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { getFeeCrystalisationReport, resetFeeCrystalisation } from "@/state/actions/adminReports/financeActions";
import { currency } from "@/utils/Format";
import DatesSelector from "../../DatesSelector";
import DayEndsAt from "../DayEndsAt";

class FeeCrystalisationReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	runLoad = () => {
		const { startDate, endDate, dayEndsAt } = this.props;
		this.load({ startDate, endDate, dayEndsAt });
	};

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getFeeCrystalisationReport } = this.props;
		getFeeCrystalisationReport(values.startDate, values.endDate, values.dayEndsAt);
	}
	retry() {
		const { resetFeeCrystalisation } = this.props;
		resetFeeCrystalisation();
	}

	render() {
		const { touch, handleSubmit, feeCrystalisationReport, startDate, endDate, dayEndsAt } = this.props;
		let columns = [
			{
				title: "Booking Fees ( Held )",
				dataIndex: "heldByUsFees",
				key: "heldByUsFees",
				render: (value) => {
					return <span>{currency(value)}</span>;
				}
			},
			{
				title: "Refunded Booking Fees ( Held )",
				dataIndex: "heldByUsRefundedFees",
				key: "heldByUsRefundedFees",
				render: (value) => {
					return <span>- {currency(value)}</span>;
				}
			},
			{
				title: "Rebates ( Held )",
				dataIndex: "heldRebates",
				key: "heldRebates",
				render: (value) => {
					return <span>- {currency(value)}</span>;
				}
			},
			{
				title: "Refunded Rebates ( Held )",
				dataIndex: "refundedHeldRebates",
				key: "refundedHeldRebates",
				render: (value) => {
					return <span>+ {currency(value)}</span>;
				}
			},
			{
				title: "Stripe Rebates",
				dataIndex: "stripeRebates",
				key: "stripeRebates",
				render: (value) => {
					return <span>- {currency(value)}</span>;
				}
			},
			{
				title: "Refunded Stripe Rebates",
				dataIndex: "refundedStripeRebates",
				key: "refundedStripeRebates",
				render: (value) => {
					return <span>+ {currency(value)}</span>;
				}
			},
			{
				title: "Total",
				dataIndex: "total",
				key: "total",
				render: (value) => {
					return <span>{currency(value)}</span>;
				}
			}
		];
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Fee Crystalisation Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView
					loading={feeCrystalisationReport.loading}
					error={feeCrystalisationReport.error}
					retryAction={this.retry}
				>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								<DayEndsAt />
							</div>
						</div>
						<Button
							type="primary"
							onClick={this.runLoad}
							disabled={!startDate || !endDate || !dayEndsAt}
							ariaLabel="Load"
						>
							Load
						</Button>
					</ReportFilters>
					<ReportContent>
						{feeCrystalisationReport.data ? (
							<div>
								<Table columns={columns} dataSource={[feeCrystalisationReport.data]} rowKey="_id" pagination={false} />
							</div>
						) : null}
					</ReportContent>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "feeCrystalisationReport";
const selector = formValueSelector(formName);
FeeCrystalisationReport = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(FeeCrystalisationReport);

export default connect(
	(state) => ({
		initialValues: {
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		feeCrystalisationReport: state.adminReports.finance.feeCrystalisationReport,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{ getFeeCrystalisationReport, resetFeeCrystalisation }
)(FeeCrystalisationReport);
