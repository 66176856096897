import { Card } from "@/ui/antd";
import { Component } from "react";

export default class AuthCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showChildren: false
		};
	}

	render() {
		const { showChildren, header, children } = this.props;
		if (showChildren && children) {
			return (
				<Card style={{ marginTop: 20 }} title={header}>
					{children}
				</Card>
			);
		}
		return <Card style={{ marginTop: 20 }}>{header}</Card>;
	}
}
