import { Alert, Radio } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { CheckboxField } from "../../components/Fields";
import { H4, P } from "../../components/text/Text";
import AuthCard from "./AuthCard";
import EventSelection from "./EventSelection";

const allEventOptions = [
	{
		id: "fullAccess",
		cardName: "Full access",
		label: "Create events on my behalf",
		description: "The user will have access to all your events."
	}
];

const eventSpecificOptions = [
	{
		id: "eventManagement",
		cardName: "Event editing",
		label: "Change my event’s basic information",
		description:
			"Member will be able to change basic information and will not be able to review any of your sensitive information."
	},
	{
		id: "ordersAndAttendees",
		cardName: "Manage orders, attendees, email campaigns and in-app Box office",
		label: (
			<ul>
				<li>View and manage orders and attendees</li>
				<li>Send email campaigns for selected events</li>
				<li>Sell/refund tickets via the in-app Box Office (requires Scanning app access)</li>
			</ul>
		),
		description:
			"Member will be able to view and change order and attendees information as well as send emails on the events behalf. This includes selling tickets via the in-app Box Office."
	},
	{
		id: "financialAndReports",
		cardName: "Finances and reports",
		label: "View and manage finances and reports",
		description: "Member will be able to view and access all financial and reporting information"
	},
	{
		id: "app",
		cardName: "Scanning app access",
		label: "Check-in attendees with Humanitix app",
		description: "Member will be able check in attendees using the Humanitix Phone App."
	}
];

const OptionInfo = ({ label, description }) => {
	return (
		<div>
			<H4 style={{ lineHeight: "22px", marginTop: "-5px" }}>{label}</H4>
			<P>{description}</P>
		</div>
	);
};

class AuthorizationOptions extends Component {
	clearValues() {
		const { changeFieldValue } = this.props;
		eventSpecificOptions.forEach(({ id }) => {
			changeFieldValue(id, null);
		});
		changeFieldValue("events", null);
	}

	componentWillReceiveProps(nextProps) {
		const { formState } = this.props;
		if (!formState.fullAccess && nextProps.formState.fullAccess) {
			this.clearValues();
		}
	}

	changeCardSelection(id) {
		const { changeFieldValue } = this.props;
		if (id === 0) {
			changeFieldValue("fullAccess", true);
		} else {
			changeFieldValue("fullAccess", false);
		}
	}

	constructCardHeader(heading, description, allEventChecked, id) {
		return (
			<Radio
				style={{ display: "flex", whiteSpace: "normal" }}
				onChange={() => this.changeCardSelection(id)}
				checked={allEventChecked}
			>
				<H4>{heading}</H4>
				{description && <span>{description}</span>}
			</Radio>
		);
	}

	render() {
		const { formState, missingPermission, issueFor } = this.props;
		const eventSpecificCards = eventSpecificOptions.map((option, i) => {
			return (
				<div key={i}>
					<Field
						name={option.id}
						style={{ display: "flex" }}
						component={CheckboxField}
						disabled={formState.fullAccess}
						defaultChecked={formState[option.id]}
						fieldChild={<OptionInfo label={option.cardName} description={option.label} />}
					/>
				</div>
			);
		});
		let allEventChecked;
		const allEventCards = allEventOptions.map((option, i) => {
			allEventChecked = formState[option.id] !== undefined ? formState[option.id] : false;
			return (
				<div key={i}>
					<AuthCard
						header={this.constructCardHeader(option.cardName, option.description, allEventChecked, 0)}
						showChildren={formState.fullAccess}
					>
						<Field
							name="enableSharing"
							label="Create and share events on my behalf"
							component={CheckboxField}
							inline
							labelAfter
						/>
						<Alert message="Bank accounts can only be managed by you (the event owner)." type="info" showIcon />
					</AuthCard>
				</div>
			);
		});
		const eventSpecificDescription = "Allow specific access to event and/or features.";
		return (
			<div>
				{allEventCards}
				<AuthCard
					header={this.constructCardHeader("Event / feature specific", eventSpecificDescription, !allEventChecked, 1)}
					showChildren={!formState.fullAccess}
				>
					{missingPermission && (
						<div
							style={{
								color: "red",
								paddingBottom: 10
							}}
						>
							At least one permission must be selected
						</div>
					)}
					<P>
						<b>Select event(s) they will have access to</b>
					</P>
					<EventSelection issueFor={issueFor} />
					{eventSpecificCards}
				</AuthCard>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		authorization: state.authorization,
		formState: state.form.authorization.values || {}
	}),
	{}
)(AuthorizationOptions);
