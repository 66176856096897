import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import PageActions from "@/components/PageActions";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import TableActions from "@/components/table/TableActions";
import { H1, P } from "@/components/text/Text";
import { deleteAppMessage, listAppMessage } from "@/state/actions/scanningMessageActions";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class ScanningMessages extends Component {
	constructor(props) {
		super(props);
		this.remove = this.remove.bind(this);
		this.load = this.load.bind(this);
		this.newMessage = this.newMessage.bind(this);
		this.state = {
			filter: "scheduled",
			expandedRowKeys: []
		};
	}

	componentDidMount() {
		this.load();
	}

	load() {
		const { listAppMessage, params } = this.props;
		const eventId = params?.eventId;
		listAppMessage(eventId);
	}

	remove(message) {
		const { deleteAppMessage } = this.props;
		deleteAppMessage(message._id);
	}

	newMessage() {
		const { navigate, params } = this.props;
		const eventId = params?.eventId;
		navigate(`/console/my-events/${eventId}/app/messages/new`);
	}

	render() {
		const { params, scanningMessage } = this.props;
		const { loading, error, appMessages } = scanningMessage;
		const eventId = params?.eventId;
		let columns = [
			{
				title: "Heading",
				dataIndex: "header",
				key: "header",
				render: (value, message) => (
					<Link style={{ color: "#196097" }} to={`/console/my-events/${eventId}/app/messages/${message._id}`}>
						{value}
					</Link>
				)
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status"
			},
			{
				title: "Applies to",
				dataIndex: "appliesTo",
				key: "appliesTo",
				render: (value) => {
					if (value?.type === "custom") {
						return "Specific Attendees";
					} else if (value?.type === "ticketType") {
						return "Ticket Type";
					}
					return "All";
				}
			},
			{
				title: "Actions",
				key: "actions",
				width: 140,
				fixed: "right",
				render: (message) => (
					<TableActions>
						<Link style={{ color: "#196097" }} to={`/console/my-events/${eventId}/app/messages/${message._id}`}>
							<IconButton icon="edit" tooltip="Edit" showTooltip ariaLabel="Edit" />
						</Link>
						<IconButton
							icon="delete"
							onClick={() => this.remove(message)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				)
			}
		];

		const isBrowser = typeof window !== "undefined";

		return (
			<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
				<PageActions />
				<H1>Scanning App messages</H1>
				<ContentBlock
					style={{
						background: "#fff",
						padding: isBrowser && window.innerWidth > 600 ? 24 : 14,
						margin: 0
					}}
				>
					<P
						style={{
							marginBottom: isBrowser && window.innerWidth > 600 ? 24 : 12
						}}
					>
						Create a more seamless event check-in experience with Scanning App Messages. Customise and apply messaging
						to an individual, ticket type or all attendee tickets. This message appears on your scanning device when
						checked in, and will support your team in making attendees feel welcome.{" "}
					</P>
					{appMessages && appMessages.length > 0 ? (
						<div style={{ overflowX: "auto", width: "100%", minWidth: 264 }}>
							<Table
								columns={columns}
								dataSource={appMessages}
								rowKey="key"
								onRow={() => null}
								scroll={{ x: 600 }}
								expandIconColumnIndex={99}
								expandIconAsCell={false}
							/>
							<ButtonsBar>
								<LegacyButton
									onClick={this.newMessage}
									icon={{ name: "plus", left: true }}
									ariaLabel="New Message"
									type="action"
								>
									New message
								</LegacyButton>
							</ButtonsBar>
						</div>
					) : (
						<NoResults
							title="You have no scanning messages."
							message=""
							action={this.newMessage}
							actionTxt="New Message"
							secondary
							inverse
						/>
					)}
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		scanningMessage: state.scanningMessage,
		currentEvent: state.currentEvent
	}),
	{ listAppMessage, deleteAppMessage }
)(withRouterHooks(ScanningMessages));
