import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Popconfirm } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import LinkButton from "../../components/buttons/LinkButton";
import {
	cancelMassPayout,
	cancelPayment,
	exportMassPayout,
	getMassPayoutCSV
} from "../../state/actions/massPayoutActions";
import PaidModel from "./PaidModel";

const menu = (payout, cancelMassPayout) => {
	const items = [
		{ key: "csv", label: "Download CSV" },
		payout.status !== "cancelled" ? { key: "export", label: "Download bank file" } : null,
		payout.status !== "cancelled" && payout.location === "US"
			? { key: "exportWithSameDayEffectiveDate", label: "Download bank file (same day effective date)" }
			: null,
		payout.status === "pending" ? { key: "paid", label: "Pay" } : null,
		["partially-paid", "paid"].includes(payout.status) ? { key: "edit", label: "Edit" } : null,
		payout.status === "paid" ? { key: "unpaid", label: "Undo payment" } : null,
		payout.status === "pending"
			? {
					key: "cancel",
					label: (
						<>
							<Popconfirm
								title="Are you sure you want to cancel this payment?"
								onConfirm={() => cancelMassPayout(payout._id)}
								okText="Cancel"
								cancelText="No"
							>
								Cancel
							</Popconfirm>
						</>
					)
			  }
			: null
	];

	return items.filter((item) => !!item);
};

class PayoutActions extends Component {
	constructor(props) {
		super(props);
		this.actionClicked = this.actionClicked.bind(this);
		this.openPayment = this.openPayment.bind(this);
		this.closePayment = this.closePayment.bind(this);
		this.state = {
			payMassPaymentId: null,
			paidModelVisible: false,
			title: null
		};
	}
	closePayment() {
		this.setState({
			payMassPaymentId: null,
			paidModelVisible: false,
			title: null
		});
	}
	openPayment(payoutId, title) {
		this.setState({
			payMassPaymentId: payoutId,
			paidModelVisible: true,
			title
		});
	}
	actionClicked(evt, payout) {
		const { exportMassPayout, cancelPayment, getMassPayoutCSV } = this.props;
		switch (evt.key) {
			case "csv":
				getMassPayoutCSV(payout._id);
				break;
			case "export":
				exportMassPayout(payout._id, payout.location);
				break;
			case "edit":
				this.openPayment(payout._id, "Edit payment");
				break;
			case "exportWithSameDayEffectiveDate":
				exportMassPayout(payout._id, payout.location, true);
				break;
			case "paid":
				this.openPayment(payout._id, "Mark as Paid");
				break;
			case "unpaid":
				cancelPayment(payout._id);
				break;
			default:
				break;
		}
	}
	render() {
		const { payout, cancelMassPayout } = this.props;
		const { payMassPaymentId, paidModelVisible, title } = this.state;
		return (
			<div>
				<Dropdown
					menu={{
						items: menu(payout, cancelMassPayout),
						onClick: (evt) => this.actionClicked(evt, payout)
					}}
					trigger={["click"]}
				>
					<LinkButton className="ant-dropdown-link" ariaLabel="Actions">
						Actions
						<DownOutlined style={{ marginLeft: 4 }} />
					</LinkButton>
				</Dropdown>
				<PaidModel
					visible={paidModelVisible}
					massPaymentId={payMassPaymentId}
					handleCancel={this.closePayment}
					title={title}
				/>
			</div>
		);
	}
}

export default connect(() => ({}), {
	exportMassPayout,
	cancelMassPayout,
	cancelPayment,
	getMassPayoutCSV
})(PayoutActions);
