import { ToursActionTypes } from "@/state/actions/tourActions";

export type Tour = {
	_id: string;
	name: string;
	userId: string;
	published: boolean;
	createdAt: string;
	updatedAt: string;
} & any;

export interface TourReducerState {
	tours: Tour[] | null;
	loading: boolean;
	error: null | Error | string;
	save: {
		loading?: boolean;
		error?: null | Error | string;
	};
	update: {
		loading?: boolean;
		error?: null | Error | string;
	};
	tour: {
		loading?: boolean;
		error?: null | Error | string;
		data?: Tour;
		publishLoading?: boolean;
	};
	deleteTour: {
		loading?: boolean;
		modalOpen?: boolean;
		error?: null | Error | string;
	};
}

interface RequestAction {
	type:
		| typeof ToursActionTypes.GET_TOURS_REQUEST
		| typeof ToursActionTypes.GET_TOUR_REQUEST
		| typeof ToursActionTypes.SAVE_TOUR_REQUEST
		| typeof ToursActionTypes.UPDATE_TOUR_REQUEST
		| typeof ToursActionTypes.DELETE_TOUR_REQUEST
		| typeof ToursActionTypes.PUBLISH_OR_UNPUBLISH_REQUEST;
}

interface ModalActions {
	type: typeof ToursActionTypes.DELETE_TOUR_MODAL_OPEN | typeof ToursActionTypes.DELETE_TOUR_MODAL_CLOSE;
}

interface GenericSuccessAction {
	type: typeof ToursActionTypes.SAVE_TOUR_SUCCESS | typeof ToursActionTypes.DELETE_TOUR_SUCCESS;
}

interface PublishOrUnpublishSuccessAction {
	type: typeof ToursActionTypes.PUBLISH_OR_UNPUBLISH_SUCCESS;
	tour: Tour;
}

interface GetTourSuccessAction {
	type: typeof ToursActionTypes.GET_TOUR_SUCCESS;
	tour: Tour;
}

interface UpdateTourSuccessAction {
	type: typeof ToursActionTypes.UPDATE_TOUR_SUCCESS;
	tour: Tour;
}

interface GetToursSuccessAction {
	type: typeof ToursActionTypes.GET_TOURS_SUCCESS;
	tours: Tour[];
}

interface ErrorAction {
	type:
		| typeof ToursActionTypes.GET_TOURS_ERROR
		| typeof ToursActionTypes.GET_TOUR_ERROR
		| typeof ToursActionTypes.SAVE_TOUR_ERROR
		| typeof ToursActionTypes.UPDATE_TOUR_ERROR
		| typeof ToursActionTypes.DELETE_TOUR_ERROR
		| typeof ToursActionTypes.PUBLISH_OR_UNPUBLISH_ERROR;
	error: Error | string;
}

type SuccessAction =
	| GenericSuccessAction
	| PublishOrUnpublishSuccessAction
	| GetToursSuccessAction
	| GetTourSuccessAction
	| UpdateTourSuccessAction;

type ActionType = RequestAction | SuccessAction | ErrorAction | ModalActions;

const initialState: TourReducerState = {
	loading: false,
	tours: null,
	error: null,
	save: {
		loading: false,
		error: null
	},
	update: {
		loading: false,
		error: null
	},
	tour: {
		loading: false,
		data: {},
		error: null,
		publishLoading: false
	},
	deleteTour: {
		loading: false,
		modalOpen: false,
		error: null
	}
};

export default (state = initialState, action: ActionType) => {
	switch (action.type) {
		case ToursActionTypes.DELETE_TOUR_MODAL_OPEN:
			return {
				...state,
				deleteTour: {
					...state.deleteTour,
					modalOpen: true,
					loading: false,
					error: null
				}
			};
		case ToursActionTypes.DELETE_TOUR_MODAL_CLOSE:
			return {
				...state,
				deleteTour: {
					...state.deleteTour,
					modalOpen: false,
					loading: false,
					error: null
				}
			};
		case ToursActionTypes.DELETE_TOUR_REQUEST:
			return {
				...state,
				deleteTour: {
					...state.deleteTour,
					loading: true,
					error: null
				}
			};
		case ToursActionTypes.DELETE_TOUR_SUCCESS:
			return {
				...state,
				...state.deleteTour,
				deleteTour: {
					loading: false,
					error: null
				}
			};
		case ToursActionTypes.DELETE_TOUR_ERROR:
			return {
				...state,
				deleteTour: {
					...state.deleteTour,
					loading: false,
					error: action.error
				}
			};
		case ToursActionTypes.GET_TOURS_REQUEST:
			return {
				...state,
				loading: true,
				tours: null,
				error: null
			};
		case ToursActionTypes.GET_TOURS_SUCCESS:
			return {
				...state,
				loading: false,
				tours: action.tours,
				error: null
			};
		case ToursActionTypes.GET_TOURS_ERROR:
			return {
				...state,
				loading: false,
				tours: null,
				error: action.error
			};
		case ToursActionTypes.GET_TOUR_REQUEST:
			return {
				...state,
				tour: {
					loading: true,
					data: {},
					error: null
				}
			};
		case ToursActionTypes.GET_TOUR_SUCCESS:
			return {
				...state,
				tour: {
					loading: false,
					data: action.tour,
					error: null
				}
			};
		case ToursActionTypes.GET_TOUR_ERROR:
			return {
				...state,
				tour: {
					loading: false,
					data: {},
					error: action.error
				}
			};
		case ToursActionTypes.SAVE_TOUR_REQUEST:
			return {
				...state,
				save: {
					loading: true,
					error: null
				}
			};
		case ToursActionTypes.SAVE_TOUR_SUCCESS:
			return {
				...state,
				save: {
					loading: false,
					error: null
				}
			};
		case ToursActionTypes.SAVE_TOUR_ERROR:
			return {
				...state,
				save: {
					loading: false,
					error: action.error
				}
			};
		case ToursActionTypes.UPDATE_TOUR_REQUEST:
			return {
				...state,
				update: {
					loading: true,
					error: null
				}
			};
		case ToursActionTypes.UPDATE_TOUR_SUCCESS:
			return {
				...state,
				update: {
					loading: false,
					error: null
				},
				tour: {
					...state.tour,
					data: action.tour,
					error: null,
					loading: false
				}
			};
		case ToursActionTypes.UPDATE_TOUR_ERROR:
			return {
				...state,
				update: {
					loading: false,
					error: action.error
				}
			};

		case ToursActionTypes.PUBLISH_OR_UNPUBLISH_REQUEST:
			return {
				...state,
				tour: {
					...state.tour,
					publishLoading: true,
					error: null
				}
			};
		case ToursActionTypes.PUBLISH_OR_UNPUBLISH_SUCCESS:
			return {
				...state,
				tour: {
					publishLoading: false,
					data: action.tour
				}
			};
		case ToursActionTypes.PUBLISH_OR_UNPUBLISH_ERROR:
			return {
				...state,
				tour: {
					publishLoading: false,
					error: action.error
				}
			};

		default:
			return state;
	}
};
