import { StorageKeys, useLocalStorage } from "@/hooks/useLocalStorage";
import { useAppSelector } from "@/state/hooks";
import { Button, ResponsiveModal } from "@/ui";
import { Form, Input, InputRef } from "@/ui/antd";
import { LocationSelect } from "@/ui/LocationSelect";
import styled from "@emotion/styled";
import { Location } from "@hx/locations";
import { FC, useEffect, useRef } from "react";

const StyledNameModalFooter = styled.div`
	display: flex;
	justify-content: flex-end;
`;

type CreateCollectionModalProps = {
	defaultName?: string;
	onClose: () => void;
	onSubmit: (newCollectionInfo: { name: string; location: Location }) => void;
	open: boolean;
};

export const CreateCollectionModal: FC<CreateCollectionModalProps> = ({ defaultName, onClose, onSubmit, open }) => {
	const user = useAppSelector((state) => state.auth.user);
	const [nameCollectionForm] = Form.useForm<{
		collectionName: string;
		location: Location;
	}>();
	const nameInputRef = useRef<InputRef>(null);
	const [filterCollectionsByLocation, _, refetchLocationStorage] = useLocalStorage<Location>(
		StorageKeys.COLLECTIONS_LOCATION,
		user.location
	);

	useEffect(() => {
		if (open) {
			if (nameInputRef.current) {
				nameInputRef.current.focus();
			}

			nameCollectionForm.setFieldsValue({ location: filterCollectionsByLocation });
		}
	}, [open, filterCollectionsByLocation, nameInputRef.current]);

	useEffect(() => {
		refetchLocationStorage();
	}, [open]);

	useEffect(() => {
		nameCollectionForm.setFieldsValue({ collectionName: defaultName ?? "" });
	}, [defaultName]);

	const handleSubmitCreateCollection = async () => {
		const formValues = await nameCollectionForm.validateFields();
		if (formValues) {
			onSubmit({
				name: formValues.collectionName,
				location: formValues.location
			});
			nameCollectionForm.resetFields();
			onClose();
		}
	};

	const accessibleLocationsCount = Object.values(user.accessibleLocations).filter(Boolean).length;
	const showCurrencySelect = accessibleLocationsCount > 1;

	return (
		<ResponsiveModal
			closable
			footer={
				<StyledNameModalFooter>
					<Button onClick={handleSubmitCreateCollection} variant="secondary">
						Create Collection
					</Button>
				</StyledNameModalFooter>
			}
			header="Name your collection"
			onCancel={onClose}
			open={open}
			padding="24px"
			width="672px"
		>
			<Form
				autoComplete="off"
				form={nameCollectionForm}
				initialValues={{ collectionName: defaultName }}
				layout="vertical"
			>
				<Form.Item
					name="collectionName"
					label={<b>Collection Name</b>}
					rules={[
						{ message: "Please enter a collection name", min: 1, required: true, type: "string" },
						{ max: 80, message: "Collection name must be less than 80 characters" }
					]}
				>
					<Input
						ref={nameInputRef}
						maxLength={80}
						showCount
						onBlur={(e) => {
							const value = e.target.value;
							if (value.length > 0) {
								nameCollectionForm.setFieldsValue({ collectionName: value.trim() });
							}
						}}
					/>
				</Form.Item>
				<Form.Item
					hidden={!showCurrencySelect}
					name="location"
					label={<b>Currency</b>}
					rules={[{ message: "Please enter a currency", min: 1, required: true, type: "string" }]}
				>
					<LocationSelect
						locations={Object.keys(user.accessibleLocations) as Location[]}
						placeholder="Select currency"
					/>
				</Form.Item>
			</Form>
		</ResponsiveModal>
	);
};
