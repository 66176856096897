import { InputNumber } from "@/ui/antd";
import { Component } from "react";
import InputWrapper from "./helpers/InputWrapper";

// !isNaN(null) gives true, so this will give the truth
const isNumber = (value) => typeof value === "number" && !isNaN(Number(value));

class InputNumberField extends Component {
	constructor() {
		super();
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	handleChange(value) {
		const { input, allowsNullValue, parser, max, min } = this.props;
		const parsed = parser ? parser(value) : value;

		const newValue = Number(parsed);
		const shouldBeNull = (parsed === "" || typeof parsed === "undefined" || parsed === null) && allowsNullValue;
		const isNotAnActualNumber = !isNumber(newValue);
		if (shouldBeNull || isNotAnActualNumber) {
			input.onChange(null);
			return;
		}

		if (isNumber(max) && max < newValue) return;
		if (isNumber(min) && min > newValue) return;

		input.onChange(newValue);
	}

	handleBlur(event) {
		const { parser, inverseFormatter, input, allowsNullValue, min, max } = this.props;
		const strVal = inverseFormatter ? inverseFormatter(event.target.value) : event.target.value;
		const parsed = parser ? parser(strVal) : strVal;

		const newValue = Number(parsed);
		const shouldBeNull = (parsed === "" || !parsed || parsed === null) && allowsNullValue;
		const isNotAnActualNumber = !isNumber(newValue);

		if (shouldBeNull || isNotAnActualNumber) {
			input.onBlur(null);
			return;
		}

		if (isNumber(max) && max < newValue) return;
		if (isNumber(min) && min > newValue) return;

		input.onBlur(newValue);
	}

	render() {
		const {
			input,
			label,
			labelAfter,
			description,
			noMargin,
			required,
			inline,
			placeholder,
			formatter,
			parser,
			disabled,
			min,
			max,
			precision = 0,
			style,
			tooltip,
			tooltipIcon = false,
			tooltipIconOnClick,
			meta,
			dataCy
		} = this.props;
		const { touched, error } = meta ?? {};
		let styleInput;
		if (style) {
			styleInput = style;
		} else {
			styleInput = {};
			styleInput.display = inline ? "inline-block" : "block";
		}

		return (
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				id={input.name}
				inline={inline}
				noMargin={noMargin}
				required={required}
				tooltip={tooltip}
				labelAfter={labelAfter}
				tooltipIcon={tooltipIcon}
				tooltipIconOnClick={tooltipIconOnClick}
			>
				<InputNumber
					style={styleInput}
					id={input.name}
					placeholder={placeholder}
					disabled={disabled}
					{...input}
					value={isNaN(input.value) ? null : input.value}
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					min={min}
					max={max}
					formatter={formatter}
					parser={parser}
					data-cy={dataCy}
					precision={precision}
				/>
			</InputWrapper>
		);
	}
}

export default InputNumberField;
