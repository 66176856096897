import { Input } from "@/ui/antd";

export default ({ element, performAction, typeLabel, nameType }) => {
	const { name, label } = element;
	return (
		<div>
			<h2>{name}</h2>
			<p>{`${typeLabel} ${nameType}`}</p>
			<Input
				value={label}
				onChange={(event) => {
					const value = event.target.value;
					performAction([element.setLabel], [[value]]);
				}}
			/>
		</div>
	);
};
