import {
	CLOSE_CREATE_MODAL,
	CLOSE_DELETE_MODEL,
	CREATE_ORGANISER_ERROR,
	DELETE_ORGANISER_ERROR,
	DELETE_ORGANISER_REQUEST,
	DELETE_ORGANISER_SUCCESS,
	EVENTS_WITH_ORGANISER,
	FETCH_ORGANISER_ERROR,
	FETCH_ORGANISER_REQUEST,
	FETCH_ORGANISER_SUCCESS,
	FIND_ORGANISER_ERROR,
	FIND_ORGANISER_REQUEST,
	FIND_ORGANISER_SUCCESS,
	GET_ORGANISER_ERROR,
	GET_ORGANISER_REQUEST,
	GET_ORGANISER_SUCCESS,
	OPEN_CREATE_MODAL,
	OPEN_DELETE_MODEL,
	ORGANISER_RESET,
	SAVE_ORGANISER_ERROR,
	SAVE_ORGANISER_REQUEST,
	SAVE_ORGANISER_SUCCESS,
	UPDATE_ORGANISER_ERROR,
	UPDATE_ORGANISER_REQUEST,
	UPDATE_ORGANISER_SUCCESS
} from "../actions/organiserActions";

type MoreOrganiserKeys = any;

type Organiser = {
	_id?: string | null;
	name?: string | null;
	slug?: string | null;
	logo?: string | null;
	userId?: string | null;
	description?: string | null;
	website?: string | null;
	facebook?: string | null;
	twitter?: string | null;
	instagram?: string | null;
	linkedIn?: string | null;
	spotify?: string | null;
	soundCloud?: string | null;
	tikTok?: string | null;
	followerCount?: number | null;
} & MoreOrganiserKeys;

export type OrganiserReducerState = {
	organisers?: Organiser[];
	selected?: Organiser;

	loading?: boolean;
	error?: boolean | string;
	page?: number;
	hadResults: boolean;

	delete: {
		open: boolean;
		loading: boolean;
		error?: boolean | string;
		events?: any[] | null;
	};
	create: {
		open: boolean;
		error?: boolean | string;
	};
	update: {
		error?: boolean | string;
	};
};

const initialState: OrganiserReducerState = {
	organisers: [],
	loading: false,
	error: false,
	selected: {},
	page: 1,
	hadResults: false,
	delete: {
		open: false,
		loading: true,
		error: false,
		events: null
	},
	create: {
		open: false,
		error: false
	},
	update: {
		error: false
	}
};

type OrganiserReducerAction = {
	type:
		| typeof SAVE_ORGANISER_ERROR
		| typeof SAVE_ORGANISER_REQUEST
		| typeof SAVE_ORGANISER_SUCCESS
		| typeof FIND_ORGANISER_ERROR
		| typeof FIND_ORGANISER_REQUEST
		| typeof FIND_ORGANISER_SUCCESS
		| typeof GET_ORGANISER_ERROR
		| typeof GET_ORGANISER_REQUEST
		| typeof GET_ORGANISER_SUCCESS
		| typeof FETCH_ORGANISER_ERROR
		| typeof FETCH_ORGANISER_REQUEST
		| typeof FETCH_ORGANISER_SUCCESS
		| typeof UPDATE_ORGANISER_ERROR
		| typeof UPDATE_ORGANISER_REQUEST
		| typeof UPDATE_ORGANISER_SUCCESS
		| typeof DELETE_ORGANISER_ERROR
		| typeof DELETE_ORGANISER_REQUEST
		| typeof DELETE_ORGANISER_SUCCESS
		| typeof ORGANISER_RESET
		| typeof EVENTS_WITH_ORGANISER
		| typeof OPEN_DELETE_MODEL
		| typeof CLOSE_DELETE_MODEL
		| typeof OPEN_CREATE_MODAL
		| typeof CLOSE_CREATE_MODAL
		| typeof CREATE_ORGANISER_ERROR;
	count?: number;
	events?: any[];
	error?: boolean | string;
	organiser?: any;
	organisers?: any[];
	page?: number;
};

export default (state = initialState, action: OrganiserReducerAction): OrganiserReducerState => {
	switch (action.type) {
		case OPEN_DELETE_MODEL:
			return {
				...state,
				delete: {
					open: true,
					loading: true,
					error: false,
					events: null
				}
			};
		case CLOSE_DELETE_MODEL:
			return {
				...state,
				delete: {
					open: false,
					loading: true,
					error: false,
					events: null
				}
			};
		case EVENTS_WITH_ORGANISER:
			return {
				...state,
				delete: {
					open: true,
					loading: false,
					error: false,
					events: action.events
				}
			};
		case FETCH_ORGANISER_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case FETCH_ORGANISER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case FETCH_ORGANISER_SUCCESS:
			return {
				...state,
				loading: false,
				organisers: action.organiser,
				page: action.page,
				hadResults: !state.hadResults && action.organiser.length !== 0 ? true : state.hadResults
			};
		case FIND_ORGANISER_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case SAVE_ORGANISER_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case SAVE_ORGANISER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_ORGANISER_SUCCESS: {
			const newOrganisers = state.organisers?.concat(action.organiser);
			return {
				...state,
				loading: false,
				create: {
					open: false,
					error: false
				},
				organisers: newOrganisers,
				selected: action.organiser
			};
		}
		case FIND_ORGANISER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case FIND_ORGANISER_SUCCESS:
			return {
				...state,
				loading: false,
				organisers: action.organisers
			};
		case UPDATE_ORGANISER_REQUEST:
			return {
				...state,
				loading: true,
				update: { error: false },
				error: false
			};
		case UPDATE_ORGANISER_SUCCESS: {
			return {
				...state,
				loading: false,
				update: { error: false },
				selected: action.organiser
			};
		}
		case UPDATE_ORGANISER_ERROR:
			return {
				...state,
				update: { error: action.error },
				loading: false
			};
		case GET_ORGANISER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_ORGANISER_SUCCESS: {
			return {
				...state,
				loading: false,
				selected: action.organiser
			};
		}
		case GET_ORGANISER_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case DELETE_ORGANISER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case DELETE_ORGANISER_SUCCESS:
			return {
				...state,
				loading: false,
				delete: {
					open: false,
					loading: false,
					error: false,
					events: null
				}
			};
		case DELETE_ORGANISER_ERROR:
			return {
				...state,
				loading: false,
				delete: {
					open: false,
					loading: false,
					error: action.error,
					events: null
				}
			};
		case ORGANISER_RESET:
			return {
				...state,
				loading: false,
				selected: {},
				create: {
					open: false,
					error: false
				},
				delete: {
					open: false,
					loading: false,
					error: false,
					events: null
				}
			};
		case CREATE_ORGANISER_ERROR: {
			return {
				...state,
				create: {
					open: true,
					error: action.error
				}
			};
		}
		case OPEN_CREATE_MODAL: {
			return {
				...state,
				create: {
					open: true,
					error: false
				}
			};
		}
		case CLOSE_CREATE_MODAL: {
			return {
				...state,
				create: {
					open: false,
					error: false
				}
			};
		}
		default:
			return state;
	}
};
