import { Alert } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, SwitchField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { updateEventLinkedInSettings } from "@/state/actions/eventActions";
import { checkLength, onlyNumbers, required } from "@/utils/Validators";

const formName = "linkedInEvent";

const idRequired = required("LinkedIn event id is required");
const idOnlyNumbers = onlyNumbers("LinkedIn event id can only contain numbers");
const idLength = checkLength("LinkedIn event id needs to be 19 numbers", 19, "==");

const LinkedInEvent = ({ handleSubmit, linkedInEventEnabled, updateEventLinkedInSettings, eventId }) => {
	const save = (values) => {
		updateEventLinkedInSettings(eventId, values);
	};

	return (
		<LoadErrorView>
			<form>
				<PageHeading title="LinkedIn event" />
				<ContentBlock>
					<Field
						name="linkedInEventEnabled"
						label="Enabled"
						description="Enables the LinkedIn event promotion"
						component={SwitchField}
					/>
					{linkedInEventEnabled ? (
						<>
							<Field
								name="linkedInEventId"
								label="LinkedIn event id"
								component={LegacyInputField}
								validate={[idRequired, idOnlyNumbers, idLength]}
							/>
							<Alert
								showIcon
								type="info"
								message={
									<>
										The id is the 19 digit number at the end of your LinkedIn event url
										<br />
										In the example below it is 6564473839015305216
										<br />
										https://www.linkedin.com/events/youreventname<b>6564473839015305216</b>/
									</>
								}
							/>
						</>
					) : null}
				</ContentBlock>

				<FormBar>
					<LegacyButton
						style={{
							float: "right",
							width: "100px"
						}}
						type="primary"
						ariaLabel="Save"
						onClick={handleSubmit(save)}
					>
						Save
					</LegacyButton>
				</FormBar>
			</form>
		</LoadErrorView>
	);
};
const selector = formValueSelector(formName);

export default connect(
	(state) => {
		const eventId = state?.currentEvent?.event?._id;
		const initialValues = {
			linkedInEventEnabled: state?.currentEvent?.event?.linkedInSettings?.linkedInEventEnabled,
			linkedInEventId: state?.currentEvent?.event?.linkedInSettings?.linkedInEventId
		};
		return {
			initialValues,
			eventId,
			linkedInEventEnabled: selector(state, "linkedInEventEnabled")
		};
	},
	{ updateEventLinkedInSettings }
)(
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true
	})(LinkedInEvent)
);
