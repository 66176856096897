import { CLOSE, CREATE, DESTROY, OPEN } from "../actions/dropdownActions";

export type DropdownReducerState = {
	[key: string]: boolean;
};

const initialState: DropdownReducerState = {};

type DropdownReducerAction = {
	type: typeof CREATE | typeof OPEN | typeof CLOSE | typeof DESTROY;
	name?: string;
};

export default (state = initialState, action: DropdownReducerAction) => {
	switch (action.type) {
		case CREATE:
			return {
				...state,
				[`${action.name}`]: false
			};
		case CLOSE:
			return {
				...state,
				[`${action.name}`]: false
			};
		case OPEN:
			return {
				...state,
				[`${action.name}`]: true
			};
		case DESTROY:
			delete state[`${action.name}`];
			return {
				...state
			};
		default:
			return state;
	}
};
