import {
	TOP_PURCHASERS_ERROR,
	TOP_PURCHASERS_REQUEST,
	TOP_PURCHASERS_RESET,
	TOP_PURCHASERS_SUCCESS
} from "../../actions/adminReports/attendeesActions";

const initialState = {
	topPurchasers: {
		data: null,
		page: 1,
		count: null,
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case TOP_PURCHASERS_RESET: {
			return {
				...state,
				topPurchasers: {
					...state.topPurchasers,
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case TOP_PURCHASERS_REQUEST:
			return {
				...state,
				topPurchasers: {
					data: null,
					page: action.page,
					loading: true,
					error: false
				}
			};
		case TOP_PURCHASERS_SUCCESS:
			return {
				...state,
				topPurchasers: {
					...state.topPurchasers,
					data: action.results,
					count: action.count,
					loading: false,
					error: false
				}
			};
		case TOP_PURCHASERS_ERROR:
			return {
				...state,
				topPurchasers: {
					...state.topPurchasers,
					data: null,
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
