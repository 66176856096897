import { Alert, Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import P from "@/components/text/P";

import { LoadingSpinner } from "@/components/AppBlocks";
import { archiveEvent, closeArchiveModal, unarchiveEvent } from "@/state/actions/eventActions";

const ArchiveEventModal = ({
	event,
	archive,
	closeArchiveModal,
	archiveEvent,
	unarchiveEvent,
	shouldReloadEventList = false
}) => {
	const { _id: eventId, name: eventName, isArchived, published, endDate } = event;
	const { loading, modalVisible } = archive;
	const titleText = isArchived ? "Unarchive event" : "Archive event";

	const eventHasPassed = Date.now() > Date.parse(endDate);
	let eventStatusMessage = "";
	if (published && eventHasPassed) {
		eventStatusMessage = "This event has passed";
	} else if (published) {
		eventStatusMessage = "This event is live";
	} else {
		eventStatusMessage = "This event is a draft";
	}

	const footerBtns = (
		<ButtonsBar footer>
			<LegacyButton key="cancel" onClick={closeArchiveModal} disabled={loading} ariaLabel="Cancel">
				Cancel
			</LegacyButton>
			<LegacyButton
				key="submit"
				disabled={loading}
				onClick={() =>
					isArchived ? unarchiveEvent(eventId, shouldReloadEventList) : archiveEvent(eventId, shouldReloadEventList)
				}
				ariaLabel={titleText}
				type="primary"
			>
				{titleText}
			</LegacyButton>
		</ButtonsBar>
	);

	return (
		<Modal open={modalVisible} title={titleText} onCancel={closeArchiveModal} destroyOnClose footer={footerBtns}>
			{loading ? (
				<LoadingSpinner style={{ margin: "auto", display: "block" }} />
			) : (
				<div>
					{isArchived ? (
						<P>
							You are about to unarchive the event <b>{eventName}.</b>
							<br />
							<br />
							This will <b>not resume sales</b> or make the event <b>public</b>.
						</P>
					) : (
						<>
							{shouldReloadEventList ? (
								<Alert
									style={{ marginBottom: 24 }}
									message={<div>Archived events will only appear in 'Archived' filter tab.</div>}
									type="info"
									showIcon
								/>
							) : null}
							<P>
								You are about to archive the event <b>{eventName}</b>. {eventStatusMessage}.
								<br />
								<br />
								This will <b>suspend sales</b> and make the event <b>private</b>.
								<br />
								<br />
								You can unarchive this event by clicking the same menu again.
							</P>
						</>
					)}
				</div>
			)}
		</Modal>
	);
};

export default connect(
	(state) => {
		return {
			archive: state.currentEvent.archive
		};
	},
	{ archiveEvent, unarchiveEvent, closeArchiveModal }
)(ArchiveEventModal);
