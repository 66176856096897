import { Radio } from "@/ui/antd";
import { CSSProperties } from "react";

type RadioGroupProps<T extends string | number> = {
	buttonStyle?: "outline" | "solid";
	defaultValue?: T;
	onChange?: (value: T) => void;
	options: { label: string; value: T; disabled?: boolean }[];
	style?: CSSProperties;
	value?: T;
};

export const RadioGroup = <T extends string | number>({
	buttonStyle = "solid",
	defaultValue,
	onChange,
	options,
	style = {},
	value
}: RadioGroupProps<T>) => (
	<Radio.Group
		defaultValue={defaultValue}
		buttonStyle={buttonStyle}
		onChange={(e) => onChange?.(e.target.value as T)}
		value={value}
		style={{ display: "flex", flexWrap: "nowrap", ...style }}
	>
		{options.map((option) => (
			<Radio.Button
				disabled={option.disabled}
				key={option.value}
				style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
				value={option.value}
			>
				{option.label}
			</Radio.Button>
		))}
	</Radio.Group>
);
