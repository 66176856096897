import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import Page from "@/components/Page";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { HelpIds } from "@/utils/Help";
import type { ScheduledReportDisplay, ScheduledReportEvent } from "@hx/console/scheduledReports";
import { Popconfirm, Table, notification } from "@/ui/antd";
import { ColumnProps } from "antd/lib/table";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ScheduledReportsHeader } from "./Header";
import { SendModalData } from "./Report";
import { SendTestEmailModal } from "./SendTestEmailModal";
import { convertDay, convertHour } from "./utils";

const ListAllScheduledReports = () => {
	const [modalData, setModalData] = useState<SendModalData | null>();
	const [sendModalOpen, setSendModalOpen] = useState(false);

	const userEmail = useAppSelector((state) => state?.auth?.user?.email);
	const { data, error, refetch } = trpc.scheduledReports.getAllReports.useQuery();
	const deleteMutation = trpc.scheduledReports.deleteSchedule.useMutation({
		onSuccess: (response) => {
			notification.success({
				message: "Success",
				description: response
			});
			refetch();
		},
		onError: (error) => {
			notification.error({
				message: "Error",
				description: error.message ?? "Failed to delete scheduled report"
			});
		}
	});

	if (error) {
		notification.error({
			message: "Error",
			description: error.message
		});
	}

	const reports = data?.reports ?? [];

	const columns: ColumnProps<ScheduledReportDisplay>[] = [
		{
			title: "Recipients",
			dataIndex: "emails",
			key: "emails",
			width: "20%",
			render: (emails: string[], data: ScheduledReportDisplay) => (
				<Link to={`/console/reports/scheduled/${data._id}`}>{emails && emails?.join(", ")}</Link>
			)
		},
		{
			title: "Events",
			dataIndex: "events",
			key: "events",
			width: "40%",
			render: (events: ScheduledReportEvent[], data: ScheduledReportDisplay) => (
				<Link to={`/console/reports/scheduled/${data._id}`}>
					{events.length
						? events?.map((event: ScheduledReportEvent, idx: number) => (
								<div key={idx}>
									{event.name}
									{idx < events.length - 1 && ","}
								</div>
						  ))
						: "All events"}
				</Link>
			)
		},
		{
			title: "Frequency",
			dataIndex: "frequency",
			key: "frequency",
			render: (_: unknown, data: ScheduledReportDisplay) => {
				const { interval, hour, day } = data;
				const hourParsed = convertHour(hour);
				const dayParsed = day && convertDay(day);
				return (
					<Link to={`/console/reports/scheduled/${data._id}`}>
						{interval === "daily" && <>Daily at {hourParsed}</>}
						{interval === "weekly" && (
							<>
								Weekly on {dayParsed}s at {hourParsed}
							</>
						)}
					</Link>
				);
			}
		},
		{
			title: "Action",
			fixed: "right",
			key: "action",
			width: 100,
			render: (_: unknown, data: ScheduledReportDisplay) => (
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Link to={`/console/reports/scheduled/${data._id}`}>
						<IconButton
							icon="edit"
							ariaLabel="Edit"
							onClick={() => null}
							showTooltip
							tooltip="Edit"
							dataCy={`edit-${data._id}`}
						/>
					</Link>
					<IconButton
						icon="email"
						ariaLabel="Email"
						onClick={() => {
							setModalData({
								_id: data._id,
								eventIds: data.eventIds,
								interval: data.interval
							});
							setSendModalOpen(true);
						}}
						showTooltip
						tooltip="Send test email"
						dataCy={`send-test-email-${data._id}`}
					/>
					<Popconfirm
						placement="left"
						title="Are you sure you want to delete this schedule?"
						onConfirm={() => deleteMutation.mutate(data._id.toString())}
						okText="Yes"
						cancelText="No"
					>
						<IconButton icon="delete" ariaLabel="Delete" showTooltip tooltip="Delete" />
					</Popconfirm>
				</div>
			)
		}
	];
	return (
		<Page
			pageName="Scheduled reports"
			type="details"
			help={{
				link: HelpIds.scheduledReports,
				title: "Help - Scheduled reports"
			}}
		>
			<>
				<ScheduledReportsHeader />
				<ContentBlock>
					<p>
						<b>Active scheduled reports</b>
					</p>
					<Table
						columns={columns}
						dataSource={reports as any as ScheduledReportDisplay[]}
						rowKey={(r) => String(r._id)}
						locale={{ emptyText: "No results" }}
					/>
				</ContentBlock>
			</>
			<SendTestEmailModal
				isOpen={sendModalOpen}
				initialValues={{
					_id: modalData?._id,
					eventIds: modalData?.eventIds,
					interval: modalData?.interval,
					email: userEmail
				}}
				onClose={() => setSendModalOpen(false)}
			/>
		</Page>
	);
};

export { ListAllScheduledReports };
