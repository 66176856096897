import { Alert, Modal } from "@/ui/antd";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FileUploadField } from "../../components/Fields";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../../components/buttons/ButtonsBar";
import { H3, P } from "../../components/text/Text";
import { closeImport, upload } from "../../state/actions/followersActions";

let ImportModal = ({ organisers, followers, closeImport, handleSubmit, upload }) => {
	const submit = (values) => {
		upload(organisers.selected._id, values.csvFile);
	};

	const footer = followers.import.results ? (
		<ButtonsBar footer>
			<LegacyButton key="cancel" onClick={closeImport} ariaLabel="Cancel">
				Close
			</LegacyButton>
		</ButtonsBar>
	) : (
		<ButtonsBar footer>
			<LegacyButton key="cancel" onClick={closeImport} ariaLabel="Cancel">
				Cancel
			</LegacyButton>
			<LegacyButton
				key="submit"
				type="warning"
				loading={followers.import.loading}
				onClick={handleSubmit(submit)}
				ariaLabel="Import"
			>
				Import
			</LegacyButton>
		</ButtonsBar>
	);

	return (
		<Modal
			width={700}
			open={followers.import.open}
			title="Import followers"
			onCancel={closeImport}
			destroyOnClose
			footer={footer}
		>
			<LoadErrorView loading={followers.import.loading}>
				{followers.import.results ? (
					<>
						<H3>Results</H3>
						<P>
							{followers.import.results.added} follower{followers.import.results.added === 1 ? null : "s"} were added
						</P>
						{followers.import.results.failed ? (
							<>
								<P>
									{followers.import.results.failed} follower{followers.import.results.failed === 1 ? null : "s"} failed
									to be added
								</P>
								<CSVLink
									filename="followers-errors.csv"
									data={[["firstName", "lastName", "email", "error"]].concat(
										followers.import.results.errors.map((row) => [row.firstName, row.lastName, row.email, row.error])
									)}
								>
									Download Errors
								</CSVLink>
							</>
						) : null}
					</>
				) : (
					<form onSubmit={handleSubmit(upload)}>
						<Alert
							style={{ marginBottom: 12 }}
							message="How to upload"
							description={
								<div>
									Import a CSV file with the columns <b>firstName</b>, <b>lastName</b>, and <b>email</b>.<br />
									<CSVLink
										filename="followers-template.csv"
										data={[
											["firstName", "lastName", "email"],
											["James", "dean", "dean@smthing.com"],
											["Jane", "Doe", "jane@smthing.com"],
											["Yezzi", "Rapha", "rapha@cocococo.com"]
										]}
									>
										Download a template csv file
									</CSVLink>
								</div>
							}
							type="info"
							showIcon
						/>
						<Field name="csvFile" label="Upload a CSV" component={FileUploadField} />
					</form>
				)}
			</LoadErrorView>
		</Modal>
	);
};

const formName = "importFollowwers";
ImportModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		return;
	}
})(ImportModal);

export default connect(
	(state) => ({
		followers: state.followers,
		organisers: state.organisers
	}),
	{ closeImport, upload }
)(ImportModal);
