import axios from "./AxiosWrapper";

const AppMessageService = {
	async find(eventId) {
		const response = await axios.get(`scanning-messages/find/${eventId}`);
		return response.data;
	},
	async get(messageId) {
		const response = await axios.get(`scanning-messages/${messageId}`);
		return response.data;
	},
	async delete(messageId) {
		const response = await axios.delete(`scanning-messages/${messageId}`);
		return response.data;
	},
	async create(eventId, message) {
		const response = await axios.put(`scanning-messages/create/${eventId}`, message);
		return response.data;
	},
	async update(messageId, message) {
		const response = await axios.post(`scanning-messages/${messageId}`, message);
		return response.data;
	}
};

export default AppMessageService;
