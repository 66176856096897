import { Table, Tooltip } from "@/ui/antd";
import { ColumnType, TableRowSelection } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../components/AppBlocks";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import Page from "../components/Page";
import SearchRefinements from "../components/SearchRefinements";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import TableActions from "../components/table/TableActions";
import { deleteManyTerminals, deleteTerminal, getTerminals } from "../state/actions/boxOfficeActions";

interface BoxOfficeTerminal {
	_id: string;
	location?: string;
	terminalId: string;
	id: string;
	deviceId: string;
	email: string;
}

interface BoxOfficeTerminalProp {
	getTerminals: () => void;
	deleteTerminal: (_id: string) => void;
	deleteManyTerminals: (_ids: string[]) => void;
	terminals: {
		loading: boolean;
		error?: string;
		data?: BoxOfficeTerminal[];
	};
	terminalDeletion: {
		loading: boolean;
		error?: string;
		success: boolean;
	};
}

const BoxOfficeTerminals = ({
	terminals,
	terminalDeletion,
	getTerminals,
	deleteTerminal,
	deleteManyTerminals
}: BoxOfficeTerminalProp) => {
	const [selectedRowKeys, onRowSelectionChange] = useState([]);
	const columns: ColumnType<BoxOfficeTerminal>[] = [
		{
			title: "Terminal number",
			dataIndex: "terminalId",
			key: "terminalId",
			render: (_: unknown, { terminalId }: BoxOfficeTerminal) => {
				return <p>{terminalId}</p>;
			}
		},
		{
			title: "User email",
			dataIndex: "email",
			key: "email",
			render: (_: unknown, { email }: BoxOfficeTerminal) => {
				return <p>{email}</p>;
			}
		},
		{
			title: "Device ID",
			dataIndex: "deviceId",
			key: "deviceId",
			render: (_: unknown, { deviceId }: BoxOfficeTerminal) => {
				return <p>{deviceId}</p>;
			}
		},
		{
			title: "User ID",
			dataIndex: "userId",
			key: "userId",
			render: (_: unknown, { id }: BoxOfficeTerminal) => {
				return <p>{id}</p>;
			}
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			fixed: "right",
			render: (_: unknown, data: BoxOfficeTerminal) => {
				return (
					<TableActions>
						<IconButton
							icon="delete"
							onClick={() => deleteTerminal(data._id)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
							dataCy={`delete-${data._id}`}
							loading={terminalDeletion.loading}
							disabled={terminalDeletion.loading || terminals.loading}
							small={undefined}
							medium={undefined}
							tableIcon={undefined}
						/>
					</TableActions>
				);
			}
		}
	];

	useEffect(() => {
		if (terminalDeletion.success) {
			// fetch the updated list of terminals after a successful deletion
			getTerminals();
		}
	}, [terminalDeletion.success]);

	const tableRowSelection: TableRowSelection<BoxOfficeTerminal> = {
		fixed: true,
		selectedRowKeys,
		preserveSelectedRowKeys: true,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		onChange: onRowSelectionChange as (selectedRowKeys: string[] | number[]) => void
	};

	return (
		<Page pageName="Box Office Terminals">
			<LoadErrorView loading={terminals.loading} error={terminals.error} retryAction={getTerminals}>
				<ContentBlock>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore */}
					<SearchRefinements load={getTerminals} />
					{terminals.data && !terminals.loading ? (
						<>
							<Table
								columns={columns}
								dataSource={terminals.data}
								rowKey={(r) => r._id}
								locale={{ emptyText: "No results" }}
								scroll={{ x: "max-content" }}
								rowSelection={tableRowSelection}
							/>
							<Tooltip title={selectedRowKeys.length ? "Delete selected terminals" : "Select terminals to delete "}>
								<LegacyButton
									size="large"
									onClick={() => deleteManyTerminals(selectedRowKeys)}
									disabled={!selectedRowKeys.length || terminalDeletion.loading || terminals.loading}
									aria-label="delete multiple terminals"
								>
									Delete multiple
								</LegacyButton>
							</Tooltip>
						</>
					) : null}
				</ContentBlock>
			</LoadErrorView>
		</Page>
	);
};

interface BoxOfficeTerminalState {
	boxOffice: {
		terminals: {
			loading: boolean;
			error: string;
			page: number;
			data?: BoxOfficeTerminal[];
		};
		terminalDeletion: {
			loading: boolean;
			error: string;
			success: boolean;
		};
	};
}

export default connect(
	(state: BoxOfficeTerminalState) => {
		return {
			terminals: state.boxOffice.terminals,
			terminalDeletion: state.boxOffice.terminalDeletion
		};
	},
	{
		getTerminals,
		deleteTerminal,
		deleteManyTerminals
	}
)(BoxOfficeTerminals);
