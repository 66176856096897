import LoadErrorView from "@/components/LoadErrorView";
import Page from "@/components/Page";
import PageHeading from "@/components/PageHeading";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import NoResultsLarge from "@/components/noResults/NoResultsLarge";
import OrganiserCard from "@/organisers/OrganiserCard";
import OrganiserModal from "@/organisers/OrganiserModal";
import iconReference from "@/services/IconReference";
import { findOrganisers, openOrganiserCreateModal } from "@/state/actions/organiserActions";
import { updateSearch } from "@/state/actions/searchActions";
import { HelpIds } from "@/utils/Help";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";

const iconNoOrganisers = iconReference["no_organisers_yet"];

const pageSize = 12;

const Wrapper = ({ children, adminView }) => {
	return adminView ? <Page>{children}</Page> : <>{children}</>;
};

class Organisers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: "",
			displayOrganisers: []
		};
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.createNewOrganiser = this.createNewOrganiser.bind(this);
	}

	load(page = 1) {
		const { findOrganisers, search, adminView, userId } = this.props;
		findOrganisers(search.query, page, pageSize, userId, null, adminView);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	createNewOrganiser() {
		const { openOrganiserCreateModal } = this.props;
		openOrganiserCreateModal();
	}

	render() {
		const { user, adminView, userId } = this.props;
		const { loading, error, organisers, page, hadResults, create } = this.props.organisers;
		const { searchValue } = this.state;
		const organiserBlockWrapper = css({
			width: "100%",
			display: "flex",
			flexWrap: "wrap",
			gap: "8px"
		});
		return (
			<Wrapper adminView={adminView}>
				<OrganiserModal visible={create.open} />

				<Page type="details">
					<PageHeading
						title="Hosts"
						helpButton={{
							link: HelpIds.organisers,
							title: "Help - Hosts"
						}}
					/>

					<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
						<div
							className={css({
								display: "flex",
								alignItems: "flex-start",
								"@media(max-width: 644px)": {
									display: "block"
								}
							})}
						>
							<SearchRefinements load={this.load} />
							{adminView || userId ? null : (
								<LegacyButton
									style={{ marginLeft: 12 }}
									onClick={this.createNewOrganiser}
									type="action"
									ariaLabel="Create host"
								>
									Create host
								</LegacyButton>
							)}
						</div>

						{organisers?.length === 0 ? (
							<NoResultsLarge
								img={{
									src: iconNoOrganisers,
									alt: "No Hosts"
								}}
								title={
									searchValue !== "" || hadResults
										? "No event hosts found."
										: "You haven't created any event hosts yet."
								}
								actionTxt="Create host"
								action={this.createNewOrganiser}
							/>
						) : null}
						<div className={organiserBlockWrapper}>
							{organisers?.map((organiser) => (
								<OrganiserCard key={organiser._id} {...organiser} currentUserId={user.id} />
							))}
						</div>

						{organisers && organisers.length !== 0 ? (
							<div style={{ textAlign: "right", marginTop: 10 }}>
								<SimplePagination pageAction={this.loadPage} currentPage={page} />
							</div>
						) : null}
					</LoadErrorView>
				</Page>
			</Wrapper>
		);
	}
}

export default connect(
	(state) => ({
		organisers: state.organisers,
		search: state.search,
		user: state.auth.user
	}),
	{
		openOrganiserCreateModal,
		findOrganisers,
		updateSearch
	}
)(Organisers);
