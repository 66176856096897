import { css } from "@emotion/css";
import { FC, useEffect, useRef } from "react";
import LoadErrorView from "@/components/LoadErrorView";
import { getUser } from "@/state/actions/userActions";
import BankAccountOptions from "./BankAccountOptions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";

type BankAccountSelectionDropdownProps = {
	path: string;
	userId?: string;
	validate: ((value: any) => any)[];
	required: boolean;
	changeFieldValue: (field: string, value: string) => void;
	location: string;
};

export const BankAccountSelectionDropdown: FC<BankAccountSelectionDropdownProps> = ({
	path,
	userId,
	location,
	validate,
	required,
	changeFieldValue
}) => {
	const currentUser = useAppSelector((state) => state.currentUser);
	const dispatch = useAppDispatch();

	useEffect(() => {
		loadUser();
	}, []);

	const prevLengthRef = useRef();
	const prevCount = prevLengthRef.current ?? 0;
	useEffect(() => {
		const bankAccountsLength =
			currentUser?.user?.bankAccounts?.filter((account: any) => account.location === location).length || 0;
		if (!isNaN(prevCount)) {
			const diff = bankAccountsLength - prevCount;
			if (diff === 1) {
				changeFieldValue(
					`${path}userBankAccountId`,
					currentUser.user.bankAccounts.filter((account: any) => account.location === location)[bankAccountsLength - 1]
						._id
				);
			}
		}
		prevLengthRef.current = bankAccountsLength;
	}, [currentUser]);

	const loadUser = () => {
		if (userId) {
			dispatch(getUser(userId));
		}
	};

	const bankAccountsList =
		currentUser?.user?.bankAccounts?.filter((account: any) => account.location === location) || [];

	return (
		<LoadErrorView loading={currentUser.loading} error={currentUser.error as any} retryAction={loadUser}>
			<div>
				<div
					className={css({
						display: "flex",
						"@media(max-width: 900px)": {
							display: "block"
						}
					})}
				>
					<BankAccountOptions path={path} bankAccountsList={bankAccountsList} validate={validate} required={required} />
				</div>
			</div>
		</LoadErrorView>
	);
};
