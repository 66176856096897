import { css } from "@emotion/css";
import { Views } from "@hx/seating-map";
import { Component } from "react";
import { Circle, Icon, Line, Square } from "@/assets/seatingMap";
import ElementButton from "../../components/ElementButton";
const { SeatingConstruction } = Views;
const objects = [
	{ id: "circle", displayName: "Circle", image: Circle },
	{ id: "square", displayName: "Square", image: Square },
	{ id: "line", displayName: "Line", image: Line },
	{ id: "icon", displayName: "Icon", image: Icon }
];
const tableElementStyle = css({
	marginBottom: 16,
	"&:last-child": {
		marginBottom: 0
	},
	"div:hover": {
		background: "#4e4757",
		borderRadius: "3px"
	}
});

export default class ObjectSelection extends Component {
	constructor(props) {
		super(props);
		this.tableMap = SeatingConstruction.getInstance();
		this.state = {
			objectDragging: false,
			objectId: 0
		};
		this.addObject = this.addObject.bind(this);
	}

	addObject(objectId) {
		const object = objects[objectId];
		const type = object.id;
		const objectData = { objectType: type };
		this.tableMap.addObject(objectData);
	}

	render() {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					padding: "20px"
				}}
			>
				{objects.map(({ image, displayName }, i) => (
					<div className={tableElementStyle}>
						<ElementButton
							name={displayName}
							elementImage={image}
							onClick={() => this.addObject(i)}
							isSelected={false}
						/>
					</div>
				))}
			</div>
		);
	}
}
