import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode, forwardRef } from "react";

type StyledGridProps = {
	columnMax?: string;
	columnMin?: string;
	gap?: string;
	padding?: string;
	rowMax?: string;
	rowMin?: string;
};

export const StyledGrid = styled.div<StyledGridProps>`
	${({ columnMax, columnMin, gap, padding, rowMax, rowMin }) =>
		css`
			display: grid;
			gap: ${gap || "var(--spacing-lg)"};
			grid-auto-rows: minmax(${rowMin || "0"}, ${rowMax || "auto"});
			grid-template-columns: repeat(auto-fit, minmax(${columnMin || "220px"}, ${columnMax || "1fr"}));
			padding: ${padding || "0"};
			width: 100%;
		`}
`;

type GridProps = StyledGridProps & {
	children: ReactNode;
};

export const Grid = forwardRef<HTMLDivElement, GridProps>(({ children, ...props }, ref) => {
	return (
		<StyledGrid ref={ref} {...props}>
			{children}
		</StyledGrid>
	);
});
