import { Radio } from "@/ui/antd";
import { Component } from "react";
import { change } from "redux-form";
import Custom from "./Custom";
import Theme from "./Theme";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

/**
 * Tour page styling. You can either choose theme or custom setup
 *
 * @class TourStyling
 * @extends {Component}
 */
class TourStyling extends Component {
	constructor(props) {
		super(props);
		this.state = { themeType: "default" };
	}

	selectThemesOrCustom = (e) => {
		const { dispatch, formName } = this.props;

		this.setState({ themeType: e.target.value });
		if (e.target.value === "custom") {
			dispatch(change(formName, `theme.type`, "custom"));
		}
	};

	render() {
		const { dispatch, formName, selectedTheme, previewTheme } = this.props;
		const { themeType } = this.state;
		const options = [
			{
				label: "Themes",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}
		];

		return (
			<div style={{ height: "100%" }}>
				<p>
					Add some extra personality to your tour page. Choose one of our prebuilt themes or create your own custom
					style.
				</p>
				<RadioGroup defaultValue="default" buttonStyle="solid" onChange={this.selectThemesOrCustom}>
					{options.map((option) => (
						<RadioButton key={option.value} value={option.value}>
							{option.label}
						</RadioButton>
					))}
				</RadioGroup>
				{themeType === "custom" ? (
					<Custom dispatch={dispatch} formName={formName} previewTheme={previewTheme} />
				) : (
					<Theme dispatch={dispatch} formName={formName} selectedTheme={selectedTheme} />
				)}
			</div>
		);
	}
}

export default TourStyling;
