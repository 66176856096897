const SeatingRows = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0H24V24H0z" />
				<path
					id="ele-id-stroke"
					stroke="#67717E"
					strokeWidth="1.091"
					d="M3.818 15.75c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875S2 18.66 2 17.625c0-1.036.814-1.875 1.818-1.875zm5.455 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875zm5.454 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875zm5.455 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875zM3.818 10.125c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875S2 13.035 2 12c0-1.036.814-1.875 1.818-1.875zm5.455 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875S7.455 13.035 7.455 12c0-1.036.814-1.875 1.818-1.875zm5.454 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875S12.91 13.035 12.91 12c0-1.036.814-1.875 1.818-1.875zm5.455 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875zM3.818 4.5c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875S2 7.41 2 6.375C2 5.339 2.814 4.5 3.818 4.5zm5.455 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875zm5.454 0c1.004 0 1.818.84 1.818 1.875 0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875zm5.455 0C21.186 4.5 22 5.34 22 6.375c0 1.036-.814 1.875-1.818 1.875s-1.818-.84-1.818-1.875c0-1.036.814-1.875 1.818-1.875z"
				/>
			</g>
		</svg>
	);
};

export default SeatingRows;
