import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import Page from "@/components/Page";
import SearchRefinements from "@/components/SearchRefinements";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import LinkButton from "@/components/buttons/LinkButton";
import NoResults from "@/components/noResults/NoResults";
import NoResultsLarge from "@/components/noResults/NoResultsLarge";
import { P } from "@/components/text/Text";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import {
	deleteAllGlobalDiscountCodes,
	deleteGlobalDiscountCodes,
	editGlobalDiscountCode,
	getGlobalDiscountCodes
} from "@/state/actions/globalDiscountCodesActions";
import { Button } from "@/ui";
import { Dropdown } from "@/ui";
import { DropdownItem } from "@/ui";
import { Responsive } from "@/ui";
import { ConfigProvider, Flex, Modal, Popconfirm, Table, Tooltip } from "@/ui/antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

const confirm = Modal.confirm;

class GlobalDiscountCodes extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.debounceLoad = debounce(500, this.load);
		this.createCode = this.createCode.bind(this);
		this.confirmDeleteAll = this.confirmDeleteAll.bind(this);
		this.editGlobalDiscountCode = this.editGlobalDiscountCode.bind(this);
	}

	load(page = 1) {
		const { getGlobalDiscountCodes, currentUser } = this.props;
		getGlobalDiscountCodes(currentUser.user.id, page, true);
	}

	editGlobalDiscountCode(discountCode) {
		const { navigate, editGlobalDiscountCode } = this.props;
		editGlobalDiscountCode(discountCode);
		navigate(`/console/promote/global-discount-codes/${discountCode._id}/${discountCode.location}`);
	}

	createCode() {
		const { navigate, locations } = this.props;
		navigate(`/console/promote/global-discount-codes/new/${locations.overrideLocation}`);
	}

	confirmDeleteAll() {
		const { deleteAllGlobalDiscountCodes, currentUser } = this.props;
		confirm({
			title: "Delete all?",
			content: "Are you sure you wish to delete all your discount codes?",
			onOk() {
				deleteAllGlobalDiscountCodes(currentUser.user.id);
			},
			onCancel() {
				return;
			}
		});
	}

	render() {
		const { discountCodes, currentUser, deleteGlobalDiscountCodes } = this.props;

		const locationSelection = (
			<LocationSelection componentName="globalDiscountCodes" defaultLevel="user" onLocationChange={this.load} />
		);
		const noResults = () => <NoResultsLarge title="No discounts found" locationSelection={locationSelection} />;
		return (
			<Page pageName="Global discount codes" sibling={locationSelection}>
				<LoadErrorView error={discountCodes.getRequest.error} retryAction={this.save}>
					<ContentBlock>
						<CodeTable
							discountCodes={discountCodes}
							createCode={this.createCode}
							currentUser={currentUser}
							deleteDiscountCodes={deleteGlobalDiscountCodes}
							editDiscountCode={this.editGlobalDiscountCode}
							load={this.load}
							loading={discountCodes.getRequest.loading}
							confirmDeleteAll={this.confirmDeleteAll}
							searchUpdate={this.searchUpdate}
							debounceLoad={this.debounceLoad}
							noResults={noResults}
						/>
					</ContentBlock>
				</LoadErrorView>
			</Page>
		);
	}
}

class CodeTable extends Component {
	render() {
		const {
			discountCodes,
			createCode,
			currentUser,
			deleteDiscountCodes,
			editDiscountCode,
			load,
			confirmDeleteAll,
			loading,
			debounceLoad,
			noResults
		} = this.props;

		const columns = [
			{
				title: "Code",
				dataIndex: "code",
				key: "code",
				render: (value, discountCode) => {
					return (
						<LinkButton
							style={{ color: "#196097" }}
							onClick={() => {
								editDiscountCode(discountCode);
							}}
							ariaLabel={value}
						>
							{value}
						</LinkButton>
					);
				}
			},
			{
				title: "Discount",
				dataIndex: "discount",
				key: "discount",
				render: (value, discountCode) => {
					return discountCode.discountType === "percent" ? <span>{value}%</span> : <span>${value}</span>;
				}
			},
			{
				title: "Quantity",
				dataIndex: "quantity",
				key: "quantity"
			},
			{
				title: "Enabled",
				dataIndex: "enabled",
				key: "enabled",
				render: (value) => {
					return `${value}`;
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (...[, record]) => (
					<div>
						<div
							className={css({
								display: "block",
								"@media(max-width:600px)": {
									display: "none"
								}
							})}
						>
							<Flex>
								<Button iconOnly variant="text" onClick={() => editDiscountCode(record)} aria-label="Edit">
									<EditOutlined />
								</Button>
								<Popconfirm
									title="Are you sure you want to delete this discount code?"
									onConfirm={() => deleteDiscountCodes(currentUser.user.id, record)}
									okText="Yes"
									cancelText="No"
									placement="bottomLeft"
								>
									<Tooltip title="Delete">
										<Button iconOnly variant="text" aria-label="Delete">
											<DeleteOutlined />
										</Button>
									</Tooltip>
								</Popconfirm>
							</Flex>
						</div>
						<div
							className={css({
								display: "none",
								"@media(max-width:600px)": {
									display: "block"
								}
							})}
						>
							<Dropdown
								items={
									<>
										<DropdownItem aria-label="Edit" variant="text" onClick={() => editDiscountCode(record)}>
											Edit
										</DropdownItem>
										<Popconfirm
											title="Are you sure you want to delete this discount code?"
											onConfirm={() => deleteDiscountCodes(currentUser.user.id, record)}
											okText="Yes"
											cancelText="No"
										>
											<DropdownItem aria-label="Delete" variant="text">
												Delete
											</DropdownItem>
										</Popconfirm>
									</>
								}
							>
								<Button aria-label="Actions" iconOnly variant="text">
									<DotsThreeVertical size={18} weight="bold" />
								</Button>
							</Dropdown>
						</div>
					</div>
				)
			}
		];
		return (
			<div>
				<SearchRefinements style={{ marginBottom: 12 }} load={debounceLoad} />
				{discountCodes.codes && discountCodes.codes.length ? (
					<div
						className={css({
							float: "right"
						})}
					>
						<Responsive
							desktop={
								<Button ariaLabel="Delete all" onClick={() => confirmDeleteAll()} variant="danger" outlined>
									Delete all
								</Button>
							}
							mobile={
								<Dropdown
									title="Actions"
									items={
										<Button aria-label="Delete all" onClick={() => confirmDeleteAll()} outlined variant="danger">
											Delete all
										</Button>
									}
								>
									<Button iconOnly variant="tertiary">
										<DotsThreeVertical size={18} weight="bold" />
									</Button>
								</Dropdown>
							}
						/>
					</div>
				) : null}
				<P>
					Save time when building future events by creating Global discount codes! Global discount codes allow you to
					apply discounts across multiple events.
				</P>
				<If condition={(!discountCodes.codes || !discountCodes.codes.length) && !loading}>
					<Then>
						<NoResults noTitle message="" action={() => createCode()} actionTxt="Create Code" inverse />
					</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<ConfigProvider renderEmpty={noResults}>
									<Table
										columns={columns}
										scroll={{ x: 800 }}
										dataSource={discountCodes.codes}
										rowKey="_id"
										pagination={{
											showSizeChanger: false,
											defaultPageSize: 20,
											total: discountCodes.count,
											current: discountCodes.page,
											onChange: load
										}}
										loading={loading}
										locale={{
											filterConfirm: "Ok",
											filterReset: "Reset"
										}}
									/>
								</ConfigProvider>
								<LegacyButton
									onClick={() => createCode()}
									icon={{ left: true, name: "plus" }}
									type="action"
									style={{
										margin: "0 auto",
										"@media(max-width:600px)": {
											width: "100%",
											display: "flex",
											justifyContent: "center"
										}
									}}
									ariaLabel="Add Discount Code"
								>
									Add Code
								</LegacyButton>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search,
		discountCodes: state.discountCodes,
		currentUser: state.auth,
		userEvents: state.userEvents,
		locations: state.locations
	}),
	{
		getGlobalDiscountCodes,
		deleteGlobalDiscountCodes,
		editGlobalDiscountCode,
		deleteAllGlobalDiscountCodes
	}
)(withRouterHooks(GlobalDiscountCodes));
