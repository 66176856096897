import { FC } from "react";
import Reaptcha from "reaptcha";

type RecaptchaFieldProps = {
	onChange?: (value: string) => void;
};

export const RecaptchaField: FC<RecaptchaFieldProps> = ({ onChange }) => {
	return <Reaptcha sitekey="6LfhLHYUAAAAADPr8fQNSWpBfl9vmqxknJmDof0D" onVerify={onChange} />;
};
