const Rotate: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<g>
					<path
						d="M24 0H0v24h24V0zm0 0H0v24h24V0zM0 24h24V0H0v24z"
						transform="translate(-167 -330) translate(167 330)"
					/>
					<path
						fill={color}
						d="M17.66 8L12 2.35 6.34 8C4.78 9.56 4 11.64 4 13.64c0 2 .78 4.11 2.34 5.67 1.56 1.56 3.61 2.35 5.66 2.35 2.05 0 4.1-.79 5.66-2.35 1.56-1.56 2.34-3.67 2.34-5.67S19.22 9.56 17.66 8zM6 14c.01-2 .62-3.27 1.76-4.4L12 5.27l4.24 4.38C17.38 10.77 17.99 12 18 14H6z"
						transform="translate(-167 -330) translate(167 330)"
					/>
				</g>
			</g>
		</svg>
	);
};

export default Rotate;
