import SideMenu from "@/components/SideMenu";
import SideMenuBg from "@/components/SideMenuBg";
import MenuService from "@/services/menuService/MenuService";
import { FC } from "react";

export const AdminMenu: FC = () => {
	const menuItems = MenuService.getItemChildren("admin") ?? [];
	return (
		<SideMenuBg>
			<SideMenu menuItems={menuItems} />
		</SideMenuBg>
	);
};
