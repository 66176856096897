import axios from "./AxiosWrapper";

const TemplateService = {
	async getTicketTypeTemplates(userId) {
		const res = await axios.get(`global/templates/ticket-types/${userId}`);
		return res.data;
	},
	async getTicketTypeTemplate(userId, templateId) {
		const res = await axios.get(`global/templates/ticket-types/${userId}/${templateId}`);
		return res.data;
	},
	async updateTicketTypeTemplate(userId, templateId, ticketTypeTemplateData) {
		const res = await axios.post(`global/templates/ticket-types/${userId}/${templateId}`, ticketTypeTemplateData);
		return res.data;
	},
	async saveTicketTypeTemplate(userId, ticketTypeTemplateData) {
		const res = await axios.put(`global/templates/ticket-types/${userId}`, ticketTypeTemplateData);
		return res.data;
	},
	async removeTicketTypeTemplate(userId, templateId) {
		const res = await axios.delete(`global/templates/ticket-types/${userId}/${templateId}`);
		return res.data;
	},

	async getAdditionalQuestionsTemplates(userId) {
		const res = await axios.get(`global/templates/additional-questions/${userId}`);
		return res.data;
	},
	async getAdditionalQuestionsTemplate(userId, templateId) {
		const res = await axios.get(`global/templates/additional-questions/${userId}/${templateId}`);
		return res.data;
	},
	async updateAdditionalQuestionsTemplate(userId, templateId, additionalQuestionsTemplateData) {
		const res = await axios.post(
			`global/templates/additional-questions/${userId}/${templateId}`,
			additionalQuestionsTemplateData
		);
		return res.data;
	},
	async saveAdditionalQuestionsTemplate(userId, additionalQuestionsTemplateData) {
		const res = await axios.put(`global/templates/additional-questions/${userId}`, additionalQuestionsTemplateData);
		return res.data;
	},
	async removeAdditionalQuestionsTemplate(userId, templateId) {
		const res = await axios.delete(`global/templates/additional-questions/${userId}/${templateId}`);
		return res.data;
	},
	async getStylingTemplates(userId) {
		const res = await axios.get(`global/templates/styling/${userId}`);
		return res.data;
	},
	async getStylingTemplate(userId, templateId) {
		const res = await axios.get(`global/templates/styling/${userId}/${templateId}`);
		return res.data;
	},
	async updateStylingTemplate(userId, templateId, stylingTemplateData) {
		const res = await axios.post(`global/templates/styling/${userId}/${templateId}`, stylingTemplateData);
		return res.data;
	},
	async saveStylingTemplate(userId, stylingTemplateData) {
		const res = await axios.put(`global/templates/styling/${userId}`, stylingTemplateData);
		return res.data;
	},
	async removeStylingTemplate(userId, templateId) {
		const res = await axios.delete(`global/templates/styling/${userId}/${templateId}`);
		return res.data;
	}
};

export default TemplateService;
