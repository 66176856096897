import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import TicketsSoldCard from "./TicketsSoldCard";
import Widget from "./Widget";

class TicketsSoldList extends Component {
	render() {
		const { metrics, metricState } = this.props;
		if (!metrics) {
			return null;
		}
		const ticketsList = metrics.map((unit, i) => <TicketsSoldCard key={i} {...unit} />);
		const wrapper = css({
			maxHeight: 400, //showing maximum 3 rows
			overflow: "auto"
		});
		return (
			<Widget title="Live event ticket sales" excludeTimeScale metricState={metricState} {...this.props}>
				<div className={wrapper}>{ticketsList}</div>
			</Widget>
		);
	}
}

export default connect((state, ownProps) => {
	return {
		metrics: state[ownProps.metricState].ticketsSold
	};
})(TicketsSoldList);
