import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import Presenter from "./Presenter";

const { confirm } = Modal;

class PresentersList extends Component {
	constructor(props) {
		super(props);
		this.state = { elementPickerOpen: false };
	}

	addPresenter = () => {
		const { fields } = this.props;
		fields.push({});
	};

	move = (fromIndex, toIndex) => {
		const { fields, presenterList, changeFieldValue } = this.props;
		if (toIndex < 0 || toIndex > fields.length - 1) {
			return false;
		}
		const fromData = presenterList[fromIndex];
		const toData = presenterList[toIndex];
		changeFieldValue(`${fields.name}[${toIndex}]`, { ...fromData });
		changeFieldValue(`${fields.name}[${fromIndex}]`, { ...toData });
	};

	deletePresenter = (presenter, index) => {
		const { fields } = this.props;
		const name = presenter.name ? presenter.name : "this presenter";
		confirm({
			title: `Are you sure you would like to delete ${name}`,
			onOk: () => {
				fields.remove(index);
			},
			onCancel() {
				null;
			}
		});
	};

	render() {
		const { fields, presenterList, changeFieldValue } = this.props;
		return (
			<div
				style={{
					border: "solid 1px #dedede",
					background: "#f9f9fa",
					padding: 12
				}}
			>
				{fields.map((fieldPath, index) => {
					const presenter = presenterList[index];
					const props = {
						key: presenter._id,
						fieldPath,
						presenter,
						index,
						move: this.move,
						deleteElement: this.deletePresenter,
						totalElements: fields.length,
						changeFieldValue
					};
					return <Presenter {...props} />;
				})}
				<ButtonsBar label="" backgroundColor>
					<LegacyButton
						icon={{ left: true, name: "plus" }}
						type="action"
						onClick={this.addPresenter}
						ariaLabel="Add Element"
					>
						Add Presenter
					</LegacyButton>
				</ButtonsBar>
			</div>
		);
	}
}

const selector = formValueSelector("virtualEventsHub");

export default connect((state, ownProps) => {
	return { presenterList: selector(state, ownProps.fields.name) };
}, {})(PresentersList);
