import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import EventStatus from "../EventStatus";
import OrderStatus from "../OrderStatus";

const card = css({
	background: "#fff",
	padding: "4px 12px",
	width: "100%",
	maxWidth: 800,
	color: "rgb(50,50,50)",
	display: "flex",
	justifyContent: "space-between",
	"&:focus": {
		backgroundColor: "#f9f9fa"
	},
	"&:hover": {
		color: "rgb(50,50,50)",
		backgroundColor: "#f9f9fa"
	}
});

const first = css({
	flex: 1,
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
});

const middle = css({
	flex: 1,
	padding: "0 6px",
	color: "grey",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
});

const getCardDisplay = (data) => {
	switch (data.type) {
		case "Event":
			return (
				<>
					<div className={first}>
						<b>{data.name}</b>
					</div>
					<div>
						<EventStatus isArchived={data.isArchived} published={data.published} endDate={data.endDate} />
					</div>
				</>
			);
		case "User":
			return (
				<>
					<div className={first}>
						<b>
							{data.firstName} {data.lastName}
						</b>
					</div>
					<div>{data.email}</div>
				</>
			);
		case "Order":
			return (
				<>
					<div className={first}>
						<b>{data.orderName}</b> {data.eventName}
					</div>
					<div className={middle}>{data.email}</div>
					<div>
						<OrderStatus status={data.status} />
					</div>
				</>
			);
		case "Attendee":
			return (
				<>
					<div className={first}>
						<b>{data.orderName}</b> {data.eventName}
					</div>
					<div className={middle}>{data.ticketTypeName}</div>
					<div>
						<OrderStatus status={data.status} />
					</div>
				</>
			);
		case "Host":
			return <>{data.name}</>;
	}
};

const getLink = (data) => {
	switch (data.type) {
		case "Event":
			return `/console/my-events/${data._id}`;
		case "User":
			return `/console/admin/users/${data.id}`;
		case "Order":
			return `/console/my-events/${data.eventId}/orders/${data._id}`;
		case "Attendee":
			return `/console/my-events/${data.eventId}/orders/${data.orderId}`;
		case "Host":
			return `/console/account/hosts/${data._id}/details`;
	}
};

const Card = ({ data }) => {
	return (
		<Link className={card} to={getLink(data)}>
			{getCardDisplay(data)}
		</Link>
	);
};

export default Card;
