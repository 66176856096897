import { LoadingSpinner } from "@/components/AppBlocks";
import { FC, ReactNode, useEffect, useState } from "react";

type LoaderProps = {
	children?: ReactNode;
	isLoading: boolean;
	minDuration?: number;
};

/**
 * Loader component that shows a spinner while isLoading is true. After minDuration, it will show the children.
 * @param children The children to render when isLoading is false.
 * @param isLoading Whether to show the spinner or the children.
 * @param minDuration The minimum duration to show the spinner for. Defaults to 300ms.
 * @returns The loader component.
 *
 * @example
 * ```tsx
 * <Loader isLoading={isLoading}>
 * 	<SomeComponent />
 * </Loader>
 * ```
 */
export const Loader: FC<LoaderProps> = ({ children, isLoading, minDuration = 300 }) => {
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		let timer: NodeJS.Timeout;

		if (isLoading) {
			setShowSpinner(true);
		} else {
			timer = setTimeout(() => {
				setShowSpinner(false);
			}, minDuration);
		}

		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [isLoading]);

	return showSpinner ? <LoadingSpinner /> : <>{children}</>;
};
