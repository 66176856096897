export const isSpacingPreset = (value: string | SpacingPreset | undefined): value is SpacingPreset =>
	Object.keys(spacingTokens).includes(value as SpacingPreset);

export const spacingTokens = {
	xxs: "--spacing-xxs",
	xs: "--spacing-xs",
	sm: "--spacing-sm",
	md: "--spacing-md",
	lg: "--spacing-lg",
	xl: "--spacing-xl",
	xxl: "--spacing-xxl"
} as const;

export type SpacingPreset = keyof typeof spacingTokens;

export const styleTokens = {
	"border-color1": "#1b1b1b24",
	"border-color1-hover": "#1b1b1b44",
	border1: "1px solid var(--border-color1)",
	"border1-hover": "1px solid var(--border-color1-hover)",

	"border-color2": "#ededed",
	"border-color2-hover": "#e0e0e0",
	border2: "1px solid var(--border-color2)",
	"border2-hover": "1px solid var(--border-color2-hover)",

	"border-transition": "border 0.2s ease-in-out",

	"primary-color": "#ffb18f",
	"rounded-xs": "2px",
	"rounded-sm": "4px",
	"rounded-md": "8px",
	"rounded-lg": "12px",
	"spacing-xxs": "2px",
	"spacing-xs": "4px",
	"spacing-sm": "8px",
	"spacing-md": "12px",
	"spacing-lg": "16px",
	"spacing-xl": "20px",
	"spacing-xxl": "24px",
	surface1: "#fff",
	surface2: "#f9f9fa",
	surface3: "#f4f4f4",
	"on-surface1": "#333333",
	"shadow-md": "0px 4px 12px rgba(0, 0, 0, 0.15)",
	info: "#1890FF",
	"info-subtle": "#ECF2FF",
	success: "#008257",
	"success-subtle": "#edf8f2",
	warning: "#b97050",
	"warning-subtle": "#FBE9A8",
	error: "#bc2f35",
	"error-subtle": "#ffeceb"
};

export const GlobalStyles = () => (
	<style>
		{`
		:root {
			${Object.entries(styleTokens)
				.map(([key, value]) => `--${key}: ${value};`)
				.join("\n")}
		}
	`}
	</style>
);
