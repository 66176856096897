import { CSSInterpolation, css, keyframes } from "@emotion/css";
import hexToRgba from "hex-rgba";
import { Component, FC } from "react";
import arrowDownCleanIcon from "@/assets/ic_arrow_down_clean.svg";
import iconReference from "@/services/IconReference";

export type ButtonProps = {
	ariaLabel?: string;
	children: React.ReactNode;
	dataCy?: string;
	disabled?: boolean;
	flavor?: "default" | "minimal";
	form?: string;
	hasSecondaryClick?: boolean;
	htmlType?: "button" | "submit" | "reset";
	icon?: {
		name: string;
		left?: boolean;
		right?: boolean;
	};
	id?: string;
	innerRef?: React.RefObject<HTMLButtonElement>;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onSecondaryClick?: (e: React.MouseEvent<HTMLElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
	style?: React.CSSProperties;
	type?: "primary" | "secondary" | "action" | "default" | "launch" | "warning" | "import";
	size?: "default" | "small" | "large" | "full";
};

type ButtonType = "primary" | "secondary" | "action" | "default" | "launch" | "warning" | "import" | "disabled";

type ButtonSize = "default" | "small" | "large" | "full";

type ButtonFlavor = "default" | "minimal";

const colors = {
	white: "#fff",
	dark: "#353337",
	primary: "#ffb18f",
	primaryHover: "#F9A783",
	action: "#353337",
	actionHover: "#F9A783",
	primaryText: "#353337",
	default: "#67717E",
	defaultText: "#323232",
	defaultHover: "#d9d4df",
	iconGrey: "#8e99a8",
	primaryGrey: "#d2dff1",
	transparent: "transparent",
	secondary: "#ffb18f",
	secondaryHover: "#F9A783",
	launch: "#57cfc7",
	launchHover: "#ccebe9",
	waring: "#c0212c",
	warningHover: "#ebb9bc",
	error: "#c0212c",
	importText: "#5a626c",
	import: "#fafafa",
	importHover: "#f0f0f0",
	disabled: "#ebebeb",
	disabledText: "#838e9c"
};

export class LegacyButton extends Component<ButtonProps> {
	animationClick: string | null = null;
	computedStyle: string | undefined = undefined;
	iconSrc: NodeRequire | null = null;

	constructor(props: ButtonProps) {
		super(props);
		const { type = "default", flavor = "default", disabled, style = {}, icon, size = "default" } = this.props;
		this.state = {
			animating: false
		};
		this.computedStyle = disabled
			? css(this.constructStyles("disabled", "default", style, false, size))
			: css(this.constructStyles(type, flavor, style, false, size));

		if (icon && icon.name) {
			this.iconSrc = iconReference[icon.name];
		}
	}

	handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		const { disabled, onClick } = this.props;
		if (onClick && !disabled) {
			onClick(e);
		}
	};

	handleSecondaryClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		const { disabled, onSecondaryClick } = this.props;
		if (onSecondaryClick && !disabled) {
			onSecondaryClick(e);
		}
	};

	componentWillReceiveProps(newProps: ButtonProps) {
		if (newProps !== this.props) {
			const { type = "default", flavor = "default", disabled, style = {}, size = "default" } = newProps;

			const s1 = this.constructStyles("disabled", "default", style, false, size);
			const s2 = this.constructStyles(type, flavor, style, false, size);

			this.computedStyle = disabled ? css(s1) : css(s2);
		}
	}

	svgColorChange = (color: string) => {
		return {
			"path[fill]": {
				fill: color
			},
			"path[stroke]": {
				stroke: color
			},
			"polygon[fill]": {
				fill: color
			}
		};
	};

	flavorCompose = (color: string, flavor: "default" | "minimal") => {
		const flavors = {
			default: {
				border: `1px solid ${color}`
			},
			minimal: {
				border: `none`
			}
		};
		return flavors[flavor];
	};

	animationCompose = (color: string) => {
		this.animationClick = keyframes({
			"0%": {
				boxShadow: `0px 0px 0px 0
        px ${hexToRgba(color, 40)}`
			},
			"25%": {
				boxShadow: `0px 0px 0px 6px ${hexToRgba(color, 40)}`
			},
			"100%": {
				boxShadow: `0px 0px 0px 6px ${hexToRgba(color, 1)}`
			}
		});
	};

	constructSize = (size: ButtonSize, type: ButtonType) => {
		type SizeObj = {
			minHeight?: number | string;
			padding?: string;
			width?: string;
			"@media(maxWidth:600px)"?: {
				minHeight: number | string;
			};
		};

		const sizeObj: SizeObj = {};
		switch (size) {
			case "large":
				sizeObj.minHeight = 40;
				if (type === "primary") {
					sizeObj.padding = "0px 32px";
				}
				sizeObj["@media(maxWidth:600px)"] = {
					minHeight: 40
				};
				break;
			case "small":
				sizeObj.minHeight = 32;
				break;
			case "full":
				sizeObj.minHeight = "none";
				sizeObj.width = "100%";
				break;
			default:
				sizeObj.minHeight = 36;
				break;
		}
		return sizeObj;
	};

	constructStyles = (
		type: ButtonType,
		flavor: ButtonFlavor,
		style: React.CSSProperties,
		animating: boolean,
		size: ButtonSize
	): CSSInterpolation => {
		switch (type) {
			case "primary":
				this.animationCompose(colors.primaryHover);
				break;
			case "secondary":
				this.animationCompose(colors.secondaryHover);
				break;
			case "action":
				this.animationCompose(colors.primaryHover);
				break;
			case "launch":
				this.animationCompose(colors.launchHover);
				break;
			case "warning":
				this.animationCompose(colors.error);
				break;
			case "import":
				this.animationCompose(colors.import);
				break;
			default:
				this.animationCompose(colors.primaryGrey);
				break;
		}
		const constructedSize = this.constructSize(size, type);
		const animation = animating ? `${this.animationClick} 0.3s linear` : "none";
		const standard = {
			zIndex: 99,
			minHeight: 36,
			borderRadius: 4,
			fontSize: 14,
			fontWeight: 600,
			padding: "0px 16px",
			display: "flex",
			cursor: "pointer",
			justifyContent: "center",
			alignItems: "center",
			position: "relative",
			transition: "background 0.2s, border 0.4s",
			animation,
			"&:focus": {
				outline: "none",
				boxShadow: `0 0 0 2px ${colors.action}`
			},
			"& .secondaryActionsIcon path[fill]": {
				fill: "currentColor"
			},
			"& .secondaryActionsIcon path": {
				pointerEvents: "none"
			},
			...style,
			...constructedSize
		};
		const types = {
			primary: {
				...standard,
				background: colors.primary,
				color: colors.dark,
				...this.svgColorChange(colors.white),
				...this.flavorCompose(colors.primary, flavor),
				"&:hover": {
					color: colors.dark,
					background: colors.primaryHover,
					...this.svgColorChange(colors.dark),
					...this.flavorCompose(colors.primaryHover, flavor)
				}
			},
			secondary: {
				...standard,
				background: colors.secondary,
				color: colors.dark,
				...this.svgColorChange(colors.white),
				...this.flavorCompose(colors.secondary, flavor),
				"&:hover": {
					color: colors.dark,
					background: colors.secondaryHover,
					...this.svgColorChange(colors.white),
					...this.flavorCompose(colors.secondaryHover, flavor)
				}
			},
			action: {
				...standard,
				background: "transparent",
				color: colors.action,
				...this.svgColorChange(colors.action),
				...this.flavorCompose(colors.action, flavor),
				"&:hover": {
					color: colors.dark,
					background: colors.actionHover,
					...this.svgColorChange(colors.dark),
					...this.flavorCompose(colors.actionHover, flavor)
				}
			},
			default: {
				...standard,
				background: "transparent",
				color: colors.defaultText,
				...this.svgColorChange(colors.default),
				...this.flavorCompose(colors.iconGrey, flavor),
				"&:hover": {
					color: colors.defaultText,
					background: colors.defaultHover,
					...this.svgColorChange(colors.default),
					...this.flavorCompose(colors.transparent, flavor)
				}
			},
			launch: {
				...standard,
				background: colors.launch,
				color: colors.white,
				...this.svgColorChange(colors.launch),
				...this.flavorCompose(colors.launch, flavor),
				"&:hover": {
					color: colors.primaryText,
					background: colors.launchHover,
					...this.svgColorChange(colors.white),
					...this.flavorCompose(colors.launchHover, flavor)
				}
			},
			warning: {
				...standard,
				background: "transparent",
				color: colors.waring,
				...this.svgColorChange(colors.waring),
				...this.flavorCompose(colors.error, flavor),
				"&:hover": {
					color: colors.defaultText,
					background: colors.warningHover,
					...this.svgColorChange(colors.default),
					...this.flavorCompose(colors.error, flavor)
				}
			},
			import: {
				...standard,
				background: colors.import,
				color: colors.importText,
				...this.svgColorChange(colors.importText),
				...this.flavorCompose(colors.transparent, flavor),
				"&:hover": {
					color: colors.importText,
					background: colors.importHover,
					...this.svgColorChange(colors.importText),
					...this.flavorCompose(colors.importHover, flavor)
				}
			},
			disabled: {
				...standard,
				background: colors.disabled,
				color: colors.disabledText,
				...this.svgColorChange(colors.disabledText),
				...this.flavorCompose(colors.transparent, flavor)
			}
		};

		return types[type] as CSSInterpolation;
	};

	render() {
		const {
			children,
			icon,
			id,
			ariaLabel,
			dataCy,
			htmlType = "button",
			form,
			onMouseEnter,
			onMouseLeave,
			onFocus,
			disabled,
			hasSecondaryClick = false
		} = this.props;

		const iconDisplay = icon ? (
			<img
				src={this.iconSrc as unknown as string}
				style={{
					maxHeight: 24,
					marginRight: icon.left && children ? 6 : 0,
					marginLeft: icon.right && children ? 6 : 0
				}}
				aria-hidden="true"
			/>
		) : null;

		const secondaryActionsIcon = (
			<img
				className="secondaryActionsIcon"
				onClick={this.handleSecondaryClick}
				src={arrowDownCleanIcon}
				style={{
					marginLeft: 8,
					marginRight: -8,
					borderLeft: "1px solid currentColor",
					padding: "8px 0 8px 8px",
					width: 24,
					height: 24
				}}
				aria-hidden="true"
			/>
		);

		type ConditionalWrapperProps = {
			condition: boolean;
			wrapper: (children: React.ReactNode) => React.ReactNode;
			children: React.ReactNode;
		};

		const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
			(condition ? wrapper(children) : children) as JSX.Element;

		return (
			<button
				aria-label={ariaLabel}
				className={this.computedStyle}
				data-cy={dataCy}
				disabled={disabled}
				form={form}
				id={id}
				onClick={this.handleClick}
				onFocus={onFocus}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				ref={this.props.innerRef}
				type={htmlType}
			>
				<ConditionalWrapper condition={hasSecondaryClick} wrapper={(children) => <span>{children}</span>}>
					{icon && icon.left ? iconDisplay : null}
					{children}
					{icon && icon.right ? iconDisplay : null}
				</ConditionalWrapper>

				{hasSecondaryClick ? secondaryActionsIcon : null}
			</button>
		);
	}
}
