import { Alert, Button } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { getControlReport, resetControlReport } from "@/state/actions/adminReports/financeActions";
import DatesSelector from "../../DatesSelector";
import DayEndsAt from "../DayEndsAt";

class ControlReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getControlReport, dayEndsAt } = this.props;
		getControlReport(values.startDate, values.endDate, dayEndsAt);
	}

	retry() {
		const { resetControlReport } = this.props;
		resetControlReport();
	}

	runLoad = () => {
		const { startDate, endDate, sortOn } = this.props;
		this.load({ startDate, endDate, sortOn });
	};

	render() {
		const { touch, handleSubmit, controlReport, startDate, endDate, dayEndsAt } = this.props;

		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Control Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<div>
					<LoadErrorView loading={controlReport.loading} error={controlReport.error} retryAction={this.retry}>
						<ReportFilters>
							<Alert
								message="Only count orders where the money was processed into our accounts"
								type="info"
								showIcon
								style={{ marginBottom: 12 }}
							/>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div style={{ display: "flex" }}>
									<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
									<DayEndsAt />
								</div>
							</div>
							<Button
								type="primary"
								onClick={this.runLoad}
								disabled={!startDate || !endDate || !dayEndsAt}
								ariaLabel="Load"
								style={{ borderRadius: 4 }}
							>
								Request
							</Button>
						</ReportFilters>
						<ReportContent>
							{controlReport.data ? (
								<div>
									<table style={{ marginBottom: 26, fontSize: 16 }} className="full-width-table">
										<tr>
											<th>Opening balance</th>
											<td>${controlReport.data.openingBalance}</td>
										</tr>
										<tr>
											<th>Plus Gross Sales</th>
											<td>${controlReport.data.grossSales}</td>
										</tr>
										<tr>
											<th>Less Booking Fees</th>
											<td>${controlReport.data.bookingFees}</td>
										</tr>
										<tr>
											<th>Plus Net Rebates</th>
											<td>${controlReport.data.netRebates}</td>
										</tr>

										<tr>
											<th>Less Net Refunds</th>
											<td>${controlReport.data.netRefunds}</td>
										</tr>
										<tr>
											<th>Less Net Invoiced Booking Fees</th>
											<td>${controlReport.data.netInvoicedBookingFees}</td>
										</tr>
										<tr>
											<th>Less Payouts</th>
											<td>${controlReport.data.payouts}</td>
										</tr>
										<tr>
											<th>Less Adjustments</th>
											<td>${controlReport.data.adjustments}</td>
										</tr>
										<tr>
											<th>Closing balance</th>
											<td>${controlReport.data.closingBalance}</td>
										</tr>
									</table>
								</div>
							) : null}
						</ReportContent>
					</LoadErrorView>
				</div>
			</form>
		);
	}
}
const formName = "ControlReport";
const selector = formValueSelector(formName);
ControlReport = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(ControlReport);

export default connect(
	(state) => ({
		initialValues: {
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		controlReport: state.adminReports.finance.controlReport,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{ getControlReport, resetControlReport }
)(ControlReport);
