import TagService from "../../services/TagService";

export const SAVE_TAG_REQUEST = "SAVE_TAG_REQUEST";
export const SAVE_TAG_SUCCESS = "SAVE_TAG_SUCCESS";
export const SAVE_TAG_ERROR = "SAVE_TAG_ERROR";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_ERROR = "DELETE_TAG_ERROR";

export const FIND_TAG_REQUEST = "FIND_TAG_REQUEST";
export const FIND_TAG_SUCCESS = "FIND_TAG_SUCCESS";
export const FIND_TAG_ERROR = "FIND_TAG_ERROR";

export const FIND_TAGS_REQUEST = "FIND_TAGS_REQUEST";
export const FIND_TAGS_SUCCESS = "FIND_TAGS_SUCCESS";
export const FIND_TAGS_ERROR = "FIND_TAGS_ERROR";

export const CLEAR_TAG = "CLEAR_TAG";

export const saveTag = (tagData) => {
	return (dispatch) => {
		dispatch({ type: SAVE_TAG_REQUEST, tag: tagData });
		TagService.save(tagData)
			.then((tag) => {
				dispatch({
					type: SAVE_TAG_SUCCESS,
					tag
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_TAG_ERROR, error: err });
			});
	};
};

export const findTags = (status) => {
	return (dispatch) => {
		dispatch({ type: FIND_TAGS_REQUEST });
		TagService.findTags(status)
			.then((tags) => {
				dispatch({
					type: FIND_TAGS_SUCCESS,
					tags
				});
			})
			.catch((err) => {
				dispatch({ type: FIND_TAGS_ERROR, error: err });
			});
	};
};

export const findTag = (id) => {
	return (dispatch) => {
		dispatch({ type: FIND_TAG_REQUEST });
		TagService.findTag(id)
			.then((tag) => {
				dispatch({
					type: FIND_TAG_SUCCESS,
					tag
				});
			})
			.catch((err) => {
				dispatch({ type: FIND_TAG_ERROR, error: err });
			});
	};
};

export const deleteTag = (id) => {
	return (dispatch) => {
		dispatch({ type: DELETE_TAG_REQUEST });
		TagService.delete(id)
			.then((tags) => {
				dispatch({
					type: DELETE_TAG_SUCCESS,
					tags
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_TAG_ERROR, error: err });
			});
	};
};

export const clearTag = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_TAG });
	};
};
