import { Component } from "react";
import { connect } from "react-redux";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { LegacyInputField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import TicketRows from "@/events/event/tickets/ticketTypes/tickets/TicketsTable";
import { getEvent } from "@/state/actions/eventActions";
import {
	getTicketTypeTemplate,
	saveTicketTypeTemplate,
	updateTicketTypeTemplate
} from "@/state/actions/templateActions";
import { required } from "@/utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class Tickets extends Component {
	nameRequired = required("A template name is required");

	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.cancel = this.cancel.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	componentWillMount() {
		const { location, getEvent, params, getTicketTypeTemplate, currentUser } = this.props;
		const id = params?.id;
		const isFromEvent = location.state?.isFromEvent;
		const isExisting = location.state?.isExisting;
		if (isFromEvent) {
			getEvent(id);
		}
		if (isExisting) {
			getTicketTypeTemplate(currentUser.user.id, id);
		}
	}

	componentDidMount() {
		null;
	}

	componentDidUpdate() {
		null;
	}

	save(val) {
		const { saveTicketTypeTemplate, updateTicketTypeTemplate, currentUser, location, params, navigate } = this.props;
		const { isExisting } = location.state || {};
		const id = params?.id;
		if (isExisting) {
			updateTicketTypeTemplate(currentUser.user.id, id, val, navigate);
		} else {
			saveTicketTypeTemplate(currentUser.user.id, val, navigate);
		}
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	cancel() {
		const { navigate } = this.props;
		navigate("/console/account/advanced/templates/ticket-type-templates");
	}

	render() {
		const { touch, timezone, check, currentEvent, handleSubmit, currentTemplates, ticketTypes, dispatch } = this.props;
		const { get } = currentEvent;
		const loading = get.loading || currentTemplates.loading;
		const isFreeOnly = currentEvent.event ? currentEvent.event.isFreeOnly : false;
		const error = get.error || currentTemplates.error;
		return (
			<div>
				<H1>Ticket Type Template</H1>
				<form onSubmit={handleSubmit(this.save)}>
					<ContentBlock>
						<LoadErrorView loading={loading} error={error}>
							{check && (!ticketTypes || !ticketTypes.length) ? (
								<div className="has-error">
									<div className="ant-form-explain">Please create at least one ticket</div>
								</div>
							) : null}
							<Field
								name="name"
								label="Template Name"
								autocomplete="nope"
								description="Name your template so you can find it easier"
								component={LegacyInputField}
								style={{ width: "50%" }}
								validate={this.nameRequired}
							/>

							<FieldArray
								name="ticketTypes"
								component={TicketRows}
								ticketTypes={ticketTypes}
								isFreeOnly={isFreeOnly}
								touch={touch}
								changeFieldValue={this.changeFieldValue}
								timezone={timezone}
								formName={formName}
								dispatch={dispatch}
							/>
						</LoadErrorView>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
						<LegacyButton
							onClick={this.cancel}
							style={{
								float: "left"
							}}
							ariaLabel="Cancel"
							size="large"
						>
							Cancel
						</LegacyButton>
					</FormBar>
				</form>
			</div>
		);
	}
}

const formName = "templateTicketType";

// Decorate with redux-form

Tickets = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Tickets);

Tickets = connect((state, ownProps) => {
	let initialValues = {
		name: "",
		ticketTypes: []
	};
	const { location } = ownProps;
	const isExisting = location.state?.isExisting;
	const isFromEvent = location.state?.isFromEvent;
	if (isFromEvent && state.currentEvent && state.currentEvent.event) {
		initialValues.ticketTypes = state.currentEvent.event.ticketTypes;
	}
	if (isExisting && state.templates && state.templates.selectedTemplate) {
		initialValues = state.templates.selectedTemplate;
	}
	return {
		initialValues,
		enableReinitialize: true
	};
})(Tickets);

const selector = formValueSelector(formName);

export default connect(
	(state) => ({
		currentUser: state.auth,
		currentEvent: state.currentEvent,
		ticketTypes: selector(state, "ticketTypes"),
		currentTemplates: state.templates
	}),
	{
		getEvent,
		saveTicketTypeTemplate,
		updateTicketTypeTemplate,
		getTicketTypeTemplate
	}
)(withRouterHooks(Tickets));
