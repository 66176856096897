import missingSeatingMap from "@/assets/seating-map-image-missing.png";
import { Label, P } from "@/components/text/Text";

const MapCard = ({ thumbnail, name, totalCapacity, capacity, ticketTypeSet, size = "default" }) => {
	let width,
		height = 150;
	if (size === "small") {
		width = height = 100;
	}
	const LabelItem = ({ label, value }) => (
		<P style={{ display: "flex", marginBottom: 0 }}>
			<Label customStyle={{ marginRight: 12 }}>{label}</Label>
			{value}
		</P>
	);
	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row"
				}}
			>
				<img
					alt="Seating Map"
					style={{
						width,
						minWidth: width,
						height,
						border: "1px solid #dedede",
						borderRadius: 4
					}}
					src={
						thumbnail
							? `https://process.filestackapi.com/resize=height:150,width:150,fit:crop/${thumbnail.handle}`
							: missingSeatingMap
					}
				/>
				<div
					style={{
						marginLeft: 12,
						minWidth: 150
					}}
				>
					<div style={{ marginBottom: 20 }}>
						<Label>{name || "Missing"}</Label>
					</div>
					{totalCapacity ? <LabelItem label="Capacity" value={totalCapacity} /> : null}
					<LabelItem label="Seats" value={capacity} />
					{ticketTypeSet ? <LabelItem label="Ticket Types" value={ticketTypeSet.length} /> : null}
				</div>
			</div>
		</div>
	);
};

export default MapCard;
