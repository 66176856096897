import { Field } from "redux-form";
import { InputNumberField } from "@/components/Fields";
import { getConfig } from "@/config";

const PayoutDelayForm = ({ formPath = "" }) => {
	return (
		<>
			<Field
				name={`${formPath}daysToDelayPayout`}
				description={`Normally payouts are automatically processed ${getConfig(
					"DAYS_TO_DELAY_PAYOUT"
				)} business days from your event's end date. If you wish to delay this, please change accordingly. Otherwise, please feel free to ignore this field.`}
				label="Days to delay final payout"
				component={InputNumberField}
				placeholder={getConfig("DAYS_TO_DELAY_PAYOUT")}
				min={getConfig("DAYS_TO_DELAY_PAYOUT")}
			/>
		</>
	);
};

export default PayoutDelayForm;
