import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getConfig } from "@/config";
import { FC } from "react";

interface ViewTourButtonProps {
	slug: string;
}

export const ViewTourButton: FC<ViewTourButtonProps> = ({ slug }) => {
	const fullTourUrl = `${getConfig("TICKETING_URL")}/tours/${slug}`;

	return (
		<a href={fullTourUrl} target="_blank" rel="noreferrer">
			<LegacyButton type="action" icon={{ left: true, name: "screens" }} ariaLabel="View">
				View
			</LegacyButton>
		</a>
	);
};
