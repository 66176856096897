import AdditionalQuestion from "./additionalQuestion/AdditionalQuestion";

const OrderQuestions = ({ tickets, additionalQuestions, orderAdditionalQuestions, changeFieldValue }) => {
	const ticketTypeIds = tickets ? tickets.map((t) => t.ticketTypeId) : [];
	const orderQuestions = additionalQuestions.filter((question) => {
		if (!question.perOrder) {
			return false;
		}
		if (question.appliesTo && question.appliesTo.length) {
			const ticketFound = question.appliesTo.some(function (id) {
				return ticketTypeIds.indexOf(id) >= 0;
			});
			return ticketFound;
		}
		return true;
	});
	return (
		<div>
			{orderQuestions.map((question) => {
				question.name = `additionalFields.${question._id}`;
				return (
					<AdditionalQuestion
						key={question._id}
						question={question}
						noDefault
						orderAdditionalQuestions={orderAdditionalQuestions}
						changeFieldValue={changeFieldValue}
					/>
				);
			})}
		</div>
	);
};

export default OrderQuestions;
