import { DesignOnCanvaButton } from "@/ui";
import { FileStackUploadButton } from "@/ui";
import { DeleteOutlined, PictureOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Col, notification, Row, Space } from "@/ui/antd";
import { PickerFileMetadata } from "filestack-js";
import { FC, ReactNode, useEffect, useState } from "react";
import { Button } from "@/ui";
import { upload as uploadImageToFilestack } from "@/services/ImageService";

const StyledSmallImageContainer = styled.div<{
	aspectRatio: number;
	uploaderMinHeight: string;
}>`
	align-items: center;
	background-color: #f5f4f4;
	border-radius: 4px;
	display: flex;
	height: 100%;
	justify-content: center;
	height: ${(props) => props.uploaderMinHeight};
	min-height: ${(props) => props.uploaderMinHeight};
	min-width: ${(props) => `calc(${props.aspectRatio} * ${props.uploaderMinHeight})`};
	position: relative;
	width: 242px;

	:hover {
		button {
			opacity: 1;
		}
	}
`;

const StyledLargeImageContainer = styled.div<{
	aspectRatio: number;
	uploaderMinHeight: string;
}>`
	align-items: center;
	background-color: #f5f4f4;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	overflow: hidden;
	padding-bottom: ${(props) => 100 / props.aspectRatio}%;
	position: relative;
	width: 100%;

	img,
	span {
		bottom: 0;
		margin: auto;
		position: absolute;
		top: 0;
	}
`;

const StyledRow = styled(Row)`
	display: flex;
	gap: 8px;
`;

const StyledImage = styled.img`
	border-radius: 8px;
	height: 100%;
	max-width: 100%;
	object-fit: cover;
	overflow: hidden;
	width: 100%;
`;

const getValidHeight = (aspectRatio: number, width: number) => {
	const height = Math.round(width / aspectRatio);
	return height > 40 && height < 5000 ? height : null;
};

export type ImageUploaderResult = {
	alt: string;
	canvaDesignId?: string;
	handle?: string;
	height?: string;
	name: string;
	size?: number;
	source?: string;
	url: string;
	width?: string;
};

type ImageUploaderProps = {
	aspectRatio?: number;
	canva?: boolean;
	defaultImage?: ImageUploaderResult;
	description?: ReactNode;
	onChange?: (image: ImageUploaderResult | null) => void;
	small?: boolean;
	uploadedImageWidth?: number;
	uploaderMinHeight?: string;
};

export const ImageUploader: FC<ImageUploaderProps> = ({
	aspectRatio = 2,
	canva = false,
	defaultImage = null,
	description = (
		<p style={{ lineHeight: "20px" }}>
			Use an image that is a 2:1 ratio, min 1000px by 500px, max 10mb. <br />
			Supported files: JPG, PNG & SVG.
			<br />
			<a href="https://help.humanitix.com/en/articles/8892493-event-banner-image">Learn more</a>
		</p>
	),
	onChange,
	small,
	uploadedImageWidth = 3200,
	uploaderMinHeight = "120px"
}) => {
	const height = getValidHeight(aspectRatio, uploadedImageWidth);
	const [image, setImage] = useState<ImageUploaderResult | null>(null);
	const [isCanvaLoading, setIsCanvaLoading] = useState(false);

	useEffect(() => {
		setImage(defaultImage);
	}, [defaultImage]);

	useEffect(() => {
		onChange?.(image ?? null);
	}, [image]);

	const handleDeleteImage = () => setImage(null);

	const handleFilestackImageUpload = (img: PickerFileMetadata) => {
		setImage({
			alt: img.filename,
			handle: img.handle,
			name: img.filename,
			size: img.size,
			source: img.source,
			url: img.url
		});
	};

	const handleCanvaUpload = async (url: string, canvaDesignId: string, title: string) => {
		setIsCanvaLoading(true);
		const image = await uploadImageToFilestack(url)
			.catch(() => notification.error({ message: "Error", description: "Failed to upload image" }))
			.finally(() => setIsCanvaLoading(false));

		setImage({
			alt: title,
			canvaDesignId,
			handle: image.handle,
			name: title,
			size: image.size,
			source: "canva",
			url: image.url
		});
	};

	const offerDesignOnCanva = canva && height !== null;

	return small ? (
		<Space size={16} align="start" wrap>
			<StyledSmallImageContainer aspectRatio={aspectRatio} uploaderMinHeight={uploaderMinHeight}>
				{image?.url ? (
					<StyledImage alt={image.name} src={image.url} />
				) : (
					<PictureOutlined style={{ color: "#C6C5C5", fontSize: "40px" }} />
				)}
			</StyledSmallImageContainer>
			<Col>
				{description}
				<Space size={8}>
					<FileStackUploadButton
						aspectRatio={aspectRatio}
						disabled={isCanvaLoading}
						icon={!offerDesignOnCanva}
						image={image}
						onImageChange={handleFilestackImageUpload}
					/>
					{offerDesignOnCanva && (
						<DesignOnCanvaButton
							canvaId={image?.canvaDesignId}
							disabled={isCanvaLoading}
							isLoading={isCanvaLoading}
							height={height}
							instanceId="canva-id"
							onDesignPublish={handleCanvaUpload}
							width={uploadedImageWidth}
						/>
					)}
					<Button disabled={!image} iconOnly onClick={handleDeleteImage} variant="tertiary">
						<DeleteOutlined />
					</Button>
				</Space>
			</Col>
		</Space>
	) : (
		<Space direction="vertical" size={8} style={{ width: "100%" }}>
			<StyledLargeImageContainer aspectRatio={aspectRatio} uploaderMinHeight={uploaderMinHeight}>
				{image?.url ? (
					<StyledImage src={image.url} alt={image.name} />
				) : (
					<PictureOutlined style={{ color: "#C6C5C5", fontSize: "64px" }} />
				)}
			</StyledLargeImageContainer>
			{description}
			<StyledRow>
				<FileStackUploadButton
					aspectRatio={aspectRatio}
					disabled={isCanvaLoading}
					icon={!offerDesignOnCanva}
					image={image}
					onImageChange={handleFilestackImageUpload}
				/>
				{offerDesignOnCanva && (
					<DesignOnCanvaButton
						canvaId={image?.canvaDesignId}
						disabled={isCanvaLoading}
						isLoading={isCanvaLoading}
						height={height}
						instanceId="canva-id"
						onDesignPublish={handleCanvaUpload}
						width={uploadedImageWidth}
					/>
				)}
				<Button disabled={!image} iconOnly onClick={handleDeleteImage} variant="tertiary">
					<DeleteOutlined />
				</Button>
			</StyledRow>
		</Space>
	);
};
