import PdfUpload from "../PdfUpload";
import InputWrapper from "./helpers/InputWrapper";

const PdfUploadField = (props) => {
	const {
		input,
		label,
		description,
		inline,
		required,
		touched,
		error,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		...rest
	} = props;
	return (
		<InputWrapper
			label={label}
			description={description}
			inline={inline}
			touched={touched}
			error={error}
			id={input.name}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<PdfUpload {...input} {...rest} />
		</InputWrapper>
	);
};

export default PdfUploadField;
