import { SpacingPreset, spacingTokens } from "@/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, HTMLAttributes } from "react";

type SpacerProps = HTMLAttributes<HTMLDivElement> & {
	horizontal?: boolean;
	size: SpacingPreset | (string & {});
};

const StyledSpacer = styled.div<SpacerProps>`
	${(props) => css`
		height: ${props.horizontal ? "1px" : `${props.size}`};
		width: ${!props.horizontal ? "1px" : `${props.size}`};
	`}
`;

export const Spacer: FC<SpacerProps> = ({ horizontal, size, ...props }) => {
	if (spacingTokens[size as SpacingPreset]) {
		size = `var(--spacing-${size})`;
	}

	return <StyledSpacer horizontal={horizontal} size={size} {...props} />;
};
