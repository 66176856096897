import { css } from "@emotion/css";
import { notification } from "@/ui/antd";
import { fsClient } from "../services/ImageService";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "./text/Text";

type EmailAttachmentType = {
	name: string;
	mimetype: string;
	size: number;
	url: string;
	source: string;
	handle: string;
};

interface IAttachmentListProps {
	attachments: EmailAttachmentType[];
	removeAttachment: (index: number) => void;
}

const AttachmentList = ({ attachments = [], removeAttachment }: IAttachmentListProps) => {
	const validAttachments = attachments.filter((attachment) => attachment.url && attachment.name);
	return (
		<div>
			{validAttachments.length > 0
				? validAttachments.map((attachment, index) => {
						return (
							<div
								style={{
									backgroundColor: "#fafafa",
									padding: 5,
									marginBottom: 5,
									display: "flex",
									borderRadius: 2,
									justifyContent: "space-between"
								}}
								key={`attachment-${index}`}
							>
								<div style={{ paddingTop: 2 }}>
									<a href={attachment.url} target="_blank" rel="noopener noreferrer">
										{attachment.name}
									</a>
								</div>
								<div>
									<button
										type="button"
										onClick={() => removeAttachment(index)}
										style={{
											border: 0,
											backgroundColor: "transparent",
											color: "#aaa",
											cursor: "pointer"
										}}
										aria-label="close"
									>
										x
									</button>
								</div>
							</div>
						);
				  })
				: null}
		</div>
	);
};

interface IEmailAttachmentProps {
	attachments?: EmailAttachmentType[];
	uploadLabel?: string;
	uploadOutsideDescription?: string;
	onChange: (values: any) => void;
}

const EmailAttachment = ({
	attachments = [],
	uploadLabel = "Upload Files",
	uploadOutsideDescription = "",
	onChange
}: IEmailAttachmentProps) => {
	const maxSizeMB = 3;
	const maxSize = maxSizeMB * 1024 * 1024;
	const uploadFile = () => {
		const options = {
			fromSources: ["local_file_system", "url", "facebook", "instagram", "googledrive", "dropbox"],
			maxSize,
			minFiles: 1,
			maxFiles: 20,
			uploadInBackground: false,
			onUploadDone: emailUpload
		};
		fsClient.picker(options).open();
	};

	const emailUpload = ({ filesUploaded, filesFailed }: { filesUploaded: any; filesFailed: any }) => {
		if (filesFailed.length) {
			notification.error({ message: "file failed to upload" });
			return;
		}
		const newAttachments: EmailAttachmentType[] = attachments;
		const uploadEmails = filesUploaded;
		if (uploadEmails.length) {
			for (let i = 0; i < uploadEmails.length; i++) {
				const uploadEmail = uploadEmails[i];
				const attachment: EmailAttachmentType = {
					name: uploadEmail.filename,
					mimetype: uploadEmail.mimetype,
					size: uploadEmail.size,
					url: uploadEmail.url,
					source: uploadEmail.source,
					handle: uploadEmail.handle
				};
				newAttachments.push(attachment);
			}
		}
		const totalSize = fileTotalSize(newAttachments);
		if (totalSize >= maxSize) {
			notification.error({
				message: `Maximum file size limit is exceed (${maxSizeMB} MB)`
			});
			return;
		}
		if (onChange) {
			onChange(newAttachments);
		}
	};

	const fileTotalSize = (files: any) => {
		let totalSize = 0;
		if (files && files.length) {
			totalSize = files.reduce((acc: any, cur: any) => acc + cur.size, 0);
		}
		return totalSize;
	};

	const removeAttachment = (index: number) => {
		const newAttachments = attachments;
		newAttachments.splice(index, 1);
		if (onChange) {
			onChange(newAttachments);
		}
	};

	const hint = {
		fontSize: 14,
		color: "#323232",
		marginTop: 15
	};

	return (
		<div
			className={css({
				button: { "@media(max-width: 600px)": { width: "100%" } }
			})}
		>
			<AttachmentList attachments={attachments} removeAttachment={removeAttachment} />
			<P marginBottom={4}>Max file size {maxSizeMB}MB</P>
			<LegacyButton onClick={uploadFile} ariaLabel={uploadLabel}>
				{uploadLabel}
			</LegacyButton>
			<P style={hint}>{uploadOutsideDescription}</P>
		</div>
	);
};

export default EmailAttachment;
