import { useEffect } from "react";
import { ResponsiveModal } from "@/ui/ResponsiveModal";
import H4 from "@/components/text/H3";
import { closeResendOrderModal, getOrder } from "@/state/actions/orderActions";
import { createAppSelector, useAppDispatch, useAppSelector } from "@/state/hooks";
import { ResendOrderForm } from "./ResendOrderForm";

const selectResendOrderDetails = createAppSelector(
	[(state) => state.order.resendOrder, (state) => state.order.order],
	(resendOrder, order) => {
		return {
			modalOpen: resendOrder.modalOpen,
			resendOrderId: resendOrder.orderId,
			resendOrderEmail: resendOrder.orderEmail,
			orderId: order?._id
		};
	}
);

export const ResendOrderModal = () => {
	const dispatch = useAppDispatch();

	const { modalOpen, resendOrderId, resendOrderEmail, orderId } = useAppSelector(selectResendOrderDetails);

	useEffect(() => {
		// The modal could be opened as an action from a list of orders; we may need to refetch data if the target order changes
		if (resendOrderId && resendOrderId !== orderId) {
			// Retrieve the full order, so we can see its tickets
			dispatch(getOrder(resendOrderId));
		}
	}, [orderId, resendOrderId]);

	const handleClose = () => {
		dispatch(closeResendOrderModal());
	};

	if (!modalOpen) {
		return null;
	}

	return (
		<ResponsiveModal
			fullscreen={false}
			open={modalOpen}
			closable
			onCancel={handleClose}
			header={<H4>Resend Order</H4>}
			compactHeader
			footer={null}
			width="600px"
		>
			<ResendOrderForm orderId={resendOrderId} orderEmail={resendOrderEmail} onClose={handleClose} />
		</ResponsiveModal>
	);
};
