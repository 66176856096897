import {
	CLOSE_ACCESS_MODAL,
	DELETE_ACCESS_CODES_SUCCESS,
	DELETE_ALL_ACCESS_CODES_ERROR,
	DELETE_ALL_ACCESS_CODES_REQUEST,
	DELETE_ALL_ACCESS_CODES_SUCCESS,
	EDIT_ACCESS_CODE,
	GET_ACCESS_CODES_ERROR,
	GET_ACCESS_CODES_REQUEST,
	GET_ACCESS_CODES_SUCCESS,
	GET_EVENT_LEVEL_ACCESS_CODE_CSV_ERROR,
	GET_EVENT_LEVEL_ACCESS_CODE_CSV_REQUEST,
	GET_EVENT_LEVEL_ACCESS_CODE_CSV_SUCCESS,
	OPEN_ACCESS_MODAL,
	SAVE_ACCESS_CODES_ERROR,
	SAVE_ACCESS_CODES_REQUEST,
	SAVE_ACCESS_CODES_SUCCESS
} from "../actions/accessCodesActions";

const initialState = {
	codes: [],
	count: 0,
	page: 1,
	editingCode: null,
	modalOpen: false,
	getRequest: {
		loading: false,
		error: false,
		saved: false
	},
	saveRequest: {
		loading: false,
		error: false,
		saved: false
	},
	deleteAllRequest: {
		loading: false,
		error: false,
		saved: false
	},
	getCsvRequest: {
		loading: false,
		error: false
	}
};

function findCodeIndex(state, action) {
	const codes = state.codes.concat([]);
	const code = action.accessCode;
	const codeIndex = codes.findIndex((c) => String(c._id) === String(code._id));
	return codeIndex;
}

export default (state = initialState, action) => {
	switch (action.type) {
		case OPEN_ACCESS_MODAL: {
			return {
				...state,
				modalOpen: true,
				editingCode: null
			};
		}
		case CLOSE_ACCESS_MODAL: {
			return {
				...state,
				modalOpen: false,
				editingCode: null
			};
		}
		case EDIT_ACCESS_CODE: {
			return {
				...state,
				editingCode: action.accessCode,
				modalOpen: true
			};
		}
		case GET_ACCESS_CODES_REQUEST:
			return {
				...state,
				codes: [],
				page: action.page,
				getRequest: {
					loading: true,
					error: false,
					saved: false
				}
			};
		case GET_ACCESS_CODES_SUCCESS:
			return {
				...state,
				codes: action.accessCodes,
				count: action.count,
				getRequest: {
					loading: false,
					error: false
				}
			};
		case GET_ACCESS_CODES_ERROR:
			return {
				codes: [],
				getRequest: {
					loading: false,
					error: action.error
				}
			};
		case SAVE_ACCESS_CODES_REQUEST:
			return {
				...state,
				saveRequest: {
					loading: true,
					error: false
				}
			};
		case SAVE_ACCESS_CODES_SUCCESS: {
			let updatedCodes = state.codes.concat([]);
			if (Array.isArray(action.accessCode)) {
				updatedCodes = state.codes.concat(action.accessCode);
			} else {
				// update the access code in the array
				const updateIndex = findCodeIndex(state, action);
				if (updateIndex !== -1) {
					updatedCodes[updateIndex] = action.accessCode;
				} else {
					updatedCodes.push(action.accessCode);
				}
			}
			return {
				...state,
				codes: updatedCodes,
				saveRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};
		}
		case SAVE_ACCESS_CODES_ERROR:
			return {
				...state,
				saveRequest: {
					loading: false,
					error: action.error,
					saved: false
				}
			};
		case DELETE_ACCESS_CODES_SUCCESS: {
			const deleteIndex = findCodeIndex(state, action);
			let newCodes = state.codes.concat([]);
			if (deleteIndex > -1) {
				newCodes.splice(deleteIndex, 1);
			}
			return {
				...state,
				codes: newCodes,
				saveRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};
		}
		case DELETE_ALL_ACCESS_CODES_REQUEST:
			return {
				...state,
				deleteAllRequest: {
					loading: true,
					error: false
				}
			};
		case DELETE_ALL_ACCESS_CODES_SUCCESS:
			return {
				...state,
				deleteAllRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};

		case DELETE_ALL_ACCESS_CODES_ERROR:
			return {
				...state,
				deleteAllRequest: {
					loading: false,
					error: action.error,
					saved: false
				}
			};
		case GET_EVENT_LEVEL_ACCESS_CODE_CSV_REQUEST:
			return {
				...state,
				getCsvRequest: {
					loading: true,
					error: false
				}
			};
		case GET_EVENT_LEVEL_ACCESS_CODE_CSV_SUCCESS:
			return {
				...state,
				getCsvRequest: {
					loading: false,
					error: false
				}
			};
		case GET_EVENT_LEVEL_ACCESS_CODE_CSV_ERROR:
			return {
				...state,
				getCsvRequest: {
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
