import {
	CLEAR_SEATING_MAP_STATE,
	CREATE_SEATING_MAP_ERROR,
	CREATE_SEATING_MAP_REQUEST,
	CREATE_SEATING_MAP_SUCCESS,
	EXIT_SEATING_MAP,
	GET_CLONEABLE_MAPS_ERROR,
	GET_CLONEABLE_MAPS_REQUEST,
	GET_CLONEABLE_MAPS_SUCCESS,
	GET_ORGANISER_MAP_ERROR,
	GET_ORGANISER_MAP_REQUEST,
	GET_ORGANISER_MAP_SUCCESS,
	GET_SEATING_MAP_ERROR,
	GET_SEATING_MAP_REQUEST,
	GET_SEATING_MAP_SUCCESS,
	GET_TICKETS_FOR_MAP_ERROR,
	GET_TICKETS_FOR_MAP_REQUEST,
	GET_TICKETS_FOR_MAP_SUCCESS,
	GET_UNMAPPED_TICKETS_ERROR,
	GET_UNMAPPED_TICKETS_REQUEST,
	GET_UNMAPPED_TICKETS_SUCCESS,
	MOVE_TICKET_ERROR,
	MOVE_TICKET_REQUEST,
	MOVE_TICKET_SUCCESS,
	ManagerTab,
	NEW_ORGANISATION_STATE,
	NEW_SEATING_MAP,
	SAVE_SEATING_MAP_ERROR,
	SAVE_SEATING_MAP_REQUEST,
	SAVE_SEATING_MAP_SUCCESS,
	SEATING_MAP_CHANGE_DATE,
	SET_MANAGER_TAB,
	UNASSIGN_TICKET_ERROR,
	UNASSIGN_TICKET_REQUEST,
	UNASSIGN_TICKET_SUCCESS,
	UPDATE_SEATING_MAP_ERROR,
	UPDATE_SEATING_MAP_REQUEST,
	UPDATE_SEATING_MAP_SUCCESS,
	VIEW_SWITCH
} from "../actions/seatingMapActions";

const initialState = {
	seatingMap: false,
	newSeatingMap: false,
	organiserMap: false,
	organiserView: false,
	unmappedTickets: [],
	cloneableMaps: [],
	organiser: {
		loading: false,
		conflict: false,
		success: false,
		error: false,
		tickets: [],
		date: 0,
		tab: ManagerTab.ASSIGNED,
		search: ""
	},
	loading: false,
	error: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_MANAGER_TAB:
			return {
				...state,
				organiser: {
					...state.organiser,
					tab: action.tab,
					search: ""
				}
			};
		case GET_UNMAPPED_TICKETS_REQUEST:
			return {
				...state,
				organiser: {
					...state.organiser,
					loading: true,
					error: false,
					search: action.search
				}
			};
		case GET_UNMAPPED_TICKETS_SUCCESS:
			return {
				...state,
				unmappedTickets:
					action.page > 1 ? [...state.unmappedTickets, ...action.unmappedTickets] : action.unmappedTickets,
				organiser: {
					...state.organiser,
					loading: false
				}
			};

		case GET_UNMAPPED_TICKETS_ERROR:
			return {
				...state,
				error: action.error,
				organiser: {
					...state.organiser,
					loading: false,
					error: action.error
				}
			};

		case GET_CLONEABLE_MAPS_ERROR:
		case UPDATE_SEATING_MAP_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case GET_CLONEABLE_MAPS_REQUEST:
		case UPDATE_SEATING_MAP_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_CLONEABLE_MAPS_SUCCESS:
			return {
				...state,
				loading: false,
				cloneableMaps: action.maps
			};
		case UPDATE_SEATING_MAP_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case GET_SEATING_MAP_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_SEATING_MAP_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				seatingMap: action.seatingMap
			};
		case GET_SEATING_MAP_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case NEW_SEATING_MAP:
			return {
				...state,
				newSeatingMap: true,
				loading: false,
				error: false,
				seatingMap: {
					seatingMapData: {
						seatingConstruction: {
							sections: [],
							objects: []
						},
						sectionOverview: {
							sections: [],
							objects: []
						}
					}
				}
			};

		case CREATE_SEATING_MAP_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case CREATE_SEATING_MAP_SUCCESS:
			return {
				...state,
				newSeatingMap: false,
				loading: false,
				error: false,
				seatingMap: action.seatingMap
			};
		case CREATE_SEATING_MAP_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case SAVE_SEATING_MAP_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_SEATING_MAP_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				seatingMap: action.seatingMap
			};
		case SAVE_SEATING_MAP_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case GET_ORGANISER_MAP_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case GET_ORGANISER_MAP_REQUEST:
			return {
				...state,
				loading: true
			};
		case GET_ORGANISER_MAP_SUCCESS:
			return {
				...state,
				loading: false,
				organiserView: true,
				organiserMap: action.managerData.seatingMapData,
				unmappedTickets: action.managerData.unmappedTickets
			};
		case MOVE_TICKET_ERROR:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: action.error,
					success: false,
					conflict: false,
					loading: false
				}
			};
		case MOVE_TICKET_REQUEST:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: false,
					success: false,
					conflict: false,
					loading: true
				}
			};
		case MOVE_TICKET_SUCCESS:
			return {
				...state,
				unmappedTickets: action.unassignedTickets,
				organiser: {
					...state.organiser,
					error: action.error,
					success: true,
					conflict: false,
					loading: false
				}
			};

		case GET_TICKETS_FOR_MAP_REQUEST:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: false,
					loading: true,
					search: action.search
				}
			};

		case GET_TICKETS_FOR_MAP_SUCCESS:
			return {
				...state,
				organiser: {
					...state.organiser,
					tickets: action.page > 1 ? [...state.organiser.tickets, ...action.tickets] : action.tickets,
					error: false,
					loading: false
				}
			};

		case GET_TICKETS_FOR_MAP_ERROR:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: true,
					loading: false
				}
			};

		case UNASSIGN_TICKET_ERROR:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: action.error,
					success: false,
					conflict: false,
					loading: false
				}
			};
		case UNASSIGN_TICKET_REQUEST:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: false,
					success: false,
					conflict: false,
					loading: true
				}
			};
		case UNASSIGN_TICKET_SUCCESS:
			return {
				...state,
				unmappedTickets: action.unassignedTickets,
				organiser: {
					...state.organiser,
					error: false,
					success: true,
					conflict: false,
					loading: false
				}
			};
		case NEW_ORGANISATION_STATE:
			return {
				...state,
				organiser: {
					...state.organiser,
					error: false,
					success: false,
					conflict: false,
					loading: false
				}
			};
		case EXIT_SEATING_MAP:
			return {
				...initialState
			};

		case VIEW_SWITCH:
			return {
				...state,
				organiserMap: false,
				organiserView: false
			};

		case CLEAR_SEATING_MAP_STATE:
			return { ...initialState };
		case SEATING_MAP_CHANGE_DATE:
			return {
				...state,
				organiserMap: false,
				organiser: {
					loading: false,
					conflict: false,
					success: false,
					error: false,
					tickets: [],
					date: action.date
				}
			};
		default:
			return state;
	}
};
