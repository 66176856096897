import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useAppSelector } from "@/state/hooks";
import { ReduxState } from "@/state/store";
import { Button } from "@/ui";
import { CurrentUserSchema } from "@hx/console";
import { FC } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import { LegacyInputField } from "../components/Fields";
import FormBar from "../components/FormBar";
import LoadErrorView from "../components/LoadErrorView";
import { H1 } from "../components/text/Text";
import withSaveCheck from "../hoc/withSaveCheck";
import { mobile, required } from "../utils/Validators";

const firstNameRequired = required("Please input your first name");
const lastNameRequired = required("Please input your last name");
const mobileRequired = required("Please input your mobile");
const validMobile = mobile("Please enter a valid mobile");

const ProfileForm: FC<InjectedFormProps> = ({ handleSubmit }) => {
	const auth = useAppSelector((state) => state.auth);
	const { updateUser } = useCurrentUser();

	const { loading } = auth;

	const save = async (values: Partial<CurrentUserSchema>) => {
		await updateUser(values);
	};

	return (
		<LoadErrorView loading={loading}>
			<form onSubmit={handleSubmit(save)}>
				<H1>Profile</H1>
				<ContentBlock>
					<Field name="email" label="Email Address" placeholder="Email" component={LegacyInputField} disabled />
					<Field
						name="firstName"
						label="First Name"
						placeholder="First Name"
						component={LegacyInputField}
						required
						validate={firstNameRequired}
					/>
					<Field
						name="lastName"
						label="Last Name"
						placeholder="Last Name"
						component={LegacyInputField}
						required
						validate={lastNameRequired}
					/>
					<Field
						name="mobile"
						label="Mobile"
						placeholder="Mobile"
						component={LegacyInputField}
						required
						validate={[mobileRequired, validMobile]}
					/>
					<Field name="organization" label="Organisation" placeholder="Organisation" component={LegacyInputField} />
				</ContentBlock>

				<FormBar>
					<Button variant="primary" htmlType="submit" aria-label="Update">
						Update
					</Button>
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

const formName = "userProfile";

const ReduxDecoratedProfileForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(ProfileForm);

export default connect((state: ReduxState) => {
	return {
		initialValues: {
			firstName: state.auth.user?.firstName,
			lastName: state.auth.user?.lastName,
			mobile: state.auth.user?.mobile,
			email: state.auth.user?.email,
			organization: state.auth.user?.organization
		}
	};
})(withSaveCheck(ReduxDecoratedProfileForm, formName));
