import React from "react";
import { Badge, Modal, Space, Tabs, Button as AntButton } from "@/ui/antd";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { SeatingMap } from "@hx/seating-map";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import P from "@/components/text/P";
import ManageTicketCapacityTable from "./ManageTicketCapacityTable";
import ManagePackagedTicketsCapacityTable from "./ManagePackagedTicketsCapacityTable";

interface IProps {
	isOpen: boolean;
	handleModalClose: () => void;
	handleAutoMatchingCapacities: () => void;
}

interface IInjectedProps {
	manageCapacityFormValues: { ticketTypes: TicketType[]; packagedTickets: PackagedTicket[] };
	isLoadingOnSave: boolean;
}

type TicketType = {
	_id: string;
	name: string;
	deleted: boolean;
	disabled: boolean;
	isDonation: boolean;
	mappedSeats: number;
	isMappedToSeatingMap: boolean;
	quantity: number;
} & any;

type PackagedTicket = {
	_id: string;
	name: string;
	tickets: { quantity: number; ticketTypeId: string } & unknown[];
	mappedTables: number;
	mappedSeats: number;
	isMappedToSeatingMap: boolean;
	quantity: number;
} & any;

type ICombinedProps = IProps & IInjectedProps;

const ManageCapacityModal: React.FunctionComponent<ICombinedProps> = ({
	isOpen,
	handleModalClose,
	manageCapacityFormValues: { ticketTypes, packagedTickets },
	isLoadingOnSave,
	handleAutoMatchingCapacities
}) => {
	const packagedWithTicketNames = packagedTickets.map((p: PackagedTicket) => {
		return {
			...p,
			tickets: p.tickets.map((ticket: { quantity: number; ticketTypeId: string } & unknown) => {
				return {
					...ticket,
					name: ticketTypes.find((t: TicketType) => t._id === ticket.ticketTypeId)?.name || "NO NAME"
				};
			})
		};
	});

	const mismatchedTickets = ticketTypes.filter((t: TicketType) => t.mappedSeats > 0 && t.mappedSeats !== t.quantity);

	const mismatchedPackages = packagedTickets.filter(
		(p: PackagedTicket) => p.mappedTables > 0 && p.mappedTables !== p.quantity
	);

	const isAnythingMappedToSeatingMap =
		ticketTypes.some((t: TicketType) => t.mappedSeats > 0) ||
		packagedTickets.some((p: PackagedTicket) => p.mappedTables > 0);

	let modalContent = <P>The capacity management table becomes accessible after your initial mapping.</P>;
	let footerContent = (
		<ButtonsBar footer style={{ flexDirection: "row-reverse" }}>
			<LegacyButton key="cancel" onClick={handleModalClose} htmlType="button" ariaLabel="Got it">
				Got it
			</LegacyButton>
		</ButtonsBar>
	);

	if (isAnythingMappedToSeatingMap) {
		const tabItems = [];
		if (ticketTypes.filter((t) => t.mappedSeats > 0).length > 0) {
			tabItems.push({
				label: (
					<Space direction="horizontal">
						Tickets
						<Badge count={mismatchedTickets.length} style={{ backgroundColor: "#C0212C" }} />
					</Space>
				),
				key: "mappedTickets",
				children: (
					<ManageTicketCapacityTable
						ticketTypes={ticketTypes}
						handleAutoMatchingCapacities={handleAutoMatchingCapacities}
						isLoadingOnSave={isLoadingOnSave}
						ticketTypeColors={SeatingMap.getCurrentMap()?.getTicketTypeColorMap()}
					/>
				)
			});
		}
		if (packagedTickets.filter((p) => p.mappedTables > 0).length > 0) {
			tabItems.push({
				label: (
					<Space direction="horizontal">
						Packaged tickets
						<Badge count={mismatchedPackages.length} style={{ backgroundColor: "#C0212C" }} />
					</Space>
				),
				key: "mappedPackagedTickets",
				children: (
					<ManagePackagedTicketsCapacityTable
						packagedTickets={packagedWithTicketNames}
						handleAutoMatchingCapacities={handleAutoMatchingCapacities}
						isLoadingOnSave={isLoadingOnSave}
						packageTicketColors={SeatingMap.getCurrentMap()?.getTicketTypeColorMap()}
					/>
				)
			});
		}

		modalContent = (
			<form>
				<Tabs defaultActiveKey="1" items={tabItems} />
			</form>
		);

		footerContent = (
			<ButtonsBar footer>
				<LegacyButton
					key="cancel"
					onClick={handleModalClose}
					htmlType="button"
					ariaLabel="Got it"
					disabled={isLoadingOnSave}
				>
					Cancel
				</LegacyButton>
				<AntButton type="primary" key="save" onClick={handleModalClose} htmlType="button" disabled={isLoadingOnSave}>
					Done
				</AntButton>
			</ButtonsBar>
		);
	}

	return (
		<Modal
			open={isOpen}
			title="Ticket Settings - Manage Capacity"
			destroyOnClose
			onCancel={handleModalClose}
			width={isAnythingMappedToSeatingMap ? 860 : 540}
			footer={footerContent}
		>
			{modalContent}
		</Modal>
	);
};

const formName = "manageMappedCapacity";
const selector = formValueSelector(formName);

export default connect((state: Pick<GlobalReduxState, "seatingMap">): IInjectedProps => {
	return {
		manageCapacityFormValues: selector(state, "ticketTypes", "packagedTickets"),
		isLoadingOnSave: state.seatingMap.loading
	};
}, {})(ManageCapacityModal);
