import {
	AUDIT_LOG_GET_ERROR,
	AUDIT_LOG_GET_REQUEST,
	AUDIT_LOG_GET_SUCCESS,
	AUDIT_LOG_LIST_ERROR,
	AUDIT_LOG_LIST_REQUEST,
	AUDIT_LOG_LIST_SUCCESS
} from "../actions/auditLogsActions";

const initialState = {
	loading: false,
	error: "",
	page: 1,
	logs: [],
	log: undefined
};
export default (state = initialState, action) => {
	switch (action.type) {
		case AUDIT_LOG_LIST_REQUEST:
			return {
				...state,
				loading: true,
				page: action.page
			};
		case AUDIT_LOG_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				logs: action.data
			};
		case AUDIT_LOG_LIST_ERROR:
			return {
				...state,
				loading: false,
				logs: [],
				error: {
					title: "Something went wrong",
					message: action.error
				}
			};
		case AUDIT_LOG_GET_REQUEST:
			return {
				...state,
				loading: true
			};
		case AUDIT_LOG_GET_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				log: action.log
			};
		case AUDIT_LOG_GET_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		default:
			return state;
	}
};
