import { useAppSelector } from "@/state/hooks";
import { css } from "@emotion/css";
import { FC } from "react";
import { useNavigate } from "react-router";
import smilingPersonImage from "../../assets/people/rory_graduating_doctor_of_medicine.png";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2, P } from "../text/Text";
import Widget from "./Widget";

const container = css({
	alignItems: "center",
	display: "grid",
	gap: "16px",
	gridTemplateColumns: "1fr 4fr",
	padding: 15,
	"@media(max-width:768px)": {
		gridTemplateColumns: "1fr 2fr",
		padding: "15px 12px"
	}
});

const imageStyle = css({
	borderRadius: "4px",
	height: "100%",
	objectFit: "cover",
	width: "100%",
	"@media(max-width:768px)": {
		maxHeight: "180px"
	}
});

const textSection = css({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center"
});

const headingStyle = {
	marginBottom: 10,
	"@media (max-width: 768px)": {
		width: "100%"
	}
};

const paragraphStyle = {
	marginBottom: 18,
	maxWidth: "unset"
};

const buttonContainer = css({
	display: "flex",
	justifyContent: "flex-start"
});

const buttonStyle = css({
	maxWidth: "180px"
});

const CharityImpact: FC = (props) => {
	const navigate = useNavigate();
	const currentEvent = useAppSelector((state) => state.currentEvent);

	const handleShareImpact = () => {
		const { event } = currentEvent;
		navigate(`/console/promote/promotional-hub/social-media?eventIds=${event?._id}&tab=individual`);
	};

	return (
		<Widget excludeTimeScale style={{ padding: 0 }} {...props}>
			<div className={container}>
				<img alt="smiling man in graduation cap" className={imageStyle} src={smilingPersonImage} />
				<div className={textSection}>
					<H2 style={headingStyle}>Tickets for good, not greed</H2>
					<P style={paragraphStyle}>
						Thanks to a scholarship <strong>funded by booking fees from events like yours</strong>, Rory was able to
						study to become a Doctor! 100% of Humanitix's profits are dedicated to charities providing life's
						essentials, like education, to humans worldwide.
					</P>
					<div className={buttonContainer}>
						<LegacyButton
							ariaLabel="share your impact"
							type="action"
							className={buttonStyle}
							onClick={handleShareImpact}
						>
							Share your impact
						</LegacyButton>
					</div>
				</div>
			</div>
		</Widget>
	);
};

export default CharityImpact;
