export const constructFieldMapping = (csv, additionalQuestions) => {
	const headers = csv[0];
	const rows = [].concat(csv);
	rows.splice(0, 1);
	const requiredFields = [
		"Order Id",
		"Ticket Type",
		"Buyer First Name",
		"Buyer Last Name",
		"Buyer Email",
		"Buyer Mobile"
	];
	return headers.map((header, i) => {
		const cleanedHeader = header
			.replace(/ /g, "")
			.replace(/\r?\n|\r/g, "")
			.toLowerCase();
		const isRequiredHeader = requiredFields.find(
			(reqField) => reqField.replace(/ /g, "").toLowerCase() === cleanedHeader
		);
		if (isRequiredHeader) {
			return {
				index: i,
				ignore: false,
				requiredHeader: isRequiredHeader,
				fieldId: isRequiredHeader
			};
		}
		const additionalQuestionFound = additionalQuestions.filter((additionalQuestion) => {
			const cleanedAdditionalQuestionName = additionalQuestion.question.replace(/ /g, "").toLowerCase();
			return cleanedAdditionalQuestionName === cleanedHeader;
		});
		if (additionalQuestionFound.length === 1) {
			return {
				index: i,
				fieldId: additionalQuestionFound[0]._id,
				ignore: false
			};
		}
		return {
			index: i,
			fieldId: "",
			ignore: false
		};
	});
};
