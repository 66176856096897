import { useCollectionURL } from "@/hooks/useCollectionURL";
import { StorageKeys, useLocalStorage } from "@/hooks/useLocalStorage";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { Button, DesktopOnly, Dropdown, DropdownItem, HR, MobileOnly } from "@/ui";
import { Flex, message, Tooltip } from "@/ui/antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { CollectionSummary } from "@hx/console";
import { Location } from "@hx/locations";
import { ArrowSquareOut, DotsThreeVertical, Link } from "@phosphor-icons/react";
import { FC, MouseEvent } from "react";
import { useNavigate } from "react-router";

const StyledActionButton = styled(Button)`
	min-height: 32px;
	min-width: 32px;
	padding: 0;
	font-size: 16px;
`;

type CollectionListItemActionsProps = {
	collection: CollectionSummary;
};

export const CollectionListItemActions: FC<CollectionListItemActionsProps> = ({ collection }) => {
	const navigate = useNavigate();
	const collectionURL = useCollectionURL({ collectionSlug: collection.info.slug, isPreview: !collection.published });
	const deleteCollectionProcedure = trpc.collections.deleteCollection.useMutation();
	const user = useAppSelector((state) => state.auth.user);

	const [filterCollectionsByLocation] = useLocalStorage<Location>(StorageKeys.COLLECTIONS_LOCATION, user.location);

	const getUserCollectionsSummaryProcedure = trpc.collections.getUserCollectionsSummary.useQuery(
		{ location: filterCollectionsByLocation },
		{
			enabled: !!filterCollectionsByLocation,
			networkMode: "always"
		}
	);

	const handleViewPageClicked = (e: MouseEvent) => {
		e.stopPropagation();
		window.open(collectionURL, "_blank");
	};

	const handleDeleteClicked = async (e: MouseEvent) => {
		e.stopPropagation();
		const confirmed = confirm(`Are you sure you want to delete ${collection.name}?`);
		if (confirmed) {
			const response = await deleteCollectionProcedure.mutateAsync({
				collectionId: collection._id,
				location: collection.location
			});

			if (response.success) {
				message.success("Collection deleted");
				getUserCollectionsSummaryProcedure.refetch();
			} else {
				message.error("Could not delete collection");
			}
		}
	};

	const handleEditClicked = () => navigate(`${collection._id}/events`);

	const handleEmbedWidgetClicked = (e: MouseEvent) => {
		e.stopPropagation();
		const locationParam = collection.location?.toLowerCase();
		navigate(`${locationParam}/${collection._id}/widget`);
	};

	const handleMoreClicked = (e: MouseEvent) => e.stopPropagation();

	// TODO: THIS WILL COME BACK POST RELEASE SO LEAVE IT HERE FOR NOW - NO TICKET NUMBER YET
	// const handleDuplicateClicked = async (e: MouseEvent) => {
	// 	e.stopPropagation();
	// 	await duplicateCollectionProcedure.mutateAsync({ collectionId: collection._id });
	// 	getUserCollectionsSummaryProcedure.refetch();
	// };

	const handleCopyLinkClicked = (e: MouseEvent) => {
		e.stopPropagation();
		navigator.clipboard
			.writeText(collectionURL)
			.then(() => message.success("Collection link copied to clipboard"))
			.catch(() => message.error("Could not copy link"));
	};

	return (
		<Flex gap="sm">
			<DesktopOnly>
				<Tooltip title="Edit collection">
					<StyledActionButton aria-label="Edit collection" iconOnly onClick={handleEditClicked} variant="text">
						<EditOutlined />
					</StyledActionButton>
				</Tooltip>
				{collection.published && (
					<Tooltip title="Copy collection URL">
						<StyledActionButton
							aria-label="Copy collections link"
							iconOnly
							onClick={handleCopyLinkClicked}
							variant="text"
						>
							<Link weight="bold" />
						</StyledActionButton>
					</Tooltip>
				)}
			</DesktopOnly>
			<Dropdown
				placement="bottomRight"
				items={
					<>
						<MobileOnly>
							<DropdownItem spaceBetween iconRight={<EditOutlined />} onClick={handleEditClicked}>
								Edit collection
							</DropdownItem>
							{collection.published && (
								<DropdownItem
									spaceBetween
									aria-label="Copy collections link"
									iconRight={<Link weight="bold" />}
									onClick={handleCopyLinkClicked}
									variant="text"
								>
									Copy URL
								</DropdownItem>
							)}
						</MobileOnly>
						<DropdownItem
							spaceBetween
							fullWidth
							iconRight={<ArrowSquareOut size={18} />}
							onClick={handleViewPageClicked}
						>
							View page
						</DropdownItem>
						{/* <DropdownItem spaceBetween onClick={handleDuplicateClicked}>Duplicate collection</DropdownItem> */}
						<DesktopOnly>
							<DropdownItem spaceBetween fullWidth onClick={handleEmbedWidgetClicked}>
								Embed widget
							</DropdownItem>
						</DesktopOnly>
						<HR marginY="xs" />
						<DropdownItem
							spaceBetween
							fullWidth
							iconRight={<DeleteOutlined size={24} />}
							onClick={handleDeleteClicked}
							variant="danger-text"
						>
							Delete
						</DropdownItem>
					</>
				}
			>
				<StyledActionButton aria-label="More options" iconOnly variant="text" onClick={handleMoreClicked}>
					<DotsThreeVertical size={18} weight="bold" />
				</StyledActionButton>
			</Dropdown>
		</Flex>
	);
};
