import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Alert, Modal, Table } from "@/ui/antd";
import { Field } from "redux-form";
import { CheckboxField } from "@/components/Fields";
import { Label } from "@/components/text/Text";
import LocationService from "@/services/LocationService.js";
import { OpenHelpHandler } from "@/utils/Help";

const tableStyling = css({
	backgroundColor: "#f9f9fa",
	tr: {
		verticalAlign: "top"
	},
	th: {
		verticalAlign: "top",
		fontWeight: 600
	},
	td: {
		verticalAlign: "top"
	}
});

const iAgreeWrapper = css({
	marginTop: 36,
	display: "flex",
	label: {
		marginLeft: 8
	}
});

const getCampaignEventDetails = (campaign, campaignSummaryData) => {
	if (!campaignSummaryData.length) {
		return <>No valid events or event dates</>;
	}
	const datesTotal = campaignSummaryData.reduce((sum, event) => {
		return sum + event.dateCount;
	}, 0);
	const campaignEventIds = campaign.eventIds?.filter((id) => id !== "all") || [];
	if (campaignEventIds.length === 0) {
		return (
			<>
				All events (<b>{campaignSummaryData.length}</b>
				),{" "}
				<i>
					date{datesTotal > 1 && "s"} (<b>{datesTotal}</b>)
				</i>
			</>
		);
	} else if (campaignEventIds.length < 4) {
		return campaignSummaryData.map((event) => (
			<div key={event.eventName}>
				{event.eventName}{" "}
				<i>
					(<b>{event.dateCount}</b> date{event.dateCount > 1 && "s"})
				</i>
			</div>
		));
	}
	return (
		<>
			<b>{campaignEventIds.length}</b> Events,{" "}
			<i>
				(
				<b>
					{campaignSummaryData.reduce((sum, event) => {
						return sum + event.dateCount;
					}, 0)}
				</b>
				) date{datesTotal > 1 && "s"}
			</i>
		</>
	);
};

const VerificationModal = ({
	isModalOpen,
	save,
	toggleModalOpen,
	campaign,
	scheduleType,
	scheduleDate,
	campaignSummary
}) => {
	if (!isModalOpen) {
		return null;
	}
	const hours = campaign && campaign.schedule && campaign.schedule.hours ? campaign.schedule.hours : 0;
	const days = campaign && campaign.schedule && campaign.schedule.days ? campaign.schedule.days : 0;
	let scheduledFor;
	switch (scheduleType) {
		case "now":
			scheduledFor = (
				<p>
					Now{" "}
					<i>
						(this may take up to <b>10 minutes</b> to send)
					</i>
				</p>
			);
			break;
		case "before":
		case "after":
			scheduledFor = (
				<p>{`${days ? `${days} days` : ""} ${hours ? `${hours} hours` : ""} ${campaign.schedule.type} your event`}</p>
			);
			break;
		default:
			scheduledFor = new HumaniDate(scheduleDate, "guess", LocationService.getLocation(true)).toString();
			break;
	}
	const summaryLoading = campaignSummary.loading || !campaignSummary.data;
	return (
		<Modal
			title={`Confirm details before ${campaign?.schedule?.type === "now" ? "sending" : "scheduling"}`}
			open={isModalOpen}
			onOk={save}
			onCancel={toggleModalOpen}
			okText={campaign?.schedule?.type === "now" ? "Send" : "Schedule"}
			okButtonProps={{
				disabled: (campaign && !campaign.iAgree) || campaignSummary.loading,
				htmlType: "submit"
			}}
		>
			{campaignSummary.error ? (
				<Alert message={campaignSummary.error.reason || "Error calculating summary"} type="error" showIcon={true} />
			) : (
				<div>
					{campaign ? (
						<div>
							<table className={tableStyling}>
								<tbody>
									<tr>
										<th>Event{!summaryLoading && campaignSummary.data?.length > 1 && "s"}:</th>
										<td>{summaryLoading ? "Loading..." : getCampaignEventDetails(campaign, campaignSummary.data)}</td>
									</tr>
									{!campaignSummary?.data?.length ? (
										<tr>
											<td colSpan={2}>
												<Alert
													showIcon
													message="All event dates selected are more than 2 weeks in the past"
													type="warning"
												/>
											</td>
										</tr>
									) : null}
									<tr>
										<th>Subject:</th>
										<td>{campaign.subject}</td>
									</tr>
									<tr>
										<th>Scheduled for:</th>
										<td>{scheduledFor}</td>
									</tr>
									<tr style={{ border: "none" }}>
										<th style={{ border: "none" }}>Current recipients:</th>
										<td style={{ border: "none" }}>
											{!summaryLoading ? (
												<div>
													{campaignSummary.data.reduce((sum, event) => {
														return sum + event.recipientCount;
													}, 0)}
												</div>
											) : (
												"Loading..."
											)}
										</td>
									</tr>
									<tr style={{ border: "none" }}>
										<td colSpan="2" style={{ border: "none" }}>
											<Alert
												showIcon
												message="Any additional buyers who register before the email is sent will also receive this email."
												type="warning"
											/>
										</td>
									</tr>
									<tr style={{ border: "none" }}>
										<td colSpan="2" style={{ border: "none" }}>
											{!summaryLoading ? (
												<>
													<Table
														rowKey={(data) => data.eventName}
														columns={[
															{
																title: "Event",
																dataIndex: "eventName"
															},
															{
																title: "Recipients",
																dataIndex: "recipientCount"
															}
														]}
														scroll={{ y: 200 }}
														dataSource={campaignSummary.data}
														pagination={false}
													/>
												</>
											) : (
												" Loading... "
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					) : null}
					<div className={iAgreeWrapper}>
						<Field id="iAgree" name="iAgree" component={CheckboxField} inline labelAfter />
						<Label bold={false} htmlFor="iAgree">
							I confirm that the content within this email relates to helping attendees with their purchase, and is not
							marketing or promotional material. I understand that any abuse of the Email Campaigns tool may result in
							my access to the tool being suspended.
							<br />
							<br />
							To send Email Marketing, we recommend using a dedicated Email Marketing platform, or trying our{" "}
							<a onClick={OpenHelpHandler(8888537)}>Mailchimp integration</a>.
						</Label>
					</div>
				</div>
			)}
		</Modal>
	);
};

export default VerificationModal;
