import { Input, Select } from "@/ui/antd";
import { Component } from "react";
import { change } from "redux-form";
import InputLabel from "./helpers/InputLabel";
import InputWrapper from "./helpers/InputWrapper";

class SelectFieldWithOption extends Component {
	constructor() {
		super();
		this.changeValue = this.changeValue.bind(this);
		this.changeDescription = this.changeDescription.bind(this);
		this.state = {
			option: false
		};
	}
	changeValue(index) {
		const { meta, input, options } = this.props;
		if (Number(index) === -1) {
			meta.dispatch(change(meta.form, input.name, null));
			return;
		}
		const option = options[index];
		this.setState({ option });
		meta.dispatch(change(meta.form, input.name, option.value));
	}
	changeDescription(value) {
		const { meta, input } = this.props;
		const { option } = this.state;
		meta.dispatch(change(meta.form, input.name, `${option.value}: ${value}`));
	}
	render() {
		const {
			input,
			label,
			description,
			required,
			inline,
			placeholder,
			mode,
			options,
			style = { width: "100%" },
			tooltip,
			tooltipIcon = false,
			tooltipIconOnClick,
			trackQuantity,
			meta: { touched, error }
		} = this.props;
		const { option } = this.state;
		const value = mode === "multiple" && !input.value ? [] : input.value;
		return (
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				id={input.name}
				inline={inline}
				required={required}
				tooltip={tooltip}
				tooltipIcon={tooltipIcon}
				tooltipIconOnClick={tooltipIconOnClick}
			>
				<input type="hidden" id={input.name} {...input} value={value} />
				<Select
					placeholder={placeholder}
					popupMatchSelectWidth={false}
					mode={mode}
					value={value}
					style={style}
					onChange={(option) => this.changeValue(option)}
					options={options.map((option, index) => {
						const label = option.label ? option.label : option.value;
						const quantityLeft = option.quantityLeft || 0;
						const disabled = !(!trackQuantity || quantityLeft > 0);

						return {
							value: index,
							disabled: disabled,
							label: (
								<>
									{label} {quantityLeft > 0 && quantityLeft < 11 && <span>({quantityLeft} available)</span>}
									{trackQuantity && disabled && <span>(Not available)</span>}
								</>
							)
						};
					})}
				/>
				{option && option.descriptionRequired ? (
					<div style={{ marginTop: 12 }}>
						<InputLabel for={`${input.name}-description`} label="Please specify" />
						<Input id={`${input.name}-description`} onChange={(event) => this.changeDescription(event.target.value)} />
					</div>
				) : null}
			</InputWrapper>
		);
	}
}

export default SelectFieldWithOption;
