import { HumaniDate } from "@hx/dates";
import { notification } from "@/ui/antd";
import H3 from "../../components/text/H3";
import LocationService from "../../services/LocationService";
import TicketsService from "../../services/TicketsService";
import { getReport, UPDATE_REPORT } from "../actions/globalReportActions";

export const CHECKIN_REQUEST = "CHECKIN_REQUEST";
export const CHECKIN_SUCCESS = "CHECKIN_SUCCESS";
export const CHECKIN_ERROR = "CHECKIN_ERROR";

export const BULK_CHECKIN_REQUEST = "CHECKIN_REQUEST";
export const BULK_CHECKIN_SUCCESS = "CHECKIN_SUCCESS";
export const BULK_CHECKIN_ERROR = "CHECKIN_ERROR";

export const GET_CHECKIN_COUNT_REQUEST = "GET_CHECKIN_COUNT_REQUEST";
export const GET_CHECKIN_COUNT_SUCCESS = "GET_CHECKIN_COUNT_SUCCESS";
export const GET_CHECKIN_COUNT_ERROR = "GET_CHECKIN_COUNT_ERROR";

export const GET_SCANNING_COUNT_REQUEST = "GET_SCANNING_COUNT_REQUEST";
export const GET_SCANNING_COUNT_SUCCESS = "GET_SCANNING_COUNT_SUCCESS";
export const GET_SCANNING_COUNT_ERROR = "GET_SCANNING_COUNT_ERROR";

export const updateCheckIn = (id, ticketData) => {
	return (dispatch) => {
		dispatch({ type: CHECKIN_REQUEST, ticket: id });
		TicketsService.updateCheckIn(id, ticketData)
			.then((ticket) => {
				getCheckInCount(ticket.eventId)(dispatch);
				dispatch({
					type: UPDATE_REPORT,
					result: {
						_id: ticket._id,
						_checkedIn: ticket.checkIn.checkedIn,
						checkedIn: ticket.checkIn.checkedIn ? "Checked in" : "Not checked in",
						checkedInDate: ticket.checkIn.checkedIn
							? new HumaniDate(ticket.checkIn.date, "guess", LocationService.getLocation(true)).toString()
							: ""
					}
				});
				dispatch({ type: CHECKIN_SUCCESS });
				if (ticket.checkIn.checkedIn && ticket.scanningMessages) {
					notification.success({
						message: <H3>Ticket checked-in</H3>,
						description: (
							<>
								{ticket.scanningMessages.map((scanningMessage) => {
									return (
										<>
											<H3>{scanningMessage.header}</H3>
											<div dangerouslySetInnerHTML={{ __html: scanningMessage.message }} />
										</>
									);
								})}
							</>
						)
					});
				}
			})
			.catch((err) => {
				dispatch({ type: CHECKIN_ERROR, error: err });
			});
	};
};

export const disassociateCustomQrCode = (id) => {
	return async (dispatch) => {
		dispatch({ type: CHECKIN_REQUEST, ticket: id });
		try {
			const ticket = await TicketsService.disassociateCustomQrCode(id);
			dispatch({
				type: UPDATE_REPORT,
				result: {
					_id: ticket._id,
					_checkedIn: ticket.checkIn.checkedIn,
					_customQrCodeData: null,
					checkedIn: ticket.checkIn.checkedIn ? "Checked in" : "Not checked in",
					checkedInDate: ticket.checkIn.checkedIn
						? new HumaniDate(ticket.checkIn.date, "guess", LocationService.getLocation(true)).toString()
						: ""
				}
			});
			dispatch({ type: CHECKIN_SUCCESS });
		} catch (err) {
			dispatch({ type: CHECKIN_ERROR, error: err });
		}
	};
};

export const getCheckInCount = (eventId, dateId) => {
	return (dispatch) => {
		dispatch({ type: GET_CHECKIN_COUNT_REQUEST });
		TicketsService.checkInCount(eventId, dateId)
			.then((result) => {
				dispatch({ type: GET_CHECKIN_COUNT_SUCCESS, result });
			})
			.catch((err) => {
				dispatch({ type: GET_CHECKIN_COUNT_ERROR, error: err });
			});
	};
};

export const getScanningCount = (eventId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const search = state.search;
		dispatch({ type: GET_SCANNING_COUNT_REQUEST });
		try {
			const result = await TicketsService.getScanningCount(eventId, search);
			dispatch({ type: GET_SCANNING_COUNT_SUCCESS, result });
		} catch (err) {
			dispatch({ type: GET_SCANNING_COUNT_ERROR, error: err });
		}
	};
};
export const bulkCheckIn = (checkInState) => {
	return async (dispatch, getState) => {
		const state = getState();
		const search = state.search;
		dispatch({ type: BULK_CHECKIN_REQUEST });
		try {
			await TicketsService.bulkCheckIn(checkInState, search);
			dispatch({ type: BULK_CHECKIN_SUCCESS });
			getCheckInCount(state.currentEvent.event._id)(dispatch);
			getReport("attendee", 1)(dispatch, getState);
		} catch (err) {
			dispatch({ type: BULK_CHECKIN_ERROR, error: err });
		}
	};
};
