import { css } from "@emotion/css";
import { Menu } from "@/ui/antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import iconReference from "../services/IconReference";
import MenuService, { type MenuItem } from "../services/menuService/MenuService";
import { H4 } from "./text/Text";

interface ISideMenuProps {
	title?: string;
	separator?: any;
	menuItems: MenuItem[];
}

const SideMenu = ({ title, separator, menuItems }: ISideMenuProps) => {
	const location = useLocation();

	const wrapper = css({
		background: `#fff`,
		paddingTop: 12,
		h4: {
			padding: "14px 0 6px 38px",
			margin: "0 24px 12px",
			borderBottom: "var(--border1)",
			fontSize: "14px"
		}
	});

	const separatorStyle = {
		margin: "0 24px 12px",
		borderBottom: "var(--border1)",
		fontSize: "14px"
	};

	const selectedMenus = MenuService.getSelected(menuItems, location);
	const selectedKeys = selectedMenus.map((item) => item.path);
	const currentKeyString = MenuService.getKeyFromPath(location.pathname, menuItems);

	const subMenuOpenKeys = currentKeyString ? [currentKeyString] : [];

	const [expandedSubMenus, updateExpandedSubMenus] = useState<string[]>([]);

	//using .join(",") in the useEffect deps as useEffect expects the array of deps
	//be consistence in size, so we make one array of size 1 to only compare one string.
	//suggested by https://mattperry.is/writing-code/how-to-fix-useeffect-dependency-changed-size-error
	useEffect(() => {
		const uniqueCombination = [...new Set([...expandedSubMenus, ...subMenuOpenKeys])];
		updateExpandedSubMenus(uniqueCombination);
	}, [subMenuOpenKeys.join(",")]);

	const buildMenuItem = (item: MenuItem): MenuItem => {
		if (!item.children) {
			return {
				key: item.path,
				path: item.path,
				label: (
					<Link to={item.path} style={{ paddingLeft: "var(--spacing-md)" }}>
						{item.icon && iconReference[item.icon] ? (
							<img src={iconReference[item.icon].toString()} style={{ marginRight: 12, marginLeft: -12 }} />
						) : null}
						<span
							style={{
								fontSize: 14,
								color: item.label === "Create event" ? "#353337" : "#323232"
							}}
							data-cy={item.dataCy}
						>
							{item.label}
						</span>
					</Link>
				)
			};
		}
		const subMenuItems = item.children
			.filter((s) => s.hide !== true)
			.map((item) => {
				return buildMenuItem(item);
			});

		return {
			key: `humanitix-menu-subMenu-${item.label}`,
			path: item.path,
			label: (
				<span style={{ paddingLeft: "var(--spacing-md)" }}>
					{" "}
					{item.icon && iconReference[item.icon] ? (
						<img src={iconReference[item.icon].toString()} style={{ marginRight: 12, marginLeft: -12 }} />
					) : null}
					{item.label}{" "}
				</span>
			),
			children: subMenuItems
		};
	};

	const menuList = menuItems
		.filter((s) => s.hide !== true)
		.map((item) => {
			return buildMenuItem(item);
		});
	let menuTitle = title ? <H4>{title}</H4> : "";
	if (separator && !title) {
		menuTitle = <div style={separatorStyle} />;
	}
	return (
		<div className={wrapper}>
			{menuTitle}
			<Menu
				mode="inline"
				selectedKeys={selectedKeys}
				style={{
					height: "100%"
				}}
				items={menuList}
				onOpenChange={(currOpenKeys) => updateExpandedSubMenus(currOpenKeys)}
				openKeys={expandedSubMenus}
			/>
		</div>
	);
};
export default SideMenu;
