import { getConfig } from "@/config";
import { Button, CodeBlock, FormLabel, ResponsiveModal } from "@/ui";
import { Alert, Form, FormInstance, Switch } from "@/ui/antd";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

const eventsDomain = getConfig("EVENTS_URL");
const env = getConfig("NODE_ENV");
const widgetEnv = env === "production" ? "" : ` data-env="${env}"`;

type ConfigPanelWidgetProps = {
	form: FormInstance;
	open: boolean;
};

export const ConfigPanelWidget: FC<ConfigPanelWidgetProps> = ({ form, open }) => {
	const formValues = useWatch<Collection>([], form);
	const [showCodeModal, setShowCodeModal] = useState(false);

	const eventSlug = formValues?.info?.slug;

	const codeString = `<!-- Add the following to your website's HTML. -->
<script src="${eventsDomain}/scripts/widgets/inline.js" type="module"></script>
<iframe data-collection="${eventSlug}"${widgetEnv}></iframe>`;

	return (
		<div style={{ display: open ? "block" : "none" }}>
			{/* Register required fields for this component - Antd wont include in the field if it's not registered.  */}
			<Form.Item name={["published"]} noStyle />
			<Form.Item name={["info", "slug"]} noStyle />
			<FormLabel>Disable analytics integrations</FormLabel>
			<p>
				When active, any analytics integrations associated with this event will be disabled within the widget itself.{" "}
				<Link to="https://help.humanitix.com/en/articles/8950947">Learn more</Link>.
			</p>
			<Form.Item name={["analytics", "disableHumanitixGTM"]} noStyle valuePropName="checked">
				<Switch />
			</Form.Item>
			<br />
			<br />
			<FormLabel>Embedding the widget on your site</FormLabel>
			<p>
				You can embed the collection onto a page by clicking the "Add to site" button below and inserting the widget
				code onto your site.
			</p>
			<Alert
				description="You must publish your collection before adding the widget to your site"
				showIcon
				type="warning"
			/>
			<br />
			<Button onClick={() => setShowCodeModal(true)} variant="secondary">
				Add to site
			</Button>
			<ResponsiveModal
				primaryButtonText="Done"
				onCancel={() => setShowCodeModal(false)}
				primaryButtonAction={() => setShowCodeModal(false)}
				open={showCodeModal}
				header="Copy code to your site"
				width="800px"
			>
				<CodeBlock codeString={codeString} language="htmlbars" title="HTML" />
			</ResponsiveModal>
		</div>
	);
};
