import axios from "./AxiosWrapper";

class TicketService {
	static instance = null;

	static createInstance() {
		let object = new TicketService();
		return object;
	}

	static getInstance() {
		if (!TicketService.instance) {
			TicketService.instance = TicketService.createInstance();
		}
		return TicketService.instance;
	}

	async updateCheckIn(id, checkedIn) {
		const date = new Date();
		const response = await axios.post(`tickets/check-in/${id}`, {
			checkedIn,
			date: date.toISOString()
		});
		return response.data;
	}

	async disassociateCustomQrCode(id) {
		const response = await axios.post(`tickets/${id}/disassociate`);
		return response.data;
	}

	async checkInCount(eventId, dateId) {
		const response = await axios.get(`tickets/count/`, {
			params: { dateId, eventId }
		});
		const result = response.data;
		return result;
	}

	async getScanningCount(eventId, search) {
		const response = await axios.get(`tickets/scanning-count/`, {
			params: { eventId, search }
		});
		const result = response.data;
		return result;
	}
	async bulkCheckIn(checkInState, search) {
		const response = await axios.post(
			`tickets/bulk-check-in`,
			{
				checkInState,
				search
			},
			{
				params: { eventId: search.eventIds[0] }
			}
		);
		return response.data;
	}
}

export default TicketService.getInstance();
