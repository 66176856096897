import { Dropdown } from "@/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import { SketchPicker, ColorResult } from "react-color";

const StyledContainer = styled.div`
	.sketch-picker {
		box-sizing: border-box !important;
	}
`;

const StyledSwatch = styled.div<{
	large?: boolean;
	marginTop: number;
}>`
	${({ large, marginTop }) => css`
		background: #fff;
		border-radius: 1px;
		border: 1px solid #8d949e;
		cursor: pointer;
		display: inline-block;
		margin-left: 1;
		margin-top: ${marginTop}px;
		padding: ${large ? "1px" : "5px"};
	`}
`;

const StyledColourPatch = styled.div<{
	large?: boolean;
	value?: string;
}>`
	${({ large, value }) => css`
		background: ${value};
		border-radius: 2px;
		height: ${large ? "36px" : "14px"};
		width: ${large ? "60px" : "36px"};
	`}
`;

type ColorPickerProps = {
	large?: boolean;
	marginTop?: number;
	onChange?: (color: any) => void;
	value?: string;
};

const ColourPicker: FC<ColorPickerProps> = ({ onChange, value, marginTop = 0, large }) => {
	const [selectedColor, setSelectedColor] = useState("#aaa");

	const handleChange = (color: ColorResult) => {
		setSelectedColor(color.hex);
		onChange && onChange(color);
	};

	return (
		<Dropdown
			items={
				<StyledContainer>
					<SketchPicker color={selectedColor} onChange={handleChange} width="100%" />
				</StyledContainer>
			}
			maxWidth={280}
			noPadding
			persist
		>
			<StyledSwatch large={large} marginTop={marginTop}>
				<StyledColourPatch large={large} value={value} />
			</StyledSwatch>
		</Dropdown>
	);
};

export default ColourPicker;
