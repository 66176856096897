import { Col, Flex, Row } from "@/ui/antd";
import { Component } from "react";
import { Field } from "redux-form";

import { InputNumberField, RadioField, SelectField } from "@/components/Fields";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { required } from "@/utils/Validators";
import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";

class TicketsDateRange extends Component {
	startAtRequired = required("A start at day is required");
	endAtRequired = required("A end at day is required");

	checkStartDate = () => {
		const { index, touch, path } = this.props;
		touch(`${path}[${index}].endSaleDate`);
		return undefined;
	};

	checkEndAt = (value, allValues) => {
		const { index, path } = this.props;
		const ticketType = allValues[path][index];
		if (!ticketType) {
			return undefined;
		}
		const startSaleAtDays = ticketType.startSaleAt ? ticketType.startSaleAt : 0;
		const startSaleAtHours = ticketType.startSaleAtHours ? ticketType.startSaleAtHours : 0;

		const endSaleAtDays = ticketType.endSaleAt ? ticketType.endSaleAt : 0;
		const endSaleAtHours = ticketType.endSaleAtHours ? ticketType.endSaleAtHours : 0;

		const oneHour = 1 / 24;
		const startSaleAt = startSaleAtDays + startSaleAtHours * oneHour;
		const endSaleAt = endSaleAtDays + endSaleAtHours * oneHour;

		if (!startSaleAt || !endSaleAt) {
			return undefined;
		}
		const result = endSaleAt > startSaleAt ? `Sales must start before they end` : undefined;
		return result;
	};

	recheckEndDate = () => {
		const { touch, fieldPath, startField = "endSaleAt" } = this.props;
		const startPath = fieldPath ? `${fieldPath}.endSaleAt` : startField;
		touch(startPath);
		return undefined;
	};

	checkEndDate = (endSaleDate, allValues) => {
		const { index, path } = this.props;
		const ticketType = allValues[path][index];
		if (!ticketType) {
			return undefined;
		}
		const startDate = ticketType.startSaleDate;
		if (!startDate || !endSaleDate) {
			return undefined;
		}
		const result = endSaleDate < startDate ? `Must be after your start date` : undefined;
		return result;
	};

	getFields() {
		const { ticket, index, timezone, path, ticketTypes = [], packagedTickets = [], formName } = this.props;
		const allTickets = ticketTypes.concat(packagedTickets);
		const validTickets = allTickets
			? allTickets.filter((t) => t && !t.deleted && !t.isDonation && !t.disabled && t._id !== ticket._id)
			: false;
		const startWhenOptions = validTickets.map((t) => {
			const value = { label: t.name, value: t._id };
			return value;
		});

		const { scheduleType } = ticket;
		const at = {
			start: (
				<Field
					component={ReduxDateTimePicker}
					description="The date and time sales will start"
					displayFormat="Do MMM YYYY"
					formName={formName}
					label="Sales start"
					name={`${path}[${index}].startSaleDate`}
					placeholder="Start date"
					showTime
					timeOptions={{ placeholder: "Start time" }}
					timezone={timezone}
					validate={this.checkStartDate}
				/>
			),
			end: (
				<Field
					component={ReduxDateTimePicker}
					description="The date and time sales will end"
					displayFormat="Do MMM YYYY"
					formName={formName}
					label="Sales end"
					name={`${path}[${index}].endSaleDate`}
					placeholder="End date"
					showTime
					timeOptions={{ placeholder: "End time" }}
					timezone={timezone}
					validate={this.checkEndDate}
				/>
			)
		};

		const atMessageString = (before) => {
			const daysField = before ? "startSaleAt" : "endSaleAt";
			const hoursField = before ? "startSaleAtHours" : "endSaleAtHours";

			const daysValue = ticket[daysField] ? ticket[daysField] : 0;
			const hoursValue = ticket[hoursField] ? ticket[hoursField] : 0;
			const oneHour = 1 / 24;
			const valueInDays = daysValue + hoursValue * oneHour;
			const pointInTimeStr = before ? "start" : "end";
			const startStr = `Sales will ${pointInTimeStr}`;

			if (valueInDays === 0) {
				return `Set a custom sales ${pointInTimeStr} time, days/hours before your event begins.`;
			}
			const daysPlural = daysValue > 1 ? "s" : "";
			const hoursPlural = hoursValue > 1 ? "s" : "";
			const timeParts = [];
			if (daysValue) {
				timeParts.push(`${daysValue} day${daysPlural}`);
			}
			if (hoursValue) {
				timeParts.push(`${hoursValue} hour${hoursPlural}`);
			}
			const timeStr = timeParts.join(", and ");
			return `${startStr} ${timeStr} before each event occurrence`;
		};

		const before = {
			start: (
				<div>
					<InputLabel label="Start at" description={atMessageString(true)} />
					<div style={{ display: "flex" }}>
						<Field
							name={`${path}[${index}].startSaleAt`}
							component={InputNumberField}
							placeholder="Days"
							allowsNullValue
							min={0}
							style={{ marginRight: 8 }}
							validate={this.recheckEndDate}
						/>
						<Field
							name={`${path}[${index}].startSaleAtHours`}
							component={InputNumberField}
							placeholder="Hours"
							allowsNullValue
							min={0}
							max={23.99}
							validate={this.recheckEndDate}
						/>
					</div>
				</div>
			),
			end: (
				<div>
					<InputLabel label="End at" description={atMessageString(false)} />
					<div style={{ display: "flex" }}>
						<Field
							name={`${path}[${index}].endSaleAt`}
							component={InputNumberField}
							placeholder="Days"
							allowsNullValue
							min={0}
							validate={this.checkEndAt}
							style={{ marginRight: 8 }}
						/>
						<Field
							name={`${path}[${index}].endSaleAtHours`}
							component={InputNumberField}
							placeholder="Hours"
							allowsNullValue
							min={0}
							max={23.99}
							validate={this.recheckEndDate}
						/>
					</div>
				</div>
			)
		};

		const when = {
			start: (
				<Field
					label="Start after"
					description="Start after the selected ticket is sold out"
					name={`${path}[${index}].startWhen`}
					component={SelectField}
					options={startWhenOptions}
					placeholder="Start after"
					min={0}
					allowsNullValue
				/>
			),
			end: null
		};

		switch (scheduleType) {
			case "at":
				return at;
			case "before":
				return before;
			case "when":
				return when;
			default:
				return null;
		}
	}

	render() {
		const { index, path } = this.props;
		const fields = this.getFields();
		const isBrowser = typeof window !== "undefined";
		const scheduleToggle = (
			<Field
				label="Sales start/end"
				name={`${path}[${index}].scheduleType`}
				component={RadioField}
				description={
					<p>
						By default, sales start when your event is published
						<br /> and end when your event ends
					</p>
				}
				tooltip="Set a delayed start date or early end date for your ticket sales. Otherwise, leave this blank."
				bold
				tooltipIcon
				button
				options={[
					{ label: "At", value: "at" },
					{ label: "Before", value: "before" },
					{ label: "When", value: "when" }
				]}
				size="default"
			/>
		);
		let options = null;
		if (fields) {
			if (isBrowser && window.innerWidth > 600) {
				options = (
					<Flex gap="md">
						{fields.start}
						{fields.end}
					</Flex>
				);
			} else {
				options = (
					<>
						{" "}
						<div style={{ width: "100%" }}>{fields.start}</div>
						<div style={{ width: "100%" }}>{fields.end}</div>
					</>
				);
			}
		}

		return isBrowser && window.innerWidth > 600 ? (
			<Row>
				<Col span={24}>{scheduleToggle}</Col>
				{options}
			</Row>
		) : (
			<div>
				{scheduleToggle}
				{options}
			</div>
		);
	}
}
export default TicketsDateRange;
