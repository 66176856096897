import { Table } from "@/ui/antd";
import { Component } from "react";
import withReportProvider from "../../hoc/withReportProvider";

import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { currency } from "../../utils/Format";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

class TopPurchasers extends Component {
	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;

		const { load, loadPage, downloadCsv } = this.props;

		const columns = [
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Total orders",
				dataIndex: "totalOrders",
				key: "totalOrders"
			},
			{
				title: "Ticket earnings",
				dataIndex: "ticketEarnings",
				key: "ticketEarnings",
				render: (value) => currency(value)
			},
			{
				title: "Add-on earnings",
				dataIndex: "addOnEarnings",
				key: "addOnEarnings",
				render: (value) => currency(value)
			},
			{
				title: "Donation earnings",
				dataIndex: "donationEarnings",
				key: "donationEarnings",
				render: (value) => currency(value)
			}
		];
		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						showSearchField={false}
						fistDateName="orderDates"
						firstDateLabel="Order date range"
					/>
					{results && (
						<ReportContent>
							<div
								style={{
									marginBottom: 16,
									textAlign: "right",
									display: "flex",
									justifyContent: "flex-end"
								}}
							>
								{results.length > 0 && (
									<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
										{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
									</LegacyButton>
								)}
							</div>
							<Table
								columns={columns}
								dataSource={results}
								loading={loading}
								locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
								rowKey="_id"
								scroll={{ x: "max-content" }}
								pagination={{
									showSizeChanger: false,
									total: count,
									current: page,
									onChange: loadPage
								}}
							/>
						</ReportContent>
					)}
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default withReportProvider(TopPurchasers, "Top purchasers", "top-purchasers");
