import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Radio, Select } from "@/ui/antd";
import { css } from "@emotion/css";
import { useState } from "react";
import SearchRefinements from "../components/SearchRefinements";
import ArchiveEventModal from "../events/event/header/ArchiveEventModal";
import DuplicateModal from "../events/event/header/DuplicateModal";
import TransferEventModal from "../events/event/header/TransferEventModal";
import { findEvents, openArchiveModal, openTransferEventModal } from "../state/actions/eventActions";
import { updateSearch } from "../state/actions/searchActions";
import { ContentBlock } from "./AppBlocks";
import EventRow from "./EventRow";
import LoadErrorView from "./LoadErrorView";
import LocationSelection from "./LocationSelection";
import SimplePagination from "./SimplePagination";
import NoResults from "./noResults/NoResults";

const RadioGroup = Radio.Group;

const searchRow = css`
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
	@media (max-width: 600px) {
		display: block;
		margin-bottom: 0px;
	}
`;

const optionsRow = css`
	margin-bottom: 0px;
`;

const dateOptions = css`
	margin-right: 12px;
	@media (max-width: 600px) {
		margin-top: 10px;
		width: 100%;
		max-width: 100%;
		text-align: left;
	}
`;

const shortOptions = css`
	display: inline-block;
	vertical-align: top;
	width: 140px;
	font-size: 14px !important;
	@media (max-width: 600px) {
		margin-top: 10px;
		width: 100%;
	}
`;

const searchBarStyle = css({
	display: "inline-block",
	maxWidth: 400,
	verticalAlign: "top",
	width: "100%",
	marginRight: 12,
	"@media(max-width: 600px)": {
		maxWidth: "none",
		marginBottom: 12
	}
});

const UserEvents = ({ userId }: { userId: string }) => {
	const dispatch = useAppDispatch();
	const events = useAppSelector((state) => state.events);
	const search = useAppSelector((state) => state.search);
	const transferEventState = useAppSelector((state) => state.currentEvent.transferEventState);
	const archive = useAppSelector((state) => state.currentEvent.archive);
	const user = useAppSelector((state) => state.auth.user);

	const [state, setState] = useState<{
		duplicateOpen: boolean;
		currentEvent: any;
	}>({
		duplicateOpen: false,
		currentEvent: null
	});

	const defaultSearch = {
		sortOrder: "newest",
		filter: "all",
		userId,
		query: ""
	};

	const { events: userEvents, loading, error, page } = events;
	const { duplicateOpen, currentEvent } = state;

	const _load = (page = 1) => {
		dispatch(findEvents(page, { ...defaultSearch, ...search }));
	};

	const _loadPage = (page: number) => {
		dispatch(updateSearch({ page }, true));
	};

	const _toggleDuplicate = (eventName: string, eventId: string) =>
		setState((current) => ({
			...current,
			duplicateOpen: !current.duplicateOpen,
			currentEvent: {
				event: {
					name: eventName,
					_id: eventId
				}
			}
		}));

	const _toggleArchive = ({ _id, name, isArchived, published, endDate }: any) => {
		setState((current) => ({
			...current,
			currentEvent: {
				event: {
					_id,
					name,
					isArchived,
					published,
					endDate
				}
			}
		}));

		openArchiveModal && dispatch(openArchiveModal());
	};

	const sortOrderOptions = [
		{ label: "Most Recent", value: "newest" },
		{ label: "Least Recent", value: "oldest" },
		{ label: "Upcoming", value: "upcoming" }
	];

	return (
		<LoadErrorView loading={loading} error={error} retryAction={_load}>
			<div>
				<ContentBlock>
					<div className={searchRow}>
						<div className={searchBarStyle}>
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore */}
							<SearchRefinements load={_load} defaultSearch={defaultSearch} />
						</div>

						<LocationSelection
							componentName="userEventSearch"
							defaultLevel="user"
							onLocationChange={() => {
								_load(1);
							}}
						/>
					</div>

					<div className={optionsRow}>
						<RadioGroup
							defaultValue="all"
							value={search.filter || "all"}
							buttonStyle="solid"
							optionType="button"
							onChange={(e) => dispatch(updateSearch({ filter: e.target.value }, true))}
							className={dateOptions}
							options={[
								{ label: "All", value: "all" },
								{ label: "Live", value: "published" },
								{ label: "Draft", value: "unpublished" },
								{ label: "Past", value: "past" },
								{ label: "Archived", value: "archived" }
							]}
						/>
						<Select
							className={shortOptions}
							style={{ fontSize: 14 }}
							value={search.sortOrder || "newest"}
							onChange={(value) => dispatch(updateSearch({ sortOrder: value }, true))}
							options={sortOrderOptions}
						/>
					</div>
				</ContentBlock>

				{!userEvents.length ? (
					!loading ? (
						<NoResults title="No events found" />
					) : null
				) : (
					userEvents.map((event: any) => (
						<EventRow
							{...event}
							isPrivate={!event.public}
							user={user}
							key={event._id}
							openTransferEventModal={(id: string) => dispatch(openTransferEventModal(id))}
							toggleDuplicate={_toggleDuplicate}
							toggleArchive={_toggleArchive}
						/>
					))
				)}
				<DuplicateModal visible={duplicateOpen} handleCancel={_toggleDuplicate} currentEvent={currentEvent} />
				{archive.modalVisible ? <ArchiveEventModal event={currentEvent.event} shouldReloadEventList /> : null}

				<SimplePagination currentPage={page} pageAction={_loadPage} style={{ float: "right", marginTop: 24 }} />
				<div style={{ clear: "both" }} />
			</div>
			{transferEventState.modalOpen && <TransferEventModal />}
		</LoadErrorView>
	);
};

export default UserEvents;
