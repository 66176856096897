import { formValueSelector } from "redux-form";
import history from "@/history";
import store from "@/state/store";

const BasicsKey = "basics";
const RecurringDatesKey = "schedule";
const DetailsKey = "details";
const TicketsKey = "tickets";

export const checkInWizard = (event) => {
	const inWizard =
		!event || !event._id || (event.eventCreationSteps && !event.eventCreationSteps.complete) ? true : false;
	return inWizard;
};

export const goToNextStep = () => {
	const state = store.getState();
	const event = state && state.currentEvent && state.currentEvent.event;
	const id = event && event._id;
	const stepsForEventCreation = getStepsForEventCreation();
	const currentStepIndex = stepsForEventCreation.findIndex((step) => step.status === "active");
	let nextStepIndex = currentStepIndex + 1;
	const maxIndex = stepsForEventCreation.length - 1;
	nextStepIndex = nextStepIndex > maxIndex ? maxIndex : nextStepIndex;
	const nextStep = stepsForEventCreation[nextStepIndex];
	if (nextStep) {
		history.push(nextStep.to);
	} else {
		history.push(`/console/my-events/${id}/info/basics`);
	}
};

export const goToPreviousStep = () => {
	const state = store.getState();
	const event = state && state.currentEvent && state.currentEvent.event;
	const id = event && event._id;
	const stepsForEventCreation = getStepsForEventCreation();
	const currentStepIndex = stepsForEventCreation.findIndex((step) => step.status === "active");
	let previousStepIndex = currentStepIndex - 1;
	previousStepIndex = previousStepIndex < 0 ? 0 : previousStepIndex;
	const nextStep = stepsForEventCreation[previousStepIndex];
	if (nextStep) {
		history.push(nextStep.to);
	} else {
		history.push(`/console/my-events/${id}/info/basics`);
	}
};

const getCurrentStepKey = () => {
	const pathname = history && history.location && history.location.pathname;
	if (!pathname || pathname.includes("/basics")) return BasicsKey;
	if (!pathname || pathname.includes("/recurring-dates")) return RecurringDatesKey;
	if (!pathname || pathname.includes("/details")) return DetailsKey;
	if (!pathname || pathname.includes("/ticket-types")) return TicketsKey;
};

export const getStepsForEventCreation = () => {
	const pathname = history && history.location && history.location.pathname;
	const state = store.getState();
	const id = (state && state.currentEvent && state.currentEvent.event && state.currentEvent.event._id) || false;
	const eventRecurring = id
		? formValueSelector("basics")(state, "isRecurring") ||
		  pathname.includes("recurring-dates") ||
		  (state && state.currentEvent && state.currentEvent.event && state.currentEvent.event.isRecurring)
		: formValueSelector("basics")(state, "isRecurring");
	const activeStepKey = getCurrentStepKey();
	const scheduleStep = eventRecurring
		? [
				{
					label: "Schedule",
					key: RecurringDatesKey,
					to: `/console/my-events/${id}/info/recurring-dates`,
					displayTick: true
				}
		  ]
		: [];

	const baseSteps = [
		{
			label: "Basics",
			key: BasicsKey,
			to: `/console/my-events/${id}/info/basics`,
			displayTick: true
		},
		...scheduleStep,
		{
			label: "Details",
			key: DetailsKey,
			to: `/console/my-events/${id}/info/details`,
			displayTick: true
		},
		{
			label: "Ticket types",
			key: TicketsKey,
			to: `/console/my-events/${id}/info/ticket-types`,
			displayTick: true
		}
	];
	if (!id) {
		return baseSteps.map((step) =>
			step.key === "basics" ? { ...step, status: "active" } : { ...step, status: "disabled" }
		);
	}
	//look what they made me do to my boy
	let activeKeyReached = false;
	return baseSteps.map((step) => {
		step.status = "enabled";
		if (step.key === activeStepKey) {
			activeKeyReached = true;
			step.status = "active";
		}
		if (!step.displayTick && !activeKeyReached) {
			step.displayTick = true;
		}
		return step;
	});
};
