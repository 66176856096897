import { Component } from "react";
import { connect } from "react-redux";
import { BaseItem } from "./CustomerItems";

class CustomerManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showBasePanel: true,
			showSeatSelection: false
		};
	}

	render() {
		const { seatingMap } = this.props;
		return <div style={{ margin: "auto", width: "100%" }}>{!seatingMap.loading ? <BaseItem /> : null}</div>;
	}
}

export default connect(
	(state) => ({
		seatingMap: state.seatingMap
	}),
	{}
)(CustomerManager);
