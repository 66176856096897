import { IconButton } from "../components/IconButton";
import ToolTip from "../components/ToolTip";
import { IconNames } from "../icons";

export interface UndoRedoProps {
	onRedo: () => void;
	onUndo: () => void;
	hasUndo: boolean;
	hasRedo: boolean;
}

const UndoRedo = ({ onRedo, onUndo, hasUndo, hasRedo }: UndoRedoProps) => {
	return (
		<div
			style={{ boxShadow: "0 0 1px 0 #f1f1f1, 0 2px 3px 0 rgba(32, 7, 64, 0.13)", borderRadius: 4, display: "flex" }}
		>
			<div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
				<ToolTip message="Undo">
					<div
						style={{
							width: 36,
							height: 36
						}}
					>
						<IconButton disabled={!hasUndo} onClick={onUndo} toggleAble={true} icon={IconNames.undo} />
					</div>
				</ToolTip>
			</div>
			<div style={{ width: 1, height: 36, borderLeft: "solid 1px #dedede" }} />
			<div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
				<ToolTip message="Redo">
					<div
						style={{
							width: 36,
							height: 36
						}}
					>
						<IconButton disabled={!hasRedo} onClick={onRedo} toggleAble={true} icon={IconNames.redo} />
					</div>
				</ToolTip>
			</div>
		</div>
	);
};

export default UndoRedo;
