import { SelectField, SwitchField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { useAuth } from "@/hooks/useAuth";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { getUser } from "@/state/actions/userActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Alert, Tooltip } from "@/ui/antd";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { css } from "@emotion/css";
import { BankAccounts } from "@hx/utilities";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Field } from "redux-form";

const bankList = css({
	display: "flex",
	"@media(max-width: 900px)": {
		display: "block"
	}
});

export const EventBankAccount = () => {
	const currentUser = useAppSelector((state) => state.currentUser);
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const authUser = useAppSelector((state) => state.auth.user);
	const dispatch = useAppDispatch();

	useEffect(() => {
		loadUser();
	}, []);

	const navigate = useNavigate();
	const { assumingIdentity } = useAuth();
	const { isAdmin } = useCurrentUser();
	const loadUser = () => {
		dispatch(getUser(currentEvent?.event?.userId));
	};

	const createNewAccount = () => {
		navigate(`/console/account/profile/bank-accounts/new?redirect=${encodeURIComponent(window.location.pathname)}`);
	};

	const bankAccountsList = currentUser.user && currentUser.user.bankAccounts ? currentUser.user.bankAccounts : [];
	const accountOptions = bankAccountsList
		.filter((account: any) => account.location === currentEvent?.event?.location)
		.map((account: any) => {
			const bankAccountParts = BankAccounts.getDisplayParts(
				account.bsb,
				account.number,
				account.location,
				account.name
			);
			return {
				value: account._id,
				label: bankAccountParts.join(", ")
			};
		});

	const isEventOwner = !!(
		!assumingIdentity &&
		(!authUser || !currentUser || !currentUser.user || authUser?.id === currentUser.user.id)
	);

	const bankAccountLockedByAdmin = currentUser.user ? currentUser.user.bankAccountLockedByAdmin : false;
	const bankAccountLockedByEventOwner = currentEvent?.event?.paymentOptions.bankAccountLocked;
	const disableBankAccountCreation = !!(
		(!isEventOwner && bankAccountLockedByEventOwner) ||
		bankAccountLockedByAdmin ||
		!isEventOwner
	);
	const lockedMsg = bankAccountLockedByAdmin
		? "Bank account is locked. Please contact Humanitix if you wish to unlock."
		: "Bank account is locked please contact the event owner";

	let addMsg = "Add a new bank account";
	if (assumingIdentity) {
		addMsg = "Bank account can only the be changed by the event owner";
	} else if (!isEventOwner) {
		addMsg = `Only the event owner (${currentUser.user.email}) can add new bank accounts`;
	}

	let alertMessage: any = "Event payouts must be paid to a bank account local to the event's selected currency.";
	if (disableBankAccountCreation) {
		alertMessage = (
			<>
				{alertMessage}
				<br />
				{addMsg}
			</>
		);
	}
	return (
		<LoadErrorView loading={currentUser.loading} error={currentUser.error as any} retryAction={loadUser}>
			<div>
				<div className={bankList}>
					<div style={{ width: "100%" }}>
						<Field
							name="userBankAccountId"
							label="Bank account"
							description="Humanitix will transfer your ticket sales into this account"
							component={SelectField}
							options={accountOptions}
							style={{ width: "100%" }}
							disabled={bankAccountLockedByEventOwner}
						/>
						<Alert showIcon message={alertMessage} type="warning" style={{ marginBottom: 12 }} />
						<Alert
							showIcon
							message={
								<>
									<b>Does your team need to be notified of event payouts? </b>
									<div>Automatically send copies of payout receipts to your co-hosts or accounts team.</div>
									<div>
										<Link to={`/console/my-events/${String(currentEvent?.event?._id)}/alerts`}>
											Edit your notifications
										</Link>{" "}
										or <a onClick={OpenHelpHandler(HelpIds.eventNotifications)}>learn more</a> about notification
										settings.
									</div>
								</>
							}
							type="warning"
							style={{ margin: "12px 0 12px 0" }}
						/>
					</div>
					<div style={{ display: "flex" }}>
						{isEventOwner || isAdmin ? (
							<div style={{ marginLeft: 12 }}>
								<Tooltip title="Lock the banking details so only you can change them">
									<Field
										name="bankAccountLocked"
										label="Lock Bank Account"
										component={SwitchField}
										disabled={!isEventOwner}
									/>
								</Tooltip>
							</div>
						) : null}
						{isAdmin ? (
							<div style={{ marginLeft: 12 }}>
								<Tooltip title="Lock the banking details so no users can change them">
									<Field name="bankAccountLockedByAdmin" label="Locked by Admin" component={SwitchField} />
								</Tooltip>
							</div>
						) : null}
					</div>
				</div>
				<ButtonsBar>
					<Tooltip title={addMsg}>
						<LegacyButton
							icon={{ name: "plus", left: true }}
							onClick={createNewAccount}
							disabled={disableBankAccountCreation}
							type="action"
							ariaLabel="Create New Account"
						>
							Create new account
						</LegacyButton>
					</Tooltip>
					{bankAccountLockedByAdmin || bankAccountLockedByEventOwner ? (
						<Tooltip title={lockedMsg}> {/* <Icon icon="lock" style={{ fontSize: 20, marginTop: 0 }} /> */}</Tooltip>
					) : null}
				</ButtonsBar>
			</div>
		</LoadErrorView>
	);
};
