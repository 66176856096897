import {
	CLEAR_TAG,
	DELETE_TAG_ERROR,
	DELETE_TAG_REQUEST,
	DELETE_TAG_SUCCESS,
	FIND_TAGS_ERROR,
	FIND_TAGS_REQUEST,
	FIND_TAGS_SUCCESS,
	FIND_TAG_ERROR,
	FIND_TAG_REQUEST,
	FIND_TAG_SUCCESS,
	SAVE_TAG_ERROR,
	SAVE_TAG_REQUEST,
	SAVE_TAG_SUCCESS
} from "../actions/tagActions";

const initialState = {
	tags: [],
	tag: null,
	loading: false,
	error: false,
	saved: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		//save
		case SAVE_TAG_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_TAG_SUCCESS: {
			const oldTags = state.tags;
			const oldTag = oldTags.find((x) => x._id === action.tag._id);
			let newTags;
			if (oldTag) {
				oldTags.splice(oldTags.indexOf(oldTag), 1, action.tag);
				newTags = oldTags;
			} else {
				newTags = state.tags.concat(action.tag);
			}
			return {
				...state,
				loading: false,
				saved: true,
				tags: newTags,
				tag: action.tag
			};
		}
		case SAVE_TAG_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		//find
		case FIND_TAGS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case FIND_TAGS_SUCCESS:
			return {
				...state,
				loading: false,
				saved: true,
				tags: action.tags
			};
		case FIND_TAGS_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		//delete
		case DELETE_TAG_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case DELETE_TAG_SUCCESS:
			return {
				...state,
				loading: false,
				saved: true,
				tags: action.tags
			};
		case DELETE_TAG_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		//find single
		case FIND_TAG_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case FIND_TAG_SUCCESS:
			return {
				...state,
				loading: false,
				saved: true,
				tag: action.tag
			};
		case FIND_TAG_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		//Clear tag
		case CLEAR_TAG:
			return {
				...state,
				tag: null
			};
		default:
			return state;
	}
};
