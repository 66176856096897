import { getConfig } from "@/config";
import { PreviewMockSite, PreviewMode } from "@/ui";
import { Collection } from "@hx/console";
import { FC, ReactNode, useEffect, useState } from "react";

type PreviewCollectionWidgetProps = {
	activeCollection: Collection;
	banner?: ReactNode | null;
	isActive: boolean;
};

export const PreviewCollectionWidget: FC<PreviewCollectionWidgetProps> = ({ activeCollection, banner, isActive }) => {
	const [previewMode, setPreviewMode] = useState<PreviewMode>("desktop");

	// There is too much focus chaos going on with nested iframes here so need to
	// use state to manage the ref to ensure that the script is loaded on mount.
	const [collectionRef, setCollectionRef] = useState<HTMLIFrameElement | null>(null);

	useEffect(() => {
		const loadScript = () => {
			if (!collectionRef?.parentElement) {
				return;
			}

			const parentElement = collectionRef.parentElement;
			const scriptUrl = `${getConfig("EVENTS_URL")}/scripts/widgets/inline.js`;
			if (parentElement.querySelector(`script[src="${scriptUrl}"]`)) {
				return;
			}

			const script = document?.createElement("script");
			script.src = scriptUrl;
			parentElement.appendChild(script);
		};

		loadScript();
	}, [collectionRef, isActive]);

	const skeletonStyles = {
		backgroundColor: "#dcdcdc",
		borderRadius: "4px",
		height: "100%",
		width: "100%"
	};

	return (
		<PreviewMockSite banner={banner} noGutters previewMode={previewMode} setPreviewMode={setPreviewMode}>
			{/* Can't use emotion for styling here because this is in an iframe */}
			<div
				style={{
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					height: "100%"
				}}
			>
				<div
					style={{
						borderRadius: "8px",
						display: "flex",
						flexDirection: "column",
						gap: "16px",
						height: "92%",
						margin: "12px",
						transition: "all 0.3s ease-in-out",
						width: "90%"
					}}
				>
					<div style={{ display: "flex", gap: "8px", minHeight: "16px" }}>
						<div style={{ ...skeletonStyles, width: "25%" }} />
						<div style={{ ...skeletonStyles, width: "15%", borderRadius: "8px", margin: "0 0 0 auto" }} />
						<div style={{ ...skeletonStyles, width: "15%", borderRadius: "8px" }} />
					</div>
					<div style={{ ...skeletonStyles, minHeight: "54px" }} />
					<iframe
						data-env={getConfig("NODE_ENV")}
						data-collection={activeCollection?.info?.slug}
						height="200%"
						ref={(ref) => setCollectionRef(ref)}
						style={{
							backgroundColor: "#fff",
							borderRadius: "16px",
							border: "none",
							display: "flex",
							overflow: "hidden",
							transform: "scale(0.5) translate(-50%, -50%)"
						}}
						width="200%"
					/>
				</div>
				<div style={{ ...skeletonStyles, borderRadius: "4px 4px 0px 0px", minHeight: "54px", width: "90%" }} />
			</div>
		</PreviewMockSite>
	);
};
