import { Alert } from "@/ui/antd";
import { Component } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { FileUploadField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { cleanValue, loadCsv } from "@/utils/FileUtils";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { checkCsvIsValid } from "../utils/BulkUploadValidator";
class LoadFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: []
		};
	}

	upload = async (value) => {
		const csv = await loadCsv(value.csvFile[0]);
		const ticketTypeNames = this.props.currentEvent.event.ticketTypes
			.filter((ticketType) => {
				return !ticketType.disabled && !ticketType.deleted && !ticketType.isDonation;
			})
			.map((ticketType) => ticketType.name);
		const errors = checkCsvIsValid(csv, ticketTypeNames);
		const examples = this.getExampleArray(csv);
		if (errors.length === 0) {
			this.props.onNextStep({
				csv,
				eventDateId: value.eventDateId,
				emailOrder: value.emailOrder,
				examples
			});
		} else {
			this.setState({ errors });
		}
	};

	getExampleArray(csv) {
		const index = csv[0].findIndex((header) => {
			return cleanValue(header) === "tickettype";
		});
		const example = [];
		csv.forEach((row, i) => {
			if (i > 0) {
				if (!(example.findIndex((ex) => ex[index] === row[index]) > -1)) {
					example.push(row);
				}
			}
		});
		return example;
	}
	getTemplateCsv = () => {
		const exampleOrderIds = ["1", "1", "", "2", "461zG3!", "461zG3!", "", "4", "4", "4"];
		const { columns, currentEvent } = this.props;
		const headers = [columns.map((c) => c.name)];
		const ticketTypeNames = currentEvent.event.ticketTypes
			.filter((ticketType) => {
				return !ticketType.disabled && !ticketType.deleted && !ticketType.isDonation;
			})
			.map((ticketType, index) => [exampleOrderIds[index] || "", ticketType.name]);
		return headers.concat(ticketTypeNames);
	};

	render() {
		const { handleSubmit, currentEvent } = this.props;
		const eventName = currentEvent.event.name;
		const dateOptions = currentEvent.event.dates
			.filter((date) => !date.disabled && !date.deleted)
			.map((date) => {
				return {
					label: date.display.combined,
					value: date._id
				};
			});
		const errors = this.state.errors;
		return (
			<div>
				<form onSubmit={handleSubmit(this.upload)}>
					<ContentBlock>
						{errors.length > 0 ? (
							<Alert
								style={{ marginBottom: 12 }}
								message="CSV file invalid"
								description={
									<div>
										{errors.map((error) => (
											<div>
												* {error}
												<br />
											</div>
										))}
									</div>
								}
								type="error"
								showIcon
							/>
						) : (
							<Alert
								style={{ marginBottom: 12 }}
								message="How to upload"
								description={
									<div>
										Easily upload hundreds of tickets at once to your event.
										<br />
										Follow{" "}
										<a onClick={OpenHelpHandler(HelpIds.bulkUpload)} style={{ fontWeight: 600 }}>
											this guide
										</a>{" "}
										to using the uploader.
										<br />
										Always use the{" "}
										<CSVLink
											filename={`${eventName}-template.csv`}
											data={this.getTemplateCsv()}
											style={{ fontWeight: 600 }}
										>
											templated csv file
										</CSVLink>
										. The template is unique to the ticket types and checkout questions set-up in this event.
									</div>
								}
								type="info"
								showIcon
							/>
						)}

						<Field
							name="csvFile"
							label="Upload a CSV"
							description="File must be a csv following the format of the template available above"
							component={FileUploadField}
						/>
						<Field
							name="eventDateId"
							label="Event Date"
							description="Choose which event date these tickets apply to"
							component={SelectField}
							options={dateOptions}
						/>
					</ContentBlock>
					<FormBar sticky={false}>
						<LegacyButton
							style={{
								float: "right"
							}}
							key="submit"
							type="primary"
							htmlType="submit"
							ariaLabel="Save"
						>
							Load
						</LegacyButton>
					</FormBar>
				</form>
			</div>
		);
	}
}

const formName = "bulkUploadTickets";
LoadFile = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(LoadFile);

LoadFile = connect((state) => {
	return {
		initialValues: {
			eventDateId: state.currentEvent.event.dates.filter((date) => !date.disabled)[0]._id,
			emailOrder: false
		}
	};
})(LoadFile);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(LoadFile);
