import SideMenu from "@/components/SideMenu";
import SideMenuBg from "@/components/SideMenuBg";
import { getFeatureFlags } from "@/config";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { FC } from "react";

type EventMenuProps = {
	eventId?: string;
};

const EventMenu: FC<EventMenuProps> = ({ eventId }) => {
	const { isAdmin, isResaleAllowed } = useCurrentUser();
	const { massRefunds } = getFeatureFlags(!!isAdmin);

	const manageMenu = [
		{
			label: "Overview",
			path: `/console/my-events/${eventId}`,
			icon: "overview",
			key: "overview"
		},
		{
			label: "Orders / Refunds",
			path: `/console/my-events/${eventId}/orders`,
			icon: "orders_refunds",
			key: "orders",
			children: [
				{
					label: "Orders",
					path: `/console/my-events/${eventId}/orders`,
					key: "orders-orders"
				},
				{
					label: "Paid by invoice",
					path: `/console/my-events/${eventId}/paid-by-invoice`,
					key: "orders-paid-by-invoice"
				},
				{
					label: "Mass Refund/Cancel",
					path: `/console/my-events/${eventId}/mass-refunds/refunds`,
					hide: !massRefunds,
					key: "orders-mass-refunds"
				}
			]
		},

		{
			label: "Manage attendees",
			path: `/console/my-events/${eventId}/attendees`,
			icon: "manage_attendees",
			key: "attendees",
			children: [
				{
					label: "Attendees",
					path: `/console/my-events/${eventId}/attendees`,
					key: "attendees-attendees"
				},
				{
					label: "Waitlist",
					path: `/console/my-events/${eventId}/waitlist`,
					key: "attendees-waitlist"
				},
				{
					label: "Resale",
					path: `/console/my-events/${eventId}/resale`,
					key: "attendees-resale",
					hide: !isResaleAllowed
				},
				{
					label: "Bulk upload",
					path: `/console/my-events/${eventId}/bulk-upload`,
					key: "attendees-bulk-upload"
				},
				{
					label: "Scanning messages",
					path: `/console/my-events/${eventId}/app/messages`,
					key: "attendees-scanning-messages"
				},
				{
					label: "Scanning count",
					path: `/console/my-events/${eventId}/app/scanning-count`,
					key: "attendees-scanning-count"
				}
			]
		},
		{
			label: "Reports",
			path: `/console/my-events/${eventId}/reports`,
			icon: "reports",
			key: "reports",
			children: [
				{
					label: "Analytics",
					path: `/console/my-events/${eventId}/reports/analytics`,
					key: "reports-analytics"
				},
				{
					label: "Discounts",
					path: `/console/my-events/${eventId}/reports/discounts`,
					key: "reports-discounts"
				},
				{
					label: "Affiliate tracking",
					path: `/console/my-events/${eventId}/reports/affiliate-codes`,
					key: "reports-affiliate-codes"
				},
				{
					label: "Access codes",
					path: `/console/my-events/${eventId}/reports/access-codes`,
					key: "reports-access-codes"
				},
				{
					label: "Reporting groups",
					path: `/console/my-events/${eventId}/reports/groups`
				}
			]
		}
	];

	const editMenu = [
		{
			label: "Event information",
			icon: "basic_info",
			matchPath: `/console/my-events/${eventId}/info/`,
			path: `/console/my-events/${eventId}/info/basics`,
			dataCy: "basics",
			key: "basics"
		},
		{
			label: "Checkout questions",
			icon: "additional_questions",
			path: `/console/my-events/${eventId}/additional-questions`,
			key: "additional-questions"
		},
		{
			label: "Tickets",
			icon: "tickets",
			key: "tickets",
			path: "",
			children: [
				{
					label: "Ticket types",
					path: `/console/my-events/${eventId}/tickets`,
					key: "tickets"
				},
				{
					label: "Ticket groups",
					path: `/console/my-events/${eventId}/ticket-groups`,
					key: "ticket-groups"
				},
				{
					label: "Packaged tickets",
					path: `/console/my-events/${eventId}/packaged-tickets`,
					key: "packaged-tickets"
				},
				{
					label: "Ticket ordering",
					path: `/console/my-events/${eventId}/ticket-order`,
					key: "ticket-order"
				},
				{
					label: "Grouped capacity",
					path: `/console/my-events/${eventId}/grouped-capacity`,
					key: "grouped-capacity"
				}
			]
		},
		{
			label: "Promote",
			path: `/console/my-events/${eventId}/codes`,
			icon: "promote",
			key: "promote",
			children: [
				{
					label: "Discounts",
					path: `/console/my-events/${eventId}/discounts/codes`,
					key: "promote-discounts"
				},
				{
					label: "Access codes",
					path: `/console/my-events/${eventId}/access-codes`,
					key: "promote-access-codes"
				},
				{
					label: "Facebook events",
					path: `/console/my-events/${eventId}/facebook-events`,
					key: "promote-facebook-events"
				},
				{
					label: "Virtual event hub",
					path: `/console/my-events/${eventId}/virtual-event-hub`,
					key: "promote-virtual-event-hub"
				},
				{
					label: "Affiliate tracking",
					path: `/console/my-events/${eventId}/affiliate-tracking`,
					key: "promote-affiliate-tracking"
				},
				...(isAdmin
					? [
							{
								label: "LinkedIn event",
								path: `/console/my-events/${eventId}/linked-in-event`,
								key: "promote-linked-in-event"
							}
					  ]
					: [])
			]
		},
		{
			label: "Seating maps",
			path: `/console/my-events/${eventId}/seatingmap/selection`,
			icon: "seating_map",
			key: "seating_map"
		},
		{
			label: "Payments and fees",
			path: `/console/my-events/${eventId}/payments`,
			icon: "payment_fees",
			key: "payment_fees",
			children: [
				{
					label: "Settings",
					path: `/console/my-events/${eventId}/payments/settings`,
					key: "payment-settings"
				},
				{
					label: "Gateways",
					path: `/console/my-events/${eventId}/payments/gateways`,
					key: "payment-gateways"
				},
				{
					label: "Payouts",
					path: `/console/my-events/${eventId}/payments/payouts`,
					key: "payment-payouts"
				},
				{
					label: "Fees",
					path: `/console/my-events/${eventId}/payments/fees`,
					hide: !isAdmin,
					key: "payment-fees"
				},
				{
					label: "Ticket fees",
					path: `/console/my-events/${eventId}/payments/ticket-fees`,
					hide: !isAdmin,
					key: "payment-ticket-fees"
				},
				{
					label: "Self-serve payouts",
					path: `/console/my-events/${eventId}/payments/self-serve-payouts`,
					hide: !isAdmin,
					key: "payment-self-serve-payouts"
				}
			]
		},

		{
			label: "Design & comms",
			path: `/console/my-events/${eventId}/advanced`,
			icon: "design",
			key: "design",
			children: [
				{
					label: "Styling",
					path: `/console/my-events/${eventId}/styling`,
					key: "design-styling"
				},
				{
					label: "Messages",
					path: `/console/my-events/${eventId}/messages`,
					key: "design-messages"
				},
				{
					label: "Embedded widgets",
					path: `/console/my-events/${eventId}/widgets`,
					key: "design-widgets"
				},
				{
					label: "Accessibility",
					path: `/console/my-events/${eventId}/accessibility`,
					key: "promote-accessibility",
					children: [
						{
							label: "Information",
							path: `/console/my-events/${eventId}/accessibility/information`,
							key: "promote-accessibility-information"
						},
						{
							label: "Features",
							path: `/console/my-events/${eventId}/accessibility/features`,
							key: "promote-accessibility-features"
						},
						{
							label: "Tips",
							path: `/console/my-events/${eventId}/accessibility/tips`,
							key: "promote-accessibility-tips"
						}
					]
				}
			]
		},
		{
			label: "Advanced",
			path: `/console/my-events/${eventId}/advanced`,
			icon: "advanced",
			key: "advanced",
			children: [
				{
					label: "Settings",
					path: `/console/my-events/${eventId}/settings`,
					key: "advanced-settings"
				},
				{
					label: "Integrations",
					path: `/console/my-events/${eventId}/integrations`,
					key: "advanced-integrations"
				},
				{
					label: "Notifications",
					path: `/console/my-events/${eventId}/alerts`,
					key: "advanced-alerts"
				}
			]
		}
	];

	return (
		<SideMenuBg>
			<SideMenu title="Manage Event" menuItems={manageMenu} />
			<SideMenu title="Edit Event" menuItems={editMenu} />
		</SideMenuBg>
	);
};

export default EventMenu;
