import { getConfig } from "@/config";
import { useAppSelector } from "@/state/hooks";
import { FC, useEffect, useRef } from "react";

// Must use inline styles because this gets injected into the iframe.
// Also layout doesn't behave as expected due to the scaled down iframe.
export const CheckoutWidgetBuilderPreview: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);

	const checkoutItemRef = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		const loadScript = () => {
			if (!checkoutItemRef.current?.parentElement) {
				return;
			}

			const parentElement = checkoutItemRef.current.parentElement;
			const scriptUrl = `${getConfig("EVENTS_URL")}/scripts/widgets/inline.js`;
			if (parentElement.querySelector(`script[src="${scriptUrl}"]`)) {
				return;
			}

			const script = document.createElement("script");
			script.src = scriptUrl;
			parentElement.appendChild(script);
		};

		loadScript();
	}, [checkoutItemRef.current]);

	const skeletonStyles = {
		backgroundColor: "#dcdcdc",
		borderRadius: "4px",
		height: "100%",
		width: "100%"
	};

	return (
		<div
			style={{
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				height: "100%"
			}}
		>
			<div
				style={{
					borderRadius: "8px",
					display: "flex",
					flexDirection: "column",
					gap: "16px",
					height: "92%",
					margin: "12px",
					transition: "all 0.3s ease-in-out",
					width: "90%"
				}}
			>
				<div style={{ display: "flex", gap: "8px", minHeight: "16px" }}>
					<div style={{ ...skeletonStyles, width: "25%" }} />
					<div style={{ ...skeletonStyles, width: "15%", borderRadius: "8px", margin: "0 0 0 auto" }} />
					<div style={{ ...skeletonStyles, width: "15%", borderRadius: "8px" }} />
				</div>
				<div style={{ ...skeletonStyles, minHeight: "54px" }} />
				<iframe
					data-env={getConfig("NODE_ENV")}
					data-checkout={currentEvent.event?.slug}
					height="200%"
					ref={checkoutItemRef}
					style={{
						borderRadius: "16px",
						display: "flex",
						overflow: "hidden",
						transform: "scale(0.5) translate(-50%, -50%)"
					}}
					width="200%"
				/>
			</div>
			<div style={{ ...skeletonStyles, borderRadius: "4px 4px 0px 0px", minHeight: "54px", width: "90%" }} />
		</div>
	);
};
