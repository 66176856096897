import { notification } from "@/ui/antd";
import DiscountCodesService from "../../services/DiscountCodesService";

export const GET_DISCOUNT_CODES_REQUEST = "GET_DISCOUNT_CODES_REQUEST";
export const GET_DISCOUNT_CODES_SUCCESS = "GET_DISCOUNT_CODES_SUCCESS";
export const GET_DISCOUNT_CODES_ERROR = "GET_DISCOUNT_CODES_ERROR";

export const SAVE_DISCOUNT_CODES_REQUEST = "SAVE_DISCOUNT_CODES_REQUEST";
export const SAVE_DISCOUNT_CODES_SUCCESS = "SAVE_DISCOUNT_CODES_SUCCESS";
export const SAVE_DISCOUNT_CODES_ERROR = "SAVE_DISCOUNT_CODES_ERROR";

const DELETE_DISCOUNT_CODES_REQUEST = "DELETE_DISCOUNT_CODES_REQUEST";
export const DELETE_DISCOUNT_CODES_SUCCESS = "DELETE_DISCOUNT_CODES_SUCCESS";
const DELETE_DISCOUNT_CODES_ERROR = "DELETE_DISCOUNT_CODES_ERROR";

export const DELETE_ALL_DISCOUNT_CODES_REQUEST = "DELETE_ALL_DISCOUNT_CODES_REQUEST";
export const DELETE_ALL_DISCOUNT_CODES_SUCCESS = "DELETE_ALL_DISCOUNT_CODES_SUCCESS";
export const DELETE_ALL_DISCOUNT_CODES_ERROR = "DELETE_ALL_DISCOUNT_CODES_ERROR";

export const EDIT_DISCOUNT_CODE = "EDIT_DISCOUNT_CODE";

export const DUPLICATE_DISCOUNT_CODE_REQUEST = "DUPLICATE_DISCOUNT_CODE_REQUEST";
export const DUPLICATE_DISCOUNT_CODE_SUCCESS = "DUPLICATE_DISCOUNT_CODE_SUCCESS";
export const DUPLICATE_DISCOUNT_CODE_ERROR = "DUPLICATE_DISCOUNT_CODE_ERROR";

export const editDiscountCode = (discountCode) => {
	return (dispatch) => {
		dispatch({ type: EDIT_DISCOUNT_CODE, discountCode });
	};
};

export const duplicateDiscountCode = (eventId, discountCode) => {
	return (dispatch, getState) => {
		dispatch({ type: DUPLICATE_DISCOUNT_CODE_REQUEST });
		DiscountCodesService.duplicate(eventId, discountCode)
			.then((data) => {
				const state = getState();
				dispatch({ type: DUPLICATE_DISCOUNT_CODE_SUCCESS });
				dispatch(getDiscountCodes(eventId, state.discountCodes.page));
				let message = "Your discount code has been duplicated";
				if (!isNaN(data.count)) {
					message = `${data.count} discount codes have been duplicated`;
				}
				notification.success({ message: "Success", description: message });
			})
			.catch((err) => {
				dispatch({ type: DUPLICATE_DISCOUNT_CODE_ERROR, error: err });
				const message =
					err.response && err.response.status === 409 ? "Discount code already exists" : "Failed to save discount code";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const getDiscountCodes = (eventId, page, query) => {
	return (dispatch) => {
		dispatch({ type: GET_DISCOUNT_CODES_REQUEST, page, query });
		DiscountCodesService.find(eventId, page, query)
			.then((result) => {
				dispatch({
					type: GET_DISCOUNT_CODES_SUCCESS,
					discountCodes: result.discountCodes,
					count: result.count
				});
			})
			.catch((err) => {
				dispatch({ type: GET_DISCOUNT_CODES_ERROR, error: err });
			});
	};
};

export const saveDiscountCodes = (eventId, discountCode, navigate) => {
	return (dispatch, getState) => {
		dispatch({ type: SAVE_DISCOUNT_CODES_REQUEST });
		DiscountCodesService.save(eventId, discountCode)
			.then((data) => {
				const state = getState();
				dispatch({ type: SAVE_DISCOUNT_CODES_SUCCESS });
				dispatch(getDiscountCodes(eventId, state.discountCodes.page));
				let message = "Your discount code has been saved";
				if (!isNaN(data.count)) {
					message = `${data.count} discount codes have been added`;
				}
				notification.success({ message: "Success", description: message });
			})
			.catch((err) => {
				dispatch({ type: SAVE_DISCOUNT_CODES_ERROR, error: err });
				const message =
					err.response && err.response.status === 409 ? "Discount code already exists" : "Failed to save discount code";
				notification.error({ message: "Error", description: message });
			})
			.finally(() => {
				navigate(`/console/my-events/${eventId}/discounts/codes`);
			});
	};
};

export const deleteDiscountCodes = (eventId, discountCode) => {
	return (dispatch) => {
		dispatch({ type: DELETE_DISCOUNT_CODES_REQUEST });
		DiscountCodesService.delete(eventId, discountCode)
			.then((discountCode) => {
				dispatch({
					type: DELETE_DISCOUNT_CODES_SUCCESS,
					discountCode
				});
				notification.success({
					message: "Success",
					description: "Your discount code has been deleted"
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_DISCOUNT_CODES_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to delete discount code`
				});
			});
	};
};

export const deleteAllDiscountCodes = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELETE_ALL_DISCOUNT_CODES_REQUEST });
		DiscountCodesService.deleteAll(eventId)
			.then(() => {
				dispatch({ type: DELETE_ALL_DISCOUNT_CODES_SUCCESS });
				const state = getState();
				dispatch(getDiscountCodes(eventId, state.accessCodes.page));
				notification.success({
					message: "Success",
					description: "Your discount codes have been deleted"
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_ALL_DISCOUNT_CODES_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to delete all discount code`
				});
			});
	};
};
