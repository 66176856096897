import { Steps } from "@/ui/antd";
import { useState } from "react";
import PageHeading from "@/components/PageHeading";
import { LoadFile, MapFields, Results } from "./Pages";
const Step = Steps.Step;

const ComparePayments = () => {
	const [{ step, data }, updateStep] = useState({ step: 0, data: undefined });

	const onNextStep = (newData) => {
		const newStep = step + 1;
		updateStep({
			step: newStep,
			data: {
				...data,
				...newData
			}
		});
	};

	const getPage = (step) => {
		switch (step) {
			case 0:
				return LoadFile;
			case 1:
				return MapFields;
			case 2:
				return Results;
			default:
				return LoadFile;
		}
	};
	const Page = getPage(step);
	return (
		<div>
			<PageHeading title="Compare payments" />

			<Steps current={step} style={{ margin: "0 auto 12px auto", maxWidth: 600 }}>
				<Step title="Load CSV" />
				<Step title="Map Fields" />
				<Step title="Upload" />
			</Steps>
			<Page step={step} data={data} onNextStep={onNextStep} updateStep={updateStep} />
		</div>
	);
};

export default ComparePayments;
