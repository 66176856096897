import { ContentBlock } from "@/components/AppBlocks";
import { ImageUploadField, LegacyInputField, SelectField, SwitchField, TextAreaField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import { P } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { saveEvent } from "@/state/actions/eventActions";
import type { AppDispatch, ReduxState as GlobalReduxState } from "@/state/store";
import { Button } from "@/ui";
import { Flex, Tabs, Tooltip } from "@/ui/antd";
import { includes } from "@/utils/Array";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { DeleteOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Field, FieldArray, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";

const formName = "ticketGroups";
const nameRequired = required("Name required");
const selector = formValueSelector(formName);

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	})
};

interface ReduxActions {
	saveEvent: (eventId: string, data: any) => void;
}

type ReduxInput = Partial<GlobalReduxState> & ReduxActions;
type TicketGroupsInput = ReduxInput &
	InjectedFormProps & {
		primaryGroups: any;
		secondaryGroups: any;
		dispatch: AppDispatch;
	};

const TicketGroups = ({
	saveEvent,
	handleSubmit,
	currentEvent,
	primaryGroups,
	secondaryGroups,
	dispatch
}: TicketGroupsInput) => {
	const params = useParams();

	const hasSecondary = !!currentEvent?.event?.settings?.isSecondaryTicketsEnabled;

	const primaryTicketTypes = currentEvent?.event?.ticketTypes?.filter(
		(ticket: any) => !ticket.isDonation && !ticket.isSecondary
	);

	const secondaryTicketTypes = currentEvent?.event?.ticketTypes?.filter(
		(ticket: any) => !ticket.isDonation && ticket.isSecondary
	);

	const _save = (values: any) => {
		const eventId = params?.eventId ?? "";
		saveEvent(eventId, {
			ticketGroups: [...values.primaryGroups, ...values.secondaryGroups.map((g: any) => ({ ...g, isSecondary: true }))]
		});
	};

	const _createGroup = (fields: any) => {
		const group = {
			name: null,
			tickets: []
		};
		fields.push(group);
	};

	const primaryGroupsComponent = (
		<FieldArray
			name="primaryGroups"
			component={TicketGroup}
			ticketTypes={primaryTicketTypes}
			packagedTickets={currentEvent?.event.packagedTickets}
			ticketGroups={primaryGroups}
			createGroup={_createGroup}
			dispatch={dispatch}
			fieldName="primaryGroups"
		/>
	);

	const secondaryGroupsComponent = (
		<Tabs
			items={[
				{ label: "Primary Groups", key: "primary-groups", children: primaryGroupsComponent },
				{
					label: "Secondary Groups",
					key: "secondary-groups",
					children: (
						<FieldArray
							name="secondaryGroups"
							component={TicketGroup}
							ticketTypes={secondaryTicketTypes}
							packagedTickets={currentEvent?.event.packagedTickets}
							ticketGroups={secondaryGroups}
							createGroup={_createGroup}
							dispatch={dispatch}
							fieldName="secondaryGroups"
						/>
					)
				}
			]}
		/>
	);

	return (
		<form onSubmit={handleSubmit(_save)}>
			<PageHeading
				title="Ticket groups"
				helpButton={{
					link: HelpIds.ticketGroups,
					title: "Help - Ticket groups"
				}}
			/>
			<LoadErrorView loading={!!currentEvent?.save.loading} error={currentEvent?.save?.error}>
				<ContentBlock>
					<P>
						If your event has a large list of tickets then use groups to better present ticket options to the buyer.
					</P>

					{hasSecondary ? secondaryGroupsComponent : primaryGroupsComponent}
				</ContentBlock>
				<FormBar>
					<Button variant="primary" htmlType="submit" disabled={currentEvent?.save.loading} aria-label="Save">
						Save
					</Button>
				</FormBar>
			</LoadErrorView>
		</form>
	);
};

interface ITicketGroupProps {
	fields: any;
	ticketGroups: any;
	ticketTypes: any;
	packagedTickets: any;
	createGroup: any;
	dispatch: AppDispatch;
	fieldName: string;
}

const TicketGroup = ({
	fields,
	ticketGroups,
	ticketTypes,
	packagedTickets = [],
	createGroup,
	dispatch,
	fieldName
}: ITicketGroupProps) => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

	const _isRowExpanded = (key: string) => includes(expandedRowKeys, key);

	const _collapseExpandedRow = (key: string) => {
		const newExpandedRowKeys = expandedRowKeys.filter((item) => item !== key);
		setExpandedRowKeys(newExpandedRowKeys);
	};

	const _onExpand = (key: string) => {
		if (_isRowExpanded(key)) {
			_collapseExpandedRow(key);
		} else {
			const tempRowKeys = [...expandedRowKeys];
			tempRowKeys.push(key);
			setExpandedRowKeys(tempRowKeys);
		}
	};

	const _onDelete = (key: string, index: number) => {
		if (_isRowExpanded(key)) {
			_collapseExpandedRow(key);
		}
		fields.remove(index);
	};

	let tickets = ticketTypes.concat(packagedTickets);
	tickets = tickets.filter((ticket: any) => !(ticket.disabled || ticket.deleted));
	const options = tickets.map((ticket: any) => ({ value: ticket._id, label: ticket.name }));

	const ticketGroupsKeyed = ticketGroups?.map((group: any, index: number) => ({ ...group, key: index }));

	const columns = [
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Name
				</span>
			),
			dataIndex: "name",
			key: "name",
			width: "41%",
			render: (_: string, __: any, index: number) => {
				return (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<Field
							inline
							name={`${fieldName}[${index}].name`}
							component={LegacyInputField}
							placeholder="Name"
							validate={nameRequired}
						/>
					</div>
				);
			}
		},
		{
			title: "Tickets",
			dataIndex: "tickets",
			key: "tickets",
			width: "41%",
			render: (_: string, __: any, index: number) => {
				return (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<Field
							style={{ maxWidth: 180 }}
							mode="multiple"
							name={`${fieldName}[${index}].tickets`}
							component={SelectField}
							options={options}
						/>
					</div>
				);
			}
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (_: string, item: any, index: number) => {
				return (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<Flex gap="xs">
							<Tooltip title="Settings">
								<Button aria-label="Settings" iconOnly variant="text" onClick={() => _onExpand(item.key)}>
									<SettingOutlined style={{ fontSize: 18 }} />
								</Button>
							</Tooltip>

							<Tooltip title="Delete">
								<Button aria-label="Delete" iconOnly variant="text" onClick={() => _onDelete(item.key, index)}>
									<DeleteOutlined style={{ fontSize: 18 }} />
								</Button>
							</Tooltip>
						</Flex>
					</div>
				);
			}
		}
	];

	return (
		<div>
			{!ticketGroups?.length ? (
				<NoResults
					title="You have no ticket groups."
					message=""
					action={() => createGroup(fields, ticketTypes)}
					actionTxt="Create Group"
				/>
			) : (
				<div style={{ width: "100%", overflowX: "auto" }}>
					<ResponsiveTable
						pagination={false}
						draggable={{ dispatch, formName, fieldName }}
						columns={columns}
						dataSource={ticketGroupsKeyed}
						rowKey="key"
						expandedRowKeys={expandedRowKeys}
						expandedRowRender={(_: any, index: number) => <TicketGroupSettings index={index} fieldName={fieldName} />}
						expandIconColumnIndex={99}
						expandIconAsCell={false}
					/>

					<ButtonsBar>
						<Button
							onClick={() => createGroup(fields, ticketTypes)}
							variant="tertiary"
							aria-label="Add Group"
							iconLeft={<PlusOutlined style={{ fontSize: 18 }} />}
						>
							Add group
						</Button>
					</ButtonsBar>
				</div>
			)}
		</div>
	);
};

const TicketGroupSettings = ({ index, fieldName }: { index: number; fieldName: string }) => {
	return (
		<>
			<Field
				label="Description"
				name={`${fieldName}[${index}].description`}
				component={TextAreaField}
				rows={2}
				placeholder="Ticket group description"
			/>

			<Field
				label="Collapse by default"
				name={`${fieldName}[${index}].isDefaultCollapsed`}
				component={SwitchField}
				description={<>Decide which ticket groups your attendees notice the most.</>}
			/>

			<Field
				name={`${fieldName}[${index}].image`}
				label="Image"
				description={
					<>
						Use an image with a 2:1 ratio (e.g. 1200px by 600px).{" "}
						<a onClick={OpenHelpHandler(HelpIds.ticketImages)}>Learn more</a>
					</>
				}
				component={ImageUploadField}
				aspectRatio={2}
				imageStyleOverride={{
					width: "100%",
					maxHeight: "230px",
					margin: "auto",
					paddingTop: undefined,
					aspectRatio: "2"
				}}
			/>
		</>
	);
};

const ReduxConnectedComponent = reduxForm<TicketGroupsInput, any>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => scrollToErrors()
})(TicketGroups);

export default connect(
	(state: Partial<GlobalReduxState>) => ({
		initialValues: {
			primaryGroups: (state.currentEvent?.event?.ticketGroups || []).filter((group: any) => !group.isSecondary),
			secondaryGroups: (state.currentEvent?.event?.ticketGroups || []).filter((group: any) => group.isSecondary)
		},
		currentEvent: state.currentEvent,
		primaryGroups: selector(state, "primaryGroups"),
		secondaryGroups: selector(state, "secondaryGroups")
	}),
	{ saveEvent }
)(withSaveCheck(ReduxConnectedComponent, formName));
