import { dayjs, Dayjs } from "@/libs/dayjs";
import { DatePicker, Dropdown } from "@/ui/antd";
import { css } from "@emotion/css";
import React, { KeyboardEvent, useEffect, useState } from "react";
import CalendarIcon from "../assets/IcCalendar";
import LinkButton from "./buttons/LinkButton";

const { RangePicker } = DatePicker;
type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof RangePicker>["onChange"]>>[0];

const styles = {
	wrapper: css({
		width: 180,
		clear: "both",
		margin: "20px 0"
	}),
	calendarRow: css({
		display: "flex",
		marginBottom: 4
	}),
	label: {
		marginLeft: 4,
		textAlign: "left",
		textDecoration: "underline",
		"&:focus": {
			outline: "1px dotted #196097"
		}
	},
	rangePicker: css({
		width: 300
	})
};

interface IDatesSelectorProps {
	defaultValue: [Dayjs | string, Dayjs | string] | null;
	filterChange: (name: string, values: [Dayjs, Dayjs] | []) => void;
	name: string;
	label: string;
}

const items = [
	{
		key: "custom",
		label: "Custom date range"
	},
	{
		key: "weekToDate",
		label: "This week"
	},
	{
		key: "monthToDate",
		label: "This month"
	},
	{
		key: "yearToDate",
		label: "This year"
	}
];

const DatesSelector = ({ defaultValue, filterChange, name, label }: IDatesSelectorProps) => {
	const [values, setValues] = useState<any>([]);
	const [showOnlyPicker, setShowOnlyPicker] = useState(false);

	useEffect(() => {
		if (defaultValue && defaultValue.length === 2) {
			setValues([
				dayjs.isDayjs(defaultValue[0]) ? defaultValue[0] : dayjs(defaultValue[0]),
				dayjs.isDayjs(defaultValue[1]) ? defaultValue[1] : dayjs(defaultValue[1])
			]);
		}
	}, []);

	const _selectDates = (values: RangeValue) => {
		if (!values) {
			filterChange(name, []);
			setValues([]);
		}
		const first = values?.[0]?.hour(0).minute(0).second(0);
		const second = values?.[1]?.hour(23).minute(59).second(59);
		if (!!first && !!second) {
			filterChange(name, [first, second]);
			setValues([first, second]);
		}
	};

	const _dateRangeChange = (range?: string) => {
		if (range === "custom") {
			setShowOnlyPicker(true);
		} else {
			let startDate = dayjs().startOf("day");
			let endDate = dayjs().endOf("day");
			switch (range) {
				case "weekToDate":
					startDate = startDate.startOf("week");
					endDate = endDate.endOf("week");
					break;
				case "monthToDate":
					startDate = startDate.startOf("month");
					endDate = endDate.endOf("month");
					break;
				case "yearToDate":
					startDate = startDate.startOf("year");
					endDate = endDate.endOf("year");
					break;

				default:
					break;
			}
			setShowOnlyPicker(false);
			filterChange(name, [startDate, endDate]);
			setValues([startDate, endDate]);
		}
	};

	const valueOption = showOnlyPicker ? null : { value: values };
	return (
		<div className={styles.wrapper}>
			<Dropdown
				menu={{
					items,
					onKeyUp: (e: KeyboardEvent<HTMLUListElement>) => {
						const target = e.target as HTMLDivElement;
						if ((e.key === "Enter" || e.key === " ") && target.dataset) {
							e.preventDefault();
							_dateRangeChange(target.dataset.key);
						}
					},
					onClick: ({ key }) => _dateRangeChange(key)
				}}
			>
				<div className={styles.calendarRow}>
					<CalendarIcon color="#196097" />
					<LinkButton onClick={(e: MouseEvent) => e.preventDefault()} style={styles.label} ariaLabel={label}>
						{label}
					</LinkButton>
				</div>
			</Dropdown>
			{values || showOnlyPicker ? (
				<div className={styles.rangePicker}>
					<RangePicker onChange={_selectDates} {...valueOption} style={{ width: "100%" }} />
				</div>
			) : null}
		</div>
	);
};

export default DatesSelector;
