import { Select, Spin } from "@/ui/antd";
import { DefaultOptionType, SelectProps } from "antd/lib/select";
import { replaceSpecialCharacters } from "../../utils/Format";
import InputWrapper from "./helpers/InputWrapper";

const wrapInArray = (input: any, field: any) => (value: any) => {
	input[field]([value]);
};

const defaultFilterOption = (input: string, optionOrGroup?: DefaultOptionType): boolean => {
	const isGroup = Array.isArray(optionOrGroup?.options);
	if (isGroup || !optionOrGroup?.value) {
		return false;
	}
	return optionOrGroup.value.toString().toLowerCase().includes(replaceSpecialCharacters(input).toLowerCase());
};

interface IWrappedProps {
	input: any;
	label: string;
	description: string;
	inline?: boolean;
	required: boolean;
	placeholder: string;
	style?: any;
	tooltip?: any;
	tooltipIcon?: any;
	tooltipIconOnClick?: () => void;
	wrapValuesInArray?: boolean;
	showSearch?: boolean;
	notFoundContentLoading?: boolean;
	meta: { touched?: boolean; error?: string };
	defaultValue?: any;
}

type ISearchFieldProps = IWrappedProps & SelectProps;

const SelectField = ({
	input,
	label,
	description,
	inline,
	required,
	placeholder,
	mode,
	options,
	disabled,
	style,
	filterOption = defaultFilterOption,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	wrapValuesInArray = false,
	showSearch = false,
	notFoundContentLoading = false,
	meta: { touched, error },
	onSelect,
	onSearch,
	onDeselect = () => null,
	onPopupScroll,
	onBlur = () => null,
	defaultValue,
	loading = false
}: ISearchFieldProps) => {
	const value = mode === "multiple" && !input?.value ? [] : input?.value;
	const selectStyle = {
		minWidth: 244,
		...style
	};
	let wrappingProps = {};
	if (wrapValuesInArray) {
		wrappingProps = {
			onChange: wrapInArray(input, "onChange"),
			onBlur: wrapInArray(input, "onBlur")
		};
	}

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<Select
				id={input.name}
				data-cy={input.name}
				{...input}
				{...wrappingProps}
				placeholder={placeholder}
				mode={mode}
				value={value || defaultValue}
				style={selectStyle}
				showSearch={showSearch}
				notFoundContent={notFoundContentLoading ? <Spin size="small" /> : null}
				onSelect={onSelect}
				onSearch={onSearch}
				onDeselect={onDeselect}
				onPopupScroll={onPopupScroll}
				filterOption={onSearch ? false : filterOption}
				disabled={disabled}
				onBlur={onBlur}
				options={options}
				loading={loading}
			/>
		</InputWrapper>
	);
};

export default SelectField;
