import { CloseCircleTwoTone, PictureOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Badge, notification } from "@/ui/antd";
import { fsClient } from "../services/ImageService";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "./buttons/ButtonsBar";
import { P } from "./text/Text";

const DisplayImages = (props) => {
	const { selectedImages = [], deleteImage } = props;
	if (!selectedImages.length) {
		return null;
	}
	const images = selectedImages.map((image, index) => {
		return (
			<div style={{ width: 200, height: 100, margin: 20 }} key={index}>
				<Badge count={<CloseCircleTwoTone theme="twoTone" twoToneColor="#eb2f96" onClick={() => deleteImage(index)} />}>
					<img src={image.url} style={{ width: "100%" }} alt="Uploaded file display" />
				</Badge>
			</div>
		);
	});

	return (
		<div
			style={{
				display: "flex",
				position: "relative",
				justifyContent: "center",
				flexWrap: "wrap"
			}}
		>
			{images}
		</div>
	);
};

const MultipleImageUpload = (props) => {
	const {
		aspectRatio,
		uploadLabel = "Upload images",
		uploadDescription = "Images should be smaller than 10 mb",
		uploadOutsideDescription = "",
		onChange,
		value,
		maxWidth = "100%",
		height = "440px"
	} = props;
	let padding = 100;

	if (aspectRatio) {
		padding = 100 / aspectRatio > 50 ? 50 : 100 / aspectRatio;
	}
	const accept = ["image/*"];

	const uploadImage = () => {
		let options = {
			fromSources: ["local_file_system", "url", "facebook", "instagram", "googledrive", "dropbox"],
			maxSize: 102400000,
			maxFiles: 20,
			minFiles: 1,
			imageMax: [1000, 1000],
			uploadInBackground: false,
			transformations: {
				rotate: true,
				crop: true
			},
			accept,
			onUploadDone: imageUploaded
		};

		if (aspectRatio) {
			options.transformations.crop = {
				force: true,
				aspectRatio
			};
		}

		fsClient.picker(options).open();
	};
	const editImage = () => {
		const urls = value.map((image) => {
			return image.url;
		});
		let options = {
			transformations: {
				rotate: true,
				crop: true
			},
			accept,
			onUploadDone: imageUploaded
		};
		if (aspectRatio) {
			options.transformations.crop = {
				force: true,
				aspectRatio
			};
		}
		fsClient.picker(options).crop(urls);
	};
	const deleteImage = (removedIndex) => {
		const newSelectedImages = [].concat(value);
		newSelectedImages.splice(removedIndex, 1);

		if (onChange) {
			onChange(newSelectedImages);
		}
	};
	const imageUploaded = ({ filesUploaded, filesFailed }) => {
		if (filesFailed.length) {
			notification.error({ message: "Image failed to upload" });
			return;
		}
		let images = filesUploaded.map((file) => {
			return {
				name: file.filename,
				size: file.size,
				url: file.url,
				source: file.source,
				handle: file.handle
			};
		});
		// const image = saveHandleOnly
		//   ? uploadedImage.handle
		//   : {
		//       name: uploadedImage.filename,
		//       size: uploadedImage.size,
		//       url: uploadedImage.url,
		//       source: uploadedImage.source,
		//       handle: uploadedImage.handle
		//     };

		if (value && value.length) {
			images.unshift(...value);
		}
		if (onChange) {
			onChange(images);
		}
	};

	const imageArea = css`
		background: transparent;
		background-size: contain;
		background-position: center;
		max-width: ${maxWidth};
		height: ${height};
		width: 100%;
		// padding-top: ${padding}%;
		border: 1px dashed #d9d9d9;
		position: relative;
		text-align: center;
		cursor: pointer;
		@media (max-width: 600px) {
			// height: auto;
			max-width: 100%;
			margin-bottom: 16px;
		}
	`;

	const noImage = css`
		position: absolute;
		top: calc(50% - 100px);
		bottom: 0;
		left: 0;
		right: 0;
		height: 150px;
		text-align: center;
	`;

	const icon = css`
		font-size: 90px;
		color: #eee;
		margin: 0;
		line-height: 1.2;
	`;

	const hint = css`
		font-size: 14px;
		color: #323232;
		margin-top: 15px;
	`;

	return (
		<div>
			{value && value.length ? (
				<div>
					<DisplayImages selectedImages={value} deleteImage={deleteImage} />
					<ButtonsBar>
						<LegacyButton onClick={editImage} type="action" ariaLabel="Edit">
							Edit
						</LegacyButton>
						<LegacyButton onClick={uploadImage} type="action" ariaLabel="Upload">
							Add more
						</LegacyButton>
					</ButtonsBar>
					<div className={hint}>{uploadDescription}</div>
				</div>
			) : (
				<div className={imageArea} onClick={uploadImage}>
					<div className={noImage}>
						<div className={icon}>
							<PictureOutlined />
						</div>
						<LegacyButton ariaLabel={uploadLabel} style={{ margin: "0 auto" }} type="action" onClick={uploadImage}>
							{uploadLabel}
						</LegacyButton>
						<div className={hint}>{uploadDescription}</div>
					</div>
				</div>
			)}
			<P className={hint}>{uploadOutsideDescription}</P>
		</div>
	);
};

export default MultipleImageUpload;
