import { css } from "@emotion/css";
import { max } from "../utils/CssUtils";
import { currency } from "../utils/Format";
import { Label } from "./text/Text";

const feeTypeDisplay = {
	education: "Schools",
	notForProfit: "Not-for-profit"
};

const wrapper = css({
	padding: 12,
	border: "solid 1px #dedede",
	borderRadius: 4,
	backgroundColor: "#f9f9fa",
	maxWidth: 250,
	position: "absolute",
	top: 24,
	right: 20,
	[max(975)]: {
		maxWidth: 300,
		position: "relative",
		top: 0,
		right: 0,
		marginBottom: 16
	}
});

const FeeDisplay = ({ fees }) => {
	if (!fees) {
		return null;
	}
	const displayPaymentFee = fees.paymentFeePercent;
	const displayFeeType = Object.keys(feeTypeDisplay).includes(fees.type);
	const partsToDisplay = [];
	if (displayFeeType) {
		partsToDisplay.push({
			label: "Fee type: ",
			content: feeTypeDisplay[fees.type]
		});
	}
	partsToDisplay.push({
		label: "Booking fee: ",
		content: `${fees.percent}% + ${currency(fees.fixed)}`
	});
	if (fees.bookingFeeCap) {
		partsToDisplay.push({
			label: "Booking fee cap: ",
			content: currency(fees.bookingFeeCap)
		});
	}
	if (displayPaymentFee) {
		partsToDisplay.push({
			label: "Payment fee: ",
			content: `${fees.paymentFeePercent}%`
		});
	}
	return (
		<div className={wrapper}>
			<Label>Your fee structure:</Label>
			{partsToDisplay.map((part) => (
				<>
					<b>{part.label}</b>
					{part.content}
					<br />
				</>
			))}
		</div>
	);
};

export default FeeDisplay;
