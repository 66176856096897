import { Field } from "redux-form";
import { HumaniDate } from "@hx/dates";
import {
	CheckboxField,
	LegacyInputField,
	InputNumberField,
	PasswordField,
	RadioField,
	SelectField
} from "@/components/Fields";
import { H3, P } from "@/components/text/Text";
import LocationService from "@/services/LocationService";
import { getCountryOptions } from "@/utils/Countries";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { allowCharacters, checkLength, email, onlyNumbers, required, getValueFromObjString } from "@/utils/Validators";
import { Alert } from "@/ui/antd";

const MAX_NAME_LENGTH = 50;
const nameMaxLength = (value: string) =>
	value?.length > MAX_NAME_LENGTH ? `Must be ${MAX_NAME_LENGTH} characters or less` : undefined;
const nameRequired = required("Name is required (As shown on your income tax return)");
const addressRequired = required("Address is required");
const cityRequired = required("City is required");
const countryRequired = required("Country is required");
const tinTypeRequired = required("Must select a TIN type");

const emailRequired = required("Email is required to receive your 1099-K form");
const validEmail = email("Please enter a valid email");

const signatureRequired = required("Signature is required (Typing your name acts as your signature)");

const allowedCharacters = allowCharacters([
	"a",
	"b",
	"c",
	"d",
	"e",
	"f",
	"g",
	"h",
	"i",
	"j",
	"k",
	"l",
	"m",
	"n",
	"o",
	"p",
	"q",
	"r",
	"s",
	"t",
	"u",
	"v",
	"w",
	"x",
	"y",
	"z",
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
	"-",
	"_",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"0"
]);
const NOT_APPLICABLE = "Not applicable";

const taxNumberRequiredValidator = (_value: any, formValues: any) => {
	if (formValues.usTaxpayerInfo.tin || formValues.usTaxpayerInfo.ftin) {
		return undefined;
	}
	return "Foreign tax identification number, social security number, or international tax identification number required";
};

const tinLength = checkLength("TIN must be 9 digits", 9, "==");
const tinCharacters = onlyNumbers("TIN must be numbers only");
const tinChecks = [taxNumberRequiredValidator, tinLength, tinCharacters];

export const fieldMatch = (msg: any, field: any) => (value: any, formValues: any) => {
	const originalValue = getValueFromObjString(field, formValues.usTaxpayerInfo);
	return value === originalValue ? undefined : msg;
};

const chapter3StatusOptions = [
	{ label: "Corporation", value: "Corporation" },
	{ label: "Disregarded entity", value: "Disregarded entity" }, //can be hybrid
	{ label: "Partnership", value: "Partnership" }, // can be hybrid
	{ label: "Simple trust", value: "Simple trust" }, // can be hybrid
	{ label: "Grantor trust", value: "Grantor trust" }, // can be hybrid
	{ label: "Complex trust", value: "Complex trust" },
	{ label: "Estate", value: "Estate" },
	{ label: "Government", value: "Government" },
	{ label: "Central bank of issue", value: "Central bank of issue" },
	{ label: "Tax-exempt organization", value: "Tax-exempt organization" },
	{ label: "Private foundation", value: "Private foundation" },
	{ label: "International organisation", value: "International organisation" }
];

const taxTreatyProvisionOptions = [
	{ label: "Government", value: "Government" },
	{ label: "Tax exempt pension trust or pension fund", value: "Tax exempt pension trust or pension fund" },
	{ label: "Other tax exempt organization", value: "Other tax exempt organization" },
	{ label: "Publicly traded organization", value: "Publicly traded organization" },
	{ label: "Subsidiary of a publicly traded corporation", value: "Subsidiary of a publicly traded corporation" },
	{
		label: "Company that meets the ownership and base erosion test",
		value: "Company that meets the ownership and base erosion test"
	},
	{
		label: "Company that meets the derivative benefits test",
		value: "Company that meets the derivative benefits test"
	},
	{
		label: "Company with an item of income that meets active trade or business test",
		value: "Company with an item of income that meets active trade or business test"
	},
	{
		label: "Favorable discretionary determination by the U.S. competent authority received",
		value: "Favorable discretionary determination by the U.S. competent authority received"
	},
	{ label: "Other", value: "Other" }
];

export const W8BENEForm = ({ formValues }: any) => {
	const formattedSignatureDate = new HumaniDate(
		formValues.signatureDate,
		"guess",
		LocationService.getLocation(true)
	).formatting.dateTime();
	return (
		<>
			<H3>IRS Substitute Form W-8BEN-E</H3>
			<P>This tax form is required by US tax law. Please note that we are unable to offer any tax advice.</P>
			<Alert
				showIcon
				message="Need help filling in the form?"
				description={
					<>
						See:
						<ul style={{ paddingLeft: 20, marginBottom: 0 }}>
							<li>
								Our <a onClick={OpenHelpHandler(HelpIds.irsW8Form)}>FAQ</a>
							</li>
							<li>
								<a href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf" target="_blank" rel="noreferrer">
									IRS Form W-8BENE Instructions
								</a>
							</li>
						</ul>
					</>
				}
				type="info"
				style={{ marginBottom: 32 }}
			/>
			<P>
				Please fill out the form in English. If you don't have a tax treaty benefit to claim, select "Not Applicable" as
				your country.
			</P>
			<br />
			<br />
			<Field
				name="usTaxpayerInfo.name"
				label="Name"
				required
				component={LegacyInputField}
				validate={[nameRequired, nameMaxLength]}
				maxLength={MAX_NAME_LENGTH}
				placeholder="As shown on your income tax return"
			/>
			<Field
				name="usTaxpayerInfo.entityName"
				label="Name of disregarded entity receiving the payment, if applicable"
				component={LegacyInputField}
				maxLength={MAX_NAME_LENGTH}
				placeholder="If different from name"
			/>
			<Field
				name="usTaxpayerInfo.country"
				label="Country"
				required
				validate={countryRequired}
				component={SelectField}
				options={getCountryOptions().filter((option) => option.label !== "United States")}
				placeholder="Country of citizenship"
			/>
			<Field
				name="usTaxpayerInfo.chapter3Status"
				label="Chapter 3 status"
				required
				component={SelectField}
				options={chapter3StatusOptions}
				placeholder="Chapter 3 status (entity type)"
			/>
			{["Disregarded entity", "Partnership", "Simple trust", "Grantor trust"].includes(formValues?.chapter3Status) ? (
				<Field
					name="usTaxpayerInfo.isHybrid"
					label="Is the entity a hybrid making a treaty claim?"
					component={RadioField}
					button
					options={[
						{
							label: "Yes",
							value: true
						},
						{
							label: "No",
							value: false
						}
					]}
				/>
			) : null}

			<Field
				name="usTaxpayerInfo.address.address"
				label="Address"
				placeholder="Street address"
				required
				validate={addressRequired}
				component={LegacyInputField}
			/>
			<Field
				label="Address 2"
				name="usTaxpayerInfo.address.addressTwo"
				component={LegacyInputField}
				placeholder="Apt, suite, unit, building, floor, etc"
			/>
			<Field
				label="City"
				name="usTaxpayerInfo.address.city"
				component={LegacyInputField}
				placeholder="City"
				required
				validate={cityRequired}
			/>

			<Field
				required
				name="usTaxpayerInfo.tinType"
				label="Taxpayer identification number"
				component={RadioField}
				button
				validate={tinTypeRequired}
				options={[
					{
						label: "TIN",
						value: "TIN"
					},
					{
						label: "FTIN",
						value: "FTIN"
					}
				]}
			/>
			{formValues.tinType === "TIN" ? (
				<Field
					description="Enter your US taxpayer identification number (TIN)"
					name="usTaxpayerInfo.tin"
					component={PasswordField}
					placeholder="Enter your TIN"
					type="password"
					validate={tinChecks}
				/>
			) : null}

			{formValues.tinType === "FTIN" ? (
				<Field
					description="Enter your foreign tax identification number (FTIN)"
					name="usTaxpayerInfo.ftin"
					component={PasswordField}
					placeholder="Enter your foreign tax identifying number"
					validate={[taxNumberRequiredValidator]}
					type="password"
				/>
			) : null}

			<Field
				name="usTaxpayerInfo.taxTreatyCountry"
				label="Tax treaty country"
				description={
					<>
						The beneficial owner is a resident of the country (to be specified below) within the meaning of the{" "}
						<a href="https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z">
							income tax treaty between the United States and that country.
						</a>
					</>
				}
				required
				component={SelectField}
				options={[{ value: NOT_APPLICABLE, label: NOT_APPLICABLE }, ...getCountryOptions()].filter(
					(option) => option.label !== "United States"
				)}
				placeholder="Tax treaty country"
			/>
			{formValues.taxTreatyCountry && formValues.taxTreatyCountry !== NOT_APPLICABLE ? (
				<>
					<P>
						The beneficial owner derives the item (or items) of income for which the treaty benefits are claimed, and,
						if applicable, meets the requirements of the treaty provision dealing with limitation on benefits. The
						following are types of limitation on benefit provisions that may be included in an applicable tax treaty
						(select only one):
					</P>
					<Field
						name="usTaxpayerInfo.taxTreatyProvision"
						required
						component={SelectField}
						options={taxTreatyProvisionOptions}
						placeholder="Tax treaty provision"
					/>
					{formValues.taxTreatyProvision === "Other" ? (
						<>
							<Field
								name="usTaxpayerInfo.benefitsArticle"
								label="Article"
								required
								component={LegacyInputField}
								placeholder="Specific article"
							/>
							<Field
								name="usTaxpayerInfo.benefitsParagraph"
								label="Paragraph"
								required
								component={LegacyInputField}
								placeholder="Specific paragraph"
							/>
						</>
					) : null}
					<Field
						name="usTaxpayerInfo.isQualified"
						description={
							<>
								The beneficial owner is claiming treaty benefits for U.S. source dividends received from a foreign
								corporation or interest from a U.S. trade or business of a foreign corporation and meets qualified
								resident status (see <a href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf">instructions</a>)
							</>
						}
						component={RadioField}
						button
						options={[
							{
								label: "Yes",
								value: true
							},
							{
								label: "No",
								value: false
							}
						]}
					/>
					<Field
						name="usTaxpayerInfo.article"
						label="Article"
						required
						component={LegacyInputField}
						placeholder="Specific treaty article"
						validate={allowedCharacters}
					/>
					<Field
						name="usTaxpayerInfo.paragraph"
						label="Paragraph"
						required
						component={LegacyInputField}
						placeholder="Specific treaty paragraph"
						validate={allowedCharacters}
					/>
					<Field
						name="usTaxpayerInfo.witholdingRate"
						label="Witholding Rate"
						required
						component={InputNumberField}
						precision={2}
						min={0}
						max={100}
					/>
					<Field
						name="usTaxpayerInfo.incomeType"
						label="Type of income to apply the witholding"
						required
						component={LegacyInputField}
					/>
					<Field
						name="usTaxpayerInfo.additionalConditions"
						label="Additional conditions"
						description="Explain the additional conditions in the Article and paragraph the beneficial owner meets to be eligible for the rate of withholding:"
						component={LegacyInputField}
					/>
				</>
			) : null}
			<Field
				name="usTaxpayerInfo.email"
				label="Email"
				required
				component={LegacyInputField}
				validate={[emailRequired, validEmail]}
				maxLength={MAX_NAME_LENGTH}
				placeholder="Email address that you want to receive the 1099-K form"
			/>
			<div style={{ padding: 16, backgroundColor: "rgba(27, 27, 27, 0.06)", borderRadius: 4 }}>
				<P>
					Under penalties of perjury, I declare that I have examined the information on this form and to the best of my
					knowledge and belief it is true, correct, and complete. I further certify under penalties of perjury that:
				</P>
				<ol style={{ fontWeight: 600 }}>
					<li>
						The entity identified on line 1 of this form is the beneficial owner of all the income to which this form
						relates, is using this form to certify its status for chapter 4 purposes, or is a merchant submitting this
						form for purpose of section 6050W;
					</li>
					<li> The entity identified on line 1 of this form is not a U.S. person;</li>
					<li>
						The income to which this form relates is: (a) not effectively connected with the conduct of a trade or
						business in the United States, (b) effectively connected but is not subject to tax under an income tax
						treaty, or (c) the partner's share of a partnership's effectively connected income; and
					</li>
					<li>
						For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in
						the instructions.
					</li>
				</ol>
				<P>
					Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or
					custody of the income of which the entity on line 1 is the beneficial owner or any withholding agent that can
					disburse or make payments of the income of which the entity on line 1 is the beneficial owner.
				</P>
				<P>I agree that I will submit a new form within 30 days if any certification on this form becomes incorrect.</P>
				<P>
					The Internal Revenue Service does not require your consent to any provisions of this document other than the
					certifications required to establish your status as a non-U.S. person, establish your chapter 4 status (if
					required), and, if applicable, obtain a reduced rate of withholding.
				</P>

				<Field
					name="usTaxpayerInfo.signature"
					label="Signature"
					required
					component={LegacyInputField}
					validate={[signatureRequired]}
					placeholder="Typing your name acts as your signature"
				/>
				<Field
					label="I certify that I have the capacity to sign for the entity identified in Step 1 of this form."
					name="usTaxpayerInfo.certifiedToSign"
					component={CheckboxField}
					inline
					labelAfter
				/>
				<Field
					name="usTaxpayerInfo.signatureCapacity"
					label="Signature of individual authorized to sign for beneficial owner"
					component={LegacyInputField}
				/>
				{formValues.signatureDate ? (
					<P style={{ color: "#808080", marginTop: "-10px" }}>Signed on {formattedSignatureDate}</P>
				) : null}
			</div>
		</>
	);
};
