import { notification } from "@/ui/antd";
import PayoutsService from "../../services/PayoutsService";
import FileDownload from "../../utils/FileDownload";

export const PUSH_PAYMENT_REQUEST = "PUSH_PAYMENT_REQUEST";
export const PUSH_PAYMENT_SUCCESS = "PUSH_PAYMENT_SUCCESS";
export const PUSH_PAYMENT_ERROR = "PUSH_PAYMENT_ERROR";

export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_ERROR = "UPDATE_PAYMENT_ERROR";

export const GET_PAYMENT_REQUEST = "GET_PAYMENT_REQUEST";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_ERROR = "GET_PAYMENT_ERROR";

export const REMOVE_PAYMENT_REQUEST = "REMOVE_PAYMENT_REQUEST";
export const REMOVE_PAYMENT_SUCCESS = "REMOVE_PAYMENT_SUCCESS";
export const REMOVE_PAYMENT_ERROR = "REMOVE_PAYMENT_ERROR";

export const PUSH_NOTE_REQUEST = "PUSH_NOTE_REQUEST";
export const PUSH_NOTE_SUCCESS = "PUSH_NOTE_SUCCESS";
export const PUSH_NOTE_ERROR = "PUSH_NOTE_ERROR";

export const DELETE_NOTE_REQUEST = "DELETE_NOTE_REQUEST";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";

export const CHANGE_AUTO_PAYOUT_REQUEST = "CHANGE_AUTO_PAYOUT_REQUEST";
export const CHANGE_AUTO_PAYOUT_SUCCESS = "CHANGE_AUTO_PAYOUT_SUCCESS";
export const CHANGE_AUTO_PAYOUT_ERROR = "CHANGE_AUTO_PAYOUT_ERROR";
export const SEARCH_PAYOUT_REQUEST = "SEARCH_PAYOUT_REQUEST";
export const SEARCH_PAYOUT_SUCCESS = "SEARCH_PAYOUT_SUCCESS";
export const SEARCH_PAYOUT_ERROR = "SEARCH_PAYOUT_ERROR";

export const CSV_PAYOUT_REQUEST = "CSV_PAYOUT_REQUEST";
export const CSV_PAYOUT_SUCCESS = "CSV_PAYOUT_SUCCESS";
export const CSV_PAYOUT_ERROR = "CSV_PAYOUT_ERROR";

export const CHANGE_PAYOUT_STATUS_REQUEST = "CHANGE_PAYOUT_STATUS_REQUEST";
export const CHANGE_PAYOUT_STATUS_SUCCESS = "CHANGE_PAYOUT_STATUS_SUCCESS";
export const CHANGE_PAYOUT_STATUS_ERROR = "CHANGE_PAYOUT_STATUS_ERROR";

export const CHANGE_PAYOUT_FLAG_REQUEST = "CHANGE_PAYOUT_FLAG_REQUEST";
export const CHANGE_PAYOUT_FLAG_SUCCESS = "CHANGE_PAYOUT_FLAG_SUCCESS";
export const CHANGE_PAYOUT_FLAG_ERROR = "CHANGE_PAYOUT_FLAG_ERROR";

const SEND_USER_VERIFICATION_EMAIL_REQUEST = "SEND_USER_VERIFICATION_EMAIL_REQUEST";
const SEND_USER_VERIFICATION_EMAIL_SUCCESS = "SEND_USER_VERIFICATION_EMAIL_SUCCESS";
const SEND_USER_VERIFICATION_EMAIL_ERROR = "SEND_USER_VERIFICATION_EMAIL_ERROR";

const SEND_USER_BANK_ACCOUNT_EMAIL_REQUEST = "SEND_USER_BANK_ACCOUNT_EMAIL_REQUEST";
const SEND_USER_BANK_ACCOUNT_EMAIL_SUCCESS = "SEND_USER_BANK_ACCOUNT_EMAIL_SUCCESS";
const SEND_USER_BANK_ACCOUNT_EMAIL_ERROR = "SEND_USER_BANK_ACCOUNT_EMAIL_ERROR";

const SEND_PAYOUT_EMAIL_REMINDERS_REQUEST = "SEND_PAYOUT_EMAIL_REMINDERS_REQUEST";
const SEND_PAYOUT_EMAIL_REMINDERS_SUCCESS = "SEND_PAYOUT_EMAIL_REMINDERS_SUCCESS";
const SEND_PAYOUT_EMAIL_REMINDERS_ERROR = "SEND_PAYOUT_EMAIL_REMINDERS_ERROR";

const SEND_BULK_PAYOUT_EMAIL_REMINDERS_REQUEST = "SEND_BULK_PAYOUT_EMAIL_REMINDERS_REQUEST";
const SEND_BULK_PAYOUT_EMAIL_REMINDERS_SUCCESS = "SEND_BULK_PAYOUT_EMAIL_REMINDERS_SUCCESS";
const SEND_BULK_PAYOUT_EMAIL_REMINDERS_ERROR = "SEND_BULK_PAYOUT_EMAIL_REMINDERS_ERROR";

export const SEARCH_PAYMENTS_REQUEST = "SEARCH_PAYMENTS_REQUEST";
export const SEARCH_PAYMENTS_SUCCESS = "SEARCH_PAYMENTS_SUCCESS";
export const SEARCH_PAYMENTS_ERROR = "SEARCH_PAYMENTS_ERROR";

const MASS_MARK_PAYMENT_COMPLETE_REQUEST = "MASS_MARK_PAYMENT_COMPLETE_REQUEST";
const MASS_MARK_PAYMENT_COMPLETE_SUCCESS = "MASS_MARK_PAYMENT_COMPLETE_SUCCESS";
const MASS_MARK_PAYMENT_COMPLETE_ERROR = "MASS_MARK_PAYMENT_COMPLETE_ERROR";

export const GET_PAYOUT_INVOICE_REQUEST = "GET_PAYOUT_INVOICE_REQUEST";
export const GET_PAYOUT_INVOICE_SUCCESS = "GET_PAYOUT_INVOICE_SUCCESS";
export const GET_PAYOUT_INVOICE_ERROR = "GET_PAYOUT_INVOICE_ERROR";

export const searchPayouts = () => {
	return (dispatch, getState) => {
		dispatch({ type: SEARCH_PAYOUT_REQUEST });
		PayoutsService.searchPayouts(getState().search)
			.then((response) => {
				dispatch({
					type: SEARCH_PAYOUT_SUCCESS,
					payouts: response.results,
					count: response.count
				});
			})
			.catch(() => {
				dispatch({ type: SEARCH_PAYOUT_ERROR });
			});
	};
};

export const changePayoutStatus = (payoutDateId, status, load) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_PAYOUT_STATUS_REQUEST });
		PayoutsService.changePayoutStatus(payoutDateId, status)
			.then(() => {
				dispatch({ type: CHANGE_PAYOUT_STATUS_SUCCESS });
				load();
			})
			.catch(() => {
				dispatch({ type: CHANGE_PAYOUT_STATUS_ERROR });
			});
	};
};

export const flagPayout = (payoutId, load) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_PAYOUT_FLAG_REQUEST });
		PayoutsService.flagPayout(payoutId)
			.then(() => {
				dispatch({ type: CHANGE_PAYOUT_FLAG_SUCCESS });
				load();
			})
			.catch(() => {
				dispatch({ type: CHANGE_PAYOUT_FLAG_ERROR });
			});
	};
};

export const massFlagPayout = (payoutIdArray, flagged, load) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_PAYOUT_FLAG_REQUEST });
		PayoutsService.massFlagPayout(payoutIdArray, flagged)
			.then(() => {
				dispatch({ type: CHANGE_PAYOUT_FLAG_SUCCESS });
				load();
			})
			.catch(() => {
				dispatch({ type: CHANGE_PAYOUT_FLAG_ERROR });
			});
	};
};

export const massChangePayoutStatus = (payoutDateIdArray, status, load) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_PAYOUT_STATUS_REQUEST });
		PayoutsService.massChangePayoutStatus(payoutDateIdArray, status)
			.then(() => {
				dispatch({ type: CHANGE_PAYOUT_STATUS_SUCCESS });
				load();
			})
			.catch(() => {
				dispatch({ type: CHANGE_PAYOUT_STATUS_ERROR });
			});
	};
};

export const getPayoutCsvReport = () => {
	return (dispatch, getState) => {
		dispatch({ type: CSV_PAYOUT_REQUEST });
		PayoutsService.getPayoutCsvReport(getState().search)
			.then((response) => {
				dispatch({ type: CSV_PAYOUT_SUCCESS });
				FileDownload.saveAs(response, "payouts-report.csv");
			})
			.catch(() => {
				dispatch({ type: CSV_PAYOUT_ERROR });
			});
	};
};

export const getPaymentCsvReport = () => {
	return (dispatch, getState) => {
		dispatch({ type: CSV_PAYOUT_REQUEST });
		PayoutsService.getPaymentCsvReport(getState().search)
			.then((response) => {
				dispatch({ type: CSV_PAYOUT_SUCCESS });
				FileDownload.saveAs(response, "payment-report.csv");
			})
			.catch(() => {
				dispatch({ type: CSV_PAYOUT_ERROR });
			});
	};
};

export const sendUserVerificationEmail = (eventId) => {
	return (dispatch) => {
		dispatch({ type: SEND_USER_VERIFICATION_EMAIL_REQUEST });
		PayoutsService.sendUserVerificationEmail(eventId)
			.then(() => {
				dispatch({ type: SEND_USER_VERIFICATION_EMAIL_SUCCESS });
				notification.success({
					message: "Email sent",
					description: "User verification email sent"
				});
			})
			.catch(() => {
				dispatch({ type: SEND_USER_VERIFICATION_EMAIL_ERROR });
			});
	};
};

export const sendUserBankDetailsEmail = (eventId) => {
	return (dispatch) => {
		dispatch({ type: SEND_USER_BANK_ACCOUNT_EMAIL_REQUEST });
		PayoutsService.sendUserBankDetailsEmail(eventId)
			.then(() => {
				dispatch({ type: SEND_USER_BANK_ACCOUNT_EMAIL_SUCCESS });
				notification.success({
					message: "Email sent",
					description: "User bank details email sent"
				});
			})
			.catch(() => {
				dispatch({ type: SEND_USER_BANK_ACCOUNT_EMAIL_ERROR });
			});
	};
};

export const sendPayoutReminderEmails = (eventId, verifyEmail, bankEmail) => {
	return (dispatch) => {
		dispatch({ type: SEND_PAYOUT_EMAIL_REMINDERS_REQUEST });
		PayoutsService.sendPayoutReminderEmails(eventId, verifyEmail, bankEmail)
			.then(() => {
				dispatch({ type: SEND_PAYOUT_EMAIL_REMINDERS_SUCCESS });
				notification.success({
					message: "Emails sent",
					description: "Reminder emails sent"
				});
			})
			.catch(() => {
				dispatch({ type: SEND_PAYOUT_EMAIL_REMINDERS_ERROR });
			});
	};
};

export const massSendPayoutEmails = (eventIds, verifyEmail, bankEmail) => {
	return (dispatch) => {
		dispatch({ type: SEND_BULK_PAYOUT_EMAIL_REMINDERS_REQUEST });
		PayoutsService.massSendPayoutEmails(eventIds, verifyEmail, bankEmail)
			.then(() => {
				dispatch({ type: SEND_BULK_PAYOUT_EMAIL_REMINDERS_SUCCESS });
				notification.success({
					message: "Emails sent",
					description: "Reminder emails sent"
				});
			})
			.catch(() => {
				dispatch({ type: SEND_BULK_PAYOUT_EMAIL_REMINDERS_ERROR });
			});
	};
};

export const searchPayments = () => {
	return (dispatch, getState) => {
		dispatch({ type: SEARCH_PAYMENTS_REQUEST });
		PayoutsService.searchPayments(getState().search)
			.then((response) => {
				dispatch({
					type: SEARCH_PAYMENTS_SUCCESS,
					payments: response.results,
					count: response.count
				});
			})
			.catch(() => {
				dispatch({ type: SEARCH_PAYMENTS_ERROR });
			});
	};
};

export const markPaymentsAsComplete = (payoutsToMarkAsComplete) => {
	return async (dispatch) => {
		dispatch({ type: MASS_MARK_PAYMENT_COMPLETE_REQUEST });
		try {
			await PayoutsService.markPaymentsAsComplete(payoutsToMarkAsComplete);
			notification.success({
				message: "Marked as complete"
			});
			dispatch({ type: MASS_MARK_PAYMENT_COMPLETE_SUCCESS });
			dispatch(searchPayouts());
		} catch (err) {
			dispatch({ type: MASS_MARK_PAYMENT_COMPLETE_ERROR });
			notification.error({
				message: "Error",
				description: "Could not mark payments as complete"
			});
		}
	};
};
