import { Component } from "react";
import { connect } from "react-redux";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import Breadcrumb from "@/components/Breadcrumb";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { LegacyInputField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import QuestionRows from "@/events/event/additionalQuestions/QuestionRows";
import { getEvent } from "@/state/actions/eventActions";
import {
	getAdditionalQuestionsTemplate,
	saveAdditionalQuestionsTemplate,
	updateAdditionalQuestionsTemplate
} from "@/state/actions/templateActions";
import { required } from "@/utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class AdditionalQuestions extends Component {
	nameRequired = required("A template name is required");
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.cancel = this.cancel.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	componentDidMount() {
		const { location, getEvent, params, getAdditionalQuestionsTemplate, currentUser } = this.props;
		const id = params?.id;
		const { isFromEvent, isExisting } = location && location.state ? location.state : {};
		if (isFromEvent) {
			getEvent(id);
		}
		if (isExisting) {
			getAdditionalQuestionsTemplate(currentUser.user.id, id);
		}
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	save(value) {
		const {
			saveAdditionalQuestionsTemplate,
			updateAdditionalQuestionsTemplate,
			currentUser,
			location,
			params,
			navigate
		} = this.props;
		const { isExisting } = location && location.state ? location.state : {};
		const id = params?.id;

		const additionalQuestionsConverted = value.additionalQuestions.map((question) => {
			const convertedQuestion = {
				...question,
				conditions: []
			};
			if (question.conditions && Array.isArray(question.conditions)) {
				convertedQuestion.conditions = question.conditions.map((condition) => {
					const newCondition =
						condition.value && Array.isArray(condition.value)
							? { ...condition, value: condition.value.join("{|}") }
							: { ...condition };
					return newCondition;
				});
			}
			return convertedQuestion;
		});
		const newValues = {
			...value,
			additionalQuestions: additionalQuestionsConverted
		};
		if (isExisting) {
			updateAdditionalQuestionsTemplate(currentUser.user.id, id, newValues);
		} else {
			saveAdditionalQuestionsTemplate(currentUser.user.id, newValues, navigate);
		}
	}

	cancel() {
		const { navigate } = this.props;
		navigate("/console/account/advanced/templates/additional-questions-templates");
	}

	render() {
		const {
			touch,
			timezone,
			check,
			currentEvent,
			handleSubmit,
			currentTemplates,
			additionalQuestions,
			name,
			dispatch
		} = this.props;
		const { get } = currentEvent;
		const loading = get.loading || currentTemplates.loading;
		const error = get.error || currentTemplates.error;
		const title = name || "New checkout question";
		return (
			<div>
				<Breadcrumb
					prevPath="/console/account/advanced/templates/additional-questions-templates"
					prevTitle="Checkout questions template"
					currTitle={title}
				/>
				<H1>{title}</H1>
				<form onSubmit={handleSubmit(this.save)}>
					<ContentBlock>
						<LoadErrorView loading={loading} error={error}>
							{check && (!additionalQuestions || !additionalQuestions.length) ? (
								<div className="has-error">
									<div className="ant-form-explain">Please create at least one ticket</div>
								</div>
							) : null}
							<Field
								name="name"
								label="Template Name"
								autocomplete="nope"
								description="Name your template so you can find it easier"
								component={LegacyInputField}
								style={{ width: "50%" }}
								validate={this.nameRequired}
							/>

							<FieldArray
								name="additionalQuestions"
								component={QuestionRows}
								additionalQuestions={additionalQuestions || []}
								isFromtemplate
								touch={touch}
								changeFieldValue={this.changeFieldValue}
								timezone={timezone}
								removeConditions={this.changeFieldValue}
								formName={formName}
								dispatch={dispatch}
							/>
						</LoadErrorView>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							ariaLabel="Save"
							size="large"
							dataCy="Save"
						>
							Save
						</LegacyButton>
						<LegacyButton
							onClick={this.cancel}
							style={{
								float: "left"
							}}
							ariaLabel="Cancel"
							size="large"
						>
							Cancel
						</LegacyButton>
					</FormBar>
				</form>
			</div>
		);
	}
}

const formName = "additionalQuestions";
AdditionalQuestions = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(AdditionalQuestions);

const selector = formValueSelector(formName);

AdditionalQuestions = connect((state, ownProps) => {
	let initialValues = {
		name: "",
		additionalQuestions: []
	};
	const { location } = ownProps;
	const { isFromEvent, isExisting } = location && location.state ? location.state : {};
	if (isFromEvent && state.currentEvent && state.currentEvent.event) {
		initialValues.additionalQuestions =
			state.currentEvent.event.additionalQuestions && state.currentEvent.event.additionalQuestions.length
				? state.currentEvent.event.additionalQuestions.map((question) => {
						question.appliesTo = [];
						return question;
				  })
				: [];
	}
	if (isExisting && state.templates && state.templates.selectedTemplate) {
		initialValues = state.templates.selectedTemplate;
	}
	// map per order to the ui value per ticket
	initialValues.additionalQuestions = initialValues.additionalQuestions.map((a) => {
		a.perTicket = !a.perOrder;
		return a;
	});

	return {
		initialValues,
		enableReinitialize: true,
		additionalQuestions: selector(state, "additionalQuestions"),
		name: selector(state, "name")
	};
})(AdditionalQuestions);

export default connect(
	(state) => ({
		currentUser: state.auth,
		currentEvent: state.currentEvent,
		currentTemplates: state.templates
	}),
	{
		getEvent,
		saveAdditionalQuestionsTemplate,
		updateAdditionalQuestionsTemplate,
		getAdditionalQuestionsTemplate
	}
)(withRouterHooks(AdditionalQuestions));
