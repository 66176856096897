import { SeatingMap, State } from "@hx/seating-map";
import { notification } from "@/ui/antd";
import SeatingMapService from "../../services/SeatingMapService";
import { GET_EVENT_SUCCESS } from "./eventActions";
import EventsService from "@/services/EventsService";

const { AssigningManager } = State.Assigning;
export const CREATE_SEATING_MAP_REQUEST = "CREATE_SEATING_MAP_REQUEST";
export const CREATE_SEATING_MAP_SUCCESS = "CREATE_SEATING_MAP_SUCCESS";
export const CREATE_SEATING_MAP_ERROR = "CREATE_SEATING_MAP_ERROR";

export const GET_SEATING_MAP_REQUEST = "GET_SEATING_MAP_REQUEST";
export const GET_SEATING_MAP_SUCCESS = "GET_SEATING_MAP_SUCCESS";
export const GET_SEATING_MAP_ERROR = "GET_SEATING_MAP_ERROR";

export const GET_ORGANISER_MAP_REQUEST = "GET_ORGANISER_MAP_REQUEST";
export const GET_ORGANISER_MAP_SUCCESS = "GET_ORGANISER_MAP_SUCCESS";
export const GET_ORGANISER_MAP_ERROR = "GET_ORGANISER_MAP_ERROR";

export const NEW_ORGANISATION_STATE = "NEW_ORGANISATION_STATE";

export const MOVE_TICKET_REQUEST = "MOVE_TICKET_REQUEST";
export const MOVE_TICKET_SUCCESS = "MOVE_TICKET_SUCCESS";
export const MOVE_TICKET_ERROR = "MOVE_TICKET_ERROR";

export const UNASSIGN_TICKET_REQUEST = "UNASSIGN_TICKET_REQUEST";
export const UNASSIGN_TICKET_SUCCESS = "UNASSIGN_TICKET_SUCCESS";
export const UNASSIGN_TICKET_ERROR = "UNASSIGN_TICKET_ERROR";

export const SAVE_SEATING_MAP_REQUEST = "SAVE_SEATING_MAP_REQUEST";
export const SAVE_SEATING_MAP_SUCCESS = "SAVE_SEATING_MAP_SUCCESS";
export const SAVE_SEATING_MAP_ERROR = "SAVE_SEATING_MAP_ERROR";

export const GET_TICKETS_FOR_MAP_REQUEST = "GET_TICKETS_FOR_MAP_REQUEST";
export const GET_TICKETS_FOR_MAP_SUCCESS = "GET_TICKETS_FOR_MAP_SUCCESS";
export const GET_TICKETS_FOR_MAP_ERROR = "GET_TICKETS_FOR_MAP_ERROR";

export const NEW_SEATING_MAP = "NEW_SEATING_MAP";
export const EXIT_SEATING_MAP = "EXIT_SEATING_MAP";
export const VIEW_SWITCH = "VIEW_SWITCH";
export const CLEAR_SEATING_MAP_STATE = "CLEAR_SEATING_MAP_STATE";

export const UPDATE_SEATING_MAP_REQUEST = "UPDATE_SEATING_MAP_REQUEST";
export const UPDATE_SEATING_MAP_SUCCESS = "UPDATE_SEATING_MAP_SUCCESS";
export const UPDATE_SEATING_MAP_ERROR = "UPDATE_SEATING_MAP_ERROR";

export const GET_UNMAPPED_TICKETS_REQUEST = "GET_UNMAPPED_TICKETS_REQUEST";
export const GET_UNMAPPED_TICKETS_SUCCESS = "GET_UNMAPPED_TICKETS_SUCCESS";
export const GET_UNMAPPED_TICKETS_ERROR = "GET_UNMAPPED_TICKETS_ERROR";

export const GET_CLONEABLE_MAPS_REQUEST = "GET_CLONEABLE_MAPS_REQUEST";
export const GET_CLONEABLE_MAPS_SUCCESS = "GET_CLONEABLE_MAPS_SUCCESS";
export const GET_CLONEABLE_MAPS_ERROR = "GET_CLONEABLE_MAPS_ERROR";

export const SEATING_MAP_CHANGE_DATE = "SEATING_MAP_CHANGE_DATE";

export const SET_MANAGER_TAB = "SET_MANAGER_TAB";

export const ManagerTab = {
	ASSIGNED: "assigned",
	UNASSIGNED: "unassigned"
};

export const setManagerTab = (tab) => {
	return (dispatch) => {
		dispatch({ type: SET_MANAGER_TAB, tab });
	};
};

export const removeSeatingMap = (eventId, id) => {
	return async (dispatch) => {
		dispatch({ type: UPDATE_SEATING_MAP_REQUEST });
		try {
			const updateEvent = await SeatingMapService.removeFromEvent(eventId, id);
			dispatch({ type: UPDATE_SEATING_MAP_SUCCESS });
			dispatch({ type: GET_EVENT_SUCCESS, event: updateEvent });
			notification.success({
				message: "Removed",
				description: "Your seating map has been removed"
			});
		} catch (err) {
			dispatch({ type: UPDATE_SEATING_MAP_ERROR, error: err });
		}
	};
};

export const changeSetting = (eventId, id, setting, pub) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_SEATING_MAP_REQUEST });
		SeatingMapService.changeSetting(eventId, id, setting, pub)
			.then((result) => {
				dispatch({ type: UPDATE_SEATING_MAP_SUCCESS });
				dispatch({ type: GET_EVENT_SUCCESS, event: result });
			})
			.catch((err) => {
				dispatch({ type: UPDATE_SEATING_MAP_ERROR, error: err });
			});
	};
};

export const newSeatingMap = (eventId, navigate) => {
	return async (dispatch) => {
		dispatch({ type: CREATE_SEATING_MAP_REQUEST });
		const { seatingMapId, event } = await SeatingMapService.new(eventId);
		dispatch({ type: GET_EVENT_SUCCESS, event });
		navigate(`/console/my-events/${eventId}/seatingmap/${seatingMapId}/construction`);
	};
};

export const getCloneableMaps = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_CLONEABLE_MAPS_REQUEST });
			const { maps } = await SeatingMapService.getCloneableMaps();
			dispatch({ type: GET_CLONEABLE_MAPS_SUCCESS, maps });
		} catch (err) {
			dispatch({ type: GET_CLONEABLE_MAPS_ERROR, err });
		}
	};
};

export const cloneSeatingMap = (eventIdWithSeatingMap, capacity, seatingMapIdToClone, navigate) => {
	return async (dispatch, getState) => {
		try {
			const eventIdWithoutSeatingMap = getState().currentEvent.event._id;
			dispatch({ type: CREATE_SEATING_MAP_REQUEST });
			const { seatingMapId, event } = await SeatingMapService.cloneMap(
				eventIdWithoutSeatingMap,
				eventIdWithSeatingMap,
				seatingMapIdToClone,
				capacity
			);
			dispatch({ type: GET_EVENT_SUCCESS, event });
			navigate(`/console/my-events/${eventIdWithoutSeatingMap}/seatingmap/${seatingMapId}/construction`);
		} catch (err) {
			console.log("err", err);
			dispatch({ type: CREATE_SEATING_MAP_ERROR, err });
		}
	};
};

export const createSeatingMap = (eventId, seatingMapData, capacity) => {
	return (dispatch) => {
		dispatch({ type: CREATE_SEATING_MAP_REQUEST });
		SeatingMapService.create(eventId, seatingMapData, capacity)
			.then((data) => {
				notification.success({
					message: "Created",
					description: "Your seating map has been created"
				});
				dispatch({
					type: CREATE_SEATING_MAP_SUCCESS,
					seatingMap: data.seatingMap
				});
				dispatch({ type: GET_EVENT_SUCCESS, event: data.event });
			})
			.catch((err) => {
				dispatch({ type: CREATE_SEATING_MAP_ERROR, error: err });
			});
	};
};

export const saveSeatingMap = ({
	eventId,
	seatingMapId,
	seatingMapData,
	capacity,
	view,
	navigate,
	exitSeatingMapAfterSave,
	eventCapacityData
}) => {
	return async (dispatch, getState) => {
		dispatch({ type: SAVE_SEATING_MAP_REQUEST });
		try {
			const updatedEvent = await SeatingMapService.save(
				eventId,
				seatingMapId,
				seatingMapData,
				capacity,
				eventCapacityData
			);

			notification.success({
				message: "Saved",
				description: "Your seating map has been saved"
			});

			dispatch({ type: SAVE_SEATING_MAP_SUCCESS, seatingMap: { _id: seatingMapId, seatingMapData } });
			dispatch({ type: GET_EVENT_SUCCESS, event: updatedEvent });

			SeatingMap.instance.reload(seatingMapData);

			if (exitSeatingMapAfterSave) {
				exitSeatingMap(eventId, navigate)(dispatch);
			} else {
				switch (view) {
					case "managerView": {
						const {
							currentEvent: { event }
						} = getState();
						const eventDateId = event.dates.find((date) => !date.disabled && !date.deleted)._id;
						getOrganiserMap(eventId, eventDateId, seatingMapId)(dispatch, getState);
						break;
					}
					case "ticketMapping":
						SeatingMap.instance.switchToTicketMappingView();
						break;
					case "seatingView":
						SeatingMap.instance.switchToSeatView();
						break;
				}
			}
		} catch (err) {
			console.log("err", err);
			notification.error({
				message: "Error",
				description: "Failed to save seating map"
			});
			dispatch({ type: SAVE_SEATING_MAP_ERROR, error: err });
		}
	};
};

export const getOrganiserMap = (eventId, eventDateId, seatingMapId) => {
	return (dispatch) => {
		dispatch({ type: GET_ORGANISER_MAP_REQUEST });
		SeatingMapService.getOrganiserMap(eventId, eventDateId, seatingMapId)
			.then((managerData) => {
				SeatingMap.getCurrentMap().switchToManagementView(managerData.seatingMapData.seatingMapData, {});
				dispatch({ type: GET_ORGANISER_MAP_SUCCESS, managerData });
			})
			.catch((err) => {
				dispatch({ type: GET_ORGANISER_MAP_ERROR, error: err });
			});
	};
};

export const move = (eventId, seatingMapId, ticketId, to) => {
	return (dispatch, getState) => {
		const eventDateId = getCurrentEventDateId(getState());
		dispatch({ type: MOVE_TICKET_REQUEST });
		SeatingMapService.move(eventId, eventDateId, seatingMapId, ticketId, to)
			.then((result) => {
				if (!result.success) {
					AssigningManager.conflictIndividualMove(result.conflictingTicket);
					dispatch({
						type: MOVE_TICKET_ERROR,
						error: "This ticket has been assigned to a seat that is taken. Please select a different seat."
					});
				} else {
					AssigningManager.commitIndividualMove();
					dispatch({
						type: MOVE_TICKET_SUCCESS,
						success: true,
						unassignedTickets: result.result
					});
				}
			})
			.catch((err) => {
				dispatch({ type: MOVE_TICKET_ERROR, error: err.message });
			});
	};
};

export const getUnmappedTickets = ({ assignOption, search, page }) => {
	return async (dispatch, getState) => {
		const state = getState();
		const event = state.currentEvent.event;
		const eventId = event._id;
		const seatingMapState = state.seatingMap;
		const seatingMapId = seatingMapState.seatingMap._id;
		const organiserState = seatingMapState.organiser;
		const eventDateId = getCurrentEventDateId(state);
		await fetchUnmappedTickets(dispatch, organiserState, {
			eventId,
			eventDateId,
			seatingMapId,
			assignOption: assignOption || organiserState.unassignedMode,
			search: search !== undefined ? search : organiserState.search,
			page: page
		});
	};
};

const fetchUnmappedTickets = async (
	dispatch,
	organiserState,
	{ eventId, eventDateId, seatingMapId, assignOption, search, page }
) => {
	const searchQuery = search !== undefined ? search : organiserState?.search;
	dispatch({ type: GET_UNMAPPED_TICKETS_REQUEST, unassignedMode: assignOption, search, page });
	try {
		const result = await SeatingMapService.getUnassignedTickets(
			eventId,
			eventDateId,
			seatingMapId,
			assignOption,
			searchQuery,
			page
		);
		dispatch({
			type: GET_UNMAPPED_TICKETS_SUCCESS,
			unmappedTickets: result.tickets,
			page: result.page
		});
	} catch (err) {
		dispatch({ type: GET_UNMAPPED_TICKETS_ERROR, error: err });
	}
};

export const moveIndividual = () => {
	return (dispatch, getState) => {
		const {
			currentEvent: { event },
			seatingMap: { organiserMap }
		} = getState();
		const { fromTicketId, fromSeat, toSeat, toTicketId, isSwap } = AssigningManager.getSaveMoveIndividual();
		const eventId = event._id;
		const seatingMapId = organiserMap._id;
		if (isSwap) {
			swap(eventId, seatingMapId, fromTicketId, fromSeat, toSeat, toTicketId)(dispatch, getState);
		} else {
			move(eventId, seatingMapId, fromTicketId, toSeat)(dispatch, getState);
		}
	};
};

export const moveBulk = () => {
	return async (dispatch, getState) => {
		try {
			const {
				currentEvent: { event },
				seatingMap: { organiserMap }
			} = getState();
			dispatch({ type: MOVE_TICKET_REQUEST });
			const moveArray = AssigningManager.getSaveMoveOrder();
			const eventId = event._id;
			const seatingMapId = organiserMap._id;
			const eventDateId = getCurrentEventDateId(getState());
			const moveBulkResult = await SeatingMapService.moveBulk(eventId, eventDateId, seatingMapId, moveArray);
			const numberOfConflicts = moveBulkResult.bulkMoveResult.filter(({ conflict }) => conflict).length;
			const hasConflicts = numberOfConflicts > 0;
			const plural = numberOfConflicts > 1;
			let error;
			if (hasConflicts) {
				if (!plural) {
					error = "This ticket has been assigned to a seat that is taken. Please select a different seat.";
				} else {
					error =
						"One of your tickets has been assigned to a seat that is taken. Please select a different seat for this ticket.";
				}
			}
			AssigningManager.commitOrderMove(moveBulkResult.bulkMoveResult);
			dispatch({
				type: MOVE_TICKET_SUCCESS,
				unassignedTickets: moveBulkResult.unassignedTickets,
				error
			});
		} catch (err) {
			dispatch({ type: MOVE_TICKET_ERROR, error: err });
		}
	};
};

export const movePackage = () => {
	return async (dispatch, getState) => {
		try {
			const {
				currentEvent: { event },
				seatingMap: { organiserMap }
			} = getState();
			dispatch({ type: MOVE_TICKET_REQUEST });
			const moveArray = AssigningManager.getSaveMovePackage();
			const eventId = event._id;
			const seatingMapId = organiserMap._id;
			const eventDateId = getCurrentEventDateId(getState());
			const moveBulkResult = await SeatingMapService.movePackage(eventId, eventDateId, seatingMapId, moveArray);
			let error;
			if (moveBulkResult.conflict) {
				AssigningManager.revertPackageMove(moveBulkResult.conflictChecks);
				error =
					"One of your tickets has been assigned to a seat that is taken. Please select a different seat for this ticket.";
			} else {
				AssigningManager.commitPackageMove();
			}
			dispatch({
				type: MOVE_TICKET_SUCCESS,
				unassignedTickets: moveBulkResult.unassignedTickets,
				error
			});
		} catch (err) {
			dispatch({ type: MOVE_TICKET_ERROR, error: err });
		}
	};
};

export const swap = (eventId, seatingMapId, fromTicketId, fromSeat, toSeat, toTicketId) => {
	return (dispatch, getState) => {
		const eventDateId = getCurrentEventDateId(getState());
		dispatch({ type: MOVE_TICKET_REQUEST });
		SeatingMapService.swap(eventId, eventDateId, seatingMapId, fromTicketId, fromSeat, toSeat, toTicketId)
			.then((managerData) => {
				AssigningManager.commitIndividualMove();
				dispatch({
					type: MOVE_TICKET_SUCCESS,
					success: true,
					unassignedTickets: managerData
				});
			})
			.catch((err) => {
				dispatch({ type: MOVE_TICKET_ERROR, error: err });
			});
	};
};

export const searchTickets = ({ eventId, seatingMapId, type, searchQuery, page }) => {
	return (dispatch, getState) => {
		const state = getState();
		const seatingMapState = state.seatingMap;
		const eventDateId = getCurrentEventDateId(getState());
		const organiserState = seatingMapState.organiser;
		const sQuery = searchQuery !== undefined ? searchQuery : organiserState?.search;

		dispatch({ type: GET_TICKETS_FOR_MAP_REQUEST, assignedMode: type, search: sQuery, page });

		SeatingMapService.findTickets(eventId, eventDateId, seatingMapId, type, sQuery, page)
			.then((data) => {
				dispatch({
					type: GET_TICKETS_FOR_MAP_SUCCESS,
					tickets: data.tickets,
					page: data.page
				});
			})
			.catch((err) => {
				dispatch({ type: GET_TICKETS_FOR_MAP_ERROR, error: err });
			});
	};
};

export const unAssign = () => {
	return (dispatch, getState) => {
		const {
			currentEvent: { event },
			seatingMap: { organiserMap }
		} = getState();
		const eventId = event._id;
		const seatingMapId = organiserMap._id;
		const eventDateId = getCurrentEventDateId(getState());
		const { fromTicketId } = AssigningManager.getSaveMoveIndividual();
		dispatch({ type: UNASSIGN_TICKET_REQUEST });
		SeatingMapService.unAssign(eventId, eventDateId, seatingMapId, fromTicketId)
			.then((managerData) => {
				dispatch({
					type: UNASSIGN_TICKET_SUCCESS,
					success: true,
					unassignedTickets: managerData
				});
				AssigningManager.commitIndividualMove();
			})
			.catch((err) => {
				dispatch({ type: UNASSIGN_TICKET_ERROR, error: err });
			});
	};
};

export const resetOrganiserState = () => {
	return (dispatch) => {
		dispatch({ type: NEW_ORGANISATION_STATE });
	};
};

export const startLoading = () => {
	return async (dispatch) => {
		dispatch({ type: GET_SEATING_MAP_REQUEST });
	};
};

export const getSeatingMap = ({ seatingMapId, undo, redo, addUndoAction, actions, view }) => {
	return async (dispatch, getState) => {
		dispatch({ type: GET_SEATING_MAP_REQUEST });
		try {
			const state = getState();
			const event = state.currentEvent.event;
			const eventId = event._id;
			const seatingMap = await SeatingMapService.get(eventId, seatingMapId);
			const seatingMapData = seatingMap.seatingMapData;
			const ticketTypeIds = event.ticketTypes
				.concat(event.packagedTickets)
				.filter((ticketType) => !ticketType.deleted && !ticketType.disabled)
				.map((ticketType) => String(ticketType._id));
			const instancedSeatingMap = await SeatingMap.createSeatingMap(
				seatingMapData,
				actions,
				"/",
				{ width: "73%", height: "100%" },
				ticketTypeIds,
				{
					undoTrackerCb: addUndoAction,
					undoCb: undo,
					redoCb: redo
				}
			);
			instancedSeatingMap.switchToSeatView();
			dispatch({ type: GET_SEATING_MAP_SUCCESS, seatingMap });
			if (view === "managerView") {
				dispatch({ type: VIEW_SWITCH });
				//todo remove this, this is just for joel
				const eventDateId = event.dates.find((date) => !date.disabled && !date.deleted)._id;
				const managerData = await SeatingMapService.getOrganiserMap(eventId, eventDateId, seatingMapId);
				SeatingMap.getCurrentMap().switchToManagementView(managerData.seatingMapData.seatingMapData, actions);
				dispatch({ type: GET_ORGANISER_MAP_SUCCESS, managerData });
			}
		} catch (err) {
			console.log("err", err);
			dispatch({ type: GET_SEATING_MAP_ERROR, error: err });
		}
	};
};

export const clearSeatingMapState = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_SEATING_MAP_STATE });
	};
};

export const exitSeatingMap = (eventId, navigate) => {
	return async (dispatch) => {
		const event = await EventsService.get(eventId);
		dispatch({ type: GET_EVENT_SUCCESS, event });
		navigate(`/console/my-events/${eventId}/seatingmap/selection`);
		dispatch({ type: EXIT_SEATING_MAP });
	};
};

export const leftManagerView = () => {
	return (dispatch) => {
		dispatch({ type: VIEW_SWITCH });
	};
};

export const changeDate = (date, validDates, eventId, seatingMapId) => {
	return (dispatch) => {
		console.log(date, validDates, validDates[date]);
		dispatch({ type: SEATING_MAP_CHANGE_DATE, date });
		getOrganiserMap(eventId, validDates[date]._id, seatingMapId)(dispatch);
	};
};

const getCurrentEventDateId = (state) => {
	const { seatingMap, currentEvent } = state;
	const validDates = currentEvent.event.dates.filter((date) => !date.disabled && !date.deleted);
	const organiser = seatingMap.organiser;
	const date = organiser.date;
	return validDates[date]._id;
};
