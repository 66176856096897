import { getConfig } from "@/config";
import { Flex } from "@/ui/antd";
import styled from "@emotion/styled";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { Divider, Space } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { LinkWrapper as LinkWrapperComponent } from "./Links";

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 36px;
	height: 64px;
	width: 100%;

	img {
		width: auto;
		height: 24px;
	}

	@media (max-width: 768px) {
		padding: 0 18px;

		img {
			height: 18px;
		}
	}
`;

const LinkWrapper = styled(LinkWrapperComponent)`
	a {
		font-size: 14px;
	}
`;

const ForHostsText = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: #000;
	line-height: 16px;

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const HumanitixForHostsHeader: FC<{ showBuyTicketLink?: boolean }> = ({ showBuyTicketLink }) => {
	const WEBSITE_URL = getConfig("WEBSITE_URL");

	return (
		<Wrapper>
			<Space size={4} align="start">
				<img src="https://static.humanitix.com/logos/humanitix-logo-onyx.png" alt="Humanitix Logo" />

				<Divider
					type="vertical"
					style={{
						height: "1.2rem",
						marginTop: -4
					}}
				/>

				<ForHostsText>For hosts</ForHostsText>
			</Space>
			{showBuyTicketLink ? (
				<Flex gap="0.25rem" justify="center">
					<LinkWrapper>
						<Link to={`${WEBSITE_URL}/search`}>Ticket buyer?</Link>
					</LinkWrapper>
					<LinkWrapper>
						<Link to={`${WEBSITE_URL}/search`}>
							<ArrowSquareOut size={16} style={{ marginTop: "2px" }} />
						</Link>
					</LinkWrapper>
				</Flex>
			) : null}
		</Wrapper>
	);
};
