import H4 from "@/components/text/H4";
import { toggleFraudCheck, calculateFraudCheck } from "@/state/actions/newPayoutActions";
import { PayoutHeaderInformation } from "@/state/reducers/newPayoutReducer";
import { css } from "@emotion/css";
import { Alert, Switch } from "@/ui/antd";
import { connect } from "react-redux";
import { HumaniDate } from "@hx/dates";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const tableStyle = css`
	margin-bottom: 16px;

	th {
		border-bottom: none;
	}
`;

const FraudWarnings = ({
	header,
	toggleFraudCheck,
	eventId,
	calculateFraudCheck
}: IInjectedProps & IInjectedFunctions) => {
	const { fraudChecks } = header || {};
	if (!fraudChecks) {
		return (
			<Alert
				showIcon
				message={<H4>PAYOUT WARNING</H4>}
				type="warning"
				description={
					<>
						<p>This event has no fraud checks.</p>{" "}
						<LegacyButton onClick={() => calculateFraudCheck(String(eventId))}>Recalculate fraud warnings</LegacyButton>
					</>
				}
			/>
		);
	}

	if (!fraudChecks.updatedAt || new HumaniDate().dayJs.diff(new HumaniDate(fraudChecks.updatedAt).dayJs, "days") > 1) {
		return (
			<Alert
				showIcon
				message={<H4>PAYOUT WARNING</H4>}
				type="warning"
				description={
					<>
						<p>Fraud checks are out of date.</p>
						<LegacyButton onClick={() => calculateFraudCheck(String(eventId))}>Recalculate fraud warnings</LegacyButton>
					</>
				}
			/>
		);
	}

	const someFraudChecksFailed = Object.values(fraudChecks).some((fraudCheck: any) => fraudCheck.failing);
	if (!someFraudChecksFailed) return null;

	return (
		<Alert
			showIcon
			message={<H4>PAYOUT WARNING</H4>}
			type="warning"
			description={
				<>
					<table className={tableStyle}>
						<tbody>
							<tr>
								<th>
									<b>Check</b>
								</th>
								<th>
									<b>Reason</b>
								</th>
								<th>
									<b>Ignore</b>
								</th>
							</tr>
							{Object.entries(fraudChecks)
								.filter(([_, fraudCheck]: any) => fraudCheck.failing)
								.map(([key, fraudCheck]: any) => (
									<tr>
										<td>{fraudCheck.label.slice(0, -1)}</td>
										<td>{fraudCheck.message}</td>
										<td>
											<Switch
												onClick={() => toggleFraudCheck(eventId!, key, !fraudCheck.ignore)}
												checked={fraudCheck.ignore}
											/>
										</td>
									</tr>
								))}
						</tbody>
					</table>
					<LegacyButton onClick={() => calculateFraudCheck(String(eventId))}>Recalculate fraud warnings</LegacyButton>
				</>
			}
		/>
	);
};

interface IInjectedProps {
	header?: PayoutHeaderInformation;
	eventId?: string;
}

interface IInjectedFunctions {
	toggleFraudCheck: (eventId: string, fraudId: string, setTo: boolean) => void;
	calculateFraudCheck: (eventId: string) => void;
}

export default connect(
	(state: any): IInjectedProps => {
		const header = state.payouts.eventPayout.payoutInformation?.header;
		return {
			header,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ toggleFraudCheck, calculateFraudCheck }
)(FraudWarnings);
