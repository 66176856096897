import { Button } from "@/ui";
import { Form, FormInstance, Tooltip } from "@/ui/antd";
import { DeleteOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";

const StyledChevron = styled(UpOutlined, {
	shouldForwardProp: (prop) => prop !== "expanded"
})<{
	expanded: boolean;
}>`
	${({ expanded }) =>
		css`
			transform: ${expanded ? "scaleY(1)" : "scaleY(-1)"};
			transition: transform 0.2s ease-in-out;
		`}
`;

const AddModuleButton = styled.button`
	align-items: center;
	background: none;
	border-radius: 8px;
	border: 1px dashed #1b1b1b24;
	color: #151413;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: 500;
	gap: 8px;
	justify-content: center;
	padding: 16px;
	width: 100%;

	span {
		display: flex;
		gap: 8px;
	}

	p {
		margin: 0;
	}

	&:hover {
		background: #f7f7f7;
	}
`;

const headerMarginBottom = 24;

const CardHeader = styled.div<{
	expanded: boolean;
}>`
	${({ expanded }) => css`
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: ${expanded ? headerMarginBottom : 0}px;
		max-height: 24px;
		cursor: pointer;
		transition: margin 0.2s ease-in-out;

		h3 {
			font-size: 14px;
			font-weight: 700;
			margin: 0;
		}
	`}
`;

const ModuleCard = styled.div<{
	expanded: boolean;
	maxHeight: number;
}>`
	${({ expanded, maxHeight }) => {
		const padding = 24;
		const finalMaxHeight = maxHeight + 2 * padding + (expanded ? headerMarginBottom : 0);

		return css`
			background: none;
			border-radius: 8px;
			border: 1px solid #1b1b1b24;
			max-height: ${finalMaxHeight}px;
			overflow: hidden;
			padding: ${padding}px 16px;
			transition: max-height 0.3s ease-in-out;
		`;
	}}
`;

const CardActions = styled.div`
	display: flex;
	gap: 4px;
`;

const CardContent = styled.div<{
	expanded: boolean;
}>`
	display: flex;
	flex-direction: column;
	gap: 16px;
	opacity: ${({ expanded }) => (expanded ? 1 : 0)};
	transition: opacity 0.2s ease-in-out;
`;

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

type CollectionModule = {
	contents: any;
	description?: string;
	formPath: string[];
	isVisible: boolean;
	onAddModule: () => void;
	onRemoveModule: () => void;
	title: string;
};

type CollectionModulesProps = {
	form: FormInstance<Collection>;
	isVisible: boolean;
	items: CollectionModule[];
};

export const CollectionModules: FC<CollectionModulesProps> = ({ form, isVisible, items }) => {
	const formValues = useWatch([], form);

	const [expanded, setExpanded] = useState<number | null>(null);
	const [hasSetExpanded, setHasSetExpanded] = useState(false);
	const [maxHeights, setMaxHeights] = useState<number[]>([]);

	const headerRefs = useRef<(HTMLDivElement | null)[]>([]);
	const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(() => {
		if (!formValues || hasSetExpanded) return;

		const { showFeaturedEvents, showEventGroups, showEventListing } = formValues.events;
		const moduleStates = [showFeaturedEvents, showEventGroups, showEventListing];
		const showSingleModule = moduleStates.filter(Boolean).length === 1;

		if (!showSingleModule) setExpanded(null);

		// If only one module is visible, expand it by default
		const index = [showFeaturedEvents, showEventGroups, showEventListing].findIndex(Boolean);
		setExpanded(index);
		setHasSetExpanded(true);
	}, [formValues]);

	const handleToggleAccordion = (e: MouseEvent<HTMLDivElement>, index: number) => {
		e.stopPropagation();

		const newExpanded = expanded === index ? null : index;
		setExpanded(newExpanded);

		if (newExpanded !== null) {
			recalculateMaxHeights();
		}
	};

	const handleAddModule = (index: number) => {
		items[index].onAddModule();
		setTimeout(() => setExpanded(index), 100);
	};

	const recalculateMaxHeights = () => {
		const newMaxHeights = contentRefs.current.map((ref, index) => {
			if (!ref) return 0;
			const headerHeight = headerRefs.current[index]?.clientHeight || 0;
			const contentHeight = ref.clientHeight;
			const isExpanded = expanded === index;
			const height = isExpanded ? headerHeight + contentHeight : headerHeight;

			return height;
		});
		setMaxHeights(newMaxHeights);
	};

	useEffect(() => {
		recalculateMaxHeights();
	}, [formValues, expanded, isVisible]);

	return (
		<FlexColumn>
			{items.map((item, index) => {
				return (
					<div key={index}>
						<Form.Item name={item.formPath} hidden={item.isVisible} noStyle>
							<AddModuleButton onClick={() => handleAddModule(index)}>
								<span>
									<PlusOutlined />
									Add {item.title.toLowerCase()}
								</span>
								<p>{item.description}</p>
							</AddModuleButton>
						</Form.Item>
						<ModuleCard expanded={expanded === index} maxHeight={maxHeights[index]} hidden={!item.isVisible}>
							<CardHeader
								expanded={expanded === index}
								onClick={(e) => handleToggleAccordion(e, index)}
								ref={(el) => (headerRefs.current[index] = el)}
							>
								<h3>{item.title}</h3>
								<CardActions>
									<Button iconOnly variant="text">
										<StyledChevron expanded={expanded === index} />
									</Button>
									<Tooltip title={`Remove ${item.title.toLowerCase()}`}>
										<Button onClick={item.onRemoveModule} iconOnly variant="text">
											<DeleteOutlined />
										</Button>
									</Tooltip>
								</CardActions>
							</CardHeader>
							<CardContent expanded={expanded === index} ref={(el) => (contentRefs.current[index] = el)}>
								{item.contents}
							</CardContent>
						</ModuleCard>
					</div>
				);
			})}
		</FlexColumn>
	);
};
