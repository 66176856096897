import { Component } from "react";
import { Button } from "@/ui/antd";
import { connect } from "react-redux";
import { updateManageCapacityFormOnPerformAction } from "@/state/actions/eventActions";

import { BookByTable, TicketMapping } from "./Items";

const nestedElements = ["moveableText", "moveableIcon"];

class TicketMappingManager extends Component {
	constructor(props) {
		super(props);
		props.elements.forEach((element) => {
			element.setOnChange(this.forceRerender);
			nestedElements.forEach((subEle) => {
				if (element[subEle]) {
					element[subEle].setOnChange(this.forceRerender);
				}
			});
		});
	}

	forceRerender = () => {
		this.forceUpdate();
	};

	componentWillReceiveProps(nextProps) {
		this.props.elements.forEach((element) => {
			element.setOnChange(false);
			nestedElements.forEach((subEle) => {
				if (element[subEle]) {
					element[subEle].setOnChange(false);
				}
			});
		});
		nextProps.elements.forEach((element) => {
			element.setOnChange(this.forceRerender);
			nestedElements.forEach((subEle) => {
				if (element[subEle]) {
					element[subEle].setOnChange(this.forceRerender);
				}
			});
		});
	}

	render() {
		const { elements, addUndoAction, openManageCapacityModal, updateManageCapacityFormOnPerformAction } = this.props;
		const action = performAction(addUndoAction, this.forceRerender, updateManageCapacityFormOnPerformAction);
		let content = <label>Please select a seating element(s) to assign tickets.</label>;
		if (elements && elements.length) {
			content = (
				<div>
					{elements[0].basicType === "table" ? <BookByTable performAction={action} elements={elements} /> : null}
					<TicketMapping performAction={action} elements={elements} />
				</div>
			);
		}
		return (
			<div
				style={{
					backgroundColor: "white",
					padding: "16px 12px",
					width: "100%",
					minHeight: 368
				}}
			>
				<div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
					<h3>Mapping</h3>
					<Button onClick={() => openManageCapacityModal()} ariaLabel="Edit">
						Manage capacity
					</Button>
				</div>
				{content}
			</div>
		);
	}
}
export default connect(() => ({}), {
	updateManageCapacityFormOnPerformAction
})(TicketMappingManager);

const performAction =
	(addUndoAction, onChange, updateManageCapacityFormOnPerformAction) => (actionFunctionCalls, actionParams) => {
		// call change actions on seating map and return a single undo function
		const { type, functionCalls, params } = actionFunctionCalls
			.map((funcCall, i) => {
				//expecting any action to return the undo/redo structure
				return funcCall(...actionParams[i]);
			})
			// reduce to undo action to one single func call
			.reduce(
				(undoObj, functionCallValues) => {
					if (!functionCallValues) {
						return undoObj;
					}
					if (!undoObj.type) {
						undoObj.type = functionCallValues.type;
					}
					undoObj.functionCalls.push(functionCallValues.functionCall);
					undoObj.params.push(functionCallValues.params);
					return undoObj;
				},
				{ type: false, functionCalls: [], params: [] }
			);
		// add undo to stack
		if (functionCalls.length > 0) {
			addUndoAction(type, functionCalls, params);
		}
		updateManageCapacityFormOnPerformAction(params);
		// trigger state change
		onChange();
	};
