import { Views } from "@hx/seating-map";
import { Component } from "react";
import InputLabel from "../../../components/InputLabel";
import StepInput from "../../../components/StepInput";
const { SeatingConstruction } = Views;

export default class SeatNumber extends Component {
	seatNumChanged = (value) => {
		const tableMap = SeatingConstruction.getInstance();
		const { element, performAction } = this.props;
		const seatNum = this.props.element.seats.length;
		if (value) {
			let amount = value - seatNum;
			if (amount > 0) {
				performAction([tableMap.addSeats], [[element.sectionId, element.tableId, amount]]);
			} else if (seatNum > 0) {
				amount = seatNum - value;
				performAction([tableMap.removeSeats], [[element.sectionId, element.tableId, amount]]);
			}
		}
	};

	render() {
		const seatNum = this.props.element.seats.length;
		const label = this.props.labelOverride || "Number of seats";
		return (
			<div style={{ overflow: "auto", paddingTop: 20 }}>
				<InputLabel>{label}</InputLabel>
				<StepInput value={seatNum} onChange={this.seatNumChanged} style={{ float: "right", width: 60 }} />
			</div>
		);
	}
}
