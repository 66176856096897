import { getConfig } from "@/config";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { SalesforceConnectImage } from "../../assets/integrations/connect-to-salesforce";
import { ContentBlock } from "../../components/AppBlocks";
import { CheckboxField } from "../../components/Fields";
import PageHeading from "../../components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "../../components/text/Text";
import SalesforceService from "../../services/SalesforceService";

const style = {
	row: css({
		display: "flex",
		"@media (max-width: 1200px)": {
			flexDirection: "column-reverse"
		}
	}),
	colStyle: css({
		display: "flex",
		flexWrap: "wrap",
		flexGrow: 1,
		justifyContent: "center",
		alignContent: "center"
	}),
	imgStyle: css({
		width: 360,
		height: 120,
		"@media (max-width: 575px)": {
			marginTop: 20
		},
		"@media (max-width: 420px)": {
			width: 240,
			height: 80
		}
	})
};

class SalesforceSetUp extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}

	save(values) {
		const { userId } = this.props;
		SalesforceService.authoriseApp(userId, values.sandbox);
	}

	render() {
		const { handleSubmit, isSandbox } = this.props;
		const url = isSandbox ? "https://test.salesforce.com" : "https://login.salesforce.com";
		const managedPackagePath = getConfig("SALESFORCE_MANAGED_PACKAGE");
		const managedPackageLink = `${url}${managedPackagePath}`;

		return (
			<>
				<PageHeading title="Salesforce" />
				<ContentBlock>
					<form onSubmit={handleSubmit(this.save)}>
						<div className={style.row}>
							<div>
								<P marginBottom={16}>
									Connect Humanitix to your Salesforce account to sync data.
									<br />
									Please make sure you have the correct access level.
								</P>
								<Field
									id="sandbox"
									name="sandbox"
									type="checkbox"
									label="Connect to a sandbox account"
									labelAfter
									component={CheckboxField}
									inline
								/>
								<P marginBottom={16}>
									You will need to install our{" "}
									<a
										href={managedPackageLink}
										target="_blank"
										aria-label="Salesforce Managed Package"
										style={{ wordBreak: "break-word" }}
										rel="noopener noreferrer"
									>
										Salesforce Managed Package
										<br />
										{managedPackageLink}
									</a>
								</P>
								<div>
									<LegacyButton type="action" htmlType="submit" ariaLabel="Connect Salesforce">
										Connect Salesforce
									</LegacyButton>
								</div>
							</div>
							<div className={style.colStyle}>
								<SalesforceConnectImage className={style.imgStyle} />
							</div>
						</div>
					</form>
				</ContentBlock>
			</>
		);
	}
}

const formName = "salesforceSetUp";
const selector = formValueSelector(formName);
// Decorate with redux-form
SalesforceSetUp = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true
})(SalesforceSetUp);

export default connect(
	(state) => ({
		isSandbox: selector(state, "sandbox")
	}),
	{}
)(SalesforceSetUp);
