import logo from "@/assets/logo_afterpay.svg";
import { SwitchField } from "@/components/Fields";
import { Button } from "@/ui/Button";
import { getConfig } from "@/config";
import { PaymentCard } from "@/events/event/payments/components/gateways/GatewayComponents";
import { ReduxState } from "@/state/store";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { Alert, Modal } from "@/ui/antd";
import { FC, useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import {
	Footer,
	GatewayEnabledWrapper,
	ImageWrapper
} from "@/events/event/payments/components/gateways/GatewayComponents";

type ReduxInjectedProps = {
	orgPaymentOptions: any;
};

type AfterpayFormProps = {
	afterpayEnabled: boolean;
	allowed: boolean;
	location: string;
	paymentOptionsStr: string;
	save: (data: any) => void;
};

const AfterpayForm: FC<AfterpayFormProps & InjectedFormProps & ReduxInjectedProps> = ({
	afterpayEnabled,
	allowed,
	handleSubmit,
	location,
	orgPaymentOptions,
	paymentOptionsStr,
	save
}) => {
	const [modalOpen, toggleModal] = useState(false);
	if (!allowed) {
		return null;
	}
	let AFTERPAY_FEE;
	try {
		AFTERPAY_FEE = getConfig("AFTERPAY_FEE", location);
	} catch (err) {
		// when switching to a location that doesn't support this gateway, hide the form until the new gatewayOptions are loaded
		return null;
	}
	AFTERPAY_FEE = getConfig("AFTERPAY_FEE", location);
	const AFTERPAY_MAX_ORDER_VALUE = getConfig("AFTERPAY_MAX_ORDER_VALUE", location);

	const saveForm = (values: any) => {
		toggleModal(false);
		const updateData: any = {};
		updateData[paymentOptionsStr] = {
			...orgPaymentOptions,
			afterpayEnabled: values.afterpayEnabled
		};
		save(updateData);
	};

	const afterpayWarning = (
		<Alert
			showIcon
			message={
				<span>
					You will be charged an <b>Afterpay Fee of {AFTERPAY_FEE}%</b> for all sales processed through Afterpay. Sales
					will only be paid to you 5 business days post event.
				</span>
			}
			style={{ margin: "16px 0" }}
			type="info"
		/>
	);

	return (
		<PaymentCard>
			<ImageWrapper>
				<img alt="Afterpay" src={logo} />
			</ImageWrapper>

			<div>
				<p>Allow your clients to buy now, pay later with Afterpay for orders up to ${AFTERPAY_MAX_ORDER_VALUE}.</p>
				{afterpayWarning}
			</div>
			<Footer>
				{afterpayEnabled ? (
					<GatewayEnabledWrapper>
						<div>Afterpay enabled</div>
					</GatewayEnabledWrapper>
				) : null}
				<Button
					aria-label="Settings"
					onClick={() => toggleModal(true)}
					style={{ width: "100%", margin: "auto", maxWidth: 240 }}
					variant="tertiary"
				>
					{afterpayEnabled ? "Settings" : "Set up"}
				</Button>
			</Footer>
			<Modal
				title="Afterpay Settings"
				open={modalOpen}
				onCancel={() => toggleModal(false)}
				footer={
					<Button
						aria-label="Save"
						form="afterpayForm"
						htmlType="submit"
						onClick={handleSubmit(saveForm)}
						style={{ float: "right" }}
						variant="primary"
					>
						Save
					</Button>
				}
			>
				<form>
					<Field name="afterpayEnabled" label="Enable Afterpay" component={SwitchField} />
					{afterpayWarning}
				</form>
			</Modal>
		</PaymentCard>
	);
};

const ReduxAfterpayForm = reduxForm({
	form: "afterpayForm",
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => scrollToErrors()
})(AfterpayForm as any);

const mapStateToProps = (_: ReduxState, ownProps: ReduxInjectedProps) => ({
	initialValues: {
		afterpayEnabled: ownProps.orgPaymentOptions ? ownProps.orgPaymentOptions.afterpayEnabled : null
	},
	afterpayEnabled: ownProps.orgPaymentOptions ? ownProps.orgPaymentOptions.afterpayEnabled : false
});

export default connect(mapStateToProps)(ReduxAfterpayForm);
