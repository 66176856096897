import { Modal, Select } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { P } from "@/components/text/Text";
import {
	getAdditionalQuestionsTemplates,
	getStylingTemplates,
	getTicketTypeTemplates
} from "@/state/actions/templateActions";

class TemplateModal extends Component {
	constructor(props) {
		super(props);
		this.state = { chosen: false, templateData: false };
		this.determineTemplateArray = this.determineTemplateArray.bind(this);
		this.determineDescriptionText = this.determineDescriptionText.bind(this);
		this.determineExplanationText = this.determineExplanationText.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentWillMount() {
		const { currentUser, getTicketTypeTemplates, templateType, getAdditionalQuestionsTemplates, getStylingTemplates } =
			this.props;
		if (templateType === "ticketTypes") {
			getTicketTypeTemplates(currentUser.user.id);
		}
		if (templateType === "additionalQuestions") {
			getAdditionalQuestionsTemplates(currentUser.user.id);
		}
		if (templateType === "styling") {
			getStylingTemplates(currentUser.user.id);
		}
	}

	determineDescriptionText() {
		const { templateType } = this.props;
		if (templateType === "ticketTypes" || templateType === "additionalQuestions" || templateType === "styling") {
			return "Select a template name you would like to import";
		}
	}

	determineExplanationText() {
		const { templateType } = this.props;
		if (templateType === "ticketTypes") {
			return "ticket types";
		}
		if (templateType === "additionalQuestions") {
			return "checkout questions";
		}
		if (templateType === "styling") {
			return "styling";
		}
	}

	determineTemplateArray() {
		const { templates } = this.props;
		return (
			templates.templates.map((tt) => ({
				value: tt._id,
				label: tt.name
			})) || []
		);
	}

	onChange(templateId) {
		const { templateType, templates } = this.props;
		const template = templates.templates.filter((tt) => tt._id === templateId)[0];
		if (templateType === "ticketTypes") {
			this.setState({ chosen: true, templateData: template.ticketTypes });
		} else if (templateType === "additionalQuestions") {
			this.setState({
				chosen: true,
				templateData: template.additionalQuestions
			});
		} else if (templateType === "styling") {
			this.setState({
				chosen: true,
				templateData: template.eventTheme
			});
		}
	}

	handleSubmit() {
		const { onTemplateChosen } = this.props;
		const { templateData } = this.state;
		onTemplateChosen(templateData);
	}

	render() {
		const { error, loading, visible, handleCancel } = this.props;
		const { chosen } = this.state;
		let templateArray = [];
		if (!error && !loading && visible) {
			templateArray = this.determineTemplateArray();
		}
		if (!visible) {
			return null;
		}
		return (
			<Modal
				open={visible}
				title="Import template"
				destroyOnClose
				onCancel={handleCancel}
				footer={
					<ButtonsBar footer>
						<LegacyButton key="cancel" onClick={handleCancel} htmlType="button" ariaLabel="Cancel">
							Cancel
						</LegacyButton>
						<LegacyButton
							onClick={() => this.handleSubmit()}
							disabled={!chosen}
							key="submit"
							type="primary"
							htmlType="submit"
							ariaLabel="Continue"
						>
							Continue
						</LegacyButton>
					</ButtonsBar>
				}
			>
				<LoadErrorView loading={loading} error={error}>
					{templateArray.length > 0 ? (
						<div>
							<P style={{ marginBottom: 24 }}>{this.determineDescriptionText()}</P>
							<Select
								popupMatchSelectWidth={false}
								placeholder=""
								style={{ width: "100%" }}
								onChange={this.onChange}
								options={templateArray.map((option) => {
									return { value: option.value, label: option.label };
								})}
							/>
						</div>
					) : (
						<div>
							<P
								style={{ marginBottom: 24 }}
							>{`You currently have no templates for ${this.determineExplanationText()}`}</P>
						</div>
					)}
				</LoadErrorView>
			</Modal>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		templates: state.templates,
		currentUser: state.auth
	}),
	{
		getTicketTypeTemplates,
		getAdditionalQuestionsTemplates,
		getStylingTemplates
	}
)(TemplateModal);
