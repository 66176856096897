import axios from "./AxiosWrapper";

const create = async (facebookEvent, eventId, pages) => {
	const page = pages.find((page) => page.id === facebookEvent.pageId);
	facebookEvent.pageName = page.name;
	const response = await axios.post(`facebook/event`, {
		facebookEvent,
		eventId,
		pageAccessToken: page.access_token
	});
	return response.data;
};

const load = async (facebookEvent, pages) => {
	const page = pages.find((page) => page.id === facebookEvent.pageId);
	facebookEvent.pageName = page.name;
	const response = await axios.get(`facebook/event/${facebookEvent.facebookId}`, {
		params: {
			pageAccessToken: page.access_token
		}
	});
	return response.data;
};

const update = async (facebookEvent, eventId, pages) => {
	const page = pages.find((page) => page.id === facebookEvent.pageId);
	facebookEvent.pageName = page.name;
	const response = await axios.post(`facebook/event/${facebookEvent.facebookId}`, {
		facebookEvent,
		eventId,
		pageAccessToken: page.access_token
	});
	return response.data;
};

const remove = async (facebookEvent, eventId, pages) => {
	const page = pages.find((page) => page.id === facebookEvent.pageId);
	const response = await axios.delete(`facebook/event/${facebookEvent.facebookId}`, {
		data: { pageAccessToken: page.access_token, eventId }
	});
	return response.data;
};

const getLoginStatus = () => {
	return new Promise((resolve, reject) => {
		window.FB.getLoginStatus(function (response) {
			if (response.status === "connected") {
				// The user is logged in and has authenticated your
				// app, and response.authResponse supplies
				// the user's ID, a valid access token, a signed
				// request, and the time the access token
				// and signed request each expire.
				// var uid = response.authResponse.userID;
				// var accessToken = response.authResponse.accessToken;

				resolve();
				return;
			}
			reject();
		});
	});
};

const login = () => {
	return new Promise((resolve, reject) => {
		window.FB.login(
			function (response) {
				if (response.authResponse) {
					resolve(response.authResponse);
				} else {
					reject("Login cancelled or not fully authorized.");
				}
			},
			{ scope: "pages_show_list,pages_read_engagement,business_management" }
		);
	});
};

const connect = async (userId) => {
	const auth = await login();
	const response = await axios.get(`facebook/connect`, {
		params: {
			userId,
			userToken: auth.accessToken,
			facebookUserId: auth.userID
		}
	});
	return response.data;
};

const disconnect = async (userId) => {
	const response = await axios.get(`facebook/disconnect`, {
		params: {
			userId
		}
	});
	return response.data;
};

const getAccounts = async (userId) => {
	const response = await axios.get(`facebook/account/${userId}`);
	return response.data;
};

export { connect, create, disconnect, getAccounts, getLoginStatus, load, remove, update };
