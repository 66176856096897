import { Switch } from "@/ui/antd";
import InputLabel from "../../../components/InputLabel";

export default ({ label, element, performAction }) => {
	let locked = true;
	element.forEach((element) => (locked = locked && element.locked));
	return (
		<div style={{ overflow: "auto", paddingTop: 20 }}>
			<InputLabel style={{ float: "left" }}>{label}</InputLabel>
			<Switch
				checked={locked}
				onChange={(val) => {
					performAction(
						element.map((element) => element.setLocked),
						element.map(() => [val])
					);
				}}
				style={{ float: "right" }}
			/>
		</div>
	);
};
//this.props.element.forEach(element => element.setLocked(value))
