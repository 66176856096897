import H2 from "@/components/text/H2";
import React from "react";
import { Pie, PieChart, ResponsiveContainer, Tooltip, Cell, Legend } from "recharts";

interface PieLabel {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	percent: number;
}
const COLORS = [
	"#ffa179",
	"#ec769d",
	"#793c52",
	"#976db7",
	"#bf1a88",
	"#21c0b5",
	"#196097",
	"#e1c641",
	"#4f1b1a",
	"#06a8a5"
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: PieLabel) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const Chart = ({ title, dataKey, data }: any) => {
	if (!data || !data.length) {
		return (
			<div style={{ height: "400px" }}>
				<H2>{title}</H2>
				<p style={{ textAlign: "center" }}>No results found</p>
			</div>
		);
	}
	return (
		<>
			<H2>{title}</H2>
			<ResponsiveContainer width="100%" height={400}>
				<PieChart data={data} width={320} height={400}>
					<Pie
						dataKey={dataKey}
						data={data}
						cx="50%"
						cy="50%"
						fill="#FFB18F"
						outerRadius={100}
						label={renderCustomizedLabel}
						labelLine={false}
						nameKey="label"
						legendType="circle"
					>
						{data.map((_, index: number) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
					</Pie>
					<Legend />
					<Tooltip formatter={(value) => `${value}%`} />
				</PieChart>
			</ResponsiveContainer>
		</>
	);
};

export default Chart;
