import * as React from "react";
import { iconMap, IconNames } from "./icons";
import RowModifier from "./tableModifiers/RowModifier";
import { TabFactory, TabTypes } from "./tabs";
import Store, { InteractionManagerMethods } from "./utils/Store";
import getWindow from "./utils/Window";

enum ToolbarTypes {
	TABLE = "table",
	BACKGROUND = "background",
	OBJECT = "object",
	TEXT = "text",
	LINE = "line",
	ICON = "icon"
}

interface Props {
	type?: ToolbarTypes;
	visible: boolean;
}

interface State {
	x: number;
	y: number;
	type: ToolbarTypes;
	visible: boolean;
	forceHide: boolean;
	isRow: boolean;
}

interface ToolBarConfig {
	name: string;
	icon: Icon;
	tabType: TabTypes;
	methodName: InteractionManagerMethods;
	endMethodName?: InteractionManagerMethods;
}

const generalObjectConfig = [
	{
		name: "Duplicate",
		icon: iconMap[IconNames.duplicate],
		tabType: TabTypes.BASIC_TAB,
		methodName: InteractionManagerMethods.DUPLICATE
	},
	{
		name: "Delete",
		icon: iconMap[IconNames.bin],
		tabType: TabTypes.BASIC_TAB,
		methodName: InteractionManagerMethods.DELETE
	},
	{
		name: "Rotate",
		icon: iconMap[IconNames.rotate],
		tabType: TabTypes.TOGGLE_TAB,
		methodName: InteractionManagerMethods.ROTATE,
		endMethodName: InteractionManagerMethods.STOP_ROTATE
	}
];

const toolBarTypeMap: { [key: string]: ToolBarConfig[] } = {
	[ToolbarTypes.TABLE]: generalObjectConfig,
	[ToolbarTypes.TEXT]: generalObjectConfig,
	[ToolbarTypes.ICON]: generalObjectConfig,
	[ToolbarTypes.LINE]: generalObjectConfig,
	[ToolbarTypes.OBJECT]: [
		{
			name: "Duplicate",
			icon: iconMap[IconNames.duplicate],
			tabType: TabTypes.BASIC_TAB,
			methodName: InteractionManagerMethods.DUPLICATE
		},
		{
			name: "Delete",
			icon: iconMap[IconNames.bin],
			tabType: TabTypes.BASIC_TAB,
			methodName: InteractionManagerMethods.DELETE
		},
		{
			name: "Rotate",
			icon: iconMap[IconNames.rotate],
			tabType: TabTypes.TOGGLE_TAB,
			methodName: InteractionManagerMethods.ROTATE,
			endMethodName: InteractionManagerMethods.STOP_ROTATE
		}
	],
	[ToolbarTypes.BACKGROUND]: [
		{
			name: "Duplicate",
			icon: iconMap[IconNames.duplicate],
			tabType: TabTypes.BASIC_TAB,
			methodName: InteractionManagerMethods.DUPLICATE
		},
		{
			name: "Delete",
			icon: iconMap[IconNames.bin],
			tabType: TabTypes.BASIC_TAB,
			methodName: InteractionManagerMethods.DELETE
		},
		{
			name: "Rotate",
			icon: iconMap[IconNames.rotate],
			tabType: TabTypes.TOGGLE_TAB,
			methodName: InteractionManagerMethods.ROTATE,
			endMethodName: InteractionManagerMethods.STOP_ROTATE
		},
		{
			name: "Opacity",
			icon: iconMap[IconNames.opacity],
			tabType: TabTypes.SLIDER_TAB,
			methodName: InteractionManagerMethods.DUPLICATE
		}
	]
};

export default class ToolBar extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			x: 0,
			y: 0,
			type: props.type || ToolbarTypes.TABLE,
			visible: props.visible || false,
			forceHide: false,
			isRow: false
		};
		this.registerListeners();
	}

	registerListeners() {
		getWindow().addEventListener("moveTest", (data: any) => {
			const { type } = this.state;
			const toolBarType = toolBarTypeMap[type];
			const { x, y, isRow } = data.detail;
			const xOffset = isRow ? 0 : (toolBarType.length * 40) / 2;
			this.setState({
				x: x - xOffset,
				y: y - 55,
				isRow
			});
		});
		getWindow().addEventListener("objectSelected", (data: any) => {
			const { x, y, type, isRow } = data.detail;
			const toolBarType = toolBarTypeMap[type];
			const xOffset = isRow ? 0 : (toolBarType.length * 40) / 2;
			this.setState({
				x: x - xOffset,
				y: y - 55,
				type,
				visible: true,
				isRow
			});
		});
		getWindow().addEventListener("objectDeselected", () => {
			this.setState({
				visible: false
			});
		});
		getWindow().addEventListener("interactionManagerInitialised", (data: any) => {
			const { interactionManager } = data.detail;
			Store.setInteractionManager(interactionManager);
		});
		getWindow().addEventListener("hideToolbar", (data: any) => {
			const { hide } = data.detail;
			this.setState({ forceHide: hide });
		});
	}

	render() {
		const { type, x, y, visible, forceHide, isRow } = this.state;
		const toolBarType = toolBarTypeMap[type];
		if (!visible || forceHide) {
			return null;
		}
		return (
			<div style={{ height: 40, borderRadius: 4, position: "absolute", top: y, left: x }}>
				<div style={{ display: visible && !forceHide ? "flex" : "none" }}>
					{toolBarType.map((config, i) => {
						return TabFactory.constructTab(config.tabType, {
							...config,
							first: i === 0,
							last: i === toolBarType.length - 1,
							key: `tabFactory-${i}`
						});
					})}
					{isRow ? (
						<div style={{ marginLeft: 12, marginTop: -12 }}>
							<RowModifier />
						</div>
					) : null}
				</div>
			</div>
		);
	}
}
