import CheckSvg from "@/assets/check.svg";
import CrossSvg from "@/assets/cross.svg";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import LocationService from "@/services/LocationService";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { currency } from "@/utils/Format";
import type {
	FailedAddOnsReport,
	FailedTicketsReport,
	GenericFailedItemsReport,
	UnknownFailedOrdersReport
} from "@hx/console/massRefunds";
import { HumaniDate } from "@hx/dates";
import { Table, Tabs, notification } from "@/ui/antd";
import { ColumnProps } from "antd/lib/table";
import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

type TableRecordType = UnknownFailedOrdersReport | FailedTicketsReport | FailedAddOnsReport | GenericFailedItemsReport;

export const MassRefundFailureReport: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const navigate = useNavigate();
	const params = useParams();

	const { event } = currentEvent;
	if (!event) return null;

	const massRefundId = params?.massRefundId ?? "";
	const { _id: eventId, dates = [] } = event;
	const clientDonationTicketTypeName = event?.ticketTypes?.find((tt) => tt.isDonation)?.name;

	const { data, isLoading } = trpc.massRefunds.getMassRefundFailureReport.useQuery(massRefundId, {
		enabled: !!massRefundId,
		onError: (error) => {
			notification.error({
				description: error.message ?? "An error occurred while mass refund report",
				message: "Error"
			});
		}
	});

	if (isLoading || !data) {
		return (
			<div style={{ minHeight: "350px" }}>
				<LoadErrorView loading />
			</div>
		);
	}

	const { report } = data;

	const {
		massRefundName,
		createdAt,
		reason,
		user,
		eventDateIdsAffected = [],
		unknownFailedOrders = [],
		failedTickets = [],
		failedAddons = [],
		failedDonations = []
	} = report ?? {};

	const prettyEventDates =
		eventDateIdsAffected.map((affectedDateId) => dates.find((date) => date._id === affectedDateId)) || [];

	const columns: (itemType: "orders" | "tickets" | "addons" | "donations") => ColumnProps<TableRecordType>[] = (
		itemType: "orders" | "tickets" | "addons" | "donations"
	) => [
		{
			dataIndex: "orderName",
			fixed: "left",
			render: (_, record) => (
				<Link to={`/console/my-events/${eventId}/orders/${record.orderId}`}>{record.orderName}</Link>
			),
			title: "Order ID"
		},
		{
			dataIndex: "itemRefundStatus",
			render: () => (
				<div>
					<img src={CrossSvg} />
					<span style={{ paddingLeft: 5 }}>Refund failed</span>
				</div>
			),
			title: "Item Refund Status"
		},
		...(itemType !== "orders"
			? [
					{
						dataIndex: "itemTypeName",
						render: (itemTypeName: string) => (itemType === "donations" ? clientDonationTicketTypeName : itemTypeName),
						title: "Item Name"
					},
					{
						dataIndex: "total",
						render: (total: number) => <>{currency(total)}</>,
						title: "Total"
					},
					{
						dataIndex: "status",
						render: (status: string) => (
							<>
								<img src={status === "complete" ? CheckSvg : CrossSvg} />
								<span style={{ paddingLeft: 5 }}>
									{status === "complete" ? "Complete" : status === "cancelled" ? "Cancelled" : status}
								</span>
							</>
						),
						title: "Item Status"
					}
			  ]
			: []),
		...(itemType === "tickets"
			? [
					{
						dataIndex: "checkIn",
						render: (checkIn: string) => {
							return (
								<>
									<img src={checkIn ? CheckSvg : CrossSvg} />
									<span style={{ paddingLeft: 5 }}>{checkIn ? "Checked-In" : "Not Checked-In"}</span>
								</>
							);
						},
						title: "Check-in Status"
					}
			  ]
			: []),
		{
			dataIndex: "eventDateId",
			title: "Event Date/Time",
			render: (eventDateId: string) => {
				const prettyDate = event?.dates?.find((date) => date._id === eventDateId);
				return <div>{prettyDate?.display?.combined}</div>;
			}
		},
		{
			render: (_, record) => (
				<>
					{record.firstName} {record.lastName}
				</>
			),
			title: "Buyer name"
		}
	];

	const totalItemsFailed = [...unknownFailedOrders, ...failedTickets, ...failedAddons, ...failedDonations].length;

	const tabItems = [];

	unknownFailedOrders?.length &&
		tabItems.push({
			children: (
				<>
					<div>{unknownFailedOrders.length} orders failed to refund</div>
					<div style={{ marginTop: 20, minWidth: "100%", overflowX: "auto" }}>
						<Table
							columns={columns("orders")}
							dataSource={unknownFailedOrders}
							rowKey="_id"
							scroll={{ x: "max-content" }}
						/>
					</div>
				</>
			),
			key: "orders",
			label: "Orders"
		});

	failedTickets?.length &&
		tabItems.push({
			children: (
				<>
					<div>{failedTickets.length} tickets failed to refund</div>
					<div style={{ marginTop: 20, minWidth: "100%", overflowX: "auto" }}>
						<Table columns={columns("tickets")} dataSource={failedTickets} rowKey="_id" scroll={{ x: "max-content" }} />
					</div>
				</>
			),
			key: "tickets",
			label: "Tickets"
		});

	failedAddons?.length &&
		tabItems.push({
			children: (
				<>
					<div>{failedAddons.length} addons failed to refund</div>
					<div style={{ marginTop: 20, minWidth: "100%", overflowX: "auto" }}>
						<Table columns={columns("addons")} dataSource={failedAddons} rowKey="_id" scroll={{ x: "max-content" }} />
					</div>
				</>
			),
			key: "addons",
			label: "Add-ons"
		});

	failedDonations?.length &&
		tabItems.push({
			children: (
				<>
					<div>{failedDonations.length} donations failed to refund</div>
					<div style={{ marginTop: 20, minWidth: "100%", overflowX: "auto" }}>
						<Table
							columns={columns("donations")}
							dataSource={failedDonations}
							rowKey="_id"
							scroll={{ x: "max-content" }}
						/>
					</div>
				</>
			),
			key: "donations",
			label: "Donations"
		});

	return (
		<>
			<H1>Mass Refund: {massRefundName}</H1>
			<ContentBlock>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						<div style={{ paddingBottom: 10 }}>
							<b>Refund date: </b>
							{new HumaniDate(createdAt, "guess", LocationService.getLocation(true)).formatting.dateTime()}
						</div>
						<div style={{ paddingBottom: 10 }}>
							<b>Reason for refund: </b>
							{reason}
						</div>
						<div style={{ paddingBottom: 10 }}>
							<b>Actioned by: </b>
							{user?.firstName} {user?.lastName}
						</div>
					</div>
					<div>
						<div>
							<b>Event date(s) refunded:</b>
							{prettyEventDates.map((date) => (
								<div>{date?.display?.combined}</div>
							))}
						</div>
					</div>
				</div>
				<div>
					<div style={{ color: "red" }}>{totalItemsFailed} items failed to refund</div>
					<Tabs items={tabItems} />
					<LegacyButton
						ariaLabel="Go Back"
						onClick={() => navigate(`/console/my-events/${event._id}/mass-refunds/refund-history`)}
						type="action"
					>
						Go Back
					</LegacyButton>
				</div>
			</ContentBlock>
		</>
	);
};
