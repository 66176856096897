import { css } from "@emotion/css";

import impactText from "@/assets/ticketPreview/charityBox.png";
import note from "@/assets/ticketPreview/note.svg";
import ticketBlank from "@/assets/ticketPreview/ticket.svg";

const impact = css({
	height: "auto",
	backgroundColor: "#f9f9fa",
	marginBottom: 5,
	width: "100%",
	img: {
		width: "100%",
		height: "auto"
	}
});

const noteImg = css({
	width: "100%",
	marginBottom: 8
});

const ticket = css({
	position: "relative",
	backgroundColor: "white",
	borderRadius: 4
});

const ticketBlankCss = css({
	width: "100%",
	height: "auto"
});

const bannerImage = css({
	width: "100%",
	marginBottom: 5
});

const card = css({
	padding: 12,
	marginBottom: 12,
	borderRadius: 8,
	border: "2px solid #DEDEDE",
	"@media(max-width: 600px)": {
		width: "80%",
		float: "inherit",
		marginLeft: "auto",
		marginRight: "auto"
	}
});
const header = css({
	width: "100%",
	borderRadius: 4,
	marginBottom: 5,
	position: "relative",
	paddingTop: 5
});

const organiserLogo = css({
	width: "17%",
	height: "auto",
	top: 5,
	left: 5
});

function isFree(event) {
	let paidTickets = event.ticketTypes.filter((t) => !t.disabled && !t.isDonation && t.price > 0);
	return !paidTickets.length;
}

const TicketPreview = (props) => {
	const { currentEvent, theme, banner, ticketMessage, hideCharity, ticketLogo } = props;
	const { ticketBackgroundColor = "#FFA680" } = theme || {};

	const ticketWrapper = css({
		width: "100%",
		marginBottom: 5,
		padding: "6px 22px",
		backgroundColor: ticketBackgroundColor
	});

	const event = currentEvent.event;
	const user = currentEvent.eventUser;
	const userFees = user.fees && user.fees[event.location] ? user.fees[event.location] : false;
	const fees = event.fees ? event.fees : userFees;
	const isEventFree = isFree(event);
	const showCharity = !(
		isEventFree ||
		(fees && fees.donation.amount === 0) ||
		(fees && fees.type === "charity") ||
		hideCharity
	);

	return (
		<div>
			<div className={card}>
				<div className={header}>
					{ticketLogo && ticketLogo.url ? (
						<img src={ticketLogo.url} alt="ticketLogo" className={organiserLogo} />
					) : null}
				</div>
				<div className={ticketWrapper}>
					<div className={ticket}>
						<img src={ticketBlank} alt="ticket blank" className={ticketBlankCss} />
					</div>
				</div>
				{ticketMessage && ticketMessage.length ? <img src={note} className={noteImg} alt="note" /> : ""}
				{showCharity ? (
					<div className={impact}>
						<img src={impactText} alt="impactText" />
					</div>
				) : null}
				{banner && banner.url ? <img src={banner.url} alt="banner" className={bannerImage} /> : null}
			</div>
		</div>
	);
};

export default TicketPreview;
