const Search: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path
					fill={color}
					d="M15.867 14.32h-.818l-.284-.282A6.657 6.657 0 0 0 16.38 9.69a6.69 6.69 0 1 0-6.69 6.69 6.66 6.66 0 0 0 4.349-1.612l.284.282v.815L19.466 21 21 19.466l-5.133-5.146zm-6.177 0a4.63 4.63 0 1 1 0-9.262 4.631 4.631 0 0 1 0 9.262z"
				/>
				<path d="M0 0h24v24H0z" />
			</g>
		</svg>
	);
};

export default Search;
