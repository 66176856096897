const MultiSelect: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path d="M0 0L24 0 24 24 0 24z" />
				<path fill={color} d="M21 3L3 10.53 3 11.51 9.84 14.16 12.48 21 13.46 21z" transform="matrix(-1 0 0 1 24 0)" />
			</g>
		</svg>
	);
};

export default MultiSelect;
