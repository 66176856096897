import { getConfig } from "@/config";
import { css } from "@emotion/css";
import { Component } from "react";
import ReactGoogleMapLoader from "react-google-maps-loader";
import { Field } from "redux-form";
import { getCountry, getPostalCode, getState, getSuburb } from "../../utils/Address";
import { required as requiredValidate } from "../../utils/Validators";
import LegacyInputField from "./LegacyInputField";

const styles = {
	locationWrapper: css({
		display: "flex",
		"@media(max-width: 600px)": {
			display: "block"
		}
	}),
	leftColumn: css({
		flex: 1,
		"@media(max-width: 600px)": {
			marginRight: 0,
			display: "block"
		}
	}),
	list: css({
		position: "relative",
		display: "block",
		fontSize: 16,
		marginTop: 0,
		marginBottom: 0,
		cursor: "pointer"
	}),
	locationManualContainer: css({
		display: "flex",
		flexWrap: "wrap"
	}),
	streetAddress: css({
		width: "100%"
	}),
	locationManualFields: css({
		width: "49%"
	}),
	locationManualLeftFields: css({
		width: "49%",
		marginRight: 8
	})
};

const AddressField = (props) => {
	const { changeManualValue, showAddressList, style, predictions, clickAddress } = props;
	const customStyles = {
		options: css({
			zIndex: 10001,
			overflowX: "auto",
			backgroundColor: "#fff",
			position: "absolute",
			margin: "-15px 0 0",
			border: "0.05rem solid #e7e7e7",
			borderRadius: 4,
			width: "100%",
			maxWidth: 522,
			padding: 0,
			paddingLeft: 10,
			display: showAddressList ? "block" : "none",
			color: "#323232",
			boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)"
		})
	};
	props.input.onChange = changeManualValue;
	return (
		<div style={style}>
			<LegacyInputField {...props} />
			<ul className={customStyles.options}>
				{predictions.length
					? predictions.map((prediction, key) => (
							<li className={styles.list} onClick={() => clickAddress(prediction)} key={key}>
								{prediction.description}
							</li>
					  ))
					: null}
				<li className={styles.list} onClick={() => clickAddress("noAddress")} key="none">
					Can't find your address?
				</li>
			</ul>
		</div>
	);
};
class Location extends Component {
	constructor(props) {
		super(props);
		this.googleMaps = null;
		this.autocomplete = null;
		this.googleService = null;
		this.sessionToken = null;
		this.createAutoComplete = this.createAutoComplete.bind(this);
		this.state = {
			showAddressList: false,
			showAddressManualFields: false,
			predictions: [],
			noAddress: false
		};
	}

	componentDidMount() {
		this.createAutoComplete();
	}

	createAutoComplete() {
		const { googleMaps } = this.props;
		if (googleMaps) {
			this.googleMaps = googleMaps;
			// token is required to prevent double charges from Google for two API calls of AutocompleteService and PlacesService
			// we only get charged when we call PlacesService with token
			this.sessionToken = new googleMaps.places.AutocompleteSessionToken();
			this.autocomplete = new googleMaps.places.AutocompleteService();
			this.googleService = new googleMaps.places.PlacesService(document.createElement("span"));
		} else {
			setTimeout(this.createAutoComplete, 300);
		}
	}

	onPlaceChanged = (e) => {
		const { questionName } = this.props;
		document.getElementById(`${questionName}.location`).removeAttribute("value");
		const value = e.target.value ? e.target.value : false;

		if (value) {
			const displaySuggestions = (predictions, status) => {
				if (status !== this.googleMaps.places.PlacesServiceStatus.OK) {
					return;
				}
				this.setState({ predictions });
			};
			this.autocomplete.getQueryPredictions({ input: value, sessionToken: this.sessionToken }, displaySuggestions);
			this.setState({ showAddressList: true });
		}
	};

	clickAddress = async (value) => {
		const { changeFieldValue, questionName } = this.props;
		if (value === "noAddress") {
			document.getElementById(`${questionName}.location`).value = "";
			changeFieldValue(`${questionName}.location`, "");
			changeFieldValue(`${questionName}.details.country`, "");
			changeFieldValue(`${questionName}.details.state`, "");
			changeFieldValue(`${questionName}.details.postalCode`, "");
			changeFieldValue(`${questionName}.details.suburb`, "");
			changeFieldValue(`${questionName}.details.street`, "");
			this.setState({ noAddress: true });
		} else {
			const fullAddress = await this.getAddressDetails(value);
			const country = getCountry(fullAddress.address_components);
			const state = getState(fullAddress.address_components);
			const suburb = getSuburb(fullAddress.address_components);
			const postalCode = getPostalCode(fullAddress.address_components);
			const streetAddress = value.structured_formatting.main_text;
			document.getElementById(`${questionName}.location`).value = value.description;
			changeFieldValue(`${questionName}.location`, value.description);
			changeFieldValue(`${questionName}.details.country`, country);
			changeFieldValue(`${questionName}.details.state`, state);
			changeFieldValue(`${questionName}.details.postalCode`, postalCode);
			changeFieldValue(`${questionName}.details.suburb`, suburb);
			changeFieldValue(`${questionName}.details.street`, streetAddress);
		}
		this.setState({ showAddressList: false, showAddressManualFields: true });
	};

	getAddressDetails = async (value) => {
		let request = {
			placeId: value.place_id,
			sessionToken: this.sessionToken,
			fields: ["formatted_address", "address_component"]
		};
		return new Promise((resolve) => {
			this.googleService.getDetails(request, (place, status) => {
				if (status === this.googleMaps.places.PlacesServiceStatus.OK) {
					resolve(place);
				}
			});
		});
	};

	changeManualValue = (e) => {
		this.onPlaceChanged(e);
		const { changeFieldValue } = this.props;
		changeFieldValue(e.target.name, e.target.value);
	};
	addressValidation = requiredValidate("This field is required");
	render() {
		const {
			questionName,
			label,
			required,
			forceShowManualFields,
			meta: { touched, error }
		} = this.props;
		const { showAddressList, showAddressManualFields, predictions, noAddress } = this.state;

		return (
			<div className={styles.locationWrapper}>
				<div className={styles.leftColumn}>
					<div className={touched && error ? "form-group has-error" : "form-group"}>
						{!noAddress ? (
							<Field
								label={label}
								component={AddressField}
								predictions={predictions}
								showAddressList={showAddressList}
								clickAddress={this.clickAddress}
								changeManualValue={this.changeManualValue}
								onPlaceChanged={this.onPlaceChanged}
								name={`${questionName}.location`}
								required={required}
								validate={required ? this.addressValidation : undefined}
							/>
						) : null}
						{showAddressManualFields || forceShowManualFields ? (
							<div className={styles.locationManualContainer}>
								<div className={styles.streetAddress}>
									<Field
										label="Street Address"
										component={LegacyInputField}
										name={`${questionName}.details.street`}
										autocomplete="nope"
										required={required}
										validate={required ? this.validation : undefined}
									/>
									{touched && error && <p className="form-input-hint">{error}</p>}
								</div>
								<div className={styles.locationManualLeftFields}>
									<Field
										label="Suburb"
										component={LegacyInputField}
										name={`${questionName}.details.suburb`}
										autocomplete="nope"
										required={required}
										validate={required ? this.validation : undefined}
									/>
									{touched && error && <p className="form-input-hint">{error}</p>}
								</div>
								<div className={styles.locationManualFields}>
									<Field
										label="State"
										component={LegacyInputField}
										name={`${questionName}.details.state`}
										autocomplete="nope"
										required={required}
										validate={required ? this.validation : undefined}
									/>
									{touched && error && <p className="form-input-hint">{error}</p>}
								</div>
								<div className={styles.locationManualLeftFields}>
									<Field
										label="Postcode"
										component={LegacyInputField}
										name={`${questionName}.details.postalCode`}
										autocomplete="nope"
										required={required}
										validate={required ? this.validation : undefined}
									/>
								</div>
								<div className={styles.locationManualFields}>
									<Field
										label="Country"
										component={LegacyInputField}
										name={`${questionName}.details.country`}
										autocomplete="nope"
										required={required}
										validate={required ? this.validation : undefined}
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Autocomplete input filed for address
 *
 * @param {*} props
 * @returns
 */
const LocationField = (props) => {
	return (
		<ReactGoogleMapLoader
			params={{
				key: getConfig("GOOGLE_API_KEY"), // Define your api key here
				libraries: "places"
			}}
			render={(googleMaps, error) =>
				googleMaps ? (
					<div>
						{/*Show a custom error if SDK Authentication Error. See N/B 2 below.*/}
						{error ? error : <Location {...props} googleMaps={googleMaps} />}
					</div>
				) : (
					<div>
						{/*Check for network error so loading state ends if user lost connection.*/}
						{error === "Network Error" ? <p>{error}</p> : <p>isLoading...</p>}
					</div>
				)
			}
		/>
	);
};

export default LocationField;
