import iconRefund from "@/assets//icon_refund.svg";
import { P } from "@/components/text/Text";
import LocationService from "@/services/LocationService";
import styled from "@emotion/styled";
import type { DisplayMassRefund } from "@hx/console/massRefunds";
import { HumaniDate } from "@hx/dates";
import { FC } from "react";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	text-align: center;
`;

const GreyText = styled(P)`
	color: grey;
`;

type ExistingMassRefundProps = {
	existingMassRefund: DisplayMassRefund;
};

export const ExistingMassRefund: FC<ExistingMassRefundProps> = ({ existingMassRefund }) => {
	const link = `/console/my-events/${existingMassRefund?.eventId}/mass-refunds/refund-history`;
	const displayName = existingMassRefund?.massRefundName;
	const displayDate = new HumaniDate(
		existingMassRefund?.createdAt,
		"guess",
		LocationService.getLocation(true)
	).formatting.dateTime();

	return (
		<Wrapper>
			<P>
				Mass refund <Link to={link}>{displayName}</Link> started at <b>{displayDate}</b>
			</P>
			<img src={iconRefund} alt="refund icon" />
			<P>
				<b>We’re still processing the mass refund for you.</b>
			</P>
			<GreyText>You won’t be able to start another mass refund until the current one has been completed.</GreyText>
			<GreyText>We’ll notify you via email when it’s done.</GreyText>
		</Wrapper>
	);
};
