import { LegacyButton } from "@/components/buttons/LegacyButton";
import LoadErrorView from "@/components/LoadErrorView";
import { H2, P } from "@/components/text/Text";
import Widget from "@/components/widgets/Widget";
import iconReference from "@/services/IconReference";
import { getSalesByTicketType } from "@/state/actions/metricActions";
import { DatePicker, Flex, Select, Table } from "@/ui/antd";
import { Button } from "@/ui";
import { Dropdown } from "@/ui";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { SlidersHorizontal } from "@phosphor-icons/react";
import { Component } from "react";
import { connect } from "react-redux";

const icDownArrow = iconReference["down_blue"];

const ticketCols = [
	{
		title: "Ticket type",
		dataIndex: "type",
		key: "type"
	},
	{
		title: "Earnings",
		dataIndex: "earnings",
		key: "earnings",
		render: (value) => currency(value)
	},
	{
		title: "Sold",
		dataIndex: "sold",
		key: "sold",
		render: (sold, record) => `${sold}/${record.available}`
	}
];

const packagedTicketCols = [
	{
		title: "Package Name",
		dataIndex: "packageName",
		key: "type",
		render: (value, record) => {
			return (
				<div>
					<P
						style={{
							marginBottom: 12
						}}
					>
						{value}
					</P>
					{record.ticketTypes.map((ticketType) => {
						return (
							<div>
								<P
									style={{
										marginBottom: 2,
										color: "#5a626c"
									}}
								>
									{ticketType.numberOfTickets} x {ticketType.ticketType}
								</P>
							</div>
						);
					})}
				</div>
			);
		}
	},
	{
		title: "Earnings",
		dataIndex: "earnings",
		key: "earnings",
		render: (value) => currency(value)
	},
	{
		title: "Sold Packages",
		dataIndex: "numOfPackagesSold",
		key: "sold"
	},
	{
		title: "Tickets Sold through Package",
		dataIndex: "numOfTicketsSold",
		key: "available"
	}
];

class TicketSalesTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openPackageBreakdown: false,
			startDate: undefined,
			endDate: undefined,
			eventDateId: "allDates"
		};
	}
	//this is the component youre working on

	togglePackagedTickets = () => {
		const { openPackageBreakdown } = this.state;
		this.setState({
			openPackageBreakdown: !openPackageBreakdown
		});
	};

	statusDateUpdate = (date, type) => {
		const { startDate, endDate, eventDateId } = this.state;
		this.setState({
			[type]: date
		});
		const dateRange = {
			startDate: startDate,
			endDate: endDate
		};
		dateRange[type] = date;
		const eventId = this.props.currentEvent.event._id;
		this.props.getSalesByTicketType(eventId, dateRange, eventDateId);
	};

	eventDateIdUpdate = (eventDateId) => {
		const { startDate, endDate } = this.state;
		this.setState({
			eventDateId
		});
		const dateRange = {
			startDate: startDate,
			endDate: endDate
		};
		const eventId = this.props.currentEvent.event._id;
		this.props.getSalesByTicketType(eventId, dateRange, eventDateId);
	};

	tryAgain = () => {
		const { startDate, endDate, eventDateId } = this.state;
		const dateRange = {
			startDate: startDate,
			endDate: endDate
		};
		const eventId = this.props.currentEvent.event._id;
		this.props.getSalesByTicketType(eventId, dateRange, eventDateId);
	};

	render() {
		const { metrics, currentEvent } = this.props;
		const { event } = currentEvent;
		const { dates = [] } = event;
		const { openPackageBreakdown } = this.state;
		const { loading, error, data } = metrics;
		const showPackagedTickets = !!(data.length > 0 && data[1].length > 0);

		const eventDateOptions = [{ label: "All event dates", value: "allDates" }].concat(
			dates.map((eventDate) => ({ label: eventDate.display.combined, value: eventDate._id }))
		);

		const titleBarWrapper = css({
			display: "flex",
			justifyContent: "space-between"
		});
		return (
			<Widget excludeTimeScale {...this.props}>
				<div className={titleBarWrapper}>
					<H2>Earnings by ticket type</H2>
					<Dropdown
						persist
						title="Refine by sales date"
						items={
							<Flex vertical gap="sm">
								<DatePicker
									id="startDate"
									name="startDate"
									onChange={(e) => this.statusDateUpdate(e, "startDate")}
									placeholder="Start Date"
									size="large"
								/>
								<DatePicker
									id="endDate"
									name="endDate"
									onChange={(e) => this.statusDateUpdate(e, "endDate")}
									placeholder="End Date"
									size="large"
								/>
								{dates.length > 1 && (
									<Select
										defaultValue={eventDateOptions[0].value}
										onChange={(value) => this.eventDateIdUpdate(value)}
										options={eventDateOptions}
										style={{
											height: "auto",
											wordWrap: "break-word"
										}}
									/>
								)}
							</Flex>
						}
					>
						<Button iconOnly variant="text">
							<SlidersHorizontal size={20} />
						</Button>
					</Dropdown>
				</div>
				<LoadErrorView loading={loading} error={error} retryAction={this.tryAgain}>
					<Table pagination={false} columns={ticketCols} dataSource={data[0]} rowKey="_id" />
				</LoadErrorView>
				{showPackagedTickets ? (
					<div style={{ paddingTop: 24 }}>
						<P>Some of the tickets above have been sold through packaged tickets.</P>
						<LegacyButton
							style={{
								marginBottom: openPackageBreakdown ? 24 : 0
							}}
							type="action"
							onClick={this.togglePackagedTickets}
							ariaLabel={`${openPackageBreakdown ? "Hide" : "View"} Packaged tickets breakdown`}
						>
							<div
								className={css({
									paddingRight: 24,
									position: "relative"
								})}
							>
								{`${openPackageBreakdown ? "Hide" : "View"} Packaged tickets breakdown`}
								<div
									className={css({
										position: "absolute",
										width: 24,
										height: 24,
										top: 0,
										right: -6,
										backgroundImage: `url(${icDownArrow})`,
										transform: `rotate(${openPackageBreakdown ? "180" : "0"}deg)`
									})}
								/>
							</div>
						</LegacyButton>
						{openPackageBreakdown ? (
							<Table
								pagination={false}
								columns={packagedTicketCols}
								dataSource={data[1]}
								scroll={{ x: 400 }}
								rowKey="_id"
							/>
						) : null}
					</div>
				) : null}
			</Widget>
		);
	}
}

export default connect(
	(state, ownProps) => ({
		currentEvent: state.currentEvent,
		metrics: state[ownProps.metricState].salesTable,
		timeScale: state[ownProps.metricState].timeScale
	}),
	{ getSalesByTicketType }
)(TicketSalesTable);
