import { Col, Row } from "@/ui/antd";
import { useCallback } from "react";
import { Field } from "redux-form";

import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";

import type { TicketType } from "@/state/reducers/eventReducer";

interface ITicketHiddenDateRangeProps {
	index: number;
	touch: (input: string) => void;
	timezone: string;
	formName: string;
}

const TicketHiddenDateRange = ({ index, touch, timezone, formName }: ITicketHiddenDateRangeProps) => {
	const _checkStartDate = useCallback(() => {
		touch(`ticketTypes[${index}].endSaleDate`);
		return undefined;
	}, [touch, index]);

	const _checkEndDate = useCallback((endSaleDate: Date, allValues: { ticketTypes: TicketType[] }) => {
		const ticketType = allValues.ticketTypes[index];
		if (!ticketType) {
			return undefined;
		}
		const startDate = ticketType.startSaleDate;
		if (!startDate || !endSaleDate) {
			return undefined;
		}
		const result = endSaleDate < startDate ? `Must be after your start date` : undefined;
		return result;
	}, []);

	return (
		<Row>
			<Col xs={24} md={12}>
				<Field
					component={ReduxDateTimePicker}
					displayFormat="Do MMM YYYY"
					formname={formName}
					label="Hide from"
					name={`ticketTypes[${index}].hiddenOptions.startDate`}
					placeholder="Start date"
					showTime
					timeOptions={{ placeholder: "Start time" }}
					timezone={timezone}
					validate={_checkStartDate}
				/>
			</Col>
			<Col xs={24} md={12}>
				<div
					style={{
						float: "left"
					}}
				>
					<Field
						component={ReduxDateTimePicker}
						displayFormat="Do MMM YYYY"
						formname={formName}
						label="Hide before"
						name={`ticketTypes[${index}].hiddenOptions.endDate`}
						placeholder="End date"
						showTime
						timeOptions={{ placeholder: "End time" }}
						timezone={timezone}
						validate={_checkEndDate}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default TicketHiddenDateRange;
