import { LegacyButton } from "@/components/buttons/LegacyButton";
import H2 from "@/components/text/H2";
import { Label } from "@/components/text/Text";
import { dayjs } from "@/libs/dayjs";
import LocationService from "@/services/LocationService";
import { Modal } from "@/ui/antd";
import { DateFormats, HumaniDate } from "@hx/dates";
import { useEffect, useState } from "react";

const EventDateModal = (props) => {
	const [modalState, setModalState] = useState({ open: false, eventDate: {} });

	useEffect(() => {
		const open = props.open;
		const eventDate = props.eventDate;
		setModalState(() => ({ open, eventDate }));
	}, [props.open, props.eventDate]);

	const eventDate = modalState.eventDate || {};

	const startDate = dayjs(eventDate.startDate).tz(props.timezone).format("LLL");
	const endDate = dayjs(eventDate.endDate).tz(props.timezone).format("LLL");

	return (
		<Modal
			open={modalState.open}
			destroyOnClose
			onCancel={props.onModalClose}
			footer={
				<>
					<LegacyButton
						style={{
							float: "left"
						}}
						key="cancel"
						onClick={props.onModalClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Cancel
					</LegacyButton>

					<LegacyButton
						style={{
							float: "right",
							marginLeft: "5px"
						}}
						key="edit"
						onClick={() => props.onDateEdit(eventDate)}
						htmlType="button"
						ariaLabel="edit"
						type="secondary"
					>
						Edit/Reschedule
					</LegacyButton>
					<LegacyButton
						style={{
							float: "right"
						}}
						key="delete"
						onClick={() => props.onDeleteClick(eventDate)}
						htmlType="button"
						ariaLabel="edit"
						type="warning"
					>
						Delete
					</LegacyButton>
				</>
			}
		>
			<H2>{`${new HumaniDate(eventDate.startDate, props.timezone, LocationService.getLocation(true)).formatting.date(
				DateFormats.Full
			)}`}</H2>
			<Label bold={false}>{`${startDate} - ${endDate}`}</Label>
		</Modal>
	);
};

export default EventDateModal;
