import axios from "./AxiosWrapper";

const getHeaders = (location) => {
	return {
		headers: {
			"x-override-location": location
		}
	};
};

const FeesService = {
	async getDefaultFee(defaultType, location) {
		const response = await axios.get(`admin/fees/default/${defaultType}`, { ...getHeaders(location) });
		return response.data;
	}
};
export default FeesService;
