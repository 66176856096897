import { Alert, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";

import { reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import { SelectField } from "../components/Fields";
import LoadErrorView from "../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import { P } from "../components/text/Text";
import { required } from "../utils/Validators";

import { closeTransferAccount, find, transferAccount } from "../state/actions/userActions";

const transferred = [
	"Events",
	"Hosts",
	"Tags",
	"Global templates",
	"Email campaigns",
	"Text message campaigns",
	"Tours",
	"Gift cards",
	"Gift cards pages",
	"Add-ons",
	"Global discount codes",
	"Shared permissions"
];
class TransferAccountModal extends Component {
	cancel = () => {
		const { closeTransferAccount } = this.props;
		closeTransferAccount();
	};

	onSearch = (value) => {
		debounce(500, this.load)(value);
	};

	load = (value) => {
		const { find } = this.props;
		find({ query: value }, 1);
	};
	accountRequired = required("Please select an account to transfer to");

	transferAccount = (values) => {
		const { transferAccount, currentUser } = this.props;
		transferAccount(currentUser.user.id, values.user);
	};

	render() {
		const { handleSubmit, currentUser, users } = this.props;
		const { transfer } = currentUser;
		const closeLabel = transfer.result && transfer.result.success ? "Close" : "Cancel";
		const userOptions =
			users.results && users.results.users
				? users.results.users.map((user) => {
						return { label: user.email, value: user.id };
				  })
				: [];
		return (
			<Modal
				open={transfer.open}
				title="Transfer Account"
				onCancel={this.cancel}
				destroyOnClose
				footer={
					<ButtonsBar footer>
						<LegacyButton key="cancel" onClick={this.cancel} ariaLabel={closeLabel}>
							{closeLabel}
						</LegacyButton>
						{transfer.result && transfer.result.success ? null : (
							<LegacyButton
								key="submit"
								type="warning"
								loading={transfer.loading}
								onClick={handleSubmit(this.transferAccount)}
								ariaLabel="Transfer Account"
							>
								Transfer Account
							</LegacyButton>
						)}
					</ButtonsBar>
				}
			>
				<LoadErrorView loading={transfer.loading} error={transfer.error}>
					{transfer.result && transfer.result.success ? (
						<Alert
							message="Success"
							description={`${transfer.result.fromUserEmail} account has been transferred to ${transfer.result.toUserEmail}`}
							type="success"
							showIcon
						/>
					) : (
						<form>
							{currentUser.user ? (
								<P>
									Transfer account from:
									<br />
									<b>{currentUser.user.email}</b>
								</P>
							) : null}
							<Field
								name="user"
								label="To user"
								description="The user account to transfer to"
								component={SelectField}
								options={userOptions}
								showSearch
								onSearch={this.onSearch}
								notFoundContent={null}
								loading={users.loading}
								required
								validate={[this.accountRequired]}
								size="large"
							/>
							<P>
								{`The following settings will be transferred: `}
								{transferred.join(", ")}
							</P>
							{currentUser.user ? (
								<Alert
									message="Note"
									description={`Permission shared to ${currentUser.user.email} from other accounts will not be transferred due to security risks`}
									type="warning"
									showIcon
								/>
							) : null}
						</form>
					)}
				</LoadErrorView>
			</Modal>
		);
	}
}

const formName = "transferAccount";
TransferAccountModal = reduxForm({
	form: formName
})(TransferAccountModal);

const selector = formValueSelector(formName);
export default connect(
	(state) => {
		return {
			users: state.users,
			currentUser: state.currentUser,
			transferSettings: selector(state, "transferSettings")
		};
	},
	{ closeTransferAccount, find, transferAccount }
)(TransferAccountModal);
