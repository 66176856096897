import { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Page from "../components/Page";
import Order from "../events/event/order/Order";

const GiftCardOrder: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	return (
		<Page type="details">
			<Order location={location} navigate={navigate} params={params} />
		</Page>
	);
};

export default GiftCardOrder;
