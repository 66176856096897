const types = {
	selected: "#57cfc7",
	default: "#67717e"
};

/**
 * @param {{type: ("selected"|"default") }} config config for section element svg
 */
const ObjectElement = ({ type = "default" }) => {
	const fill = types[type];
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0H24V24H0z" />
				<path
					id="ele-id-fill"
					fill={fill}
					d="M14.5 7.5c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm1-5c.552 0 1 .448 1 1v3.674c-.909-.432-1.926-.674-3-.674-3.866 0-7 3.134-7 7 0 1.074.242 2.091.674 3H3.5c-.552 0-1-.448-1-1v-12c0-.552.448-1 1-1h12z"
				/>
			</g>
		</svg>
	);
};

export default ObjectElement;
