import { PreviewMockSite, PreviewMode } from "@/ui";
import { Collection } from "@hx/console";
import { FC, ReactNode, useEffect, useState } from "react";
import { useCollectionURL } from "@/hooks/useCollectionURL";

type PreviewCollectionPageProps = {
	activeCollection: Collection;
	banner?: ReactNode | null;
};

export const PreviewCollectionPage: FC<PreviewCollectionPageProps> = ({ activeCollection, banner }) => {
	const [previewMode, setPreviewMode] = useState<PreviewMode>("desktop");
	const [refreshToken, setRefreshToken] = useState<number>(Date.now());
	const collectionURL = useCollectionURL({
		collectionSlug: activeCollection.info.slug,
		isPreview: !activeCollection.published
	});

	useEffect(() => {
		setRefreshToken(Date.now());
	}, [activeCollection]);

	return (
		<PreviewMockSite
			banner={banner}
			linkOut={collectionURL}
			noGutters
			noScroll
			previewMode={previewMode}
			setPreviewMode={setPreviewMode}
		>
			<iframe
				allow="clipboard-write;"
				key={refreshToken}
				src={collectionURL}
				style={{
					backgroundColor: "#fff",
					border: "none",
					minHeight: "200%",
					overflow: "hidden",
					transform: "scale(0.5) translate(-50%, -50%)"
				}}
				width="200%"
			/>
		</PreviewMockSite>
	);
};
