import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { DateField, LegacyInputField, InputNumberField } from "../../components/Fields";
import { markMassPayoutAsPaid } from "../../state/actions/massPayoutActions";
import { required } from "../../utils/Validators";

import { scrollToErrors } from "../../utils/ScrollToErrors";

const formName = "mass-payment-paid";

class PaidModel extends Component {
	constructor() {
		super();
		this.save = this.save.bind(this);
	}

	transactionIdRequired = required("A transaction id is required");
	amountProcessedRequired = required("An amount processed is required");
	clearedAtRequired = required("A cleared at is required");

	save(values) {
		const { massPaymentId, markMassPayoutAsPaid, handleCancel } = this.props;
		markMassPayoutAsPaid(massPaymentId, values.transactionId, values.amountProcessed, values.clearedAt);
		handleCancel();
	}

	render() {
		const { visible, handleSubmit, handleCancel, title } = this.props;
		return (
			<form onSubmit={handleSubmit(this.save)}>
				<Modal title={title} open={visible} onOk={handleSubmit(this.save)} onCancel={handleCancel}>
					<Field
						name="transactionId"
						label="Transaction Id"
						component={LegacyInputField}
						validate={this.transactionIdRequired}
					/>

					<Field
						name="amountProcessed"
						label="Amount Processed"
						precision={2}
						component={InputNumberField}
						validate={this.amountProcessedRequired}
					/>

					<Field name="clearedAt" label="Cleared at" component={DateField} validate={this.clearedAtRequired} />
				</Modal>
			</form>
		);
	}
}

// Decorate with redux-form
PaidModel = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(PaidModel);

export default connect(
	(state, ownProps) => {
		const massPayoutsList = state?.massPayouts?.payouts?.payments || [];
		const massPayout = massPayoutsList.find((massPayout) => massPayout._id === ownProps?.massPaymentId);
		return {
			initialValues: {
				transactionId: massPayout?.transactionId,
				amountProcessed: massPayout?.amountProcessed,
				clearedAt: massPayout?.clearedAt
			}
		};
	},
	{ markMassPayoutAsPaid }
)(PaidModel);
