import LoadErrorView from "@/components/LoadErrorView";
import Page from "@/components/Page";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { getCampaign } from "@/state/actions/emailCampaignsActions";
import { Component } from "react";
import { connect } from "react-redux";
import CampaignForm from "./CampaignForm";
import PreviewModel from "./PreviewModal";

class EmailCampaign extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.load();
	}

	load() {
		const { params, getCampaign, currentUser } = this.props;
		const userId = currentUser.user.id;
		const emailId = params?.emailId;
		const template = params?.template;

		getCampaign(userId, emailId, template);
	}

	render() {
		const { navigate, emailCampaigns } = this.props;
		const { loading, error, data } = emailCampaigns.campaign;
		return (
			<Page>
				<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
					{data && <CampaignForm navigate={navigate} initialValues={data} />}
					<PreviewModel />
				</LoadErrorView>
			</Page>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			currentUser: state.auth,
			emailCampaigns: state.emailCampaigns
		};
	},
	{ getCampaign }
)(withRouterHooks(EmailCampaign));
