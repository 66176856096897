import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { openDeleteModel, updateOrganiser } from "../../state/actions/organiserActions";
import { urlSafe } from "../../utils/Format";
import { findLinkedInBaseUrl } from "../../utils/socials/linkedIn";
import OrganiserFormFields from "./FormFields";
import { prepareFormData } from "./utils";

const formName = "organiserDetails";

const OrganiserDetails = ({
	organisers,
	handleSubmit,
	updateOrganiser,
	openDeleteModel,
	name,
	slug,
	dispatch,
	linkedInProfileType
}) => {
	const handleSave = (organiserData) => {
		if (organisers.selected?._id) {
			updateOrganiser(organisers.selected._id, prepareFormData(organiserData));
		}
	};

	return (
		<form id={formName} style={{ margin: 4 }}>
			<OrganiserFormFields
				name={name}
				slug={slug}
				slugError={organisers.update.error}
				onGenerate={() => {
					const newSlug = urlSafe(name);
					dispatch(change(formName, "slug", newSlug));
				}}
				linkedInProfileType={linkedInProfileType}
				onLinkedProfileTypeChange={(profileType) => {
					dispatch(change(formName, "linkedInProfileType", profileType));
				}}
			/>
			<div>
				<LegacyButton
					style={{
						float: "right"
					}}
					onClick={handleSubmit(handleSave)}
					type="primary"
					htmlType="submit"
					ariaLabel="Update"
					size="large"
				>
					{organisers?.selected?._id ? "Update" : "Create host"}
				</LegacyButton>
				<LegacyButton
					type="warning"
					ariaLabel="Delete host"
					size="large"
					onClick={() => openDeleteModel(organisers.selected._id)}
				>
					Delete
				</LegacyButton>
			</div>
		</form>
	);
};

const OrganiserForm = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(OrganiserDetails);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		return {
			initialValues: state.organisers.selected,
			organisers: state.organisers,
			name: selector(state, "name"),
			slug: selector(state, "slug"),
			linkedInProfileType: findLinkedInBaseUrl(selector(state, "linkedIn"))
		};
	},
	{
		updateOrganiser,
		openDeleteModel
	}
)(OrganiserForm);
