import { notification } from "@/ui/antd";
import GiftCardService from "../../services/GiftCardService";
import FileDownload from "../../utils/FileDownload";
import { getLocationFromState } from "./locationActions";
export const GET_GIFT_CARD_REQUEST = "GET_GIFT_CARD_REQUEST";
export const GET_GIFT_CARD_SUCCESS = "GET_GIFT_CARD_SUCCESS";
export const GET_GIFT_CARD_ERROR = "GET_GIFT_CARD_ERROR";

export const GET_GIFT_CARD_PAGE_REQUEST = "GET_GIFT_CARD_PAGE_REQUEST";
export const GET_GIFT_CARD_PAGE_SUCCESS = "GET_GIFT_CARD_PAGE_SUCCESS";
export const GET_GIFT_CARD_PAGE_ERROR = "GET_GIFT_CARD_PAGE_ERROR";

export const SAVE_GIFT_CARD_PAGE_REQUEST = "SAVE_GIFT_CARD_PAGE_REQUEST";
export const SAVE_GIFT_CARD_PAGE_SUCCESS = "SAVE_GIFT_CARD_PAGE_SUCCESS";
export const SAVE_GIFT_CARD_PAGE_ERROR = "SAVE_GIFT_CARD_PAGE_ERROR";

export const SAVE_GIFT_CARD_REQUEST = "SAVE_GIFT_CARD_REQUEST";
export const SAVE_GIFT_CARD_SUCCESS = "SAVE_GIFT_CARD_SUCCESS";
export const SAVE_GIFT_CARD_ERROR = "SAVE_GIFT_CARD_ERROR";

export const GET_GIFT_CARD_LIST_REQUEST = "GET_GIFT_CARD_LIST_REQUEST";
export const GET_GIFT_CARD_LIST_SUCCESS = "GET_GIFT_CARD_LIST_SUCCESS";
export const GET_GIFT_CARD_LIST_ERROR = "GET_GIFT_CARD_LIST_ERROR";

export const GET_GIFT_CARD_SOLD_REQUEST = "GET_GIFT_CARD_SOLD_REQUEST";
export const GET_GIFT_CARD_SOLD_SUCCESS = "GET_GIFT_CARD_SOLD_SUCCESS";
export const GET_GIFT_CARD_SOLD_ERROR = "GET_GIFT_CARD_SOLD_ERROR";

export const GET_GIFT_CARD_REPORT_CSV_REQUEST = "GET_GIFT_CARD_REPORT_CSV_REQUEST";
export const GET_GIFT_CARD_REPORT_CSV_SUCCESS = "GET_GIFT_CARD_REPORT_CSV_SUCCESS";
export const GET_GIFT_CARD_REPORT_CSV_ERROR = "GET_GIFT_CARD_REPORT_CSV_ERROR";

export const getGiftCardList = (userId) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_GIFT_CARD_LIST_REQUEST });
		GiftCardService.find(userId, location)
			.then((giftCardList) => {
				dispatch({
					type: GET_GIFT_CARD_LIST_SUCCESS,
					giftCardList
				});
			})
			.catch((err) => {
				notification.error({ message: "Error", description: err.message });
				dispatch({ type: GET_GIFT_CARD_LIST_ERROR, err });
			});
	};
};

export const getGiftCard = (userId, giftCardId) => {
	return (dispatch) => {
		dispatch({ type: GET_GIFT_CARD_REQUEST });
		GiftCardService.get(userId, giftCardId)
			.then((giftCard) => {
				dispatch({ type: GET_GIFT_CARD_SUCCESS, giftCardData: giftCard });
			})
			.catch((err) => {
				notification.error({ message: "Error", description: err.message });
				dispatch({ type: GET_GIFT_CARD_ERROR, err });
			});
	};
};

export const updateGiftCard = (userId, giftCardId, giftCardData, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_GIFT_CARD_REQUEST });
		GiftCardService.update(userId, giftCardId, giftCardData)
			.then((giftCardList) => {
				dispatch({
					type: SAVE_GIFT_CARD_SUCCESS,
					giftCardList
				});
				if (navigate) {
					navigate(`/console/promote/gift-cards`);
				}
			})
			.catch((err) => {
				notification.error({ message: "Error", description: err.message });
				dispatch({ type: SAVE_GIFT_CARD_ERROR, err });
			});
	};
};

export const createGiftCard = (userId, giftCardData, navigate) => {
	return (dispatch) => {
		dispatch({ type: SAVE_GIFT_CARD_REQUEST });
		GiftCardService.create(userId, giftCardData)
			.then((giftCardList) => {
				notification.success({
					message: "Success",
					description: "Saved gift card page"
				});
				if (navigate) {
					navigate(`/console/promote/gift-cards`);
				}
				dispatch({ type: SAVE_GIFT_CARD_SUCCESS, giftCardList });
			})
			.catch((err) => {
				notification.error({ message: "Error", description: err.message });
				dispatch({ type: SAVE_GIFT_CARD_ERROR, err });
			});
	};
};

export const getGiftCardPage = (userId) => {
	return (dispatch) => {
		dispatch({ type: GET_GIFT_CARD_PAGE_REQUEST });
		try {
			GiftCardService.getPage(userId).then((giftCardPage) => {
				dispatch({
					type: GET_GIFT_CARD_PAGE_SUCCESS,
					giftCardPage
				});
			});
		} catch (err) {
			dispatch({ type: GET_GIFT_CARD_PAGE_ERROR, err });
		}
	};
};

export const updateGiftCardPage = (userId, giftCardPageId, giftCardPageData) => {
	return (dispatch) => {
		dispatch({ type: SAVE_GIFT_CARD_PAGE_REQUEST });
		try {
			GiftCardService.updatePage(userId, giftCardPageId, giftCardPageData).then((giftCardPage) => {
				notification.success({
					message: "Success",
					description: "Saved gift card page"
				});
				dispatch({
					type: SAVE_GIFT_CARD_PAGE_SUCCESS,
					giftCardPage
				});
			});
		} catch (err) {
			notification.error({ message: "Error", description: err.message });
			dispatch({ type: SAVE_GIFT_CARD_PAGE_ERROR, err });
		}
	};
};

export const findGiftCards = (queryBy, page) => {
	return (dispatch) => {
		dispatch({ type: GET_GIFT_CARD_SOLD_REQUEST });
		try {
			const location = getLocationFromState(true);
			GiftCardService.findGiftCards(queryBy, page, location).then(({ soldGiftCards, count }) => {
				dispatch({
					type: GET_GIFT_CARD_SOLD_SUCCESS,
					soldGiftCards,
					page,
					count
				});
			});
		} catch (err) {
			dispatch({ type: GET_GIFT_CARD_SOLD_ERROR, err });
		}
	};
};

export const exportGiftCards = (queryBy) => {
	return (dispatch, getState) => {
		dispatch({ type: GET_GIFT_CARD_REPORT_CSV_REQUEST });
		try {
			const location = getLocationFromState(getState(), "giftcards", true);
			GiftCardService.exportGiftCards(queryBy, location).then((data) => {
				FileDownload.saveAs(data, "gift-cards.csv");
				dispatch({
					type: GET_GIFT_CARD_REPORT_CSV_SUCCESS
				});
			});
		} catch (err) {
			dispatch({ type: GET_GIFT_CARD_REPORT_CSV_ERROR, err });
		}
	};
};
