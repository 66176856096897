import { Field, FieldArray } from "redux-form";
import { LegacyInputField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import ElementHeader from "../ElementHeader";
import PresentersList from "./PresentersList";

const titleRequired = required("A title url is required");
const Presenters = (props) => {
	const { fieldPath, changeFieldValue } = props;
	return (
		<>
			<ElementHeader {...props} />
			<div>
				<Field
					name={`${fieldPath}.title`}
					label="Title"
					component={LegacyInputField}
					required
					validate={titleRequired}
				/>
				<FieldArray
					name={`${fieldPath}.presenterList`}
					component={PresentersList}
					changeFieldValue={changeFieldValue}
				/>
			</div>
			<hr />
		</>
	);
};

export default Presenters;
