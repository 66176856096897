import { css } from "@emotion/css";
import { notification } from "@/ui/antd";
import { Component } from "react";
import { fsClient } from "../services/ImageService";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const fileRow = css({
	display: "flex"
});

class FileUpload extends Component {
	fileUploaded = ({ filesUploaded, filesFailed }) => {
		if (filesFailed.length) {
			notification.error({ message: "File failed to upload" });
			return;
		}
		const uploadedFile = filesUploaded[0];
		this.setFiles(uploadedFile);
	};

	setFiles = (file) => {
		const { saveHandleOnly, onChange } = this.props;
		const fileData = saveHandleOnly
			? file.handle
			: {
					name: file.filename,
					size: file.size,
					url: file.url,
					source: file.source,
					handle: file.handle
			  };
		if (onChange) {
			onChange(fileData);
		}
	};

	uploadFile = () => {
		const { aspectRatio } = this.props;
		let options = {
			fromSources: ["local_file_system", "url", "facebook", "instagram", "googledrive", "dropbox"],
			maxSize: 102400000,
			maxFiles: 1,
			minFiles: 1,
			imageMax: [1000, 1000],
			uploadInBackground: false,
			accept: ["image/*", "application/pdf", "text/*", "video/*", "audio/*"],
			onUploadDone: this.fileUploaded
		};

		if (aspectRatio) {
			options.transformations.crop = {
				force: true,
				aspectRatio
			};
		}

		fsClient.picker(options).open();
	};

	render() {
		const { value, uploadLabel = "Upload file" } = this.props;

		return (
			<div className={fileRow}>
				<LegacyButton ariaLabel={uploadLabel} type="action" onClick={this.uploadFile}>
					{uploadLabel}
				</LegacyButton>
				{value ? <p style={{ margin: 10 }}>{value.name}</p> : null}
			</div>
		);
	}
}

export default FileUpload;
