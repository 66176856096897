import { Divider } from "@/components/Divider";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { EventDetails } from "@/state/reducers/eventReducer";
import { Button } from "@/ui";
import { CopyField } from "@/ui";
import { Modal } from "@/ui/antd";
import { getEventUrls } from "@/utils/Events";
import { ShareAltOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useState } from "react";
import { useNavigate } from "react-router";
import { DownloadQRCode } from "../DownloadQRCode";

const contentWrapper = css({
	display: "flex",
	flexDirection: "column",
	gap: 24
});

const primaryContent = css({
	display: "flex",
	flexDirection: "column",
	gap: 8
});

const secondaryContent = css({
	display: "flex",
	width: "100%",
	gap: 8,
	justifyContent: "space-between",
	flexDirection: "row",
	"@media(max-width: 600px)": {
		flexDirection: "column"
	}
});

type EventSharingOptionsProps = EventSharingProps & {
	onNavigating: () => void;
};

export const EventSharingOptions = ({ event, onNavigating }: EventSharingOptionsProps) => {
	const navigate = useNavigate();
	const { eventPage, selectTicketsPage } = getEventUrls(event, true);
	const isEventPublished = event?.published;

	return (
		<div className={contentWrapper}>
			<div className={primaryContent}>
				<CopyField
					displayValue={eventPage}
					dropdownOptions={[
						{ label: "For event page", value: eventPage },
						{ label: "For select tickets page", value: selectTicketsPage }
					]}
				/>
				{isEventPublished ? <DownloadQRCode event={event} /> : null}
			</div>

			<Divider text="More ways to share" />

			<div className={secondaryContent}>
				<LegacyButton
					type="action"
					icon={{ left: true, name: "facebook" }}
					ariaLabel="Create Facebook event"
					style={{
						flexGrow: 1
					}}
					onClick={() => {
						onNavigating();
						navigate(`/console/my-events/${event?._id}/facebook-events`);
					}}
				>
					Facebook event
				</LegacyButton>

				<LegacyButton
					type="action"
					icon={{ left: true, name: "promote" }}
					ariaLabel="Go to Promotional Hub"
					style={{ flexGrow: 1 }}
					onClick={() => {
						onNavigating();
						navigate(
							`/console/promote/promotional-hub/social-media?page=1&query=&sortOrder=relevant&filter=all&tab=individual&eventIds=${event?._id}`
						);
					}}
				>
					Promotional hub
				</LegacyButton>
			</div>
		</div>
	);
};

type EventSharingProps = {
	event?: EventDetails;
};

export const EventSharingButton = ({ event }: EventSharingProps) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Button aria-label="Share event" iconOnly onClick={() => setOpen(true)} variant="tertiary">
				<ShareAltOutlined />
			</Button>
			<Modal open={open} onCancel={() => setOpen(false)} title="Share" footer={null}>
				<EventSharingOptions event={event} onNavigating={() => setOpen(false)} />
			</Modal>
		</>
	);
};
