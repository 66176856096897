import { promoteMenu } from "@/services/menuService/menus/promote";
import { Location } from "history";
import { ReactNode } from "react";
import { matchPath } from "react-router";
import { accountMenu } from "./menus/account";
import { adminMenu } from "./menus/admin";
import { eventsMenu } from "./menus/events";
import { homeMenu } from "./menus/home";
import { hostsMenu } from "./menus/hosts";
import { reportsMenu } from "./menus/Reports";
import { userMenu } from "./menus/user";

const emailCampaigns: MenuItem = {
	label: "Email campaigns",
	path: "/console/comms/email-campaigns",
	key: "comms-email-campaigns"
};

export type MenuItem = {
	children?: MenuItem[];
	cols?: number;
	dataCy?: string;
	hide?: boolean;
	icon?: string;
	key: string;
	label: string | ReactNode;
	matchPath?: string;
	path: string;
	subMenu?: boolean;
};

class MenuService {
	static instance: MenuService;
	menu: MenuItem[] = [
		...homeMenu(),
		...eventsMenu(),
		...reportsMenu(),
		...promoteMenu(),
		emailCampaigns,
		...accountMenu(),
		...userMenu(),
		...hostsMenu(),
		...adminMenu()
	];

	static createInstance(): MenuService {
		this.instance = new MenuService();
		return this.instance;
	}

	static getInstance(): MenuService {
		if (!this.instance) {
			this.instance = this.createInstance();
		}
		return this.instance;
	}

	checkSelected = (item: MenuItem, location: Location): boolean => {
		if (!location) {
			return false;
		}
		const pathToMatch = item.matchPath || item.path;
		const match = matchPath({ path: pathToMatch, end: true }, location.pathname);
		return !!match;
	};

	getSelected = (items: MenuItem[], location: Location): MenuItem[] => {
		let selected: MenuItem[] = [];
		if (items && items.length) {
			items.forEach((item) => {
				if (item.children) {
					selected = selected.concat(this.getSelected(item.children, location));
				}
				if (this.checkSelected(item, location)) {
					selected.push(item);
				}
			});
		}

		return selected;
	};

	getItemFromKey(key: string, workingMenu: MenuItem[]): MenuItem | null {
		let result = null;
		for (const item of workingMenu) {
			if (item.key === key) {
				result = item;
			}
			if (!result && item.children) {
				result = this.getItemFromKey(key, item.children);
			}
		}
		return result;
	}

	getItemFromPath(path: string, workingMenu: MenuItem[]): MenuItem | null {
		let result = null;
		path = path.replace(/\/$/, "");
		for (const item of workingMenu) {
			if (item && item.path === path) {
				result = item;
			}
			if (!result && item && item.children) {
				result = this.getItemFromPath(path, item.children);
			}
		}
		return result;
	}

	getItemChildren(key: string): MenuItem[] | null {
		const item = this.getItemFromKey(key, this.menu);
		return item?.children ?? null;
	}

	getItemLabel(key: string): string | ReactNode | null {
		const item = this.getItemFromKey(key, this.menu);
		return item?.label ?? null;
	}

	getSubMenuKeyWithLocation(location: Location): string[] {
		if (!location || !location.pathname) {
			return [];
		}
		const path = location.pathname;
		const key = path.slice(0, path.lastIndexOf("/"));
		return [`humanitix-menu-subMenu-${key}`];
	}

	getKeyFromPath(path: string, workingMenu: MenuItem[], parent?: MenuItem): string | null {
		let result = null;
		path = path.replace(/\/$/, "");

		for (let i = 0; i < workingMenu.length; i++) {
			const item = workingMenu[i];

			if (item && item.path === path && !item.subMenu && parent) {
				result = `humanitix-menu-subMenu-${parent.key}`;
			}
			if (!result && item && item.children) {
				result = this.getKeyFromPath(path, item.children, item);
			}
		}
		return result;
	}

	getUserMenu(): MenuItem[] {
		this.menu = [
			...homeMenu(),
			...eventsMenu(),
			...reportsMenu(),
			...promoteMenu(),
			emailCampaigns,
			...accountMenu(),
			...userMenu(),
			...hostsMenu(),
			...adminMenu()
		];
		return this.menu;
	}
}

export default MenuService.getInstance();
