import { css } from "@emotion/css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import iconReference from "../services/IconReference";
import { findAncestor } from "../utils/Browser";
import { lastOfType, max } from "../utils/CssUtils";

const icTick = iconReference["tick"];
const icTickInverted = iconReference["tick_inverted"];

const listWrapper = css({
	margin: "-24px auto -6px",
	listStyleType: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "sticky",
	top: 0,
	zIndex: 100,
	padding: "18px 0",
	maxWidth: "80vw",
	hr: {
		width: 24,
		margin: "0 4px",
		[lastOfType()]: {
			display: "none"
		}
	},
	a: {
		"&:focus": {
			border: "1px solid #ffb18f",
			borderRadius: 4
		}
	},
	[max(720)]: {
		maxWidth: "100vw",
		marginBottom: 30,
		paddingBottom: "26px"
	}
});

const getListElementStyle = (
	textColor,
	numberBackgroundColor,
	numberBorderColor,
	numberTextColor,
	showLabelOnMobile
) => {
	return css({
		position: "relative",
		display: "flex",
		color: textColor,
		fontSize: 14,
		img: { marginRight: 4 },
		span: {
			width: 24,
			height: 24,
			borderRadius: "50%",
			backgroundColor: numberBackgroundColor,
			border: `1px solid ${numberBorderColor}`,
			color: numberTextColor,
			textAlign: "center",
			marginRight: 4,
			textDecoration: "none !important"
		},
		"&:hover": {
			textDecoration: "underline"
		},
		[max(720)]: {
			flexDirection: "column",
			alignItems: "center",
			div: {
				display: showLabelOnMobile ? "block" : "none",
				position: "absolute",
				bottom: -20,
				whiteSpace: "nowrap"
			}
		}
	});
};

const stickyStyles = {
	background: "#f9f9fa",
	borderBottom: "1px solid #dedede",
	maxWidth: "100vw"
};

const listElementLookup = {
	active: getListElementStyle("#353337", "#353337", "#353337", "#fff", true),
	disabled: getListElementStyle("#5a626c", "#eeecf0", "#eeecf0", "#5a626c", false),
	enabled: getListElementStyle("#323232", "#f9f9fa", "#353337", "#353337", false)
};

const getStepIndicator = (step, index) => {
	if (!step.displayTick) return <span>{index + 1}</span>;
	return <img src={step.status === "active" ? icTick : icTickInverted} alt={`${step.label} ${step.status}`} />;
};

function Steps({ steps }) {
	const [isSticky, setSticky] = useState(false);
	const ref = useRef(null);

	const handleScroll = () => {
		try {
			if (ref.current) {
				const scrollEl = findAncestor(ref.current, "scrollEl");
				setSticky(
					ref.current.getBoundingClientRect().top <= scrollEl.getBoundingClientRect().top && scrollEl.scrollTop !== 0
				);
			}
		} catch (err) {
			null;
		}
	};

	useEffect(() => {
		try {
			if (ref.current) {
				const scrollEl = findAncestor(ref.current, "scrollEl");
				scrollEl.addEventListener("scroll", handleScroll);
				return () => {
					scrollEl.removeEventListener("scroll", () => handleScroll);
				};
			}
		} catch (err) {
			null;
		}
	}, []);

	return (
		<ul className={listWrapper} style={isSticky ? stickyStyles : {}} ref={ref}>
			{steps.map((step, i) => {
				const liElement = (
					<li className={listElementLookup[step.status]} data-cy={step.key}>
						{getStepIndicator(step, i)}
						<div>{step.label}</div>
					</li>
				);

				return step.status === "enabled" ? (
					<>
						<Link to={step.to}>{liElement}</Link>
						<hr />
					</>
				) : (
					<>
						{liElement}
						<hr />
					</>
				);
			})}
		</ul>
	);
}

export default Steps;
