import {
	FOLLOWERS_CSV_ERROR,
	FOLLOWERS_CSV_REQUEST,
	FOLLOWERS_CSV_SUCCESS,
	FOLLOWERS_FIND_ERROR,
	FOLLOWERS_FIND_REQUEST,
	FOLLOWERS_FIND_SUCCESS,
	FOLLOWERS_IMPORT_CLOSE,
	FOLLOWERS_IMPORT_OPEN,
	FOLLOWERS_UPLOAD_ERROR,
	FOLLOWERS_UPLOAD_REQUEST,
	FOLLOWERS_UPLOAD_SUCCESS
} from "../actions/followersActions";

const initialState = {
	list: {
		loading: false,
		error: false,
		page: 1,
		data: null
	},
	download: {
		loading: false,
		error: false
	},
	count: null,
	import: {
		open: false,
		loading: false,
		error: false,
		results: null
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FOLLOWERS_IMPORT_OPEN:
			return {
				...state,
				import: {
					...state.import,
					open: true
				}
			};
		case FOLLOWERS_IMPORT_CLOSE:
			return {
				...state,
				import: {
					...state.import,
					open: false,
					loading: false,
					error: false,
					results: null
				}
			};
		case FOLLOWERS_FIND_REQUEST:
			return {
				...state,
				list: {
					...state.list,
					loading: true,
					error: false
				}
			};
		case FOLLOWERS_FIND_SUCCESS:
			return {
				...state,
				list: {
					...state.list,
					loading: false,
					error: false,
					page: action.page,
					data: action.followers
				}
			};

		case FOLLOWERS_FIND_ERROR:
			return {
				...state,
				list: {
					...state.list,
					loading: false,
					error: action.error
				}
			};
		case FOLLOWERS_CSV_REQUEST:
			return {
				...state,
				download: {
					...state.download,
					loading: true,
					error: false
				}
			};
		case FOLLOWERS_CSV_SUCCESS:
			return {
				...state,
				download: {
					...state.download,
					loading: false,
					error: false
				}
			};
		case FOLLOWERS_CSV_ERROR:
			return {
				...state,
				download: {
					...state.download,
					loading: false,
					error: true
				}
			};

		case FOLLOWERS_UPLOAD_REQUEST:
			return {
				...state,
				import: {
					...state.import,
					loading: true,
					error: false,
					results: null
				}
			};
		case FOLLOWERS_UPLOAD_SUCCESS:
			return {
				...state,
				import: {
					...state.import,
					loading: false,
					error: false,
					results: action.results
				}
			};
		case FOLLOWERS_UPLOAD_ERROR:
			return {
				...state,
				import: {
					...state.import,
					loading: false,
					error: true,
					results: null
				}
			};

		default:
			return state;
	}
};
