import axios from "./AxiosWrapper";
import LocationService from "./LocationService";

const getLocationHeader = () => {
	return { "x-override-location": LocationService.getLocation(false) };
};

const PayoutService = {
	async searchPayouts(refinements) {
		const response = await axios.get(`admin/payments/payout/search`, {
			params: refinements,
			headers: getLocationHeader()
		});
		return response.data;
	},
	async searchPayments(refinements) {
		const response = await axios.get(`admin/payments/payment/search`, {
			params: refinements,
			headers: getLocationHeader()
		});
		return response.data;
	},
	async getPayoutCsvReport(refinements) {
		const response = await axios.get(`admin/payments/payout/csv`, {
			responseType: "blob",
			params: refinements,
			headers: getLocationHeader()
		});
		return response.data;
	},
	async getPaymentCsvReport(refinements) {
		const response = await axios.get(`admin/payments/payment/csv`, {
			responseType: "blob",
			params: refinements,
			headers: getLocationHeader()
		});
		return response.data;
	},
	async changePayoutStatus(payoutDateId, status) {
		const response = await axios.put(
			`admin/payments/payout/status/${payoutDateId}`,
			{ status },
			{ headers: getLocationHeader() }
		);
		return response.data;
	},
	async massChangePayoutStatus(payoutDateIdArray, status) {
		const response = await axios.put(
			`admin/payments/payout/mass-status/`,
			{
				payoutDateIdArray,
				status
			},
			{ headers: getLocationHeader() }
		);
		return response.data;
	},
	async flagPayout(payoutId) {
		const response = await axios.put(`admin/payments/payout/flag/${payoutId}`, {}, { headers: getLocationHeader() });
		return response.data;
	},
	async massFlagPayout(payoutIdArray, flagged) {
		const response = await axios.put(
			`admin/payments/payout/mass-flag/`,
			{
				payoutIdArray,
				flagged
			},
			{ headers: getLocationHeader() }
		);
		return response.data;
	},
	async getPayments(id) {
		const response = await axios.get(`events/${id}/payments`, {}, { headers: getLocationHeader() });
		return response.data;
	},
	async pushPayment(id, paymentInfo) {
		const response = await axios.put(`admin/events/${id}/payments`, paymentInfo);
		return response.data;
	},
	async addCredit(id, paymentInfo) {
		const response = await axios.put(`admin/events/${id}/credits`, paymentInfo);
		return response.data;
	},
	async changeAutoPayout(id, value) {
		const response = await axios.put(
			`admin/events/${id}/auto-payout`,
			{
				autoPayout: value
			},
			{ headers: getLocationHeader() }
		);
		return response.data;
	},
	async pushNote(id, note) {
		const response = await axios.put(
			`admin/events/${id}/payments/note`,
			{
				note
			},
			{ headers: getLocationHeader() }
		);
		return response.data;
	},
	async deleteNote(id, noteId) {
		const response = await axios.delete(
			`admin/events/${id}/payments/note/delete/${noteId}`,
			{},
			{ headers: getLocationHeader() }
		);
		return response.data;
	},
	async updatePayment(id, paymentId, paymentInfo) {
		const response = await axios.post(`admin/events/${id}/payments/${paymentId}`, paymentInfo);
		return response.data;
	},
	async removePayment(eventId, paymentId, options) {
		const response = await axios.post(`admin/events/${eventId}/payment/remove/${paymentId}`, options, {
			headers: getLocationHeader()
		});
		return response.data;
	},
	async sendUserVerificationEmail(eventId) {
		const response = await axios.post(
			`admin/events/${eventId}/emails/user-verification`,
			{},
			{ headers: getLocationHeader() }
		);
		return response;
	},
	async sendUserBankDetailsEmail(eventId) {
		const response = await axios.post(
			`admin/events/${eventId}/emails/user-bank-acount-details`,
			{},
			{ headers: getLocationHeader() }
		);
		return response;
	},
	async sendPayoutReminderEmails(eventId, verifyEmail, bankEmail) {
		const response = await axios.post(
			`admin/events/${eventId}/emails/payout-email-requests`,
			{
				verifyUserEmail: verifyEmail,
				bankAccountEmail: bankEmail
			},
			{ headers: getLocationHeader() }
		);
		return response;
	},
	async massSendPayoutEmails(eventIds, verifyEmail, bankEmail) {
		const response = await axios.post(
			`admin/events/emails/mass-payout-email-requests`,
			{
				eventIds,
				verifyUserEmail: verifyEmail,
				bankAccountEmail: bankEmail
			},
			{ headers: getLocationHeader() }
		);
		return response;
	},

	async markPaymentsAsComplete(paymentsToMarkAsComplete) {
		const response = await axios.put(
			`admin/payments/payout/mass-mark-complete/`,
			{
				paymentsToMarkAsComplete
			},
			{ headers: getLocationHeader() }
		);
		return response;
	},

	async downloadInvoice(eventId) {
		const response = await axios.get(
			`reports/download-invoice/${eventId}`,
			{
				responseType: "blob"
			},
			{ headers: getLocationHeader() }
		);
		return response.data;
	}
};
export default PayoutService;
