import { Input } from "@/ui/antd";
import { FC, useEffect, useState } from "react";
import IconButton from "../IconButton";
import InputWrapper from "./helpers/InputWrapper";

type PasswordFieldProps = {
	addonBefore?: any;
	autoFocus?: boolean;
	dataCy?: string;
	description?: string;
	disabled?: boolean;
	inline?: boolean;
	input: any;
	label: string;
	maxLength?: number;
	meta: { error: string; touched: boolean };
	noMargin?: boolean;
	normalize?: (value: any) => any;
	placeholder?: string;
	prefix?: any;
	preventPaste?: boolean;
	required?: boolean;
	size?: "default" | "large" | "small";
	style?: any;
	tooltip?: string;
	tooltipIcon?: boolean;
	tooltipIconOnClick?: () => void;
};

const PasswordField: FC<PasswordFieldProps> = ({
	addonBefore,
	autoFocus,
	dataCy,
	description,
	disabled,
	inline,
	input,
	label,
	maxLength = 10000,
	meta: { touched, error },
	noMargin = false,
	normalize,
	placeholder,
	prefix,
	preventPaste,
	required,
	size = "default",
	style,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick
}) => {
	const [type, setType] = useState("password");

	const toggleType = () => {
		const newType = type === "password" ? "text" : "password";
		setType(newType);
	};

	// Manage the internal state because antd and redux form don't play nice together
	const [value, setValue] = useState(input.value);

	useEffect(() => {
		if (input.value !== value) {
			setValue(input.value);
		}
	}, [input.value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		input.onChange(e);
	};

	return (
		<InputWrapper
			description={description}
			error={error}
			id={input.name}
			inline={inline}
			label={label}
			noMargin={noMargin}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			touched={touched}
		>
			<Input
				id={input.name}
				prefix={prefix}
				suffix={
					<IconButton
						ariaLabel="Show Password"
						icon={type === "password" ? "preview" : "disable"}
						onClick={toggleType}
						tooltip="Show Password"
					/>
				}
				{...input}
				addonBefore={addonBefore}
				autoFocus={autoFocus}
				data-cy={dataCy}
				disabled={disabled}
				maxLength={maxLength}
				normalize={normalize}
				onChange={handleChange}
				onPaste={preventPaste ? (e) => e.preventDefault() : undefined}
				placeholder={placeholder}
				size={size}
				style={style}
				type={type}
				value={value}
			/>
		</InputWrapper>
	);
};

export default PasswordField;
