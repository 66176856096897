import * as filestack from "filestack-js";
import { getConfig } from "@/config";
import axios from "./AxiosWrapper";
// init Filestack client
const fsClient = filestack.init(getConfig("FILESTACK_KEY"));

const getImageUrl = (image, width, height) => {
	const handle = image && image.handle ? image.handle : image;
	let resizeParams = `width:${width}`;
	resizeParams = height ? `${resizeParams},height:${height}` : resizeParams;
	resizeParams = `${resizeParams},fit:crop`;
	return `https://process.filestackapi.com/resize=${resizeParams}/${handle}`;
};

const getFileUrl = (file) => {
	const handle = file && file.handle ? file.handle : file;
	return `https://process.filestackapi.com/${handle}`;
};

const upload = async (url) => {
	const response = await axios.post(`images/upload`, { url });
	return response.data;
};

export { fsClient, getFileUrl, getImageUrl, upload };
