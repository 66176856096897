export const CREATE = "CREATE";
export const OPEN = "OPEN";
export const CLOSE = "CLOSE";
export const DESTROY = "DESTROY";

export const create = (name) => {
	return (dispatch) => {
		dispatch({ type: CREATE, name });
	};
};

export const open = (name) => {
	return (dispatch) => {
		setTimeout(() => dispatch({ type: OPEN, name }), 100);
	};
};

export const close = (name) => {
	return (dispatch) => {
		dispatch({ type: CLOSE, name });
	};
};

export const destroy = (name) => {
	return (dispatch) => {
		dispatch({ type: DESTROY, name });
	};
};
