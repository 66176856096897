import { CameraOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Col, Row } from "@/ui/antd";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const MiniPreview = (props) => {
	const { theme } = props;
	const {
		hideHeader = false,
		primaryColor = "#353337",
		footerColor = "#8E99A8",
		ticketButtonText = "Get Tickets",
		background,
		primaryTextColor
	} = theme || {};
	let backgroundStyle;
	if (background && background.type && background.type !== "") {
		switch (background.type) {
			case "image":
				backgroundStyle = {
					background:
						background.image && background.image.url
							? `url(${background.image.url}) no-repeat center #eee`
							: background.color,
					backgroundSize: "cover"
				};
				break;
			case "pattern":
				backgroundStyle = {
					background: background.image && background.image.url ? `url(${background.image.url})` : background.color,
					backgroundSize: "35%"
				};
				break;
			default:
				backgroundStyle = {
					backgroundColor: `${background.color}`
				};
				break;
		}
	}

	const card = css`
		padding: 5px;
		border-radius: 8px;
		border: 2px solid #dedede;
		margin-bottom: 20px;
	`;

	const container = css`
		border: 1px solid #eee;
		margin: 0;
		${backgroundStyle}
	`;

	const header = css({
		background: "#fff",
		height: 24
	});

	const topContainer = css`
		width: 80%;
		margin: auto;
	`;

	const icon = css`
		font-size: 40px;
		color: #eee;
		padding-top: 20px;
	`;

	const buttonDiv = css`
		text-align: right;
		background: #fff;
		height: 32px;
	`;

	const button = css`
		background: ${primaryColor};
		color: ${primaryTextColor};
		float: right;
		margin-top: 5px;
		margin-right: 10px;
	`;
	const contentsContainer = css`
		background: #fff;
		margin-bottom: 4px;
	`;
	const contentsLeftCol = css`
		padding: 10px !important;
	`;
	const contentsRightCol = css`
		padding: 10px !important;
	`;

	const contentsRight = css`
		width: 60px;
		height: 100px;
		background: #eee;
		float: right;
	`;
	const headerLine = css`
		border-top: 5px solid #aaa;
		border-radius: 50px;
		width: 60px;
		margin-bottom: 30px;
	`;

	const linkLine = css`
		border-top: 3px solid ${primaryColor};
		border-radius: 50px;
		width: 40px;
		margin-bottom: 20px;
	`;

	const veryShortLine = css`
		border-top: 3px solid #aaa;
		border-radius: 50px;
		width: 20px;
		margin-bottom: 5px;
		margin-right: 5px;
		display: inline-block;
	`;

	const circle = css`
		border-radius: 50%;
		width: 10px;
		height: 10px;
		margin-right: 5px;
		background-color: #aaa;
		display: inline-block;
	`;

	const shortLine = css`
		border-top: 3px solid #aaa;
		border-radius: 50px;
		width: 40px;
		margin-bottom: 10px;
	`;

	const longLine = css`
		border-top: 3px solid #aaa;
		border-radius: 50px;
		width: 90px;
		margin-bottom: 10px;
	`;

	const footerContainer = css`
		height: 60px;
		background: ${footerColor};
	`;
	const br = css`
		margin-bottom: 20px;
	`;

	const previewDiv = css`
		display: flex;
		align-items: center;
		justify-content: center;
	`;

	const bannerWrapper = css({
		width: "100%",
		height: 100,
		padding: "10px 0"
	});

	const bannerImage = css({
		float: "left",
		width: "76%",
		height: 80,
		backgroundColor: "#cce5ff",
		borderRadius: 4,
		textAlign: "center"
	});

	const infoBox = css({
		float: "right",
		width: "20%",
		height: 80,
		backgroundColor: "#eee",
		borderRadius: 4
	});

	return (
		<div>
			<div className={card}>
				<div className={container}>
					{!hideHeader ? <div className={header} /> : null}
					<div className={topContainer}>
						<div className={bannerWrapper}>
							<div className={infoBox} />
							<div className={bannerImage}>
								<CameraOutlined className={icon} />
							</div>
						</div>
						<div className={buttonDiv}>
							<div style={{ float: "right", marginRight: 10 }}>
								<LegacyButton size="small" className={button} ariaLabel={ticketButtonText}>
									{ticketButtonText}
								</LegacyButton>
							</div>
						</div>
						<Row className={contentsContainer}>
							<Col span={12} className={contentsLeftCol}>
								<div className={headerLine} />
								<div className={shortLine} />
								<div className={linkLine} />

								<div className={longLine} />
								<div className={longLine} />
								<div className={longLine} />
								<div className={longLine} />

								<div className={veryShortLine} />
								<div className={circle} />
								<div className={circle} />
								<div className={circle} />
								<div className={circle} />
								<div className={br} />
								<div className={br} />
								<div className={linkLine} />
								<div className={br} />
								<div className={br} />
								<div className={longLine} />
								<div className={longLine} />
								<div className={longLine} />
								<div className={longLine} />
								<div className={br} />
								<div className={br} />
								<div className={linkLine} />
							</Col>
							<Col span={12} className={contentsRightCol}>
								<div className={contentsRight} />
							</Col>
						</Row>
					</div>
					<div className={footerContainer} />
				</div>
			</div>
			<div className={previewDiv}>
				{/* JEN-14649 hidden until v2 version is created 
        {currentEvent && currentEvent.event ? (
          <a
            href={`${getConfig("TICKETING_URL")}/${currentEvent.event.slug}/tickets?theme=${themeJson}&token=${
              AuthService.token
            }`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button icon={{ left: true, name: "preview" }} type="action" flavor="minimal" ariaLabel="Preview checkout">
              Preview checkout
            </Button>
          </a>
        ) : null}*/}
			</div>
		</div>
	);
};

export default MiniPreview;
