import {
	CLEAR_USER_EVENTS,
	GET_USER_EVENTS_ERROR,
	GET_USER_EVENTS_REQUEST,
	GET_USER_EVENTS_SUCCESS
} from "../actions/eventActions";

const initialState = {
	events: false,
	loading: false,
	error: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_EVENTS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_USER_EVENTS_SUCCESS:
			return {
				...state,
				events: action.events,
				loading: false
			};
		case GET_USER_EVENTS_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case CLEAR_USER_EVENTS:
			return {
				...initialState
			};
		default:
			return state;
	}
};
