import { css } from "@emotion/css";
import { Field, FieldArray, FieldArrayFieldsProps } from "redux-form";
import {
	LegacyInputField,
	InputNumberField,
	PdfUploadField,
	RadioField,
	RichTextAreaField,
	SelectField,
	SwitchField,
	TextAreaField
} from "@/components/Fields";
import InputLabel from "@/components/fields/helpers/InputLabel";
import TableActions from "@/components/table/TableActions";
import AfterCheckout from "./AfterCheckout";
import ConditionalQuestions from "./ConditionalQuestions";
import DefaultValues from "./DefaultValues";
import RenameValues from "./RenameValues";

import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import RequiredAsterisk from "@/components/fields/helpers/RequiredAsterisk";
import { getTicketDisplayName } from "@/utils/Ticket";
import { checkNumber, required } from "@/utils/Validators";
import ShowOnTicket from "./ShowOnTicket";

const optionNameRequired = required("An option name is required");
const quantityNotNegative = checkNumber("Quantity must not be negative", 0, ">=");

const imageAspectRatioOptions = [
	{
		label: "Any",
		value: 0
	},
	{
		label: "1:1",
		value: 1
	},
	{
		label: "4:5",
		value: 0.8
	},
	{
		label: "5:4",
		value: 1.25
	},
	{
		label: "4:3",
		value: 1.3
	},
	{
		label: "3:2",
		value: 1.5
	},
	{
		label: "5:3",
		value: 1.6
	},
	{
		label: "16:9",
		value: 1.7
	}
];

const styles = {
	fieldContainer: css`
		min-width: 100%;
		width: 100%;
		max-width: 320px;
		margin-bottom: 6px;
		@media (max-width: 576px) {
			max-width: 100%;
			min-width: 100%;
		}
	`
};

interface IQuestionOptions {
	question: any;
	event: any;
	additionalQuestions: any[];
	index: number;
	fields: any[];
	removeConditions: (field: string, value: any) => void;
	isFromtemplate: boolean;
	alreadySoldTickets?: boolean;
	isNew?: boolean;
}

const QuestionOptions = ({
	question,
	event,
	additionalQuestions,
	index,
	fields,
	removeConditions,
	isFromtemplate,
	alreadySoldTickets,
	isNew
}: IQuestionOptions) => {
	const ticketTypes = event.ticketTypes ? event.ticketTypes.filter((t: any) => !t.deleted && !t.isDonation) : [];
	const options = ticketTypes.map((ticket: any) => {
		return { value: ticket._id, label: getTicketDisplayName(ticket) };
	});
	const trackQuantity =
		additionalQuestions[index] && additionalQuestions[index].trackQuantity
			? additionalQuestions[index].trackQuantity
			: false;

	return (
		<div>
			{!isFromtemplate ? (
				<div className={styles.fieldContainer}>
					<Field
						name={`additionalQuestions[${index}].appliesTo`}
						label="Applies to"
						mode="multiple"
						placeholder="All Ticket Types"
						description="Which tickets does the question apply to"
						component={SelectField}
						style={{ minWidth: "60%" }}
						size="small"
						options={options}
					/>
				</div>
			) : null}
			{question.type !== "note" ? (
				<Field
					label="Description"
					name={`additionalQuestions[${index}].description`}
					component={TextAreaField}
					rows={2}
					placeholder="Questions description"
				/>
			) : null}
			{question.type === "image" ? (
				<Field
					name={`additionalQuestions[${index}].settings.aspectRatio`}
					label="Aspect Ratio"
					component={SelectField}
					options={imageAspectRatioOptions}
				/>
			) : null}
			{question.type === "options" || question.type === "multiSelect" ? (
				<FieldArray
					name={`additionalQuestions[${index}].options`}
					component={Options}
					question={question}
					trackQuantity={trackQuantity}
					options={additionalQuestions[index].options}
					questionIndex={index}
					alreadySoldTickets={alreadySoldTickets}
					isNew={isNew}
					capacityEnabled={additionalQuestions[index].trackQuantity}
				/>
			) : null}
			{!(question.type === "note" || question.type === "image") ? (
				<DefaultValues
					additionalQuestions={additionalQuestions}
					questionIndex={index}
					mode={question.type === "multiSelect" ? "multiple" : null}
				/>
			) : null}
			{question.type === "terms" ? <RenameValues questionIndex={index} /> : null}

			<ConditionalQuestions
				additionalQuestions={additionalQuestions}
				questionIndex={index}
				fields={fields}
				removeConditions={removeConditions}
			/>
			{question.type === "terms" ? (
				<TermsOptions additionalQuestions={additionalQuestions} questionIndex={index} />
			) : null}
			<AfterCheckout questionIndex={index} />
			<ShowOnTicket questionIndex={index} />
		</div>
	);
};

interface ITermOptionsProps {
	additionalQuestions: any;
	questionIndex: number;
}

const TermsOptions = ({ additionalQuestions, questionIndex }: ITermOptionsProps) => {
	return (
		<div>
			<Field
				name={`additionalQuestions[${questionIndex}].inputType`}
				component={RadioField}
				button
				size="large"
				options={[
					{
						label: "Insert link",
						value: "url"
					},
					{
						label: "Upload PDF",
						value: "file"
					},
					{
						label: "Enter text",
						value: "text"
					}
				]}
			/>
			{additionalQuestions[questionIndex].inputType === "url" ? (
				<Field
					name={`additionalQuestions[${questionIndex}].settings.link`}
					label="Insert link that will take attendees to Terms & Conditions"
					placeholder="Insert link"
					component={LegacyInputField}
					style={{ maxWidth: 320 }}
				/>
			) : null}
			{additionalQuestions[questionIndex].inputType === "file" ? (
				<Field
					name={`additionalQuestions[${questionIndex}].settings.pdf`}
					label="Upload the PDF version of Terms & Conditions"
					placeholder="Insert link"
					component={PdfUploadField}
					saveHandleOnly
				/>
			) : null}
			{additionalQuestions[questionIndex].inputType === "text" ? (
				<Field
					name={`additionalQuestions[${questionIndex}].settings.text`}
					label="Write Terms & Conditions"
					component={RichTextAreaField}
				/>
			) : null}
		</div>
	);
};

const optionstyles = {
	fieldContainer: css`
		display: inline-block;
		width: 100%;
		max-width: 247px;
		@media (max-width: 576px) {
			max-width: 90%;
		}
	`,
	quantityContainer: css`
		display: inline-block;
		margin-left: 10px;
		width: 100px;
		@media (max-width: 576px) {
			margin-left: 0px;
		}
	`,
	field: css`
		min-width: 100%;
		width: 100%;
		max-width: 246px;
		margin-bottom: 6px;
	`,
	button: css`
		border-color: #8e99a8;
		color: rgb(50, 50, 50);
		@media (max-width: 576px) {
			margin-bottom: 16px;
		}
	`,
	firstRowLabel: css`
		display: inline-block;
	`,
	label: css`
		display: none;
		@media (max-width: 576px) {
			display: inline-block;
		}
	`,
	line: css`
		display: none;
		@media (max-width: 576px) {
			display: block;
		}
	`,
	iconContainer: css`
		display: inline-block;
		@media (max-width: 576px) {
			display: none;
		}
	`
};

interface IOptionsProps {
	fields: FieldArrayFieldsProps<any>;
	options: any[];
	questionIndex: number;
	trackQuantity: boolean;
	question: any;
	alreadySoldTickets?: boolean;
	isNew?: boolean;
	capacityEnabled: boolean;
}

const Options = ({
	fields,
	options,
	questionIndex,
	trackQuantity,
	question,
	alreadySoldTickets,
	isNew,
	capacityEnabled
}: IOptionsProps) => {
	if (fields && fields.length === 0) {
		fields.push({});
	}
	const tooltipMessage = `Limit the total number of times this option can be selected by your customers.`;

	const optionsList = options ? options : [];
	const optionsRows = optionsList.map((_: any, index: number) => {
		const labelClassName = index === 0 ? optionstyles.firstRowLabel : optionstyles.label;

		const fieldContainer = css`
			display: inline-block;
			width: ${index ? "calc(100% - 36px)" : "100%"};
			max-width: 247px;
			@media (max-width: 576px) {
				max-width: 90%;
			}
		`;

		return (
			<div key={index}>
				<div className={fieldContainer}>
					<div className={labelClassName}>
						Option name <RequiredAsterisk />
					</div>
					<Field
						name={`additionalQuestions[${questionIndex}].options[${index}].value`}
						placeholder="Option Name"
						component={LegacyInputField}
						size="small"
						validate={[optionNameRequired]}
						className={optionstyles.field}
					/>
				</div>
				{trackQuantity && (
					<div className={optionstyles.quantityContainer}>
						<div className={labelClassName}>
							Quantity
							<span className={optionstyles.iconContainer}>
								<IconButton icon="info" tooltip={tooltipMessage} showTooltip ariaLabel={tooltipMessage} />
							</span>
						</div>
						<Field
							name={`additionalQuestions[${questionIndex}].options[${index}].quantity`}
							placeholder="Quantity"
							component={InputNumberField}
							size="small"
							validate={[quantityNotNegative]}
							className={optionstyles.field}
						/>
					</div>
				)}
				<div style={{ height: 26, display: "inline-block", position: "relative" }}>
					{index ? (
						<TableActions
							containerStyle={{
								position: "absolute",
								left: 0,
								top: 0,
								paddingTop: 0,
								paddingLeft: 10
							}}
						>
							<IconButton
								icon="delete"
								onClick={() => fields.remove(index)}
								tooltip="Delete"
								showTooltip
								ariaLabel="Delete"
							/>
						</TableActions>
					) : (
						""
					)}
				</div>
				{trackQuantity ? <hr className={optionstyles.line} /> : null}
			</div>
		);
	});

	return (
		<div>
			<InputLabel label="Available Options" description="Limit the quantity of each option" />
			{question.type === "options" || question.type === "multiSelect" ? (
				<Field
					name={`additionalQuestions[${questionIndex}].trackQuantity`}
					type="text"
					component={SwitchField}
					disabled={!isNew && alreadySoldTickets && !capacityEnabled}
				/>
			) : null}
			{optionsRows}
			<LegacyButton
				icon={{ name: "plus", left: true }}
				onClick={() => {
					fields.push({});
				}}
				ariaLabel="Add"
				style={{ marginBottom: 12 }}
			>
				Add
			</LegacyButton>
		</div>
	);
};

export default QuestionOptions;
