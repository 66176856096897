import { css } from "@emotion/css";
import { Checkbox } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";
const CheckboxGroup = Checkbox.Group;

const MultipleCheckboxField = ({
	style,
	input,
	defaultValue = [],
	label,
	description,
	inline,
	required,
	options,
	inputDescriptionStyle,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error },
	bold,
	border = false
}) => {
	const Option = Checkbox;

	const wrapper = css({
		...style
	});

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			inputDescriptionStyle={inputDescriptionStyle}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			bold={bold}
		>
			<CheckboxGroup
				id={input.name.toLowerCase().split(".").join("-")}
				onChange={input.onChange}
				defaultValue={defaultValue}
			>
				<div className={wrapper}>
					{options.map((option) => (
						<Option
							style={
								border
									? {
											border:
												input.value && input.value.find((v) => v === option.value)
													? "solid 1px #353337"
													: "solid 1px #dedede",
											borderRadius: 4,
											padding: 7,
											marginBottom: 8,
											marginRight: 16,
											width: 180,
											height: 40
									  }
									: {}
							}
							key={option.value}
							value={option.value}
							id={`${input.name}-${option.value}`}
						>
							{option.icon ? <img src={option.icon} alt={option.value} style={{ paddingRight: 8 }} /> : null}
							{option.label}
						</Option>
					))}
				</div>
			</CheckboxGroup>
		</InputWrapper>
	);
};

export default MultipleCheckboxField;
