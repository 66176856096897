import { css } from "@emotion/css";
import { Field } from "redux-form";
import { InputNumberField } from "@/components/Fields";
import { H5 } from "@/components/text/Text";
import { currency } from "@/utils/Format";
import { percentFormat, percentParse } from "@/utils/InputFormatters";
import { required } from "@/utils/Validators";

const valueRequired = required("A percent is required");

const splitRows = css({
	display: "flex"
});

const splitOption = css({
	background: "#f9f9fa",
	padding: "12px 12px 0 12px",
	marginRight: 12
});

const fieldRow = css({
	display: "flex"
});

const feeNote = css({
	padding: "8px 8px 0 8px",
	width: 230
});

const SplitOption = ({ path, label, id, feeValue, percentFee, splitValues, changeFieldValue }) => {
	const onChange = (changeField) => (evt, value) => {
		const oppositeValue = 100 - value;
		changeFieldValue(`${path}.splitAmounts.${id}.${changeField}`, oppositeValue);
	};
	const value = feeValue === undefined ? 0 : feeValue;
	const onePercent = value / 100;
	const passOnValue = splitValues.passOn && onePercent ? (splitValues.passOn * onePercent).toFixed(2) : 0;
	const passOnDisplay = percentFee ? `${passOnValue}%` : `${currency(passOnValue)}`;

	const absorbValue = splitValues.absorb && onePercent ? (splitValues.absorb * onePercent).toFixed(2) : 0;

	const absorbDisplay = percentFee ? `${absorbValue}%` : `${currency(absorbValue)}`;

	const feeDisplay = percentFee ? `${value}%` : `${currency(value)}`;

	const paymentFeeNote = id === "payment" ? "If payment fee = 0%, this does not apply to your account" : undefined;

	return (
		<div>
			<div className={splitOption}>
				<H5>
					{label} {feeDisplay}
				</H5>
				<div className={fieldRow}>
					<Field
						name={`${path}.splitAmounts.${id}.passOn`}
						component={InputNumberField}
						label={`Pass on ${passOnDisplay}`}
						style={{ marginRight: 12 }}
						min={0}
						max={100}
						formatter={percentFormat}
						parser={percentParse}
						onChange={onChange("absorb")}
						onBlur={onChange("absorb")}
						validate={valueRequired}
					/>
					<Field
						name={`${path}.splitAmounts.${id}.absorb`}
						component={InputNumberField}
						label={`Absorb ${absorbDisplay}`}
						min={0}
						max={100}
						formatter={percentFormat}
						parser={percentParse}
						onChange={onChange("passOn")}
						onBlur={onChange("passOn")}
						validate={valueRequired}
					/>
				</div>
			</div>
			{paymentFeeNote && <div className={feeNote}>{paymentFeeNote}</div>}
		</div>
	);
};

const SplitOptions = ({ path, feeStructure, changeFieldValue, feeChargeSettings }) => {
	return (
		<div className={splitRows}>
			<SplitOption
				label="Fixed fee"
				id="fixed"
				path={path}
				feeValue={feeStructure.fixed}
				percentFee={false}
				changeFieldValue={changeFieldValue}
				splitValues={feeChargeSettings.splitAmounts.fixed}
			/>
			<SplitOption
				label="Percentage fee"
				id="percent"
				path={path}
				feeValue={feeStructure.percent}
				percentFee
				changeFieldValue={changeFieldValue}
				splitValues={feeChargeSettings.splitAmounts.percent}
			/>
			<SplitOption
				label="Payment Fee"
				id="payment"
				path={path}
				percentFee
				feeValue={feeStructure.paymentFeePercent ? feeStructure.paymentFeePercent : 0}
				changeFieldValue={changeFieldValue}
				splitValues={feeChargeSettings.splitAmounts.payment}
			/>
		</div>
	);
};

export default SplitOptions;
