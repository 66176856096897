import { css } from "@emotion/css";
import { Component } from "react";
import { Link } from "react-router-dom";
import PageHeading from "@/components/PageHeading";
import { H3, P } from "@/components/text/Text";
import { max } from "@/utils/CssUtils";

const cardStyle = {
	wrapper: css({
		background: "var(--surface1)",
		borderRadius: "var(--rounded-md)",
		marginRight: "var(--spacing-xl)",
		marginBottom: "var(--spacing-xl)",
		cursor: "pointer",
		transition: "var(--border-transition)",
		"&:hover": {
			outline: "var(--border1-hover)"
		},
		[max(600)]: {
			width: "100%",
			marginRight: 0
		}
	}),
	body: css({
		width: 270,
		height: 118,
		padding: "var(--spacing-xxl) var(--spacing-xl)",
		img: {
			height: 50,
			marginBottom: "var(--spacing-sm)"
		},
		h3: {
			marginBottom: "var(--spacing-sm)"
		},
		p: { letterSpacing: "-0.33px" }
	})
};

const pageStyle = {
	wrapper: css({
		display: "flex",
		flexWrap: "wrap"
	})
};

const Card = ({ title, description, path }) => {
	return (
		<div className={cardStyle.wrapper}>
			<Link to={path} tabIndex={0} role="button">
				<div className={cardStyle.body}>
					<H3>{title}</H3>
					<P>{description}</P>
				</div>
			</Link>
		</div>
	);
};

const Items = [
	{
		title: "Ticket types",
		description: "Create ticket type templates and reuse them for future events.",
		path: "/console/account/advanced/templates/ticket-type-templates"
	},
	{
		title: "Checkout questions",
		description: "Create checkout questions templates for future events.",
		path: "/console/account/advanced/templates/additional-questions-templates"
	},
	{
		title: "Styling",
		description: "Save styling templates and apply  them to future events.",
		path: "/console/account/advanced/templates/styling-templates"
	}
];

class TemplateLandingPage extends Component {
	render() {
		return (
			<div>
				<PageHeading title="Templates" />
				<P marginBottom={16}>Save time when building future events by creating templates.</P>
				<div className={pageStyle.wrapper}>
					{Items.map((item, index) => (
						<Card {...item} key={`template_card_${index}`} />
					))}
				</div>
			</div>
		);
	}
}

export default TemplateLandingPage;
