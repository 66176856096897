import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2, P } from "@/components/text/Text";

import door from "@/assets/403-image.svg";
import { max } from "@/utils/CssUtils";

const wrapper = css({
	maxWidth: 507,
	margin: "0 auto",
	marginTop: 72,
	display: "flex",
	justifyContent: "space-between",
	div: {
		display: "block",
		maxWidth: 287,
		h2: {
			fontSize: 20,
			fontWeight: 600,
			marginBottom: 8
		},
		p: {
			marginBottom: 24
		},
		img: {
			marginBottom: 32
		}
	},
	[max(600)]: {
		maxWidth: 292,
		margin: "0 auto",
		marginTop: 40,
		img: {
			display: "none"
		},
		div: {
			maxWidth: 292,
			margin: "0 auto",
			img: {
				display: "block"
			}
		}
	}
});

const NotAllowed = () => (
	<div className={wrapper}>
		<img src={door} alt="Locked door with no entry sign" />
		<div>
			<H2>Forbidden ( 403 )</H2>
			<P>You don’t have permission to access this page.</P>
			<Link to="/console/dashboard">
				<LegacyButton ariaLabel="Home" type="primary">
					Home
				</LegacyButton>
			</Link>
		</div>
	</div>
);

export default NotAllowed;
