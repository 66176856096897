import {
	CLEAR_USER,
	CLOSE_FEE_APPLIED_MODAL,
	GET_USER_ERROR,
	GET_USER_FEE_STRUCTURE_ERROR,
	GET_USER_FEE_STRUCTURE_REQUEST,
	GET_USER_FEE_STRUCTURE_SUCCESS,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	HUBSPOT_DISCONNECT_ERROR,
	HUBSPOT_DISCONNECT_REQUEST,
	HUBSPOT_DISCONNECT_SUCCESS,
	HUBSPOT_SETTINGS_ERROR,
	HUBSPOT_SETTINGS_REQUEST,
	HUBSPOT_SETTINGS_SUCCESS,
	OPEN_FEE_APPLIED_MODAL,
	PUT_API_KEY_ERROR,
	PUT_API_KEY_REQUEST,
	PUT_API_KEY_SUCCESS,
	REQUEST_OWNER_ERROR,
	REQUEST_OWNER_REQUEST,
	REQUEST_OWNER_SUCCESS,
	SAVE_USER_CANCEL,
	SAVE_USER_ERROR,
	SAVE_USER_REQUEST,
	SAVE_USER_SUCCESS,
	TRANSFER_ACCOUNT_CLOSE,
	TRANSFER_ACCOUNT_ERROR,
	TRANSFER_ACCOUNT_OPEN,
	TRANSFER_ACCOUNT_REQUEST,
	TRANSFER_ACCOUNT_SUCCESS,
	WARNING_MODAL_CLOSE,
	WARNING_MODAL_OPEN
} from "../actions/userActions";

type TransferUserAccountResult = {
	success: true;
	fromUserEmail: string;
	toUserEmail: string;
};

type DeleteBankAccountConfirmationRequest = {
	allowToDelete: boolean;
	allowToConfirm: boolean;
	message: string;
	eventNames: string[];
	bankAccountId: string;
};

type MoreUserKeys = any;

type User = {
	_id: string;
} & MoreUserKeys;

export type UserReducerState = {
	user?: User | null;
	loading: boolean;
	error: boolean;
	saved: boolean;
	showFeeAppliedModal: boolean;
	deprecatedApiKey: {
		key: any | null;
		loading: boolean;
		error: boolean;
	};
	apiKey: {
		loading: boolean;
		error: boolean;
	};
	transfer: {
		open: boolean;
		loading: boolean;
		error: boolean;
		result?: TransferUserAccountResult | null;
	};
	owner: {
		loading: boolean;
		error: boolean;
	};
	feeStructure: {
		loading: boolean;
		data: any | null;
		error: boolean;
	};
	warningModalOpen: boolean;
	confirmationRequest: {
		message: any | null;
		eventNames?: string[] | null;
		bankAccountId: any | null;
	};
};

const initialState: UserReducerState = {
	user: null,
	loading: false,
	error: false,
	saved: false,
	showFeeAppliedModal: false,
	deprecatedApiKey: {
		key: null,
		loading: false,
		error: false
	},
	apiKey: {
		loading: false,
		error: false
	},
	transfer: {
		open: false,
		loading: false,
		error: false,
		result: null
	},
	owner: {
		loading: false,
		error: false
	},
	feeStructure: {
		loading: false,
		data: null,
		error: false
	},
	warningModalOpen: false,
	confirmationRequest: {
		message: null,
		eventNames: null,
		bankAccountId: null
	}
};

type UserReducerAction = {
	type:
		| typeof TRANSFER_ACCOUNT_OPEN
		| typeof TRANSFER_ACCOUNT_CLOSE
		| typeof TRANSFER_ACCOUNT_REQUEST
		| typeof TRANSFER_ACCOUNT_SUCCESS
		| typeof TRANSFER_ACCOUNT_ERROR
		| typeof GET_USER_FEE_STRUCTURE_REQUEST
		| typeof GET_USER_FEE_STRUCTURE_SUCCESS
		| typeof GET_USER_FEE_STRUCTURE_ERROR
		| typeof GET_USER_REQUEST
		| typeof GET_USER_SUCCESS
		| typeof GET_USER_ERROR
		| typeof SAVE_USER_REQUEST
		| typeof SAVE_USER_CANCEL
		| typeof SAVE_USER_SUCCESS
		| typeof SAVE_USER_ERROR
		| typeof HUBSPOT_DISCONNECT_REQUEST
		| typeof HUBSPOT_DISCONNECT_SUCCESS
		| typeof HUBSPOT_DISCONNECT_ERROR
		| typeof HUBSPOT_SETTINGS_REQUEST
		| typeof HUBSPOT_SETTINGS_SUCCESS
		| typeof HUBSPOT_SETTINGS_ERROR
		| typeof CLEAR_USER
		| typeof PUT_API_KEY_REQUEST
		| typeof PUT_API_KEY_SUCCESS
		| typeof PUT_API_KEY_ERROR
		| typeof REQUEST_OWNER_REQUEST
		| typeof REQUEST_OWNER_SUCCESS
		| typeof REQUEST_OWNER_ERROR
		| typeof WARNING_MODAL_OPEN
		| typeof WARNING_MODAL_CLOSE
		| typeof OPEN_FEE_APPLIED_MODAL
		| typeof CLOSE_FEE_APPLIED_MODAL;
	error?: Error | string;
	user?: Partial<User> | null;
	result?: TransferUserAccountResult | null;
	feeStructure?: any;
	owner?: any;
	confirmationRequest?: DeleteBankAccountConfirmationRequest | null;
};

export default (state = initialState, action: UserReducerAction) => {
	switch (action.type) {
		case TRANSFER_ACCOUNT_OPEN:
			return {
				...state,
				transfer: {
					open: true,
					loading: false,
					error: false,
					result: null
				}
			};
		case TRANSFER_ACCOUNT_CLOSE:
			return {
				...state,
				transfer: {
					open: false,
					loading: false,
					error: false,
					result: null
				}
			};
		case TRANSFER_ACCOUNT_REQUEST:
			return {
				...state,
				transfer: {
					...state.transfer,
					loading: true,
					error: false,
					result: null
				}
			};
		case TRANSFER_ACCOUNT_SUCCESS:
			return {
				...state,
				transfer: {
					...state.transfer,
					loading: false,
					error: false,
					result: action.result
				}
			};
		case TRANSFER_ACCOUNT_ERROR:
			return {
				...state,
				transfer: {
					...state.transfer,
					loading: false,
					error: true,
					result: null
				}
			};

		case GET_USER_FEE_STRUCTURE_REQUEST:
			return {
				...state,
				feeStructure: {
					data: false,
					loading: true,
					error: false
				}
			};
		case GET_USER_FEE_STRUCTURE_SUCCESS:
			return {
				...state,
				feeStructure: {
					data: action.feeStructure, //
					loading: false,
					error: false
				}
			};

		case GET_USER_FEE_STRUCTURE_ERROR:
			return {
				...state,
				feeStructure: {
					data: false,
					loading: false,
					error: action.error
				}
			};
		case GET_USER_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				saved: false
			};
		case GET_USER_SUCCESS:
			return {
				...state,
				user: action.user,
				loading: false,
				error: false
			};
		case GET_USER_ERROR:
			return {
				...state,
				user: false,
				loading: false,
				error: action.error
			};

		case SAVE_USER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_USER_CANCEL:
			return {
				...state,
				loading: false,
				error: false
			};
		case SAVE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.user,
				error: false,
				saved: true,
				warningModalOpen: false,
				confirmationRequest: initialState.confirmationRequest
			};
		case SAVE_USER_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case HUBSPOT_DISCONNECT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case HUBSPOT_DISCONNECT_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case HUBSPOT_DISCONNECT_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case HUBSPOT_SETTINGS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case HUBSPOT_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case HUBSPOT_SETTINGS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case CLEAR_USER:
			return initialState;

		case PUT_API_KEY_REQUEST:
			return {
				...state,
				apiKey: {
					loading: true,
					error: false
				}
			};
		case PUT_API_KEY_SUCCESS:
			return {
				...state,
				apiKey: {
					loading: false,
					error: false
				}
			};
		case PUT_API_KEY_ERROR:
			return {
				...state,
				apiKey: {
					loading: false,
					error: action.error
				}
			};

		case REQUEST_OWNER_REQUEST:
			return {
				...state,
				owner: {
					...state.owner,
					loading: true,
					error: false
				}
			};
		case REQUEST_OWNER_SUCCESS:
			return {
				...state,
				owner: {
					...state.owner,
					loading: false,
					error: false,
					[action.owner.id]: action.owner
				}
			};
		case REQUEST_OWNER_ERROR:
			return {
				...state,
				owner: {
					...state.owner,
					loading: false,
					error: true
				}
			};
		case WARNING_MODAL_OPEN:
			return {
				...state,
				warningModalOpen: true,
				confirmationRequest: action.confirmationRequest,
				loading: false
			};
		case WARNING_MODAL_CLOSE:
			return {
				...state,
				warningModalOpen: false,
				confirmationRequest: initialState.confirmationRequest
			};
		case OPEN_FEE_APPLIED_MODAL:
			return {
				...state,
				showFeeAppliedModal: true
			};
		case CLOSE_FEE_APPLIED_MODAL:
			return {
				...state,
				showFeeAppliedModal: false
			};
		default:
			return state;
	}
};
