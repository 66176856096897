const stripUrl = (url: string) => {
	return url?.replace("https://", "").replace("http://", "").replace("www.", "");
};

const serializeSocialProfileUrl = (domain: string, url?: string) => {
	const handle = findSocialProfileHandle(domain, url);

	if (!handle) return null;
	return `${domain}${handle}`;
};

/**
 * This is a utility function to remove the domain parts of a social profile url. e.g:
 *  - https://wwww.tiktok.com/@hx -> @hx
 *  - https://wwww.spotify.com/track/4cOdK2wGLETKBW3PvgPWqT?si=b1874a3f50514425 -> track/4cOdK2wGLETKBW3PvgPWqT?si=b1874a3f50514425
 *
 * It also handles removing the https/https/www sections of the provided url and domain
 */
const findSocialProfileHandle = (domain: string, url?: string) => {
	if (!url) return null;

	const cleanedUrl = stripUrl(url);
	const cleanedDomain = stripUrl(domain);

	// remove the cleaned characters
	return cleanedUrl.replace(cleanedDomain, "");
};

export { findSocialProfileHandle, serializeSocialProfileUrl, stripUrl };
