import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import styled from "@emotion/styled";
import { ViewTourButton } from "./TourViewButton";

const Wrapper = styled.div`
	min-height: 98px;
	height: 100%;
	padding: 0px 20px;
	background: #fff;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	@media (max-width: 600px) {
		display: block;
		padding: 10px 12px;
		margin-bottom: 16px;
	}
`;

const Title = styled.div`
	width: 50%;
	font-size: 18px;
	color: #323232;
	font-weight: 600;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (max-width: 600px) {
		font-size: 16px;
		width: 100%;
	}
`;

const ActionWrapper = styled.div`
	width: 50%;
	@media (max-width: 600px) {
		width: 100%;
	}
`;

const ActionContainer = styled.div`
	align-items: flex-end;
	p {
		text-align: right;
		font-weight: 600;
		max-width: 100%;
	}
`;

const ActionButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
`;

type TourHeaderProps = {
	tourSlug: string;
	tourName: string;
	publishOrUnpublish: () => void;
	publishLoading: boolean;
	published: boolean;
};

export const TourHeader = ({ tourSlug, tourName, publishOrUnpublish, publishLoading, published }: TourHeaderProps) => {
	const publishButtonLabel = published ? "Unpublish" : "Publish";
	const publishDescription = published ? "This tour is live" : "This tour is a draft";

	return (
		<Wrapper>
			<Title>{tourName}</Title>
			<ActionWrapper>
				<ActionContainer>
					<P desktopMarginBottom={7} mobileMarginBottom={4} style={{ color: "#5a626c" }}>
						{publishDescription}
					</P>

					<ActionButtonWrapper>
						{published && <ViewTourButton slug={tourSlug} />}

						<LegacyButton
							style={{
								background: "#157e77",
								color: "#fff",
								display: "inline-block"
							}}
							onClick={publishOrUnpublish}
							htmlType="button"
							disabled={publishLoading}
							ariaLabel={publishButtonLabel}
							type="launch"
						>
							{publishButtonLabel}
						</LegacyButton>
					</ActionButtonWrapper>
				</ActionContainer>
			</ActionWrapper>
		</Wrapper>
	);
};
