import { ReactNode } from "react";

interface ISideMenuBgProps {
	children: ReactNode;
}

// TODO This component needs to go but its a bit of a cancer. Enevitably, it will be removed with the new navbar work in the next month.
const SideMenuBg = ({ children }: ISideMenuBgProps) => {
	return (
		<div
			style={{
				background: "white",
				height: "100%"
			}}
		>
			{children}
		</div>
	);
};

export default SideMenuBg;
