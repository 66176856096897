import { css } from "@emotion/css";
import { Component } from "react";
import ResponsiveTable from "@/components/table/ResponsiveTable";

/**
 * Display ticket oder table
 *
 * @class TicketOrderTable
 * @extends {Component}
 */
class TicketOrderTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedRowKeys: []
		};
	}

	render() {
		let { ticketOrder, currentEvent, touch, dispatch, formName, fieldName } = this.props;
		const { expandedRowKeys } = this.state;
		const newExpandedRowKeys = expandedRowKeys.concat([]);
		let ticketOrderKeyed = ticketOrder.map((ticket) => {
			if (ticket._id) {
				ticket.rowKey = ticket._id;
				return ticket;
			}
			if (ticket.deleted) {
				return ticket;
			}
			newExpandedRowKeys.push(ticket.rowKey);
			return ticket;
		});

		const hideRow = css`
			display: none;
		`;

		const options = {
			currentEvent,
			touch,
			dispatch
		};
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (_, data) => <span>{data.name}</span>
			},
			{
				title: "Ticket Order Type",
				dataIndex: "ticketOrderType",
				key: "ticketOrderType",
				render: (_, data) => <span>{data.ticketOrderType}</span>
			},
			{
				title: "Order",
				dataIndex: "",
				key: "x",
				render: (_, __, index) => <span>{index + 1}</span>
			}
		];
		return (
			<div
				className={css({
					"tr.ant-table-expanded-row": {
						background: "#fff"
					}
				})}
			>
				<ResponsiveTable
					pagination={false}
					draggable={{ dispatch, formName, fieldName }}
					columns={columns}
					dataSource={ticketOrderKeyed}
					rowKey="key"
					rowClassName={(record) => {
						return record.deleted ? hideRow : "";
					}}
					expandedRowKeys={newExpandedRowKeys}
					expandIconColumnIndex={99}
					expandIconAsCell={false}
					options={options}
				/>
			</div>
		);
	}
}

export default TicketOrderTable;
