import { Component } from "react";
import { Field, FieldArray } from "redux-form";
import {
	CheckboxField,
	ImageUploadField,
	LegacyInputField,
	LocationField,
	MultipleSelectField,
	NoteField,
	SelectFieldWithOption,
	TermsAndConditionsField,
	TextAreaField
} from "@/components/Fields";
import { required } from "@/utils/Validators.js";
import { addQuestionQuantityCheck } from "./AdditionalQuestionQuantityValidator";
import { checkCondition, checkOrderQuestionCondition } from "./CheckCondition";

/**
 * Display form field based on conditions
 *
 * @param {object, string, object, object, objectId, boolean, function} { question, formName, orderAdditionalQuestions, ticketsAdditionalQuestions, ticketId, disabled, changeFieldValue }
 * @returns redux form
 */
const ConditionalQuestion = ({
	question,
	orderAdditionalQuestions,
	ticketsAdditionalQuestions,
	ticketId,
	disabled,
	validate,
	changeFieldValue
}) => {
	const trackQuantity = question.trackQuantity ? question.trackQuantity : false;
	let RenderField;
	let fieldStyle = {};
	switch (question.type) {
		case "multiSelect":
			RenderField = MultipleSelectField;
			fieldStyle = { minWidth: "268px" };
			break;
		case "options":
			RenderField = SelectFieldWithOption;
			break;
		case "textarea":
			RenderField = TextAreaField;
			break;
		case "checkbox":
			RenderField = CheckboxField;
			break;
		case "image":
			RenderField = ImageUploadField;
			break;
		case "terms":
			RenderField = TermsAndConditionsField;
			break;
		case "note":
			RenderField = NoteField;
			break;
		case "address":
			RenderField = LocationField;
			break;
		default:
			RenderField = LegacyInputField;
			break;
	}
	const defaultValue = question.defaultValue || "";
	let conditionalQuestion;

	if (question.type === "address") {
		conditionalQuestion = (
			<FieldArray
				label={question.question}
				type={question.type}
				questionName={question.name}
				name={`${question.name}-${question.type}`}
				inputType={question.inputType}
				trackQuantity={trackQuantity}
				component={RenderField}
				saveHandleOnly
				defaultValue={defaultValue}
				disabled={disabled}
				options={question.options}
				settings={question.settings}
				required={question.required}
				changeFieldValue={changeFieldValue}
				placeholder=""
				addQuestionQuantityCheck={addQuestionQuantityCheck(question)}
				forceShowManualFields
			/>
		);
	} else {
		conditionalQuestion = (
			<Field
				label={question.question}
				type={question.type}
				name={question.name}
				inputType={question.inputType}
				trackQuantity={trackQuantity}
				component={RenderField}
				saveHandleOnly
				defaultValue={defaultValue}
				disabled={disabled}
				options={question.options}
				settings={question.settings}
				required={question.required}
				placeholder=""
				validate={validate}
				addQuestionQuantityCheck={addQuestionQuantityCheck(question)}
				style={fieldStyle}
			/>
		);
	}
	if (
		checkOrderQuestionCondition(question, orderAdditionalQuestions) ||
		(ticketId !== "" && checkCondition(question, ticketsAdditionalQuestions, ticketId))
	) {
		return conditionalQuestion;
	}
	return "";
};

/**
 * Display additional questions for order and tickets
 *
 * @class AdditionalQuestion
 * @extends {Component}
 */
class AdditionalQuestion extends Component {
	validate = (value) => {
		const { question } = this.props;
		return question.required ? required(`${question.question} is required`)(value) : undefined;
	};
	render() {
		const {
			question,
			ticketAdditionalFields = [],
			ticketId = "",
			ticketsAdditionalQuestions = {},
			orderAdditionalQuestions = {},
			orderAdditionalFields = [],
			formName = "details",
			disabled = false,
			noDefault = false,
			changeFieldValue
		} = this.props;

		ticketAdditionalFields.length > 0 &&
			ticketId !== "" &&
			ticketAdditionalFields.forEach((field) => {
				ticketsAdditionalQuestions[ticketId] = ticketsAdditionalQuestions[ticketId] || {};
				ticketsAdditionalQuestions[ticketId][field.questionId] =
					ticketsAdditionalQuestions[ticketId][field.questionId] || field.value;
			});
		orderAdditionalFields.length > 0 &&
			orderAdditionalFields.forEach((field) => {
				if (field && field.questionId)
					orderAdditionalQuestions[field.questionId] = orderAdditionalQuestions[field.questionId] || field.value;
			});
		return (
			<ConditionalQuestion
				noDefault={noDefault}
				question={question}
				formName={formName}
				ticketsAdditionalQuestions={ticketsAdditionalQuestions}
				ticketId={ticketId}
				orderAdditionalQuestions={orderAdditionalQuestions}
				disabled={disabled}
				validate={this.validate}
				changeFieldValue={changeFieldValue}
			/>
		);
	}
}

export default AdditionalQuestion;
