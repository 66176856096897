import axios from "./AxiosWrapper";

const getHeaders = (location) => {
	if (location) {
		return {
			headers: {
				"x-override-location": location
			}
		};
	}
	return {};
};

class MerchandiseService {
	static instance = this.getInstance();

	static createInstance() {
		let object = new MerchandiseService();
		return object;
	}

	static getInstance() {
		if (!MerchandiseService.instance) {
			MerchandiseService.instance = MerchandiseService.createInstance();
		}
		return MerchandiseService.instance;
	}

	async find(userId, location) {
		const response = await axios.get(`merchandise/${userId}`, getHeaders(location));
		return response.data;
	}

	async create(userId, data) {
		const response = await axios.post(`merchandise/${userId}`, data);
		return response.data;
	}

	async update(userId, merchId, data, location) {
		const response = await axios.post(`merchandise/${userId}/${merchId}`, data, getHeaders(location));
		return response.data;
	}

	async get(userId, merchId, location) {
		const response = await axios.get(`merchandise/${userId}/${merchId}`, getHeaders(location));
		return response.data;
	}
	async getReport(userId, eventIds, eventDateId, query, page, location) {
		const params = {
			params: {
				eventIds,
				eventDateId,
				query,
				page
			},
			headers: {
				"x-override-location": location
			}
		};
		const response = await axios.get(`merchandise/${userId}/report/local`, params);
		return response.data;
	}
	async getReportCsv(userId, eventIds, eventDateId, query, location) {
		const params = {
			responseType: "blob",
			params: { eventIds, eventDateId, query },
			headers: {
				"x-override-location": location
			}
		};
		const response = await axios.get(`merchandise/${userId}/report/csv`, params);
		return response.data;
	}
	async saveMerchOrder(userId, data) {
		const response = await axios.post(`merchandise/${userId}/save-order`, data);
		return response.data;
	}

	async getAddOnListing(userId, orderId) {
		const response = await axios.get(`merchandise/checkout/list/${userId}/${orderId}`);
		return response.data;
	}

	async addAddOnsToManualOrder(userId, orderId, data) {
		const response = await axios.post(`merchandise/add-manual-order/${userId}/${orderId}`, data);
		return response.data;
	}
}

export default MerchandiseService.getInstance();
