import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import type { PreviewMassRefundResponse } from "@hx/console/massRefunds";
import { notification } from "@/ui/antd";
import { FC, useState } from "react";
import { ExistingMassRefund } from "./ExistingMassRefund";
import { MassRefundSteps } from "./MassRefundSteps";

export const RefundTab: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const [massRefundData, setMassRefundData] = useState<PreviewMassRefundResponse | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const eventId = currentEvent.event?._id ?? "";

	trpc.massRefunds.getExistingAsyncMassRefundEstimation.useQuery(eventId, {
		enabled: !!eventId,
		onError: (error) => {
			notification.error({
				description: error.message ?? "An error occurred while fetching mass refund data.",
				message: "Error"
			});
		},
		onSuccess(data) {
			if (data?.massRefund) {
				setMassRefundData(data.massRefund);
			}
		}
	});

	const { data } = trpc.massRefunds.listEventMassRefunds.useQuery(
		{ eventId, statusFilter: "all" },
		{
			enabled: !!eventId,
			onError: (error) => {
				notification.error({
					description: error.message ?? "An error occurred while fetching mass refunds.",
					message: "Error"
				});
			}
		}
	);

	const { massRefunds = [] } = data ?? {};
	const existingMassRefundPending = massRefunds.find((refund) => refund.status === "pending");
	const hasExistingPreview = !!massRefundData;

	return existingMassRefundPending ? (
		<ExistingMassRefund existingMassRefund={existingMassRefundPending} />
	) : (
		<MassRefundSteps
			isLoading={isLoading}
			massRefundData={massRefundData}
			setIsLoading={setIsLoading}
			setMassRefundData={setMassRefundData}
			skipToReviewEstimation={existingMassRefundPending || hasExistingPreview}
		/>
	);
};
