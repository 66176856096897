import { Collapse } from "@/ui/antd";
import { Field } from "redux-form";
import { SwitchField } from "../Fields";
const Panel = Collapse.Panel;

const SendTicketField = (props) => {
	const { path, currentEvent = {}, showSendTicketPerTicket } = props;
	const ticketTypes = currentEvent.event && currentEvent.event.ticketTypes ? currentEvent.event.ticketTypes : [];

	return (
		<div>
			<Field
				name={`${path}.sendTickets`}
				label="Send Tickets"
				description="Ticket is automatically generated and sent to the customers."
				component={SwitchField}
			/>
			{showSendTicketPerTicket && (
				<Collapse defaultActiveKey={[]}>
					<Panel header="Set per ticket type" key="1">
						<table style={{ width: "100%" }}>
							<tbody>
								{ticketTypes.map((ticketType, index) => {
									if (ticketType.deleted) return null;
									return (
										<tr key={index}>
											<th>{ticketType.name}</th>
											<td style={{ width: 62 }}>
												<Field name={`ticketTypes[${index}].sendTicket`} component={SwitchField} />
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Panel>
				</Collapse>
			)}
		</div>
	);
};

export default SendTicketField;
