import { HumaniDate } from "@hx/dates";
import { Menu, Table } from "@/ui/antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "@/components/AppBlocks";
import LocationSelection from "@/components/LocationSelection";
import Page from "@/components/Page";
import SearchRefinements from "@/components/SearchRefinements";
import P from "@/components/text/P";
import LocationService from "@/services/LocationService";
import { getUnsubscribers } from "@/state/actions/emailCampaignsActions";
import { getUserEvents } from "@/state/actions/eventActions";
import { updateSearch } from "@/state/actions/searchActions";
import { HelpIds } from "@/utils/Help";
import BannerMailchimp from "./BannerMailchimp";
import SimplePagination from "@/components/SimplePagination";

const defaultSearch = {
	query: "",
	page: 1
};

const UnsubscriberList = ({
	getUnsubscribers,
	currentUser,
	unsubscriptions,
	updateSearch,
	getUserEvents,
	search,
	userEvents
}) => {
	const { data, loading } = unsubscriptions;
	useEffect(() => {
		const userId = currentUser.user.id;
		getUserEvents(userId, "name _id");
	}, []);

	const load = (page = 1) => {
		const userId = currentUser.user.id;
		getUnsubscribers(userId, page, search.query, search.eventIds);
	};

	const loadPage = (page) => {
		updateSearch({ page }, true);
	};

	const onLocationChange = (loc) => {
		updateSearch({ loc }, true);
	};

	let columns = [
		{
			title: "Email",
			dataIndex: "email",
			key: "email"
		},
		{
			title: "Event",
			dataIndex: "eventName",
			key: "eventName",
			render: (value, row) => (
				<Link style={{ color: "#196097" }} to={`/console/my-events/${row.eventId}`}>
					{value}
				</Link>
			)
		},
		{
			title: "Unsubscribed at",
			dataIndex: "unsubscribeAt",
			key: "unsubscribeAt",
			render: (unsubscribeAt) =>
				new HumaniDate(unsubscribeAt, "guess", LocationService.getLocation(true)).formatting.dateTime()
		}
	];

	const messages = loading ? { emptyText: "Loading" } : { emptyText: "No unsubscriptions" };

	const eventOptions = (userEvents.events || []).map((event) => {
		return { value: event._id, name: event.name };
	});

	const sibling = (
		<LocationSelection componentName="email-campaigns" defaultLevel="user" onLocationChange={onLocationChange} />
	);
	return (
		<Page
			pageName="Email campaigns"
			sibling={sibling}
			help={{
				link: HelpIds.emailCampaigns,
				title: "Help - Email campaigns"
			}}
		>
			<BannerMailchimp />
			<ContentBlock>
				<Menu
					defaultSelectedKeys={["unsubscriber-list"]}
					items={[
						{
							key: "email-campaigns",
							label: (
								<>
									<span>Campaigns</span>
									<Link to="/console/comms/email-campaigns" />
								</>
							)
						},
						{
							key: "unsubscriber-list",
							label: (
								<>
									<span>Unsubscriber list</span>
									<Link to="/console/comms/email-campaigns-unsubscriptions" />
								</>
							)
						}
					]}
					mode="horizontal"
					style={{ marginBottom: 12 }}
				/>
				<P>
					Attendees/buyers who have chosen to unsubscribe from receiving your event communications will no longer
					receive emails sent through email campaigns for the event.
				</P>
				<SearchRefinements
					load={load}
					defaultSearch={defaultSearch}
					showRefinements
					dropdownId="events"
					filterOptions={[
						{
							key: "eventIds",
							label: "Events",
							mode: "multiple",
							kind: "select",
							placeholder: "All events",
							options: eventOptions
						}
					]}
				/>

				<Table columns={columns} dataSource={data} rowKey="key" locale={messages} pagination={false} />
				<SimplePagination pageAction={loadPage} currentPage={Number(search.page || 1)} />
			</ContentBlock>
		</Page>
	);
};

export default connect(
	(state) => ({
		currentUser: state.auth,
		userEvents: state.userEvents,
		unsubscriptions: state.emailCampaigns.unsubscriptions,
		search: state.search
	}),
	{
		getUnsubscribers,
		updateSearch,
		getUserEvents
	}
)(UnsubscriberList);
