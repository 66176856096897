import { Link } from "react-router-dom";

const USTaxInfoWarning = ({ isOwner }) => {
	const callToAction = isOwner ? (
		<>
			To receive your next payout, please fill out your W8/9 form (only takes 3 minutes){" "}
			<Link to="/console/account/profile/bank-accounts">Tax & Bank Details.</Link>
		</>
	) : (
		<>The owner of the event needs to fill out the US taxpayer info under their account settings</>
	);
	return (
		<div>
			<b>W8/9 form required.</b> {callToAction}
		</div>
	);
};

const gatewaysToDisableWarningFor = ["stripe", "pin"];

export const getUSTaxInfoWarning = (event, currentUser) => {
	if (
		event.location !== "US" ||
		event.isFree ||
		gatewaysToDisableWarningFor.includes(event?.paymentOptions?.paymentId?.toLowerCase())
	)
		return null;
	if (event.userHasTaxInfo) return null;

	return <USTaxInfoWarning isOwner={event.userId === currentUser.id} />;
};
