import { LoadingOutlined } from "@ant-design/icons";
import { Col, DatePicker, Row, Select, Spin } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import circleIcon from "../../assets/charts/ic_circle.svg";
import rectangularIcon from "../../assets/charts/ic_rectangular.svg";
import triangleIcon from "../../assets/charts/ic_triangle.svg";
import triangleIconPurple from "../../assets/charts/ic_triangle_purple.svg";
import LoadErrorView from "../../components/LoadErrorView";
import Widget from "./Widget";
const RangePicker = DatePicker.RangePicker;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const CustomizedDot = (props) => {
	const { cx, cy, fill, dataKey } = props ?? {};
	let stroke = props.stroke === "#fff" ? fill : props.stroke;
	let path;
	let x = cx - 9,
		y = cy - 8;
	if (dataKey === "cancellations" || dataKey === "refunds") {
		path = <circle cx={80} cy={80} r={80} />;
		x += 4;
		y += 4;
	} else if (dataKey === "orders" || dataKey === "grossSales") {
		path = <rect width={150} height={150} />;
		x += 4;
		y += 4;
	} else if (dataKey === "tickets" || dataKey === "netSales" || dataKey === "resales") {
		path = <path d="M150 0 L65 200 L235 200 Z" />;
	} else {
		path = <circle cx={80} cy={80} r={80} />;
		x += 4;
		y += 4;
	}

	return (
		<svg x={x} y={y} width={60} height={60} fill={stroke} viewBox="0 0 1024 1024">
			{path}
		</svg>
	);
};

const ChartLegend = (props) => {
	const { data, prefix } = props ?? {};
	const styles = {
		row: {
			background: "#f9f9fa",
			minHeight: 75,
			padding: 10
		},
		label: {
			fontSize: 14,
			color: "#323232"
		},
		value: {
			fontSize: 18,
			color: "#323232"
		}
	};
	const chart =
		data &&
		data.totals &&
		data.totals.map(({ label, value }) => {
			let icon;
			if (label === "Cancellations" || label === "Refunds") {
				icon = <img src={circleIcon} alt="circle" />;
			} else if (label === "Orders" || label === "Gross Sales") {
				icon = <img src={rectangularIcon} alt="rectangular" />;
			} else if (label === "Tickets" || label === "Net Sales") {
				icon = <img src={triangleIcon} alt="triangle" />;
			} else if (label === "Resales") {
				icon = <img src={triangleIconPurple} alt="triangle" />;
			}
			const paddingLeft = icon ? 35 : 0;
			return (
				<Col key={label}>
					<div style={styles.label}>
						{icon} {`${label}`}
					</div>
					<div style={{ ...styles.value, ...{ paddingLeft } }}>{`${prefix ? prefix : ""}${value}`}</div>
				</Col>
			);
		});
	return (
		<Row justify="space-around" type="flex" style={styles.row}>
			{chart}
		</Row>
	);
};

const BuildChart = (props) => {
	const { data, newLines } = props ?? {};

	return (
		<ResponsiveContainer width="100%" height={200}>
			<LineChart data={data?.data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
				<XAxis dataKey="date" />
				<YAxis allowDecimals={false} />
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip />
				{newLines}
			</LineChart>
		</ResponsiveContainer>
	);
};

class SalesChart extends Component {
	constructor(props) {
		super(props);
		const { lines } = this.props ?? {};
		const state = {};
		lines?.forEach((line) => {
			state[line.key] = true;
		});
		this.state = state;
		this.onChange = this.onChange.bind(this);
	}

	onChange(value) {
		const state = {};
		const { lines } = this.props;
		lines?.forEach((line) => {
			state[line.key] = value.indexOf(line.key) > -1;
		});
		this.setState(state);
	}

	handleValueChange(date) {
		const { onChange } = this.props;
		if (date === "customDate") {
			this.setState({ isCustomRangePicked: true });
		} else {
			this.setState({ isCustomRangePicked: false });
		}
		onChange(date);
	}

	render() {
		const { style, metrics, metricState, title, lines, onChange, prefix } = this.props ?? {};
		const { loading, data } = metrics ?? {};
		let newLines = lines?.map((line, i) => {
			return (
				this.state[line.key] && (
					<Line
						key={i}
						name={line.label}
						type="monotone"
						dataKey={line.key}
						stroke={line.stroke}
						dot={<CustomizedDot />}
						activeDot={<CustomizedDot />}
						strokeWidth={2}
					/>
				)
			);
		});
		return (
			<>
				<LoadErrorView loading={loading}>
					<Widget title={title} style={style} metricState={metricState}>
						<div>
							<Select
								size="default"
								style={{ fontSize: 14, width: 140, marginBottom: 8, marginRight: 10 }}
								defaultValue="today"
								onChange={(value) => this.handleValueChange(value)}
								options={[
									{ label: "Today", value: "today" },
									{ label: "Yesterday", value: "yesterday" },
									{ label: "Last 7 days", value: "week" },
									{ label: "Last 30 days", value: "month" },
									{ label: "Custom Date", value: "customDate" }
								]}
							/>
							{this.state.isCustomRangePicked ? <RangePicker onChange={onChange} style={{ paddingLeft: 30 }} /> : null}
							{loading ? (
								<Spin indicator={antIcon} style={{ alignContent: "center" }} />
							) : (
								<Row>
									<Col span={24}>
										<BuildChart data={data} newLines={newLines} state={this.state} />
									</Col>
									<Col span={24} style={{ marginTop: 24 }}>
										<ChartLegend data={data} prefix={prefix} />
									</Col>
								</Row>
							)}
						</div>
					</Widget>
				</LoadErrorView>
			</>
		);
	}
}

export default connect((state, ownProps) => ({
	metrics: state[ownProps.metricState][ownProps.data],
	timeScale: state[ownProps.metricState].timeScale
}))(SalesChart);
