import { Button, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import { DateField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getGiftCardsHeldReport,
	getGiftCardsHeldReportCsv,
	resetGiftCardsHeldReport
} from "@/state/actions/adminReports/financeActions";
import { currency } from "@/utils/Format";
import DayEndsAt from "../DayEndsAt";

class GiftCardsHeld extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values, page) {
		if (!values.heldAt) {
			return;
		}
		const { getGiftCardsHeldReport } = this.props;
		getGiftCardsHeldReport(values.heldAt, values.dayEndsAt, page);
	}
	retry() {
		const { resetGiftCardsHeldReport } = this.props;
		resetGiftCardsHeldReport();
	}

	downloadCsv() {
		const { heldAt, getGiftCardsHeldReportCsv, dayEndsAt } = this.props;
		getGiftCardsHeldReportCsv(heldAt, dayEndsAt);
	}

	runLoad = () => {
		const { heldAt, dayEndsAt } = this.props;
		this.load({ heldAt, dayEndsAt });
	};

	render() {
		const { handleSubmit, giftCardsHeldReport, giftCardsHeldCvsReport, heldAt, dayEndsAt } = this.props;
		let columns = [
			{
				title: "Order name",
				dataIndex: "orderName",
				key: "orderName"
			},
			{
				title: "Code",
				dataIndex: "giftCardCode",
				key: "giftCardCode"
			},

			{
				title: "Type",
				dataIndex: "giftCardTypeName",
				key: "giftCardTypeName",
				render: (v) => currency(v)
			},
			{
				title: "Expires on",
				dataIndex: "expiresOn",
				key: "expiresOn"
			},
			{
				title: "Price",
				dataIndex: "price",
				key: "price",
				render: (v) => currency(v)
			},
			{
				title: "Spent",
				dataIndex: "spent",
				key: "spent",
				render: (v) => currency(v)
			},
			{
				title: "Held",
				dataIndex: "held",
				key: "held",
				render: (v) => currency(v)
			}
		];
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Gift cards Held">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView loading={giftCardsHeldReport.loading} error={giftCardsHeldReport.error} retryAction={this.retry}>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<div style={{ marginRight: 12 }}>
									<Field
										label="Held At"
										name="heldAt"
										component={DateField}
										placeholder="Held At"
										displayFormat="Do MMM YYYY"
									/>
								</div>
								<DayEndsAt />
							</div>
							<div style={{ width: 60 }}>
								{giftCardsHeldReport.data ? (
									<IconButton
										icon="export"
										type="background"
										showTooltip
										tooltip="Export"
										onClick={this.downloadCsv}
										loading={giftCardsHeldCvsReport.loading}
										ariaLabel="Export"
									/>
								) : null}
							</div>
						</div>
						<Button type="primary" onClick={this.runLoad} disabled={!heldAt || !dayEndsAt} ariaLabel="Load">
							Load
						</Button>
					</ReportFilters>
					<ReportContent>
						{giftCardsHeldReport.data ? (
							<div>
								<Table
									columns={columns}
									dataSource={giftCardsHeldReport.data}
									rowKey="_id"
									pagination={{
										showSizeChanger: false,
										total: giftCardsHeldReport.count,
										current: giftCardsHeldReport.page,
										defaultPageSize: 20,
										onChange: (page) => this.load({ heldAt, dayEndsAt }, page)
									}}
								/>
								<ExportBar
									buttonText="Export"
									loading={giftCardsHeldCvsReport.loading}
									buttonClick={this.downloadCsv}
								/>
							</div>
						) : null}
					</ReportContent>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "giftCardsHeldReport";
const selector = formValueSelector(formName);
GiftCardsHeld = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(GiftCardsHeld);

export default connect(
	(state) => ({
		giftCardsHeldCvsReport: state.adminReports.finance.giftCardsHeldCvsReport,
		giftCardsHeldReport: state.adminReports.finance.giftCardsHeldReport,
		heldAt: selector(state, "heldAt"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{
		getGiftCardsHeldReport,
		getGiftCardsHeldReportCsv,
		resetGiftCardsHeldReport
	}
)(GiftCardsHeld);
