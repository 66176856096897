import { css } from "@emotion/css";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import Page from "@/components/Page";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import history from "@/history";
import { getEmail, resendEmail } from "@/state/actions/emailLogsActions";
import BaseInfo from "./components/BaseInfo";
import Preview from "./components/Preview";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const buttonWrapper = css({
	display: "flex",
	justifyContent: "space-between"
});

class EmailInspect extends Component {
	constructor(props) {
		super(props);
		this.debounceLoad = debounce(500, this.load);
		this.state = {
			modalOpen: false
		};
	}

	componentDidMount() {
		this.load();
	}

	load = () => {
		const { getEmail, params } = this.props;
		const { emailId, emailType } = params || {};

		if (emailId && emailType) {
			getEmail(emailId, emailType);
		}
	};

	sendEmail = () => {
		const { modalOpen } = this.state;
		const { resendEmail, emailLogs } = this.props;
		const { log } = emailLogs;
		if (!modalOpen) {
			this.setState({
				modalOpen: true
			});
		} else {
			resendEmail(log.emailId, log.emailType);
			this.setState({
				modalOpen: false
			});
		}
	};

	render() {
		const { modalOpen } = this.state;
		const { emailLogs } = this.props;
		const { loading, error, log, email, template } = emailLogs;

		return (
			<Page pageName="Email Inspect">
				<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
					<Modal
						title="Resend Email?"
						open={modalOpen}
						onCancel={() => this.setState({ modalOpen: false })}
						okText="Resend"
						onOk={this.sendEmail}
					>
						<div>This email has already been sent, would you like to resend it?</div>
					</Modal>
					{email && <BaseInfo email={email} log={log} />}
					{template && <Preview template={template} />}
					<ContentBlock>
						<div className={buttonWrapper}>
							<a href="#" onClick={history.goBack}>
								<LegacyButton ariaLabel="Back">Back</LegacyButton>
							</a>
							<LegacyButton ariaLabel="resend" onClick={this.sendEmail} type="action">
								Resend
							</LegacyButton>
						</div>
					</ContentBlock>
				</LoadErrorView>
			</Page>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search,
		emailLogs: state.emailLogs
	}),
	{ getEmail, resendEmail }
)(withRouterHooks(EmailInspect));
