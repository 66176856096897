import { Input } from "@/ui/antd";
import { Label } from "../../../../components/text/Text";
import InputLabel from "../../../components/InputLabel";

export default ({ elements, performAction }) => {
	const note = elements.reduce((note, element, i) => {
		if (i === 0) {
			return element.note;
		}
		if (note !== element.note) {
			return "";
		}
		return note;
	}, "");
	return (
		<div style={{ marginTop: 10 }}>
			<InputLabel>Note</InputLabel>
			<Label bold={false}>This will appear on the ticket under seat location</Label>
			<Input
				value={note}
				onChange={(event) => {
					const value = event.target.value;

					performAction(
						elements.map((element) => element.setNote),
						elements.map(() => [value])
					);
				}}
			/>
		</div>
	);
};
