import { State } from "@hx/seating-map";
import { Button, Input, Select, Spin, Tabs } from "@/ui/antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import ScrollComponent from "@/components/ScrollComponent";
import { Label, P } from "@/components/text/Text";
import { getUnmappedTickets } from "@/state/actions/seatingMapActions";

const { AssigningManager } = State.Assigning;
const InputSearch = Input.Search;
const { MODES } = State.Assigning;

const SortOptions = {
	RELEVANT: "RELEVANT",
	ORDER_DATE_NEWEST: "ORDER_DATE_NEWEST",
	ORDER_DATE_OLDEST: "ORDER_DATE_OLDEST",
	FIRSTNAME: "FIRSTNAME",
	LASTNAME: "LASTNAME"
};

const UnassignedAttendees = ({ seatingMap, getUnmappedTickets }) => {
	const [sortPreference, updateSortPreference] = useState(SortOptions.RELEVANT);
	const [tab, setTab] = useState(MODES.ASSIGN_INDIVIDUAL);
	const [page, setPage] = useState(1);

	const load = (searchQuery) => {
		getUnmappedTickets({
			search: searchQuery,
			assignOption: tab,
			page: page
		});
	};

	const debounceLoad = debounce(500, load);

	useEffect(() => {
		load();
	}, [tab]);

	useEffect(() => {
		if (page > 1) {
			load();
		}
	}, [page]);

	const onTabChange = (newTab) => {
		setPage(1);
		setTab(newTab);
	};

	const onSearch = useCallback(
		(e) => {
			setPage(1);
			debounceLoad(e.target.value);
		},
		[debounceLoad]
	);

	const pageUp = useCallback(() => {
		setPage((prevPage) => prevPage + 1);
	}, []);

	const debouncePageUp = debounce(1000, pageUp);

	const determineCard = (ticketData) => {
		switch (ticketData.assigningType) {
			case "tickets":
				return <TicketCard {...ticketData} />;
			case "orders":
				return <OrderCard {...ticketData} />;
		}
	};

	const getSortedUnmappedTickets = () => {
		const { unmappedTickets = [] } = seatingMap;

		switch (sortPreference) {
			case SortOptions.ORDER_DATE_NEWEST:
				return unmappedTickets.toSorted(function (a, b) {
					if (a.completedAt < b.completedAt) {
						return 1;
					}
					if (a.completedAt > b.completedAt) {
						return -1;
					}
					return 0;
				});
			case SortOptions.ORDER_DATE_OLDEST:
				return unmappedTickets.toSorted(function (a, b) {
					if (a.completedAt < b.completedAt) {
						return -1;
					}
					if (a.completedAt > b.completedAt) {
						return 1;
					}
					return 0;
				});
			case SortOptions.FIRSTNAME:
				return unmappedTickets.toSorted(function (a, b) {
					if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
						return -1;
					}
					if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
						return 1;
					}
					return 0;
				});
			case SortOptions.LASTNAME:
				return unmappedTickets.toSorted(function (a, b) {
					if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
						return -1;
					}
					if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
						return 1;
					}
					return 0;
				});
			case SortOptions.RELEVANT:
			default:
				return unmappedTickets;
		}
	};

	return (
		<ScrollComponent onReachedBottom={debouncePageUp}>
			<div
				style={{
					marginTop: 16,
					padding: "0px 12px 16px 12px",
					background: "white"
				}}
			>
				<Tabs
					activeKey={tab}
					onChange={onTabChange}
					items={[
						{ key: MODES.ASSIGN_INDIVIDUAL, label: "Attendees" },
						{ key: MODES.ASSIGN_ORDER, label: "Buyer" }
					]}
				/>
				<p>
					Search by {tab === MODES.ASSIGN_INDIVIDUAL ? "" : "attendee "} name, company, order ID, ticket type, email or
					mobile.
				</p>

				<InputSearch
					onChange={onSearch}
					enterButton
					placeholder={`Search for ${tab === MODES.ASSIGN_INDIVIDUAL ? "attendees" : "buyer"}`}
				/>

				<div style={{ marginTop: 16, marginBottom: 16 }}>
					<Label bold>Sort by</Label>
					<Select
						style={{ width: "100%" }}
						defaultValue={SortOptions.RELEVANT}
						onChange={(sortPreference) => updateSortPreference(sortPreference)}
						options={[
							{ value: SortOptions.RELEVANT, label: "Relevant" },
							{ value: SortOptions.ORDER_DATE_NEWEST, label: "Order date (newest - oldest)" },
							{ value: SortOptions.ORDER_DATE_OLDEST, label: "Order date (oldest - newest)" },
							{ value: SortOptions.FIRSTNAME, label: "First name (A - Z)" },
							{ value: SortOptions.LASTNAME, label: "Last name (A - Z)" }
						]}
					/>
				</div>
			</div>
			<div
				style={{
					borderTop: "solid 1px #dedede",
					marginBottom: 16,
					padding: "0px 12px 16px 12px",
					flex: "1 1 auto",
					overflowY: "auto",
					background: "white"
				}}
			>
				{getSortedUnmappedTickets().map((ticketData, index) => {
					return (
						<div
							style={{
								paddingTop: 16,
								paddingBottom: 4,
								display: "flex",
								flexDirection: "row",
								borderBottom: "solid 1px #dedede"
							}}
							key={`unassigned-attendee-${index}`}
						>
							{determineCard(ticketData)}
							<Button
								style={{ margin: "auto" }}
								onClick={() =>
									AssigningManager.startAssignUnassignedTicket({
										...ticketData,
										ticketId: ticketData._id
									})
								}
							>
								Assign
							</Button>
						</div>
					);
				})}
				{seatingMap.organiser.loading && (
					<div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
						<Spin size="small" />
					</div>
				)}
			</div>
		</ScrollComponent>
	);
};

const TicketCard = ({ ticketTypeName, displayName, orderName }) => {
	return (
		<div style={{ flexGrow: 1 }}>
			<P style={{ fontWeight: 600, marginBottom: 4 }}>{displayName}</P>
			<P style={{ marginBottom: 4 }}>{ticketTypeName}</P>
			<P>{`Order: ${orderName}`}</P>
		</div>
	);
};

const OrderCard = ({ displayName, orderName, tickets }) => {
	return (
		<div style={{ flexGrow: 1 }}>
			<P style={{ fontWeight: 600, marginBottom: 4 }}>{displayName}</P>
			<P>{`Order: ${orderName}`}</P>
			<P>{`${tickets.length} tickets`}</P>
		</div>
	);
};

export default connect(
	(state) => {
		return {
			seatingMap: state.seatingMap
		};
	},
	{ getUnmappedTickets }
)(UnassignedAttendees);
