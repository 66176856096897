import { css } from "@emotion/css";
import * as React from "react";
import ToolTip from "./ToolTip";

const darkTheme = {
	sliderStyling: css({
		flexGrow: 1,
		zIndex: 2,
		borderRadius: 5,
		width: "100%",
		margin: 0,
		WebkitAppearance: "none",
		height: "4px",
		position: "absolute",
		background: "none",
		outline: "none",
		WebkitTransition: ".2s",
		"&::-webkit-slider-thumb": {
			WebkitAppearance: "none",
			appearance: "none",
			width: 16,
			height: 16,
			borderRadius: "50%",
			background: "#ffb18f",
			cursor: "pointer"
		},

		"&::-moz-range-thumb": {
			width: 16,
			height: 16,
			borderRadius: "50%",
			background: "#ffb18f",
			cursor: "pointer"
		}
	}),
	barStyle: css({
		position: "absolute",
		zIndex: 1,
		height: "4px",
		width: `100%`,
		backgroundColor: "#dedede",
		top: 0,
		borderRadius: 4
	}),
	scrubber: css({
		height: "4px",
		backgroundColor: "#57cfc7",
		top: 0,
		borderRadius: 4,
		position: "absolute",
		zIndex: 1
	})
};

const lightTheme = {
	sliderStyling: css({
		flexGrow: 1,
		zIndex: 2,
		borderRadius: 5,
		width: "100%",
		margin: 0,
		WebkitAppearance: "none",
		height: "4px",
		position: "absolute",
		background: "none",
		outline: "none",
		WebkitTransition: ".2s",
		"&::-webkit-slider-thumb": {
			WebkitAppearance: "none",
			appearance: "none",
			width: 12,
			height: 12,
			borderRadius: "50%",
			background: "#ffffff",
			cursor: "pointer"
		},

		"&::-moz-range-thumb": {
			width: 16,
			height: 16,
			borderRadius: "50%",
			background: "#ffffff",
			cursor: "pointer"
		}
	}),
	barStyle: css({
		position: "absolute",
		zIndex: 1,
		height: "2px",
		width: `100%`,
		backgroundColor: "#57cfc7",
		top: 0,
		borderRadius: 4
	}),
	scrubber: css({
		height: "2px",
		backgroundColor: "#57cfc7",
		top: 0,
		borderRadius: 3,
		position: "absolute",
		zIndex: 1
	})
};
enum StyleKey {
	DARK = "DARK",
	LIGHT = "LIGHT"
}
type StyleType = {
	sliderStyling: string;
	scrubber: string;
	barStyle: string;
};

const styleMap = new Map<StyleKey, StyleType>();
styleMap.set(StyleKey.DARK, darkTheme);
styleMap.set(StyleKey.LIGHT, lightTheme);

type Props = {
	styleType: StyleKey;
	sliderPercent?: number;
	onChange: (e: any) => void;
	showLabels?: boolean;
	min?: number;
	max?: number;
	disableTooltip?: boolean;
};
type State = { sliderPercent: number };

class Slider extends React.Component<Props, State> {
	static styleKey = StyleKey;

	constructor(props: Props) {
		super(props);
		this.state = { sliderPercent: props.sliderPercent !== undefined ? props.sliderPercent : 50 };
	}

	onChange = (e: any) => {
		this.setState({
			sliderPercent: e.target.value
		});
		this.props.onChange(e.target.value);
	};

	render() {
		const { sliderStyling, scrubber, barStyle } = styleMap.get(this.props.styleType) as StyleType;
		const { showLabels, min = 0, max = 100, disableTooltip = false } = this.props;
		const { sliderPercent } = this.state;
		const labelDisplay = showLabels ? "inherit" : "none";

		return (
			<div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div style={{ fontSize: 14, marginRight: 4, display: labelDisplay }}>{`${min}%`}</div>
				<div style={{ flexGrow: 1, position: "relative" }}>
					<div className={barStyle} />
					<div className={scrubber} style={{ width: `${sliderPercent - min}%` }} />
					<ToolTip message={`${sliderPercent}%`} disableTooltip={disableTooltip}>
						<input
							className={sliderStyling}
							onInput={this.onChange}
							type="range"
							min={String(min)}
							max={String(max)}
							value={sliderPercent}
						/>
					</ToolTip>
				</div>
				<div style={{ fontSize: 14, marginLeft: 4, display: labelDisplay }}>{`${max}%`}</div>
			</div>
		);
	}
}

export default Slider;
