import { css } from "@emotion/css";
import { Col, DatePicker, Flex, Row, Select } from "@/ui/antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Cell, Pie, PieChart } from "recharts";
import { getEventSalesOverview } from "@/state/actions/metricActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { currency } from "@/utils/Format";
import { add, subtract } from "@/utils/Math";
import { LoadingSpinner } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LinkButton from "@/components/buttons/LinkButton";
import { H2, H4, H5, P } from "@/components/text/Text";
import Widget from "@/components/widgets/Widget";
import { Dropdown } from "@/ui";
import { SlidersHorizontal } from "@phosphor-icons/react";
import { Button } from "@/ui";

const { RangePicker } = DatePicker;
type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof RangePicker>["onChange"]>>[0];

const container = css({
	display: "flex",
	flexFlow: "row wrap",
	H5: {
		lineHeight: "14px"
	},
	"@media(max-width:768px)": {
		flexDirection: "column"
	}
});

const charts = css({
	padding: "16px 20px 16px 0px",
	flex: 1,
	"@media(max-width:768px)": {
		borderBottom: "solid 1px #eeecf0",
		padding: "16px 0px 16px 0px"
	}
});

const tripleBoxContainer = css({
	marginTop: 33
});

const boxStyle = css({
	height: 66,
	borderRadius: 4,
	display: "block",
	overflow: "hidden",
	background: "#f9f9fa",
	H5: {
		marginTop: 12,
		textAlign: "center"
	},
	H4: {
		textAlign: "center"
	}
});

const soldBoxCorner = css`
	height: 24px;
	width: 24px;
	background: #57cfc7;
	-webkit-transform: rotate(-45deg);
	bottom: 4px;
	left: -12px;
	position: relative;
`;

const pendingBoxCorner = css`
	height: 24px;
	width: 24px;
	background: #ffb18f;
	-webkit-transform: rotate(-45deg);
	bottom: 4px;
	left: -12px;
	position: relative;
`;

const labelStyle = css({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: 16,
	h2: {
		display: "inline",
		"&:nth-child(2)": {
			color: "#353337"
		}
	}
});

const dropdownContents = css({
	display: "grid",
	rowGap: 8,
	".ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
		height: "auto"
	},
	".ant-select-single.ant-select-show-arrow .ant-select-selection-item": {
		whiteSpace: "normal",
		wordBreak: "break-all"
	},
	".ant-select-single:not(.ant-select-customize-input) .ant-select-selector": {
		height: "unset"
	},

	"@media(max-width:768px)": {
		width: "80svw"
	}
});

interface ITicketSalesProgressProps {
	ticketsSold?: number;
	ticketsPending?: number;
	capacity?: number;
}

const TicketSalesProgress = ({ ticketsSold = 0, ticketsPending = 0, capacity = 100 }: ITicketSalesProgressProps) => {
	const empty = subtract(capacity, add(ticketsSold, ticketsPending));
	const radius = 70;
	const data = [
		{ name: "SOLD", value: ticketsSold },
		{ name: "PENDING", value: ticketsPending },
		{ name: "EMPTY", value: empty }
	];
	const COLORS = ["#57cfc7", "#ffb18f", "#eeecf0"];
	return (
		<div>
			<H4 style={{ textAlign: "center", marginBottom: 10 }}>
				<b>Tickets sales</b>
			</H4>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center"
				}}
			>
				<div
					style={{
						marginRight: 8,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						marginLeft: -20
					}}
				>
					<H5>
						{ticketsSold}/{capacity}
					</H5>
				</div>
				<PieChart width={radius * 2} height={70}>
					<Pie
						data={data}
						dataKey="value"
						cx={65}
						cy={65}
						startAngle={180}
						endAngle={0}
						innerRadius={58}
						outerRadius={radius}
						paddingAngle={0}
					>
						{data.map((...[, index]) => (
							<Cell fill={COLORS[index % COLORS.length]} key={`cell-${index}`} />
						))}
					</Pie>
				</PieChart>
				<div
					style={{
						marginLeft: 8,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end"
					}}
				>
					<H5>{capacity < 1 ? 0 : Math.floor((ticketsSold / capacity) * 100)}%</H5>
				</div>
			</div>
		</div>
	);
};

interface IReportTableProps {
	overview: {
		ticketSales?: number;
		addOnSales?: number;
		additionalDonations?: number;
		feeRebates?: number;
		absorbedFees?: number;
		absorbedHumanitixFees?: number;
		absorbedZipFees?: number;
		absorbedAfterpayFees?: number;
		refunds?: number;
		salesTaxHeld?: number;
		totalEarnings?: number;
		customTax?: number;
	};
	currentEvent: any;
	location: string;
}

const ReportTable = ({
	overview: {
		ticketSales = 0,
		addOnSales = 0,
		additionalDonations = 0,
		feeRebates = 0,
		absorbedFees = 0,
		absorbedHumanitixFees = 0,
		absorbedZipFees = 0,
		absorbedAfterpayFees = 0,
		refunds = 0,
		salesTaxHeld = 0,
		totalEarnings = 0,
		customTax = 0
	},
	currentEvent,
	location
}: IReportTableProps) => {
	const rowBorderStyle = "solid 1px #dedede";

	const wrapper = css({
		paddingLeft: 20,
		flex: 1,

		".tableRowBackground:nth-child(odd)": {
			backgroundColor: "#fafafa"
		},
		".tableRowBackground:nth-child(even)": {
			backgroundColor: "#ffffff"
		},

		"@media(max-width:768px)": {
			padding: "15px 0px 0px 0px"
		}
	});

	return (
		<div className={wrapper}>
			<Row className="tableRowBackground" key="Ticket sales">
				<Col span={12}>
					<P>Ticket sales{location === "US" ? " (incl. sales tax)" : ""}</P>
				</Col>
				<Col span={12}>
					<P>
						<b>{currency(ticketSales)}</b>
					</P>
				</Col>
			</Row>
			{currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.enabled ? (
				<Row className="tableRowBackground" key="CustomTax">
					<Col span={12}>
						<P>{currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.name}</P>
					</Col>
					<Col span={12}>
						<P>
							<b>{currency(customTax)}</b>
						</P>
					</Col>
				</Row>
			) : null}
			<Row className="tableRowBackground" key="Add-on sales">
				<Col span={12}>
					<P>Add-on sales</P>
				</Col>
				<Col span={12}>
					<P>
						<b>{currency(addOnSales)}</b>
					</P>
				</Col>
			</Row>
			<Row className="tableRowBackground" key="Additional donations">
				<Col span={12}>
					<P>Additional donations</P>
				</Col>
				<Col span={12}>
					<P>
						<b>{currency(additionalDonations)}</b>
					</P>
				</Col>
			</Row>
			{feeRebates ? (
				<Row className="tableRowBackground" style={{ borderBottom: "solid 1px #dedede" }} key="Rebates">
					<Col span={12}>
						<P>Fee rebates</P>
					</Col>
					<Col span={12}>
						<P>
							<b>{currency(feeRebates)}</b>
						</P>
					</Col>
				</Row>
			) : null}
			<Row className="tableRowBackground" key="Fees" style={{ borderTop: rowBorderStyle }}>
				<Col span={12}>
					{absorbedZipFees || absorbedAfterpayFees ? (
						<P>
							Absorbed fees{" "}
							<IconButton
								icon="info_hover"
								tooltip={
									<div>
										<b>Fee breakdown:</b>
										<br />
										<b>Humanitix fees:</b> {currency(absorbedHumanitixFees)}
										<br />
										<b>Zip fees:</b> {currency(absorbedZipFees)}
										<br />
										<b>Afterpay fees:</b> {currency(absorbedAfterpayFees)}
										<br />
									</div>
								}
								showTooltip
								small
								ariaLabel="Fee breakdown"
							/>
						</P>
					) : (
						<P>Absorbed fees</P>
					)}
				</Col>
				<Col span={12}>
					<P>
						<b>{currency(absorbedFees)}</b>
					</P>
				</Col>
			</Row>
			<Row className="tableRowBackground" key="Refunds">
				<Col span={12}>
					<P>Refunds</P>
				</Col>
				<Col span={12}>
					<P>
						<b>{currency(refunds)}</b>
					</P>
				</Col>
			</Row>

			{salesTaxHeld ? (
				<Row className="tableRowBackground" key="Discounts">
					<Col span={12}>
						<P>Sales Tax Held</P>
					</Col>
					<Col span={12}>
						<P>
							<b>{currency(salesTaxHeld)}</b>
						</P>
					</Col>
				</Row>
			) : null}
			<Row
				className="tableRowBackground"
				style={{ borderTop: rowBorderStyle, borderBottom: rowBorderStyle }}
				key="Net sales"
			>
				<Col span={12}>
					<P>
						<b>Total earnings</b>
					</P>
				</Col>
				<Col span={12}>
					<P>
						<b>{currency(totalEarnings)}</b>
					</P>
				</Col>
			</Row>
		</div>
	);
};

type EventDate = {
	_id: string;
	display: {
		combined: string;
	};
};

interface IWidgetHeaderProps {
	totalEarnings: number;
	dates?: EventDate[];
	handleChange: (key: string, value: string | RangeValue) => void;
	showRangePicker: boolean;
}

const WidgetHeader = ({ totalEarnings, dates = [], handleChange, showRangePicker }: IWidgetHeaderProps) => {
	const eventDateOptions = [{ label: "All dates", value: "allDates" }].concat(
		dates.map((eventDate) => ({ label: eventDate.display.combined, value: eventDate._id }))
	);

	return (
		<div className={labelStyle}>
			<div>
				<H2>Your earnings: </H2>
				<H2>{currency(totalEarnings || 0)}</H2>
			</div>
			<Flex justify="flex-end">
				<Dropdown
					persist
					title="Refine overview"
					items={
						<div className={dropdownContents}>
							{dates.length > 1 && (
								<Select
									style={{ fontSize: 14, height: "auto", wordWrap: "break-word" }}
									defaultValue={eventDateOptions[0].value}
									onChange={(value) => handleChange("eventDateId", value)}
									options={eventDateOptions}
								/>
							)}

							<Select
								style={{ fontSize: 14, height: "auto" }}
								defaultValue="all"
								onChange={(value) => handleChange("channel", value)}
								options={[
									{ label: "All channels", value: "all" },
									{ label: "Online sales", value: "online" },
									{ label: "Manual sales", value: "manual" },
									{ label: "Box Office", value: "boxOffice" }
								]}
							/>

							<Select
								style={{ fontSize: 14, height: "auto" }}
								defaultValue="allTime"
								onChange={(value) => handleChange("period", value)}
								options={[
									{ label: "All time", value: "allTime" },
									{ label: "Today", value: "today" },
									{ label: "Yesterday", value: "yesterday" },
									{ label: "Last 7 days", value: "week" },
									{ label: "Last 30 days", value: "month" },
									{ label: "Custom Date", value: "custom" }
								]}
							/>

							{showRangePicker && (
								<RangePicker onChange={(value) => handleChange("customDate", value)} style={{ paddingLeft: 30 }} />
							)}
						</div>
					}
				>
					<Button iconOnly variant="text">
						<SlidersHorizontal size={20} />
					</Button>
				</Dropdown>
			</Flex>
		</div>
	);
};

const EventSalesOverview = (props: any) => {
	const [state, setState] = useState({
		period: "allTime",
		channel: "all",
		eventDateId: "",
		customDate: null
	});

	const navigate = useNavigate();
	const location = useLocation();
	const { currentEvent, getEventSalesOverview, metricsByDate } = props;
	const { event } = currentEvent;
	const { dates = [] } = event;
	const eventId = currentEvent.event._id;
	const { loading, overview } = metricsByDate;
	const { capacity, ticketOrders, totalEarnings, ticketsSold, ticketsPending } = overview || {};

	useEffect(() => {
		getEventSalesOverview(
			eventId,
			state.period !== "custom" ? state.period : state.customDate,
			state.channel,
			state.eventDateId === "allDates" ? undefined : state.eventDateId
		);
	}, [state]);

	const _handleNavigateToPayout = () => navigate(`/console/my-events/${event._id}/payments/payouts`);

	const _handleChange = (key: string, value: string | RangeValue) =>
		setState((current) => ({ ...current, ...{ [key]: value } }));

	return (
		<>
			<LoadErrorView loading={loading}>
				<Widget
					title="Sales"
					overwriteTimeScale
					{...props}
					customTitle={
						<WidgetHeader
							totalEarnings={totalEarnings}
							dates={dates}
							handleChange={_handleChange}
							showRangePicker={state.period === "custom"}
						/>
					}
					style={{ ...props.style, width: "100%" }}
				>
					{loading ? (
						<LoadingSpinner size={30} />
					) : (
						<div className={container}>
							<div className={charts}>
								<TicketSalesProgress ticketsSold={ticketsSold} ticketsPending={ticketsPending} capacity={capacity} />
								<div className={tripleBoxContainer}>
									<Row gutter={8} key="3boxes">
										<Col span={8} key="sold">
											<div className={boxStyle}>
												<H5 bold={false}>Tickets sold</H5>
												<H4>{ticketsSold}</H4>
												<div className={soldBoxCorner} />
											</div>
										</Col>
										<Col span={8} key="pending">
											<div className={boxStyle}>
												<H5 bold={false}>Tickets pending</H5>
												<H4>{ticketsPending}</H4>
												<div className={pendingBoxCorner} />
											</div>
										</Col>
										<Col span={8} key="orders">
											<div className={boxStyle}>
												<H5 bold={false}>Orders</H5>
												<H4>{ticketOrders}</H4>
											</div>
										</Col>
									</Row>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: 16
									}}
								>
									<LinkButton onClick={_handleNavigateToPayout} ariaLabel="See your payouts">
										<P style={{ textDecoration: "underline", color: "#196097" }}>See your payouts</P>
									</LinkButton>
								</div>
							</div>
							<ReportTable overview={overview} currentEvent={currentEvent} location={location} />
						</div>
					)}
				</Widget>
			</LoadErrorView>
		</>
	);
};

type ReduxState = Partial<GlobalReduxState> & {
	eventMetrics: any;
	locations: {
		overrideLocation: string;
	};
	currentEvent: Pick<GlobalReduxState, "currentEvent">;
	search: Pick<GlobalReduxState, "search">;
};

interface IEventSalesOverviewProps {
	metricState: "eventMetrics";
}

type IComponentProps = Pick<GlobalReduxState, "currentEvent" | "search"> & {
	metricsByDate: any;
	location: string;
};

export default connect(
	(state: ReduxState, ownProps: IEventSalesOverviewProps): IComponentProps => ({
		currentEvent: state.currentEvent,
		metricsByDate: state[ownProps.metricState].overviewByDate,
		search: state.search,
		location: state.locations.overrideLocation
	}),
	{ getEventSalesOverview }
)(EventSalesOverview);
