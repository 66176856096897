import { useViewport } from "../hooks/useViewport";

const withViewport = (Component: any) => {
	return function WrappedComponent(props: any) {
		const { width, height, isMobile, isTablet, isDesktop } = useViewport();

		return (
			<Component
				{...props}
				viewportWidth={width}
				viewportHeight={height}
				isMobile={isMobile}
				isTablet={isTablet}
				isDesktop={isDesktop}
			/>
		);
	};
};

type IWithViewPortProps = {
	viewportWidth: number;
	viewportHeight: number;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
};

export default withViewport;
export type { IWithViewPortProps };
