import { sentenceCase } from "../utils/Format";

const getColor = (status: string) => {
	if (status === "complete") {
		return "#157e77";
	} else {
		return "#5a626c";
	}
};

const OrderStatus = ({ status }: { status: string }) => (
	<span style={{ color: getColor(status) }}>{sentenceCase(status)}</span>
);

export default OrderStatus;
