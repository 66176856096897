const Icon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path
					id="ele-id-fill"
					fill="#67717E"
					d="M12 14.5c1.66 0 2.99-1.34 2.99-3l.01-6c0-1.66-1.34-3-3-3s-3 1.34-3 3v6c0 1.66 1.34 3 3 3zm7-3h-1.7c0 3-2.54 5.1-5.3 5.1s-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72v3.28h2v-3.28c3.28-.49 6-3.31 6-6.72z"
				/>
				<path d="M0 0L24 0 24 24 0 24z" />
			</g>
		</svg>
	);
};

export default Icon;
