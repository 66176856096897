import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import P from "@/components/text/P";
import withSaveCheck from "@/hoc/withSaveCheck";
import { saveEvent } from "@/state/actions/eventActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { css } from "@emotion/css";
import { ConnectedProps, connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm, InjectedFormProps } from "redux-form";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import ObjectID from "bson-objectid";
import { Link } from "react-router-dom";
import { HelpIds } from "@/utils/Help";

const formName = "reportingGroups";
const nameRequired = required("Name required");
const selector = formValueSelector(formName);

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	})
};

interface EventTicketType {
	_id: string;
	name: string;
}

interface ReportingGroupType {
	name: string;
	ticketIds: string[];
}

const columns = (ticketTypes: EventTicketType[], onDelete: (index: number) => void) => [
	{
		title: (
			<span>
				<span className={styles.asterisk}>* </span> Name
			</span>
		),
		dataIndex: "name",
		key: "name",
		width: "41%",
		render: (_: string, __: ReportingGroupType, index: number) => {
			return (
				<div
					className={css({
						"@media(max-width: 600px)": {
							float: "left",
							marginRight: 10,
							minWidth: "75vw"
						}
					})}
				>
					<Field
						inline
						name={`reportingGroups[${index}].name`}
						component={LegacyInputField}
						placeholder="Name"
						validate={nameRequired}
					/>
				</div>
			);
		}
	},
	{
		title: "Tickets",
		dataIndex: "ticketIds",
		key: "ticketIds",
		width: "41%",
		render: (_: string[], __: ReportingGroupType, index: number) => {
			return (
				<div
					className={css({
						"@media(max-width: 600px)": {
							float: "left",
							marginRight: 10,
							minWidth: "75vw"
						}
					})}
				>
					<Field
						style={{ maxWidth: 180 }}
						mode="multiple"
						name={`reportingGroups[${index}].ticketIds`}
						component={SelectField}
						options={ticketTypes.map((tt) => ({ value: tt._id, label: tt.name }))}
					/>
				</div>
			);
		}
	},
	{
		title: "Action",
		dataIndex: "",
		key: "x",
		render: (_: string, _item: ReportingGroupType, index: number) => {
			return (
				<div
					className={css({
						"@media(max-width: 600px)": {
							float: "left",
							marginRight: 10,
							minWidth: "75vw"
						}
					})}
				>
					<TableActions>
						<IconButton icon="delete" onClick={() => onDelete(index)} tooltip="Delete" showTooltip ariaLabel="Delete" />
					</TableActions>
				</div>
			);
		}
	}
];

const ReportingGroups = ({ handleSubmit }: PropsFromRedux & InjectedFormProps) => {
	const params = useParams();
	const dispatch = useAppDispatch();

	const currentEvent = useAppSelector((state) => state.currentEvent);
	const reportingGroups = useAppSelector((state) => selector(state, "reportingGroups") || []);

	const _save = (values: { reportingGroups: ReportingGroupType[] }) => {
		const eventId = params.eventId;
		dispatch(
			saveEvent(eventId, {
				reportingGroups: values.reportingGroups.map((rg) => ({ ...rg, _id: new ObjectID().toHexString() }))
			})
		);
	};

	return (
		<form onSubmit={handleSubmit(_save)}>
			<PageHeading
				title="Reporting groups"
				helpButton={{
					link: HelpIds.reportingGroups,
					title: "Help - Reporting groups"
				}}
			/>

			<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error}>
				<ContentBlock>
					<P>
						Track sales and check-ins across a subset of ticket types using reporting groups. For example, you may want
						to report on all “Admission” ticket types.
					</P>
					<P>
						View your reporting groups on the{" "}
						<Link to={`/console/my-events/${currentEvent.event._id}`}>Overview page.</Link>
					</P>

					<FieldArray
						name="reportingGroups"
						component={ReportingGroup}
						reportingGroups={reportingGroups}
						ticketTypes={currentEvent?.event?.ticketTypes}
					/>
				</ContentBlock>

				<FormBar>
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						disabled={currentEvent.save.loading}
						size="large"
					>
						Save
					</LegacyButton>
				</FormBar>
			</LoadErrorView>
		</form>
	);
};

const ReportingGroup = ({
	fields,
	reportingGroups,
	ticketTypes
}: {
	fields: any;
	reportingGroups: ReportingGroupType[];
	ticketTypes: EventTicketType[];
}) => {
	const onDelete = (index: number) => {
		fields.remove(index);
	};

	const createGroup = (fields: any) => {
		const group = {
			name: "",
			ticketIds: []
		};
		fields.push(group);
	};

	return (
		<>
			{reportingGroups.length ? (
				<>
					<ResponsiveTable
						pagination={false}
						columns={columns(ticketTypes, onDelete)}
						dataSource={reportingGroups.map((group, index) => ({ ...group, key: index }))}
						rowKey="key"
					/>
					<ButtonsBar>
						<LegacyButton onClick={() => createGroup(fields)} icon={{ name: "plus", left: true }}>
							Add group
						</LegacyButton>
					</ButtonsBar>
				</>
			) : (
				<NoResults
					title="You have no reporting groups."
					message=""
					action={() => createGroup(fields)}
					actionTxt="Create Group"
				/>
			)}
		</>
	);
};

const ReduxConnectedComponent = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => scrollToErrors()
})(ReportingGroups);

const mapStateToProps = (state: Partial<GlobalReduxState>) => ({
	initialValues: {
		reportingGroups: state?.currentEvent?.event?.reportingGroups
	}
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Connected = withSaveCheck(connector(ReduxConnectedComponent), formName);
export default Connected;
