import { InputNumberField, SelectField, TextAreaField } from "@/components/Fields";
import { AdjustmentData, addAdjustment, editPayment } from "@/state/actions/newPayoutActions";
import { DateBreakdown } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { mustNotBeZero, required } from "@/utils/Validators";
import { Modal } from "@/ui/antd";
import * as React from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

const amountRequired = required("An amount is required");

type ICombinedProps = IProps & IInjectedProps & IInjectedFunctions;

const AdjustmentModal: React.FunctionComponent<ICombinedProps & InjectedFormProps<AdjustmentData, ICombinedProps>> = ({
	isOpen,
	handleSubmit,
	addAdjustment,
	eventId,
	breakdown,
	isEdit,
	adjustmentId,
	editPayment,
	close
}) => {
	const save = (values: AdjustmentData) => {
		isEdit ? editPayment(eventId!, adjustmentId!, values) : addAdjustment(eventId!, values);
		close();
	};

	const titleAndButtonText = `${isEdit ? "Edit" : "Add"} adjustment`;

	return (
		<Modal
			open={isOpen}
			destroyOnClose={true}
			onCancel={close}
			okText={titleAndButtonText}
			title={titleAndButtonText}
			onOk={handleSubmit(save)}
		>
			<form>
				<Field
					name="eventDateId"
					label="Select date"
					component={SelectField}
					options={breakdown.map((date) => ({ label: date.displayDate, value: date.dateId }))}
				/>
				<Field
					name="amount"
					label="Amount"
					component={InputNumberField}
					precision={2}
					required
					validate={[amountRequired, mustNotBeZero]}
				/>
				<Field name="notes" label="Notes" component={TextAreaField} />
			</form>
		</Modal>
	);
};

const formName = "addAdjustment";

interface IInjectedProps {
	eventId?: string;
	breakdown: DateBreakdown[];
	initialValues: AdjustmentData;
}

interface IProps {
	isOpen: boolean;
	close: () => void;
	focusedDate?: string | null;
	adjustmentId?: string | null;
	isEdit?: boolean;
}

interface IInjectedFunctions {
	addAdjustment: (eventId: string, adjustment: AdjustmentData) => void;
	editPayment: (eventId: string, adjustmentId: string, adjustment: AdjustmentData) => void;
}

const mapStateToProps = (state: ReduxState, ownProps: IProps): IInjectedProps => {
	const breakdown = state.payouts.eventPayout.payoutInformation?.datesBreakdown || [];
	const creditsOrAdjustments = state.payouts.eventPayout.payoutInformation?.creditsOrAdjustments || [];
	const editingAdjustment = creditsOrAdjustments.find((adjustment) => adjustment._id === ownProps.adjustmentId);
	const initialValues =
		ownProps.isEdit && editingAdjustment
			? {
					eventDateId: editingAdjustment.eventDateId,
					amount: editingAdjustment.amount,
					notes: editingAdjustment.notes
			  }
			: {
					eventDateId: ownProps.focusedDate || undefined,
					amount: 0,
					notes: undefined
			  };
	return {
		initialValues,
		eventId: state.payouts.eventPayout.payoutInformation?.header.eventId,
		breakdown
	};
};

export default connect(mapStateToProps, { addAdjustment, editPayment })(
	reduxForm<AdjustmentData, ICombinedProps>({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(AdjustmentModal)
);
