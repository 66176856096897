import { Utils } from "@hx/seating-map";
import * as React from "react";
import { IconButton } from "../components/IconButton";
import ToolTip from "../components/ToolTip";
import { IconNames } from "../icons";
const { ToolBarInterface } = Utils;
class Pan extends React.Component<{}, { active: boolean }> {
	constructor(props: {}) {
		super(props);
		this.state = {
			active: true
		};
		ToolBarInterface.multiSelect = () => {
			this.setState({ active: false });
		};

		ToolBarInterface.panSelect = () => {
			this.setState({ active: true });
		};
	}

	onClick = () => {
		ToolBarInterface.panButtonSelected();
	};

	render() {
		const { active } = this.state;
		return (
			<div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
				<ToolTip message="Pan tool">
					<div
						style={{
							boxShadow: "0 0 1px 0 #f1f1f1, 0 2px 3px 0 rgba(32, 7, 64, 0.13)",
							width: 36,
							height: 36,
							borderRadius: 4
						}}
					>
						<IconButton onClick={this.onClick} toggleAble={true} icon={IconNames.pan} active={active} />
					</div>
				</ToolTip>
			</div>
		);
	}
}

export default Pan;
