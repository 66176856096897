import { DatePicker as AntdDatePicker, DatePickerProps as AntdDatePickerProps } from "@/ui/antd";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";

type DatePickerValue = string | undefined;

const dateToDayjs = (date: DatePickerValue, timezone: string | undefined): Dayjs | undefined => {
	if (!date) return undefined;
	if (dayjs(date).isValid()) return dayjs(date).tz(timezone);

	return undefined;
};

export type DateTimePickerResult = string | undefined;

export type DateTimePickerProps = {
	/** Whether or not the date picker is disabled */
	disabled?: boolean;
	/** The maximum date that can be selected */
	maxDate?: DatePickerValue;
	/** The minimum date that can be selected */
	minDate?: DatePickerValue;
	/** Provides the time as an ISO string */
	onChange?: (value: DateTimePickerResult) => void;
	/** Whether or not to show time */
	showTime?: boolean | { format: string };
	/** The timezone that the given time value should be displayed to the user as */
	timezone?: string;
	/** The time to display, as ISO string */
	value?: DatePickerValue;
};

export const DateTimePicker: FC<DateTimePickerProps> = ({
	disabled,
	maxDate,
	minDate,
	onChange,
	showTime,
	timezone,
	value
}) => {
	const formattedMaxDate = dateToDayjs(maxDate, timezone);
	const formattedMinDate = dateToDayjs(minDate, timezone);
	const displayValue = dateToDayjs(value, timezone);

	const handleSingleDateChange: AntdDatePickerProps["onChange"] = (nextValue) => {
		onChange?.(nextValue?.toISOString() ?? undefined);
	};

	const dateFormat = showTime ? "Do MMM YYYY h:mm a" : "Do MMM YYYY";

	return (
		<AntdDatePicker
			disabled={disabled}
			format={dateFormat}
			maxDate={formattedMaxDate}
			minDate={formattedMinDate}
			onChange={handleSingleDateChange}
			showTime={showTime}
			value={displayValue}
			style={{ width: "100%" }}
			placeholder={showTime ? "Select Date and Time" : "Select Date"}
		/>
	);
};
