import axios from "./AxiosWrapper";

const AccessCodesService = {
	async find(eventId, page, query, sortOrder) {
		const response = await axios.get(`events/access-codes/${eventId}`, {
			params: {
				page,
				query,
				sortOrder
			}
		});
		return response.data;
	},
	async save(eventId, accessCode) {
		if (accessCode._id) {
			return this.update(eventId, accessCode);
		}
		return this.create(eventId, accessCode);
	},
	async duplicate(eventId, accessCode) {
		const response = await axios.put(`events/access-codes/duplicate/${eventId}`, accessCode);
		return response.data;
	},
	async create(eventId, accessCode) {
		if (accessCode.type === "single") {
			const response = await axios.put(`events/access-codes/${eventId}`, accessCode);
			return response.data;
		}
		// upload a CSV
		let fd = new FormData();
		fd.append("file", accessCode.csvFile[0]);
		fd.append("appliesTo", accessCode.appliesTo);
		fd.append("enabled", accessCode.enabled);
		const config = {
			headers: {
				"content-type": "multipart/form-data"
			}
		};
		const response = await axios.put(`events/access-codes/upload/${eventId}`, fd, config);
		return response.data;
	},
	async update(eventId, accessCode) {
		const response = await axios.post(`events/access-codes/${eventId}`, accessCode);
		return response.data;
	},
	async delete(eventId, accessCode) {
		const response = await axios.delete(`events/access-codes/${eventId}/${accessCode._id}`, accessCode);
		return response.data;
	},

	async deleteAll(eventId) {
		const response = await axios.delete(`events/access-codes/${eventId}/all`);
		return response.data;
	},

	async getAccessCodesCsv(eventId, query, sortOrder) {
		const response = await axios.get(`events/access-codes/${eventId}/download/csv`, {
			responseType: "blob",
			params: {
				query,
				sortOrder
			}
		});
		return response.data;
	}
};
export default AccessCodesService;
