import { css } from "@emotion/css";
import { Modal } from "@/ui/antd";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H4 } from "@/components/text/Text";

const AddQuestionsModal = ({ visible, addQuestion, handleCancel }) => {
	const questions = {
		display: "flex",
		justifyContent: "space-between",
		"@media(max-width: 600px)": {
			display: "block",
			div: {
				"&:first-of-type": {
					marginBottom: 24
				}
			}
		}
	};
	const modalWrapper = {
		width: "600px !important",
		"@media(max-width: 600px)": {
			width: "calc(100% - 28px) !important"
		}
	};

	const questionArea = {
		width: 251
	};

	const templateQuestions = [
		{
			question: "Full name",
			fields: [
				{
					type: "firstName",
					question: "First name"
				},
				{
					type: "lastName",
					question: "Last name"
				}
			]
		},
		{
			type: "mobile",
			question: "Mobile"
		},
		{
			type: "organisation",
			question: "Company"
		},
		{
			type: "email",
			question: "Email",
			inputType: "email"
		},
		{
			type: "multiSelect",
			question: "Dietary requirements",
			options: [
				{ value: "None" },
				{ value: "Nut Allergy" },
				{ value: "Gluten Free" },
				{ value: "Kosher" },
				{ value: "Halal" },
				{ value: "Vegetarian" },
				{ value: "Pescatarian" },
				{ value: "Vegan" },
				{ value: "Other" }
			],
			requiresOther: true
		},
		{
			type: "text",
			question: "Accessibility requirements",
			inputType: "text"
		},
		{
			type: "text",
			question: "Date of birth",
			inputType: "date"
		},
		{
			type: "text",
			question: "Age",
			inputType: "number"
		},
		{
			type: "address",
			question: "Address",
			inputType: "text"
		},
		{
			type: "options",
			question: "",
			options: [{ value: "Yes" }, { value: "No" }],
			label: "Yes/No Question"
		}
	];

	const customQuestions = [
		{
			type: "text",
			question: "",
			label: "Text"
		},
		{
			type: "textarea",
			question: "",
			label: "Text area"
		},
		{
			type: "options",
			question: "",
			label: "Dropdown select"
		},
		{
			type: "multiSelect",
			question: "",
			label: "Multi select"
		},
		{
			type: "checkbox",
			question: "",
			label: "Checkbox"
		},
		{
			type: "note",
			question: "",
			label: "Message"
		},
		{
			type: "image",
			question: "",
			label: "Upload Image/Logo"
		},
		{
			type: "terms",
			question: "Terms & Conditions",
			label: "Terms & Conditions",
			options: [{ type: "text" }, { type: "url" }, { type: "file" }]
		}
	];

	return (
		<Modal
			open={visible}
			title={<H4>Select Question Type</H4>}
			onCancel={handleCancel}
			footer={[]}
			className={css(modalWrapper)}
		>
			<div className={css(questions)}>
				<div className={css(questionArea)}>
					<H4>Prebuilt templates</H4>
					{templateQuestions.map((question, index) => (
						<LegacyButton
							key={index}
							onClick={() => addQuestion(question)}
							type="action"
							flavor="minimal"
							icon={{ name: "plus", left: true }}
							ariaLabel={question.label ? question.label : question.question}
							dataCy={question.label ? question.label : question.question}
						>
							{question.label ? question.label : question.question}
						</LegacyButton>
					))}
				</div>
				<div className={css(questionArea)}>
					<H4>Create your own</H4>
					{customQuestions.map((question, index) => (
						<LegacyButton
							key={index}
							onClick={() => addQuestion(question)}
							type="action"
							flavor="minimal"
							icon={{ name: "plus", left: true }}
							ariaLabel={question.label ? question.label : question.question}
						>
							{question.label ? question.label : question.question}
						</LegacyButton>
					))}
				</div>
			</div>
		</Modal>
	);
};
export default AddQuestionsModal;
