import axios from "./AxiosWrapper";

const TourService = {
	async getTours(userId) {
		const response = await axios.get(`tours/${userId}`);
		return response.data;
	},
	async saveTour(values) {
		const response = await axios.post(`tours/tour/`, values);
		return response.data;
	},
	async getTour(tourId) {
		const response = await axios.get(`tours/tour/${tourId}`);
		return response.data;
	},
	async updateTour(tourId, values) {
		const response = await axios.put(`tours/tour/${tourId}`, values);
		return response.data;
	},
	async deleteTour(tourId) {
		const response = await axios.delete(`tours/tour/${tourId}`);
		return response.data;
	},
	async publish(tourId) {
		const response = await axios.put(`tours/tour/${tourId}/publish`);
		return response.data;
	},
	async unpublish(tourId) {
		const response = await axios.put(`tours/tour/${tourId}/unpublish`);
		return response.data;
	}
};

export default TourService;
