import { ContentBlock } from "@/components/AppBlocks";
import ApplyToAllSave from "@/components/ApplyToAllSave";
import EventSettingsFields from "@/components/EventSettingsFields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import withSaveCheck from "@/hoc/withSaveCheck";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { applyDefaultSettings } from "@/state/actions/applyToAllActions";
import { getUser, saveUser } from "@/state/actions/userActions";
import { useAppSelector } from "@/state/hooks";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { InjectedFormProps, formValueSelector, reduxForm } from "redux-form";

const formName = "defaultUserForm";
const selector = formValueSelector(formName);

const defaultSettings = {
	sendTickets: true,
	ticketPriceRangeDisplay: true,
	showPricesBeforeSalesTax: false,
	ticketsRemainingDisplay: false,
	allowUserEdit: true,
	hideIsBusinessQuestion: true,
	customTimeout: 20,
	mailList: true,
	showOrganiserMailListOptIn: true,
	mobileRequired: true,
	hideAddToCalendar: false,
	requireEmailConfirmation: false,
	featured: false,
	featuredPriority: 0,
	disallowTrending: false,
	disableHumanitixGTM: false,
	enableTicketAssigning: false,
	allowAttendeeRefund: false,
	disallowRefundFees: true,
	datePickerLayout: "auto",
	disallowAdvertising: false
};

type UserEventSettings = {
	sendTickets: boolean;
	ticketPriceRangeDisplay: boolean;
	showPricesBeforeSalesTax: boolean;
	ticketsRemainingDisplay: boolean;
	allowUserEdit: boolean;
	hideIsBusinessQuestion: boolean;
	customTimeout?: number;
	mailList: boolean;
	showOrganiserMailListOptIn: boolean;
	mobileRequired: boolean;
	hideAddToCalendar: boolean;
	requireEmailConfirmation: boolean;
	featured: boolean;
	featuredPriority: number;
	disallowTrending: boolean;
	disableHumanitixGTM: boolean;
	enableTicketAssigning: boolean;
	allowAttendeeRefund: boolean;
	disallowRefundFees: boolean;
	datePickerLayout: "auto" | "calendar" | "dropdown";
	disallowAdvertising: boolean;
	maximumTicketsPerOrder?: number;
};

type DefaultSettingsFormValues = {
	defaultEventSettings: UserEventSettings;
};

type DefaultSettingsFormComponentProps = PropsFromRedux &
	InjectedFormProps & {
		userId?: string;
	};

const DefaultSettingsFormComponent = ({
	getUser,
	userId,
	saveUser,
	handleSubmit,
	applyDefaultSettings
}: DefaultSettingsFormComponentProps) => {
	const currentUserState = useAppSelector((state) => state.currentUser);
	const { isAdmin } = useCurrentUser();
	const sendTickets = useAppSelector((state) => selector(state, "sendTickets"));
	const afterCheckoutRedirectEnabled = useAppSelector((state) =>
		selector(state, "defaultEventSettings.afterCheckoutRedirect.enabled")
	);
	const disableAdditionalParameters = useAppSelector((state) =>
		selector(state, "defaultEventSettings.afterCheckoutRedirect.disableAdditionalParameters")
	);

	const _loadData = () => {
		getUser(userId);
	};

	useEffect(() => {
		_loadData();
	}, []);

	const _save = (values: Partial<DefaultSettingsFormValues>) => {
		saveUser(userId, {
			applyToAllRequired: true,
			defaultEventSettings: {
				...values.defaultEventSettings,
				customTimeout:
					values.defaultEventSettings?.customTimeout !== 0 ? values.defaultEventSettings?.customTimeout : null,
				maximumTicketsPerOrder:
					values.defaultEventSettings?.maximumTicketsPerOrder !== 0
						? values.defaultEventSettings?.maximumTicketsPerOrder
						: null
			}
		});
	};

	const _applyDefaultSettings = () => {
		applyDefaultSettings(isAdmin ? userId : undefined);
	};

	return (
		<LoadErrorView loading={currentUserState.loading} error={currentUserState.error as any} retryAction={_loadData}>
			<PageHeading title="Default settings" />
			<form onSubmit={handleSubmit(_save)}>
				<ContentBlock>
					<EventSettingsFields
						isAdmin={isAdmin}
						path="defaultEventSettings"
						showSendTicketPerTicket={false}
						sendTickets={sendTickets}
						afterCheckoutRedirectEnabled={afterCheckoutRedirectEnabled}
						disableAdditionalParameters={disableAdditionalParameters}
						formName={formName}
						userLocation={currentUserState.user?.location}
					/>
				</ContentBlock>
				<FormBar>
					<ApplyToAllSave onOk={_applyDefaultSettings} />
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

const mapStateToProps = (state: GlobalReduxState) => {
	const defaultEventSettings = state?.currentUser?.user?.defaultEventSettings ?? defaultSettings;
	return {
		initialValues: {
			defaultEventSettings
		}
	};
};

const mapDispatchToProps = {
	getUser,
	saveUser,
	applyDefaultSettings
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const FormWithRedux = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(DefaultSettingsFormComponent);

const DefaultSettings = connector(withSaveCheck(FormWithRedux, formName));

export { DefaultSettings };
