import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getTicketDisplayName } from "../../utils/Ticket";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

class EarningsByTicketType extends Component {
	constructor(props) {
		super(props);
		this.getFilterOptions = this.getFilterOptions.bind(this);
	}

	getFilterOptions() {
		const { filters = {} } = this.props.reportState;
		const { ticketTypes = [] } = filters;

		return [
			{
				key: "ticketOrPackageTypeIds",
				label: "Select ticket types",
				mode: "multiple",
				options: ticketTypes
					.map((tt) => ({
						value: tt._id,
						name: getTicketDisplayName(tt)
					}))
					.filter((tt) => !tt.value.startsWith("package-"))
			}
		];
	}

	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;

		const { load, loadPage, downloadCsv } = this.props;

		const columns = [
			{
				title: "Event name",
				dataIndex: "eventName",
				key: "eventName",
				render: (eventName, record) => {
					return <Link to={`/console/my-events/${record._id}`}>{eventName}</Link>;
				}
			},
			{
				title: "Ticket type",
				dataIndex: "ticketTypeName",
				key: "ticketTypeName"
			},
			{
				title: "Event Date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "Event Time",
				dataIndex: "time",
				key: "time"
			},
			{
				title: "Capacity",
				dataIndex: "capacity",
				key: "capacity"
			},
			{
				title: "Sold",
				dataIndex: "sold",
				key: "sold"
			},
			{
				title: "Available",
				dataIndex: "available",
				key: "available"
			},
			{
				title: "Earnings",
				dataIndex: "earnings",
				key: "earnings"
			}
		];
		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						firstDateName="eventDates"
						firstDateLabel="Event date range"
						filterOptions={this.getFilterOptions()}
						showSearchField={false}
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							{results.length > 0 && (
								<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
									{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
								</LegacyButton>
							)}
						</div>
						<Table
							columns={columns}
							dataSource={results}
							rowKey={(o) => o.eventDateId + o.ticketTypeId}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default withReportProvider(EarningsByTicketType, "Earnings by ticket type", "earnings-by-ticket-type");
