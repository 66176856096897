import {
	GET_GIFT_CARD_ERROR,
	GET_GIFT_CARD_LIST_ERROR,
	GET_GIFT_CARD_LIST_REQUEST,
	GET_GIFT_CARD_LIST_SUCCESS,
	GET_GIFT_CARD_PAGE_ERROR,
	GET_GIFT_CARD_PAGE_REQUEST,
	GET_GIFT_CARD_PAGE_SUCCESS,
	GET_GIFT_CARD_REPORT_CSV_ERROR,
	GET_GIFT_CARD_REPORT_CSV_REQUEST,
	GET_GIFT_CARD_REPORT_CSV_SUCCESS,
	GET_GIFT_CARD_REQUEST,
	GET_GIFT_CARD_SOLD_ERROR,
	GET_GIFT_CARD_SOLD_REQUEST,
	GET_GIFT_CARD_SOLD_SUCCESS,
	GET_GIFT_CARD_SUCCESS,
	SAVE_GIFT_CARD_ERROR,
	SAVE_GIFT_CARD_PAGE_ERROR,
	SAVE_GIFT_CARD_PAGE_REQUEST,
	SAVE_GIFT_CARD_PAGE_SUCCESS,
	SAVE_GIFT_CARD_REQUEST,
	SAVE_GIFT_CARD_SUCCESS
} from "../actions/giftCardActions";

const initialState = {
	loading: false,
	error: false,
	giftCardList: [],
	giftCardData: false,
	giftCardPage: false,
	report: [],
	page: 1,
	count: 0,
	hasMoreResults: false,
	csv: {
		loading: false,
		error: false
	},
	soldGiftCards: [],
	bulkUpload: {
		loading: false,
		error: false,
		success: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_GIFT_CARD_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_GIFT_CARD_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case GET_GIFT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				giftCardData: action.giftCardData
			};
		case GET_GIFT_CARD_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_GIFT_CARD_LIST_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case GET_GIFT_CARD_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				giftCardList: action.giftCardList
			};
		case SAVE_GIFT_CARD_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_GIFT_CARD_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case SAVE_GIFT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				giftCardList: action.giftCardList
			};

		case GET_GIFT_CARD_REPORT_CSV_SUCCESS:
			return {
				...state,
				csv: {
					loading: false,
					error: false
				}
			};
		case GET_GIFT_CARD_REPORT_CSV_REQUEST:
			return {
				...state,
				csv: {
					loading: true,
					error: false
				}
			};
		case GET_GIFT_CARD_REPORT_CSV_ERROR:
			return {
				...state,
				csv: {
					loading: false,
					error: true
				}
			};
		case GET_GIFT_CARD_PAGE_ERROR: {
			return {
				...state,
				error: action.error
			};
		}
		case GET_GIFT_CARD_PAGE_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}
		case GET_GIFT_CARD_PAGE_SUCCESS: {
			return {
				...state,
				giftCardPage: action.giftCardPage,
				loading: false
			};
		}
		case SAVE_GIFT_CARD_PAGE_ERROR: {
			return {
				...state,
				error: action.error,
				loading: false
			};
		}
		case SAVE_GIFT_CARD_PAGE_SUCCESS: {
			return {
				...state,
				loading: false,
				giftCardPage: action.giftCardPage
			};
		}
		case SAVE_GIFT_CARD_PAGE_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}

		case GET_GIFT_CARD_SOLD_ERROR: {
			return {
				...state,
				loading: false,
				error: action.error
			};
		}
		case GET_GIFT_CARD_SOLD_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}
		case GET_GIFT_CARD_SOLD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				soldGiftCards: action.soldGiftCards,
				page: action.page,
				count: action.count
			};
		}
		default:
			return state;
	}
};
