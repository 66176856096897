import { css } from "@emotion/css";
import { Slider } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";

const styles = {
	container: css`
		& .ant-tooltip-inner {
			background: #57cfc7;
			color: #323232;
		}
		& .ant-tooltip-arrow {
			&:before {
				background: #57cfc7;
			}
		}
	`
};

const SliderField = ({
	input,
	label,
	rows,
	description,
	placeholder,
	inline,
	required,
	disabled,
	tooltip,
	min = 0,
	defaultValue = 100,
	meta: { touched, error }
}) => {
	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
		>
			<div
				style={{
					position: "relative",
					padding: "0px 40px 0px 30px",
					width: 282
				}}
				id="slider-container"
				className={styles.container}
			>
				<div style={{ position: "absolute", top: -2, left: 0 }}>{min}%</div>
				<Slider
					id={input.name}
					{...input}
					placeholder={placeholder}
					rows={rows}
					disabled={disabled}
					min={min}
					defaultValue={defaultValue}
					tooltip={{
						getPopupContainer: () => document.querySelector("div#slider-container")
					}}
				/>
				<div style={{ position: "absolute", top: -2, right: 0 }}>100%</div>
			</div>
		</InputWrapper>
	);
};

export default SliderField;
