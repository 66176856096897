import { Component } from "react";
import { CustomerManager, ElementEditor, ElementManager, ElementSelector, TicketMappingManager } from "./Panels";

export default class ControlPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data,
			type: props.type
		};
		this.elementSelected = this.elementSelected.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ data: nextProps.data, type: nextProps.type });
	}

	elementSelected() {
		this.props.removeSelected();
		this.setState({ data: [] });
	}

	determinePanel() {
		const { view, addUndoAction, openManageCapacityModal } = this.props;
		const { data, type } = this.state;

		if (view === "seatingView") {
			if (data.length > 0) {
				return <ElementEditor elements={data} type={type} addUndoAction={addUndoAction} />;
			}

			return <ElementSelector showSelection={data.length <= 0} onElementSelection={this.elementSelected} />;
		}

		if (view === "ticketMapping") {
			return (
				<TicketMappingManager
					elements={data}
					addUndoAction={addUndoAction}
					openManageCapacityModal={openManageCapacityModal}
				/>
			);
		}

		if (view === "managerView") {
			return <ElementManager />;
		}

		if (view === "customerView") {
			return <CustomerManager />;
		}
		return null;
	}

	render() {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					position: "absolute",
					backgroundColor: "white",
					width: 300,
					top: 0,
					bottom: 0,
					overflowY: "auto"
				}}
			>
				{this.determinePanel()}
			</div>
		);
	}
}
