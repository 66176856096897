import { SALESFORCE_TEST_ERROR, SALESFORCE_TEST_REQUEST, SALESFORCE_TEST_SUCCESS } from "../actions/salesforceActions";

const initialState = {
	test: {
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case SALESFORCE_TEST_REQUEST:
			return {
				...state,
				test: {
					...state.test,
					loading: true,
					error: false
				}
			};
		case SALESFORCE_TEST_SUCCESS:
			return {
				...state,
				test: {
					...state.test,
					loading: false,
					error: false
				}
			};
		case SALESFORCE_TEST_ERROR:
			return {
				...state,
				test: {
					...state.test,
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
