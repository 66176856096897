import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import { validate } from "@/events/event/virtualEventHub/VEHValidator";
import EnabledModal from "@/events/event/virtualEventHub/components/EnabledModal";
import { HubSettings } from "@/events/event/virtualEventHub/components/HubSettings";
import PreviewModal from "@/events/event/virtualEventHub/components/PreviewModal";
import VirtualEventTabs from "@/events/event/virtualEventHub/components/Tabs";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import {
	clearVirtualEventHub,
	createVirtualEventHub,
	enableVirtualEventHub,
	getVirtualEventHub,
	openPreviewModal,
	saveVirtualEventHub
} from "@/state/actions/virtualEventHubActions";
import { Button, Spacer } from "@/ui";
import { Flex, Modal, notification } from "@/ui/antd";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";

const formName = "virtualEventsHub";
const { confirm } = Modal;

class VirtualEventsHub extends Component {
	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		const { clearVirtualEventHub } = this.props;
		clearVirtualEventHub();
	}

	openPreview = () => {
		const { openPreviewModal } = this.props;
		openPreviewModal();
	};

	load = () => {
		const { params, getVirtualEventHub } = this.props;
		const eventId = params?.eventId;
		getVirtualEventHub(eventId);
	};

	save = (values) => {
		const { params, saveVirtualEventHub, currentEvent } = this.props;
		const eventId = params?.eventId;
		const result = validate(
			values,
			currentEvent.event.ticketTypes.concat(currentEvent.event.packagedTickets),
			currentEvent.event.dates
		);
		if (result.valid) {
			saveVirtualEventHub(eventId, values);
		} else {
			notification.error({
				message: "Errors",
				description: result.errors.join(", ")
			});
		}
	};

	create = () => {
		const { params, createVirtualEventHub } = this.props;
		const eventId = params?.eventId;
		createVirtualEventHub(eventId);
	};

	enable = () => {
		const { params, enableVirtualEventHub } = this.props;
		const eventId = params?.eventId;
		enableVirtualEventHub(eventId);
	};

	disable = () => {
		const { params, saveVirtualEventHub } = this.props;
		const eventId = params?.eventId;
		confirm({
			title: `Are you sure you would like to disable your virtual event`,
			onOk: () => {
				saveVirtualEventHub(eventId, { enabled: false });
			},
			onCancel() {
				null;
			}
		});
	};

	changeFieldValue = (field, value) => {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	};

	render() {
		const { virtualEventHub, settings, handleSubmit } = this.props;
		return (
			<LoadErrorView loading={virtualEventHub.loading} error={virtualEventHub.error}>
				<PageHeading
					title="Virtual event hub"
					helpButton={{
						link: HelpIds.virtualEventsHub,
						title: "Help - Virtual event hub"
					}}
				/>

				{!virtualEventHub || !virtualEventHub.data ? (
					<ContentBlock>
						<P>
							Create a virtual hub landing page for your online event. Your webinar links, videos, and additional
							content will be available only for your online attendees.
							<br />
							This virtual hub is accessible via the Online Access link on the attendees digital ticket.
						</P>
						<LegacyButton type="action" ariaLabel="Create" size="large" onClick={this.create}>
							Create
						</LegacyButton>
					</ContentBlock>
				) : (
					<>
						<PreviewModal />
						<EnabledModal />
						<form onSubmit={handleSubmit(this.save)}>
							<ContentBlock allowOverflow>
								<Flex gap="sm" justify="space-between" wrap>
									<P>
										Select elements you wish to appear on your virtual event hub. Under each elements settings, edit how
										and when that content can be accessed.
									</P>

									<Flex gap="sm" justify="flex-end" flex={1}>
										<HubSettings virtualEventHub={virtualEventHub} settings={settings} formName={formName} />
										{!virtualEventHub.data.enabled ? (
											<Button type="action" aria-label="Enable" onClick={this.enable} variant="tertiary">
												Enable
											</Button>
										) : (
											<Button aria-label="Disable" onClick={this.disable} variant="danger" outlined>
												Disable
											</Button>
										)}
									</Flex>
								</Flex>
								<Spacer size="sm" />
								<VirtualEventTabs changeFieldValue={this.changeFieldValue} />
							</ContentBlock>
							<FormBar formLayout="horizontal">
								<LegacyButton
									style={{
										float: "right"
									}}
									type="primary"
									htmlType="submit"
									ariaLabel="Save"
									size="large"
								>
									Save
								</LegacyButton>
								<LegacyButton
									style={{
										float: "right",
										marginRight: 12
									}}
									onClick={this.openPreview}
									htmlType="button"
									ariaLabel="Preview"
									size="large"
								>
									Preview
								</LegacyButton>
							</FormBar>
						</form>
					</>
				)}
			</LoadErrorView>
		);
	}
}

VirtualEventsHub = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(VirtualEventsHub);

const selector = formValueSelector("virtualEventsHub");
export default connect(
	(state) => {
		const initData = state.virtualEventHub.data ? state.virtualEventHub.data : { settings: { showWhen: "always" } };
		if (!initData.settings) {
			initData.settings = { showWhen: "always" };
		}
		return {
			initialValues: initData,
			currentEvent: state.currentEvent,
			virtualEventHub: state.virtualEventHub,
			settings: selector(state, "settings")
		};
	},
	{
		getVirtualEventHub,
		createVirtualEventHub,
		enableVirtualEventHub,
		saveVirtualEventHub,
		openPreviewModal,
		clearVirtualEventHub
	}
)(withRouterHooks(VirtualEventsHub));
