import Reaptcha from "reaptcha";
import InputWrapper from "./helpers/InputWrapper";

const RecaptchaField = ({
	input,
	label,
	description,
	inline,
	noPadding,
	required,
	labelAfter,
	noMargin,
	tooltip,
	meta: { touched, error }
}) => {
	input.value = input.value === "true" ? true : input.value === "false" ? false : input.value;
	const onVerify = (value) => {
		input.onChange(value);
	};
	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			noPadding={noPadding}
			id={input.name}
			inline={inline}
			required={required}
			labelAfter={labelAfter}
			noMargin={noMargin}
			tooltip={tooltip}
		>
			<Reaptcha sitekey="6LfhLHYUAAAAADPr8fQNSWpBfl9vmqxknJmDof0D" onVerify={onVerify} />
		</InputWrapper>
	);
};

export default RecaptchaField;
