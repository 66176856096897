import { Alert } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { FileUploadField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { loadCsv } from "@/utils/FileUtils";
import { scrollToErrors } from "@/utils/ScrollToErrors";
class LoadFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: []
		};
	}

	upload = async (values) => {
		let csv = [];
		for (let i = 0; i < values.csvFiles.length; i++) {
			let file = await loadCsv(values.csvFiles[i]);
			if (i !== 0) {
				file.shift();
			}
			csv = csv.concat(file);
		}
		this.props.onNextStep({
			csv
		});
	};

	render() {
		const { handleSubmit } = this.props;
		const errors = this.state.errors;
		return (
			<div>
				<form onSubmit={handleSubmit(this.upload)}>
					<ContentBlock>
						{errors.length > 0 ? (
							<Alert
								style={{ marginBottom: 12 }}
								message="CSV file invalid"
								description={
									<div>
										{errors.map((error) => (
											<div>
												* {error}
												<br />
											</div>
										))}
									</div>
								}
								type="error"
								showIcon
							/>
						) : null}

						<Field
							name="csvFiles"
							label="Upload a CSV"
							description="Select a payment csv from our gateways to compare the totals to the values in our system"
							component={FileUploadField}
							multiple={true}
						/>
					</ContentBlock>
					<FormBar sticky={false}>
						<LegacyButton
							style={{
								float: "right"
							}}
							key="submit"
							type="primary"
							htmlType="submit"
							ariaLabel="Save"
						>
							Load
						</LegacyButton>
					</FormBar>
				</form>
			</div>
		);
	}
}

const formName = "comaprePayments";
LoadFile = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(LoadFile);

LoadFile = connect(() => {
	return {
		initialValues: {}
	};
})(LoadFile);

export default connect(() => ({}), {})(LoadFile);
