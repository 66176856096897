import { CodeBlock, DateTimePicker, DateTimePickerResult, HR } from "@/ui";
import { Alert, Flex } from "@/ui/antd";
import { FC, useState } from "react";

export const DateTimePickerPage: FC = () => {
	const [selectedDate, setSelectedDate] = useState<DateTimePickerResult>("2021-09-01T00:00:00.000Z");
	const [lastSelectedValue, setLastSelectedValue] = useState<string | undefined>(undefined);

	const timezone = "America/Denver";

	const handleDateChange = (value: DateTimePickerResult) => {
		setSelectedDate(value);
		setLastSelectedValue(JSON.stringify(value));
	};

	const handleDateChangeSampleCode = `	import { DateTimePicker, DateTimePickerResult } from "@/ui";

	const [selectedDate, setSelectedDate] = useState<DateTimePickerResult>("2021-09-01T00:00:00.000Z");

	const handleDateChange = (value: DateTimePickerResult) => {
		console.log("Date changed to: ", value);
		setSelectedDate(value);
	};

	return <DateTimePicker onChange={handleDateChange} value={time} />`;

	return (
		<Flex align="start" gap="md" vertical wide>
			<Flex gap="md">
				<Flex gap="md" vertical>
					<p>
						<code>DateTimePicker</code> is a wrapper around Ant Design's{" "}
						<a href="https://ant.design/components/date-picker/" target="_blank" rel="noreferrer">
							DateTimePicker
						</a>{" "}
						component that provides a more consistent interface for date selection.
					</p>
					<p>
						We always store time in UTC and keep the relevant timezone handy for display and selection purposes. When a
						user selects a time, they are chosing a time in the timezone you have specified. The returned result will be
						the ISO string of the selected time in UTC.
					</p>
					<p>
						E.g Denver is 6 hours behind UTC. If a user selects 1:00 PM with the Denver timezone set, the returned ISO
						will be YYYY-MM-DDT19:00:00.000Z. To select in users local time, omit the timezone prop.
					</p>
				</Flex>
			</Flex>
			<HR />
			<b>Date lifecycle</b>
			<p style={{ maxWidth: "unset", textAlign: "center" }}>
				<b>Database:</b> ISO string <br />
				⬇️
				<br />
				<b>API:</b> ISO string <br />
				⬇️
				<br />
				<b>Frontend:</b> ISO string <br />
				⬇️
				<br />
				<b>Frontend date picker:</b> Appears in the provided timezone. E.g YYYY-MM-DDT19:00:00.000Z provided and Denver
				timezone, user will see 1:00 PM <br />
				⬇️
				<br />
				<b>Frontend date picker selection:</b> Selection made in provided timezone <br />
				⬇️
				<br />
				<b>DateTimePicker result:</b> ISO string in UTC representing the selection made in the timezone - E.g Selected
				5pm Denver time, result will be 11pm UTC <br />
				⬇️
				<br />
				<b>API:</b> ISO string <br />
				⬇️
				<br />
				<b>Database:</b> ISO string
			</p>
			<HR />
			<div style={{ top: 0, right: 0, position: "sticky", zIndex: 10 }}>
				<Alert message={`Last selected value: ${lastSelectedValue}`} showIcon type="info" />
			</div>
			<b>DateTimePicker (Single date)</b>
			<DateTimePicker onChange={handleDateChange} value={selectedDate} />
			<CodeBlock codeString={handleDateChangeSampleCode} fullWidth />
			<HR />
			<b>DateTimePicker (Single date with time)</b>
			<DateTimePicker onChange={handleDateChange} showTime value={selectedDate} />
			<CodeBlock codeString="<DateTimePicker onChange={handleDateChange} showTime value={time} />" fullWidth />
			<HR />
			<b>RangePicker (Date only)</b>
			<DateTimePicker onChange={handleDateChange} value={selectedDate} />
			<CodeBlock codeString="<DateTimePicker onChange={handleDateChange} value={time} />" fullWidth />
			<HR />
			<b>RangePicker (With time)</b>
			<DateTimePicker onChange={handleDateChange} showTime value={selectedDate} />
			<CodeBlock codeString="<DateTimePicker onChange={handleDateChange} showTime value={time} />" fullWidth />
			<HR />
			<b>RangePicker (With time and timezone)</b>
			<span>
				Denver time now:{" "}
				<code>
					{new Date().toLocaleString("en-US", { timeZone: timezone, dateStyle: "medium", timeStyle: "short" })}
				</code>
				<br />
				Denver is 6 hours behind UTC. So the returned ISO should be 6 hours ahead of what is displayed in the date
				picker.
			</span>
			<DateTimePicker onChange={handleDateChange} showTime timezone={timezone} value={selectedDate} />
			<CodeBlock
				codeString={`<DateTimePicker onChange={handleDateChange} showTime timezone="${timezone}" value={time} />`}
				fullWidth
			/>
		</Flex>
	);
};
