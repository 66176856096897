import { DateField, SwitchField } from "@/components/Fields";
import { ReverseData, deletePayment } from "@/state/actions/newPayoutActions";
import { ReduxState } from "@/state/store";
import { required } from "@/utils/Validators";
import { Modal } from "@/ui/antd";
import React from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, formValueSelector, reduxForm } from "redux-form";

const reversedAtDateRequired = required("A reversed at date is required");

type ICombinedProps = IProps & IInjectedProps & IInjectedFunctions;

const DeletePaymentModal: React.FunctionComponent<ICombinedProps & InjectedFormProps<ReverseData, ICombinedProps>> = ({
	paymentId,
	handleSubmit,
	close,
	reversed,
	deletePayment,
	eventId,
	isOpen
}) => {
	if (!eventId || !paymentId) return null;

	const save = (values: ReverseData) => {
		deletePayment(eventId, paymentId, values);
		close();
	};

	return (
		<Modal
			open={isOpen}
			destroyOnClose={true}
			onCancel={close}
			okText="Delete payment"
			title="Delete payment"
			onOk={handleSubmit(save)}
		>
			<form>
				<Field name="reversed" label="Was this payment reversed" component={SwitchField} />
				{reversed ? (
					<Field
						name="reversedAt"
						label="Select date of reversal"
						component={DateField}
						required
						validate={reversedAtDateRequired}
					/>
				) : null}
			</form>
		</Modal>
	);
};

const formName = "deletePayment";

const selector = formValueSelector(formName);

interface IInjectedProps {
	initialValues: ReverseData;
	reversed: boolean;
	eventId?: string;
}

interface IProps {
	paymentId: string | null;
	close: () => void;
	isOpen: boolean;
}

interface IInjectedFunctions {
	deletePayment: (eventId: string, paymentId: string, data: ReverseData) => void;
}

export default connect(
	(state: ReduxState) => {
		return {
			initialValues: { reversed: false },
			reversed: selector(state, "reversed"),
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ deletePayment }
)(
	reduxForm<ReverseData, ICombinedProps>({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(DeletePaymentModal)
);
