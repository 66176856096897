import { useViewport } from "@/hooks/useViewport";
import { FC, ReactNode } from "react";

type ResponsiveProps = {
	desktop?: ReactNode;
	mobile?: ReactNode;
};

export const Responsive: FC<ResponsiveProps> = ({
	desktop: desktopComponent = null,
	mobile: mobileComponent = null
}) => {
	const { isMobile, isTablet } = useViewport();

	return <>{isMobile || isTablet ? mobileComponent : desktopComponent}</>;
};
