import { CameraOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button } from "@/ui/antd";
import hexToRgba from "hex-rgba";

/**
 * Mini preview for tour styling
 *
 * @param {*} props
 * @returns
 */
const MiniPreview = (props) => {
	const { theme } = props;
	const {
		primaryColor = "#353337",
		footerColor = "#8E99A8",
		ticketButtonText = "Get tickets",
		titleBackgroundColor = "#fff",
		titleTextColor = "#323232",
		background,
		primaryTextColor,
		ticketButtonDisplay = "filled",
		sponsorBoxBackgroundColor = "#fff",
		cardAndBarOpacity = 100,
		eventCardBackgroundColor = "#fff"
	} = theme || {};
	let backgroundStyle;
	if (background && background.type && background.type !== "") {
		switch (background.type) {
			case "image":
				backgroundStyle = {
					background:
						background.image && background.image.url
							? `url(${background.image.url}) no-repeat center #eee`
							: background.color,
					backgroundSize: "cover"
				};
				break;
			case "pattern":
				backgroundStyle = {
					background: background.image && background.image.url ? `url(${background.image.url})` : background.color,
					backgroundSize: "35%"
				};
				break;
			default: {
				const backgroundColor = background.color ? background.color : "#fafafa";
				backgroundStyle = {
					backgroundColor
				};
				break;
			}
		}
	}

	const card = css`
		width: 100%;
		max-width: 360px;
		padding: 5px;
		border-radius: 8px;
		border: 2px solid #dedede;
		float: right;
		margin-bottom: 20px;
	`;

	const container = css`
		border: 1px solid #eee;
		margin: 0;
		${backgroundStyle}
	`;

	const topContainer = css`
		width: 260px;
		margin: auto;
	`;

	const icon = css`
		font-size: 40px;
		color: #eee;
		padding-top: 45px;
	`;

	const button = css`
		width: 33px !important;
		height: 10px !important;
		font-size: 4px !important;
		border-radius: 2px !important;
		padding: 0 2px;
		background: ${primaryColor};
		color: ${ticketButtonDisplay === "outlined" ? "#000" : primaryTextColor};
		border: 1px solid ${primaryColor}!important;
		margin-top: 5px;
		margin-right: 10px;
	`;

	const headerLine = css`
		border-top: 4px solid ${titleTextColor};
		border-radius: 50px;
		width: 70px;
		margin-left: auto;
		margin-right: auto;
	`;

	const linkLine = css`
		border-top: 3px solid ${primaryColor};
		border-radius: 50px;
		width: 21px;
	`;

	const veryShortLine = css`
		border-top: 3px solid #d8d8d8;
		border-radius: 50px;
		width: 20px;
		margin-bottom: 5px;
		margin-right: 5px;
		display: inline-block;
	`;

	const middleLine = css`
		border-top: 2px solid #d8d8d8;
		border-radius: 50px;
		width: 40px;
		margin-bottom: 5px;
		margin-right: 5px;
		display: inline-block;
		line-height: 0px;
	`;

	const circle = css`
		border-radius: 50%;
		width: 10px;
		height: 10px;
		margin-right: 3px;
		background-color: #d8d8d8;
		display: inline-block;
	`;

	const shortLine = css`
		border-top: 2px solid #d8d8d8;
		border-radius: 20px;
		width: 20px;
		margin-bottom: 4px;
	`;

	const shortThickLine = css`
		border-top: 4px solid #d8d8d8;
		border-radius: 50px;
		width: 40px;
		margin-bottom: 10px;
		margin-right: 5px;
		display: inline-block;
	`;

	const longThickLine = css`
		border-top: 3px solid #d8d8d8;
		border-radius: 50px;
		width: 55px;
		margin-bottom: 3px;
	`;

	const middleThickLine = css`
		border-top: 4px solid #d8d8d8;
		border-radius: 50px;
		width: 40px;
		margin-bottom: 3px;
	`;

	const footerContainer = css`
		height: 58px;
		background: ${footerColor};
	`;

	/*const previewDiv = css`
    padding-left: 40px;
    text-align: center;
  `;*/

	const bannerWrapper = css({
		width: "100%"
	});

	const bannerImage = css({
		width: 260,
		height: 130,
		backgroundColor: "#cce5ff",
		textAlign: "center"
	});

	const secondHeaderRow = css`
		width: 260px;
		height: 24px;
		padding-top: 10px;
		background-color: ${hexToRgba(titleBackgroundColor, cardAndBarOpacity)};
	`;
	const thirdHeaderRow = css`
		width: 260px;
		height: 18px;
		margin-bottom: 12px;
		line-height: 0px;
		background-color: ${hexToRgba(eventCardBackgroundColor, cardAndBarOpacity)};
		padding-left: 6px;
		padding-top: 5px;
		box-shadow: rgba(9, 30, 66, 0.23) 0px -1px 10px 0px;
	`;
	const secondRow = css`
		width: 100%;
		height: 14px;
		background-color: ${hexToRgba(eventCardBackgroundColor, cardAndBarOpacity)};
		margin-bottom: 8px;
		padding-top: 3px;
		padding-left: 6px;
		border-radius: 4px;
	`;
	const hr = css`
		width: 100%;
		border-top: 1px solid #d8d8d8;
	`;
	const logoBox = css`
		width: 40px;
		height: 24px;
		border-radius: 4px;
		background-color: #d8d8d8;
		margin: 2px;
		text-align: center;
		padding: 9px;
		color: white;
		font-size: 5px;
	`;

	return (
		<div>
			<div className={card}>
				<div className={container}>
					<div className={topContainer}>
						<div className={bannerWrapper}>
							<div className={bannerImage}>
								<CameraOutlined className={icon} />
							</div>
							<div className={secondHeaderRow}>
								<div className={headerLine} />
							</div>
							<div className={thirdHeaderRow}>
								<div className={veryShortLine} />
								<div className={veryShortLine} />
								<div className={linkLine} />
							</div>
						</div>

						<div className={secondRow}>
							<div className={shortThickLine} />
							<div className={shortThickLine} />
						</div>

						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div
								style={{
									backgroundColor: hexToRgba(eventCardBackgroundColor, cardAndBarOpacity),
									width: 190,
									borderRadius: 4
								}}
							>
								<div className={middleLine} style={{ marginLeft: 6 }} />
								<div className={hr} />
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<div style={{ width: 30, paddingTop: 10, paddingLeft: 6 }}>
										<div className={shortLine} />
										<div className={shortLine} />
									</div>
									<div style={{ paddingTop: 6 }}>
										<div className={middleThickLine} />
										<div className={longThickLine} />
										<div className={longThickLine} />
									</div>
									<div style={{ width: 40 }}>
										<Button
											size="small"
											className={button}
											ghost={ticketButtonDisplay === "outlined"}
											ariaLabel={ticketButtonText}
										>
											{ticketButtonText}
										</Button>
									</div>
								</div>
								<div className={hr} />
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<div style={{ width: 30, paddingTop: 10, paddingLeft: 6 }}>
										<div className={shortLine} />
										<div className={shortLine} />
									</div>
									<div style={{ paddingTop: 6 }}>
										<div className={middleThickLine} />
										<div className={longThickLine} />
										<div className={longThickLine} />
									</div>
									<div style={{ width: 40 }}>
										<Button
											size="small"
											className={button}
											ghost={ticketButtonDisplay === "outlined"}
											ariaLabel={ticketButtonText}
										>
											{ticketButtonText}
										</Button>
									</div>
								</div>
								<div className={hr} />
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<div style={{ width: 30, paddingTop: 10, paddingLeft: 6 }}>
										<div className={shortLine} />
										<div className={shortLine} />
									</div>
									<div style={{ paddingTop: 6 }}>
										<div className={middleThickLine} />
										<div className={longThickLine} />
										<div className={longThickLine} />
									</div>
									<div style={{ width: 40 }}>
										<Button
											size="small"
											className={button}
											ghost={ticketButtonDisplay === "outlined"}
											ariaLabel={ticketButtonText}
										>
											{ticketButtonText}
										</Button>
									</div>
								</div>
								<div className={hr} />
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<div style={{ width: 30, paddingTop: 10, paddingLeft: 6 }}>
										<div className={shortLine} />
										<div className={shortLine} />
									</div>
									<div style={{ paddingTop: 6 }}>
										<div className={middleThickLine} />
										<div className={longThickLine} />
										<div className={longThickLine} />
									</div>
									<div style={{ width: 40 }}>
										<Button
											size="small"
											className={button}
											ghost={ticketButtonDisplay === "outlined"}
											ariaLabel={ticketButtonText}
										>
											{ticketButtonText}
										</Button>
									</div>
								</div>
							</div>
							<div
								style={{
									width: 62,
									height: 43,
									paddingTop: 9,
									paddingLeft: 5,
									borderRadius: 4,
									backgroundColor: hexToRgba(eventCardBackgroundColor, cardAndBarOpacity)
								}}
							>
								<div className={veryShortLine} style={{ display: "block", marginBottom: 5 }} />
								<div className={circle} />
								<div className={circle} />
								<div className={circle} />
								<div className={circle} />
							</div>
						</div>

						<div
							style={{
								marginTop: 10,
								fontSize: 4,
								width: 190,
								textAlign: "right",
								marginBottom: 19
							}}
						>
							<LeftOutlined style={{ color: "#dedede", size: 5 }} />
							<div
								style={{
									width: 7,
									height: 7,
									backgroundColor: primaryColor,
									color: primaryTextColor,
									borderRadius: 2,
									display: "inline-block",
									padding: 1,
									textAlign: "center"
								}}
							>
								1
							</div>{" "}
							2 3 4 <RightOutlined style={{ color: "#323232", size: 5 }} />
						</div>
					</div>
					<div
						style={{
							height: 40,
							display: "flex",
							justifyContent: "center",
							backgroundColor: sponsorBoxBackgroundColor,
							paddingTop: 7
						}}
					>
						<div className={logoBox}>logo</div>
						<div className={logoBox}>logo</div>
						<div className={logoBox}>logo</div>
						<div className={logoBox}>logo</div>
					</div>
					<div className={footerContainer} />
				</div>
			</div>
		</div>
	);
};

export default MiniPreview;
