import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { CheckboxField, DateField } from "@/components/Fields";
import { required } from "@/utils/Validators";

const reversedAtRequired = required("A reversed date is required");

class PaymentRemoveForm extends Component {
	delete = (values) => {
		const { deleteFunction, payment, userId, handleCancel } = this.props;
		deleteFunction(userId, {
			_id: payment._id,
			...values
		});
		handleCancel();
	};

	render() {
		const { handleSubmit, reversed } = this.props;
		return (
			<form onSubmit={handleSubmit(this.delete)} id="removePaymentForm">
				<Field name="reversed" label="Was the payment reversed?" component={CheckboxField} inline labelAfter />

				{reversed ? (
					<Field
						name="reversedAt"
						label="On what date was it reversed?"
						component={DateField}
						validate={reversedAtRequired}
					/>
				) : null}
			</form>
		);
	}
}
const formName = "removePaymentForm";
const selector = formValueSelector(formName);
// Decorate with redux-form
PaymentRemoveForm = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(PaymentRemoveForm);

export default connect((state) => ({
	reversed: selector(state, "reversed")
}))(PaymentRemoveForm);
