import { css } from "@emotion/css";
import React from "react";

const H2 = ({ style, children }: { style?: Record<string, unknown>; children: React.ReactNode }) => {
	const customStyle = css({
		fontWeight: 600,
		fontSize: 20,
		margin: 0,
		marginBottom: 16,
		lineHeight: "27px",
		"@media(max-width: 600px)": {
			fontSize: 18,
			marginBottom: 16,
			lineHeight: "24px"
		},
		...style
	});

	return <h2 className={customStyle}>{children}</h2>;
};

export default H2;
