import P from "../components/text/P";

export const AdminUSTaxpayerInfo = ({ usTaxpayerInfo }) => {
	const taxParts = [];

	taxParts.push({
		label: "User has filled out a 1099-K of type",
		value: usTaxpayerInfo.type.toUpperCase()
	});
	taxParts.push({
		label: "Taxpayer name",
		value: usTaxpayerInfo.name
	});
	if (usTaxpayerInfo.tin) {
		taxParts.push({
			label: "Taxpayer ID (TIN)",
			value: usTaxpayerInfo.tin
		});
	}

	if (usTaxpayerInfo.ftin) {
		taxParts.push({
			label: "Foreign tax identification number (FTIN)",
			value: usTaxpayerInfo.ftin
		});
	}
	taxParts.push({
		label: "Taxpayer Address",
		value: usTaxpayerInfo.address.address
	});

	taxParts.push({
		label: "Taxpayer city",
		value: usTaxpayerInfo.address.city
	});
	if (usTaxpayerInfo.address.state) {
		taxParts.push({
			label: "Taxpayer state",
			value: usTaxpayerInfo.address.state
		});
	}
	if (usTaxpayerInfo.address.postalCode) {
		taxParts.push({
			label: "Taxpayer zip",
			value: usTaxpayerInfo.address.postalCode
		});
	}
	if (usTaxpayerInfo.country) {
		taxParts.push({
			label: "Taxpayer country",
			value: usTaxpayerInfo.country
		});
	}
	if (usTaxpayerInfo.taxTreatyCountry) {
		taxParts.push({
			label: "Taxpayer tax treaty country",
			value: usTaxpayerInfo.taxTreatyCountry
		});
	}

	return (
		<div>
			{taxParts.map((part, index) => (
				<P key={`admin-us-taxpayer-info-${index}`}>
					{part.label}: <b> {part.value}</b>
				</P>
			))}
		</div>
	);
};
