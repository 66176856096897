import { css } from "@emotion/css";
import { Steps } from "@/ui/antd";
import { FC } from "react";
import { useNavigate } from "react-router";
import awards from "../../assets/landingPage/awards.png";
import dialogueIcon from "../../assets/landingPage/buttonIcons/dialogue.svg";
import documentIcon from "../../assets/landingPage/buttonIcons/document.svg";
import guideDocIcon from "../../assets/landingPage/buttonIcons/guideDoc.svg";
import monitoringIcon from "../../assets/landingPage/buttonIcons/monitoring.svg";
import paintingPaletteIcon from "../../assets/landingPage/buttonIcons/paintingPallete.svg";
import paymentCardIcon from "../../assets/landingPage/buttonIcons/paymentCard.svg";
import supportIcon from "../../assets/landingPage/buttonIcons/support.svg";
import ticketIcon from "../../assets/landingPage/buttonIcons/ticket.svg";
import charity from "../../assets/landingPage/charity.png";
import conferences from "../../assets/landingPage/conference.png";
import education from "../../assets/landingPage/education.png";
import expo from "../../assets/landingPage/expo.png";
import festivalsMusic from "../../assets/landingPage/festival.png";
import kidInRed from "../../assets/landingPage/kid_in_red.jpg";
import kidsInYellow from "../../assets/landingPage/kids_in_yellow.jpg";
import online from "../../assets/landingPage/online.png";
import workshops from "../../assets/landingPage/workshop.png";
import AnchorButton from "../../components/buttons/AnchorButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, H2, H4, P } from "../../components/text/Text";
import { firstOfType, max } from "../../utils/CssUtils";
import { HelpIds, HelpUrls, OpenHelpHandler, OpenHelpUrlHandler } from "../../utils/Help";

const Step = Steps.Step;

type UserCategory = "charity" | "education";

type EventTypes =
	| "Awards"
	| "Conferences"
	| "Expo"
	| "Festivals"
	| "Galas"
	| "Music"
	| "Online"
	| "Other"
	| "Workshops";

type Categories =
	| "Awards"
	| "Charity"
	| "Conferences"
	| "Education"
	| "Expo"
	| "FestivalsMusic"
	| "Online"
	| "Workshops";

type CategoryMap<T extends string> = {
	[key in T]: string;
};

type OrganiserTipsProps = {
	eventTypes: EventTypes[];
	userFirstname: string;
	userCategory: UserCategory;
	history: any;
};

const OrganiserTips: FC<OrganiserTipsProps> = ({ eventTypes = [], userFirstname, userCategory }) => {
	const navigate = useNavigate();
	const createEvent = () => {
		navigate("/console/my-events/new/basics");
	};

	const userType = getUserType(userCategory, eventTypes);
	const welcomeEmoji = welcomeEmojis[userType as keyof typeof welcomeEmojis];
	const subHeader = subHeaderTexts[userType as keyof typeof subHeaderTexts];
	const welcomeImage = welcomeImages[userType as keyof typeof welcomeImages];
	return (
		<div style={{ overflow: "hidden" }}>
			<div className={sectionWrapper}>
				<H2 style={{ marginBottom: 4 }}>
					{welcomeEmoji} Welcome to Humanitix, {userFirstname}! We're so happy you're here.
				</H2>
				<P>{subHeader}</P>
			</div>
			<div className={[stepSection, sectionWrapper].join(" ")}>
				<img src={welcomeImage} alt="" />
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						marginLeft: 20
					}}
				>
					<P>
						<b>Let's go! Set up your first event in a few easy steps...</b>
					</P>
					<Steps direction="vertical" current={0}>
						{createDraftEventSteps.map((step, index) => (
							<Step description={window.innerWidth > 960 ? step.description : null} key={index} title={step.title} />
						))}
					</Steps>
					<LegacyButton type="secondary" onClick={createEvent} ariaLabel="create event" style={{ maxWidth: 189 }}>
						Create event
					</LegacyButton>
				</div>
			</div>

			<div className={sectionWrapper}>
				<H4 style={{ marginBottom: 10 }}>Learn how to use Humanitix</H4>
				<div className={helpSection}>
					{learnHowToUseHumanitixLinks.map((e, index) => {
						return (
							<AnchorButton
								description={e.description}
								icon={e.icon}
								key={`${index}_${e.title}`}
								onClick={e.onClick}
								title={e.title}
							/>
						);
					})}
				</div>
				<H4 style={{ marginBottom: 10 }}>Get help</H4>
				<div className={helpSection}>
					{gettingHelpLinks.map((e, index) => (
						<AnchorButton
							description={e.description}
							icon={e.icon}
							key={`${index}_${e.title}`}
							onClick={e.helpId}
							title={e.title}
						/>
					))}
				</div>
			</div>
			<div className={sectionWrapper} style={{ border: "none" }}>
				<H4 style={{ marginBottom: 10 }}>Turning booking fees into a force for good</H4>
				<div className={thisIsHumanitixImageWrapper}>
					<a href="https://www.humanitix.com/au/impact" target="_blank" rel="noopener noreferrer" tabIndex={0}>
						<H1 color="#FFF">100% of profits dedicated to education and healthcare charities</H1>
					</a>
					<a href="https://humanitix.com/au/reports/nov2023" target="_blank" rel="noopener noreferrer" tabIndex={0}>
						<span>
							<P style={{ fontSize: 48, color: "#FFF" }}>$6,500,000</P>
							<H1 color="#FFF">
								donated to our charity partners
								<br />
								to-date!
							</H1>
						</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default OrganiserTips;

const sectionWrapper = css({
	borderBottom: "solid 1px #DEDEDE",
	padding: "25px 0px"
});

const stepSection = css({
	display: "flex",
	img: {
		borderRadius: 4,
		maxHeight: 358,
		maxWidth: "50%"
	},
	[max(960)]: {
		alignItems: "center",
		alignSelf: "center",
		flexDirection: "column",
		img: {
			borderRadius: 4,
			marginBottom: 12,
			maxWidth: 540
		}
	}
});

const helpSection = css({
	display: "flex",
	flexFlow: "row wrap",
	justifyContent: "space-between",
	marginBottom: 40,
	a: {
		marginRight: 10,
		marginBottom: 10
	},
	[max(960)]: {
		alignItems: "center",
		alignSelf: "center",
		flexDirection: "column"
	}
});

const thisIsHumanitixImageWrapper = css({
	display: "flex",
	justifyContent: "space-between",
	a: {
		alignItems: "flex-end",
		background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1)), url(${kidsInYellow}) no-repeat center`,
		backgroundSize: 540,
		borderRadius: 4,
		display: "flex",
		height: 304,
		margin: 5,
		paddingLeft: 40,
		paddingRight: 40,
		textAlign: "center",
		width: 540,
		[firstOfType()]: {
			background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1)), url(${kidInRed}) no-repeat center`,
			backgroundSize: 540
		},
		":hover": {
			boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.07), 0 2px 15px rgba(80, 80, 80, 0.25)"
		},
		":focus": {
			border: "solid 2px #323232"
		}
	},

	[max(960)]: {
		alignItems: "center",
		alignSelf: "center",
		flexDirection: "column"
	},

	[max(500)]: {
		h1: {
			margin: "0px auto 20px",
			maxWidth: 200
		},
		span: {
			margin: "0px auto ",
			maxWidth: 200
		}
	}
});

const userTypes = {
	Charity: "charity",
	Education: "education"
};

const eventsTypes: CategoryMap<EventTypes> = {
	Awards: "awards",
	Conferences: "conferences",
	Expo: "expo",
	Festivals: "festivals",
	Galas: "galas",
	Music: "music",
	Online: "online",
	Other: "other",
	Workshops: "workshops"
};

const landingPageTypes: CategoryMap<Categories> = {
	Awards: "Awards",
	Charity: "charity",
	Conferences: "Conferences",
	Education: "Education",
	Expo: "Expo",
	FestivalsMusic: "FestivalsMusic",
	Online: "Online",
	Workshops: "Workshops"
};

const welcomeEmojis: CategoryMap<Categories> = {
	Awards: "🏆",
	Charity: "🎗",
	Conferences: "🎤",
	Education: "🎓",
	Expo: "📣",
	FestivalsMusic: "🎉",
	Online: "💻",
	Workshops: "🎨"
};

const subHeaderTexts: CategoryMap<Categories> = {
	Awards: "Start adding impact to your events now!",
	Charity: "Start creating events with impact now!",
	Conferences: "Start creating events and adding impact to your conferences now!",
	Education: "Start adding impact to your events now!",
	Expo: "Start creating events and adding impact to your expo now!.",
	FestivalsMusic: "Start adding impact to your events now!",
	Online: "Start adding impact to your online events now!",
	Workshops: "Start creating events and adding impact to your workshops now!"
};

const welcomeImages: CategoryMap<Categories> = {
	Awards: awards,
	Charity: charity,
	Conferences: conferences,
	Education: education,
	Expo: expo,
	FestivalsMusic: festivalsMusic,
	Online: online,
	Workshops: workshops
};

const createDraftEventSteps = [
	{
		title: "Draft the basics",
		description: "Let attendees know when, where and what your event is about."
	},
	{
		title: "Style it up",
		description: "Customise your event page: apply your brand colours or have fun a little with it."
	},
	{
		title: "Publish and share your event",
		description: "It's go time! Set your event live and share it with the world!"
	}
];

const learnHowToUseHumanitixLinks = [
	{
		description: "sell your tickets",
		icon: ticketIcon,
		onClick: OpenHelpHandler(HelpIds.setUpTickets),
		title: "How to set up tickets"
	},
	{
		description: "more about pricing",
		icon: paymentCardIcon,
		onClick: OpenHelpHandler(HelpIds.paymentOptionsAndFees),
		title: "Payment options & fees"
	},
	{
		description: "monitor, update and manage",
		icon: monitoringIcon,
		onClick: OpenHelpHandler(HelpIds.orders),
		title: "Sales & bookings"
	},
	{
		description: "ticketing",
		icon: paintingPaletteIcon,
		onClick: OpenHelpHandler(HelpIds.customDesign),
		title: "Design and comms"
	}
];

const gettingHelpLinks = [
	{
		description: "Tick the boxes",
		icon: documentIcon,
		link: OpenHelpUrlHandler(HelpUrls.eventGuides),
		title: "Event guides"
	},
	{
		description: "New to humanitix",
		icon: guideDocIcon,
		helpId: OpenHelpHandler(HelpIds.quickCreationGuide),
		title: "Quick start guide"
	},
	{
		description: "Frequently asked questions",
		icon: dialogueIcon,
		link: OpenHelpUrlHandler(HelpUrls.faqs),
		title: "FAQs"
	},
	{
		description: "No luck?",
		icon: supportIcon,
		helpId: OpenHelpUrlHandler(HelpUrls.support),
		title: "Raise a support ticket"
	}
];

function getUserType(userCategory: UserCategory, eventTypes: string[]) {
	//Tier 1: based on user type; Charity or School
	if (userCategory === userTypes.Charity) return landingPageTypes.Charity;
	else if (userCategory === userTypes.Education) return landingPageTypes.Education;
	//Tier 2: based on event types
	else if (eventTypes.includes(eventsTypes.Workshops)) return landingPageTypes.Workshops;
	else if (eventTypes.includes(eventsTypes.Conferences)) return landingPageTypes.Conferences;
	else if (eventTypes.includes(eventsTypes.Awards)) return landingPageTypes.Awards;
	else if (eventTypes.includes(eventsTypes.Galas)) return landingPageTypes.Awards;
	else if (eventTypes.includes(eventsTypes.Online)) return landingPageTypes.Online;
	else if (eventTypes.includes(eventsTypes.Expo)) return landingPageTypes.Expo;
	else return landingPageTypes.FestivalsMusic;
}
