import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import NoResults from "@/components/noResults/NoResults";
import { H1 } from "@/components/text/Text";
import { getTours } from "@/state/actions/tourActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useNavigate } from "react-router";
import TourRow from "./TourRow";

interface ToursProps extends PropsFromRedux {}

const ToursComponent: FC<ToursProps> = ({ tours: toursReducer, getTours, userId }) => {
	const navigate = useNavigate();
	const { loading, error, tours } = toursReducer;

	const _load = () => getTours(userId);

	useEffect(() => {
		_load();
	}, []);

	const _createNew = () => navigate("/console/promote/tours/new");

	return (
		<div>
			<LoadErrorView loading={loading} error={error} retryAction={() => _load()}>
				<H1>Tours</H1>
				<ContentBlock>
					<NoResults
						title="Hosting a festival, conference or a music show? Create tour page and include all events that are a part of a tour.  
			Have all your events on the separate page and give your tour a customised styled look. 
			Group your events for easier navigation; e.g. group them by location, date, category etc."
						message=""
						action={() => _createNew()}
						actionTxt="Create tour page"
					/>
				</ContentBlock>

				{tours?.length
					? tours.map((tour) => {
							return <TourRow {...tour} key={tour._id} />;
					  })
					: null}
			</LoadErrorView>
		</div>
	);
};

const mapStateToProps = (state: GlobalReduxState) => {
	return {
		tours: state.tours,
		userId: state.auth?.user?.id
	};
};

const mapDispatchToProps = {
	getTours
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const Tours = connector(ToursComponent);
