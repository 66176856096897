import { createSlice } from "@reduxjs/toolkit";

type ApplyToAllReducerState = {
	modalOpen: boolean;
};

const initialState: ApplyToAllReducerState = {
	modalOpen: false
};

const applyToAllSlice = createSlice({
	name: "applyToAll",
	initialState,
	reducers: {
		toggleModal(state, action) {
			state.modalOpen = action.payload.stateToApplyToModal;
		}
	}
});

export const { toggleModal } = applyToAllSlice.actions;

export default applyToAllSlice.reducer;
