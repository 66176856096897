import {
	FIND_MASS_PAYOUTS_ERROR,
	FIND_MASS_PAYOUTS_REQUEST,
	FIND_MASS_PAYOUTS_SUCCESS,
	GET_MASS_PAYOUT_CSV_ERROR,
	GET_MASS_PAYOUT_CSV_REQUEST,
	GET_MASS_PAYOUT_CSV_SUCCESS,
	GET_MASS_PAYOUT_ERROR,
	GET_MASS_PAYOUT_REQUEST,
	GET_MASS_PAYOUT_SUCCESS,
	REQ_MASS_PAYOUT_ERROR,
	REQ_MASS_PAYOUT_REQUEST
} from "../actions/massPayoutActions";

const initialState = {
	payouts: {
		payments: [],
		count: 0,
		loading: false,
		error: false,
		page: 1
	},
	payout: {
		data: null,
		loading: false,
		error: false,
		page: 1
	},
	payoutCSV: {
		data: null,
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case FIND_MASS_PAYOUTS_REQUEST:
			return {
				...state,
				payouts: {
					...state.payouts,
					loading: true,
					error: false,
					page: action.page
				}
			};
		case FIND_MASS_PAYOUTS_SUCCESS:
			return {
				...state,
				payouts: {
					...state.payouts,
					loading: false,
					error: false,
					payments: action.result.payments,
					count: action.result.count
				}
			};
		case FIND_MASS_PAYOUTS_ERROR:
			return {
				...state,
				payouts: {
					...state.payouts,
					loading: false,
					error: action.error,
					payments: [],
					count: 0
				}
			};
		case GET_MASS_PAYOUT_REQUEST:
			return {
				...state,
				payout: {
					...state.payouts,
					loading: true,
					data: null,
					error: false
				}
			};
		case GET_MASS_PAYOUT_SUCCESS: {
			const payments = action.payments.map((payment) => {
				return {
					...payment,
					amount: payment.payments.amount,
					date: payment.payments.date
				};
			});
			return {
				...state,
				payout: {
					...state.payout,
					loading: false,
					error: false,
					data: payments
				}
			};
		}
		case GET_MASS_PAYOUT_ERROR:
			return {
				...state,
				payout: {
					...state.payout,
					loading: false,
					error: action.error,
					data: []
				}
			};
		case GET_MASS_PAYOUT_CSV_REQUEST:
			return {
				...state,
				payoutCSV: {
					...state.payoutCSV,
					loading: true,
					error: false
				}
			};
		case GET_MASS_PAYOUT_CSV_SUCCESS:
			return {
				...state,
				payoutCSV: {
					loading: false,
					error: false
				}
			};
		case GET_MASS_PAYOUT_CSV_ERROR:
			return {
				...state,
				payoutCSV: {
					...state.payoutCSV,
					loading: false,
					error: action.error
				}
			};
		case REQ_MASS_PAYOUT_REQUEST:
			return {
				...state,
				payouts: {
					...state.payouts,
					loading: true,
					error: false
				}
			};
		case REQ_MASS_PAYOUT_ERROR:
			return {
				...state,
				payouts: {
					...state.payouts,
					loading: false,
					error: false,
					data: null
				}
			};
		default:
			return state;
	}
};
