import { SelectField } from "@/components/Fields";
import { css } from "@emotion/css";
import {
	CategorySlug,
	ClassificationOption,
	EventTypeSlug,
	SubcategorySlug,
	getCategories,
	getSubCategoriesByCategory,
	getTypes
} from "@hx/event-classification";
import { Field } from "redux-form";

const wrapperStyle = css({
	display: "flex",
	flexDirection: "row",
	gap: 4,
	flexFlow: "wrap",
	"@media(max-width: 961px)": {
		flexDirection: "column"
	}
});
type EventClassification = {
	type?: EventTypeSlug;
	category?: CategorySlug;
	subcategory?: SubcategorySlug;
};

type ClassificationProps = {
	classification: EventClassification;
	changeFieldValue: (fieldName: string, value?: string | null) => void;
};

const Classification = ({ classification, changeFieldValue }: ClassificationProps) => {
	const eventTypes: ClassificationOption[] = getTypes();
	const eventCategories: ClassificationOption[] = getCategories();
	const availableSubCategories: ClassificationOption[] = getSubCategoriesByCategory(
		(classification?.category ?? eventCategories[0].value) as CategorySlug
	);

	return (
		<div>
			<div className={wrapperStyle}>
				<Field
					name="classification.type"
					label="Type"
					placeholder="Select a type"
					component={SelectField}
					options={eventTypes}
					showSearch={true}
					style={{ width: "100%" }}
				/>
				<Field
					name="classification.category"
					label="Category"
					placeholder="Select a category"
					component={SelectField}
					options={eventCategories}
					onSelect={() => changeFieldValue("classification.subcategory", null)}
					showSearch={true}
					style={{ width: "100%" }}
				/>

				{!!availableSubCategories?.length && (
					<Field
						name="classification.subcategory"
						label="Sub-category"
						placeholder="Select a sub-category"
						component={SelectField}
						options={availableSubCategories}
						showSearch={true}
						style={{ width: "100%" }}
					/>
				)}
			</div>
		</div>
	);
};

export default Classification;
