import { Component } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { LegacyInputField, SelectField } from "../components/Fields";
import { email, required } from "../utils/Validators";
import AuthorizationOptions from "./form/AuthorizationOptions";

class AuthorizationForm extends Component {
	constructor(props) {
		super(props);
		this.state = { submit: false };
	}

	componentWillReceiveProps(props) {
		if (this.props.submit && !props.submit) {
			this.setState({ submit: false });
		} else if (props.submit) {
			this.setState({ submit: true });
		}
	}

	render() {
		return <Form {...this.props} save={this.props.save} addClicked={this.state.submit} />;
	}
}
const emailRequired = required("An email is required");
const validEmail = email("Please enter a valid email");
class Form extends Component {
	componentWillUpdate(props) {
		const { addClicked, handleSubmit, save } = props;
		if (addClicked && !this.props.addClicked) {
			handleSubmit(save)();
		}
	}
	render() {
		const { dispatch, authorization, missingPermission, fullAccess, issueFor, showUserSelect } = this.props;
		const options = [
			{
				label: "Yourself",
				value: "self"
			}
		].concat(
			authorization.sharedAccounts.map((account) => {
				return {
					label: `${account.firstName} ${account.lastName}`,
					value: account.id
				};
			})
		);
		const { selectedAuthorization } = authorization;
		const changeFieldValue = (field, value) => {
			dispatch(change(formName, field, value));
		};
		return (
			<form>
				<div>
					{options.length > 1 && showUserSelect ? (
						<Field
							name="issueFor"
							label="Select the user you wish to issue permissions from"
							component={SelectField}
							options={options}
						/>
					) : null}
					<Field
						name="email"
						label="Email of the user you want to share permissions with"
						component={LegacyInputField}
						required
						disabled={!!selectedAuthorization}
						validate={[emailRequired, validEmail]}
					/>
					<AuthorizationOptions
						missingPermission={missingPermission}
						changeFieldValue={changeFieldValue}
						issueFor={issueFor}
						fullAccess={fullAccess}
					/>
				</div>
			</form>
		);
	}
}

const formName = "authorization";

// Decorate with redux-form

const selector = formValueSelector(formName);
Form = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(Form);

Form = connect((state) => {
	const initialValues = state.authorization.selectedAuthorization
		? state.authorization.selectedAuthorization
		: { fullAccess: true, enableSharing: false, issueFor: "self" };
	if (initialValues.fullAccess === null || initialValues.fullAccess === undefined) {
		initialValues.fullAccess = false;
	}
	return {
		initialValues,
		enableReinitialize: true
	};
})(Form);

export default connect((state) => ({
	authorization: state.authorization,
	fullAccess: selector(state, "fullAccess"),
	issueFor: selector(state, "issueFor")
}))(AuthorizationForm);
