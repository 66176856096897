import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Input } from "@/ui/antd";
import { useState } from "react";
import { debounce } from "throttle-debounce";
import ReportService from "../../services/ReportService";
import Card from "./ResultsCard";

const wrapper = css({
	position: "relative",

	".ant-input-affix-wrapper": {
		background: "#464548",
		borderColor: "#464448",
		color: "#fff"
	}
});

const SearchBarWrapper = styled.div`
	width: 300px;

	@media (max-width: 585px) {
		width: 100%;
	}
`;

const SearchBar = styled(Input)`
	input {
		background: #464548;
		border-color: #464448;
		color: #fff;

		::placeholder {
			color: #ccc;
		}
	}
`;

const noResultsClass = css({
	P: {
		padding: 12,
		margin: 0
	},
	maxWidth: 300,
	width: "100%"
});

const typeGroup = css({
	padding: "12px 0px",
	borderBottom: "1px solid #ebeef1",
	":last-child": {
		borderBottom: "none"
	}
});

const typeClass = css({
	fontWeight: "bold",
	fontSize: 12,
	padding: "0px 12px"
});

const getResultsClass = (placeholder = "Search events, orders, tickets, host and users") => {
	return css({
		borderRadius: 4,
		background: "#fff",
		boxShadow: "0 1px 10px 0 rgb(9 30 66 / 13%);",
		marginTop: 12,
		zIndex: 200,
		position: "absolute",
		top: 40,
		right: 0,
		fontSize: 14,
		width: "calc(100vw - 80px)",
		maxWidth: 600,
		maxHeight: "calc(100vh - 90px)",
		overflowY: "auto",
		overflowX: "hidden",
		"&:after": {
			display: "block",
			content: `"${placeholder}"`,
			background: "#f9f9fa",
			fontSize: 12,
			padding: "8px 12px",
			borderRadius: "0 0 4px 4px"
		}
	});
};

const MegaSearch = ({ isAdmin = false }) => {
	const [state, setState] = useState({ loading: false, results: null, query: null, focused: false });
	const { loading, results, focused, query } = state;
	const search = (queryStr) => {
		const validQuery = queryStr && queryStr.length;
		setState({ loading: validQuery, results: null, query: queryStr, focused });
		if (validQuery) {
			ReportService.megaSearch(queryStr)
				.then((results) => {
					open(results);
				})
				.catch(() => {
					close();
				});
		}
	};

	const onFocus = () => {
		setState({ loading, results, query, focused: true });
	};

	const onBlur = () => {
		setState({ loading, results, query, focused: false });
	};

	const open = (results) => {
		setState({ loading: false, results, query, focused });
		document.addEventListener("click", close);
	};

	const close = () => {
		setState({ loading: false, results: null, query: null, focused: false });
		document.removeEventListener("click", close);
	};

	const debounceLoad = debounce(500, search);

	const searchUpdate = (e) => {
		debounceLoad(e.target.value);
	};

	const IconDisplay = loading ? <LoadingOutlined /> : <SearchOutlined />;

	const groupedResults =
		results && results.length
			? results.reduce((groups, result) => {
					if (!groups[result.type]) {
						groups[result.type] = {
							type: result.type,
							results: []
						};
					}
					groups[result.type].results.push(result);
					return groups;
			  }, {})
			: null;

	const placeholderText = isAdmin
		? "Search events, orders, attendees, host and users"
		: "Search events, orders, and attendees";

	const resultsClass = getResultsClass(placeholderText);

	return (
		<div className={wrapper}>
			<SearchBarWrapper>
				<SearchBar
					prefix={IconDisplay}
					onChange={searchUpdate}
					onFocus={onFocus}
					name="MegaSearch"
					onBlur={onBlur}
					placeholder={placeholderText}
				/>
			</SearchBarWrapper>
			{results?.length === 0 && focused ? (
				<div
					className={css`
						${resultsClass} ${noResultsClass}
					`}
				>
					<p>No results found</p>
				</div>
			) : null}
			{results?.length ? (
				<div className={resultsClass}>
					{Object.values(groupedResults).map((groupedResult) => (
						<div key={groupedResult.type} className={typeGroup}>
							<h3 className={typeClass}>{groupedResult.type}s</h3>
							{groupedResult.results.map((result) => {
								return <Card key={result._id} data={result}></Card>;
							})}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

export default MegaSearch;
