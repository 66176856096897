import { Modal, notification } from "@/ui/antd";
import { useState } from "react";
import { Field, change } from "redux-form";

import { LegacyInputField, InputNumberField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import InfoIcon from "@/components/InfoIcon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { includes } from "@/utils/Array";
import { required } from "@/utils/Validators";
import { css } from "@emotion/css";
import PreviewPrice from "../../PreviewPrice";
import AdvancedSettings from "./AdvancedSettings";
import InfoTitle from "@/components/table/InfoTitle";

const nameRequired = required("A package name is required");
const priceRequired = required("A price name is required");
const packageCapacityRequired = required("A package capacity is required");

const styles = {
	hr: css({
		marginTop: 0
	}),
	asterisk: css({
		color: "#c0212c"
	}),
	hideRow: css({
		display: "none"
	}),
	largeCell: css({
		"@media(max-width: 600px)": {
			float: "left",
			marginRight: 10,
			minWidth: "75vw"
		}
	}),
	smallCell: css({
		display: "flex",
		"@media(max-width: 600px)": {
			float: "left",
			marginRight: 10,
			minWidth: "38vw"
		}
	})
};

const PackagedTicketsTable = ({
	dispatch,
	packagedTickets,
	currentEvent,
	touch,
	fields,
	formName,
	fieldName,
	primaryPackages
}: any) => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
	const [expandedRowOptions, setExpandedRowOptions] = useState<{
		[key: string]: any;
	}>({});
	const [showPriceModal, setShowPriceModal] = useState(false);
	const timezone = currentEvent.event.timezone;
	const isFreeOnly = currentEvent.event.isFreeOnly;

	const _togglePriceModal = () => setShowPriceModal((current) => !current);

	const _onExpandClick = (key: string, option: any) => {
		const tempRowKeys = [...expandedRowKeys];
		const newExpandedRowOptions = { ...expandedRowOptions };

		if (includes(expandedRowKeys, key)) {
			if (newExpandedRowOptions[key] === option) {
				const index = tempRowKeys.indexOf(key);
				tempRowKeys.splice(index, 1);
				delete newExpandedRowOptions[key];
			} else {
				newExpandedRowOptions[key] = option;
			}
		} else {
			tempRowKeys.push(key);
			newExpandedRowOptions[key] = option;
		}

		setExpandedRowKeys(tempRowKeys);
		setExpandedRowOptions(newExpandedRowOptions);
	};

	const _checkNoSoldTicket = (ticket: any) => {
		if (ticket) {
			const isSoldTicket = ticket.hasBeenSold;
			if (isSoldTicket) {
				notification.error({
					message: "Error",
					description: "This package has been sold. You can only disable it"
				});
				return false;
			}
		}
		return true;
	};

	const _checkNotMappedToSeatingMap = (ticket: any) => {
		if (ticket) {
			if (ticket.isMappedToSeatingMap) {
				notification.error({
					message: "Error",
					description: "This package has been mapped to seating map. You can only disable it"
				});
				return false;
			}
		}
		return true;
	};

	const _toggleDisabled = (index: number, disabled: boolean) => {
		dispatch(change("packagedTickets", `${fieldName}[${index}].disabled`, !disabled));
	};

	const _deletePackage = (index: number, item: any) => {
		const tempRowKeys = [...expandedRowKeys];

		if (item._id && _checkNoSoldTicket(item) && _checkNotMappedToSeatingMap(item)) {
			dispatch(change(`packagedTickets`, `${fieldName}[${index}].deleted`, true));
			if (includes(expandedRowKeys, item.rowKey)) {
				const index = tempRowKeys.indexOf(item.rowKey);
				tempRowKeys.splice(index, 1);
			}
			setExpandedRowKeys(tempRowKeys);
			return true;
		}
		return false;
	};
	const newExpandedRowKeys = expandedRowKeys.concat([]);
	const packagedTicketsKeyed = packagedTickets.map((packagedTicket: any) => {
		if (packagedTicket._id) {
			packagedTicket.rowKey = packagedTicket._id;
			return packagedTicket;
		}
		if (packagedTicket.deleted) {
			return packagedTicket;
		}
		newExpandedRowKeys.push(packagedTicket.rowKey);
		return packagedTicket;
	});

	const options = {
		currentEvent,
		touch,
		dispatch
	};

	const columns = [
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Package name{" "}
				</span>
			),
			dataIndex: "name",
			key: "name",
			render: (_: string, packageTicket: any, index: number) => {
				if (packageTicket.disabled) {
					return `Disabled: ${packageTicket.name}`;
				}
				return (
					<div className={styles.largeCell}>
						<Field
							name={`${fieldName}[${index}].name`}
							placeholder="Package Name"
							component={LegacyInputField}
							validate={nameRequired}
						/>
					</div>
				);
			}
		},
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Price
				</span>
			),
			dataIndex: "price",
			key: "price",
			width: 110,
			render: (_: string, data: any, index: number) => {
				return (
					<div className={styles.smallCell}>
						<Field
							name={`${fieldName}[${index}].price`}
							placeholder="Price"
							component={InputNumberField}
							min={0}
							max={isFreeOnly ? 0 : 100000000}
							validate={priceRequired}
							allowsNullValue={true}
							disabled={data.disabled || isFreeOnly}
							precision={2}
						/>
						{isFreeOnly ? (
							<div style={{ marginLeft: 6 }}>
								<InfoIcon tooltip="To offer paid packages, please connect your Stripe account" />
							</div>
						) : null}
					</div>
				);
			}
		},
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Package capacity{" "}
				</span>
			),
			dataIndex: "quantity",
			key: "quantity",
			width: 150,
			render: (_: string, { disabled, isMappedToSeatingMap, mappedTables }: any, index: number) => {
				return (
					<div className={styles.smallCell}>
						{isMappedToSeatingMap ? (
							<div style={{ paddingLeft: 10, paddingBottom: 5 }}>
								<InfoTitle title={mappedTables} tooltip={<>Capacity is determined by the seating map</>} />
							</div>
						) : (
							<Field
								name={`${fieldName}[${index}].quantity`}
								placeholder="Quantity"
								component={InputNumberField}
								min={0}
								allowsNullValue={true}
								validate={packageCapacityRequired}
								disabled={disabled}
							/>
						)}
					</div>
				);
			}
		},

		{
			title: "Action",
			dataIndex: "",
			key: "x",
			width: 100,
			render: (_: string, data: any, index: number) => (
				<div className={styles.largeCell}>
					<TableActions containerStyle={{ width: 200 }}>
						{!data.disabled ? (
							<div>
								{!data._id ? null : (
									<div style={{ display: "inline-block" }}>
										<IconButton
											icon="edit"
											tooltip="Edit"
											showTooltip
											onClick={() => _onExpandClick(data.rowKey, "edit")}
											ariaLabel="Edit"
										/>
										<IconButton
											icon="settings"
											tooltip="Settings"
											showTooltip
											onClick={() => _onExpandClick(data.rowKey, "settings")}
											ariaLabel="Settings"
										/>
										<IconButton
											icon="disable"
											tooltip="Disable"
											onClick={() => _toggleDisabled(index, data.disabled)}
											showTooltip
											ariaLabel="Disable"
										/>
									</div>
								)}
								<IconButton
									icon="delete"
									tooltip="Delete"
									onClick={() => _deletePackage(index, data)}
									showTooltip
									ariaLabel="Delete"
								/>
							</div>
						) : (
							<IconButton
								icon="undo"
								tooltip="Enable"
								onClick={() => _toggleDisabled(index, data.disabled)}
								showTooltip
								ariaLabel="Undo"
							/>
						)}
					</TableActions>
				</div>
			)
		}
	];
	return (
		<div
			className={css({
				"tr.ant-table-expanded-row > td": {
					background: "#f9f9fa"
				},
				"tr.ant-table-expanded-row > .ant-table-tbody > tr.ant-table-row > td": {
					background: "#f9f9fa"
				}
			})}
		>
			<ResponsiveTable
				pagination={false}
				columns={columns}
				dataSource={packagedTicketsKeyed}
				rowKey="rowKey"
				expandedRowRender={((options) => {
					return function (packageTicket: any, index: number) {
						return (
							<AdvancedSettings
								data={packageTicket}
								packagedTickets={packagedTickets}
								index={index}
								options={options}
								expandedRowOptions={expandedRowOptions}
								timezone={timezone}
								formName={formName}
								fieldName={fieldName}
								primaryPackages={primaryPackages}
							/>
						);
					};
				})(options)}
				rowClassName={(record: any) => {
					return record.deleted ? styles.hideRow : "";
				}}
				expandedRowKeys={newExpandedRowKeys}
				expandIconColumnIndex={-1}
				expandIconAsCell={false}
				options={options}
			/>

			<ButtonsBar>
				<LegacyButton
					onClick={() => {
						fields.push({
							rowKey: Math.random().toString(36).substring(7),
							tickets: [{}],
							price: isFreeOnly ? 0 : null,
							isSecondary: fieldName === "secondaryPackages"
						});
					}}
					icon={{ name: "plus", left: true }}
					type="action"
					ariaLabel="New Package"
				>
					New package
				</LegacyButton>
			</ButtonsBar>
			<hr className={styles.hr} />
			<LegacyButton
				icon={{ left: true, name: "preview" }}
				type="action"
				flavor="minimal"
				ariaLabel="Preview total prices"
				onClick={_togglePriceModal}
			>
				Preview total prices
			</LegacyButton>
			<Modal
				open={showPriceModal}
				title="Package ticket total price preview"
				destroyOnClose
				onCancel={_togglePriceModal}
				footer={false}
			>
				<PreviewPrice ticketTypes={packagedTicketsKeyed} packagedTickets />
			</Modal>
		</div>
	);
};

export default PackagedTicketsTable;
