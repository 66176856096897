import { Component } from "react";
import { ContentBlock } from "@/components/AppBlocks";
import { H1, H3, H4, P } from "@/components/text/Text";

class Tips extends Component {
	render() {
		const styles = {
			link: {
				color: "#353337"
			}
		};
		return (
			<div>
				<H1>Accessibility Tips</H1>

				<ContentBlock>
					<H3>Event Accessibility Checklist</H3>
					<div>
						<P>
							When organising an event, whether it’s a board meeting, an annual conference, or the team Christmas party,
							there are a few small considerations to make to ensure it can be enjoyed by everybody.
						</P>
						<P>
							The information below is an overview of considerations that should be made to accommodate people with
							disability. It is by no means exhaustive, but is a good place to start when planning your event. Always
							remember to ask each attendee about their unique requirements;&nbsp;<strong>never assume</strong>.
						</P>
						<H4>Choosing a venue</H4>
						<ul type="disc">
							<li>Ensure entrances, lifts, ramps and corridor widths comply with Australian Standards.</li>
							<li>Ensure automatic doors at entrance are available and functioning.</li>
							<li>
								Ensure accessible bathrooms are available. Check that the bathrooms are functioning, clear of clutter,
								and can be easily accessed.
							</li>
							<li>Choose a venue that can be easily accessed by public transport.</li>
							<li>
								Make sure your venue understands the&nbsp;
								<a
									href="http://www.antidiscrimination.justice.nsw.gov.au/Pages/adb1_antidiscriminationlaw/adb1_types/adb1_disability_discrimination.aspx"
									target="_blank"
									rel="noopener noreferrer"
									style={styles.link}
									alt="laws surrounding service animals in Australia"
								>
									laws surrounding service animals in Australia
								</a>
								.
							</li>
							<li>
								Check that the acoustics of the venue are adequate, and that noise from external sources (traffic,
								crowds, other events, etc) do not interfere.
							</li>
						</ul>
						<H4>Invitations</H4>
						<ul type="disc">
							<li>
								Ask attendees to advise of any accessibility requirements when registering so that these adjustments are
								managed as a part of the event. Outline the accessibility features of the venue you have chosen.
							</li>
							<li>
								Ensure digital invitations are accessible. If you are unsure, consult{" "}
								<a href="http://www.mediaaccess.org.au/" target="_blank" rel="noopener noreferrer" style={styles.link}>
									Media Access Australia
								</a>
								. If physical invitations are provided, ensure attendees can easily access accessible digital versions.
							</li>
							<li>
								Ensure that guests and participants can register for the event in a range of ways, including by
								telephone, by email or online. If using an online form or third party booking service, make sure it is
								accessible.
							</li>
							<li>
								Provide information about accessing the venue, including accessible parking, general parking, public
								transport, and venue drop off points.
							</li>
							<li>
								For ticketed events,&nbsp;
								<a
									href="http://www.companioncard.org.au/"
									target="_blank"
									rel="noopener noreferrer"
									style={styles.link}
									alt="Companion cards"
								>
									Companion cards
								</a>
								&nbsp;and similar services should be honoured.
							</li>
						</ul>
						<H4>Marketing and communications</H4>
						<ul>
							<li>
								Written material should be available in alternative formats, as required, before and after the event
								(e.g. braille, large print, audio, electronic).
							</li>
							<li>Signage, presentations and written material should have accessible contrast levels.</li>
							<li>Make written material available in plain English/Easy English alternatives.</li>
						</ul>
						<H4>Wayfinding</H4>
						<ul>
							<li>
								Ensure that your event venue is accessible. Is there level access? Do signs and elevators have braille
								signage? Are there sufficient Tactile Ground Surface Indicators (TGSIs)?
							</li>
							<li>Wayfinding materials should be simple and easy to read (clear directions, appropriate signage).</li>
							<li>
								The MC or event host should provide a verbal explanation of the layout of the venue at the start of the
								event. This should include the layout of the room and directions to toilets, meal areas, breakout rooms
								and fire exits.
							</li>
						</ul>
						<H4>Room arrangement</H4>
						<ul type="disc">
							<li>
								Book Auslan interpreters as needed, and reserve seats in front to enable a clear view for people who are
								deaf and hard of hearing.
							</li>
							<li>Provide sufficient space between tables for wheelchair access.</li>
							<li>
								Ensure table height is accessible to wheelchair users and people of short stature. This includes
								refreshment tables and buffets.
							</li>
							<li>For standing events, provide some chairs for people who may experience fatigue</li>
							<li>
								Venue should be clear of obstacles, and trip hazards such as cables should be removed or taped down.
							</li>
							<li>Provide guests with access to a separate, quiet area to allow them to take a break, if needed.</li>
						</ul>
						<H4>Audio Visuals</H4>
						<ul>
							<li>Avoid strobe lighting or flashing lights.</li>
							<li>
								Provide a wheelchair ramp to the stage (if required) and ensure it complies with Australian Standards.
							</li>
							<li>Provide adjustable height microphones, or lapel microphones if required.</li>
							<li>Ensure a hearing loop is available.</li>
							<li>
								Provide space for Auslan interpreters (if required). Ensure interpreters are positioned in a well-lit
								area and clearly visible to the audience.
							</li>
							<li>
								Provide live captioning (available through{" "}
								<a href="http://www.ai-media.tv/" style={styles.link} alt="Ai-Media">
									Ai-Media
								</a>
								). This involves having an adequate internet connection available for attendees to connect to through
								their personal devices, as well as a phone line to connect the captioners.
							</li>
							<li>Venue should be evenly lit throughout.</li>
						</ul>
						<H4>Presentations</H4>
						<ul>
							<li>All videos must be captioned.</li>
							<li>
								Videos should be audio described where appropriate. If they cannot be audio described, the presenter
								should supply any visual information that a person with low vision may not be able to access.
							</li>
							<li>Presenters should describe any visual information in their presentations.</li>
						</ul>
						<H4>Catering</H4>
						<ul>
							<li>
								Provide a variety of meal options and include items that are easy to eat. This includes foods that are
								not too messy, and foods that do not require utensils or intricacy.
							</li>
							<li>
								Ensure catering staff are briefed and available to assist attendees with serving items where required.
							</li>
							<li>
								Ensure special meals (e.g. vegetarian, gluten free, etc) are clearly labelled and easily accessible.
							</li>
						</ul>
					</div>

					<H3>Gig Buddies Sydney</H3>
					<P>https://www.gigbuddiessydney.org/</P>
				</ContentBlock>
			</div>
		);
	}
}

export default Tips;
