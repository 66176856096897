import { css } from "@emotion/css";

const PageContainer = ({ header, children, footer, bgcolor = "#f9f9fa" }) => {
	const wrap = css({
		background: bgcolor,
		backgroundSize: "735px auto",
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		alignItems: "center",
		justifyContent: "center",
		"@media(max-width:600px)": {
			padding: "0px 12px 0px 12px"
		}
	});
	const container = css({
		display: "flex",
		maxWidth: 900,
		width: "80%",
		margin: "16px auto 12px auto",
		position: "relative",
		borderRadius: "4px",
		boxShadow: "0 1px 10px 0 rgba(9, 30, 66, 0.13)",
		background: "#fff",
		"@media (max-width: 600px)": {
			width: "100%"
		}
	});

	return (
		<div className={wrap}>
			{header}
			<div className={container}>{children}</div>
			{footer}
		</div>
	);
};

export { PageContainer };
