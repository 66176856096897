import { Modal } from "@/ui/antd";
import { useState } from "react";
import { Field, FieldArray } from "redux-form";
import { RichTextAreaField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getMessagePersonalisations } from "@/utils/Personalisations";
import TicketMessagesTable from "./TicketMessagesTable";
import PersonalisationInformation from "@/components/PersonalisationInformation";

const personalisationsObject = getMessagePersonalisations();

let TicketMessages = ({ currentEvent, messages, changeFieldValue }) => {
	const [{ previewModalOpen }, updateModels] = useState({
		previewModalOpen: false
	});

	const togglePreviewModal = () => {
		updateModels({ previewModalOpen: !previewModalOpen });
	};

	return (
		<>
			<LegacyButton
				style={{ float: "right" }}
				icon={{ left: true, name: "preview" }}
				type="action"
				flavor="minimal"
				onClick={togglePreviewModal}
				ariaLabel="Preview ticket"
			>
				Preview
			</LegacyButton>
			<Field
				label="Add a message to the tickets"
				description="This message will show up on the ticket emailed to the customer"
				name="messages.ticketMessage"
				placeholder="Thank you for purchasing tickets! See you at the Event!"
				component={RichTextAreaField}
				minHeight={150}
				basicEditor={true}
				personalisations={personalisationsObject}
			/>
			<FieldArray
				name="messages.ticketMessagesByTicketType"
				ticketMessages={messages.ticketMessagesByTicketType}
				component={TicketMessagesTable}
				currentEvent={currentEvent}
				changeFieldValue={changeFieldValue}
			/>
			<PersonalisationInformation personalisationList={personalisationsObject} />

			<Modal
				title="Ticket Preview"
				open={previewModalOpen}
				onCancel={togglePreviewModal}
				destroyOnClose
				footer={
					<LegacyButton onClick={togglePreviewModal} ariaLabel="Close">
						Close
					</LegacyButton>
				}
			>
				{/* JEN-14649 disabled until v2 version is created  */}
				<p>Previews are currently not available. We’re building something new!</p>{" "}
				<p>To preview, we recommend making a free order</p>
				{/* <PreviewTicket
          eventId={currentEvent.event._id}
          currentEvent={currentEvent}
          ticketMessage={messages.ticketMessage}
          banner={banner}
          logo={ticketLogo}
        /> */}
			</Modal>
		</>
	);
};

export default TicketMessages;
