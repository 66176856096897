import { FullPageWrap, LoadingSpinner } from "@/components/AppBlocks";
import { H2 } from "@/components/text/Text";
import { connectStripe } from "@/state/actions/paymentActions";
import { useAppDispatch } from "@/state/hooks";
import { getUrlParameter } from "@/utils/Urls";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";

export const ConnectStripe: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const code = getUrlParameter("code");
	const scope = getUrlParameter("scope");
	const state = getUrlParameter("state");

	useEffect(() => {
		if (code && scope && state) {
			dispatch(connectStripe(state, code, navigate));
		}
	}, [code, scope, state]);

	return (
		<FullPageWrap>
			<div>
				<H2 style={{ textAlign: "center" }}>Connecting Stripe</H2>
				<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
			</div>
		</FullPageWrap>
	);
};
