import laptop from "@/assets/virtualEventHub/ic-laptop.svg";

const Empty = ({ visible }) => {
	if (!visible) {
		return null;
	}
	return (
		<>
			<div
				style={{
					background: `url(${laptop}) no-repeat center`,
					width: 84,
					height: 50,
					margin: "20px auto"
				}}
			/>
			<hr />
		</>
	);
};
export default Empty;
