import { Alert, Modal } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Icon from "@/components/Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { Label } from "@/components/text/Text";
import TicketsSoldList from "./components/TicketsSoldList";

import { resetResult } from "@/state/actions/schedulerActions";

let RescheduleResultModal = (props) => {
	const [modalState, setModalState] = useState({ open: false });

	useEffect(() => {
		const open = !!props.rescheduleResult.numberOfChangedDates;
		setModalState(() => ({ open }));
	}, [props.rescheduleResult]);

	const onClose = () => {
		setModalState({ ...modalState, open: false });
		props.resetResult();
	};

	const { changedDatesWithOrders, numberOfChangedDates } = props.rescheduleResult;
	const changeDateCount = changedDatesWithOrders.length;
	return (
		<Modal
			open={modalState.open}
			destroyOnClose
			title="Occurrence updated successfully"
			onCancel={onClose}
			footer={
				<>
					<LegacyButton
						style={{
							float: "right"
						}}
						key="cancel"
						onClick={onClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Got it!
					</LegacyButton>
				</>
			}
		>
			<div
				style={{
					display: numberOfChangedDates > 0 ? "flex" : "none",
					alignItems: "center"
				}}
			>
				<Icon style={{ marginRight: 8 }} icon="success-circle" />
				<Label bold={false}>{`${numberOfChangedDates} occurrences updated`}</Label>
			</div>
			{changeDateCount > 0 ? (
				<div style={{ marginTop: 20 }}>
					<Alert
						type="info"
						showIcon
						description="Some of these occurrences have sold tickets. Please notify affected ticket holders about these changes."
					/>
				</div>
			) : null}
			<div
				style={{
					display: changeDateCount > 0 ? "inherit" : "none",
					marginTop: 24
				}}
			>
				<TicketsSoldList dates={changedDatesWithOrders} />
			</div>
		</Modal>
	);
};

export default RescheduleResultModal = connect(
	(state) => {
		return {
			rescheduleResult: state.scheduler.rescheduleResult
		};
	},
	{ resetResult }
)(RescheduleResultModal);
