import { ContentBlock } from "@/components/AppBlocks";
import ApplyToAllSave from "@/components/ApplyToAllSave";
import FeeDisplay from "@/components/FeeDisplay";
import { LegacyInputField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { P } from "@/components/text/Text";
import AutoSelfServicePayoutSetting from "@/events/event/payments/AutoSelfServicePayoutSetting";
import DGRSettings from "@/events/event/payments/DGRSettings";
import PayoutDelayForm from "@/events/event/payments/PayoutDelayForm";
import RefundSettingsForm from "@/events/event/payments/RefundSettingsForm";
import TaxSettingsForm from "@/events/event/payments/TaxSettingsForm";
import PerEventFeeSettings from "@/events/event/payments/components/PerEventFeeSettings";
import withSaveCheck from "@/hoc/withSaveCheck";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { applyDefaultPaymentOptions } from "@/state/actions/applyToAllActions";
import { getFeeStructure, getUser, saveUser } from "@/state/actions/userActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { Alert, Tabs } from "@/ui/antd";
import { TaxSettings } from "@hx/utilities";
import { Fragment, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Field, InjectedFormProps, change, formValueSelector, reduxForm } from "redux-form";

const formName = "defaultPaymentSettingsForm";
const selector = formValueSelector(formName);

type UserDefaultPaymentSettings = {
	defaultPaymentOptions: {
		[locationId: string]: {
			invoiceCode: string;
			feeChargeSettings: any;
			salesTaxSettings: any;
			refundSettings: any;
			dgrInfo: any;
			autoSelfServe: any;
		};
	};
};

type DefaultPaymentSettingsComponentProps = PropsFromRedux &
	InjectedFormProps & {
		userId: string;
	};

const DefaultPaymentSettingsComponent = ({ userId, handleSubmit }: DefaultPaymentSettingsComponentProps) => {
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector((state) => state.currentUser);
	const locations = useAppSelector((state) => state.locations);
	const { isAdmin } = useCurrentUser();

	const { overrideLocation, userLevelLocation } = locations;

	const refundSettings = useAppSelector((state) =>
		selector(state, `defaultPaymentOptions.${overrideLocation}.refundSettings`)
	);
	const salesTaxSettings = useAppSelector((state) =>
		selector(state, `defaultPaymentOptions.${overrideLocation}.salesTaxSettings`)
	);
	const feeChargeSettings = useAppSelector((state) =>
		selector(state, `defaultPaymentOptions.${overrideLocation}.feeChargeSettings`)
	);
	const autoSelfServe = useAppSelector((state) =>
		selector(state, `defaultPaymentOptions.${overrideLocation}.autoSelfServe`)
	);
	const dgrEnabled = useAppSelector((state) =>
		selector(state, `defaultPaymentOptions.${overrideLocation}.dgrInfo.enabled`)
	);
	const customSalesTaxSettings = useAppSelector((state) =>
		selector(state, `defaultPaymentOptions.${overrideLocation}.customSalesTaxSettings`)
	);

	useEffect(() => {
		loadUser();
	}, []);

	useEffect(() => {
		dispatch(getFeeStructure(userId, overrideLocation || userLevelLocation));
	}, [overrideLocation]);

	const loadUser = () => {
		dispatch(getUser(userId));
	};

	const save = (values: Partial<UserDefaultPaymentSettings>) => {
		dispatch(
			saveUser(userId, {
				applyToAllRequired: true,
				defaultPaymentOptions: values.defaultPaymentOptions
			})
		);
	};

	const changeFieldValue = (field: any, value: any) => {
		dispatch(change(formName, field, value));
	};

	const _handleApplyToAllEvents = () => {
		dispatch(applyDefaultPaymentOptions(isAdmin ? userId : undefined));
	};

	const fees = currentUser?.feeStructure?.data;
	const taxInfo = TaxSettings.getEventTaxInfo(salesTaxSettings, overrideLocation);

	return (
		<LoadErrorView
			loading={currentUser.loading || currentUser.feeStructure.loading}
			error={currentUser.error || currentUser.feeStructure.error}
			retryAction={() => loadUser()}
		>
			<PageHeading title="Default payment settings">
				<LocationSelection componentName="defaultPaymentSettings" defaultLevel="user" onLocationChange={loadUser} />
			</PageHeading>
			<form onSubmit={handleSubmit(save)}>
				<Alert
					description={
						<div>
							These default settings will be applied to all new events created by you.
							<br />
							NOTE: Changes to these settings won't apply to existing events.
						</div>
					}
					type="info"
					showIcon
					style={{ marginBottom: 12, maxWidth: 870 }}
				/>
				<ContentBlock>
					<Tabs
						defaultActiveKey="payouts"
						items={[
							{
								children: (
									<Fragment>
										<Field
											name={`defaultPaymentOptions.${overrideLocation}.invoiceCode`}
											label="Note on Payout"
											description="Automatically include your GL code on the payout receipt sent with your payout confirmation email to help your finance team know where to allocate the payment. "
											component={LegacyInputField}
										/>
										<hr />
										<PayoutDelayForm formPath={`defaultPaymentOptions.${overrideLocation}.`} />
										<AutoSelfServicePayoutSetting
											formPath={`defaultPaymentOptions.${overrideLocation}.`}
											isVisible={currentUser?.user?.selfServicePayoutsSettings?.currentSettings}
											autoSelfServe={autoSelfServe}
										/>
									</Fragment>
								),
								key: "payouts",
								label: "Payouts"
							},
							{
								children: (
									<Fragment>
										<FeeDisplay fees={fees} />
										<P>
											Pass On Fees will add the fee to the ticket price for your customers.
											<br />
											Absorb Fees will absorb the fee inside the ticket price.
											<br />
											There are no fees for free tickets.
										</P>

										<PerEventFeeSettings
											feeStructure={currentUser.feeStructure.data}
											path={`defaultPaymentOptions.${overrideLocation}.feeChargeSettings`}
											feeChargeSettings={feeChargeSettings}
											changeFieldValue={changeFieldValue}
										/>
									</Fragment>
								),
								key: "fees",
								label: "Booking Fees"
							},
							{
								children: (
									<TaxSettingsForm
										salesTaxSettings={salesTaxSettings}
										location={overrideLocation}
										fromPath={`defaultPaymentOptions.${overrideLocation}.`}
										customSalesTaxSettings={customSalesTaxSettings}
									/>
								),
								key: "tax",
								label: taxInfo.name
							},
							{
								children: (
									<RefundSettingsForm
										refundSettings={refundSettings}
										fromPath={`defaultPaymentOptions.${overrideLocation}.`}
										changeFieldValue={changeFieldValue}
									/>
								),
								key: "refunds",
								label: "Refunds"
							},
							{
								children: (
									<DGRSettings
										dgrEnabled={dgrEnabled}
										location={overrideLocation}
										fromPath={`defaultPaymentOptions.${overrideLocation}.`}
									/>
								),
								key: "DGRReceipts",
								label: "DGR Receipts"
							}
						]}
					/>
				</ContentBlock>
				<FormBar>
					<ApplyToAllSave onOk={_handleApplyToAllEvents} />
				</FormBar>
			</form>
		</LoadErrorView>
	);
};

const mapStateToProps = (state: GlobalReduxState) => {
	const initState = state?.currentUser?.user ?? {
		defaultPaymentOptions: {}
	};
	return {
		initialValues: initState
	};
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const FormWithRedux = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(DefaultPaymentSettingsComponent);

const DefaultPaymentSettings = connector(withSaveCheck(FormWithRedux, formName));

export { DefaultPaymentSettings };
