import { css } from "@emotion/css";
import { ConfigProvider, Table } from "@/ui/antd";
import type { ColumnsType } from "antd/es/table";
import { ReactNode } from "react";
import SVG from "react-inlinesvg";
import withViewport, { type IWithViewPortProps } from "@/hoc/withViewport";
import iconReference from "@/services/IconReference";
import DraggableTable from "@/components/DraggableTable";
import { P } from "@/components/text/Text";

const emptyIcon = iconReference["no_data"];

const customizeRenderEmpty = () => {
	const wrapper = css({
		textAlign: "center",
		p: {
			maxWidth: "none"
		}
	});

	return (
		<div className={wrapper}>
			<SVG src={String(emptyIcon)} aria-hidden="true" />
			<P>Data Not Found</P>
		</div>
	);
};

interface IExpandedRowProps<DataType> {
	expandedRowKeys: (string | number)[];
	expandedRowRender: (data: DataType, index: number, options?: any) => ReactNode;
	expandedRowKey?: string | number;
	data: DataType;
	index: number;
	options?: any;
	isMobile: boolean;
}

const ExpandedRow = <
	DataType extends {
		[key: string]: string;
	}
>({
	expandedRowKeys,
	expandedRowRender,
	expandedRowKey,
	data,
	index,
	options,
	isMobile
}: IExpandedRowProps<DataType>) => {
	const _checkIsRowExpanded = () => {
		if (!expandedRowKeys.length) return false;
		switch (typeof expandedRowKey) {
			case "number": {
				return expandedRowKeys.includes(expandedRowKey);
			}
			case "string": {
				return expandedRowKeys.includes(Number(expandedRowKey));
			}
			default: {
				return false;
			}
		}
	};

	if (_checkIsRowExpanded()) {
		return (
			<div
				style={{
					backgroundColor: "var(--surface2)",
					border: "var(--border-color1)",
					padding: isMobile ? 12 : 0,
					marginTop: isMobile ? 12 : 0
				}}
			>
				{expandedRowRender(data, index, options)}
			</div>
		);
	}

	return null;
};

type ResponsiveTableInput<DataType> = {
	columns: ColumnsType<DataType>;
	dataSource: DataType[];
	rowKey: string;
	draggable: boolean;
	footer?: () => ReactNode;
	rowClassName?: (data: DataType) => string;
	noResults?: () => ReactNode;
} & IExpandedRowProps<DataType>;

const ResponsiveTable = <
	DataType extends {
		[key: string]: string;
	}
>(
	props: ResponsiveTableInput<DataType> & IWithViewPortProps
) => {
	const {
		columns,
		dataSource,
		rowKey,
		expandedRowKeys = [],
		expandedRowRender,
		options,
		draggable,
		rowClassName,
		noResults,
		isMobile
	} = props;

	const { footer, ...rest } = props;

	return (
		<ConfigProvider renderEmpty={noResults ? noResults : customizeRenderEmpty}>
			<div>
				{!isMobile ? (
					<div>{draggable ? <DraggableTable {...props} /> : <Table {...rest} />}</div>
				) : (
					<div>
						{dataSource?.map((data: DataType, index: number) => {
							const wrapper = css({
								borderBottom: "var(--border1)",
								padding: "14px 0px"
							});
							return (
								<div key={index} className={`${wrapper} ${rowClassName ? rowClassName(data) : ""}`}>
									<div
										className={css({
											display: "flex",
											flexWrap: "wrap",
											alignItems: "baseline"
										})}
										key={index}
									>
										{columns.map((col: any) => {
											const rendered = col?.render?.(col.dataIndex ? data[col.dataIndex] : data, data, index, true);
											if (rendered !== "") {
												return (
													<div key={col.key} className={css({ margin: 8 })}>
														{col.renderTitleMobile !== false ? (
															<div
																style={{
																	fontSize: 14,
																	color: "#323232",
																	fontWeight: 600
																}}
															>
																{col.title}
															</div>
														) : null}
														{dataSource[index]
															? col?.render?.(col.dataIndex ? data[col.dataIndex] : data, data, index, true)
															: ""}
													</div>
												);
											}
											return "";
										})}
									</div>

									<ExpandedRow
										expandedRowKeys={expandedRowKeys}
										expandedRowRender={expandedRowRender}
										data={data}
										expandedRowKey={data[rowKey]}
										index={index}
										options={options}
										isMobile={isMobile}
									/>
								</div>
							);
						})}
						{footer ? footer() : null}
					</div>
				)}
			</div>
		</ConfigProvider>
	);
};
export default withViewport(ResponsiveTable);
