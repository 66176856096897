import { Alert } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import P from "@/components/text/P";
import { bulkUpload } from "@/state/actions/bulkUploadActions";

const countOrders = (csv) => {
	const countOfOrders = csv.slice(1).reduce(
		(counter, row) => {
			if (row[0]) {
				counter[row[0]] = counter[row[0]] ? counter[row[0]] : 1;
			} else {
				counter.nonassigned = counter.nonassigned + 1;
			}
			return counter;
		},
		{ nonassigned: 0 }
	);
	return Object.values(countOfOrders).reduce((partialSum, count) => partialSum + count, 0);
};

class UploadTickets extends Component {
	submit = (values) => {
		const eventId = this.props.currentEvent.event._id;
		this.props.bulkUpload(eventId, this.props.eventDateId, values.emailOrder, this.props.csv, this.props.fields);
	};

	render() {
		const { handleSubmit, csv } = this.props;
		const { error, success } = this.props.order.bulkUpload;
		const ticketsPlural = csv.length - 1 === 1 ? "" : "s";
		const orderCount = countOrders(csv);
		const ordersPlural = orderCount === 1 ? "" : "s";
		return (
			<form onSubmit={handleSubmit(this.submit)}>
				<ContentBlock>
					{error || success ? (
						<Alert
							style={{ marginBottom: 12 }}
							message={error ? "Error" : "Success"}
							description={
								<div>
									{error ||
										"Your file has successfully been uploaded. Expect an email from us shortly with a summary of the results of your ticket generation!"}
								</div>
							}
							type={error ? "error" : "success"}
							showIcon
						/>
					) : null}
					{!error && !success ? (
						<>
							<Alert
								style={{ marginBottom: 12 }}
								type="warning"
								showIcon
								message="Are you sure?"
								description={
									<div>
										<P>
											<b>
												{orderCount} order{ordersPlural}
											</b>{" "}
											will be created
										</P>
										<P>
											<b>
												{csv.length - 1} ticket{ticketsPlural}
											</b>{" "}
											will be created.
										</P>
									</div>
								}
							/>
							<Field name="emailOrder" label="Email each order created" component={CheckboxField} inline labelAfter />
						</>
					) : null}
				</ContentBlock>
				{error || success ? null : (
					<FormBar>
						<LegacyButton
							style={{
								float: "right",
								width: "100px"
							}}
							onClick={handleSubmit(this.submit)}
							type="primary"
							ariaLabel="upload"
						>
							Upload
						</LegacyButton>
					</FormBar>
				)}
			</form>
		);
	}
}

UploadTickets = reduxForm({
	form: "confirmBulkUpload",
	touchOnChange: true,
	touchOnBlur: true
})(UploadTickets);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		order: state.order,
		initialValues: { emailOrder: true }
	}),
	{
		bulkUpload
	}
)(UploadTickets);
