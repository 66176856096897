import { css } from "@emotion/css";
import React from "react";

const Label = ({
	bold = true,
	htmlFor,
	maxWidth,
	children,
	customStyle = {}
}: {
	bold?: boolean;
	htmlFor?: any;
	maxWidth?: number;
	children: React.ReactNode;
	customStyle?: any;
}) => {
	const style = css({
		margin: 0,
		fontWeight: bold ? 600 : 400,
		fontSize: 14,
		marginBottom: 4,
		display: "block",
		lineHeight: "19px",
		width: "100%",
		maxWidth: maxWidth ? maxWidth : 500,
		...customStyle,
		"@media(max-width: 600px)": {
			fontSize: 14,
			marginBottom: 4
		}
	});
	return (
		<label className={style} htmlFor={htmlFor}>
			{children}
		</label>
	);
};

export default Label;
