const Expand: Icon = ({ color = "#67717E" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path
					fill={color}
					d="M12 13.568l4.278-4.273a1.01 1.01 0 0 1 1.427 0 1.006 1.006 0 0 1 0 1.424l-4.992 4.986a1.01 1.01 0 0 1-1.426 0l-4.992-4.986a1.006 1.006 0 0 1 0-1.424 1.01 1.01 0 0 1 1.427 0L12 13.568z"
				/>
				<path d="M0 0h24v24H0z" />
			</g>
		</svg>
	);
};

export default Expand;
