const Smile = ({ color = "#57CFC7", style }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 71" style={style}>
			<g fill={color} fillRule="nonzero">
				<path d="M36.203 40.14c-3.106 0-4.744-1.654-4.744-4.781V27.33a1.953 1.953 0 0 0-.559-1.543 1.925 1.925 0 0 0-1.533-.563H18.585a1.925 1.925 0 0 0-1.533.563 1.953 1.953 0 0 0-.56 1.543v8.029c0 3.127-1.642 4.78-4.748 4.78h-7C1.643 40.14 0 38.486 0 35.36V5.393C0 2.266 1.643.618 4.744.618h7c3.106 0 4.749 1.648 4.749 4.775v5.413c-.051.573.153 1.139.557 1.546.403.407.965.614 1.535.564h10.782c.57.05 1.132-.157 1.535-.564.404-.407.608-.973.557-1.546V5.393c0-3.127 1.638-4.775 4.744-4.775h7c3.106 0 4.749 1.648 4.749 4.775V35.36c0 3.127-1.643 4.78-4.75 4.78h-7zM44.498 70.81c-2.402.001-4.801-.148-7.184-.447a5.255 5.255 0 0 1-3.623-2.179 5.402 5.402 0 0 1-.875-4.226l.73-3.472a5.366 5.366 0 0 1 5.193-4.289c.205 0 .41.014.614.039 1.707.201 3.425.302 5.145.301 9.589 0 18.782-3.258 23.42-8.3a5.289 5.289 0 0 1 6.478-1.032L77.58 49a5.348 5.348 0 0 1 2.632 3.737 5.374 5.374 0 0 1-1.207 4.418c-7.329 8.55-20.232 13.655-34.507 13.655zM88.937 40.752c-1.304 0-2.604-.584-3.865-1.74l-7.425-6.872-7.43 6.871c-1.25 1.157-2.55 1.741-3.864 1.741s-2.6-.584-3.865-1.74l-5.082-4.699c-1.237-1.142-1.865-2.324-1.865-3.5 0-1.178.628-2.36 1.865-3.507l7.522-6.93-7.498-6.925c-1.237-1.148-1.865-2.33-1.865-3.506 0-1.177.628-2.359 1.865-3.506l5.082-4.693C63.768.588 65.058 0 66.362 0c1.305 0 2.604.588 3.865 1.746l7.43 6.871 7.425-6.871C86.333.588 87.632 0 88.947 0c1.314 0 2.599.588 3.865 1.746l5.077 4.693c1.241 1.147 1.87 2.324 1.87 3.506s-.629 2.358-1.87 3.506l-7.517 6.925 7.493 6.93c1.241 1.147 1.87 2.324 1.87 3.506s-.629 2.359-1.87 3.501l-5.078 4.698c-1.25 1.157-2.545 1.741-3.85 1.741z" />
			</g>
		</svg>
	);
};

export default Smile;
