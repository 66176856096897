import { Modal } from "@/ui/antd";
import { Component } from "react";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const elements = [
	// {
	//   name: "Livestream",
	//   inputType: "livestream",
	//   type: "link"
	// },
	// {
	//   name: "Webinar",
	//   inputType: "webinar",
	//   type: "link"
	// },
	{
		name: "Video, Livestream or Webinar",
		inputType: "video",
		type: "link"
	},
	{
		name: "Text",
		inputType: "text"
	},
	{
		name: "Presenters",
		inputType: "presenters"
	}
];

class BodyElementsModal extends Component {
	render() {
		const { visible, closePicker, elementSelected } = this.props;
		return (
			<Modal title="Select element" open={visible} onCancel={closePicker} footer={null}>
				{elements.map((element, index) => (
					<LegacyButton
						key={index}
						onClick={() =>
							elementSelected({
								...element,
								settings: { showWhen: "always" }
							})
						}
						type="action"
						flavor="minimal"
						icon={{ name: "plus", left: true }}
						ariaLabel={element.name}
					>
						{element.name}
					</LegacyButton>
				))}
			</Modal>
		);
	}
}

export default BodyElementsModal;
