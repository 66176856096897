import { Field } from "redux-form";
import { CheckboxField } from "@/components/Fields";

import IconButton from "@/components/IconButton";

const AfterCheckout = ({ questionIndex }) => {
	const styles = {
		container: {
			marginTop: 0
		},
		description: {
			marginBottom: 10
		}
	};
	const tooltipMessage = `Don’t make your attendees feel overwhelmed with the information they need to provide. Let them make their purchase and afterwards fill out the checkout questions.`;
	return (
		<div style={styles.container}>
			<Field
				label={
					<div style={styles.description}>
						Ask this question after checkout
						<IconButton icon="info_hover" tooltip={tooltipMessage} showTooltip ariaLabel={tooltipMessage} />
					</div>
				}
				name={`additionalQuestions[${questionIndex}].askAfterCheckout`}
				type="text"
				component={CheckboxField}
				inline
				labelAfter
			/>
		</div>
	);
};

export default AfterCheckout;
