import { css } from "@emotion/css";
import { ReactNode } from "react";

const ButtonsBar = ({ children, footer, style }: { children: ReactNode; footer?: ReactNode; style?: any }) => {
	const wrapper = css({
		...style,
		display: "flex",
		justifyContent: footer ? "space-between" : "center",
		...(!footer && {
			padding: "12px 0",

			button: {
				margin: "0px 6px"
			},
			"@media(max-width: 600px)": {
				display: "block",
				justifyContent: "flex-start",
				padding: 0,
				button: {
					width: "100%",
					margin: "6px 0px"
				}
			}
		})
	});

	return <div className={wrapper}>{children}</div>;
};
export default ButtonsBar;
