export type Dict = {
	[key: string]: any;
};

/**
 * Individual tracking events should extend this class. See `UserActiveEvent` or `UserSignupEvent` for examples.
 * By providing an instance of this class to the tracking service, it can be sent to supported analytics providers.
 *
 * As an example, the `UserActiveEvent` extends this class.
 * Calling `TrackingService.track(new UserActiveEvent("festival"))` will transform and send the `UserActiveEvent` event to supported tracking providers.
 *
 * @member props - internal properties available when transforming the event into a specific tracking format. Set in the child class.
 * @method toDataLayerMessage - transforms the event to a message that can be pushed to the GTM Data Layer. Implemented in the child class.
 */
abstract class TrackingEvent<P extends Dict> {
	public readonly name: string;
	public readonly dataLayerEventName: string;
	protected props: P;
	abstract toDataLayerMessage(): Dict;

	/**
	 * @param name - the human readable name of the event
	 * @param dataLayerEventName - the event's corresponding Google Tag Manager (GTM) event name. Ask the growth team if you're unsure.
	 */
	constructor(name: string, dataLayerEventName: string) {
		this.name = name;
		this.dataLayerEventName = dataLayerEventName;
		this.props = {} as P;
	}
}

export default TrackingEvent;
