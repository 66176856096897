import { Field } from "redux-form";
import { CheckboxField } from "@/components/Fields";

const ShowOnTicket = ({ questionIndex }) => {
	const styles = {
		container: {
			marginTop: 0
		},
		description: {
			marginBottom: 10
		}
	};
	return (
		<div style={styles.container}>
			<Field
				label={<div style={styles.description}>Show this checkout question and answer on the buyers ticket</div>}
				name={`additionalQuestions[${questionIndex}].showOnTickets`}
				type="text"
				component={CheckboxField}
				inline
				labelAfter
			/>
		</div>
	);
};

export default ShowOnTicket;
