import { css } from "@emotion/css";
import plus from "@/assets/ic_plus_onyx.svg";

const primaryColour = "#353337";
const secondaryColour = "#ffb18f";
const grey1 = "#5a626c";
const grey2 = "#f1f1f3";
const grey3 = "#b3b7bc";
const white = "#ffffff";

const addButton = {
	"&:hover": {
		".fc-daygrid-day-top": {
			"&:after": {
				display: "block",
				content: '"Add"',
				color: primaryColour,
				fontSize: 14,
				fontWeight: 600,
				backgroundImage: `url(${plus})`,
				backgroundRepeat: "no-repeat",
				position: "absolute",
				left: 0,
				right: 0,
				top: 4,
				paddingLeft: 14,
				backgroundPosition: "14px -2px"
			}
		}
	}
};

export const RecurringDatesOverrides = css({
	//global stuff
	a: {
		color: grey1
	},
	"a.fc-list-day-text": {
		color: grey1,
		cursor: "default",
		":hover": {
			color: "#5a626c"
		}
	},
	".fc-highlight": {
		backgroundColor: "transparent"
	},
	//date box wrapper
	".fc-daygrid-day.fc-day": {
		height: 120
	},
	//little number boys in top right
	".fc-daygrid-day-number": {
		color: grey3,
		fontWeight: 600
	},
	".fc-daygrid-day.fc-day.fc-day-today .fc-daygrid-day-number": {
		color: primaryColour
	},
	".fc-daygrid-day.fc-day.fc-day-future .fc-daygrid-day-number": {
		color: grey1
	},
	//date box colour thingy
	".fc-daygrid-event": {
		backgroundColor: white,
		color: grey3,
		"&:hover": {
			backgroundColor: white
		}
	},
	".fc-daygrid-event.fc-event.fc-event-past": {
		borderColor: white
	},
	".fc-daygrid-event.fc-event.fc-event-past .fc-event-main": {
		color: grey3
	},
	".fc-daygrid-event.fc-event-future": {
		backgroundColor: primaryColour,
		color: white
	},
	".fc-daygrid-event.fc-event-today": {
		backgroundColor: primaryColour,
		color: white
	},
	".fc-event-title": {
		fontWeight: "normal",
		margin: "auto",
		flexGrow: "unset",
		fontSize: 14
	},
	//see more link ie "3+ more"
	".fc-daygrid-more-link": {
		color: primaryColour,
		fontWeight: 600,
		fontSize: 14
	},
	".fc-day-past .fc-daygrid-more-link": {
		color: grey3,
		fontWeight: 400,
		fontSize: 12
	},
	//individual date box thingy
	".fc-daygrid-day": {
		textAlign: "center",
		cursor: "pointer"
	},
	".fc .fc-daygrid-day.fc-day-today": {
		backgroundColor: white,
		...addButton
	},
	".fc .fc-daygrid-day.fc-day-future": addButton,
	".fc-daygrid-day.fc-day-past": {
		backgroundColor: grey2
	},
	//removing some shit
	".fc-daygrid-event-dot": {
		display: "none"
	},
	".fc-event-time": {
		display: "none"
	},
	//see more modal internals
	".fc-popover": {
		".fc-daygrid-event.fc-event": {
			textAlign: "center",
			".fc-event-title": {
				fontSize: 12,
				fontWeight: 400
			}
		}
	},

	//list view shit
	".fc .fc-list-event-dot": {
		borderColor: secondaryColour
	},
	".fc .fc-list-event.fc-event.fc-event-future .fc-list-event-dot": {
		borderColor: secondaryColour
	},
	".fc .fc-list-event.fc-event.fc-event-today .fc-list-event-dot": {
		borderColor: secondaryColour
	},
	".fc .fc-list-event.fc-event.fc-event-past .fc-list-event-dot": {
		borderColor: primaryColour
	},

	//hide second title
	".fc-list-event-title": {
		display: "none"
	}
});
