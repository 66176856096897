import { AnotherFuckingCheckboxField, DateField, InputNumberField, RadioField, SelectField } from "@/components/Fields";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { Label } from "@/components/text/Text";
import { dayjs } from "@/libs/dayjs";
import { Radio } from "@/ui/antd";
import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import TimeRange from "./components/TimeRange";
import { checkEndDate, dateNumToStringOccurrence, daysOfWeek, futureDateRequired } from "./dateFormUtils";

export const formName = "scheduleForm";

const Separator = () => (
	<div
		style={{
			borderBottom: "solid 1px #dedede",
			width: "100%",
			marginBottom: 16
		}}
	/>
);

let ScheduleForm = ({
	scheduleType,
	touch,
	handleSubmit,
	onSubmit,
	onDays,
	forcedActiveDay,
	dispatch,
	startDate,
	timezone
}) => {
	useEffect(() => {
		if (!onDays.includes(forcedActiveDay)) {
			dispatch(
				change(
					formName,
					"onDays",
					onDays.concat(forcedActiveDay).filter((day) => day)
				)
			);
		}
	}, [forcedActiveDay, onDays]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<TimeRange touch={touch} />
			<Field
				label="When will this event occur?"
				name="type"
				component={RadioField}
				button
				style={{
					"@media(max-width: 600px)": {
						".ant-radio-button-wrapper": {
							padding: "0px 12px !important"
						}
					}
				}}
				options={[
					{
						label: "Once",
						value: "ONCE"
					},
					{
						label: "Daily",
						value: "DAILY"
					},
					{
						label: "Weekly",
						value: "WEEKLY"
					},
					{
						label: "Monthly",
						value: "MONTHLY"
					}
				]}
			/>
			<RepeatFields scheduleType={scheduleType} startDate={startDate} />
			<RecurringEnds scheduleType={scheduleType} dispatch={dispatch} timezone={timezone} />
		</form>
	);
};

const RepeatFields = ({ scheduleType, startDate }) => {
	if (scheduleType === "ONCE") {
		return null;
	}
	if (scheduleType === "DAILY") {
		return (
			<>
				<Field
					label="Repeat every"
					name="skip"
					component={InputNumberField}
					min={1}
					max={7}
					formatter={(value) => `${value} Day`}
					inverseFormatter={(value) => value.replace(" Day", "")}
				/>
				<Separator />
			</>
		);
	}
	if (scheduleType === "WEEKLY") {
		return (
			<>
				<Field
					label="Repeat every"
					name="skip"
					component={InputNumberField}
					min={1}
					formatter={(value) => `${value} Week`}
					inverseFormatter={(value) => value.replace(" Week", "")}
				/>

				<Field label="Repeat on" name="onDays" component={AnotherFuckingCheckboxField} options={daysOfWeek} />

				<Separator />
			</>
		);
	}
	if (scheduleType === "MONTHLY") {
		return (
			<>
				<Field
					label="Repeat every"
					name="skip"
					component={InputNumberField}
					min={1}
					formatter={(value) => `${value} Month`}
					inverseFormatter={(value) => value.replace(" Month", "")}
				/>
				<Field
					label="Repeat on"
					name="exactPosition"
					component={SelectField}
					button
					buttonStyle="solid"
					options={[
						{
							value: true,
							label: `On the ${dayjs(startDate).format("Do")}`
						},
						{
							value: false,
							label: `On the ${dateNumToStringOccurrence(startDate)} ${dayjs(startDate).format("dddd")}`
						}
					]}
				/>
			</>
		);
	}
	return null;
};

const RecurringEnds = ({ scheduleType, dispatch, timezone }) => {
	const [radioChoice, radioChange] = useState(1);

	if (scheduleType === "ONCE") {
		return null;
	}

	const radioStyle = {
		display: "flex",
		alignItems: "center",
		marginBottom: 12
	};

	const onFieldChange = (option) => () => {
		radioChange(option);
		const fieldNameToRemove = option === 1 ? "ending.afterNumberOfInstances" : "ending.endOnNthDate";
		dispatch(change(formName, fieldNameToRemove, null));
	};

	return (
		<>
			<InputLabel label="Recurring ends" required bold />
			<Radio.Group value={radioChoice} onChange={({ target: { value } }) => onFieldChange(value)()}>
				<Radio style={radioStyle} value={1}>
					<div
						className={css({
							".ant-row": {
								marginBottom: "0px !important"
							}
						})}
					>
						<Field
							label="On specific date"
							name="ending.endOnNthDate"
							type="text"
							onChange={onFieldChange(1)}
							component={DateField}
							validate={[checkEndDate, futureDateRequired]}
							placeholder="End Time"
							timezone={timezone}
							inline
							noMargin
						/>
					</div>
				</Radio>
				<Radio style={radioStyle} value={2}>
					<div style={{ display: "flex" }}>
						<div style={{ alignSelf: "center", marginRight: 8 }}>
							<Label>After</Label>
						</div>
						<div
							className={css({
								".ant-row": {
									marginBottom: "0px !important"
								}
							})}
						>
							<Field
								dataCy="afterNumberOfInstances"
								label="occurrences"
								name="ending.afterNumberOfInstances"
								onChange={onFieldChange(2)}
								component={InputNumberField}
								min={1}
								inline
								labelAfter
								style={{ marginBottom: 0 }}
								noMargin
							/>
						</div>
					</div>
				</Radio>
			</Radio.Group>
		</>
	);
};

// Decorate with redux-form
ScheduleForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: false,
	enableReinitialize: false,
	destroyOnUnmount: true,
	onSubmitFail: () => {
		// scrollToErrors();
	}
})(ScheduleForm);

export default ScheduleForm = connect((state, props) => {
	const selector = formValueSelector(formName);
	const onDays = selector(state, "onDays") || [];
	const startDate = selector(state, "startDate");
	const scheduleType = selector(state, "type");
	let forcedActiveDay;
	if (startDate) {
		const momStartDate = dayjs(startDate).tz(state.currentEvent.event.timezone);
		forcedActiveDay = momStartDate.format("dddd");
	}
	const initialValues = {
		...props.scheduleValues
	};
	return {
		initialValues,
		scheduleType,
		onDays,
		forcedActiveDay,
		startDate,
		timezone: state.currentEvent.event.timezone
	};
})(ScheduleForm);
