import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { LoadingSpinner } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LinkButton from "@/components/buttons/LinkButton";
import { disassociateCustomQrCode, updateCheckIn } from "@/state/actions/checkInActions";

class TicketActions extends Component {
	constructor(props) {
		super(props);
		this.actionClicked = this.actionClicked.bind(this);
	}
	actionClicked(evt) {
		const { ticket } = this.props;
		switch (evt.key) {
			case "check-in":
				this.props.updateCheckIn(ticket._id, !ticket._checkedIn);
				break;
			case "remove-association":
				this.props.disassociateCustomQrCode(ticket._id);
				break;
			default:
				break;
		}
	}
	render() {
		const { checkIn, ticket } = this.props;
		const menuItems = [
			{
				key: "check-in",
				label: ticket._checkedIn ? "Check out" : "Check In"
			},
			{
				key: "remove-association",
				label: "Disassociate"
			}
		];
		if (checkIn.loading) {
			return <LoadingSpinner size={24} />;
		}

		if (ticket._status !== "complete") {
			return null;
		}
		return (
			<div style={{ minWidth: 82 }}>
				{ticket._customQrCodeData ? (
					<Dropdown
						menu={{
							items: menuItems,
							onClick: (event) => this.actionClicked(event)
						}}
						trigger={["click"]}
					>
						<LinkButton className="ant-dropdown-link" ariaLabel="Actions">
							Actions
							<DownOutlined style={{ marginLeft: 4 }} />
						</LinkButton>
					</Dropdown>
				) : (
					<LoadErrorView
						loading={checkIn.loading && ticket._id === checkIn.currentId}
						error={checkIn.checkInError && ticket._id === checkIn.currentId ? checkIn.checkInError : null}
						retryAction={() => this.props.updateCheckIn(ticket._id, !ticket._checkedIn)}
					>
						<LinkButton
							onClick={() => this.props.updateCheckIn(ticket._id, !ticket._checkedIn)}
							ariaLabel={ticket._checkedIn ? "Check Out" : "Check In"}
						>
							{ticket._checkedIn ? "Check Out" : "Check In"}
						</LinkButton>
					</LoadErrorView>
				)}
			</div>
		);
	}
}

export default connect(
	(state) => ({
		checkIn: state.checkIn
	}),
	{
		updateCheckIn,
		disassociateCustomQrCode
	}
)(TicketActions);
