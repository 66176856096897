import { Button, Table } from "@/ui/antd";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ContentBlock, LoadingSpinner } from "@/components/AppBlocks";
import { H2, H3, H4, P } from "@/components/text/Text";
import AdminReportService from "@/services/AdminReportService";
import { currency } from "@/utils/Format";

let columns = [
	{
		title: "Order Id",
		dataIndex: "orderId",
		key: "orderId"
	},
	{
		title: "Payment total",
		dataIndex: "paymentAmount",
		key: "paymentAmount",
		render: (value) => currency(value)
	},
	{
		title: "Order Amount",
		dataIndex: "orderAmount",
		key: "orderAmount",
		render: (value) => currency(value)
	},
	{
		title: "Refund",
		dataIndex: "refund",
		key: "refund",
		render: (value) => String(value)
	},
	{
		title: "Missing",
		dataIndex: "missing",
		key: "missing",
		render: (value) => String(value)
	},
	{
		title: "Error",
		dataIndex: "error",
		key: "error",
		render: (value) => String(value)
	}
];

const settlementCols = [
	{
		title: "Type",
		dataIndex: "type",
		key: "type",
		sorter: (a, b) => a.type < b.type
	},
	{
		title: "Settled date",
		dataIndex: "settledDate",
		key: "settledDate",
		render: (value) => currency(value),
		sorter: (a, b) => a.settledDate < b.settledDate
	},
	{
		title: "Amount",
		dataIndex: "amount",
		key: "amount",
		render: (value) => currency(value),
		sorter: (a, b) => a.amount - b.amount
	}
];

const paymentCols = [
	{
		title: "Found",
		dataIndex: "found",
		key: "found"
	},
	{
		title: "In File",
		dataIndex: "infile",
		key: "infile"
	},
	{
		title: "Expecting",
		dataIndex: "expecting",
		key: "expecting"
	}
];

const Results = ({ data, updateStep }) => {
	const [{ loading, errors, results }, updateState] = useState({
		loading: true,
		errors: false,
		results: {}
	});

	useEffect(() => {
		async function comparePayments() {
			try {
				const { csv, fields } = data;
				const results = await AdminReportService.comparePayments(csv, fields);
				updateState({ loading: false, errors: false, results });
			} catch (err) {
				updateState({ loading: false, errors: true, results: undefined });
			}
		}

		comparePayments();
	}, []);
	const { paymentsWithInfo, info, rowCount, settlements } = results;
	if (loading || errors) {
		return (
			<ContentBlock>
				<Button style={{ float: "right" }} onClick={() => updateStep({ step: 0, data: null })}>
					Restart
				</Button>
				<H2>Results</H2>
				{loading ? <LoadingSpinner /> : null}
				{errors ? <H2>Error</H2> : null}
			</ContentBlock>
		);
	}

	const paymentErrors = paymentsWithInfo.filter((payment) => payment.error);

	return (
		<ContentBlock>
			<Button style={{ float: "right" }} onClick={() => updateStep(0)}>
				Restart
			</Button>
			<H2>Results</H2>
			<>
				<P>Files contained {rowCount} rows</P>
				<P>Payment total: {currency(info.paymentTotal)} </P>
				<P>Order total: {currency(info.orderTotal)} </P>
				<div style={{ marginBottom: 12 }}>
					<H3>Payments</H3>
					<Table
						pagination={{ hideOnSinglePage: true, showSizeChanger: false }}
						columns={paymentCols}
						dataSource={[
							{
								found: info.payments.found,
								infile: info.payments.infile,
								expecting: info.payments.expecting,
								firstDate: info.payments.firstDate,
								lastDate: info.payments.lastDate
							}
						]}
					/>
				</div>
				<div style={{ marginBottom: 12 }}>
					<H3>Refunds</H3>
					<Table
						columns={paymentCols}
						pagination={{ hideOnSinglePage: true, showSizeChanger: false }}
						dataSource={[
							{
								found: info.refunds.found,
								infile: info.refunds.infile,
								expecting: info.refunds.expecting,
								firstDate: info.refunds.firstDate,
								lastDate: info.refunds.lastDate
							}
						]}
					/>
				</div>
				<div style={{ marginBottom: 12 }}>
					<H4>Settlements</H4>
					<Table
						pagination={{ hideOnSinglePage: true, showSizeChanger: false }}
						columns={settlementCols}
						dataSource={settlements}
						rowKey="_id"
					/>
				</div>
				<div style={{ marginBottom: 12 }}>
					<CSVLink filename="payments.csv" data={paymentsWithInfo}>
						<Button>Download Payments</Button>
					</CSVLink>
					{paymentErrors && paymentErrors.length ? (
						<CSVLink filename="paymentErrors.csv" data={paymentErrors}>
							<Button>Download Errors</Button>
						</CSVLink>
					) : null}
				</div>
				{paymentErrors && paymentErrors.length ? (
					<Table
						pagination={{ hideOnSinglePage: true, defaultPageSize: 50, showSizeChanger: false }}
						columns={columns}
						dataSource={paymentErrors}
						rowKey="_id"
					/>
				) : null}
			</>
		</ContentBlock>
	);
};

export default Results;
