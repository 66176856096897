import { notification } from "@/ui/antd";
import MassPayoutService from "../../services/MassPayoutsService";
import FileDownload from "../../utils/FileDownload";
import { getLocationFromState } from "./locationActions";

export const FIND_MASS_PAYOUTS_REQUEST = "FIND_MASS_PAYOUTS_REQUEST";
export const FIND_MASS_PAYOUTS_SUCCESS = "FIND_MASS_PAYOUTS_SUCCESS";
export const FIND_MASS_PAYOUTS_ERROR = "FIND_MASS_PAYOUTS_ERROR";

export const REQ_MASS_PAYOUT_REQUEST = "REQ_MASS_PAYOUT_REQUEST";
const REQ_MASS_PAYOUT_SUCCESS = "REQ_MASS_PAYOUT_SUCCESS";
export const REQ_MASS_PAYOUT_ERROR = "REQ_MASS_PAYOUT_ERROR";

const EXPORT_MASS_PAYOUT_REQUEST = "EXPORT_MASS_PAYOUT_REQUEST";
const EXPORT_MASS_PAYOUT_SUCCESS = "EXPORT_MASS_PAYOUT_SUCCESS";
const EXPORT_MASS_PAYOUT_ERROR = "EXPORT_MASS_PAYOUT_ERROR";

export const GET_MASS_PAYOUT_REQUEST = "GET_MASS_PAYOUT_REQUEST";
export const GET_MASS_PAYOUT_SUCCESS = "GET_MASS_PAYOUT_SUCCESS";
export const GET_MASS_PAYOUT_ERROR = "GET_MASS_PAYOUT_ERROR";

export const GET_MASS_PAYOUT_CSV_REQUEST = "GET_MASS_PAYOUT_CSV_REQUEST";
export const GET_MASS_PAYOUT_CSV_SUCCESS = "GET_MASS_PAYOUT_CSV_SUCCESS";
export const GET_MASS_PAYOUT_CSV_ERROR = "GET_MASS_PAYOUT_CSV_ERROR";

const PAY_MASS_PAYOUT_REQUEST = "PAY_MASS_PAYOUT_REQUEST";
const PAY_MASS_PAYOUT_SUCCESS = "PAY_MASS_PAYOUT_SUCCESS";
const PAY_MASS_PAYOUT_ERROR = "PAY_MASS_PAYOUT_ERROR";

const CANCEL_PAY_MASS_PAYOUT_REQUEST = "CANCEL_PAY_MASS_PAYOUT_REQUEST";
const CANCEL_PAY_MASS_PAYOUT_SUCCESS = "CANCEL_PAY_MASS_PAYOUT_SUCCESS";
const CANCEL_PAY_MASS_PAYOUT_ERROR = "CANCEL_PAY_MASS_PAYOUT_ERROR";

const CANCEL_MASS_PAYOUT_REQUEST = "CANCEL_MASS_PAYOUT_REQUEST";
const CANCEL_MASS_PAYOUT_SUCCESS = "CANCEL_MASS_PAYOUT_SUCCESS";
const CANCEL_MASS_PAYOUT_ERROR = "CANCEL_MASS_PAYOUT_ERROR";

export const find = (page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FIND_MASS_PAYOUTS_REQUEST, page });
		MassPayoutService.find(page, location)
			.then((result) => {
				dispatch({ type: FIND_MASS_PAYOUTS_SUCCESS, result });
			})
			.catch((err) => {
				dispatch({ type: FIND_MASS_PAYOUTS_ERROR, error: err });
			});
	};
};

export const requestMassPayout = () => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const location = state.locations.overrideLocation;
			dispatch({ type: REQ_MASS_PAYOUT_REQUEST });
			await MassPayoutService.requestMassPayout(location);
			notification.success({
				message: "Success",
				description: "Payout Created"
			});
			dispatch({ type: REQ_MASS_PAYOUT_SUCCESS });
			dispatch(find(1));
		} catch (err) {
			const message = err && err.response && err.response.data ? err.response.data : "Failed to request payment";
			notification.error({
				message: "Error Creating Payout",
				description: err.response.data
			});
			dispatch({ type: REQ_MASS_PAYOUT_ERROR, error: message });
		}
	};
};

export const getMassPayout = (massPaymentId) => {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_MASS_PAYOUT_REQUEST, massPaymentId });
			const payments = await MassPayoutService.getMassPayout(massPaymentId);
			dispatch({ type: GET_MASS_PAYOUT_SUCCESS, massPaymentId, payments });
		} catch (err) {
			dispatch({ type: GET_MASS_PAYOUT_ERROR, massPaymentId });
			notification.error({
				message: "Error",
				description: "Error loading payment"
			});
		}
	};
};

export const getMassPayoutCSV = (massPaymentId) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: GET_MASS_PAYOUT_CSV_REQUEST, massPaymentId });
			const state = getState();
			const location = state.locations.overrideLocation;
			const data = await MassPayoutService.getMassPayoutCSV(massPaymentId, location);
			FileDownload.saveAs(data, `mass-payout-${massPaymentId}.csv`);
			dispatch({ type: GET_MASS_PAYOUT_CSV_SUCCESS, massPaymentId });
		} catch (err) {
			dispatch({ type: GET_MASS_PAYOUT_CSV_ERROR, massPaymentId });
			notification.error({
				message: "Error",
				description: "Error loading payment"
			});
		}
	};
};

export const exportMassPayout = (massPaymentId, location, sameDayEffectiveDate = false) => {
	return async (dispatch) => {
		try {
			dispatch({ type: EXPORT_MASS_PAYOUT_REQUEST, massPaymentId });
			const data = await MassPayoutService.exportMassPayout(massPaymentId, location, sameDayEffectiveDate);
			dispatch({ type: EXPORT_MASS_PAYOUT_SUCCESS, massPaymentId });
			const fileName = getMassPayoutFileName(location);
			FileDownload.saveAs(data, fileName);
		} catch (err) {
			dispatch({ type: EXPORT_MASS_PAYOUT_ERROR, massPaymentId });
			notification.error({
				message: "Error",
				description: "Error downloading file"
			});
		}
	};
};

const getMassPayoutFileName = (location) => {
	switch (location) {
		case "NZ":
			return "payout.kbb";
		case "US":
			return "payout.txt";
		default:
			return "payout.aba";
	}
};

export const markMassPayoutAsPaid = (massPaymentId, transactionId, amountProcessed, clearedAt) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const location = state.locations.overrideLocation;
			dispatch({ type: PAY_MASS_PAYOUT_REQUEST, massPaymentId });
			await MassPayoutService.markMassPayoutAsPaid(massPaymentId, transactionId, amountProcessed, clearedAt, location);
			dispatch({ type: PAY_MASS_PAYOUT_SUCCESS, massPaymentId });
			dispatch(find(1));
		} catch (err) {
			dispatch({ type: PAY_MASS_PAYOUT_ERROR, massPaymentId });
			notification.error({
				message: "Error",
				description: "Failed to mark payout as paid"
			});
		}
	};
};

export const cancelPayment = (massPaymentId) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const location = state.locations.overrideLocation;
			dispatch({ type: CANCEL_PAY_MASS_PAYOUT_REQUEST, massPaymentId });
			await MassPayoutService.cancelPayment(massPaymentId, location);
			dispatch({ type: CANCEL_PAY_MASS_PAYOUT_SUCCESS, massPaymentId });
			dispatch(find(1));
		} catch (err) {
			dispatch({ type: CANCEL_PAY_MASS_PAYOUT_ERROR, massPaymentId });
			notification.error({
				message: "Error",
				description: "Failed to cancel payment"
			});
		}
	};
};

export const cancelMassPayout = (massPaymentId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const location = state.locations.overrideLocation;
		dispatch({ type: CANCEL_MASS_PAYOUT_REQUEST, massPaymentId });
		MassPayoutService.cancelMassPayout(massPaymentId, location)
			.then(() => {
				dispatch({ type: CANCEL_MASS_PAYOUT_SUCCESS, massPaymentId });
				dispatch(find(1));
			})
			.catch(() => {
				dispatch({ type: CANCEL_MASS_PAYOUT_ERROR, massPaymentId });
				notification.error({
					message: "Error",
					description: "Failed to cancel payout"
				});
			});
	};
};
