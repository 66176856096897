import { css } from "@emotion/css";
import { Field } from "redux-form";

import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { InputNumberField, SelectField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";

import { required } from "@/utils/Validators";

const ticketTypeRequired = required("A ticket type is required");
const quantityRequired = required("A quantity is required");

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	leftOnMobile: (minWidth = 75) =>
		css({
			"@media(max-width: 600px)": {
				float: "left",
				marginRight: 10,
				minWidth: `${minWidth}vw`
			}
		})
};

interface ITicketProps {
	tickets: any;
	ticketOptions: any;
	packageIndex: number;
	fields: any;
	fieldName: string;
}

const Tickets = ({ fields, tickets, ticketOptions, packageIndex, fieldName }: ITicketProps) => {
	const ticketTypesList = tickets ? tickets : [{}];

	const columns = [
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Ticket type <span> (required)</span>
				</span>
			),
			dataIndex: "name",
			key: "name",
			width: "41%",
			render: (_: string, __: any, index: number) => (
				<div className={styles.leftOnMobile()}>
					<Field
						name={`${fieldName}[${packageIndex}].tickets[${index}].ticketTypeId`}
						component={SelectField}
						options={ticketOptions}
						style={{ maxWidth: 200 }}
						validate={ticketTypeRequired}
					/>
				</div>
			)
		},
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Available per package <span> (required)</span>
				</span>
			),
			dataIndex: "quantity",
			key: "quantity",
			width: "41%",
			render: (_: string, __: any, index: number) => (
				<div className={styles.leftOnMobile(40)}>
					<Field
						name={`${fieldName}[${packageIndex}].tickets[${index}].quantity`}
						component={InputNumberField}
						validate={quantityRequired}
						min={0}
						allowsNullValue={true}
					/>
				</div>
			)
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (_: string, __: any, index: number) => (
				<div className={styles.leftOnMobile()}>
					{ticketTypesList.length > 1 ? (
						<TableActions>
							<IconButton
								icon="delete"
								onClick={() => fields.remove(index)}
								tooltip="Delete"
								showTooltip
								ariaLabel="Delete"
							/>
						</TableActions>
					) : null}
				</div>
			)
		}
	];

	return (
		<div>
			<ResponsiveTable pagination={false} columns={columns} dataSource={ticketTypesList} rowKey="key" />
			<ButtonsBar>
				<LegacyButton
					icon={{ left: true, name: "plus" }}
					type="action"
					onClick={() => fields.push({})}
					ariaLabel="Add ticket type"
				>
					Add ticket type
				</LegacyButton>
			</ButtonsBar>
		</div>
	);
};

export default Tickets;
