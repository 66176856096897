import { CodeBlock } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type CodeBlockProps = {
	codeString?: string;
	copyButtonAriaLabel?: string;
	language?: "js" | "ts" | "htmlbars";
	title?: string;
};`;

const sample = `import { CodeBlock } from "@/ui";

<CodeBlock codeString='console.log("Few words do trick lmao");' />`;

export const CodeBlockPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<CodeBlock codeString='console.log("Few words do trick lmao");' />
			<textarea
				style={{
					border: "2px solid #e8e8e8",
					borderRadius: "4px",
					height: "100px",
					marginBottom: "16px",
					padding: "8px",
					width: "100%"
				}}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
