import { Field } from "redux-form";
import { RichTextAreaField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import ElementHeader from "../ElementHeader";

const textRequired = required("text is required");
const Text = (props) => {
	const { fieldPath } = props;
	return (
		<>
			<ElementHeader {...props} />
			<div>
				<Field
					name={`${fieldPath}.text`}
					label="text"
					component={RichTextAreaField}
					rows={3}
					required
					validate={textRequired}
				/>
			</div>
			<hr />
		</>
	);
};

export default Text;
