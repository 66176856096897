import { useAppSelector } from "@/state/hooks";
import { Button, ResponsiveModal } from "@/ui";
import styled from "@emotion/styled";
import { FC, ReactNode, useState } from "react";

const Content = styled.div`
	display: flex;
	flex-direction: column;

	h2 {
		font-size: 14px;
		font-weight: 700;
		margin-bottom: var(--spacing-xs);
		margin-top: var(--spacing-md);
	}

	p {
		max-width: 100%;
	}
`;

type WidgetAddToSiteButtonProps = {
	children?: ReactNode;
};

export const WidgetAddToSiteButton: FC<WidgetAddToSiteButtonProps> = ({ children }) => {
	const [showCodeModal, setShowCodeModal] = useState(false);
	const currentEvent = useAppSelector((state) => state.currentEvent);

	if (!currentEvent?.event) {
		return null;
	}

	return (
		<>
			<Button onClick={() => setShowCodeModal(true)} variant="secondary">
				Add to site
			</Button>

			<ResponsiveModal
				primaryButtonText="Done"
				onCancel={() => setShowCodeModal(false)}
				primaryButtonAction={() => setShowCodeModal(false)}
				open={showCodeModal}
				header={<b>Copy code to your site</b>}
				width="800px"
			>
				<Content>{children}</Content>
			</ResponsiveModal>
		</>
	);
};
