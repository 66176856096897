import { State } from "@hx/seating-map";
import * as React from "react";
const { ReservationStateChangeTracker, ReservationState } = State.Reservation;
type StateMapping = { [variableName: string]: string };

const ConnectTicketingReservation = (stateMapping: StateMapping) => (Component: new () => React.Component<any, any>) =>
	class extends React.Component<{}, {}> {
		constructor(props: {}) {
			super(props);
			Object.keys(stateMapping).forEach((variable) => {
				ReservationStateChangeTracker.setVariableTrack(variable, () => this.forceUpdate());
			});
		}
		render() {
			const seatingMapVariableProps = Object.keys(stateMapping).reduce((map, variable) => {
				// eslint-disable-next-line
				// @ts-ignore
				map[variable] = ReservationState[variable];
				return map;
			}, {});
			return <Component {...this.props} {...seatingMapVariableProps} />;
		}
	};

export default ConnectTicketingReservation;
