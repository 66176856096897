import axios from "./AxiosWrapper";

const AdminUtilitiesService = {
	async runFraudCheck() {
		axios.post(`admin/utilities/run-global-fraud-check`);
	},
	async runFraudCheckForEvent(id) {
		const response = await axios.post(`admin/utilities/run-fraud-check/${id}`);
		return response.data;
	}
};

export default AdminUtilitiesService;
