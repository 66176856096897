import { ComponentType, FC, ReactElement, cloneElement } from "react";
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from "react-router";

export type InjectedRouterHooksProps = {
	location: Location;
	navigate: NavigateFunction;
	params: Readonly<Params<string>>;
};

type WrappedComponentProps = {
	children: ReactElement;
};

const WrappedComponent: FC<WrappedComponentProps> = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	return cloneElement(children, { location, navigate, params });
};

export const withRouterHooks = <P extends InjectedRouterHooksProps>(ChildComponent: ComponentType<P>) => {
	return (props: Omit<P, keyof InjectedRouterHooksProps>) => (
		<WrappedComponent>
			<ChildComponent {...(props as P)} />
		</WrappedComponent>
	);
};
