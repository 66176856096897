import sanityClient from "@sanity/client";
import createImageUrlBuilder from "@sanity/image-url";

const SANITY_PROJECT_ID = "yq7knwhz";
const SANITY_DATASET = "production";
const config = {
	dataset: SANITY_DATASET,
	projectId: SANITY_PROJECT_ID
};

export default sanityClient({
	...config,
	useCdn: true
});

export const imageBuilder = (source) => createImageUrlBuilder(config).image(source);
