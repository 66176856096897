import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, InputNumberField, RadioField, SelectField, SwitchField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, H2, P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { saveEvent } from "@/state/actions/eventActions";
import { getCurrencySymbol } from "@/utils/Format";
import { currencyFormat, currencyParse } from "@/utils/InputFormatters";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { getTicketDisplayName } from "@/utils/Ticket";
import { mustBeGreaterThanZero, mustBeLessThanOrEqualTo, mustBeUnique, required } from "@/utils/Validators";
import DiscountCodesDateRange from "@/events/event/discountCodes/DiscountCodesDateRange";
import TicketsPurchasedTrigger from "@/events/event/automaticDiscounts/TicketsPurchasedTrigger";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "autoDiscountCode";
const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	}),
	codeField: css({
		width: 480
	}),
	appliesToField: css({
		maxWidth: 480
	})
};

class AutomaticDiscount extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	/**
	 * @param values: values of all the Fields of the form
	 *
	 *
	 */
	save(values) {
		const { saveEvent, currentEvent, params, navigate } = this.props;
		const autoDiscountId = params?.autoDiscountId;
		const autoDiscounts = currentEvent.event.autoDiscounts ? currentEvent.event.autoDiscounts.concat([]) : [];
		if (autoDiscountId !== "new") {
			// this is an edit
			const autoDiscountIndex = autoDiscounts.findIndex((discount) => discount._id === autoDiscountId);
			autoDiscounts[autoDiscountIndex] = values;
		} else {
			// this is a new discount
			autoDiscounts.push(values);
		}
		saveEvent(
			currentEvent.event._id,
			{ autoDiscounts },
			navigate,
			`/console/my-events/${currentEvent.event._id}/discounts/automatic`
		);
	}

	/**
	 * @param
	 *
	 * Discard all changes and withought checking for required fields push the history to discounts/automatic page
	 */
	cancel() {
		const { currentEvent, navigate } = this.props;
		navigate(`/console/my-events/${currentEvent.event._id}/discounts/automatic`);
	}

	addTicket(fields) {
		const group = {
			ticketId: null,
			quantity: null
		};
		fields.push(group);
	}

	nameRequired = required("A name required");
	discountAmountRequired = required("Discount amount required");
	appliesToRequired = required("Applies to required");
	codeMustBeUnique = mustBeUnique(
		"Code with this name exists",
		this.props.params?.autoDiscountId,
		this.props.currentEvent.event.autoDiscounts
	);
	quantityRequired = required("Quantity required");
	triggerAmountRequired = required("Amount required");
	percentageLessThanOneHundred = mustBeLessThanOrEqualTo(100);

	render() {
		const { currentEvent, touch, triggerType, handleSubmit, dispatch, purchased } = this.props;
		const timezone = currentEvent.event.timezone ? currentEvent.event.timezone : getConfig("TIMEZONE_DEFAULT");

		const isSecondaryTicketsEnabled = !!currentEvent?.event?.settings?.isSecondaryTicketsEnabled;

		let tickets = currentEvent.event.ticketTypes.filter((t) => !t.isDonation && !t.deleted);
		tickets = tickets.filter((ticket) => !(ticket.disabled || ticket.deleted));
		const ticketOptions = [
			{ value: "all", label: `All ${isSecondaryTicketsEnabled ? "primary " : ""}ticket types` }
		].concat(
			tickets
				.filter((ticket) => !ticket.isSecondary)
				.map((ticket) => {
					return { value: ticket._id, label: getTicketDisplayName(ticket) };
				})
		);

		return (
			<form onSubmit={handleSubmit(this.save)}>
				<LoadErrorView loading={currentEvent.save.loading} retryAction={this.save}>
					<H1>Automatic Discount</H1>
					<ContentBlock>
						<H2>Trigger</H2>
						<P>Define how the discount is triggered</P>
						<Field
							label="Name"
							name="code"
							description="Specify a name for your discount. This will appear on the user's order."
							component={LegacyInputField}
							required
							validate={[this.codeMustBeUnique, this.nameRequired]}
							style={{
								maxWidth: 480
							}}
						/>
						<Field
							name="trigger.type"
							label="Type"
							component={RadioField}
							button
							options={[
								{
									label: isSecondaryTicketsEnabled ? "Selection of primary tickets" : "What is purchased",
									value: "purchase"
								},
								{
									label: isSecondaryTicketsEnabled ? "Value of primary tickets" : "Value of order",
									value: "amount"
								}
							]}
						/>
						{triggerType === "purchase" ? (
							<FieldArray
								name="trigger.purchased"
								component={TicketsPurchasedTrigger}
								ticketOptions={ticketOptions}
								purchased={purchased}
								addTicket={this.addTicket}
								dispatch={dispatch}
								formName={formName}
							/>
						) : (
							<Field
								label="Amount"
								name="trigger.amount"
								component={InputNumberField}
								precision={2}
								validate={this.triggerAmountRequired}
								min={0}
								formatter={currencyFormat}
								parser={currencyParse}
							/>
						)}
						<H2>Discount</H2>
						<div
							className={css({
								position: "relative",
								".ant-row": {
									margin: "0 !important"
								}
							})}
						>
							<Field
								label="Discount per ticket"
								required
								name="discount"
								component={InputNumberField}
								placeholder="0"
								min={0}
								max={this.props.discountType === "percent" ? 100 : undefined}
								precision={2}
								style={{ width: 70, height: 36 }}
								validate={[
									this.discountAmountRequired,
									mustBeGreaterThanZero,
									...((this.props.discountType === "percent" && [this.percentageLessThanOneHundred]) || [])
								]}
							/>
							<Field
								inline
								name="discountType"
								component={RadioField}
								button
								placeholder="discountType"
								style={{
									position: "absolute",
									top: 23,
									left: 76
								}}
								options={[
									{ value: "percent", label: "%" },
									{ value: "amount", label: getCurrencySymbol() }
								]}
							/>
						</div>
						<div className={styles.appliesToField}>
							{ticketOptions ? (
								<Field
									mode="multiple"
									name="appliesTo"
									label="Applies to"
									placeholder="Please select"
									validate={this.appliesToRequired}
									required
									component={SelectField}
									options={ticketOptions}
									style={{ width: "100%" }}
								/>
							) : null}
						</div>

						<Field
							label="Quantity"
							description="The total amount of tickets that can be discounted"
							name="quantity"
							required
							component={InputNumberField}
							validate={this.quantityRequired}
							min={0}
							style={{ width: "120px" }}
						/>
						<Field
							label="Maximum use per order"
							description="The amount of tickets that can be discounted per order"
							name="maximumUsePerOrder"
							component={InputNumberField}
							allowsNullValue
							min={0}
						/>
						<DiscountCodesDateRange touch={touch} timezone={timezone} formName={formName} />
						<Field name="enabled" label="Enabled" component={SwitchField} style={{ marginBottom: 24 }} />
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right",
								width: "100px"
							}}
							onClick={handleSubmit(this.save)}
							type="primary"
							disabled={currentEvent.save.loading}
							ariaLabel="Save"
						>
							Save
						</LegacyButton>
						<LegacyButton
							style={{
								float: "left",
								width: "100px"
							}}
							onClick={this.cancel}
							disabled={currentEvent.save.loading}
							ariaLabel="Cancel"
						>
							Cancel
						</LegacyButton>
					</FormBar>
				</LoadErrorView>
			</form>
		);
	}
}

AutomaticDiscount = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(AutomaticDiscount);

const selector = formValueSelector(formName);
export default withRouterHooks(
	connect(
		(state, ownProps) => {
			const { params } = ownProps;
			const autoDiscountId = params?.autoDiscountId;
			let initialValues = {
				trigger: {
					type: "purchase",
					purchased: [{ ticketId: null, quantity: null }]
				},
				enabled: true,
				discountType: "percent"
			};
			if (autoDiscountId !== "new") {
				const autoDiscount = state.currentEvent.event.autoDiscounts.find((discount) => discount._id === autoDiscountId);
				initialValues = autoDiscount;
			}
			return {
				initialValues,
				currentEvent: state.currentEvent,
				type: selector(state, "type"),
				discountType: selector(state, "discountType"),
				triggerType: selector(state, "trigger.type"),
				purchased: selector(state, "trigger.purchased")
			};
		},
		{ saveEvent }
	)(AutomaticDiscount)
);
