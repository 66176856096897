import { css } from "@emotion/css";
import { Link } from "react-router-dom";

const wrapper = css({
	marginBottom: 16
});

interface IBreadcrumbProps {
	prevPath: string;
	prevTitle: string;
	currTitle?: string;
}

const Breadcrumb = ({ prevPath, prevTitle, currTitle }: IBreadcrumbProps) => {
	if (!currTitle) return null;

	return (
		<div className={wrapper}>
			<Link to={prevPath} style={{ textDecoration: "underline", color: "#323232" }}>
				{prevTitle}
			</Link>{" "}
			&gt; {currTitle}
		</div>
	);
};
export default Breadcrumb;
