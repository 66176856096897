import { css } from "@emotion/css";
import { Input, Select } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import DatesSelector from "../components/DatesSelector";
import { NumberRange } from "../components/NumberRange";
import EventSearch from "../components/fields/EventSearchField";
import { findEvents } from "../state/actions/eventActions";
import { updateSearch } from "../state/actions/searchActions";
import { Button } from "@/ui";

const selectFieldProps = {
	width: 300,
	fontWeight: "bold",
	marginRight: 20,
	display: "block",
	".ant-select": {
		marginTop: 4,
		fontWeight: "normal ",
		display: "block"
	}
};

const styles = {
	filterContainer: css({
		width: "100%",
		borderRadius: 4,
		backgroundColor: "#fafafa",
		padding: 20
	}),
	selectContainer: css({ display: "flex", flexWrap: "wrap", gap: "12px", overflow: "hidden", marginBottom: 12 }),
	searchField: css({ ...selectFieldProps, marginBottom: 24 }),
	filterField: css({ ...selectFieldProps, width: 200 })
};

class ReportsFilter extends Component {
	constructor(props) {
		super(props);
		this.apply = this.apply.bind(this);
		this.handleEventOnSearch = this.handleEventOnSearch.bind(this);
		this.handleEventsOnBlur = this.handleEventsOnBlur.bind(this);
		this.handleEventOnChange = this.handleEventOnChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleQueryOnchange = this.handleQueryOnchange.bind(this);
		this.debouncedFindEvents = this.debouncedFindEvents.bind(this);
	}

	debouncedFindEvents(page = 1, query, toAppend = false) {
		clearTimeout(this.searchEventTimeout);
		this.searchEventTimeout = setTimeout(() => {
			this.props.findEvents(
				page,
				{
					query,
					sortOrder: "relevant",
					filter: "all"
				},
				toAppend
			);
		}, 200);
	}

	handleEventOnSearch(query) {
		if (query.trim().length > 2) {
			this.debouncedFindEvents(1, query);
		}
	}

	handleEventsOnBlur() {
		this.debouncedFindEvents(1, "");
	}

	handleEventOnChange(eventIds) {
		const { updateSearch } = this.props;
		updateSearch({ eventIds });
	}

	handleEventOnScroll = (e) => {
		const { eventSearchState, loading } = this.props;
		const { page } = eventSearchState;
		let target = e.target;
		if (!loading && Math.round(target.scrollTop + target.offsetHeight) >= target.scrollHeight) {
			const nextPage = Math.round(parseInt(page) + 1);
			this.debouncedFindEvents(nextPage, "", true);
			target.scrollTo(0, target.scrollHeight);
		}
	};

	handleFilterChange(key, value) {
		const { updateSearch } = this.props;
		updateSearch({ [key]: value });
	}

	handleQueryOnchange(e) {
		const value = e.target.value;
		clearTimeout(this.queryChangeTimeout);
		this.queryChangeTimeout = setTimeout(() => {
			this.handleFilterChange("query", value);
		}, 200);
	}

	apply() {
		const { updateSearch, locations, loadReport } = this.props;
		updateSearch({ loc: locations.overrideLocation }, true);
		loadReport();
	}

	constructFilter(filter) {
		const { search } = this.props;

		switch (filter.kind) {
			case "numberRange": {
				return (
					<NumberRange
						key={`filter-${filter.key}NumberRange`}
						filter={filter}
						search={search}
						handleFilterChange={this.handleFilterChange}
					/>
				);
			}
			default: {
				return (
					<label key={`filter-${filter.key}`} className={styles.filterField}>
						{filter.label}
						<Select
							mode={filter.mode || "default"}
							onChange={(value) => this.handleFilterChange(filter.key, value)}
							optionFilterProp="name"
							value={
								search[filter.key]
									? search[filter.key]
									: filter.mode === "multiple"
									? undefined
									: filter.options[0].value
							}
							placeholder={
								filter.placeholder ? filter.placeholder : filter.mode === "multiple" ? "Type to find" : undefined
							}
							options={filter.options.map((o) => ({ value: o.value, label: o.name }))}
						/>
					</label>
				);
			}
		}
	}

	render() {
		const {
			header,
			selectEvent = true,
			showSearchField = true,
			firstDateName,
			firstDateLabel,
			secondDateName,
			secondDateLabel,
			eventSearchState,
			eventSearchPlaceholder = "All events",
			searchPlaceholder = "First name, last name, and order id",
			getReportButtonText = "Get report",
			search,
			filterOptions = []
		} = this.props;

		return (
			<div className={styles.filterContainer}>
				{header}
				<div className={styles.selectContainer}>
					{selectEvent ? (
						<EventSearch
							eventSearchState={eventSearchState}
							label="Filter by events"
							handleEventOnChange={this.handleEventOnChange}
							placeholder={eventSearchPlaceholder}
							selectedValues={search.eventIds}
							input={{ name: "eventFilter" }}
						/>
					) : null}
					{filterOptions.map((filterOptions) => this.constructFilter(filterOptions))}
				</div>
				<div style={{ clear: "both" }} />
				{firstDateName && firstDateLabel && (
					<DatesSelector
						key={firstDateName}
						name={firstDateName}
						label={firstDateLabel}
						filterChange={this.handleFilterChange}
						defaultValue={search[firstDateName]}
					/>
				)}

				{secondDateName && secondDateLabel && (
					<DatesSelector
						key={secondDateName}
						name={secondDateName}
						label={secondDateLabel}
						filterChange={this.handleFilterChange}
						defaultValue={search[secondDateName]}
					/>
				)}

				{showSearchField && (
					<label key="query" className={styles.searchField}>
						Search by
						<Input
							style={{ marginTop: 4 }}
							name="query"
							placeholder={searchPlaceholder}
							defaultValue={search.query}
							onChange={this.handleQueryOnchange}
						/>
					</label>
				)}
				<div style={{ clear: "both", marginTop: 24 }}>
					<Button onClick={this.apply} variant="primary" aria-label={getReportButtonText}>
						{getReportButtonText}
					</Button>
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			eventSearchState: state.events,
			search: state.search,
			locations: state.locations
		};
	},
	{
		updateSearch,
		findEvents
	}
)(ReportsFilter);
