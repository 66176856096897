import confettiSvg from "@/assets/organiserOnboarding/confetti.svg";
import awardsIcon from "@/assets/organiserOnboarding/ic-awards.svg";
import beginnerIcon from "@/assets/organiserOnboarding/ic-beginner.svg";
import businessIcon from "@/assets/organiserOnboarding/ic-business.svg";
import charityIcon from "@/assets/organiserOnboarding/ic-charity.svg";
import conferencesIcon from "@/assets/organiserOnboarding/ic-conferences.svg";
import exportIcon from "@/assets/organiserOnboarding/ic-expert.svg";
import expoIcon from "@/assets/organiserOnboarding/ic-expo.svg";
import festivalIcon from "@/assets/organiserOnboarding/ic-festivals.svg";
import fewTimesPerYearIcon from "@/assets/organiserOnboarding/ic-fewTimesPerYear.svg";
import galasIcon from "@/assets/organiserOnboarding/ic-galas.svg";
import individualIcon from "@/assets/organiserOnboarding/ic-individual.svg";
import intermediateIcon from "@/assets/organiserOnboarding/ic-intermediate.svg";
import monthlyIcon from "@/assets/organiserOnboarding/ic-monthly.svg";
import musicIcon from "@/assets/organiserOnboarding/ic-music.svg";
import onlineIcon from "@/assets/organiserOnboarding/ic-online.svg";
import otherIcon from "@/assets/organiserOnboarding/ic-other.svg";
import schoolIcon from "@/assets/organiserOnboarding/ic-school.svg";
import weeklyIcon from "@/assets/organiserOnboarding/ic-weekly.svg";
import workshopsIcon from "@/assets/organiserOnboarding/ic-workshops.svg";
import yearlyIcon from "@/assets/organiserOnboarding/ic-yearly.svg";
import { MultipleCheckboxField, RadioField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2, H3, P } from "@/components/text/Text";
import TrackingService, { UserSignupEvent } from "@/services/TrackingService";
import { sha256 } from "@/utils/Crypto";
import { max } from "@/utils/CssUtils";
import { estimateAnnualFeeValue } from "@/utils/EstimateAnnualFeeValue";
import { required } from "@/utils/Validators";
import { css } from "@emotion/css";
import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Footer from "./Footer";
import Header from "./Header";
import { PageContainer } from "./LocalBlocks";
import { SubFields } from "./SubFields";

let OnboardingQuestions = ({ currStep, handleBack, save, handleNext, user, handleSubmit, eventTypes, formValues }) => {
	const trackUserFeeEstimate = useCallback(async () => {
		const estimatedFees = estimateAnnualFeeValue(
			formValues.frequency,
			formValues.price,
			formValues.ticketsSold,
			user?.location
		);
		const hashedEmail = await sha256(user?.email).catch(console.error);
		TrackingService.track(new UserSignupEvent(hashedEmail, estimatedFees));
	}, [formValues.frequency, formValues.price, formValues.ticketsSold, user?.email, user?.location]);

	useEffect(() => {
		if (currStep === 4) {
			trackUserFeeEstimate().catch(console.error);
		}
	}, [currStep, trackUserFeeEstimate]);

	const handleSkip = () => {
		trackUserFeeEstimate().catch(console.error);
		handleSubmit(save)();
	};

	const currQuestion = QUESTIONS.find((q) => q.step === currStep);
	const { questionKey, question, description, options, isCheckbox, radioType, isNumber } = currQuestion
		? currQuestion
		: {};

	if (!questionKey) return false;

	const header = <Header currStep={currStep} total={QUESTIONS.length} />;
	const footer = <Footer handleSkip={handleSkip} />;
	const selectedValue = formValues[questionKey];
	const selectedOption = options.find((o) => o.value === selectedValue);
	const children = selectedOption && selectedOption.children ? selectedOption.children : [];

	const nextDisabled =
		typeof formValues[questionKey] === "undefined" || !children.every((child) => formValues[child.name]);

	return (
		<PageContainer bgcolor="#fff" header={header} footer={footer}>
			<div className={wrapper}>
				{currStep === 1 ? (
					<div className={welcome}>
						<img src={confettiSvg} alt="confetti" />
						<H2 style={{ color: "#ffffff" }}>Welcome to Humanitix, {user?.firstName}!</H2>
					</div>
				) : (
					<div className={goBackStyle}>
						<IconButton
							style={{ width: "100%" }}
							icon="left_small"
							onClick={() => handleBack()}
							ariaLabel="Go back"
							buttonText="Back"
						/>
					</div>
				)}

				<div className={content}>
					<H3>{question}</H3>
					<P>{description}</P>
					<form onSubmit={handleSubmit(save)}>
						{isCheckbox ? (
							<Field
								parse={(value) => (isNumber ? Number(value) : value)}
								key={questionKey}
								style={multipleCheckboxStyle}
								id={questionKey}
								name={questionKey}
								labelAfter
								component={MultipleCheckboxField}
								inline={false}
								border
								options={options}
								defaultValue={eventTypes}
								validate={[eventTypeIsRequired]}
							/>
						) : (
							<>
								<Field
									parse={(value) => (isNumber ? Number(value) : value)}
									key={questionKey}
									id={questionKey}
									name={questionKey}
									labelAfter
									component={RadioField}
									inline={false}
									border
									vertical={!(radioType && radioType === "square")}
									square={radioType}
									options={options}
									style={{
										[max(768)]: {
											width: radioType === "square" ? 130 : 202
										}
									}}
								/>
								<SubFields children={children} />
							</>
						)}
						<LegacyButton
							type="secondary"
							ariaLabel="next"
							dataCy="next"
							disabled={nextDisabled}
							onClick={currStep < QUESTIONS.length ? handleNext : handleSubmit(save)}
						>
							Next
						</LegacyButton>
					</form>
				</div>
			</div>
		</PageContainer>
	);
};

const eventTypeIsRequired = required("Please select at least one of the options");

const formName = "onboardingOrganiser";
const selector = formValueSelector(formName);
OnboardingQuestions = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true
})(OnboardingQuestions);

export default connect((state) => ({
	auth: state.auth,
	formValues: selector(
		state,
		"frequency",
		"price",
		"ticketsSold",
		"experience",
		"eventTypes",
		"whoAreYou",
		"charityWebsite",
		"schoolWebsite"
	)
}))(OnboardingQuestions);

const wrapper = css({
	display: "flex",
	width: "100%",
	[max(768)]: {
		flexDirection: "column",
		width: "100%"
	}
});

const welcome = css({
	background: "#353337",
	width: "266px",
	borderRadius: "4px 0px 0px 4px",
	padding: "0px 20px 0px 20px",
	textAlign: "center",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",

	[max(768)]: {
		width: "100%",
		height: "auto",
		borderRadius: "4px 4px 0px 0px",
		padding: "16px 0px 16px 0px"
	}
});

const goBackStyle = css({
	position: "absolute",
	margin: "12px 0px 0px 10px"
});

const content = css({
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: "32px auto",

	[max(768)]: {
		margin: "44px auto 24px",
		padding: "0px 12px"
	},
	h3: {
		maxWidth: 400,
		textAlign: "center",

		margin: "0px auto 12px"
	},
	p: {
		textAlign: "center",
		maxWidth: 600,
		margin: "0px auto 16px"
	},
	form: {
		margin: "0px auto"
	},
	button: {
		margin: "0px auto"
	}
});

const multipleCheckboxStyle = {
	width: 376,
	height: 245,
	display: "flex",
	flexDirection: "column",
	flexWrap: "wrap",
	[max(768)]: {
		flexDirection: "column",
		width: 180,
		height: "auto"
	}
};

const QUESTIONS = [
	{
		step: 1,
		questionKey: "frequency",
		question: "How often do you run events?",
		description: "Help us predict the impact your events generate",
		isNumber: true,
		options: [
			{
				value: 52,
				label: "Weekly",
				icon: weeklyIcon
			},
			{
				value: 12,
				label: "Monthly",
				icon: monthlyIcon
			},
			{
				value: 2,
				label: "A few times a year",
				icon: fewTimesPerYearIcon
			},
			{
				value: 1,
				label: "Once a year",
				icon: yearlyIcon
			}
		]
	},
	{
		step: 2,
		questionKey: "price",
		question: "How much do you typically charge for tickets?",
		description: "Help us predict the impact your events generate",
		isNumber: true,
		options: [
			{
				label: "Free",
				value: 0
			},
			{
				label: "$1 - $20",
				value: 10
			},
			{
				label: "$21 - $50",
				value: 35
			},
			{
				label: "$51 - $200",
				value: 125
			},
			{
				label: "$200+",
				value: 300
			}
		]
	},
	{
		step: 3,
		questionKey: "ticketsSold",
		question: "How many tickets do you typically sell for each event?",
		description: "Help us predict the impact your events generate",
		isNumber: true,
		options: [
			{
				label: "1 - 100",
				value: 50
			},
			{
				label: "101 -500",
				value: 300
			},
			{
				label: "501 - 1000",
				value: 750
			},
			{
				label: "1001 - 5000",
				value: 3000
			},
			{
				label: "5000+",
				value: 6000
			}
		]
	},
	{
		step: 4,
		radioType: "square",
		questionKey: "experience",
		question: "What is your experience with ticketing?",
		description: "Have you been rocking this world for a while or are you just getting started? :)",
		options: [
			{
				value: "beginner",
				label: "It's my first time (Beginner)",
				icon: beginnerIcon
			},
			{
				value: "intermediate",
				label: "I've dabbled (Intermediate)",
				icon: intermediateIcon
			},
			{
				value: "expert",
				label: "I'm a ticketing veteran (Expert)",
				icon: exportIcon
			}
		]
	},
	{
		step: 5,
		questionKey: "eventTypes",
		question: "What type of events do you run?",
		description: "Select multiple event categories if one doesn't work for you",
		isCheckbox: true,
		options: [
			{
				value: "online",
				label: "Online",
				icon: onlineIcon
			},
			{
				value: "festivals",
				label: "Festivals",
				icon: festivalIcon
			},
			{
				value: "expo",
				label: "Expos",
				icon: expoIcon
			},
			{
				value: "awards",
				label: "Awards Nights",
				icon: awardsIcon
			},
			{
				value: "workshops",
				label: "Workshops",
				icon: workshopsIcon
			},
			{
				value: "conferences",
				label: "Conferences",
				icon: conferencesIcon
			},
			{
				value: "music",
				label: "Music Gigs",
				icon: musicIcon
			},
			{
				value: "galas",
				label: "Galas",
				icon: galasIcon
			},
			{
				value: "other",
				label: "Other",
				icon: otherIcon
			}
		]
	},
	{
		step: 6,
		questionKey: "whoAreYou",
		question: "Get the most out of Humanitix by telling us a few things about yourself :)",
		description: "Who are you?",
		options: [
			{
				value: "individual",
				label: "Individual",
				icon: individualIcon
			},
			{
				value: "business",
				label: "Business",
				icon: businessIcon
			},
			{
				value: "charity",
				label: "Registered Charity",
				children: [
					{
						label: "Please provide a link to your online charity registration documents",
						value: "",
						name: "charityWebsite",
						required: true
					}
				],
				icon: charityIcon
			},
			{
				value: "education",
				label: "School",
				children: [
					{
						label: "Please provide the link to your school website",
						value: "www.school.edu",
						name: "schoolWebsite",
						required: true
					}
				],
				icon: schoolIcon
			},
			{
				value: "other",
				label: "Other",
				icon: otherIcon
			}
		]
	}
];
