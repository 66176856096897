import axios from "./AxiosWrapper";

class MetricService {
	static instance = null;

	static createInstance() {
		return new MetricService();
	}

	static getInstance() {
		if (!MetricService.instance) {
			MetricService.instance = MetricService.createInstance();
		}
		return MetricService.instance;
	}

	async home(dateRange, channel, isAdmin, location) {
		const route = `metrics/${isAdmin ? "admin/" : ""}home`;
		const response = await axios.post(route, { dateRange, channel }, { headers: { "x-override-location": location } });
		return response.data;
	}

	async event(id, dateRange, channel, location) {
		const response = await axios.post(
			`metrics/reports/event/${id}`,
			{
				dateRange,
				channel
			},
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	}

	async getSalesByTicketType(eventId, dateRange, eventDateId) {
		const response = await axios.post(`metrics/reports/event/${eventId}/earnings-by-ticket-type`, {
			dateRange,
			eventDateId
		});
		return response.data;
	}

	async historicalSales(dateRange, eventId, isAdmin, location) {
		const options = location
			? {
					headers: {
						"x-override-location": location
					}
			  }
			: {};
		const response = await axios.post(
			`metrics/${isAdmin ? "admin/" : ""}historical`,
			{
				dateRange,
				eventId
			},
			options
		);
		return response.data;
	}

	async users(dateRange, location) {
		const options = location
			? {
					headers: {
						"x-override-location": location
					}
			  }
			: {};
		const response = await axios.post(
			`metrics/users`,
			{
				dateRange
			},
			options
		);
		return response.data;
	}

	async sales(dateRange, eventId, isAdmin, location) {
		const options = location
			? {
					headers: {
						"x-override-location": location
					}
			  }
			: {};
		const response = await axios.post(
			`metrics/${isAdmin ? "admin/" : ""}sales`,
			{
				dateRange,
				eventId
			},
			options
		);
		return response.data;
	}

	async getEventSalesOverviewByDate(eventId, dateRange, channel, location, eventDateId) {
		const response = await axios.post(
			`metrics/reports/event/${eventId}/sales-overview`,
			{
				dateRange,
				channel,
				eventDateId
			},
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	}

	async getDashboardSummaryByDate(dateRange, location) {
		const response = await axios.post(
			"metrics/summary",
			{ dateRange },
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	}

	async getDashboardOverviewByDate(dateRange, channel, isAdmin, location) {
		const route = `metrics/${isAdmin ? "admin/" : ""}overview`;
		const response = await axios.post(route, { dateRange, channel }, { headers: { "x-override-location": location } });
		return response.data;
	}
}
export default MetricService.getInstance();
