/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as allSvgs from "../assets/**/*.svg";
// @ts-ignore
const { default: svgs, filenames } = allSvgs;

const icons = [
	"action_chevron_left",
	"action_chevron_right",
	"additional_questions",
	"advanced",
	"arrow_down",
	"arrow_up",
	"basic_info",
	"au_flag",
	"ca_flag",
	"us_flag",
	"nz_flag",
	"fj_flag",
	"sg_flag",
	"gb_flag",
	"my_flag",
	"de_flag",
	"mx_flag",
	"calendar",
	"cancel",
	"change",
	"check_circle",
	"clear_formating",
	"close_large",
	"close_white",
	"close",
	"code_slash",
	"code",
	"delete",
	"design",
	"disable",
	"down_blue",
	"down",
	"drag",
	"duplicate",
	"edit",
	"email",
	"error",
	"export_bar",
	"export",
	"facebook_pixel",
	"facebook",
	"filter",
	"good_afternoon",
	"good_evening",
	"good_morning",
	"google_analytics",
	"google_tag_manager",
	"google",
	"image",
	"info_hover",
	"info",
	"left_small",
	"lifebouy",
	"manage_attendees",
	"no_data",
	"no_organisers_yet",
	"open_book",
	"open_external",
	"orders_refunds",
	"overview",
	"payment_fees",
	"pencils",
	"plus",
	"preview",
	"preview_eye",
	"promote",
	"radio_empty",
	"radio",
	"redo",
	"refresh",
	"reports",
	"right_small",
	"scanning_app",
	"screens",
	"seating_map",
	"see_more",
	"sent",
	"settings",
	"settings_white",
	"share",
	"shared",
	"success-circle",
	"tick",
	"tick_inverted",
	"ticket_sold_out",
	"tickets",
	"triangle_warning",
	"twitter",
	"undo",
	"warning_red",
	"warning",
	"warning_black"
];

interface Reference {
	[key: string]: NodeRequire;
}

const iconReference: Reference = icons.reduce((accumulator: any, value) => {
	// @ts-ignore
	const imported = svgs[filenames.findIndex((name: string) => name.indexOf(`ic_${value}.svg`) > -1)].default;
	return { ...accumulator, [value]: imported };
}, {});

export default iconReference;
