import {
	GET_REPORT_CSV_ERROR,
	GET_REPORT_CSV_REQUEST,
	GET_REPORT_CSV_SUCCESS,
	GET_REPORT_ERROR,
	GET_REPORT_PDF_ERROR,
	GET_REPORT_PDF_REQUEST,
	GET_REPORT_PDF_SUCCESS,
	GET_REPORT_REQUEST,
	GET_REPORT_SUCCESS,
	RESET_GLOBAL_REPORTS,
	UPDATE_REPORT
} from "../actions/globalReportActions";

export type GlobalReportReducerState = {
	page: number;
	results: any[];
	filters: any;
	columns: any;
	count: number;
	error?: string;
	loading: boolean;
	csvLoading: boolean;
	pdfLoading: boolean;
	invoiceLoading: boolean;
	hasLoadedOnce: boolean;
};

const initialState: GlobalReportReducerState = {
	page: 1,
	results: [],
	filters: {},
	columns: [],
	count: 0,
	error: undefined,
	loading: false,
	csvLoading: false,
	pdfLoading: false,
	invoiceLoading: false,
	hasLoadedOnce: false
};

type GlobalReportReducerAction = {
	type:
		| typeof RESET_GLOBAL_REPORTS
		| typeof UPDATE_REPORT
		| typeof GET_REPORT_REQUEST
		| typeof GET_REPORT_SUCCESS
		| typeof GET_REPORT_ERROR
		| typeof GET_REPORT_CSV_REQUEST
		| typeof GET_REPORT_CSV_SUCCESS
		| typeof GET_REPORT_CSV_ERROR
		| typeof GET_REPORT_PDF_REQUEST
		| typeof GET_REPORT_PDF_SUCCESS
		| typeof GET_REPORT_PDF_ERROR;
	result?: any;
	page?: number;
	error?: string;
};

export default (state = initialState, action: GlobalReportReducerAction) => {
	switch (action.type) {
		case RESET_GLOBAL_REPORTS:
			return initialState;
		case UPDATE_REPORT: {
			const results = state.results.slice();
			const resultIndex = results.findIndex((a) => a._id === action.result._id);
			const result = {
				...results[resultIndex],
				...action.result
			};

			results.splice(resultIndex, 1, result);
			return {
				...state,
				results
			};
		}
		case GET_REPORT_REQUEST:
			return {
				...state,
				error: undefined,
				loading: true
			};
		case GET_REPORT_SUCCESS:
			return {
				...state,
				...action.result,
				error: undefined,
				page: action.page,
				loading: false,
				hasLoadedOnce: true
			};
		case GET_REPORT_ERROR:
			return {
				...state,
				error: { title: "Oops", message: action.error }
			};
		case GET_REPORT_CSV_REQUEST:
			return {
				...state,
				csvLoading: true
			};
		case GET_REPORT_CSV_SUCCESS:
			return {
				...state,
				csvLoading: false
			};
		case GET_REPORT_CSV_ERROR:
			return {
				...state,
				csvLoading: false,
				csvError: action.error
			};
		case GET_REPORT_PDF_REQUEST:
			return {
				...state,
				pdfLoading: true
			};
		case GET_REPORT_PDF_SUCCESS:
			return {
				...state,
				pdfLoading: false
			};
		case GET_REPORT_PDF_ERROR:
			return {
				...state,
				pdfLoading: false,
				csvError: action.error
			};

		default:
			return state;
	}
};
