import { FieldArray } from "redux-form";
import Settings from "./Settings";
import Tickets from "./Tickets";

interface IPackageAdvancedSettingsProps {
	data: {
		tickets: any[];
		rowKey: string;
		hiddenOptions: any;
	};
	index: number;
	options: any;
	packagedTickets: any[];
	expandedRowOptions: any;
	timezone: string;
	formName: string;
	fieldName: string;
	primaryPackages: any[];
}

const PackageAdvancedSettings = ({
	data,
	index,
	options,
	packagedTickets,
	expandedRowOptions,
	timezone,
	formName,
	fieldName,
	primaryPackages
}: IPackageAdvancedSettingsProps) => {
	const tickets = data.tickets;
	let ticketTypes = options.currentEvent.event.ticketTypes || [];
	ticketTypes = ticketTypes.filter((ticket: any) => !(ticket.disabled || ticket.deleted || ticket.isDonation));

	const ticketOptions = ticketTypes.map((ticket: any) => ({ value: ticket._id, label: ticket.name }));
	const expandedRowOption = expandedRowOptions[data.rowKey];

	if (expandedRowOption === "settings") {
		return (
			<Settings
				touch={options.touch}
				packageIndex={index}
				ticketTypes={ticketTypes}
				packagedTickets={packagedTickets}
				hiddenOptions={data.hiddenOptions}
				timezone={timezone}
				formName={formName}
				fieldName={fieldName}
				primaryPackages={primaryPackages}
			/>
		);
	}

	return (
		<FieldArray
			name={`${fieldName}[${index}].tickets`}
			component={Tickets}
			tickets={tickets}
			ticketOptions={ticketOptions}
			packageIndex={index}
			fieldName={fieldName}
		/>
	);
};

export default PackageAdvancedSettings;
