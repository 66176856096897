import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Pagination, Radio, Table, Tag } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import stripAnsi from "strip-ansi";
import { debounce } from "throttle-debounce";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import SearchRefinements from "@/components/SearchRefinements";
import LinkButton from "@/components/buttons/LinkButton";
import LocationService from "@/services/LocationService";
import { getEmails, resendListEmail } from "@/state/actions/emailLogsActions";
import { updateSearch } from "@/state/actions/searchActions";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const errorMessage = css({
	maxWidth: 230,
	wordBreak: "break-word"
});

const searchWrapper = css({
	overflow: "hidden",
	".ant-radio-group": {
		marginLeft: 16
	}
});

class EmailLogs extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.handleResend = this.handleResend.bind(this);
		this.debounceLoad = debounce(500, this.load);
	}

	defaultSearch = { status: "all" };

	handleResend(id, emailType) {
		const { resendListEmail } = this.props;
		resendListEmail(id, emailType);
	}

	load(page = 1) {
		const { getEmails } = this.props;
		getEmails(page);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	updateRefinements = (value) => {
		const { updateSearch } = this.props;
		updateSearch({ status: value }, true);
	};

	render() {
		const { emailLogs, search } = this.props;
		const { loading, error, emails, count, resending, resendingId } = emailLogs;
		let columns = [
			{
				title: "Status",
				dataIndex: "success",
				key: "status",
				render: (value) => {
					switch (value) {
						case true:
							return <Tag color="#157E77">Sent</Tag>;
						case false:
							return <Tag color="#C0212C">Failed</Tag>;
						default:
							return <Tag color="#999999">Pending</Tag>;
					}
				}
			},
			{
				title: "Sent At",
				dataIndex: "date",
				key: "date",
				render: (value, email) => {
					return (
						<Link to={`/console/admin/email-logs/inspect/${email.emailId}/${email.emailType}`}>
							{value && new HumaniDate(value, "guess", LocationService.getLocation(true)).formatting.dateTime()}
						</Link>
					);
				}
			},
			{
				title: "Message",
				dataIndex: "message",
				key: "message",
				render: (value, email) => (
					<div className={errorMessage}>
						<Link to={`/console/admin/email-logs/inspect/${email.emailId}/${email.emailType}`}>
							{stripAnsi(String(value))}
						</Link>
					</div>
				)
			},
			{
				title: "To",
				dataIndex: "to",
				key: "to",
				render: (value, email) => (
					<Link to={`/console/admin/email-logs/inspect/${email.emailId}/${email.emailType}`}>{value}</Link>
				)
			},
			{
				title: "Subject",
				dataIndex: "subject",
				key: "subject",
				render: (value, email) => (
					<Link to={`/console/admin/email-logs/inspect/${email.emailId}/${email.emailType}`} style={{ maxWidth: 200 }}>
						{value}
					</Link>
				)
			},
			{
				title: "Action",
				key: "action",
				fixed: "right",
				width: 100,
				render: (row, email) => {
					return (
						<LoadErrorView
							loading={resending && email.emailId === resendingId}
							error=""
							retryAction={() => this.handleResend(email.emailId, email.emailType)}
						>
							<LinkButton
								onClick={() => {
									this.handleResend(email.emailId, email.emailType);
								}}
								ariaLabel="Resend"
							>
								Resend
							</LinkButton>
						</LoadErrorView>
					);
				}
			}
		];
		return (
			<>
				<PageHeading title="Email Logs" />

				<LoadErrorView loading={loading} error={error} retryAction={this.load}>
					<ContentBlock>
						<div className={searchWrapper}>
							<div style={{ float: "left" }}>
								<SearchRefinements
									load={this.load}
									defaultSearch={this.defaultSearch}
									showRefinements={false}
									style={{ float: "left" }}
								/>
							</div>
							<div style={{ float: "left" }}>
								<RadioGroup
									value={search.status || "all"}
									buttonStyle="solid"
									onChange={(e) => this.updateRefinements(e.target.value)}
								>
									<RadioButton value="all">All</RadioButton>
									<RadioButton value="sent">Successful</RadioButton>
									<RadioButton value="failed">Failed</RadioButton>
								</RadioGroup>
							</div>
						</div>
					</ContentBlock>
					{emails && emails.length ? (
						<ContentBlock>
							<Table
								dataSource={emails}
								rowKey="_id"
								columns={columns}
								scroll={{ x: "max-content" }}
								pagination={false}
							/>
							<Pagination
								style={{ float: "right", marginTop: 24 }}
								total={count}
								current={search.page}
								pageSize={20}
								onChange={this.loadPage}
							/>
							<div style={{ clear: "both" }} />
						</ContentBlock>
					) : null}
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search,
		emailLogs: state.emailLogs
	}),
	{
		getEmails,
		resendListEmail,
		updateSearch
	}
)(EmailLogs);
