import {
	GET_EVENT_METRICS_ERROR,
	GET_EVENT_METRICS_REQUEST,
	GET_EVENT_METRICS_SUCCESS,
	GET_EVENT_SALES_OVERVIEW_ERROR,
	GET_EVENT_SALES_OVERVIEW_REQUEST,
	GET_EVENT_SALES_OVERVIEW_SUCCESS,
	GET_HISTORICAL_SALES_ERROR,
	GET_HISTORICAL_SALES_REQUEST,
	GET_HISTORICAL_SALES_SUCCESS,
	GET_SALES_CHART_ERROR,
	GET_SALES_CHART_REQUEST,
	GET_SALES_CHART_SUCCESS,
	GET_TICKET_SALES_ERROR,
	GET_TICKET_SALES_REQUEST,
	GET_TICKET_SALES_SUCCESS
} from "../actions/metricActions";

const initialState = {
	sales: { error: false, loading: false, data: [] },
	revenueSales: { error: false, loading: false, data: [] },
	salesTable: { error: false, loading: false, data: [] },
	ticketsSoldByDate: [],
	charityDonation: [],
	overview: {},
	overviewByDate: { error: false, loading: false, overview: {} },
	promotingProgress: {},
	loading: false,
	error: false,
	timeScale: "Today"
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_EVENT_METRICS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_EVENT_METRICS_SUCCESS:
			return {
				...state,
				loading: false,
				sales: { data: action.data.sales },
				revenueSales: { data: action.data.revenueSales },
				charityDonation: action.data.charityDonation,
				salesTable: { data: action.data.salesTable },
				ticketsSoldByDate: action.data.salesTableByDate,
				overview: action.data.overview,
				overviewByDate: { overview: action.data.overview }, //default is "allTime"
				promotingProgress: action.data.promotingProgress,
				error: false
			};
		case GET_EVENT_METRICS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case GET_HISTORICAL_SALES_REQUEST:
			if (!action.dashboard) {
				return {
					...state,
					sales: {
						loading: true,
						error: false
					}
				};
			}
			return state;

		case GET_HISTORICAL_SALES_SUCCESS:
			if (!action.dashboard) {
				return {
					...state,
					sales: {
						loading: false,
						data: action.data
					}
				};
			}
			return state;

		case GET_HISTORICAL_SALES_ERROR:
			if (!action.dashboard) {
				return {
					...state,
					sales: {
						loading: false,
						error: action.error
					}
				};
			}
			return state;

		case GET_EVENT_SALES_OVERVIEW_REQUEST: {
			return {
				...state,
				overviewByDate: {
					loading: true,
					error: false
				}
			};
		}
		case GET_EVENT_SALES_OVERVIEW_SUCCESS: {
			return {
				...state,
				overviewByDate: {
					loading: false,
					overview: action.data.overview
				}
			};
		}

		case GET_EVENT_SALES_OVERVIEW_ERROR: {
			return {
				...state,
				overviewByDate: {
					loading: false,
					error: action.error
				}
			};
		}

		case GET_SALES_CHART_REQUEST:
			if (!action.dashboard) {
				return {
					...state,
					revenueSales: {
						loading: true,
						error: false
					}
				};
			}
			return state;

		case GET_SALES_CHART_SUCCESS:
			if (!action.dashboard) {
				return {
					...state,
					revenueSales: {
						loading: false,
						data: action.data
					}
				};
			}
			return state;

		case GET_SALES_CHART_ERROR:
			if (!action.dashboard) {
				return {
					...state,
					revenueSales: {
						loading: false,
						error: action.error
					}
				};
			}
			return state;

		case GET_TICKET_SALES_REQUEST:
			return {
				...state,
				salesTable: {
					...state.salesTable,
					loading: true,
					error: false
				}
			};
		case GET_TICKET_SALES_SUCCESS:
			return {
				...state,
				salesTable: {
					...state.salesTable,
					loading: false,
					data: action.data
				}
			};
		case GET_TICKET_SALES_ERROR:
			return {
				...state,
				salesTable: {
					...state.salesTable,
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
