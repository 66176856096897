import axios from "./AxiosWrapper";

const TicketSaleStatusService = {
	async get(eventId, eventDateId) {
		const response = await axios.get(`events/ticket-sale-status/${eventId}`, {
			params: {
				eventDateId
			}
		});
		return response.data;
	}
};
export default TicketSaleStatusService;
