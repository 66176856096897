import { trpc } from "@/trpc";
import { ReactNode, useEffect, useState } from "react";

export type TagProps = {
	label: ReactNode;
	value: string;
};

export const useTags = (initialTagIds: string[] = []) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedTags, setSelectedTags] = useState<TagProps[]>([]);
	const [initialTags, setInitialTags] = useState<TagProps[]>([]);
	const [filteredTags, setFilteredTags] = useState<TagProps[]>([]);
	const [defaultLimit, setDefaultLimit] = useState(30);
	const [pendingSearch, setPendingSearch] = useState(false); // Use this pattern so no race conditions occur

	const searchTagsQuery = trpc.tags.searchTags.useQuery(
		{ pageSize: defaultLimit, searchTerm },
		{ enabled: searchTerm.length > 1 }
	);

	useEffect(() => {
		if (searchTagsQuery.data) {
			const tags = searchTagsQuery.data.map((tag) => ({ label: tag.name, value: tag._id }));
			setFilteredTags(tags);
		}
	}, [searchTagsQuery.data]);

	const getTagsQuery = trpc.tags.getTagsByIds.useQuery({ ids: initialTagIds }, { enabled: initialTagIds.length > 0 });

	useEffect(() => {
		if (getTagsQuery.data) {
			const tags = getTagsQuery.data.map((tag) => ({ label: tag.name, value: tag._id }));
			setInitialTags(tags);
			setSelectedTags(tags);
		}
	}, [getTagsQuery.data]);

	const createTagMutation = trpc.tags.createTag.useMutation();

	useEffect(() => {
		if (pendingSearch) {
			searchTagsQuery.refetch().finally(() => {
				setPendingSearch(false);
			});
		}
	}, [pendingSearch]);

	const handleSearchTags = (term: string, limit = null, force = false) => {
		setSearchTerm(term);
		setDefaultLimit(limit ?? defaultLimit);
		if (force || term.length > 1) {
			setPendingSearch(true);
		}
	};

	const handleCreateTag = async (name: string) => {
		const newTag = await createTagMutation.mutateAsync({ name });
		searchTagsQuery.refetch();
		return newTag;
	};

	const addSelectedTag = (tag: TagProps) => {
		setSelectedTags((prev) => [...prev, tag]);
	};

	const handleRemoveTag = (tagId: string) => {
		setSelectedTags((prev) => prev.filter((tag) => tag.value !== tagId));
	};

	const handleTagChange = async (values: string[]) => {
		const selectedTagIds = selectedTags.map((tag) => tag.value);

		// The difference in the new tags (B \ A) - tags to add/create
		const newTags = values.filter((tagId) => !selectedTagIds.includes(tagId));

		// The newTags that are not in the filtered tags or the initial tags are tags to create
		const tagsToCreate = newTags.filter((tagId) => {
			const notInFiltered = !filteredTags.some((tag) => tag.value === tagId);
			const notInInitial = !initialTags.some((tag) => tag.value === tagId);
			return notInFiltered && notInInitial;
		});

		for (const tag of tagsToCreate) {
			const newTag = await handleCreateTag(tag);
			addSelectedTag({ label: newTag.name, value: newTag._id });
		}

		// New tags that are not in tags to create
		const tagIdsToAdd = newTags.filter((tagId) => !tagsToCreate.includes(tagId));
		const tagsToAdd = filteredTags.filter((tag) => tagIdsToAdd.includes(tag.value));
		tagsToAdd.forEach((tag) => addSelectedTag({ label: tag.label, value: tag.value }));

		// The difference in the selected tags (A \ B) - tags to remove
		const tagsToRemove = selectedTagIds.filter((tagId) => !values.includes(tagId));
		tagsToRemove.forEach((tagId) => handleRemoveTag(tagId));

		// Research
		handleSearchTags("", null, true);
	};

	const handleReset = () => {
		setSearchTerm("");
		setSelectedTags([]);
		setInitialTags([]);
		setFilteredTags([]);
	};

	const combinedTags: TagProps[] = [...initialTags, ...selectedTags, ...filteredTags].filter(
		(tag, index, self) => index === self.findIndex((t) => t.value === tag.value)
	);

	const noContent = searchTerm.length > 1 ? "No results" : "Start typing to search";

	return {
		handleChange: handleTagChange,
		noContent,
		options: combinedTags,
		removeOne: handleRemoveTag,
		reset: handleReset,
		search: handleSearchTags,
		selectedTags
	};
};
