export const getConfig = (key: string, locale?: string) => {
	const fullKey = `${key}${locale ? `_${locale}` : ""}`;
	let value = (window as any)?.config?.[fullKey];
	if (value === undefined) value = (window as any)?.config?.[key];
	if (value === undefined) {
		console.trace("Missing env var", key);
		throw new Error(`Environment variable not set key:${key} locale:${locale}`);
	}
	value = value === "true" ? true : value;
	value = value === "false" ? false : value;

	return value;
};

export const gatewayRequiresPayout = (gateway: string): boolean => {
	return gateway !== "stripe" && gateway !== "pin";
};

export const getFeatureFlags = (isAdmin: boolean) => {
	const nodeEnv = getConfig("NODE_ENV");
	const isStagingOrDev = nodeEnv !== "production";
	return {
		// Mass refunds should available to all users in staging and dev but only to admins in production
		massRefunds: isAdmin || isStagingOrDev
	};
};
