import { Tooltip } from "@/ui/antd";
import { v4 as uuidv4 } from "uuid";
import InputError from "./InputError";
import InputLabel from "./InputLabel";
import InputWarning from "./InputWarning";

/**
 * Firefox hates me...
 * The reason for disabling/enabling draggable is because firefox has a known bug where most
 * features for input are disabled on draggable enabled.
 * https://stackoverflow.com/questions/21680363/prevent-drag-event-to-interfere-with-input-elements-in-firefox-using-html5-drag
 */
const onFocus = (e, id) => {
	document.getElementById(id).setAttribute("draggable", "false");
};
const onBlur = (e, id) => {
	document.getElementById(id).setAttribute("draggable", "true");
};

const InputWrapper = ({
	id,
	label,
	description,
	inline,
	touched,
	error,
	required,
	children,
	labelAfter = false,
	noMargin = false,
	tooltip,
	tooltipIcon,
	tooltipIconOnClick,
	bold = true,
	warning = false
}) => {
	const uniqueId = uuidv4();
	const style = inline ? { display: "inline-block", margin: noMargin ? 0 : null } : { margin: noMargin ? 0 : null };
	const Label = (
		<InputLabel
			label={label}
			description={description}
			id={id}
			inline={inline}
			labelAfter={labelAfter}
			required={required}
			tooltip={typeof tooltip === "string" ? tooltip : null}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			bold={bold}
		/>
	);

	return (
		<div
			style={{ ...style, marginBottom: 16 }}
			className={touched && error ? "ant-row ant-form-item ant-form-item-with-help" : "ant-row ant-form-item"}
		>
			<div
				id={uniqueId}
				style={style}
				draggable="true"
				onFocus={(e) => onFocus(e, uniqueId)}
				onBlur={(e) => onBlur(e, uniqueId)}
				onDragStart={(e) => {
					e.preventDefault();
				}}
				className={touched && error ? "ant-form-item-control has-error" : "ant-form-item-control has-success"}
			>
				{!labelAfter ? Label : null}
				{!tooltip || typeof tooltip === "string" || typeof tooltip === "undefined" ? (
					children
				) : (
					<Tooltip title={tooltip.tip} trigger="focus">
						{children}
					</Tooltip>
				)}
				{labelAfter ? Label : null}
				<InputError touched={touched} error={error} />
				<InputWarning touched={touched} warning={warning} />
			</div>
		</div>
	);
};
export default InputWrapper;
