import {
	DELETE_ALL_DISCOUNT_CODES_ERROR,
	DELETE_ALL_DISCOUNT_CODES_REQUEST,
	DELETE_ALL_DISCOUNT_CODES_SUCCESS,
	DELETE_DISCOUNT_CODES_SUCCESS,
	DUPLICATE_DISCOUNT_CODE_ERROR,
	DUPLICATE_DISCOUNT_CODE_REQUEST,
	DUPLICATE_DISCOUNT_CODE_SUCCESS,
	EDIT_DISCOUNT_CODE,
	GET_DISCOUNT_CODES_ERROR,
	GET_DISCOUNT_CODES_REQUEST,
	GET_DISCOUNT_CODES_SUCCESS,
	SAVE_DISCOUNT_CODES_ERROR,
	SAVE_DISCOUNT_CODES_REQUEST,
	SAVE_DISCOUNT_CODES_SUCCESS
} from "../actions/discountCodesActions";
import {
	EDIT_GLOBAL_DISCOUNT_CODE,
	GET_DISCOUNT_CODE_ERROR,
	GET_DISCOUNT_CODE_REQUEST,
	GET_DISCOUNT_CODE_SUCCESS
} from "../actions/globalDiscountCodesActions";

const initialState = {
	codes: null,
	count: 0,
	page: 1,
	editingCode: null,
	getRequest: {
		loading: false,
		error: false,
		saved: false
	},
	saveRequest: {
		loading: false,
		error: false,
		saved: false
	},
	duplicateRequest: {
		loading: false,
		error: false,
		saved: false
	}
};

function findCodeIndex(state, action) {
	const codes = state.codes.concat([]);
	const code = action.discountCode;
	const codeIndex = codes.findIndex((c) => String(c._id) === String(code._id));
	return codeIndex;
}

export default (state = initialState, action) => {
	switch (action.type) {
		case EDIT_DISCOUNT_CODE: {
			return {
				...state,
				editingCode: action.discountCode
			};
		}

		case EDIT_GLOBAL_DISCOUNT_CODE: {
			return {
				...state,
				editingCode: action.discountCode
			};
		}

		case GET_DISCOUNT_CODE_REQUEST: {
			return {
				...state,
				getRequest: {
					loading: true,
					error: false,
					saved: false
				}
			};
		}

		case GET_DISCOUNT_CODE_SUCCESS: {
			return {
				...state,
				editingCode: action.globalDiscountCode,
				getRequest: {
					loading: false,
					error: false,
					saved: false
				}
			};
		}

		case GET_DISCOUNT_CODE_ERROR: {
			return {
				...state,
				getRequest: {
					loading: false,
					error: true,
					saved: false
				}
			};
		}

		case GET_DISCOUNT_CODES_REQUEST:
			return {
				...state,
				codes: null,
				page: action.page,
				getRequest: {
					loading: true,
					error: false,
					saved: false
				}
			};
		case GET_DISCOUNT_CODES_SUCCESS:
			return {
				...state,
				codes: action.discountCodes,
				count: action.count,
				getRequest: {
					loading: false,
					error: false
				}
			};
		case GET_DISCOUNT_CODES_ERROR:
			return {
				...state,
				codes: null,
				getRequest: {
					loading: false,
					error: action.error
				}
			};
		case SAVE_DISCOUNT_CODES_REQUEST:
			return {
				...state,
				saveRequest: {
					loading: true,
					error: false
				}
			};
		case SAVE_DISCOUNT_CODES_SUCCESS:
			return {
				...state,
				saveRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};
		case DELETE_DISCOUNT_CODES_SUCCESS: {
			const deleteIndex = findCodeIndex(state, action);
			let newCodes = state.codes.concat([]);
			if (deleteIndex > -1) {
				newCodes.splice(deleteIndex, 1);
			}
			return {
				...state,
				codes: newCodes,
				saveRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};
		}
		case SAVE_DISCOUNT_CODES_ERROR:
			return {
				...state,
				saveRequest: {
					loading: false,
					error: action.error,
					saved: false
				}
			};
		case DELETE_ALL_DISCOUNT_CODES_REQUEST:
			return {
				...state,
				deleteAllRequest: {
					loading: true,
					error: false
				}
			};
		case DELETE_ALL_DISCOUNT_CODES_SUCCESS:
			return {
				...state,
				deleteAllRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};

		case DELETE_ALL_DISCOUNT_CODES_ERROR:
			return {
				...state,
				deleteAllRequest: {
					loading: false,
					error: action.error,
					saved: false
				}
			};

		case DUPLICATE_DISCOUNT_CODE_REQUEST:
			return {
				...state,
				saveRequest: {
					loading: true,
					error: false,
					saved: false
				}
			};
		case DUPLICATE_DISCOUNT_CODE_SUCCESS:
			return {
				...state,
				saveRequest: {
					loading: false,
					error: false,
					saved: true
				}
			};
		case DUPLICATE_DISCOUNT_CODE_ERROR:
			return {
				...state,
				saveRequest: {
					loading: false,
					error: action.error,
					saved: false
				}
			};

		default:
			return state;
	}
};
