import NoMassRefund from "@/assets//NoMassRefund.svg";
import CheckSvg from "@/assets//check.svg";
import CrossSvg from "@/assets//cross.svg";
import InProgressSvg from "@/assets//inprogress.svg";
import P from "@/components/text/P";
import LocationService from "@/services/LocationService";
import { EventDetails } from "@/state/reducers/eventReducer";
import { currency } from "@/utils/Format";
import type { DisplayMassRefund } from "@hx/console/massRefunds";
import { HumaniDate } from "@hx/dates";
import { Table } from "@/ui/antd";
import { ColumnProps } from "antd/lib/table";
import { FC } from "react";
import { Link } from "react-router-dom";

type HistoryTableProps = {
	event: EventDetails | null;
	massRefundHistory: DisplayMassRefund[];
};

export const HistoryTable: FC<HistoryTableProps> = ({ massRefundHistory, event }) => {
	const { dates } = event ?? {};
	const columns: ColumnProps<DisplayMassRefund>[] = [
		{
			dataIndex: "massRefundName",
			fixed: "left",
			key: "massRefundName",
			title: "Refund ID"
		},
		{
			dataIndex: "status",
			render: (status) => (
				<>
					<img src={status === "complete" ? CheckSvg : InProgressSvg} />
					<span style={{ paddingLeft: 5 }}>{status === "complete" ? "Completed" : "In progress"}</span>
				</>
			),
			title: "Refund Status"
		},
		{
			key: "result",
			render: (_, record) => {
				if (record.status === "pending") {
					return (
						<>
							<img src={InProgressSvg} />
							<span style={{ paddingLeft: 5 }}>In progress</span>
						</>
					);
				}
				return (
					<>
						{record.successfulOrdersCount > 0 && (
							<div>
								<img src={CheckSvg} />
								<span style={{ paddingLeft: 5 }}>{record.successfulOrdersCount} Successful orders</span>
							</div>
						)}
						{record.failedOrdersCount > 0 && (
							<div>
								<Link to={`/console/my-events/${event?._id}/mass-refunds/failure-report/${record._id}`}>
									<img src={CrossSvg} />
									<span style={{ paddingLeft: 5 }}>{record.failedOrdersCount} Failed orders &rsaquo;</span>
								</Link>
							</div>
						)}
					</>
				);
			},
			title: "Results"
		},
		{
			key: "eventDate",
			render: (_, record) => {
				const prettyDates = record.eventDateIdsAffected?.map((affectedDateId) => {
					return dates?.find((date) => date._id === affectedDateId);
				});
				return (
					<>
						{prettyDates?.map((date) => (
							<div>{date?.display?.combined}</div>
						))}
					</>
				);
			},
			title: "Event Date/Time"
		},
		{
			dataIndex: ["options", "reason"],
			key: "reason",
			title: "Refund Reason"
		},
		{
			key: "refundDate",
			render: (_, record) => (
				<>{new HumaniDate(record.createdAt, "guess", LocationService.getLocation(true)).formatting.dateTime()}</>
			),
			title: "Refund Date/Time"
		},
		{
			dataIndex: "user",
			key: "user",
			render: (user) => (
				<>
					{user.firstName} {user.lastName}
				</>
			),
			title: "Actioned by"
		},
		{
			dataIndex: "total",
			fixed: "right",
			key: "total",
			render: (total) => <div style={{ textAlign: "right" }}>{currency(total)}</div>,
			title: "Total Refund Amount"
		}
	];

	return (
		<>
			{massRefundHistory.length ? (
				<div
					style={{
						marginTop: 20,
						minWidth: "100%",
						overflowX: "auto"
					}}
				>
					<Table
						columns={columns}
						dataSource={massRefundHistory}
						pagination={false}
						rowKey="_id"
						scroll={{ x: "max-content" }}
					/>
				</div>
			) : (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						textAlign: "center"
					}}
				>
					<img alt="No mass refunds" height={120} src={NoMassRefund} width={120} />
					<P>
						<b>Start by mass refunding orders</b>
					</P>
					<P style={{ color: "grey" }}>
						To refund orders, click on the{" "}
						<Link to={`/console/my-events/${event?._id}/mass-refunds/refunds`}>Refund tab</Link>.
					</P>
				</div>
			)}
		</>
	);
};
