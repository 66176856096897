import heroImage from "@/assets/frames.jpg";
import styled from "@emotion/styled";
import { Divider, Space } from "antd";
import { FC, ReactNode } from "react";

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 100%;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

const FirstColumn = styled.div<{ alignItems?: "center" | "flex-start" }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: ${(props) => props.alignItems};
	padding: 48px;
`;

const FirstColumnInner = styled.div<{ alignItems?: "center" | "flex-start" }>`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: ${(props) => props.alignItems};
	justify-content: center;
	width: 370px;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const SecondColumn = styled.div`
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;

const ForHostsText = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: #000;
	line-height: 16px;
`;

export const TwoColumnPageWrap: FC<{ alignItems?: "center" | "flex-start"; children: ReactNode }> = ({
	children,
	alignItems = "center"
}) => {
	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<Grid>
				<FirstColumn alignItems={alignItems}>
					<Space />
					<FirstColumnInner alignItems={alignItems}>
						<>
							<Space size={4} align="start" style={{ marginBottom: "48px" }}>
								<img
									src="https://static.humanitix.com/logos/humanitix-logo-onyx.png"
									alt="Humanitix"
									style={{ height: 24, width: "auto" }}
								/>

								<Divider
									type="vertical"
									style={{
										height: "1.2rem",
										marginTop: -4
									}}
								/>

								<ForHostsText>For hosts</ForHostsText>
							</Space>
							{children}
						</>
					</FirstColumnInner>
				</FirstColumn>

				<SecondColumn>
					<img src={heroImage} alt="humanitix attendees" />
				</SecondColumn>
			</Grid>
		</div>
	);
};
