import { Switch } from "@/ui/antd";
import { connect } from "react-redux";

const BookByTable = ({ elements, performAction, currentEvent }) => {
	let bookByTable = true;
	elements.forEach((ele) => (bookByTable = bookByTable && ele.bookByTable));
	const hasPackagedTickets = currentEvent.event.packagedTickets && currentEvent.event.packagedTickets.length > 0;
	if (!hasPackagedTickets) {
		return null;
	}
	return (
		<div style={{ overflow: "auto" }}>
			<h3 style={{ float: "left" }}>Require whole table booking</h3>
			<Switch
				checked={bookByTable}
				onChange={(val) =>
					performAction(
						elements.map((element) => element.setBookByTable),
						elements.map(() => [val])
					)
				}
				style={{ float: "right" }}
			/>
		</div>
	);
};

export default connect(
	(state) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(BookByTable);
