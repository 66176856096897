import { css } from "@emotion/css";
import { Component } from "react";
import { change } from "redux-form";
import ThemeImage from "../../assets/tours/styling/Theme";
import selectedIcon from "../../assets/tours/styling/selected.svg";

const styles = {
	wrapper: css`
		width: 100%;
		max-width: 680px;
		margin-left: auto;
		margin-right: auto;
	`,
	container: css`
		display: flex;
		flex-wrap: wrap;
		@media (max-width: 1090px) {
			justify-content: space-between;
		}
	`
};
/**
 * Tour page theme. You can choose theme for your tour page
 *
 * @class Theme
 * @extends {Component}
 */
class Theme extends Component {
	constructor(props) {
		super(props);
		const selectedTheme = props.selectedTheme ? props.selectedTheme.type : "default";
		this.state = { selectedTheme };
	}

	selectTheme = (theme) => {
		const { dispatch, formName } = this.props;
		dispatch(change(formName, `theme`, theme));
		this.setState({ selectedTheme: theme.type });
	};

	render() {
		const { selectedTheme } = this.state;
		const themeNames = [
			{
				label: "Default",
				type: "default",
				primaryColor: "#353337",
				linkColor: "#196097",
				footerColor: "#4E4757",
				primaryTextColor: "#fff",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "twoTone",
				ticketButtonDisplay: "filled",
				titleBackgroundColor: "#ffffff",
				eventCardBackgroundColor: "#ffffff",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#fafafa" }
			},
			{
				label: "Navy",
				type: "navy",
				primaryColor: "#192295",
				linkColor: "#192295",
				footerColor: "#192295",
				primaryTextColor: "#fff",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "white",
				ticketButtonDisplay: "filled",
				titleBackgroundColor: "#ffffff",
				eventCardBackgroundColor: "#ffffff",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#fafafa" }
			},
			{
				label: "The Dark Knight",
				type: "dark",
				primaryColor: "#57CFC7",
				linkColor: "#57CFC7",
				footerColor: "#1d1d1d",
				primaryTextColor: "#323232",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "white",
				ticketButtonDisplay: "filled",
				titleBackgroundColor: "#1d1d1d",
				titleTextColor: "#ffffff",
				eventCardBackgroundColor: "#1d1d1d",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#9C9C9C" }
			},
			{
				label: "Provence",
				type: "provence",
				primaryColor: "#8C33A6",
				linkColor: "#8C33A6",
				footerColor: "#8C33A6",
				primaryTextColor: "#fff",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "white",
				ticketButtonDisplay: "filled",
				titleBackgroundColor: "#ffffff",
				eventCardBackgroundColor: "#ffffff",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#fafafa" }
			},
			{
				label: "Royal",
				type: "royal",
				primaryColor: "#8D721E",
				linkColor: "#8D721E",
				footerColor: "#8D721E",
				primaryTextColor: "#fff",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "white",
				ticketButtonDisplay: "filled",
				eventCardBackgroundColor: "#ffffff",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#fafafa" }
			},
			{
				label: "Coral",
				type: "coral",
				primaryColor: "#D3401B",
				linkColor: "#D3401B",
				footerColor: "#D3401B",
				primaryTextColor: "#fff",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "white",
				ticketButtonDisplay: "filled",
				titleBackgroundColor: "#ffffff",
				eventCardBackgroundColor: "#ffffff",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#fafafa" }
			},
			{
				label: "Funky Plum",
				type: "funkyPlum",
				primaryColor: "#FFB18F",
				linkColor: "#FFB18F",
				footerColor: "#2D0E54",
				primaryTextColor: "#323232",
				backgroundTextColor: "#323232",
				footerTextColor: "#fff",
				footerLogoColor: "white",
				ticketButtonDisplay: "filled",
				titleBackgroundColor: "#2D0E54",
				titleTextColor: "#ffffff",
				eventCardBackgroundColor: "#2D0E54",
				sponsorBoxBackgroundColor: "#ffffff",
				cardAndBarOpacity: 100,
				background: { type: "color", color: "#E7C5AC" }
			}
		];
		const customStyles = {
			themeContainer: css`
        margin: 13px 25px;
        position: relative;
        @media (max-width: 600px) {
          margin: 24px 4px 0px;
      `,
			theme: css`
				@media (max-width: 600px) {
					width: 120px;
				}
			`
		};

		return (
			<div className={styles.wrapper}>
				<div className={styles.container}>
					{themeNames.map((theme, index) => {
						const isSelected = theme.type === selectedTheme;
						const stylingThemeButton = css`
							border: ${isSelected ? "1px solid #353337" : "none"};
							border-radius: 4px;
							padding: 4px 4px 0px;
							cursor: pointer;
							margin-bottom: 26px;
							width: 172px;
							height: 231px;
							background-color: transparent;
							&:focus {
								outline: none;
							}
							@media (max-width: 600px) {
								width: 128px;
								height: 171px;
								margin-bottom: 0px;
							}
						`;
						return (
							<div key={index} className={customStyles.themeContainer}>
								<div style={{ marginBottom: 10, fontWeight: 600 }}>{theme.label}</div>
								{isSelected && (
									<img alt="selected theme" src={selectedIcon} style={{ position: "absolute", top: 47, right: 15 }} />
								)}
								<button
									type="button"
									className={[stylingThemeButton]}
									onClick={() => {
										this.selectTheme(theme);
									}}
									aria-label="Theme"
								>
									<div className={customStyles.theme}>
										<ThemeImage {...theme} />
									</div>
								</button>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default Theme;
