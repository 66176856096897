import { Switch } from "@/ui/antd";
import { Component } from "react";

export default class SectionLock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locked: props.element.locked
		};
		this.reserveChanged = this.reserveChanged.bind(this);
		this.onLock = this.onLock.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		nextProps.element.listenOnLock(this.onLock);
		this.setState({ locked: nextProps.element.locked });
	}

	reserveChanged(value) {
		this.setState({ locked: value });
		this.props.element.lockChanged(value);
	}

	onLock() {
		this.setState({ locked: true });
	}

	render() {
		const locked = this.state.locked;
		return (
			<div style={{ overflow: "auto", paddingTop: 20 }}>
				<h3 style={{ float: "left" }}>Custom Shape</h3>
				<Switch checked={locked} onChange={this.reserveChanged} style={{ float: "right" }} />
			</div>
		);
	}
}
