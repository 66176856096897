import React, { useEffect, useState } from "react";
import LinkButton from "../../../components/buttons/LinkButton";
import { P } from "../../../components/text/Text";
import MappingErrorModal from "./MappingErrorModal";

type TicketType = {
	_id: string;
	name: string;
	deleted: boolean;
	disabled: boolean;
	isDonation: boolean;
	mappedSeats: number;
	isMappedToSeatingMap: boolean;
	quantity: number;
} & any;

type PackagedTicket = {
	_id: string;
	name: string;
	tickets: { quantity: number } & any[];
	mappedTables: number;
	mappedSeats: number;
	isMappedToSeatingMap: boolean;
	disabled: boolean;
	deleted: boolean;
} & any;

interface SeatingMapCapacityIssueProps {
	ticketTypes: TicketType[];
	packagedTickets: PackagedTicket[];
	isThereAnyUnMappedSeats: boolean;
	style?: any;
	onShowManageCapacity: () => void;
	onNavigateToMappingTab: () => void;
}

const SeatingMapCapacityIssue = ({
	ticketTypes,
	packagedTickets,
	isThereAnyUnMappedSeats,
	style = {},
	onShowManageCapacity,
	onNavigateToMappingTab
}: SeatingMapCapacityIssueProps) => {
	const [isMappingErrorModalVisible, toggleMappingErrorModal] = useState(false);

	const hasMismatchInTickets = ticketTypes
		?.filter((tt) => tt.mappedSeats > 0)
		?.some((tt) => tt.mappedSeats !== tt.quantity);

	const hasMismatchInPackages = packagedTickets
		?.filter((pt) => pt.mappedTables > 0)
		?.some((pt) => pt.mappedTables !== pt.quantity);

	const [hasMismatch, toggleHasMismatch] = useState(hasMismatchInTickets || hasMismatchInPackages);

	useEffect(() => {
		toggleHasMismatch(hasMismatchInTickets || hasMismatchInPackages);
	}, [hasMismatchInTickets, hasMismatchInPackages]);

	const atLeastOneSeatIsMapped =
		ticketTypes.some((tt) => tt.mappedSeats > 0) || packagedTickets.some((pt) => pt.mappedSeats > 0);

	if (!atLeastOneSeatIsMapped || (!hasMismatch && !isThereAnyUnMappedSeats)) {
		return null;
	} else {
		return (
			<div style={{ ...style, display: "flex" }}>
				<span
					style={{
						backgroundColor: "#8D0013",
						height: 8,
						width: 8,
						borderRadius: "50%",
						marginRight: 0,
						marginTop: 7
					}}
				/>
				<P marginBottom={0}>
					<LinkButton
						ariaLabel="See issues"
						onClick={() => toggleMappingErrorModal(true)}
						style={{
							paddingLeft: 4,
							textDecoration: "underline"
						}}
					>
						See issue{hasMismatch && isThereAnyUnMappedSeats ? "s" : ""}
					</LinkButton>
				</P>
				{isMappingErrorModalVisible ? (
					<MappingErrorModal
						isOpen={isMappingErrorModalVisible}
						showMismatchError={hasMismatch}
						showNotFullyMappedError={isThereAnyUnMappedSeats}
						onConfirm={() => toggleMappingErrorModal(false)}
						onNavigateToManageCapacity={() => {
							toggleMappingErrorModal(false);
							onShowManageCapacity();
						}}
						onNavigateToMappingTab={() => {
							toggleMappingErrorModal(false);
							onNavigateToMappingTab();
						}}
					/>
				) : null}
			</div>
		);
	}
};

export default SeatingMapCapacityIssue;
