import iconTick from "@/assets/icon-tick.svg";
import styled from "@emotion/styled";

export const ImageWrapper = styled.div`
	margin: 0px auto 12px auto;
	width: 140px;
	height: 60px;
	img {
		width: 140px;
		height: auto;
	}
`;
export const Footer = styled.div`
	margin-top: auto;
	width: 100%;
`;

export const GatewayEnabledWrapper = styled.div`
	text-align: center;
	margin-bottom: 8px;

	div {
		background: url(${iconTick}) no-repeat;
		background-size: 16px;
		background-position: 0 3px;
		color: #157e77;
		display: inline;
		font-weight: 600;
		padding: 0 0 12px 22px;
	}
`;

export const PaymentCard = styled.div`
	background: #fff;
	border-radius: 12px;
	border: 1px solid rgba(27, 27, 27, 0.31);
	display: flex;
	flex-direction: column;
	max-width: 450px;
	min-height: 428px;
	padding: 24px;

	:last-child {
		margin-right: 0;
	}

	@media (max-width: 600px) {
		width: 100%;
	}
`;
