import { findSocialProfileHandle, serializeSocialProfileUrl, stripUrl } from "../../utils/socials";
import { findLinkedInHandle, parseLinkedInData } from "../../utils/socials/linkedIn";

const prepareLinkedInData = (data: any) => {
	const { linkedIn, linkedInProfileType } = data;

	const { url, handle } = parseLinkedInData(linkedIn);
	return serializeSocialProfileUrl(linkedInProfileType ?? url, handle);
};

const prepareFormData = (data: any) => {
	const linkedIn = prepareLinkedInData(data);
	const facebook = serializeSocialProfileUrl("https://facebook.com/", stripUrl(data.facebook));
	const spotify = serializeSocialProfileUrl("https://open.spotify.com/", stripUrl(data.spotify));
	const tikTok = serializeSocialProfileUrl("https://tiktok.com/", stripUrl(data.tikTok));
	const twitter = serializeSocialProfileUrl("https://twitter.com/", stripUrl(data.twitter));
	const instagram = serializeSocialProfileUrl("https://instagram.com/", stripUrl(data.instagram));
	const soundCloud = serializeSocialProfileUrl("https://soundcloud.com/", stripUrl(data.soundCloud));
	return {
		...data,
		...{
			linkedIn,
			facebook,
			spotify,
			tikTok,
			twitter,
			instagram,
			soundCloud
		}
	};
};

const formatField = (fieldName: string) => (value: string) => {
	switch (fieldName) {
		case "linkedIn":
			return findLinkedInHandle(value);
		case "spotify":
			return findSocialProfileHandle("https://open.spotify.com/", value);
		case "tikTok":
			return findSocialProfileHandle("https://tiktok.com/", value);
		case "twitter":
			return findSocialProfileHandle("https://twitter.com/", value);
		case "instagram":
			return findSocialProfileHandle("https://instagram.com/", value);
		case "soundcloud":
			return findSocialProfileHandle("https://soundcloud.com/", value);
		case "facebook":
			return findSocialProfileHandle("https://facebook.com/", value);

		default:
			return value;
	}
};

export { formatField, prepareFormData };
