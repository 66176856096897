import { Modal } from "@/ui/antd";
import { Component } from "react";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import PayoutForm from "./PayoutForm";

/**
 * Used on referral program payout.
 *
 * @export
 * @class PayoutModal
 * @extends {Component}
 */
export default class PayoutModal extends Component {
	render() {
		const { visible, handleCancel, save, payment } = this.props;
		return (
			<Modal
				open={visible}
				title="Add Payment"
				onCancel={handleCancel}
				destroyOnClose
				footer={[
					<LegacyButton
						style={{ margin: "0 0 0 auto" }}
						key="payment"
						htmlType="submit"
						form="paymentForm"
						value="Submit"
						type="primary"
						ariaLabel={payment && payment._id ? "Update" : "Save"}
					>
						{payment && payment._id ? "Update" : "Save"}
					</LegacyButton>
				]}
			>
				<PayoutForm remainingAmount={this.props.remainingAmount} save={save} closed={!visible} payment={payment} />
			</Modal>
		);
	}
}
