import RichTextArea from "../RichTextArea";
import InputWrapper from "./helpers/InputWrapper";

const RichTextAreaField = (props) => {
	const {
		input,
		label,
		description,
		required,
		basicEditor,
		customAdvancedPlugins,
		inline,
		mentionOptions,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		meta: { touched, error, warning = false },
		...rest
	} = props;

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			warning={warning}
		>
			{/* Do not remove this space. It causes label move down without it*/}{" "}
			<RichTextArea
				{...input}
				{...rest}
				customAdvancedPlugins={customAdvancedPlugins}
				basicEditor={basicEditor}
				mentionOptions={mentionOptions}
			/>
		</InputWrapper>
	);
};

export default RichTextAreaField;
