import React from "react";
import { Tabs, Tooltip } from "@/ui/antd";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import LineChart from "../components/LineChart";
import { ContentBlock } from "@/components/AppBlocks";

const tabs = css({
	".ant-tabs-tab": {
		borderLeft: "1px solid #e0e0e0",
		minWidth: 120
	},
	".ant-tabs-tab:first-child": {
		borderLeft: "none"
	},
	".ant-tabs-tab + .ant-tabs-tab": {
		margin: 0
	}
});

const total = css({
	flex: 1,
	marginRight: 8,
	maxWidth: 140,
	h3: {
		fontSize: "12px"
	},
	h4: {
		fontSize: "24px"
	}
});

interface AnalyticsData {
	conversionRate: number;
	uniqueSessionsOverTime: any;
	completeOverTime: any;
	eventViewsOverTime: any;
	conversionRateOverTime: any;
}

//eslint-disable-next-line
interface TotalsData {
	conversionRate?: string;
	eventPageViews?: string;
	ticketSectionViews?: string;
	totalPageViews?: string;
	uniqueVisitors?: string;
	totalVisits?: string;
	viewsPerVisit?: string;
	medianDuration?: string;
}

interface TotalsProps {
	analyticsData: AnalyticsData | null;
}

const ScoreCards = ({ analyticsData }: TotalsProps) => {
	const mapDateData = (data: any) => {
		if (!data?.length) return { total: 0, overtime: [] };
		let total = 0;
		const overtime = data.map((item: any) => {
			const itemTotal = item.total === "Infinity" ? 0 : item.total;
			total += itemTotal;
			return {
				date: new HumaniDate(item.date).dayJs.format("MMM D"),
				total: itemTotal
			};
		});
		return { total, overtime };
	};

	const eventViews = mapDateData(analyticsData?.eventViewsOverTime);
	const unqiueSessions = mapDateData(analyticsData?.uniqueSessionsOverTime);
	const completeOrders = mapDateData(analyticsData?.completeOverTime);
	const conversionRate = mapDateData(analyticsData?.conversionRateOverTime);
	const overallVonversionRate =
		String(analyticsData?.conversionRate) === "Infinity" ? 0 : analyticsData?.conversionRate;
	return (
		<ContentBlock>
			<Tabs
				className={tabs}
				defaultActiveKey="1"
				items={[
					{
						label: (
							<Tooltip title="How many page views your event has received">
								<div className={total}>
									<h3>Views</h3>
									<h4>{eventViews.total}</h4>
								</div>
							</Tooltip>
						),
						key: "1",
						children: <LineChart title="Views" data={eventViews.overtime} dataKey="date" />
					},
					{
						label: (
							<Tooltip title="How many individual users have viewed your event">
								<div className={total}>
									<h3>Unique visitors</h3>

									<h4>{unqiueSessions.total}</h4>
								</div>
							</Tooltip>
						),
						key: "2",
						children: <LineChart title="Unqiue visitors" data={unqiueSessions.overtime} dataKey="date" />
					},
					{
						label: (
							<Tooltip title="How many orders were successfully completed">
								<div className={total}>
									<h3>Completed orders</h3>

									<h4>{completeOrders.total}</h4>
								</div>
							</Tooltip>
						),
						key: "3",
						children: <LineChart title="Completed orders" data={completeOrders.overtime} dataKey="date" />
					},
					{
						label: (
							<Tooltip title="The percentage of unique visitors that completed an order">
								<div className={total}>
									<h3>Conversion rate</h3>

									<h4>{overallVonversionRate}%</h4>
								</div>
							</Tooltip>
						),
						key: "4",
						children: <LineChart title="Conversion rate" data={conversionRate.overtime} dataKey="date" />
					}
				]}
			/>
		</ContentBlock>
	);
};

export default ScoreCards;
