import { getConfig } from "@/config";
import { DatePicker as AntdDatePicker } from "@/ui/antd";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";
import { WrappedFieldProps } from "redux-form";
import InputWrapper from "./helpers/InputWrapper";

type ReduxDateTimePickerProps = WrappedFieldProps & {
	description?: string;
	disabled?: boolean;
	format?: string;
	inline?: boolean;
	label?: string;
	required?: boolean;
	showTime?: boolean | { format: string };
	timezone?: string;
};

export const ReduxDateTimePicker: FC<ReduxDateTimePickerProps> = (props) => {
	const {
		input,
		meta: { touched, error },
		label,
		description,
		inline,
		required,
		timezone = getConfig("TIMEZONE_DEFAULT"),
		showTime = false,
		format,
		disabled,
		...rest
	} = props;

	const parseValue = (inputValue: string): Dayjs | null => {
		if (!inputValue) return null;

		return dayjs(inputValue).tz(timezone);
	};

	const handleChange = (date: Dayjs | null) => {
		if (date) {
			// Using format helps represent the time as is was selected without timezone information
			// Then we convert it to the appropriate timezone. Without this issues arise when working across daylight savings times.
			const dateInTimezone = dayjs.tz(date.format("YYYY-MM-DDTHH:mm:ss"), timezone);

			input.onChange(dateInTimezone.toISOString());
		} else {
			input.onChange(null);
		}
	};

	const dateFormat = format || (showTime ? "Do MMM YYYY h:mm A" : "Do MMM YYYY");

	const pickerValue = parseValue(input.value);

	return (
		<InputWrapper
			description={description}
			error={error}
			id={input.name}
			inline={inline}
			label={label}
			required={required}
			tooltip=""
			tooltipIcon=""
			tooltipIconOnClick={() => {}}
			touched={touched}
		>
			<AntdDatePicker
				disabled={disabled}
				format={dateFormat}
				id={input.name}
				onChange={handleChange}
				showTime={showTime}
				style={{ minWidth: "200px" }}
				value={pickerValue}
				{...rest}
			/>
		</InputWrapper>
	);
};
