import { Modal, Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { css } from "@emotion/css";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton as HButton } from "@/components/buttons/LegacyButton";
import { getTicketDisplayName } from "../../utils/Ticket";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

const cellStyle = css({
	maxWidth: "250px"
});

class GuestList extends Component {
	constructor(props) {
		super(props);
		this.getDisplayColumns = this.getDisplayColumns.bind(this);
		this.getFilterOptions = this.getFilterOptions.bind(this);
	}

	state = {
		showPhotoModal: false,
		showDownloadModal: false,
		photoLink: "",
		photoModalTitle: "Image Answer"
	};

	getFilterOptions() {
		const { filters = {} } = this.props.reportState;
		const { ticketTypes = [] } = filters;

		return [
			{
				key: "sortBy",
				label: "Sort by",
				options: [
					{ value: "newest", name: "Newest" },
					{ value: "oldest", name: "Oldest" },
					{ value: "firstName", name: "First Name" },
					{ value: "lastName", name: "Last Name" },
					{ value: "checkedIn", name: "Checked In" },
					{ value: "notCheckedIn", name: "Not Checked In" }
				]
			},
			{
				key: "ticketOrPackageTypeIds",
				label: "Select ticket types",
				mode: "multiple",
				options: ticketTypes.map((tt) => ({
					value: tt._id,
					name: getTicketDisplayName(tt)
				}))
			}
		];
	}

	getDisplayColumns() {
		const { columns } = this.props.reportState;
		if (!columns.length) return [];

		return columns.map((column) => {
			let render = (value) => <div className={cellStyle}>{value}</div>;
			if (column.dataIndex === "eventName") {
				render = (value, data) => (
					<div className={cellStyle}>
						<Link to={`/console/my-events/${data._eventId}`}>{value}</Link>
					</div>
				);
			} else if (column.dataIndex === "orderId") {
				render = (value, data) => (
					<div className={cellStyle}>
						<Link to={`/console/my-events/${data._eventId}/orders/${data._orderId}`}>{value}</Link>
					</div>
				);
			}
			return {
				...column,
				render
			};
		});
	}

	openPhotoModal = (photoLink, question) => {
		this.setState({
			showPhotoModal: !this.state.showPhotoModal,
			photoLink,
			photoModalTitle: question
		});
	};

	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;

		const { load, loadPage, downloadCsv } = this.props;

		const displayColumns = this.getDisplayColumns();
		const filterOptions = this.getFilterOptions();

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						firstDateName="orderDates"
						firstDateLabel="Order date range"
						secondDateName="eventDates"
						secondDateLabel="Event date range"
						filterOptions={filterOptions}
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							{results.length > 0 && (
								<HButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
									{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
								</HButton>
							)}
						</div>
						<Table
							columns={displayColumns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
				<Modal
					title={this.state.photoModalTitle}
					open={this.state.showPhotoModal}
					onOk={() => this.openPhotoModal("")}
					onCancel={() => this.openPhotoModal("")}
				>
					<img src={this.state.photoLink} style={{ width: "100%" }} alt="img" />
				</Modal>
			</LoadErrorView>
		);
	}
}

export default withReportProvider(GuestList, "Guest list", "guest-list");
