import { css } from "@emotion/css";
import { Alert, Button, Dropdown, Modal, Spin, Table, Tooltip } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock, LoadingSpinner } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import HeadingWithSubheading from "@/components/HeadingWithSubheading";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import { P } from "@/components/text/Text";
import { bulkCheckIn, getCheckInCount, updateCheckIn } from "@/state/actions/checkInActions";
import { getReport, getReportCsv, getReportPdf } from "@/state/actions/globalReportActions";
import { updateSearch } from "@/state/actions/searchActions";
import { getTicketDisplayName } from "@/utils/Ticket";
import TicketActions from "@/events/event/AttendeeActions";

const BULK_CHECK_IN = "bulk-check-in";
const BULK_CHECK_OUT = "bulk-check-out";

const questionHeaderStyle = css({
	maxWidth: "250px",
	overflow: "hidden",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis"
});

const cellStyle = css({
	maxWidth: "250px"
});

const mobileHide = css({
	"@media(max-width:600px)": {
		display: "none"
	}
});

class Attendees extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPhotoModal: false,
			showDownloadModal: false,
			photoLink: "",
			photoModalTitle: "Image Answer",
			bulkCheckInModal: undefined
		};

		this.loadPage = this.loadPage.bind(this);
		this.handleCheckIn = this.handleCheckIn.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.downloadPdf = this.downloadPdf.bind(this);
		this.openDownloadModal = this.openDownloadModal.bind(this);
		this.loadCheckInCount = this.loadCheckInCount.bind(this);
		this.getDisplayColumns = this.getDisplayColumns.bind(this);
		this.getFilterOptions = this.getFilterOptions.bind(this);
	}

	componentDidMount() {
		this.loadCheckInCount();
	}

	componentWillUnmount() {
		clearTimeout(this.checkInTimeout);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	getFilterOptions() {
		const {
			currentEvent: {
				event: { dates, datesUseCalendar, reportingGroups }
			}
		} = this.props;
		const { filters = {} } = this.props.reportState;
		const { ticketTypes = [] } = filters;
		return [
			{
				key: "orderStatus",
				label: "Order Status",
				kind: "radio",
				options: [
					{ value: "complete", name: "Complete" },
					{ value: "cancelled", name: "Cancelled" }
				]
			},
			{
				key: "ticketOrPackageTypeIds",
				label: "Select ticket types",
				mode: "multiple",
				placeholder: "All Ticket Types",
				options: ticketTypes.map((tt) => ({
					value: tt._id,
					name: getTicketDisplayName(tt)
				}))
			},
			{
				key: "eventDateIds",
				label: "Event Dates",
				mode: "multiple",
				kind: datesUseCalendar ? "eventDatePicker" : "select",
				placeholder: "All dates",
				options: dates.map((date) => ({
					...(datesUseCalendar ? date : {}),
					value: date._id,
					name: `${date.display.date}, ${date.display.time}`
				}))
			},
			...(reportingGroups?.length
				? [
						{
							key: "reportingGroupIds",
							label: "Reporting groups",
							mode: "multiple",
							placeholder: "All",
							options: reportingGroups.map((group) => ({ value: group._id, name: group.name }))
						}
				  ]
				: []),
			{
				key: "salesChannel",
				label: "Sales channel",
				kind: "radio",
				options: [
					{ value: "all", name: "All" },
					{ value: "online", name: "Online" },
					{ value: "manual", name: "Manual" },
					{ value: "boxOffice", name: "Box office" },
					{ value: "resale", name: "Resale" },
					{ value: "swap", name: "Swap" }
				]
			}
		];
	}

	loadCheckInCount() {
		const { getCheckInCount, currentEvent } = this.props;
		const eventId = currentEvent.event._id;
		getCheckInCount(eventId);

		if (this.checkInTimeout) {
			clearTimeout(this.checkInTimeout);
		}
		this.checkInTimeout = setTimeout(() => {
			getCheckInCount(eventId);
		}, 1000 * 30);
	}

	handleMenuSelect = (key) => {
		switch (key) {
			case BULK_CHECK_IN:
				this.setState({ bulkCheckInModal: key });
				break;
			case BULK_CHECK_OUT:
				this.setState({ bulkCheckInModal: key });
				break;
			default:
			// do nothing
		}
	};

	handleCheckIn(id, checkedIn) {
		this.props.updateCheckIn(id, checkedIn);
	}

	openDownloadModal() {
		this.setState({
			showDownloadModal: !this.state.showDownloadModal
		});
	}

	downloadCsv() {
		const { getReportCsv } = this.props;
		getReportCsv("attendee");
	}

	downloadPdf() {
		const { getReportPdf } = this.props;
		getReportPdf("attendee");
	}

	downloadCheckInCsv = () => {
		const { getReportCsv } = this.props;
		getReportCsv("check-in");
	};

	openPhotoModal = (photoLink, question) => {
		this.setState({
			showPhotoModal: !this.state.showPhotoModal,
			photoLink,
			photoModalTitle: question
		});
	};

	getDisplayColumns() {
		const { columns } = this.props.reportState;
		if (!columns.length) return [];
		const displayColumns = columns.map((column) => {
			const displayColumn = { ...column };
			if (displayColumn.questionType) {
				displayColumn.title = () => (
					<Tooltip title={column.title}>
						<div className={questionHeaderStyle}>{column.title}</div>
					</Tooltip>
				);
				if (displayColumn.questionType === "image") {
					displayColumn.render = (value) =>
						value ? (
							<Button
								shape="circle"
								icon="picture"
								onClick={() => this.openPhotoModal(`https://process.filestackapi.com/${value}`, column.title)}
								aria-label="Open photo"
							/>
						) : (
							""
						);
				} else {
					displayColumn.render = (value) => <div className={cellStyle}>{value}</div>;
				}
			} else if (displayColumn.dataIndex === "orderId") {
				displayColumn.render = (value, data) => (
					<div className={cellStyle}>
						<Link to={`/console/my-events/${data._eventId}/orders/${data._orderId}`}>{value}</Link>
					</div>
				);
			} else {
				displayColumn.render = (value) => <div className={cellStyle}>{value}</div>;
			}
			return displayColumn;
		});

		displayColumns.shift(); // Remove "Event name" since that it only useful on the global report

		displayColumns.push({
			title: "Action",
			key: "action",
			fixed: "right",
			width: 100,
			render: (_, ticket) => {
				if (ticket._status !== "complete") {
					return null;
				}
				return <TicketActions ticket={ticket} />;
			}
		});
		return displayColumns;
	}

	render() {
		const { results, page, error, csvLoading, csvError, pdfLoading, pdfError } = this.props.reportState;
		const { currentEvent, getReport, checkIn, bulkCheckIn, search } = this.props;
		const { showDownloadModal, bulkCheckInModal } = this.state;
		const loading = this.props.reportState.loading || checkIn.loading;
		const messages = loading ? { emptyText: "Loading" } : { emptyText: "No Attendees" };
		if (!currentEvent) return <Spin />;
		const displayColumns = this.getDisplayColumns();
		const filterOptions = this.getFilterOptions();
		return (
			<div>
				<HeadingWithSubheading heading="Attendees" subheading={`Checked in: ${checkIn.checkedIn}/${checkIn.total}`} />
				<ContentBlock>
					<Modal
						title="Download Attendees Reports"
						open={showDownloadModal}
						onOk={this.openDownloadModal}
						onCancel={this.openDownloadModal}
						footer={null}
					>
						<P>Download the Attendees report as a CSV or a PDF.</P>
						{csvLoading ? (
							<Button style={{ marginRight: 12 }} aria-disabled disabled>
								<LoadingSpinner size={17} color="#353337" />
							</Button>
						) : (
							<Button onClick={this.downloadCsv} style={{ marginRight: 12 }} aria-label="CSV">
								Attendee details CSV
							</Button>
						)}
						{pdfLoading ? (
							<Button aria-disabled disabled>
								<LoadingSpinner size={17} color="#353337" />
							</Button>
						) : (
							<Button onClick={this.downloadPdf} aria-label="Guest list (PDF)">
								Guest list PDF
							</Button>
						)}
						{csvLoading ? (
							<Button style={{ marginRight: 12 }} aria-disabled disabled>
								<LoadingSpinner size={17} color="#353337" />
							</Button>
						) : (
							<Button onClick={this.downloadCheckInCsv} style={{ marginLeft: 12 }} aria-label="Check-in CSV">
								Check in history
							</Button>
						)}
						<br />
						{csvError && <Alert message="CSV failed to generate" type="error" showIcon style={{ marginTop: 12 }} />}
						{pdfError && <Alert message="PDF failed to generate" type="error" showIcon style={{ marginTop: 12 }} />}
					</Modal>

					<Modal
						title={bulkCheckInModal === BULK_CHECK_IN ? "Bulk check in" : "Bulk check out"}
						open={bulkCheckInModal}
						onOk={() => {
							this.setState({ bulkCheckInModal: undefined });
							bulkCheckIn(bulkCheckInModal === BULK_CHECK_IN);
						}}
						onCancel={() => this.setState({ bulkCheckInModal: undefined })}
						okText="Confirm"
					>
						<Alert
							message="Warning"
							description={
								<>
									You are about to {bulkCheckInModal === BULK_CHECK_IN ? "check in" : "check out"} all attendees with
									the <b>current search filters</b>
								</>
							}
							showIcon
							type="warning"
						/>
					</Modal>

					<SearchRefinements
						defaultSearch={{ eventIds: [currentEvent.event._id] }}
						load={(page) => getReport("attendee", page)}
						tooltip="Filter attendees"
						filterOptions={filterOptions}
						showRefinements
						dropdownId="attendeesReportFilter"
						download={{
							download: this.openDownloadModal,
							loading: false,
							tooltip: "Export attendees"
						}}
						extras={
							<Dropdown
								menu={{
									items: [
										{
											key: "email-campaigns",
											label: (
												<Link to="/console/comms/email-campaigns" className={mobileHide}>
													Email campaigns
												</Link>
											)
										},
										{ key: BULK_CHECK_IN, label: "Bulk check in", disabled: search.orderStatus === "cancelled" },
										{ key: BULK_CHECK_OUT, label: "Bulk check out", disabled: search.orderStatus === "cancelled" }
									],
									onClick: ({ key }) => this.handleMenuSelect(key)
								}}
								trigger={["click"]}
								placement="bottomRight"
							>
								<IconButton icon="see_more" ariaLabel="Actions" type="background" dataCy="event-actions" />
							</Dropdown>
						}
					/>
					<LoadErrorView loading={loading} error={error} retryAction={() => getReport("attendee")}>
						<Table
							columns={displayColumns.map((col) => {
								if (col.title === "Custom tax") {
									return { ...col, title: currentEvent?.event?.paymentOptions?.customSalesTaxSettings?.name };
								}
								return col;
							})}
							dataSource={results}
							loading={loading}
							locale={messages}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={false}
						/>
						<SimplePagination pageAction={this.loadPage} currentPage={page} />
					</LoadErrorView>
				</ContentBlock>
				<ExportBar buttonText="Download Report" buttonClick={this.openDownloadModal} />
				<Modal
					title={this.state.photoModalTitle}
					open={this.state.showPhotoModal}
					onOk={() => this.openPhotoModal("")}
					onCancel={() => this.openPhotoModal("")}
				>
					<img src={this.state.photoLink} style={{ width: "100%" }} alt="img" />
				</Modal>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			reportState: state.globalReportState,
			checkIn: state.checkIn,
			search: state.search
		};
	},
	{
		getReport,
		getReportCsv,
		getReportPdf,
		updateSearch,
		getCheckInCount,
		updateCheckIn,
		bulkCheckIn
	}
)(Attendees);
