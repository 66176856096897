import { Checkbox, Modal } from "@/ui/antd";
import { Component } from "react";
import RichTextDisplay from "../richTextArea/RichTextDisplay";
import InputWrapper from "./helpers/InputWrapper";

class TermsAndConditionsField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}
	openModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};
	render() {
		const {
			input,
			label,
			description,
			inline,
			noPadding,
			required,
			type,
			style,
			disabled,
			defaultChecked,
			labelAfter,
			noMargin,
			tooltip,
			tooltipIcon = false,
			tooltipIconOnClick,
			settings,
			meta: { touched, error }
		} = this.props;
		let { inputType } = this.props;
		inputType = inputType ? inputType : type;
		let modalContents = "";
		if (inputType === "text") {
			modalContents = settings ? <RichTextDisplay html={settings.text} /> : null;
		} else {
			modalContents = settings ? (
				<embed
					src={`https://process.filestackapi.com/${settings.pdf}`}
					width="100%"
					height="480"
					type="application/pdf"
				/>
			) : null;
		}
		input.value = input.value === "true" ? true : input.value === "false" ? false : input.value;
		return (
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				noPadding={noPadding}
				id={input.name}
				inline={inline}
				required={required}
				labelAfter={labelAfter}
				noMargin={noMargin}
				tooltip={tooltip}
				tooltipIcon={tooltipIcon}
				tooltipIconOnClick={tooltipIconOnClick}
			>
				<Checkbox
					id={input.name}
					{...input}
					checked={input.value}
					style={style}
					disabled={disabled}
					defaultChecked={defaultChecked}
				>
					I agree to{" "}
					{inputType === "url" && settings && settings.link ? (
						<a href={settings.link} target="_blank" rel="noopener noreferrer">
							Terms & Conditions
						</a>
					) : (
						<a href="/" onClick={this.openModal}>
							Terms & Conditions
						</a>
					)}{" "}
					of (host/event name)
				</Checkbox>
				<Modal open={this.state.showModal} onOk={this.openModal} onCancel={this.openModal} title={label}>
					{modalContents}
				</Modal>
			</InputWrapper>
		);
	}
}

export default TermsAndConditionsField;
