import { Col, Row } from "@/ui/antd";
import { Field } from "redux-form";
import { ColourField, ImageUploadField, RadioField } from "@/components/Fields";
import MiniPreview from "./MiniPreview";

import { getLocalisedText, sentenceCase } from "@/utils/Format";
import { required } from "@/utils/Validators";

const colourText = getLocalisedText("colour");
const footerColorRequired = required(`A footer ${colourText} is required`);
const backgroundStyleRequired = required("A background style is required");
const backgroundColourRequired = required(`A background ${colourText} is required`);
const backgroundImageRequired = required("A background image is required");
/**
 * Provides custom theme setup for users.
 *
 * @param {object} props
 * @returns custom theme options
 */
const Custom = (props) => {
	const { theme, updateFooterTextColor, updateBackgroundTextColor } = props;

	const RadioOptions = [
		{
			value: "image",
			label: "Image"
		},
		{
			value: "color",
			label: sentenceCase(colourText)
		},
		{
			value: "pattern",
			label: "Pattern"
		}
	];

	return (
		<div>
			<Row>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<div style={{ paddingBottom: 220 }}>
						<Field
							label={`Footer ${colourText}`}
							description={`Footer ${colourText}s change the Footer ${colourText}`}
							name="theme.footerColor"
							component={ColourField}
							marginTop={16}
							onChange={updateFooterTextColor}
							validate={footerColorRequired}
						/>
						<Field
							label="Background Style"
							description="Background style won't appear on mobile layout."
							name="theme.background.type"
							component={RadioField}
							button
							options={RadioOptions}
							validate={backgroundStyleRequired}
						/>
						{theme && theme.background && (theme.background.type === "image" || theme.background.type === "pattern") ? (
							<Field
								description="Choose image to"
								name="theme.background.image"
								uploadDescription="Used for background image"
								component={ImageUploadField}
								aspectRatio={2}
								validate={backgroundImageRequired}
							/>
						) : (
							<Field
								description={`Choose the ${colourText}`}
								name="theme.background.color"
								component={ColourField}
								onChange={updateBackgroundTextColor}
								validate={backgroundColourRequired}
							/>
						)}
					</div>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<MiniPreview {...props} />
				</Col>
			</Row>
		</div>
	);
};

export default Custom;
