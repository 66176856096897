import { trpc } from "@/trpc";
import { Event } from "@hx/console";
import { useEffect, useState } from "react";

export type EventProps = { label: string; value: string };

type UseEventsProps = (
	initialEventIds?: string[],
	options?: {
		fields?: string[];
		filters?: Partial<Event>;
		conditions?: {
			startDateIsAfter?: string;
		};
	}
) => {
	handleChange: (values: string[]) => void;
	noContent: string;
	options: EventProps[];
	reset: () => void;
	search: (term: string, limit?: null | number, force?: boolean) => void;
	selectedEvents: EventProps[];
};

export const useEvents: UseEventsProps = (
	initialEventIds: string[] = [],
	options = { fields: [], filters: {}, conditions: {} }
) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedEvents, setSelectedEvents] = useState<EventProps[]>([]);
	const [initialEvents, setInitialEvents] = useState<EventProps[]>([]);
	const [filteredEvents, setFilteredEvents] = useState<EventProps[]>([]);
	const [defaultLimit, setDefaultLimit] = useState(10);
	const [pendingSearch, setPendingSearch] = useState(false); // Use this pattern so no race conditions occur

	const searchEventsQuery = trpc.events.searchEvents.useQuery(
		{
			conditions: options?.conditions ?? undefined,
			fields: options?.fields ?? ["name"],
			filters: options?.filters ?? {},
			pageSize: defaultLimit,
			searchTerm: searchTerm.length > 1 ? searchTerm : ""
		},
		{ enabled: true }
	);

	useEffect(() => {
		if (searchEventsQuery.data) {
			const events = searchEventsQuery.data.map((event) => ({ label: event.name, value: event._id }));
			setFilteredEvents(events);
		}
	}, [searchEventsQuery.data]);

	const getEventsQuery = trpc.events.getEventsByIds.useQuery(
		{
			ids: initialEventIds,
			options: {
				fields: options?.fields ?? [],
				filters: options?.filters ?? {}
			}
		},
		{ enabled: initialEventIds.length > 0 }
	);

	useEffect(() => {
		if (getEventsQuery.data) {
			const events = getEventsQuery.data.map((event) => ({ label: event.name, value: event._id }));
			setInitialEvents(events);
			setSelectedEvents(events);
		}
	}, [getEventsQuery.data]);

	useEffect(() => {
		if (pendingSearch) {
			searchEventsQuery.refetch().finally(() => {
				setPendingSearch(false);
			});
		}
	}, [pendingSearch]);

	const handleSearchEvents = (term: string, limit?: null | number, force = false) => {
		setSearchTerm(term);
		setDefaultLimit(limit ?? defaultLimit);
		if (force || term.length > 1) {
			setPendingSearch(true);
		}
	};

	const addSelectedEvent = (events: EventProps) => setSelectedEvents((prev) => [...prev, events]);

	const removeSelectedEvent = (eventId: string) =>
		setSelectedEvents((prev) => prev.filter((event) => event.value !== eventId));

	const handleEventChange = async (values: string[]) => {
		const existingEventsToAdd = filteredEvents.filter((event) => values.includes(event.value));
		existingEventsToAdd.forEach((event) => addSelectedEvent(event));

		const removedEvents = selectedEvents.filter((selectedEvent) => !values.includes(selectedEvent.value));
		removedEvents.forEach((event) => removeSelectedEvent(event.value));

		// Research
		handleSearchEvents("", null, true);
	};

	const handleReset = () => {
		setSearchTerm("");
		setSelectedEvents([]);
		setInitialEvents([]);
		setFilteredEvents([]);
	};

	const combinedEvents: EventProps[] = [...selectedEvents, ...filteredEvents, ...initialEvents].filter(
		(event, index, self) => index === self.findIndex((t) => t.value === event.value)
	);

	const noContent = searchTerm.length > 0 ? "No results" : "Start typing to search";

	return {
		handleChange: handleEventChange,
		noContent,
		options: combinedEvents,
		reset: handleReset,
		search: handleSearchEvents,
		selectedEvents
	};
};
