import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import BodyElementsModal from "./BodyElementsModal";
import Empty from "./Empty";
import Presenters from "./elements/Presenters";
import Text from "./elements/Text";
import Video from "./elements/Video";

const { confirm } = Modal;

class Body extends Component {
	constructor(props) {
		super(props);
		this.state = { elementPickerOpen: false };
	}

	elementSelected = (element) => {
		const { fields } = this.props;
		element.key = Math.random();
		fields.push(element);
		this.closePicker();
	};

	openPicker = () => {
		this.setState({ elementPickerOpen: true });
	};

	closePicker = () => {
		this.setState({ elementPickerOpen: false });
	};

	move = (fromIndex, toIndex) => {
		const { fields, body, changeFieldValue } = this.props;
		if (toIndex < 0 || toIndex > fields.length - 1) {
			return false;
		}
		const fromData = body[fromIndex];
		const toData = body[toIndex];
		changeFieldValue(`${fields.name}[${toIndex}]`, { ...fromData });
		changeFieldValue(`${fields.name}[${fromIndex}]`, { ...toData });
	};

	deleteElement = (element, index) => {
		const { fields } = this.props;
		confirm({
			title: `Are you sure you would like to delete ${element.name}`,
			onOk: () => {
				fields.remove(index);
			},
			onCancel() {
				null;
			}
		});
	};

	render() {
		const { fields, body, changeFieldValue } = this.props;
		const { elementPickerOpen } = this.state;
		return (
			<>
				{fields.map((fieldPath, index) => {
					const element = body[index];
					const props = {
						key: element._id || element.key,
						fieldPath,
						element,
						index,
						move: this.move,
						deleteElement: this.deleteElement,
						totalElements: fields.length,
						changeFieldValue
					};
					switch (element.inputType) {
						case "text":
							return <Text {...props} />;
						case "presenters":
							return <Presenters {...props} />;
						default:
							return <Video {...props} />;
					}
				})}
				<Empty visible={!fields.length} />
				<ButtonsBar label="" backgroundColor>
					<LegacyButton
						icon={{ left: true, name: "plus" }}
						type="action"
						onClick={this.openPicker}
						ariaLabel="Add Element"
					>
						Add Element
					</LegacyButton>
				</ButtonsBar>
				<BodyElementsModal
					visible={elementPickerOpen}
					closePicker={this.closePicker}
					elementSelected={this.elementSelected}
				/>
			</>
		);
	}
}

const selector = formValueSelector("virtualEventsHub");

export default connect(
	(state) => ({
		body: selector(state, "body")
	}),
	{}
)(Body);
