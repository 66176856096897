import { ContentBlock } from "@/components/AppBlocks";
import { H1, P } from "@/components/text/Text";
import EventListing from "@/events/event/widgets/legacy-widgets/EventListing";
import EventSearch from "@/events/event/widgets/legacy-widgets/EventSearch";
import { Alert, Tabs } from "@/ui/antd";

export const LegacyWidgetsSection = () => {
	return (
		<div>
			<H1>Embedded widgets (Update coming soon)</H1>
			<Alert
				message={
					<>
						We’re in the process of updating our ‘multi-event’ widgets. In the meantime, you can still use the widgets
						below.
					</>
				}
				showIcon
				type="info"
			/>
			<ContentBlock>
				<P>
					Embed our widgets to your website and give your attendees ability to directly book tickets from your website.
					Easily copy and paste provided code to your website and start selling tickets right away.
				</P>
				<Tabs
					items={[
						{ children: <EventListing />, key: "eventListing", label: "Event Listing" },
						{ children: <EventSearch />, key: "customSearch", label: "Custom Search" }
					]}
				/>
			</ContentBlock>
		</div>
	);
};
