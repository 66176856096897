import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { Field } from "redux-form";
import { ImageUploadField } from "@/components/Fields";

const BannerImage = () => {
	return (
		<Field
			name="bannerImage"
			component={ImageUploadField}
			description={
				<>
					Use an image that is a 2:1 ratio, min 1000px by 500px, max 10mb. Supported files: JPG, PNG & SVG.{" "}
					<a onClick={OpenHelpHandler(HelpIds.images)}>Learn more</a>
				</>
			}
			aspectRatio={2}
		/>
	);
};

export default BannerImage;
