import H2 from "@/components/text/H2";

import React from "react";
import { CartesianGrid, Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
const LineChart = ({ title, dataKey, data }: any) => {
	return (
		<>
			<H2>{title}</H2>
			<ResponsiveContainer width="100%" height={400}>
				<AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
					<defs>
						<linearGradient id="fade" x1="0" y1="0" x2="0" y2="1">
							<stop offset="50%" stopColor="#ffa179" stopOpacity={1} />
							<stop offset="100%" stopColor="#ffa179" stopOpacity={0.3} />
						</linearGradient>
					</defs>
					<XAxis dataKey={dataKey} />
					<YAxis allowDecimals={false} />
					<CartesianGrid strokeDasharray="3 3" />
					<Tooltip />
					<Area type="monotone" dataKey="total" stroke="#ffa179" strokeWidth={2} fillOpacity={1} fill="url(#fade)" />
				</AreaChart>
			</ResponsiveContainer>
		</>
	);
};

export default LineChart;
