import ReportService from "../../services/ReportService";
import FileDownload from "../../utils/FileDownload";
import Filename from "../../utils/Filename";

export const GET_DISCOUNT_REPORTS_REQUEST = "GET_DISCOUNT_REPORTS_REQUEST";
export const GET_DISCOUNT_REPORTS_SUCCESS = "GET_DISCOUNT_REPORTS_SUCCESS";
export const GET_DISCOUNT_REPORTS_ERROR = "GET_DISCOUNT_REPORTS_ERROR";

export const GET_AFFILIATE_REPORTS_REQUEST = "GET_AFFILIATE_REPORTS_REQUEST";
export const GET_AFFILIATE_REPORTS_SUCCESS = "GET_AFFILIATE_REPORTS_SUCCESS";
export const GET_AFFILIATE_REPORTS_ERROR = "GET_AFFILIATE_REPORTS_ERROR";

export const GET_AFFILIATE_CODE_CSV_REQUEST = "GET_AFFILIATE_CODE_CSV_REQUEST";
export const GET_AFFILIATE_CODE_CSV_SUCCESS = "GET_AFFILIATE_CODE_CSV_SUCCESS";
export const GET_AFFILIATE_CODE_CSV_ERROR = "GET_AFFILIATE_CODE_CSV_ERROR";

export const GET_DISCOUNT_CODE_CSV_REQUEST = "GET_DISCOUNT_CODE_CSV_REQUEST";
export const GET_DISCOUNT_CODE_CSV_SUCCESS = "GET_DISCOUNT_CODE_CSV_SUCCESS";
export const GET_DISCOUNT_CODE_CSV_ERROR = "GET_DISCOUNT_CODE_CSV_ERROR";

export const getDiscountReport = (id) => {
	return (dispatch) => {
		discountReport(dispatch, id);
	};
};

const discountReport = (dispatch, id) => {
	dispatch({ type: GET_DISCOUNT_REPORTS_REQUEST });
	ReportService.discount(id)
		.then((data) => {
			dispatch({ type: GET_DISCOUNT_REPORTS_SUCCESS, data });
		})
		.catch((err) => {
			dispatch({ type: GET_DISCOUNT_REPORTS_ERROR, error: err });
		});
};

export const getAffiliateReport = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_AFFILIATE_REPORTS_REQUEST });
		ReportService.affiliateCodes(id)
			.then((data) => {
				dispatch({ type: GET_AFFILIATE_REPORTS_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GET_AFFILIATE_REPORTS_ERROR, error: err });
			});
	};
};

export const getAffiliateCodesCsv = (eventId, event) => {
	const filename = Filename.generateFileName("AffiliateCodesOrders", ".csv", event);
	return (dispatch) => {
		dispatch({ type: GET_AFFILIATE_CODE_CSV_REQUEST });
		ReportService.getAffiliateCodesCsv(eventId).then((data) => {
			dispatch({ type: GET_AFFILIATE_CODE_CSV_SUCCESS });
			FileDownload.saveAs(data, filename);
		});
	};
};

export const getDiscountCodesCsv = (eventId, event) => {
	const filename = Filename.generateFileName("DiscountCodesOrders", ".csv", event);
	return (dispatch) => {
		dispatch({ type: GET_DISCOUNT_CODE_CSV_REQUEST });
		ReportService.getDiscountCodesCsv(eventId).then((data) => {
			dispatch({ type: GET_DISCOUNT_CODE_CSV_SUCCESS });
			FileDownload.saveAs(data, filename);
		});
	};
};
