import { Label } from "@/components/text/Text";
import { dayjs } from "@/libs/dayjs";

const TicketsSoldList = ({ dates }) => {
	return (
		<>
			<Label>Occurrences with tickets sold:</Label>
			{dates.map(({ startDate, endDate }) => {
				return <Label>{`${dayjs(startDate).format("ddd Do MMM YYYY, LT")} - ${dayjs(endDate).format("LT")}`}</Label>;
			})}
		</>
	);
};

export default TicketsSoldList;
