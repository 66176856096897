import {
	LOAD_CANVA_ERROR,
	LOAD_CANVA_REQUEST,
	LOAD_CANVA_SUCCESS,
	UPLOAD_IMG_ERROR,
	UPLOAD_IMG_REQUEST,
	UPLOAD_IMG_SUCCESS
} from "@/state/actions/canvaActions";

export type CanvaReducerState = {
	script: {
		loaded: boolean;
		loading: boolean;
		error: boolean;
		api: any;
	};
	imageUploads: any;
};

const initialState: CanvaReducerState = {
	script: {
		loaded: false,
		loading: false,
		error: false,
		api: null
	},
	imageUploads: {}
};

let imageUploads;

type CanvaReducerAction = {
	type:
		| typeof LOAD_CANVA_REQUEST
		| typeof LOAD_CANVA_SUCCESS
		| typeof LOAD_CANVA_ERROR
		| typeof UPLOAD_IMG_REQUEST
		| typeof UPLOAD_IMG_SUCCESS
		| typeof UPLOAD_IMG_ERROR;
	canvaId?: string;
	imageUrl?: string;
	image?: any;
	designId?: string;
};

export default (state = initialState, action: CanvaReducerAction) => {
	switch (action.type) {
		case LOAD_CANVA_REQUEST:
			return {
				...state,
				script: {
					loaded: false,
					loading: true,
					error: false
				}
			};
		case LOAD_CANVA_SUCCESS:
			return {
				...state,
				script: {
					loaded: true,
					loading: false,
					error: false
				}
			};
		case LOAD_CANVA_ERROR:
			return {
				...state,
				script: {
					loaded: false,
					loading: false,
					error: true
				}
			};
		case UPLOAD_IMG_REQUEST:
			imageUploads = {
				...state.imageUploads
			};
			if (action.canvaId) {
				imageUploads[action.canvaId] = {
					loading: true
				};
			}
			return {
				...state,
				imageUploads
			};
		case UPLOAD_IMG_SUCCESS:
			imageUploads = {
				...state.imageUploads
			};
			if (action.canvaId) {
				imageUploads[action.canvaId] = {
					load: true,
					imageUrl: action.imageUrl,
					image: action.image,
					designId: action.designId
				};
			}
			return {
				...state,
				imageUploads
			};
		case UPLOAD_IMG_ERROR:
			imageUploads = {
				...state.imageUploads
			};

			if (action.canvaId) {
				imageUploads[action.canvaId] = {
					loading: true
				};
			}
			return {
				...state,
				imageUploads
			};
		default:
			return state;
	}
};
