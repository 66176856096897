import { dayjs } from "@/libs/dayjs";

const occurrenceStringMap = {
	0: "first",
	1: "second",
	2: "third",
	3: "forth",
	4: "fifth"
};

const dateNumToStringOccurrence = (date) => {
	const datObj = dayjs(date);
	const dayNum = datObj.date();
	const occurrences = Math.floor((dayNum - 1) / 7);
	return occurrenceStringMap[occurrences];
};

const daysOfWeek = [
	{
		label: "S",
		value: "Sunday",
		tooltip: "Sunday"
	},
	{
		label: "M",
		value: "Monday",
		tooltip: "Monday"
	},
	{
		label: "T",
		value: "Tuesday",
		tooltip: "Tuesday"
	},
	{
		label: "W",
		value: "Wednesday",
		tooltip: "Wednesday"
	},
	{
		label: "T",
		value: "Thursday",
		tooltip: "Thursday"
	},
	{
		label: "F",
		value: "Friday",
		tooltip: "Friday"
	},
	{
		label: "S",
		value: "Saturday",
		tooltip: "Saturday"
	}
];

const futureDateRequired = (value) => {
	const now = dayjs();
	const date = dayjs(value);
	if (now.isAfter(date)) {
		return "Date must be in the future";
	}
	return undefined;
};

const checkStartDate = (touch) => () => {
	touch(`endDate`);
	return undefined;
};

const checkEndDate = (endDate, allValues) => {
	const startDate = dayjs(allValues.startDate);
	const result = dayjs(endDate).isBefore(startDate) ? `Must be after your start date` : undefined;
	return result;
};

export { checkEndDate, checkStartDate, dateNumToStringOccurrence, daysOfWeek, futureDateRequired };
