import { css } from "@emotion/css";
import * as React from "react";

const toolTipStyling: any = css({
	visibility: "hidden",
	width: "auto",
	whiteSpace: "nowrap",
	backgroundColor: "#4e4757",
	fontSize: 12,
	fontWeight: 600,
	textAlign: "center",
	top: "-38px",
	color: "#fff",
	borderRadius: "4px",
	padding: "8px",
	position: "absolute",
	zIndex: 1
});

const toolTipTail = css({
	border: "solid",
	borderColor: "#4e4757 transparent",
	borderWidth: "6px 6px 0 6px",
	bottom: "-6px",
	left: "50%",
	WebkitTransform: "translateX(-50%)",
	MozTransform: "translateX(-50%)",
	msTransform: "translateX(-50%)",
	OTransform: "translateX(-50%)",
	transform: "translateX(-50%)",
	position: "absolute"
});
const toolTipWrapper = css({
	"&:hover": {
		[`.${toolTipStyling}`]: {
			visibility: "visible",
			left: " 50%",
			["WebkitTransform"]: "translateX(-50%)",
			["MozTransform"]: "translateX(-50%)",
			["msTransform"]: "translateX(-50%)",
			["OTransform"]: "translateX(-50%)",
			transform: "translateX(-50%)",
			position: "absolute"
		}
	}
});

const ToolTip = ({
	children,
	message,
	disableTooltip = false
}: {
	children?: React.ReactNode;
	message: string;
	disableTooltip?: boolean;
}) => {
	if (disableTooltip) {
		return <div>{children}</div>;
	}
	return (
		<div className={toolTipWrapper}>
			<div className={toolTipStyling}>
				{message}
				<div className={toolTipTail} />
			</div>
			{children}
		</div>
	);
};

export default ToolTip;
