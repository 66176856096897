import { Modal } from "@/ui/antd";
import { useState } from "react";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import RichTextDisplay from "@/components/richTextArea/RichTextDisplay";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import AddMessageModal from "./AddMessageModal";
const { confirm } = Modal;

const TicketMessagesTable = ({ currentEvent, ticketMessages, fields, changeFieldValue }) => {
	const [{ addModalOpen, editingIndex }, updateModals] = useState({
		editingIndex: null,
		addModalOpen: false
	});

	const saveMessage = (values, index) => {
		if (index !== null && index >= 0) {
			changeFieldValue(`${fields.name}.${index}`, values);
		} else {
			fields.push(values);
		}

		updateModals({ addModalOpen: false, editingIndex: null });
	};

	const deleteMessage = (index) => {
		console.log("deleteMessage", { index });
		confirm({
			title: `Are you sure you would like to delete`,
			onOk: () => {
				fields.remove(index);
			}
		});
	};

	const editMessage = (editingIndex) => {
		updateModals({ addModalOpen: !addModalOpen, editingIndex });
	};

	const addMessage = () => {
		const num = ticketMessages ? ticketMessages.length : 0;
		updateModals({ addModalOpen: !addModalOpen, editingIndex: -(num + 1) });
	};

	const closeAddModal = () => {
		updateModals({ addModalOpen: !addModalOpen, editingIndex: null });
	};
	const packagedTickets = currentEvent.event.packagedTickets || [];
	const tickets = currentEvent.event.ticketTypes.concat(packagedTickets);
	const ticketNameLookUp = tickets.reduce((lookUp, ticket) => {
		lookUp[ticket._id] = ticket.name;
		return lookUp;
	}, {});

	const columns = [
		{
			title: "Ticket types",
			dataIndex: "ticketTypeIds",
			key: "ticketTypeIds",
			render: (ticketTypeIds) => {
				return ticketTypeIds
					.map((id) => {
						return ticketNameLookUp[id];
					})
					.join(", ");
			}
		},
		{
			title: "Message",
			dataIndex: "message",
			key: "message",
			render: (message) => <RichTextDisplay html={message} />
		},
		{
			title: "Actions",
			dataIndex: "",
			key: "actions",
			render: (_, __, index) => (
				<TableActions>
					<IconButton icon="edit" onClick={() => editMessage(index)} tooltip="Edit" showTooltip ariaLabel="Edit" />

					<IconButton
						icon="delete"
						onClick={() => deleteMessage(index)}
						tooltip="Delete"
						showTooltip
						ariaLabel="Delete"
					/>
				</TableActions>
			)
		}
	];
	const btnProps = {
		onClick: () => addMessage(),
		icon: { name: "plus", left: true },
		type: "action",
		ariaLabel: "Add message"
	};

	const modalProps = {
		visible: addModalOpen,
		currentEvent,
		ticketMessages,
		handleCancel: closeAddModal,
		editingIndex,
		handleSave: saveMessage
	};

	if (!ticketMessages || !ticketMessages.length) {
		return (
			<>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginBottom: 12
					}}
				>
					<p style={{ margin: 10 }}>Add custom messages by ticket type </p>
					<LegacyButton {...btnProps}>Add message</LegacyButton>
				</div>
				<AddMessageModal {...modalProps} />
			</>
		);
	}

	return (
		<div style={{ width: "100%", overflowX: "auto" }}>
			<ResponsiveTable
				pagination={false}
				columns={columns}
				dataSource={ticketMessages}
				locale={{ emptyText: "No messages created" }}
				rowKey="key"
			/>

			<ButtonsBar>
				<LegacyButton {...btnProps}>Add message</LegacyButton>
			</ButtonsBar>
			<AddMessageModal {...modalProps} />
		</div>
	);
};

export default TicketMessagesTable;
