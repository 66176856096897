import { MenuItem } from "@/services/menuService/MenuService";

export const homeMenu = (): MenuItem[] => {
	return [
		{
			label: "Home",
			path: "/console/dashboard",
			key: "home"
		}
	];
};
