import { Modal } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Icon from "@/components/Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { Label } from "@/components/text/Text";
import TicketsSoldList from "./components/TicketsSoldList";

import { resetResult } from "@/state/actions/schedulerActions";

let DeleteResultModal = (props) => {
	const [modalState, setModalState] = useState({ open: false });

	useEffect(() => {
		const open = !!(props.deleteResult.numberDeleted > 0 || props.deleteResult.unsuccessfulDates.length);
		setModalState(() => ({ open }));
	}, [props.deleteResult]);

	const { numberDeleted, unsuccessfulDates } = props.deleteResult;
	const unsuccessfulCount = unsuccessfulDates.length;

	const onClose = () => {
		setModalState({ ...modalState, open: false });
		props.resetResult();
	};
	return (
		<Modal
			open={modalState.open}
			destroyOnClose
			onCancel={onClose}
			// onOk={onClose}
			footer={
				<>
					<LegacyButton
						style={{
							float: "right"
						}}
						key="cancel"
						onClick={onClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Got it!
					</LegacyButton>
				</>
			}
		>
			<div
				style={{
					display: numberDeleted > 0 ? "flex" : "none",
					alignItems: "center"
				}}
			>
				<Icon style={{ marginRight: 8 }} icon="success-circle" />
				<Label bold={false}>{`${numberDeleted} occurrences deleted successfully`}</Label>
			</div>

			<div
				style={{
					display: unsuccessfulCount > 0 ? "flex" : "none",
					alignItems: "center"
				}}
			>
				<Icon style={{ marginRight: 8 }} icon="warning_red" />
				<Label bold={false}>{`${unsuccessfulCount} occurrences with tickets sold couldn’t be deleted`}</Label>
			</div>
			<div
				style={{
					display: unsuccessfulCount > 0 ? "inherit" : "none",
					marginTop: 24
				}}
			>
				<TicketsSoldList dates={unsuccessfulDates} />
			</div>
		</Modal>
	);
};

export default DeleteResultModal = connect(
	(state) => {
		return {
			deleteResult: state.scheduler.deleteResult
		};
	},
	{ resetResult }
)(DeleteResultModal);
