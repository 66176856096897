import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import { SelectField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getTopPurchasers,
	getTopPurchasersCsv,
	resetTopPurchasers
} from "@/state/actions/adminReports/attendeesActions";
import { currency } from "@/utils/Format";
import DatesSelector from "../DatesSelector";

class TopPurchasers extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getTopPurchasers } = this.props;
		getTopPurchasers(values.startDate, values.endDate, values.sortOn, 1);
	}

	loadPage(page) {
		const { getTopPurchasers, startDate, endDate, sortOn } = this.props;
		getTopPurchasers(startDate, endDate, sortOn, page);
	}

	retry() {
		const { resetTopPurchasers } = this.props;
		resetTopPurchasers();
	}

	downloadCsv() {
		const { startDate, endDate, sortOn, getTopPurchasersCsv } = this.props;
		if (!startDate || !endDate) {
			return;
		}
		getTopPurchasersCsv(startDate, endDate, sortOn);
	}
	componentDidUpdate(preProps) {
		const props = ["startDate", "endDate", "sortOn"];
		const changed = props.reduce((changed, prop) => {
			if (this.props[prop] !== preProps[prop]) {
				return true;
			}
			return changed;
		}, false);
		if (changed) {
			this.runLoad();
		}
	}

	runLoad = () => {
		const { startDate, endDate, sortOn } = this.props;
		this.load({ startDate, endDate, sortOn });
	};

	render() {
		const { touch, handleSubmit, topPurchasers } = this.props;
		const columns = [
			{
				title: "Email",
				dataIndex: "_id",
				key: "_id",
				render: (email) => <Link to={`/console/reports/orders?page=1&query=${email}`}>{email}</Link>
			},
			{
				title: "Orders",
				dataIndex: "totalOrders",
				key: "totalOrders"
			},
			{
				title: "Net Sales",
				dataIndex: "netSales",
				key: "netSales",
				render: (value) => currency(value)
			},
			{
				title: "Donations",
				dataIndex: "donations",
				key: "donations",
				render: (value) => currency(value)
			}
		];
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Top purchasers">
					<LocationSelection componentName="adminReportAttendees" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<div>
					<LoadErrorView loading={topPurchasers.loading} error={topPurchasers.error} retryAction={this.retry}>
						<ReportFilters>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div style={{ display: "flex" }}>
									<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								</div>

								<div style={{ width: 60 }}>
									{topPurchasers.data ? (
										<IconButton
											icon="export"
											type="background"
											showTooltip
											tooltip="Export"
											onClick={this.downloadCsv}
											ariaLabel="Export"
										/>
									) : null}
								</div>
							</div>
							<Field
								label="Sort On"
								name="sortOn"
								id="sortOn"
								component={SelectField}
								options={[
									{ label: "Tickets Purchased", value: "tickets" },
									{ label: "Net Sales", value: "netSales" },
									{ label: "Donations", value: "donations" }
								]}
							/>
						</ReportFilters>
						<ReportContent>
							{topPurchasers.data ? (
								<div>
									<Table
										columns={columns}
										dataSource={topPurchasers.data}
										rowKey="_id"
										scroll={{ x: 800 }}
										pagination={{
											showSizeChanger: false,
											pageSize: 30,
											total: topPurchasers.count,
											current: topPurchasers.page,
											onChange: (page) => this.loadPage(page)
										}}
									/>
									<ExportBar buttonText="Export" buttonClick={this.downloadCsv} />
								</div>
							) : null}
						</ReportContent>
					</LoadErrorView>
				</div>
			</form>
		);
	}
}
const formName = "topPurchasers";
const selector = formValueSelector(formName);
TopPurchasers = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(TopPurchasers);

export default connect(
	(state) => ({
		initialValues: {
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate,
			sortOn: "tickets"
		},
		topPurchasers: state.adminReports.attendees.topPurchasers,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		sortOn: selector(state, "sortOn")
	}),
	{ getTopPurchasers, resetTopPurchasers, getTopPurchasersCsv }
)(TopPurchasers);
