import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import H2 from "@/components/text/H2";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { downloadPaymentReceipt } from "@/state/actions/newPayoutActions";
import { BankAccount, PaymentsWithDisplayDate } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { Table } from "@/ui/antd";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { ColumnType } from "antd/lib/table";
import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DeletePaymentModal from "../modals/DeletePaymentModal";

const strikethroughClass = css`
	text-decoration: line-through;
`;

const Payments = ({ payments, downloadPaymentReceipt, eventId }: IInjectedProps & IInjectedFunctions) => {
	const { isAdmin } = useCurrentUser();
	const columns = [
		{
			title: "Event date",
			key: "eventDate",
			dataIndex: "eventDate",
			fixed: "left"
		},
		{
			title: "Payout reference",
			key: "payoutReference",
			dataIndex: "payoutReference"
		},
		{
			title: "Transaction id",
			key: "transactionId",
			dataIndex: "transactionId",
			admin: true
		},
		{
			title: "Amount",
			key: "amount",
			dataIndex: "amount",
			render: (value: number) => currency(value)
		},
		{
			title: "Paid by",
			key: "userName",
			dataIndex: "userName",
			render: (value: string, record: PaymentsWithDisplayDate) => {
				switch (record.processedType) {
					case "auto-self-serve":
						return "Self Serve(Auto)";
					case "auto":
						return "Auto payout";
				}

				if (!isAdmin) {
					switch (record.processedType) {
						case "manual":
							return "Humanitix";
						case "self-serve":
							return value;
						default:
							"Humanitix";
					}
				}

				return <Link to={`/console/admin/users/${record.userId}`}>{value}</Link>;
			}
		},
		{
			title: "Paid",
			key: "paid",
			dataIndex: "paid",
			render: (value: boolean) => `${value}`,
			admin: true
		},
		{
			title: "Mass payment",
			key: "awaitingMassPayment",
			dataIndex: "awaitingMassPayment",
			render: (_value: boolean, payment: PaymentsWithDisplayDate) => {
				if (payment.massPaymentId)
					return <Link to={`/console/admin/mass-payout/${payment.massPaymentId}`}>{payment.massPaymentId}</Link>;
				if (payment.transactionId) return "NA";
				return "awaiting";
			},
			admin: true
		},
		{
			title: "Date paid",
			key: "date",
			dataIndex: "createdAt",
			render: (value: string) => new HumaniDate(value, "guess").formatting.dateTime()
		},
		{
			title: "Date cleared",
			key: "clearedAt",
			dataIndex: "clearedAt",
			render: (value: string) => value && new HumaniDate(value, "guess").formatting.dateTime()
		},
		{
			title: "Reversed",
			key: "reversedAt",
			dataIndex: "reversedAt",
			render: (value?: string) => value && new HumaniDate(value, "guess").formatting.dateTime()
		},
		{
			title: "Paid to account",
			key: "bankAccount",
			dataIndex: "bankAccount",
			render: (value: BankAccount) => value?.anonymisedAccount?.join(" ")
		},
		{
			title: "Notes",
			key: "notes",
			dataIndex: "notes",
			admin: true
		},
		{
			title: "Actions",
			key: "actions",
			fixed: "right",
			render: (payment: PaymentsWithDisplayDate) => {
				if (payment.removed) return null;
				return (
					<>
						{isAdmin && (
							<IconButton
								icon="delete"
								tooltip="Delete"
								showTooltip
								ariaLabel="Delete"
								onClick={() => onModalOpen(payment._id, "delete")}
							/>
						)}

						<IconButton
							icon="export"
							tooltip="Download receipt"
							showTooltip
							ariaLabel="Download"
							onClick={() => downloadPaymentReceipt(eventId!, payment)}
						/>
					</>
				);
			}
		}
	];

	const [focusedPaymentId, setFocusedPaymentId] = useState<string | null>(null);

	const [openedModelName, setOpenModelName] = useState("none");
	const onModalClose = () => {
		setFocusedPaymentId(null);
		setOpenModelName("none");
	};
	const onModalOpen = (focusedPaymentId: string, modalName: string) => {
		setFocusedPaymentId(focusedPaymentId);
		setOpenModelName(modalName);
	};
	return (
		<>
			<DeletePaymentModal isOpen={openedModelName === "delete"} close={onModalClose} paymentId={focusedPaymentId} />
			<ContentBlock>
				<H2>Payments</H2>
				<Table
					rowClassName={(row) => (row.removed ? strikethroughClass : "")}
					dataSource={payments?.sort((a, b) =>
						new HumaniDate(a.createdAt).toDate() > new HumaniDate(b.createdAt).toDate() ? -1 : 1
					)}
					columns={columns.filter((col) => isAdmin || !col.admin) as ColumnType<PaymentsWithDisplayDate>[]}
					scroll={{ x: "max-content" }}
				/>
			</ContentBlock>
		</>
	);
};

interface IInjectedProps {
	payments?: PaymentsWithDisplayDate[];
	eventId?: string;
}

interface IInjectedFunctions {
	downloadPaymentReceipt: (
		eventId: string,
		payment: { _id: string; eventDateId: string; payoutReference: string }
	) => void;
}

export default connect(
	(state: ReduxState): IInjectedProps => {
		const payments = state.payouts.eventPayout.payoutInformation?.payments;
		return {
			payments,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ downloadPaymentReceipt }
)(Payments);
