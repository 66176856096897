import { css } from "@emotion/css";
import { Alert, Input } from "@/ui/antd";
import copy from "copy-text-to-clipboard";
import { useState } from "react";
import CopyButton from "../CopyButton";
import IconButton from "../IconButton";
import { Label } from "../text/Text";
import InputWrapper from "./helpers/InputWrapper";

const Search = Input.Search;

const styles = {
	wrapper: css({
		marginBottom: 16
	}),
	urlFieldStyle: css({
		display: "flex"
	}),
	copyInput: css({
		display: "flex",
		width: "100%",
		background: "rgb(250, 250, 250)",
		border: "1px solid #8d949e",
		borderRight: "none",
		maxHeight: 36,
		borderRadius: 4
	}),
	urlLink: css({
		width: "100%",
		color: "#353337",
		height: "100%",
		overflow: "hidden",
		padding: "6px 10px",
		wordBreak: "break-all"
	}),

	searchAndEdit: css({ width: "100%", maxWidth: "100%", display: "flex" })
};

const CopyInputField = ({
	input,
	fullUrl,
	addonBefore,
	label,
	description,
	placeholder,
	inline,
	warning = "Updating your url may break any existing marketing links you have set up.",
	required,
	autoFocus,
	disabled,
	style,
	maxLength = 10000,
	size = "default",
	buttonLabel = "COPY",
	meta: { touched, error }
}) => {
	const [isEditable, toggleEdit] = useState(false);

	const EditBtn = () => (
		<IconButton icon="edit" onClick={() => toggleEdit(!isEditable)} style={styles.editBtn} ariaLabel="Edit url" />
	);

	if (!isEditable) {
		return (
			<div className={styles.wrapper}>
				<Label>{label}</Label>
				<div className={styles.urlFieldStyle}>
					<div className={styles.copyInput}>
						<a className={styles.urlLink} href={fullUrl} data-cy="url-link" target="_blank" rel="noopener noreferrer">
							{fullUrl}
						</a>
						<CopyButton
							buttonText="Copy"
							toCopy={fullUrl}
							type="secondary"
							style={{ borderRadius: "0 4px 4px 0", maxWidth: 73, marginTop: -1 }}
						/>
					</div>
					<EditBtn />
				</div>
			</div>
		);
	}

	return (
		<div className={styles.wrapper}>
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				id={input.name}
				inline={inline}
				required={required}
			>
				{warning ? (
					<Alert message="Warning" description={warning} type="warning" showIcon style={{ margin: "0 0 12px 0" }} />
				) : null}

				<div className={styles.searchAndEdit}>
					<Search
						id={input.name}
						addonBefore={addonBefore}
						{...input}
						placeholder={placeholder}
						style={style}
						disabled={disabled}
						size={size}
						maxLength={maxLength}
						autoFocus={autoFocus}
						enterButton={buttonLabel}
						onSearch={() => copy(fullUrl)}
					/>
					<EditBtn />
				</div>
			</InputWrapper>
		</div>
	);
};

export default CopyInputField;
