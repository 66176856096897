import { Checkbox } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { SeatingMap } from "@hx/seating-map";
import P from "@/components/text/P";
import { currency } from "../../../../utils/Format";
import H4 from "@/components/text/H4";
import { css } from "@emotion/css";
import { formValueSelector } from "redux-form";

class TicketMapping extends Component {
	removeTicketsSelected = (ticketId) => {
		const { performAction, elements } = this.props;
		performAction(
			elements.map((element) => element.removeTicketType),
			elements.map(() => [ticketId])
		);
	};

	addTicketsSelected = (ticketId) => {
		const { performAction, elements } = this.props;
		performAction(
			elements.map((element) => element.assignTicketType),
			elements.map(() => [ticketId])
		);
	};

	onTicketSelected = (ticketId, selected) => {
		if (selected) {
			this.removeTicketsSelected(ticketId);
		} else {
			this.addTicketsSelected(ticketId);
		}
	};

	determineSelectedTickets = (elements) => {
		const ticketIds = elements[0].ticketIds;
		return ticketIds.filter((ticketId) => {
			return elements.reduce((included, element) => {
				return included && element.ticketIds.includes(ticketId);
			}, true);
		});
	};

	render() {
		const { elements, currentEvent, manageCapacityFormValues } = this.props;
		const usePackagedTickets = elements[0].bookByTable;
		const selectedTickets = this.determineSelectedTickets(elements);
		let ticketTypes = [];
		let title = "";
		if (usePackagedTickets) {
			ticketTypes = currentEvent.event.packagedTickets
				.filter((packagedTicket) => !packagedTicket.deleted && !packagedTicket.disabled)
				.map((pt) => {
					return { ...pt, isPackage: true };
				});
			title = "Packaged tickets";
		} else {
			ticketTypes = currentEvent.event.ticketTypes.filter(
				(ticketType) => !ticketType.isDonation && !ticketType.disabled && !ticketType.deleted
			);
			title = "Tickets";
		}
		return (
			<div style={{ overflow: "auto", paddingTop: 8 }}>
				<h3>{title}</h3>
				<div style={{ marginTop: 8 }}>
					{ticketTypes.map((ticketType, i) => (
						<TicketMapCard
							key={`ticketMapCard_${i}`}
							ticketType={ticketType}
							selectedTickets={selectedTickets}
							onCheck={this.onTicketSelected}
							manageCapacityFormValues={manageCapacityFormValues}
						/>
					))}
				</div>
			</div>
		);
	}
}

const convertToHexString = (num = 0) => `#${("000000" + num.toString(16)).substr(-6)}`;

const TicketMapCard = ({ ticketType, onCheck, selectedTickets, manageCapacityFormValues }) => {
	const checked = selectedTickets.includes(ticketType._id);
	const ticketTypeColor = convertToHexString(SeatingMap.getCurrentMap().getTicketTypeColorMap()[ticketType._id]);

	const toggleCheckBox = () => onCheck(ticketType._id, checked);

	let displayableCount,
		quantity = ticketType.quantity;
	if (ticketType.isPackage) {
		const formValue = manageCapacityFormValues.packagedTickets.find((t) => String(t._id) === String(ticketType._id));
		displayableCount = formValue.mappedTables;
		quantity = formValue.quantity;
	} else {
		const formValue = manageCapacityFormValues.ticketTypes.find((t) => String(t._id) === String(ticketType._id));
		displayableCount = formValue.mappedSeats;
		quantity = formValue.quantity;
	}

	let warningColor = "";
	if (displayableCount === 0) {
		warningColor = "";
	} else if (displayableCount > quantity) {
		warningColor = "#C0212C";
	} else if (displayableCount < quantity) {
		warningColor = "#EAA200";
	}

	const warningDot = (
		<span
			style={{
				backgroundColor: warningColor,
				borderRadius: "50%",
				marginLeft: 4,
				marginTop: 7,
				width: 5,
				height: 5
			}}
		/>
	);

	return (
		<div style={{ position: "relative", marginTop: 8 }}>
			<div
				className={css({
					display: "flex",
					minHeight: 86,
					padding: 12,
					border: `solid 1px ${checked ? "#ffb18f" : "#8e99a8"}`,
					borderRight: `solid 8px ${ticketTypeColor}`,
					borderRadius: 4,
					"&:hover": {
						cursor: "pointer",
						border: "solid 1px #ffb18f",
						borderRight: `solid 8px ${ticketTypeColor}`
					}
				})}
				onClick={toggleCheckBox}
			>
				<Checkbox
					checked={checked}
					onChange={toggleCheckBox}
					style={{
						width: 18,
						height: 18,
						marginTop: 17
					}}
				/>
				<div style={{ marginLeft: 12, marginRight: 24, flexGrow: 1 }}>
					<H4 style={{ marginBottom: 2 }}>{ticketType.name}</H4>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<div style={{ display: "flex" }}>
							<P marginBottom={0}>Mapped:</P>
							<P style={{ color: warningColor, paddingLeft: 4 }} marginBottom={0}>
								{displayableCount}
							</P>
							{warningColor ? warningDot : null}
						</div>

						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<P marginBottom={0}>Capacity: {quantity}</P> {`${currency(ticketType.price)}`}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const formName = "manageMappedCapacity";
const selector = formValueSelector(formName);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		manageCapacityFormValues: selector(state, "ticketTypes", "packagedTickets")
	}),
	{}
)(TicketMapping);
