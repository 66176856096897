import logoSmile from "@/assets/logos/faces/smile.svg";
import logo from "@/assets/logos/humanitix.svg";
import ErrorMsg from "@/components/ErrorMsg";
import { H1, H2, P } from "@/components/text/Text";
import { hardRefresh } from "@/utils/Browser";
import { LoadingOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { CSSProperties, FC, HTMLAttributes, ReactNode } from "react";

const StyledLoadingSpinner = styled(LoadingOutlined)<{
	color: string;
	size: number;
}>`
	color: ${({ color }) => color};
	font-size: ${({ size }) => size}px;
	height: ${({ size }) => size}px;
	overflow: hidden;
	width: ${({ size }) => size}px;
`;

type LoadingSpinnerProps = {
	color?: string;
	size?: number;
	style?: CSSProperties;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 40, style = {}, color = "currentColor" }) => (
	<StyledLoadingSpinner color={color} size={size} style={style} />
);

const StyledLoadingWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding: 20%;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: flex-start;
`;

const StyledLoadingPane = styled.div<{
	backgroundColor?: string;
}>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	height: 100%;
	left: 0;
	opacity: 0.7;
	position: absolute;
	top: 0;
	width: 100%;
`;

type LoadingProps = {
	backgroundColor?: string;
	error?: Error | null;
};

export const Loading = ({ backgroundColor = "var(--surface2)", error = null }: LoadingProps) => {
	if (error) {
		console.error(error);
		return <ErrorMsg title="Error" message="Sorry there was an error loading" retryAction={hardRefresh} />;
	}

	const stopClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	return (
		<StyledLoadingWrapper onClick={stopClick}>
			<StyledLoadingPane backgroundColor={backgroundColor} />
			<LoadingOutlined style={{ fontSize: 42 }} />
		</StyledLoadingWrapper>
	);
};

const FullPageBg = styled.div`
	background-color: #f9f9fa;
	min-height: 100vh;

	@media (max-width: 600px) {
		height: 100%;
	}
`;

const StyledWrap = styled.div`
	align-items: center;
	background-size: 735px auto;
	background-color: #f9f9fa;
	display: flex;
	justify-content: center;
	min-height: 100vh;

	@media (max-width: 600px) {
		background-size: 500px auto;
	}
`;

const StyledFullPageContainer = styled.div`
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 10px 0 rgba(9, 30, 66, 0.13);
	display: flex;
	margin: 20px auto;
	max-width: 900px;
	position: relative;
	width: 80%;

	@media (max-width: 600px) {
		border-radius: 0;
		min-width: auto;
		margin: auto;
		width: 100%;
	}

	.ant-input-affix-wrapper .ant-input-suffix {
		right: 0;
	}
`;

const StyledMsgContainer = styled.div`
	background-color: #353337;
	border-radius: 4px 0 0 4px;
	flex: 1;
	text-align: center;
	width: 50%;

	@media (max-width: 600px) {
		display: none;
	}
`;

const StyledMessagePadding = styled.div`
	background: #353337 url(${logoSmile}) no-repeat;
	background-position: center 158px;
	background-size: 100px 71px;
	border-radius: 4px 0 0 4px;
	height: 100%;
	max-height: 805px;
	padding: 250px 70px 48px;
`;

const StyledFormContainer = styled.div`
	background-color: #fff;
	border-radius: 0 4px 4px 0;
	box-sizing: border-box;
	flex: 1;
`;

const StyledFormPadding = styled.div`
	padding: 48px 36px;
	position: relative;

	@media (max-width: 600px) {
		padding: 48px 24px;
	}

	@media (max-width: 320px) {
		padding: 48px 8px;
	}
`;

const StyledLinkPosition = styled.div`
	margin-top: 120px;

	a {
		color: #fff;
		text-decoration: underline;

		:hover {
			color: #fff;
			font-weight: 600;
		}
	}
`;

const StyledLogoDiv = styled.div`
	background: url(${logo});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	height: 30px;
	margin: 0 auto 38px;
	width: 173px;

	@media (max-width: 600px) {
		margin: 6px auto;
	}
`;

type FullPageContainerProps = {
	children: ReactNode;
	link?: ReactNode;
	msg?: ReactNode;
	smile?: ReactNode;
};

export const FullPageWrap: FC<FullPageContainerProps> = ({ children, msg, link, smile }) => {
	return (
		<FullPageBg>
			<StyledWrap>
				<StyledFullPageContainer>
					{msg || smile || link ? (
						<StyledMsgContainer>
							<StyledMessagePadding>
								{msg ? <H1 color="#fff">{msg}</H1> : null}
								{link ? <StyledLinkPosition>{link}</StyledLinkPosition> : null}
							</StyledMessagePadding>
						</StyledMsgContainer>
					) : null}
					<StyledFormContainer>
						<StyledFormPadding>
							<StyledLogoDiv />
							{children}
						</StyledFormPadding>
					</StyledFormContainer>
				</StyledFullPageContainer>
			</StyledWrap>
		</FullPageBg>
	);
};

export const ReportFilters = styled.div`
	background: #fafafa;
	padding: 24px;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 1px 10px 0 rgba(9, 30, 66, 0.13);

	@media (max-width: 600px) {
		padding: 16px 12px;
	}
`;

export const ReportContent = styled.div`
	background: #fff;
	border-radius: 0 0 4px 4px;
	margin-bottom: 12px;
	margin-top: 0px;
	padding: 24px;

	@media (max-width: 600px) {
		padding: 16px 12px;
	}
`;

const StyledContentBlock = styled.div<{
	error: boolean;
	left: boolean;
	right: boolean;
	small: boolean;
	allowOverflow?: boolean;
}>`
	${({ allowOverflow, error, small, left, right }) => css`
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid ${error ? "red" : "transparent"};
		margin-bottom: 12px;
		margin-top: 6px;
		padding: ${small ? "12px" : "24px"};
		position: relative;
		overflow: ${allowOverflow ? "visible" : "hidden"};

		@media (max-width: 600px) {
			padding: ${small ? "12px" : "16px 12px"};
		}

		${left &&
		css`
			flex: 1;
			margin-right: 6px;
		`}

		${right &&
		css`
			flex: 1;
			margin-left: 6px;
		`}
	`}
`;

type ContentBlockProps = {
	allowOverflow?: boolean;
	children: ReactNode;
	error?: boolean;
	left?: boolean;
	right?: boolean;
	shadow?: boolean;
	small?: boolean;
	style?: CSSProperties;
};

export const ContentBlock: FC<ContentBlockProps & HTMLAttributes<HTMLDivElement>> = ({
	allowOverflow = false,
	children,
	error = false,
	left = false,
	right = false,
	small = false,
	style = {},
	...props
}) => {
	return (
		<StyledContentBlock
			allowOverflow={allowOverflow}
			error={error}
			left={left}
			right={right}
			small={small}
			style={style}
			{...props}
		>
			{children}
		</StyledContentBlock>
	);
};

const StyledHeading = styled.div<{ inline?: boolean }>`
	display: ${({ inline }) => (inline ? "inline-block" : "block")};
	margin-bottom: 16px;
`;

type HeadingProps = {
	description?: string;
	inline?: boolean;
	title?: string;
};

export const Heading: FC<HeadingProps> = ({ description, inline, title }) => (
	<StyledHeading inline={inline}>
		{title && <H2>{title}</H2>}
		{description && <P>{description}</P>}
	</StyledHeading>
);
