import { getConfig } from "@/config";
import { useAppSelector } from "@/state/hooks";
import { FC, useEffect, useRef } from "react";

// NOTE: Inline styles because we need to inject the styles into the iframe
export const PopupWidgetBuilderPreview: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);

	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		const loadScript = () => {
			if (!buttonRef.current?.parentElement) {
				return;
			}

			const parentElement = buttonRef.current.parentElement;
			const scriptUrl = `${getConfig("EVENTS_URL")}/scripts/widgets/popup.js`;
			if (parentElement.querySelector(`script[src="${scriptUrl}"]`)) {
				return;
			}

			const script = document.createElement("script");
			script.type = "module";
			script.src = scriptUrl;
			parentElement.appendChild(script);
		};

		loadScript();
	}, [buttonRef.current]);

	return (
		<>
			<div className="skeleton shrink" />
			<div className="skeleton tall" />

			<button
				hx-popup={currentEvent.event?.slug}
				data-env={getConfig("NODE_ENV")}
				is-widget-builder-demo="true"
				ref={buttonRef}
			>
				Your Website Element
			</button>

			<div style={{ display: "flex", gap: "16px" }}>
				<div className="skeleton tall" />
				<div className="skeleton tall" />
			</div>
			<div className="skeleton tall no-gutter" style={{ minHeight: "100px", borderRadius: "4px 4px 0px 0px" }} />
		</>
	);
};
