import IconButton from "@/components/IconButton";
import { EventPreview } from "@/events/event/header/eventPreview/Preview";
import { useAppSelector } from "@/state/hooks";
import { Drawer } from "@/ui/antd";
import { Button } from "@/ui";
import { Responsive } from "@/ui";
import { getEventUrls } from "@/utils/Events";
import { Devices } from "@phosphor-icons/react";
import { FC, useState } from "react";

type DynamicButtonProps = {
	isPublished: boolean;
	onClick?: (e: React.MouseEvent) => void;
};

const DynamicButton: FC<DynamicButtonProps> = ({ isPublished, onClick }) => (
	<Button aria-label="Event publish toggle" iconLeft={<Devices size={18} />} onClick={onClick} variant="tertiary">
		{isPublished ? "View" : "Preview"}
	</Button>
);

export const EventPreviewButton: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const [showModal, setShowModal] = useState(false);
	const { eventPage } = getEventUrls(currentEvent?.event);
	const isPublished = !!currentEvent?.event?.published;

	return (
		<Responsive
			desktop={
				<>
					<DynamicButton
						isPublished={isPublished}
						onClick={(e) => {
							if (e.ctrlKey || e.metaKey) {
								return window.open(eventPage, "_blank", "noopener,noreferrer");
							}
							return setShowModal(true);
						}}
					/>
					<Drawer
						placement="bottom"
						height="98vh"
						open={showModal}
						title="View Desktop & Mobile"
						onClose={() => setShowModal(false)}
						extra={<IconButton ariaLabel="close" onClick={() => setShowModal(false)} icon="close" />}
						closeIcon={null}
					>
						{showModal ? <EventPreview /> : null}
					</Drawer>
				</>
			}
			mobile={
				<a href={eventPage} target="_blank" rel="noreferrer" style={{ background: "white" }}>
					<DynamicButton isPublished={isPublished} />
				</a>
			}
		/>
	);
};
