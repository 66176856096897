import axios from "./AxiosWrapper";

const MailchimpService = {
	async getToken(code, userId) {
		const response = await axios.get(`mailchimp/get-token/${code}/${userId}`);
		return response.data;
	},
	async testConnection(userId) {
		const response = await axios.get(`mailchimp/test-connection/${userId}`);
		return response.data;
	},
	async getAudiences(userId) {
		const response = await axios.get(`mailchimp/get-audiences/${userId}`);
		return response.data;
	},
	async adminDisconnect(userId) {
		const response = await axios.post(`admin/mailchimp/disconnect/${userId}`);
		return response.data;
	}
};
export default MailchimpService;
