import React from "react";
import { Route, Routes as RouterRoutes } from "react-router";
import MenuLayout from "../components/MenuLayout";
import type { MenuItem } from "../services/menuService/MenuService";
import ReportsList from "./ReportsList";
import SideMenu from "./ReportsMenu";
import { ListAllScheduledReports } from "./ScheduledReports/ListAll";
import { ScheduledReport } from "./ScheduledReports/Report";
import { GlobalAccessCodesReport } from "./components/AccessCodes";
import AdditionalDonationsReport from "./components/AdditionalDonation";
import AttendeesReport from "./components/Attendees";
import { GlobalDiscountCodesReport } from "./components/Discounts";
import EarningsByTicketTypeReport from "./components/EarningsByTicketType";
import EventSalesUpdate from "./components/EventSalesUpdate";
import EventsReport from "./components/Events";
import GiftCardsReport from "./components/GiftCardsReport";
import GuestListReport from "./components/GuestList";
import Invoices from "./components/Invoices";
import { MerchandiseReport } from "./components/MerchandiseReport";
import OrdersReport from "./components/Orders";
import PayoutsReport from "./components/Payouts";
import TopPurchasersReport from "./components/TopPurchasers";

import { css } from "@emotion/css";

const reportPageWrapper = css({
	height: "100%",
	margin: "auto",
	maxWidth: 1800
});

const WithMenuLayout = ({
	title = "",
	menuItems,
	children
}: {
	title?: string;
	menuItems: MenuItem[];
	children: React.ReactNode;
}) => {
	return (
		<MenuLayout>
			<SideMenu title={title} menuItems={menuItems} />
			{children}
		</MenuLayout>
	);
};

const ReportsRoutes = () => {
	return (
		<RouterRoutes>
			<Route path="" element={<ReportsList />} />
			<Route path="attendee-details" element={<AttendeesReport />} />
			<Route path="orders" element={<OrdersReport />} />
			<Route path="events" element={<EventsReport />} />
			<Route path="payouts" element={<PayoutsReport />} />
			<Route path="discounts" element={<GlobalDiscountCodesReport />} />
			<Route path="access-codes" element={<GlobalAccessCodesReport />} />
			<Route path="earnings-by-ticket-type" element={<EarningsByTicketTypeReport />} />
			<Route path="top-purchasers" element={<TopPurchasersReport />} />
			<Route path="guest-list" element={<GuestListReport />} />
			<Route path="paid-by-invoice" element={<Invoices />} />
			<Route path="gift-cards" element={<GiftCardsReport />} />
			<Route path="add-ons" element={<MerchandiseReport />} />
			<Route path="additional-donations" element={<AdditionalDonationsReport />} />
			<Route path="event-sales-update" element={<EventSalesUpdate />} />
			<Route path="scheduled" element={<ListAllScheduledReports />} />
			<Route path="scheduled/:scheduledId" element={<ScheduledReport />} />
		</RouterRoutes>
	);
};

const Reports = () => {
	return (
		<WithMenuLayout
			menuItems={[
				{
					key: "reports",
					path: "/console/reports",
					label: "Reports"
				},
				{
					key: "scheduledReports",
					path: "/console/reports/scheduled",
					label: "Scheduled reports"
				}
			]}
		>
			<div className={reportPageWrapper}>
				<ReportsRoutes />
			</div>
		</WithMenuLayout>
	);
};

export default Reports;
