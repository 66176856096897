import { Label } from "../../../../components/text/Text";
import InputLabel from "../../../components/InputLabel";
import StepInput from "../../../components/StepInput";

export default ({ elements, performAction }) => {
	const note = elements.reduce((num, element) => {
		if (num > element.socialDistanceNum) {
			return element.socialDistanceNum;
		}
	}, Number.MAX_SAFE_INTEGER);
	return (
		<div style={{ marginTop: 10 }}>
			<InputLabel>Social Distancing</InputLabel>
			<Label bold={false}>Ensure a minimum seat spacing between orders.</Label>
			<div style={{ display: "flex", flexDirection: "row-reverse" }}>
				<StepInput
					min={0}
					value={note}
					onChange={(num) => {
						performAction(
							elements.map((element) => element.setSocialDistance),
							elements.map(() => [num])
						);
					}}
				/>
			</div>
		</div>
	);
};
