import { LegacyButton } from "@/components/buttons/LegacyButton";
import { dayjs } from "@/libs/dayjs";
import { Modal, Select } from "@/ui/antd";
import { Component } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import ButtonsBar from "./buttons/ButtonsBar";

export default class EventDatePicker extends Component {
	constructor(props) {
		super(props);
		const firstDate = props.dates[0];
		this.state = {
			month: dayjs(),
			eventDatesWithinMonth: [],
			visible: false,
			selectedDay: new Date(firstDate.startDate),
			relevantDates: [],
			selectedDate: props.selectedDate
		};
		this.onShowModal = this.onShowModal.bind(this);
		this.getStartingMonth = this.getStartingMonth.bind(this);
		this.checkDateDisabled = this.checkDateDisabled.bind(this);
		this.handleDayClick = this.handleDayClick.bind(this);
		this.onHideModal = this.onHideModal.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.onDateSubmit = this.onDateSubmit.bind(this);
		this.onMonthChange = this.onMonthChange.bind(this);
	}

	componentDidMount() {
		this.dateMapper = new DateMapper(this.props.dates);
		const startingMonth = this.getStartingMonth();
		const eventDatesWithinMonth = this.dateMapper.getMonthTable(startingMonth);
		const relevantDates = this.getRelevantDates(eventDatesWithinMonth, this.state.selectedDay);
		this.setState({
			month: startingMonth,
			eventDatesWithinMonth,
			relevantDates
		});
	}

	onShowModal() {
		this.setState({ visible: true });
	}

	onHideModal() {
		this.setState({ visible: false });
	}

	getStartingMonth() {
		return dayjs();
	}

	checkDateDisabled(day) {
		const { eventDatesWithinMonth } = this.state;
		const calendarDate = dayjs(day);
		const isContainedInEvent =
			eventDatesWithinMonth[calendarDate.format("D")] && eventDatesWithinMonth[calendarDate.format("D")].length > 0;
		return !isContainedInEvent;
	}

	handleDayClick(day, { selected, disabled }) {
		if (disabled) {
			// Day is disabled, do nothing
			return;
		}
		if (selected) {
			// Unselect the day if already selected
			this.setState({
				selectedDay: undefined,
				relevantDates: [],
				selectedDate: undefined
			});
			return;
		}
		const relevantDates = this.getRelevantDates(this.state.eventDatesWithinMonth, day);
		this.setState({
			selectedDay: day,
			relevantDates,
			selectedDate: relevantDates[0]
		});
	}

	getRelevantDates(monthDays, day) {
		const calendarDate = dayjs(day);
		return monthDays[calendarDate.format("D")] || [];
	}
	handleDateChange(id) {
		const date = this.state.relevantDates.find((date) => date._id === id);
		this.setState({
			selectedDate: date
		});
	}

	onDateSubmit() {
		const { onDatePicked } = this.props;
		const { selectedDate } = this.state;
		onDatePicked([selectedDate._id]);
		this.setState({
			visible: false
		});
	}

	onMonthChange(month) {
		const newMonth = dayjs(month);
		const eventDatesWithinMonth = this.dateMapper.getMonthTable(newMonth);
		this.setState({
			month: newMonth,
			eventDatesWithinMonth
		});
	}

	render() {
		const { month, visible, selectedDay, relevantDates, selectedDate } = this.state;
		const dateStr = selectedDate ? `${selectedDate.display.date}, ${selectedDate.display.time}` : "Select Date";
		return (
			<div style={{ marginBottom: 8 }}>
				<LegacyButton onClick={this.onShowModal} ariaLabel={dateStr}>
					{dateStr}
				</LegacyButton>
				<Modal
					open={visible}
					title="Choose Date"
					onCancel={this.onHideModal}
					destroyOnClose
					footer={
						<ButtonsBar footer>
							<LegacyButton key="cancel" onClick={this.onHideModal} ariaLabel="Cancel">
								Cancel
							</LegacyButton>
							<LegacyButton
								key="submit"
								onClick={this.onDateSubmit}
								disabled={!selectedDate}
								type="primary"
								ariaLabel="Select"
							>
								Select
							</LegacyButton>
						</ButtonsBar>
					}
				>
					<div style={{ textAlign: "center" }}>
						<DatePicker
							style={{ display: "inline-block" }}
							onMonthChange={this.onMonthChange}
							selectedDays={selectedDay}
							onDayClick={this.handleDayClick}
							month={month.toDate()}
							disabledDays={this.checkDateDisabled}
						/>
					</div>
					{relevantDates.length > 0 ? (
						<Select
							size="large"
							showSearch
							value={selectedDate ? selectedDate._id : ""}
							style={{ width: "100%", marginBottom: 10 }}
							popupMatchSelectWidth={false}
							onChange={this.handleDateChange}
							options={relevantDates.map((date) => {
								const dateStr = `${date.display.date}, ${date.display.time}`;

								return { value: date._id, label: dateStr };
							})}
						/>
					) : null}
				</Modal>
			</div>
		);
	}
}

const DatePicker = (props) => {
	return <DayPicker {...props} />;
};

class DateMapper {
	constructor(dates) {
		this.dateMapper = dates.reduce((map, date) => {
			const currentDate = dayjs(date.startDate);
			const yearMonth = currentDate.format("Y-M");
			if (!map[yearMonth]) {
				map[yearMonth] = {};
			}
			const day = currentDate.format("D");
			if (!map[yearMonth][day]) {
				map[yearMonth][day] = [];
			}
			map[yearMonth][day].push(date);
			return map;
		}, {});
	}

	getMonthTable(date) {
		return this.dateMapper[date.format("Y-M")] || {};
	}
}
