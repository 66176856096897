import { css } from "@emotion/css";
import { Table, Tag } from "@/ui/antd";
import { Field } from "redux-form";
import { CheckboxField, InputNumberField } from "@/components/Fields";
import { H3, P } from "@/components/text/Text";
import { currency } from "@/utils/Format";
import { useAppSelector } from "@/state/hooks";

const tableWrapper = css`
	margin-bottom: 12px;
	.ant-form-item {
		margin-bottom: 0 !important;
	}
`;

const TicketsTable = ({
	ticketsToCancel,
	allTicketsSelected,
	selectAllTickets,
	fundsAvailable,
	setRefundAmount,
	maximumRefund
}) => {
	const user = useAppSelector((state) => state.auth.user);
	const isAdmin = user.role === "admin";

	if (!ticketsToCancel) return null;
	const tickets = Object.values(ticketsToCancel);
	const hasSeats = tickets.some((t) => !!t.seat);
	if (!tickets.length) return null;
	const columns = [
		{
			title: "Ticket",
			key: "ticketDetails",
			render: (_, ticket) => {
				return (
					<P>
						{ticket.ticketTypeName}: {ticket.firstName} {ticket.lastName}
					</P>
				);
			}
		},
		...(hasSeats
			? [
					{
						title: "Seat",
						key: "seat",
						dataIndex: "seat"
					}
			  ]
			: []),
		{
			title: (
				<CheckboxField
					id="ticketsCancelAll"
					name="ticketsCancelAll"
					label="Cancel all"
					labelAfter
					input={{ value: allTicketsSelected, onChange: selectAllTickets, name: "ticketsCancelAll" }}
					meta={{}}
					inline
				/>
			),
			key: "refundTicket",
			fixed: "right",
			render: (_, ticket) => {
				if (ticket.status === "cancelled") return <Tag color="#5A626C">Cancelled</Tag>;
				if (ticket.status === "resold") return <Tag color="#b18fff">Resold</Tag>;
				return (
					<Field
						id={`ticketsToCancel[${ticket._id}].cancel`}
						name={`ticketsToCancel[${ticket._id}].cancel`}
						component={CheckboxField}
						label="Cancel"
						inline
						labelAfter
						onChange={(_, checked) => {
							setRefundAmount(
								"ticketsToCancel",
								ticket._id,
								checked,
								Math.min(maximumRefund + ticket.amount, fundsAvailable[ticket._id])
							);
						}}
					/>
				);
			}
		},
		{
			title: "Remaining",
			key: "ticketPrice",
			fixed: "right",
			render: (_, ticket) => currency(fundsAvailable[ticket._id] - ticket.amount)
		},
		{
			title: `Refund amount`,
			fixed: "right",
			key: "refundAmount",
			render: (_, ticket) => {
				return (
					<Field
						id={`ticketsToCancel[${ticket._id}].amount`}
						name={`ticketsToCancel[${ticket._id}].amount`}
						component={InputNumberField}
						precision={2}
						min={0}
						max={Math.min(fundsAvailable[ticket._id], maximumRefund + ticket.amount) || 0}
						disabled={fundsAvailable[ticket._id] === 0 || (ticket.status === "resold" && !isAdmin)}
					/>
				);
			}
		}
	];
	return (
		<div className={tableWrapper}>
			<H3>Tickets</H3>
			<Table dataSource={tickets} columns={columns} pagination={false} rowKey={(data) => data._id} />
		</div>
	);
};

export default TicketsTable;
