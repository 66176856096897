import { css } from "@emotion/css";
import { Format } from "@hx/utilities";
import { notification } from "@/ui/antd";
import ObjectID from "bson-objectid";
import { Component } from "react";
import { Field } from "redux-form";
import { LegacyInputField, RadioField, SwitchField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import InfoTitle from "@/components/table/InfoTitle";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { includes } from "@/utils/Array";
import { required } from "@/utils/Validators";
import AddQuestionsModal from "./Modal";
import QuestionOptions from "./QuestionOptions";

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	})
};

const hideRow = css`
	display: none;
`;

export default class QuestionRows extends Component {
	constructor(props) {
		super(props);

		this.state = {
			questions: [],
			addModalVisible: false,
			expandedRowKeys: []
		};

		this.addQuestion = this.addQuestion.bind(this);
		this.removeQuestion = this.removeQuestion.bind(this);
		this.enableQuestion = this.enableQuestion.bind(this);
		this.showAddQuestionsModal = this.showAddQuestionsModal.bind(this);
		this.hideAddQuestionsModal = this.hideAddQuestionsModal.bind(this);
		this.rowExpand = this.rowExpand.bind(this);
		this.onExpandClick = this.onExpandClick.bind(this);
	}

	rowExpand(expanded, row) {
		const keys = expanded ? [row.key] : [];
		this.setState({ expandedRowKeys: keys });
	}

	addQuestion(question) {
		const { fields } = this.props;
		if (question.question === "Full name") {
			question.fields.forEach((q) => {
				q._id = ObjectID().toHexString();
				q.required = false;
				q.perTicket = true;
				q.newOne = true;
			});
		} else {
			question._id = ObjectID().toHexString();
			question.required = false;
			question.perTicket = true;
			question.newOne = true;
			question.showOnTickets = true;
		}
		const fieldsToPush = question.fields ? question.fields : [question];
		if (question.requiresOther) {
			fieldsToPush.push({
				newOne: true,
				perTicket: true,
				question: `${question.question} other`,
				type: "text",
				_id: ObjectID().toHexString(),
				showConditions: true,
				conditions: [
					{
						additionalQuestionId: question._id,
						value: ["Other"],
						condition: "contains"
					}
				]
			});
		}
		fieldsToPush.forEach((q) => fields.push(q));
		this.hideAddQuestionsModal();
	}

	onExpandClick(key, forceClose) {
		const tempRowKeys = [...this.state.expandedRowKeys];
		if (includes(this.state.expandedRowKeys, key)) {
			const index = tempRowKeys.indexOf(key);
			tempRowKeys.splice(index, 1);
		} else if (!forceClose) {
			tempRowKeys.push(key);
		}
		this.setState({ expandedRowKeys: tempRowKeys });
	}

	checkQuestionsUsed(additionalQuestionId) {
		const { additionalQuestionsOnTicket } = this.props;

		if (additionalQuestionsOnTicket && additionalQuestionsOnTicket.length) {
			const isQuestionAnswered = additionalQuestionsOnTicket.some((questionId) => questionId === additionalQuestionId);
			if (isQuestionAnswered) {
				notification.error({
					message: "Error",
					description: "This question has been answered. You can only disable it"
				});
				return false;
			}
		}
		return true;
	}

	checkQuestionInCondition(additionalQuestionId) {
		const { additionalQuestions } = this.props;
		if (additionalQuestions && additionalQuestions.length) {
			const isQuestionInCondition = additionalQuestions.some((question) => {
				if (!question.deleted && !question.disabled && question.conditions && question.conditions.length) {
					return question.conditions.some((question) => question.additionalQuestionId === additionalQuestionId);
				}
				return false;
			});
			if (isQuestionInCondition) {
				notification.error({
					message: "Error",
					description:
						"This question relates to another conditional question, you can delete or disable this question after deleting or removing the conditional questions."
				});
				return false;
			}
		}
		return true;
	}

	removeQuestion(index, item) {
		const { isFromtemplate, fields, additionalQuestions, deleteQuestion } = this.props;

		if (isFromtemplate) {
			return fields.remove(index);
		}
		const event = this.props.currentEvent && this.props.currentEvent.event ? this.props.currentEvent.event : {};
		const additionalQuestion = additionalQuestions[index];
		const orgAdditionalQuestions = event && event.additionalQuestions ? event.additionalQuestions : {};
		const _isExist = orgAdditionalQuestions.find((question) => question._id === additionalQuestion._id) || false;

		if (_isExist) {
			if (this.checkQuestionInCondition(additionalQuestion._id) && this.checkQuestionsUsed(additionalQuestion._id)) {
				const forceClose = true;
				this.onExpandClick(item.key, forceClose);
				fields.remove(index);
				deleteQuestion(event._id, additionalQuestion._id);
				return;
			}
		} else if (this.checkQuestionInCondition(additionalQuestion._id)) {
			fields.remove(index);
		}
	}

	disableQuestion = (index) => {
		const { additionalQuestions, changeFieldValue } = this.props;
		const additionalQuestion = additionalQuestions[index];
		if (additionalQuestion._id) {
			if (this.checkQuestionInCondition(additionalQuestion._id))
				changeFieldValue(`additionalQuestions[${index}].disabled`, true);
		} else changeFieldValue(`additionalQuestions[${index}].disabled`, true);
	};

	enableQuestion(index) {
		const { changeFieldValue } = this.props;
		changeFieldValue(`additionalQuestions[${index}].disabled`, false);
	}

	showAddQuestionsModal() {
		this.setState({ addModalVisible: true });
	}
	hideAddQuestionsModal() {
		this.setState({ addModalVisible: false });
	}

	questionRequired = required("Please enter your question");

	render() {
		const {
			additionalQuestions,
			currentEvent,
			changeFieldValue,
			fields,
			removeConditions,
			isFromtemplate,
			dispatch,
			formName
		} = this.props;

		if ((!currentEvent || !currentEvent.event) && !isFromtemplate) {
			return "";
		}

		const columns = [
			{
				title: "Type",
				dataIndex: "type",
				key: "type",
				width: "15%",
				render: (_, data) => {
					const type = data.type;
					let newType = "";
					switch (type) {
						case "firstName":
							newType = "First name";
							break;
						case "lastName":
							newType = "Last name";
							break;
						case "note":
							newType = "Message";
							break;
						case "options":
							newType = "Dropdown select";
							break;
						default:
							newType = Format.camelCaseToSentenceCase(type);
							break;
					}
					return data.disabled ? (
						"Disabled"
					) : (
						<div
							className={css({
								"@media(max-width: 600px)": {
									marginRight: 10,
									minWidth: "25vw"
								}
							})}
						>
							<div style={{ paddingTop: 6 }}>{newType}</div>
						</div>
					);
				}
			},
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Question <span className={styles.required}> (required)</span>
					</span>
				),
				dataIndex: "question",
				width: "40%",
				key: "question",
				render: (_, data, index) => {
					if (data.defaultOrderQuestion) {
						return <span>{data.question}</span>;
					}
					return data.disabled ? (
						<s>{data.question}</s>
					) : (
						<div
							className={css({
								"@media(max-width: 600px)": {
									marginRight: 10,
									minWidth: "35vw"
								}
							})}
						>
							<Field
								name={`additionalQuestions[${index}].question`}
								type="text"
								component={LegacyInputField}
								validate={this.questionRequired}
								placeholder="Question"
								onDrop={(e) => {
									e.preventDefault();
								}}
							/>
						</div>
					);
				}
			},
			{
				title: (
					<InfoTitle
						title="Apply to"
						tooltip='If "Tickets" is selected this question will be asked once per each selected ticket. If "Order" is selected this question will be asked once per order.'
						bold
					/>
				),
				dataIndex: "perTicket",
				key: "perTicket",
				width: 150,
				render: (_, data, index) => {
					return (
						<div
							className={css({
								minWidth: 150,
								"@media(max-width: 600px)": {
									marginRight: 10
								}
							})}
						>
							<Field
								name={`additionalQuestions[${index}].perTicket`}
								type="text"
								disabled={data.disabled || data.defaultOrderQuestion}
								component={RadioField}
								size="small"
								button
								options={[
									{
										label: "Tickets",
										value: true
									},
									{
										label: "Order",
										value: false
									}
								]}
							/>
						</div>
					);
				}
			},
			{
				title: "Required",
				dataIndex: "required",
				key: "required",
				width: 130,
				render: (_, data, index) => {
					return data.type === "note" ? (
						<div />
					) : (
						<div
							className={css({
								"@media(max-width: 600px)": {
									marginRight: 10,
									minWidth: "25vw"
								}
							})}
						>
							<Field
								name={`additionalQuestions[${index}].required`}
								type="text"
								component={SwitchField}
								disabled={data.disabled || data.defaultOrderQuestion}
							/>
						</div>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				width: 140,
				render: (item, data, index) => {
					if (data.defaultOrderQuestion) {
						return null;
					}
					return data.disabled ? (
						<div
							className={css({
								"@media(max-width: 600px)": {
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<TableActions containerStyle={{ paddingTop: 3 }}>
								<IconButton
									icon="undo"
									onClick={() => this.enableQuestion(index, item)}
									tooltip="Undo"
									showTooltip
									ariaLabel="Undo"
								/>
							</TableActions>
						</div>
					) : (
						<div
							className={css({
								"@media(max-width: 600px)": {
									marginRight: 10,
									minWidth: "35vw"
								}
							})}
						>
							<TableActions containerStyle={{ position: "relative" }}>
								<IconButton
									icon="settings"
									onClick={() => this.onExpandClick(item.key, false)}
									tooltip="Settings"
									showTooltip
									// expanded used to change setting icon active
									expanded={includes(this.state.expandedRowKeys, item.key)}
									ariaLabel="Settings"
									dataCy={`additionalQuestion-${item.key}-settings`}
								/>

								<IconButton
									icon="disable"
									onClick={() => this.disableQuestion(index)}
									tooltip="Disable"
									showTooltip
									ariaLabel="Disable"
									dataCy={`additionalQuestion-${item.key}-disable`}
								/>

								<IconButton
									icon="delete"
									onClick={() => this.removeQuestion(index, item)}
									tooltip="Delete"
									showTooltip
									ariaLabel="Delete"
									dataCy={`additionalQuestion-${item.key}-delete`}
								/>
							</TableActions>
						</div>
					);
				}
			}
		];
		//pre render row
		if (additionalQuestions) {
			additionalQuestions.forEach((question, index) => {
				question.key = index;
			});
		}
		return (
			<div>
				<AddQuestionsModal
					visible={this.state.addModalVisible}
					handleCancel={this.hideAddQuestionsModal}
					addQuestion={this.addQuestion}
				/>

				<ResponsiveTable
					pagination={false}
					columns={columns}
					dataSource={additionalQuestions}
					rowKey="key"
					locale={{
						emptyText: (
							<div style={{ margin: "12px 0" }}>
								Please add any additional questions you would like to ask your guests.
							</div>
						)
					}}
					expandedRowRender={(question) => {
						return question.disabled || question.deleted || !additionalQuestions[question.key] ? null : (
							<QuestionOptions
								event={isFromtemplate ? {} : currentEvent.event}
								isFromtemplate={isFromtemplate}
								question={question}
								additionalQuestions={additionalQuestions}
								index={question.key}
								changeFieldValue={changeFieldValue}
								fields={fields}
								removeConditions={removeConditions}
								alreadySoldTickets={currentEvent?.event?.ticketSold}
								isNew={question.newOne}
							/>
						);
					}}
					expandedRowKeys={this.state.expandedRowKeys}
					expandIconColumnIndex={99}
					expandIconAsCell={false}
					draggable={{ dispatch, formName, fieldName: "additionalQuestions" }}
					rowClassName={(record) => {
						return record.deleted ? hideRow : "";
					}}
				/>

				<div
					className={css({
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: 12,
						"@media(max-width: 600px)": {
							display: "block",
							".ant-btn": {
								display: "block",
								margin: "0 auto",
								marginBottom: 12,
								width: "100%",
								":last-child": {
									marginBottom: 0
								}
							}
						}
					})}
				>
					<ButtonsBar>
						<LegacyButton
							onClick={() => this.showAddQuestionsModal()}
							icon={{ name: "plus", left: true }}
							type="action"
							ariaLabel="Add question"
							dataCy="Add question"
						>
							Add question
						</LegacyButton>
					</ButtonsBar>
				</div>

				<div
					className="ant-tooltip  ant-tooltip-placement-top  ant-tooltip-hidden"
					style={{ left: 918.109, top: 459, transformOrigin: "50% 45px 0px" }}
				>
					<div className="ant-tooltip-content">
						<div className="ant-tooltip-arrow" />
						<div className="ant-tooltip-inner">Add</div>
					</div>
				</div>
			</div>
		);
	}
}
