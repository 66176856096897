import axios from "./AxiosWrapper";

const AuthorizationService = {
	async add(authorization) {
		const response = await axios.post(`authorization/add`, authorization);
		return response.data;
	},
	async update(id, authorization, permissionListingFor) {
		const response = await axios.post(`authorization/update/${id}`, authorization, {
			params: { permissionListingFor }
		});
		return response.data;
	},
	async getSharedAccounts() {
		const response = await axios.get(`authorization/shared`);
		return response.data;
	},
	async getValidEvents(page, issueFor, query) {
		const response = await axios.get("authorization/valid-events", {
			params: {
				page,
				issueFor,
				query
			}
		});
		return response.data;
	},
	async getAuthorizations(provided, permissionListingFor) {
		const response = await axios.get("authorization/list/", {
			params: { provided, permissionListingFor }
		});
		return response.data;
	},
	async getAuthorizationsForUser(userId, userLocation, provided, permissionListingFor) {
		const response = await axios.get(`admin/users/${userId}/permissions/list/`, {
			params: { provided, permissionListingFor, userLocation }
		});
		return response.data;
	},
	async getSharedForUser(userId, userLocation) {
		const response = await axios.get(`admin/users/${userId}/permissions/shared/`, {
			params: { userLocation }
		});
		return response.data;
	},
	async loadAuthorization(id, unregistered = false) {
		const response = await axios.get(`authorization/load/${id}/${unregistered}`);
		return response.data;
	},
	async deleteAuthorization(id, unregistered = false) {
		const response = await axios.delete(`authorization/remove/${id}/${unregistered}`);
		return response.data;
	},
	async getPermissionQrCode(eventId) {
		const response = await axios.get(`authorization/app/access/${eventId}`);
		return response.data;
	},
	async reissueEmail(id) {
		const response = await axios.post(`authorization/email/${id}`);
		return response.data;
	}
};
export default AuthorizationService;
