import { CopyField } from "@/components/CopyField";
import {
	ImageUploadField,
	LegacyInputField,
	InputNumberField,
	RadioField,
	SwitchField,
	TextAreaField
} from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3, P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { BankAccountSelectionDropdown } from "@/events/event/payments/BankAccountSelectionDropdown";
import { closeInvoicingModal } from "@/state/actions/gatewayActions";
import { EventReducerState } from "@/state/reducers/eventReducer";
import { ReduxState } from "@/state/store";
import { getInvoiceDueString } from "@/utils/Format";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { oneRequired, required } from "@/utils/Validators";
import { TaxSettings } from "@hx/utilities";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, change, formValueSelector, reduxForm } from "redux-form";

const formName = "invoicingSettings";

type InvoiceSettings = {
	enabled: boolean;
	linkAccessOnly: boolean;
	sendTicketImmediately: boolean;
	sendManualInvoice: boolean;
	from: {
		companyName: string;
		businessNumber: string;
		address: string;
	};
	payTo: {
		bankTransfer: {
			enabled: boolean;
			accountName: string;
			bsb: string;
			accountNumber: string;
		};
		cheque: {
			enabled: boolean;
			instructions: string;
		};
	};
	dueType: string;
	duePeriodInDays: number;
	logo: any;
};

type ReduxInjectedProps = {
	bankTransfer: InvoiceSettings["payTo"]["bankTransfer"];
	chequesEnabled: boolean;
	currentEvent: EventReducerState;
	dispatch?: any;
	duePeriodInDays: number;
	dueType: string;
	enabled: boolean;
	gateways: ReduxState["gateways"];
	initialValues: InvoiceSettings;
	linkAccessOnly: boolean;
	sendManualInvoice: boolean;
};

type InvoicingModalProps = {
	closeInvoicingModal?: typeof closeInvoicingModal;
	location: string;
	orgPaymentOptions: Record<string, any>;
	paymentOptionsStr: string;
	save: (values: any) => void;
	visible: boolean;
	invoicePayLinkAllowed: boolean;
};

class InvoicingModal extends Component<InjectedFormProps & ReduxInjectedProps & InvoicingModalProps> {
	accountNameRequired = required("Please your account name");
	accountNumberRequired = required("Please your account number");
	addressRequired = required("Please your address");
	bankAccountRequired = required("Please select a bank account");
	bsbRequired = required("Please your BSB");
	companyRequired = required("Please input your company name");
	paymentRequired = oneRequired("Either payment by Bank transfer or Cheque must be enabled", [
		{ label: "Bank transfer", path: "payTo.bankTransfer.enabled" },
		{ label: "Cheque", path: "payTo.cheque.enabled" }
	]);

	businessNumberRequired: any;

	changeFieldValue = (field: string, value: any) => {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	};

	save = (values: Partial<InvoiceSettings>) => {
		const { save, orgPaymentOptions, paymentOptionsStr } = this.props;
		const updateData: Partial<InvoiceSettings> = {
			[paymentOptionsStr]: {
				...orgPaymentOptions,
				invoiceInfo: values
			}
		};
		save(updateData);
	};

	render() {
		const {
			bankTransfer,
			chequesEnabled,
			closeInvoicingModal,
			currentEvent,
			duePeriodInDays,
			dueType,
			enabled,
			handleSubmit,
			linkAccessOnly,
			location,
			sendManualInvoice,
			visible,
			invoicePayLinkAllowed
		} = this.props;

		const invoicingUrl = currentEvent?.event?.slug
			? `${getConfig("TICKETING_URL")}/${currentEvent.event.slug}?iv=true`
			: "";

		const taxIdName = TaxSettings.getGlobalTaxInfo(location).taxIdName;
		this.businessNumberRequired = required(`Please input your ${taxIdName}`);
		const hasInlineAccount = bankTransfer?.bsb?.length || bankTransfer?.accountNumber?.length;

		const payToSection = (
			<>
				<H3>Pay to:</H3>
				<Field
					component={SwitchField}
					description="Accept payments by bank transfer"
					label="Bank transfer"
					name="payTo.bankTransfer.enabled"
					validate={[this.paymentRequired]}
				/>
				{bankTransfer?.enabled ? (
					<>
						{hasInlineAccount ? (
							<div>
								<Field
									component={LegacyInputField}
									label="Account name"
									name="payTo.bankTransfer.accountName"
									required
									validate={[this.accountNameRequired]}
								/>
								<Field
									component={LegacyInputField}
									label="BSB"
									name="payTo.bankTransfer.bsb"
									required
									validate={[this.bsbRequired]}
								/>
								<Field
									component={LegacyInputField}
									label="Account Number"
									name="payTo.bankTransfer.accountNumber"
									required
									validate={[this.accountNumberRequired]}
								/>
							</div>
						) : (
							<BankAccountSelectionDropdown
								changeFieldValue={this.changeFieldValue}
								location={location}
								path="payTo.bankTransfer."
								required
								userId={currentEvent?.event?.userId}
								validate={[this.bankAccountRequired]}
							/>
						)}
					</>
				) : null}

				<Field
					component={SwitchField}
					description="Accept payments by cheque"
					label="Cheque"
					name="payTo.cheque.enabled"
					validate={[this.paymentRequired]}
				/>
				{chequesEnabled ? (
					<Field
						component={TextAreaField}
						label="Send cheque to"
						name="payTo.cheque.instructions"
						validate={[this.accountNumberRequired]}
					/>
				) : null}
			</>
		);

		return (
			<Modal
				destroyOnClose
				footer={
					<LegacyButton
						ariaLabel="Save"
						form="invoicingSettings"
						htmlType="submit"
						key="invoicingSettings"
						onClick={handleSubmit(this.save)}
						style={{ float: "right" }}
						type="primary"
					>
						Save
					</LegacyButton>
				}
				maskClosable={false}
				onCancel={closeInvoicingModal}
				open={visible}
				title="Invoicing Settings"
			>
				<form onSubmit={handleSubmit(this.save)}>
					<Field
						component={SwitchField}
						description="Allow customers to choose to pay by invoice on checkout"
						label="Enable Invoicing"
						name="enabled"
					/>

					{enabled ? (
						<div>
							{sendManualInvoice ? null : (
								<div>
									<H3>Invoice from:</H3>
									<Field
										component={LegacyInputField}
										label="Company name"
										name="from.companyName"
										required
										validate={[this.companyRequired]}
									/>
									<Field
										component={LegacyInputField}
										label={taxIdName}
										name="from.businessNumber"
										required
										validate={[this.businessNumberRequired]}
									/>
									<Field
										component={TextAreaField}
										label="Address"
										name="from.address"
										required
										rows={2}
										validate={[this.addressRequired]}
									/>

									{
										// PayTo section is not shown and collected for events that have invoicePayLink enabled
										invoicePayLinkAllowed ? null : payToSection
									}
									<Field
										button
										component={RadioField}
										defaultValue="raised"
										label="Invoice is due:"
										name="dueType"
										options={[
											{ label: "After purchase", value: "raised" },
											{ label: "Before event", value: "before" },
											{ label: "After event", value: "after" }
										]}
										required
									/>
									<Field label="Days" name="duePeriodInDays" component={InputNumberField} min={1} required />
									<P>{getInvoiceDueString(dueType, duePeriodInDays)}</P>
								</div>
							)}

							<Field
								aspectRatio={2}
								component={ImageUploadField}
								name="logo"
								uploadDescription="Upload your business logo to invoice that is sent to buyer"
								uploadLabel="Logo on buyers invoice"
								uploadOutsideDescription="(Recommended logo size: ratio 2:1, max 10mb)"
							/>
							<Field
								component={SwitchField}
								description="Send tickets to buyer immediately after checkout (before invoice is paid)"
								label="Tickets sent after checkout"
								name="sendTicketImmediately"
							/>

							<Field
								component={SwitchField}
								description="Humanitix will not generate invoices, they will be sent manually"
								label="Send Invoice manually"
								name="sendManualInvoice"
							/>
							<Field
								component={SwitchField}
								description="Customers with a link can choose to pay by invoice on checkout"
								label="Only allow invoices via a link"
								name="linkAccessOnly"
							/>
							{linkAccessOnly && invoicingUrl ? <CopyField value={invoicingUrl} label="Invoice Link" /> : null}
						</div>
					) : null}
				</form>
			</Modal>
		);
	}
}

const selector = formValueSelector(formName);

const ReduxInvoicingModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => scrollToErrors()
})(InvoicingModal as any);

const mapStateToProps = (state: ReduxState, ownProps: InvoicingModalProps): ReduxInjectedProps => {
	const initialValues = ownProps.orgPaymentOptions?.invoiceInfo ?? {};
	initialValues.dueType = initialValues.dueType || "raised";
	initialValues.duePeriodInDays = initialValues.duePeriodInDays || 14;
	return {
		bankTransfer: selector(state, "payTo.bankTransfer"),
		chequesEnabled: selector(state, "payTo.cheque.enabled"),
		currentEvent: state.currentEvent,
		duePeriodInDays: selector(state, "duePeriodInDays"),
		dueType: selector(state, "dueType"),
		enabled: selector(state, "enabled"),
		gateways: state.gateways,
		initialValues,
		linkAccessOnly: selector(state, "linkAccessOnly"),
		sendManualInvoice: selector(state, "sendManualInvoice")
	};
};

const mapDispatchToProps = {
	closeInvoicingModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ReduxInvoicingModal);
