const Pan: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path d="M0 0L24 0 24 24 0 24z" />
				<path
					fill={color}
					d="M20 19.333C20 21.35 18.364 23 16.364 23H9.727c-.982 0-1.909-.394-2.59-1.09L.632 15.242c-.35-.358-.333-.926.034-1.264l.484-.447c.369-.34 1.024-.406 1.459-.15l3.754 2.202V4.667c0-.761.609-1.375 1.363-1.375.755 0 1.364.614 1.364 1.375v6.416H10V2.375C10 1.615 10.61 1 11.364 1c.754 0 1.363.614 1.363 1.375v8.708h.91V3.292c0-.761.608-1.375 1.363-1.375s1.364.614 1.364 1.375v7.791h.909V6.042c0-.761.609-1.375 1.363-1.375.755 0 1.364.614 1.364 1.375v13.291z"
				/>
			</g>
		</svg>
	);
};
export default Pan;
