import { FC, ReactNode, useEffect, useState } from "react";
import { ContentBlock } from "../components/AppBlocks";
import Page from "../components/Page";
import { H3 } from "../components/text/Text";
import EventsService from "../services/EventsService";
import { EventDetails } from "@/state/reducers/eventReducer";
import { useAppSelector } from "@/state/hooks";
import { CurrentUserSchema } from "@hx/console";

export const CATEGORIES = {
	FINANCIAL_AND_REPORTING: "financialAndReporting",
	ORDERS: "orders",
	ATTENDEES: "attendees",
	CHECKIN: "checkin",
	EVENT_INFORMATION: "eventInformation",
	DISCOUNT_CODES: "discountCodes",
	PAY_OUTS: "payOuts",
	APP: "app",
	MY_EVENTS: "myEvents"
};

type PermissionWrapperProps = {
	eventId?: string;
	category: string;
	adminReq?: boolean;
	children: ReactNode;
};

export const PermissionWrapper: FC<PermissionWrapperProps> = ({ eventId = "", category, adminReq, children }) => {
	const [loading, setLoading] = useState(true);
	const [verified, setVerified] = useState(false);
	const user = useAppSelector((state) => state.auth?.user);

	if (!eventId) {
		return null;
	}

	const verifyAccess = async () => {
		const allowed = await verifyAccessByEventId(eventId, category, user, adminReq);
		setLoading(false);
		setVerified(allowed);
	};

	useEffect(() => {
		verifyAccess();
	}, [user]);

	if (loading) {
		return null;
	}

	if (verified) {
		return children;
	}

	return <PermissionDenied />;
};

const verifyAccessByEventId = async (eventId: string, category: string, user: CurrentUserSchema, adminReq = false) => {
	const event = await EventsService?.get(eventId);
	const userId = user?.id;
	const isAdmin = user?.isAdmin;
	if (!event) {
		return false;
	}
	if ((event.userId === userId && !adminReq) || isAdmin) {
		return true;
	} else if (!adminReq) {
		return fullAccessCheck(event, category, user);
	}
	return false;
};

const fullAccessCheck = async (event: EventDetails, category: string, user: CurrentUserSchema) => {
	const { permissions = [] } = user;
	let hasPermission = false;
	if (permissions.length) {
		permissions.forEach((permission) => {
			if (permission.fullAccess) {
				if (permission.fullAccess.userId === event.userId) {
					hasPermission = true;
				}
			}
		});
	}
	if (hasPermission) {
		return true;
	}
	return partialAccessCheck(event, category, user);
};

const partialAccessCheck = (event: EventDetails, category: string, user: CurrentUserSchema) => {
	const { permissions = [] } = user;
	let hasPermission = false;
	if (permissions.length > 0) {
		permissions.forEach((permission) => {
			if (permission.eventAccess) {
				if (
					permission.eventAccess.userId === event.userId &&
					(permission.eventAccess.events.length === 0 ||
						permission.eventAccess.events.find(
							(eventPermission) => String(eventPermission.eventId) === String(event._id)
						)) &&
					permission.eventAccess.access[category]
				) {
					hasPermission = true;
				}
			}
		});
	}
	if (hasPermission) {
		return true;
	}
	return false;
};

const PermissionDenied: FC = () => {
	return (
		<Page pageName="Permission Denied">
			<ContentBlock>
				<H3>You don't have permission to visit this page.</H3>
			</ContentBlock>
		</Page>
	);
};
