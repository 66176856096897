import IconSuccess from "@/assets//ic-success-18.svg";
import { LoadingSpinner } from "@/components/AppBlocks";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3 } from "@/components/text/Text";
import LocationService from "@/services/LocationService";
import { currency } from "@/utils/Format";
import type { AsyncMassRefundResults, PreviewMassRefundResponse } from "@hx/console/massRefunds";
import { HumaniDate } from "@hx/dates";
import { Alert, Collapse, Tag } from "@/ui/antd";
import React, { Fragment } from "react";
import { useAppSelector } from "@/state/hooks";

const Panel = Collapse.Panel;

interface MassRefundPreviewResultMassRefundPreviewResultProps {
	cancelOrEditRefundPreview: () => void;
	isItemsLoading: boolean;
	massRefundData: PreviewMassRefundResponse;
	numberOfAddOnsAffectedResult: number;
	numberOfDonationsAffectedResult: number;
	numberOfTicketsAffectedResult: number;
	totalNumberOfItemsAffected: number;
}

export const MassRefundPreviewResult: React.FC<MassRefundPreviewResultMassRefundPreviewResultProps> = ({
	cancelOrEditRefundPreview,
	isItemsLoading,
	massRefundData,
	numberOfAddOnsAffectedResult,
	numberOfDonationsAffectedResult,
	numberOfTicketsAffectedResult,
	totalNumberOfItemsAffected
}) => {
	const event = useAppSelector((state) => state.currentEvent.event);

	const { dates, merchandise, packagedTickets = [], ticketTypes } = event ?? {};

	const filteredTicketTypes = ticketTypes
		?.concat(packagedTickets)
		.filter((ticket) => !ticket.isDonation && !ticket.deleted);

	const { createdAt = new Date().toISOString(), options, result } = massRefundData;

	const isAsyncMassRefundResults = result && Object.keys(result).includes("eventDateIdsAffected");

	const affectedEventDates = isAsyncMassRefundResults
		? dates?.filter((eventDate) => (result as AsyncMassRefundResults).eventDateIdsAffected!.includes(eventDate._id))
		: [];

	const ticketRefundTypeIsSelect = options?.ticketTypes?.ids?.length;
	const addonRefundTypeIsSelect = options?.merchandise?.ids?.length;

	const ticketTypeNames = options?.ticketTypes?.ids?.map(
		(id: string) => filteredTicketTypes?.find((ticketType) => ticketType._id === id).name
	);

	const addonTypeNames = options?.merchandise?.ids?.map(
		(id: string) => merchandise?.find((addonType) => addonType._id === id).itemName
	);

	const totalNumberOfItemsLoading = isItemsLoading;

	return (
		<div style={{ padding: 10 }}>
			<div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
				<div style={{ display: "flex" }}>
					<img src={IconSuccess} style={{ width: 32, height: 32, marginRight: 8 }} alt="Success Icon" />
					<div style={{ display: "flex", flexDirection: "column" }}>
						<b>
							Preview successfully created at{" "}
							{new HumaniDate(createdAt, "guess", LocationService.getLocation(true)).formatting.dateTime()}
						</b>
						<>Your mass refund will have the following effect:</>
					</div>
				</div>
				<LegacyButton aria-label="Edit" onClick={cancelOrEditRefundPreview} style={{ width: 80 }}>
					Edit
				</LegacyButton>
			</div>

			<div>
				<Collapse ghost>
					<Panel
						header={
							<>
								<b>{affectedEventDates?.length}</b> event dates affected
							</>
						}
						key="1"
					>
						{affectedEventDates?.map((affectedEventDates) => (
							<Fragment key={affectedEventDates._id}>
								<Tag>{affectedEventDates.display?.combined}</Tag>
								<br />
							</Fragment>
						))}
					</Panel>
				</Collapse>
				<hr />
				{options?.ticketTypes?.refund && (
					<div>
						<H3>Tickets</H3>
						<div>
							{ticketRefundTypeIsSelect ? (
								<Collapse ghost>
									<Panel
										header={
											<>
												<b style={{ marginRight: 10 }}>Refund Type</b> Selected tickets
											</>
										}
										key="2"
									>
										{ticketTypeNames.map((name: string) => (
											<Tag key={name}>{name}</Tag>
										))}
									</Panel>
								</Collapse>
							) : (
								<div>
									<b style={{ marginRight: 10 }}>Refund Type</b> All tickets
								</div>
							)}
						</div>
						<div style={{ paddingTop: 10 }}>
							{numberOfTicketsAffectedResult ? (
								<>
									{isItemsLoading ? (
										<LoadingSpinner />
									) : (
										<b>
											{numberOfTicketsAffectedResult} tickets to be refunded{" "}
											{options.ticketTypes.cancel && " & cancelled"}
										</b>
									)}
								</>
							) : null}
						</div>
						<hr />
					</div>
				)}
				{options?.merchandise?.refund && (
					<div>
						<H3>Add-ons</H3>
						{addonRefundTypeIsSelect ? (
							<Collapse ghost>
								<Panel
									header={
										<>
											<b style={{ marginRight: 10 }}>Refund Type</b> Selected add-ons
										</>
									}
									key="3"
								>
									{addonTypeNames.map((name: string) => (
										<Tag key={name}>{name}</Tag>
									))}
								</Panel>
							</Collapse>
						) : (
							<div>
								<b style={{ marginRight: 10 }}>Refund Type</b> All add-ons
							</div>
						)}
						<div style={{ paddingTop: 10 }}>
							{numberOfAddOnsAffectedResult ? (
								<>
									{isItemsLoading ? (
										<LoadingSpinner />
									) : (
										<b>
											{numberOfAddOnsAffectedResult} add-ons to be refunded{" "}
											{options.merchandise.cancel && " & cancelled"}{" "}
										</b>
									)}
								</>
							) : null}
						</div>
						<hr />
					</div>
				)}
				{options?.donations?.refund && (
					<>
						<H3>Donations</H3>
						<div>
							<b style={{ marginRight: 10 }}>Refund Type</b> All donations
						</div>
						<div style={{ paddingTop: 10 }}>
							{numberOfDonationsAffectedResult ? (
								<>
									{isItemsLoading ? (
										<LoadingSpinner />
									) : (
										<b>{numberOfDonationsAffectedResult} donations to be refunded </b>
									)}
								</>
							) : null}
						</div>
						<hr />
					</>
				)}
				<div>
					{totalNumberOfItemsLoading ? (
						<LoadingSpinner />
					) : (
						<b>{totalNumberOfItemsAffected} total items to be refunded </b>
					)}
					<hr />
				</div>
				<div>
					<b>{result?.ordersAffected} total orders affected</b>
				</div>
				<hr />
				{result?.fees && result?.fees > 0 ? (
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
						<b>Fees refunded</b>
						<b>{currency(result.fees)}</b>
					</div>
				) : null}
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<b>Total refund amount</b>
					<b>{currency(result?.total)}</b>
				</div>
				<div style={{ paddingTop: 32, paddingBottom: 32 }}>
					<Alert
						message={
							<div style={{ padding: 5 }}>
								<b>Note: Total refund amount may vary.</b>
								<br />
								<>
									This preview reflects the mass refund status as of the time it was generated. Processing a mass refund
									will include any relevant orders created thereafter.
								</>
							</div>
						}
						type="warning"
						showIcon
					/>
				</div>
			</div>
		</div>
	);
};
