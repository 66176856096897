import { css } from "@emotion/css";
import { Radio, Tabs } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "../components/AppBlocks";
import {
	ColourField,
	CountryField,
	ImageUploadField,
	LegacyInputField,
	InputNumberField,
	RadioField,
	SelectField,
	SwitchField,
	TextAreaField
} from "../components/Fields";
import FormBar from "../components/FormBar";
import LoadErrorView from "../components/LoadErrorView";
import NoImage from "../components/NoImage";
import Picture from "../components/Picture";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, P } from "../components/text/Text";
import LocationService from "../services/LocationService";
import { getUserEvents } from "../state/actions/eventActions";
import { createGiftCard, getGiftCard, updateGiftCard } from "../state/actions/giftCardActions";
import { onOverrideLocationSet } from "../state/actions/locationActions";
import { getGiftCardEnabledCountries } from "../utils/GiftcardUtilties";
import { required } from "../utils/Validators";
import GiftCard from "./GiftCard";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const giftCardPreviewStyle = css({
	width: 300,
	height: 180,
	"@media(max-width:600px)": {
		width: 240,
		height: 144
	}
});

const giftCardImagePreviewStyle = css({
	width: 300,
	height: 150,
	marginBottom: 12,
	"@media(max-width:600px)": {
		width: 238,
		height: 120
	}
});

const giftCardStyling = css({
	display: "flex",
	flexWrap: "nowrap",
	"@media(max-width:600px)": {
		flexWrap: "wrap"
	}
});

const formInputHalfScreen = css({
	width: "50%",
	"@media(max-width:600px)": {
		width: "100%"
	}
});

const { TabPane } = Tabs;
const formName = "giftCardForm";
const nameRequired = required("Please input a gift card name");
const valueRequired = required("Please input a gift card value");
const expiryRequired = required("Please input a expiry value");
const companyNameRequired = required("Please input a company name");
const maxQuantityRequired = required("Please input a max quantity");
const maxQuantityPerOrderRequired = required("Please input a max quantity per order");

class GiftCardForm extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.isUpdate = props.location.pathname.includes("/update");
	}
	componentWillMount() {
		const { params, currentUser, getGiftCard } = this.props;
		const userId = currentUser.user.id;
		this.loadEvents();
		if (this.isUpdate) {
			const giftCardId = params?.id;
			getGiftCard(userId, giftCardId);
		}
	}

	loadEvents = () => {
		const { currentUser, getUserEvents, location } = this.props;
		const userId = currentUser.user.id;
		getUserEvents(userId, "_id name", false, location);
	};

	save(data) {
		const { createGiftCard, updateGiftCard, currentUser, params, navigate } = this.props;
		const userId = currentUser.user.id;
		if (this.isUpdate) {
			const giftCardId = params?.id;
			if (data.validFor === 0) {
				//somehow jeremy was able to set this to zero
				// despite there being a min value
				data.validFor = 1;
			}
			updateGiftCard(userId, giftCardId, data, navigate);
		} else {
			createGiftCard(userId, data, navigate);
		}
	}

	onCancel() {
		this.props.navigate("/console/promote/gift-cards");
	}

	changeLocation = () => {
		const { onOverrideLocationSet, location } = this.props;
		onOverrideLocationSet(location);
	};

	render() {
		const { giftCard, userEvents, eventIds, style, companyLogo } = this.props;
		const { loading, error } = giftCard;
		const eventOptions = [{ value: "all", label: "All events" }].concat(
			(userEvents.events || []).map((event) => {
				return { value: event._id, label: event.name };
			})
		);
		return (
			<div>
				<H1>Gift cards</H1>
				<LoadErrorView loading={giftCard.loading} error={giftCard.error}>
					<Form
						loading={loading || userEvents.loading}
						error={error || userEvents.error}
						save={this.save}
						eventOptions={eventOptions}
						eventIds={eventIds}
						onCancel={this.onCancel}
						style={style}
						companyLogo={companyLogo}
						loadEvents={this.loadEvents}
						changeLocation={this.changeLocation}
						isUpdate={this.isUpdate}
					/>
				</LoadErrorView>
			</div>
		);
	}
}

class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colourPickerOpen: false
		};
	}
	changeFieldValue = (field, value) => {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	};
	onPremadeStyleChange = (styleName) => {
		this.changeFieldValue("style.type", styleName);
	};

	onColourPickerClicked = () => {
		this.setState({ colourPickerOpen: !this.state.colourPickerOpen });
	};

	render() {
		const { save, handleSubmit, loading, error, onCancel, style, companyLogo, loadEvents, location, changeLocation } =
			this.props;
		const { colourPickerOpen } = this.state;
		return (
			<LoadErrorView loading={loading} error={error}>
				<form onSubmit={handleSubmit(save)} id={formName}>
					<ContentBlock>
						<Tabs defaultActiveKey="1" style={{ minHeight: colourPickerOpen ? 550 : 0 }}>
							<TabPane tab="Settings" key="1">
								<GiftCardSettings
									eventOptions={this.props.eventOptions}
									loadEvents={loadEvents}
									location={location}
									changeFieldValue={this.changeFieldValue}
									changeLocation={changeLocation}
								/>
							</TabPane>
							<TabPane tab="Styling" key="2">
								<GiftCardStyling
									onPremadeStyleChange={this.onPremadeStyleChange}
									style={style}
									onColourPickerClicked={this.onColourPickerClicked}
									companyLogo={companyLogo}
								/>
							</TabPane>
						</Tabs>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "left"
							}}
							type="secondary"
							id="cancelButton"
							size="large"
							onClick={onCancel}
							disabled={loading}
							ariaLabel="Close"
						>
							Close
						</LegacyButton>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							size="large"
							disabled={loading}
							ariaLabel="Save"
						>
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const GiftCardSettings = ({ eventOptions, location, loadEvents, changeFieldValue, changeLocation }) => {
	return (
		<div>
			<div className={formInputHalfScreen}>
				<Field
					name="name"
					label="Gift Card Name"
					description="Name that will show up in email on gift card (max 15 characters)"
					required
					component={LegacyInputField}
					validate={nameRequired}
					maxLength={15}
				/>
				<Field
					name="price"
					label="Value"
					required
					description="Gift card price"
					component={InputNumberField}
					validate={valueRequired}
					min={0}
					precision={2}
				/>
				<CountryField
					location={location}
					onLocationChange={() => {
						changeFieldValue("eventIds", []);
						loadEvents();
						changeLocation();
					}}
					enabledCountries={getGiftCardEnabledCountries()}
				/>
				<Field
					label="Select Events"
					description="Which events will this gift card apply to?"
					mode="multiple"
					name="eventIds"
					required
					placeholder="Please select"
					component={SelectField}
					options={eventOptions}
				/>
				<Field
					name="validFor"
					label="Valid until"
					required
					description="Select number of months this gift card will be valid"
					component={InputNumberField}
					validate={expiryRequired}
					min={1}
				/>
				<Field
					name="companyName"
					label="Company Name"
					required
					description="The name of the company who the gift card is from"
					component={LegacyInputField}
					validate={companyNameRequired}
				/>
				<Field
					name="companyLogo"
					label="Company Logo"
					component={ImageUploadField}
					uploadLabel="Upload image"
					aspectRatio={1}
				/>
			</div>
			<Field name="description" label="Description" component={TextAreaField} style={{ height: 72 }} />
			<Field
				name="quantity"
				label="Gift Card Quantity"
				description="Limit the number of gift cards you want to sell"
				component={InputNumberField}
				min={0}
				required={maxQuantityRequired}
			/>
			<Field
				name="maxPerOrder"
				label="Max gift cards per order"
				description="Limit the number of gift cards that can be purchased in the same order"
				component={InputNumberField}
				min={0}
				required={maxQuantityPerOrderRequired}
			/>
		</div>
	);
};

const GiftCardStyling = ({ onPremadeStyleChange, style, onColourPickerClicked, companyLogo }) => {
	let ImagePreview = null;
	if (style.displayBanner) {
		ImagePreview = (
			<div className={giftCardImagePreviewStyle}>
				{style.banner && style.banner.handle ? (
					<Picture handle={style.banner.handle} alt="Banner Image" />
				) : (
					<NoImage />
				)}
			</div>
		);
	}
	return (
		<div className={giftCardStyling}>
			<div style={{ width: "100%" }}>
				<P>
					Add some extra personality to your gift card. Choose one of our prebuilt themes or create your own custom
					style.
				</P>
				<Field
					name="style.type"
					component={RadioField}
					button
					defaultActiveKey="default"
					options={[
						{
							label: "Themes",
							value: style.type !== "custom" ? style.type : "default"
						},
						{
							label: "Custom",
							value: "custom"
						}
					]}
				/>
				{style.type === "custom" ? (
					<CustomStyling style={style} onColourPickerClicked={onColourPickerClicked} />
				) : (
					<PremadeStyling onPremadeStyleChange={onPremadeStyleChange} style={style} />
				)}
			</div>
			<div style={{ display: "flex", flexGrow: 1 }}>
				{style.type === "custom" ? (
					<div
						style={{
							padding: 12,
							border: "solid 2px",
							borderRadius: 4,
							borderColor: "#dedede",
							height: "fit-content"
						}}
					>
						{ImagePreview}
						<div className={giftCardPreviewStyle}>
							<GiftCard
								backgroundColor={style.backgroundColor}
								amount="-"
								expiryDate="-"
								cardNumber="123456ABC"
								name="GIFT CARD"
								image={style.displayLogo ? companyLogo : false}
							/>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

const CustomStyling = ({ style, onColourPickerClicked }) => {
	return (
		<div>
			<Field
				label="Background Colour"
				name="style.backgroundColor"
				component={ColourField}
				marginTop={16}
				onClick={onColourPickerClicked}
				//style={{ width: 60, height: 36 }}
			/>
			<Field
				name="style.displayLogo"
				label="Display logo on the gift card"
				component={SwitchField}
				defaultValue={false}
			/>
			<Field
				name="style.displayBanner"
				label="Display gift card banner image"
				component={SwitchField}
				defaultValue={false}
			/>
			{style.displayBanner ? (
				<div style={{ maxWidth: 280, marginTop: 18 }}>
					<Field
						name="style.banner"
						component={ImageUploadField}
						uploadLabel="Upload image"
						aspectRatio={2}
						style={{ width: 240, height: 120 }}
					/>
				</div>
			) : null}
		</div>
	);
};

const PremadeStyling = ({ onPremadeStyleChange, style }) => {
	const radioStyle = {
		display: "flex"
	};
	const options = [
		{ value: "default", backgroundColor: "#353337", name: "Default" },
		{ value: "royal", backgroundColor: "#8D721E", name: "Royal" },
		{ value: "breeze", backgroundColor: "#1E72D6", name: "Breeze" }
	];
	return (
		<Radio.Group
			value={style.type}
			onChange={({ target }) => onPremadeStyleChange(target.value)}
			style={{ display: "flex", flexWrap: "wrap" }}
		>
			{options.map((opt) => (
				<div key={opt.value} style={{ display: "flex", flexGrow: 1, marginTop: 18 }}>
					<Radio style={radioStyle} value={opt.value} />
					<div>
						<div>{opt.name}</div>
						<div style={{ width: 200, height: 120 }}>
							<GiftCard
								backgroundColor={opt.backgroundColor}
								amount="-"
								expiryDate="-"
								cardNumber="123456ABC"
								name="GIFT CARD"
							/>
						</div>
					</div>
				</div>
			))}
		</Radio.Group>
	);
};

// Decorate with redux-form

Form = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Form);
const selector = formValueSelector(formName);

Form = connect((state, props) => {
	const initialValues =
		state.giftCard && state.giftCard.giftCardData && props.isUpdate
			? state.giftCard.giftCardData
			: {
					location: LocationService.getLocation(true),
					style: { type: "default", backgroundColor: "#854498" }
			  };
	return {
		initialValues,
		location: selector(state, "location")
	};
})(Form);

export default connect(
	(state) => ({
		eventIds: selector(state, "eventIds"),
		style: selector(state, "style"),
		companyLogo: selector(state, "companyLogo"),
		location: selector(state, "location") || LocationService.getLocation(true),
		giftCard: state.giftCard,
		userEvents: state.userEvents,
		currentUser: state.auth
	}),
	{
		updateGiftCard,
		createGiftCard,
		getGiftCard,
		getUserEvents,
		onOverrideLocationSet
	}
)(withRouterHooks(GiftCardForm));
