import ExportBar from "@/components/ExportBar";
import IconButton from "@/components/IconButton";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { download, find, openImport, remove } from "@/state/actions/followersActions";
import { updateSearch } from "@/state/actions/searchActions";
import { Button } from "@/ui/Button";
import { Popconfirm, Table } from "@/ui/antd";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import ImportModal from "./ImportModal";

const Followers = ({ followers, organiserId, find, download, remove, search, openImport }) => {
	const { isAdmin } = useCurrentUser();

	const loadPage = (page, passedSearchState) => {
		const query = passedSearchState ? passedSearchState.query : search.query;
		updateSearch({ page }, true);
		find(page, organiserId, query);
	};

	const columns = [
		{
			title: "First Name",
			dataIndex: "firstName",
			key: "firstName"
		},
		{
			title: "Last Name",
			dataIndex: "lastName",
			key: "lastName"
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email"
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			fixed: "right",
			width: 100,
			render: (_, data) => (
				<Popconfirm
					title={`Are you sure to delete ${data.email}`}
					onConfirm={() => remove(organiserId, data._id)}
					okText="Yes"
					cancelText="No"
				>
					<IconButton icon="delete" tooltip="Delete" showTooltip ariaLabel="Delete" />
				</Popconfirm>
			)
		}
	];

	const messages = followers.list.loading ? { emptyText: "Loading" } : { emptyText: "No Followers" };
	return (
		<div>
			<ImportModal />
			<SearchRefinements
				defaultSearch={null}
				placeholder="email address"
				load={loadPage}
				download={{
					download: () => {
						download(organiserId, search.query);
					},
					loading: followers.download.loading,
					tooltip: "Export followers"
				}}
				extras={
					isAdmin ? (
						<Button variant="tertiary" iconLeft={<PlusOutlined />} ariaLabel="Import Followers" onClick={openImport}>
							Import Followers
						</Button>
					) : null
				}
			/>
			<div style={{ minWidth: "100%", overflowX: "auto" }}>
				<Table
					columns={columns}
					dataSource={followers.list.data}
					rowKey="_id"
					locale={messages}
					scroll={{ x: "max-content" }}
					pagination={false}
				/>
			</div>
			<SimplePagination
				pageAction={loadPage}
				currentPage={followers.list.page}
				totalPages={followers.list.data?.length < 20 ? followers.list.page : followers.list.page + 1}
			/>
			<ExportBar
				buttonText="Export followers"
				buttonClick={() => {
					download(organiserId, search.query);
				}}
				loading={followers.download.loading}
			/>
		</div>
	);
};

export default connect(
	(state) => {
		return {
			followers: state.followers,
			search: state.search
		};
	},
	{ find, download, remove, openImport }
)(Followers);
