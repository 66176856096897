import noEvents from "@/assets/ic-calendar-no-events.svg";
import NoResultsLarge from "@/components/noResults/NoResultsLarge";

const NoEventsFound = ({ title, createEvent, locationSelection }) => {
	return (
		<NoResultsLarge
			title={title}
			img={{ src: noEvents, alt: "No event found" }}
			action={createEvent}
			actionTxt="Create event"
			locationSelection={locationSelection}
		/>
	);
};

export default NoEventsFound;
