import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { currency } from "../../utils/Format";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";
import withReportProvider from "@/hoc/withReportProvider";

class AccessCodes extends Component {
	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;
		const { load, loadPage, downloadCsv, local } = this.props;

		const columns = [
			...(!local
				? [
						{
							title: "Event Name",
							dataIndex: "eventName",
							key: "eventName",
							render: (eventName, record) => {
								return <Link to={`/console/my-events/${record.eventId}`}>{eventName}</Link>;
							}
						}
				  ]
				: []),
			{
				title: "Code",
				dataIndex: "code",
				key: "code",
				render: (code, record) => {
					return <Link to={`/console/my-events/${record.eventId}/attendees?query=accesscode%253A${code}`}>{code}</Link>;
				}
			},
			{
				title: "Quantity",
				dataIndex: "quantity",
				key: "quantity"
			},
			{
				title: "Used",
				dataIndex: "used",
				key: "used"
			},
			{
				title: "Cancelled",
				dataIndex: "cancelled",
				key: "cancelled"
			},
			{
				title: "Remaining",
				dataIndex: "remaining",
				key: "remaining"
			},
			{
				title: "Earnings",
				dataIndex: "amount",
				key: "amount",
				render: (value) => currency(value)
			}
		];

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						searchPlaceholder="Access code"
						selectEvent={!local}
						getReportButtonText="Get report"
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
								{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
							</LegacyButton>
						</div>
						<Table
							columns={columns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export const EventAccessCodesReport = withReportProvider(AccessCodes, "Access codes", "access-codes", null, true);
export const GlobalAccessCodesReport = withReportProvider(AccessCodes, "Access codes", "access-codes");
