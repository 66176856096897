import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField } from "../components/Fields";
import LoadErrorView from "../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import { closeDeleteModal, deleteTour } from "../state/actions/tourActions";
import { required } from "../utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class TourDeleteModal extends Component {
	nameRequired = required("Please enter your tour name");
	nameMatch = (value) => {
		const { tours } = this.props;
		const tour = tours.tour.data;
		return value === tour.name ? undefined : "The name of the tour must match your current tour";
	};

	deleteTour = () => {
		const { deleteTour, tours, navigate } = this.props;
		const tour = tours.tour.data;
		deleteTour(tour._id, navigate);
	};

	cancel = () => {
		const { closeDeleteModal } = this.props;
		closeDeleteModal();
	};

	render() {
		const { handleSubmit, tours } = this.props;
		const tour = tours.tour.data;
		const visible = tours.deleteTour.modalOpen;
		if (!tour) {
			return null;
		}
		return (
			<Modal
				open={visible}
				title="Delete Tour"
				onCancel={this.cancel}
				destroyOnClose
				footer={
					<ButtonsBar footer>
						<LegacyButton key="cancel" onClick={this.cancel} ariaLabel="Cancel">
							Cancel
						</LegacyButton>
						<LegacyButton
							key="submit"
							type="warning"
							loading={tours.deleteTour.loading}
							onClick={handleSubmit(this.deleteTour)}
							ariaLabel="Delete"
						>
							Delete
						</LegacyButton>
					</ButtonsBar>
				}
			>
				<LoadErrorView
					loading={tours.deleteTour.loading}
					error={tours.deleteTour.error}
					retryAction={() => this.deleteEvent()}
				>
					<form>
						<Field
							name="name"
							label="Tour Name"
							description={`Please confirm the name of the tour you wish to delete (${tour.name})`}
							component={LegacyInputField}
							validate={[this.nameRequired, this.nameMatch]}
						/>
					</form>
				</LoadErrorView>
			</Modal>
		);
	}
}

// Decorate with redux-form
const formName = "deleteTour";
TourDeleteModal = reduxForm({
	form: formName
})(TourDeleteModal);

export default connect(
	(state) => ({
		tours: state.tours
	}),
	{ deleteTour, closeDeleteModal }
)(withRouterHooks(TourDeleteModal));
