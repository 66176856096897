import {
	ASSOCIATE_USER_REFERRAL_ERROR,
	ASSOCIATE_USER_REFERRAL_REQUEST,
	DELETE_USER_REFERRAL_PAYMENT_ERROR,
	DELETE_USER_REFERRAL_PAYMENT_REQUEST,
	DELETE_USER_REFERRAL_PAYMENT_SUCCESS,
	GET_USER_REFERRAL_ERROR,
	GET_USER_REFERRAL_REQUEST,
	GET_USER_REFERRAL_SUCCESS,
	UPDATE_USER_REFERRAL_ERROR,
	UPDATE_USER_REFERRAL_REQUEST,
	UPDATE_USER_REFERRAL_SUCCESS,
	USER_REFERRAL_PAYMENT_ERROR,
	USER_REFERRAL_PAYMENT_REQUEST,
	USER_REFERRAL_PAYMENT_SUCCESS
} from "../actions/referralActions";

const initialState = {
	loading: false,
	referral: {},
	error: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		//get user
		case ASSOCIATE_USER_REFERRAL_REQUEST:
		case GET_USER_REFERRAL_REQUEST:
			return {
				...state,
				loading: true,
				referral: {},
				error: false
			};
		case GET_USER_REFERRAL_SUCCESS:
			return {
				...state,
				loading: false,
				referral: action.referral,
				error: false
			};
		case ASSOCIATE_USER_REFERRAL_ERROR:
		case GET_USER_REFERRAL_ERROR:
			return {
				...state,
				loading: false,
				referral: {},
				error: action.error
			};
		//update user
		case UPDATE_USER_REFERRAL_REQUEST:
			return {
				...state,
				loading: true,
				referral: {},
				error: false
			};
		case UPDATE_USER_REFERRAL_SUCCESS:
			return {
				...state,
				loading: false,
				referral: action.referral,
				error: false
			};
		case UPDATE_USER_REFERRAL_ERROR:
			return {
				...state,
				loading: false,
				referral: {},
				error: action.error
			};
		//managePayment
		case USER_REFERRAL_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
				referral: {},
				error: false
			};
		case USER_REFERRAL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				referral: action.referral,
				error: false
			};
		case USER_REFERRAL_PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				referral: {},
				error: action.error
			};
		//removePayment
		case DELETE_USER_REFERRAL_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
				referral: {},
				error: false
			};
		case DELETE_USER_REFERRAL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				referral: action.referral,
				error: false
			};
		case DELETE_USER_REFERRAL_PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				referral: {},
				error: action.error
			};
		default:
			return state;
	}
};
