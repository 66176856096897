import axios from "./AxiosWrapper";

const VirtualEventHubService = {
	async get(eventId) {
		const response = await axios.get(`events/virtual-event-hub/${eventId}`);
		return response.data;
	},
	async update(eventId, virtualEventHub) {
		const response = await axios.post(`events/virtual-event-hub/${eventId}`, virtualEventHub);
		return response.data;
	},
	async create(eventId) {
		const response = await axios.post(`events/virtual-event-hub/${eventId}/create`);
		return response.data;
	},

	async savePreview(eventId, data) {
		const response = await axios.post(`events/virtual-event-hub/savePreview/${eventId}`, data);
		return response.data;
	}
};
export default VirtualEventHubService;
