import {
	BULK_CHECKIN_ERROR,
	BULK_CHECKIN_REQUEST,
	BULK_CHECKIN_SUCCESS,
	CHECKIN_ERROR,
	CHECKIN_REQUEST,
	CHECKIN_SUCCESS,
	GET_CHECKIN_COUNT_ERROR,
	GET_CHECKIN_COUNT_REQUEST,
	GET_CHECKIN_COUNT_SUCCESS,
	GET_SCANNING_COUNT_ERROR,
	GET_SCANNING_COUNT_REQUEST,
	GET_SCANNING_COUNT_SUCCESS
} from "../actions/checkInActions";

const initialState = {
	loading: false,
	countError: false,
	checkInError: false,
	checkedIn: 0,
	total: 0,
	scanningCount: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CHECKIN_REQUEST:
			return {
				...state,
				currentId: action.ticket,
				loading: true,
				checkInError: false
			};
		case CHECKIN_SUCCESS:
			return {
				...state,
				currentId: "",
				loading: false,
				checkInError: false
			};
		case CHECKIN_ERROR:
			return {
				...state,
				loading: false,
				checkInError: action.error
			};
		case BULK_CHECKIN_REQUEST:
			return {
				...state,
				loading: true,
				checkInError: false
			};
		case BULK_CHECKIN_SUCCESS:
			return {
				...state,
				currentId: "",
				loading: false,
				checkInError: false
			};
		case BULK_CHECKIN_ERROR:
			return {
				...state,
				loading: false,
				checkInError: action.error
			};
		case GET_CHECKIN_COUNT_REQUEST:
			return {
				...state,
				loading: true,
				countError: false
			};
		case GET_CHECKIN_COUNT_SUCCESS:
			return {
				...state,
				...action.result,
				loading: false,
				countError: false
			};
		case GET_CHECKIN_COUNT_ERROR:
			return {
				...state,
				loading: false,
				countError: action.error
			};
		case GET_SCANNING_COUNT_REQUEST:
			return {
				...state,
				loading: true,
				scanningCount: []
			};
		case GET_SCANNING_COUNT_SUCCESS:
			return {
				...state,
				loading: false,
				scanningCount: action.result
			};
		case GET_SCANNING_COUNT_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				scanningCount: []
			};
		default:
			return state;
	}
};
