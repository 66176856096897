import {
	DELETE_BOXOFFICE_TERMINAL_ERROR,
	DELETE_BOXOFFICE_TERMINAL_REQUEST,
	DELETE_BOXOFFICE_TERMINAL_SUCCESS,
	GET_BOXOFFICE_TERMINALS_ERROR,
	GET_BOXOFFICE_TERMINALS_REQUEST,
	GET_BOXOFFICE_TERMINALS_SUCCESS
} from "../actions/boxOfficeActions";

const initialState = {
	terminals: {
		loading: false,
		error: undefined,
		page: 1,
		data: null
	},
	terminalDeletion: {
		loading: false,
		error: undefined,
		success: false
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_BOXOFFICE_TERMINALS_REQUEST:
			return {
				...state,
				terminals: {
					...state.terminals,
					loading: true,
					error: undefined
				}
			};
		case GET_BOXOFFICE_TERMINALS_SUCCESS:
			return {
				...state,
				terminals: {
					...state.terminals,
					loading: false,
					error: undefined,
					page: action.page,
					data: action.data
				},
				terminalDeletion: initialState.terminalDeletion
			};
		case GET_BOXOFFICE_TERMINALS_ERROR:
			return {
				...state,
				terminals: {
					...state.terminals,
					loading: false,
					error: action.error
				},
				terminalDeletion: initialState.terminalDeletion
			};
		case DELETE_BOXOFFICE_TERMINAL_REQUEST:
			return {
				...state,
				terminalDeletion: {
					...state.terminalDeletion,
					loading: true,
					error: undefined
				}
			};
		case DELETE_BOXOFFICE_TERMINAL_SUCCESS:
			return {
				...state,
				terminalDeletion: {
					...state.terminalDeletion,
					loading: false,
					error: undefined,
					success: true
				}
			};

		case DELETE_BOXOFFICE_TERMINAL_ERROR:
			return {
				...state,
				terminalDeletion: {
					...state.terminalDeletion,
					loading: false,
					error: action.error,
					success: false
				}
			};

		default:
			return state;
	}
};
