import { AuthorizationActionTypes } from "@/state/actions/authorizationActions";

export interface AuthorizationReducerState {
	loading: boolean;
	error: boolean | string;
	eventsLoading: boolean;
	eventsError: boolean | string;
	events: { [user: string]: any[] };
	authorizations: any[];
	sharedAccounts: any[];
	selectedAuthorization: boolean | any;
	saved: boolean;
	deleted: boolean;
	qr: boolean | any;
}

const initialState: AuthorizationReducerState = {
	loading: false,
	error: false,
	eventsLoading: false,
	eventsError: false,
	events: {},
	authorizations: [],
	sharedAccounts: [],
	selectedAuthorization: false,
	saved: false,
	deleted: false,
	qr: false
};

interface BasicAuthorizationAction {
	type:
		| typeof AuthorizationActionTypes.SEND_AUTHORIZATION_REQUEST
		| typeof AuthorizationActionTypes.CLEAR_AUTHORIZATION
		| typeof AuthorizationActionTypes.SEND_AUTHORIZATION_SUCCESS
		| typeof AuthorizationActionTypes.UPDATE_AUTHORIZATION_REQUEST
		| typeof AuthorizationActionTypes.UPDATE_AUTHORIZATION_SUCCESS
		| typeof AuthorizationActionTypes.DELETE_AUTHORIZATION_REQUEST
		| typeof AuthorizationActionTypes.DELETE_AUTHORIZATION_SUCCESS
		| typeof AuthorizationActionTypes.GET_EVENTS_AUTH_REQUEST
		| typeof AuthorizationActionTypes.GET_AUTHORIZATIONS_REQUEST
		| typeof AuthorizationActionTypes.LOAD_AUTHORIZATION_REQUEST
		| typeof AuthorizationActionTypes.GET_PERMISSION_QR_CODE_REQUEST
		| typeof AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_REQUEST
		| typeof AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_SUCCESS
		| typeof AuthorizationActionTypes.GET_SHARED_ACCOUNTS_REQUEST
		| typeof AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_REQUEST
		| typeof AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_REQUEST;
}

interface AuthorizationErrorAction {
	type:
		| typeof AuthorizationActionTypes.SEND_AUTHORIZATION_ERROR
		| typeof AuthorizationActionTypes.UPDATE_AUTHORIZATION_ERROR
		| typeof AuthorizationActionTypes.DELETE_AUTHORIZATION_ERROR
		| typeof AuthorizationActionTypes.GET_EVENTS_AUTH_ERROR
		| typeof AuthorizationActionTypes.GET_AUTHORIZATIONS_ERROR
		| typeof AuthorizationActionTypes.LOAD_AUTHORIZATION_ERROR
		| typeof AuthorizationActionTypes.GET_PERMISSION_QR_CODE_ERROR
		| typeof AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_ERROR
		| typeof AuthorizationActionTypes.GET_SHARED_ACCOUNTS_ERROR;
	error: any;
}

type GetEventsAuthSuccessAction = {
	type: typeof AuthorizationActionTypes.GET_EVENTS_AUTH_SUCCESS;
	events: any;
	toAppend: boolean;
};

type GetAuthorizationsSuccessAction = {
	type: typeof AuthorizationActionTypes.GET_AUTHORIZATIONS_SUCCESS;
	authorizations: any;
};

type LoadAuthorizationSuccessAction = {
	type: typeof AuthorizationActionTypes.LOAD_AUTHORIZATION_SUCCESS;
	authorization: any;
};

type GetPermissionQRCodeSuccessAction = {
	type: typeof AuthorizationActionTypes.GET_PERMISSION_QR_CODE_SUCCESS;
	qr: any;
};

type GetSharedAccountsSuccessAction = {
	type: typeof AuthorizationActionTypes.GET_SHARED_ACCOUNTS_SUCCESS;
	sharedAccounts: any;
};

type AuthorizationReducerAction =
	| BasicAuthorizationAction
	| AuthorizationErrorAction
	| GetEventsAuthSuccessAction
	| GetAuthorizationsSuccessAction
	| LoadAuthorizationSuccessAction
	| GetPermissionQRCodeSuccessAction
	| GetSharedAccountsSuccessAction;

export default (state = initialState, action: AuthorizationReducerAction) => {
	switch (action.type) {
		case AuthorizationActionTypes.SEND_AUTHORIZATION_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case AuthorizationActionTypes.CLEAR_AUTHORIZATION:
			return {
				...state,
				events: {},
				authorizations: [],
				selectedAuthorization: false,
				saved: false,
				deleted: false
			};
		case AuthorizationActionTypes.SEND_AUTHORIZATION_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case AuthorizationActionTypes.SEND_AUTHORIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				saved: true
			};
		case AuthorizationActionTypes.UPDATE_AUTHORIZATION_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case AuthorizationActionTypes.UPDATE_AUTHORIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				saved: true
			};
		case AuthorizationActionTypes.UPDATE_AUTHORIZATION_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case AuthorizationActionTypes.DELETE_AUTHORIZATION_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				deleted: false
			};
		case AuthorizationActionTypes.DELETE_AUTHORIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				deleted: true
			};
		case AuthorizationActionTypes.DELETE_AUTHORIZATION_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case AuthorizationActionTypes.GET_EVENTS_AUTH_REQUEST:
			return {
				...state,
				eventsLoading: true,
				eventsError: false
			};
		case AuthorizationActionTypes.GET_EVENTS_AUTH_SUCCESS: {
			let events = action.events;

			if (action.toAppend) {
				const eventsMap: { [user: string]: any[] } = {};
				Object.keys(action.events ?? {})?.map((user) => {
					const cachedUserEvents = state.events[user] ?? [];
					const newUserEvents = action.events?.[user] ?? [];

					const combined = [...new Set([...cachedUserEvents, ...newUserEvents])].filter(
						(v, i, a) => a.findIndex((t) => t._id === v._id) === i
					);

					eventsMap[user] = combined;
				});
				events = eventsMap;
			}

			return {
				...state,
				events,
				eventsLoading: false,
				eventsError: false
			};
		}
		case AuthorizationActionTypes.GET_EVENTS_AUTH_ERROR:
			return {
				...state,
				eventsLoading: false,
				eventsError: action.error
			};
		case AuthorizationActionTypes.GET_AUTHORIZATIONS_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				authorizations: []
			};
		case AuthorizationActionTypes.GET_AUTHORIZATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				authorizations: action.authorizations
			};
		case AuthorizationActionTypes.GET_AUTHORIZATIONS_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case AuthorizationActionTypes.LOAD_AUTHORIZATION_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case AuthorizationActionTypes.LOAD_AUTHORIZATION_REQUEST:
			return {
				...state,
				loading: true
			};
		case AuthorizationActionTypes.LOAD_AUTHORIZATION_SUCCESS: {
			return {
				...state,
				selectedAuthorization: action.authorization,
				loading: false
			};
		}
		case AuthorizationActionTypes.GET_PERMISSION_QR_CODE_ERROR: {
			return {
				...state,
				loading: false,
				error: action.error
			};
		}
		case AuthorizationActionTypes.GET_PERMISSION_QR_CODE_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}
		case AuthorizationActionTypes.GET_PERMISSION_QR_CODE_SUCCESS: {
			return {
				...state,
				loading: false,
				qr: action.qr
			};
		}
		case AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_ERROR: {
			return {
				...state,
				loading: false,
				error: action.error
			};
		}
		case AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}
		case AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case AuthorizationActionTypes.GET_SHARED_ACCOUNTS_REQUEST: {
			return {
				...state,
				loading: true,
				error: false
			};
		}
		case AuthorizationActionTypes.GET_SHARED_ACCOUNTS_SUCCESS: {
			return {
				...state,
				sharedAccounts: action.sharedAccounts,
				loading: false
			};
		}
		case AuthorizationActionTypes.GET_SHARED_ACCOUNTS_ERROR: {
			return {
				...state,
				error: true,
				loading: false
			};
		}
		default:
			return state;
	}
};
