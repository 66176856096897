import { css } from "@emotion/css";
import ObjectID from "bson-objectid";
import { useState } from "react";
import { Field } from "redux-form";

import { LegacyInputField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import DonationAdvancedSettings from "./AdvancedSettings";

import { includes } from "@/utils/Array";
import { required } from "@/utils/Validators";

const styles = {
	asterisk: css({
		color: "#c0212c"
	})
};

const hideRow = css`
	display: none;
`;

interface IDonationsTableProps {
	fields: any;
	ticketTypes: any;
	changeFieldValue: any;
	touch: any;
	isFreeOnly: boolean;
}

const DonationsTable = ({ fields, ticketTypes, changeFieldValue, touch, isFreeOnly }: IDonationsTableProps) => {
	const nameRequired = required("Donation name is required");
	const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

	const _disableTicket = (index: number) => changeFieldValue(`ticketTypes[${index}].disabled`, true);
	const _enableTicket = (index: number) => changeFieldValue(`ticketTypes[${index}].disabled`, false);

	const _removeItem = (index: number) => {
		const ticketType = ticketTypes[index];
		if (ticketType.isNew) {
			fields.remove(index);
		} else if (ticketType._id && ticketType.isDonation) {
			changeFieldValue(`ticketTypes[${index}].deleted`, true);
		}
	};

	const _handleChangeRow = (ticket: any, index: number) => {
		const newTicket = { ...ticket, key: index };
		changeFieldValue(`ticketTypes[${index}]`, newTicket);
	};

	const _onExpandClick = (key: string, forceClose: boolean) => {
		const tempRowKeys = [...expandedRowKeys];
		if (includes(expandedRowKeys, key)) {
			const index = tempRowKeys.indexOf(key);
			tempRowKeys.splice(index, 1);
		} else if (!forceClose) {
			tempRowKeys.push(key);
		}

		setExpandedRowKeys(tempRowKeys);
	};

	const _hasNoDonations = () => {
		const activeDonations = ticketTypes?.filter((ticketType: any) => ticketType.isDonation && !ticketType.deleted);
		return activeDonations?.length < 1;
	};

	const totalValidTicketTypes = ticketTypes?.filter((t: any) => !t.disabled && !t.deleted)?.length;
	const hasDonationTicket = ticketTypes ? ticketTypes.filter((t: any) => !t.deleted && t.isDonation).length : false;

	const columns = [
		{
			title: (
				<>
					<span className={styles.asterisk}>* </span>Donation name
				</>
			),
			dataIndex: "name",
			key: "name",
			render: (_: any, ticket: any) => {
				if (ticket.deleted) {
					return "";
				}
				return ticket.disabled ? (
					`Disabled: ${ticket.name}`
				) : (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<Field
							name={`ticketTypes[${ticket.index}].name`}
							dataCy="donation.name"
							type="text"
							component={LegacyInputField}
							validate={nameRequired}
							placeholder="Name your donation"
							disabled={ticket.disabled}
							onDrop={(e: any) => {
								e.preventDefault();
							}}
						/>
					</div>
				);
			}
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			align: "right",
			render: (item: any, ticket: any) => {
				if (ticket.deleted) {
					return "";
				}
				return totalValidTicketTypes > 1 || ticket.disabled ? (
					ticket.disabled ? (
						<div
							className={css({
								float: "right",
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<TableActions>
								<IconButton
									icon="undo"
									onClick={() => _enableTicket(ticket.index)}
									tooltip="Enable"
									showTooltip
									ariaLabel="Undo delete"
									dataCy="undo-delete-donation"
								/>
							</TableActions>
						</div>
					) : (
						<div
							className={css({
								float: "right",
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<TableActions>
								<IconButton
									icon="settings"
									onClick={() => _onExpandClick(item.key, false)}
									tooltip="Settings"
									showTooltip
									expanded={includes(expandedRowKeys, item.key)}
									ariaLabel="Settings"
								/>
								<IconButton
									icon="disable"
									onClick={() => _disableTicket(ticket.index)}
									tooltip="Disable"
									showTooltip
									ariaLabel="Disable"
									dataCy={`disable-donation-${ticket.index}`}
								/>
								<IconButton
									icon="delete"
									onClick={() => _removeItem(ticket.index)}
									tooltip="Delete"
									showTooltip
									ariaLabel="Delete"
									dataCy="delete-donation"
								/>
							</TableActions>
						</div>
					)
				) : (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<TableActions>
							<IconButton
								icon="settings"
								onClick={() => _onExpandClick(item.key, false)}
								tooltip="Settings"
								showTooltip
								expanded={includes(expandedRowKeys, item.key)}
								ariaLabel="Settings"
							/>
							<IconButton
								icon="delete"
								onClick={() => _removeItem(ticket.index)}
								tooltip="Delete"
								showTooltip
								ariaLabel="Delete"
								dataCy="delete-donation"
							/>
						</TableActions>
					</div>
				);
			}
		}
	];

	if (ticketTypes) {
		ticketTypes.forEach((ticket: any, index: number) => {
			ticket.key = index;
			ticket.index = index;
		});
	}

	const donations = ticketTypes?.filter((ticket: any) => ticket.isDonation);

	return (
		<div style={{ overflowX: "auto" }}>
			<ResponsiveTable
				pagination={false}
				columns={columns}
				dataSource={donations}
				rowKey="key"
				changeRow={_handleChangeRow}
				locale={{ emptyText: "You can create a donation for your cause" }}
				expandedRowRender={(ticket: any) => {
					return ticket.disabled ? null : (
						<DonationAdvancedSettings index={ticket.index} ticket={ticket} touch={touch} ticketTypes={ticketTypes} />
					);
				}}
				expandedRowKeys={expandedRowKeys}
				expandIconColumnIndex={-1}
				expandIconAsCell={false}
				rowClassName={(record: any) => {
					return record.deleted || !record.isDonation ? hideRow : "";
				}}
			/>
			{_hasNoDonations() && (
				<ButtonsBar>
					<LegacyButton
						onClick={() =>
							fields.push({
								_id: new ObjectID().toHexString(),
								isDonation: true,
								quantity: 1,
								hiddenOptions: { hidden: false, when: "always" },
								isNew: true
							})
						}
						icon={{ name: "plus", left: true }}
						type="action"
						ariaLabel="Donation"
						dataCy="add-Donation-button"
						disabled={isFreeOnly || hasDonationTicket}
					>
						Donation
					</LegacyButton>
				</ButtonsBar>
			)}
		</div>
	);
};

export default DonationsTable;
