import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip, TooltipProps } from "@/ui/antd";
import { FC } from "react";

export type InfoTooltipProps = {
	marginLeft?: string;
	marginRight?: string;
	placement?: TooltipProps["placement"];
	title: TooltipProps["title"];
};

export const InfoTooltip: FC<InfoTooltipProps> = ({
	marginLeft = "4px",
	marginRight = "4px",
	placement = "right",
	title
}) => {
	return (
		<Tooltip placement={placement} title={title}>
			<InfoCircleFilled style={{ marginLeft, marginRight }} />
		</Tooltip>
	);
};
