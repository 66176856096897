import { ContentBlock, Loading } from "@/components/AppBlocks";
import ErrorMsg from "@/components/ErrorMsg";
import { FC, ReactNode } from "react";

type LoadErrorViewProps = {
	children?: ReactNode;
	error?: { title: string; message: string } | null;
	loading: boolean;
	renderChildren?: boolean;
	retryAction?: () => void;
};

const LoadErrorView: FC<LoadErrorViewProps> = ({ children, loading, error, renderChildren = true, retryAction }) => {
	if (error) {
		const title = error.title || "Oops";
		const message =
			error.message && !String(error.message).includes("Request failed with status code")
				? error.message
				: "Something went wrong. Please try again";

		return (
			<ContentBlock>
				<ErrorMsg title={title} message={message} retryAction={() => retryAction?.()} />
			</ContentBlock>
		);
	}

	return (
		<div>
			{loading ? <Loading /> : ""}
			{renderChildren || !loading ? children : null}
		</div>
	);
};

export default LoadErrorView;
