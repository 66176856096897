import { dayjs } from "@/libs/dayjs";
import { Button, Dropdown } from "@/ui/antd";
import { CalendarOutlined } from "@ant-design/icons";
import { Component } from "react";
import DateRangeFields from "../../components/fields/DateRangeFields";

class DatesSelector extends Component {
	dateRangeChange = (range) => {
		const { changeFieldValue } = this.props;
		let year;
		let currentMonth;
		let startDate = dayjs().startOf("day");
		let endDate = dayjs().endOf("day");
		switch (range) {
			case "last7Days":
				startDate = startDate.subtract(7, "days");
				break;
			case "lastWeek":
				startDate = startDate.subtract(7, "days");
				break;
			case "last30Days":
				startDate = startDate.subtract(30, "days");
				break;
			case "lastMonth":
				startDate = startDate.subtract(1, "month").startOf("month");
				endDate = endDate.subtract(1, "month").endOf("month");
				break;
			case "monthToDate":
				startDate = startDate.startOf("month");
				break;
			case "yearToDate":
				startDate = startDate.startOf("year");
				break;
			case "financialYearToDate":
				currentMonth = dayjs().month();
				year = dayjs().year();
				if (currentMonth <= 5) {
					year = year - 1;
				}
				startDate = dayjs({ year, month: 6, day: 1 }).startOf("day");
				endDate = dayjs().endOf("day");
				break;
			case "lastFinancialYear": {
				currentMonth = dayjs().month();
				year = dayjs().year();
				if (currentMonth <= 5) {
					year = year - 2;
				} else {
					year = year - 1;
				}
				const endYear = year + 1;
				startDate = dayjs({ year, month: 6, day: 1 }).startOf("day");
				endDate = dayjs({ year: endYear, month: 5, day: 30 }).endOf("day");
				break;
			}
			case "last180Days":
				startDate = startDate.subtract(180, "days");
				break;
			case "last365Days":
				startDate = startDate.subtract(365, "days");
				break;
			case "lastYear":
				year = dayjs().year();
				year = year - 1;
				startDate = dayjs({ year, month: 0, day: 1 }).startOf("day");
				endDate = dayjs({ year, month: 11, day: 31 }).endOf("day");
				break;
			default:
				break;
		}
		changeFieldValue("startDate", startDate);
		changeFieldValue("endDate", endDate);
	};

	render() {
		const { touch } = this.props;
		const items = [
			{
				key: "last7Days",
				label: "Last 7 Days"
			},
			{
				key: "last30Days",
				label: "Last 30 Days"
			},
			{
				key: "lastMonth",
				label: "Last month"
			},
			{
				key: "monthToDate",
				label: "Month to date"
			}
		];
		return (
			<div style={{ display: "flex" }}>
				<Dropdown menu={{ items, onClick: ({ key }) => this.dateRangeChange(key) }}>
					<Button
						icon={<CalendarOutlined />}
						style={{
							width: 36,
							height: 36,
							border: "none",
							marginRight: 12,
							marginTop: 24
						}}
						aria-label="Calendar"
					/>
				</Dropdown>
				<DateRangeFields
					touch={touch}
					startLabel="Start"
					endLabel="End"
					startField="startDate"
					endField="endDate"
					allowEqual
					hideTime
					maxDayRange={31}
				/>
			</div>
		);
	}
}
export default DatesSelector;

/**
 Removed date selectors to limit to the range you can select
        <Menu.Item key="yearToDate">Year to date</Menu.Item>
        <Menu.Item key="financialYearToDate">Financial Year to date</Menu.Item>
        <Menu.Item key="lastFinancialYear">Last Financial Year</Menu.Item>
        <Menu.Item key="last180Days">Last 180 days</Menu.Item>
        <Menu.Item key="last365Days">Last 356 days</Menu.Item>
        <Menu.Item key="lastYear">Last Year</Menu.Item>
        */
