import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { required } from "../utils/Validators";
import { LegacyInputField, SelectField, SwitchField } from "./Fields";

interface ITaxExemptFormProps {
	path?: string;
	formName: string;
}

type ITaxExemptFormInput = {
	taxExemptEnabled: boolean;
	otherTaxExemptionCategory: boolean;
} & ITaxExemptFormProps;

const exemptionCategoryRequired = required("A exemption category is required");
const otherExemptionCategoryRequired = required("A tax exemption description is required");

const TaxExemptFormInput = ({ taxExemptEnabled, otherTaxExemptionCategory, path }: ITaxExemptFormInput) => {
	const appendedPath = path ? `${path}.` : "";
	return (
		<>
			<Field name={`${appendedPath}taxExemption.taxExempt`} label="US events tax exempt" component={SwitchField} />
			{taxExemptEnabled && (
				<>
					<Field
						label="Exemption Category"
						name={`${appendedPath}taxExemption.exemptionCategory`}
						component={SelectField}
						button
						required
						validate={[exemptionCategoryRequired]}
						options={[
							{
								label: "Wholesale",
								value: "wholesale"
							},
							{
								label: "Government",
								value: "government"
							},
							{
								label: "Other",
								value: "other"
							}
						]}
					/>
					{otherTaxExemptionCategory && (
						<Field
							name={`${appendedPath}taxExemptionDescription`}
							label="Tax exemption description"
							component={LegacyInputField}
							required
							validate={[otherExemptionCategoryRequired]}
						/>
					)}
				</>
			)}
		</>
	);
};

export default connect((state, ownProps: ITaxExemptFormProps) => {
	const appendedPath = ownProps.path ? `${ownProps.path}.` : "";
	const selector = formValueSelector(ownProps.formName);
	return {
		taxExemptEnabled: selector(state, `${appendedPath}taxExemption.taxExempt`),
		otherTaxExemptionCategory: selector(state, `${appendedPath}taxExemption.exemptionCategory`) === "other"
	};
}, {})(TaxExemptFormInput);
