import { InputNumberField } from "@/components/Fields";
import InfoIcon from "@/components/InfoIcon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import P from "@/components/text/P";
import { SelfServicePayoutsData, addSelfServePayments } from "@/state/actions/newPayoutActions";
import { DateBreakdown, PayoutHeaderInformation } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { getFormValuesWrapper } from "@/utils/Form";
import { currency } from "@/utils/Format";
import { BankAccounts } from "@hx/utilities";
import { Alert, Modal, Table } from "@/ui/antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm, reset } from "redux-form";

const getColumns = (tooltipText: JSX.Element) => [
	{
		title: "Event date",
		dataIndex: "displayDate"
	},
	{
		title: "Available",
		dataIndex: "selfServeAmount",
		render: (value: number) => (
			<div style={{ display: "flex", alignItems: "center" }}>
				{currency(value)}
				<InfoIcon tooltip={tooltipText} />
			</div>
		)
	},
	{
		title: "Payout amount",
		render: (row: DateBreakdown) => (
			<Field
				name={row.dateId}
				component={InputNumberField}
				precision={2}
				min={0}
				max={row.selfServeAmount > 0 ? row.selfServeAmount : 0}
			/>
		)
	}
];

type ICombinedProps = IInjectedProps & IInjectedFunctions;

const SelfServicePayouts: React.FunctionComponent<
	ICombinedProps & InjectedFormProps<SelfServicePayoutsData, ICombinedProps>
> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isNextPage, setIsNextPage] = useState(false);

	const { datesBreakdown, header, formValues, eventName, addSelfServePayments, eventId, handleSubmit, dispatch } =
		props;
	if (header?.preventPayout) return null;
	const showSelfServicePayouts = datesBreakdown.some((date) => date.selfServeAmount);
	if (!showSelfServicePayouts) return null;

	const handleOk = () => {
		if (isNextPage && eventId) {
			handleSubmit((values) => addSelfServePayments(eventId, values))();
			setIsOpen(false);
			setIsNextPage(false);
			dispatch(reset(formName));
		} else {
			setIsNextPage(true);
		}
	};

	const handleCancel = () => {
		setIsOpen(false);
		setIsNextPage(false);
		dispatch(reset(formName));
	};

	const total = Object.values(formValues).reduce((total: number, row) => {
		return total + (row || 0);
	}, 0);

	const settings = header?.selfServiceSettings;

	if (!settings) return null;

	const tooltipText = (
		<ul style={{ paddingLeft: 16, textAlign: "left" }}>
			<li>{settings.percent}% of event date earnings</li>
			{settings.cap ? <li>Capped at {currency(settings.cap)}</li> : null}
		</ul>
	);

	const alertText = `You can payout up to ${settings.percent}% of your total event earnings. ${
		settings.cap ? `Capped at ${currency(settings.cap)} per event occurrence.` : ""
	}`;

	return (
		<>
			<LegacyButton onClick={() => setIsOpen(true)}>Process early payout</LegacyButton>
			<Modal
				title="Process early payout"
				open={isOpen}
				onCancel={handleCancel}
				onOk={handleOk}
				okText={isNextPage ? "Submit" : "Next"}
				okButtonProps={{ disabled: !total }}
			>
				<form>
					{isNextPage ? (
						<>
							<P>Please confirm the following details are correct:</P>
							<b>Payout amount</b>
							<P>{currency(total)}</P>
							<b>Event name</b>
							<P>{eventName}</P>
							<b>Event payout(s)</b>
							<div style={{ marginBottom: 10 }}>
								{Object.keys(formValues).map((dateId) => {
									const amount = formValues[dateId];
									if (!amount || amount <= 0) return null;
									return (
										<P marginBottom={2}>
											{datesBreakdown.find((date) => date.dateId === dateId)?.displayDate || ""} -{" "}
											<b>{currency(formValues[dateId])}</b>
										</P>
									);
								})}
							</div>
							<b>Bank Account</b>
							{header?.bankAccount ? (
								<div>
									{BankAccounts.getDisplayParts(
										String(header.bankAccount.bsb),
										header.bankAccount.number,
										String(header.bankAccount.location),
										header.bankAccount.name
									).map((part, index) => (
										<P key={index} marginBottom={2}>
											{part}
										</P>
									))}
								</div>
							) : null}

							<Alert
								type="info"
								showIcon
								message="Payouts will appear in your bank account within 2-5 business days."
								style={{ marginTop: 20 }}
							/>
						</>
					) : (
						<>
							<Alert type="info" showIcon message={alertText} style={{ marginBottom: 12 }} />
							<P>Enter the amount you wish to be paid out below</P>
							<Table
								dataSource={datesBreakdown}
								columns={getColumns(tooltipText)}
								rowKey={(row) => row.dateId}
								pagination={false}
								footer={() => (
									<div style={{ textAlign: "right" }}>
										<b>Total: {currency(total)}</b>
									</div>
								)}
							/>
						</>
					)}
				</form>
			</Modal>
		</>
	);
};

interface IInjectedProps {
	datesBreakdown: DateBreakdown[];
	eventId?: string;
	header?: PayoutHeaderInformation;
	formValues: SelfServicePayoutsData;
	eventName: string;
}

interface IInjectedFunctions {
	addSelfServePayments: (id: string, values: SelfServicePayoutsData) => void;
	dispatch: (...rest: any) => void;
}

const formName = "selfServicePayoutRequest";

export default connect(
	(state: ReduxState): IInjectedProps => {
		const datesBreakdown = state.payouts.eventPayout.payoutInformation?.datesBreakdown || [];
		const formValues = getFormValuesWrapper<SelfServicePayoutsData>(state, formName) || {};
		const eventName = state.currentEvent.event?.name;
		return {
			datesBreakdown: datesBreakdown,
			header: state.payouts.eventPayout.payoutInformation?.header,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId,
			eventName,
			formValues
		};
	},
	{ addSelfServePayments }
)(reduxForm<SelfServicePayoutsData, ICombinedProps>({ form: formName })(SelfServicePayouts));
