import { Button, Modal } from "@/ui/antd";

interface ModalProps {
	visible: boolean;
	loading: boolean;
	handleOk: () => void;
	handleCancel: () => void;
	handleDiscard: () => void;
}

const SaveCheckModal = ({ visible, loading, handleOk, handleCancel, handleDiscard }: ModalProps) => {
	return (
		<Modal
			open={visible}
			title="Unsaved changes"
			onOk={handleOk}
			onCancel={handleCancel}
			closable={true}
			footer={[
				<Button key="back" onClick={handleDiscard} aria-label="Cancel">
					Discard
				</Button>,
				<Button key="submit" type="primary" loading={loading} onClick={handleOk} aria-label="Save">
					Save and close
				</Button>
			]}
		>
			<p>You have some unsaved changes.</p>
			<p>Do you wish to save your change(s) before closing?</p>
		</Modal>
	);
};

export default SaveCheckModal;
