import {
	OPEN_LOCK_BANK_ACCOUNT_MODAL,
	CLOSE_LOCK_BANK_ACCOUNT_MODAL,
	EDIT_BANK_ACCOUNT_CODE,
	CLOSE_BANK_ACCOUNT_FORM
} from "../actions/bankAccountsActions";

const initialState = {
	account: null,
	showLockBankAccountModal: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case EDIT_BANK_ACCOUNT_CODE: {
			return {
				...state,
				account: action.account
			};
		}
		case CLOSE_BANK_ACCOUNT_FORM: {
			return {
				...state,
				account: null
			};
		}
		case OPEN_LOCK_BANK_ACCOUNT_MODAL: {
			return {
				...state,
				showLockBankAccountModal: true
			};
		}
		case CLOSE_LOCK_BANK_ACCOUNT_MODAL: {
			return {
				...state,
				showLockBankAccountModal: false
			};
		}
		default:
			return state;
	}
};
