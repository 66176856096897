import { css } from "@emotion/css";
import { FC, ReactNode } from "react";

type ParagraphProps = {
	children: ReactNode;
	desktopMarginBottom?: number;
	marginBottom?: number;
	mobileMarginBottom?: number;
	style?: Record<string, unknown>;
	center?: boolean;
};

const P: FC<ParagraphProps> = ({
	style,
	children,
	marginBottom,
	desktopMarginBottom = 12,
	mobileMarginBottom = 8,
	center
}) => {
	const customTextStyle = css({
		fontWeight: 400,
		fontSize: 14,
		margin: 0,
		marginBottom: marginBottom ?? desktopMarginBottom,
		lineHeight: "19px",
		textAlign: center ? "center" : "unset",
		"@media(max-width: 600px)": {
			fontSize: 14,
			marginBottom: marginBottom ?? mobileMarginBottom
		},
		...style
	});
	return <p className={customTextStyle}>{children}</p>;
};

export default P;
