const Circle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0H24V24H0z" />
				<circle id="ele-id-stroke" cx="12" cy="12" r="10" stroke="#67717E" strokeWidth="2" />
			</g>
		</svg>
	);
};

export default Circle;
