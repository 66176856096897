import { notification } from "@/ui/antd";
import InvoiceService from "../../services/InvoiceService";
import { getReport } from "./globalReportActions";
import { openResendOrderModal } from "./orderActions";

export const INVOICE_UPDATE_REQUEST = "INVOICE_UPDATE_REQUEST";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
export const INVOICE_UPDATE_ERROR = "INVOICE_UPDATE_ERROR";

export const INVOICE_REFUND_UNPAID_REQUEST = "INVOICE_REFUND_REQUEST";
export const INVOICE_REFUND_UNPAID_SUCCESS = "INVOICE_REFUND_SUCCESS";
export const INVOICE_REFUND_UNPAID_ERROR = "INVOICE_REFUND_ERROR";

export const markPaid = (id) => {
	return (dispatch) => {
		dispatch({ type: INVOICE_UPDATE_REQUEST, orderId: id });
		InvoiceService.markPaid(id)
			.then((order) => {
				dispatch({ type: INVOICE_UPDATE_SUCCESS, orderId: id, order });
				notification.success({
					message: "Success",
					description: `Invoice INV-${order.orderName} has been marked as paid`
				});
				window.setTimeout(() => {
					openResendOrderModal(order.eventId, order)(dispatch);
				}, 500);
			})
			.catch((err) => {
				dispatch({ type: INVOICE_UPDATE_ERROR, orderId: id });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to update";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const removePayment = (id) => {
	return (dispatch) => {
		dispatch({ type: INVOICE_UPDATE_REQUEST, orderId: id });
		InvoiceService.removePayment(id)
			.then((order) => {
				dispatch({ type: INVOICE_UPDATE_SUCCESS, orderId: id, order });
				notification.success({
					message: "Success",
					description: `Payment removed from Invoice INV-${order.orderName}`
				});
			})
			.catch((err) => {
				dispatch({ type: INVOICE_UPDATE_ERROR, orderId: id });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to update";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const refundUnpaidInvoice = (orderId, reloadOptions) => {
	return (dispatch) => {
		dispatch({ type: INVOICE_REFUND_UNPAID_REQUEST, orderId });
		InvoiceService.refundUnpaidInvoice(orderId)
			.then((order) => {
				dispatch({ type: INVOICE_REFUND_UNPAID_SUCCESS, orderId });
				notification.success({
					message: "Success",
					description: `Removed INV-${order.orderName}`
				});
				if (reloadOptions) {
					dispatch(getReport("invoice", 1));
				}
			})
			.catch((err) => {
				dispatch({ type: INVOICE_REFUND_UNPAID_ERROR, orderId });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to update";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const markSent = (id) => {
	return (dispatch) => {
		dispatch({ type: INVOICE_UPDATE_REQUEST, orderId: id });
		InvoiceService.markSent(id)
			.then((order) => {
				dispatch({ type: INVOICE_UPDATE_SUCCESS, orderId: id, order });
				notification.success({
					message: "Success",
					description: `Invoice INV-${order.orderName} has been marked as sent`
				});
			})
			.catch((err) => {
				dispatch({ type: INVOICE_UPDATE_ERROR, orderId: id });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to update";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const removeSent = (id) => {
	return (dispatch) => {
		dispatch({ type: INVOICE_UPDATE_REQUEST, orderId: id });
		InvoiceService.removeSent(id)
			.then((order) => {
				dispatch({ type: INVOICE_UPDATE_SUCCESS, orderId: id, order });
				notification.success({
					message: "Success",
					description: `Sent removed from Invoice INV-${order.orderName}`
				});
			})
			.catch((err) => {
				dispatch({ type: INVOICE_UPDATE_ERROR, orderId: id });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to update";
				notification.error({ message: "Error", description: message });
			});
	};
};
