import { getConfig } from "@/config";
import { Component } from "react";
import { Field } from "redux-form";
import { CopyInputField } from "../../components/Fields";
import { urlSafe } from "../../utils/Format";

export default class PageUrl extends Component {
	render() {
		const { slug } = this.props;
		const url = `${getConfig("TICKETING_URL")}/gift-cards/`;
		const fullUrl = `${url}${slug}`;
		return (
			<Field
				label="Gift Card URL"
				name="slug"
				placeholder="gift card slug"
				style={{ maxWidth: "100%", width: "100%" }}
				fullUrl={fullUrl}
				component={CopyInputField}
				normalize={urlSafe}
				addonBefore={url}
				warning="Updating your gift card url may break any existing marketing links you have set up."
			/>
		);
	}
}
