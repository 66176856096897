import { State } from "@hx/seating-map";
import { Connectors } from "@hx/seating-map-toolbar";
import { Component } from "react";
import { connect } from "react-redux";
import { getUnmappedTickets, unAssign } from "@/state/actions/seatingMapActions";
import { AssigningBulk, AssigningIndividual, AssigningPackage, AttendeeInfo, Default } from "./modes";

const { ConnectTicketManagement } = Connectors;
const { MODES } = State.Assigning;

class BaseItem extends Component {
	getView = () => {
		const { currentMode } = this.props;
		switch (currentMode) {
			case MODES.SEARCH:
				return <Default />;
			case MODES.EXISTING_MAPPED_TICKET_INFO:
				return <AttendeeInfo />;
			case MODES.ASSIGN_INDIVIDUAL:
				return <AssigningIndividual />;
			case MODES.ASSIGN_ORDER:
				return <AssigningBulk />;
			case MODES.ASSIGN_PACKAGE:
				return <AssigningPackage />;
			default:
				return <Default />;
		}
	};

	render() {
		return <>{this.getView()}</>;
	}
}

BaseItem = ConnectTicketManagement({
	currentMode: "currentMode"
})(BaseItem);

export default connect(
	(state) => ({
		seatingMap: state.seatingMap,
		currentEvent: state.currentEvent
	}),
	{ unAssign, getUnmappedTickets }
)(BaseItem);
