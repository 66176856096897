import { getImageUrl } from "../services/ImageService";
//https://github.com/filestack/adaptive

interface IBorderRadiiParams {
	topLeft: number;
	topRight: number;
	bottomLeft: number;
	bottomRight: number;
}

interface IPictureProps {
	handle: string;
	alt: string;
	width?: string;
	height?: string;
	borderRadii?: IBorderRadiiParams;
	defaultWidth?: number;
	defaultHeight?: number;
}

const Picture = ({
	handle,
	alt,
	width = "100%",
	height = "100%",
	borderRadii = { topLeft: 4, topRight: 4, bottomLeft: 4, bottomRight: 4 },
	defaultWidth = 800,
	defaultHeight = 400
}: IPictureProps) => {
	const style = {
		border: "1px solid rgb(222, 222, 222)",
		borderRadius: `${borderRadii.topLeft}px ${borderRadii.topRight}px ${borderRadii.bottomRight}px ${borderRadii.bottomLeft}px`
	};
	return (
		<img
			width={width}
			height={height}
			style={{ minWidth: width, minHeight: height, ...style }}
			alt={alt}
			src={getImageUrl(
				handle,
				typeof width === "string" ? defaultWidth * 2 : width * 2,
				typeof height === "string" ? defaultHeight * 2 : height * 2
			)}
		/>
	);
};

export default Picture;
