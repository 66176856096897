import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { LoadingSpinner } from "../components/AppBlocks";
import { loadCanva, uploadImage } from "../state/actions/canvaActions";

const btnStyle = css({
	background: "#00c4cc",
	color: "#fff",
	padding: "0px 16px 0 0",
	cursor: "pointer",
	display: "inline-flex",
	justifyContent: "center",
	alignItems: "center",
	whitespace: "nowrap",
	borderRadius: 4,
	borderColor: "#00c4cc",
	span: {
		height: 24,
		width: 24,
		margin: "3px 10px",
		borderRadius: "50%",
		position: "relative",
		boxSizing: "border-box",
		display: "block",
		background: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm5-7.7c0-.2-.2-.3-.4-.3s-.3 0-.4.2l-.1.2-.1.1c-.6 1-1.1 1.3-2.2 1.9a4 4 0 0 1-1.7.3c-1.7 0-2.6-1.3-3-2.5-.5-2 .4-4.6 1.6-6 .7-.9 1.5-1.4 2.2-1.4.3 0 .7.2 1 .5.3.6.4 1 0 1.9 0 .2 0 .4.2.5.3 0 .6 0 1-.6.4-.5.4-1.4 0-2A3 3 0 0 0 12.7 6c-.8 0-1.5.2-2.1.6a7.5 7.5 0 0 0-3.4 8 4.7 4.7 0 0 0 4.4 3.4c1.4 0 2.4-.5 3-1 .8-.4 1.4-1 2-1.8l.1-.2.2-.2v-.5z'/%3E%3C/svg%3E")`,
		backgroundRepeat: "no-repeat"
	}
});

class CanvaButton extends Component {
	constructor(props) {
		super(props);
		const { canvaId } = props;
		this.funcId = `canvaCallBack${canvaId}`;
		this.create = this.create.bind(this);
		this.edit = this.edit.bind(this);
	}

	componentDidMount() {
		const { loadCanva } = this.props;
		window[this.funcId] = this.canvaCallBack;
		loadCanva();
	}

	create() {
		const { uploadImage, canvaId, width = 3200, height = 1600 } = this.props;
		window.canvaApi.createDesign({
			design: {
				dimensions: {
					width,
					height
				}
			},
			onDesignPublish: (result) => {
				const { exportUrl, designId } = result;
				uploadImage(exportUrl, canvaId, designId);
			}
		});
	}

	edit() {
		const { uploadImage, canvaId, designId } = this.props;
		window.canvaApi.editDesign({
			design: {
				id: designId
			},
			onDesignPublish: (result) => {
				const { exportUrl, designId } = result;
				uploadImage(exportUrl, canvaId, designId);
			}
		});
	}

	componentWillUnmount() {
		window[this.funcId] = null;
	}

	render() {
		const { children, designId, canva } = this.props;

		if (canva.script.loading || !canva.script.loaded) {
			return (
				<button className={btnStyle} aria-label="Create canva image">
					<span />
					{children} <LoadingSpinner size={30} color="#fffff" style={{ margin: "2px 0 0 20px" }} />
				</button>
			);
		}
		if (designId) {
			return (
				<button className={btnStyle} aria-label="Edit canva image" onClick={this.edit} type="button">
					<span />
					{children}
				</button>
			);
		}
		return (
			<button aria-label="Create canva image" className={btnStyle} onClick={this.create} type="button">
				<span />
				{children}
			</button>
		);
	}
}

export default connect(
	(state) => ({
		canva: state.canva
	}),
	{
		loadCanva,
		uploadImage
	}
)(CanvaButton);
