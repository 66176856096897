import { Checkbox } from "@/ui/antd";
import { Component } from "react";
import LinkButton from "@/components/buttons/LinkButton";

const icons = [
	{ imageName: "bar.svg", id: "bar" },
	{ imageName: "blind.svg", id: "blind" },
	{ imageName: "exit.svg", id: "exit" },
	{ imageName: "food.svg", id: "food" },
	{ imageName: "smoking.svg", id: "smoking" },
	{ imageName: "stairs.svg", id: "stairs" },
	{ imageName: "wheelchair.svg", id: "wheelchair" },
	{ imageName: "bathroom.svg", id: "bathroom" },
	{ imageName: "dancefloor.svg", id: "dance" },
	{ imageName: "first_aid.svg", id: "first_aid" },
	{ imageName: "info.svg", id: "info" },
	{ imageName: "stage.svg", id: "stage" }
	// {imageName:"stairs_up_down.svg", id:"stairs_up_down"}
];

export default class IconPicker extends Component {
	checkboxClicked = (e) => {
		const { performAction, element } = this.props;
		const checked = e.target.checked;
		performAction([element.showIcon], [[checked]]);
	};

	iconClicked = (id) => {
		const { performAction, element } = this.props;
		performAction([element.setIcon], [[id]]);
	};

	render() {
		const { element, isIndependentIcon } = this.props;
		if (isIndependentIcon) {
			const selected = element.icon;
			return (
				<div style={{}}>
					<h3>Icons</h3>
					{icons.map((icon) => (
						<IconTile {...icon} onClick={this.iconClicked} selected={icon.id === selected} />
					))}
				</div>
			);
		}
		const showIcons = element.moveableIcon.iconVisible;
		const selected = element.moveableIcon.icon;
		return (
			<div style={{ overflow: "auto", background: "white" }}>
				<div style={{ paddingTop: 13, float: "left" }}>
					<Checkbox defaultChecked={showIcons} value={showIcons} onChange={this.checkboxClicked}>
						Object Icon
					</Checkbox>
					<div style={{ marginTop: 14 }}>
						{showIcons
							? icons.map((icon, i) => (
									<IconTile {...icon} onClick={this.iconClicked} selected={icon.id === selected} index={i} />
							  ))
							: null}
					</div>
				</div>
			</div>
		);
	}
}

const IconTile = ({ imageName, id, selected, onClick, index }) => {
	return (
		<LinkButton
			onClick={() => onClick(id)}
			style={{
				width: 32,
				height: 32,
				marginLeft: index % 7 === 0 ? 0 : 8,
				borderRadius: "3px",
				background: selected ? "#1A7CF1" : "#FFFFFF",
				padding: 0
			}}
			ariaLabel={imageName}
		>
			<img
				src={`/frontend/seatingMap/object_icons/${imageName}`}
				style={{
					display: "block",
					margin: "auto",
					verticalAlign: "center"
				}}
				alt={`${imageName}`}
			/>
		</LinkButton>
	);
};
