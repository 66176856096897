import { createSlice } from "@reduxjs/toolkit";
import { CurrentUserSchema } from "@hx/console";

export type AuthReducerState = {
	user: CurrentUserSchema | null;
	loading: boolean;
	error: boolean;
	assumingIdentity: boolean;
	token?: string | null;
};

const initialState: AuthReducerState = {
	user: null,
	loading: false,
	error: false,
	assumingIdentity: false
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setUser(state, action) {
			state.user = {
				...state.user,
				...action.payload
			};
		},
		saveUserSuccess(state, action) {
			if (state?.user?.id === action?.payload?.user?.id) {
				state.user = {
					...state.user,
					...action.payload
				};
			}
		},
		onUserEmailVerified(state) {
			if (!state.user) {
				return;
			}
			state.user = {
				...state.user,
				emailVerified: true
			};
		},
		setAssumingIdentity(state, action) {
			state.assumingIdentity = action.payload;
		},
		setToken(state, action) {
			state.token = action.payload;
		},
		clearToken(state) {
			state.token = null;
		}
	}
});

export const { clearToken, onUserEmailVerified, saveUserSuccess, setAssumingIdentity, setLoading, setToken, setUser } =
	authSlice.actions;

export default authSlice.reducer;
