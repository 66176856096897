/**
 * This file is used to export all the antd components that are used in the project.
 * So we can easily style the antd components in one place, with global styles.
 */

import {
	Badge,
	Button,
	Card,
	Checkbox,
	Col,
	Collapse,
	ConfigProvider,
	DatePicker,
	DatePickerProps,
	Divider,
	Drawer,
	Dropdown,
	DropdownProps,
	Empty,
	Form,
	FormInstance,
	FormItemProps,
	GetProps,
	Input,
	InputNumber,
	InputRef,
	List,
	Menu,
	message,
	Modal,
	notification,
	Pagination,
	Popconfirm,
	Progress,
	Radio,
	RadioGroupProps,
	Row,
	RowProps,
	Slider,
	Space,
	Spin,
	Steps,
	Switch,
	Table,
	Tabs,
	TabsProps,
	Tag,
	TimePicker,
	Transfer,
	Typography
} from "antd";
import { ColumnProps } from "antd/lib/table";

export * from "@/ui/antd/Alert";
export * from "@/ui/antd/AntdOverrides";
export * from "@/ui/antd/Confirm";
export * from "@/ui/antd/Flex";
export * from "@/ui/antd/Select";
export * from "@/ui/antd/Tooltip";

export {
	Badge,
	Button,
	Card,
	Checkbox,
	Col,
	Collapse,
	ConfigProvider,
	DatePicker,
	Divider,
	Drawer,
	Dropdown,
	Empty,
	Form,
	Input,
	InputNumber,
	List,
	Menu,
	message,
	Modal,
	notification,
	Pagination,
	Popconfirm,
	Progress,
	Radio,
	Row,
	Slider,
	Space,
	Spin,
	Steps,
	Switch,
	Table,
	Tabs,
	Tag,
	TimePicker,
	Transfer,
	Typography
};

export type {
	ColumnProps,
	DatePickerProps,
	DropdownProps,
	FormInstance,
	FormItemProps,
	GetProps,
	InputRef,
	RadioGroupProps,
	RowProps,
	TabsProps
};
