import { stripUrl } from "./";

const linkedInProfileTypes = {
	DEFAULT: "https://linkedin.com/in/",
	COMPANY: "https://linkedin.com/company/",
	SCHOOL: "https://linkedin.com/school/"
};

/**
 * a helper function to grab the just the baseUrl from the linkedIn profile.
 * e.g.
 *  shak => https://linkedin.com/in/
 *  * "" => https://linkedin.com/in
 *
 *  https://www.linkedin.com/in/shak => https://linkedin.com/in/
 *  http://www.linkedin.com/in/shak => https://linkedin.com/in/
 *  https://linkedin.com/in/shak => https://linkedin.com/in/
 *  http://linkedin.com/in/shak => https://linkedin.com/in/
 *
 *  https://www.linkedin.com/company/shak => https://linkedin.com/company/
 *  ...
 *
 *  https://www.linkedin.com/school/shak => https://linkedin.com/school/
 *  ...
 *
 */
const findLinkedInBaseUrl = (input: string): string => {
	// prod data has urls with or without the domain. These are to be treated as /in

	if (!input) return linkedInProfileTypes.DEFAULT;

	// linkedin.com/company/
	const companyDomain = linkedInProfileTypes.COMPANY.replace("https://", "");

	// linkedin.com/school/
	const schoolDomain = linkedInProfileTypes.SCHOOL.replace("https://", "");

	const isCompany = stripUrl(input).includes(companyDomain);
	if (isCompany) return linkedInProfileTypes.COMPANY;

	const isSchool = stripUrl(input).includes(schoolDomain);
	if (isSchool) return linkedInProfileTypes.SCHOOL;

	return linkedInProfileTypes.DEFAULT;
};

/**
 * This is a utility function to remove the domain parts of a social profile url. e.g:
 *  - https://linkedin.com/in/humanitix -> humanitix
 *  - https://linkedin.com/company/humanitix -> humanitix
 *  - https://linkedin.com/school/humanitix -> humanitix
 */
const findLinkedInHandle = (url: string) => {
	const base = findLinkedInBaseUrl(url);

	// remove the cleaned characters
	return url?.replace(base, "");
};

const parseLinkedInData = (url: string) => {
	const baseUrl = findLinkedInBaseUrl(url);
	const handle = findLinkedInHandle(url);

	return {
		url: baseUrl,
		handle
	};
};

export { findLinkedInBaseUrl, findLinkedInHandle, linkedInProfileTypes, parseLinkedInData };
