import { Field } from "redux-form";

import { HumaniDate } from "@hx/dates";
import { LegacyInputField, PasswordField, RadioField, SelectField } from "../../components/Fields";
import { H3, P } from "../../components/text/Text";
import LocationService from "../../services/LocationService";
import { HelpIds, OpenHelpHandler } from "../../utils/Help";
import LocationMap from "../../utils/LocationMap";
import { checkLength, email, getValueFromObjString, onlyNumbers, required } from "../../utils/Validators";
import { InfoTooltip } from "@/ui";
import { Alert, Flex } from "@/ui/antd";

const MAX_NAME_LENGTH = 50;
const nameMaxLength = (value: string) =>
	value?.length > MAX_NAME_LENGTH ? `Must be ${MAX_NAME_LENGTH} characters or less` : undefined;
const nameRequired = required("Name is required (As shown on your income tax return)");
const classificationRequired = required("Classification type is required");
const exemptPayeeCodeRequired = required("Selection of one code is required");
const addressRequired = required("Address is required");
const cityRequired = required("City is required");
const postalCodeRequired = required("Postal code is required");
const stateRequired = required("State is required");
const emailRequired = required("Email is required to receive your 1099-K form");
const validEmail = email("Please enter a valid email");
const validPostalCode = (value: string) => {
	const result = value && !/^\d{5}(-\d{4})?$/.test(value) ? "Invalid postal code" : undefined;
	return result;
};
const tinTypeRequired = required("Must select a TIN type");
const signatureRequired = required("Signature is required (Typing your name acts as your signature)");

const tinRequired = required("TIN is required");
const tinLength = checkLength("TIN must be 9 digits", 9, "==");
const tinCharacters = onlyNumbers("TIN must be numbers only");
const tinChecks = [tinRequired, tinLength, tinCharacters];

export const fieldMatch = (msg: any, field: any) => (value: any, allValues: any) => {
	const originalValue = getValueFromObjString(field, allValues);
	return value === originalValue ? undefined : msg;
};
const tinMatch = fieldMatch(`Previous field must match`, "usTaxpayerInfo.tin");

const federalTaxClassificationType = {
	CCorporation: "C corporation",
	SCorporation: "S Corporation",
	Partnership: "Partnership",
	TrustEstate: "Trust/estate",
	LLCCC: "LLC (C Corporation)",
	LLCCPartnership: "LLC (Partnership)",
	LLCCS: "LLC (S Corporation)",
	SingelMember: "Individual/sole proprietor or LLC (Single member)",
	Other: "Other",
	Exempt: "Exempt payee"
};

const federalTaxClassificationOptions = Object.keys(federalTaxClassificationType).map((key) => {
	return {
		value: federalTaxClassificationType[key as keyof typeof federalTaxClassificationType],
		label: federalTaxClassificationType[key as keyof typeof federalTaxClassificationType]
	};
});

const exemptPayeeCode = {
	NotApplicable: "N/A",
	ONE: "1",
	TWO: "2",
	THREE: "3",
	FOUR: "4",
	FIVE: "5",
	SIX: "6",
	SEVEN: "7",
	EIGHT: "8",
	NINE: "9",
	TEN: "10",
	ELEVEN: "11",
	TWELVE: "12",
	THIRTEEN: "13"
};

const exemptPayeeCodeOptions = Object.keys(exemptPayeeCode).map((key) => {
	return {
		value: exemptPayeeCode[key as keyof typeof exemptPayeeCode],
		label: exemptPayeeCode[key as keyof typeof exemptPayeeCode]
	};
});

export const W9Form = ({ formValues }: any) => {
	const formattedSignatureDate = new HumaniDate(
		formValues.signatureDate,
		"guess",
		LocationService.getLocation(true)
	).formatting.dateTime();
	return (
		<>
			<H3>IRS Substitute Form W-9</H3>
			<P>This tax form is required by US tax law. Please note that we are unable to offer any tax advice.</P>

			<Alert
				showIcon
				message="Need help filling in the form?"
				description={
					<>
						See:
						<ul style={{ paddingLeft: 20, marginBottom: 0 }}>
							<li>
								Our <a onClick={OpenHelpHandler(HelpIds.irsW9Form)}>FAQ</a>
							</li>

							<li>
								<a href="https://www.irs.gov/pub/irs-pdf/iw9.pdf" target="_blank" rel="noreferrer">
									IRS Form W-9 Instructions
								</a>
							</li>
							<li>
								<a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noreferrer">
									PDF version of W-9 form that includes instructions
								</a>
							</li>
						</ul>
					</>
				}
				type="info"
				style={{ marginBottom: 32 }}
			/>

			<Field
				name="usTaxpayerInfo.name"
				label={
					<>
						Name <InfoTooltip title="This corresponds to Field 1 on the IRS W-9 form." />
					</>
				}
				required
				component={LegacyInputField}
				validate={[nameRequired, nameMaxLength]}
				maxLength={MAX_NAME_LENGTH}
				placeholder="As shown on your income tax return"
			/>

			<Field
				name="usTaxpayerInfo.businessName"
				label={
					<>
						Business name (if different from above){" "}
						<InfoTooltip title="This corresponds to Field 2 on the IRS W-9 form." />
					</>
				}
				component={LegacyInputField}
				maxLength={MAX_NAME_LENGTH}
				placeholder="if different from above"
			/>
			<Flex gap="20px">
				<Field
					name="usTaxpayerInfo.federalTaxClassification"
					label={
						<>
							Federal Tax Classification <InfoTooltip title="This corresponds to Field 3a on the IRS W-9 form." />
						</>
					}
					required
					placeholder="Select one"
					component={SelectField}
					validate={classificationRequired}
					options={federalTaxClassificationOptions}
				/>
				<Field
					name="usTaxpayerInfo.exemptPayeeCode"
					label={
						<>
							Exempt payee code (if any) <InfoTooltip title="This corresponds to Field 4 on the IRS W-9 form." />
						</>
					}
					required
					placeholder="Select one"
					component={SelectField}
					validate={exemptPayeeCodeRequired}
					options={exemptPayeeCodeOptions}
				/>
			</Flex>
			<P style={{ marginTop: "-10px" }}>
				See description of each code{" "}
				<a target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" rel="noreferrer">
					here
				</a>{" "}
				("Line 4, Exemptions" on page 3).
			</P>
			<Field
				name="usTaxpayerInfo.address.address"
				label="Address"
				placeholder="Street address"
				component={LegacyInputField}
				required
				validate={[addressRequired]}
			/>
			<Field
				label="Address 2"
				name="usTaxpayerInfo.address.addressTwo"
				component={LegacyInputField}
				placeholder="Apt, suite, unit, building, floor, etc"
			/>
			<Field
				label="City"
				name="usTaxpayerInfo.address.city"
				component={LegacyInputField}
				placeholder="City"
				required
				validate={[cityRequired]}
			/>
			<div style={{ display: "flex", gap: 20 }}>
				<Field
					name="usTaxpayerInfo.address.postalCode"
					label="Postal code"
					component={LegacyInputField}
					placeholder="Postal code"
					required
					maxLength={5}
					validate={[postalCodeRequired, validPostalCode]}
				/>
				<Field
					name="usTaxpayerInfo.address.state"
					label="State"
					component={SelectField}
					placeholder="Select a state"
					validate={[stateRequired]}
					showSearch
					required
					options={LocationMap.US}
				/>
			</div>
			<Alert
				showIcon
				message="Note: Exemption from FATCA reporting code (if any) is not applicable"
				type="warning"
				style={{ marginBottom: 12 }}
			/>
			<Field
				name="usTaxpayerInfo.email"
				label="Email"
				required
				component={LegacyInputField}
				validate={[emailRequired, validEmail]}
				maxLength={MAX_NAME_LENGTH}
				placeholder="Email address that you want to receive the 1099-K form"
			/>

			<Field
				name="usTaxpayerInfo.tinType"
				label="Taxpayer identification number (TIN)"
				component={RadioField}
				button
				required
				validate={tinTypeRequired}
				options={[
					{
						label: "Social security number (SSN)",
						value: "SSN"
					},
					{
						label: "Employer identification number (EIN)",
						value: "EIN"
					}
				]}
			/>
			{formValues.tinType ? (
				<>
					<Field
						name="usTaxpayerInfo.tin"
						required
						component={PasswordField}
						validate={tinChecks}
						placeholder={`Enter your ${formValues.tinType} number`}
						type="password"
					/>
					<Field
						name="usTaxpayerInfo.tinCheck"
						component={PasswordField}
						validate={tinMatch}
						placeholder={`Enter your ${formValues.tinType} number again`}
						type="password"
						description={`We need to verify your ${formValues.tinType} number. Please enter it again.`}
						preventPaste
					/>
				</>
			) : null}
			<div style={{ padding: 16, backgroundColor: "rgba(27, 27, 27, 0.06)", borderRadius: 4 }}>
				<P>Under penalties of perjury, I certify that:</P>
				<ol style={{ fontWeight: 600 }}>
					<li>
						The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to
						be issued to me); and
					</li>
					<li>
						I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not
						been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a
						failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to
						backup withholding; and
					</li>
					<li>I am a US citizen or other US person; and</li>
					<li>
						The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.
					</li>
				</ol>
				<P style={{ maxWidth: "100%" }}>
					The Internal Revenue Service does not require your consent to any provision of this document other than the
					certifications required to avoid backup withholding.
				</P>

				<Field
					name="usTaxpayerInfo.signature"
					label="Signature"
					required
					component={LegacyInputField}
					validate={[signatureRequired]}
					placeholder="Typing your name acts as your signature"
				/>
				{formValues.signatureDate ? (
					<P style={{ color: "#808080", marginTop: "-10px" }}>Signed on {formattedSignatureDate}</P>
				) : null}
			</div>
		</>
	);
};
