import {
	GET_EVENT_TICKET_TYPES_ERROR,
	GET_EVENT_TICKET_TYPES_REQUEST,
	GET_EVENT_TICKET_TYPES_SUCCESS,
	GET_MERCH_ERROR,
	GET_MERCH_LIST_ERROR,
	GET_MERCH_LIST_REQUEST,
	GET_MERCH_LIST_SUCCESS,
	GET_MERCH_REPORT_CSV_ERROR,
	GET_MERCH_REPORT_CSV_REQUEST,
	GET_MERCH_REPORT_CSV_SUCCESS,
	GET_MERCH_REPORT_ERROR,
	GET_MERCH_REPORT_REQUEST,
	GET_MERCH_REPORT_SUCCESS,
	GET_MERCH_REQUEST,
	GET_MERCH_SUCCESS,
	MOVE_MERCH_IN_LIST,
	SAVE_MERCH_ERROR,
	SAVE_MERCH_REQUEST,
	SAVE_MERCH_SUCCESS
} from "../actions/merchandiseActions";

const initialState = {
	loading: false,
	error: false,
	merchList: [],
	merchData: false,
	ticketTypes: {
		data: [],
		loading: false,
		error: false
	},
	report: [],
	dates: [],
	datesUseCalendar: false,
	count: 0,
	page: 1,
	csv: {
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_MERCH_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_MERCH_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case GET_MERCH_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				merchData: action.merchData
			};
		case GET_MERCH_LIST_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_MERCH_LIST_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case GET_MERCH_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				merchList: action.merchList
			};
		case SAVE_MERCH_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_MERCH_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case SAVE_MERCH_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				merchList: action.merchList
			};
		case GET_EVENT_TICKET_TYPES_REQUEST:
			return {
				...state,
				ticketTypes: {
					...state.ticketTypes,
					loading: true
				}
			};
		case GET_EVENT_TICKET_TYPES_SUCCESS:
			return {
				...state,
				ticketTypes: {
					...state.ticketTypes,
					loading: false,
					error: false,
					data: action.ticketTypes
				}
			};
		case GET_EVENT_TICKET_TYPES_ERROR:
			return {
				...state,
				ticketTypes: {
					...state.ticketTypes,
					error: true,
					loading: false
				}
			};
		case GET_MERCH_REPORT_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				page: action.page
			};
		case GET_MERCH_REPORT_SUCCESS:
			return {
				...state,
				loading: false,
				report: action.report,
				dates: action.dates,
				count: action.count,
				datesUseCalendar: action.datesUseCalendar
			};
		case GET_MERCH_REPORT_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};

		case GET_MERCH_REPORT_CSV_SUCCESS:
			return {
				...state,
				csv: {
					loading: false,
					error: false
				}
			};
		case GET_MERCH_REPORT_CSV_REQUEST:
			return {
				...state,
				csv: {
					loading: true,
					error: false
				}
			};
		case GET_MERCH_REPORT_CSV_ERROR:
			return {
				...state,
				csv: {
					loading: false,
					error: true
				}
			};
		case MOVE_MERCH_IN_LIST: {
			const { fromId, toId } = action;

			const currentItems = [...state.merchList];

			let temp = currentItems[fromId];
			currentItems[fromId] = currentItems[toId];
			currentItems[toId] = temp;

			return {
				...state,
				merchList: currentItems
			};
		}
		default:
			return state;
	}
};
