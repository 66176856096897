import { SingleColumnPageWrap } from "@/auth/components/SingleColumnPageWrap";
import { LoadingSpinner } from "@/components/AppBlocks";
import { H2, P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/ui/Button";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const MicrosoftAuth = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { microsoftSignIn } = useAuth();

	const [errorMessage, setErrorMessage] = useState("");
	const [isNotAssignedError, setIsNotAssignedError] = useState(false);

	const consoleUrl = getConfig("CONSOLE_URL");

	const code = searchParams?.get("code");
	const state = searchParams?.get("state");
	const from = searchParams?.get("from");
	const error = searchParams?.get("error");
	const errorDescription = searchParams?.get("error_description");

	const handleMicrosoftSignIn = async () => {
		if (error) {
			if (errorDescription?.indexOf("is not assigned") !== -1) {
				setIsNotAssignedError(true);
			} else {
				setIsNotAssignedError(false);
				setErrorMessage(errorDescription);
			}
			return;
		}

		if (!code) {
			notification.error({
				message: "Error",
				description: "Code not found"
			});
			navigate("/signin");
			return;
		}

		await microsoftSignIn(
			{
				code,
				redirect_uri: `${consoleUrl}/sso/microsoft`,
				nonce: uuidv4(),
				state
			},
			{ from }
		);
	};

	useEffect(() => {
		handleMicrosoftSignIn();
	}, []);

	return (
		<SingleColumnPageWrap showBuyTicketLink>
			{errorMessage || isNotAssignedError ? (
				<div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
					<H2>Error</H2>
					{isNotAssignedError ? (
						<P>
							You have not been given access to Humanitix by UTS, if this is for a personal event please sign in with a
							personal email address or please contact <a href="mailto:events@uts.edu.au">events@uts.edu.au</a> for
							access.
						</P>
					) : (
						<P>{errorMessage}</P>
					)}
					<Link to="/signin" style={{ textDecoration: "none" }}>
						<Button variant="tertiary">Try again</Button>
					</Link>
				</div>
			) : (
				<div style={{ textAlign: "center" }}>
					<H2 style={{ textAlign: "center" }}>Requesting access token</H2>
					<LoadingSpinner />
				</div>
			)}
		</SingleColumnPageWrap>
	);
};
