import { locationDataByCountryCode } from "@hx/locations";
import speakingurl from "speakingurl";
import LocationService from "../services/LocationService";

const localisedText = {
	colour: {
		US: "color"
	}
};

export const getCurrencyFromLocation = (location = LocationService.getLocation()) => {
	return locationDataByCountryCode[location]?.currency || "AUD";
};

export const getCurrencySymbol = (location = LocationService.getLocation()) => {
	return locationDataByCountryCode[location]?.symbol || "$";
};

export const getLocalisedText = (text, location = LocationService.getLocation(true)) => {
	if (localisedText[text] && localisedText[text][location.toUpperCase()]) {
		return localisedText[text][location.toUpperCase()];
	}
	return text;
};

export const trim = (str) => {
	if (!isString(str)) {
		return str;
	}
	return str.trim();
};

export const urlSafe = (str) => {
	if (!isString(str)) {
		return str;
	}
	const lastCharacter = str.charAt(str.length - 1);
	const formatedStr = speakingurl(str);
	if (lastCharacter === "-") {
		return `${formatedStr}-`;
	}
	return formatedStr;
};

export const fullName = (first, last) => {
	const parts = [];
	if (first) {
		parts.push(first);
	}
	if (last) {
		parts.push(last);
	}
	return parts.join(" ");
};

export const currency = (number, noSign) => {
	if (isNaN(number)) {
		return number;
	}
	number = parseFloat(number);
	number = number.toFixed(2);
	number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	const dollarSign = noSign ? "" : getCurrencySymbol();
	return `${dollarSign}${number}`;
};

export const objToQueryString = (obj) => {
	const queryStr = Object.keys(obj)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
		.join("&");
	return `?${queryStr}`;
};

export const replaceSpecialCharacters = (string) => {
	if (!isString(string)) {
		return string;
	}
	return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const sentenceCase = (string) => {
	if (!isString(string)) {
		return string;
	}
	let result = string.replace(/([A-Z])/g, " $1");
	let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	return finalResult;
};

const isString = (string) => {
	if (string && (typeof string === "string" || string instanceof String)) {
		return true;
	}
	return false;
};

//encoding compatible with RFC 3986 (which reserves !, ', (, ), and *) as encodeURIComponent doesn't escape on  - _ . ! ~ * ' ( )
export const encodeURIComponentWithRFC3986Characters = (string) => {
	if (!isString(string)) {
		return string;
	}
	return encodeURIComponent(string).replace(/[!'()*]/g, escape);
};

// convert value to array based on delimiter
export const convertValueToArray = (value, delimiter) => {
	const values = value && String(value).indexOf(delimiter) !== -1 ? value.split(delimiter) : [value];
	return values;
};

export const formatDateString = (dateStr) => {
	if (!(typeof dateStr === "string") || dateStr.length === 10 || dateStr.length < 3) {
		return dateStr;
	}
	const delimiter = dateStr.indexOf("/") !== -1 ? "/" : "-";
	const parts = dateStr.split(delimiter);
	const formatedStr = parts
		.reduce((formatedParts, part) => {
			let formattedPart = part;
			if (part.length < 2) {
				formattedPart = `0${part}`;
			}
			formatedParts.push(formattedPart);
			return formatedParts;
		}, [])
		.join(delimiter);
	return formatedStr;
};

export const strComparisonCaseIgnore = (str1, str2) => {
	if (!str1) {
		return false;
	}
	const result = str1.localeCompare(str2, "en", { sensitivity: "base" });
	return result === 0;
};

export const getInvoiceDueString = (dueType, duePeriodInDays) => {
	const prefix = `Invoice will be due ${duePeriodInDays} day${duePeriodInDays > 1 ? "s" : ""} `;
	switch (dueType) {
		case "before":
			return prefix + "before the event date";
		case "after":
			return prefix + "after the event date";
		case "raised":
		default:
			return prefix + "after the purchase";
	}
};

export const truncateString = (string, maxLength) => {
	return string.length > maxLength ? `${string.substring(0, maxLength - 3)} ...` : string;
};
