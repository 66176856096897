import { getConfig } from "@/config";

interface IFBTrackingProps {
	pageName: string;
}

const FBTracking = ({ pageName }: IFBTrackingProps) => {
	const WEBSITE_URL = getConfig("WEBSITE_URL");
	const iframe = `<iframe src="${WEBSITE_URL}/fbp/${pageName}" style="width:0;height:0;border:none;"></iframe>`;
	return <div dangerouslySetInnerHTML={{ __html: iframe }} />;
};

export default FBTracking;
