import { notification } from "@/ui/antd";
import FileDownload from "react-file-download";
import EventsService from "../../services/EventsService";
import WaitlistService from "../../services/WaitlistService";
import Filename from "../../utils/Filename";
import { GET_EVENT_SUCCESS } from "./eventActions";
import { updateSearch } from "./searchActions";

export const FIND_WAITLIST_REQUEST = "FIND_WAITLIST_REQUEST";
export const FIND_WAITLIST_SUCCESS = "FIND_WAITLIST_SUCCESS";
export const FIND_WAITLIST_ERROR = "FIND_WAITLIST_ERROR";

export const ADD_TO_WAITLIST_REQUEST = "ADD_TO_WAITLIST_REQUEST";
export const ADD_TO_WAITLIST_SUCCESS = "ADD_TO_WAITLIST_SUCCESS";
export const ADD_TO_WAITLIST_ERROR = "ADD_TO_WAITLIST_ERROR";

const REMOVE_FROM_WAITLIST_REQUEST = "REMOVE_FROM_WAITLIST_REQUEST";
const REMOVE_FROM_WAITLIST_SUCCESS = "REMOVE_FROM_WAITLIST_SUCCESS";
const REMOVE_FROM_WAITLIST_ERROR = "REMOVE_FROM_WAITLIST_ERROR";

export const OPEN_OFFER_MODAL = "OPEN_OFFER_MODAL";
export const CLOSE_OFFER_MODAL = "CLOSE_OFFER_MODAL";

export const OFFER_TICKETS_REQUEST = "OFFER_TICKETS_REQUEST";
export const OFFER_TICKETS_SUCCESS = "OFFER_TICKETS_SUCCESS";
export const OFFER_TICKETS_ERROR = "OFFER_TICKETS_ERROR";

export const GET_WAITLIST_CSV_REQUEST = "GET_WAITLIST_CSV_REQUEST";
export const GET_WAITLIST_CSV_SUCCESS = "GET_WAITLIST_CSV_SUCCESS";
export const GET_WAITLIST_CSV_ERROR = "GET_WAITLIST_CSV_ERROR";

export const SET_WAITLIST_SELECTED = "SET_WAITLIST_SELECTED";

const PUT_BACK_ON_SALE_REQUEST = "PUT_BACK_ON_SALE_REQUEST";
const PUT_BACK_ON_SALE_SUCCESS = "PUT_BACK_ON_SALE_SUCCESS";
const PUT_BACK_ON_SALE_ERROR = "PUT_BACK_ON_SALE_ERROR";

export const UPDATE_SHOW_SETTINGS_TAB_ONLY = "UPDATE_SHOW_SETTINGS_TAB_ONLY";

const reloadEvent = async (dispatch, getState) => {
	const { currentEvent } = getState();
	const event = await EventsService.get(currentEvent.event._id);
	dispatch({ type: GET_EVENT_SUCCESS, event });
};

function IsSearchFilterOnDefault(search) {
	const queryIsEmpty = !search.query || search.query === "";
	const searchFilterIsOnDefault =
		queryIsEmpty && !search.ticketIds?.length && !search.eventDateIds?.length && !search.status;
	return searchFilterIsOnDefault;
}

export const putBackOnSale = (eventId) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: PUT_BACK_ON_SALE_REQUEST });
			await WaitlistService.putBackOnSale(eventId);
			dispatch({ type: PUT_BACK_ON_SALE_SUCCESS });
			await reloadEvent(dispatch, getState);
			notification.success({
				message: "Success",
				description: "Event has been put back on sale"
			});
		} catch (error) {
			dispatch({ type: PUT_BACK_ON_SALE_ERROR, error });
		}
	};
};

export const openOfferModal = () => (dispatch) => dispatch({ type: OPEN_OFFER_MODAL });
export const closeOfferModal = () => (dispatch) => dispatch({ type: CLOSE_OFFER_MODAL });

export const setSelected = (selectedRowKeys) => (dispatch) =>
	dispatch({ type: SET_WAITLIST_SELECTED, selectedRowKeys });

export const find = (eventId, requestingPage) => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const { currentEvent } = state;
			const { enabled } = currentEvent?.event?.waitlistSettings || {};
			const page = requestingPage || state.search.page;
			const searchFilterIsOnDefault = IsSearchFilterOnDefault(state.search);
			dispatch({ type: FIND_WAITLIST_REQUEST, page });
			const wailitstResults = await WaitlistService.find(eventId, state.search, page);
			dispatch({
				type: FIND_WAITLIST_SUCCESS,
				list: wailitstResults.waitlist,
				total: wailitstResults.total,
				showSettingsTabOnly: !enabled && !wailitstResults.length && page === 1 && searchFilterIsOnDefault
			});
		} catch (error) {
			dispatch({ type: FIND_WAITLIST_ERROR, error });
		}
	};
};

export const updateShowSettingsTabOnly = (waitlistEnabled) => {
	return (dispatch, getState) => {
		const state = getState();
		const { waitlist } = state;
		const searchFilterIsOnDefault = IsSearchFilterOnDefault(state.search);
		dispatch({
			type: UPDATE_SHOW_SETTINGS_TAB_ONLY,
			showSettingsTabOnly:
				!waitlistEnabled && !waitlist.list.data.length && waitlist.list.page === 1 && searchFilterIsOnDefault
		});
	};
};

export const getCsv = (eventId) => {
	const filename = Filename.generateFileName("Waitlist", ".csv");
	return (dispatch, getState) => {
		const state = getState();
		const queryBy = state.search;
		dispatch({ type: GET_WAITLIST_CSV_REQUEST });
		WaitlistService.getCsv(eventId, queryBy)
			.then((data) => {
				dispatch({ type: GET_WAITLIST_CSV_SUCCESS });
				FileDownload(data, filename);
			})
			.catch(() => {
				dispatch({ type: GET_WAITLIST_CSV_ERROR });
			});
	};
};

export const add = (eventId, data, handleClose) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: ADD_TO_WAITLIST_REQUEST });
			await WaitlistService.add(eventId, data);
			dispatch({ type: ADD_TO_WAITLIST_SUCCESS });
			await reloadEvent(dispatch, getState);
			handleClose();
		} catch (err) {
			dispatch({ type: ADD_TO_WAITLIST_ERROR });
		}
	};
};

export const remove = (eventId, waitlistIds) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: REMOVE_FROM_WAITLIST_REQUEST });
			await WaitlistService.remove(eventId, waitlistIds);
			dispatch({ type: REMOVE_FROM_WAITLIST_SUCCESS });
			await reloadEvent(dispatch, getState);
			const requestingPage = 1;
			dispatch(updateSearch({ page: requestingPage }, true));
			dispatch(find(eventId, requestingPage));
		} catch (error) {
			dispatch({ type: REMOVE_FROM_WAITLIST_ERROR });
		}
	};
};

export const offerTickets = (eventId, eventDateIds, waitlistIds) => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: OFFER_TICKETS_REQUEST });
			await WaitlistService.offerTickets(eventId, eventDateIds, waitlistIds);
			dispatch({ type: OFFER_TICKETS_SUCCESS });
			dispatch(find(eventId));
			await reloadEvent(dispatch, getState);
			const msg = waitlistIds.length > 1 ? "Offers have been sent" : "Offer has been sent";
			notification.success({ message: "Success", description: msg });
			dispatch(closeOfferModal());
		} catch (error) {
			dispatch({ type: OFFER_TICKETS_ERROR });
			const msg = waitlistIds.length > 1 ? "Failed to send offers" : "Failed to send offer";
			notification.error({ message: "Error", description: msg });
		}
	};
};
