import { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb";
import LoadErrorView from "../../components/LoadErrorView";
import { getUser } from "../../state/actions/userActions";

import SalesforceSetUp from "./SalesforceSetUp";
import SalesforceSettings from "./SalesforceSettings";
import TestSalesforceConnection from "./TestSalesforceConnection";

class Salesforce extends Component {
	constructor(props) {
		super(props);
		this.loadUser = this.loadUser.bind(this);
	}

	componentDidMount() {
		const { userId, getUser } = this.props;
		getUser(userId);
	}

	loadUser() {
		const { userId, getUser } = this.props;
		getUser(userId);
	}

	render() {
		const { currentUser, userId } = this.props;

		const showSettings =
			currentUser &&
			currentUser.user &&
			currentUser.user.salesforceSettings &&
			currentUser.user.salesforceSettings.refreshToken;

		const showTest = showSettings && !currentUser.user.salesforceSettings.connectionTested;

		const showSetup = !showSettings && !currentUser.loading;

		return (
			<LoadErrorView
				loading={!currentUser.user && currentUser.loading}
				error={currentUser.error}
				retryAction={() => this.loadUser()}
			>
				<Breadcrumb prevPath="/console/account/advanced/integrations" prevTitle="Integrations" currTitle="Salesforce" />
				{showTest && <TestSalesforceConnection userId={userId} />}
				{showSettings && <SalesforceSettings userId={userId} />}
				{showSetup && <SalesforceSetUp userId={userId} />}
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => {
		return { currentUser: state.currentUser };
	},
	{ getUser }
)(Salesforce);
