import { css } from "@emotion/css";
import React from "react";

const heading = css({
	fontWeight: 600,
	fontSize: 26,
	margin: 0,
	marginBottom: 16,
	lineHeight: "36px",
	"@media(max-width: 600px)": {
		fontSize: 20,
		marginBottom: 16,
		lineHeight: "27px"
	}
});

const H1 = ({ children, color }: { children: React.ReactNode; color?: string }) => {
	return (
		<h1 className={heading} style={{ color: color || "inherit" }}>
			{children}
		</h1>
	);
};

export default H1;
