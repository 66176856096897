import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { saveEvent } from "@/state/actions/eventActions";
import { Button } from "@/ui";
import { Responsive } from "@/ui";
import { Tabs } from "@/ui/antd";
import TemplateModal from "@/events/event/info/TemplateModal";
import { getTextColor } from "@/utils/Colours";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { ImportOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { change, formValueSelector, reduxForm } from "redux-form";
import EventStyling from "./EventStyling";
import Ticket from "./Ticket";

const headerWrapper = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center"
});

const formName = "styling";

let Styling = ({
	dispatch,
	saveEvent,
	currentEvent,
	handleSubmit,
	ticketMessage,
	banner,
	theme,
	hideCharity,
	ticketLogo,
	ticketBackgroundColor
}) => {
	const params = useParams();
	const navigate = useNavigate();
	const tab = params?.tab || "settings";
	const [showModal, toggleModal] = useState(false);
	const [isCustom, setIsCustom] = useState(false);

	// change ticket line color (picked by color picker)
	const updateTicketLineColor = (e, _ticketLineColor) => {
		const ticketLineColor = getTextColor(_ticketLineColor);
		dispatch(change(formName, "theme.ticketLineColor", ticketLineColor));
	};

	const save = (values) => {
		const defaultTheme = values && values.theme && values.theme.type ? values.theme.type : "default";
		const eventId = params?.eventId;
		const type = isCustom ? "custom" : defaultTheme;
		const theme = { ...values.theme, type };
		const messages = { ...currentEvent.event.messages, ...values.messages };
		if (theme.customTicketButtonText !== "" && theme.ticketButtonText === "Custom") {
			theme.ticketButtonText = theme.customTicketButtonText;
		}
		dispatch(change(formName, "theme", theme));
		const updateData = { theme, messages, bannerImage: values.bannerImage, logoImage: values.logoImage };
		saveEvent(eventId, updateData);
	};

	const onTemplateChosen = (templateData) => {
		dispatch(change(formName, "theme", templateData));
		setIsCustom(true);
		toggleModal(false);
	};

	const importWrapper = css({
		"@media(max-width:600px)": {
			"&:after": { clear: "both", display: "block", content: '""' }
		}
	});
	return (
		<div>
			<form onSubmit={handleSubmit(save)}>
				<PageHeading
					title="Styling"
					helpButton={{
						link: HelpIds.customDesign,
						title: "Help - Custom Styling"
					}}
				/>

				<ContentBlock>
					<div className={headerWrapper}>
						<P>Edit and save your styling using the option below.</P>
						<div className={importWrapper}>
							<Responsive
								desktop={
									<Button aria-label="Import template" onClick={() => toggleModal(true)} variant="ghost">
										Import template
									</Button>
								}
								mobile={
									<Button aria-label="Import template" iconOnly onClick={() => toggleModal(true)} variant="ghost">
										<ImportOutlined />
									</Button>
								}
							/>
						</div>
					</div>

					<Tabs
						activeKey={tab}
						onChange={(activeKey) => {
							if (activeKey === "settings") {
								navigate(`/console/my-events/${currentEvent.event._id}/styling`);
							} else {
								navigate(`/console/my-events/${currentEvent.event._id}/styling/${activeKey}`);
							}
						}}
						items={[
							{
								children: <EventStyling theme={theme} currentEvent={currentEvent} />,
								key: "settings",
								label: "Event page"
							},
							{
								children: (
									<Ticket
										currentEvent={currentEvent}
										theme={theme}
										updateTicketLineColor={updateTicketLineColor}
										ticketMessage={ticketMessage}
										banner={banner}
										hideCharity={hideCharity}
										ticketLogo={ticketLogo}
										ticketBackgroundColor={ticketBackgroundColor}
									/>
								),
								key: "tickets",
								label: "Ticket"
							}
						]}
					/>
					<TemplateModal
						onTemplateChosen={onTemplateChosen}
						handleCancel={() => toggleModal(false)}
						visible={showModal}
						templateType="styling"
					/>
				</ContentBlock>
				<FormBar formLayout="horizontal">
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						ariaLabel="Save"
						size="large"
					>
						Save
					</LegacyButton>
				</FormBar>
			</form>
		</div>
	);
};

Styling = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(Styling);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		const initialValues = state.currentEvent.event;

		const defaultTheme = {
			ticketBackgroundColor: "#FFA680",
			background: { type: "color" },
			primaryColor: "#ffb18f",
			baseColor: "#353337",
			colorScheme: "light"
		};
		initialValues.theme = {
			ticketButtonText: "Get Tickets",
			customTicketButtonText: "",
			...defaultTheme,
			...initialValues.theme
		};
		return {
			initialValues: initialValues,
			currentEvent: state.currentEvent,
			theme: selector(state, "theme") || initialValues.theme,
			ticketMessage: selector(state, "messages.ticketMessage"),
			banner: selector(state, "messages.banner"),
			ticketLogo: selector(state, "messages.ticketLogo"),
			hideCharity: selector(state, "messages.hideImpact"),
			ticketBackgroundColor: selector(state, "theme.ticketBackgroundColor")
		};
	},
	{ saveEvent }
)(withSaveCheck(Styling, formName));
