import axios from "./AxiosWrapper";

const BoxOfficeService = {
	async getTerminals({ search, page }) {
		const response = await axios.get(`admin/box-office/terminals`, {
			params: { search, page }
		});
		return response;
	},
	async deleteTerminal(id) {
		const response = await axios.delete(`admin/box-office/terminals/${id}`, {});
		return response;
	},
	async deleteManyTerminals(ids) {
		const response = await axios.delete(`admin/box-office/terminals`, { params: { ids } });
		return response;
	}
};
export default BoxOfficeService;
