import { ADD_UNDO_ACTION, REDO, SET_VIEW, UNDO, stack } from "../actions/seatingMapUndoActions";

import { SAVE_SEATING_MAP_SUCCESS } from "../actions/seatingMapActions";

const initialState = {
	view: "seatingView",
	seatingView: {
		undoActions: stack(),
		redoActions: stack()
	},
	sectionView: {
		undoActions: stack(),
		redoActions: stack()
	},
	managerView: {
		undoActions: stack(),
		redoActions: stack()
	},
	ticketMapping: {
		undoActions: stack(),
		redoActions: stack()
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_SEATING_MAP_SUCCESS:
			return {
				...state,
				seatingView: {
					undoActions: stack(),
					redoActions: stack()
				},
				sectionView: {
					undoActions: stack(),
					redoActions: stack()
				},
				managerView: {
					undoActions: stack(),
					redoActions: stack()
				},
				ticketMapping: {
					undoActions: stack(),
					redoActions: stack()
				}
			};
		case ADD_UNDO_ACTION:
			return {
				...state,
				[state.view]: {
					undoActions: action.undo,
					redoActions: action.redo
				}
			};
		case UNDO:
			return {
				...state,
				[state.view]: {
					undoActions: action.undo,
					redoActions: action.redo
				}
			};
		case REDO:
			return {
				...state,
				[state.view]: {
					undoActions: action.undo,
					redoActions: action.redo
				}
			};
		case SET_VIEW:
			return {
				view: action.view,
				seatingView: {
					undoActions: stack(),
					redoActions: stack()
				},
				sectionView: {
					undoActions: stack(),
					redoActions: stack()
				},
				managerView: {
					undoActions: stack(),
					redoActions: stack()
				},
				ticketMapping: {
					undoActions: stack(),
					redoActions: stack()
				}
			};
		default:
			return state;
	}
};

export default reducer;
