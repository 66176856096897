import { Modal } from "@/ui/antd";
import { Component } from "react";
import { Field, FieldArray } from "redux-form";
import { RichTextAreaField } from "@/components/Fields";
import PersonalisationInformation from "@/components/PersonalisationInformation";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getMessagePersonalisations } from "@/utils/Personalisations";
import TicketMessagesTable from "./TicketMessagesTable";

const personalisationsObject = getMessagePersonalisations();

class ConfirmationMesaage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false
		};
	}

	toggleModal = () => {
		// const { previewCompleteMessage, messages, currentEvent } = this.props;
		// previewCompleteMessage(currentEvent.event._id, messages.orderMessage);
		this.setState({ modalOpen: !this.state.modalOpen });
	};
	render() {
		const { modalOpen } = this.state;
		const { currentEvent, messages, changeFieldValue } = this.props;
		return (
			<>
				<LegacyButton
					style={{ float: "right" }}
					icon={{ left: true, name: "preview" }}
					type="action"
					flavor="minimal"
					onClick={this.toggleModal}
					ariaLabel="Preview confirmation page"
				>
					Preview
				</LegacyButton>

				<Field
					label="Add message to confirmation page"
					description="This message will show up on the confirmation page after a customer has successfully purchased tickets"
					name="messages.orderMessage"
					placeholder="Thank you for purchasing tickets! See you at the Event!"
					component={RichTextAreaField}
					minHeight={150}
					personalisations={personalisationsObject}
				/>
				<FieldArray
					name="messages.orderMessagesByTicketType"
					ticketMessages={messages.orderMessagesByTicketType}
					component={TicketMessagesTable}
					currentEvent={currentEvent}
					changeFieldValue={changeFieldValue}
				/>
				<PersonalisationInformation personalisationList={personalisationsObject} />
				<Modal
					title="Order confirmation message preview"
					open={modalOpen}
					onCancel={this.toggleModal}
					destroyOnClose
					footer={
						<LegacyButton onClick={this.toggleModal} ariaLabel="Close">
							Close
						</LegacyButton>
					}
				>
					{/* JEN-14649 disabled until v2 version is created  */}
					<p>Previews are currently not available. We’re building something new!</p>{" "}
					<p>To preview, we recommend making a free order</p>
					{/* <PreviewConfirmation eventSlug={currentEvent.event.slug} /> */}
				</Modal>
			</>
		);
	}
}

export default ConfirmationMesaage;
