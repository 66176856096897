import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

class AdditionalDonation extends Component {
	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;

		const { load, loadPage, downloadCsv } = this.props;

		const columns = [
			{
				title: "Id",
				dataIndex: "orderName",
				key: "orderName",
				render: (orderName, record) => {
					const link = record.userId
						? `/console/promote/gift-cards/order/${record._id}`
						: `/console/my-events/${record.eventId}/orders/${record._id}`;
					return <Link to={link}>{orderName}</Link>;
				}
			},
			{
				title: "First name",
				dataIndex: "firstName",
				key: "firstName"
			},
			{
				title: "Last name",
				dataIndex: "lastName",
				key: "lastName"
			},
			{
				title: "Event",
				dataIndex: "eventName",
				key: "eventName",
				render: (eventName, record) => {
					return <Link to={`/console/my-events/${record.eventId}`}>{eventName}</Link>;
				}
			},
			{
				title: "Event date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "Net additional donation",
				dataIndex: "netClientDonation",
				key: "netClientDonation"
			},
			{
				title: "Fees",
				dataIndex: "fee",
				key: "fee"
			},
			{
				title: "Refund",
				dataIndex: "refund",
				key: "refund"
			},
			{
				title: "Total additional donation (inc. fees)",
				dataIndex: "clientDonation",
				key: "total"
			}
		];

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						showSearchField={false}
						firstDateName="eventDates"
						firstDateLabel="Event date range"
						secondDateName="orderDates"
						secondDateLabel="Order date range"
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
								{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
							</LegacyButton>
						</div>
						<Table
							columns={columns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default withReportProvider(AdditionalDonation, "Additional donations", "additional-donation");
