import {
	GET_AFFILIATE_CODE_CSV_ERROR,
	GET_AFFILIATE_CODE_CSV_REQUEST,
	GET_AFFILIATE_CODE_CSV_SUCCESS,
	GET_AFFILIATE_REPORTS_ERROR,
	GET_AFFILIATE_REPORTS_REQUEST,
	GET_AFFILIATE_REPORTS_SUCCESS,
	GET_DISCOUNT_CODE_CSV_ERROR,
	GET_DISCOUNT_CODE_CSV_REQUEST,
	GET_DISCOUNT_CODE_CSV_SUCCESS,
	GET_DISCOUNT_REPORTS_ERROR,
	GET_DISCOUNT_REPORTS_REQUEST,
	GET_DISCOUNT_REPORTS_SUCCESS
} from "../actions/reportActions";

const initialState = {
	discounts: {
		data: [],
		loading: false,
		error: false,
		timeScale: "Today"
	},
	accessCodes: {
		data: [],
		loading: false,
		error: false,
		timeScale: "Today"
	},
	affiliateCodes: {
		data: [],
		loading: false,
		error: false
	},
	reportRequests: {
		affiliateCSV: {
			loading: false,
			error: false
		},
		discountsCSV: {
			loading: false,
			error: false
		},
		accessCodesCSV: {
			loading: false,
			error: false
		}
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_DISCOUNT_REPORTS_REQUEST:
			return {
				...state,
				discounts: {
					...state.discounts,
					loading: true,
					error: false
				}
			};
		case GET_DISCOUNT_REPORTS_SUCCESS:
			return {
				...state,
				discounts: {
					...state.discounts,
					data: action.data.discounts,
					loading: false,
					error: false
				}
			};
		case GET_DISCOUNT_REPORTS_ERROR:
			return {
				...state,
				discounts: {
					...state.discounts,
					loading: false,
					error: action.error
				}
			};

		case GET_AFFILIATE_REPORTS_REQUEST:
			return {
				...state,
				affiliateCodes: {
					...state.affiliateCodes,
					loading: true,
					error: false
				}
			};
		case GET_AFFILIATE_REPORTS_SUCCESS:
			return {
				...state,
				affiliateCodes: {
					...state.affiliateCodes,
					data: action.data,
					loading: false,
					error: false
				}
			};
		case GET_AFFILIATE_REPORTS_ERROR:
			return {
				...state,
				affiliateCodes: {
					...state.affiliateCodes,
					loading: false,
					error: action.error
				}
			};
		//affiliate code csv
		case GET_AFFILIATE_CODE_CSV_REQUEST:
			return {
				...state,
				reportRequests: {
					...state.reportRequests,
					affiliateCSV: {
						loading: true,
						error: false
					}
				}
			};
		case GET_AFFILIATE_CODE_CSV_SUCCESS:
			return {
				...state,
				reportRequests: {
					...state.reportRequests,
					affiliateCSV: {
						loading: false,
						error: false
					}
				}
			};
		case GET_AFFILIATE_CODE_CSV_ERROR:
			return {
				...state,
				reportRequests: {
					...state.reportRequests,
					affiliateCSV: {
						loading: false,
						error: true
					}
				}
			};
		//discount code csv
		case GET_DISCOUNT_CODE_CSV_REQUEST:
			return {
				...state,
				reportRequests: {
					...state.reportRequests,
					discountsCSV: {
						loading: true,
						error: false
					}
				}
			};
		case GET_DISCOUNT_CODE_CSV_SUCCESS:
			return {
				...state,
				reportRequests: {
					...state.reportRequests,
					discountsCSV: {
						loading: false,
						error: false
					}
				}
			};
		case GET_DISCOUNT_CODE_CSV_ERROR:
			return {
				...state,
				reportRequests: {
					...state.reportRequests,
					discountsCSV: {
						loading: false,
						error: true
					}
				}
			};

		default:
			return state;
	}
};
