import { LoadingSpinner } from "@/components/AppBlocks";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import { Link } from "react-router-dom";

export type ButtonVariant = "primary" | "secondary" | "tertiary" | "ghost" | "text" | "danger" | "danger-text";

type ButtonState = "default" | "hover" | "active" | "focus" | "disabled";

type ButtonStateStyles = {
	bgColor?: string;
	borderColor?: string;
	borderWidth?: string;
	color?: string;
	fontWeight?: number;
};

type Styles = {
	[key in ButtonVariant]: {
		[key in ButtonState]: ButtonStateStyles;
	};
};

const primary = "#FFB18F";
const secondary = "#000000";
const textDefault = "#333333";
const textLight = "#FFFFFF";
const tertiaryGrey = "#b8b8b8";
const ghostGrey = "#b8b8b8";
const textHover = "#f1f1f1";
const textActive = "#e0e0e0";
const danger = "#BC2F35";
const disabledOpacity = 0.38;
const fontWeightDefault = 500;
const fontWeightLight = 400;

const makeStyles = (outlined = false): Styles => ({
	primary: {
		default: {
			bgColor: outlined ? "#ffffff" : primary,
			borderColor: primary,
			borderWidth: "1px",
			color: outlined ? primary : textDefault
		},
		hover: {
			bgColor: outlined ? "#ffffff" : "#FFB593",
			borderColor: "#FFB593",
			borderWidth: "1px",
			color: outlined ? primary : textDefault
		},
		active: {
			bgColor: outlined ? "#ffffff" : "#9D5737",
			borderColor: "#9D5737",
			borderWidth: "1px",
			color: outlined ? primary : textDefault
		},
		focus: {
			bgColor: outlined ? "#ffffff" : primary,
			borderColor: primary,
			borderWidth: "1px",
			color: outlined ? primary : textDefault
		},
		disabled: {
			bgColor: outlined ? "#ffffff" : "#FFEAC6",
			borderColor: "#FFEAC6",
			borderWidth: "1px",
			color: outlined ? primary : textDefault
		}
	},
	secondary: {
		default: {
			bgColor: outlined ? "#ffffff" : secondary,
			borderColor: secondary,
			borderWidth: "1px",
			color: outlined ? secondary : textLight
		},
		hover: {
			bgColor: outlined ? "#ffffff" : "#323232",
			borderColor: "#323232",
			borderWidth: "1px",
			color: outlined ? secondary : textLight
		},
		active: {
			bgColor: outlined ? "#ffffff" : "#4a4a4a",
			borderColor: "#4a4a4a",
			borderWidth: "1px",
			color: outlined ? secondary : textLight
		},
		focus: {
			bgColor: outlined ? "#ffffff" : secondary,
			borderColor: secondary,
			borderWidth: "1px",
			color: outlined ? secondary : textLight
		},
		disabled: {
			bgColor: outlined ? "#ffffff" : secondary,
			borderColor: secondary,
			borderWidth: "1px",
			color: outlined ? secondary : textLight
		}
	},
	tertiary: {
		default: {
			bgColor: "#ffffff",
			borderColor: tertiaryGrey,
			borderWidth: "1px"
		},
		hover: {
			bgColor: textHover,
			borderColor: tertiaryGrey,
			borderWidth: "1px"
		},
		active: {
			bgColor: textActive,
			borderColor: tertiaryGrey,
			borderWidth: "1px"
		},
		focus: {
			borderColor: tertiaryGrey,
			borderWidth: "1px"
		},
		disabled: {
			borderColor: tertiaryGrey,
			borderWidth: "1px"
		}
	},
	ghost: {
		default: {
			borderColor: ghostGrey,
			borderWidth: "1px",
			fontWeight: fontWeightLight
		},
		hover: {
			bgColor: textHover,
			borderColor: ghostGrey,
			borderWidth: "1px",
			fontWeight: fontWeightLight
		},
		active: {
			bgColor: textActive,
			borderColor: ghostGrey,
			borderWidth: "1px",
			fontWeight: fontWeightLight
		},
		focus: {
			borderColor: ghostGrey,
			borderWidth: "1px",
			fontWeight: fontWeightLight
		},
		disabled: {
			borderColor: ghostGrey,
			borderWidth: "1px",
			fontWeight: fontWeightLight
		}
	},
	text: {
		default: {
			bgColor: "transparent"
		},
		hover: {
			bgColor: textHover
		},
		active: {
			bgColor: textActive
		},
		focus: {
			bgColor: "transparent"
		},
		disabled: {
			bgColor: "transparent"
		}
	},
	danger: {
		default: {
			bgColor: outlined ? "transparent" : danger,
			borderColor: danger,
			borderWidth: "1px",
			color: outlined ? danger : textLight
		},
		hover: {
			bgColor: outlined ? "transparent" : "#a92a30",
			borderColor: "#a92a30",
			borderWidth: "1px",
			color: outlined ? "#a92a30" : textLight
		},
		active: {
			bgColor: outlined ? "transparent" : "#8f2329",
			borderColor: "#8f2329",
			borderWidth: "1px",
			color: outlined ? "#8f2329" : textLight
		},
		focus: {
			bgColor: outlined ? "transparent" : danger,
			borderColor: danger,
			borderWidth: "1px",
			color: outlined ? danger : textLight
		},
		disabled: {
			bgColor: outlined ? "transparent" : danger,
			borderColor: danger,
			borderWidth: "1px",
			color: outlined ? danger : textLight
		}
	},
	"danger-text": {
		default: {
			bgColor: "transparent",
			color: danger
		},
		hover: {
			bgColor: textHover,
			color: danger
		},
		active: {
			bgColor: textActive,
			color: danger
		},
		focus: {
			bgColor: "transparent",
			color: danger
		},
		disabled: {
			bgColor: "transparent",
			color: danger
		}
	}
});

type StyledButtonProps = {
	disabled?: boolean;
	fullWidth?: boolean;
	href?: string;
	iconOnly?: boolean;
	isLoading?: boolean;
	justify?: "start" | "center" | "end" | "space-between" | "space-around" | "space-evenly" | "stretch";
	noRadius?: boolean;
	outlined?: boolean;
	small?: boolean;
	to?: string;
	variant: ButtonVariant;
};

const StyledButton = styled.button<StyledButtonProps>`
	${(props) => {
		const state = props.disabled ? "disabled" : "default";
		const baseStyle = makeStyles(props.outlined)[props.variant];
		const statefulStyles = baseStyle[state];

		return css`
			align-items: center;
			background-color: ${statefulStyles.bgColor ?? "transparent"};
			border: ${statefulStyles.borderWidth
				? `${statefulStyles.borderWidth} solid ${statefulStyles.borderColor}`
				: "none"};
			border-radius: ${props.noRadius ? "0" : "var(--rounded-sm)"};
			color: ${statefulStyles.color || textDefault};
			cursor: ${props.disabled ? "default" : "pointer"};
			display: flex;
			font-size: 14px;
			font-weight: ${statefulStyles.fontWeight || fontWeightDefault};
			max-height: ${props.small ? "24px" : "36px"};
			min-height: ${props.small ? "24px" : "36px"};
			justify-content: ${props.justify || "center"};
			line-height: normal;
			min-width: ${props.iconOnly ? "36px" : "90px"};
			opacity: ${props.disabled ? disabledOpacity : 1};
			overflow: hidden;
			padding: ${props.small
				? "var(--spacing-sm)"
				: props.iconOnly
				? "var(--spacing-sm)"
				: "var(--spacing-md) var(--spacing-lg)"};
			position: relative;
			text-align: center;
			text-wrap: nowrap;
			transition: all 0.2s ease-in-out;
			user-select: none;
			width: ${props.fullWidth ? "100%" : ""};

			&:hover:not(:disabled) {
				background-color: ${baseStyle.hover.bgColor};
				border-color: ${baseStyle.hover.borderColor};
				color: ${baseStyle.hover.color || textDefault};
			}

			&:active:not(:disabled) {
				background-color: ${baseStyle.active.bgColor};
				border-color: ${baseStyle.active.borderColor};
				color: ${baseStyle.active.color};
			}

			&:focus:visible {
				background-color: ${baseStyle.focus.bgColor};
				border-color: ${baseStyle.focus.borderColor};
				color: ${baseStyle.focus.color};
				outline: 2px solid ${baseStyle.focus.borderColor};
				outline-offset: 2px;
			}

			&:disabled {
				background-color: ${baseStyle.disabled.bgColor};
				border-color: ${baseStyle.disabled.borderColor};
				color: ${baseStyle.disabled.color};
			}
		`;
	}}
`;

const IconContainer = styled.div<{ position: "left" | "right" }>`
	${(props) => (props.position === "left" ? "margin-right" : "margin-left")}: 8px;
	align-items: center;
	display: flex;
	justify-content: center;
	min-width: 18px;
`;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
	StyledButtonProps & {
		children?: ReactNode;
		dataCy?: string;
		htmlType?: "button" | "submit" | "reset";
		iconLeft?: ReactNode;
		iconRight?: ReactNode;
	};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			disabled,
			href = undefined,
			htmlType,
			iconLeft,
			iconOnly,
			iconRight,
			isLoading,
			to = undefined,
			variant,
			...props
		},
		ref
	) => {
		return (
			<StyledButton
				as={to ? Link : href ? "a" : "button"}
				disabled={disabled}
				to={to}
				iconOnly={iconOnly}
				isLoading={isLoading}
				ref={ref}
				type={htmlType || "button"}
				variant={variant ?? "primary"}
				{...props}
			>
				{!iconOnly && isLoading && (
					<IconContainer position="left">
						<LoadingSpinner size={16} />
					</IconContainer>
				)}
				{!iconOnly && !isLoading && iconLeft && <IconContainer position="left">{iconLeft}</IconContainer>}
				{isLoading && iconOnly ? <LoadingSpinner size={16} /> : children}
				{!iconOnly && iconRight && <IconContainer position="right">{iconRight}</IconContainer>}
			</StyledButton>
		);
	}
);
