import { Field, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "../../components/AppBlocks";
import { LegacyInputField } from "../../components/Fields";
import PageHeading from "../../components/PageHeading";

import { notification } from "@/ui/antd";
import copy from "copy-text-to-clipboard";
import { connect } from "react-redux";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const getEmailSignature = (name, position, countryCode, phone, meetingLink) => {
	const meetingLinkDOM = meetingLink
		? `
  <div>
  <a href="${meetingLink}" target="_blank">
      <img src="https://docs.google.com/uc?export=download&id=10C5eZnEYclcD-WMbRlE9PI6Imj6qY3YD&revid=0B_fQEWaTf7MbQ0NCakEraGZIbTZSeDRVMlpWWElua3ZBREtrPQ"
          width="96" height="26" />
  </a>
  <br/>
</div>
  `
		: "";

	return `
  <div dir="ltr" class="gmail=_signature" data-smartmail="gmail_signature">
    <div dir="ltr">
        <b>
            <font size="4">${name}</font>
        </b>
        <div>
            <font color="#444444">${position}</font>
        </div>
        <div><a href="tel:${phone?.replace(/[^0-9+]/g, "")}">(${countryCode}) ${phone}</a></div>
        <div><a href="http://humanitix.com" target="_blank">humanitix.com</a></div>

        <div>
            <br/>
        </div>
        ${meetingLinkDOM}
        <div>
            <br/>
        </div>
        <div>
            <a href="https://hubs.ly/Q01D9Qqq0" target="_blank">
                <img src="https://drive.google.com/uc?export=download&id=1rr4u1Xd0FtUJSG3r-O4fpV6g_c634d-c"
                    width="420" />
            </a>
            <br/>
        </div>

        <div>
            <br/>
        </div>
        <div>
            <a href="https://humanitix.com" target="_blank">
                <img src="https://docs.google.com/uc?export=download&id=15l_O7HtQJsBFgpt30KjZQrLD8HI35yRR&revid=0B_fQEWaTf7MbeEp6b2owNUtURzFoRkxjV0JTYytvUFFxRnZBPQ"
                    width="420" height="98" />
            </a>
            <br/>
        </div>
    </div>
</div>
  `;
};

let EmailSignatureGenerator = ({ name, title, countryCode, phone, meetingLink }) => {
	const copyDom = () => {
		copy(getEmailSignature(name, title, countryCode, phone, meetingLink));
		notification.success({
			message: "Copied",
			description: "Please paste in your gmail signature"
		});
	};

	return (
		<>
			<PageHeading title="Email signature generator" />
			<ContentBlock>
				<form>
					<Field name="name" label="Name" component={LegacyInputField} required />
					<Field name="title" label="Title" component={LegacyInputField} required />
					<Field name="countryCode" label="Phone country code" component={LegacyInputField} required />
					<Field name="phone" label="Phone number" component={LegacyInputField} required />
					<Field name="meetingLink" label="Meeting link (optional)" component={LegacyInputField} />
				</form>

				<LegacyButton onClick={copyDom}>Copy signature html</LegacyButton>
			</ContentBlock>

			<ContentBlock>
				<div dir="ltr" className="gmail=_signature" data-smartmail="gmail_signature">
					<div dir="ltr">
						<b>
							<font size="4">{name}</font>
						</b>
						<div>
							<font color="#444444">{title}</font>
						</div>
						<div>
							<a href={`tel:${phone?.replace(/[^0-9+]/g, "")}`}>
								({countryCode}) {phone}
							</a>
						</div>
						<div>
							<a href="http://humanitix.com" target="_blank" rel="noreferrer">
								humanitix.com
							</a>
						</div>

						<div>
							<br />
						</div>
						{meetingLink ? (
							<div>
								<a href={meetingLink} target="_blank" rel="noreferrer">
									<img
										src="https://docs.google.com/uc?export=download&id=10C5eZnEYclcD-WMbRlE9PI6Imj6qY3YD&revid=0B_fQEWaTf7MbQ0NCakEraGZIbTZSeDRVMlpWWElua3ZBREtrPQ"
										width="96"
										height="26"
									/>
								</a>
								<br />
							</div>
						) : null}

						<div>
							<br />
						</div>

						<div>
							<a href="https://hubs.ly/Q01D9Qqq0" target="_blank" rel="noreferrer">
								<img
									src="https://drive.google.com/uc?export=download&id=1rr4u1Xd0FtUJSG3r-O4fpV6g_c634d-c"
									width="420"
								/>
							</a>
							<br />
						</div>

						<div>
							<br />
						</div>
						<div>
							<a href="https://humanitix.com" target="_blank" rel="noreferrer">
								<img
									src="https://docs.google.com/uc?export=download&id=15l_O7HtQJsBFgpt30KjZQrLD8HI35yRR&revid=0B_fQEWaTf7MbeEp6b2owNUtURzFoRkxjV0JTYytvUFFxRnZBPQ"
									width="420"
									height="98"
								/>
							</a>
							<br />
						</div>
					</div>
				</div>
			</ContentBlock>
		</>
	);
};

const formName = "emailSig";

const selector = formValueSelector(formName);

export default connect((state) => {
	return {
		name: selector(state, "name"),
		title: selector(state, "title"),
		countryCode: selector(state, "countryCode"),
		phone: selector(state, "phone"),
		meetingLink: selector(state, "meetingLink")
	};
})(
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(EmailSignatureGenerator)
);
