import { css } from "@emotion/css";
import { Modal, ModalFuncProps } from "antd";

const confirmFn = Modal.confirm;

// We need to use !important because antd have added a ".css-dev-only" style with a higher specificity
const okButtonStyles = css`
	&:hover {
		background: rgba(0, 0, 0, 0.5) !important;
	}
`;

const cancelButtonStyles = css`
	&:hover {
		color: rgba(0, 0, 0, 0.5) !important;
		border-color: rgba(0, 0, 0, 0.5) !important;
	}
`;

export const confirm = (props: ModalFuncProps) => {
	return confirmFn({
		...props,
		okButtonProps: {
			className: okButtonStyles
		},
		cancelButtonProps: {
			className: cancelButtonStyles
		}
	});
};
