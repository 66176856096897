type InteractionManager = {
	rotStart: () => void;
	deleteSelected: () => void;
	dupeSelected: () => void;
	setCurveScaleFactor: (val: number) => void;
	getCurveScaleFactor: () => number;
	setSkewScaleFactor: (val: number) => void;
	getSkewScaleFactor: () => number;
};
export enum InteractionManagerMethods {
	ROTATE = "rotStart",
	DELETE = "deleteSelected",
	DUPLICATE = "dupeSelected",
	STOP_ROTATE = "rotEnd"
}

const interactionManagerMock = {
	rotStart: () => {
		/* Hi Steve */
	},
	deleteSelected: () => {
		/* Hi Steve */
	},
	dupeSelected: () => {
		/* Hi Steve */
	},
	setCurveScaleFactor: (val: number) => {
		console.log(val);
	},
	getCurveScaleFactor: () => 0,
	getSkewScaleFactor: () => 0,
	setSkewScaleFactor: (val: number) => {
		console.log(val);
	}
};

export default class Store {
	private static _interactionManager: InteractionManager = interactionManagerMock;

	static getInteractionManager(): InteractionManager {
		return Store._interactionManager;
	}

	static callInteractionManagerMethod(methodName: string) {
		(Store._interactionManager as any)[methodName]();
	}

	static setInteractionManager(value: InteractionManager) {
		Store._interactionManager = value;
	}
}
