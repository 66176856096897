import { LegacyInputField } from "@/components/Fields";
import { dayjs } from "@/libs/dayjs";
import { AppDispatch } from "@/state/store";
import { trpc } from "@/trpc";
import { Button, Modal, notification } from "@/ui/antd";
import { email, required } from "@/utils/Validators";
import type { ScheduledReportDisplay, SendTestEmailInput } from "@hx/console/scheduledReports";
import { Field, InjectedFormProps, reduxForm, reset } from "redux-form";

const formName = "sendScheduledReport";

type SendTestEmailModalInputProps = {
	onClose: () => void;
	isOpen: boolean;
	initialValues: {
		_id?: Pick<ScheduledReportDisplay, "_id">;
		eventIds: Pick<ScheduledReportDisplay, "eventIds">;
		interval: Pick<ScheduledReportDisplay, "interval">;
	};
};

type SendTestEmailModalCombinedProps = SendTestEmailModalInputProps &
	InjectedFormProps<unknown, unknown, string> & {
		dispatch: AppDispatch;
	};

const SendTestEmailComponent = ({ handleSubmit, dispatch, isOpen, onClose }: SendTestEmailModalCombinedProps) => {
	const mutation = trpc.scheduledReports.sendTestEmail.useMutation({
		onSuccess: (response) => {
			onClose();
			notification.success({
				message: "Success",
				description: response
			});
		},
		onError: (error) => {
			notification.error({
				message: "Error",
				description: error.message ?? "Failed send email"
			});
		}
	});

	const _submit = async (values: any) => {
		const timezone = dayjs.tz.guess();
		const data: SendTestEmailInput = {
			_id: values._id,
			email: values.email,
			eventIds: values.eventIds,
			timezone,
			interval: values.interval
		};

		mutation.mutate(data);
	};

	const _cancel = () => {
		dispatch(reset(formName));
		onClose();
	};

	const emailRequired = required("Please input an email");
	const validEmail = email("Please enter a valid email");

	return (
		<Modal
			open={isOpen}
			title="Send email"
			onCancel={_cancel}
			destroyOnClose
			footer={
				<div>
					<Button size="large" onClick={_cancel}>
						Cancel
					</Button>
					<Button size="large" type="primary" disabled={false} loading={false} onClick={handleSubmit(_submit)}>
						Send
					</Button>
				</div>
			}
		>
			<form onSubmit={handleSubmit(_submit)}>
				<Field
					name="email"
					label="Email"
					component={LegacyInputField}
					required
					validate={[emailRequired, validEmail]}
				/>
			</form>
		</Modal>
	);
};

const SendTestEmailModal = reduxForm<unknown, any, string>({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(SendTestEmailComponent);

export { SendTestEmailModal };
