import type { SearchReducerState } from "@/state/reducers/searchReducer";
import { AppDispatch, ReduxState } from "@/state/store";
import { pushSearchStateIntoQuerystring } from "@/utils/Urls";

export enum SearchActionTypes {
	SET_SEARCH = "SET_SEARCH",
	UPDATE_SEARCH = "UPDATE_SEARCH",
	RESET_SEARCH = "RESET_SEARCH"
}

export const setSearch = (search: Partial<SearchReducerState>, routeChange = false, localStorageSearchKey = "") => {
	return (dispatch: AppDispatch, getState: () => ReduxState) => {
		dispatch({ type: SearchActionTypes.SET_SEARCH, search });
		if (routeChange) pushSearchStateIntoQuerystring(getState().search);
		try {
			const currentSearch = { ...getState().search };
			delete currentSearch.query;
			if (localStorageSearchKey) localStorage.setItem(localStorageSearchKey, JSON.stringify(currentSearch));
		} catch (err) {
			console.log(err);
		}
	};
};

export const updateSearch = (search: Partial<SearchReducerState>, routeChange = false) => {
	return (dispatch: AppDispatch, getState: () => ReduxState) => {
		dispatch({ type: SearchActionTypes.UPDATE_SEARCH, search });
		if (routeChange) pushSearchStateIntoQuerystring(getState().search);
	};
};

export const resetSearch = () => (dispatch: AppDispatch) => dispatch({ type: SearchActionTypes.RESET_SEARCH });
