import { Modal } from "@/ui/antd";
import { useState } from "react";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import RichTextDisplay from "@/components/richTextArea/RichTextDisplay";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { H5 } from "@/components/text/Text";
import CustomInfoModal from "./CustomInfoModal";
const { confirm } = Modal;

const CustomInfo = ({ currentEvent, customInfo, fields, changeFieldValue }) => {
	const [{ addModalOpen, editingIndex }, updateModals] = useState({
		editingIndex: null,
		addModalOpen: false
	});

	const saveInfo = (values, index) => {
		if (index !== null && index >= 0) {
			changeFieldValue(`${fields.name}.${index}`, values);
		} else {
			fields.push(values);
		}

		updateModals({ addModalOpen: false, editingIndex: null });
	};

	const deleteInfo = (index) => {
		confirm({
			title: `Are you sure you would like to delete?`,
			onOk: () => {
				fields.remove(index);
			}
		});
	};

	const editInfo = (editingIndex) => {
		updateModals({ addModalOpen: !addModalOpen, editingIndex });
	};

	const addInfo = () => {
		const num = customInfo ? customInfo.length : 0;
		updateModals({ addModalOpen: !addModalOpen, editingIndex: -(num + 1) });
	};

	const closeAddModal = () => {
		updateModals({ addModalOpen: !addModalOpen, editingIndex: null });
	};

	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title"
		},
		{
			title: "Message",
			dataIndex: "message",
			key: "message",
			render: (message) => <RichTextDisplay html={message} />
		},
		{
			title: "Actions",
			dataIndex: "",
			key: "actions",
			render: (_, __, index) => (
				<TableActions>
					<IconButton icon="edit" onClick={() => editInfo(index)} tooltip="Edit" showTooltip ariaLabel="Edit" />

					<IconButton icon="delete" onClick={() => deleteInfo(index)} tooltip="Delete" showTooltip ariaLabel="Delete" />
				</TableActions>
			)
		}
	];
	const btnProps = {
		onClick: () => addInfo(),
		icon: { name: "plus", left: true },
		type: "action",
		ariaLabel: "Add message"
	};

	const modalProps = {
		visible: addModalOpen,
		currentEvent,
		customInfo,
		handleCancel: closeAddModal,
		editingIndex,
		handleSave: saveInfo
	};

	if (!customInfo || !customInfo.length) {
		return (
			<>
				<H5>Custom accessibility information</H5>
				<LegacyButton {...btnProps}>Add custom info</LegacyButton>
				<CustomInfoModal {...modalProps} />
			</>
		);
	}

	return (
		<div style={{ width: "100%", overflowX: "auto" }}>
			<H5>Custom accessibility information</H5>
			<ResponsiveTable
				pagination={false}
				columns={columns}
				dataSource={customInfo}
				locale={{ emptyText: "No messages created" }}
				rowKey="key"
			/>

			<ButtonsBar>
				<LegacyButton {...btnProps}>Add custom info</LegacyButton>
			</ButtonsBar>
			<CustomInfoModal {...modalProps} />
		</div>
	);
};

export default CustomInfo;
