import { Button, Modal, Table, Tooltip } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { css } from "@emotion/css";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import SimplePagination from "../../components/SimplePagination";
import { LegacyButton as HButton } from "@/components/buttons/LegacyButton";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

const questionHeaderStyle = css({
	maxWidth: "250px",
	overflow: "hidden",
	whiteSpace: "nowrap",
	textOverflow: "ellipsis"
});

const cellStyle = css({
	maxWidth: "250px"
});

const paginationStyle = css({
	display: "flex",
	justifyContent: "flex-end",
	alignItems: "center",
	padding: "8px 0",
	position: "relative",
	top: -12
});

class Attendees extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPhotoModal: false,
			showDownloadModal: false,
			photoLink: "",
			photoModalTitle: "Image Answer"
		};
		this.getDisplayColumns = this.getDisplayColumns.bind(this);
		this.getFilterOptions = this.getFilterOptions.bind(this);
	}

	getFilterOptions() {
		const { filters = {} } = this.props.reportState;
		const { ticketTypes = [] } = filters;

		return [
			{
				key: "ticketOrPackageTypeIds",
				label: "Select ticket types",
				mode: "multiple",
				options: ticketTypes.map((tt) => ({
					value: tt._id,
					name: tt.name
				}))
			}
		];
	}

	getDisplayColumns() {
		const { columns } = this.props.reportState;
		if (!columns.length) return [];

		return columns.map((column) => {
			const displayColumn = { ...column };
			if (displayColumn.questionType) {
				displayColumn.title = () => (
					<Tooltip title={column.title}>
						<div className={questionHeaderStyle}>{column.title}</div>
					</Tooltip>
				);
				if (displayColumn.questionType === "image") {
					displayColumn.render = (value) =>
						value ? (
							<Button
								shape="circle"
								icon="picture"
								onClick={() => this.openPhotoModal(`https://process.filestackapi.com/${value}`, column.title)}
								aria-label="Open photo"
							/>
						) : (
							""
						);
				} else {
					displayColumn.render = (value) => <div className={cellStyle}>{value}</div>;
				}
			} else if (displayColumn.dataIndex === "eventName") {
				displayColumn.render = (value, data) => (
					<div className={cellStyle}>
						<Link to={`/console/my-events/${data._eventId}`}>{value}</Link>
					</div>
				);
			} else if (displayColumn.dataIndex === "orderId") {
				displayColumn.render = (value, data) => (
					<div className={cellStyle}>
						<Link to={`/console/my-events/${data._eventId}/orders/${data._orderId}`}>{value}</Link>
					</div>
				);
			} else {
				displayColumn.render = (value) => <div className={cellStyle}>{value}</div>;
			}
			return displayColumn;
		});
	}

	openPhotoModal = (photoLink, question) => {
		this.setState({
			showPhotoModal: !this.state.showPhotoModal,
			photoLink,
			photoModalTitle: question
		});
	};

	render() {
		const { results, loading, error, page, csvLoading, hasLoadedOnce } = this.props.reportState;

		const { load, loadPage, downloadCsv } = this.props;

		const displayColumns = this.getDisplayColumns();
		const filterOptions = this.getFilterOptions();

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						firstDateName="orderDates"
						firstDateLabel="Order date range"
						secondDateName="eventDates"
						secondDateLabel="Event date range"
						filterOptions={filterOptions}
					/>
					<ReportContent>
						<div>
							{results.length > 0 && (
								<div className={paginationStyle}>
									<SimplePagination
										pageAction={loadPage}
										currentPage={page}
										style={{ textAlign: "right", margin: 0 }}
									/>

									<HButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
										{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
									</HButton>
								</div>
							)}
						</div>
						<Table
							columns={displayColumns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={false}
						/>
						<SimplePagination pageAction={loadPage} currentPage={page} />{" "}
					</ReportContent>
				</ContentBlock>
				<Modal
					title={this.state.photoModalTitle}
					open={this.state.showPhotoModal}
					onOk={() => this.openPhotoModal("")}
					onCancel={() => this.openPhotoModal("")}
				>
					<img src={this.state.photoLink} style={{ width: "100%" }} alt="" />
				</Modal>
			</LoadErrorView>
		);
	}
}

export default withReportProvider(Attendees, "Attendee details", "attendee");
