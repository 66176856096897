import {
	EVENT_SALES_LOCATION_ERROR,
	EVENT_SALES_LOCATION_REQUEST,
	EVENT_SALES_LOCATION_RESET,
	EVENT_SALES_LOCATION_SUCCESS,
	TOP_EVENTS_ERROR,
	TOP_EVENTS_REQUEST,
	TOP_EVENTS_RESET,
	TOP_EVENTS_SUCCESS,
	TOP_FORECASTED_EVENTS_ERROR,
	TOP_FORECASTED_EVENTS_REQUEST,
	TOP_FORECASTED_EVENTS_RESET,
	TOP_FORECASTED_EVENTS_SUCCESS
} from "../../actions/adminReports/eventsActions";

const initialState = {
	eventSalesByLocation: {
		data: null,
		loading: false,
		error: false
	},
	topEvents: {
		data: null,
		page: 1,
		count: null,
		loading: false,
		error: false
	},
	topForecastedEvents: {
		data: null,
		page: 1,
		count: null,
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case EVENT_SALES_LOCATION_RESET: {
			return {
				...state,
				eventSalesByLocation: {
					...state.eventSalesByLocation,
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case EVENT_SALES_LOCATION_REQUEST:
			return {
				...state,
				eventSalesByLocation: {
					data: null,
					loading: true,
					error: false
				}
			};
		case EVENT_SALES_LOCATION_SUCCESS:
			return {
				...state,
				eventSalesByLocation: {
					...state.eventSalesByLocation,
					data: action.data,
					loading: false,
					error: false
				}
			};
		case EVENT_SALES_LOCATION_ERROR:
			return {
				...state,
				eventSalesByLocation: {
					...state.eventSalesByLocation,
					data: null,
					loading: false,
					error: action.error
				}
			};
		case TOP_EVENTS_RESET: {
			return {
				...state,
				topEvents: {
					...state.topEvents,
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case TOP_EVENTS_REQUEST:
			return {
				...state,
				topEvents: {
					data: null,
					page: action.page,
					loading: true,
					error: false
				}
			};
		case TOP_EVENTS_SUCCESS:
			return {
				...state,
				topEvents: {
					...state.topEvents,
					data: action.results,
					count: action.count,
					loading: false,
					error: false
				}
			};
		case TOP_EVENTS_ERROR:
			return {
				...state,
				topEvents: {
					...state.topEvents,
					data: null,
					loading: false,
					error: action.error
				}
			};

		case TOP_FORECASTED_EVENTS_RESET: {
			return {
				...state,
				topForecastedEvents: {
					...state.topForecastedEvents,
					data: null,
					loading: false,
					error: false
				}
			};
		}
		case TOP_FORECASTED_EVENTS_REQUEST:
			return {
				...state,
				topForecastedEvents: {
					data: null,
					page: action.page,
					loading: true,
					error: false
				}
			};
		case TOP_FORECASTED_EVENTS_SUCCESS:
			return {
				...state,
				topForecastedEvents: {
					...state.topForecastedEvents,
					data: action.results,
					count: action.count,
					loading: false,
					error: false
				}
			};
		case TOP_FORECASTED_EVENTS_ERROR:
			return {
				...state,
				topForecastedEvents: {
					...state.topForecastedEvents,
					data: null,
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
