import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button } from "@/ui/antd";
import { Field, FieldArray } from "redux-form";

import { ImageUploadField, InputNumberField, RadioField, TextAreaField } from "@/components/Fields";
import InputError from "@/components/fields/helpers/InputError";
import InputLabel from "@/components/fields/helpers/InputLabel";
import IconButton from "@/components/IconButton";

import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { required } from "@/utils/Validators";
import styled from "@emotion/styled";

const fieldStyle = css`
	margin-bottom: 6px;
	max-width: 246px;
	min-width: 100%;
`;

const FieldContainer = styled.div`
	display: flex;
	max-width: 247px;
	@media (max-width: 576px) {
		max-width: 90%;
	}
`;

interface IOptionsProps {
	fields: any;
	options: any;
	meta: any;
}
type Option = { value: number; label: string };
const isValid = (option: Option) => !isNaN(option.value) && Number(option.value) !== 0;
const optionsValidator = (options: Option[]) => {
	const validValues = options?.filter(isValid) ?? [];
	if (!validValues || !validValues.length) {
		return "At least one option must be entered";
	}
	return undefined;
};

const requiredOption = required("Option is required");

const Options = ({ fields, options = [], meta }: IOptionsProps) => {
	const { error } = meta ?? {};
	if (fields && fields.length === 0) {
		fields.push({});
	}

	const optionsRows = options.map((_: any, index: number) => (
		<FieldContainer key={index}>
			<Field
				name={`${fields.name}[${index}].value`}
				placeholder="Amount"
				component={InputNumberField}
				precision={2}
				required
				validate={requiredOption}
				size="small"
				className={fieldStyle}
			/>
			<IconButton icon="delete" onClick={() => fields.remove(index)} tooltip="Delete" showTooltip ariaLabel="Delete" />
		</FieldContainer>
	));
	return (
		<div style={{ marginBottom: 16 }}>
			<InputLabel label="Options" description="Type amount" />
			<InputError touched error={error} />
			{optionsRows}
			<Button
				size="small"
				icon={<PlusOutlined />}
				onClick={() => {
					fields.push({});
				}}
				aria-label="Add"
			>
				Add
			</Button>
		</div>
	);
};

interface IDonationAdvancedSettings {
	ticket: any;
	index: number;
	ticketTypes: any;
	touch?: any;
}

const DonationAdvancedSettings = ({ ticket, index, ticketTypes }: IDonationAdvancedSettings) => {
	return (
		<div key={ticket.key}>
			<Field
				label="Description"
				name={`ticketTypes[${index}].description`}
				component={TextAreaField}
				rows={2}
				placeholder={ticket.isDonation ? "Donation description" : "Ticket description"}
			/>
			<Field
				label="Donation Options"
				description="Choose donation type"
				name={`ticketTypes[${index}].priceOptions.enabled`}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				component={RadioField}
				button
				defaultValue={false}
				options={[
					{
						label: "Amount Range",
						value: false
					},
					{
						label: "List of amounts",
						value: true
					}
				]}
			/>
			{/* There is an antd override targeting these elements and removing the bottom margin. This div excludes it from the override. */}
			<div>
				{ticket?.priceOptions?.enabled ? (
					<FieldArray
						name={`ticketTypes[${index}].priceOptions.options`}
						component={Options}
						options={ticketTypes[index].priceOptions.options}
						validate={optionsValidator}
					/>
				) : (
					<div style={{ display: "flex" }}>
						<div style={{ marginRight: 16 }}>
							<Field
								label="Minimum Price"
								name={`ticketTypes[${index}].priceRange.min`}
								component={InputNumberField}
								placeholder="Min"
								precision={2}
								min={0}
								max={100000}
								pre
								allowsNullValue
							/>
						</div>
						<Field
							label="Maximum Price"
							name={`ticketTypes[${index}].priceRange.max`}
							component={InputNumberField}
							placeholder="Max"
							min={0}
							max={100000}
							precision={2}
							allowsNullValue
						/>
					</div>
				)}
			</div>
			<Field
				name={`ticketTypes[${index}].image`}
				label="Image"
				description={
					<>
						Use an image with a 2:1 ratio (e.g. 1200px by 600px).{" "}
						<a onClick={OpenHelpHandler(HelpIds.ticketImages)}>Learn more</a>
					</>
				}
				component={ImageUploadField}
				aspectRatio={2}
				imageStyleOverride={{
					width: "100%",
					maxHeight: "230px",
					margin: "auto",
					paddingTop: undefined,
					aspectRatio: "2"
				}}
			/>
		</div>
	);
};

export default DonationAdvancedSettings;
