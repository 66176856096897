import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField } from "../../components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { runFraudCheckForEvent } from "../../state/actions/adminUtilityActions";
import { scrollToErrors } from "../../utils/ScrollToErrors";
const formName = "eventFraudCheckRunner";

let EventFraudCheckRunner = ({ handleSubmit, runFraudCheckForEvent }) => {
	const runFraudCheck = (values) => {
		runFraudCheckForEvent(values.eventId);
	};

	return (
		<form onSubmit={handleSubmit(runFraudCheck)}>
			<Field name="eventId" placeholder="eventId" label="Event id for event to run:" component={LegacyInputField} />
			<LegacyButton type="primary" ariaLabel="run" htmlType="submit">
				Run fraud check
			</LegacyButton>
		</form>
	);
};

// Decorate with redux-form
EventFraudCheckRunner = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(EventFraudCheckRunner);

export default connect(
	() => {
		return {};
	},
	{
		runFraudCheckForEvent
	}
)(EventFraudCheckRunner);
