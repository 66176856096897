import { HumaniDate, TimezoneFormats } from "@hx/dates";
import moment from "moment-timezone";
const timeZones = moment.tz.names();
const timezoneList = [];

//TODO lmao JEN-10276 https://humanitix.atlassian.net/browse/JEN-10276
// remove the if check and fix the underlying problem
// https://github.com/iamkun/dayjs/issues/2059
for (let i in timeZones) {
	if (timeZones[i] !== "Europe/Kyiv")
		try {
			timezoneList.push({
				label: `GMT${new HumaniDate(undefined, timeZones[i]).formatting.timezone(TimezoneFormats.Offset)} ${
					timeZones[i]
				}`,
				value: timeZones[i]
			});
		} catch (err) {
			null;
		}
}

timezoneList.sort(compare);

function compare(a, b) {
	const labelA = a.label.toUpperCase();
	const labelB = b.label.toUpperCase();
	let comparison = 0;
	if (labelA > labelB) {
		comparison = 1;
	} else if (labelA < labelB) {
		comparison = -1;
	}
	return comparison;
}

function getOptions(localTimezone) {
	let suggested = [];
	if (localTimezone && localTimezone.split("/").length > 1) {
		const country = localTimezone.split("/")[0];
		suggested = timezoneList.filter((timezone) => {
			if (timezone.value.indexOf(country) === 0) {
				return true;
			}
			return false;
		});
	}
	return { suggested, timezones: timezoneList };
}

export default { getOptions };
