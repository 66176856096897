import H4 from "@/components/text/H4";
import { PayoutHeaderInformation } from "@/state/reducers/newPayoutReducer";
import { Alert } from "@/ui/antd";
import { HumaniDate } from "@hx/dates";
import { connect } from "react-redux";
import { calculateTrackRecord } from "@/state/actions/newPayoutActions";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import icUserFail from "@/assets/payouts/ic_user_fail.svg";
import icUserPass from "@/assets/payouts/ic_user_pass.svg";

interface IInjectedProps {
	header?: PayoutHeaderInformation;
}

const PaidOutEventDates = ({ totalPaidOutEventDates, status }: { totalPaidOutEventDates: number; status: string }) => (
	<p>
		<b>Paid out event dates: </b> {totalPaidOutEventDates}{" "}
		<img src={status === "pass" ? icUserPass : icUserFail} style={{ marginRight: 8, width: 20, height: 20 }} />
	</p>
);

const FirstPaidOutEventDate = ({
	firstPaidOutEventDate,
	status
}: {
	firstPaidOutEventDate: number;
	status: string;
}) => (
	<p>
		<b>First paid out event date</b> {new HumaniDate(firstPaidOutEventDate).formatting.dateTime()}{" "}
		<img src={status === "pass" ? icUserPass : icUserFail} style={{ marginRight: 8, width: 20, height: 20 }} />
	</p>
);

const TotalPaidOutValue = ({ totalPaidOutValue, status }: { totalPaidOutValue: number; status: string }) => (
	<p>
		<b>Total paid out value</b> ${totalPaidOutValue}{" "}
		<img src={status === "pass" ? icUserPass : icUserFail} style={{ marginRight: 8, width: 20, height: 20 }} />
	</p>
);

const TrackRecord = ({ header, calculateTrackRecord }: IInjectedProps & IInjectedFunctions) => {
	if (!header) return null;

	const trackRecord = header?.trackRecord || null;

	if (!trackRecord) {
		return (
			<Alert
				showIcon
				message={<H4>Track record missing</H4>}
				type="warning"
				description={
					<>
						<p>No Track record for user found.</p>
						<LegacyButton onClick={() => calculateTrackRecord(header.eventId)}>Recalculate track record</LegacyButton>
					</>
				}
			/>
		);
	}
	const checks = trackRecord.checks;

	const isAnyCheckFailed = checks.some((check: any) => check.status === "fail");

	return (
		<Alert
			showIcon
			message={<H4>Track record</H4>}
			type={isAnyCheckFailed ? "warning" : "success"}
			description={
				<div>
					Last calculated: {new HumaniDate(trackRecord.updatedAt).formatting.dateTime()}
					{checks.map((check: any) => {
						switch (check.type) {
							case "paidOutEventDates":
								return PaidOutEventDates(check);
							case "firstPaidOutEventDate":
								return FirstPaidOutEventDate(check);
							case "totalPaidOutValue":
								return TotalPaidOutValue(check);
							default:
								return (
									<b>
										Unknown check: {check.type} {check.status}
									</b>
								);
						}
					})}
					<LegacyButton onClick={() => calculateTrackRecord(header.eventId)}>Recalculate track record</LegacyButton>
				</div>
			}
		/>
	);
};

interface IInjectedProps {
	header?: PayoutHeaderInformation;
	eventId?: string;
}

interface IInjectedFunctions {
	calculateTrackRecord: (eventId: string) => void;
}

export default connect(
	(state: any): IInjectedProps => {
		const header = state.payouts.eventPayout.payoutInformation?.header;
		return {
			header,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ calculateTrackRecord }
)(TrackRecord);
