import { Card, CodeBlock } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type CardProps = HTMLAttributes<HTMLDivElement> & {
	padding?: string;
};`;

const sample = `import { Card } from "@/ui";

<Card>Does this really deserve it's own page?</Card>`;

export const CardPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<Card>Does this really deserve it's own page?</Card>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
