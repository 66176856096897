import { Button, ButtonVariant, CodeBlock } from "@/ui";
import { Flex } from "@/ui/antd";
import { PlusOutlined } from "@ant-design/icons";
import { FC } from "react";

const types: ButtonVariant[] = ["primary", "secondary", "tertiary", "ghost", "text", "danger", "danger-text"];

const titleCase = (str: string) => str[0].toUpperCase() + str.slice(1);

const props = `type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	children?: ReactNode;
	disabled?: boolean;
	fullWidth?: boolean;
	iconLeft?: ReactNode;
	iconOnly?: boolean;
	iconRight?: ReactNode;
	loading?: boolean;
	noRadius?: boolean;
	outlined?: boolean;
	variant?: ${types.join(" | ")};
};`;

const sample = `import { Button } from "@/ui";
import { PlusOutlined } from "@ant-design/icons";

<Button variant="primary">Primary</Button>
<Button variant="secondary">Secondary</Button>
<Button variant="tertiary">Tertiary</Button>
<Button variant="ghost">Ghost</Button>
<Button variant="text">Text</Button>
<Button variant="danger">Danger</Button>
<Button variant="danger-text">Danger Text</Button>
<Button variant="primary" disabled>Disabled</Button>
<Button variant="primary" loading>Loading</Button>
<Button variant="primary" iconLeft={<PlusOutlined />}>Icon Left</Button>
<Button variant="primary" iconRight={<PlusOutlined />}>Icon Right</Button>
<Button variant="primary" iconLeft={<PlusOutlined />} loading>Loading</Button>
<Button variant="primary" iconLeft={<PlusOutlined />} disabled>Disabled</Button>
<Button variant="primary" iconOnly><PlusOutlined /></Button>
<Button variant="primary" iconOnly loading><PlusOutlined /></Button>`;

export const ButtonsPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			{types.map((type) => (
				<div key={type}>
					<h2>{titleCase(type)}</h2>
					<Flex gap="sm" wrap>
						<Button variant={type}>{titleCase(type)}</Button>
						<Button outlined variant={type}>
							Outlined
						</Button>
						<Button isLoading variant={type}>
							Loading
						</Button>
						<Button disabled variant={type}>
							Disabled
						</Button>
						<Button iconLeft={<PlusOutlined />} variant={type}>
							Icon Left
						</Button>
						<Button iconRight={<PlusOutlined />} variant={type}>
							Icon Right
						</Button>
						<Button isLoading iconLeft={<PlusOutlined />} variant={type}>
							Icon Left Loading
						</Button>
						<Button disabled isLoading iconLeft={<PlusOutlined />} variant={type}>
							Icon Left Loading Disabled
						</Button>
						<Button iconOnly variant={type}>
							<PlusOutlined />
						</Button>
						<Button iconOnly isLoading variant={type}>
							<PlusOutlined />
						</Button>
					</Flex>
				</div>
			))}
			<br />
			<Flex align="flex-start" gap="sm" vertical>
				<p>Using a Link element for internal navigation</p>
				<Button to="/console/blah" variant="primary">
					Link button
				</Button>
				<CodeBlock codeString={`<Button to="/console/blah" variant="primary">Link button</Button>`} />
				<br />
				<p>Using an external link</p>
				<Button href="www.humanitix.com" variant="primary">
					Anchor button
				</Button>
				<CodeBlock codeString={`<Button href="www.humanitix.com" variant="primary">Anchor button</Button>`} />
			</Flex>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
