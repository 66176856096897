export const OrganiserSkeleton = () => {
	return (
		<svg viewBox="0 0 1200 2090" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: "block" }}>
			<g id="Event page ">
				<rect width="1200" height="2090" fill="var(--colorv3ConsoleHostStylingPreview-background-base)" />
				<rect
					x="40"
					y="16"
					width="216"
					height="40"
					rx="4"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect y="72" width="1200" height="291" fill="#7F7F7F" />
				<rect
					x="80"
					y="379"
					width="1040"
					height="188"
					rx="4"
					fill="var(--colorv3ConsoleHostStylingPreview-sunken-base)"
				/>
				<rect
					x="880"
					y="615"
					width="240"
					height="240"
					rx="4"
					fill="var(--colorv3ConsoleHostStylingPreview-sunken-base)"
				/>
				<rect
					x="96"
					y="395"
					width="160"
					height="156"
					rx="4"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="80"
					y="951"
					width="133"
					height="24"
					rx="4"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="991"
					width="248"
					height="32"
					rx="16"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="344.5"
					y="991.5"
					width="134"
					height="31"
					rx="15.5"
					stroke="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="287"
					y="511"
					width="133"
					height="40"
					rx="4"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="428.5"
					y="511.5"
					width="217"
					height="39"
					rx="3.5"
					stroke="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="80"
					y="615"
					width="740.769"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="287"
					y="395"
					width="192"
					height="18"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="287"
					y="419"
					width="118"
					height="12"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="287"
					y="465"
					width="284"
					height="12"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="670.385"
					width="591.923"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="542.692"
					y="642.692"
					width="278.077"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="642.692"
					width="348.462"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="435.385"
					y="642.692"
					width="99.2308"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="680"
					y="670.385"
					width="140.769"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="333.846"
					y="725.769"
					width="100.385"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="80"
					y="698.077"
					width="740.769"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="725.769"
					width="246.923"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<line
					x1="80.5"
					y1="902.5"
					x2="1119.5"
					y2="902.5"
					stroke="var(--colorv3ConsoleHostStylingPreview-background-text-low)"
				/>
				<rect y="1781" width="1200" height="309" fill="#333333" />

				<g id="event-card/default/D">
					<rect
						x="80"
						y="1049"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M80 1339.25H328V1377C328 1379.21 326.209 1381 324 1381H84C81.7909 1381 80 1379.21 80 1377V1339.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="237"
						y="1339"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1339"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1355"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner"
						d="M80 1053C80 1050.79 81.7909 1049 84 1049H324C326.209 1049 328 1050.79 328 1053V1172.26H80V1053Z"
						fill="#7F7F7F"
					/>
					<rect
						x="92"
						y="1190"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1274"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1220"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="92"
						y="1244"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_2">
					<rect
						x="80"
						y="1399"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>

					<path
						d="M80 1689.25H328V1727C328 1729.21 326.209 1731 324 1731H84C81.7909 1731 80 1729.21 80 1727V1689.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="237"
						y="1689"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1689"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1705"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_2"
						d="M80 1403C80 1400.79 81.7909 1399 84 1399H324C326.209 1399 328 1400.79 328 1403V1522.26H80V1403Z"
						fill="#7F7F7F"
					/>
					<rect
						x="92"
						y="1540"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1624"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="92"
						y="1570"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="92"
						y="1594"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_3">
					<rect
						x="344"
						y="1049"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M344 1339.25H592V1377C592 1379.21 590.209 1381 588 1381H348C345.791 1381 344 1379.21 344 1377V1339.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="501"
						y="1339"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1339"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1355"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_3"
						d="M344 1053C344 1050.79 345.791 1049 348 1049H588C590.209 1049 592 1050.79 592 1053V1172.26H344V1053Z"
						fill="#7F7F7F"
					/>
					<rect
						x="356"
						y="1190"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1274"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1220"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="356"
						y="1244"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_4">
					<rect
						x="344"
						y="1399"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M344 1689.25H592V1727C592 1729.21 590.209 1731 588 1731H348C345.791 1731 344 1729.21 344 1727V1689.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="501"
						y="1689"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1689"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1705"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_4"
						d="M344 1403C344 1400.79 345.791 1399 348 1399H588C590.209 1399 592 1400.79 592 1403V1522.26H344V1403Z"
						fill="#7F7F7F"
					/>
					<rect
						x="356"
						y="1540"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1624"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="356"
						y="1570"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="356"
						y="1594"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_5">
					<rect
						x="608"
						y="1049"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M608 1339.25H856V1377C856 1379.21 854.209 1381 852 1381H612C609.791 1381 608 1379.21 608 1377V1339.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="765"
						y="1339"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1339"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1355"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_5"
						d="M608 1053C608 1050.79 609.791 1049 612 1049H852C854.209 1049 856 1050.79 856 1053V1172.26H608V1053Z"
						fill="#7F7F7F"
					/>
					<rect
						x="620"
						y="1190"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1274"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1220"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="620"
						y="1244"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_6">
					<rect
						x="608"
						y="1399"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M608 1689.25H856V1727C856 1729.21 854.209 1731 852 1731H612C609.791 1731 608 1729.21 608 1727V1689.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="765"
						y="1689"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1689"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1705"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_6"
						d="M608 1403C608 1400.79 609.791 1399 612 1399H852C854.209 1399 856 1400.79 856 1403V1522.26H608V1403Z"
						fill="#7F7F7F"
					/>
					<rect
						x="620"
						y="1540"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1624"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="620"
						y="1570"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="620"
						y="1594"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_7">
					<rect
						x="872"
						y="1047"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M872 1337.25H1120V1375C1120 1377.21 1118.21 1379 1116 1379H876C873.791 1379 872 1377.21 872 1375V1337.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="1029"
						y="1337"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1337"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1353"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_7"
						d="M872 1051C872 1048.79 873.791 1047 876 1047H1116C1118.21 1047 1120 1048.79 1120 1051V1170.26H872V1051Z"
						fill="#7F7F7F"
					/>
					<rect
						x="884"
						y="1188"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1272"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1218"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="884"
						y="1242"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<g id="event-card/default/D_8">
					<rect
						x="872"
						y="1397"
						width="248"
						height="330.012"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<path
						d="M872 1687.25H1120V1725C1120 1727.21 1118.21 1729 1116 1729H876C873.791 1729 872 1727.21 872 1725V1687.25Z"
						fill="var(--colorv3ConsoleHostStylingPreview-raised-base)"
					/>
					<rect
						x="1029"
						y="1687"
						width="75.2299"
						height="29.8204"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1687"
						width="40"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1703"
						width="64"
						height="14"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<path
						id="banner_8"
						d="M872 1401C872 1398.79 873.791 1397 876 1397H1116C1118.21 1397 1120 1398.79 1120 1401V1520.26H872V1401Z"
						fill="#7F7F7F"
					/>
					<rect
						x="884"
						y="1538"
						width="208"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1622"
						width="220"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-medium)"
					/>
					<rect
						x="884"
						y="1568"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
					<rect
						x="884"
						y="1592"
						width="200"
						height="16"
						rx="4"
						fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
					/>
				</g>

				<rect
					x="228"
					y="807"
					width="591.923"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="807"
					width="140.769"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<rect
					x="651.154"
					y="836.538"
					width="99.2308"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="836.538"
					width="309.231"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="80"
					y="780"
					width="740.769"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-background-text-high)"
				/>
				<rect
					x="396.154"
					y="836.538"
					width="246.923"
					height="18.4615"
					rx="4.61538"
					fill="var(--colorv3ConsoleHostStylingPreview-primary-base)"
				/>
				<circle cx="785" cy="433" r="12" fill="var(--colorv3ConsoleHostStylingPreview-primary-base)" />
				<circle cx="785" cy="473" r="12" fill="var(--colorv3ConsoleHostStylingPreview-primary-base)" />
				<circle cx="785" cy="513" r="12" fill="var(--colorv3ConsoleHostStylingPreview-primary-base)" />
				<circle cx="942" cy="433" r="12" fill="var(--colorv3ConsoleHostStylingPreview-primary-base)" />
				<circle cx="942" cy="473" r="12" fill="var(--colorv3ConsoleHostStylingPreview-primary-base)" />
				<circle cx="942" cy="513" r="12" fill="var(--colorv3ConsoleHostStylingPreview-primary-base)" />
			</g>
		</svg>
	);
};
