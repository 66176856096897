import { css } from "@emotion/css";

const wrapper = css({
	display: "flex",
	alignItems: "center",
	textAlign: "center",
	gap: 12
});

const bar = css({
	height: 1,
	backgroundColor: "rgb(232, 232, 232)",
	flex: 1
});

const textStyle = css({
	fontSize: "0.8rem",
	color: "#3F3E41",
	fontWeight: 600
});

export const Divider = ({ text }: { text: string }) => {
	return (
		<div className={wrapper}>
			<div className={bar} />
			<span className={textStyle}>{text}</span>
			<div className={bar} />
		</div>
	);
};
