import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, HTMLAttributes, ReactNode } from "react";

type CardStyleProps = {
	padding?: string;
};

const StyledCard = styled.div<CardStyleProps>`
	${({ padding }) => css`
		background: white;
		border-radius: var(--rounded-md);
		padding: ${padding || "var(--spacing-xl)"};
		display: flex;
		flex-direction: column;
		overflow: hidden;
	`}
`;

type CardProps = HTMLAttributes<HTMLDivElement> &
	CardStyleProps & {
		children: ReactNode;
		className?: string;
	};

export const Card: FC<CardProps> = ({ children, padding, ...props }) => {
	return (
		<StyledCard padding={padding} {...props}>
			{children}
		</StyledCard>
	);
};
