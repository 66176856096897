import { Link } from "react-router-dom";
import { gatewayRequiresPayout } from "@/config";
import { getCountryName } from "@/utils/Countries";

const BankWarning = ({ event }) => {
	const countryName = getCountryName(event.location);

	return (
		<div>
			<b>{countryName} bank account required.</b> Please enter your bank account details in{" "}
			<Link to={`/console/my-events/${event._id}/payments/settings`}>Payments Settings</Link> and confirm if you are
			charging tax.
		</div>
	);
};

export default BankWarning;

export const getBankWarning = (event) => {
	const paymentOptions = event.paymentOptions;
	const hasLinkedBankAccount = !!paymentOptions.userBankAccountId;
	if (hasLinkedBankAccount) return null;

	const requiresPayout = gatewayRequiresPayout(paymentOptions.paymentId);
	if (!requiresPayout) return null;

	const isPaidEvent = event.ticketTypes.some((ticket) => {
		return !ticket.disabled && !ticket.deleted && (ticket.price > 0 || ticket.isDonation);
	});

	if (!isPaidEvent) return null;

	return <BankWarning event={event} />;
};
