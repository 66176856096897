import SearchRefinements from "@/components/SearchRefinements";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { useViewport } from "@/hooks/useViewport";
import { deleteAccessCodes, duplicateAccessCode, editAccessCode } from "@/state/actions/accessCodesActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Button, CopyField, Dropdown, DropdownItem, Responsive } from "@/ui";
import { ColumnProps, Flex, Popconfirm, Select, Table, Tooltip } from "@/ui/antd";
import { encodeURIComponentWithRFC3986Characters } from "@/utils/Format";
import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	TeamOutlined
} from "@ant-design/icons";
import { css } from "@emotion/css";
import { DotsThreeVertical, DownloadSimple } from "@phosphor-icons/react";
import { FC } from "react";
import { Link } from "react-router-dom";

const Actions = ({ onDeleteAll, onDownloadCsv }: { onDeleteAll: () => void; onDownloadCsv: () => void }) => {
	const accessCodes = useAppSelector((state) => state.accessCodes);
	const isLoading = accessCodes.getRequest?.loading;

	return (
		<Responsive
			mobile={
				<Dropdown
					items={[
						{ label: "Download csv", onClick: onDownloadCsv },
						{ label: "Delete all", onClick: onDeleteAll }
					].map((item, index) => {
						if (item) {
							return (
								<DropdownItem key={index} onClick={item.onClick} variant="text">
									{item.label}
								</DropdownItem>
							);
						}
					})}
					placement="bottomRight"
				>
					<Button iconOnly variant="text">
						<DotsThreeVertical size={18} weight="bold" />
					</Button>
				</Dropdown>
			}
			desktop={
				<div className={css({ display: "flex", gap: "8px" })}>
					<Button aria-label="Delete All" variant="tertiary" onClick={onDeleteAll}>
						Delete all
					</Button>

					<Tooltip title="Download csv" placement="bottom">
						<Button aria-label="Download csv" iconOnly variant="text" isLoading={isLoading} onClick={onDownloadCsv}>
							<DownloadSimple size={18} />
						</Button>
					</Tooltip>
				</div>
			}
		/>
	);
};

type AccessCode = {
	_id: string;
	code: string;
	quantity: number;
	enabled: boolean;
};

type AccessCodesTableProps = {
	confirmDeleteAll: () => void;
	createCode: () => void;
	downloadCsv: () => void;
	load: (page: number) => void;
	loadPage: (page: number) => void;
	updateRefinements: (sortOrder: string) => void;
};

export const AccessCodesTable: FC<AccessCodesTableProps> = ({
	confirmDeleteAll,
	createCode,
	downloadCsv,
	load,
	loadPage,
	updateRefinements
}) => {
	const ticketingUrl = getConfig("TICKETING_URL");
	const accessCodes = useAppSelector((state) => state.accessCodes);
	const search = useAppSelector((state) => state.search);
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const dispatch = useAppDispatch();
	const { isMobile } = useViewport();

	const isLoading = accessCodes.getRequest.loading;
	const count = (accessCodes as any).count;
	const page = (accessCodes as any).page;

	const columns: ColumnProps<AccessCode>[] = [
		{
			title: "Code",
			dataIndex: "code",
			key: "code",
			render: (value, accessCode) => {
				return (
					<a aria-label={value} onClick={() => editAccessCode(accessCode)}>
						{value}
					</a>
				);
			}
		},
		{
			title: "Link",
			dataIndex: "code",
			key: "link",
			width: 300,
			render: (value) => {
				const link = `${ticketingUrl}/${currentEvent.event?.slug}?accesscode=${encodeURIComponentWithRFC3986Characters(
					value
				)}`;
				return <CopyField displayValue={link} />;
			}
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity",
			render: (value) => {
				return value ? value : "Unlimited";
			}
		},
		{
			title: "Enabled",
			dataIndex: "enabled",
			key: "enabled",
			render: (value) => (
				<div style={{ marginLeft: "16px" }}>
					{value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f70e0e" />}
				</div>
			)
		},
		{
			title: "Action",
			dataIndex: "",
			key: "actions",
			fixed: "right",
			width: 200,
			render: (_, accessCode) => (
				<Flex gap="sm">
					<Tooltip title="Edit">
						<Button
							aria-label="Edit"
							onClick={() => dispatch(editAccessCode(accessCode))}
							iconOnly
							variant="text"
							dataCy={`access-${accessCode.code}-edit`}
						>
							<EditOutlined style={{ fontSize: 18 }} />
						</Button>
					</Tooltip>
					<Popconfirm
						title="Are you sure you want to delete this access code?"
						onConfirm={() => dispatch(deleteAccessCodes(currentEvent.event?._id, accessCode))}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip title="Delete">
							<Button aria-label="Delete" iconOnly variant="text" dataCy={`access-${accessCode.code}-delete`}>
								<DeleteOutlined style={{ fontSize: 18 }} />
							</Button>
						</Tooltip>
					</Popconfirm>
					<Link to={`/console/my-events/${currentEvent.event?._id}/attendees?query=accesscode%253A${accessCode.code}`}>
						<Tooltip title="View attendees">
							<Button aria-label="View attendees" iconOnly variant="text" dataCy={`access-${accessCode.code}-view`}>
								<TeamOutlined style={{ fontSize: 18 }} />
							</Button>
						</Tooltip>
					</Link>
					<Tooltip title="Duplicate">
						<Button
							aria-label="Duplicate"
							iconOnly
							variant="text"
							dataCy={`access-${accessCode.code}-duplicate`}
							onClick={() => {
								if (!currentEvent.event) return null;
								dispatch(duplicateAccessCode(currentEvent.event._id, accessCode));
							}}
						>
							<CopyOutlined style={{ fontSize: 18 }} />
						</Button>
					</Tooltip>
				</Flex>
			)
		}
	];

	return (
		<div>
			<Flex justify="space-between">
				<P>
					Access codes allow your attendees to access hidden tickets. Create an access code and assign it to a ticket
					type.
				</P>

				{accessCodes.codes?.length ? (
					<Actions onDeleteAll={() => confirmDeleteAll()} onDownloadCsv={() => downloadCsv()} />
				) : null}
			</Flex>

			<Flex gap="sm" style={{ marginBottom: "24px" }} vertical={isMobile}>
				<SearchRefinements load={load} wrapperMarginBottom={0} fullWidth />
				<Select
					value={search.sortOrder || "az"}
					onChange={(e) => updateRefinements(e)}
					options={[
						{ label: "A - Z", value: "az" },
						{ label: "Z - A", value: "za" },
						{ label: "Enabled first", value: "enabled" },
						{ label: "Disabled first", value: "disabled" },
						{ label: "Created first", value: "createdFirst" },
						{ label: "Created last", value: "createdLast" }
					]}
				/>
			</Flex>

			{accessCodes.codes?.length && !isLoading ? (
				<div style={{ overflowX: "auto" }}>
					<Table
						columns={columns}
						dataSource={accessCodes.codes}
						rowKey="key"
						pagination={{
							showSizeChanger: false,
							defaultPageSize: 10,
							total: count,
							current: page,
							onChange: loadPage
						}}
						loading={isLoading}
						scroll={{ x: 800 }}
						locale={{
							filterConfirm: "Ok",
							filterReset: "Reset",
							emptyText: "No access codes found"
						}}
					/>
					<ButtonsBar>
						<Button onClick={() => createCode()} iconLeft={<PlusOutlined />} variant="tertiary" dataCy="Create code">
							Add code
						</Button>
					</ButtonsBar>
				</div>
			) : (
				<NoResults title="No access codes found" message="" action={() => createCode()} actionTxt="Create code" />
			)}
		</div>
	);
};
