import { HR, Spacer } from "@/ui";
import { Dropdown as AntDropdown, DropdownProps as AntDropdownProps, Flex } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

const StyledMenu = styled.div<{
	padding?: string;
	maxWidth?: number;
}>`
	background-color: var(--surface1);
	border-radius: var(--rounded-md);
	box-shadow: var(--shadow-md);
	display: flex;
	flex-direction: column;
	min-width: 160px;
	padding: ${(props) => props.padding ?? "var(--spacing-sm)"};
	${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`}
`;

const StyledTitle = styled.div`
	font-weight: 600;
	padding: var(--spacing-sm) var(--spacing-lg);
`;

const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: var(--spacing-md) var(--spacing-lg);
`;

type DropdownProps = {
	/** The trigger element */
	children: ReactNode;
	/** Items to display in the dropdown, a react node or an array of */
	items: ReactNode | ReactNode[];
	/** Maximum width of the dropdown */
	maxWidth?: number;
	/** Removes padding from the dropdown */
	noPadding?: boolean;
	/** Fired when the dropdown is closed */
	onClose?: () => void;
	/** Placement of the dropdown */
	placement?: AntDropdownProps["placement"];
	/** Requires click to toggle */
	persist?: boolean;
	/** Title of the dropdown */
	title?: ReactNode;
};

export const Dropdown: FC<DropdownProps> = ({
	children,
	items,
	maxWidth,
	noPadding,
	onClose,
	persist = false,
	placement = "bottomRight",
	title
}) => {
	return (
		<AntDropdown
			trigger={persist ? ["click"] : undefined}
			placement={placement}
			onOpenChange={(open) => !open && onClose?.()}
			dropdownRender={() => (
				<StyledMenu
					onClick={(e) => e.stopPropagation()}
					padding={noPadding || title ? "0" : undefined}
					maxWidth={maxWidth}
				>
					{title ? (
						<Flex vertical>
							<StyledTitle>{title}</StyledTitle>
							<HR noMargin />
							<Spacer horizontal size="var(--spacing-sm)" />
							<StyledContent>{items}</StyledContent>
						</Flex>
					) : (
						items
					)}
				</StyledMenu>
			)}
		>
			{children}
		</AntDropdown>
	);
};
