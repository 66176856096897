import { css } from "@emotion/css";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Link } from "react-router-dom";

import type { MenuItem as MenuItemType } from "@/services/menuService/MenuService";

const linkStyle = (isInHeader: boolean) =>
	css({
		padding: isInHeader ? "0px 16px" : "6px 9px",
		color: "#323232",
		"&:hover": !isInHeader
			? {
					background: "#f9f9fa"
			  }
			: undefined
	});

const MenuItem = ({ item, isInHeader }: { item: MenuItemType; isInHeader?: boolean }) => (
	<Link to={item.path}>
		<NavigationMenu.Link asChild>
			<div className={linkStyle(!!isInHeader)}>{item.label}</div>
		</NavigationMenu.Link>
	</Link>
);

export default MenuItem;
