import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import Empty from "./Empty";
import File from "./elements/File";

const { confirm } = Modal;

class Files extends Component {
	addFile = () => {
		const { fields } = this.props;
		fields.push({
			name: "File",
			type: "file",
			settings: { showWhen: "always" }
		});
	};

	move = (fromIndex, toIndex) => {
		const { fields, files, changeFieldValue } = this.props;
		if (toIndex < 0 || toIndex > fields.length - 1) {
			return false;
		}
		const fromData = files[fromIndex];
		const toData = files[toIndex];
		changeFieldValue(`${fields.name}[${toIndex}]`, { ...fromData });
		changeFieldValue(`${fields.name}[${fromIndex}]`, { ...toData });
	};

	deleteFile = (_, index) => {
		const { fields } = this.props;
		confirm({
			title: `Are you sure you would like to delete`,
			onOk: () => {
				fields.remove(index);
			},
			onCancel() {
				null;
			}
		});
	};
	render() {
		const { fields, files } = this.props;
		return (
			<>
				{fields.map((fieldPath, index) => {
					const file = files[index];
					const props = {
						key: index,
						fieldPath,
						file,
						element: file,
						index,
						move: this.move,
						deleteElement: this.deleteFile,
						totalElements: fields.length
					};
					return (
						<div>
							<File {...props} />
						</div>
					);
				})}
				<Empty visible={!fields.length} />
				<ButtonsBar label="" backgroundColor>
					<LegacyButton
						icon={{ left: true, name: "plus" }}
						type="action"
						onClick={() => this.addFile()}
						ariaLabel="Add File"
					>
						Add File
					</LegacyButton>
				</ButtonsBar>
			</>
		);
	}
}

const selector = formValueSelector("virtualEventsHub");

export default connect(
	(state) => ({
		files: selector(state, "files")
	}),
	{}
)(Files);
