import { updateManageCapacityFormOnPerformAction } from "@/state/actions/eventActions";
export const ADD_UNDO_ACTION = "ADD_UNDO_ACTION";
export const UNDO = "UNDO";
export const REDO = "REDO";
export const SET_VIEW = "SET_VIEW";
//ensure that we don't rely on mutation for reducer
export function stack(array = []) {
	const arr = array;
	const push = (data) => {
		arr.splice(0, 0, data);
		return newStack([].concat(arr));
	};
	const pop = () => {
		const element = arr.shift();
		return {
			poppedElement: element,
			newArr: newStack([].concat(arr))
		};
	};
	const length = () => arr.length;
	const newStack = (array) => stack(array);
	return { push, pop, newStack, length, arr };
}

export const addUndoAction = (actionType, functionCalls, params) => {
	return (dispatch, getState) => {
		const state = getState();
		const { seatingMapUndo } = state;
		const view = seatingMapUndo.view;
		const { undoActions } = seatingMapUndo[view];
		dispatch({
			type: ADD_UNDO_ACTION,
			undo: undoActions.push({ actionType, functionCalls, params }),
			redo: stack()
		});
	};
};

export const resetUndoRedo = (view) => {
	return (dispatch) => {
		dispatch({ type: SET_VIEW, view });
	};
};

export const undo = () => {
	return (dispatch, getState) => {
		const state = getState();
		const { seatingMapUndo } = state;
		const view = seatingMapUndo.view;
		const { undoActions, redoActions } = seatingMapUndo[view];
		if (undoActions.length() > 0) {
			const { newFrom, newTo } = performAction(undoActions, redoActions, dispatch);
			dispatch({ type: UNDO, undo: newFrom, redo: newTo });
		}
	};
};

export const redo = () => {
	return (dispatch, getState) => {
		const state = getState();
		const { seatingMapUndo } = state;
		const view = seatingMapUndo.view;
		const { undoActions, redoActions } = seatingMapUndo[view];
		if (redoActions.length() > 0) {
			const { newFrom, newTo } = performAction(redoActions, undoActions, dispatch);
			dispatch({ type: REDO, undo: newTo, redo: newFrom });
		}
	};
};

const performAction = (from, to, dispatch) => {
	const { poppedElement, newArr } = from.pop();
	const action = poppedElement.functionCalls
		.map((fc, i) => fc(...poppedElement.params[i]))
		.reduce(
			(cur, action) => {
				cur.functionCalls.push(action.functionCall);
				cur.params.push(action.params);
				dispatch(updateManageCapacityFormOnPerformAction(action.params));
				return cur;
			},
			{
				type: poppedElement.type,
				functionCalls: [],
				params: []
			}
		);
	const newTo = to.push(action);

	return { newFrom: newArr, newTo };
};
