import { RadioField, SelectField } from "@/components/Fields";
import { dayjs } from "@/libs/dayjs";
import OrderService from "@/services/OrdersService";
import { notification } from "@/ui/antd";
import timezones from "@/utils/timezones";
import { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import EventDateRange from "./EventDateRange";
import RecurringDatePrompt from "./RecurringDatePrompt";

class DateRows extends Component {
	removeDate = async (index) => {
		const { fields, dates, changeFieldValue } = this.props;
		const date = dates[index];
		if (date._id) {
			const orders = await OrderService.findForDeleteFields(date._id);
			if (orders && orders.length > 0) {
				notification.error({
					message: "Error",
					description: "There is a ticket sale on this date. You can still disable the date"
				});
			} else {
				changeFieldValue(`dates[${index}].deleted`, true);
			}
		} else {
			fields.remove(index);
		}
	};

	disableDate = (index) => {
		const { changeFieldValue } = this.props;
		changeFieldValue(`dates[${index}].disabled`, true);
	};

	enableDate = (index) => {
		const { changeFieldValue } = this.props;
		changeFieldValue(`dates[${index}].disabled`, false);
	};

	eventHasPassed = () => {
		const { newEvent, currentEvent } = this.props;
		if (newEvent) {
			return false;
		}
		const dates = currentEvent.event.dates.filter(({ disabled, deleted }) => !disabled && !deleted);
		const endDate = dates.length ? dates[0].endDate : {};
		const eventHasPassed = dayjs().isAfter(endDate);

		return eventHasPassed;
	};
	render() {
		const {
			dates,
			fields,
			touch,
			changeFieldValue,
			timezone,
			eventRecurring,
			newEvent,
			currentEvent,
			auth: { user = {} }
		} = this.props;
		const isUser = user.role === "user";
		const recurringTooltip = `Add a date if your event
      repeats again 
      in the future.
      The same capacity and 
      ticket types will apply 
      for each recurring 
      date`;
		const timezoneOptions = timezones.getOptions(timezone);

		const groups = timezoneOptions.suggested.length
			? [
					{
						label: "Suggested",
						options: timezoneOptions.suggested.map(({ label, value }) => ({ label, value, key: `suggested-${value}` })),
						key: "Suggested"
					},
					{
						label: "All",
						options: timezoneOptions.timezones.map(({ label, value }) => ({ label, value, key: value })),
						key: "All"
					}
			  ]
			: timezoneOptions.timezones;

		const dateCount = !newEvent
			? currentEvent.event?.dates?.filter(({ disabled, deleted }) => !disabled && !deleted).length
			: 0;
		const dateTypeDisabled = currentEvent?.event?.ticketSold && eventRecurring && dateCount > 1;

		return (
			<div>
				<div style={{ display: "flex", flexWrap: "wrap", marginTop: 16 }}>
					<div style={{ marginRight: 24 }}>
						<Field
							label="Timezone"
							name="timezone"
							component={SelectField}
							style={{ maxWidth: 310 }}
							dropdownMatchSelectWidth={false}
							options={groups}
							showSearch
						/>
					</div>
				</div>
				<div>
					<Field
						name="isRecurring"
						component={RadioField}
						disabled={dateTypeDisabled}
						button
						options={[
							{
								label: "Single event",
								value: false,
								tooltip: dateTypeDisabled
									? "When more than one occurrence have tickets sold you can’t switch to “Single” event type."
									: ""
							},
							{
								label: "Recurring event",
								value: true,
								tooltip: "Only use recurring events if the event repeats again in the future."
							}
						]}
						tooltip={recurringTooltip}
					/>
				</div>
				{!eventRecurring ? (
					<EventDateRange
						fields={fields}
						timezone={timezone}
						enableDate={this.enableDate}
						disableDate={this.disableDate}
						removeDate={this.removeDate}
						dates={dates}
						touch={touch}
						changeFieldValue={changeFieldValue}
						ticketSold={currentEvent?.event?.ticketSold}
						isUser={isUser}
						eventHasPassed={this.eventHasPassed()}
					/>
				) : (
					<RecurringDatePrompt newEvent={newEvent} dateCount={dateCount} eventId={currentEvent.event?._id} />
				)}
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		auth: state.auth
	}),
	{}
)(DateRows);
