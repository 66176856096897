import styled from "@emotion/styled";
import cleanHtml from "clean-html";
import { Component } from "react";
import { connect } from "react-redux";
import { fsClient } from "../services/ImageService";
import { loadRedactor } from "../state/actions/assetsActions";
import { Loading } from "./AppBlocks";

const RedactorWrapper = styled.div`
	.redactor-toolbar-wrapper {
		position: sticky;
		top: ${(props) => props.topPosition}px;
		background: white;
		z-index: 1;
	}
`;

const TextArea = styled.textarea`
	min-height: ${(props) => props.minHeight}px;
`;

const Display = ({ content, loading, id, minHeight = 300 }) => {
	return loading ? (
		<div style={{ minHeight }}>
			<Loading />
		</div>
	) : (
		<TextArea id={id} defaultValue={content} minHeight={minHeight} />
	);
};

class RichTextArea extends Component {
	constructor() {
		super();
		// set an unique id
		let idString = Math.random().toString();
		idString = idString.replace(".", "");
		this.id = `content-${idString}`;
	}
	componentDidMount() {
		const { loadRedactor, assets } = this.props;
		// make available for the redactor custom plugins
		window.fsClient = fsClient;
		window.cleanHtml = cleanHtml;
		if (!assets.redactor) {
			loadRedactor();
			return;
		}
		this.initTimeout = setTimeout(() => {
			this.init();
		}, 100);
	}

	componentWillUnmount() {
		try {
			clearTimeout(this.initTimeout);
			const $R = window.$R;
			$R(`#${this.id}`, "destroy");
			// remove dropdowns which are not cleaned up
			const dropDowns = document.getElementsByClassName("redactor-dropdown");
			Array.from(dropDowns).forEach((e) => e.remove());
			// remove any left over modals
			const modalDom = document.getElementById("redactor-modal");
			if (modalDom) {
				modalDom.remove();
			}
		} catch (err) {
			return null;
		}
	}

	init() {
		const { onChange, basicEditor, customAdvancedPlugins, minHeight = 300, personalisations } = this.props;
		const $R = window.$R;
		const advancedPlugins = customAdvancedPlugins ? customAdvancedPlugins : ["video", "addimage", "table"];
		const advancedButtons = ["html", "addimage", "image"];
		let plugins = ["fontcolor", "clearformating", "fullscreen"];
		let buttons = ["format", "bold", "italic", "deleted", "lists", "link", "undo", "redo"];
		if (!basicEditor) {
			plugins = plugins.concat(advancedPlugins);
			buttons = buttons.concat(advancedButtons);
		}
		let personalisationsStr = "{}";
		if (personalisations) {
			plugins.push("handle");
			personalisationsStr = JSON.stringify(personalisations);
		}
		const redactorInit = {
			minHeight: `${minHeight}px`,
			imageResizable: true,
			imagePosition: true,
			toolbarFixed: false,
			shortcodes: false,
			linkTarget: "_blank",
			autoparseVideo: plugins.includes("video"),
			plugins,
			buttons,
			personalisations: personalisationsStr,
			formatting: ["p", "blockquote", "pre", "h2", "h3", "h4", "h5", "h6"],
			callbacks: {
				changed: () => {
					const sanitisedHtml = $R(`#${this.id}`, "source.getCode").replace(/<p data-children-count="\d">/g, "<p>");
					onChange && onChange(sanitisedHtml);
				},
				keyup: () => {
					const sanitisedHtml = $R(`#${this.id}`, "source.getCode").replace(/<p data-children-count="\d">/g, "<p>");
					onChange && onChange(sanitisedHtml);
				}
			}
		};
		$R(`#${this.id}`, redactorInit);
	}

	componentDidUpdate() {
		const { assets } = this.props;
		const loading = !assets.redactor;
		if (!loading) {
			this.init();
		}
	}

	render() {
		const { value, assets, initialValue, minHeight, personalisations, topPosition = 0 } = this.props;
		const loading = !assets.redactor;
		const content = value === undefined ? initialValue : value;
		return (
			<RedactorWrapper topPosition={topPosition}>
				<link rel="stylesheet" href="/frontend/redactor-3_1_5/redactor/redactor.min.css" />
				{personalisations ? (
					<link rel="stylesheet" href="/frontend/redactor-3_1_5/redactor/_plugins/handle/handle.min.css" />
				) : null}

				<link rel="stylesheet" href="/frontend/redactor-custom-plugins/custom.css" />
				<Display content={content} loading={loading} id={this.id} minHeight={minHeight} />
			</RedactorWrapper>
		);
	}
}

export default connect(
	(state) => ({
		assets: state.assets
	}),
	{ loadRedactor }
)(RichTextArea);
