import { connect } from "react-redux";
import { FieldArray, InjectedFormProps, change, formValueSelector, reduxForm } from "redux-form";

import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import NoResults from "@/components/noResults/NoResults";
import { P } from "@/components/text/Text";
import PackagedTicketsTable from "./PackagedTicketsTable";

import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";

import withSaveCheck from "@/hoc/withSaveCheck";

import { saveEvent } from "@/state/actions/eventActions";
import type { AppDispatch, ReduxState as GlobalReduxState } from "@/state/store";
import { useParams } from "react-router";
import { Tabs } from "@/ui/antd";

const formName = "packagedTickets";
const selector = formValueSelector(formName);

interface ReduxActions {
	saveEvent: (eventId: string, data: any) => void;
}

type ReduxInput = Partial<GlobalReduxState> & ReduxActions;
type PackagedTicketsInput = ReduxInput &
	InjectedFormProps & {
		dispatch: AppDispatch;
	};

const PackagedTickets = ({
	saveEvent,
	currentEvent,
	handleSubmit,
	touch,
	primaryPackages = [],
	secondaryPackages = [],
	dispatch
}: PackagedTicketsInput) => {
	const params = useParams();
	const _save = (values: any) => {
		const eventId = params?.eventId ?? "";
		const primaryPackagedTickets = values?.primaryPackages?.filter((packagedTicket: any) => packagedTicket.name);
		const secondaryPackagedTickets = values?.secondaryPackages?.filter((packagedTicket: any) => packagedTicket.name);
		const packagedTickets = [...primaryPackagedTickets, ...secondaryPackagedTickets];
		saveEvent(eventId, { packagedTickets });
	};

	const hasSecondary = !!currentEvent?.event?.settings?.isSecondaryTicketsEnabled;

	const primaryPackagedTicketsValid =
		primaryPackages.filter((ticket: any) => !ticket.deleted && !ticket.isSecondary) || [];

	const secondarypackagedTicketValid =
		secondaryPackages.filter((ticket: any) => !ticket.deleted && ticket.isSecondary) || [];

	const primaryPackagesComponent = !primaryPackagedTicketsValid?.length ? (
		<NoResults
			title="You have no packages"
			message=""
			action={() => {
				dispatch(
					change("packagedTickets", "primaryPackages", [
						{
							rowKey: Math.random().toString(36).substring(7),
							tickets: [{}],
							price: isFreeOnly ? 0 : null
						}
					])
				);
			}}
			actionTxt="Create package"
		/>
	) : (
		<FieldArray
			name="primaryPackages"
			component={PackagedTicketsTable}
			packagedTickets={primaryPackages}
			currentEvent={currentEvent}
			touch={touch}
			dispatch={dispatch}
			formName={formName}
			fieldName="primaryPackages"
		/>
	);

	const secondaryPackagesComponent = !secondarypackagedTicketValid?.length ? (
		<NoResults
			title="You have no secondary packages"
			message=""
			action={() => {
				dispatch(
					change("packagedTickets", "secondaryPackages", [
						{
							rowKey: Math.random().toString(36).substring(7),
							tickets: [{}],
							price: isFreeOnly ? 0 : null,
							isSecondary: true
						}
					])
				);
			}}
			actionTxt="Create package"
		/>
	) : (
		<FieldArray
			name="secondaryPackages"
			component={PackagedTicketsTable}
			packagedTickets={secondaryPackages}
			currentEvent={currentEvent}
			touch={touch}
			dispatch={dispatch}
			formName={formName}
			fieldName="secondaryPackages"
			primaryPackages={primaryPackages}
		/>
	);

	const packagesDisplayComponent = hasSecondary ? (
		<Tabs
			items={[
				{ label: "Primary Packages", key: "primary-packages", children: primaryPackagesComponent },
				{
					label: "Secondary Packages",
					key: "secondary-packages",
					children: secondaryPackagesComponent
				}
			]}
		/>
	) : (
		primaryPackagesComponent
	);

	const isFreeOnly = currentEvent?.event.isFreeOnly;
	return (
		<form onSubmit={handleSubmit(_save)}>
			<PageHeading
				title="Packaged tickets"
				helpButton={{
					link: HelpIds.packagedTickets,
					title: "Help - Packaged tickets"
				}}
			/>
			<LoadErrorView loading={!!currentEvent?.save.loading} error={currentEvent?.save.error}>
				<ContentBlock>
					<P>
						Manage capacity and provide value packs for your customers with packages i.e. table of 10, family, Team of 4
						etc.
					</P>
					{packagesDisplayComponent}
				</ContentBlock>
				<FormBar>
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						id="submitButton"
						disabled={currentEvent?.save.loading}
						ariaLabel="Save"
						size="large"
					>
						Save
					</LegacyButton>
				</FormBar>
			</LoadErrorView>
		</form>
	);
};

const ReduxConnectedComponent = reduxForm<PackagedTicketsInput, any>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => scrollToErrors()
})(PackagedTickets);

export default connect(
	(state: Partial<GlobalReduxState>) => ({
		initialValues: {
			primaryPackages:
				state.currentEvent?.event?.packagedTickets?.filter((ticket: any) => !ticket.deleted && !ticket.isSecondary) ||
				[],
			secondaryPackages:
				state.currentEvent?.event?.packagedTickets?.filter((ticket: any) => !ticket.deleted && ticket.isSecondary) || []
		},
		currentEvent: state.currentEvent,
		primaryPackages: selector(state, "primaryPackages"),
		secondaryPackages: selector(state, "secondaryPackages")
	}),
	{ saveEvent }
)(withSaveCheck(ReduxConnectedComponent, formName));
