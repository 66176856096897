import { css } from "@emotion/css";
import { notification } from "@/ui/antd";
import ObjectID from "bson-objectid";
import { useState } from "react";
import { Field } from "redux-form";

import { LegacyInputField, InputNumberField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import InfoIcon from "@/components/InfoIcon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import InfoTitle from "@/components/table/InfoTitle";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { includes } from "@/utils/Array";
import { subtract } from "@/utils/Math";
import { getPriceOptionsDisplay, getPriceRangeDisplay } from "@/utils/Ticket";
import { required } from "@/utils/Validators";
import AdvancedSettings from "./AdvancedSettings";

import type { TicketType } from "@/state/reducers/eventReducer";

const generateFlexiTicket = (isSecondary?: boolean) => ({
	_id: new ObjectID().toHexString(),
	hiddenOptions: { hidden: false, when: "always" },
	isNew: true,
	priceRange: {
		enabled: true
	},
	priceOptions: {
		enabled: false
	},
	minTickets: 0,
	maxTickets: 10,
	isSecondary
});

const generateEmptyFreeTicket = (isSecondary?: boolean) => ({
	_id: new ObjectID().toHexString(),
	price: 0,
	hiddenOptions: { hidden: false, when: "always" },
	isNew: true,
	minTickets: 0,
	maxTickets: 10,
	isSecondary
});

const generateEmptyPaidTicket = (isSecondary?: boolean) => ({
	_id: new ObjectID().toHexString(),
	price: null,
	hiddenOptions: { hidden: false, when: "always" },
	isNew: true,
	minTickets: 0,
	maxTickets: 10,
	isSecondary
});

const styles = {
	asterisk: css({
		color: "#c0212c"
	})
};

const hideRow = css`
	display: none;
`;

const nameRequired = required("Ticket Type is required");
const quantityRequired = required("Quantity is required");
const priceRequired = required("Price is required");

interface ITicketTableProps {
	packagedTickets: any;
	additionalQuestions: any;
	ticketGroups: any;
	fields: any;
	ticketTypes: TicketType[];
	primaryTicketTypes: TicketType[];
	changeFieldValue: (field: any, value: number | boolean) => void;
	touch: any;
	timezone: string;
	isFreeOnly?: boolean;
	formName: string;
	dispatch: () => void;
	location: any;
	isSecondary?: boolean;
}

const TicketsTable = ({
	packagedTickets,
	additionalQuestions,
	ticketGroups,
	fields,
	ticketTypes,
	changeFieldValue,
	touch,
	timezone,
	isFreeOnly,
	formName,
	dispatch,
	location,
	isSecondary,
	primaryTicketTypes = []
}: ITicketTableProps) => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

	const fieldName = isSecondary ? "secondaryTicketTypes" : "ticketTypes";

	const _checkPackageTicket = (ticketTypeId: string) => {
		const foundInPackages: string[] = [];
		if (packagedTickets && packagedTickets.length) {
			packagedTickets
				.filter((packagedTicket: any) => !packagedTicket.deleted)
				.forEach((packagedTicket: any) => {
					const ticketIds = packagedTicket.tickets.map((ticket: any) => ticket.ticketTypeId);
					if (ticketIds.find((id: any) => String(id) === String(ticketTypeId))) {
						foundInPackages.push(packagedTicket.name);
					}
				}, []);
			if (foundInPackages.length) {
				notification.error({
					message: "Error",
					description: `This ticket is included in a package so it can not be deleted or disabled, please remove it from the package${
						foundInPackages.length > 1 ? "s" : ""
					} "${foundInPackages.join('", "')}"`
				});
				return false;
			}
		}
		return true;
	};

	const _checkAddtionalQuestions = (ticketTypeId: string) => {
		const foundInQuestions: string[] = [];
		if (!additionalQuestions) return true;

		additionalQuestions.forEach((additionalQuestion: any) => {
			if (additionalQuestion.appliesTo?.find((id: any) => String(id) === String(ticketTypeId))) {
				foundInQuestions.push(additionalQuestion.question);
			}
		}, []);

		if (foundInQuestions.length) {
			notification.error({
				message: "Error",
				description: `This ticket has been applied to a checkout question and therefore cannot be deleted or disabled, please remove it from the question${
					foundInQuestions.length > 1 ? "s" : ""
				} "${foundInQuestions.join('", "')}"`
			});
			return false;
		}

		return true;
	};

	const _checkGroupTicket = (ticketTypeId: string) => {
		if (!ticketGroups) return true;

		const foundInGroup: string[] = [];
		ticketGroups.forEach((group: any) => {
			if (group.tickets.some((ticket: TicketType) => ticket === ticketTypeId)) {
				foundInGroup.push(group.name);
			}
		});

		if (foundInGroup.length) {
			notification.error({
				message: "Error",
				description: `This ticket is included in a group. You can only disable it, it is included in the group${
					foundInGroup.length > 1 ? "s" : ""
				} "${foundInGroup.join('", "')}"`
			});
			return false;
		}

		return true;
	};

	const _checkTicketSold = (ticketType: TicketType) => {
		if (ticketType.hasBeenSold) {
			notification.error({
				message: "Error",
				description: "This ticket has been sold. You can only disable it."
			});
			return true;
		}
		return false;
	};

	const _checkTicketMappedToSeatingMap = (ticketType: TicketType) => {
		if (ticketType.isMappedToSeatingMap) {
			notification.error({
				message: "Error",
				description: "This ticket has been mapped to seating map. You can only disable it."
			});
			return true;
		}
		return false;
	};

	const _removeTicket = (index: number, item: TicketType) => {
		const ticketType = ticketTypes[index];
		if (ticketType.isNew) {
			fields.remove(index);
		} else if (ticketType._id && !ticketType.isDonation) {
			if (
				_checkPackageTicket(ticketType._id) &&
				_checkAddtionalQuestions(ticketType._id) &&
				!_checkTicketSold(ticketType) &&
				!_checkTicketMappedToSeatingMap(ticketType) &&
				_checkGroupTicket(ticketType._id)
			) {
				changeFieldValue(`${fieldName}[${index}].deleted`, true);
				const forceClose = true;
				_onExpandClick(item.key, forceClose);
			}
		} else if (ticketType._id && ticketType.isDonation) {
			changeFieldValue(`${fieldName}[${index}].deleted`, true);
		}
	};

	const _disableTicket = (index: number) => {
		const ticketType = ticketTypes[index];
		if (ticketType._id && !ticketType.isDonation) {
			if (_checkPackageTicket(ticketType._id)) changeFieldValue(`${fieldName}[${index}].disabled`, true);
		} else changeFieldValue(`${fieldName}[${index}].disabled`, true);
	};

	const _enableTicket = (index: number) => {
		changeFieldValue(`${fieldName}[${index}].disabled`, false);
	};

	const _handleChangeRow = (ticket: TicketType, index: number) => {
		const newTicket = { ...ticket, key: index, index };
		changeFieldValue(`${fieldName}[${index}]`, newTicket);
	};

	const _handleDuplicateTicket = (originalTicket: TicketType) => {
		const duplicateTicketObj = {
			...originalTicket,
			_id: new ObjectID().toHexString(),
			isNew: true
		};
		delete duplicateTicketObj.key;
		delete duplicateTicketObj.isMappedToSeatingMap;
		delete duplicateTicketObj.mappedSeats;
		delete duplicateTicketObj.mappedLockedSeats;
		fields.push(duplicateTicketObj);
	};

	const _onExpandClick = (key: string, forceClose: boolean) => {
		const tempRowKeys = [...expandedRowKeys];
		if (includes(expandedRowKeys, key)) {
			const index = tempRowKeys.indexOf(key);
			tempRowKeys.splice(index, 1);
		} else if (!forceClose) {
			tempRowKeys.push(key);
		}

		setExpandedRowKeys(tempRowKeys);
		setTimeout(() => {
			try {
				document.getElementById(`${fieldName}[${key}].minTickets`)?.focus();
			} catch (err) {
				return;
			}
		}, 100);
	};

	const totalValidTicketTypes = ticketTypes
		? ticketTypes.filter((t: TicketType) => !t.disabled && !t.deleted).length
		: 0;

	if (ticketTypes) {
		ticketTypes.forEach((ticket: TicketType, index: number) => {
			ticket.key = index;
			ticket.index = index;
		});
	}

	const columns = [
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Ticket Type
				</span>
			),
			dataIndex: "name",
			key: "name",
			render: (_: string, ticket: TicketType, index: number) => {
				if (ticket.deleted) {
					return "";
				}
				return (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<Field
							name={`${fieldName}[${ticket.index}].name`}
							dataCy={`${fieldName}[${ticket.index}].name`}
							type="text"
							component={LegacyInputField}
							disabled={ticket.disabled}
							validate={!ticket.isDonation && nameRequired}
							placeholder={ticket.disabled ? "" : "e.g. General Admission/Adult"}
							tooltip={
								index === 0
									? {
											tip: "Name your tickets, such as “General Admission - Adult”, “Early Bird”, “Complimentary”, etc."
									  }
									: null
							}
							onDrop={(e: any) => {
								e.preventDefault();
							}}
						/>
					</div>
				);
			}
		},
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Price
				</span>
			),
			dataIndex: "price",
			key: "price",
			width: 140,

			render: (_: string, ticket: TicketType) => {
				if (ticket?.priceOptions?.enabled) {
					return getPriceOptionsDisplay(ticket.priceOptions);
				}
				if (ticket?.priceRange?.enabled) {
					return getPriceRangeDisplay(ticket.priceRange);
				}
				if (ticket.deleted) {
					return "";
				}
				return (
					<div
						className={css({
							display: "flex",
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "35vw"
							}
						})}
					>
						<Field
							name={`${fieldName}[${ticket.index}].price`}
							dataCy={`${fieldName}[${ticket.index}].price`}
							component={InputNumberField}
							placeholder="Price"
							validate={!ticket.isDonation && priceRequired}
							disabled={ticket.disabled || isFreeOnly}
							min={0}
							max={isFreeOnly ? 0 : 100000000}
							precision={2}
							tooltip={ticket.index === 0 ? { tip: "How much is this ticket?" } : null}
							allowsNullValue={true}
						/>
						{isFreeOnly ? (
							<div style={{ marginLeft: 6 }}>
								<InfoIcon tooltip="To offer paid tickets, please connect your Stripe account" />
							</div>
						) : null}
					</div>
				);
			}
		},
		{
			title: (
				<span>
					<span className={styles.asterisk}>* </span> Ticket Capacity
				</span>
			),
			dataIndex: "quantity",
			key: "quantity",
			width: 140,
			render: (_: string, ticket: TicketType) => {
				if (ticket.isDonation || ticket.deleted) {
					return "";
				}

				const tooltipMessage = ticket.isMappedToSeatingMap ? (
					<>
						Capacity is determined by the seating map
						<ul style={{ textAlign: "left", paddingTop: 4 }}>
							<li>{subtract(ticket.mappedSeats, ticket.mappedLockedSeats)} seats open to public</li>
							<li>{ticket.mappedLockedSeats} seats held</li>
						</ul>
					</>
				) : null;

				return (
					<div
						className={css({
							display: "flex",
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 34,
								minWidth: "35vw"
							}
						})}
					>
						{ticket.isMappedToSeatingMap ? (
							<div style={{ paddingLeft: 10, paddingBottom: 5 }}>
								<InfoTitle title={ticket.mappedSeats} tooltip={tooltipMessage} />
							</div>
						) : (
							<Field
								name={`${fieldName}[${ticket.index}].quantity`}
								dataCy={`${fieldName}[${ticket.index}].quantity`}
								component={InputNumberField}
								validate={!ticket.isDonation && quantityRequired}
								placeholder="Quantity"
								disabled={ticket.disabled}
								min={0}
								tooltip={
									ticket.index === 0
										? {
												tip: "How many tickets in total will be available for this ticket type?"
										  }
										: null
								}
								allowsNullValue={true}
							/>
						)}
					</div>
				);
			}
		},

		{
			title: "Action",
			dataIndex: "",
			key: "x",
			width: 120,
			render: (_: string, ticket: TicketType) => {
				if (ticket.deleted) {
					return "";
				}
				return totalValidTicketTypes > 1 || ticket.disabled || isSecondary ? (
					ticket.disabled ? (
						<div
							className={css({
								float: "right",
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<TableActions>
								<IconButton
									icon="undo"
									onClick={() => _enableTicket(ticket.index)}
									tooltip="Enable Ticket"
									showTooltip
									ariaLabel="Undo delete"
									dataCy={`undo-delete-${ticket.index}`}
								/>
							</TableActions>
						</div>
					) : (
						<div
							className={css({
								float: "right",
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<TableActions>
								<IconButton
									icon="settings"
									onClick={() => _onExpandClick(ticket.key, false)}
									tooltip="Settings"
									showTooltip
									expanded={includes(expandedRowKeys, ticket.key)}
									ariaLabel="Settings"
								/>
								<IconButton
									icon="disable"
									onClick={() => _disableTicket(ticket.index)}
									tooltip="Disable"
									showTooltip
									ariaLabel="Disable"
									dataCy={`disable-${ticket.index}`}
								/>
								{ticket?.canDelete?.preventDelete ? (
									<IconButton
										disabled
										icon="delete"
										tooltip={ticket.canDelete.message}
										showTooltip
										ariaLabel="Delete"
										dataCy={`delete-${ticket.index}`}
									/>
								) : (
									<IconButton
										icon="delete"
										onClick={() => _removeTicket(ticket.index, ticket)}
										tooltip="Delete"
										showTooltip
										ariaLabel="Delete"
										dataCy={`delete-${ticket.index}`}
									/>
								)}

								{!ticket.isDonation ? (
									<IconButton
										icon="duplicate"
										onClick={() => _handleDuplicateTicket(ticket)}
										tooltip="Duplicate"
										showTooltip
										ariaLabel="Duplicate"
										dataCy={`duplicate-${ticket.index}`}
									/>
								) : null}
							</TableActions>
						</div>
					)
				) : (
					<div
						className={css({
							float: "right",
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<TableActions>
							<IconButton
								icon="settings"
								onClick={() => _onExpandClick(ticket.key, false)}
								tooltip="Settings"
								showTooltip
								expanded={includes(expandedRowKeys, ticket.key)}
								ariaLabel="Settings"
							/>
							<IconButton
								icon="duplicate"
								onClick={() => _handleDuplicateTicket(ticket)}
								tooltip="Duplicate"
								showTooltip
								ariaLabel="Duplicate"
							/>
						</TableActions>
					</div>
				);
			}
		}
	];

	// pre render row
	if (ticketTypes) {
		ticketTypes.forEach((ticket: TicketType, index: number) => {
			ticket.key = index;
		});
	}

	return (
		<div style={{ overflowX: "auto" }}>
			<ResponsiveTable
				pagination={false}
				columns={columns}
				dataSource={ticketTypes}
				rowKey="key"
				changeRow={_handleChangeRow}
				locale={{ emptyText: "Please add a ticket type below" }}
				expandedRowRender={(ticket: TicketType) => {
					return ticket.disabled ? null : (
						<AdvancedSettings
							index={ticket.index}
							ticket={ticket}
							touch={touch}
							timezone={timezone}
							ticketTypes={ticketTypes}
							packagedTickets={packagedTickets}
							formName={formName}
							location={location}
							fieldName={fieldName}
							primaryTicketTypes={primaryTicketTypes}
						/>
					);
				}}
				expandedRowKeys={expandedRowKeys}
				expandIconAsCell={false}
				draggable={{ formName, fieldName, dispatch }}
				rowClassName={(record: TicketType) => (record.deleted || record.isDonation ? hideRow : "")}
			/>

			<ButtonsBar>
				<LegacyButton
					onClick={() => fields.push(generateEmptyPaidTicket(isSecondary))}
					icon={{ name: "plus", left: true }}
					type="action"
					ariaLabel="Paid"
					dataCy="add-paid-button"
					disabled={isFreeOnly}
				>
					Paid
				</LegacyButton>
				<LegacyButton
					onClick={() => fields.push(generateEmptyFreeTicket(isSecondary))}
					icon={{ name: "plus", left: true }}
					type="action"
					ariaLabel="Free"
					dataCy="add-free-button"
				>
					Free
				</LegacyButton>
				<LegacyButton
					onClick={() => fields.push(generateFlexiTicket(isSecondary))}
					icon={{ name: "plus", left: true }}
					type="action"
					ariaLabel="Price range"
					disabled={isFreeOnly}
				>
					Flexible pricing
				</LegacyButton>
			</ButtonsBar>
		</div>
	);
};

export default TicketsTable;
