import TrackingEvent from "./trackingEvent";

type UserSignupEventInternalProps = {
	emailHash: string;
	estimatedFees?: number;
};

type UserSignupEventDataLayerMessage = {
	event: string;
	hxEmailHash: string;
	hxEstimatedFees?: number;
};

export class UserSignupEvent extends TrackingEvent<UserSignupEventInternalProps> {
	constructor(userEmailSHA256Hash: string, estimatedFees?: number) {
		super("User Signup", "hx-analytics-signup");

		this.props = {
			emailHash: userEmailSHA256Hash,
			estimatedFees
		};
	}

	public toDataLayerMessage(): UserSignupEventDataLayerMessage | Record<string, never> {
		// This event is useless in GTM without a user email hash
		if (!this.props.emailHash) {
			return {};
		}

		return {
			event: this.dataLayerEventName,
			hxEmailHash: this.props.emailHash,
			...(this.props.estimatedFees && { hxEstimatedFees: this.props.estimatedFees })
		};
	}
}
