import { css } from "@emotion/css";

export const wrapperNoMenu = css({
	maxWidth: "80vw",
	margin: "0 auto",
	marginTop: 30
});

export const wrapperMenu = css({
	maxWidth: "80vw",
	margin: "0 auto",
	"@media (max-width: 700px)": {
		maxWidth: "100%"
	}
});
