import { REPORT_DATE_CHANGE } from "../../actions/adminReports/datesActions";

const initialState = {
	startDate: null,
	endDate: null
};
export default (state = initialState, action) => {
	switch (action.type) {
		case REPORT_DATE_CHANGE: {
			return {
				...state,
				startDate: action.startDate,
				endDate: action.endDate
			};
		}
		default:
			return state;
	}
};
