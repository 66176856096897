import { Component } from "react";
import { connect } from "react-redux";
import { BaseItem } from "./ManagementItems";

class ElementManager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showBasePanel: true,
			showSeatSelection: false
		};
	}

	render() {
		const { seatingMap } = this.props;
		return <>{!seatingMap.loading ? <BaseItem /> : null}</>;
	}
}

export default connect(
	(state) => ({
		seatingMap: state.seatingMap,
		currentEvent: state.currentEvent
	}),
	{}
)(ElementManager);
