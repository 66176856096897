import App from "@/App";
import "@/assets/styles/index.css";
import { unregister } from "@/registerServiceWorker";
import store from "@/state/store";
import { themeConfig } from "@/theme";
import { trpc, trpcClient } from "@/trpc";
import { GlobalStyles } from "@/ui";
import { ConfigProvider as AntdConfigProvider, AntdOverrides } from "@/ui/antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import enGB from "antd/lib/locale/en_GB";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import "./assets/styles/index.css";
import { BrowserRouter } from "@/BrowserRouter";
import history from "@/history";

const queryClient = new QueryClient();
const { Provider: TrpcProvider } = trpc;

// TODO: This will come in once Pierre and Nick have aligned on the design tokens
// import { ColourV3Functions } from "@hx/colours";
// Inject the HX colour system into the app
// const tokens = ColourV3Functions.generateCssString("#ffb18f");
// const styleSheet = document.createElement("style");
// styleSheet.innerText = tokens;
// document.head.appendChild(styleSheet);

const root = createRoot(document.getElementById("root")!);
root.render(
	<QueryClientProvider client={queryClient}>
		<ReduxProvider store={store}>
			<BrowserRouter history={history}>
				<TrpcProvider client={trpcClient} queryClient={queryClient}>
					<AntdConfigProvider locale={enGB} theme={themeConfig}>
						<App />
						<GlobalStyles />
						<AntdOverrides />
					</AntdConfigProvider>
				</TrpcProvider>
			</BrowserRouter>
		</ReduxProvider>
	</QueryClientProvider>
);

unregister();

if (window?.Cypress) {
	window.store = store;
}
