import { CheckboxField, LegacyInputField, InputNumberField, SwitchField, TextAreaField } from "@/components/Fields";
import { PaymentsData, addPayments } from "@/state/actions/newPayoutActions";
import { DateBreakdown } from "@/state/reducers/newPayoutReducer";
import { AppDispatch, ReduxState } from "@/state/store";
import { getFormValuesWrapper } from "@/utils/Form";
import { currency } from "@/utils/Format";
import { required } from "@/utils/Validators";
import { Modal, Table } from "@/ui/antd";
import React from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, change, reduxForm } from "redux-form";
import FraudWarnings from "../components/FraudWarnings";

const transactionIdRequired = required("A transaction id is required");

type ICombinedProps = IProps & IInjectedProps & IInjectedFunctions;

const PaymentModal: React.FunctionComponent<ICombinedProps & InjectedFormProps<PaymentsData, ICombinedProps>> = ({
	isOpen,
	breakdown,
	dispatch,
	formValues,
	handleSubmit,
	addPayments,
	eventId,
	close
}) => {
	const columns = [
		{
			key: "selected",
			render: (row: DateBreakdown) => (
				<Field
					component={CheckboxField}
					name={`payments.${row.dateId}.enabled`}
					onChange={(target: React.ChangeEvent<HTMLInputElement>) => {
						dispatch(change(formName, `payments.${row.dateId}.amount`, target.target.checked ? row.due : 0));
					}}
				/>
			)
		},
		{
			title: "Event date",
			key: "displayDate",
			dataIndex: "displayDate"
		},
		{
			title: "Maturity date",
			key: "maturityDate",
			dataIndex: "maturityDate"
		},
		{
			title: "Balance",
			key: "due",
			dataIndex: "due",
			render: (value: number) => currency(value)
		},
		{
			title: "Amount",
			key: "amount",
			render: (row: DateBreakdown) => (
				<Field
					name={`payments.${row.dateId}.amount`}
					component={InputNumberField}
					precision={2}
					min={0.01}
					max={row.due > 0 ? row.due : 0}
					onChange={() => {
						dispatch(change(formName, `payments.${row.dateId}.enabled`, true));
					}}
				/>
			)
		}
	];

	const save = (values: PaymentsData) => {
		addPayments(eventId!, values);
		close();
	};
	return (
		<Modal
			open={isOpen}
			destroyOnClose={true}
			onCancel={close}
			okText="Add payments"
			title="Add payments"
			onOk={handleSubmit(save)}
		>
			<form>
				<Table
					dataSource={breakdown.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())}
					columns={columns}
					pagination={false}
					footer={() => (
						<div style={{ textAlign: "right" }}>
							<b>
								Total:{" "}
								{currency(
									Object.values(formValues?.payments || {}).reduce((total, row) => {
										return total + row.amount;
									}, 0)
								)}
							</b>
						</div>
					)}
					style={{ marginBottom: 16 }}
				/>

				<Field name="payNow" label="Pay now" component={SwitchField} />
				{formValues.payNow ? (
					<Field
						name="transactionId"
						label="Transaction Id"
						component={LegacyInputField}
						required
						validate={transactionIdRequired}
					/>
				) : null}
				<Field name="notes" label="Notes" component={TextAreaField} />
				<Field name="shouldSendEmail" label="Send email" component={SwitchField} />
			</form>
			<FraudWarnings />
		</Modal>
	);
};

const formName = "AddPayments";

interface IInjectedProps {
	breakdown: DateBreakdown[];
	initialValues: PaymentsData;
	formValues: PaymentsData;
	eventId?: string;
}

interface IProps {
	isOpen: boolean;
	close: () => void;
	focusedDate: string | null;
}

interface IInjectedFunctions {
	addPayments: (eventId: string, data: PaymentsData) => void;
	dispatch: AppDispatch;
}

export default connect(
	(state: ReduxState, ownProps: IProps): IInjectedProps => {
		const formValues = getFormValuesWrapper<PaymentsData>(state, formName) || {};

		const breakdown = (state.payouts.eventPayout.payoutInformation?.datesBreakdown || []).filter((row) => row.due > 0);

		const initialValues = {
			payments: breakdown.reduce((accumulator, date) => {
				const isSelectedDate = ownProps.focusedDate === date.dateId;
				return {
					...accumulator,
					[date.dateId]: {
						enabled: isSelectedDate,
						amount: isSelectedDate ? date.due : 0
					}
				};
			}, {}),
			payNow: false,
			transactionId: undefined,
			notes: undefined,
			shouldSendEmail: true
		};
		return {
			initialValues,
			breakdown,
			formValues,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ addPayments }
)(
	reduxForm<PaymentsData, ICombinedProps>({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true,
		enableReinitialize: true
	})(PaymentModal)
) as unknown as React.FunctionComponent<IProps>;
