import { css } from "@emotion/css";
import { Menu } from "@/ui/antd";
import { Component } from "react";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import LinkButton from "../components/buttons/LinkButton";
import EventMenu from "../events/event/EventMenu";
import iconReference from "../services/IconReference";
import MenuService from "../services/menuService/MenuService";
import IconButton from "./IconButton";

/**
 * Side menu for referral program
 *
 * @param {string, object, array} {title, location, menuItems}
 * @returns
 */
class SplitLevelMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			level: 0,
			menuKey: null
		};
	}

	componentDidMount() {
		const { location } = this.props;
		const menuItems = MenuService.getUserMenu();
		const currentMenu = MenuService.getItemFromPath(location.pathname, menuItems);
		if (currentMenu && currentMenu.key && currentMenu.children && currentMenu.children.length !== 0) {
			this.setState({
				level: 1,
				menuKey: currentMenu.key
			});
		}
	}

	changeMenuLevel = (menuKey) => {
		this.setState({
			level: this.state.level + 1,
			menuKey
		});
	};

	backLevel = () => {
		const { navigate } = this.props;
		const eventSelected = matchPath({ path: "/console/my-events/:eventId", end: false }, location.pathname);

		if (eventSelected) {
			navigate("/console/my-events");
		} else {
			this.setState({
				level: this.state.level - 1
			});
		}
	};

	close = () => {
		this.props.navigated();
	};

	buildMenuItem = (item) => {
		const { level } = this.state;
		if (!item.children || level === 0) {
			const style = {
				fontSize: 14,
				color: "#323232"
			};
			const contents =
				!item.children || item.children.length === 0 ? (
					<Link to={item.path}>
						<span style={style}>{item.label}</span>
					</Link>
				) : (
					<span style={style} onClick={() => this.changeMenuLevel(item.key)}>
						{item.label}
					</span>
				);
			return { key: item.path, label: contents };
		}

		const subMenuItems = item.children
			.filter((s) => s.hide !== true)
			.map((item) => {
				return this.buildMenuItem(item);
			});

		return {
			key: `humanitix-menu-subMenu-${item.label}`,
			label: (
				<span>
					{iconReference[item.icon] ? (
						<img src={iconReference[item.icon]} style={{ marginRight: 12, marginLeft: -12 }} />
					) : null}
					{item.label}{" "}
				</span>
			),
			children: subMenuItems
		};
	};

	render() {
		const { location } = this.props;
		const { level, menuKey } = this.state;
		let menuItems = [];
		if (level === 0) {
			menuItems = MenuService.getUserMenu();
		} else {
			menuItems = MenuService.getItemChildren(menuKey);
		}
		const pathname = this.props.location.pathname;
		const eventSelect = matchPath({ path: "/console/my-events/:eventId", end: false }, pathname);

		const eventId = eventSelect ? eventSelect.params?.eventId : null;
		const displayOptions = level !== 0 || eventSelect;

		const wrapper = css({
			".ant-menu-item": {
				padding: 0,
				height: "38px"
			},
			".ant-menu": {
				fontSize: 16
			},
			".anticon": {
				display: "none"
			},
			a: {
				fontSize: "16px !important"
			}
		});

		const headingWrapper = css({
			height: 54,
			borderBottom: !displayOptions ? "" : "1px solid #dedede",
			position: "relative",
			padding: "16px 32px"
		});
		const selectedMenus = MenuService.getSelected(menuItems, location);
		const selectedKeys = selectedMenus.map((item) => item.path);
		const menuList = menuItems
			? menuItems
					.filter((s) => s.hide !== true)
					.map((item) => {
						return this.buildMenuItem(item);
					})
			: [];

		let menuForRender = (
			<Menu
				mode="inline"
				selectedKeys={selectedKeys}
				items={menuList}
				style={{
					height: "100%",
					marginBottom: 24
				}}
			/>
		);
		if (eventSelect && eventId !== "new") menuForRender = <EventMenu eventId={eventId} />;
		if (eventId === "new") menuForRender = null;
		return (
			<div style={{ background: `#fff`, paddingTop: 12 }} className={wrapper}>
				<div style={{ padding: "0px 24px" }}>
					<div className={headingWrapper}>
						{displayOptions ? (
							<IconButton
								icon="left_small"
								style={{
									float: "left",
									position: "absolute",
									left: -14,
									top: "calc(50% - 18px)"
								}}
								onClick={this.backLevel}
								ariaLabel="Go back"
							/>
						) : null}
						{displayOptions ? (
							<LinkButton
								onClick={this.backLevel}
								ariaLabel={eventSelect ? "Events" : MenuService.getItemLabel(menuKey)}
							>
								<h4>{eventSelect ? "Events" : MenuService.getItemLabel(menuKey)}</h4>
							</LinkButton>
						) : null}
						<IconButton
							icon="close"
							style={{
								float: "right",
								position: "absolute",
								right: -14,
								top: "calc(50% - 18px)"
							}}
							onClick={this.close}
							ariaLabel="Close"
						/>
					</div>
				</div>
				{menuForRender}
				<div
					style={{
						padding: "12px 12px 12px 38px",
						position: "sticky",
						width: "100%",
						bottom: 0,
						left: 0,
						boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 4px",
						background: "#fff"
					}}
				>
					<Link to="/console/my-events/new/basics">
						<span
							style={{
								fontSize: 14,
								color: "#353337"
							}}
						>
							Create event
						</span>
					</Link>
				</div>
			</div>
		);
	}
}
export default SplitLevelMenu;
