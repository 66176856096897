import { BuyTicketsOrHelp } from "@/auth/components/BuyTicketsOrHelp";
import { BackToSignin } from "@/auth/components/Links";
import { TwoColumnPageWrap } from "@/auth/components/TwoColumnPageWrap";
import { H1, P } from "@/components/text/Text";
import { useAuth } from "@/hooks/useAuth";
import { Form, Input } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { FormItem } from "@/ui/FormItem";
import { UserOutlined } from "@ant-design/icons";
import { FC } from "react";

export const ForgotPassword: FC = () => {
	const [forgotPasswordForm] = Form.useForm();
	const { forgotPassword } = useAuth();

	const handleSave = async () => {
		const formValues = forgotPasswordForm.getFieldsValue(true);
		const userMessage = "If you have an account, a reset password link will been sent";
		await forgotPassword(formValues.email, userMessage, userMessage);
	};

	return (
		<TwoColumnPageWrap>
			<H1>Forgot your password?</H1>

			<P center>Just enter the email address connected to your account and we'll send you a link to reset it.</P>

			<Form
				form={forgotPasswordForm}
				layout="vertical"
				style={{
					marginBottom: 48,
					marginTop: 24,
					width: "100%"
				}}
				onFinish={handleSave}
			>
				<FormItem
					changeTriggerOnBlur
					label={<b>Email Address</b>}
					name="email"
					required={false}
					validateFirst
					rules={[
						{
							min: 1,
							required: true,
							whitespace: true,
							message: "Please enter an email address"
						},
						{
							min: 1,
							type: "email",
							whitespace: true,
							message: "Please enter a valid email address"
						}
					]}
				>
					<Input prefix={<UserOutlined />} placeholder="Email address" />
				</FormItem>

				<Button aria-label="Submit" fullWidth htmlType="submit" variant="primary">
					Submit
				</Button>

				<div style={{ marginTop: "8px" }}>
					<BackToSignin />
				</div>
			</Form>

			<BuyTicketsOrHelp />
		</TwoColumnPageWrap>
	);
};
