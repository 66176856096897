import { Col, Row, Select, Table, Tabs, DatePicker, Flex } from "@/ui/antd";
import { ContentBlock, Heading } from "../AppBlocks";
import LoadErrorView from "../LoadErrorView";
import { trpc } from "../../trpc";
import { currency } from "@/utils/Format";
import { useState } from "react";
import { GetReportingGroupsInput } from "@hx/console";
import IconButton from "../IconButton";
import { css } from "@emotion/css";
import { getDateRange } from "@/utils/metrics";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { HumaniDate } from "@hx/dates";
import { Dropdown } from "@/ui";
import { Button } from "@/ui";
import { SlidersHorizontal } from "@phosphor-icons/react";

const { RangePicker } = DatePicker;

const Pane = Tabs.TabPane;

const dropdownContents = css({
	display: "grid",
	rowGap: 8,
	".ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
		height: "auto"
	},
	".ant-select-single.ant-select-show-arrow .ant-select-selection-item": {
		whiteSpace: "normal",
		wordBreak: "break-all"
	},
	".ant-select-single:not(.ant-select-customize-input) .ant-select-selector": {
		height: "unset"
	},

	"@media(max-width:768px)": {
		width: "80svw"
	}
});

const tableWrapper = css`
	.ant-table-expanded-row > .ant-table-cell {
		padding: 0;
	}
	.ant-table-expanded-row > .ant-table {
		margin: 0;
	}
`;

const expandedRowStylings = css`
	.ant-table {
		margin: 0;
	}
`;

const tableRowColor = css`
	td {
		background-color: #f9f9fa;
	}

	tr {
		background-color: #f9f9fa;
	}
`;

const salesColumns = (onExpandClick?: (key: string) => void) => [
	{
		title: "Reporting group",
		dataIndex: "name",
		render: (name: string) => (
			<div style={{ paddingLeft: onExpandClick ? 0 : 24 }}>
				{onExpandClick ? <IconButton icon="arrow_down" ariaLabel="expand" onClick={() => onExpandClick(name)} /> : null}
				{onExpandClick ? <b>{name}</b> : name}
			</div>
		)
	},
	{
		title: "Earnings",
		dataIndex: "earnings",
		fixed: "right",
		width: 150,
		render: (earnings: any) => (onExpandClick ? <b>{currency(earnings)}</b> : currency(earnings))
	},
	{
		title: "Sold",
		fixed: "right",
		width: 150,
		render: (row: any) =>
			onExpandClick ? (
				<b>
					{row.countPurchasedTickets} / {row.numberOfMaxTickets}
				</b>
			) : (
				`${row.countPurchasedTickets} / ${row.numberOfMaxTickets}`
			)
	}
];

const checkInsColumns = (onExpandClick?: (key: string) => void) => [
	{
		title: "Reporting group",
		dataIndex: "name",
		render: (name: string) => (
			<div style={{ paddingLeft: onExpandClick ? 0 : 24 }}>
				{onExpandClick ? <IconButton icon="arrow_down" ariaLabel="expand" onClick={() => onExpandClick(name)} /> : null}
				{onExpandClick ? <b>{name}</b> : name}
			</div>
		)
	},
	{
		title: "Checked in",
		fixed: "right",
		width: 150,
		render: (row: any) =>
			onExpandClick ? (
				<b>
					{row.countCheckedInTickets} / {row.numberOfMaxTickets}
				</b>
			) : (
				`${row.countCheckedInTickets} / ${row.numberOfMaxTickets}`
			)
	}
];

const ReportingGroups = ({
	reportingGroupsExist,
	eventId,
	eventDates,
	location
}: {
	reportingGroupsExist: boolean;
	eventId: string;
	eventDates: any[];
	location: string;
}) => {
	const [searchParams, _setSearchParams] = useState<GetReportingGroupsInput>({
		eventId,
		salesChannel: "all",
		eventDateId: "all",
		dateRange: undefined
	});

	const [showDateRangePicker, setShowDateRangePicker] = useState(false);

	const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

	const _onExpandClick = (key: string) => {
		const tempRowKeys = [...expandedRowKeys];
		if (tempRowKeys.includes(key)) {
			const index = tempRowKeys.indexOf(key);
			tempRowKeys.splice(index, 1);
		} else {
			tempRowKeys.push(key);
		}
		setExpandedRowKeys(tempRowKeys);
	};

	const returnFilters = () => {
		const { salesChannel, eventDateId, dateRange } = searchParams;
		const filters = [];
		switch (salesChannel) {
			case "online":
				filters.push({
					label: "Online sales",
					action: () => _setSearchParams({ ...searchParams, salesChannel: "all" })
				});
				break;
			case "manual":
				filters.push({
					label: "Manual sales",
					action: () => _setSearchParams({ ...searchParams, salesChannel: "all" })
				});
				break;
			case "boxOffice":
				filters.push({
					label: "Box office sales",
					action: () => _setSearchParams({ ...searchParams, salesChannel: "all" })
				});
				break;
			default:
				break;
		}

		if (eventDateId !== "all") {
			const date = eventDates.find((date) => String(date._id) === String(eventDateId));
			filters.push({
				label: date.display.combined,
				action: () => _setSearchParams({ ...searchParams, eventDateId: "all" })
			});
		}
		if (showDateRangePicker && dateRange?.timeScale?.length === 2) {
			filters.push({
				label: new HumaniDate(dateRange.timeScale[0], "guess", location).formatting.dateRange(
					new HumaniDate(dateRange.timeScale[1], "guess", location)
				),
				action: () => {
					_setSearchParams({ ...searchParams, dateRange: undefined });
					setShowDateRangePicker(false);
				}
			});
		} else if (dateRange) {
			switch (dateRange.timeScale) {
				case "today":
					filters.push({ label: "Today", action: () => _setSearchParams({ ...searchParams, dateRange: undefined }) });
					break;
				case "yesterday":
					filters.push({
						label: "Yesterday",
						action: () => _setSearchParams({ ...searchParams, dateRange: undefined })
					});
					break;
				case "week":
					filters.push({
						label: "Last 7 days",
						action: () => _setSearchParams({ ...searchParams, dateRange: undefined })
					});
					break;
				case "month":
					filters.push({
						label: "Last 30 days",
						action: () => _setSearchParams({ ...searchParams, dateRange: undefined })
					});
					break;
				default:
					break;
			}
		}

		return filters;
	};

	if (!reportingGroupsExist) return null;

	const { data, isLoading, refetch, isError } = trpc.metrics.reportingGroups.useQuery(searchParams);

	const eventDateOptions = eventDates.map((date) => ({
		label: date.display.combined,
		value: date._id
	}));

	return (
		<Row>
			<Col span={24}>
				<LoadErrorView loading={isLoading} retryAction={refetch} error={isError}>
					<ContentBlock>
						<Flex justify="space-between">
							<Heading title="Reporting groups" />
							<Dropdown
								title="Refine overview"
								persist
								items={
									<div className={dropdownContents}>
										{eventDateOptions.length > 1 && (
											<Select
												style={{ fontSize: 14, height: "auto", wordWrap: "break-word" }}
												defaultValue="all"
												onChange={(value) =>
													value === "all"
														? _setSearchParams({ ...searchParams, eventDateId: "all" })
														: _setSearchParams({ ...searchParams, eventDateId: value })
												}
												options={[
													{
														label: "All dates",
														value: "all"
													},
													...eventDateOptions
												]}
												value={searchParams.eventDateId}
											/>
										)}
										<Select
											style={{ fontSize: 14 }}
											defaultValue="all"
											onChange={(value: "all" | "online" | "manual" | "boxOffice") =>
												_setSearchParams({ ...searchParams, salesChannel: value })
											}
											options={[
												{ label: "All channels", value: "all" },
												{ label: "Online sales", value: "online" },
												{ label: "Manual sales", value: "manual" },
												{ label: "Box Office", value: "boxOffice" }
											]}
											value={searchParams.salesChannel}
										/>

										<Select
											style={{ fontSize: 14 }}
											defaultValue="allTime"
											onChange={(value: "allTime" | "today" | "yesterday" | "week" | "month" | "custom") => {
												if (value === "custom") {
													setShowDateRangePicker(true);
													_setSearchParams({ ...searchParams, dateRange: undefined });
													return;
												}
												setShowDateRangePicker(false);
												if (value === "allTime") {
													_setSearchParams({ ...searchParams, dateRange: undefined });
													return;
												}
												_setSearchParams({ ...searchParams, dateRange: getDateRange(value) });
											}}
											options={[
												{ label: "All time", value: "allTime" },
												{ label: "Today", value: "today" },
												{ label: "Yesterday", value: "yesterday" },
												{ label: "Last 7 days", value: "week" },
												{ label: "Last 30 days", value: "month" },
												{ label: "Custom Date", value: "custom" }
											]}
										/>

										{showDateRangePicker && (
											<RangePicker
												onChange={(value: any) => _setSearchParams({ ...searchParams, dateRange: getDateRange(value) })}
												style={{ paddingLeft: 30 }}
											/>
										)}
									</div>
								}
							>
								<Button aria-label="More options" iconOnly variant="text">
									<SlidersHorizontal size={20} />
								</Button>
							</Dropdown>
						</Flex>
						<Flex>
							{returnFilters().map((refinement) => (
								<LegacyButton
									key={refinement.label}
									onClick={refinement.action}
									type="import"
									icon={{
										name: "close",
										right: true
									}}
									style={{ marginRight: 8 }}
									ariaLabel={refinement.label}
								>
									{refinement.label}
								</LegacyButton>
							))}
						</Flex>
						<Tabs defaultActiveKey="1" onTabClick={() => setExpandedRowKeys([])}>
							<Pane tab="Sales" key="1">
								<div className={tableWrapper}>
									<Table
										columns={salesColumns(_onExpandClick)}
										dataSource={data?.result?.sales ?? []}
										rowKey="name"
										expandable={{
											expandedRowRender: (group) => {
												return (
													<div className={expandedRowStylings}>
														<Table
															columns={salesColumns()}
															dataSource={group.byTicket}
															pagination={false}
															showHeader={false}
															rowClassName={(_record, index) => {
																if (index % 2 === 0) {
																	return "";
																}
																return tableRowColor;
															}}
														/>
													</div>
												);
											},
											expandedRowKeys: expandedRowKeys,
											expandIconColumnIndex: -1
										}}
										expandIconAsCell={false}
										pagination={false}
									/>
								</div>
							</Pane>
							<Pane tab="Check-ins" key="2">
								<div className={tableWrapper}>
									<Table
										columns={checkInsColumns(_onExpandClick)}
										dataSource={data?.result?.checkIns ?? []}
										rowKey="name"
										expandable={{
											expandedRowRender: (group) => {
												return (
													<div>
														<Table
															columns={checkInsColumns()}
															dataSource={group.byTicket}
															pagination={false}
															showHeader={false}
															rowClassName={(_record, index) => {
																if (index % 2 === 0) {
																	return "";
																}
																return tableRowColor;
															}}
														/>
													</div>
												);
											},
											expandedRowKeys: expandedRowKeys,
											expandIconColumnIndex: -1
										}}
										expandIconAsCell={false}
										pagination={false}
									/>
								</div>
							</Pane>
						</Tabs>
					</ContentBlock>
				</LoadErrorView>
			</Col>
		</Row>
	);
};

export default ReportingGroups;
