import { DateField, TimeField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { dayjs } from "@/libs/dayjs";
import { required } from "@/utils/Validators";
import { css } from "@emotion/css";
import { Component } from "react";
import { Field } from "redux-form";
import EventDateTooltip from "./EventDateTooltip";

class EventDateRange extends Component {
	startDateRequired = required("Start date is required");
	endDateRequired = required("End date is required");
	futureDateRequired = (value, allValues, props, name) => {
		const index = Number(name.split("[")[1].split("]")[0]);
		const dateRow = allValues.dates[index];
		if (dateRow._id) {
			// the date has been create already dont check
			return undefined;
		}
		const now = dayjs();
		const date = dayjs(value);
		if (now.isAfter(date)) {
			return "Date must be in the future";
		}
		return undefined;
	};

	componentDidUpdate(preProps) {
		const preDates = preProps.dates;
		const { dates, changeFieldValue } = this.props;
		if (!dates) {
			return;
		}
		dates.forEach((date, index) => {
			const lastStartDate = preDates && preDates[index] ? preDates[index].startDate : null;
			const lastEndDate = preDates && preDates[index] ? preDates[index].endDate : null;
			const startDate = date.startDate;
			const endDate = date.endDate;

			if (startDate && endDate) {
				// Set endDate to startDate when startDate is set after endDate

				const startDateDayjs = dayjs(startDate);
				const startDateBefore = lastStartDate ? dayjs(lastStartDate) : dayjs();
				const endDateDayjs = dayjs(endDate);
				const endDateBefore = lastEndDate ? dayjs(lastEndDate) : dayjs();

				if (
					!startDateDayjs.isSame(startDateBefore) &&
					endDateDayjs.isSame(endDateBefore) &&
					startDateDayjs.isAfter(endDateDayjs)
				) {
					const dayDifference = endDateDayjs.diff(startDateBefore, "days");
					changeFieldValue(
						`dates[${index}].endDate`,
						startDateDayjs
							.hour(endDateDayjs.hour())
							.minute(endDateDayjs.minute())
							.add(dayDifference, "days")
							.toISOString()
					);
				}
			}

			if (startDate && !endDate) {
				const newStartDate = dayjs(startDate);
				if (!lastStartDate) {
					const startDateBefore =
						dates[index - 1] && dates[index - 1].startDate ? dayjs(dates[index - 1].startDate) : null;
					if (startDateBefore) {
						newStartDate.hour(startDateBefore.hour());
						newStartDate.minute(startDateBefore.minute());
						newStartDate.second(startDateBefore.second());
					} else {
						newStartDate.hour(19);
						newStartDate.minute(0);
						newStartDate.second(0);
					}

					changeFieldValue(`dates[${index}].startDate`, newStartDate.toISOString());
				}
				if (!endDate) {
					const endDate = dayjs(newStartDate);
					const endDateBefore = dates[index - 1] && dates[index - 1].endDate ? dayjs(dates[index - 1].endDate) : null;
					if (endDateBefore) {
						endDate.hour(endDateBefore.hour());
						endDate.minute(endDateBefore.minute());
						endDate.second(endDateBefore.second());
					} else {
						endDate.hour(23);
						endDate.minute(0);
						endDate.second(0);
					}
					changeFieldValue(`dates[${index}].endDate`, endDate.toISOString());
				}
			}
		});
	}

	enableDate = () => {
		const { changeFieldValue } = this.props;
		changeFieldValue(`dates[0].disabled`, false);
	};

	checkStartDate = (startDate, allValues, obj, label) => {
		const { touch } = this.props;
		const index = label.match(/\[([^<]+)]/)[1];
		touch(`date[${index}].endDate`);
		return undefined;
	};

	checkEndDate = (endDate, allValues, obj, label) => {
		const index = label.match(/\[([^<]+)]/)[1];
		const startDate = allValues.dates[index].startDate;
		const result = endDate < startDate ? `Must be after your start date` : undefined;
		return result;
	};

	checkEventDateIsEditable = () => {
		return this.isEventDateLocked() ? "Event has already passed" : undefined;
	};

	isEventDateLocked = () => {
		const { eventHasPassed, ticketSold = false, isUser } = this.props;
		return eventHasPassed && ticketSold && isUser;
	};

	render() {
		const { timezone, dates } = this.props;
		const isEventDateLocked = this.isEventDateLocked();
		const dateDisabled = dates && dates[0] && dates[0].disabled;
		return (
			<div
				className={css({
					display: "flex",
					flexWrap: "wrap",
					alignItems: "center"
				})}
			>
				<div
					className={css({
						marginRight: 12,
						"@media(max-width: 600px)": {
							float: "left",
							minWidth: "35vw",
							marginRight: 10,
							verticalAlign: "top"
						}
					})}
				>
					<EventDateTooltip isEventDateLocked={isEventDateLocked}>
						<Field
							name="dates[0].startDate"
							type="text"
							label="Start Date"
							component={DateField}
							validate={[
								this.startDateRequired,
								this.checkStartDate,
								this.futureDateRequired,
								this.checkEventDateIsEditable
							]}
							placeholder="Start Date"
							timezone={timezone}
							displayFormat="Do MMM YYYY"
							required
							disabled={isEventDateLocked || dateDisabled}
						/>
					</EventDateTooltip>
				</div>
				<div
					className={css({
						marginRight: 12,
						"@media(max-width: 600px)": {
							float: "left",
							minWidth: "35vw",
							marginRight: 10,
							verticalAlign: "top"
						}
					})}
				>
					<EventDateTooltip isEventDateLocked={isEventDateLocked}>
						<Field
							name="dates[0].startDate"
							type="text"
							label="Start Time"
							component={TimeField}
							validate={[
								this.startDateRequired,
								this.checkStartDate,
								this.futureDateRequired,
								this.checkEventDateIsEditable
							]}
							placeholder="Start Time"
							timezone={timezone}
							displayFormat="h:mm a"
							required
							disabled={isEventDateLocked || dateDisabled}
						/>
					</EventDateTooltip>
				</div>

				<div
					className={css({
						marginRight: 12,
						"@media(max-width: 600px)": {
							float: "left",
							minWidth: "35vw",
							marginRight: 10,
							verticalAlign: "top"
						}
					})}
				>
					<EventDateTooltip isEventDateLocked={isEventDateLocked}>
						<Field
							name="dates[0].endDate"
							type="text"
							label="End Date"
							component={DateField}
							validate={[
								this.endDateRequired,
								this.checkEndDate,
								this.futureDateRequired,
								this.checkEventDateIsEditable
							]}
							placeholder="End Date"
							timezone={timezone}
							displayFormat="Do MMM YYYY"
							required
							disabled={isEventDateLocked || dateDisabled}
						/>
					</EventDateTooltip>
				</div>
				<div
					className={css({
						marginRight: 12,
						"@media(max-width: 600px)": {
							float: "left",
							minWidth: "35vw",
							marginRight: 10,
							verticalAlign: "top"
						}
					})}
				>
					<EventDateTooltip isEventDateLocked={isEventDateLocked}>
						<Field
							name="dates[0].endDate"
							type="text"
							label="End Time"
							component={TimeField}
							validate={[
								this.endDateRequired,
								this.checkEndDate,
								this.futureDateRequired,
								this.checkEventDateIsEditable
							]}
							placeholder="End Time"
							timezone={timezone}
							displayFormat="h:mm a"
							required
							disabled={isEventDateLocked || dateDisabled}
						/>
					</EventDateTooltip>
				</div>

				{dateDisabled && (
					<IconButton
						icon="disable"
						tooltip="Enable date"
						showTooltip
						ariaLabel="Enable_date"
						onClick={this.enableDate}
					/>
				)}
			</div>
		);
	}
}
export default EventDateRange;
