import { css } from "@emotion/css";
import { State } from "@hx/seating-map";
import Icon from "@/components/Icon";
import { Label, Note, P } from "@/components/text/Text";
import { truncateString } from "@/utils/Format";

const { AssigningManager } = State.Assigning;

const AttendeeStyle = css({
	borderRadius: 4,
	border: "solid 1px #dedede",
	marginBottom: 8,
	flexGrow: 1,
	padding: "12px 12px 8px 12px",
	"&:hover": {
		backgroundColor: "#f9f9fa"
	}
});

const Attendee = ({ attendeeInfo }) => {
	return <div className={AttendeeStyle}>{determineCard(attendeeInfo)}</div>;
};

const TicketCard = (prop) => {
	const { ticketTypeName, firstName, lastName, seatingLocation, orderName, organisation = "" } = prop;
	return (
		<div
			onClick={() => AssigningManager.changeFocus(100)}
			onMouseOver={() => AssigningManager.highlight(seatingLocation.seat.id)}
			onMouseLeave={() => AssigningManager.removeHighlight(seatingLocation.seat.id)}
		>
			<div className={css({ display: "flex" })}>
				<Label>{`${firstName} ${lastName}`}</Label>
				<Note bold={false}>{orderName}</Note>
			</div>
			<div className={css({ display: "flex", justifyContent: "space-between" })}>
				<Note bold={false}>{ticketTypeName}</Note>
				{organisation && <Note bold={false}>{truncateString(organisation, 20)}</Note>}
			</div>
			<div className={css({ height: 1, border: "solid 1px #dedede" })} />
			<Label bold={false}>{seatingLocation?.name || "Unassigned"}</Label>
			<div className={css({ display: "flex", flexDirection: "row", marginTop: 16 })}>
				<div
					className={css({
						display: "flex",
						flexDirection: "row",
						flexGrow: 1,
						cursor: "pointer"
					})}
					onClick={() => AssigningManager.enterSeatUnassign(seatingLocation.seat.id)}
				>
					<Icon icon="cancel" style={{ marginRight: 12 }} />
					<div style={{ marginTop: 2 }}>
						<Label>Clear seat</Label>
					</div>
				</div>
				<div
					className={css({
						display: "flex",
						flexDirection: "row",
						cursor: "pointer",
						marginTop: 2
					})}
					onClick={() => AssigningManager.enterChangeSeat(seatingLocation.seat.id)}
				>
					<Icon icon="change" style={{ marginRight: 6 }} />
					<Label>Change seat</Label>
				</div>
			</div>
		</div>
	);
};

const OrderCard = (orderData) => {
	const { displayName, orderName, tickets, email } = orderData;
	const highlightSeats = () => {
		tickets?.map(({ seatingLocation }) => {
			AssigningManager.highlight(seatingLocation.seat.id);
		});
	};

	const unhighlightSeats = () => {
		tickets?.map(({ seatingLocation }) => {
			AssigningManager.removeHighlight(seatingLocation.seat.id);
		});
	};

	return (
		<div onMouseOver={highlightSeats} onMouseLeave={unhighlightSeats}>
			<P style={{ fontWeight: 600, marginBottom: 4 }}>{displayName}</P>
			<P marginBottom={4}>{`Order: ${orderName}`}</P>
			<P>{email}</P>
			<P>{`${tickets.length} tickets`}</P>
		</div>
	);
};

const ActiveSeatCard = ({ name, firstName, lastName, ticketTypeName, selectedObjectId }) => {
	return (
		<div className={css({ padding: "12px 12px 8px 12px" })}>
			<Label>{`${firstName} ${lastName}`}</Label>
			<Note bold={false}>{ticketTypeName}</Note>
			<div className={css({ height: 1, border: "solid 1px #dedede" })} />
			<Label bold={false}>{name || "Unassigned"}</Label>
			<div className={css({ display: "flex", flexDirection: "row", marginTop: 16 })}>
				<div
					className={css({
						display: "flex",
						flexDirection: "row",
						flexGrow: 1,
						cursor: "pointer"
					})}
					onClick={() => AssigningManager.enterSeatUnassign(selectedObjectId)}
				>
					<Icon icon="cancel" style={{ marginRight: 12 }} />
					<div className={css({ marginTop: 2 })}>
						<Label>Clear seat</Label>
					</div>
				</div>
				<div
					className={css({
						display: "flex",
						flexDirection: "row",
						cursor: "pointer",
						marginTop: 2
					})}
					onClick={() => AssigningManager.enterChangeSeat(selectedObjectId)}
				>
					<Icon icon="change" style={{ marginRight: 6 }} />
					<Label>Change seat</Label>
				</div>
			</div>
		</div>
	);
};

const determineCard = (attendeeInfo) => {
	if (!attendeeInfo?.assigningType) return <ActiveSeatCard {...attendeeInfo} />;
	if (attendeeInfo?.assigningType === "orders") return <OrderCard {...attendeeInfo} />;
	if (attendeeInfo?.assigningType === "tickets") return <TicketCard {...attendeeInfo} />;
};

export default Attendee;
