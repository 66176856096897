import React, { useEffect, useRef, useState } from "react";

interface ScrollComponentProps {
	children?: React.ReactNode;
	style?: React.CSSProperties;
	onReachedBottom?: () => void;
}
const ScrollComponent: React.FC = ({ children, style = {}, onReachedBottom }: ScrollComponentProps) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const [hasReachedBottom, setHasReachedBottom] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const isBottom = (el: HTMLDivElement) => el.scrollTop + el.clientHeight === el.scrollHeight;

			const scrollContainer = scrollContainerRef.current;

			if (scrollContainer && isBottom(scrollContainer) && onReachedBottom) {
				setHasReachedBottom(true);
				onReachedBottom();
			} else if (scrollContainer && !isBottom(scrollContainer) && hasReachedBottom) {
				// User has scrolled up, reset hasReachedBottom
				setHasReachedBottom(false);
			}
		};

		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", handleScroll);
			return () => {
				scrollContainer.removeEventListener("scroll", handleScroll);
			};
		}
	}, [scrollContainerRef]);

	return (
		<div ref={scrollContainerRef} style={{ ...style, height: "100%", overflowY: "scroll" }}>
			{children}
		</div>
	);
};

export default ScrollComponent;
