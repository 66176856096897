import { CollectionsConfigPanelKey, configMenuItems } from "@/features/collections/configPanel/CollectionsConfigPanel";
import { PreviewCollectionPage } from "@/features/collections/preview/PreviewCollectionPage";
import { PreviewCollectionWidget } from "@/features/collections/preview/PreviewCollectionWidget";
import { Alert, Tabs } from "@/ui/antd";
import styled from "@emotion/styled";
import { Collection } from "@hx/console";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";

const PreviewWrapper = styled.div`
	flex: 1 0 50%;
	height: 100%;
	min-width: min(600px, 80vw);
	padding: 16px;
	position: sticky;
	top: 0;
`;

type PreviewPanelProps = {
	activeCollection: Collection;
	hasUnsavedChanges: boolean;
	selectedPanel: CollectionsConfigPanelKey;
};

export const PreviewPanel: FC<PreviewPanelProps> = ({ activeCollection, hasUnsavedChanges, selectedPanel }) => {
	const navigate = useNavigate();
	const widgetKey = configMenuItems.widget.key;
	const [showBanner, setShowBanner] = useState(false);

	useEffect(() => {
		setShowBanner(hasUnsavedChanges);
	}, [hasUnsavedChanges]);

	const handleTabChange = (key: string) => {
		if (key === widgetKey && selectedPanel !== widgetKey) {
			navigate(`../${activeCollection._id}/widget`);
		} else if (key !== widgetKey && selectedPanel === widgetKey) {
			navigate(`../${activeCollection._id}/events`);
		}
	};

	const banner = (
		<Alert
			collapsed={!showBanner}
			description="There are unsaved changes. Save to view your progress."
			type="warning"
			showIcon
		/>
	);

	return (
		<PreviewWrapper>
			<h3>
				<b>Preview</b>
			</h3>
			<Tabs
				activeKey={selectedPanel === widgetKey ? widgetKey : "page"}
				defaultActiveKey={selectedPanel === widgetKey ? widgetKey : "page"}
				onChange={handleTabChange}
				items={[
					{
						children: <PreviewCollectionPage activeCollection={activeCollection} banner={banner} />,
						key: "page",
						label: "Collection Page"
					},
					{
						children: (
							<PreviewCollectionWidget
								activeCollection={activeCollection}
								banner={banner}
								isActive={selectedPanel === widgetKey}
							/>
						),
						key: widgetKey,
						label: "Widget"
					}
				]}
			/>
		</PreviewWrapper>
	);
};
