import { ContentBlock } from "@/components/AppBlocks";
import { RadioField, SelectField, SwitchField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";
import { useViewport } from "@/hooks/useViewport";
import { updateResaleSettings } from "@/state/actions/eventActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { Flex } from "@/ui/antd";
import { getEventUrls } from "@/utils/Events";
import copy from "copy-text-to-clipboard";
import { useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, formValueSelector, reduxForm } from "redux-form";

const formName = "resaleSettings";

const checkEndDate = (endDate: any, allValues: any) => {
	const { startResaleDate } = allValues;
	if (startResaleDate && endDate && endDate < startResaleDate) {
		return "Resale end date must be after start date";
	}
	return undefined;
};

const MustNotBeEmpty = (selectedOptions: any[]) => {
	if (!selectedOptions.length) return "At least one option should be selected.";
	return undefined;
};

interface FormProps {
	enabled: boolean;
	allowedTicketTypes: "all" | "custom";
	ticketTypeIds: string[];
	allowedPackagedTickets: "all" | "custom";
	packagedTicketIds: string[];
	preventTicketSplitForPackages: boolean;
	startResaleDate?: Date;
	endResaleDate?: Date;
	hidePromotion: boolean;
}

type ResaleProps = FormProps & InjectedFormProps & Pick<GlobalReduxState, "currentEvent"> & any;
const emptyFormValues: FormProps = {
	enabled: false,
	allowedTicketTypes: "all",
	ticketTypeIds: [],
	allowedPackagedTickets: "all",
	packagedTicketIds: [],
	preventTicketSplitForPackages: false,
	startResaleDate: undefined,
	endResaleDate: undefined,
	hidePromotion: false
};

const Resale = ({
	enabled,
	allowedTicketTypes,
	allowedPackagedTickets,
	currentEvent,
	handleSubmit,
	updateResaleSettings
}: ResaleProps) => {
	const [loading, setLoading] = useState(false);
	const { publicResaleUrl } = getEventUrls(currentEvent?.event, true);
	const isEventPublished = currentEvent?.event?.published;

	const { isMobile } = useViewport();

	const ticketOptions = currentEvent.event.ticketTypes
		?.filter((tt: any) => !tt.deleted && !tt.isDonation)
		.map((tt: any) => {
			return {
				label: tt.name,
				value: tt._id
			};
		});

	const packageOptions = currentEvent.event.packagedTickets
		?.filter((pt: any) => !pt.deleted && !pt.disabled)
		?.map((pt: any) => {
			return {
				label: pt.name,
				value: pt._id
			};
		});

	const handleSave = async (formValues: FormProps) => {
		const newSettingData = {
			...formValues,
			ticketTypeIds: formValues.allowedTicketTypes === "all" ? [] : formValues.ticketTypeIds,
			packagedTicketIds: formValues.allowedPackagedTickets === "all" ? [] : formValues.packagedTicketIds
		};
		setLoading(true);

		await updateResaleSettings(String(currentEvent.event._id), newSettingData);

		setLoading(false);
	};

	return (
		<>
			<PageHeading title="Resale" />
			<ContentBlock>
				<LoadErrorView loading={loading}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Field
							name="enabled"
							description="Allow attendees to re-sell tickets to others"
							label="Enable ticket resale"
							component={SwitchField}
						/>
						{enabled ? (
							<>
								<hr style={{ marginBottom: 24, marginTop: 24 }} />

								<Field
									name="allowedTicketTypes"
									label="Select ticket type(s) for resale"
									component={RadioField}
									button
									options={[
										{
											label: "All tickets",
											value: "all"
										},
										{
											label: "Custom",
											value: "custom"
										}
									]}
									style={{ marginBottom: 10 }}
								/>
								{allowedTicketTypes === "custom" ? (
									<Field
										name="ticketTypeIds"
										label="For which ticket type(s)"
										required
										component={SelectField}
										mode="multiple"
										options={ticketOptions}
										placeholder="select at least one ticket type"
										style={{ width: "100%" }}
										validate={ticketOptions.length ? MustNotBeEmpty : undefined}
									/>
								) : null}

								<hr style={{ marginBottom: 24, marginTop: 24 }} />
								<Field
									name="allowedPackagedTickets"
									label="Select package(s) for resale"
									component={RadioField}
									button
									options={[
										{
											label: "All packages",
											value: "all"
										},
										{
											label: "Custom",
											value: "custom"
										}
									]}
									style={{ marginBottom: 10 }}
								/>
								{allowedPackagedTickets === "custom" ? (
									<Field
										name="packagedTicketIds"
										label="For which package(s)"
										component={SelectField}
										required={packageOptions.length}
										mode="multiple"
										options={packageOptions}
										placeholder={
											packageOptions.length ? "select at least one package" : "This event has no packaged tickets"
										}
										style={{ width: "100%", marginBottom: 10 }}
										disabled={!packageOptions.length}
										validate={packageOptions.length ? MustNotBeEmpty : undefined}
									/>
								) : null}
								<Field
									name="preventTicketSplitForPackages"
									label="Packages can only be sold as a whole"
									description="Tickets within a package cannot be split and attendees can only sell packages as a single unit."
									component={SwitchField}
								/>

								<hr style={{ marginBottom: 24, marginTop: 24 }} />

								<Flex gap={isMobile ? "sm" : "xxl"} style={{ marginBottom: 24 }} wrap={isMobile ? "wrap" : "nowrap"}>
									<Field
										component={ReduxDateTimePicker}
										description="The date and time resales will start"
										displayFormat="Do MMM YYYY"
										formName={formName}
										label="Resale start"
										name="startResaleDate"
										placeholder="Start date"
										showTime
										timeOptions={{ placeholder: "Start time" }}
										timezone={currentEvent.event.timezone}
									/>
									<Field
										component={ReduxDateTimePicker}
										description="The date and time resales will end"
										displayFormat="Do MMM YYYY"
										formName={formName}
										label="Resale end"
										name="endResaleDate"
										placeholder="End date"
										showTime
										timeOptions={{ placeholder: "End time" }}
										timezone={currentEvent.event.timezone}
										validate={checkEndDate}
									/>
								</Flex>
								<Field
									name="hidePromotion"
									description="Remove all references and links to resale from the select tickets page."
									label="Hide resale on select tickets page"
									component={SwitchField}
								/>
								{isEventPublished ? (
									<div style={{ display: "flex" }}>
										<div
											style={{
												background: "rgb(250, 250, 250)",
												border: "1px solid rgb(232, 232, 232)",
												padding: 8,
												borderRadius: "4px 0 0 4px",
												flex: 1,
												overflow: "hidden"
											}}
										>
											<a
												href={publicResaleUrl}
												target="_blank"
												style={{
													overflow: "hidden",
													whiteSpace: "nowrap",
													flex: 1
												}}
												rel="noopener noreferrer"
											>
												{publicResaleUrl}
											</a>
										</div>

										<LegacyButton
											ariaLabel="Copy event url"
											type="secondary"
											style={{ borderRadius: "0 4px 4px 0" }}
											onClick={() => copy(publicResaleUrl)}
										>
											Copy
										</LegacyButton>
									</div>
								) : null}
							</>
						) : null}
					</form>
				</LoadErrorView>
			</ContentBlock>
			<FormBar>
				<LegacyButton
					style={{
						float: "right",
						width: "100px"
					}}
					onClick={handleSubmit(handleSave)}
					type="primary"
					disabled={loading}
					ariaLabel="Save"
				>
					Save
				</LegacyButton>
			</FormBar>
		</>
	);
};

export default connect(
	(state: Pick<GlobalReduxState, "currentEvent"> & any) => {
		const { resaleSettings } = state.currentEvent.event;
		const initialValues: FormProps = resaleSettings
			? {
					...resaleSettings,
					allowedTicketTypes: resaleSettings.ticketTypeIds.length ? "custom" : "all",
					allowedPackagedTickets: resaleSettings.packagedTicketIds.length ? "custom" : "all"
			  }
			: emptyFormValues;
		const selector = formValueSelector(formName);

		return {
			initialValues,
			enabled: selector(state, "enabled"),
			allowedTicketTypes: selector(state, "allowedTicketTypes"),
			allowedPackagedTickets: selector(state, "allowedPackagedTickets"),
			currentEvent: state.currentEvent,
			hidePromotion: selector(state, "hidePromotion")
		};
	},
	{ updateResaleSettings }
)(
	reduxForm({
		form: formName,
		touchOnChange: true,
		touchOnBlur: true
	})(Resale)
);
