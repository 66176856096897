import { Field } from "redux-form";
import { ImageUploadField, LegacyInputField, RadioField, TextAreaField, RichTextAreaField } from "@/components/Fields";
import { required, url } from "@/utils/Validators";
import ElementHeader from "../ElementHeader";

const titleRequired = required("A title is required");
const urlRequired = required("A url is required");
const embedCodeRequired = required("Embed code is required");
const urlValid = url("Must be a valid url");
const Video = (props) => {
	const { fieldPath, element } = props;
	return (
		<>
			<ElementHeader {...props} />
			<div>
				<Field
					name={`${fieldPath}.title`}
					label="Title"
					component={LegacyInputField}
					required
					validate={titleRequired}
				/>

				<Field name={`${fieldPath}.description`} label="Description" component={RichTextAreaField} cols={3} />

				<Field
					name={`${fieldPath}.type`}
					label="Link or embed"
					component={RadioField}
					button
					defaultValue="link"
					options={[
						{
							label: "Link",
							value: "link"
						},
						{
							label: "Embed",
							value: "embed"
						}
					]}
				/>
				{element.type === "link" ? (
					<>
						<Field
							name={`${fieldPath}.url`}
							label="URL"
							component={LegacyInputField}
							required
							validate={[urlRequired, urlValid]}
						/>
						<div style={{ maxWidth: 450 }}>
							<Field
								name={`${fieldPath}.image`}
								label="Placeholder Image"
								component={ImageUploadField}
								aspectRatio={2}
							/>
						</div>
					</>
				) : (
					<Field
						name={`${fieldPath}.embedCode`}
						label="Embed code"
						component={TextAreaField}
						cols={3}
						required
						validate={embedCodeRequired}
					/>
				)}
			</div>
			<hr />
		</>
	);
};

export default Video;
