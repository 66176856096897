import { Utils } from "@hx/seating-map";
import { Input, InputNumber, Radio, Select } from "@/ui/antd";
import { Component } from "react";
import InputLabel from "../../../components/InputLabel";
const { LabelGenerator } = Utils;

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

export default class LabelOptions extends Component {
	onLabelPatternChange = (value) => {
		const { element, performAction } = this.props;
		element.setSeatLabelPattern(value);
		performAction([element.setSeatLabelPattern], [[value]]);
	};
	onSeatPrefixChange = (event) => {
		const { element, performAction } = this.props;
		const value = event.target.value;
		performAction([element.setSeatLabelPrefix], [[value]]);
	};
	onTablePrefixChange = (event) => {
		const { element, performAction } = this.props;
		const value = event.target.value;
		performAction([element.setTableLabelPrefix], [[value]]);
	};
	onSeatStartsAt = (value) => {
		const { element, performAction } = this.props;
		// const value = event.target.value;
		const seatLabelPattern = this.state;
		if (seatLabelPattern === "alphabet") {
			const num = value.toUpperCase().charCodeAt(0) - 65;
			performAction([element.setSeatLabelStartAt], [[num]]);
		} else if (/^\d+$/.test(value)) {
			performAction([element.setSeatLabelStartAt], [[parseInt(value)]]);
		}
	};

	onLabelDirectionChange = (event) => {
		const { element, performAction } = this.props;
		performAction([element.setLabelDirection], [[event.target.value]]);
	};

	render() {
		const { element } = this.props;
		const { tablePrefix, seatPrefix, seatLabelPattern } = element;
		let formatter = (value) => value;
		if (seatLabelPattern === "alphabet") {
			formatter = (value) => {
				return LabelGenerator(Number(value));
			};
		}
		return (
			<div style={{ overflow: "auto", paddingTop: 12 }}>
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: 8 }}>
						<InputLabel>Table Prefix</InputLabel>
						<Input style={{ width: 134 }} defaultValue={tablePrefix} onChange={this.onTablePrefixChange} />
					</div>
					<div style={{}}>
						<InputLabel>Seat Prefix</InputLabel>
						<Input style={{ width: 134 }} defaultValue={seatPrefix} onChange={this.onSeatPrefixChange} />
					</div>
				</div>
				<div style={{ display: "flex", marginTop: 16 }}>
					<div style={{ width: 134 }}>
						<InputLabel>Seat Labels</InputLabel>
						<Select
							popupMatchSelectWidth={false}
							value={seatLabelPattern}
							style={{ width: 134, minWidth: "unset" }}
							onChange={this.onLabelPatternChange}
							options={[
								{ value: "count", label: "1,2,3..." },
								{ value: "alphabet", label: "A,B,C..." }
							]}
						/>
					</div>
					<div style={{ marginLeft: 8, flexGrow: 1 }}>
						<InputLabel>Start With</InputLabel>
						<InputNumber style={{ height: 36 }} min={0} formatter={formatter} onChange={this.onSeatStartsAt} />
					</div>
				</div>
				<div style={{ marginTop: 16, display: "grid" }}>
					<InputLabel>Label direction</InputLabel>
					<RadioGroup
						size="large"
						buttonStyle="solid"
						value={element.labelDirection}
						onChange={this.onLabelDirectionChange}
					>
						<RadioButton key="left" value="left">
							Left
						</RadioButton>
						<RadioButton key="right" value="right">
							Right
						</RadioButton>
					</RadioGroup>
				</div>
			</div>
		);
	}
}
