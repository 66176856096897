import {
	GET_TEMPLATES_ERROR,
	GET_TEMPLATES_REQUEST,
	GET_TEMPLATES_SUCCESS,
	GET_TEMPLATE_ERROR,
	GET_TEMPLATE_REQUEST,
	GET_TEMPLATE_SUCCESS,
	REMOVE_TEMPLATE_ERROR,
	REMOVE_TEMPLATE_REQUEST,
	REMOVE_TEMPLATE_SUCCESS,
	SAVE_TEMPLATE_ERROR,
	SAVE_TEMPLATE_REQUEST,
	SAVE_TEMPLATE_SUCCESS,
	UPDATE_TEMPLATE_ERROR,
	UPDATE_TEMPLATE_REQUEST,
	UPDATE_TEMPLATE_SUCCESS
} from "../actions/templateActions";

const initialState = {
	selectedTemplate: false,
	templates: false,
	page: 1,
	loading: false,
	error: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case REMOVE_TEMPLATE_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};

		case REMOVE_TEMPLATE_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};

		case REMOVE_TEMPLATE_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				templates: action.templates
			};

		case SAVE_TEMPLATE_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};

		case SAVE_TEMPLATE_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};
		case SAVE_TEMPLATE_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				templates: action.templates
			};
		case UPDATE_TEMPLATE_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};

		case UPDATE_TEMPLATE_SUCCESS:
			return {
				...state,
				error: false,
				loading: false
			};

		case UPDATE_TEMPLATE_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};

		case GET_TEMPLATE_REQUEST:
			return {
				...state,
				error: false,
				loading: true,
				selectedTemplate: false
			};

		case GET_TEMPLATE_SUCCESS:
			return {
				...state,
				error: false,
				loading: false,
				selectedTemplate: action.template
			};

		case GET_TEMPLATE_ERROR:
			return {
				...state,
				error: action.error
			};

		case GET_TEMPLATES_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case GET_TEMPLATES_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};
		case GET_TEMPLATES_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				templates: action.templates
			};

		default:
			return state;
	}
};
