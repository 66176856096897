import { css } from "@emotion/css";
import { DateFormats, HumaniDate } from "@hx/dates";
import { StringUtils } from "@hx/utilities";
import { Checkbox, Dropdown, Modal, Table, Tooltip } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import icFlagged from "@/assets/payoutsSearch/flagged.svg";
import icCheck from "@/assets/payoutsSearch/ic_check.svg";
import icCross from "@/assets/payoutsSearch/ic_cross.svg";
import icFraudFail from "@/assets/payoutsSearch/ic_fraud_fail.svg";
import icFraudPass from "@/assets/payoutsSearch/ic_fraud_pass.svg";
import icUserFail from "@/assets/payoutsSearch/ic_user_not_verified.svg";
import icUserPass from "@/assets/payoutsSearch/ic_user_verified.svg";
import icPaused from "@/assets/payoutsSearch/paused.svg";
import icStale from "@/assets/payoutsSearch/stale.svg";
import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import SearchRefinements from "@/components/SearchRefinements";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import TableActions from "@/components/table/TableActions";
import P from "@/components/text/P";
import LocationService from "@/services/LocationService";
import {
	changePayoutStatus,
	flagPayout,
	getPayoutCsvReport,
	markPaymentsAsComplete,
	massChangePayoutStatus,
	massFlagPayout,
	massSendPayoutEmails,
	searchPayouts,
	sendPayoutReminderEmails
} from "@/state/actions/payoutActions";
import { updateSearch } from "@/state/actions/searchActions";
import { currency } from "@/utils/Format";

const eventNameStyling = css({
	maxWidth: 120,
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	display: "block"
});

const modalFooter = css({
	display: "flex",
	justifyContent: "space-between"
});

const noteWrapper = css({
	borderBottom: "1px solid rgba(32, 7, 64, 0.13)",
	padding: "16px 0",
	div: {
		display: "flex",
		justifyContent: "space-between",
		p: {
			marginBottom: 12
		}
	},
	p: {
		marginBottom: 4
	}
});

const readButton = css({
	border: "none",
	backgroundColor: "transparent",
	color: "#196097",
	cursor: "pointer"
});

class Payouts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRowKeys: [],
			payout: false,
			emailModalOpen: false,
			massEmail: false,
			notesModalOpen: false,
			sendUserVerify: false,
			sendBankAccountReminder: false
		};
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.csvDownload = this.csvDownload.bind(this);
	}

	defaultSearch = {
		bankAccount: "all",
		userVerified: "all",
		fraudCheck: "all",
		autoPayout: "all",
		flagged: "all",
		status: "none",
		page: 1
	};

	load() {
		const { searchPayouts } = this.props;
		searchPayouts();
		this.setState({ selectedRowKeys: [] });
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	csvDownload() {
		const { getPayoutCsvReport } = this.props;
		getPayoutCsvReport();
	}

	onSelectChange = (selectedRowKeys) => {
		this.setState({ selectedRowKeys });
	};

	emailModalOptions = (key, value) => {
		this.setState({
			[key]: value
		});
	};

	sendPayoutEmail = () => {
		const { sendPayoutReminderEmails } = this.props;
		const { payout, sendUserVerify, sendBankAccountReminder } = this.state;
		sendPayoutReminderEmails(payout.payoutDates.eventDateId, sendUserVerify, sendBankAccountReminder);
		this.setState({
			payout: false,
			emailModalOpen: false
		});
	};

	bulkEmailActions = () => {
		const { selectedRowKeys, sendUserVerify, sendBankAccountReminder } = this.state;
		const { payouts, massSendPayoutEmails } = this.props;
		const eventIds = payouts.payouts
			.filter((payout, index) => selectedRowKeys.indexOf(index) !== -1)
			.map((payout) => payout.payoutDates.eventDateId);
		massSendPayoutEmails(eventIds, sendUserVerify, sendBankAccountReminder);
		this.setState({
			payout: false,
			emailModalOpen: false,
			sendBankAccountReminder: false,
			sendUserVerify: false,
			massEmail: false
		});
	};

	filterOptions = [
		{
			key: "eventIdInput",
			label: "Event ID",
			kind: "text"
		},
		{
			key: "userVerified",
			label: "Account status",
			kind: "radio",
			options: [
				{ value: "all", name: "All" },
				{ value: "true", name: "Verified" },
				{ value: "false", name: "Not Verified" }
			]
		},
		{
			key: "fraudCheck",
			label: "Fraud status",
			kind: "radio",
			options: [
				{ value: "all", name: "All" },
				{ value: "pass", name: "Pass" },
				{ value: "fail", name: "Fail" }
			]
		},
		{
			key: "bankAccount",
			label: "Bank account",
			kind: "radio",
			options: [
				{ value: "all", name: "All" },
				{ value: "true", name: "Yes" },
				{ value: "false", name: "No" }
			]
		},
		{
			key: "autoPayout",
			label: "Auto Payout",
			kind: "radio",
			options: [
				{ value: "all", name: "All" },
				{ value: "true", name: "Yes" },
				{ value: "false", name: "No" }
			]
		},
		{
			key: "flagged",
			label: "Flagged",
			kind: "radio",
			options: [
				{ value: "all", name: "All" },
				{ value: "true", name: "Yes" },
				{ value: "false", name: "No" }
			]
		},
		{
			key: "status",
			label: "Status",
			kind: "radio",

			options: [
				{ value: "all", name: "All", cy: "status-all" },
				{ value: "stale", name: "Stale" },
				{ value: "paused", name: "Paused" },
				{ value: "none", name: "None" }
			]
		},
		{
			key: "fraudChecksIds",
			label: "Fraud flags",
			mode: "multiple",
			placeholder: "Any or none",
			options: [
				{ value: "eventHasTooManyInternationalOrders", name: "Int orders > 30% " },
				{ value: "isEventPublishedTooSoon", name: "Published within 5 days" },
				{ value: "eventHasTooManySameDayTransactions", name: "90% orders in 24 hrs" },
				{ value: "eventHasTooManyRefunds", name: "Refunds > 20%" },
				{ value: "eventHasTooManySwappedTickets", name: "Swapped tickets > 50%" },
				{ value: "tooManyOrdersWithinSevenDaysOfStart", name: "90% orders in 7 days" },
				{ value: "userDoesNotHaveOldEnoughOrder", name: "No order over 30 days" }
			]
		}
	];

	render() {
		const { selectedRowKeys, payout, emailModalOpen, notesModalOpen, massEmail } = this.state;
		const {
			payouts,
			changePayoutStatus,
			flagPayout,
			massFlagPayout,
			massChangePayoutStatus,
			search,
			markPaymentsAsComplete
		} = this.props;
		const isUS = payouts?.payouts?.[0]?.location === "US" || false;
		const massActionMenu = [
			{
				key: "mass-remove-status",
				label: (
					<div
						data-cy="mass-remove-status"
						onClick={() =>
							massChangePayoutStatus(
								selectedRowKeys.map((key) => key.split("-")[1]),
								false,
								this.load
							)
						}
					>
						Remove Status
					</div>
				)
			},
			{
				key: "mass-apply-stale",
				label: (
					<div
						data-cy="mass-apply-stale"
						onClick={() =>
							massChangePayoutStatus(
								selectedRowKeys.map((key) => key.split("-")[1]),
								"stale",
								this.load
							)
						}
					>
						Stale
					</div>
				)
			},
			{
				key: "mass-apply-paused",
				label: (
					<div
						data-cy="mass-apply-paused"
						onClick={() =>
							massChangePayoutStatus(
								selectedRowKeys.map((key) => key.split("-")[1]),
								"paused",
								this.load
							)
						}
					>
						Paused
					</div>
				)
			},
			{
				key: "mass-apply-flag",
				label: (
					<div
						data-cy="mass-apply-flag"
						onClick={() =>
							massFlagPayout(
								selectedRowKeys.map((key) => key.split("-")[0]),
								true,
								this.load
							)
						}
					>
						Flag
					</div>
				)
			},
			{
				key: "mass-apply-unflag",
				label: (
					<div
						data-cy="mass-apply-unflag"
						onClick={() =>
							massFlagPayout(
								selectedRowKeys.map((key) => key.split("-")[0]),
								false,
								this.load
							)
						}
					>
						Unflag
					</div>
				)
			},
			{
				key: "resend-emails",
				label: <div onClick={() => this.setState({ emailModalOpen: true, massEmail: true })}>Resend emails</div>
			},
			{
				key: "mark-complete",
				label: <div onClick={() => markPaymentsAsComplete(selectedRowKeys)}>Mark as complete</div>
			}
		];

		const getMenu = (payout) => {
			if (!payout) return [];
			const newMenu = [
				{
					key: "view",
					label: (
						<Link
							to={`/console/my-events/${payout.eventId}/payments/payouts`}
							data-cy={`view-${payout.payoutDates._id}`}
						>
							View
						</Link>
					)
				},
				{
					key: `removeStatus-${payout.payoutDates._id}`,
					label: (
						<div
							data-cy={`removeStatus-${payout.payoutDates._id}`}
							onClick={() => changePayoutStatus(payout.payoutDates.eventDateId, null, this.load)}
						>
							{payout.payoutDates.status === "paused" ? "Un-pause" : "Un-stale"}
						</div>
					)
				},
				payout.payoutDates.status !== "stale"
					? {
							key: `stale-${payout.payoutDates._id}`,
							label: (
								<div
									data-cy={`stale-${payout.payoutDates._id}`}
									onClick={() => changePayoutStatus(payout.payoutDates.eventDateId, "stale", this.load)}
								>
									Stale
								</div>
							)
					  }
					: null,
				payout.payoutDates.status !== "paused"
					? {
							key: `pause-${payout.payoutDates._id}`,
							label: (
								<div
									data-cy={`pause-${payout.payoutDates._id}`}
									onClick={() => changePayoutStatus(payout.payoutDates.eventDateId, "paused", this.load)}
								>
									Paused
								</div>
							)
					  }
					: null,
				{
					key: `flag-${payout.payoutDates._id}`,
					label: (
						<div onClick={() => flagPayout(payout._id, this.load)} data-cy={`flag-${payout.payoutDates._id}`}>
							{payout.flagged ? "Un-Flag" : "Flag"}
						</div>
					)
				},
				{
					key: `openModal-${payout.payoutDates._id}`,
					label: (
						<div
							onClick={() =>
								this.setState({
									emailModalOpen: true,
									payout,
									massEmail: false
								})
							}
							data-cy={`openModal-${payout.payoutDates._id}`}
						>
							Resend emails
						</div>
					)
				},
				{
					key: `markComplete-${payout.payoutDates._id}`,
					label: (
						<div onClick={() => markPaymentsAsComplete([`${payout._id}-${payout.payoutDates.eventDateId}`])}>
							Mark as complete
						</div>
					)
				}
			];
			return newMenu.filter((item) => !!item);
		};

		const columns = [
			{
				title: "Status",
				dataIndex: "",
				key: "status",
				render: (value) => {
					let status;
					const isFraudulent =
						value && value.fraudChecks ? Object.values(value.fraudChecks).some((check) => check === true) : true;
					const fraudMessage =
						value && value.fraudChecks
							? Object.keys(value.fraudChecks).reduce((message, key) => {
									if (value.fraudChecks[key] === true) {
										return message + StringUtils.reverseCamelize(key) + "\n";
									}
									return message;
							  }, "")
							: "Event doesnt have fraud check";
					switch (value.payoutDates.status || false) {
						case "stale":
							status = (
								<Tooltip title="Event is stale">
									<img src={icStale} data-cy={`ic-stale-${value.payoutDates._id}`} alt="stale icon" />
								</Tooltip>
							);
							break;
						case "paused":
							status = (
								<Tooltip title="Event is paused">
									<img src={icPaused} data-cy={`ic-paused-${value.payoutDates._id}`} alt="paused icon" />
								</Tooltip>
							);
							break;
						default:
					}
					let flagged = value.flagged ? (
						<Tooltip title="Flagged event">
							<img src={icFlagged} data-cy={`ic-flagged-${value.payoutDates._id}`} alt="flagged icon" />
						</Tooltip>
					) : null;
					return (
						<span style={{ display: "flex" }}>
							<Tooltip
								title={value.user.verified && !value.user.fraudulentUser ? "User is passing" : "User is failing"}
							>
								<img
									alt={value.user.verified && !value.user.fraudulentUser ? "User verified" : "User fail"}
									src={value.user.verified && !value.user.fraudulentUser ? icUserPass : icUserFail}
								/>
							</Tooltip>
							<Tooltip title={isFraudulent ? `Event fraud is failing : ${fraudMessage}` : "Event is not fraudulent"}>
								<img alt={isFraudulent ? "Fraud Fail" : "Fraud Pass"} src={isFraudulent ? icFraudFail : icFraudPass} />
							</Tooltip>
							{status}
							{flagged}
						</span>
					);
				}
			},
			{
				title: "Event",
				dataIndex: ["event", "name"],
				key: "eventName",
				width: 120,
				render: (value, record, index) => {
					return (
						<Tooltip title={value}>
							<Link
								to={`/console/my-events/${record.event._id}/payments/payouts`}
								className={eventNameStyling}
								id={`eventName-${index}`}
							>
								{value}
							</Link>
						</Tooltip>
					);
				}
			},
			{
				title: "End date",
				dataIndex: "payoutDates",
				key: "endDate",
				width: 120,
				render: (value, payout) => {
					return new HumaniDate(
						value.endDate,
						payout?.event?.timezone || "guess",
						LocationService.getLocation(true)
					).formatting.dateTime();
				}
			},
			{
				title: "Maturity date",
				dataIndex: "payoutDates",
				key: "maturityDate",
				width: 120,
				render: (value) =>
					new HumaniDate(value.payoutMaturityDate, "guess", LocationService.getLocation(true)).formatting.dateTime()
			},
			{
				title: "Paid/Payable",
				dataIndex: "",
				key: "p/p",
				render: (value) => {
					return `${currency(value.paid)}/${currency(value.payable)}`;
				}
			},
			{
				title: "Remaining",
				dataIndex: "remaining",
				key: "remaining",
				render: (value) => {
					return currency(value);
				}
			},
			{
				title: "Type",
				dataIndex: "autoPayoutEnabled",
				key: "autoPayoutEnabled",
				render: (autoPayoutEnabled) => {
					if (autoPayoutEnabled) {
						return "Auto";
					}
					return "Manual";
				}
			},
			{
				title: "Bank account",
				dataIndex: ["event", "paymentOptions", "userBankAccountId"],
				key: "bankAccount",
				render: (value) => <img src={value ? icCheck : icCross} alt={value ? "Has bank account" : "No bank account"} />
			},
			...(isUS
				? [
						{
							title: "Tax info",

							key: "hasProvidedUsTaxInfo",
							render: (record) => {
								const hasTaxInfo = record?.user?.hasProvidedUsTaxInfo;
								return <img src={hasTaxInfo ? icCheck : icCross} alt={hasTaxInfo ? "Has tax info" : "No tax info"} />;
							}
						}
				  ]
				: []),
			{
				title: "Notes",
				dataIndex: "notes",
				key: "notes",
				width: 66,
				render: (value, record) => {
					const validNotes = value && value.length ? value.filter((note) => !note.deleted) : [];
					return validNotes && validNotes.length ? (
						<button onClick={() => this.setState({ payout: record, notesModalOpen: true })} className={readButton}>
							Read
						</button>
					) : (
						"-"
					);
				}
			},
			{
				title: "Actions",
				dataIndex: "",
				key: "actions",
				fixed: "right",
				render: (value) => {
					return (
						<TableActions>
							<Dropdown menu={{ items: getMenu(value) }} trigger={["click"]}>
								<IconButton icon="see_more" ariaLabel="actions" dataCy={`actions-${value.payoutDates._id}`} />
							</Dropdown>
						</TableActions>
					);
				}
			}
		];
		const rowSelection = {
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: (value, b) => this.onSelectChange(value, b)
		};
		return (
			<>
				<PageHeading title="Payouts">
					<LocationSelection componentName="payouts-search" defaultLevel="user" onLocationChange={this.load} />
				</PageHeading>
				<ContentBlock>
					<Modal
						title="Resend Emails"
						open={!!(emailModalOpen && (payout || massEmail))}
						onCancel={() =>
							this.setState({
								payout: false,
								emailModalOpen: false,
								sendBankAccountReminder: false,
								sendUserVerify: false,
								massEmail: false
							})
						}
						onOk={() => (massEmail ? this.bulkEmailActions() : this.sendPayoutEmail())}
					>
						<div>
							<Checkbox onChange={(e) => this.emailModalOptions("sendBankAccountReminder", e.target.checked)}>
								Missing bank details
							</Checkbox>
						</div>
						<div>
							<Checkbox onChange={(e) => this.emailModalOptions("sendUserVerify", e.target.checked)}>
								Need to verify user
							</Checkbox>
						</div>
					</Modal>
					<Modal
						title="Notes"
						open={!!(notesModalOpen && payout && payout.notes.length)}
						onCancel={() => this.setState({ payout: false, notesModalOpen: false })}
						footer={
							<div className={modalFooter}>
								<LegacyButton ariaLabel="close" onClick={() => this.setState({ payout: false })}>
									Close
								</LegacyButton>
								<Link to={`/console/my-events/${payout.eventId}/payments/payouts`}>
									<LegacyButton ariaLabel="see notes" type="secondary">
										See notes
									</LegacyButton>
								</Link>
							</div>
						}
					>
						{payout &&
							payout.notes &&
							payout.notes.length &&
							payout.notes
								.filter((note) => !note.deleted)
								.map((note) => {
									return (
										<div className={noteWrapper}>
											<div>
												<P>
													<b>Author: </b>
													{note.userName}
												</P>
												<P>
													<b>Date: </b>
													{new HumaniDate(note.createdAt, "guess", LocationService.getLocation(true)).formatting.date(
														DateFormats.Normal
													)}
												</P>
											</div>
											<P>
												<b>Note</b>
											</P>
											{note.note}
										</div>
									);
								})}
					</Modal>
					<div
						style={{
							marginBottom: 12,
							display: "flex",
							justifyContent: "space-between"
						}}
					>
						<SearchRefinements
							load={() => this.load()}
							dropdownId="payoutFilters"
							openDirection="left"
							filterOptions={this.filterOptions}
							defaultSearch={this.defaultSearch}
							hideSearch
							showRefinements
						/>
						<div style={{ display: "flex" }}>
							<LegacyButton
								style={{ height: 25 }}
								ariaLabel="Export"
								icon={{ name: "export", left: true }}
								type="import"
								onClick={this.csvDownload}
							>
								Export
							</LegacyButton>
							{selectedRowKeys.length ? (
								<Dropdown
									trigger={["click"]}
									menu={{
										items: massActionMenu
									}}
								>
									<IconButton
										icon="see_more"
										ariaLabel="actions"
										type="background"
										dataCy="mass-action"
										style={{ marginLeft: 12 }}
									/>
								</Dropdown>
							) : null}
						</div>
					</div>
					<LoadErrorView loading={payouts.loading} retryAction={this.load}>
						<div style={{ minHeight: 600 }}>
							<Table
								columns={columns}
								dataSource={payouts.payouts}
								rowKey={(o) => `${o._id}-${o.payoutDates.eventDateId}`}
								pagination={{
									showSizeChanger: false,
									total: payouts.count,
									current: search.page,
									pageSize: 20,
									onChange: this.loadPage
								}}
								rowSelection={rowSelection}
								scroll={{ x: 1200 }}
							/>
						</div>
					</LoadErrorView>
				</ContentBlock>
			</>
		);
	}
}

export default connect(
	(state) => ({
		payouts: state.payments,
		search: state.search
	}),
	{
		searchPayouts,
		updateSearch,
		changePayoutStatus,
		flagPayout,
		massFlagPayout,
		massChangePayoutStatus,
		getPayoutCsvReport,
		sendPayoutReminderEmails,
		massSendPayoutEmails,
		markPaymentsAsComplete
	}
)(Payouts);
