import { ContentBlock } from "@/components/AppBlocks";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import styled from "@emotion/styled";
import { Alert, Dropdown } from "@/ui/antd";
import { FC } from "react";
import { useNavigate } from "react-router";

const FullWithText = styled.p`
	max-width: unset;
`;

const ColumnLayout = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const ScheduledReportsHeaderComponent: FC = () => {
	const navigate = useNavigate();

	return (
		<ContentBlock>
			<ColumnLayout>
				<Alert
					type="info"
					showIcon
					message=""
					description={
						<div>
							The Event sales update is currently the only report that can be scheduled to be sent via email.
							<br />
							All other reports must be downloaded from the console.
						</div>
					}
				/>
				<div>
					<FullWithText>
						Schedule a report of live event data that can be emailed to a list of recipients. <br></br>
						Past events (event or event date that has concluded more than 2 days ago) will not be included.
					</FullWithText>

					<Dropdown
						trigger={["click"]}
						menu={{
							items: [{ key: "event-sales-update", label: "Event sales update" }],
							onClick: () => navigate("/console/reports/scheduled/new")
						}}
					>
						<LegacyButton type="action" icon={{ right: true, name: "down" }} ariaLabel="Create scheduled report">
							Create scheduled report
						</LegacyButton>
					</Dropdown>
				</div>
			</ColumnLayout>
		</ContentBlock>
	);
};

const ScheduledReportsHeader = ScheduledReportsHeaderComponent;

export { ScheduledReportsHeader };
