import store from "@/state/store";
import { MenuItem } from "@/services/menuService/MenuService";

const adminOnlyMenu: MenuItem[] = [
	{
		label: "Users",
		path: "/console/admin/users",
		key: "admin-users"
	}
];

export const userMenu = (): MenuItem[] => {
	const { auth } = store.getState();
	const isAdmin = auth?.user?.isAdmin;

	return isAdmin ? adminOnlyMenu : [];
};
