import { css } from "@emotion/css";
import { Component } from "react";
import { OpenHelpHandler, OpenHelpUrlHandler } from "@/utils/Help";
import { InfoCircleOutlined } from "@ant-design/icons";

class HelpButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mobile: false
		};
	}

	componentDidMount() {
		const isBrowser = typeof window !== "undefined";
		if (isBrowser) {
			window.addEventListener("resize", this.handleResize, { passive: true });
		}
		this.handleResize();
	}

	componentWillUnmount() {
		const isBrowser = typeof window !== "undefined";
		if (isBrowser) {
			window.removeEventListener("resize", this.handleResize);
		}
	}

	handleResize = () => {
		const { mobile } = this.state;
		if (window.innerWidth <= 600 && !mobile) {
			this.setState({ mobile: true });
		}
		if (window.innerWidth > 600 && mobile) {
			this.setState({ mobile: false });
		}
	};

	render() {
		const { buttonText, helpLink, styles, stylesMobile } = this.props;
		const { mobile } = this.state;
		let buttonStyle = css({
			alignItems: "center",
			backgroundColor: "transparent",
			border: "none",
			borderRadius: 4,
			color: "#353337",
			cursor: "pointer",
			display: "flex",
			fontSize: 14,
			fontWeight: 600,
			gap: 8,
			height: 24,
			position: "relative",
			textDecoration: "none",
			"path[fill]": {
				fill: "#353337"
			},
			"path[stroke]": {
				stroke: "#353337"
			},
			"&:hover": {
				backgroundColor: "#fafafa",
				color: "#FFA680",
				cursor: "pointer",
				"path[fill]": {
					fill: "#FFA680"
				},
				"path[stroke]": {
					stroke: "#FFA680"
				}
			},
			"@media(max-width: 600px)": {
				width: 24,
				height: 24,
				...stylesMobile
			},
			...styles
		});

		const onClick = isNaN(helpLink) ? OpenHelpUrlHandler(helpLink) : OpenHelpHandler(helpLink);
		return (
			<button type="button" onClick={onClick} className={buttonStyle} aria-label={buttonText}>
				<InfoCircleOutlined />
				{!mobile ? buttonText : ""}
			</button>
		);
	}
}

export default HelpButton;
