import { Component } from "react";
import bow from "../assets/bow.svg";
import { getImageUrl } from "../services/ImageService";
const cornerPercentWidth = "26%";
const cornerPercentHeight = "43%";
export default class GiftCard extends Component {
	constructor(props) {
		super(props);
		this.state = { width: 200 };
	}

	componentDidMount() {
		const { width } = this.parent.getBoundingClientRect();
		this.setState({ width });
	}

	render() {
		const { backgroundColor, name, cardNumber, expiryDate, amount, image } = this.props;
		const { width } = this.state;
		const fontSize = width / 10;
		const hasImage = image && image.handle;
		return (
			<div
				ref={(ref) => (this.parent = ref)}
				style={{
					width: "100%",
					height: "100%",
					borderRadius: 4,
					backgroundColor,
					position: "relative",
					fontSize
				}}
			>
				<div
					style={{
						backgroundImage: `url(${bow})`,
						position: "absolute",
						width: cornerPercentWidth,
						height: cornerPercentHeight,
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat"
					}}
				/>
				<div
					style={{
						fontSize: "1em",
						fontWeight: 600,
						color: "white",
						position: "absolute",
						right: 8,
						top: 8
					}}
				>{`$ ${amount}`}</div>
				<div style={{ width: "100%", height: "100%" }}>
					<div
						style={{
							position: "absolute",
							width: "55%",
							height: "35%",
							overflow: "auto",
							margin: "auto",
							alignItems: "center",
							textAlign: "center",
							verticalAlign: "middle",
							top: 0,
							left: 0,
							bottom: 0,
							right: 0
						}}
					>
						<div
							style={{
								display: "flex",
								marginBottom: "0.2em",
								alignItems: "center",
								textAlign: "center",
								justifyContent: "center"
							}}
						>
							{hasImage ? (
								<div style={{ height: fontSize, marginRight: 2, display: "flex" }}>
									<img
										alt="Host logo"
										style={{
											width: fontSize,
											height: fontSize,
											borderRadius: 4,
											verticalAlign: "super"
										}}
										src={getImageUrl(image.handle, 30, 30)}
									/>
								</div>
							) : null}
							<div
								style={{
									fontSize: "0.65em",
									fontWeight: 600,
									color: "white",
									display: "flex"
								}}
							>{`${name}`}</div>
						</div>
						<div
							style={{
								borderRadius: 3,
								backgroundColor: "white",
								padding: "2px 6px 2px 6px",
								fontSize: "0.4em",
								width: "auto"
							}}
						>
							{`Card number: ${cardNumber}`}
						</div>
					</div>
				</div>
				<div
					style={{
						position: "absolute",
						bottom: 6,
						textAlign: "center",
						width: "100%",
						fontSize: "0.35em",
						fontWeight: 600,
						color: "white"
					}}
				>
					{`Expires on: ${expiryDate}`}
				</div>
			</div>
		);
	}
}
