import { css } from "@emotion/css";
// import { Icon, notification } from "@/ui/antd";
import { notification } from "@/ui/antd";
import { fsClient, getFileUrl } from "../services/ImageService";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "./text/Text";

const PdfUpload = (props) => {
	const { value, uploadLabel = "Upload text", uploadOutsideDescription = "", saveHandleOnly = false, onChange } = props;
	const url = value !== "file" ? getFileUrl(value, 800) : null;
	const accept = [".pdf"];
	const uploadPdf = () => {
		const options = {
			fromSources: ["local_file_system", "url", "facebook", "instagram", "googledrive", "dropbox"],
			maxSize: 102400000,
			maxFiles: 1,
			minFiles: 1,
			uploadInBackground: false,
			accept,
			onUploadDone: pdfUpload
		};
		fsClient.picker(options).open();
	};
	const pdfUpload = ({ filesUploaded, filesFailed }) => {
		if (filesFailed.length) {
			notification.error({ message: "Image failed to upload" });
			return;
		}
		const uploadedPdf = filesUploaded[0];
		const pdf = saveHandleOnly
			? uploadedPdf.handle
			: {
					name: uploadedPdf.filename,
					size: uploadedPdf.size,
					url: uploadedPdf.url,
					source: uploadedPdf.source,
					handle: uploadedPdf.handle
			  };
		// url = uploadedPdf.url;
		if (onChange) {
			onChange(pdf);
		}
	};

	const hint = css`
		font-size: 14px;
		color: #323232;
		margin-top: 15px;
	`;
	return (
		<div>
			{url && value && (
				<div>
					<a href={url} target="_blank" rel="noopener noreferrer">
						{/* <Icon type="file-pdf" theme="twoTone" style={{ fontSize: 20, padding: "10px 0px" }} /> View */}
					</a>
				</div>
			)}
			<LegacyButton type="action" onClick={uploadPdf} ariaLabel={uploadLabel}>
				{uploadLabel}
			</LegacyButton>
			<P className={hint}>{uploadOutsideDescription}</P>
		</div>
	);
};

export default PdfUpload;
