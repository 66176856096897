import { Field } from "redux-form";
import { getTicketDisplayName } from "../../utils/Ticket";
import SelectField from "./SelectField";
import SwitchField from "./SwitchField";

const CustomQrDataField = (props) => {
	const { path, currentEvent = {}, enableCustomQrData } = props;
	const ticketTypes = currentEvent.event && currentEvent.event.ticketTypes ? currentEvent.event.ticketTypes : [];
	const ticketOptions = [{ value: "all", label: "All Ticket Types" }].concat(
		ticketTypes
			.filter((t) => !t.isDonation && !t.deleted)
			.map((ticket) => {
				return { value: ticket._id, label: getTicketDisplayName(ticket) };
			})
	);
	if (!currentEvent || !currentEvent.event) {
		return null;
	}
	return (
		<div>
			<Field
				name={`${path}.associateCustomQrData.enabled`}
				label="Enable custom qr code association"
				description="Associate custom qr code on scanned ticket. Allows for attendee lookup on custom qr code scan"
				component={SwitchField}
				tooltip={`If enabled the selected ticket types will require a custom qr code to be associated on check-in on the app. All custom qr codes must be prefixed with "HTIX:/"`}
			/>
			{enableCustomQrData && (
				<Field
					mode="multiple"
					name={`${path}.associateCustomQrData.appliesTo`}
					label="Applies to"
					placeholder="Please Select"
					component={SelectField}
					options={ticketOptions}
					style={{ width: "100%" }}
					required
				/>
			)}
		</div>
	);
};

export default CustomQrDataField;
