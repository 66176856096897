{
	"US_TAX_OPTIONS": [
		{ "label": "Normal ticket", "value": "19003", "key": -1 },
		{ "label": "Accounting Services (87210200A0000)", "value": "87210200A0000", "key": 743 },
		{ "label": "Acne Treatments (51241400A0001)", "value": "51241400A0001", "key": 239 },
		{ "label": "Admission Services (19003)", "value": "19003", "key": 26 },
		{ "label": "Advertising Services (19001)", "value": "19001", "key": 23 },
		{
			"label": "Agricultural Chemicals - Non-commercial gardening (10191500A0001)",
			"value": "10191500A0001",
			"key": 118
		},
		{
			"label": "Agricultural Fertilizer - Non-commercial gardening (10171800A0001)",
			"value": "10171800A0001",
			"key": 365
		},
		{ "label": "Air conditioners - Energy Star (40101701A0000)", "value": "40101701A0000", "key": 142 },
		{ "label": "Aircraft Repair (78181800A0000)", "value": "78181800A0000", "key": 702 },
		{ "label": "Alkaline Batteries (26111702A0000)", "value": "26111702A0000", "key": 497 },
		{
			"label": "Ammunition - designed for firearms other than small arms. (46101600A0002)",
			"value": "46101600A0002",
			"key": 785
		},
		{ "label": "Ammunition (46101600A0001)", "value": "46101600A0001", "key": 159 },
		{ "label": "Appliance Repair (52143609A0000)", "value": "52143609A0000", "key": 759 },
		{ "label": "Application implementation services\r\n (81111508A0000)", "value": "81111508A0000", "key": 474 },
		{ "label": "Application programming services (81111504A0000)", "value": "81111504A0000", "key": 843 },
		{ "label": "Archery Accessories (49181602A0002)", "value": "49181602A0002", "key": 682 },
		{ "label": "Archery Cases (46101801A0001)", "value": "46101801A0001", "key": 622 },
		{ "label": "Archery Equipment (49181602A0001)", "value": "49181602A0001", "key": 153 },
		{ "label": "Architectural Services (87120000A0000)", "value": "87120000A0000", "key": 727 },
		{ "label": "Armored Car Services (73810101A0000)", "value": "73810101A0000", "key": 719 },
		{ "label": "Artificial Ice (24121512A0000)", "value": "24121512A0000", "key": 584 },
		{
			"label": "Assembly - in conjunction with final purchase of article (93121706A0000)",
			"value": "93121706A0000",
			"key": 623
		},
		{ "label": "Assembly - prior to final purchase of article (93121706A0001)", "value": "93121706A0001", "key": 701 },
		{ "label": "Audio Turntables (52161548A0000)", "value": "52161548A0000", "key": 176 },
		{ "label": "Axes/Hatchets (27112005A0000)", "value": "27112005A0000", "key": 202 },
		{ "label": "Baby Feeding Bottles/Nipples (42311808A0000)", "value": "42311808A0000", "key": 689 },
		{ "label": "Baby Oil (51241900A0001)", "value": "51241900A0001", "key": 492 },
		{ "label": "Baby Powder (53131649A0001)", "value": "53131649A0001", "key": 527 },
		{ "label": "Baby Wipes/Cleansing Wipes (53131624A0000)", "value": "53131624A0000", "key": 73 },
		{ "label": "Bath Mats/rugs (52101507A0000)", "value": "52101507A0000", "key": 114 },
		{ "label": "Bath towels (52121700A0000)", "value": "52121700A0000", "key": 150 },
		{ "label": "Bathroom Faucets - WaterSense (30181702A0001)", "value": "30181702A0001", "key": 541 },
		{ "label": "Bedding (52121500A0000)", "value": "52121500A0000", "key": 151 },
		{ "label": "Bibles (81121)", "value": "81121", "key": 758 },
		{ "label": "Bicycle Helmets - Adult (46181704A0003)", "value": "46181704A0003", "key": 160 },
		{ "label": "Bicycle Helmets - Youth (46181704A0002)", "value": "46181704A0002", "key": 77 },
		{
			"label": "Birth Control - Over-the-Counter Oral Contraceptives (51350000A0001)",
			"value": "51350000A0001",
			"key": 694
		},
		{
			"label": "Birth Control - Over-the-Counter Oral Contraceptives with Prescription (51350000A0002)",
			"value": "51350000A0002",
			"key": 692
		},
		{
			"label": "Birth Control - Prescription non-Oral Contraceptives - Barriers (42143103A0000)",
			"value": "42143103A0000",
			"key": 534
		},
		{
			"label": "Birth Control - Prescription non-Oral Contraceptives - Hormonal (51350000A0003)",
			"value": "51350000A0003",
			"key": 735
		},
		{
			"label": "Birth Control - Prescription Oral Contraceptives (51350000A0000)",
			"value": "51350000A0000",
			"key": 693
		},
		{
			"label": "Blood Glucose Monitoring Devices with Prescription (41116201A0000)",
			"value": "41116201A0000",
			"key": 276
		},
		{
			"label": "Blood Glucose Monitoring Devices without Prescription (41116201A0001)",
			"value": "41116201A0001",
			"key": 275
		},
		{ "label": "Blood Pressure Testing Devices (42181600A0001)", "value": "42181600A0001", "key": 61 },
		{ "label": "Body Piercing (72990190A0000)", "value": "72990190A0000", "key": 746 },
		{ "label": "Boilers - Energy Star (40102004A0001)", "value": "40102004A0001", "key": 137 },
		{ "label": "Bookbags/Backpacks - Student (53121603A0001)", "value": "53121603A0001", "key": 89 },
		{ "label": "Books (81100)", "value": "81100", "key": 6 },
		{
			"label": "Bottled coffee - containing milk or milk substitute (50201708A0002)",
			"value": "50201708A0002",
			"key": 658
		},
		{ "label": "Bottled coffee - no milk - sweetened (50201708A0001)", "value": "50201708A0001", "key": 657 },
		{ "label": "Bottled coffee - no milk - unsweetened (50201708A0000)", "value": "50201708A0000", "key": 656 },
		{ "label": "Bottled tea - carbonated  - sweetened (50201712A0002)", "value": "50201712A0002", "key": 659 },
		{ "label": "Bottled tea - carbonated - unsweetened (50201712A0001)", "value": "50201712A0001", "key": 260 },
		{ "label": "Bottled tea - non-carbonated  - sweetened (50201712A0003)", "value": "50201712A0003", "key": 661 },
		{ "label": "Bottled tea - non-carbonated - unsweetened (50201712A0000)", "value": "50201712A0000", "key": 660 },
		{ "label": "Bottled Water - Carbonated - Sweetened (50202301A0004)", "value": "50202301A0004", "key": 779 },
		{ "label": "Bottled Water - Carbonated Artificially (50202301A0002)", "value": "50202301A0002", "key": 205 },
		{ "label": "Bottled Water - Carbonated Naturally (50202301A0003)", "value": "50202301A0003", "key": 62 },
		{ "label": "Bottled Water - Sweetened (50202301A0001)", "value": "50202301A0001", "key": 211 },
		{ "label": "Bottled Water (40060)", "value": "40060", "key": 13 },
		{ "label": "Bottled Water (50202301A0000)", "value": "50202301A0000", "key": 179 },
		{ "label": "Breast Pumps (42231901A0000)", "value": "42231901A0000", "key": 193 },
		{ "label": "Breath Spray/dissolvable strips (53131509A0000)", "value": "53131509A0000", "key": 196 },
		{ "label": "Briefcases (53121701A0000)", "value": "53121701A0000", "key": 53 },
		{ "label": "Bubble Bath, Bath Salts/Oils/Crystals (53131612A0001)", "value": "53131612A0001", "key": 247 },
		{ "label": "Building Management Services (73490000A0000)", "value": "73490000A0000", "key": 716 },
		{ "label": "Calculators (44101807A0000)", "value": "44101807A0000", "key": 238 },
		{ "label": "Can opener - manual (52151605A0001)", "value": "52151605A0001", "key": 522 },
		{ "label": "Candles (39112604A0001)", "value": "39112604A0001", "key": 124 },
		{ "label": "Candy (40010)", "value": "40010", "key": 11 },
		{ "label": "Car Washing (81119200A0000)", "value": "81119200A0000", "key": 691 },
		{ "label": "Carbon Monoxide Detectors (46191509A0001)", "value": "46191509A0001", "key": 126 },
		{ "label": "Cash donation (14111803A0002)", "value": "14111803A0002", "key": 364 },
		{ "label": "Ceiling fans - Energy Star (40101609A0000)", "value": "40101609A0000", "key": 146 },
		{ "label": "Chainsaw accessories (27112038A0001)", "value": "27112038A0001", "key": 119 },
		{ "label": "Childcare Services / Adultcare (83510000A0000)", "value": "83510000A0000", "key": 725 },
		{ "label": "Children's Books (35010001)", "value": "35010001", "key": 825 },
		{ "label": "Cholesterol Testing Kits (41116202A0001)", "value": "41116202A0001", "key": 210 },
		{ "label": "Cleaning of Tangible Personal Property (76110000A1000)", "value": "76110000A1000", "key": 778 },
		{ "label": "Cleaning/Janitorial Services - Commercial (76111501A0000)", "value": "76111501A0000", "key": 794 },
		{ "label": "Cleaning/Janitorial Services - Residential (76111501A0001)", "value": "76111501A0001", "key": 837 },
		{ "label": "Client or server programming services (81111506A0000)", "value": "81111506A0000", "key": 842 },
		{ "label": "Cloth Face Masks - Reusable (42131713A0002)", "value": "42131713A0002", "key": 708 },
		{ "label": "Clothes drying machine - Energy Star (52141602A0000)", "value": "52141602A0000", "key": 37 },
		{ "label": "Clothes Washing Machine - Energy Star (52141601A0000)", "value": "52141601A0000", "key": 111 },
		{ "label": "Clothing - Adult diapers (53102306A0000)", "value": "53102306A0000", "key": 314 },
		{ "label": "Clothing - Ambulance officers uniforms (53102709A0000)", "value": "53102709A0000", "key": 573 },
		{ "label": "Clothing - Anti cut gloves (46181536A0001)", "value": "46181536A0001", "key": 583 },
		{ "label": "Clothing - Anti vibratory gloves (46181539A0001)", "value": "46181539A0001", "key": 552 },
		{ "label": "Clothing - Aprons - Household/Kitchen (46181501A0002)", "value": "46181501A0002", "key": 175 },
		{ "label": "Clothing - Armbands (53102508A0000)", "value": "53102508A0000", "key": 476 },
		{ "label": "Clothing - Athletic footwear (53111900A0000)", "value": "53111900A0000", "key": 417 },
		{ "label": "Clothing - Athletic supporter (49161517A0001)", "value": "49161517A0001", "key": 430 },
		{ "label": "Clothing - Athletic wear (53102900A0000)", "value": "53102900A0000", "key": 510 },
		{ "label": "Clothing - Ballet or tap shoes (53111900A0001)", "value": "53111900A0001", "key": 388 },
		{ "label": "Clothing - Bandannas (53102511A0000)", "value": "53102511A0000", "key": 477 },
		{ "label": "Clothing - Baseball batting gloves (49211606A0001)", "value": "49211606A0001", "key": 368 },
		{ "label": "Clothing - Bath robes (53102606A0000)", "value": "53102606A0000", "key": 406 },
		{ "label": "Clothing - Belt Buckle (53102501A0001)", "value": "53102501A0001", "key": 304 },
		{ "label": "Clothing - Belts or suspenders (53102501A0000)", "value": "53102501A0000", "key": 454 },
		{ "label": "Clothing - Bib (53102521A0000)", "value": "53102521A0000", "key": 405 },
		{ "label": "Clothing - Body shaping garments (53102307A0000)", "value": "53102307A0000", "key": 343 },
		{ "label": "Clothing - Boots (53111500A0000)", "value": "53111500A0000", "key": 415 },
		{ "label": "Clothing - Bowling gloves (49211606A0002)", "value": "49211606A0002", "key": 369 },
		{ "label": "Clothing - Bowling shoes (53111900A0003)", "value": "53111900A0003", "key": 391 },
		{ "label": "Clothing - Boxing gloves (49171600A0000)", "value": "49171600A0000", "key": 393 },
		{ "label": "Clothing - Brassieres (53102304A0000)", "value": "53102304A0000", "key": 383 },
		{ "label": "Clothing - Bridal Gown (53101801A0004)", "value": "53101801A0004", "key": 426 },
		{ "label": "Clothing - Bullet proof vests (46181502A0001)", "value": "46181502A0001", "key": 577 },
		{ "label": "Clothing - Button covers (53102515A0000)", "value": "53102515A0000", "key": 665 },
		{ "label": "Clothing - Buttons (53141505A0000)", "value": "53141505A0000", "key": 367 },
		{ "label": "Clothing - Caps (53102516A0000)", "value": "53102516A0000", "key": 162 },
		{ "label": "Clothing - Chemical resistant gloves (46181541A0001)", "value": "46181541A0001", "key": 553 },
		{ "label": "Clothing - Chevrons (53102518A0000)", "value": "53102518A0000", "key": 408 },
		{ "label": "Clothing - Clasps (53141507A0000)", "value": "53141507A0000", "key": 395 },
		{ "label": "Clothing - Cleanroom apparel (46181512A0001)", "value": "46181512A0001", "key": 581 },
		{ "label": "Clothing - Cleanroom footwear (46181603A0001)", "value": "46181603A0001", "key": 673 },
		{ "label": "Clothing - Cleated or spiked shoes (53111900A0002)", "value": "53111900A0002", "key": 429 },
		{ "label": "Clothing - Cloth Diapers (53102305A0001)", "value": "53102305A0001", "key": 158 },
		{ "label": "Clothing - Coats or jackets (53101800A0000)", "value": "53101800A0000", "key": 412 },
		{ "label": "Clothing - Cooling vest (46181554A0001)", "value": "46181554A0001", "key": 562 },
		{ "label": "Clothing - Corporate uniforms (53102710A0000)", "value": "53102710A0000", "key": 410 },
		{ "label": "Clothing - Costume (60141401A0000)", "value": "60141401A0000", "key": 319 },
		{ "label": "Clothing - Costume Mask (60122800A0000)", "value": "60122800A0000", "key": 352 },
		{ "label": "Clothing - Customs uniforms (53102702A0000)", "value": "53102702A0000", "key": 478 },
		{ "label": "Clothing - Diaper liners (53102308A0000)", "value": "53102308A0000", "key": 403 },
		{ "label": "Clothing - Disposable work coat (53103201A0000)", "value": "53103201A0000", "key": 407 },
		{ "label": "Clothing - Disposable youth training pants (53102311A0000)", "value": "53102311A0000", "key": 445 },
		{ "label": "Clothing - Doctors coat (53102707A0000)", "value": "53102707A0000", "key": 572 },
		{
			"label": "Clothing - Dresses or skirts or saris or kimonos (53102000A0000)",
			"value": "53102000A0000",
			"key": 507
		},
		{ "label": "Clothing - Elbow protectors (46181514A0001)", "value": "46181514A0001", "key": 513 },
		{ "label": "Clothing - Eye shield garters (46181809A0001)", "value": "46181809A0001", "key": 302 },
		{ "label": "Clothing - Eye shields (46181803A0001)", "value": "46181803A0001", "key": 565 },
		{ "label": "Clothing - Fabric dye (60105810A0000)", "value": "60105810A0000", "key": 354 },
		{ "label": "Clothing - Fabric for use in clothing (11160000A0000)", "value": "11160000A0000", "key": 353 },
		{ "label": "Clothing - Face protection kit (46181709A0001)", "value": "46181709A0001", "key": 255 },
		{ "label": "Clothing - Facial shields (46181702A0001)", "value": "46181702A0001", "key": 675 },
		{ "label": "Clothing - Facial shields parts or accessories (46181707A0001)", "value": "46181707A0001", "key": 221 },
		{ "label": "Clothing - Fire retardant apparel (46181508A0001)", "value": "46181508A0001", "key": 637 },
		{ "label": "Clothing - Fire retardant footwear (46181601A0001)", "value": "46181601A0001", "key": 672 },
		{ "label": "Clothing - Firefighter uniform (53102718A0000)", "value": "53102718A0000", "key": 462 },
		{ "label": "Clothing - Folkloric clothing (53102200A0000)", "value": "53102200A0000", "key": 509 },
		{ "label": "Clothing - Football receiver gloves (49211606A0004)", "value": "49211606A0004", "key": 291 },
		{ "label": "Clothing - Footwear covers (46181606A0001)", "value": "46181606A0001", "key": 563 },
		{ "label": "Clothing - Formal Dress (53101801A0002)", "value": "53101801A0002", "key": 346 },
		{ "label": "Clothing - Fur Coat or Jacket (53101800A0001)", "value": "53101800A0001", "key": 437 },
		{ "label": "Clothing - Fur Ear muffs or scarves (53102502A0001)", "value": "53102502A0001", "key": 650 },
		{ "label": "Clothing - Fur Gloves (53102503A0001)", "value": "53102503A0001", "key": 568 },
		{ "label": "Clothing - Fur Hat (53102504A0001)", "value": "53102504A0001", "key": 438 },
		{ "label": "Clothing - Fur Poncho or Cape (53101806A0001)", "value": "53101806A0001", "key": 472 },
		{ "label": "Clothing - Fur Vest (53103100A0001)", "value": "53103100A0001", "key": 569 },
		{ "label": "Clothing - Garters (53102509A0000)", "value": "53102509A0000", "key": 670 },
		{ "label": "Clothing - Gloves or mittens (53102504A0000)", "value": "53102504A0000", "key": 401 },
		{ "label": "Clothing - Goggle protective covers (46181808A0001)", "value": "46181808A0001", "key": 293 },
		{ "label": "Clothing - Goggles (46181804A0001)", "value": "46181804A0001", "key": 292 },
		{ "label": "Clothing - Golf gloves (49211606A0000)", "value": "49211606A0000", "key": 387 },
		{ "label": "Clothing - Golf shoes (53111900A0004)", "value": "53111900A0004", "key": 392 },
		{ "label": "Clothing - Gorgets (53102519A0000)", "value": "53102519A0000", "key": 603 },
		{ "label": "Clothing - Hair Accessories (53102500A0001)", "value": "53102500A0001", "key": 32 },
		{ "label": "Clothing - Handkerchiefs (53102512A0000)", "value": "53102512A0000", "key": 345 },
		{ "label": "Clothing - Hard hats (46181701A0001)", "value": "46181701A0001", "key": 674 },
		{ "label": "Clothing - Hats (53102503A0000)", "value": "53102503A0000", "key": 456 },
		{
			"label": "Clothing - Hazardous material protective apparel (46181509A0001)",
			"value": "46181509A0001",
			"key": 580
		},
		{
			"label": "Clothing - Hazardous material protective footwear (46181602A0001)",
			"value": "46181602A0001",
			"key": 612
		},
		{ "label": "Clothing - Headbands (53102513A0000)", "value": "53102513A0000", "key": 585 },
		{ "label": "Clothing - Heat resistant clothing (46181518A0001)", "value": "46181518A0001", "key": 582 },
		{ "label": "Clothing - Heel pads (53112003A0000)", "value": "53112003A0000", "key": 459 },
		{ "label": "Clothing - Helmet parts or accessories (46181706A0001)", "value": "46181706A0001", "key": 570 },
		{ "label": "Clothing - Hunting Vests (53103100A0003)", "value": "53103100A0003", "key": 75 },
		{ "label": "Clothing - Ice Skates (49151602A0000)", "value": "49151602A0000", "key": 371 },
		{ "label": "Clothing - Infant diapers (53102305A0000)", "value": "53102305A0000", "key": 402 },
		{
			"label": "Clothing - Infant swaddles or buntings or receiving blankets (53102608A0000)",
			"value": "53102608A0000",
			"key": 457
		},
		{
			"label": "Clothing - Institutional food preparation or service attire (53102704A0000)",
			"value": "53102704A0000",
			"key": 480
		},
		{
			"label": "Clothing - Insulated clothing for cold environments (46181529A0001)",
			"value": "46181529A0001",
			"key": 517
		},
		{ "label": "Clothing - Insulated cold weather shoe (46181610A0000)", "value": "46181610A0000", "key": 443 },
		{ "label": "Clothing - Insulated gloves (46181537A0001)", "value": "46181537A0001", "key": 539 },
		{ "label": "Clothing - Insulated or flotation suits (46181517A0001)", "value": "46181517A0001", "key": 514 },
		{ "label": "Clothing - Judicial robe (53102714A0000)", "value": "53102714A0000", "key": 504 },
		{ "label": "Clothing - Lab coats (46181532A0000)", "value": "46181532A0000", "key": 380 },
		{ "label": "Clothing - Leg protectors (46181520A0001)", "value": "46181520A0001", "key": 608 },
		{ "label": "Clothing - Life vests or preservers  (46161604A0000)", "value": "46161604A0000", "key": 370 },
		{ "label": "Clothing - Martial Arts Attire (53102717A0001)", "value": "53102717A0001", "key": 54 },
		{ "label": "Clothing - Military boot (46181612A0000)", "value": "46181612A0000", "key": 285 },
		{ "label": "Clothing - Military uniforms (53102701A0000)", "value": "53102701A0000", "key": 460 },
		{ "label": "Clothing - Motorcycle helmets (46181705A0001)", "value": "46181705A0001", "key": 567 },
		{ "label": "Clothing - Mountain climbing boot (46181613A0000)", "value": "46181613A0000", "key": 287 },
		{ "label": "Clothing - Mouth guards (42152402A0001)", "value": "42152402A0001", "key": 394 },
		{ "label": "Clothing - Neck gaitor (46181549A0001)", "value": "46181549A0001", "key": 559 },
		{ "label": "Clothing - Nurses uniforms (53102708A0000)", "value": "53102708A0000", "key": 502 },
		{ "label": "Clothing - Overalls or coveralls (53102100A0000)", "value": "53102100A0000", "key": 508 },
		{ "label": "Clothing - Overshoes (53112000A0000)", "value": "53112000A0000", "key": 418 },
		{ "label": "Clothing - Pajamas or nightshirts or robes (53102600A0000)", "value": "53102600A0000", "key": 465 },
		{ "label": "Clothing - Panty hose (53102403A0000)", "value": "53102403A0000", "key": 384 },
		{ "label": "Clothing - Paramedic uniforms (53102712A0000)", "value": "53102712A0000", "key": 574 },
		{ "label": "Clothing - Pocket protectors (53102514A0000)", "value": "53102514A0000", "key": 586 },
		{ "label": "Clothing - Police uniforms (53102703A0000)", "value": "53102703A0000", "key": 479 },
		{ "label": "Clothing - Poncho (53101806A0000)", "value": "53101806A0000", "key": 85 },
		{ "label": "Clothing - Prison officer uniform (53102715A0000)", "value": "53102715A0000", "key": 411 },
		{ "label": "Clothing - Prisoner uniform (53102716A0000)", "value": "53102716A0000", "key": 575 },
		{ "label": "Clothing - Prom Dress (53101801A0003)", "value": "53101801A0003", "key": 347 },
		{ "label": "Clothing - Protective aprons (46181501A0001)", "value": "46181501A0001", "key": 512 },
		{ "label": "Clothing - Protective clogs (46181607A0000)", "value": "46181607A0000", "key": 60 },
		{ "label": "Clothing - Protective coats (46181533A0001)", "value": "46181533A0001", "key": 518 },
		{ "label": "Clothing - Protective coveralls (46181503A0001)", "value": "46181503A0001", "key": 607 },
		{ "label": "Clothing - Protective finger cots (46181530A0001)", "value": "46181530A0001", "key": 475 },
		{ "label": "Clothing - Protective frock (46181528A0001)", "value": "46181528A0001", "key": 516 },
		{ "label": "Clothing - Protective gloves (46181504A0001)", "value": "46181504A0001", "key": 631 },
		{ "label": "Clothing - Protective hair net (46181708A0001)", "value": "46181708A0001", "key": 190 },
		{ "label": "Clothing - Protective hood (46181710A0001)", "value": "46181710A0001", "key": 161 },
		{ "label": "Clothing - Protective insole (46181609A0000)", "value": "46181609A0000", "key": 69 },
		{ "label": "Clothing - Protective knee pads (46181505A0001)", "value": "46181505A0001", "key": 578 },
		{ "label": "Clothing - Protective lens (46181811A0001)", "value": "46181811A0001", "key": 344 },
		{ "label": "Clothing - Protective mesh jacket (46181551A0001)", "value": "46181551A0001", "key": 561 },
		{ "label": "Clothing - Protective mittens (46181542A0001)", "value": "46181542A0001", "key": 554 },
		{ "label": "Clothing - Protective pants (46181527A0001)", "value": "46181527A0001", "key": 671 },
		{ "label": "Clothing - Protective ponchos (46181506A0001)", "value": "46181506A0001", "key": 632 },
		{ "label": "Clothing - Protective sandals (46181608A0000)", "value": "46181608A0000", "key": 382 },
		{ "label": "Clothing - Protective scarf (46181550A0001)", "value": "46181550A0001", "key": 560 },
		{ "label": "Clothing - Protective shirts (46181526A0001)", "value": "46181526A0001", "key": 610 },
		{ "label": "Clothing - Protective socks or hosiery (46181535A0001)", "value": "46181535A0001", "key": 538 },
		{ "label": "Clothing - Protective wristbands (46181534A0001)", "value": "46181534A0001", "key": 537 },
		{ "label": "Clothing - Reflective apparel (46181531A0001)", "value": "46181531A0001", "key": 546 },
		{ "label": "Clothing - Roller skates or roller blades (49221509A0000)", "value": "49221509A0000", "key": 372 },
		{ "label": "Clothing - Safety boots (46181604A0000)", "value": "46181604A0000", "key": 432 },
		{ "label": "Clothing - Safety glasses (46181802A0001)", "value": "46181802A0001", "key": 649 },
		{ "label": "Clothing - Safety helmets (46181704A0001)", "value": "46181704A0001", "key": 189 },
		{ "label": "Clothing - Safety hoods (46181522A0001)", "value": "46181522A0001", "key": 515 },
		{ "label": "Clothing - Safety shoes (46181605A0000)", "value": "46181605A0000", "key": 433 },
		{ "label": "Clothing - Safety sleeves (46181516A0001)", "value": "46181516A0001", "key": 638 },
		{ "label": "Clothing - Safety vests (46181507A0001)", "value": "46181507A0001", "key": 579 },
		{ "label": "Clothing - Salon smocks (53102711A0000)", "value": "53102711A0000", "key": 461 },
		{ "label": "Clothing - Sandals (53111800A0000)", "value": "53111800A0000", "key": 466 },
		{ "label": "Clothing - School uniforms (53102705A0000)", "value": "53102705A0000", "key": 501 },
		{ "label": "Clothing - Security uniforms (53102706A0000)", "value": "53102706A0000", "key": 409 },
		{ "label": "Clothing - Sequins for use in clothing (60123900A0000)", "value": "60123900A0000", "key": 123 },
		{ "label": "Clothing - Shin guards (49161525A0001)", "value": "49161525A0001", "key": 431 },
		{ "label": "Clothing - Shirts (53101600A0000)", "value": "53101600A0000", "key": 441 },
		{ "label": "Clothing - Shoe Inserts/Insoles (46182208A0000)", "value": "46182208A0000", "key": 76 },
		{ "label": "Clothing - Shoelaces (53112002A0000)", "value": "53112002A0000", "key": 458 },
		{ "label": "Clothing - Shoes (53111600A0000)", "value": "53111600A0000", "key": 511 },
		{ "label": "Clothing - Shoulder boards or epaulettes (53102520A0000)", "value": "53102520A0000", "key": 602 },
		{ "label": "Clothing - Shoulder pads for sports (46181506A0002)", "value": "46181506A0002", "key": 435 },
		{ "label": "Clothing - Shower Cap (53131601A0000)", "value": "53131601A0000", "key": 303 },
		{ "label": "Clothing - Ski boots (53111900A0005)", "value": "53111900A0005", "key": 351 },
		{ "label": "Clothing - Slacks or trousers or shorts (53101500A0000)", "value": "53101500A0000", "key": 463 },
		{ "label": "Clothing - Sleep or eye mask (53102607A0000)", "value": "53102607A0000", "key": 666 },
		{ "label": "Clothing - Slippers (53111700A0000)", "value": "53111700A0000", "key": 416 },
		{ "label": "Clothing - Slips (53102302A0000)", "value": "53102302A0000", "key": 294 },
		{ "label": "Clothing - Snaps (53141506A0000)", "value": "53141506A0000", "key": 396 },
		{ "label": "Clothing - Socks (53102402A0000)", "value": "53102402A0000", "key": 301 },
		{ "label": "Clothing - Sport uniform (53102717A0000)", "value": "53102717A0000", "key": 505 },
		{ "label": "Clothing - Stockings (53102401A0000)", "value": "53102401A0000", "key": 300 },
		{ "label": "Clothing - Suits (53101900A0000)", "value": "53101900A0000", "key": 506 },
		{ "label": "Clothing - Sweat bands (53102506A0000)", "value": "53102506A0000", "key": 571 },
		{ "label": "Clothing - Sweaters (53101700A0000)", "value": "53101700A0000", "key": 464 },
		{ "label": "Clothing - Swim Fins (4914606A0001)", "value": "4914606A0001", "key": 816 },
		{ "label": "Clothing - Swim goggles (49141606A0000)", "value": "49141606A0000", "key": 359 },
		{ "label": "Clothing - Swimwear (20041)", "value": "20041", "key": 35 },
		{ "label": "Clothing - Swimwear (53102800A0000)", "value": "53102800A0000", "key": 413 },
		{ "label": "Clothing - Synthetic Fur Coat or Jacket (53101800A0002)", "value": "53101800A0002", "key": 439 },
		{ "label": "Clothing - Synthetic Fur Ear muffs or scarves (53102502A0002)", "value": "53102502A0002", "key": 473 },
		{ "label": "Clothing - Synthetic Fur Gloves (53102503A0002)", "value": "53102503A0002", "key": 440 },
		{ "label": "Clothing - Synthetic Fur Hat (53102504A0002)", "value": "53102504A0002", "key": 601 },
		{ "label": "Clothing - Synthetic Fur Poncho or Cape (53101806A0002)", "value": "53101806A0002", "key": 308 },
		{ "label": "Clothing - Synthetic Fur Vest (53103100A0002)", "value": "53103100A0002", "key": 630 },
		{ "label": "Clothing - T-Shirts (53103000A0000)", "value": "53103000A0000", "key": 414 },
		{ "label": "Clothing - Thermal gloves (46181538A0001)", "value": "46181538A0001", "key": 551 },
		{ "label": "Clothing - Ties or scarves or mufflers (53102502A0000)", "value": "53102502A0000", "key": 455 },
		{ "label": "Clothing - Tights (53102404A0000)", "value": "53102404A0000", "key": 453 },
		{ "label": "Clothing - Tuxedo or Formalwear (53101801A0001)", "value": "53101801A0001", "key": 381 },
		{ "label": "Clothing - Umbrellas (53102505A0000)", "value": "53102505A0000", "key": 587 },
		{ "label": "Clothing - Underpants (53102303A0000)", "value": "53102303A0000", "key": 342 },
		{ "label": "Clothing - Undershirts (53102301A0000)", "value": "53102301A0000", "key": 444 },
		{ "label": "Clothing - Ushers uniforms (53102713A0000)", "value": "53102713A0000", "key": 503 },
		{ "label": "Clothing - Vest or waistcoats (53103100A0000)", "value": "53103100A0000", "key": 576 },
		{ "label": "Clothing - Waterproof boot (46181611A0000)", "value": "46181611A0000", "key": 341 },
		{ "label": "Clothing - Waterproof cap (46181546A0000)", "value": "46181546A0000", "key": 425 },
		{ "label": "Clothing - Waterproof cap cover (46181547A0001)", "value": "46181547A0001", "key": 557 },
		{ "label": "Clothing - Waterproof jacket or raincoat (46181543A0000)", "value": "46181543A0000", "key": 132 },
		{ "label": "Clothing - Waterproof suit (46181545A0001)", "value": "46181545A0001", "key": 556 },
		{ "label": "Clothing - Waterproof trousers or pants (46181544A0001)", "value": "46181544A0001", "key": 555 },
		{ "label": "Clothing - Welder bib (46181548A0001)", "value": "46181548A0001", "key": 558 },
		{ "label": "Clothing - Welder gloves (46181540A0001)", "value": "46181540A0001", "key": 611 },
		{ "label": "Clothing - Welders helmet (46181711A0001)", "value": "46181711A0001", "key": 564 },
		{ "label": "Clothing - Welding masks (46181703A0001)", "value": "46181703A0001", "key": 589 },
		{ "label": "Clothing - Wetsuit (49141506A0000)", "value": "49141506A0000", "key": 307 },
		{ "label": "Clothing - Wigs, Hairpieces, Hair extensions (53102500A0002)", "value": "53102500A0002", "key": 51 },
		{ "label": "Clothing - Yarn (11151700A0000)", "value": "11151700A0000", "key": 313 },
		{ "label": "Clothing - Zippers (53141503A0000)", "value": "53141503A0000", "key": 684 },
		{ "label": "Clothing (20010)", "value": "20010", "key": 1 },
		{
			"label": "Cloud-based business process as a service - Business Use (81162300A9000)",
			"value": "81162300A9000",
			"key": 306
		},
		{
			"label": "Cloud-based business process as a service - Personal Use (81162300A0000)",
			"value": "81162300A0000",
			"key": 436
		},
		{
			"label": "Cloud-based infrastructure as a service (IaaS) - Business Use (81162200A9000)",
			"value": "81162200A9000",
			"key": 305
		},
		{
			"label": "Cloud-based infrastructure as a service (IaaS) - Personal Use (81162200A0000)",
			"value": "81162200A0000",
			"key": 361
		},
		{
			"label": "Cloud-based platform as a service (PaaS) - Business Use (81162100A9000)",
			"value": "81162100A9000",
			"key": 362
		},
		{
			"label": "Cloud-based platform as a service (PaaS) - Personal Use (81162100A0000)",
			"value": "81162100A0000",
			"key": 385
		},
		{
			"label": "Cloud-based software as a service (SaaS) - Business Use (81162000A9000)",
			"value": "81162000A9000",
			"key": 326
		},
		{
			"label": "Cloud-based software as a service (SaaS) - Personal Use (81162000A0000)",
			"value": "81162000A0000",
			"key": 320
		},
		{ "label": "Co-location service (81111814A0000)", "value": "81111814A0000", "key": 646 },
		{
			"label": "Cold or Hot Therapy Packs - Disposable - Medicated (42142100A0004)",
			"value": "42142100A0004",
			"key": 209
		},
		{ "label": "Cold or Hot Therapy Packs - Disposable (42142100A0003)", "value": "42142100A0003", "key": 267 },
		{ "label": "Cold or Hot Therapy Packs - Reusable (42142100A0002)", "value": "42142100A0002", "key": 41 },
		{ "label": "Compact Fluorescent Light Bulbs - Energy Star (39101619A0001)", "value": "39101619A0001", "key": 144 },
		{ "label": "Computer Batteries (26111711A0001)", "value": "26111711A0001", "key": 100 },
		{ "label": "Computer Cables (43202222A0001)", "value": "43202222A0001", "key": 540 },
		{ "label": "Computer Drives (43201800A0001)", "value": "43201800A0001", "key": 139 },
		{ "label": "Computer graphics service\r\n (81111512A0000)", "value": "81111512A0000", "key": 628 },
		{ "label": "Computer Keyboards (43211706A0000)", "value": "43211706A0000", "key": 98 },
		{ "label": "Computer Microphones (43211719A0000)", "value": "43211719A0000", "key": 103 },
		{ "label": "Computer Monitor/Displays (43211900A0000)", "value": "43211900A0000", "key": 99 },
		{ "label": "Computer Mouse/Pointing Devices (43211708A0000)", "value": "43211708A0000", "key": 140 },
		{ "label": "Computer or network or internet security\r\n (81111801A0000)", "value": "81111801A0000", "key": 329 },
		{ "label": "Computer Printer (43212100A0001)", "value": "43212100A0001", "key": 485 },
		{ "label": "Computer programmers\r\n (81111600A0000)", "value": "81111600A0000", "key": 604 },
		{ "label": "Computer Repair (81112300A0000)", "value": "81112300A0000", "key": 760 },
		{ "label": "Computer Speakers (43211607A0000)", "value": "43211607A0000", "key": 104 },
		{ "label": "Computer Storage Media (43202000A0000)", "value": "43202000A0000", "key": 95 },
		{ "label": "Conditioner - Hair (53131628A0002)", "value": "53131628A0002", "key": 203 },
		{ "label": "Condoms (53131622A0000)", "value": "53131622A0000", "key": 57 },
		{ "label": "Condoms with Prescription (53131622A0003)", "value": "53131622A0003", "key": 172 },
		{ "label": "Condoms with Spermicide (53131622A0001)", "value": "53131622A0001", "key": 187 },
		{ "label": "Condoms with Spermicide with Prescription (53131622A0004)", "value": "53131622A0004", "key": 711 },
		{ "label": "Consulting Services (87480000A0000)", "value": "87480000A0000", "key": 730 },
		{ "label": "Contact Lens Disinfecting Solutions (42142914A0000)", "value": "42142914A0000", "key": 46 },
		{ "label": "Contact Lens Lubricating Solutions - For eyes (42142914A0002)", "value": "42142914A0002", "key": 71 },
		{ "label": "Contact Lens Lubricating Solutions - For lens (42142914A0001)", "value": "42142914A0001", "key": 49 },
		{ "label": "Contact Lenses with Prescription (42142913A0000)", "value": "42142913A0000", "key": 18 },
		{ "label": "Content or data classification services\r\n (81112009A0000)", "value": "81112009A0000", "key": 348 },
		{ "label": "Content or data standardization services\r\n (81112007A0000)", "value": "81112007A0000", "key": 324 },
		{ "label": "Contraceptive Ointments (53131622A0002)", "value": "53131622A0002", "key": 59 },
		{ "label": "Corrective Lenses without Prescription (42142900A0002)", "value": "42142900A0002", "key": 29 },
		{ "label": "Corrective Lenses, Eyeglasses with Prescription (42142900A0001)", "value": "42142900A0001", "key": 30 },
		{ "label": "Cosmetic Medical Procedure (80110517A0000)", "value": "80110517A0000", "key": 722 },
		{ "label": "Cosmetics - Beautifying (53131619A0001)", "value": "53131619A0001", "key": 664 },
		{ "label": "Cotton Balls/Swabs - Sterile (42141500A0001)", "value": "42141500A0001", "key": 250 },
		{ "label": "Cotton Balls/Swabs - Unsterile (42141500A0002)", "value": "42141500A0002", "key": 251 },
		{ "label": "Credit Card Processing Services (52232000A0000)", "value": "52232000A0000", "key": 452 },
		{ "label": "Credit Monitoring Services (56145000A0000)", "value": "56145000A0000", "key": 741 },
		{ "label": "Credit Reporting Services (73230000A0000)", "value": "73230000A0000", "key": 280 },
		{ "label": "Crib bumpers/liners (56101804A0001)", "value": "56101804A0001", "key": 115 },
		{ "label": "Daily Planners (44112004A0001)", "value": "44112004A0001", "key": 499 },
		{ "label": "Data center services (81112003A0000)", "value": "81112003A0000", "key": 643 },
		{ "label": "Data conversion service\r\n (81112010A0000)", "value": "81112010A0000", "key": 389 },
		{ "label": "Data processing or preparation services\r\n (81112002A0000)", "value": "81112002A0000", "key": 323 },
		{ "label": "Data storage service\r\n (81112006A0000)", "value": "81112006A0000", "key": 312 },
		{ "label": "Database analysis service\r\n (81111806A0000)", "value": "81111806A0000", "key": 322 },
		{ "label": "Database design\r\n (81111704A0000)", "value": "81111704A0000", "key": 605 },
		{ "label": "Database information retrieval (81111901A0000)", "value": "81111901A0000", "key": 246 },
		{
			"label": "Database information retrieval (personal or individual) (81111901A0001)",
			"value": "81111901A0001",
			"key": 493
		},
		{ "label": "Dating Services (72990301A0000)", "value": "72990301A0000", "key": 748 },
		{ "label": "Debt Collection Services (73229902A0000)", "value": "73229902A0000", "key": 495 },
		{ "label": "Dehumidifier - Energy Star (40101902A0000)", "value": "40101902A0000", "key": 143 },
		{
			"label": "Demining geographical or geospatial information system GIS\r\n (81111709A0000)",
			"value": "81111709A0000",
			"key": 325
		},
		{ "label": "Dental Floss/picks (53131504A0000)", "value": "53131504A0000", "key": 213 },
		{ "label": "Dental Prosthetics with Prescription (42151500A0001)", "value": "42151500A0001", "key": 183 },
		{ "label": "Dental Prosthetics without Prescription (42151500A0002)", "value": "42151500A0002", "key": 185 },
		{ "label": "Denture creams/adhesives (53131510A0000)", "value": "53131510A0000", "key": 358 },
		{ "label": "Deodorant - Natural or no active ingredients (53131606A0001)", "value": "53131606A0001", "key": 266 },
		{ "label": "Deodorant/Antiperspirant (53131606A0000)", "value": "53131606A0000", "key": 212 },
		{
			"label": "Diabetic Testing Supplies - single use - with Prescription (41116120A0002)",
			"value": "41116120A0002",
			"key": 531
		},
		{
			"label": "Diabetic Testing Supplies - single use - without Prescription (41116120A0001)",
			"value": "41116120A0001",
			"key": 533
		},
		{ "label": "Diaper Cream (51241859A0001)", "value": "51241859A0001", "key": 223 },
		{ "label": "Dictionaries/Thesauruses (55101526A0001)", "value": "55101526A0001", "key": 519 },
		{
			"label": "Digital Audio Visual Works - bundle - downloaded and streamed - subscription - with conditional rights (55111516A0310)",
			"value": "55111516A0310",
			"key": 790
		},
		{
			"label": "Digital Audio Visual Works - bundle - downloaded with limited rights and streamed - non subscription (55111516A0210)",
			"value": "55111516A0210",
			"key": 812
		},
		{
			"label": "Digital Audio Visual Works - bundle - downloaded with permanent rights and streamed - non subscription (55111516A0110)",
			"value": "55111516A0110",
			"key": 768
		},
		{
			"label": "Digital Audio Visual Works - downloaded - non subscription - with limited rights (55111516A0020)",
			"value": "55111516A0020",
			"key": 764
		},
		{
			"label": "Digital Audio Visual Works - downloaded - non subscription - with permanent rights (55111516A0010)",
			"value": "55111516A0010",
			"key": 762
		},
		{
			"label": "Digital Audio Visual Works - streamed - non subscription - with limited rights (55111516A0030)",
			"value": "55111516A0030",
			"key": 827
		},
		{
			"label": "Digital Audio Visual Works - streamed - subscription - with conditional rights (55111516A0040)",
			"value": "55111516A0040",
			"key": 763
		},
		{
			"label": "Digital Audio Works - downloaded - non subscription - with limited rights (55111512A0020)",
			"value": "55111512A0020",
			"key": 799
		},
		{
			"label": "Digital Audio Works - downloaded - non subscription - with permanent rights (55111512A0010)",
			"value": "55111512A0010",
			"key": 766
		},
		{
			"label": "Digital Audio Works - streamed - non subscription - with limited rights (55111512A0030)",
			"value": "55111512A0030",
			"key": 767
		},
		{
			"label": "Digital Audio Works - streamed - subscription - with conditional rights (55111512A0040)",
			"value": "55111512A0040",
			"key": 771
		},
		{
			"label": "Digital Books - downloaded - non subscription - with limited rights (55111505A0020)",
			"value": "55111505A0020",
			"key": 795
		},
		{
			"label": "Digital Books - downloaded - non subscription - with permanent rights (55111505A0010)",
			"value": "55111505A0010",
			"key": 781
		},
		{
			"label": "Digital Books - downloaded - subscription - with conditional rights (55111505A0050)",
			"value": "55111505A0050",
			"key": 817
		},
		{
			"label": "Digital Books - viewable only - subscription - with conditional rights (55111505A0060)",
			"value": "55111505A0060",
			"key": 818
		},
		{ "label": "Digital Cameras (45121504A0000)", "value": "45121504A0000", "key": 55 },
		{
			"label": "Digital Finished Artwork - downloaded - non subscription - with limited rights (82141502A0020)",
			"value": "82141502A0020",
			"key": 809
		},
		{
			"label": "Digital Finished Artwork - downloaded - non subscription - with permanent rights (82141502A0010)",
			"value": "82141502A0010",
			"key": 811
		},
		{
			"label": "Digital Finished Artwork - downloaded - subscription - with conditional rights (82141502A0050)",
			"value": "82141502A0050",
			"key": 821
		},
		{ "label": "Digital Goods (31000)", "value": "31000", "key": 838 },
		{ "label": "Digital Greeting Cards - Audio Only (14111605A0002)", "value": "14111605A0002", "key": 776 },
		{ "label": "Digital Greeting Cards - Audio Visual (14111605A0001)", "value": "14111605A0001", "key": 803 },
		{
			"label": "Digital Greeting Cards - Static text and/or images only (14111605A0003)",
			"value": "14111605A0003",
			"key": 804
		},
		{
			"label": "Digital Magazines/Periodicals - downloadable - non subscription - with limited rights (55111506A0020)",
			"value": "55111506A0020",
			"key": 805
		},
		{
			"label": "Digital Magazines/Periodicals - downloadable - non subscription - with permanent rights (55111506A0010)",
			"value": "55111506A0010",
			"key": 806
		},
		{
			"label": "Digital Magazines/Periodicals - downloadable - subscription - with conditional rights (55111506A0050)",
			"value": "55111506A0050",
			"key": 833
		},
		{
			"label": "Digital Magazines/Periodicals - subscription tangible and digital (55111506A0070)",
			"value": "55111506A0070",
			"key": 765
		},
		{
			"label": "Digital Magazines/Periodicals - viewable only - non subscription - with limited rights (55111506A0030)",
			"value": "55111506A0030",
			"key": 834
		},
		{
			"label": "Digital Magazines/Periodicals - viewable only - non subscription - with permanent rights (55111506A0040)",
			"value": "55111506A0040",
			"key": 808
		},
		{
			"label": "Digital Magazines/Periodicals - viewable only - subscription - with conditional rights (55111506A0060)",
			"value": "55111506A0060",
			"key": 835
		},
		{ "label": "Digital Music Players (52161543A0000)", "value": "52161543A0000", "key": 177 },
		{
			"label": "Digital Newspapers - downloadable - non subscription - with limited rights (55111507A0020)",
			"value": "55111507A0020",
			"key": 810
		},
		{
			"label": "Digital Newspapers - downloadable - non subscription - with permanent rights (55111507A0010)",
			"value": "55111507A0010",
			"key": 782
		},
		{
			"label": "Digital Newspapers - downloadable - subscription - with conditional rights (55111507A0050)",
			"value": "55111507A0050",
			"key": 783
		},
		{
			"label": "Digital Newspapers - subscription tangible and digital (55111507A0070)",
			"value": "55111507A0070",
			"key": 784
		},
		{
			"label": "Digital Newspapers - viewable only - non subscription - with limited rights (55111507A0030)",
			"value": "55111507A0030",
			"key": 836
		},
		{
			"label": "Digital Newspapers - viewable only - non subscription - with permanent rights (55111507A0040)",
			"value": "55111507A0040",
			"key": 770
		},
		{
			"label": "Digital Newspapers - viewable only - subscription - with conditional rights (55111507A0060)",
			"value": "55111507A0060",
			"key": 798
		},
		{
			"label": "Digital other news or documents - downloadable - non subscription - with limited rights (82111900A0004)",
			"value": "82111900A0004",
			"key": 165
		},
		{
			"label": "Digital other news or documents - downloadable - non subscription - with permanent rights (82111900A0003)",
			"value": "82111900A0003",
			"key": 831
		},
		{
			"label": "Digital other news or documents - downloadable - subscription - with conditional rights (82111900A0001)",
			"value": "82111900A0001",
			"key": 832
		},
		{
			"label": "Digital other news or documents - viewable only - non subscription - with limited rights (82111900A0006)",
			"value": "82111900A0006",
			"key": 814
		},
		{
			"label": "Digital other news or documents - viewable only - non subscription - with permanent rights (82111900A0005)",
			"value": "82111900A0005",
			"key": 807
		},
		{
			"label": "Digital other news or documents - viewable only - subscription - with conditional rights (82111900A0002)",
			"value": "82111900A0002",
			"key": 826
		},
		{
			"label": "Digital Photographs/Images - downloaded - non subscription - with permanent rights (60121011A0001)",
			"value": "60121011A0001",
			"key": 769
		},
		{ "label": "Digital Picture Frames (52161549A0000)", "value": "52161549A0000", "key": 79 },
		{
			"label": "Digital Products (> 10%) / General Services (> 10%) Bundle (55111500A5000)",
			"value": "55111500A5000",
			"key": 186
		},
		{
			"label": "Digital School Textbooks - downloaded - non subscription - with limited rights (55111513A0020)",
			"value": "55111513A0020",
			"key": 813
		},
		{
			"label": "Digital School Textbooks - downloaded - non subscription - with permanent rights (55111513A0010)",
			"value": "55111513A0010",
			"key": 792
		},
		{ "label": "Disaster recovery services (81112004A0000)", "value": "81112004A0000", "key": 290 },
		{ "label": "Dish towels (52121601A0000)", "value": "52121601A0000", "key": 116 },
		{ "label": "Dishwashers - Energy Star (52141505A0000)", "value": "52141505A0000", "key": 109 },
		{ "label": "Disposable Gloves (42132203A0000)", "value": "42132203A0000", "key": 270 },
		{ "label": "Disposable Paper Products, non-commercial use (14111700A0001)", "value": "14111700A0001", "key": 690 },
		{ "label": "DNA Testing Kits (41116205A0003)", "value": "41116205A0003", "key": 231 },
		{ "label": "Docking Stations (43211602A0000)", "value": "43211602A0000", "key": 101 },
		{ "label": "Document scanning service\r\n (81112005A0000)", "value": "81112005A0000", "key": 327 },
		{ "label": "Drug Testing Kits (41116136A0001)", "value": "41116136A0001", "key": 240 },
		{ "label": "Dry Cleaning - items other than clothing (91111503A1601)", "value": "91111503A1601", "key": 696 },
		{ "label": "Dry Cleaning Services (19006)", "value": "19006", "key": 749 },
		{ "label": "Duct Tape (31201501A0000)", "value": "31201501A0000", "key": 121 },
		{
			"label": "Durable Medical Equipment for home use with Prescription (42140000A0001)",
			"value": "42140000A0001",
			"key": 178
		},
		{
			"label": "Durable Medical Equipment for home use with Prescription billed to Medicaid (42140000A0003)",
			"value": "42140000A0003",
			"key": 167
		},
		{
			"label": "Durable Medical Equipment for home use with Prescription billed to Medicare (42140000A0002)",
			"value": "42140000A0002",
			"key": 525
		},
		{
			"label": "Durable Medical Equipment for home use with Prescription reimbursed by Medicaid (42140000A0005)",
			"value": "42140000A0005",
			"key": 655
		},
		{
			"label": "Durable Medical Equipment for home use with Prescription reimbursed by Medicare (42140000A0004)",
			"value": "42140000A0004",
			"key": 544
		},
		{
			"label": "Durable Medical Equipment for home use without Prescription (42140000A0006)",
			"value": "42140000A0006",
			"key": 530
		},
		{ "label": "E-Book Readers (43211519A0000)", "value": "43211519A0000", "key": 83 },
		{ "label": "Ear Drops - Medicated (51241000A0001)", "value": "51241000A0001", "key": 451 },
		{ "label": "Educational Services (86132209A0000)", "value": "86132209A0000", "key": 259 },
		{ "label": "Electrolysis (72310102A0000)", "value": "72310102A0000", "key": 740 },
		{
			"label": "Electronic content bundle - Delivered electronically with less than permanent rights of usage and streamed (55111500A9220)",
			"value": "55111500A9220",
			"key": 590
		},
		{
			"label": "Electronic content bundle - Delivered electronically with permanent rights of usage and streamed (55111500A9210)",
			"value": "55111500A9210",
			"key": 373
		},
		{ "label": "Electronic data interchange EDI design\r\n (81111703A0000)", "value": "81111703A0000", "key": 641 },
		{ "label": "Electronic Filing Service (72910000A0000)", "value": "72910000A0000", "key": 16 },
		{
			"label": "Electronic publications and music - Delivered electronically with less than permanent rights of usage (55111500A1220)",
			"value": "55111500A1220",
			"key": 334
		},
		{
			"label": "Electronic publications and music - Delivered electronically with permanent rights of usage (55111500A1210)",
			"value": "55111500A1210",
			"key": 335
		},
		{ "label": "Electronic publications and music - Streamed (55111500A1500)", "value": "55111500A1500", "key": 336 },
		{
			"label": "Electronic software documentation or user manuals - Custom, electronic delivery (55111601A2200)",
			"value": "55111601A2200",
			"key": 337
		},
		{
			"label": "Electronic software documentation or user manuals - Custom, load and leave delivery (55111601A2300)",
			"value": "55111601A2300",
			"key": 338
		},
		{
			"label": "Electronic software documentation or user manuals - Custom, tangible media (55111601A2100)",
			"value": "55111601A2100",
			"key": 339
		},
		{
			"label": "Electronic software documentation or user manuals - Prewritten, electronic delivery (55111601A1200)",
			"value": "55111601A1200",
			"key": 651
		},
		{
			"label": "Electronic software documentation or user manuals - Prewritten, load and leave delivery (55111601A1300)",
			"value": "55111601A1300",
			"key": 340
		},
		{
			"label": "Electronic software documentation or user manuals - Prewritten, tangible media (55111601A1100)",
			"value": "55111601A1100",
			"key": 321
		},
		{ "label": "Emergency/rescue ladder (30191501A0001)", "value": "30191501A0001", "key": 125 },
		{ "label": "Employment Services (73610000A0000)", "value": "73610000A0000", "key": 717 },
		{
			"label": "Energy Beverages - Carbonated - with Supplement Facts Label (50202309A0001)",
			"value": "50202309A0001",
			"key": 663
		},
		{
			"label": "Energy Beverages - Non-Carbonated - with Supplement Facts Label (50202309A0000)",
			"value": "50202309A0000",
			"key": 289
		},
		{ "label": "Engineering Services (87110000A0000)", "value": "87110000A0000", "key": 726 },
		{
			"label": "Enteral Feeding Equipment for home use with Prescription (42231500A0001)",
			"value": "42231500A0001",
			"key": 39
		},
		{
			"label": "Enteral Feeding Equipment for home use with prescription billed to Medicaid (42231500A0003)",
			"value": "42231500A0003",
			"key": 40
		},
		{
			"label": "Enteral Feeding Equipment for home use with Prescription billed to Medicare (42231500A0002)",
			"value": "42231500A0002",
			"key": 168
		},
		{
			"label": "Enteral Feeding Equipment for home use with Prescription reimbursed by Medicaid (42231500A0005)",
			"value": "42231500A0005",
			"key": 227
		},
		{
			"label": "Enteral Feeding Equipment for home use with Prescription reimbursed by Medicare (42231500A0004)",
			"value": "42231500A0004",
			"key": 180
		},
		{
			"label": "Enteral Feeding Equipment for home use without Prescription (42231500A0006)",
			"value": "42231500A0006",
			"key": 181
		},
		{
			"label": "ERP or database applications programming services (81111507A0000)",
			"value": "81111507A0000",
			"key": 841
		},
		{ "label": "Fabrication (23839000A0000)", "value": "23839000A0000", "key": 703 },
		{ "label": "Feminine Cleansing Solutions - Medicated (53131615A0002)", "value": "53131615A0002", "key": 173 },
		{ "label": "Feminine Cleansing Solutions - Unmedicated (53131615A0001)", "value": "53131615A0001", "key": 14 },
		{ "label": "Feminine Yeast Treatments (51302300A0001)", "value": "51302300A0001", "key": 72 },
		{ "label": "Fire Extinguishers (46191601A0000)", "value": "46191601A0000", "key": 127 },
		{ "label": "Firearm Accessories (46101506A0001)", "value": "46101506A0001", "key": 155 },
		{ "label": "Firearms - other than small arms (46101500A0002)", "value": "46101500A0002", "key": 780 },
		{ "label": "Firearms (46101500A0001)", "value": "46101500A0001", "key": 156 },
		{ "label": "First Aid Kits - 50% or less medicinal items (42172001A0001)", "value": "42172001A0001", "key": 489 },
		{ "label": "First Aid Kits - 51% or more medicinal items (42172001A0002)", "value": "42172001A0002", "key": 491 },
		{ "label": "Flea & Tick Products - Pets (51000000A1001)", "value": "51000000A1001", "key": 207 },
		{ "label": "Food & Groceries (40030)", "value": "40030", "key": 3 },
		{
			"label": "Food and Beverage - Alcoholic beverages - Beer/Malt Beverages (50202201A0000)",
			"value": "50202201A0000",
			"key": 375
		},
		{
			"label": "Food and Beverage - Alcoholic beverages - Spirits (50202206A0000)",
			"value": "50202206A0000",
			"key": 592
		},
		{ "label": "Food and Beverage - Alcoholic beverages - Wine (50202203A0000)", "value": "50202203A0000", "key": 591 },
		{ "label": "Food and Beverage - Baby foods and formulas (42231800A0000)", "value": "42231800A0000", "key": 613 },
		{ "label": "Food and Beverage - Bread and Flour Products (50180000A0000)", "value": "50180000A0000", "key": 419 },
		{
			"label": "Food and Beverage - Butter, Margarine, Shortening and Cooking Oils (50151500A0000)",
			"value": "50151500A0000",
			"key": 676
		},
		{ "label": "Food and Beverage - Candy (50161800A0000)", "value": "50161800A0000", "key": 677 },
		{
			"label": "Food and Beverage - Candy containing flour as an ingredient (50161800A0001)",
			"value": "50161800A0001",
			"key": 547
		},
		{ "label": "Food and Beverage - Cheese (50131800A0000)", "value": "50131800A0000", "key": 467 },
		{ "label": "Food and Beverage - Cocoa and Cocoa products (50161511A0000)", "value": "50161511A0000", "key": 614 },
		{
			"label": "Food and Beverage - Coffee, coffee substitutes and tea (50201700A0000)",
			"value": "50201700A0000",
			"key": 678
		},
		{ "label": "Food and Beverage - Cooking Ingredients (50181700A0000)", "value": "50181700A0000", "key": 615 },
		{ "label": "Food and Beverage - Cooking spices (50171500A0000)", "value": "50171500A0000", "key": 376 },
		{ "label": "Food and Beverage - Dried fruit, unsweetened (50320000A0000)", "value": "50320000A0000", "key": 597 },
		{ "label": "Food and Beverage - Eggs and egg products (50131600A0000)", "value": "50131600A0000", "key": 679 },
		{ "label": "Food and Beverage - Fish and seafood (50121500A0000)", "value": "50121500A0000", "key": 617 },
		{
			"label": "Food and Beverage - Food and Food Ingredients for Home Consumption (50000000A0000)",
			"value": "50000000A0000",
			"key": 399
		},
		{
			"label": "Food and Beverage - Foods for Immediate Consumption (90100000A0001)",
			"value": "90100000A0001",
			"key": 420
		},
		{ "label": "Food and Beverage - Fruit (50300000A0000)", "value": "50300000A0000", "key": 599 },
		{ "label": "Food and Beverage - Grains, Rice, Cereal (50221200A0000)", "value": "50221200A0000", "key": 442 },
		{
			"label": "Food and Beverage - Granola Bars, Cereal Bars, Energy Bars, Protein Bars containing flour (50221202A0001)",
			"value": "50221202A0001",
			"key": 188
		},
		{
			"label": "Food and Beverage - Granola Bars, Cereal Bars, Energy Bars, Protein Bars containing no flour (50221202A0002)",
			"value": "50221202A0002",
			"key": 184
		},
		{ "label": "Food and Beverage - Honey, Maple Syrup (50161509A0000)", "value": "50161509A0000", "key": 421 },
		{ "label": "Food and Beverage - Ice Cream, packaged (50192303A0000)", "value": "50192303A0000", "key": 374 },
		{
			"label": "Food and Beverage - Ice Cream, sold in container less than one pint (50192304A0000)",
			"value": "50192304A0000",
			"key": 593
		},
		{ "label": "Food and Beverage - Ice cubes (50202302A0000)", "value": "50202302A0000", "key": 616 },
		{ "label": "Food and Beverage - Jams and Jellies (50192401A0000)", "value": "50192401A0000", "key": 422 },
		{ "label": "Food and Beverage - Jello and pudding mixes (50192404A0000)", "value": "50192404A0000", "key": 377 },
		{ "label": "Food and Beverage - Meat and meat products (50110000A0000)", "value": "50110000A0000", "key": 299 },
		{ "label": "Food and Beverage - Meat Sticks, Meat Jerky (50112000A0000)", "value": "50112000A0000", "key": 309 },
		{ "label": "Food and Beverage - Milk and milk products (50131700A0000)", "value": "50131700A0000", "key": 468 },
		{ "label": "Food and Beverage - Milk Substitutes (50151515A9000)", "value": "50151515A9000", "key": 469 },
		{ "label": "Food and Beverage - Non-Alcoholic Beer, Wine (50202300A0001)", "value": "50202300A0001", "key": 594 },
		{ "label": "Food and Beverage - Nut Butters (50480000A9000)", "value": "50480000A9000", "key": 423 },
		{ "label": "Food and Beverage - Nuts and seeds (50101716A0000)", "value": "50101716A0000", "key": 470 },
		{
			"label": "Food and Beverage - Nuts and seeds that have been processed or treated by salting, spicing, smoking, roasting, or other means (50101716A0001)",
			"value": "50101716A0001",
			"key": 595
		},
		{ "label": "Food and Beverage - Snack Foods (50192100A0000)", "value": "50192100A0000", "key": 596 },
		{
			"label": "Food and Beverage - Sugar and Sugar Substitutes (50161900A0000)",
			"value": "50161900A0000",
			"key": 680
		},
		{ "label": "Food and Beverage - Vegetables (50400000A0000)", "value": "50400000A0000", "key": 598 },
		{
			"label": "Food and Beverage - Vitamins and Supplements - labeled with nutritional facts (50501500A0001)",
			"value": "50501500A0001",
			"key": 471
		},
		{
			"label": "Food and Beverage - Vitamins and Supplements - labeled with supplement facts (50501500A0000)",
			"value": "50501500A0000",
			"key": 378
		},
		{ "label": "Food and Beverage - Yogurt (50131800A0001)", "value": "50131800A0001", "key": 424 },
		{ "label": "Food Storage Cooler (52152002A0001)", "value": "52152002A0001", "key": 397 },
		{ "label": "Food/Candy Bundle - with Candy 10% or less (50193400A0010)", "value": "50193400A0010", "key": 536 },
		{ "label": "Food/Candy Bundle - with Candy 11% to 24% (50193400A0009)", "value": "50193400A0009", "key": 695 },
		{ "label": "Food/Candy Bundle - with Candy 25% to 49% (50193400A0008)", "value": "50193400A0008", "key": 710 },
		{ "label": "Food/Candy Bundle - with Candy 50% or more (50193400A0007)", "value": "50193400A0007", "key": 535 },
		{
			"label": "Food/TPP Bundle - with Food 90% or more - Food is all Candy (50193400A0001)",
			"value": "50193400A0001",
			"key": 269
		},
		{ "label": "Food/TPP Bundle - with Food 90% or more (50193400A0000)", "value": "50193400A0000", "key": 288 },
		{
			"label": "Food/TPP Bundle - with Food between 50% and 75% - Food is all Candy (50193400A0003)",
			"value": "50193400A0003",
			"key": 296
		},
		{
			"label": "Food/TPP Bundle - with Food between 50% and 75% (50193400A0002)",
			"value": "50193400A0002",
			"key": 662
		},
		{
			"label": "Food/TPP Bundle - with Food between 76% and 89% - Food is all Candy (50193400A0005)",
			"value": "50193400A0005",
			"key": 298
		},
		{
			"label": "Food/TPP Bundle - with Food between 76% and 89% (50193400A0004)",
			"value": "50193400A0004",
			"key": 297
		},
		{ "label": "Food/TPP Bundle - with Food less than 50% (50193400A0006)", "value": "50193400A0006", "key": 268 },
		{ "label": "Freezers- Energy Star (52141506A0000)", "value": "52141506A0000", "key": 135 },
		{ "label": "Furnaces - Energy Star (40101805A0000)", "value": "40101805A0000", "key": 133 },
		{ "label": "Furniture Reupholstering (72153614A0000)", "value": "72153614A0000", "key": 752 },
		{ "label": "Garden chainsaw (27112038A0000)", "value": "27112038A0000", "key": 120 },
		{
			"label": "Garment Alterations- in conjunction with purchase of garment (81149000A0001)",
			"value": "81149000A0001",
			"key": 750
		},
		{
			"label": "Garment Alterations- separate from purchase of garment (81149000A0000)",
			"value": "81149000A0000",
			"key": 754
		},
		{ "label": "General Services (19000)", "value": "19000", "key": 33 },
		{ "label": "Gift Cards (14111803A0001)", "value": "14111803A0001", "key": 609 },
		{
			"label": "Gift Wrapping - in conjunction with purchase of article (14111601A0001)",
			"value": "14111601A0001",
			"key": 704
		},
		{
			"label": "Gift Wrapping - separate from purchase of article (14111601A9007)",
			"value": "14111601A9007",
			"key": 839
		},
		{ "label": "Globes - Student (60104414A0001)", "value": "60104414A0001", "key": 81 },
		{ "label": "Graphing Calculators (44101808A0001)", "value": "44101808A0001", "key": 113 },
		{ "label": "Ground Anchor Systems and Tie-down Kits (31162108A0000)", "value": "31162108A0000", "key": 484 },
		{ "label": "Gun Cases (46101801A0000)", "value": "46101801A0000", "key": 154 },
		{ "label": "Gun Safe (46101806A0001)", "value": "46101806A0001", "key": 786 },
		{ "label": "Gun Safety Lock (46171502A0001)", "value": "46171502A0001", "key": 788 },
		{ "label": "Gutter Cleaning Services (72152602A0001)", "value": "72152602A0001", "key": 828 },
		{ "label": "Hair Loss Products - Medicated (51182001A0001)", "value": "51182001A0001", "key": 830 },
		{ "label": "Hair Removal Products (53131623A0000)", "value": "53131623A0000", "key": 197 },
		{ "label": "Hairdressing Services (19008)", "value": "19008", "key": 734 },
		{ "label": "Hand Sanitizers (53131626A0000)", "value": "53131626A0000", "key": 218 },
		{ "label": "Handbags, Purses (53121600A0000)", "value": "53121600A0000", "key": 56 },
		{ "label": "Hardware as a service (HaaS) (81161900A0000)", "value": "81161900A0000", "key": 386 },
		{ "label": "Headphones/Earbuds (52161514A0000)", "value": "52161514A0000", "key": 446 },
		{ "label": "Hearing Aid Batteries with Prescription (26111710A0001)", "value": "26111710A0001", "key": 174 },
		{ "label": "Hearing Aid Batteries without Prescription (26111710A0002)", "value": "26111710A0002", "key": 198 },
		{ "label": "Hearing Aids with Prescription (42211705A0000)", "value": "42211705A0000", "key": 31 },
		{ "label": "Hearing Aids without Prescription (42211705A0001)", "value": "42211705A0001", "key": 50 },
		{ "label": "Hearing Protection Earmuffs (46181902A0001)", "value": "46181902A0001", "key": 152 },
		{ "label": "Heat Pumps - Energy Star (40101806A0000)", "value": "40101806A0000", "key": 134 },
		{ "label": "Heating Pads (42142100A0001)", "value": "42142100A0001", "key": 28 },
		{ "label": "Home Inspection Services (80131802A0001)", "value": "80131802A0001", "key": 823 },
		{ "label": "Hydrogen Peroxide (51473503A0000)", "value": "51473503A0000", "key": 194 },
		{
			"label": "Hypodermic Needles/Syringes with prescription - Insulin (42142523A0002)",
			"value": "42142523A0002",
			"key": 245
		},
		{
			"label": "Hypodermic Needles/Syringes without prescription - Insulin (42142523A0001)",
			"value": "42142523A0001",
			"key": 234
		},
		{ "label": "Incandescent Light Bulbs - Energy Star (39101612A0001)", "value": "39101612A0001", "key": 145 },
		{ "label": "Infant/Child Car Seat (56101805A0000)", "value": "56101805A0000", "key": 732 },
		{ "label": "Infectious Disease Test - Over-the-Counter (41116205A0005)", "value": "41116205A0005", "key": 486 },
		{ "label": "Infectious Disease Test - Prescription only (41116205A0004)", "value": "41116205A0004", "key": 494 },
		{
			"label": "Information management system for mine action IMSMA\r\n (81111710A0000)",
			"value": "81111710A0000",
			"key": 645
		},
		{ "label": "Installation of Property - Permanent (72150000A0001)", "value": "72150000A0001", "key": 777 },
		{ "label": "Installation Services (10040)", "value": "10040", "key": 2 },
		{ "label": "Insulin with Prescription (51183603A0000)", "value": "51183603A0000", "key": 277 },
		{ "label": "Insulin without Prescription (51183603A0001)", "value": "51183603A0001", "key": 237 },
		{ "label": "Insurance Services (64110000A0000)", "value": "64110000A0000", "key": 739 },
		{ "label": "Interior Decorating Services (73890600A0000)", "value": "73890600A0000", "key": 800 },
		{ "label": "Internet cloud storage service\r\n (81111513A0000)", "value": "81111513A0000", "key": 330 },
		{
			"label": "Internet or intranet client application development services\r\n (81111509A0000)",
			"value": "81111509A0000",
			"key": 606
		},
		{
			"label": "Internet or intranet server application development services\r\n (81111510A0000)",
			"value": "81111510A0000",
			"key": 390
		},
		{ "label": "Irrigation Controls - WaterSense (21102503A0001)", "value": "21102503A0001", "key": 147 },
		{ "label": "Isopropyl (Rubbing) Alcohol (51471901A0000)", "value": "51471901A0000", "key": 195 },
		{ "label": "IT Support Services (81111811A0000)", "value": "81111811A0000", "key": 355 },
		{ "label": "Jewelry (54100000A0000)", "value": "54100000A0000", "key": 52 },
		{ "label": "Jewelry Repair (54119007A0000)", "value": "54119007A0000", "key": 705 },
		{
			"label": "Kidney Dialysis Equipment for home use with Prescription (42161800A0001)",
			"value": "42161800A0001",
			"key": 524
		},
		{
			"label": "Kidney Dialysis Equipment for home use with Prescription and reimbursed by Medicaid (42161800A0005)",
			"value": "42161800A0005",
			"key": 38
		},
		{
			"label": "Kidney Dialysis Equipment for home use with Prescription billed to Medicaid (42161800A0003)",
			"value": "42161800A0003",
			"key": 36
		},
		{
			"label": "Kidney Dialysis Equipment for home use with Prescription billed to Medicare (42161800A0002)",
			"value": "42161800A0002",
			"key": 668
		},
		{
			"label": "Kidney Dialysis Equipment for home use with Prescription reimbursed by Medicare (42161800A0004)",
			"value": "42161800A0004",
			"key": 163
		},
		{
			"label": "Kidney Dialysis Equipment for home use without Prescription (42161800A0006)",
			"value": "42161800A0006",
			"key": 669
		},
		{ "label": "Landscape Soil, Mulch, Compost - Residential (11121700A0001)", "value": "11121700A0001", "key": 681 },
		{ "label": "Landscaping Services (72102902A0000)", "value": "72102902A0000", "key": 775 },
		{ "label": "Lawn Maintenance Services (70111706A0000)", "value": "70111706A0000", "key": 797 },
		{ "label": "LED Bulbs - Energy Star (39101628A0001)", "value": "39101628A0001", "key": 542 },
		{ "label": "Legal Services (81110000A0000)", "value": "81110000A0000", "key": 723 },
		{
			"label": "Linen Services - Laundry only - items other than clothing (91111502A1601)",
			"value": "91111502A1601",
			"key": 700
		},
		{ "label": "Linen Services - Laundry only (91111502A1600)", "value": "91111502A1600", "key": 751 },
		{ "label": "Lip Balm - Medicated (53131630A0001)", "value": "53131630A0001", "key": 487 },
		{ "label": "Lip Balm (53131630A0000)", "value": "53131630A0000", "key": 549 },
		{ "label": "Lobbying Services (87439901A0000)", "value": "87439901A0000", "key": 729 },
		{ "label": "Local area network communications design\r\n (81111702A0000)", "value": "81111702A0000", "key": 566 },
		{ "label": "Local area network LAN maintenance or support (81111803A0000)", "value": "81111803A0000", "key": 644 },
		{ "label": "Luggage (53121500A0000)", "value": "53121500A0000", "key": 157 },
		{ "label": "Machine Repair (23019007A0000)", "value": "23019007A0000", "key": 706 },
		{ "label": "Magazine (81310)", "value": "81310", "key": 7 },
		{ "label": "Magazines & Subscriptions (81300)", "value": "81300", "key": 5 },
		{ "label": "Mainframe administration services\r\n (81111802A0000)", "value": "81111802A0000", "key": 647 },
		{ "label": "Mainframe software applications design\r\n (81111501A0000)", "value": "81111501A0000", "key": 434 },
		{ "label": "Manicure Services (72310104A0000)", "value": "72310104A0000", "key": 745 },
		{ "label": "Maps - Student (60103410A0001)", "value": "60103410A0001", "key": 87 },
		{ "label": "Marketing Services (87420300A0000)", "value": "87420300A0000", "key": 744 },
		{ "label": "Massage Services (72990200A0000)", "value": "72990200A0000", "key": 687 },
		{ "label": "Meat Processing (42447000A0000)", "value": "42447000A0000", "key": 755 },
		{ "label": "Medical Masks (42131713A0001)", "value": "42131713A0001", "key": 257 },
		{ "label": "Medical Oxygen with Prescription (42271700A0012)", "value": "42271700A0012", "key": 363 },
		{
			"label": "Medical Oxygen with Prescription billed to Medicaid (42271700A0008)",
			"value": "42271700A0008",
			"key": 241
		},
		{
			"label": "Medical Oxygen with Prescription billed to Medicare (42271700A0007)",
			"value": "42271700A0007",
			"key": 235
		},
		{
			"label": "Medical Oxygen with Prescription reimbursed by Medicaid (42271700A0010)",
			"value": "42271700A0010",
			"key": 448
		},
		{
			"label": "Medical Oxygen with Prescription reimbursed by Medicare (42271700A0009)",
			"value": "42271700A0009",
			"key": 236
		},
		{ "label": "Medical Oxygen without Prescription (42271700A0011)", "value": "42271700A0011", "key": 360 },
		{
			"label": "Medical Professional Services - Physician, Dentist, and similar (62139900A0000)",
			"value": "62139900A0000",
			"key": 747
		},
		{ "label": "Medical Thermometers - Disposable (42182200A0001)", "value": "42182200A0001", "key": 256 },
		{ "label": "Medical Thermometers - Reusable (42182200A0002)", "value": "42182200A0002", "key": 258 },
		{ "label": "Mobile Phone Batteries (43191501A0001)", "value": "43191501A0001", "key": 82 },
		{ "label": "Mobile Phone Charging Device/cord (43191501A0002)", "value": "43191501A0002", "key": 128 },
		{ "label": "Mobile Phones (43191501A0000)", "value": "43191501A0000", "key": 22 },
		{ "label": "Mobility Enhancing Equipment with Prescription (42211500A0001)", "value": "42211500A0001", "key": 170 },
		{
			"label": "Mobility Enhancing Equipment with Prescription Billed to Medicaid (42211500A0003)",
			"value": "42211500A0003",
			"key": 526
		},
		{
			"label": "Mobility Enhancing Equipment with Prescription Billed to Medicare (42211500A0002)",
			"value": "42211500A0002",
			"key": 164
		},
		{
			"label": "Mobility Enhancing Equipment with Prescription Reimbursed by Medicaid (42211500A0005)",
			"value": "42211500A0005",
			"key": 192
		},
		{
			"label": "Mobility Enhancing Equipment with Prescription Reimbursed by Medicare (42211500A0004)",
			"value": "42211500A0004",
			"key": 42
		},
		{
			"label": "Mobility Enhancing Equipment without Prescription (42211500A0006)",
			"value": "42211500A0006",
			"key": 274
		},
		{ "label": "Modems (43222628A0000)", "value": "43222628A0000", "key": 88 },
		{ "label": "Motherboards (43201513A0000)", "value": "43201513A0000", "key": 356 },
		{ "label": "Motor Vehicle Repair (25100000A9007)", "value": "25100000A9007", "key": 756 },
		{ "label": "Mouthwash - Cosmetic (53131501A0001)", "value": "53131501A0001", "key": 224 },
		{ "label": "Mouthwash - Therapeutic (53131501A0000)", "value": "53131501A0000", "key": 271 },
		{ "label": "Musical instruments - Student (60130000A0001)", "value": "60130000A0001", "key": 520 },
		{ "label": "Nasal Breathing Strips (42312402A0001)", "value": "42312402A0001", "key": 272 },
		{ "label": "Network planning services\r\n (81111706A0000)", "value": "81111706A0000", "key": 349 },
		{ "label": "Newspaper - Tangible (55101504A0000)", "value": "55101504A0000", "key": 736 },
		{
			"label": "Nutritional Supplements, Protein Drinks, Shakes - Contains Milk (50501703A0001)",
			"value": "50501703A0001",
			"key": 328
		},
		{
			"label": "Nutritional Supplements, Protein Drinks, Shakes - Contains No Milk (50501703A0000)",
			"value": "50501703A0000",
			"key": 366
		},
		{ "label": "Online data processing service\r\n (81112001A0000)", "value": "81112001A0000", "key": 318 },
		{ "label": "Online database information retrieval service (81111902A0000)", "value": "81111902A0000", "key": 278 },
		{
			"label": "Online database information retrieval service (personal or individual) (81111902A0001)",
			"value": "81111902A0001",
			"key": 738
		},
		{ "label": "Online Dating Services (91000000A1111)", "value": "91000000A1111", "key": 24 },
		{ "label": "Operating system programming services (81111505A0000)", "value": "81111505A0000", "key": 844 },
		{
			"label": "Optional Extended Warranty Contract on Leased Motor Vehicle (81111818A0004)",
			"value": "81111818A0004",
			"key": 829
		},
		{
			"label": "Optional Extended Warranty Contract on Motor Vehicle - After Market (81111818A0006)",
			"value": "81111818A0006",
			"key": 820
		},
		{
			"label": "Optional Extended Warranty Contract on Motor Vehicle (81111818A0002)",
			"value": "81111818A0002",
			"key": 787
		},
		{
			"label": "Optional Maintenance Contract on Leased Motor Vehicle (81111818A0005)",
			"value": "81111818A0005",
			"key": 824
		},
		{
			"label": "Optional Maintenance Contract on Motor Vehicle - After Market (81111818A0007)",
			"value": "81111818A0007",
			"key": 819
		},
		{ "label": "Optional Maintenance Contract on Motor Vehicle (81111818A0003)", "value": "81111818A0003", "key": 793 },
		{ "label": "OTC Pet Food (10122100A0000)", "value": "10122100A0000", "key": 712 },
		{ "label": "Over-the-Counter Drugs - Pets (51000000A1000)", "value": "51000000A1000", "key": 709 },
		{ "label": "Over-the-Counter Drugs (51010)", "value": "51010", "key": 27 },
		{ "label": "Over-the-Counter Drugs via Prescription (51030)", "value": "51030", "key": 550 },
		{ "label": "Ovulation Testing Kits (41116205A0002)", "value": "41116205A0002", "key": 182 },
		{
			"label": "Oxygen Delivery Equipment for home use with Prescription (42271700A0001)",
			"value": "42271700A0001",
			"key": 230
		},
		{
			"label": "Oxygen Delivery Equipment for home use with Prescription billed to Medicaid (42271700A0003)",
			"value": "42271700A0003",
			"key": 273
		},
		{
			"label": "Oxygen Delivery Equipment for home use with Prescription billed to Medicare (42271700A0002)",
			"value": "42271700A0002",
			"key": 63
		},
		{
			"label": "Oxygen Delivery Equipment for home use with Prescription reimbursed by Medicaid (42271700A0005)",
			"value": "42271700A0005",
			"key": 226
		},
		{
			"label": "Oxygen Delivery Equipment for home use with Prescription reimbursed by Medicare (42271700A0004)",
			"value": "42271700A0004",
			"key": 233
		},
		{
			"label": "Oxygen Delivery Equipment for home use without Prescription (42271700A0006)",
			"value": "42271700A0006",
			"key": 47
		},
		{ "label": "Padlocks - Student (46171501A0001)", "value": "46171501A0001", "key": 112 },
		{ "label": "Painting/Paperhanging Services - Real Property (72151300A0000)", "value": "72151300A0000", "key": 774 },
		{ "label": "Parking Services (19002)", "value": "19002", "key": 25 },
		{ "label": "Payroll Services (87210202A0000)", "value": "87210202A0000", "key": 753 },
		{ "label": "Personal computer PC application design\r\n (81111502A0000)", "value": "81111502A0000", "key": 350 },
		{ "label": "Personal Computers (43211500A0001)", "value": "43211500A0001", "key": 92 },
		{ "label": "Personal Services (72990000A0000)", "value": "72990000A0000", "key": 279 },
		{ "label": "Pest Control Services (72102100A0000)", "value": "72102100A0000", "key": 796 },
		{ "label": "Pet Services - Boarding (81291000A0001)", "value": "81291000A0001", "key": 724 },
		{ "label": "Pet Services - Grooming (81291000A0000)", "value": "81291000A0000", "key": 496 },
		{ "label": "Pet Services - Obedience Training (81291000A0002)", "value": "81291000A0002", "key": 742 },
		{ "label": "Petroleum Jelly (53131641A0000)", "value": "53131641A0000", "key": 199 },
		{ "label": "Port Replicators (43211603A0000)", "value": "43211603A0000", "key": 141 },
		{ "label": "Portable Fuel Container (24111808A0001)", "value": "24111808A0001", "key": 654 },
		{ "label": "Portable Generator (26111604A0001)", "value": "26111604A0001", "key": 84 },
		{ "label": "Portable Light Sources (39111610A0000)", "value": "39111610A0000", "key": 94 },
		{ "label": "Portable Radios (43191510A0000)", "value": "43191510A0000", "key": 498 },
		{ "label": "Powdered Drink Mixes - to be mixed with milk (50202311A0001)", "value": "50202311A0001", "key": 404 },
		{ "label": "Powdered Drink Mixes - to be mixed with water (50202311A0000)", "value": "50202311A0000", "key": 316 },
		{ "label": "Power cords (26121636A0000)", "value": "26121636A0000", "key": 683 },
		{ "label": "Pregnancy Testing Kits (41116205A0001)", "value": "41116205A0001", "key": 840 },
		{ "label": "Prepared Foods (41000)", "value": "41000", "key": 21 },
		{ "label": "Prescription Drugs (51020)", "value": "51020", "key": 17 },
		{ "label": "Printer Ink (44103105A0000)", "value": "44103105A0000", "key": 97 },
		{ "label": "Printer Paper (14111507A0000)", "value": "14111507A0000", "key": 523 },
		{ "label": "Printing - customer supplied articles (19009)", "value": "19009", "key": 698 },
		{ "label": "Printing (82121500A0000)", "value": "82121500A0000", "key": 761 },
		{ "label": "Private Investigator Services (73810204A0000)", "value": "73810204A0000", "key": 731 },
		{ "label": "Professional Services (19005)", "value": "19005", "key": 789 },
		{ "label": "Programmable Wall Thermostat - Energy Star (41112209A0001)", "value": "41112209A0001", "key": 138 },
		{ "label": "Property Surveying Services (87130000A0000)", "value": "87130000A0000", "key": 713 },
		{
			"label": "Proprietary or licensed systems maintenance or support (81111805A0000)",
			"value": "81111805A0000",
			"key": 627
		},
		{ "label": "Prosthetic Devices with Prescription (42242000A0001)", "value": "42242000A0001", "key": 169 },
		{
			"label": "Prosthetic Devices with Prescription Billed to Medicaid (42242000A0003)",
			"value": "42242000A0003",
			"key": 228
		},
		{
			"label": "Prosthetic Devices with Prescription Billed to Medicare (42242000A0002)",
			"value": "42242000A0002",
			"key": 232
		},
		{
			"label": "Prosthetic Devices with Prescription Reimbursed by Medicaid (42242000A0005)",
			"value": "42242000A0005",
			"key": 43
		},
		{
			"label": "Prosthetic Devices with Prescription Reimbursed by Medicare (42242000A0004)",
			"value": "42242000A0004",
			"key": 166
		},
		{ "label": "Prosthetic Devices without Prescription (42242000A0006)", "value": "42242000A0006", "key": 229 },
		{ "label": "Protective wear dispenser (46181553A0001)", "value": "46181553A0001", "key": 588 },
		{ "label": "Protein Powder (50501703A0002)", "value": "50501703A0002", "key": 261 },
		{ "label": "Public Relations (87430000A0000)", "value": "87430000A0000", "key": 728 },
		{ "label": "Pulse Oximeter (42181801A0000)", "value": "42181801A0000", "key": 171 },
		{ "label": "Push pins/tacks (44122106A0000)", "value": "44122106A0000", "key": 90 },
		{ "label": "Quality assurance services\r\n (81111819A0000)", "value": "81111819A0000", "key": 311 },
		{ "label": "Refrigerators - Energy Star (52141501A0000)", "value": "52141501A0000", "key": 80 },
		{ "label": "Religious books (81120)", "value": "81120", "key": 9 },
		{ "label": "Repair Services (19007)", "value": "19007", "key": 208 },
		{ "label": "Restocking Fee (93151599A0000)", "value": "93151599A0000", "key": 822 },
		{ "label": "Ropes and Cords (31151500A0000)", "value": "31151500A0000", "key": 543 },
		{ "label": "Routers (43222609A0000)", "value": "43222609A0000", "key": 96 },
		{ "label": "Scanners (43211711A0000)", "value": "43211711A0000", "key": 482 },
		{ "label": "School Art Supplies (60121200A0001)", "value": "60121200A0001", "key": 500 },
		{ "label": "School Supplies (44121600A0001)", "value": "44121600A0001", "key": 815 },
		{ "label": "Secretarial Services (73389903A0000)", "value": "73389903A0000", "key": 715 },
		{ "label": "Security - Alarm Services (73829901A0000)", "value": "73829901A0000", "key": 721 },
		{ "label": "Security - Guard Services (73810105A0000)", "value": "73810105A0000", "key": 720 },
		{ "label": "Shampoo - medicated (anti-dandruff) (53131628A0001)", "value": "53131628A0001", "key": 528 },
		{ "label": "Shampoo (53131628A0000)", "value": "53131628A0000", "key": 248 },
		{ "label": "Shaving Creams (53131611A0000)", "value": "53131611A0000", "key": 490 },
		{ "label": "Sheet music - Student (55101514A0000)", "value": "55101514A0000", "key": 521 },
		{ "label": "Shoe Repair (53111600A9007)", "value": "53111600A9007", "key": 699 },
		{ "label": "Shower Curtain or Liner (30181607A0000)", "value": "30181607A0000", "key": 117 },
		{ "label": "Showerheads - WaterSense (30181801A0000)", "value": "30181801A0000", "key": 110 },
		{ "label": "Shredding Service (44101603A9007)", "value": "44101603A9007", "key": 249 },
		{ "label": "Skin Care Products (51241200A0002)", "value": "51241200A0002", "key": 70 },
		{ "label": "Skin Care Products- Medicated (51241200A0001)", "value": "51241200A0001", "key": 545 },
		{ "label": "Smoke Detectors (46191501A0000)", "value": "46191501A0000", "key": 129 },
		{ "label": "Smoking Cessation Products (51143218A0000)", "value": "51143218A0000", "key": 449 },
		{ "label": "Soaps - Antibacterial (53131608A0001)", "value": "53131608A0001", "key": 398 },
		{
			"label": "Soft Drinks - Carbonated - 1 -9% vegetable juice (50202306A0002)",
			"value": "50202306A0002",
			"key": 254
		},
		{ "label": "Soft Drinks - Carbonated - 1-9% fruit juice (50202306A0001)", "value": "50202306A0001", "key": 295 },
		{ "label": "Soft Drinks - Carbonated - 10-24% fruit juice (50202306A0003)", "value": "50202306A0003", "key": 65 },
		{
			"label": "Soft Drinks - Carbonated - 10-24% vegetable juice (50202306A0004)",
			"value": "50202306A0004",
			"key": 206
		},
		{
			"label": "Soft Drinks - Carbonated - 100% fruit or vegetable juice (50202306A0011)",
			"value": "50202306A0011",
			"key": 252
		},
		{ "label": "Soft Drinks - Carbonated - 25-50% fruit juice (50202306A0005)", "value": "50202306A0005", "key": 215 },
		{
			"label": "Soft Drinks - Carbonated - 25-50% vegetable juice (50202306A0006)",
			"value": "50202306A0006",
			"key": 217
		},
		{ "label": "Soft Drinks - Carbonated - 51-69% fruit juice (50202306A0007)", "value": "50202306A0007", "key": 219 },
		{
			"label": "Soft Drinks - Carbonated - 51-69% vegetable juice (50202306A0008)",
			"value": "50202306A0008",
			"key": 284
		},
		{ "label": "Soft Drinks - Carbonated - 70-99% fruit juice (50202306A0009)", "value": "50202306A0009", "key": 68 },
		{
			"label": "Soft Drinks - Carbonated - 70-99% vegetable juice (50202306A0010)",
			"value": "50202306A0010",
			"key": 216
		},
		{
			"label": "Soft Drinks - Carbonated - No fruit or vegetable juice (50202306A0000)",
			"value": "50202306A0000",
			"key": 67
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 1 -9% vegetable juice (50202304A0003)",
			"value": "50202304A0003",
			"key": 262
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 1-9% fruit juice (50202304A0002)",
			"value": "50202304A0002",
			"key": 242
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 10-24% fruit juice (50202304A0004)",
			"value": "50202304A0004",
			"key": 281
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 10-24% vegetable juice (50202304A0005)",
			"value": "50202304A0005",
			"key": 66
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 100% fruit or vegetable juice (50202304A0000)",
			"value": "50202304A0000",
			"key": 253
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 25-50% fruit juice (50202304A0006)",
			"value": "50202304A0006",
			"key": 243
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 25-50% vegetable juice (50202304A0007)",
			"value": "50202304A0007",
			"key": 263
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 51-69% fruit juice (50202304A0008)",
			"value": "50202304A0008",
			"key": 244
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 51-69% vegetable juice (50202304A0009)",
			"value": "50202304A0009",
			"key": 264
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 70-99% fruit juice (50202304A0010)",
			"value": "50202304A0010",
			"key": 282
		},
		{
			"label": "Soft Drinks - Non-Carbonated - 70-99% vegetable juice (50202304A0011)",
			"value": "50202304A0011",
			"key": 283
		},
		{
			"label": "Soft Drinks - Non-Carbonated - No fruit or vegetable juice (50202304A0001)",
			"value": "50202304A0001",
			"key": 265
		},
		{ "label": "Soft Drinks (40050)", "value": "40050", "key": 12 },
		{
			"label": "Software - Custom, electronic delivery - Business Use (43230000A9201)",
			"value": "43230000A9201",
			"key": 481
		},
		{ "label": "Software - Custom, electronic delivery (43230000A2200)", "value": "43230000A2200", "key": 44 },
		{ "label": "Software - Custom, load and leave delivery (43230000A2300)", "value": "43230000A2300", "key": 310 },
		{ "label": "Software - Custom, tangible media (43230000A2100)", "value": "43230000A2100", "key": 333 },
		{
			"label": "Software - Prewritten, delivered by digital keycode printed on tangible media (43230000A1400)",
			"value": "43230000A1400",
			"key": 332
		},
		{
			"label": "Software - Prewritten, electronic delivery - Business Use (43230000A9200)",
			"value": "43230000A9200",
			"key": 15
		},
		{
			"label": "Software - Prewritten, Electronic delivery - Non-recreational (43230000A1102)",
			"value": "43230000A1102",
			"key": 107
		},
		{ "label": "Software - Prewritten, electronic delivery (43230000A1200)", "value": "43230000A1200", "key": 626 },
		{ "label": "Software - Prewritten, load and leave delivery (43230000A1300)", "value": "43230000A1300", "key": 331 },
		{
			"label": "Software - Prewritten, tangible media - Non-recreational (43230000A1101)",
			"value": "43230000A1101",
			"key": 93
		},
		{ "label": "Software - Prewritten, tangible media (43230000A1100)", "value": "43230000A1100", "key": 648 },
		{ "label": "Software as a Service (30070)", "value": "30070", "key": 4 },
		{ "label": "Software coding service\r\n (81111810A0000)", "value": "81111810A0000", "key": 220 },
		{
			"label": "Software maintenance and support - Mandatory, custom, electronic delivery (81112200A2210)",
			"value": "81112200A2210",
			"key": 618
		},
		{
			"label": "Software maintenance and support - Mandatory, custom, load and leave delivery (81112200A2310)",
			"value": "81112200A2310",
			"key": 0
		},
		{
			"label": "Software maintenance and support - Mandatory, custom, tangible media (81112200A2110)",
			"value": "81112200A2110",
			"key": 619
		},
		{
			"label": "Software maintenance and support - Mandatory, prewritten, electronic delivery (81112200A1210)",
			"value": "81112200A1210",
			"key": 652
		},
		{
			"label": "Software maintenance and support - Mandatory, prewritten, load and leave delivery (81112200A1310)",
			"value": "81112200A1310",
			"key": 653
		},
		{
			"label": "Software maintenance and support - Mandatory, prewritten, tangible media (81112200A1110)",
			"value": "81112200A1110",
			"key": 286
		},
		{
			"label": "Software maintenance and support - Optional maintenance and support charges for custom software including items delivered on tangible media (includes support services only - no updates/upgrades)\r\n (81112200A2122)",
			"value": "81112200A2122",
			"key": 633
		},
		{
			"label": "Software maintenance and support - Optional, custom, electronic delivery (incl. updates/upgrades) (81112200A2221)",
			"value": "81112200A2221",
			"key": 620
		},
		{
			"label": "Software maintenance and support - Optional, custom, electronic delivery (support services only) (81112200A2222)",
			"value": "81112200A2222",
			"key": 667
		},
		{
			"label": "Software maintenance and support - Optional, custom, load and leave delivery (incl. updates/upgrades) (81112200A2321)",
			"value": "81112200A2321",
			"key": 621
		},
		{
			"label": "Software maintenance and support - Optional, custom, load and leave delivery (support services only) (81112200A2322)",
			"value": "81112200A2322",
			"key": 624
		},
		{
			"label": "Software maintenance and support - Optional, custom, tangible media (incl. updates/upgrades) (81112200A2121)",
			"value": "81112200A2121",
			"key": 629
		},
		{
			"label": "Software maintenance and support - Optional, prewritten, electronic delivery (incl. updates/upgrades) (81112200A1221)",
			"value": "81112200A1221",
			"key": 625
		},
		{
			"label": "Software maintenance and support - Optional, prewritten, electronic delivery (support services only) (81112200A1222)",
			"value": "81112200A1222",
			"key": 634
		},
		{
			"label": "Software maintenance and support - Optional, prewritten, load and leave delivery (incl. updates/upgrades) (81112200A1321)",
			"value": "81112200A1321",
			"key": 635
		},
		{
			"label": "Software maintenance and support - Optional, prewritten, load and leave delivery (support services only) (81112200A1322)",
			"value": "81112200A1322",
			"key": 636
		},
		{
			"label": "Software maintenance and support - Optional, prewritten, tangible media (incl. updates/upgrades) (81112200A1121)",
			"value": "81112200A1121",
			"key": 639
		},
		{
			"label": "Software maintenance and support - Optional, prewritten, tangible media (support services only) (81112200A1122)",
			"value": "81112200A1122",
			"key": 640
		},
		{ "label": "Sound Cards (43201502A0000)", "value": "43201502A0000", "key": 105 },
		{ "label": "Spa Services (72990201A0000)", "value": "72990201A0000", "key": 686 },
		{ "label": "Spray Water Sprinkler Bodies - WaterSense (21101803A0001)", "value": "21101803A0001", "key": 108 },
		{ "label": "Staplers/Staples (44121615A0000)", "value": "44121615A0000", "key": 91 },
		{ "label": "Stenographic Services (73380200A0000)", "value": "73380200A0000", "key": 714 },
		{ "label": "Storm shutters/window protection devices (30151801A0001)", "value": "30151801A0001", "key": 130 },
		{ "label": "Sunglasses - Non-Rx (42142905A0001)", "value": "42142905A0001", "key": 19 },
		{ "label": "Sunscreen (53131609A0000)", "value": "53131609A0000", "key": 529 },
		{ "label": "Supplements (40020)", "value": "40020", "key": 10 },
		{ "label": "Surface Cleaning Wipes (47131502A0001)", "value": "47131502A0001", "key": 34 },
		{ "label": "System analysis service\r\n (81111808A0000)", "value": "81111808A0000", "key": 315 },
		{ "label": "System installation service\r\n (81111809A0000)", "value": "81111809A0000", "key": 131 },
		{
			"label": "System or application programming management service (81111511A0000)",
			"value": "81111511A0000",
			"key": 845
		},
		{ "label": "System usability services\r\n (81111820A0000)", "value": "81111820A0000", "key": 317 },
		{ "label": "Systems architecture\r\n (81111705A0000)", "value": "81111705A0000", "key": 102 },
		{ "label": "Systems integration design\r\n (81111503A0000)", "value": "81111503A0000", "key": 427 },
		{ "label": "Systems planning services\r\n (81111707A0000)", "value": "81111707A0000", "key": 379 },
		{ "label": "Tampons, menstrual cups, pads, liners (53131615A0000)", "value": "53131615A0000", "key": 733 },
		{ "label": "Tanning Services (72990105A0000)", "value": "72990105A0000", "key": 400 },
		{ "label": "Tarpaulins and Weatherproof Sheeting (24141506A0000)", "value": "24141506A0000", "key": 86 },
		{ "label": "Tattooing Services (72990106A0000)", "value": "72990106A0000", "key": 688 },
		{ "label": "Taxidermy Services (82151508A0000)", "value": "82151508A0000", "key": 707 },
		{ "label": "Technical Support Services (81111811A0001)", "value": "81111811A0001", "key": 447 },
		{ "label": "Teeth Whitening Kits (42151506A0000)", "value": "42151506A0000", "key": 204 },
		{ "label": "Temporary Help Services (73630103A0000)", "value": "73630103A0000", "key": 718 },
		{ "label": "Textbook (81110)", "value": "81110", "key": 8 },
		{ "label": "Toilet Paper (14111704A0000)", "value": "14111704A0000", "key": 214 },
		{ "label": "Toilets - WaterSense (30181505A0000)", "value": "30181505A0000", "key": 148 },
		{ "label": "Toothbrushes (53131503A0000)", "value": "53131503A0000", "key": 357 },
		{ "label": "Toothpaste (53131502A0000)", "value": "53131502A0000", "key": 450 },
		{ "label": "Training Services - Live (19004)", "value": "19004", "key": 45 },
		{ "label": "Training Services - Live Virtual (86132201A0000)", "value": "86132201A0000", "key": 191 },
		{ "label": "Training Services - Self-Study, Web-Based (86132000A0002)", "value": "86132000A0002", "key": 548 },
		{ "label": "Tutoring (86132001A0000)", "value": "86132001A0000", "key": 600 },
		{ "label": "Urinals - WaterSense (30181506A0000)", "value": "30181506A0000", "key": 149 },
		{ "label": "Video Cameras (45121515A0000)", "value": "45121515A0000", "key": 200 },
		{
			"label": "Video Games - downloaded - non subscription - with limited rights (60141104A0020)",
			"value": "60141104A0020",
			"key": 801
		},
		{
			"label": "Video Games - downloaded - non subscription - with permanent rights (60141104A0010)",
			"value": "60141104A0010",
			"key": 791
		},
		{
			"label": "Video Games - downloaded - subscription - with conditional rights (60141104A0050)",
			"value": "60141104A0050",
			"key": 802
		},
		{
			"label": "Video Games - streamed - non subscription - with limited rights (60141104A0030)",
			"value": "60141104A0030",
			"key": 772
		},
		{
			"label": "Video Games - streamed - subscription - with conditional rights (60141104A0040)",
			"value": "60141104A0040",
			"key": 773
		},
		{ "label": "Video Gaming Console - Fixed (52161557A0000)", "value": "52161557A0000", "key": 201 },
		{ "label": "Video Gaming Console - Portable (52161558A0000)", "value": "52161558A0000", "key": 64 },
		{ "label": "Video/Graphics Card (43201401A0000)", "value": "43201401A0000", "key": 483 },
		{ "label": "Vitamins - Pets (50501903A0000)", "value": "50501903A0000", "key": 685 },
		{ "label": "Wallets (53121600A0001)", "value": "53121600A0001", "key": 20 },
		{ "label": "Warranty - Mandatory (81111818A0001)", "value": "81111818A0001", "key": 757 },
		{ "label": "Warranty - Optional (81111818A0000)", "value": "81111818A0000", "key": 697 },
		{ "label": "Watches - Smart (54111500A0001)", "value": "54111500A0001", "key": 78 },
		{ "label": "Watches (54111500A0000)", "value": "54111500A0000", "key": 74 },
		{ "label": "Water Conserving Products (21102500A0001)", "value": "21102500A0001", "key": 58 },
		{ "label": "Water heater - Energy Star (40101825A0000)", "value": "40101825A0000", "key": 136 },
		{ "label": "Water storage container (24111810A0001)", "value": "24111810A0001", "key": 122 },
		{ "label": "Web Camera (45121520A0000)", "value": "45121520A0000", "key": 106 },
		{ "label": "Web Hosting Services (81112105A0000)", "value": "81112105A0000", "key": 48 },
		{ "label": "Web Site Design (81112103A0000)", "value": "81112103A0000", "key": 737 },
		{
			"label": "Wide area network communications design\r\n\r\n (81111701A0000)",
			"value": "81111701A0000",
			"key": 428
		},
		{ "label": "Wide area network WAN maintenance or support (81111804A0000)", "value": "81111804A0000", "key": 642 },
		{
			"label": "Wound Care Supplies - Bandages, Dressings, Gauze - for Pets (42311500A0002)",
			"value": "42311500A0002",
			"key": 225
		},
		{
			"label": "Wound Care Supplies - Bandages, Dressings, Gauze - Medicated (42311514A0000)",
			"value": "42311514A0000",
			"key": 222
		},
		{
			"label": "Wound Care Supplies - Bandages, Dressings, Gauze (42311500A0001)",
			"value": "42311500A0001",
			"key": 532
		},
		{
			"label": "Yarn - For use other than fabricating/repairing clothing (11151700A0001)",
			"value": "11151700A0001",
			"key": 488
		},
		{
			"label": "Other Taxable Product or Service",
			"value": "other",
			"key": 9999999999
		}
	]
}
