import {
	GET_TICKET_SALE_STATUS_ERROR,
	GET_TICKET_SALE_STATUS_REQUEST,
	GET_TICKET_SALE_STATUS_SUCCESS,
	TICKET_SALE_STATUS_MODAL_CLOSE,
	TICKET_SALE_STATUS_MODAL_OPEN
} from "../actions/ticketSaleStatusActions";

const initialState = {
	modalOpen: false,
	ticketSaleStatus: [],
	loading: false,
	error: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TICKET_SALE_STATUS_MODAL_OPEN:
			return {
				...state,
				modalOpen: true
			};
		case TICKET_SALE_STATUS_MODAL_CLOSE:
			return {
				...state,
				modalOpen: false
			};
		case GET_TICKET_SALE_STATUS_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				ticketSaleStatus: []
			};
		case GET_TICKET_SALE_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				ticketSaleStatus: action.ticketSaleStatus
			};
		case GET_TICKET_SALE_STATUS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error,
				ticketSaleStatus: []
			};
		default:
			return state;
	}
};
