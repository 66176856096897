import { css } from "@emotion/css";
import P from "./text/P";

const cursor = css({
	cursor: "pointer"
});

const AddField = ({ label, onClickCallback }: { label: string; onClickCallback: () => void }) => {
	return (
		<div className={cursor} onClick={onClickCallback}>
			<P marginBottom={16}>+ {label}</P>
		</div>
	);
};

export default AddField;
