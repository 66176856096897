import axios from "./AxiosWrapper";

class WaitlistService {
	static instance = null;

	static createInstance() {
		let object = new WaitlistService();
		return object;
	}

	static getInstance() {
		if (!WaitlistService.instance) {
			WaitlistService.instance = WaitlistService.createInstance();
		}
		return WaitlistService.instance;
	}

	async find(eventId, queryBy, page) {
		const response = await axios.get(`events/waitlist/${eventId}`, {
			params: { queryBy, page }
		});
		return response.data;
	}

	async getCsv(eventId, queryBy) {
		const response = await axios.get(`events/waitlist/${eventId}/csv`, {
			params: { queryBy }
		});
		return response.data;
	}

	async add(eventId, data) {
		const response = await axios.post(`events/waitlist/join/${eventId}`, {
			data
		});
		return response.data;
	}

	async remove(eventId, ids) {
		const response = await axios.delete(`events/waitlist/${eventId}`, {
			params: { ids }
		});
		return response.data;
	}

	async offerTickets(eventId, eventDateIds, waitlistIds) {
		const response = await axios.post(`events/waitlist/offer-tickets/${eventId}`, {
			eventDateIds,
			waitlistIds
		});
		return response.data;
	}

	async putBackOnSale(eventId) {
		const response = await axios.post(`events/waitlist/put-back-on-sale/${eventId}`);
		return response.data;
	}
}
export default WaitlistService.getInstance();
