import { State } from "@hx/seating-map";
import ax from "axios";
import * as fileStack from "filestack-js";
import { getConfig } from "@/config";
import axios from "./AxiosWrapper";
const { MODES } = State.Assigning;
// init Filestack client
const fsClient = fileStack.init(getConfig("FILESTACK_KEY"));

class SeatingMapService {
	static instance = null;

	static createInstance() {
		let object = new SeatingMapService();
		return object;
	}

	static getInstance() {
		if (!SeatingMapService.instance) {
			SeatingMapService.instance = SeatingMapService.createInstance();
		}
		return SeatingMapService.instance;
	}

	async create(eventId, seatingMapData, capacity) {
		const response = await axios.put(`seatingmaps/${eventId}`, {
			seatingMapData,
			capacity
		});
		return response.data;
	}

	async new(eventId) {
		const response = await axios.put(`seatingmaps/${eventId}/new`);
		return response.data;
	}

	async save(eventId, seatingMapId, seatingMapData, capacity, eventCapacityData) {
		const response = await axios.post(`seatingmaps/${eventId}/${seatingMapId}`, {
			seatingMapData,
			capacity,
			eventCapacityData
		});
		return response.data;
	}

	async findTickets(eventId, eventDateId, seatingMapId, type, searchQuery, page) {
		const params = { searchQuery, page };

		const determineEndpoint = (type) => {
			switch (type) {
				case MODES.ASSIGN_INDIVIDUAL: {
					return "tickets";
				}
				case MODES.ASSIGN_ORDER: {
					return "orders";
				}
				default: {
					return "tickets";
				}
			}
		};

		const response = await axios.get(
			`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/find/${determineEndpoint(type)}`,
			{ params }
		);
		return response.data;
	}

	async getOrganiserMap(eventId, eventDateId, seatingMapId) {
		const response = await axios.get(`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/management`);
		return response.data;
	}

	async get(eventId, seatingMapId, token, orderId) {
		let response;
		if (token && orderId) {
			response = await ax.get(`/public-api/seating-map/${eventId}/${orderId}/${token}`);
		} else {
			response = await axios.get(`seatingmaps/${eventId}/${seatingMapId}/map`);
		}
		return response.data;
	}

	async uploadSectionOverview(image) {
		const result = await fsClient.upload(image);
		return result;
	}

	async move(eventId, eventDateId, seatingMapId, ticketId, to) {
		const response = await axios.post(
			`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/${ticketId}/management/move`,
			{
				to
			}
		);
		return response.data;
	}

	async moveBulk(eventId, eventDateId, seatingMapId, moveArray) {
		const response = await axios.post(`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/management/move/bulk`, {
			moveArray
		});
		return response.data;
	}

	async movePackage(eventId, eventDateId, seatingMapId, moveArray) {
		const response = await axios.post(`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/management/move/package`, {
			moveArray
		});
		return response.data;
	}

	async removeFromEvent(eventId, id) {
		const response = await axios.delete(`seatingmaps/${eventId}/${id}/remove`);
		return response.data;
	}

	async changeSetting(eventId, id, setting, on) {
		const response = await axios.post(`seatingmaps/${eventId}/${id}/settings/${setting}`, {
			on
		});
		return response.data;
	}

	async swap(eventId, eventDateId, seatingMapId, fromTicketId, fromSeat, toSeat, toTicketId) {
		const response = await axios.post(`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/management/swap`, {
			fromTicketId,
			fromSeat,
			toSeat,
			toTicketId
		});
		return response.data;
	}

	async unAssign(eventId, eventDateId, seatingMapId, ticketId) {
		const response = await axios.post(
			`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/${ticketId}/management/unassign`
		);
		return response.data;
	}

	async getUnassignedTickets(eventId, eventDateId, seatingMapId, type, search, page) {
		const determineEndpoint = (type) => {
			switch (type) {
				case MODES.ASSIGN_ORDER:
					return "orders";
				case MODES.ASSIGN_INDIVIDUAL:
				default:
					return "tickets";
			}
		};
		const response = await axios.get(
			`seatingmaps/${eventId}/${eventDateId}/${seatingMapId}/management/unmapped/${determineEndpoint(type)}`,
			{ params: { search, page } }
		);
		return response.data;
	}

	saveTicketMapping(ticketMappingData, seatingMapId, eventId, eventDateId, skipEmails) {
		return new Promise((resolve, reject) => {
			axios
				.post(`seatingmaps/ticketmapping/${eventId}/${eventDateId}/${seatingMapId}`, {
					skipEmails,
					ticketMapping: ticketMappingData
				})
				.then((response) => {
					resolve(response.data);
				})
				.catch((err) => reject(err));
		});
	}
	getAll(eventId, eventDateId) {
		return new Promise((resolve, reject) => {
			axios
				.get(`seatingmaps/${eventId}/${eventDateId}`)
				.then((response) => resolve(response.data))
				.catch((err) => reject(err));
		});
	}

	async getCloneableMaps() {
		const response = await axios.get(`seatingmaps/list`);
		return response.data;
	}
	async cloneMap(eventIdWithoutSeatingMap, eventIdWithSeatingMap, seatingMapId, capacity) {
		const response = await axios.post(`seatingmaps/${eventIdWithoutSeatingMap}/clone`, {
			eventIdWithSeatingMap,
			seatingMapId,
			capacity
		});
		return response.data;
	}
}

export default SeatingMapService.getInstance();
