import {
	AlignCenterOutlined,
	AlignLeftOutlined,
	AlignRightOutlined,
	DownOutlined,
	LeftOutlined,
	RightOutlined,
	UpOutlined
} from "@ant-design/icons";
import { Utils } from "@hx/seating-map";
import { Button, Input, InputNumber, Select } from "@/ui/antd";
import { Component } from "react";
import InputLabel from "../../../components/InputLabel";
import Separator from "./Separator";

const { LabelGenerator } = Utils;
const ButtonGroup = Button.Group;

export default class RowEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showRows: false
		};
	}

	changeRowLength = (i, newRowNum) => {
		const row = this.props.element.getRow(i);
		const activeSeatsNum = row.filter((seat) => !seat.isDisabled()).length;
		const params = [];
		const funcCalls = [];
		const type = "updateRowLength";
		const max = activeSeatsNum < newRowNum ? newRowNum : activeSeatsNum;
		const start = activeSeatsNum < newRowNum ? activeSeatsNum : newRowNum;
		for (let j = start; j < max; j++) {
			const undoVal = this.props.element.updateRowLength(i, newRowNum);
			params.push(undoVal.params);
			funcCalls.push(undoVal.functionCall);
		}
		this.props.performAction(
			funcCalls.map((func, i) => {
				return () => {
					return {
						type,
						functionCall: func,
						params: params[i]
					};
				};
			}),
			params.map(() => [])
		);
	};

	changeRowOffset = (i, newRowOffset) => {
		const { element, performAction } = this.props;
		performAction([element.changeRowOffset], [[newRowOffset, i]]);
	};

	setRowStartAt = (i, newRowStartAt) => {
		const { element, performAction } = this.props;
		performAction([element.setRowStartAt], [[newRowStartAt, i]]);
	};

	offSetRow = (i, amount) => {
		const { element, performAction } = this.props;
		performAction([element.offSetRow], [[i, amount]]);
	};

	align = (alignType) => {
		const { element, performAction } = this.props;
		performAction([element.align], [[alignType]]);
	};

	changeSeatLabelOrder = (seatLabelRight) => {
		const { element, performAction } = this.props;
		performAction([element.setSeatLabelOrder], [[seatLabelRight]]);
	};

	changeRowLabel = (event, i) => {
		const val = event.target.value;
		const { element, performAction } = this.props;
		performAction([element.changeRowLabel], [[val, i]]);
	};

	changeLabelLayout = (label) => {
		const { element, performAction } = this.props;
		performAction([element.setRowLabelLayout], [[label]]);
	};

	changeLabelOrder = (value) => {
		const { element, performAction } = this.props;
		if (element.labelDescending !== value) {
			performAction([element.setLabelOrder], [[value]]);
		}
	};

	changeDefaultRowNumberStartAt = (value) => {
		const { element, performAction } = this.props;
		performAction([element.setDefaultRowNumberStartAt], [[value]]);
	};

	changeRowLabelStartAt = (value) => {
		const { element, performAction } = this.props;
		performAction([element.setRowLabelStartAt], [[value]]);
	};

	render() {
		const { showRows } = this.state;
		const { element } = this.props;
		const rows = element.getRows();
		return (
			<div
				style={{
					overflow: "auto",
					paddingTop: 12,
					display: "flex",
					flexDirection: "column",
					paddingLeft: 2,
					paddingRight: 2
				}}
			>
				<div style={{ display: "flex" }}>
					<div style={{ flexGrow: 1 }}>
						<InputLabel style={{ display: "flex" }}>Align</InputLabel>
						<ButtonGroup>
							<Button onClick={() => this.align("left")} icon={<AlignLeftOutlined />} ariaLabel="Align left" />
							<Button onClick={() => this.align("center")} icon={<AlignCenterOutlined />} ariaLabel="Align center" />
							<Button onClick={() => this.align("right")} icon={<AlignRightOutlined />} ariaLabel="Align right" />
						</ButtonGroup>
					</div>
					<div style={{ maxWidth: 120 }}>
						<InputLabel>Row label layout</InputLabel>
						<Select
							defaultValue="Show all"
							value={element.rowLabelLayout}
							style={{ minWidth: 120 }}
							onChange={this.changeLabelLayout}
							options={[
								{
									value: "showAll",
									label: "Show all"
								},
								{
									value: "showLeft",
									label: "Show left"
								},
								{
									value: "showRight",
									label: "Show right"
								},
								{
									value: "showNone",
									label: "Show none"
								}
							]}
						/>
					</div>
				</div>
				<Separator />
				<div style={{ display: "flex" }}>
					<div style={{ flexGrow: 1 }}>
						<InputLabel>Rows start with</InputLabel>
						<InputNumber
							style={{ display: "inline-block", minWidth: 60, width: 60 }}
							value={element.rowLabelStartAt}
							min={0}
							formatter={(value) => {
								return LabelGenerator(Number(value));
							}}
							onChange={this.changeRowLabelStartAt}
						/>
					</div>
					<div style={{ maxWidth: 122 }}>
						<InputLabel>Row label order</InputLabel>
						<ButtonGroup style={{ maxWidth: 122 }}>
							<Button onClick={() => this.changeLabelOrder(true)} ariaLabel="Left">
								Down
							</Button>
							<Button onClick={() => this.changeLabelOrder(false)} ariaLabel="Right">
								Up
							</Button>
						</ButtonGroup>
					</div>
				</div>
				<Separator />
				<div style={{ display: "flex" }}>
					<div style={{ flexGrow: 1 }}>
						<InputLabel>Seats start with</InputLabel>
						<InputNumber
							style={{ display: "inline-block", minWidth: 60, width: 60 }}
							min={0}
							value={element.defaultRowNumberStartAt}
							onChange={this.changeDefaultRowNumberStartAt}
						/>
					</div>
					<div style={{ maxWidth: 122 }}>
						<InputLabel>Seat label order</InputLabel>
						<ButtonGroup style={{ maxWidth: 122 }}>
							<Button onClick={() => this.changeSeatLabelOrder(false)} ariaLabel="Left">
								Left
							</Button>
							<Button onClick={() => this.changeSeatLabelOrder(true)} ariaLabel="Right">
								Right
							</Button>
						</ButtonGroup>
					</div>
				</div>
				<Separator />
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginBottom: showRows ? 60 : 0
					}}
				>
					<div
						onClick={() => this.setState({ showRows: !showRows })}
						style={{ paddingTop: 12, marginBottom: 12, cursor: "pointer" }}
					>
						<h3 style={{ float: "left" }}>Edit Individual Rows</h3>
						{showRows ? (
							<UpOutlined style={{ paddingLeft: 5, paddingTop: 7 }} />
						) : (
							<DownOutlined style={{ paddingLeft: 5, paddingTop: 7 }} />
						)}
					</div>

					{showRows
						? rows.map((row, i) => {
								const { rowLabel, seats, rowOffset, rowNumberStartAt } = row;
								return (
									<RowCard
										rowLabel={rowLabel}
										rowOffset={rowOffset}
										rowNumberStartAt={rowNumberStartAt}
										seats={seats}
										i={i}
										changeRowLength={this.changeRowLength}
										offSetRow={this.offSetRow}
										changeRowLabel={this.changeRowLabel}
										changeRowOffset={this.changeRowOffset}
										setRowStartAt={this.setRowStartAt}
									/>
								);
						  })
						: null}
				</div>
			</div>
		);
	}
}

const RowCard = ({
	rowLabel,
	rowOffset,
	seats,
	i,
	changeRowLength,
	changeRowLabel,
	changeRowOffset,
	setRowStartAt,
	rowNumberStartAt
}) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				border: "1px solid",
				borderRadius: 4,
				borderColor: "#8E99A8",
				marginBottom: 8
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					background: "#fafafa",
					borderRadius: 4
				}}
			>
				<div
					style={{
						borderRight: "1px solid",
						height: "100%",
						width: "71px",
						textAlign: "center",
						borderColor: "#8E99A8",
						paddingLeft: 10,
						paddingRight: 10
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							height: "100%",
							verticalAlign: "middle",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<div>
							<div style={{ fontSize: 14, fontWeight: 600, textAlign: "left" }}>Row</div>
							<Input
								style={{ display: "inline-block", minWidth: 50, width: 50 }}
								value={rowLabel}
								onChange={(ev) => changeRowLabel(ev, i)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					padding: 10,
					display: "flex",
					flexDirection: "column",
					width: "100%"
				}}
			>
				<div>
					<div style={{ display: "flex", marginBottom: 10 }}>
						<div
							style={{
								fontSize: 14,
								fontWeight: 600,
								textAlign: "left",
								flexGrow: 1,
								paddingTop: 8
							}}
						>
							Number of Seats
						</div>
						<InputNumber
							style={{ width: 55, minWidth: 55 }}
							min={0}
							value={seats.length}
							onChange={(val) => changeRowLength(i, val)}
						/>
					</div>
					<div style={{ display: "flex", marginBottom: 10 }}>
						<div
							style={{
								fontSize: 14,
								fontWeight: 600,
								textAlign: "left",
								flexGrow: 1,
								paddingTop: 8
							}}
						>
							Row starts with
						</div>
						<InputNumber
							style={{ width: 55, minWidth: 55 }}
							min={0}
							value={rowNumberStartAt || 1}
							onChange={(val) => setRowStartAt(i, val)}
						/>
					</div>
					<div style={{ display: "flex" }}>
						<div
							style={{
								fontSize: 14,
								fontWeight: 600,
								textAlign: "left",
								flexGrow: 1,
								paddingTop: 8
							}}
						>
							Offset Row
						</div>
						<ButtonGroup>
							<Button onClick={() => changeRowOffset(i, rowOffset - 0.5)} icon={<LeftOutlined />} ariaLabel="Left" />
							<Button onClick={() => changeRowOffset(i, rowOffset + 0.5)} icon={<RightOutlined />} ariaLabel="Right" />
						</ButtonGroup>
					</div>
				</div>
			</div>
		</div>
	);
};
