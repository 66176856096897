import { useConfetti } from "@/hooks/useConfetti";
import { Accordion, Button, CodeBlock } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type AccordionProps = {
	defaultOpen?: boolean;
	panels: PanelProps[];
}`;

const usage = `import { Accordion } from "@/ui";

<Accordion
  defaultOpen
  panels={[
   {
     borderBottom: true,
     children: "Content 1",
     header: "Panel 1",
     key: "panel 1",
     paddingBottom: 10
   }
  ]}
/>`;

export const AccordionPage: FC = () => {
	const confetti = useConfetti();

	return (
		<Flex gap="xxl" vertical wide>
			<b>Default</b>
			<Accordion
				panels={[
					{
						header: "Dont Open Me",
						key: "1",
						children: (
							<>
								You can read more about Opositional Defiant Disorder{" "}
								<a href="https://en.wikipedia.org/wiki/Oppositional_defiant_disorder">here</a>
								<h3>Put some more stuff in here</h3>
								<Button onClick={() => confetti()} variant="ghost">
									Click Me
								</Button>
							</>
						)
					},
					{
						header: "You can open this one",
						key: "2",
						children: (
							<Button variant="primary" onClick={() => confetti()}>
								Click me for an exciting experience
							</Button>
						)
					}
				]}
			/>
			<br />
			<CodeBlock language="js" codeString={props} title="props" />
			<CodeBlock language="js" codeString={usage} title="sample.tsx" />
		</Flex>
	);
};
