import { css } from "@emotion/css";
import { ReactNode } from "react";

interface INoteProps {
	children: ReactNode;
	style?: Record<string, unknown>;
	bold?: boolean;
}

const Note = ({ children, bold = true }: INoteProps) => {
	const style = css({
		fontWeight: bold ? 600 : "normal",
		fontSize: 12,
		margin: 0,
		marginBottom: 4,
		lineHeight: "17px",
		"@media(max-width: 600px)": {
			fontSize: 12,
			marginBottom: 4
		}
	});
	return <p className={style}>{children}</p>;
};

export default Note;
