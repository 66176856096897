import {
	CAMPAIGN_SUMMARY_ERROR,
	CAMPAIGN_SUMMARY_REQUEST,
	CAMPAIGN_SUMMARY_SUCCESS,
	DELETE_EMAIL_ERROR,
	DELETE_EMAIL_REQUEST,
	DELETE_EMAIL_SUCCESS,
	GET_CAMPAIGN_ERROR,
	GET_CAMPAIGN_REQUEST,
	GET_CAMPAIGN_SUCCESS,
	GET_SOCIAL_IMPACT_EMAIL_ERROR,
	GET_SOCIAL_IMPACT_EMAIL_REQUEST,
	GET_SOCIAL_IMPACT_EMAIL_SUCCESS,
	GET_UNSUBSCRIBERS_ERROR,
	GET_UNSUBSCRIBERS_REQUEST,
	GET_UNSUBSCRIBERS_SUCCESS,
	HIDE_PREVIEW_EMAIL_MODAL,
	HIDE_TEST_EMAIL_MODAL,
	LIST_EMAIL_ERROR,
	LIST_EMAIL_REQUEST,
	LIST_EMAIL_SUCCESS,
	SAVE_DRAFT_CAMPAIGN_ERROR,
	SAVE_DRAFT_CAMPAIGN_REQUEST,
	SAVE_DRAFT_CAMPAIGN_SUCCESS,
	SAVE_ENQUEUE_CAMPAIGN_ERROR,
	SAVE_ENQUEUE_CAMPAIGN_REQUEST,
	SAVE_ENQUEUE_CAMPAIGN_SUCCESS,
	SEND_TEST_EMAIL_ERROR,
	SEND_TEST_EMAIL_REQUEST,
	SEND_TEST_EMAIL_SUCCESS,
	SHOW_PREVIEW_EMAIL_MODAL_ERROR,
	SHOW_PREVIEW_EMAIL_MODAL_REQUEST,
	SHOW_PREVIEW_EMAIL_MODAL_SUCCESS,
	SHOW_TEST_EMAIL_MODAL,
	APPROVE_CHANGE_REQUEST,
	APPROVE_CHANGE_SUCCESS,
	APPROVE_CHANGE_ERROR
} from "../actions/emailCampaignsActions";

const initialState = {
	previewModeVisible: false,
	previewCampaign: null,
	testModalVisible: false,
	testCampaign: null,
	list: {
		loading: false,
		error: false,
		data: null,
		count: 0
	},
	campaign: {
		loading: false,
		error: false,
		data: null
	},
	testEmail: {
		loading: false,
		error: false
	},
	campaignPreview: {
		loading: false,
		error: false,
		html: null
	},
	unsubscriptions: {
		loading: false,
		error: false,
		page: 1,
		data: null
	},
	socialImpactTemplate: {
		loading: false,
		error: false,
		data: null
	},
	campaignSummary: {
		loading: false,
		error: false,
		data: null
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_PREVIEW_EMAIL_MODAL_REQUEST:
			return {
				...state,
				previewModeVisible: true,
				campaignPreview: {
					loading: true,
					error: false,
					html: null
				}
			};
		case SHOW_PREVIEW_EMAIL_MODAL_SUCCESS:
			return {
				...state,
				previewModeVisible: true,
				campaignPreview: {
					loading: false,
					error: false,
					html: action.html
				}
			};
		case SHOW_PREVIEW_EMAIL_MODAL_ERROR:
			return {
				...state,
				previewModeVisible: true,
				campaignPreview: {
					loading: false,
					error: action.error,
					html: null
				}
			};
		case HIDE_PREVIEW_EMAIL_MODAL:
			return {
				...state,
				previewCampaign: null,
				previewModeVisible: false,
				campaignPreview: {
					loading: false,
					error: false,
					html: null
				}
			};
		case SHOW_TEST_EMAIL_MODAL:
			return {
				...state,
				testCampaign: action.campaign,
				testModalVisible: true
			};
		case HIDE_TEST_EMAIL_MODAL:
			return {
				...state,
				testModalVisible: false
			};
		case LIST_EMAIL_REQUEST:
			return {
				...state,
				list: {
					...state.list,
					loading: true,
					error: false,
					data: null
				}
			};
		case LIST_EMAIL_SUCCESS: {
			return {
				...state,
				list: {
					loading: false,
					error: false,
					data: action.campaigns,
					count: action.count
				}
			};
		}
		case LIST_EMAIL_ERROR:
			return {
				...state,
				list: {
					...state.list,
					loading: false,
					error: action.error,
					data: null
				}
			};
		case GET_CAMPAIGN_REQUEST:
			return {
				...state,
				campaign: {
					loading: true,
					error: false,
					data: null
				}
			};
		case GET_CAMPAIGN_SUCCESS:
			return {
				...state,
				campaign: {
					loading: false,
					error: false,
					data: action.campaign
				}
			};
		case GET_CAMPAIGN_ERROR:
			return {
				...state,
				campaign: {
					loading: false,
					error: action.error,
					data: null
				}
			};
		case GET_UNSUBSCRIBERS_REQUEST:
			return {
				...state,
				unsubscriptions: {
					...state.unsubscriptions,
					loading: true,
					error: false
				}
			};
		case GET_UNSUBSCRIBERS_SUCCESS:
			return {
				...state,
				unsubscriptions: {
					...state.unsubscriptions,
					loading: false,
					error: false,
					data: action.result
				}
			};
		case GET_UNSUBSCRIBERS_ERROR:
			return {
				...state,
				unsubscriptions: {
					...state.unsubscriptions,
					loading: false,
					error: action.error
				}
			};
		case SEND_TEST_EMAIL_REQUEST:
			return {
				...state,
				testEmail: {
					loading: true,
					error: false
				}
			};
		case SEND_TEST_EMAIL_SUCCESS:
			return {
				...state,
				testEmail: {
					loading: false,
					error: false
				}
			};
		case SEND_TEST_EMAIL_ERROR:
			return {
				...state,
				testEmail: {
					loading: false,
					error: action.error
				}
			};

		case SAVE_DRAFT_CAMPAIGN_REQUEST:
		case APPROVE_CHANGE_REQUEST:
			return {
				...state,
				campaign: {
					loading: true,
					error: false,
					data: null
				}
			};
		case SAVE_DRAFT_CAMPAIGN_SUCCESS:
		case APPROVE_CHANGE_SUCCESS:
			return {
				...state,
				campaign: {
					loading: false,
					error: false,
					data: action.campaign
				}
			};
		case SAVE_DRAFT_CAMPAIGN_ERROR:
		case APPROVE_CHANGE_ERROR:
			return {
				...state,
				campaign: {
					loading: false,
					error: action.error,
					data: null
				}
			};

		case SAVE_ENQUEUE_CAMPAIGN_REQUEST:
			return {
				...state,
				campaign: {
					loading: true,
					error: false,
					data: null
				}
			};
		case SAVE_ENQUEUE_CAMPAIGN_SUCCESS:
			return {
				...state,
				campaign: {
					loading: false,
					error: false,
					//User is sent back to listing
					data: null
				}
			};
		case SAVE_ENQUEUE_CAMPAIGN_ERROR:
			return {
				...state,
				campaign: {
					loading: false,
					error: action.error,
					data: null
				}
			};

		case DELETE_EMAIL_REQUEST:
			return {
				...state,
				campaign: {
					loading: true,
					error: false,
					data: null
				}
			};
		case DELETE_EMAIL_SUCCESS:
			return {
				...state,
				campaign: {
					loading: false,
					error: false,
					data: null
				}
			};
		case DELETE_EMAIL_ERROR:
			return {
				...state,
				campaign: {
					loading: false,
					error: action.error,
					data: null
				}
			};
		case GET_SOCIAL_IMPACT_EMAIL_REQUEST:
			return {
				...state,
				socialImpactTemplate: {
					loading: true,
					error: false,
					data: null
				}
			};
		case GET_SOCIAL_IMPACT_EMAIL_SUCCESS:
			return {
				...state,
				socialImpactTemplate: {
					loading: false,
					error: false,
					data: action.html
				}
			};
		case GET_SOCIAL_IMPACT_EMAIL_ERROR:
			return {
				...state,
				socialImpactTemplate: {
					loading: false,
					error: action.error,
					data: null
				}
			};
		case CAMPAIGN_SUMMARY_REQUEST:
			return {
				...state,
				campaignSummary: {
					loading: true,
					error: false,
					data: null
				}
			};
		case CAMPAIGN_SUMMARY_SUCCESS:
			return {
				...state,
				campaignSummary: {
					loading: false,
					error: false,
					data: action.result
				}
			};
		case CAMPAIGN_SUMMARY_ERROR:
			return {
				...state,
				campaignSummary: {
					loading: false,
					error: action.error,
					data: null
				}
			};
		default:
			return state;
	}
};
