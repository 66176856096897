import countries from "./countryCodes.json";

type Country = {
	code: string;
	country: string;
	countryAdjective?: string;
};

type MapPosition = {
	lat: number;
	lng: number;
	zoom: number;
};

export const getCountryName = (countryCode: string, adjective = true): string => {
	const country = (countries as Country[]).find((c) => c.code === countryCode);
	if (country) {
		return adjective && country.countryAdjective ? country.countryAdjective : country.country;
	}
	return "Unknown country";
};

export const getCountryOptions = (): { value: string; label: string }[] =>
	(countries as Country[]).map((country) => ({ value: country.country, label: country.country }));

export const getCountryMapPosition = (countryCode: string): MapPosition => {
	switch (countryCode) {
		case "FJ":
			return {
				lat: -16.7421849,
				lng: 177.3178666,
				zoom: 6
			};
		case "CA":
			return {
				lat: 54.6990401,
				lng: -113.7099442,
				zoom: 3
			};
		case "GB":
			return {
				lat: 55.378052,
				lng: -3.435973,
				zoom: 4
			};
		case "US":
			return {
				lat: 37.09024,
				lng: -95.712891,
				zoom: 2
			};
		case "AU":
			return {
				lat: -25.274399,
				lng: 133.775131,
				zoom: 3
			};
		default:
			return {
				lat: 0,
				lng: 0,
				zoom: 1
			};
	}
};
