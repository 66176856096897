import { css } from "@emotion/css";
import H1 from "./text/H1";
import P from "./text/P";

interface HeadingWithSubheadingProps {
	heading: string;
	subheading: string;
}

const HeadingWithSubheading = ({ heading, subheading }: HeadingWithSubheadingProps) => {
	const wrapper = css({
		h1: {
			marginBottom: 4,
			"@media(max-width: 600px)": {
				marginBottom: 2
			}
		},
		p: {
			color: "#5a626c",
			marginBottom: 16
		}
	});

	return (
		<div className={wrapper}>
			<H1>{heading}</H1>
			<P>{subheading}</P>
		</div>
	);
};

export default HeadingWithSubheading;
