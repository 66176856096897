import { notification } from "@/ui/antd";
import AdminUtilitiesService from "../../services/AdminUtilitiesService";

export const RUN_FRAUD_CHECK_FOR_EVENT_REQUEST = "RUN_FRAUD_CHECK_FOR_EVENT_REQUEST";
export const RUN_FRAUD_CHECK_FOR_EVENT_SUCCESS = "RUN_FRAUD_CHECK_FOR_EVENT_SUCCESS";
export const RUN_FRAUD_CHECK_FOR_EVENT_ERROR = "RUN_FRAUD_CHECK_FOR_EVENT_ERROR";

export const runFraudCheckForEvent = (id) => {
	return (dispatch) => {
		dispatch({ type: RUN_FRAUD_CHECK_FOR_EVENT_REQUEST });
		AdminUtilitiesService.runFraudCheckForEvent(id)
			.then(() => {
				notification.success({
					message: `Fraud check ran for event:${id}`
				});
				dispatch({ type: RUN_FRAUD_CHECK_FOR_EVENT_SUCCESS });
			})
			.catch((err) => {
				notification.error({
					message: `Fraud check failed`
				});
				dispatch({
					type: RUN_FRAUD_CHECK_FOR_EVENT_ERROR,
					message: err.message
				});
			});
	};
};
