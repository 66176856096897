import { getConfig } from "@/config";
import { WidgetAddToSiteButton } from "@/events/event/widgets/WidgetAddToSiteButton";
import { useAppSelector } from "@/state/hooks";
import { CodeBlock } from "@/ui";
import { Alert, Input, Switch } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, Fragment, useState } from "react";
import { Link } from "react-router-dom";

const Subheading = styled.h3`
	font-size: 16px;
	font-weight: 600;
	margin: 0 0 12px;
`;

const createEventWidgetUrl = (eventsDomain: string, eventSlug: string, queryParams: string) =>
	`${eventsDomain}/${eventSlug}/tickets?${queryParams}`;

export const PopupWidgetBuilderConfig: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const [disableAnalytics, setDisableAnalytics] = useState(false);
	const [accessCode, setAccessCode] = useState("");
	const [discountCode, setDiscountCode] = useState("");
	const [affiliateTracking, setAffiliateTracking] = useState("");

	const queryParams = new URLSearchParams("widget=popup");
	if (accessCode) queryParams.set("accessCode", accessCode);
	if (affiliateTracking) queryParams.set("c", affiliateTracking);
	if (discountCode) queryParams.set("discountCode", discountCode);
	if (disableAnalytics) queryParams.set("da", "true");

	const queryParamsAttr = queryParams.size > 0 ? queryParams.toString() : "";
	const eventsUrl = getConfig("EVENTS_URL") ?? "https://events.humanitix.com";

	return (
		<Fragment>
			<Alert
				message={
					<div>
						To make changes to your Select Tickets and Checkout pages, go to the{" "}
						<Link to={`/console/my-events/${currentEvent.event?._id}/styling`}>Styling page</Link>.
					</div>
				}
				showIcon
				type="info"
			/>
			<br />
			<Subheading>Disable analytics integrations</Subheading>
			<p>
				When active, any analytics integrations associated with this event will be disabled within the widget itself.{" "}
				<Link to="https://help.humanitix.com/en/articles/8950947">Learn more</Link>.
			</p>
			<Switch checked={disableAnalytics} onChange={(checked) => setDisableAnalytics(checked)} />
			<br />
			<Subheading>Discount code</Subheading>
			<p>To apply a discount code to the tickets widget, enter the code below.</p>
			<Input placeholder="Discount code" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
			<br />
			<Subheading>Access code</Subheading>
			<p>To apply an access code to the tickets widget, enter the code below.</p>
			<Input placeholder="Access code" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
			<br />
			<Subheading>Affiliate tracking</Subheading>
			<p>When active, the widget will track the affiliate code in the URL and apply it to the checkout page.</p>
			<Input
				placeholder="Affiliate code"
				value={affiliateTracking}
				onChange={(e) => setAffiliateTracking(e.target.value)}
			/>
			<br />
			<Subheading>Embedding the pop-up on your site</Subheading>
			<p>
				To embed the pop-up on your site, take the code from the Add to site button below and add it to your website.
			</p>
			<WidgetAddToSiteButton>
				<Alert
					message="If you are using a website builder, you may need to add the code directly to the HTML of your page rather than using a
					visual editor component."
					showIcon
					type="info"
				/>
				<br />
				<h2>Step 1</h2>
				<p>
					Copy this code into your website's <b>&lt;head&gt;</b> section.
				</p>
				<CodeBlock
					codeString={`<script src="${eventsUrl}/scripts/widgets/popup.js" type="module"></script>`}
					language="htmlbars"
					title="HTML"
				/>
				<br />
				<h2>Step 2</h2>
				<p>Use the following URL within any link element on your website to open the pop-up widget.</p>
				<CodeBlock
					codeString={createEventWidgetUrl(eventsUrl, currentEvent.event?.slug ?? "", queryParamsAttr.toString())}
					language="plaintext"
					title="URL"
				/>
			</WidgetAddToSiteButton>
		</Fragment>
	);
};
