import InputWrapper from "@/components/fields/helpers/InputWrapper";
import { Input } from "@/ui/antd";
import { formatDateString } from "@/utils/Format";
import { FC, useEffect, useState } from "react";

type InputFieldProps = {
	addonBefore?: any;
	autoFocus?: boolean;
	dataCy?: string;
	description?: string;
	disabled?: boolean;
	inline?: boolean;
	input: any;
	inputType?: string;
	label: string;
	maxLength?: number;
	meta: { error: string; touched: boolean };
	noMargin?: boolean;
	normalize?: (value: any) => any;
	placeholder?: string;
	prefix?: any;
	required?: boolean;
	size?: "default" | "large" | "small";
	style?: any;
	tooltip?: string;
	tooltipIcon?: boolean;
	tooltipIconOnClick?: () => void;
	type?: string;
};

const LegacyInputField: FC<InputFieldProps> = ({
	addonBefore,
	autoFocus,
	dataCy,
	description,
	disabled,
	inline,
	input,
	inputType,
	label,
	maxLength = 10000,
	meta: { touched, error },
	noMargin = false,
	normalize,
	placeholder,
	prefix,
	required,
	size = "middle",
	style,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	type
}) => {
	// Manage the internal state because antd and redux form don't play nice together
	const [value, setValue] = useState(input.value);

	useEffect(() => {
		if (input.value !== value) {
			setValue(input.value);
		}
	}, [input.value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		input.onChange(e);
	};

	inputType = inputType ? inputType : type;
	if (inputType === "date") {
		input.value = formatDateString(input.value);
	}

	return (
		<InputWrapper
			description={description}
			error={error}
			id={input.name}
			inline={inline}
			label={label}
			noMargin={noMargin}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
			touched={touched}
		>
			<Input
				id={input.name}
				prefix={prefix}
				{...input}
				addonBefore={addonBefore}
				autoFocus={autoFocus}
				data-cy={dataCy}
				disabled={disabled}
				maxLength={maxLength}
				normalize={normalize}
				onChange={handleChange}
				placeholder={placeholder}
				size={size}
				style={style}
				type={inputType}
				value={value}
			/>
		</InputWrapper>
	);
};

export default LegacyInputField;
