import { notification } from "@/ui/antd";
import AdminReportService from "@/services/AdminReportService";
import FileDownload from "@/utils/FileDownload";
import { getLocationFromState } from "../locationActions";
import { REPORT_DATE_CHANGE } from "./datesActions";

export const CONTROL_REPORT_REQUEST = "CONTROL_REPORT_REQUEST";
export const CONTROL_REPORT_SUCCESS = "CONTROL_REPORT_SUCCESS";
export const CONTROL_REPORT_ERROR = "CONTROL_REPORT_ERROR";
export const CONTROL_REPORT_RESET = "CONTROL_REPORT_RESET";

export const SALES_SUMMARY_REQUEST = "SALES_SUMMARY_REQUEST";
export const SALES_SUMMARY_SUCCESS = "SALES_SUMMARY_SUCCESS";
export const SALES_SUMMARY_ERROR = "SALES_SUMMARY_ERROR";
export const SALES_SUMMARY_RESET = "SALES_SUMMARY_RESET";

export const REFUNDS_REPORT_REQUEST = "REFUNDS_REPORT_REQUEST";
export const REFUNDS_REPORT_SUCCESS = "REFUNDS_REPORT_SUCCESS";
export const REFUNDS_REPORT_ERROR = "REFUNDS_REPORT_ERROR";
export const REFUNDS_REPORT_RESET = "REFUNDS_REPORT_RESET";

export const FEES_REPORT_REQUEST = "FEES_REPORT_REQUEST";
export const FEES_REPORT_SUCCESS = "FEES_REPORT_SUCCESS";
export const FEES_REPORT_ERROR = "FEES_REPORT_ERROR";
export const FEES_REPORT_RESET = "FEES_REPORT_RESET";

export const FEES_DETAILS_REPORT_REQUEST = "FEES_DETAILS_REPORT_REQUEST";
export const FEES_DETAILS_REPORT_SUCCESS = "FEES_DETAILS_REPORT_SUCCESS";
export const FEES_DETAILS_REPORT_ERROR = "FEES_DETAILS_REPORT_ERROR";
export const FEES_DETAILS_REPORT_RESET = "FEES_DETAILS_REPORT_RESET";
export const FEES_DETAILS_CSV_REPORT_REQUEST = "FEES_DETAILS_CSV_REPORT_REQUEST";
export const FEES_DETAILS_CSV_REPORT_SUCCESS = "FEES_DETAILS_CSV_REPORT_SUCCESS";
export const FEES_DETAILS_CSV_REPORT_ERROR = "FEES_DETAILS_CSV_REPORT_ERROR";

export const FEES_CRYSTALISATION_REPORT_REQUEST = "FEES_CRYSTALISATION_REPORT_REQUEST";
export const FEES_CRYSTALISATION_REPORT_SUCCESS = "FEES_CRYSTALISATION_REPORT_SUCCESS";
export const FEES_CRYSTALISATION_REPORT_ERROR = "FEES_CRYSTALISATION_REPORT_ERROR";
export const FEES_CRYSTALISATION_REPORT_RESET = "FEES_CRYSTALISATION_REPORT_RESET";

export const ADJUSTMENTS_REPORT_REQUEST = "ADJUSTMENTS_REPORT_REQUEST";
export const ADJUSTMENTS_REPORT_SUCCESS = "ADJUSTMENTS_REPORT_SUCCESS";
export const ADJUSTMENTS_REPORT_ERROR = "ADJUSTMENTS_REPORT_ERROR";
export const ADJUSTMENTS_REPORT_RESET = "ADJUSTMENTS_REPORT_RESET";

export const ADJUSTMENTS_DETAILS_REPORT_REQUEST = "ADJUSTMENTS_DETAILS_REPORT_REQUEST";
export const ADJUSTMENTS_DETAILS_REPORT_SUCCESS = "ADJUSTMENTS_DETAILS_REPORT_SUCCESS";
export const ADJUSTMENTS_DETAILS_REPORT_ERROR = "ADJUSTMENTS_DETAILS_REPORT_ERROR";
export const ADJUSTMENTS_DETAILS_REPORT_RESET = "ADJUSTMENTS_DETAILS_REPORT_RESET";

export const PAYOUTS_REPORT_REQUEST = "PAYOUTS_REPORT_REQUEST";
export const PAYOUTS_REPORT_SUCCESS = "PAYOUTS_REPORT_SUCCESS";
export const PAYOUTS_REPORT_ERROR = "PAYOUTS_REPORT_ERROR";
export const PAYOUTS_REPORT_RESET = "PAYOUTS_REPORT_RESET";

export const PAYOUTS_DETAILS_REPORT_REQUEST = "PAYOUTS_DETAILS_REPORT_REQUEST";
export const PAYOUTS_DETAILS_REPORT_SUCCESS = "PAYOUTS_DETAILS_REPORT_SUCCESS";
export const PAYOUTS_DETAILS_REPORT_ERROR = "PAYOUTS_DETAILS_REPORT_ERROR";
export const PAYOUTS_DETAILS_REPORT_RESET = "PAYOUTS_DETAILS_REPORT_RESET";

export const PAYOUTS_REVERSED_REPORT_REQUEST = "PAYOUTS_REVERSED_REPORT_REQUEST";
export const PAYOUTS_REVERSED_REPORT_SUCCESS = "PAYOUTS_REVERSED_REPORT_SUCCESS";
export const PAYOUTS_REVERSED_REPORT_ERROR = "PAYOUTS_REVERSED_REPORT_ERROR";
export const PAYOUTS_REVERSED_REPORT_RESET = "PAYOUTS_REVERSED_REPORT_RESET";

export const BOARD_REPORT_REQUEST = "BOARD_REPORT_REQUEST";
export const BOARD_REPORT_SUCCESS = "BOARD_REPORT_SUCCESS";
export const BOARD_REPORT_ERROR = "BOARD_REPORT_ERROR";
export const BOARD_REPORT_RESET = "BOARD_REPORT_RESET";

export const MONEY_HELD_REPORT_REQUEST = "MONEY_HELD_REPORT_REQUEST";
export const MONEY_HELD_REPORT_SUCCESS = "MONEY_HELD_REPORT_SUCCESS";
export const MONEY_HELD_REPORT_ERROR = "MONEY_HELD_REPORT_ERROR";
export const MONEY_HELD_REPORT_RESET = "MONEY_HELD_REPORT_RESET";

export const MONEY_HELD_CSV_REPORT_REQUEST = "MONEY_HELD_CSV_REPORT_REQUEST";
export const MONEY_HELD_CSV_REPORT_SUCCESS = "MONEY_HELD_CSV_REPORT_SUCCESS";
export const MONEY_HELD_CSV_REPORT_ERROR = "MONEY_HELD_CSV_REPORT_ERROR";

export const GIFT_CARDS_HELD_REPORT_REQUEST = "GIFT_CARDS_HELD_REPORT_REQUEST";
export const GIFT_CARDS_HELD_REPORT_SUCCESS = "GIFT_CARDS_HELD_REPORT_SUCCESS";
export const GIFT_CARDS_HELD_REPORT_ERROR = "GIFT_CARDS_HELD_REPORT_ERROR";
export const GIFT_CARDS_HELD_REPORT_RESET = "GIFT_CARDS_HELD_REPORT_RESET";

export const GIFT_CARDS_HELD_CSV_REPORT_REQUEST = "GIFT_CARDS_HELD_CSV_REPORT_REQUEST";
export const GIFT_CARDS_HELD_CSV_REPORT_SUCCESS = "GIFT_CARDS_HELD_CSV_REPORT_SUCCESS";
export const GIFT_CARDS_HELD_CSV_REPORT_ERROR = "GIFT_CARDS_HELD_CSV_REPORT_ERROR";

export const REFERRAL_PAYOUT_REPORT_REQUEST = "REFERRAL_PAYOUT_REPORT_REQUEST";
export const REFERRAL_PAYOUT_REPORT_SUCCESS = "REFERRAL_PAYOUT_REPORT_SUCCESS";
export const REFERRAL_PAYOUT_REPORT_ERROR = "REFERRAL_PAYOUT_REPORT_ERROR";

export const resetBoardReport = () => {
	return (dispatch) => {
		dispatch({ type: BOARD_REPORT_RESET });
	};
};

export const getBoardReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({
			type: REPORT_DATE_CHANGE,
			startDate,
			endDate,
			dayEndsAt
		});
		dispatch({ type: BOARD_REPORT_REQUEST });
		AdminReportService.getBoardReport(startDate, endDate, location)
			.then((data) => {
				dispatch({ type: BOARD_REPORT_SUCCESS, data });
				notification.success({
					message: "Success",
					description: "Your reports has been queued you will receive an email when it is ready"
				});
			})
			.catch((err) => {
				dispatch({ type: BOARD_REPORT_ERROR, error: err });
			});
	};
};
export const resetMoneyHeldReport = () => {
	return (dispatch) => {
		dispatch({ type: MONEY_HELD_REPORT_RESET });
	};
};

export const getMoneyHeldReport = (soldBefore, heldAt, dayEndsAt, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: MONEY_HELD_REPORT_REQUEST, page });
		AdminReportService.getMoneyHeldReport(soldBefore, heldAt, dayEndsAt, page, location)
			.then(() => {
				notification.success({
					message: "Success",
					description: "Your reports has been queued you will receive an email when it is ready"
				});
				dispatch({ type: MONEY_HELD_REPORT_SUCCESS });
			})
			.catch((err) => {
				dispatch({ type: MONEY_HELD_REPORT_ERROR, error: err });
			});
	};
};

export const getMoneyHeldNowReport = () => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: MONEY_HELD_REPORT_REQUEST });
		AdminReportService.getMoneyHeldNowReport(location)
			.then(() => {
				console.log("getMoneyHeldNowReport action success");
				notification.success({
					message: "Success",
					description: "Your reports has been queued you will receive an email when it is ready"
				});
				dispatch({ type: MONEY_HELD_REPORT_SUCCESS });
			})
			.catch((err) => {
				dispatch({ type: MONEY_HELD_REPORT_ERROR, error: err });
			});
	};
};

export const resetGiftCardsHeldReport = () => {
	return (dispatch) => {
		dispatch({ type: GIFT_CARDS_HELD_REPORT_RESET });
	};
};

export const getGiftCardsHeldReport = (heldAt, dayEndsAt, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GIFT_CARDS_HELD_REPORT_REQUEST, page });
		AdminReportService.getGiftCardsHeldReport(heldAt, dayEndsAt, page, location)
			.then((data) => {
				dispatch({ type: GIFT_CARDS_HELD_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GIFT_CARDS_HELD_REPORT_ERROR, error: err });
			});
	};
};

export const getGiftCardsHeldReportCsv = (heldAt, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GIFT_CARDS_HELD_CSV_REPORT_REQUEST });
		const filename = `gift-cards-held-report-${String(heldAt).split("T")[0]}.csv`;
		AdminReportService.getGiftCardsHeldReportCsv(heldAt, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: GIFT_CARDS_HELD_CSV_REPORT_SUCCESS });
				FileDownload.saveAs(data, filename);
			})
			.catch((err) => {
				dispatch({ type: GIFT_CARDS_HELD_CSV_REPORT_ERROR, error: err });
			});
	};
};

export const resetControlReport = () => {
	return (dispatch) => {
		dispatch({ type: CONTROL_REPORT_RESET });
	};
};

export const getControlReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({
			type: REPORT_DATE_CHANGE,
			startDate,
			endDate,
			dayEndsAt
		});
		dispatch({ type: CONTROL_REPORT_REQUEST });
		AdminReportService.getControlReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				notification.success({
					message: "Success",
					description: "Your reports has been queued you will receive an email when it is ready"
				});
				dispatch({ type: CONTROL_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: CONTROL_REPORT_ERROR, error: err });
			});
	};
};

export const resetTicketSalesSummary = () => {
	return (dispatch) => {
		dispatch({ type: SALES_SUMMARY_RESET });
	};
};

export const getTicketSalesSummary = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: SALES_SUMMARY_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getTicketSalesSummary(startDate, endDate, dayEndsAt, fundsLocation, location)
			.then((data) => {
				dispatch({ type: SALES_SUMMARY_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: SALES_SUMMARY_ERROR, error: err });
			});
	};
};

export const getTicketSalesSummaryCsv = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `ticket-sales-summary-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getTicketSalesSummaryCsv(startDate, endDate, dayEndsAt, fundsLocation, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetRefundsReport = () => {
	return (dispatch) => {
		dispatch({ type: REFUNDS_REPORT_RESET });
	};
};

export const getRefundsReport = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: REFUNDS_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getRefundsReport(startDate, endDate, dayEndsAt, fundsLocation, location)
			.then((data) => {
				dispatch({ type: REFUNDS_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: REFUNDS_REPORT_ERROR, error: err });
			});
	};
};

export const getRefundsReportCsv = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `refunds-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getRefundsReportCsv(startDate, endDate, dayEndsAt, fundsLocation, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetFeesReport = () => {
	return (dispatch) => {
		dispatch({ type: FEES_REPORT_RESET });
	};
};

export const getFeesReport = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FEES_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getFeesReport(startDate, endDate, dayEndsAt, fundsLocation, location)
			.then((data) => {
				dispatch({ type: FEES_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: FEES_REPORT_ERROR, error: err });
			});
	};
};

export const resetFeeCrystalisation = () => {
	return (dispatch) => {
		dispatch({ type: FEES_CRYSTALISATION_REPORT_RESET });
	};
};

export const getFeeCrystalisationReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FEES_CRYSTALISATION_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getFeeCrystalisationReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: FEES_CRYSTALISATION_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: FEES_CRYSTALISATION_REPORT_ERROR, error: err });
			});
	};
};

export const getFeesReportCsv = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `fees-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getFeesReportCsv(startDate, endDate, dayEndsAt, fundsLocation, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetFeesDetailsReport = () => {
	return (dispatch) => {
		dispatch({ type: FEES_DETAILS_REPORT_RESET });
	};
};

export const getFeesDetailsReport = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FEES_DETAILS_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getFeesDetailsReport(startDate, endDate, dayEndsAt, fundsLocation, location)
			.then((data) => {
				dispatch({ type: FEES_DETAILS_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: FEES_DETAILS_REPORT_ERROR, error: err });
			});
	};
};

export const getFeesDetailsReportCsv = (startDate, endDate, dayEndsAt, fundsLocation) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FEES_DETAILS_CSV_REPORT_REQUEST });
		const filename = `fees-details-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getFeesDetailsReportCsv(startDate, endDate, dayEndsAt, fundsLocation, location)
			.then((data) => {
				dispatch({ type: FEES_DETAILS_CSV_REPORT_SUCCESS });
				FileDownload.saveAs(data, filename);
			})
			.catch((err) => {
				dispatch({ type: FEES_DETAILS_CSV_REPORT_ERROR, error: err });
			});
	};
};

export const resetPayoutsReport = () => {
	return (dispatch) => {
		dispatch({ type: PAYOUTS_REPORT_RESET });
	};
};

export const resetAdjustmentsReport = () => {
	return (dispatch) => {
		dispatch({ type: ADJUSTMENTS_REPORT_RESET });
	};
};

export const getAdjustmentsReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: ADJUSTMENTS_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getAdjustmentsReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: ADJUSTMENTS_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: ADJUSTMENTS_REPORT_ERROR, error: err });
			});
	};
};

export const getAdjustmentsReportCsv = (startDate, endDate, dayEndsAt) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `adjustments-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getAdjustmentsReportCsv(startDate, endDate, dayEndsAt, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const getAdjustmentsDetailsReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: ADJUSTMENTS_DETAILS_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getAdjustmentsDetailsReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: ADJUSTMENTS_DETAILS_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: ADJUSTMENTS_DETAILS_REPORT_ERROR, error: err });
			});
	};
};

export const getAdjustmentsDetailsReportCsv = (startDate, endDate, dayEndsAt) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `adjustments-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getAdjustmentsDetailsReportCsv(startDate, endDate, dayEndsAt, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetAdjustmentsDetailsReport = () => {
	return (dispatch) => {
		dispatch({ type: ADJUSTMENTS_DETAILS_REPORT_RESET });
	};
};

export const getPayoutsReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: PAYOUTS_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getPayoutsReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: PAYOUTS_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: PAYOUTS_REPORT_ERROR, error: err });
			});
	};
};

export const getPayoutsReportCsv = (startDate, endDate, dayEndsAt) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `payouts-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getPayoutsReportCsv(startDate, endDate, dayEndsAt, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const getPayoutsDetailsReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: PAYOUTS_DETAILS_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getPayoutsDetailsReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: PAYOUTS_DETAILS_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: PAYOUTS_DETAILS_REPORT_ERROR, error: err });
			});
	};
};

export const getPayoutsDetailsReportCsv = (startDate, endDate, dayEndsAt) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `payouts-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getPayoutsDetailsReportCsv(startDate, endDate, dayEndsAt, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetPayoutsDetailsReport = () => {
	return (dispatch) => {
		dispatch({ type: PAYOUTS_DETAILS_REPORT_RESET });
	};
};

export const getPayoutsReversedReport = (startDate, endDate, dayEndsAt) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: PAYOUTS_REVERSED_REPORT_REQUEST });
		dispatch({ type: REPORT_DATE_CHANGE, startDate, endDate });
		AdminReportService.getPayoutsReversedReport(startDate, endDate, dayEndsAt, location)
			.then((data) => {
				dispatch({ type: PAYOUTS_REVERSED_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: PAYOUTS_REVERSED_REPORT_ERROR, error: err });
			});
	};
};

export const getPayoutsReversedReportCsv = (startDate, endDate, dayEndsAt) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `payouts-reversed-report-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getPayoutsReversedReportCsv(startDate, endDate, dayEndsAt, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetPayoutsReversedReport = () => {
	return (dispatch) => {
		dispatch({ type: PAYOUTS_REVERSED_REPORT_RESET });
	};
};

export const getReferralPayoutReport = (sortBy, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: REFERRAL_PAYOUT_REPORT_REQUEST });
		AdminReportService.getReferralPayoutReport(sortBy, page, location)
			.then((data) => {
				dispatch({ type: REFERRAL_PAYOUT_REPORT_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: REFERRAL_PAYOUT_REPORT_ERROR, error: err });
			});
	};
};
