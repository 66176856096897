import axios from "axios";
import FileDownload from "react-file-download";

const FileService = {
	async downloadFile(link, filename) {
		const response = await axios.get(link);
		FileDownload(response.data, filename);
	}
};
export default FileService;
