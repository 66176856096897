import { IconButton } from "../components/IconButton";
import ToolTip from "../components/ToolTip";
import { IconNames } from "../icons";
const Info = () => {
	return (
		<div style={{ position: "relative", display: "flex", cursor: "pointer" }}>
			<ToolTip message="Info">
				<div
					style={{
						boxShadow: "0 0 1px 0 #f1f1f1, 0 2px 3px 0 rgba(32, 7, 64, 0.13)",
						width: 36,
						height: 36,
						borderRadius: "50%"
					}}
				>
					<IconButton
						onClick={() => {
							/* Hi Steve */
						}}
						toggleAble={true}
						icon={IconNames.info}
						style={{ borderRadius: "50%" }}
					/>
				</div>
			</ToolTip>
		</div>
	);
};

export default Info;
