import { LegacyInputField, RadioField, SelectField } from "@/components/Fields";
import { Alert } from "@/ui/antd";
import { useEffect, useState } from "react";
import { Field } from "redux-form";
import LocationMap from "@/utils/LocationMap";
import { BankAccounts as BankAccountsUtils } from "@hx/utilities";
import { checkLength, onlyNumbers, required } from "@/utils/Validators";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { H2 } from "@/components/text/Text";

type AddressType = {
	street: string;
	city: string;
	state?: string;
	postalCode: string;
	country: string;
};
type W9FormType = any;
type W8EntityFormType = any;
type W8IndividualFormType = any;
type BankAccountType = {
	number: string;
	location?: Location;
	name?: string;
	firstName?: string;
	lastName?: string;
	companyName?: string;
	default?: boolean;
	deleted?: boolean;
	encodedAccount?: string;
	anonymisedAccount?: string[];
	accountHolderType?: "individual" | "company";
	bankAccountType?: "savings" | "checking";
	address?: AddressType;
	bankName?: string;
	swiftCode?: string;
	IBAN?: string;
	bsb?: string; // Will soon be deprecated
	usTaxpayerInfo?: W9FormType | W8EntityFormType | W8IndividualFormType;
};

type TSFix = any;
type BankAccountFormProps = {
	location: string;
	formValues: BankAccountType;
	isNewBankAccount: boolean;
};
export const BankAccountForm = ({ location, formValues, isNewBankAccount }: BankAccountFormProps) => {
	const bankAccountRules = BankAccountsUtils.getRules(location);
	const [{ accountChecks, bsbChecks }, setAccountAndBsbChecks] = useState<{
		accountChecks: TSFix[];
		bsbChecks: TSFix[];
	}>({
		accountChecks: [],
		bsbChecks: []
	});

	useEffect(() => {
		const accountAndBsbChecks = getAccountAndBsbChecks(bankAccountRules);
		setAccountAndBsbChecks(accountAndBsbChecks);
	}, []);

	return (
		<div>
			<H2>Bank details</H2>
			{/* Account holder name for any location other than US*/}
			{location !== "US" ? (
				<Field
					name="name"
					label="Account name"
					required
					component={LegacyInputField}
					validate={[nameRequired, maxLength, validAccountName]}
				/>
			) : null}
			{/* For US only: Account holder type, Firstname/LastName OR Company name, Address fields, Bank account type, Bank name */}
			{location === "US" ? (
				<>
					<Field
						name="accountHolderType"
						label="Account holder information"
						component={RadioField}
						button
						required
						validate={[accountHolderTypeRequired]}
						options={[
							{
								label: "Individual",
								value: "individual"
							},
							{
								label: "Business",
								value: "business"
							}
						]}
					/>
					{formValues?.accountHolderType === "individual" ? (
						<>
							<Field
								name="firstName"
								label="First name"
								required
								component={LegacyInputField}
								validate={[firstNameRequired, maxLength, validAccountName]}
							/>
							<Field
								name="lastName"
								label="Last name"
								required
								component={LegacyInputField}
								validate={[lastNameRequired, maxLength, validAccountName]}
							/>
						</>
					) : (
						<Field
							name="companyName"
							label="Company name"
							required
							component={LegacyInputField}
							validate={[companyNameRequired, maxLength, validAccountName]}
						/>
					)}

					<Field
						name="address.address"
						label="Address"
						placeholder="Address"
						component={LegacyInputField}
						required
						validate={[addressRequired]}
					/>
					<Field label="Address 2" name="address.addressTwo" component={LegacyInputField} placeholder="Address 2" />
					<Field
						label="City"
						name="address.city"
						component={LegacyInputField}
						placeholder="City"
						required
						validate={[cityRequired]}
					/>
					<div style={{ display: "flex", gap: 17 }}>
						<Field
							name="address.postalCode"
							label="Postal code"
							component={LegacyInputField}
							placeholder="Postal code"
							required
							validate={[postalCodeRequired, validPostalCode]}
						/>
						<Field
							name="address.state"
							label="State"
							component={SelectField}
							placeholder="Select a state"
							validate={[stateRequired]}
							showSearch
							required
							options={LocationMap.US}
						/>
					</div>
					<Field
						name="bankAccountType"
						label="Bank account information"
						component={RadioField}
						button
						required
						validate={[bankAccountTypeRequired]}
						options={[
							{
								label: "Checking",
								value: "checking"
							},
							{
								label: "Savings",
								value: "savings"
							}
						]}
					/>
					<Field
						name="bankName"
						label="Bank name"
						component={LegacyInputField}
						required
						validate={[bankNameRequired, maxLength, validBankName]}
					/>
				</>
			) : (
				<Field name="bankName" label="Bank name" component={LegacyInputField} validate={[maxLength, validBankName]} />
			)}
			<Field name="swiftCode" label="Swift code" component={LegacyInputField} validate={validSwiftCode} />
			<Field name="IBAN" label="IBAN" component={LegacyInputField} validate={validIBAN} />
			{/* BSB or Routing number */}
			{bankAccountRules?.bsb?.max && (
				<>
					<Field
						name="bsb"
						label={bankAccountRules?.bsb.name}
						component={LegacyInputField}
						required
						maxLength={bankAccountRules?.bsb.max}
						validate={bsbChecks}
					/>
					<Field
						name="bsbVerification"
						label={`Confirm ${bankAccountRules?.bsb.name}`}
						component={LegacyInputField}
						required
						maxLength={bankAccountRules?.bsb.max}
						validate={bsbMatch}
					/>
				</>
			)}
			{/* Account number */}
			<Field
				name="number"
				label={bankAccountRules?.account.name}
				required
				component={LegacyInputField}
				maxLength={bankAccountRules?.account.max}
				validate={accountChecks}
			/>
			<Field
				name="numberValidation"
				label={`Confirm ${bankAccountRules?.account.name.toLowerCase()}`}
				required
				component={LegacyInputField}
				maxLength={bankAccountRules?.account.max}
				validate={accountMatch}
			/>
			{isNewBankAccount && (
				<Alert
					message={
						<>
							<div>
								<b>Note:</b> New bank accounts will not auto-apply to existing events. You must apply them on the event
								level.
							</div>
							<div>
								<a onClick={OpenHelpHandler(HelpIds.bankAccounts)}>Learn more</a> about updating bank accounts.
							</div>
						</>
					}
					showIcon
					type="warning"
					style={{ margin: "12px 0 12px 0", maxWidth: 700 }}
				/>
			)}
		</div>
	);
};

function getAccountAndBsbChecks(bankAccountRules: TSFix): { accountChecks: TSFix[]; bsbChecks: TSFix[] } {
	const bsbChecksList: TSFix[] = [];
	const accountChecksList: TSFix[] = [];
	const accountNumberRequired = required(`An ${bankAccountRules?.account.name.toLowerCase()} is required`);
	const numberOnly = onlyNumbers();
	bsbChecksList.push(numberOnly);
	accountChecksList.push(accountNumberRequired);
	accountChecksList.push(numberOnly);
	if (bankAccountRules?.account?.min === bankAccountRules?.account?.max) {
		const accountLengthCheck = checkLength(
			`Must be ${bankAccountRules?.account.min} digits`,
			bankAccountRules?.account.min,
			"=="
		);
		accountChecksList.push(accountLengthCheck);
	} else {
		const accountMinCheck = checkLength(
			`Must be at least ${bankAccountRules?.account.min} digits`,
			bankAccountRules?.account.min,
			">="
		);
		accountChecksList.push(accountMinCheck);
		const accountMaxCheck = checkLength(
			`Must not be greater than ${bankAccountRules?.account.max} digits`,
			bankAccountRules?.account.max,
			"<="
		);
		accountChecksList.push(accountMaxCheck);
	}
	if (bankAccountRules?.bsb.min === bankAccountRules?.bsb.max) {
		const bsbLengthCheck = checkLength(`Must be ${bankAccountRules?.bsb.min} digits`, bankAccountRules?.bsb.min, "==");
		bsbChecksList.push(bsbLengthCheck);
	} else {
		const bsbMinCheck = checkLength(
			`Must be at least ${bankAccountRules?.bsb.min} digits`,
			bankAccountRules?.bsb.min,
			">="
		);
		bsbChecksList.push(bsbMinCheck);
		const bsbMaxCheck = checkLength(
			`Must not be greater than ${bankAccountRules?.bsb.max} digits`,
			bankAccountRules?.bsb.max,
			"<="
		);
		bsbChecksList.push(bsbMaxCheck);
	}
	return {
		accountChecks: accountChecksList,
		bsbChecks: bsbChecksList
	};
}
// ----------------------- Validators ------------------------
const nameRequired = required("Account name is required");
const firstNameRequired = required("First name is required");
const lastNameRequired = required("Last name is required");
const companyNameRequired = required("Company name is required");
const addressRequired = required("Address is required");
const cityRequired = required("City is required");
const postalCodeRequired = required("Postal code is required");
const stateRequired = required("State is required");

const bankNameRequired = required("A bank name is required");
const accountHolderTypeRequired = required("Must select an account holder type");
const bankAccountTypeRequired = required("Must select a bank account type");
const maxLength = (value: number) =>
	value && value.toString().length > 32 ? `Must be ${32} characters or less` : undefined;
const validPostalCode = (value: TSFix) => {
	const result = value && !/^\d{5}(-\d{4})?$/.test(value) ? "Invalid postal code" : undefined;
	return result;
};
const validAccountName = (value: string) => {
	const result = value && !/^(\w|\s)*$/.test(value) ? "Invalid account name" : undefined;
	return result;
};
const validBankName = (value: string) => {
	const result = value && !/^(\w|\s)*$/.test(value) ? "Invalid bank name" : undefined;
	return result;
};
const validSwiftCode = (value: TSFix) => {
	const result = value && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) ? "Invalid swift code" : undefined;
	return result;
};

const fieldMatch = (msg: TSFix, field: TSFix) => (value: TSFix, allValues: TSFix[]) => {
	return value === allValues[field] ? undefined : msg;
};
const bsbMatch = fieldMatch(`Previous field must match`, "bsb");
const accountMatch = fieldMatch(`Previous field must match`, "number");

function validateIBAN(iban: string) {
	// https://stackoverflow.com/questions/44656264/iban-regex-design
	const ibanStripped = iban.replace(/[^A-Z0-9]+/gi, "").toUpperCase();
	const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
	if (!m) {
		return false;
	}
	const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, (ch) => {
		return String(ch.charCodeAt(0) - 55);
	});
	if (!numbericed) {
		return false;
	}
	const matched = numbericed.match(/\d{1,7}/g);
	if (!matched || !matched.length) {
		return false;
	}
	const mod97 = matched.reduce((total, curr) => {
		return Number(total + curr) % 97;
	}, 0);
	return mod97 === 1;
}

function validIBAN(value: string) {
	const result = value && !validateIBAN(value) ? "Invalid IBAN" : undefined;
	return result;
}
