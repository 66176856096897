import axios from "./AxiosWrapper";

const AuditLogsService = {
	async list(search, page) {
		const response = await axios.get(`admin/audit-logs`, {
			params: { search, page }
		});
		return response;
	},

	async get(id) {
		const response = await axios.get(`admin/audit-logs/${id}`);
		return response;
	}
};
export default AuditLogsService;
