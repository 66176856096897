export const EDIT_BANK_ACCOUNT_CODE = "EDIT_BANK_ACCOUNT_CODE";
export const OPEN_LOCK_BANK_ACCOUNT_MODAL = "OPEN_LOCK_BANK_ACCOUNT_MODAL";
export const CLOSE_LOCK_BANK_ACCOUNT_MODAL = "CLOSE_LOCK_BANK_ACCOUNT_MODAL";
export const CLOSE_BANK_ACCOUNT_FORM = "CLOSE_BANK_ACCOUNT_FORM";
import { goToPathWithSearchState } from "@/utils/Urls";

export const editBankAccount = (account, shouldNavigate = false) => {
	return (dispatch) => {
		dispatch({ type: EDIT_BANK_ACCOUNT_CODE, account });
		if (shouldNavigate) {
			goToPathWithSearchState(`/console/account/profile/bank-accounts/${String(account._id)}`);
		}
	};
};

export const endEditingBankAccount = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_BANK_ACCOUNT_FORM });
	};
};

export const openLockBankAccountModal = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_LOCK_BANK_ACCOUNT_MODAL });
	};
};

export const closeLockBankAccountModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_LOCK_BANK_ACCOUNT_MODAL });
	};
};
