import * as React from "react";
import { iconMap, IconNames } from "../icons";

import { css } from "@emotion/css";

const buttonStyle = css({
	width: 36,
	height: 36,
	paddingTop: 4,
	backgroundColor: "#ffffff",
	border: "none",
	outline: "none"
});

interface IconButtonProps {
	onClick: () => void;
	toggleAble: boolean;
	icon: IconNames;
	disabled?: boolean;
	active?: boolean;
	style?: any;
}

enum SymbolColoring {
	NOT_SELECTED = "#67717e",
	HOVERED = "#ffdccd",
	SELECTED = "#ffb18f",
	DISABLED = "#ebebeb"
}

export class IconButton extends React.Component<IconButtonProps, { symbolColor: SymbolColoring }> {
	constructor(props: IconButtonProps) {
		super(props);
		this.state = {
			symbolColor: SymbolColoring.NOT_SELECTED
		};
	}

	onUp = () => {
		this.setState({ symbolColor: SymbolColoring.HOVERED });
	};

	ondown = () => {
		this.setState({ symbolColor: SymbolColoring.SELECTED });
	};

	onHover = () => {
		this.setState({ symbolColor: SymbolColoring.HOVERED });
	};

	onLeave = () => {
		this.setState({ symbolColor: SymbolColoring.NOT_SELECTED });
	};

	render() {
		const { icon, disabled, style = {}, active, onClick } = this.props;
		const { symbolColor } = this.state;
		let svgColor = disabled ? SymbolColoring.DISABLED : symbolColor;
		svgColor = active ? SymbolColoring.SELECTED : svgColor;
		return (
			<div style={{ position: "relative", display: "inline-block", cursor: disabled ? "not-allowed" : "pointer" }}>
				<button
					disabled={disabled}
					onMouseDown={this.ondown}
					onClick={onClick}
					onMouseLeave={this.onLeave}
					onMouseUp={this.onUp}
					onMouseEnter={this.onHover}
					className={buttonStyle}
					style={{
						cursor: disabled ? "not-allowed" : "pointer",
						...style
					}}
				>
					{iconMap[icon]({ color: svgColor })}
				</button>
			</div>
		);
	}
}
