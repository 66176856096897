import axios from "./AxiosWrapper";

class MassPayoutService {
	static instance = null;

	static createInstance() {
		let object = new MassPayoutService();
		return object;
	}

	static getInstance() {
		if (!MassPayoutService.instance) {
			MassPayoutService.instance = MassPayoutService.createInstance();
		}
		return MassPayoutService.instance;
	}

	async find(page, location) {
		const options = {};
		if (location) {
			options.headers = { "x-override-location": location };
		}
		options.options = { page };
		const response = await axios.get(`admin/payments/mass-payouts?page=${page}`, options);
		return response.data;
	}

	async requestMassPayout(location) {
		const options = {};
		if (location) {
			options.headers = { "x-override-location": location };
		}
		const response = await axios.post(`admin/payments/request-mass-payout`, null, options);
		return response.data;
	}
	async exportMassPayout(massPaymentId, location, sameDayEffectiveDate) {
		const options = { responseType: "blob" };
		if (location) {
			options.headers = { "x-override-location": location };
		}
		const response = await axios.get(
			`admin/payments/mass-payout/export/${massPaymentId}?sameDayEffectiveDate=${sameDayEffectiveDate}`,
			options
		);
		return response.data;
	}
	async markMassPayoutAsPaid(massPaymentId, transactionId, amountProcessed, clearedAt, location) {
		const options = {};
		if (location) {
			options.headers = { "x-override-location": location };
		}
		const response = await axios.post(
			`admin/payments/mass-payout/paid/${massPaymentId}`,
			{
				transactionId,
				amountProcessed,
				clearedAt
			},
			options
		);
		return response.data;
	}

	async cancelPayment(massPaymentId, location) {
		const options = {};
		if (location) {
			options.headers = { "x-override-location": location };
		}
		const response = await axios.get(`admin/payments/mass-payout/cancel-paid/${massPaymentId}`, options);
		return response.data;
	}

	async getMassPayout(massPaymentId) {
		const response = await axios.get(`admin/payments/mass-payout/${massPaymentId}`);
		return response.data;
	}

	async getMassPayoutCSV(massPaymentId, location) {
		const response = await axios.get(`admin/payments/mass-payout/${massPaymentId}/csv`, {
			responseType: "blob",
			...(location ? { headers: { "x-override-location": location } } : {})
		});
		return response.data;
	}

	async cancelMassPayout(massPaymentId, location) {
		const options = {};
		if (location) {
			options.headers = { "x-override-location": location };
		}
		const response = await axios.delete(`admin/payments/mass-payout/${massPaymentId}`, options);
		return response.data;
	}
}

export default MassPayoutService.getInstance();
