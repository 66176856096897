import { dayjs } from "@/libs/dayjs";
import LocationService from "@/services/LocationService";
import { DateFormats, HumaniDate } from "@hx/dates";

export default function getSummarySentence(
	selectedEvents,
	scheduleType,
	toType,
	orderIds,
	ticketTypeIds,
	scheduleDate,
	include = "orders"
) {
	let message = `This campaign will email`;

	const includeMsg = include === "all" ? "orders and attendees" : include;

	if (!toType || toType === "all") {
		message = `${message} all ${includeMsg}`;
	} else if (toType === "byTicketType") {
		const ticketTypeMsg = !ticketTypeIds
			? `all ${includeMsg}`
			: `${includeMsg} with ${ticketTypeIds.length} ticket types`;
		message = `${message} ${ticketTypeMsg}`;
	} else {
		const orderCount = !orderIds ? 0 : orderIds.length;
		const plural = orderCount <= 1 ? "" : "s";
		message = `${message} ${includeMsg} from ${orderCount} order${plural}`;
	}
	if (!selectedEvents || selectedEvents.length === 0 || selectedEvents?.[0] === "all") {
		message = `${message} from all events,`;
	} else {
		const plural = selectedEvents.length <= 1 ? "" : "s";
		message = `${message} from ${selectedEvents.length} event${plural},`;
	}
	//scheduled to email
	message = `${message} scheduled to email`;
	if (scheduleType === "now" || scheduleType === "at") {
		if (scheduleType === "now") {
			message = `${message} now.`;
		} else if (scheduleDate) {
			let date = scheduleDate;
			if (typeof scheduleDate === "string") {
				date = dayjs(date);
			}
			message = `${message} at ${new HumaniDate(
				date.toDate(),
				"guess",
				LocationService.getLocation(true)
			).formatting.date(DateFormats.Normal)}.`;
		}
	} else {
		message = `${message} ${scheduleType} every future event date.`;
	}
	return message;
}
