import { Tabs } from "@/ui/antd";
import { Component } from "react";
import { FieldArray } from "redux-form";
import { getUrlParameter } from "@/utils/Urls";
import Body from "./Body";
import Files from "./Files";
import Gallery from "./Gallery";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class VirtualEventTabs extends Component {
	constructor(props) {
		super(props);
		let tab = getUrlParameter("tab");
		tab = tab ? tab : "body";
		this.state = { tab };
	}

	tabChange = (tab) => {
		const { navigate } = this.props;
		this.setState({ tab });
		navigate({ search: `?tab=${tab}` });
	};

	render() {
		const { changeFieldValue } = this.props;
		const { tab } = this.state;
		return (
			<Tabs
				activeKey={tab}
				onChange={this.tabChange}
				items={[
					{
						children: <FieldArray name="body" component={Body} changeFieldValue={changeFieldValue} />,
						key: "body",
						label: "Body"
					},
					{
						children: <FieldArray name="files" component={Files} changeFieldValue={changeFieldValue} />,
						key: "files",
						label: "Files"
					},
					{
						children: <FieldArray name="gallery" component={Gallery} changeFieldValue={changeFieldValue} />,
						key: "gallery",
						label: "Image gallery"
					}
				]}
			/>
		);
	}
}

export default withRouterHooks(VirtualEventTabs);
