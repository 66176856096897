import { CameraFilled } from "@ant-design/icons";
import { css } from "@emotion/css";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const PagePreview = (props) => {
	const { style } = props;
	const styleObj = style
		? style
		: {
				headerColor: "#5a626c",
				backgroundColor: "#dadada",
				primaryColor: "#353337",
				primaryTextColor: "#ffffff"
		  };
	const { headerColor, primaryColor, primaryTextColor, backgroundColor } = styleObj;

	const card = css`
		padding: 5px;
		border-radius: 8px;
		border: 2px solid #dedede;
		margin-bottom: 20px;
	`;

	const container = css`
		border: 1px solid #eee;
		margin: 0;
	`;

	const topContainer = css`
		width: 80%;
		margin: auto;
	`;

	const icon = css`
		font-size: 40px;
		color: #dadada;
		padding-top: 20px;
	`;

	const button = css({
		"#preview-button": {
			height: "16px !important",
			minHeight: "16px !important",
			background: `${primaryColor}`,
			color: `${primaryTextColor}`,
			marginTop: "5px",
			marginRight: "10px",
			width: "48px",
			fontSize: 6,
			padding: 0,
			margin: "0 auto"
		}
	});
	const contentsContainer = css`
		background: #fff;
		margin-bottom: 12px;
		padding-top: 12px;
		padding-left: 16px;
		padding-right: 8px;
		height: 232px;
	`;

	const bannerWrapper = css({
		width: "100%"
	});

	const bannerImage = css({
		height: 80,
		backgroundColor: "#cce5ff",
		borderRadius: 4,
		textAlign: "center"
	});

	const headerBox = css({
		height: 24,
		backgroundColor: headerColor //header
	});
	const backgroundWrapper = css({
		width: "100%",
		height: "100%",
		backgroundColor //bc
	});

	const header = css({
		backgroundColor: "white",
		width: "100%",
		padding: "3px 4px"
	});

	const column1 = css({
		width: "62%",
		display: "flex"
	});

	const summary = css({
		backgroundColor: "#fafafa",
		borderRadius: 2,
		height: 92,
		marginLeft: "27%",
		width: "100%"
	});
	const footer = css({
		alignItems: "center"
	});

	return (
		<div style={{ width: "100%" }}>
			<div className={card}>
				<div className={backgroundWrapper}>
					<div className={headerBox} />
					<div className={container}>
						<div className={topContainer}>
							<div className={bannerWrapper}>
								<div className={bannerImage}>
									<CameraFilled className={icon} />
								</div>
							</div>
							<div className={contentsContainer}>
								<div style={{ display: "flex", height: 200 }}>
									<div className={column1}>
										<div className={header}>
											<div style={{ paddingTop: 4 }}>
												<CardContent />
												<CardContent />
												<CardContent />
											</div>
										</div>
									</div>
									<div style={{ display: "flex", flexGrow: 1 }}>
										<div className={summary} />
									</div>
								</div>
								<div className={footer}>
									<div className={button}>
										<LegacyButton id="preview-button" size="small" ariaLabel="Enter details">
											Enter details
										</LegacyButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const subColumn = css({
	width: "50%"
});
const CardContent = () => {
	return (
		<div style={{ marginTop: 8, display: "flex" }}>
			<div className={subColumn}>
				<div style={{ backgroundColor: "#ebebeb", width: "100%", height: 50 }} />
			</div>
			<div className={subColumn}>
				<div
					style={{
						backgroundColor: "#ebebeb",
						marginBottom: 6,
						height: 2,
						marginLeft: 4,
						borderRadius: 4
					}}
				/>
				<div
					style={{
						backgroundColor: "#ebebeb",
						marginBottom: 6,
						height: 2,
						marginLeft: 4,
						borderRadius: 4
					}}
				/>
				<div
					style={{
						backgroundColor: "#ebebeb",
						marginBottom: 6,
						height: 2,
						marginLeft: 4,
						borderRadius: 4
					}}
				/>
				<div
					style={{
						backgroundColor: "#ebebeb",
						marginBottom: 6,
						height: 2,
						marginLeft: 4,
						borderRadius: 4
					}}
				/>
			</div>
		</div>
	);
};

export default PagePreview;
