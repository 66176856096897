import { PostPublishModal } from "@/events/event/header/PostPublishModal";
import PublishModal from "@/events/event/header/PublishModal";
import VerifyAccountModal from "@/events/event/header/VerifyAccountModal";
import { publishToggle } from "@/state/actions/eventActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Tooltip } from "@/ui/antd";
import { Button } from "@/ui";
import { getPublishText } from "@/utils/Events";

export const PublishButton = () => {
	const dispatch = useAppDispatch();
	const currentEvent = useAppSelector((state) => state.currentEvent);

	if (!currentEvent?.event) {
		return null;
	}

	const hasTicketsSold = currentEvent.event.ticketSold;
	const hasNoDates = !currentEvent.event.dates?.length;

	const publishDisabled = hasNoDates;
	const unpublishDisabled = hasTicketsSold;
	const publishToolTip = hasNoDates ? (
		"Event must have at least one date before publishing"
	) : (
		<>
			Publish your event.
			<br /> You can still edit after publishing
		</>
	);
	const unpublishToolTip = hasTicketsSold
		? "Cannot unpublish as tickets have been sold. To hide, set event to private and change the URL."
		: "Unpublish your event";

	const publishTxt = getPublishText(currentEvent.event);

	return (
		<>
			{publishTxt === "Publish" || publishTxt === "Schedule" ? (
				<Tooltip title={publishToolTip}>
					<div>
						<Button
							aria-label={publishTxt}
							dataCy="open-publish-modal"
							disabled={publishDisabled}
							onClick={() => dispatch(publishToggle(currentEvent))}
							variant="primary"
						>
							{publishTxt}
						</Button>
					</div>
				</Tooltip>
			) : (
				<Tooltip title={unpublishToolTip}>
					<Button
						aria-label={publishTxt}
						disabled={unpublishDisabled}
						onClick={() => dispatch(publishToggle(currentEvent))}
						outlined
						variant={unpublishDisabled ? "tertiary" : "danger"}
					>
						{publishTxt}
					</Button>
				</Tooltip>
			)}
			<PublishModal />
			<VerifyAccountModal />
			<PostPublishModal />
		</>
	);
};
