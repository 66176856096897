import { connect } from "react-redux";
import { Field } from "redux-form";
import { DateField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import { checkEndDate, checkStartDate, futureDateRequired } from "../dateFormUtils";

const startDateRequired = required("Start date is required");
const endDateRequired = required("End date is required");

const DateRange = ({
	touch,
	startDateName = "startDate",
	endDateName = "endDate",
	vertical = true,
	timezone,
	validatorDisable = []
}) => {
	const disabled = false;
	const positionStyle = vertical
		? {
				display: "flex",
				flexDirection: "column",
				marginRight: 8,
				minWidth: "calc(50% - 4px)"
		  }
		: { display: "flex", flexDirection: "row", minWidth: "100%" };
	const fieldStyle = vertical ? {} : { minWidth: "calc(50% - 4px)" };
	const startDateFieldStyle = !vertical ? { ...fieldStyle, marginRight: 8 } : fieldStyle;
	const startDateRequiredChecks = [
		{
			key: "required",
			check: startDateRequired
		},
		{
			key: "checkDate",
			check: checkStartDate(touch)
		},
		{
			key: "future",
			check: futureDateRequired
		}
	]
		.filter(({ key }) => !validatorDisable.includes(key))
		.map(({ check }) => check);

	const endDateRequiredChecks = [
		{
			key: "required",
			check: endDateRequired
		},
		{
			key: "checkDate",
			check: checkEndDate
		},
		{
			key: "future",
			check: futureDateRequired
		}
	]
		.filter(({ key }) => !validatorDisable.includes(key))
		.map(({ check }) => check);
	return (
		<div style={positionStyle}>
			<div style={startDateFieldStyle}>
				<Field
					label="Start date"
					name={startDateName}
					type="text"
					component={DateField}
					validate={startDateRequiredChecks}
					placeholder="Start Date"
					disabled={disabled}
					timezone={timezone}
					displayFormat="Do MMM YYYY"
					required
					style={{ minWidth: "100%" }}
				/>
			</div>
			<div style={fieldStyle}>
				<Field
					label="End date"
					name={endDateName}
					type="text"
					component={DateField}
					validate={endDateRequiredChecks}
					placeholder="End Date"
					disabled={disabled}
					timezone={timezone}
					displayFormat="Do MMM YYYY"
					required
					style={{ minWidth: "100%" }}
				/>
			</div>
		</div>
	);
};

export default connect((state) => {
	return {
		timezone: state.currentEvent.event.timezone
	};
}, {})(DateRange);
