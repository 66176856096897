import { FIND_ORDER_ERROR, FIND_ORDER_REQUEST, FIND_ORDER_SUCCESS } from "../actions/orderActions";

import { INVOICE_UPDATE_SUCCESS } from "../actions/invoiceActions";

const initialState = {
	orders: [],
	count: 0,
	page: 1,
	loading: false,
	error: false,
	saved: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case FIND_ORDER_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				page: action.page
			};
		case FIND_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				orders: action.orders,
				count: action.count
			};
		case FIND_ORDER_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case INVOICE_UPDATE_SUCCESS: {
			const updatedOrders = state.orders.map((order) => {
				if (String(order._id) === String(action.order._id)) {
					return action.order;
				}
				return order;
			});
			return {
				...state,
				orders: updatedOrders
			};
		}
		default:
			return state;
	}
};
