import { Component } from "react";
import InputLabel from "../../../components/InputLabel";
import StepInput from "../../../components/StepInput";
export default class EndSeatNumber extends Component {
	seatNumChanged = (value) => {
		const { element, performAction } = this.props;
		const seatNum = this.props.element.endSeatCount;
		if (typeof value === "number") {
			let amount = value - seatNum;
			if (amount >= 0) {
				performAction([element.addEndSeats], [[amount]]);
			} else {
				amount = Math.abs(amount);
				performAction([element.removeEndSeats], [[amount]]);
			}
		}
	};

	render() {
		const seatNum = this.props.element.endSeatCount;
		return (
			<div style={{ overflow: "auto", paddingTop: 20 }}>
				<InputLabel>Number of end seats</InputLabel>
				<StepInput value={seatNum} min={0} onChange={this.seatNumChanged} style={{ float: "right", width: 60 }} />
			</div>
		);
	}
}
