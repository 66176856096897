import {
	EMAIL_FIND_ERROR,
	EMAIL_FIND_REQUEST,
	EMAIL_FIND_SUCCESS,
	EMAIL_LIST_RESEND_ERROR,
	EMAIL_LIST_RESEND_REQUEST,
	EMAIL_LIST_RESEND_SUCCESS,
	EMAIL_RESEND_ERROR,
	EMAIL_RESEND_REQUEST,
	EMAIL_RESEND_SUCCESS,
	EMAIL_SEARCH_ERROR,
	EMAIL_SEARCH_REQUEST,
	EMAIL_SEARCH_SUCCESS
} from "../actions/emailLogsActions";

const initialState = {
	loading: false,
	error: "",
	page: 1,
	count: 0,
	emails: [],
	email: undefined,
	log: undefined,
	template: undefined,
	resending: false,
	resendId: undefined
};
export default (state = initialState, action) => {
	switch (action.type) {
		case EMAIL_SEARCH_REQUEST:
			return {
				...state,
				loading: true,
				page: action.page
			};
		case EMAIL_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				emails: action.data.results,
				count: action.data.count
			};
		case EMAIL_SEARCH_ERROR:
			return {
				...state,
				loading: false,
				count: 0,
				emails: [],
				error: {
					title: "Something went wrong",
					message: action.error
				}
			};
		case EMAIL_FIND_REQUEST:
			return {
				...state,
				loading: true
			};
		case EMAIL_FIND_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				log: action.log,
				email: action.email,
				template: action.template
			};
		case EMAIL_FIND_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case EMAIL_RESEND_REQUEST:
			return {
				...state,
				loading: true
			};
		case EMAIL_RESEND_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				email: action.email
			};
		case EMAIL_RESEND_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case EMAIL_LIST_RESEND_REQUEST:
			return {
				...state,
				resending: true,
				resendingId: action.id
			};
		case EMAIL_LIST_RESEND_ERROR:
			return {
				...state,
				resending: false,
				resendingId: undefined
			};
		case EMAIL_LIST_RESEND_SUCCESS: {
			const emails = state.emails.slice();
			const emailIndex = emails.findIndex((a) => a.id === action.email.id);
			const email = {
				...emails[emailIndex],
				...action.email
			};
			emails.splice(emailIndex, 1, email);
			return {
				...state,
				emails,
				resending: false,
				resendingId: undefined
			};
		}
		default:
			return state;
	}
};
