import { LegacyButton } from "@/components/buttons/LegacyButton";
import { goToPreviousStep } from "./utils/eventCreationUtils";

const StepBackButton = ({ inWizard, isPartOfSteps = true }) => {
	if (inWizard || !isPartOfSteps) {
		return null;
	}
	return (
		<LegacyButton
			htmlType="button"
			style={{
				float: "left"
			}}
			ariaLabel="Back"
			dataCy="back"
			onClick={goToPreviousStep}
		>
			Back
		</LegacyButton>
	);
};

export default StepBackButton;
