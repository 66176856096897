import { useConfetti } from "@/hooks/useConfetti";
import { Button, CodeBlock, Dropdown, DropdownItem } from "@/ui";
import { Alert, Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type DropdownProps = {
	/** The trigger element */
	children: ReactNode;
	/** Items to display in the dropdown, a react node or an array of */
	items: ReactNode | ReactNode[];
	/** Fired when the dropdown is closed */
	onClose?: () => void;
	/** Placement of the dropdown */
	placement?: AntDropdownProps["placement"];
	/** Requires click to toggle */
	persist?: boolean;
	/** Title of the dropdown */
	title?: ReactNode;
};`;

const sample = `import { Button, Dropdown } from "@/ui";
import { Flex } from "@/ui/antd";

<Dropdown
  items={[
    <DropdownItem>Item 1</DropdownItem>,
    <DropdownItem>Item 2</DropdownItem>
  ]}
>
  <Button variant="secondary">Hover this</Button>
</Dropdown>

<Dropdown
  persist
  placement="bottomLeft"
  items={
    <Flex gap="sm" vertical>
      <CodeBlock codeString={\`console.log("Welcome to Shak's place");\`} />
    </Flex>
  }
>
  <Button variant="primary">Persist dropdown</Button>
</Dropdown>
`;

export const DropdownPage: FC = () => {
	const confetti = useConfetti();

	return (
		<Flex gap="lg" vertical>
			<Alert
				showIcon
				type="info"
				description="Note: <DropdownItem/> extends <Button/> component for a left aligned button"
			/>
			<b>List Items</b>
			<Dropdown
				items={[<DropdownItem onClick={() => confetti()}>ConFETT</DropdownItem>, <DropdownItem>Item 2</DropdownItem>]}
			>
				<Button variant="secondary">Hover this</Button>
			</Dropdown>
			<br />
			<b>Any content</b>
			<Dropdown
				persist
				placement="bottomLeft"
				items={
					<Flex gap="sm" vertical>
						<CodeBlock codeString={`console.log("Welcome to Shak's place");`} />
					</Flex>
				}
			>
				<Button variant="primary">Persist dropdown</Button>
			</Dropdown>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
