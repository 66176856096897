import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { notification } from "@/ui/antd";
import { FC } from "react";
import { HistoryTable } from "./HistoryTable";

export const HistoryTab: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const eventId = currentEvent.event?._id ?? "";

	const { data } = trpc.massRefunds.listEventMassRefunds.useQuery(
		{ eventId, statusFilter: "all" },
		{
			enabled: !!eventId,
			onError: (error) => {
				notification.error({
					description: error.message ?? "An error occurred while fetching mass refunds.",
					message: "Error"
				});
			}
		}
	);

	const { massRefunds = [] } = data ?? {};

	return <HistoryTable event={currentEvent?.event} massRefundHistory={massRefunds} />;
};
