import { PictureOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { notification } from "@/ui/antd";
import { PickerFileMetadata, PickerOptions } from "filestack-js";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "./buttons/ButtonsBar";
import CanvaButton from "./CanvaButton";
import LoadErrorView from "./LoadErrorView";
import { P } from "./text/Text";

import { fsClient, getImageUrl } from "@/services/ImageService";
import { ReduxState as GlobalReduxState } from "@/state/store";

const accept = ["image/*"];
const fromSources = ["local_file_system", "facebook", "instagram", "googledrive", "dropbox"];
const defaultImageTransformations = {
	rotate: true,
	crop: true
};

const noImage = css`
	position: absolute;
	top: calc(50% - 75px);
	bottom: 0;
	left: 0;
	right: 0;
	height: 150px;
	text-align: center;
`;

const createBtns = css({
	display: "flex",
	margin: "0 auto",
	justifyContent: "center"
});

const icon = css`
	font-size: 90px;
	color: #eee;
	margin: 0;
	line-height: 1.2;
`;

const hint = {
	fontSize: 14,
	color: "#323232",
	marginTop: 15
};

const imageAreaObj = {
	width: "100%",
	position: "relative",
	textAlign: "center",
	"@media (max-width: 600px)": {
		// height: "auto",
		marginBottom: 16
	}
};

const correctPaddingBounds = (padding: number): number => (padding < 45 ? 45 : padding);
const getPadding = (aspectRatio?: number): number => {
	let padding = 100;

	if (aspectRatio) {
		const aspect = aspectRatio > 50 ? 50 : 100 / aspectRatio;
		padding = 100 / aspect;
	}

	return correctPaddingBounds(padding);
};

const pickDataFromState = (state: Partial<GlobalReduxState>) => ({
	imageUploads: state.canva?.imageUploads
});

type LocalisedReduxState = ReturnType<typeof pickDataFromState>;

type ImageData = {
	name: string;
	size: number;
	url: string;
	source: string;
	handle: string;
	canvaDesignId?: string;
};

type ImageHandleOnly = string;
type ImageOnChangeResponse = ImageHandleOnly | ImageData;

type AdditionalCanvaProps = {
	canvaDesignId?: string;
};

interface IImageUploadProps {
	aspectRatio: number;
	value: any;
	saveHandleOnly?: boolean;
	onChange?: (data: ImageOnChangeResponse | null) => void;
	uploadLabel?: string;
	uploadDescription?: string;
	uploadOutsideDescription?: string;
	canvaEnabled?: boolean;
	imageStyleOverride?: any;
}

type ImpageUploadInput = IImageUploadProps & LocalisedReduxState;

const ImageUpload = ({
	imageUploads,
	aspectRatio,
	value,
	saveHandleOnly,
	onChange,
	uploadLabel = "Upload an image",
	uploadDescription = "Images should be smaller than 10 mb",
	uploadOutsideDescription = "",
	canvaEnabled = true,
	imageStyleOverride
}: ImpageUploadInput) => {
	const url = value ? getImageUrl(value, 800) : value;
	const [imageUrl, setImageUrl] = useState(url);
	const [canvaId, setCanvaId] = useState("");

	const loading = imageUploads[canvaId]?.loading ?? false;
	const error = imageUploads[canvaId]?.error ?? false;

	const defaultWidth = 3200;
	const height = Math.round(defaultWidth / aspectRatio);
	const isValidCanvaHeight = height > 40 && height < 5000;
	const offerDesignOnCanva = isValidCanvaHeight && canvaEnabled;

	const padding = getPadding(aspectRatio);

	useEffect(() => {
		const idString = Math.random().toString();
		setCanvaId(`canvaCallBack${idString.replace(".", "")}`);
	}, []);

	const imageArea = css({
		...imageAreaObj,
		background: value ? `url(${imageUrl}) no-repeat` : "transparent",
		paddingTop: `${padding}%`,
		border: !value || loading ? "1px dashed #d9d9d9" : "none",
		backgroundSize: "contain",
		backgroundPosition: "center",
		height: "220px",
		...imageStyleOverride
	});

	useEffect(() => {
		const currentImage = imageUploads[canvaId];
		if (currentImage?.imageUrl) _setImage(currentImage.image);
	}, [imageUploads]);

	const _editImage = () => {
		let options: PickerOptions = {
			transformations: defaultImageTransformations,
			accept,
			onUploadDone: _handleImageUploaded
		};
		if (aspectRatio) {
			options = {
				...options,
				transformations: {
					...options.transformations,
					crop: {
						force: true,
						aspectRatio
					}
				}
			};
		}

		fsClient.picker(options).crop(imageUrl);
	};

	const _deleteImage = () => {
		if (onChange) onChange(null);
	};

	const _handleImageUploaded = ({
		filesUploaded,
		filesFailed
	}: {
		filesUploaded: PickerFileMetadata[];
		filesFailed: PickerFileMetadata[];
	}) => {
		if (filesFailed.length) {
			notification.error({ message: "Image failed to upload" });
			return;
		}

		const uploadedImage = filesUploaded[0];
		_setImage(uploadedImage);
	};

	const _setImage = (image: PickerFileMetadata & AdditionalCanvaProps) => {
		const imageData: ImageOnChangeResponse = saveHandleOnly
			? image.handle
			: {
					name: image.filename,
					size: image.size,
					url: image.url,
					source: image.source,
					handle: image.handle,
					canvaDesignId: image.canvaDesignId
			  };

		if (onChange) {
			onChange(imageData);
		}

		setImageUrl(image.url);
	};

	const _uploadImage = () => {
		let options: PickerOptions = {
			fromSources,
			maxSize: 102400000,
			maxFiles: 1,
			minFiles: 1,
			imageMax: [3200, 3200],
			uploadInBackground: false,
			transformations: defaultImageTransformations,
			accept,
			onUploadDone: _handleImageUploaded
		};

		if (aspectRatio) {
			options = {
				...options,
				transformations: {
					...options.transformations,
					crop: {
						force: true,
						aspectRatio
					}
				}
			};
		}

		options.exposeOriginalFile = true;
		fsClient.picker(options).open();
	};

	return (
		<LoadErrorView loading={loading} error={error}>
			<div>
				<div className={imageArea}>
					{!value ? (
						<div className={noImage}>
							<div className={icon}>
								<PictureOutlined />
							</div>
							<div className={createBtns}>
								<LegacyButton ariaLabel={uploadLabel} type="action" onClick={_uploadImage}>
									{uploadLabel}
								</LegacyButton>
								{offerDesignOnCanva && (
									<>
										<p style={{ margin: 12 }}>Or</p>
										<CanvaButton canvaId={canvaId} width={defaultWidth} height={height} uploadCallback={_setImage}>
											Design on Canva
										</CanvaButton>
									</>
								)}
							</div>
							<div style={hint}>{uploadDescription}</div>
						</div>
					) : (
						""
					)}
				</div>
				{value ? (
					<div>
						<ButtonsBar>
							{value.canvaDesignId ? (
								<CanvaButton
									canvaId={canvaId}
									designId={value.canvaDesignId}
									width={defaultWidth}
									height={height}
									uploadCallback={_setImage}
								>
									Edit on Canva
								</CanvaButton>
							) : (
								<LegacyButton onClick={_editImage} type="action" ariaLabel="Edit Image">
									Edit
								</LegacyButton>
							)}
							<LegacyButton onClick={_uploadImage} type="action" ariaLabel="Upload Image">
								Upload
							</LegacyButton>
							<LegacyButton onClick={_deleteImage} type="action" ariaLabel="Delete Image">
								Delete
							</LegacyButton>
						</ButtonsBar>
						<P style={hint}>{uploadOutsideDescription}</P>
					</div>
				) : (
					""
				)}
			</div>
		</LoadErrorView>
	);
};

export default connect(pickDataFromState, {})(ImageUpload);
