import humanitixLogo from "@/assets/humanitix_logo.svg";
import { Button } from "@/ui/Button";
import InvoicingModal from "@/events/event/payments/components/gateways/InvoicingModal";
import PaymentInfo from "@/events/event/payments/components/gateways/PaymentInfo";
import { closeInvoicingModal, openInvoicingModal } from "@/state/actions/gatewayActions";
import { ReduxState } from "@/state/store";
import { FC } from "react";
import { connect } from "react-redux";
import { GatewayEnabledWrapper } from "@/events/event/payments/components/gateways/GatewayComponents";

type InvoicingProps = {
	allowed: boolean;
	invoicePayLinkAllowed: boolean;
	closeInvoicingModal: () => void;
	gateways: any;
	id: string;
	location: string;
	openInvoicingModal: () => void;
	orgPaymentOptions: any;
	paymentOptionsStr: string;
	save: (values: any) => void;
	selected: string;
	type: string;
};

const Invoicing: FC<InvoicingProps> = ({
	allowed,
	invoicePayLinkAllowed,
	closeInvoicingModal,
	gateways,
	id,
	location,
	openInvoicingModal,
	orgPaymentOptions,
	paymentOptionsStr,
	save,
	selected,
	type
}) => {
	if (!allowed) {
		return null;
	}

	const enabled = !!(orgPaymentOptions && orgPaymentOptions.invoiceInfo && orgPaymentOptions.invoiceInfo.enabled);
	return (
		<PaymentInfo
			description={
				<div>
					Accept payments by invoice. Easily send and manage invoices for your events and receive payments directly into
					your account.
				</div>
			}
			id="invoice"
			image={humanitixLogo}
			imageSubtitle="invoicing"
			name="Invoice"
			selected={selected}
		>
			{enabled ? (
				<GatewayEnabledWrapper>
					<div>Invoicing enabled</div>
				</GatewayEnabledWrapper>
			) : null}
			<Button
				aria-label={enabled ? "Settings" : "Set up"}
				onClick={openInvoicingModal}
				style={{ width: "100%", margin: "auto", maxWidth: 240 }}
				variant="tertiary"
			>
				{enabled ? "Settings" : "Set up"}
			</Button>
			{gateways.invoicingModalOpen ? (
				<InvoicingModal
					id={id}
					location={location}
					orgPaymentOptions={orgPaymentOptions}
					paymentOptionsStr={paymentOptionsStr}
					save={(values: any) => {
						closeInvoicingModal();
						save(values);
					}}
					type={type}
					visible={gateways.invoicingModalOpen}
					invoicePayLinkAllowed={invoicePayLinkAllowed}
				/>
			) : null}
		</PaymentInfo>
	);
};

const mapStateToProps = (state: ReduxState) => ({
	gateways: state.gateways
});

const mapDispatchToProps = {
	closeInvoicingModal,
	openInvoicingModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoicing);
