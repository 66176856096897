import { LegacyButton } from "@/components/buttons/LegacyButton";
import { css } from "@emotion/css";

import { defaultDeviceViewWrapperStyle, deviceActionButtonStyle } from "./shared";

const wrapperStyle = css({
	width: "100%",
	height: "100%",
	overflow: "hidden"
});

const iframeStyle = css({
	width: "100%",
	height: "100%",
	transformOrigin: "top left",
	border: "none",
	"@media (max-width: 1200px)": {
		width: "200%",
		height: "200%",
		transform: "scale(0.5)"
	}
});

const DesktopDevice = ({ url }: { url: string }) => {
	return (
		<div className={wrapperStyle}>
			<iframe className={iframeStyle} src={url} />
		</div>
	);
};

const desktopViewStylingWrapper = css({
	flex: 1,
	height: "100%"
});

export const DesktopView = ({ url }: { url: string }) => {
	return (
		<div className={`${defaultDeviceViewWrapperStyle} ${desktopViewStylingWrapper}`}>
			<a
				href={url}
				target="_blank"
				rel="noreferrer"
				style={{
					alignSelf: "flex-end",
					background: "white",
					borderRadius: 4
				}}
			>
				<LegacyButton
					icon={{ right: true, name: "open_external" }}
					className={deviceActionButtonStyle}
					ariaLabel="View event in new tab"
				>
					View in new tab
				</LegacyButton>
			</a>
			<DesktopDevice url={url} />
		</div>
	);
};
