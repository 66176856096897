const ZoomIn: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path d="M0 0H24V24H0z" />
				<path
					fill={color}
					d="M12 5c-.552 0-1 .451-1 .99V11H5.99c-.546 0-.99.444-.99 1 0 .552.451 1 .99 1H11v5.01c0 .546.444.99 1 .99.552 0 1-.451 1-.99V13h5.01c.546 0 .99-.444.99-1 0-.552-.451-1-.99-1H13V5.99c0-.546-.444-.99-1-.99z"
				/>
			</g>
		</svg>
	);
};

export default ZoomIn;
