import { CheckboxField, InputNumberField } from "@/components/Fields";
import H3 from "@/components/text/H3";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { Table } from "@/ui/antd";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { ColumnProps } from "antd/lib/table";
import { Field } from "redux-form";

const tableWrapper = css`
	margin-bottom: 12px;
	.ant-form-item {
		margin-bottom: 0 !important;
	}
`;

interface IProps {
	canRefundInsurance?: boolean;
	refundInsurance: boolean;
	forceInsuranceRefund?: boolean;
	insurance: {
		ins: string;
		status: string;
		totalsV2: {
			total: number;
		};
	};
}

const InsuranceTable = ({ insurance, canRefundInsurance, refundInsurance, forceInsuranceRefund }: IProps) => {
	const { isAdmin } = useCurrentUser();

	if (insurance?.status !== "purchased" && insurance?.status !== "refunded") return null;
	const insuranceAmount = insurance.totalsV2.total;

	const columns: ColumnProps<object>[] = [
		{
			title: "",
			dataIndex: "",
			key: "name",
			fixed: "left",
			render: () =>
				isAdmin ? (
					<a href={`https://partners.xcover.com/bookings/${insurance.ins}`} target="_blank" rel="noreferrer">
						Refund protection
					</a>
				) : (
					"Refund protection"
				)
		},
		{
			title: "Status",
			key: "status",
			fixed: "right",
			render: () => (canRefundInsurance ? insurance.status : "Non-refundable")
		},
		{
			title: "Remaining",
			key: "insuranceRemaining",
			fixed: "right",
			render: () => currency(insuranceAmount)
		},
		...(isAdmin
			? [
					{
						title: "Force refund",
						key: "forceRefund",
						render: () => {
							return <Field name="forceInsuranceRefund" component={CheckboxField} type="checkbox" />;
						}
					}
			  ]
			: []),
		{
			title: "Refund amount",
			fixed: "right",
			key: "refundAmount",
			render: () => {
				return (
					<InputNumberField
						value={100}
						input={{
							name: "insuranceRefundAmount",
							value: refundInsurance || forceInsuranceRefund ? insuranceAmount : 0
						}}
						disabled={true}
						precision={2}
					/>
				);
			}
		}
	];

	return (
		<div className={tableWrapper}>
			<H3>Refund protection</H3>
			<Table columns={columns} dataSource={[{}]} />
		</div>
	);
};

export default InsuranceTable;
