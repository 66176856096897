import { Col, Row } from "@/ui/antd";
import { CSSProperties, FC, ReactNode } from "react";
import { CopyField } from "@/components/CopyField";
import InputLabel from "@/components/fields/helpers/InputLabel";

const ControlsStyle = {
	paddingRight: "16px"
};

const ColumnStyle = {
	background: "var(--surface1)",
	boxShadow: "inset 0 1px 4px 0 rgba(9,30,66,0.13)",
	borderRadius: "var(--rounded-sm)"
};

const ContainerStyle = {
	display: "flex",
	height: 500,
	flexDirection: "column"
} as CSSProperties;

const PreviewContainerStyle = {
	flex: 1,
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
};

const CopyFieldStyle = {
	background: "var(--surface2)",
	padding: 12
};

type PreviewColsProps = {
	code: string;
	controls: ReactNode;
	preview: ReactNode;
};

export const PreviewCols: FC<PreviewColsProps> = ({ code, controls, preview }) => {
	return (
		<Row>
			<Col md={12} sm={24}>
				<div style={ControlsStyle}>{controls}</div>
			</Col>
			<Col md={12} sm={24} style={ColumnStyle}>
				<div style={ContainerStyle}>
					<div style={PreviewContainerStyle}>{preview}</div>
					<div style={CopyFieldStyle}>
						<InputLabel label="Copy this code to your website" />
						<CopyField label="" value={code} />
					</div>
				</div>
			</Col>
		</Row>
	);
};
