import IconButton from "@/components/IconButton";
import { InputNumber } from "@/ui/antd";
import { FC, useEffect, useMemo, useState } from "react";
import { debounce } from "throttle-debounce";

type SimplePaginationProps = {
	pageAction: (pageNumber: number) => void;
	currentPage?: number;
	totalPages?: number;
	style?: any;
};

/*
	Added internal state so we can debounce the input change to allow 
	typing multiple digits without triggering multiple requests.
*/
const SimplePagination: FC<SimplePaginationProps> = ({ pageAction, currentPage = 1, totalPages = 1000, style }) => {
	const [inputValue, setInputValue] = useState<string | number>(currentPage);

	const debouncedChangeHandler = useMemo(() => debounce(500, (value) => value && pageAction(Math.abs(value))), []);

	useEffect(() => {
		setInputValue(currentPage);
	}, [currentPage]);

	useEffect(() => {
		return () => debouncedChangeHandler.cancel();
	}, []);

	const handleInputChange = (value: string | number | null) => {
		if (value === null) return;

		let pageNumber = Number(value);
		if (isNaN(pageNumber) || pageNumber < 1) {
			pageNumber = 1;
		}

		setInputValue(pageNumber);
		debouncedChangeHandler(pageNumber);
	};

	return (
		<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 6, marginBottom: 6, ...style }}>
			<IconButton
				ariaLabel="Previous"
				disabled={currentPage === 1}
				icon="action_chevron_left"
				onClick={() => pageAction(currentPage - 1)}
				showTooltip
				tooltip="Previous"
			/>

			<InputNumber
				max={totalPages || 1000}
				min={1}
				onChange={handleInputChange}
				onInput={handleInputChange}
				style={{ width: 56, minWidth: 56 }}
				value={inputValue}
			/>
			<IconButton
				ariaLabel="Next"
				disabled={currentPage === totalPages}
				icon="action_chevron_right"
				onClick={() => pageAction(currentPage + 1)}
				showTooltip
				tooltip="Next"
			/>
		</div>
	);
};

export default SimplePagination;
