import LoadErrorView from "@/components/LoadErrorView";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { getAppMessage } from "@/state/actions/scanningMessageActions";
import { Component } from "react";
import { connect } from "react-redux";
import ScanningMessageForm from "./ScanningMessageForm";

class ScanningMessage extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.load();
	}

	load() {
		const { params, getAppMessage } = this.props;
		const messageId = params?.messageId;
		if (messageId) {
			getAppMessage(messageId);
		}
	}

	render() {
		const { params, scanningMessage } = this.props;
		const { loading, error, appMessage } = scanningMessage;
		const messageId = params?.messageId;
		return (
			<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
				{!messageId || appMessage ? <ScanningMessageForm params={params} /> : null}
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			scanningMessage: state.scanningMessage
		};
	},
	{ getAppMessage }
)(withRouterHooks(ScanningMessage));
