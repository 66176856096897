import { Component } from "react";
import { Field } from "redux-form";
import { DateTimeField } from "@/components/Fields";

class DiscountCodesDateRange extends Component {
	checkStartDate = () => {
		const { touch } = this.props;
		touch(`endSaleDate`);
		return undefined;
	};

	checkEndDate = (endDate, allValues) => {
		const startDate = allValues.startDate;
		if (!startDate || !endDate) {
			return undefined;
		}
		const result = endDate < startDate ? `Must be after your start date` : undefined;
		return result;
	};

	render() {
		const { timezone, formName } = this.props;
		return (
			<div>
				<Field
					label="Start"
					description="Discounts available from"
					name="startDate"
					component={DateTimeField}
					placeholder="Start Date"
					timeOptions={{
						placeholder: "Start Time"
					}}
					timezone={timezone}
					displayFormat="Do MMM YYYY"
					validate={this.checkStartDate}
					formName={formName}
				/>
				<Field
					label="End"
					description="Discounts end on"
					name="endDate"
					component={DateTimeField}
					placeholder="End Date"
					timeOptions={{
						placeholder: "End Time"
					}}
					timezone={timezone}
					displayFormat="Do MMM YYYY"
					validate={this.checkEndDate}
					formName={formName}
				/>
			</div>
		);
	}
}
export default DiscountCodesDateRange;
