import { FormItemProps as AntdFormItemProps, Form } from "@/ui/antd";
import { cloneElement, FC, FocusEvent, isValidElement, ReactElement, ReactNode, useState } from "react";

interface FormItemRowProps extends AntdFormItemProps {
	/** If true, the validation trigger will become onChange after first onBlur */
	changeTriggerOnBlur?: boolean;
	children: ReactNode;
}

export const FormItem: FC<FormItemRowProps> = ({ changeTriggerOnBlur, children, ...props }) => {
	const [validationTriggerType, setValidationTriggerType] = useState<"onBlur" | "onChange">("onBlur");

	let childrenWithProps = children;
	const shouldChangeTriggerOnBlur = changeTriggerOnBlur && isValidElement(children);

	if (shouldChangeTriggerOnBlur) {
		const childElement = children as ReactElement<{ onBlur?: (e: FocusEvent) => void }>;
		const originalOnBlur = childElement.props.onBlur;

		childrenWithProps = cloneElement(childElement, {
			onBlur: (e: FocusEvent) => {
				if (originalOnBlur) {
					originalOnBlur(e);
				}
				setValidationTriggerType("onChange");
			}
		});
	}

	return (
		<Form.Item validateTrigger={validationTriggerType} {...props}>
			{childrenWithProps}
		</Form.Item>
	);
};
