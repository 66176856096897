import { css } from "@emotion/css";

export const deviceActionButtonStyle = css({
	backgroundColor: "white",
	width: "50%",
	"@media(max-width: 600px)": {
		width: "100%"
	}
});

export const defaultDeviceViewWrapperStyle = css({
	padding: "20px 20px 0px 20px",
	borderRadius: 8,
	backgroundColor: "#F0F0F0",

	display: "flex",
	flexDirection: "column",
	gap: 12
});
