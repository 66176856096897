import axios from "./AxiosWrapper";

const ReferralService = {
	async getUserReferral(userId, location) {
		const response = await axios.get(`referrals/user/${userId}`, {
			headers: { "x-override-location": location }
		});
		const referralValues = response.data;
		return referralValues;
	},
	async updateUserReferralBankAccount(userId, values) {
		const response = await axios.post(`referrals/user/update-bank-account/${userId}`, values);
		const referralValues = response.data;
		return referralValues;
	},
	async updateUserReferralDetails(userId, referralObj) {
		const response = await axios.post(`admin/referrals/update-details/${userId}`, referralObj);
		const referralValues = response.data;
		return referralValues;
	},
	async managePayment(userId, paymentObj, location) {
		paymentObj.location = location;
		const response = await axios.post(`admin/referrals/manage-payment/${userId}`, paymentObj, {
			headers: { "x-override-location": location }
		});
		const referralValues = response.data;
		return referralValues;
	},
	async removePayment(userId, paymentObj, location) {
		const response = await axios.post(
			`admin/referrals/remove-payment/${userId}`,
			{ ...paymentObj },
			{
				headers: { "x-override-location": location }
			}
		);
		const referralValues = response.data;
		return referralValues;
	},
	async associateUserToReferral(id, userIdOfReferrer) {
		await axios.post(`admin/referrals/associate-referral/${id}/${userIdOfReferrer}`);
	}
};
export default ReferralService;
