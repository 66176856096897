const checkingCondition = (condition, conditionValue, formValue) => {
	switch (condition) {
		case "checkboxEqual":
			return formValue.toString() === conditionValue;
		case "checkboxNotEqual":
			return formValue.toString() !== conditionValue;
		case "===":
			return parseInt(formValue) === parseInt(conditionValue);
		case "!==":
			return parseInt(formValue) !== parseInt(conditionValue);
		case "<":
			return parseInt(formValue) < parseInt(conditionValue);
		case "<=":
			return parseInt(formValue) <= parseInt(conditionValue);
		case ">":
			return parseInt(formValue) > parseInt(conditionValue);
		case ">=":
			return parseInt(formValue) >= parseInt(conditionValue);
		case "isEqualTo":
			return formValue.toLowerCase() === conditionValue.toLowerCase();
		case "isNotEqualTo":
			return formValue.toLowerCase() !== conditionValue.toLowerCase();
		case "beginsWith":
			return formValue.toLowerCase().substring(0, conditionValue.length) === conditionValue.toLowerCase();
		case "endsWith": {
			const startingPoint = formValue.length - conditionValue.length;
			const endingPoint = formValue.length;
			return formValue.toLowerCase().substring(startingPoint, endingPoint) === conditionValue.toLowerCase();
		}
		case "contains":
			return formValue.toLowerCase().indexOf(conditionValue.toLowerCase()) !== -1;
		case "notContain":
			return formValue.toLowerCase().indexOf(conditionValue.toLowerCase()) === -1;
		default:
			return false;
	}
};

/**
 * Compare values.
 *
 * @param {string or boolean} formValue
 * @param {string} conditionValue
 * @param {string} condition
 * @returns true or false
 */
const compareValues = (formValue, conditionValue, condition) => {
	const compareFormValues = (condition, conditionValue, formValue) => {
		if (Array.isArray(formValue)) {
			let result;
			if (condition === "contains") {
				result = formValue.some((fValue) => {
					return checkingCondition(condition, conditionValue, fValue);
				});
			} else {
				result = formValue.every((fValue) => {
					return checkingCondition(condition, conditionValue, fValue);
				});
			}
			return result;
		}
		return checkingCondition(condition, conditionValue, formValue);
	};
	conditionValue =
		conditionValue && String(conditionValue).indexOf("{|}") !== -1 ? conditionValue.split("{|}") : conditionValue;
	formValue = formValue && String(formValue).indexOf("{|}") !== -1 ? formValue.split("{|}") : formValue;
	if (Array.isArray(conditionValue)) {
		let result;
		if (condition === "contains") {
			result = conditionValue.every((cValue) => {
				return compareFormValues(condition, cValue, formValue);
			});
		} else {
			result = conditionValue.some((cValue) => {
				return compareFormValues(condition, cValue, formValue);
			});
		}
		return result;
	}
	return compareFormValues(condition, conditionValue, formValue);
};

/**
 * Check condition of additional questions
 *
 * @param {object} question
 * @param {array} ticketsAdditionalQuestions
 * @param {objectId} ticketId
 * @returns true or false
 */
export const checkCondition = (question, ticketsAdditionalQuestions = {}, ticketId) => {
	if (question.showConditions && question.conditions && question.conditions.length > 0) {
		let fakeTrue = false;
		// f.some only stops when it finds true. fakeTrue is used when loop should stop but needs to return false.
		const pass = question.conditions.some((condition, index) => {
			if (
				ticketsAdditionalQuestions[ticketId] &&
				condition.additionalQuestionId in ticketsAdditionalQuestions[ticketId]
			) {
				const formValue = ticketsAdditionalQuestions[ticketId][condition.additionalQuestionId];
				const conditionValue = condition.value;
				const compareValuesTrue =
					formValue && formValue !== "" ? compareValues(formValue, conditionValue, condition.condition) : false;
				if (condition.options === "||" && compareValuesTrue) {
					return true;
				} else if (condition.options === "&&" && !compareValuesTrue) {
					fakeTrue = true;
					return true;
				} else if (condition.options === "&&" && compareValuesTrue) {
					// If someone adds new condition and delete it, condition.options value is still staying
					// In this case, the function should ignore the && options and return true instead of trying to check next condition
					// That's why the index and length should be checked
					// Check test case 5
					return index + 1 === question.conditions.length;
				}
				return compareValuesTrue;
			} else if (
				ticketsAdditionalQuestions[ticketId] &&
				!(condition.additionalQuestionId in ticketsAdditionalQuestions[ticketId])
			) {
				fakeTrue = true;
				return true;
			}
			return null;
		});
		return !!(pass && !fakeTrue);
	}
	return true;
};

export const checkOrderQuestionCondition = (question, orderAdditionalQuestions) => {
	if (question.showConditions && question.conditions && question.conditions.length > 0) {
		let fakeTrue = false;
		// f.some only stops when it finds true. fakeTrue is used when loop should stop but needs to return false.
		const pass = question.conditions.some((condition, index) => {
			if (orderAdditionalQuestions && condition.additionalQuestionId in orderAdditionalQuestions) {
				const formValue = orderAdditionalQuestions[condition.additionalQuestionId];
				const conditionValue = condition.value;
				const compareValuesTrue =
					formValue && formValue !== "" ? compareValues(formValue, conditionValue, condition.condition) : false;
				if (condition.options === "||" && compareValuesTrue) {
					return true;
				} else if (condition.options === "&&" && !compareValuesTrue) {
					fakeTrue = true;
					return true;
				} else if (condition.options === "&&" && compareValuesTrue) {
					// If someone adds new condition and delete it, condition.options value is still staying
					// In this case, the function should ignore the && options and return true instead of trying to check next condition
					// That's why the index and length should be checked
					// Check test case 5
					return index + 1 === question.conditions.length;
				}
				return compareValuesTrue;
			} else if (orderAdditionalQuestions && !(condition.additionalQuestionId in orderAdditionalQuestions)) {
				fakeTrue = true;
				return true;
			}
			return null;
		});
		return !!(pass && !fakeTrue);
	}
	return true;
};
