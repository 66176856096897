import { Select, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { Label } from "@/components/text/Text";
import { getReferralPayoutReport } from "@/state/actions/adminReports/financeActions";
import { currency } from "@/utils/Format";

class ReferralPayoutReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: "amountOwed",
			page: 1
		};
	}

	componentDidMount() {
		this.load();
	}

	load = (sortBy = "amountOwed", page = 1) => {
		const { getReferralPayoutReport } = this.props;
		getReferralPayoutReport(sortBy, page);
	};

	handleChange = (value) => {
		const { page } = this.state;
		this.setState({
			sortBy: value
		});
		this.load(value, page);
	};

	changePage = (value) => {
		const { sortBy } = this.state;
		this.setState({
			page: value
		});
		this.load(sortBy, value);
	};

	runLoad = () => {
		const { page, sortBy } = this.props;
		this.load(page, sortBy);
	};

	render() {
		const { page } = this.state;
		const { referralPayoutReport } = this.props;
		const columns = [
			{
				title: "User",
				dataIndex: "userId",
				key: "userId",
				render: (value, record) => {
					return (
						<Link to={`/console/admin/users/${value}`}>{`${record?.user?.firstName} ${record?.user?.lastName}`}</Link>
					);
				}
			},
			{
				title: "Users Referred",
				dataIndex: "usersReferred",
				key: "usersReferred",
				render: (value) => {
					return value;
				}
			},

			{
				title: "Total Money Generated",
				dataIndex: "amountEarned",
				key: "amountEarned",
				render: (value) => {
					return currency(value);
				}
			},

			{
				title: "Total Money Available for Payout",
				dataIndex: "validForPayout",
				key: "validForPayout",
				render: (value) => {
					return currency(value);
				}
			},
			{
				title: "Total Money Paid",
				dataIndex: "amountPaidOut",
				key: "amountPaidOut",
				render: (value) => {
					if (value) {
						return currency(value);
					}
					return currency(0);
				}
			},
			{
				title: "Total Money Owed",
				dataIndex: "amountOwed",
				key: "amountOwed",
				render: (value) => {
					if (value) {
						return currency(value);
					}
					return currency(0);
				}
			}
		];
		return (
			<div>
				<PageHeading title="Referral Payouts Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView
					loading={referralPayoutReport.loading}
					error={referralPayoutReport.error}
					retryAction={this.load}
				>
					<ReportFilters>
						<Label>Sort by</Label>
						<Select
							defaultValue="amountOwed"
							onChange={this.handleChange}
							options={[
								{ value: "usersReferred", label: "Users Referred" },
								{ value: "amountEarned", label: "Total Money Generated" },
								{ value: "validForPayout", label: "Total Money Valid for Payout" },
								{ value: "amountPaidOut", label: "Total Money Paid" },
								{ value: "amountOwed", label: "Total Money Owed" }
							]}
						/>
					</ReportFilters>

					{referralPayoutReport.data ? (
						<ReportContent>
							<Table
								columns={columns}
								dataSource={referralPayoutReport.data}
								rowKey="_id"
								pagination={{
									showSizeChanger: false,
									total: referralPayoutReport.data.length,
									current: page,
									onChange: this.changePage
								}}
							/>
						</ReportContent>
					) : null}
				</LoadErrorView>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		referralPayoutReport: state.adminReports.finance.referralPayoutReport
	}),
	{ getReferralPayoutReport }
)(ReferralPayoutReport);
