import { css } from "@emotion/css";
import ObjectID from "bson-objectid";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { Field, FieldArray, change } from "redux-form";
import { ImageUploadField, LegacyInputField, SelectField } from "../components/Fields";
import IconButton from "../components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import NoResult from "../components/noResults/NoResults";
import ResponsiveTable from "../components/table/ResponsiveTable";
import TableActions from "../components/table/TableActions";
import { P } from "../components/text/Text";
import { includes } from "../utils/Array";
import { required } from "../utils/Validators";

const packageCellLarge = css({
	"@media(max-width: 600px)": {
		float: "left",
		marginRight: 10,
		minWidth: "75vw"
	}
});

const hideRow = css`
	display: none;
`;

const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	}),
	p: css({
		padding: 0,
		margin: 0,
		"@media(max-width: 600px)": {
			borderBottom: "1px solid #d9d9d9",
			paddingBottom: 8
		}
	})
};

/**
 * Tour groups table
 *
 * @class TourGroups
 * @extends {Component}
 */
class TourGroups extends Component {
	createGroup(fields) {
		const group = {
			name: null,
			events: [],
			_id: ObjectID().toHexString()
		};
		fields.push(group);
	}

	render() {
		const { dispatch, userEvents, groups, formName, events } = this.props;

		return (
			<div>
				<div className={styles.p}>
					<P>
						Group your events to better present them to your buyers. When making live your “Tour groups” all your
						unpublished events that are a part of Tour groups will be published automatically.
					</P>
				</div>
				<FieldArray
					name="groups"
					formName={formName}
					component={TourGroup}
					groups={groups}
					userEvents={userEvents}
					events={events}
					createGroup={this.createGroup}
					dispatch={dispatch}
				/>
			</div>
		);
	}
}

/**
 * Each tour inside tour group table
 *
 * @class TourGroup
 * @extends {Component}
 */
class TourGroup extends Component {
	nameRequired = required("Name required");

	constructor(props) {
		super(props);
		this.state = { expandedRowKeys: [] };
	}

	onExpandClick = (key) => {
		const tempRowKeys = [...this.state.expandedRowKeys];
		if (includes(this.state.expandedRowKeys, key)) {
			const index = tempRowKeys.indexOf(key);
			tempRowKeys.splice(index, 1);
		} else {
			tempRowKeys.push(key);
		}
		this.setState({ expandedRowKeys: tempRowKeys });
	};

	deleteGroup = (index, item) => {
		const { dispatch, formName } = this.props;
		const tempRowKeys = [...this.state.expandedRowKeys];
		dispatch(change(formName, `groups[${index}].deleted`, true));
		if (includes(this.state.expandedRowKeys, item.rowKey)) {
			const index = tempRowKeys.indexOf(item.rowKey);
			tempRowKeys.splice(index, 1);
		}
		this.setState({ expandedRowKeys: tempRowKeys });
		return true;
	};

	render() {
		const { fields, groups, userEvents = [], createGroup, events = [], dispatch, formName } = this.props;
		const { expandedRowKeys } = this.state;
		const options = events
			.map((tourEventId) => {
				const userEvent = userEvents.find((userEvent) => String(tourEventId) === String(userEvent._id));
				if (userEvent) {
					return { value: userEvent._id, label: userEvent.name };
				}
				return false;
			})
			.filter((value) => value);
		const columns = [
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Group Name <span className={styles.required}> (required)</span>
					</span>
				),
				dataIndex: "name",
				key: "name",
				width: "41%",
				render: (_, __, index) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<Field
								inline
								name={`groups[${index}].name`}
								component={LegacyInputField}
								placeholder="Name"
								validate={this.nameRequired}
							/>
						</div>
					);
				}
			},
			{
				title: "Events",
				dataIndex: "events",
				key: "events",
				width: "41%",
				render: (_, __, index) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<Field
								style={{ maxWidth: 180 }}
								mode="multiple"
								name={`groups[${index}].events`}
								component={SelectField}
								options={options}
							/>
						</div>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				render: (item, _, index) => (
					<div className={packageCellLarge}>
						<TableActions containerStyle={{ width: 200 }}>
							<div>
								<div style={{ display: "inline-block" }}>
									<IconButton
										icon="settings"
										tooltip="Settings"
										showTooltip
										onClick={() => {
											this.onExpandClick(item.key);
										}}
										ariaLabel="Settings"
									/>
								</div>
								<IconButton
									icon="delete"
									tooltip="Delete"
									onClick={() => {
										this.deleteGroup(index, item);
									}}
									showTooltip
									ariaLabel="Delete"
								/>
							</div>
						</TableActions>
					</div>
				)
			}
		];
		//pre render row
		if (groups) {
			groups.forEach((group, index) => {
				group.key = index;
			});
		}

		return (
			<div>
				<If condition={!groups || !groups.length}>
					<Then>
						<NoResult
							noTitle
							message=""
							action={() => createGroup(fields, userEvents)}
							actionTxt="Create Group"
							secondary
							inverse
						/>
					</Then>
					<Else>
						{() => (
							<div style={{ width: "100%", overflowX: "auto" }}>
								<ResponsiveTable
									pagination={false}
									draggable={{ dispatch, formName, fieldName: "groups" }}
									columns={columns}
									dataSource={groups}
									rowKey="key"
									rowClassName={(record) => {
										return record.deleted ? hideRow : "";
									}}
									expandedRowKeys={expandedRowKeys}
									expandedRowRender={(_, index) => {
										return <Field name={`groups[${index}].image`} component={ImageUploadField} aspectRatio={2} />;
									}}
									expandIconColumnIndex={99}
									expandIconAsCell={false}
								/>
								<ButtonsBar>
									<LegacyButton
										onClick={() => createGroup(fields, userEvents)}
										icon={{ name: "plus", left: true }}
										type="action"
										ariaLabel="Add Group"
									>
										Add group
									</LegacyButton>
								</ButtonsBar>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default TourGroups;
