import { Alert, Modal, Table, Tag } from "@/ui/antd";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { H4, P } from "@/components/text/Text";
import { currency } from "@/utils/Format";

const RefundConfirmationModal = ({
	confirmationModalOpen,
	setConfirmationModalOpen,
	submit,
	totalRefundAmount,
	ticketsToCancel,
	giftCardsToCancel,
	merchandiseToCancel,
	clientDonation,
	clientDonationTicketType,
	refundInsurance,
	insurance,
	sendEmails
}) => {
	const numberOfTicketsBeingCanceled = Object.values(ticketsToCancel || {}).filter((item) => item.cancel).length;
	const numberOfMerchandiseBeingCanceled = Object.values(merchandiseToCancel || {}).filter(
		(item) => item.cancel
	).length;
	return (
		<Modal
			title="Review"
			open={confirmationModalOpen}
			onCancel={() => setConfirmationModalOpen(false)}
			footer={[
				<ButtonsBar footer>
					<LegacyButton
						key="cancel"
						htmlType="button"
						ariaLabel="Cancel"
						onClick={() => setConfirmationModalOpen(false)}
					>
						Go back
					</LegacyButton>
					<LegacyButton key="submit" type="warning" htmlType="submit" ariaLabel="Confirm" onClick={submit}>
						Confirm
					</LegacyButton>
				</ButtonsBar>
			]}
		>
			<Alert
				style={{ marginBottom: 12 }}
				message="Please note"
				description="It can take 2 - 5 business days for a refund to be processed."
				type="info"
				showIcon
			/>
			<TicketBreakdown ticketsToCancel={ticketsToCancel} />
			<GiftCardBreakdown giftCardsToCancel={giftCardsToCancel} />
			<MerchandiseBreakdown merchandiseToCancel={merchandiseToCancel} />
			<ClientDonationBreakdown clientDonation={clientDonation} clientDonationTicketType={clientDonationTicketType} />
			<InsuranceBreakdown refundInsurance={refundInsurance} insurance={insurance} />
			<div style={{ textAlign: "right", marginTop: 12 }}>
				<H4>Total to refund: {currency(totalRefundAmount)}</H4>
				{numberOfTicketsBeingCanceled > 0 ? <H4>Number of tickets to cancel: {numberOfTicketsBeingCanceled}</H4> : null}
				{numberOfMerchandiseBeingCanceled > 0 ? (
					<H4>Number of add-ons to cancel: {numberOfMerchandiseBeingCanceled}</H4>
				) : null}
				{sendEmails ? <H4>Confirmation email will be sent</H4> : <H4>Confirmation email will not be sent</H4>}
			</div>
		</Modal>
	);
};

const TicketBreakdown = ({ ticketsToCancel }) => {
	if (!ticketsToCancel) return null;
	const tickets = Object.values(ticketsToCancel).filter((ticket) => ticket.amount || ticket.cancel);
	if (!tickets.length) return null;
	const columns = [
		{
			title: "Ticket",
			key: "ticketDetails",
			fixed: "left",
			render: (_, ticket) => {
				return (
					<P>
						{ticket.ticketTypeName}: {ticket.firstName} {ticket.lastName}
					</P>
				);
			}
		},
		{
			title: "Action",
			key: "action",
			dataIndex: "cancel",
			fixed: "right",
			render: (cancelled) => {
				return cancelled ? <Tag color="#C0212C">Cancel</Tag> : null;
			}
		},
		{
			title: () => <div style={{ textAlign: "right" }}>Refunding</div>,
			key: "amount",
			dataIndex: "amount",
			fixed: "right",
			render: (amount) => <div style={{ textAlign: "right" }}>{currency(amount)}</div>
		}
	];
	return (
		<div style={{ marginBottom: 12 }}>
			<H4>Tickets</H4>
			<Table dataSource={tickets} columns={columns} pagination={false} rowKey={(data) => data._id} />
		</div>
	);
};

const GiftCardBreakdown = ({ giftCardsToCancel }) => {
	if (!giftCardsToCancel) return null;
	const giftCards = Object.values(giftCardsToCancel).filter((giftCard) => giftCard.amount || giftCard.cancel);
	if (!giftCards.length) return null;
	const columns = [
		{
			title: "GiftCard",
			key: "giftCardDetails",
			fixed: "left",
			render: (_, giftCard) => {
				return (
					<P>
						{giftCard.giftCardTypeName}: {giftCard.firstName} {giftCard.lastName}
					</P>
				);
			}
		},
		{
			title: "Action",
			key: "action",
			dataIndex: "cancel",
			fixed: "right",
			render: (cancelled) => {
				return cancelled ? <Tag color="#C0212C">Cancel</Tag> : null;
			}
		},
		{
			title: () => <div style={{ textAlign: "right" }}>Refunding</div>,
			key: "amount",
			dataIndex: "amount",
			fixed: "right",
			render: (amount) => <div style={{ textAlign: "right" }}>{currency(amount)}</div>
		}
	];
	return (
		<div style={{ marginBottom: 12 }}>
			<H4>Gift cards</H4>
			<Table dataSource={giftCards} columns={columns} pagination={false} />
		</div>
	);
};

const MerchandiseBreakdown = ({ merchandiseToCancel }) => {
	if (!merchandiseToCancel) return null;
	const merchandise = Object.values(merchandiseToCancel).filter((merch) => merch.amount || merch.cancel);
	if (!merchandise.length) return null;
	const columns = [
		{
			title: "Add-on",
			key: "merchandiseDetails",
			fixed: "left",
			render: (_, merch) => {
				return (
					<P>
						{merch.merchandiseTypeName}: {merch.firstName} {merch.lastName}
					</P>
				);
			}
		},
		{
			title: "Action",
			key: "Action",
			dataIndex: "cancel",
			fixed: "right",
			render: (cancelled) => {
				return cancelled ? <Tag color="#C0212C">Cancel</Tag> : null;
			}
		},
		{
			title: () => <div style={{ textAlign: "right" }}>Refunding</div>,
			key: "amount",
			dataIndex: "amount",
			fixed: "right",
			render: (amount) => <div style={{ textAlign: "right" }}>{currency(amount)}</div>
		}
	];
	return (
		<div style={{ marginBottom: 12 }}>
			<H4>Add-ons</H4>
			<Table dataSource={merchandise} columns={columns} pagination={false} />
		</div>
	);
};

const ClientDonationBreakdown = ({ clientDonation, clientDonationTicketType }) => {
	if (!clientDonation || !clientDonationTicketType) return null;
	const columns = [
		{
			title: "Donation name",
			dataIndex: "name",
			key: "donationName",
			fixed: "left",
			render: () => clientDonationTicketType.name
		},
		{
			title: () => <div style={{ textAlign: "right" }}>Refunding</div>,
			fixed: "right",
			key: "refundAmount",
			render: () => <div style={{ textAlign: "right" }}>{currency(clientDonation)}</div>
		}
	];
	return (
		<div style={{ marginBottom: 12 }}>
			<H4>Donation</H4>
			<Table dataSource={[{}]} columns={columns} pagination={false} />
		</div>
	);
};

const InsuranceBreakdown = ({ refundInsurance, insurance }) => {
	if (!refundInsurance) return null;
	const columns = [
		{
			title: "Refund protection",
			key: "insuranceName",
			fixed: "left",
			render: () => "Refund protection"
		},
		{
			title: () => <div style={{ textAlign: "right" }}>Refunding</div>,
			fixed: "right",
			key: "refundAmount",
			render: () => <div style={{ textAlign: "right" }}>{currency(insurance?.totalsV2?.total)}</div>
		}
	];
	return (
		<div style={{ marginBottom: 12 }}>
			<H4>Refund protection</H4>
			<Table dataSource={[{}]} columns={columns} pagination={false} />
		</div>
	);
};

export default RefundConfirmationModal;
