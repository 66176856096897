import { Tabs } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { previewCompleteMessage, saveEvent } from "@/state/actions/eventActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import CalendarMessage from "./CalendarMessage";
import ConfirmationMessage from "./ConfirmationMessage";
import EmailMessage from "./EmailMessage";
import TicketMessages from "./TicketMessages";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "messages";

class OrderMessages extends Component {
	toggleCompleteMessageModal = () => {
		const { previewCompleteMessage, messages, params } = this.props;
		const eventId = params?.eventId;
		previewCompleteMessage(eventId, messages.orderMessage);
		this.setState({ showCompletePreview: !this.state.showCompletePreview });
	};
	save = (values) => {
		const { params, saveEvent } = this.props;
		const eventId = params?.eventId;
		const newMessages = values.messages;
		saveEvent(eventId, {
			messages: newMessages
		});
	};
	changeFieldValue = (field, value) => {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	};

	render() {
		const { handleSubmit, currentEvent, messages, banner, ticketLogo, previewCompleteMessage } = this.props;
		return (
			<form onSubmit={handleSubmit(this.save)}>
				<PageHeading title="Messages" />
				<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
					<ContentBlock>
						<P>
							There are 4 different places where you can communicate with your guests when they have purchased a ticket.
							You can choose to add a custom message to each place or you can leave it blank and let our templates do
							their job.
						</P>
						<Tabs
							defaultActiveKey="0"
							items={[
								{
									children: (
										<ConfirmationMessage
											changeFieldValue={this.changeFieldValue}
											currentEvent={currentEvent}
											messages={messages}
											previewCompleteMessage={previewCompleteMessage}
										/>
									),
									key: "0",
									label: "Confirmation page"
								},
								{
									children: (
										<EmailMessage
											formName={formName}
											changeFieldValue={this.changeFieldValue}
											currentEvent={currentEvent}
											messages={messages}
										/>
									),
									key: "1",
									label: "Email message"
								},
								{
									children: (
										<TicketMessages
											changeFieldValue={this.changeFieldValue}
											currentEvent={currentEvent}
											messages={messages}
											banner={banner}
											logo={ticketLogo}
										/>
									),
									key: "2",
									label: "Ticket message"
								},
								{
									children: <CalendarMessage messages={messages} />,
									key: "3",
									label: "Calendar description"
								}
							]}
						/>
					</ContentBlock>
					<FormBar formLayout="horizontal">
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							size="large"
							disabled={currentEvent.save.loading}
							ariaLabel="Save"
						>
							Save
						</LegacyButton>
					</FormBar>
				</LoadErrorView>
			</form>
		);
	}
}

OrderMessages = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(OrderMessages);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		const messages = selector(state, "messages") || {};
		return {
			initialValues: state.currentEvent.event,
			currentEvent: state.currentEvent,
			customEmailMessage: selector(state, "messages.customEmailMessage"),
			messages,
			banner: selector(state, "messages.banner"),
			ticketLogo: selector(state, "messages.ticketLogo"),
			hideCharity: selector(state, "messages.hideImpact")
		};
	},
	{ saveEvent, previewCompleteMessage }
)(withSaveCheck(withRouterHooks(OrderMessages), formName));
