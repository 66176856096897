const Area = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0H24V24H0z" />
				<path
					id="ele-id-fill"
					fill="#67717E"
					d="M10.022 13.522V16.5H8.435v-1.489h-5.29V16.5H1.558v-2.978h8.464zm7.167.22c.209.006.66 1.046.66 1.62 0 .575-.575 1.04-1.285 1.04-.29 0-.75-.15-1.042-.489-.418-.487-.938-1.397-.625-1.59.348-.215 2.084-.589 2.292-.582zm2.465 0c.208-.008 1.944.366 2.292.58.313.194-.207 1.104-.625 1.591-.292.339-.751.49-1.042.49-.71 0-1.285-.466-1.285-1.04 0-.575.451-1.615.66-1.622zM15.149 7.5c1.245 0 2.027 1.55 2.187 3.09.125 1.193.125 1.79-.208 2.294-.284.43-2.253.967-2.535.826-.825-.412-1.25-1.9-1.25-3.12 0-1.637.56-3.09 1.806-3.09zm6.545 0c1.246 0 1.806 1.453 1.806 3.09 0 1.22-.425 2.708-1.25 3.12-.282.141-2.251-.395-2.535-.826-.333-.504-.333-1.101-.208-2.294.16-1.54.942-3.09 2.187-3.09zM11.08 11.04v1.49H9.493v-1.49h1.587zm-8.993 0v1.49H.5v-1.49h1.587zm5.29-3.473c.582 0 1.058.447 1.058.992v3.97h-5.29V8.56c0-.545.476-.992 1.058-.992h3.174z"
				/>
			</g>
		</svg>
	);
};
export default Area;
