import { Field } from "redux-form";
import { ImageUploadField, LegacyInputField, RichTextAreaField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import { required } from "@/utils/Validators";

const titleRequired = required("A title is required");

const Presenter = ({ fieldPath, presenter, index, totalElements, move, deleteElement }) => {
	return (
		<>
			<div style={{ display: "flex" }}>
				<div style={{ flex: 1 }}>
					<div style={{ display: "flex" }}>
						<div style={{ flex: 1, paddingRight: 12 }}>
							<div style={{ maxWidth: 450 }}>
								<Field name={`${fieldPath}.image`} label="Image" component={ImageUploadField} aspectRatio={1} />
							</div>
						</div>
						<div style={{ flex: 1 }}>
							<Field
								name={`${fieldPath}.name`}
								label="Name"
								component={LegacyInputField}
								required
								validate={titleRequired}
							/>

							<Field name={`${fieldPath}.description`} label="Description" component={RichTextAreaField} cols={3} />
						</div>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<IconButton
						icon="arrow_down"
						ariaLabel="Move down"
						disabled={index === totalElements - 1}
						onClick={() => {
							move(index, index + 1);
						}}
					/>
					<IconButton
						icon="arrow_up"
						ariaLabel="Move up"
						disabled={index === 0}
						onClick={() => {
							move(index, index - 1);
						}}
					/>
					<IconButton
						icon="delete"
						ariaLabel="Delete"
						onClick={() => {
							deleteElement(presenter, index);
						}}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default Presenter;
