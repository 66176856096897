import axios from "./AxiosWrapper";

export type AvailableSteps = ("seatingMaps" | "details" | "addOns" | "payment" | "afterCheckoutQuestions")[];

const NewOrderService = {
	async createManualOrder(
		eventId: string,
		eventDateId: string,
		requestedTickets: any,
		clientDonation: number,
		paymentType: string,
		note: string,
		skipEmails: boolean
	): Promise<
		| {
				success: true;
				orderId: string;
				location: string;
				availableSteps: AvailableSteps;
		  }
		| { success: false }
	> {
		try {
			return (
				await axios.put(`orders/${eventId}/create-manual-order`, {
					eventId,
					eventDateId,
					requestedTickets,
					clientDonation,
					note,
					paymentType,
					skipEmails
				})
			).data;
		} catch {
			return { success: false };
		}
	}
};

export default NewOrderService;
