import ax from "axios";
import LocationService from "../services/LocationService";
import axios from "./AxiosWrapper";

class OrdersService {
	static instance = null;

	static createInstance() {
		let object = new OrdersService();
		return object;
	}

	static getInstance() {
		if (!OrdersService.instance) {
			OrdersService.instance = OrdersService.createInstance();
		}
		return OrdersService.instance;
	}

	async update(id, order, eventId, token) {
		const response = token
			? await ax.post(`/public-api/edit-order/order/${id}/${eventId}/${token}`, order)
			: await axios.post(`orders/${id}`, order);

		return response.data;
	}

	async get(id, eventId, token) {
		const response = token
			? await ax.get(`/public-api/edit-order/order/${id}/${eventId}/${token}`)
			: await axios.get(`orders/${id}`);
		return response.data;
	}

	async getGlobalRefundFundsAvailable(orderId) {
		const response = await axios.get(`orders/refund/global-available-funds/${orderId}`);
		return response.data;
	}

	async getRefundFundsAvailable(orderId) {
		const response = await axios.get(`orders/refund/available-funds/${orderId}`);
		return response.data;
	}

	async sendOrder(orderId, forceSendTickets = true, emailTo = false, location) {
		const response = await axios.get(`orders/send-order/${orderId}`, {
			params: { forceSendTickets, emailTo },
			headers: { "x-override-location": location }
		});
		return response.data;
	}

	async sendAttendeeTickets(orderId, ticketIds = [], location) {
		const response = await axios.get(`orders/send-attendee-tickets/${orderId}`, {
			params: { ticketIds },
			headers: { "x-override-location": location }
		});
		return response.data;
	}

	async userSendOrder(
		orderId,
		forceSendTickets = true,
		token,
		emailTo = false,
		emailAttendees = false,
		ticketIds = [],
		resendOrder = false
	) {
		const response = await ax.get(`/public-api/user-send-order/${orderId}`, {
			params: { forceSendTickets, token, emailAttendees, emailTo, ticketIds, resendOrder },
			headers: { "x-event-level-location": LocationService.getLocation(false) }
		});
		return response.data;
	}

	async refund(id, refundObject) {
		const response = await axios.post(`orders/refund/${id}`, refundObject);
		return response.data;
	}

	async globalRefund(id, refundObject) {
		const response = await axios.post(`orders/refund/global/${id}`, refundObject);
		return response.data;
	}

	async downloadInvoice(orderId) {
		const response = await axios.get(`orders/download-invoice/${orderId}`, {
			responseType: "blob"
		});
		return response.data;
	}

	async massRefund(eventId, dateId, refundFees, sendEmails, reason, customMessage) {
		const response = await axios.post(`admin/orders/massRefund/${eventId}`, {
			dateId,
			refundFees,
			sendEmails,
			reason,
			customMessage
		});
		return response.data;
	}

	async find(queryBy, page, location) {
		const headers = {};
		if (location) {
			headers["x-override-location"] = location;
		}
		const query = queryBy.query;
		delete queryBy.query;
		const response = await axios.get(`orders/`, {
			params: {
				query,
				status: queryBy,
				page
			},
			headers
		});
		const orders = response.data;
		return orders;
	}

	// This is used to delete date or additional questions (checking order before delete them)
	async findForDeleteFields(dateId) {
		const response = await axios.get(`orders/find-for-delete-fields`, {
			params: { dateId }
		});
		return response.data;
	}

	async findAnsweredAdditionalQuestions(eventId) {
		const response = await axios.get(`orders/find-answered-additional-questions`, {
			params: {
				eventId
			}
		});
		const answeredAdditionalQuestions = response.data;
		return answeredAdditionalQuestions;
	}

	async getOrdersCsv(queryBy, location) {
		const query = queryBy.query;
		delete queryBy.query;
		const response = await axios.get(`orders/csv/`, {
			responseType: "blob",
			params: { query, status: queryBy },
			headers: {
				"x-override-location": location
			}
		});
		return response.data;
	}

	async bulkUpload(eventId, eventDateId, emailOrder, csv, fields) {
		const response = await axios.post(`orders/bulk-upload/${eventId}`, {
			csv,
			fields,
			eventDateId,
			emailOrder
		});
		return response.data;
	}

	async swapTicket(orderId, values) {
		const response = await axios.post(`orders/swap/${orderId}`, {
			...values
		});
		return response.data;
	}

	async hubspotSync(orderId) {
		const response = await axios.post(`/orders/hubspotSync/${orderId}`);
		return response.data;
	}

	async getEventDateCapacity(eventId, eventDateId) {
		const response = await axios.get(`/orders/swap/event-capacity-check/${eventId}/${eventDateId}`);
		return response.data;
	}

	async forceComplete(orderId) {
		const response = await axios.post(`orders/force-complete/${orderId}`);
		return response.data;
	}
}
export default OrdersService.getInstance();
