import { notification } from "@/ui/antd";
import AccessCodesService from "../../services/AccessCodesService";
import FileDownload from "../../utils/FileDownload";

export const GET_ACCESS_CODES_REQUEST = "GET_ACCESS_CODES_REQUEST";
export const GET_ACCESS_CODES_SUCCESS = "GET_ACCESS_CODES_SUCCESS";
export const GET_ACCESS_CODES_ERROR = "GET_ACCESS_CODES_ERROR";

export const SAVE_ACCESS_CODES_REQUEST = "SAVE_ACCESS_CODES_REQUEST";
export const SAVE_ACCESS_CODES_SUCCESS = "SAVE_ACCESS_CODES_SUCCESS";
export const SAVE_ACCESS_CODES_ERROR = "SAVE_ACCESS_CODES_ERROR";

const DELETE_ACCESS_CODES_REQUEST = "DELETE_ACCESS_CODES_REQUEST";
export const DELETE_ACCESS_CODES_SUCCESS = "DELETE_ACCESS_CODES_SUCCESS";
export const DELETE_ACCESS_CODES_ERROR = "DELETE_ACCESS_CODES_ERROR";

export const DELETE_ALL_ACCESS_CODES_REQUEST = "DELETE_ALL_ACCESS_CODES_REQUEST";
export const DELETE_ALL_ACCESS_CODES_SUCCESS = "DELETE_ALL_ACCESS_CODES_SUCCESS";
export const DELETE_ALL_ACCESS_CODES_ERROR = "DELETE_ALL_ACCESS_CODES_ERROR";

export const OPEN_ACCESS_MODAL = "OPEN_ACCESS_MODAL";
export const CLOSE_ACCESS_MODAL = "CLOSE_ACCESS_MODAL";
export const EDIT_ACCESS_CODE = "EDIT_ACCESS_CODE";

const DUPLICATE_ACCESS_CODE_REQUEST = "DUPLICATE_ACCESS_CODE_REQUEST";
const DUPLICATE_ACCESS_CODE_SUCCESS = "DUPLICATE_ACCESS_CODE_SUCCESS";
const DUPLICATE_ACCESS_CODE_ERROR = "DUPLICATE_ACCESS_CODE_ERROR";

export const GET_EVENT_LEVEL_ACCESS_CODE_CSV_REQUEST = "GET_EVENT_LEVEL_ACCESS_CODE_CSV_REQUEST";
export const GET_EVENT_LEVEL_ACCESS_CODE_CSV_SUCCESS = "GET_EVENT_LEVEL_ACCESS_CODE_CSV_SUCCESS";
export const GET_EVENT_LEVEL_ACCESS_CODE_CSV_ERROR = "GET_EVENT_LEVEL_ACCESS_CODE_CSV_ERROR";

export const openAccessModal = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_ACCESS_MODAL });
	};
};

export const closeAccessModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_ACCESS_MODAL });
	};
};

export const editAccessCode = (accessCode) => {
	return (dispatch) => {
		dispatch({ type: EDIT_ACCESS_CODE, accessCode });
	};
};

export const getAccessCodes = (eventId, page) => {
	return (dispatch, getState) => {
		const state = getState();
		const { query, sortOrder } = state.search;
		dispatch({ type: GET_ACCESS_CODES_REQUEST, page });

		AccessCodesService.find(eventId, page, query, sortOrder)
			.then((result) => {
				dispatch({
					type: GET_ACCESS_CODES_SUCCESS,
					accessCodes: result.accessCodes,
					count: result.count
				});
			})
			.catch((err) => {
				dispatch({ type: GET_ACCESS_CODES_ERROR, error: err });
			});
	};
};

export const saveAccessCodes = (eventId, accessCode) => {
	return (dispatch) => {
		dispatch({ type: SAVE_ACCESS_CODES_REQUEST });
		AccessCodesService.save(eventId, accessCode)
			.then((accessCode) => {
				dispatch({ type: SAVE_ACCESS_CODES_SUCCESS, accessCode });
				let message = "Your access code have been saved";
				if (Array.isArray(accessCode)) {
					message = `${accessCode.length} access codes have been added`;
				}
				notification.success({ message: "Success", description: message });
				dispatch(closeAccessModal());
			})
			.catch((err) => {
				dispatch({ type: SAVE_ACCESS_CODES_ERROR, error: err });
				const message =
					err.response && err.response.status === 409 ? "Access code already exists" : "Failed to save access code";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const duplicateAccessCode = (eventId, accessCode) => {
	return (dispatch, getState) => {
		dispatch({ type: DUPLICATE_ACCESS_CODE_REQUEST });
		delete accessCode._id;
		accessCode.type = "single";

		AccessCodesService.duplicate(eventId, accessCode)
			.then((data) => {
				const state = getState();
				dispatch({ type: DUPLICATE_ACCESS_CODE_SUCCESS });
				dispatch(getAccessCodes(eventId, state.accessCodes.page));
				let message = "Your access code have been duplicated";
				if (data.count) {
					message = `${data.count} access codes have been duplicated`;
				}
				notification.success({ message: "Success", description: message });
			})
			.catch((err) => {
				dispatch({ type: DUPLICATE_ACCESS_CODE_ERROR, error: err });
				const message =
					err.response && err.response.status === 409 ? "Access code already exists" : "Failed to save access code";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const deleteAccessCodes = (eventId, accessCode) => {
	return (dispatch) => {
		dispatch({ type: DELETE_ACCESS_CODES_REQUEST });
		AccessCodesService.delete(eventId, accessCode)
			.then((accessCode) => {
				dispatch({ type: DELETE_ACCESS_CODES_SUCCESS, accessCode });
				notification.success({
					message: "Success",
					description: "Your access code have been deleted"
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_ACCESS_CODES_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to delete access code`
				});
			});
	};
};

export const deleteAllAccessCodes = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: DELETE_ALL_ACCESS_CODES_REQUEST });
		AccessCodesService.deleteAll(eventId)
			.then(() => {
				dispatch({ type: DELETE_ALL_ACCESS_CODES_SUCCESS });
				const state = getState();
				dispatch(getAccessCodes(eventId, state.accessCodes.page));
				notification.success({
					message: "Success",
					description: "Your access codes have been deleted"
				});
			})
			.catch((err) => {
				dispatch({ type: DELETE_ALL_ACCESS_CODES_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to delete all access code`
				});
			});
	};
};

export const getAccessCodesCsv = (eventId) => {
	return (dispatch, getState) => {
		dispatch({ type: GET_EVENT_LEVEL_ACCESS_CODE_CSV_REQUEST });
		const state = getState();
		const { query, sortOrder } = state.search;
		const filename = `access-code-list.csv`;
		AccessCodesService.getAccessCodesCsv(eventId, query, sortOrder)
			.then((data) => {
				dispatch({ type: GET_EVENT_LEVEL_ACCESS_CODE_CSV_SUCCESS });
				FileDownload.saveAs(data, filename);
			})
			.catch((err) => {
				dispatch({ type: GET_EVENT_LEVEL_ACCESS_CODE_CSV_ERROR, error: err });
			});
	};
};
