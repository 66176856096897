import React, { ReactNode, cloneElement } from "react";

interface IFormBarProps {
	children: ReactNode;
	height?: number;
	sticky?: boolean;
}

const FormBar = ({ children, height = 60, sticky = true }: IFormBarProps) => {
	const modifiedChildren = React.Children.map(children, (el) => {
		if (
			React.isValidElement(el) &&
			((el.props?.type === "primary" && el.props?.prefixCls === "ant-btn") || el.props?.variant === "primary")
		) {
			return cloneElement(el, {
				style: {
					float: "right"
				}
			} as any);
		}
		return el;
	});

	return (
		<div
			style={{
				background: "#fff",
				borderRadius: "4px",
				bottom: 0,
				height,
				left: 0,
				padding: "12px 24px",
				position: sticky ? "sticky" : "initial",
				width: "100%",
				zIndex: 100
			}}
		>
			{modifiedChildren}
		</div>
	);
};
export default FormBar;
