import { css } from "@emotion/css";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import iconTick from "@/assets/icon-tick.svg";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2, H3, Label } from "@/components/text/Text";
import { closeOnlineModal } from "@/state/actions/eventActions";
import { firstOfType, max } from "@/utils/CssUtils.js";

const wrapper = css({
	textAlign: "center",
	padding: "24px 0",
	label: {
		fontWeight: 400,
		marginBottom: 16
	}
});

const header = css({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	h3: {
		color: "#157e77 !important"
	}
});

const svgClass = css({
	marginRight: 6,
	"polygon:last-child": {
		fill: "#157e77"
	}
});

const buttonWrapper = css({
	maxWidth: 152,
	margin: "24px auto 0 auto",
	display: "flex",
	flexDirection: "column",
	button: {
		padding: 0,
		width: "100%",
		[firstOfType()]: {
			marginBottom: 12
		}
	}
});

const videoIframe = css({
	width: 500,
	height: 283,
	[max(600)]: {
		width: 268,
		height: 151
	}
});

class OnlineEventModal extends Component {
	close = () => {
		const { closeOnlineModal } = this.props;
		closeOnlineModal();
	};

	render() {
		const { currentEvent } = this.props;

		return (
			<Modal
				open={currentEvent.onlineModalOpen}
				title=""
				onCancel={this.close}
				destroyOnClose
				footer={null}
				width={600}
			>
				<div className={wrapper}>
					<div className={header}>
						<SVG className={svgClass} src={iconTick} uniquifyIDs={false} cacheRequests aria-hidden="true" />
						<H3>Your event has been saved!</H3>
					</div>
					<H2>Would you like to create a Virtual Event Hub? </H2>
					<Label>
						Watch video tutorial and find out how to manage your online event content using Virtual Event Hub{" "}
					</Label>
					<iframe
						title="Online events video"
						className={videoIframe}
						src="https://www.youtube.com/embed/Mk8e3804m1U"
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					/>
					<div className={buttonWrapper}>
						<Link to={`/console/my-events/${currentEvent.event._id}/virtual-event-hub`} onClick={this.close}>
							<LegacyButton key="button" type="secondary" ariaLabel="Create Virtual Hub">
								Create Virtual Hub
							</LegacyButton>
						</Link>
						<LegacyButton key="button" ariaLabel="Create later" onClick={this.close}>
							Create later
						</LegacyButton>
					</div>
				</div>
			</Modal>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent
	}),
	{ closeOnlineModal }
)(OnlineEventModal);
