const StandingArea = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<g fill="none" fillRule="evenodd">
				<g fill="#67717E">
					<path
						d="M6.07 10.593c.329.012 1.041 1.777 1.041 2.753 0 .975-.908 1.765-2.028 1.765-.459 0-1.184-.256-1.644-.83-.66-.827-1.48-2.373-.986-2.701.548-.363 3.288-.998 3.617-.987zM2.85 0c1.965 0 3.199 2.63 3.452 5.245.196 2.025.196 3.038-.329 3.894-.448.73-3.555 1.641-4 1.402C.67 9.842 0 7.315 0 5.245 0 2.467.884 0 2.85 0zM9.93 10.593c-.329.012-1.041 1.777-1.041 2.753 0 .975.908 1.765 2.028 1.765.459 0 1.184-.256 1.644-.83.66-.827 1.48-2.373.986-2.701-.548-.363-3.288-.998-3.617-.987zM13.15 0c-1.965 0-3.199 2.63-3.452 5.245-.196 2.025-.196 3.038.329 3.894.448.73 3.555 1.641 4 1.402C15.33 9.842 16 7.315 16 5.245 16 2.467 15.116 0 13.15 0z"
						transform="translate(2 2)"
					/>
				</g>
				<path d="M0 0H20V20H0z" />
			</g>
		</svg>
	);
};

export default StandingArea;
