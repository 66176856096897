import { downloadPaymentReceipt } from "@/state/actions/newPayoutActions";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import IconButton from "@/components/IconButton";
import { connect } from "react-redux";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

class Payout extends Component {
	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;
		const { load, loadPage, downloadCsv, downloadPaymentReceipt } = this.props;

		const columns = [
			{
				title: "Event ID",
				dataIndex: "eventReference",
				key: "eventReference"
			},
			{
				title: "Event Name",
				dataIndex: "eventName",
				key: "eventName",
				render: (eventName, record) => {
					return <Link to={`/console/my-events/${record.eventId}`}>{eventName}</Link>;
				}
			},
			{
				title: "Event Date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "Payout reference",
				dataIndex: "payoutReference",
				key: "payoutReference",
				render: (ref, payment) => {
					const refStr = ref || String(payment.paymentId).substring(6, String(payment.paymentId).length);
					return <Link to={`/console/my-events/${payment.eventId}/payments/payouts`}>{refStr}</Link>;
				}
			},
			{
				title: "Event Time",
				dataIndex: "time",
				key: "time"
			},
			{
				title: "Payout Amount",
				dataIndex: "payoutAmount",
				key: "payoutAmount"
			},
			{
				title: "Date Paid",
				dataIndex: "datePaid",
				key: "datePaid"
			},
			{
				title: "Paid to account",
				dataIndex: "bankAccount",
				width: 150,
				render: (bankAccount) => {
					return bankAccount ? bankAccount.anonymisedAccount.join("-") : "-";
				}
			},
			{
				title: "Actions",
				render: (record) => (
					<IconButton
						icon="export"
						tooltip="Download receipt"
						showTooltip
						ariaLabel="Download"
						onClick={() =>
							downloadPaymentReceipt(record.eventId, {
								_id: record.paymentId,
								payoutReference: record.payoutReference,
								eventDateId: record.eventDateId
							})
						}
					/>
				)
			}
		];

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						showSearchField={false}
						firstDateName="payoutDates"
						firstDateLabel="Payout date range"
						secondDateName="eventDates"
						secondDateLabel="Event date range"
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							{results.length > 0 && (
								<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
									{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
								</LegacyButton>
							)}
						</div>
						<Table
							columns={columns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="paymentId"
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export default connect(() => {}, { downloadPaymentReceipt })(withReportProvider(Payout, "Payouts", "payout"));
