import { Modal } from "@/ui/antd";
import { Component } from "react";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import PaymentRemoveForm from "./PaymentRemoveForm";

export default class DeleteModal extends Component {
	render() {
		const { visible, handleCancel, deleteFunction, payment, userId } = this.props;
		return (
			<Modal
				open={visible}
				title="Remove Payment"
				onCancel={handleCancel}
				destroyOnClose
				footer={[
					<LegacyButton
						key="payment"
						htmlType="submit"
						form="removePaymentForm"
						value="Submit"
						dataCy="deletePayout"
						type="primary"
						style={{ float: "right" }}
						ariaLabel="Remove Payment"
					>
						Remove payment
					</LegacyButton>
				]}
			>
				<PaymentRemoveForm
					deleteFunction={deleteFunction}
					closed={!visible}
					payment={payment}
					userId={userId}
					handleCancel={handleCancel}
				/>
			</Modal>
		);
	}
}
