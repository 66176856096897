import { ColourField, LegacyInputField, SelectField } from "@/components/Fields";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { PreviewCols } from "@/events/event/widgets/PreviewCols";
import { findEvents } from "@/state/actions/eventActions";
import { findOrganisers } from "@/state/actions/organiserActions";
import { getLocalisedText } from "@/utils/Format";
import { css } from "@emotion/css";
import colorContrast from "color-contrast";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";

const Controls = ({ organisers, handleSearch, handleSelect }) => {
	const colourText = getLocalisedText("colour");
	organisers = organisers.map((organiser) => {
		return { label: organiser.name, value: organiser._id };
	});
	organisers.unshift({ label: "All events", value: "all" });
	const options = organisers;
	return (
		<div>
			<P>
				Event listing widget will show on your website a list of live events made by this account or events made by one
				of your event organisers.
			</P>

			<Field
				label="Select which host's events you would like to list"
				name="selectOrganiser"
				component={SelectField}
				onSearch={handleSearch}
				onSelect={handleSelect}
				showSearch
				options={options}
			/>
			<Field label="Button Text" name="buttonText" component={LegacyInputField} maxLength={13} />
			<Field label={`Button ${colourText}`} name="buttonColour" component={ColourField} />
		</div>
	);
};

const Preview = ({ fullUrl }) => {
	const wrap = css`
		max-width: 320px;
		width: 100%;
		height: 375px;
		display: inline;
		padding: 0;
		overflow: hidden;
		@media (max-width: 640px) {
			overflow: scroll;
		}
	`;

	const center = css({
		width: "auto",
		margin: "0 auto"
	});

	const iframe = css`
		width: 710px;
		height: 750px;
		text-align: center;
		border: 0;
		margin-left: 8%;
		-ms-transform: scale(0.55);
		-moz-transform: scale(0.55);
		-o-transform: scale(0.55);
		-webkit-transform: scale(0.55);
		transform: scale(0.55);
		-ms-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		@media (max-width: 1078px) {
			margin-left: 0;
		}
	`;

	return (
		<div className={wrap}>
			<div className={center}>
				<iframe title="Events list" src={fullUrl} frameBorder="0" className={iframe} />
			</div>
		</div>
	);
};

/**
 * Display event listing on widget
 *
 * @class EventListing
 * @extends {Component}
 */
class EventListing extends Component {
	constructor() {
		super();
		this.handleSearch = this.handleSearch.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}

	componentDidMount() {
		this.load();
	}
	load() {
		this.props.findOrganisers("");
		this.props.findEvents(1);
	}
	handleSearch(query) {
		this.props.findOrganisers(query);
	}
	handleSelect() {
		this.props.findOrganisers("");
	}
	render() {
		const { buttonText, selectOrganiser, auth } = this.props;
		const buttonColour = this.props.buttonColour;
		const { organisers } = this.props.organisers;
		const userId = auth.user?.id;
		let { events } = this.props.events;
		if (events && events.length > 0 && selectOrganiser) {
			events = events.filter((event) => event.organiserId === selectOrganiser);
		}
		const colorCode = ["#000000", "#FFFFFF"];
		let textColour;
		colorCode.forEach((color) => {
			if (colorContrast(buttonColour, color) >= 4.5) {
				textColour = color;
			}
		});
		const fullUrl = `${getConfig(
			"TICKETING_URL"
		)}/event-listings?w=true&u=${userId}&o=${selectOrganiser}&bc=${encodeURIComponent(
			buttonColour
		)}&bt=${encodeURIComponent(buttonText)}&tc=${encodeURIComponent(textColour)}`;
		const code = `<iframe id="iframe-container" src="${fullUrl}" width="100%" height="600px" frameborder="0"></iframe>
                    <script>
                        window.addEventListener('message', function (e) {
                            if (!e.origin.endsWith(".humanitix.com")){
                                return;
                            }   
                            var messageData = e.data;
                            var iframeEl = document.getElementById('iframe-container');
                            if (iframeEl && messageData && !isNaN(messageData.scrollHeight) && messageData.location === iframeEl.src) {
                                iframeEl.style.height = messageData.scrollHeight + 20 + 'px'; }
                        }, false);
                    </script>`;

		return (
			<div>
				<PreviewCols
					controls={
						<Controls organisers={organisers} handleSearch={this.handleSearch} handleSelect={this.handleSelect} />
					}
					preview={<Preview fullUrl={fullUrl} />}
					code={code}
				/>
			</div>
		);
	}
}

const formName = "widgetEventListing";
const selector = formValueSelector(formName);
// Decorate with redux-form
EventListing = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true
})(EventListing);

export default connect(
	(state) => {
		return {
			initialValues: {
				selectOrganiser: "all",
				buttonColour: "#353337",
				buttonText: "Get Tickets"
			},
			organisers: state.organisers,
			events: state.events,
			auth: state.auth,
			selectOrganiser: selector(state, "selectOrganiser"),
			buttonText: selector(state, "buttonText"),
			buttonColour: selector(state, "buttonColour")
		};
	},
	{ findOrganisers, findEvents }
)(EventListing);
