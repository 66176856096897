import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { saveUserOnBoardingData } from "../../state/actions/userActions";
import OnboardingQuestions from "./components/OnboardingQuestions";

const Onboarding = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector((state) => state.auth?.user);
	const userLocation = useAppSelector((state) => state.auth.user?.location);

	const currStep = params?.step ? parseInt(params.step) : 1;

	useEffect(() => {
		if (currStep <= 0 || currStep > 6) {
			handleNavigation(`/onboarding-organiser/1`);
		}
	}, []);

	const handleNext = () => {
		handleNavigation(`/onboarding-organiser/${currStep + 1}`);
	};

	const handleBack = () => {
		handleNavigation(`/onboarding-organiser/${currStep - 1}`);
	};

	const handleNavigation = (to: string) => {
		if (to) {
			navigate(`${to}?newuser=true`);
		}
	};

	const save = (values: any) => {
		if (Object.keys(values).length) {
			const { id: userId } = user;
			dispatch(saveUserOnBoardingData(userId, values, userLocation));
		}
		handleNavigation("/console/dashboard");
	};

	const { organiserOnboardingInfo } = user ?? {};

	// already answered
	if (organiserOnboardingInfo) {
		handleNavigation("/console/dashboard");
	}

	return (
		<OnboardingQuestions user={user} currStep={currStep} handleBack={handleBack} handleNext={handleNext} save={save} />
	);
};

export default Onboarding;
