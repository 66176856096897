import { ReactNode } from "react";

interface IPageActionProps {
	children: ReactNode;
}

const PageActions = ({ children }: IPageActionProps) => {
	return (
		<div
			style={{
				float: "right",
				marginLeft: 12
			}}
		>
			{children}
		</div>
	);
};

export default PageActions;
