import stripeLogo from "@/assets/logo-stripe.svg";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getConfig } from "@/config";
import PaymentInfo from "@/events/event/payments/components/gateways/PaymentInfo";
import styled from "@emotion/styled";
import { FC } from "react";

const StripeInfo = styled.div`
	text-align: center;
	margin-top: 10px;
	p {
		max-width: 100%;
	}
`;

const getStripeConnectLocation = (location: string) => {
	if (location === "CA" || location === "US" || location === "MX") {
		return "US";
	}
	return "AU";
};

type StripeProps = {
	allowed: boolean;
	gatewaysLocked: boolean;
	id: string;
	isAdmin: boolean;
	location: string;
	orgPaymentOptions: any;
	paymentProcessed: boolean;
	selected: string;
	type: string;
};

const Stripe: FC<StripeProps> = ({
	allowed,
	gatewaysLocked,
	id,
	isAdmin,
	location,
	orgPaymentOptions,
	paymentProcessed,
	selected,
	type
}) => {
	if (!allowed) {
		return null;
	}
	const connectStripe = () => {
		const connectionLocation = getStripeConnectLocation(location);
		const clientId = getConfig("STRIPE_CLIENT_ID", connectionLocation);
		const redirectUri = `${window.location.origin}/connect-stripe`;
		window.open(
			`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&state=${type}-${id}-${location}&redirect_uri=${redirectUri}`,
			"_self"
		);
	};
	const stripeUserId =
		orgPaymentOptions && orgPaymentOptions.stripeInfo && orgPaymentOptions.stripeInfo.stripeUserId
			? orgPaymentOptions.stripeInfo.stripeUserId
			: null;

	return (
		<PaymentInfo
			description={
				<div>
					Stripe is an international professional merchant account provider. Connect your Stripe account to begin
					accepting payments directly into your account.
					<br />
					<br />
					<a
						aria-label="Stripe pricing page"
						href="https://stripe.com/pricing"
						rel="noopener noreferrer"
						target="_blank"
					>
						Stripe’s payment processing fees apply
					</a>
				</div>
			}
			id="stripe"
			image={stripeLogo}
			name="Stripe"
			selected={selected}
		>
			{selected === "stripe" && stripeUserId ? (
				<StripeInfo>
					{orgPaymentOptions?.stripeInfo?.displayName && (
						<p>Connected to {orgPaymentOptions?.stripeInfo?.displayName}</p>
					)}
					{isAdmin && orgPaymentOptions?.stripeInfo?.stripeUserId && (
						<p>Account Id {orgPaymentOptions?.stripeInfo?.stripeUserId}</p>
					)}
					<LegacyButton
						ariaLabel="Connect a different account"
						onClick={connectStripe}
						style={{ margin: "auto", maxWidth: 240, width: "100%" }}
						type="action"
					>
						Connect a different account
					</LegacyButton>
				</StripeInfo>
			) : (
				<LegacyButton
					ariaLabel="Connect Stripe"
					disabled={paymentProcessed || gatewaysLocked}
					onClick={connectStripe}
					style={{ margin: "auto", marginTop: 12, maxWidth: 240, width: "100%" }}
					type="primary"
				>
					Connect Stripe
				</LegacyButton>
			)}
		</PaymentInfo>
	);
};
export default Stripe;
