import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import React, { ReactNode } from "react";
import SVG from "react-inlinesvg";
import iconReference from "../services/IconReference";
import { LoadingSpinner } from "./AppBlocks";

/*
  Please note if youre updating svgs etc you may need to add class names
  to the elements that you wish to change colors. Due to the way that the 
  anchies svgs are exported the paths,gs,rects etc can be a bit weird due 
  to the fill rules.
*/

const getSize = (small?: boolean, medium?: boolean) => {
	if (small && medium) {
		throw new Error("You cant set multiple sizes");
	}
	if (small) {
		return "20px";
	}
	if (medium) {
		return "24px";
	}
	return "36px";
};

interface IIconButtonProps {
	icon: string;
	ariaLabel: string;
	disabled?: boolean;
	style?: any;
	showTooltip?: boolean;
	tooltip?: string | ReactNode;
	onClick?: (e: React.MouseEvent) => void;
	type?: "default" | "background";
	dataCy?: string;
	expandable?: boolean;
	expanded?: boolean;
	active?: boolean;
	cursor?: string;
	small?: boolean;
	medium?: boolean;
	loading?: boolean;
	tableIcon?: boolean;
	buttonText?: string | boolean;
}

const IconButton = ({
	icon,
	disabled,
	style = {},
	showTooltip = false,
	tooltip = "default tooltip",
	onClick = () => null,
	type = "default",
	dataCy = "default data CY",
	ariaLabel,
	expandable = false,
	expanded = false,
	active = false,
	cursor = "pointer",
	small,
	medium,
	loading,
	tableIcon,
	buttonText = false
}: IIconButtonProps) => {
	const iconSVG = iconReference[icon];
	let pathFill = "#67717E";
	let background = "transparent";
	let pathHoverFill = "#FFA680";
	let backgroundHoverFill = "transparent";
	const onClickHandler = loading || disabled ? undefined : onClick;

	switch (type) {
		case "default":
			if (active || expanded) {
				pathFill = "#353337";
			}
			break;
		case "background":
			background = "#fafafa";
			pathHoverFill = "#fff";
			backgroundHoverFill = "#FFA680";
			if (active || expanded) {
				pathFill = "#fff";
				background = "#353337";
			}
			break;
		default:
			break;
	}

	if (disabled) {
		pathFill = "#ebebeb";
		pathHoverFill = pathFill;
		background = "transparent";
	}

	const pathHoverFillObj = {
		fill: pathHoverFill
	};

	const svgClass = {
		".shapes": {
			fill: pathFill
		},
		"path[fill]": {
			fill: pathFill
		},
		"path[stroke]": {
			stroke: pathFill
		},
		rect: {
			fill: "transparent"
		},
		"rect[stroke]": {
			stroke: pathFill
		},
		"&:hover": {
			"path[fill]": pathHoverFillObj,
			"path[stroke]": pathHoverFillObj,
			"use[fill]": pathHoverFillObj,
			".shapes": pathHoverFillObj
		}
	};
	const buttonStyling = css`
		border: none;
		position: relative;
		background: ${background};
		width: ${getSize(small, medium)};
		height: ${getSize(small, medium)};
		margin-top: 0;
		margin-right: ${tableIcon ? "10px" : "0px"};
		padding: 0;
		padding-top: ${small || medium ? "0px" : "6px"};
		cursor: ${disabled ? "not-allowed" : cursor};
		border-radius: 4px;
		&:focus {
			outline: 0;
			border: 1px solid #ffa680;
		}
		&:after {
			display: none;
			content: "";
		}
		&:hover {
			background: ${backgroundHoverFill};
			p {
				color: #ffa680;
			}
		}
		p {
			margin-left: 8px;
			margin-top: 1px;
		}
		${svgClass}
		${style}
	`;

	if (showTooltip) {
		return (
			<Tooltip title={tooltip}>
				<button
					className={buttonStyling}
					style={
						disabled
							? {
									background,
									border: "none"
							  }
							: {}
					}
					type="button"
					disabled={disabled}
					onClick={onClickHandler}
					data-cy={dataCy}
					aria-label={ariaLabel}
					aria-expanded={expandable ? expanded : undefined}
				>
					{loading ? (
						<LoadingSpinner size={24} color="#353337" />
					) : (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div>
								<SVG src={String(iconSVG)} uniquifyIDs={false} cacheRequests aria-hidden="true" />
							</div>
							{buttonText ? <p>{buttonText}</p> : null}
						</div>
					)}
				</button>
			</Tooltip>
		);
	}
	return (
		<button
			type="button"
			className={buttonStyling}
			disabled={disabled}
			onClick={onClickHandler}
			data-cy={dataCy}
			aria-label={ariaLabel}
			aria-expanded={expandable ? expanded : undefined}
		>
			{loading ? (
				<LoadingSpinner size={24} color="#353337" />
			) : (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div>
						<SVG src={String(iconSVG)} uniquifyIDs={false} cacheRequests aria-hidden="true" />
					</div>
					{buttonText ? <p>{buttonText}</p> : null}
				</div>
			)}
		</button>
	);
};

export default IconButton;
