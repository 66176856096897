import { FullPageWrap } from "@/components/AppBlocks";
import { H2 } from "@/components/text/Text";

const NotFound = () => (
	<FullPageWrap>
		<H2 style={{ textAlign: "center" }}>404 Page Not Found</H2>
	</FullPageWrap>
);

export default NotFound;
