import { round10 } from "round10";
import { add, div, mul, sub } from "sinful-math";
/**
 * HAVE TO ACCOUNT FOR THE SCENARIO WHEN WE FIND MAXIMUM TICKET PRICE
 */

const getTicketPriceBounding = (fees) => {
	let minMaxValue = 0;
	const result = {
		min: 0,
		max: 0
	};
	if (fees) {
		const fixedRebate = fees.rebates && fees.rebates.fixed ? fees.rebates.fixed : 0;
		const percentageRebate = fees.rebates && fees.rebates.percent ? div(fees.rebates.percent, 100) : 0;
		const donation = fees.donation || 0;
		const bookingFeeFixed = fees.fixed || 0;
		const bookingFeePercent = div(fees.percent || 0, 100);
		if (donation) {
			let donationAmount = 0;
			let denominator, numerator, percentValuesLess, fixedValuesLess;
			if (donation.type === "fixed") {
				donationAmount = donation.amount || 0;
				percentValuesLess = bookingFeePercent < add(percentageRebate, 0.01);
				fixedValuesLess = bookingFeeFixed < add(donationAmount, fixedRebate);
				numerator = sub(bookingFeeFixed, donationAmount, fixedRebate);
				denominator = sub(add(percentageRebate, 0.01), bookingFeePercent);
			} else {
				donationAmount = div(donation.amount || 0, 100);
				percentValuesLess = bookingFeePercent < add(percentageRebate, 0.01, mul(donationAmount, bookingFeePercent));
				fixedValuesLess = bookingFeeFixed < add(donationAmount, fixedRebate, mul(donationAmount, bookingFeeFixed));
				numerator = sub(bookingFeeFixed, fixedRebate, mul(donationAmount, bookingFeeFixed));
				denominator = add(sub(percentageRebate, bookingFeePercent), 0.01, mul(donationAmount, bookingFeePercent));
			}
			if (numerator === 0) {
				if (percentValuesLess) {
					result.max = result.min = -1;
				} else {
					result.min = 0;
					result.max = Infinity;
				}
			} else if (denominator === 0) {
				if (fixedValuesLess) {
					result.max = result.min = -1;
				} else {
					result.min = 0;
					result.max = Infinity;
				}
			} else {
				minMaxValue = div(numerator, denominator);
				const profitPlusOne = calculateProfit(fees, minMaxValue + 1);
				const profitMinusOne = calculateProfit(fees, minMaxValue - 1);
				if (profitPlusOne > 0 && profitMinusOne < 0) {
					result.min = minMaxValue < 0 ? 0 : minMaxValue;
					result.max = Infinity;
				} else if (profitMinusOne > 0 && profitPlusOne < 0 && minMaxValue > 0) {
					result.min = 0;
					result.max = minMaxValue;
				} else {
					result.max = result.min = -1;
				}
			}
			if (fees.bookingFeeCap && result.max !== -1 && result.max !== -1) {
				let cappedNumerator = 0;
				let cappedDenominator = 0;
				const bookingFeeCap = fees.bookingFeeCap;
				if (donation.type === "fixed") {
					cappedNumerator = sub(bookingFeeCap, donation.amount || 0, fixedRebate);
					cappedDenominator = add(percentageRebate, 0.01);
				} else {
					const donationAmount = div(donation.amount || 0, 100);
					cappedNumerator = sub(bookingFeeCap, fixedRebate, mul(donationAmount, bookingFeeCap));
					cappedDenominator = add(percentageRebate, 0.01);
				}
				const cappedMinMaxValue = div(cappedNumerator, cappedDenominator);
				if (cappedMinMaxValue > 0) {
					if (cappedMinMaxValue < result.max) {
						result.max = cappedMinMaxValue;
					}
				} else {
					result.min = result.max = -1;
				}
			}
		}
		result.min = round10(result.min, -2);
		result.max = result.max === Infinity ? result.max : round10(result.max, -2);
	}

	return result;
};

const calculateProfit = (fees, ticketPrice) => {
	const fixedRebate = fees.rebates && fees.rebates.fixed ? fees.rebates.fixed : 0;
	const percentageRebate = fees.rebates && fees.rebates.percent ? div(fees.rebates.percent, 100) : 0;
	const donation = fees.donation;
	const bookingFeeFixed = fees.fixed || 0;
	const bookingFeePercent = div(fees.percent || 0, 100);
	const grossProfit = add(bookingFeeFixed, mul(bookingFeePercent * ticketPrice));
	const rebateTotal = add(fixedRebate, mul(percentageRebate, ticketPrice));
	const constantTransactionFee = mul(0.01, ticketPrice);
	let donationAmount = 0;
	if (donation.type === "fixed") {
		donationAmount = donation.amount || 0;
	} else {
		donationAmount = mul(div(donation.amount || 0, 100), grossProfit);
	}
	const profit = sub(grossProfit, rebateTotal, donationAmount, constantTransactionFee);
	return profit;
};
export default getTicketPriceBounding;
