import {
	CHECK_FACEBOOK_CONTENTION_ERROR,
	CHECK_FACEBOOK_CONTENTION_REQUEST,
	CHECK_FACEBOOK_CONTENTION_SUCCESS,
	CLEAR_POST_FACEBOOK_ERROR,
	CONNECT_FACEBOOK_ERROR,
	CONNECT_FACEBOOK_REQUEST,
	CONNECT_FACEBOOK_SUCCESS,
	DELETE_FACEBOOK_ERROR,
	DELETE_FACEBOOK_REQUEST,
	DELETE_FACEBOOK_SUCCESS,
	DISCONNECT_FACEBOOK_ERROR,
	DISCONNECT_FACEBOOK_REQUEST,
	DISCONNECT_FACEBOOK_SUCCESS,
	FACEBOOK_ACCOUNT_ERROR,
	FACEBOOK_ACCOUNT_REQUEST,
	FACEBOOK_ACCOUNT_RESET,
	FACEBOOK_ACCOUNT_SUCCESS,
	LOAD_FACEBOOK_EVENT_ERROR,
	LOAD_FACEBOOK_EVENT_REQUEST,
	LOAD_FACEBOOK_EVENT_SUCCESS,
	LOAD_FACEBOOK_SDK_ERROR,
	LOAD_FACEBOOK_SDK_REQUEST,
	LOAD_FACEBOOK_SDK_SUCCESS,
	POST_FACEBOOK_ERROR,
	POST_FACEBOOK_REQUEST,
	POST_FACEBOOK_SUCCESS
} from "../actions/facebookActions";

import { notification } from "@/ui/antd";

const initialState = {
	sdk: {
		loaded: false,
		loading: false,
		error: false
	},
	connectionCheck: {
		loaded: false,
		loading: false,
		error: false,
		pages: null
	},
	connect: {
		connected: false,
		loading: false,
		error: false
	},
	disconnect: {
		connected: false,
		loading: false,
		error: false
	},
	account: {
		loaded: false,
		loading: false,
		error: false,
		pages: null
	},
	post: {
		loaded: false,
		loading: false,
		error: false
	},
	delete: {
		connected: false,
		loading: false,
		error: false
	},
	event: {
		data: null,
		loaded: false,
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case FACEBOOK_ACCOUNT_RESET:
			return {
				...state,
				account: {
					loaded: false,
					loading: false,
					error: false,
					pages: null
				},
				event: {
					data: null,
					loaded: false,
					loading: false,
					error: false
				}
			};

		case LOAD_FACEBOOK_EVENT_REQUEST:
			return {
				...state,
				event: {
					loaded: false,
					loading: true,
					error: false
				}
			};
		case LOAD_FACEBOOK_EVENT_SUCCESS:
			return {
				...state,
				event: {
					data: action.fbEvent,
					loaded: true,
					loading: false,
					error: false
				}
			};
		case LOAD_FACEBOOK_EVENT_ERROR:
			return {
				...state,
				event: {
					data: null,
					loaded: false,
					loading: false,
					error: action.error
				}
			};

		case LOAD_FACEBOOK_SDK_REQUEST:
			return {
				...state,
				sdk: {
					loaded: false,
					loading: true,
					error: false
				}
			};
		case LOAD_FACEBOOK_SDK_SUCCESS:
			return {
				...state,
				sdk: {
					loaded: true,
					loading: false,
					error: false
				}
			};
		case LOAD_FACEBOOK_SDK_ERROR:
			return {
				...state,
				sdk: {
					loaded: false,
					loading: false,
					error: action.error
				}
			};
		case CHECK_FACEBOOK_CONTENTION_REQUEST:
			return {
				...state,
				connectionCheck: {
					loaded: false,
					loading: true,
					error: false
				}
			};
		case CHECK_FACEBOOK_CONTENTION_SUCCESS:
			return {
				...state,
				connectionCheck: {
					loaded: true,
					loading: false,
					error: false
				}
			};
		case CHECK_FACEBOOK_CONTENTION_ERROR:
			return {
				...state,
				connectionCheck: {
					loaded: false,
					loading: false,
					error: action.error
				}
			};
		case CONNECT_FACEBOOK_REQUEST:
			return {
				...state,
				connect: {
					connected: false,
					loading: true,
					error: false
				}
			};
		case CONNECT_FACEBOOK_SUCCESS:
			return {
				...state,
				connect: {
					connected: true,
					loading: false,
					error: false,
					pages: action.pages
				}
			};
		case CONNECT_FACEBOOK_ERROR:
			notification.error({
				message: "Error",
				description: action.error
			});
			return {
				...state,
				connect: {
					connected: false,
					loading: false,
					error: action.error
				}
			};
		case DISCONNECT_FACEBOOK_REQUEST:
			return {
				...state,
				disconnect: {
					connected: false,
					loading: true,
					error: false
				}
			};
		case DISCONNECT_FACEBOOK_SUCCESS:
			return {
				...state,
				disconnect: {
					connected: true,
					loading: false,
					error: false,
					pages: action.pages
				},
				connect: {
					connected: false,
					loading: false,
					error: false
				}
			};
		case DISCONNECT_FACEBOOK_ERROR:
			return {
				...state,
				disconnect: {
					connected: false,
					loading: false,
					error: true
				}
			};
		case FACEBOOK_ACCOUNT_REQUEST:
			return {
				...state,
				account: {
					loaded: false,
					loading: true,
					error: false,
					pages: null
				}
			};
		case FACEBOOK_ACCOUNT_SUCCESS:
			return {
				...state,
				account: {
					loaded: true,
					loading: false,
					error: false,
					pages: [].concat(action.pages)
				}
			};
		case FACEBOOK_ACCOUNT_ERROR:
			return {
				...state,
				account: {
					loaded: false,
					loading: false,
					error: action.error,
					pages: null
				}
			};
		case POST_FACEBOOK_REQUEST:
			return {
				...state,
				post: {
					loaded: false,
					loading: true,
					error: false
				}
			};
		case POST_FACEBOOK_SUCCESS:
			return {
				...state,
				post: {
					loaded: true,
					loading: false,
					error: false
				}
			};
		case POST_FACEBOOK_ERROR:
			return {
				...state,
				post: {
					loaded: false,
					loading: false,
					error: {
						title: "Error Posting to Facebook",
						message: action.error
					}
				}
			};
		case DELETE_FACEBOOK_REQUEST:
			return {
				...state,
				delete: {
					loaded: false,
					loading: true,
					error: false
				}
			};
		case DELETE_FACEBOOK_SUCCESS:
			return {
				...state,
				delete: {
					loaded: true,
					loading: false,
					error: false
				}
			};
		case DELETE_FACEBOOK_ERROR:
			return {
				...state,
				delete: {
					loaded: false,
					loading: false,
					error: true
				}
			};
		case CLEAR_POST_FACEBOOK_ERROR:
			return {
				...state,
				post: {
					loaded: false,
					loading: false,
					error: false
				}
			};
		default:
			return state;
	}
};
