type ConfettiOptions = {
	decay?: number;
	scalar?: number;
	spread?: number;
	startVelocity?: number;
};

export const useConfetti = () => {
	const fireConfetti = (opts?: ConfettiOptions) => {
		const count = 200,
			defaults = {
				origin: { y: 0.9 },
				zIndex: 10000
			};

		const _fire = (particleRatio: number, opts: ConfettiOptions) => {
			try {
				window.confetti(
					Object.assign({}, defaults, opts, {
						particleCount: Math.floor(count * particleRatio)
					})
				);
			} catch (e: any) {
				console.log("failed to fire confetti: ", e.message);
			}
		};

		_fire(0.25, { spread: 26, startVelocity: 85, ...opts });
		_fire(0.2, { spread: 60, startVelocity: 85, ...opts });
		_fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8, ...opts });
		_fire(0.1, { spread: 120, startVelocity: 45, decay: 0.92, scalar: 1.2, ...opts });
		_fire(0.1, { spread: 120, startVelocity: 85, ...opts });
	};

	return fireConfetti;
};
