import { Alert, Button, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import { getFeesReport, getFeesReportCsv, resetFeesReport } from "@/state/actions/adminReports/financeActions";
import DatesSelector from "../../DatesSelector";
import DayEndsAt from "../DayEndsAt";
import FundLocationFilters from "../FundLocationFilters";

class FeesReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getFeesReport } = this.props;
		getFeesReport(values.startDate, values.endDate, values.dayEndsAt, values.fundsLocation);
	}
	retry() {
		const { resetFeesReport } = this.props;
		resetFeesReport();
	}

	downloadCsv() {
		const { startDate, endDate, getFeesReportCsv, dayEndsAt, fundsLocation } = this.props;
		getFeesReportCsv(startDate, endDate, dayEndsAt, fundsLocation);
	}

	runLoad = () => {
		const { startDate, endDate, dayEndsAt, fundsLocation } = this.props;
		this.load({ startDate, endDate, dayEndsAt, fundsLocation });
	};

	render() {
		const { touch, handleSubmit, feesReport, startDate, endDate, dayEndsAt } = this.props;
		let columns = [
			{
				title: "Date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "Fees",
				dataIndex: "fees",
				key: "fees",
				render: (value) => {
					return <span>${value}</span>;
				}
			},
			{
				title: "Refunds",
				dataIndex: "refunds",
				key: "refunds",
				render: (value) => {
					return <span>${value}</span>;
				}
			},
			{
				title: "Rebates",
				dataIndex: "rebates",
				key: "rebates",
				render: (value) => {
					return <span>${value}</span>;
				}
			},

			{
				title: "Total",
				dataIndex: "total",
				key: "total",
				render: (value) => {
					return <span>${value}</span>;
				}
			},

			{
				title: "Tax free fees",
				dataIndex: "taxFree",
				key: "taxFree",
				render: (value) => {
					return <span>${value}</span>;
				}
			},
			{
				title: "Tax free refunds",
				dataIndex: "taxFreeRefunds",
				key: "taxFreeRefunds",
				render: (value) => {
					return <span>${value}</span>;
				}
			},
			{
				title: "Tax free total",
				dataIndex: "taxFreeTotal",
				key: "taxFreeTotal",
				render: (value) => {
					return <span>${value}</span>;
				}
			}
		];
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Fees Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView loading={feesReport.loading} error={feesReport.error} retryAction={this.retry}>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								<DayEndsAt />
							</div>
							<div style={{ width: 60 }}>
								{feesReport.data ? (
									<IconButton
										icon="export"
										type="background"
										showTooltip
										tooltip="Export"
										onClick={this.downloadCsv}
										ariaLabel="Export"
									/>
								) : null}
							</div>
						</div>
						<FundLocationFilters />
						<Button
							type="primary"
							onClick={this.runLoad}
							disabled={!startDate || !endDate || !dayEndsAt}
							ariaLabel="Load"
						>
							Load
						</Button>
						<Alert
							style={{ margin: "12px 0" }}
							message={
								<table style={{ width: "100%" }}>
									<tr>
										<th>
											<b>Fees</b>
										</th>
										<td>The total gross fees</td>
									</tr>
									<tr>
										<th>
											<b>Refunds</b>
										</th>
										<td>The total amount of refunded fees</td>
									</tr>

									<tr>
										<th>
											<b>Rebates</b>
										</th>
										<td>The net amount of rebates</td>
									</tr>

									<tr>
										<th>
											<b>Total</b>
										</th>
										<td>Fees - ( Refunds + Rebates )</td>
									</tr>

									<tr>
										<th>
											<b>Tax free fees</b>
										</th>
										<td>Tax Free Fee ( No Rebates Include )</td>
									</tr>

									<tr>
										<th>
											<b>Tax free refunds</b>
										</th>
										<td>Tax Free Fee Refunds ( No Rebates Include )</td>
									</tr>
									<tr>
										<th>
											<b>Tax free total</b>
										</th>
										<td>Total tax free fees</td>
									</tr>
								</table>
							}
							type="info"
						/>
					</ReportFilters>
					<ReportContent>
						{feesReport.data ? (
							<div>
								<Table columns={columns} dataSource={feesReport.data} rowKey="_id" pagination={false} />
								<ExportBar buttonText="Export" buttonClick={this.downloadCsv} />
							</div>
						) : null}
					</ReportContent>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "feesReport";
const selector = formValueSelector(formName);
FeesReport = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(FeesReport);

export default connect(
	(state) => ({
		initialValues: {
			fundsLocation: "all",
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		feesReport: state.adminReports.finance.feesReport,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		fundsLocation: selector(state, "fundsLocation"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{ getFeesReport, getFeesReportCsv, resetFeesReport }
)(FeesReport);
