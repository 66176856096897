import AdminReportService from "@/services/AdminReportService";
import FileDownload from "@/utils/FileDownload";
import { getLocationFromState } from "../locationActions";
import { REPORT_DATE_CHANGE } from "./datesActions";

export const EVENT_SALES_LOCATION_REQUEST = "EVENT_SALES_LOCATION_REQUEST";
export const EVENT_SALES_LOCATION_SUCCESS = "EVENT_SALES_LOCATION_SUCCESS";
export const EVENT_SALES_LOCATION_ERROR = "EVENT_SALES_LOCATION_ERROR";
export const EVENT_SALES_LOCATION_RESET = "EVENT_SALES_LOCATION_RESET";

export const TOP_EVENTS_REQUEST = "TOP_EVENTS_REQUEST";
export const TOP_EVENTS_SUCCESS = "TOP_EVENTS_SUCCESS";
export const TOP_EVENTS_ERROR = "TOP_EVENTS_ERROR";
export const TOP_EVENTS_RESET = "TOP_EVENTS_RESET";

export const TOP_FORECASTED_EVENTS_REQUEST = "TOP_FORECASTED_EVENTS_REQUEST";
export const TOP_FORECASTED_EVENTS_SUCCESS = "TOP_FORECASTED_EVENTS_SUCCESS";
export const TOP_FORECASTED_EVENTS_ERROR = "TOP_FORECASTED_EVENTS_ERROR";
export const TOP_FORECASTED_EVENTS_RESET = "TOP_EVENTS_RESET";

export const resetEventSalesByLocation = () => {
	return (dispatch) => {
		dispatch({ type: EVENT_SALES_LOCATION_RESET });
	};
};

export const getEventSalesByLocation = (startDate, endDate) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: EVENT_SALES_LOCATION_REQUEST });
		AdminReportService.getEventSalesByLocation(startDate, endDate, location)
			.then((data) => {
				dispatch({
					type: EVENT_SALES_LOCATION_SUCCESS,
					data
				});
			})
			.catch((err) => {
				dispatch({ type: EVENT_SALES_LOCATION_RESET, error: err });
			});
	};
};

export const getEventSalesByLocationCsv = (startDate, endDate) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `event-sales-by-location-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getEventSalesByLocationCsv(startDate, endDate, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetTopEvents = () => {
	return (dispatch) => {
		dispatch({ type: TOP_EVENTS_RESET });
	};
};

export const getTopEvents = (startDate, endDate, sortOn, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({
			type: REPORT_DATE_CHANGE,
			startDate,
			endDate
		});
		dispatch({ type: TOP_EVENTS_REQUEST, page });
		AdminReportService.getTopEvents(startDate, endDate, sortOn, page, location)
			.then((data) => {
				dispatch({
					type: TOP_EVENTS_SUCCESS,
					results: data.results,
					count: data.count
				});
			})
			.catch((err) => {
				dispatch({ type: TOP_EVENTS_RESET, error: err });
			});
	};
};

export const getTopEventsCsv = (startDate, endDate, sortOn) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `top-events-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getTopEventsCsv(startDate, endDate, sortOn, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};

export const resetTopForecastEvents = () => {
	return (dispatch) => {
		dispatch({ type: TOP_FORECASTED_EVENTS_RESET });
	};
};

export const getTopForecastEvents = (sortOn, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: TOP_FORECASTED_EVENTS_REQUEST, page });
		AdminReportService.getTopForecastEvents(sortOn, page, location)
			.then((data) => {
				dispatch({
					type: TOP_FORECASTED_EVENTS_SUCCESS,
					results: data.results,
					count: data.count
				});
			})
			.catch((err) => {
				dispatch({ type: TOP_FORECASTED_EVENTS_RESET, error: err });
			});
	};
};

export const getTopForecastEventsCsv = (sortOn) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `top-forecasted-events.csv`;
		AdminReportService.getTopForecastEventsCsv(sortOn, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};
