import { Alert, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import { SelectField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { P } from "@/components/text/Text";
import { closeTransferEventModal, transferEvent } from "@/state/actions/eventActions";
import { find } from "@/state/actions/userActions";
import { required } from "@/utils/Validators";

const transferred = [
	"Owner",
	"Organiser",
	"Email alerts",
	"Payment options",
	"Global discount codes",
	"Email campaignes",
	"Merchandises"
];
class TransferEventModal extends Component {
	cancel = () => {
		const { closeTransferEventModal } = this.props;
		closeTransferEventModal();
	};

	onSearch = (value) => {
		debounce(200, this.load)(value);
	};

	load = (value) => {
		const { find } = this.props;
		find({ query: value }, 1);
	};
	accountRequired = required("Please select an account to transfer to");

	transferEvent = (values) => {
		const { currentEvent, transferEvent, users, locations } = this.props;
		const { transferEventState } = currentEvent;
		const user = users.results.users.find((user) => user.id === values.user);
		transferEvent(
			String(transferEventState.eventId),
			locations.eventLevelLocation || locations.overrideLocation,
			user.id,
			user.location
		);
	};

	render() {
		const { handleSubmit, currentUser, users, currentEvent } = this.props;
		const { transferEventState } = currentEvent;
		const { transfer } = currentUser;
		const closeLabel = transfer.result && transfer.result.success ? "Close" : "Cancel";
		const userOptions =
			users.results && users.results.users
				? users.results.users.map((user) => {
						return { label: user.email, value: user.id };
				  })
				: [];
		return (
			<Modal
				open={transferEventState.modalOpen}
				title="Transfer event"
				onCancel={this.cancel}
				destroyOnClose
				footer={
					<ButtonsBar footer>
						<LegacyButton key="cancel" onClick={this.cancel} ariaLabel={closeLabel}>
							{closeLabel}
						</LegacyButton>
						{transfer.result && transfer.result.success ? null : (
							<LegacyButton
								key="submit"
								type="warning"
								loading={transferEventState.loading}
								onClick={handleSubmit(this.transferEvent)}
								ariaLabel="Transfer event"
							>
								Transfer event
							</LegacyButton>
						)}
					</ButtonsBar>
				}
			>
				<LoadErrorView loading={transferEventState.loading} error={transferEventState.error}>
					{transfer.result && transfer.result.success ? (
						<Alert
							message="Success"
							description={`${transfer.result.fromUserEmail} account has been transferred to ${transfer.result.toUserEmail}`}
							type="success"
							showIcon
						/>
					) : (
						<form>
							{currentUser.user ? (
								<P>
									Transfer Event from account:
									<br />
									<b>{currentUser.user.email}</b>
								</P>
							) : null}
							<Field
								name="user"
								label="To user"
								description="The user account to transfer the event to"
								component={SelectField}
								options={userOptions}
								showSearch
								onSearch={this.onSearch}
								notFoundContent={null}
								loading={users.loading}
								required
								validate={[this.accountRequired]}
								size="large"
							/>
							<P>
								{`The following fields will be updated: `}
								{transferred.join(", ")}
							</P>
						</form>
					)}
				</LoadErrorView>
			</Modal>
		);
	}
}

const formName = "transferEvent";
TransferEventModal = reduxForm({
	form: formName
})(TransferEventModal);

const selector = formValueSelector(formName);
export default connect(
	(state) => {
		return {
			users: state.users,
			currentUser: state.currentUser,
			currentEvent: state.currentEvent,
			locations: state.locations,
			transferSettings: selector(state, "transferSettings")
		};
	},
	{ closeTransferEventModal, transferEvent, find }
)(TransferEventModal);
