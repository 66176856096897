import { SeatingMap } from "@hx/seating-map";
import { Button, Input } from "@/ui/antd";
import { Component } from "react";
import LinkButton from "../components/buttons/LinkButton";
export default class EditableName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			name: ""
		};
		this.onNameChange = this.onNameChange.bind(this);
		this.onEditClicked = this.onEditClicked.bind(this);
		this.onCloseClicked = this.onCloseClicked.bind(this);
	}

	componentWillMount() {
		this.seatingMap = SeatingMap.getCurrentMap();
		this.setState({ name: this.seatingMap.getName() });
	}

	onEditClicked() {
		this.setState({ open: true });
	}

	onNameChange(e) {
		const name = e.target.value;
		this.seatingMap.changeName(name);
		this.setState({ name });
	}

	onCloseClicked() {
		this.setState({ open: false });
	}

	render() {
		const { open, name } = this.state;
		if (open) {
			return (
				<div style={{ display: "flex", marginRight: 12, height: 40 }}>
					<Input
						style={{ width: 200 }}
						maxLength={80}
						value={name}
						onChange={this.onNameChange}
						onPressEnter={this.onCloseClicked}
					/>
					<Button
						style={{
							width: 100,
							height: 37,
							paddingRight: 10,
							marginLeft: 8
						}}
						onClick={this.onCloseClicked}
						type="primary"
						ariaLabel="Done"
					>
						Done
					</Button>
				</div>
			);
		}
		return (
			<div
				style={{
					display: "flex",
					marginRight: 12,
					height: 40,
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<LinkButton onClick={this.onEditClicked} ariaLabel="Edit">
					<img src="/frontend/seatingMap/pen.svg" alt="pen" />
				</LinkButton>
				<h4 style={{ marginBottom: 0, paddingLeft: 8 }}>{name}</h4>
			</div>
		);
	}
}
