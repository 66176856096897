import { Modal, Table } from "@/ui/antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import { H2, H5, P } from "../components/text/Text";
import { closeWarningModal, deleteBankAccount } from "../state/actions/userActions";

let BankAccountWarningModal = ({ modalOpen, closeWarningModal, confirmationRequest, userId, deleteBankAccount }) => {
	const { events, message, bankAccountId, allowToConfirm } = confirmationRequest;

	const handleConfirmedDelete = () => {
		deleteBankAccount(userId, bankAccountId);
	};

	const title = (
		<div style={{ display: "flex" }}>
			<Icon style={{ marginRight: 8, marginTop: 2 }} icon={allowToConfirm ? "triangle_warning" : "warning_red"} />
			<H2 style={{ marginBottom: 0 }}>
				{allowToConfirm ? "Warning: deleting bank account" : "Bank account cannot be deleted"}
			</H2>
		</div>
	);

	const footer = allowToConfirm ? (
		<ButtonsBar footer>
			<LegacyButton key="cancel" onClick={closeWarningModal} htmlType="button" disabled={false} ariaLabel="Cancel">
				Cancel
			</LegacyButton>
			<LegacyButton
				onClick={handleConfirmedDelete}
				key="delete"
				type="warning"
				disabled={false}
				htmlType="button"
				ariaLabel="Delete"
			>
				Delete
			</LegacyButton>
		</ButtonsBar>
	) : (
		<LegacyButton
			key="cancel"
			onClick={closeWarningModal}
			htmlType="button"
			disabled={false}
			ariaLabel="Cancel"
			style={{
				float: "right"
			}}
		>
			Cancel
		</LegacyButton>
	);
	return (
		<Modal
			title={title}
			open={modalOpen}
			onCancel={closeWarningModal}
			destroyOnClose
			style={{ height: "auto", padding: 0 }}
			bodyStyle={{ padding: "15px 20px 0px" }}
			footer={footer}
		>
			<P style={{ marginBottom: 16 }}>{message}</P>
			{events && events.length > 0 ? (
				<>
					<H5>Events using this bank account:</H5>
					<Table
						showHeader={false}
						style={{ marginBottom: 16 }}
						columns={[
							{
								title: "Event",
								dataIndex: "_id",
								key: "_id",
								render: (_, event) => (
									<Link to={`/console/my-events/${event._id}`} key={event._id}>
										{event.name}
									</Link>
								)
							}
						]}
						scroll={{ y: 200 }}
						pagination={false}
						dataSource={events}
						rowKey="_id"
					/>
				</>
			) : null}
		</Modal>
	);
};

export default connect(
	(state) => {
		return {
			modalOpen: state.currentUser.warningModalOpen,
			confirmationRequest: state.currentUser.confirmationRequest
		};
	},
	{ closeWarningModal, deleteBankAccount }
)(BankAccountWarningModal);
