import zipLogo from "@/assets/logo_zip.svg";
import { SwitchField } from "@/components/Fields";
import { Button } from "@/ui/Button";
import { getConfig } from "@/config";
import {
	Footer,
	GatewayEnabledWrapper,
	ImageWrapper,
	PaymentCard
} from "@/events/event/payments/components/gateways/GatewayComponents";
import { dayjs } from "@/libs/dayjs";
import { ReduxState } from "@/state/store";
import { Alert, Modal } from "@/ui/antd";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { FC, useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

type ReduxInjectedProps = {
	orgPaymentOptions: any;
	zipMoneyEnabled?: boolean;
};

type ZipFormProps = {
	allowed: boolean;
	event: any;
	location: string;
	paymentOptionsStr: string;
	save: (data: any) => void;
	type: string;
};

const ZipForm: FC<ZipFormProps & InjectedFormProps & ReduxInjectedProps> = ({
	allowed,
	event,
	handleSubmit,
	location,
	orgPaymentOptions,
	paymentOptionsStr,
	save,
	type,
	zipMoneyEnabled
}) => {
	const [modalOpen, toggleModal] = useState(false);
	if (!allowed) {
		return null;
	}
	let ZIP_FEE;
	try {
		ZIP_FEE = getConfig("ZIP_FEE", location);
	} catch (err) {
		// when switching to a location that doesn't support this gateway, hide the form until the new gatewayOptions are loaded
		return null;
	}

	const saveForm = (values: any) => {
		toggleModal(false);
		const updateData: any = {};
		updateData[paymentOptionsStr] = {
			...orgPaymentOptions,
			zipMoneyEnabled: values.zipMoneyEnabled
		};
		save(updateData);
	};

	const zipWarning =
		type !== "event" ||
		(type === "event" &&
			event?.event?.createdAt &&
			dayjs(event.event.createdAt).isAfter(dayjs("2021-04-28"), "day")) ? (
			<Alert
				message={
					<span>
						You will be charged a <b>Zip Fee of {ZIP_FEE}%</b> for all sales processed through Zip. Sales will only be
						paid to you 5 business days post event.
					</span>
				}
				showIcon
				style={{ margin: "16px 0" }}
				type="info"
			/>
		) : null;

	return (
		<PaymentCard>
			<ImageWrapper>
				<img alt="Zip pay" src={zipLogo} />
			</ImageWrapper>

			<div>
				<p>Allow your clients to buy now, pay later with ZipPay for orders up to $1500.</p>
				{zipWarning}
			</div>
			<Footer>
				{zipMoneyEnabled ? (
					<GatewayEnabledWrapper>
						<div>Zip pay enabled</div>
					</GatewayEnabledWrapper>
				) : null}
				<Button
					aria-label="Settings"
					onClick={() => toggleModal(true)}
					style={{ width: "100%", margin: "auto", maxWidth: 240 }}
					variant="tertiary"
				>
					{zipMoneyEnabled ? "Settings" : "Set up"}
				</Button>
			</Footer>
			<Modal
				footer={
					<Button
						aria-label="Save"
						form="zipForm"
						htmlType="submit"
						onClick={handleSubmit(saveForm)}
						style={{ float: "right" }}
						variant="primary"
					>
						Save
					</Button>
				}
				onCancel={() => toggleModal(false)}
				open={modalOpen}
				title="Zip Money Settings"
			>
				<form>
					<Field name="zipMoneyEnabled" label="Enable Zip Money" component={SwitchField} />
					{zipWarning}
				</form>
			</Modal>
		</PaymentCard>
	);
};

const ReduxZipForm = reduxForm({
	form: "zipForm",
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => scrollToErrors()
})(ZipForm as any);

const mapStateToProps = (_: ReduxState, ownProps: ReduxInjectedProps) => ({
	initialValues: {
		zipMoneyEnabled: ownProps.orgPaymentOptions ? ownProps.orgPaymentOptions.zipMoneyEnabled : null
	},
	zipMoneyEnabled: ownProps.orgPaymentOptions ? ownProps.orgPaymentOptions.zipMoneyEnabled : false
});

export default connect(mapStateToProps)(ReduxZipForm);
