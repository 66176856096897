import { dayjs } from "@/libs/dayjs";
import EventsService from "@/services/EventsService";
import scheduleService from "@/services/SchedulerService";
import { notification } from "@/ui/antd";
import { GET_EVENT_SUCCESS } from "./eventActions";

export const CREATE_SCHEDULE_REQUEST = "CREATE_SCHEDULE_REQUEST";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_ERROR = "CREATE_SCHEDULE_ERROR";

const RESCHEDULE_REQUEST = "RESCHEDULE_REQUEST";
export const RESCHEDULE_SUCCESS = "RESCHEDULE_SUCCESS";
const RESCHEDULE_ERROR = "RESCHEDULE_ERROR";

export const LOAD_DATES_REQUEST = "LOAD_DATES_REQUEST";
export const LOAD_DATES_SUCCESS = "LOAD_DATES_SUCCESS";
export const LOAD_DATES_ERROR = "LOAD_DATES_ERROR";

export const DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_ERROR = "DELETE_SCHEDULE_ERROR";

export const RESET_SCHEDULE_RESULT = "RESET_SCHEDULE_RESULT";

export const deleteSchedule = (deleteScheduleData) => {
	return async (dispatch, getState) => {
		const { currentEvent } = getState();
		dispatch({ type: DELETE_SCHEDULE_REQUEST });
		try {
			const { numberDeleted, unsuccessfulDates } = await scheduleService.deleteSchedule(
				currentEvent.event._id,
				deleteScheduleData.scheduleId,
				deleteScheduleData
			);
			dispatch({
				type: DELETE_SCHEDULE_SUCCESS,
				numberDeleted,
				unsuccessfulDates
			});
			loadDates()(dispatch, getState);
			await reloadEvent(dispatch, getState);
		} catch (err) {
			dispatch({ type: DELETE_SCHEDULE_ERROR });
		}
	};
};

export const reschedule = (rescheduleData) => {
	return async (dispatch, getState) => {
		const { currentEvent } = getState();
		dispatch({ type: RESCHEDULE_REQUEST });
		try {
			const { changedDatesWithOrders, numberOfChangedDates } = await scheduleService.reschedule(
				currentEvent.event._id,
				rescheduleData.scheduleId,
				rescheduleData
			);
			dispatch({
				type: RESCHEDULE_SUCCESS,
				changedDatesWithOrders,
				numberOfChangedDates
			});
			loadDates()(dispatch, getState);
		} catch (err) {
			dispatch({ type: RESCHEDULE_ERROR });
		}
	};
};

export const createSchedule = (scheduleData) => {
	return async (dispatch, getState) => {
		const { currentEvent } = getState();
		dispatch({ type: CREATE_SCHEDULE_REQUEST });
		try {
			const { success, errorMessage } = await scheduleService.createSchedule(currentEvent.event._id, scheduleData);
			if (!success && errorMessage) {
				notification.error({ message: errorMessage });
				dispatch({ type: CREATE_SCHEDULE_ERROR });
			} else {
				dispatch({ type: CREATE_SCHEDULE_SUCCESS });
				loadDates()(dispatch, getState);
				await reloadEvent(dispatch, getState);
			}
		} catch (err) {
			dispatch({ type: CREATE_SCHEDULE_ERROR });
		}
	};
};

const reloadEvent = async (dispatch, getState) => {
	const { currentEvent } = getState();
	const event = await EventsService.get(currentEvent.event._id);
	dispatch({ type: GET_EVENT_SUCCESS, event });
};

let calendarApi;
export const linkCalendarApi = (calendarApiRef) => {
	calendarApi = calendarApiRef;
};

export const loadDates = (jumpToUpcoming = false) => {
	if (!calendarApi) throw new Error("Calendar api not linked");
	return async (dispatch, getState) => {
		const { currentEvent } = getState();
		const calendarDate = dayjs(calendarApi.getDate());
		const { currentViewType } = calendarApi.getCurrentData();
		dispatch({
			type: LOAD_DATES_REQUEST
		});
		if (currentViewType === "listYear") {
			/*
        When doing list year view there is a TZ issue were instead of the 
        date being 1st Jan, itll be 1st Jan +- the timezone offset sometimes giving the
        wrong date range
      */
			calendarDate.set("month", 6);
		}
		try {
			const { dates, gotoDate } = await scheduleService.loadDates(
				currentEvent.event._id,
				calendarDate.toDate(),
				currentViewType,
				jumpToUpcoming
			);
			if (gotoDate) {
				calendarApi.gotoDate(new Date(gotoDate));
			}
			dispatch({ type: LOAD_DATES_SUCCESS, dates });
		} catch (err) {
			dispatch({ type: LOAD_DATES_ERROR });
		}
	};
};

export const resetResult = () => {
	return (dispatch) => {
		dispatch({ type: RESET_SCHEDULE_RESULT });
	};
};
