import { CLOSE_MODAL } from "../actions/previewActions";

const initialState = {
	error: false,
	modalOpen: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case CLOSE_MODAL:
			return {
				...state,
				modalOpen: false
			};
		default:
			return state;
	}
};
