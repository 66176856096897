import { css } from "@emotion/css";
import { useNavigate } from "react-router";
import welcomeImage from "../../assets/illustration_dashboard.svg";
import { LegacyButton } from "@/components/buttons/LegacyButton";

const welcomeImageClass = css`
	width: 100%;
	maxwidth: 360px;
	height: 310px;
	margin-bottom: 16px;
	@media (max-width: 600px) {
		max-width: 260px;
		max-height: 223px;
		margin-top: 24px;
		margin-bottom: 12px;
	}
`;
const noResultsMessageClass = css`
	font-size: 18px;
	margin-bottom: 12px;
	margin-top: 0px;
	@media (max-width: 600px) {
		font-size: 14px;
	}
`;

const CreateFirstEvent = ({ totalEvents }) => {
	const navigate = useNavigate();
	const createEvent = () => {
		navigate("/console/my-events/new/basics");
	};
	const goToEvents = () => {
		navigate("/console/my-events/");
	};

	const noResultsMessage =
		totalEvents === 0
			? "Welcome! Let's get started by creating your first event."
			: "All your events are currently in draft. Publish your events to start selling tickets!";
	const noResultsAction = totalEvents === 0 ? createEvent : goToEvents;
	const noResultsActionTxt = totalEvents === 0 ? "Create event" : "Events";
	return (
		<div style={{ textAlign: "center" }}>
			<img src={welcomeImage} alt="no event welcome" className={welcomeImageClass} />
			<div className={noResultsMessageClass}>{noResultsMessage}</div>
			<LegacyButton
				type="primary"
				onClick={noResultsAction}
				ariaLabel={noResultsActionTxt}
				style={{ margin: "0 auto" }}
			>
				{noResultsActionTxt}
			</LegacyButton>
		</div>
	);
};

export default CreateFirstEvent;
