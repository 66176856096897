import H2 from "@/components/text/H2";

import React from "react";
import { CartesianGrid, Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Chart = ({ title, dataKey, data }: any) => {
	if (!data || !data.length) {
		return (
			<>
				<H2>{title}</H2>
				<p style={{ textAlign: "center" }}>No results found</p>
			</>
		);
	}
	return (
		<>
			<H2>{title}</H2>
			<ResponsiveContainer width="100%" height={400}>
				<BarChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
					<XAxis dataKey={dataKey} />
					<YAxis allowDecimals={false} />
					<CartesianGrid strokeDasharray="3 3" />
					<Tooltip cursor={{ stroke: "#e0e0e0", strokeWidth: 1, fillOpacity: 0 }} />
					<Bar dataKey="value" fill="#ffa179" />
				</BarChart>
			</ResponsiveContainer>
		</>
	);
};

export default Chart;
