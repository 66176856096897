import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton as HButton } from "@/components/buttons/LegacyButton";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

class MerchandiseReportComponent extends Component {
	constructor(props) {
		super(props);
		this.constructColumns = this.constructColumns.bind(this);
		this.getFilterOptions = this.getFilterOptions.bind(this);
	}

	constructColumns(results = []) {
		const merch = results[0] || {};
		const exclude = ["_id", "orderId", "eventId"];

		let columns = Object.keys(merch)
			.map((key) => {
				if (key === "Order id") {
					return {
						title: key,
						dataIndex: key,
						key,
						render: (orderName, record) => (
							<Link style={{ color: "#196097" }} to={`/console/my-events/${record.eventId}/orders/${record.orderId}`}>
								{orderName}
							</Link>
						)
					};
				}
				const column = {
					title: key,
					dataIndex: key,
					key,
					render: (merch) => {
						if (merch === undefined || merch === "") {
							return "--";
						}
						return merch;
					}
				};
				if (key === "First Name") {
					column.width = 110;
				}
				return column;
			})
			.filter((col) => !(exclude.indexOf(col.key) > -1));

		return columns;
	}

	getFilterOptions() {
		const { dates = [], datesUseCalendar } = this.props.reportState;
		const eventDates = dates || [];
		return [
			...(dates.length > 0
				? [
						{
							key: "eventDateIds",
							label: "Event Dates",
							mode: "multiple",
							kind: datesUseCalendar ? "eventDatePicker" : "select",
							placeholder: "All dates",
							options: eventDates.map((date) => ({
								...(datesUseCalendar ? date : {}),
								value: date._id,
								name: `${date.display.date}, ${date.display.time}`
							}))
						}
				  ]
				: [])
		];
	}

	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;
		const { load, loadPage, downloadCsv } = this.props;

		const columns = this.constructColumns(results);
		const filterOptions = this.getFilterOptions();

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						firstDateName="orderDates"
						firstDateLabel="Order date range"
						filterOptions={filterOptions}
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							{results?.length > 0 && (
								<HButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
									{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
								</HButton>
							)}
						</div>

						<Table
							columns={columns}
							dataSource={results}
							loading={loading}
							rowKey="_id"
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							scroll={{ x: columns.length ? columns.length * 150 : 2000 }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}
export const MerchandiseReport = withReportProvider(MerchandiseReportComponent, "Add-ons", "merchandise");
