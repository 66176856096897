import { FieldArray } from "redux-form";

import { ContentBlock } from "@/components/AppBlocks";
import { H2, P } from "@/components/text/Text";
import DonationsTable from "./DonationsTable";

type DonationsProps = {
	touch: any;
	changeFieldValue: (field: any, value: number) => void;
	timezone: string;
	ticketTypes: any;
	isFreeOnly: boolean;
};

const Donations = ({ isFreeOnly, touch, changeFieldValue, ticketTypes = [] }: DonationsProps) => (
	<ContentBlock>
		<H2>Donations</H2>
		<P>Note: Donations will not generate a ticket</P>
		<div style={{ clear: "both" }}>
			<FieldArray
				name="ticketTypes"
				component={DonationsTable}
				ticketTypes={ticketTypes}
				touch={touch}
				changeFieldValue={changeFieldValue}
				isFreeOnly={isFreeOnly}
			/>
		</div>
	</ContentBlock>
);

export default Donations;
