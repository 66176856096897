import { css } from "@emotion/css";
import { Checkbox, Tooltip } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";

const checkboxStyle = css({
	".ant-checkbox-inner": {
		width: 30,
		height: 30,
		borderRadius: 4,
		border: "1px solid #dedede"
	},
	".ant-checkbox-inner::after": {
		display: "none !important"
	},
	".ant-checkbox + span": {
		position: "absolute",
		zIndex: 1,
		margin: "auto",
		top: 6,
		left: 0,
		bottom: 0,
		right: 0,
		textAlign: "center"
	},
	".ant-checkbox-wrapper": {
		position: "relative",
		marginRight: 8
	},
	".ant-checkbox-wrapper-checked": {
		color: "white"
	}
});
const AnotherFuckingCheckboxField = ({
	input,
	label,
	description,
	inline,
	noPadding,
	required,
	options,
	labelAfter,
	noMargin,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error }
}) => {
	return (
		<div className={checkboxStyle}>
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				noPadding={noPadding}
				id={input.name}
				inline={inline}
				required={required}
				labelAfter={labelAfter}
				noMargin={noMargin}
				tooltip={tooltip}
				tooltipIcon={tooltipIcon}
				tooltipIconOnClick={tooltipIconOnClick}
			>
				<Checkbox.Group id={input.name} onChange={input.onChange} value={input.value}>
					{options.map((option) => {
						return (
							<Tooltip title={option.tooltip}>
								<Checkbox value={option.value}>{option.label}</Checkbox>
							</Tooltip>
						);
					})}
				</Checkbox.Group>
			</InputWrapper>
		</div>
	);
};

export default AnotherFuckingCheckboxField;
