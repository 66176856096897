import { css } from "@emotion/css";
import { Progress } from "@/ui/antd";
import { div, mul } from "sinful-math";
import { P } from "@/components/text/Text";

const Header = ({ currStep, total }) => {
	const headerStyle = css({
		width: 240,
		marginTop: 24,
		p: {
			textAlign: "right",
			margin: 0,
			color: "#323232"
		}
	});

	return (
		<div className={headerStyle}>
			<P
				style={{
					paddingLeft: 4,
					paddingTop: 2,
					textDecoration: "none",
					color: "black"
				}}
			>
				{currStep}/{total}
			</P>
			<Progress
				strokeWidth={8}
				percent={mul(div(currStep, total), 100)}
				width={100}
				strokeColor="#353337"
				showInfo={false}
			/>
		</div>
	);
};

export default Header;
