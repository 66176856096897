import axios from "./AxiosWrapper";

const GatewaysService = {
	async update(type, id, paymentOptions, location) {
		let options = {};
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}
		const response = await axios.post(`gateways/${type}/${id}`, paymentOptions, options);
		return response.data;
	}
};
export default GatewaysService;
