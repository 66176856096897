import { css } from "@emotion/css";
import { Tooltip } from "@/ui/antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import iconReference from "../services/IconReference";
import { requestOwner } from "../state/actions/userActions";
import type { ReduxState as GlobalReduxState } from "../state/store";

const sharedIcon = iconReference["shared"];

const spanStyling = css({
	color: "#fff",
	":hover": {
		textDecoration: "underline"
	}
});

interface ISharedIndicatorProps {
	userId: string;
	ownerId: string;
	sharedObjectName: string;
}

interface IReduxActions {
	requestOwner: (ownerId: string) => void;
}

interface IPickedReduxState {
	owner: any;
}

type SharedIndicatorInput = ISharedIndicatorProps & IPickedReduxState & IReduxActions;

const SharedIndicator = ({ userId, ownerId, requestOwner, owner, sharedObjectName }: SharedIndicatorInput) => {
	const _triggerLoad = () => {
		if (!owner[ownerId] && userId !== ownerId) {
			requestOwner(ownerId);
		}
	};

	if (userId === ownerId) {
		return null;
	}

	const eventOwnerInformation = owner[ownerId];

	return (
		<span onMouseEnter={_triggerLoad}>
			<Tooltip
				title={
					owner.loading || !eventOwnerInformation ? (
						"Loading..."
					) : (
						<Link to="/console/account/authorization">
							<span className={spanStyling}>
								This {sharedObjectName} has been shared with you by {eventOwnerInformation.firstName}{" "}
								{eventOwnerInformation.lastName} ({eventOwnerInformation.email})
							</span>
						</Link>
					)
				}
			>
				<img src={String(sharedIcon)} style={{ marginRight: 8 }} alt="Shared event" />
			</Tooltip>
		</span>
	);
};

export default connect(
	(state: Partial<GlobalReduxState>) => ({
		owner: state?.currentUser?.owner
	}),
	{
		requestOwner
	}
)(SharedIndicator);
