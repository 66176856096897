import { LOAD_REDACTOR_ERROR, LOAD_REDACTOR_REQUEST, LOAD_REDACTOR_SUCCESS } from "../actions/assetsActions";

const initialState = {
	redactor: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_REDACTOR_REQUEST:
			return {
				...state,
				redactor: false
			};
		case LOAD_REDACTOR_SUCCESS:
			return {
				...state,
				redactor: true
			};
		case LOAD_REDACTOR_ERROR:
			return {
				...state,
				redactor: false
			};
		default:
			return state;
	}
};
