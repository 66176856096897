import { ComponentType, useEffect } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../components/Breadcrumb";
import { H1 } from "../components/text/Text";

import LocationSelection from "../components/LocationSelection";
import Page from "../components/Page";
import { findEvents, getEventsById } from "../state/actions/eventActions";
import { getReport, getReportCsv, resetGlobalReports } from "../state/actions/globalReportActions";
import { resetSearch, setSearch, updateSearch } from "../state/actions/searchActions";
import type { SearchReducerState } from "../state/reducers/searchReducer";
import type { ReduxState as GlobalReduxState } from "../state/store";
import { getSearchStateFromUrl } from "../utils/Urls";

type Search = SearchReducerState;

interface ReduxActions {
	getReport: (reportName: string, page: number) => void;
	updateSearch: (search: Search, routeChange?: boolean) => void;
	getReportCsv: (reportName: string) => void;
	setSearch: (search: Search, routeChange?: boolean) => void;
	getEventsById: (eventIds: string[]) => void;
	findEvents: (page: number, search: Search) => void;
	resetSearch: () => void;
	resetGlobalReports: () => void;
}

const pickDataFromState = (state: Partial<GlobalReduxState>) => ({
	reportState: state.globalReportState,
	eventSearchState: state.events,
	search: state.search,
	userRole: state.auth?.user?.role,
	currentEvent: state.currentEvent,
	showInsuranceColumn: state.auth?.user?.showInsuranceColumn
});

type LocalisedReduxState = ReturnType<typeof pickDataFromState>;
type IReportProps = LocalisedReduxState & ReduxActions;

type IReportComponentProps = {
	load: () => void;
	loadPage: (page: number) => void;
	downloadCsv: () => void;
	local: boolean;
} & IReportProps;

const withReportProvider = (
	Component: ComponentType<IReportComponentProps>,
	title: string,
	reportName: string,
	reportDefaultSearch: Search = {},
	isLocalisedReport = false
) => {
	const Report = (props: IReportProps) => {
		const {
			getReport,
			updateSearch,
			getReportCsv,
			setSearch,
			getEventsById,
			findEvents,
			resetSearch,
			resetGlobalReports,
			currentEvent
		} = props;

		const defaultSearch: Search = {
			query: "",
			sortOrder: "relevant",
			filter: "all"
		};

		const _load = (page = 1) => {
			if (typeof page === "number") {
				getReport(reportName, page);
			} else {
				getReport(reportName, 1);
			}
		};
		const _loadPage = (page: number) => {
			updateSearch({ page }, true);
			getReport(reportName, page);
		};

		const _downloadCsv = () => getReportCsv(reportName);

		useEffect(() => {
			const search: Search = getSearchStateFromUrl(reportDefaultSearch);

			if (isLocalisedReport && currentEvent?.event?._id) {
				search.eventIds = [currentEvent.event._id];
				defaultSearch.eventIds = [currentEvent.event._id];
			}

			setSearch(search);
			if (search.eventIds && Array.isArray(search.eventIds)) {
				getEventsById(search.eventIds);
			}

			findEvents(1, defaultSearch);

			return () => _resetReportState();
		}, []);

		const _resetReportState = () => {
			resetGlobalReports();
			resetSearch();
		};

		const _onLocationChange = (loc: string) => {
			const { setSearch, findEvents, resetGlobalReports } = props;

			resetGlobalReports();
			setSearch({ loc }, true);
			findEvents(1, defaultSearch);
		};

		const report = (
			<Component {...props} load={_load} loadPage={_loadPage} downloadCsv={_downloadCsv} local={isLocalisedReport} />
		);

		return (
			<>
				{isLocalisedReport ? (
					<>
						<H1>{title}</H1>
						{report}
					</>
				) : (
					<Page
						type="details"
						pageName={title}
						breadcrumb={<Breadcrumb prevPath="/console/reports" prevTitle="Reports" currTitle={title} />}
						sibling={
							<LocationSelection componentName="reports" defaultLevel="user" onLocationChange={_onLocationChange} />
						}
					>
						{report}
					</Page>
				)}
			</>
		);
	};

	return connect(pickDataFromState, {
		findEvents,
		getEventsById,
		getReport,
		getReportCsv,
		setSearch,
		updateSearch,
		resetSearch,
		resetGlobalReports
	})(Report);
};

export default withReportProvider;
