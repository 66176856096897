import { HumaniDate } from "@hx/dates";
import { Tag } from "@/ui/antd";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { ContentBlock } from "@/components/AppBlocks";
import H2 from "@/components/text/H2";
import LocationService from "@/services/LocationService";

const getStatus = (value) => {
	switch (value) {
		case true:
			return <Tag color="#157E77">Sent</Tag>;
		case false:
			return <Tag color="#C0212C">Failed</Tag>;
		default:
			return <Tag color="#999999">Pending</Tag>;
	}
};

const BaseInfo = ({ log, email }) => {
	if (!email) {
		return null;
	}
	const filters = [
		{
			val: [log.subject],
			selectedFieldName: "subject",
			comparisonType: "Is"
		},
		{
			val: [log.to],
			selectedFieldName: "to_email",
			comparisonType: "Is"
		}
	];
	const showSendGridLink = email && email.failureType !== "api-validation-error";
	const searchUrl = `https://app.sendgrid.com/email_activity?filters=${encodeURIComponent(
		JSON.stringify(filters)
	)}&isAndOperator=true`;

	return (
		<ContentBlock>
			<H2>Initial Email Response Information</H2>
			<table className="full-width-table" style={{ marginBottom: 24 }}>
				<tbody>
					<tr style={{ background: "#f9f9fa" }}>
						<th>Field</th>
						<th>Value</th>
					</tr>
					<tr>
						<td>Email Id</td>
						<td>{log.emailId}</td>
					</tr>
					<tr>
						<td>Template Type</td>
						<td>{log.emailType}</td>
					</tr>
					<tr>
						<td>Subject</td>
						<td>{log.subject}</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>{getStatus(log.success)} </td>
					</tr>
					<tr>
						<td>Attempts</td>
						<td>{log.attempts}</td>
					</tr>
					<tr>
						<td>Attachments</td>
						<td>{log.attachmentCount}</td>
					</tr>
					<tr>
						<td>Last attempt</td>
						<td>
							{new HumaniDate(email.modifiedAt, "guess", LocationService.getLocation(true)).formatting.dateTime()}
						</td>
					</tr>
					{showSendGridLink ? (
						<tr>
							<td>SendGrid link</td>
							<td>
								<a href={searchUrl} target="_blank" rel="noopener noreferrer">
									View Email on SendGrid
								</a>
							</td>
						</tr>
					) : null}
				</tbody>
			</table>
			<H2>JSON Preview</H2>
			<JSONPretty id="json-pretty" data={email} />
		</ContentBlock>
	);
};

export default BaseInfo;
