import { findAncestor, findPos, focusFirstInput } from "@/utils/Browser";

export const scrollToErrors = () => {
	setTimeout(() => {
		try {
			const errors = Array.from(document.getElementsByClassName("has-error")) as HTMLElement[];
			const validErrors = errors.filter((ele) => ele.offsetParent !== null);
			if (validErrors.length) {
				const firstEl = validErrors[0];
				const scrollEl = findAncestor(firstEl, "scrollEl");
				scrollTo(findPos(firstEl) - 200, 200, scrollEl);
				focusFirstInput(firstEl);
			}
		} catch (err) {
			return;
		}
	}, 100);
};

export const scrollToId = (id: string) => {
	setTimeout(() => {
		try {
			const element = document.getElementById(id);
			if (element) {
				const scrollEl = findAncestor(element, "scrollEl");
				scrollTo(findPos(element) - 200, 200, scrollEl);
			}
		} catch (e) {
			return;
		}
	}, 100);
};

function elementScrollTo(element: HTMLElement, x: number, y: number) {
	try {
		element.scrollTo(x, y);
	} catch (e) {
		return;
	}
}

const scrollTo = (elementY: number, duration: number, scrollEl: HTMLElement) => {
	const startingY = scrollEl.scrollTop;
	const diff = elementY - startingY;
	let start: number;

	if (!window.requestAnimationFrame) {
		elementScrollTo(scrollEl, 0, elementY);
		return;
	}
	window.requestAnimationFrame(function step(timestamp) {
		if (!start) start = timestamp;
		const time = timestamp - start;
		const percent = Math.min(time / duration, 1);
		elementScrollTo(scrollEl, 0, startingY + diff * percent);
		if (time < duration) {
			window.requestAnimationFrame(step);
		}
	});
};
