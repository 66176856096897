const Square = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0H24V24H0z" />
				<path id="ele-id-stroke" stroke="#67717E" strokeWidth="2" d="M3 3H21V21H3z" />
			</g>
		</svg>
	);
};

export default Square;
