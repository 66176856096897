import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { SelectField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { H2, P } from "@/components/text/Text";
import { closeOfferModal, offerTickets } from "@/state/actions/waitlistActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { checkLength } from "@/utils/Validators";

class ManualOfferModal extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}
	save(values) {
		const { currentEvent, offerTo, offerTickets, searchParams } = this.props;

		offerTickets(currentEvent.event._id, values.eventDateIds, offerTo, {
			eventDateIds: searchParams.eventDateIds,
			ticketIds: searchParams.ticketIds,
			query: searchParams.query,
			page: searchParams.page
		});
	}
	dateRequired = checkLength("One event date is required", 0, ">");

	render() {
		const { currentEvent, closeOfferModal, handleSubmit, waitlist, offerTo } = this.props;
		const dateOptions = currentEvent.event.dates.map((date) => {
			const dateStr = `${date.display.date}, ${date.display.time}`;
			return {
				value: date._id,
				label: dateStr
			};
		});
		return (
			<Modal
				open={waitlist.offerModalOpen}
				title={<H2 style={{ marginBottom: 0 }}>Offer tickets</H2>}
				destroyOnClose
				onCancel={closeOfferModal}
				footer={[
					<ButtonsBar footer>
						<LegacyButton
							style={{
								float: "left"
							}}
							key="cancel"
							onClick={closeOfferModal}
							htmlType="button"
							ariaLabel="Cancel"
						>
							Cancel
						</LegacyButton>
						,
						<LegacyButton
							onClick={handleSubmit(this.save)}
							key="submit"
							type="primary"
							htmlType="submit"
							ariaLabel="Offer"
						>
							Offer
						</LegacyButton>
					</ButtonsBar>
				]}
			>
				<form onSubmit={handleSubmit(this.save)}>
					<LoadErrorView
						loading={waitlist.offer.loading}
						error={waitlist.offer.error}
						retryAction={handleSubmit(this.save)}
					>
						<P>
							Offer tickets to {offerTo.length} {offerTo.length === 1 ? "person" : "people"}
						</P>

						<Field
							name="eventDateIds"
							label="Event dates"
							description="For which event dates"
							component={SelectField}
							options={dateOptions}
							style={{ width: "50%" }}
							mode="multiple"
							validate={this.dateRequired}
						/>
					</LoadErrorView>
				</form>
			</Modal>
		);
	}
}
const formName = "waitlistNotify";
ManualOfferModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(ManualOfferModal);

export default connect(
	(state) => {
		const event = state.currentEvent.event;
		const initialValues = event.waitlistSettings;
		return {
			initialValues,
			currentEvent: state.currentEvent,
			waitlist: state.waitlist
		};
	},
	{ closeOfferModal, offerTickets }
)(ManualOfferModal);
