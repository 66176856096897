import { ContentBlock } from "@/components/AppBlocks";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import LinkButton from "@/components/buttons/LinkButton";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { dayjs } from "@/libs/dayjs";
import { notification, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class FacebookEventsList extends Component {
	creatEvent = () => {
		const { navigate, currentEvent } = this.props;
		const event = currentEvent.event;

		if (event.eventLocation.type === "toBeAnnounced") {
			notification.error({
				message: "Error",
				description: "Event location must be set to create a Facebook event"
			});
			return;
		}

		const now = dayjs().tz(event.timezone);
		const validDates = event.dates.filter((date) => {
			const startDate = dayjs(date.startDate).tz(event.timezone);
			const inFuture = startDate.isAfter(now);
			return !date.disabled && !date.deleted && inFuture;
		});

		if (validDates.length) {
			navigate(`/console/my-events/${currentEvent.event._id}/facebook-event`);
		} else {
			notification.error({
				message: "Error",
				description: `Event must not be in the past to create a Facebook event`
			});
		}
	};
	render() {
		const { currentEvent } = this.props;
		const facebookEvents = currentEvent.event.facebookEvents;

		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (value, data) => (
					<Link
						style={{ color: "#353337" }}
						to={`/console/my-events/${currentEvent.event._id}/facebook-event/${data.facebookId}`}
					>
						{value}
					</Link>
				)
			},
			{
				title: "Page",
				dataIndex: "pageName",
				key: "pageName",
				render: (value, data) => (
					<Link
						style={{ color: "#353337" }}
						to={`/console/my-events/${currentEvent.event._id}/facebook-event/${data.facebookId}`}
					>
						{value}
					</Link>
				)
			},
			{
				title: "View",
				dataIndex: "facebookId",
				key: "facebookId",
				render: (facebookId) => (
					<a href={`https://www.facebook.com/events/${facebookId}`} target="_blank" rel="noopener noreferrer">
						<LinkButton ariaLabel="View Event" size="large">
							View Event
						</LinkButton>
					</a>
				)
			}
		];

		return (
			<ContentBlock>
				<div style={{ overflowX: "auto" }}>
					<Table pagination={false} columns={columns} dataSource={facebookEvents} />
					<ButtonsBar>
						<LegacyButton
							onClick={this.creatEvent}
							icon={{ name: "plus", left: true }}
							ariaLabel=" Create new Facebook event"
							type="action"
						>
							Create new Facebook event
						</LegacyButton>
					</ButtonsBar>
				</div>
			</ContentBlock>
		);
	}
}

export default connect((state) => {
	return {
		currentEvent: state.currentEvent
	};
})(withRouterHooks(FacebookEventsList));
