import SelectField from "@/components/fields/SelectField";
import { notification } from "@/ui/antd";
import { FC, useState } from "react";
import { WrappedFieldArrayProps } from "redux-form";
import { debounce } from "throttle-debounce";
import { trpc } from "../trpc";
import type { Artist } from "@hx/console/types";

type ArtistsProps = WrappedFieldArrayProps & {
	label: string;
	description: string;
	parentValidate?: (values: string[]) => string | undefined;
	childValidate?: (value: string) => string | undefined;
	meta?: {
		dirty?: boolean;
		error?: string | undefined;
	};
	required: boolean;
	selectedArtists?: Artist[];
};

export const Artists: FC<ArtistsProps> = ({ fields, label, description, meta, required, selectedArtists }) => {
	const [query, setQuery] = useState<string>("");
	const { data, isLoading } = trpc.artists.searchArtists.useQuery(query, {
		onError: (error) => {
			notification.error({
				message: "Error",
				description: error.message
			});
		}
	});

	const { error } = meta ?? {};

	const findArtistIndex = (artistName: string) => fields.getAll().findIndex((artist) => artist.name === artistName);
	const findArtistByName = (artistName: string) => data?.artists?.find((artist) => artist.name === artistName);
	const formatData = (arr: Artist[]) => arr?.map((item) => ({ value: item.name, label: item.name }));

	const handleDelete = (item: string) => fields.remove(findArtistIndex(item));
	const handleSelect = (item: string) => fields.push(findArtistByName(item));

	const formattedArtists = formatData(selectedArtists ?? []);
	const options = formatData(data?.artists ?? []);

	const debouncedQuery = debounce(1000, setQuery);

	return (
		<SelectField
			label={label}
			description={description}
			meta={meta}
			id={fields.name}
			required={required}
			disabled={!!error}
			input={{
				name: fields.name,
				value: formattedArtists
			}}
			mode="multiple"
			placeholder="Search artists"
			options={options}
			showSearch
			onSelect={handleSelect}
			onDeselect={handleDelete}
			onSearch={debouncedQuery}
			onBlur={() => setQuery("")}
			loading={isLoading}
		/>
	);
};
