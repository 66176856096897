import { getCurrencySymbol } from "./Format";

const cleanStr = (str, replacement) => str.trim().replace(`${replacement}`, "").trim().replaceAll(" ", "");

const adornmentParse = (prefix) => (value) => {
	if (typeof value === "string") {
		return cleanStr(value, prefix);
	}
	return value;
};

const suffixFormat = (str) => (value) => {
	const cleanValue = adornmentParse(str)(value);
	return `${cleanValue} ${str}`;
};

export const currencyFormat = (value) => {
	const symbol = getCurrencySymbol();
	const cleanValue = adornmentParse(symbol)(value);
	return `${symbol} ${cleanValue}`;
};
export const currencyParse = (value) => {
	const symbol = getCurrencySymbol();
	if (typeof value === "string") {
		return cleanStr(value, symbol);
	} else if (typeof value === "number") {
		return cleanStr(value.toString(), symbol);
	}
	return symbol;
};

export const percentFormat = suffixFormat("%");
export const percentParse = adornmentParse("%");

export const round = (value) => {
	if (isNaN(value)) {
		return value;
	}
	try {
		return +value.toFixed(2);
	} catch (err) {
		return value;
	}
};
