import { notification } from "@/ui/antd";
import { Component } from "react";
import { LoadingSpinner } from "../components/AppBlocks";
import { H1 } from "../components/text/Text";
import PublicService from "../services/PublicService";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class Authorize extends Component {
	constructor(props) {
		super(props);
		this.relocate = this.relocate.bind(this);
		this.showToaster = this.showToaster.bind(this);
	}

	breakUpSearch(search) {
		if (search.length > 0) {
			return search
				.substring(1, search.length)
				.split("&")
				.map((query) => {
					const keyValuePair = query.split("=");
					return { key: keyValuePair[0], value: keyValuePair[1] };
				})
				.reduce((map, current) => {
					map[current.key] = current.value;
					return map;
				}, {});
		}
		return [];
	}

	async componentWillMount() {
		const { location } = this.props;
		try {
			const { email, token } = this.breakUpSearch(location.search);
			const passedEmail = email ? decodeURIComponent(email) : undefined;
			const result = await PublicService.attemptAuthorization(token, passedEmail);
			this.showToaster(result);
			this.relocate(`/signin`, `?email=${encodeURIComponent(passedEmail)}&token=${token}`);
		} catch (err) {
			return;
		}
	}

	showToaster(result) {
		const { message, description } = result;
		const note = {
			message,
			description
		};
		if (result.error) {
			notification.error(note);
		} else {
			notification.success(note);
		}
	}

	relocate(path, params) {
		const { navigate } = this.props;
		navigate(path, { search: params });
	}

	render() {
		return (
			<div style={{ position: "absolute", top: "40%", left: "45%" }}>
				<LoadingSpinner size={100} />
				<H1 style={{ marginLeft: -20, marginTop: 10 }}>Authorizing...</H1>
			</div>
		);
	}
}

export default withRouterHooks(Authorize);
