import { Col, Row } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { P } from "../text/Text";
import Widget from "./Widget";

/**
 *   const data = {
    all:all[0].all,
  }
  data.published = published.filter(pub => pub._id).pop().published
  data.draft = published.filter(pub => !pub._id).pop().published
  data.current = current.length
  data.past = data.all - current.length
 */
class EventOverviewTable extends Component {
	render() {
		const { metricState } = this.props;
		const { all, published, draft, current, past } = this.props.metrics ?? {};
		return (
			<Widget title="Event overview" metricState={metricState} {...this.props}>
				<Row className="tableRowBackground">
					<Col span={12}>
						<P style={{ textAlign: "left" }}>All Events Created:</P>
					</Col>
					<Col span={12}>
						<P style={{ textAlign: "right" }}>{all}</P>
					</Col>
				</Row>
				<Row className="tableRowBackground">
					<Col span={12}>
						<P style={{ textAlign: "left" }}>Published:</P>
					</Col>
					<Col span={12}>
						<P style={{ textAlign: "right" }}>{published}</P>
					</Col>
				</Row>
				<Row className="tableRowBackground">
					<Col span={12}>
						<P style={{ textAlign: "left" }}>Draft:</P>
					</Col>
					<Col span={12}>
						<P style={{ textAlign: "right" }}>{draft}</P>
					</Col>
				</Row>
				<hr />
				<Row className="tableRowBackground">
					<Col span={12}>
						<P style={{ textAlign: "left" }}>Current Live Events:</P>
					</Col>
					<Col span={12}>
						<P style={{ textAlign: "right" }}>{current}</P>
					</Col>
				</Row>
				<Row style={{ paddingBottom: 100 }} className="tableRowBackground">
					<Col span={12}>
						<P style={{ textAlign: "left" }}>Past Events:</P>
					</Col>
					<Col span={12}>
						<P style={{ textAlign: "right" }}>{past}</P>
					</Col>
				</Row>
			</Widget>
		);
	}
}

export default connect((state, ownProps) => ({
	metrics: state[ownProps.metricState].eventOverview
}))(EventOverviewTable);
