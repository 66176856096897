import { CopyButton } from "@/ui";
import styled from "@emotion/styled";
import { forwardRef, HTMLAttributes } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import highlightTheme from "react-syntax-highlighter/dist/esm/styles/hljs/sunburst";

highlightTheme.hljs = {
	background: "transparent"
};

highlightTheme["hljs-tag"] = {
	color: "#ab875d"
};

const Container = styled.div<CodeBlockProps>`
	background: #f5f4f4;
	border: 1px solid rgba(21, 20, 19, 0.31);
	border-radius: 16px;
	color: #000;
	display: block;
	position: relative;
	width: ${(props) => (props.fullWidth ? "100%" : "")};

	&:hover header {
		opacity: 1;
	}
`;

const Title = styled.h3`
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
	margin: 0;
`;

const Header = styled.header`
	align-items: center;
	background: #e0dfde;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	overflow: hidden;
`;

const CodeContainer = styled.div`
	padding: 16px 0 0 0;

	pre {
		margin: 0;
		overflow-x: auto;
		padding-bottom: 16px;
		padding-right: 16px;
	}
`;

type CodeBlockProps = HTMLAttributes<HTMLDivElement> & {
	codeString?: string;
	copyButtonAriaLabel?: string;
	fullWidth?: boolean;
	language?: "js" | "ts" | "htmlbars" | "plaintext";
	title?: string;
};

export const CodeBlock = forwardRef<HTMLDivElement, CodeBlockProps>(
	({ codeString, copyButtonAriaLabel, language, title, ...props }, ref) => {
		const codeText = codeString ?? "(num) => num + 1";

		return (
			<Container ref={ref} {...props}>
				<style>
					{`span.comment.linenumber.react-syntax-highlighter-line-number {
					background: #f5f4f4;
					left: 0;
					padding-left: 16px;
					position: sticky;
				}`}
				</style>
				<Header>
					<Title>{title ?? "JavaScript"}</Title>
					<CopyButton aria-label={copyButtonAriaLabel ?? "copy code"} toCopy={codeText} variant="ghost" />
				</Header>
				<CodeContainer>
					<SyntaxHighlighter language={language ?? "js"} showLineNumbers style={highlightTheme} wrapLines>
						{codeText}
					</SyntaxHighlighter>
				</CodeContainer>
			</Container>
		);
	}
);

/* If you need to customise the theme, these are the available options as edited at the top of the file:
{
    "hljs": {
        "background": "transparent"
    },
    "hljs-comment": {
        "color": "#777"
    },
    "hljs-quote": {
        "color": "#777"
    },
    "hljs-variable": {
        "color": "#ab875d"
    },
    "hljs-template-variable": {
        "color": "#ab875d"
    },
    "hljs-tag": {
        "color": "#ab875d"
    },
    "hljs-regexp": {
        "color": "#ab875d"
    },
    "hljs-meta": {
        "color": "#ab875d"
    },
    "hljs-number": {
        "color": "#ab875d"
    },
    "hljs-built_in": {
        "color": "#ab875d"
    },
    "hljs-builtin-name": {
        "color": "#ab875d"
    },
    "hljs-literal": {
        "color": "#ab875d"
    },
    "hljs-params": {
        "color": "#ab875d"
    },
    "hljs-symbol": {
        "color": "#ab875d"
    },
    "hljs-bullet": {
        "color": "#ab875d"
    },
    "hljs-link": {
        "color": "#ab875d"
    },
    "hljs-deletion": {
        "color": "#ab875d"
    },
    "hljs-section": {
        "color": "#9b869b"
    },
    "hljs-title": {
        "color": "#9b869b"
    },
    "hljs-name": {
        "color": "#9b869b"
    },
    "hljs-selector-id": {
        "color": "#9b869b"
    },
    "hljs-selector-class": {
        "color": "#9b869b"
    },
    "hljs-type": {
        "color": "#9b869b"
    },
    "hljs-attribute": {
        "color": "#9b869b"
    },
    "hljs-string": {
        "color": "#8f9c6c"
    },
    "hljs-keyword": {
        "color": "#8f9c6c"
    },
    "hljs-selector-tag": {
        "color": "#8f9c6c"
    },
    "hljs-addition": {
        "color": "#8f9c6c"
    },
    "hljs-emphasis": {
        "fontStyle": "italic"
    },
    "hljs-strong": {
        "fontWeight": "bold"
    }
}
*/
