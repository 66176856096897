import { Component } from "react";
import { Field } from "redux-form";
import { CheckboxField } from "@/components/Fields";
import { H3 } from "@/components/text/Text";
import AdditionalQuestion from "./additionalQuestion/AdditionalQuestion";

class TicketInfo extends Component {
	constructor() {
		super();
		this.toggleSameAsBuyer = this.toggleSameAsBuyer.bind(this);
	}

	componentDidUpdate(previousProps) {
		const lastBuyerInfo = previousProps.buyerData;
		const { buyerData, sameAsBuyerChecks, ticket } = this.props;
		const sameAsBuyer = sameAsBuyerChecks && sameAsBuyerChecks[ticket._id] ? sameAsBuyerChecks[ticket._id] : false;
		if (sameAsBuyer && JSON.stringify(buyerData) !== JSON.stringify(lastBuyerInfo)) {
			this.toggleSameAsBuyer(null, true);
		}
	}

	toggleSameAsBuyer(evt, value) {
		const { questions, buyerData, changeFieldValue, ticket } = this.props;
		questions.forEach((q) => {
			const fieldName = `tickets.${ticket._id}.${q._id}`;
			if (!value || !buyerData) {
				return;
			}
			switch (q.type) {
				case "firstName":
					changeFieldValue(fieldName, buyerData.firstName);
					break;
				case "lastName":
					changeFieldValue(fieldName, buyerData.lastName);
					break;
				case "mobile":
					changeFieldValue(fieldName, buyerData.mobile);
					break;
				case "email":
					changeFieldValue(fieldName, buyerData.email);
					break;
				default:
					break;
			}
		});
	}

	render() {
		const { sameAsBuyerChecks, ticket, questions, ticketsAdditionalQuestions, changeFieldValue } = this.props;
		const style = {
			marginBottom: 12
		};
		const askSameAsBuyer = questions.reduce((fieldMatch, q) => {
			if (q.type === "firstName" || q.type === "lastName" || q.type === "mobile" || q.type === "email") {
				fieldMatch = true;
			}
			return fieldMatch;
		}, false);
		const sameAsBuyer = sameAsBuyerChecks && sameAsBuyerChecks[ticket._id] ? sameAsBuyerChecks[ticket._id] : false;
		return (
			<div style={style}>
				<H3>{ticket.label}</H3>
				{askSameAsBuyer ? (
					<Field
						label="Same as buyer"
						type="checkbox"
						name={`sameAsBuyerChecks.${ticket._id}`}
						onChange={this.toggleSameAsBuyer}
						component={CheckboxField}
						inline
						labelAfter
					/>
				) : (
					false
				)}
				{questions.map((question) => {
					const disabled = !!(
						sameAsBuyer &&
						(question.type === "firstName" ||
							question.type === "lastName" ||
							question.type === "mobile" ||
							question.type === "email")
					);
					question.name = `tickets.${ticket._id}.${question._id}`;
					return (
						<AdditionalQuestion
							key={question._id}
							question={question}
							ticketId={ticket._id}
							disabled={disabled}
							ticketsAdditionalQuestions={ticketsAdditionalQuestions}
							changeFieldValue={changeFieldValue}
						/>
					);
				})}
			</div>
		);
	}
}
export default TicketInfo;
