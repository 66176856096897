import { InfoTooltip, InfoTooltipProps } from "@/ui";
import { Typography } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

const StyledText = styled(Typography.Text, {
	shouldForwardProp: (prop) => !["noMargin", "size"].includes(prop)
})<{
	noMargin: boolean;
	size: number;
}>`
	display: flex;
	font-size: ${(props) => props.size}px;
	font-weight: 700;
	margin-bottom: ${(props) => (props.noMargin ? "" : "8px")};
`;

type FormLabelProps = {
	children: ReactNode;
	noMargin?: boolean;
	placement?: InfoTooltipProps["placement"];
	required?: boolean;
	size?: number;
	tooltip?: InfoTooltipProps["title"];
};

export const FormLabel: FC<FormLabelProps> = ({
	children,
	noMargin = false,
	placement = "right",
	required = false,
	size = 14,
	tooltip
}) => {
	return (
		<StyledText noMargin={noMargin} size={size}>
			{required && <span style={{ color: "#BC2F35", marginRight: "4px" }}>*</span>}
			<span>{children}</span>
			{tooltip && <InfoTooltip placement={placement} title={tooltip} />}
		</StyledText>
	);
};
