import TrackingEvent, { Dict } from "./events/trackingEvent";

declare global {
	interface Window {
		dataLayer?: Record<string, any>[];
	}
}

/**
 * Used for sending various tracking events to analytics services.
 *
 * The initial analytics service this supports is Google Tag Manager (GTM), through the window's dataLayer.
 *
 * It can easily be extended to support additional analytics services by:
 * 1) Implementing a `.toTheNewServiceFormat` method in the `TrackingEvent` class that will transform the event data to a shape suitable for that service.
 * 2) Calling `event.toTheNewServiceFormat` in this class's `track` method, and sending the result to the service.
 *
 * New events should be added in the `/events` directory, and be exported from `/events/index.ts`
 * These events must extend the `TrackingEvent` class.
 *
 * Example usage:
 *
 * TrackingService.track(new UserActiveEvent('festival'));
 */
export default class TrackingService {
	public static track(event: TrackingEvent<Dict>): void {
		if (window.dataLayer) {
			const dataLayerProps = event.toDataLayerMessage();
			window.dataLayer.push(dataLayerProps);
		}
	}
}
