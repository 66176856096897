const Cross: Icon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 12 12">
			<g fill="none" fill-rule="evenodd">
				<path d="M-6-6h24v24H-6z" />
				<path fill="#67717E" d="M12 1.2L10.8 0 6 4.8 1.2 0 0 1.2 4.8 6 0 10.8 1.2 12 6 7.2l4.8 4.8 1.2-1.2L7.2 6z" />
			</g>
		</svg>
	);
};

export default Cross;
