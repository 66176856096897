import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField, InputNumberField } from "@/components/Fields";
import HelpButton from "@/components/HelpButton";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2 } from "@/components/text/Text";
import TemplateModal from "@/events/event/info/TemplateModal";
import PreviewPrice from "@/events/event/tickets/PreviewPrice";
import type { TicketType } from "@/state/reducers/eventReducer";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { Button } from "@/ui";
import { Spacer } from "@/ui";
import { Col, Flex, Modal, Row, Tabs } from "@/ui/antd";
import { HelpIds } from "@/utils/Help";
import { required } from "@/utils/Validators";
import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, FieldArray } from "redux-form";
import TicketsTable from "./TicketsTable";

const styles = {
	wrapper: (inline: boolean) =>
		css({
			h2: {
				display: inline ? "inline-block" : "block"
			}
		}),
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	})
};
const totalRequired = required("Total Event Capacity is required");

type ReduxInput = Partial<GlobalReduxState>;

type TicketsProps = {
	customCapacity?: boolean;
	changeFieldValue: (field: any, value: number | boolean | undefined) => void;
	ticketTypes?: TicketType[];
	secondaryTicketTypes?: TicketType[];
	touch: any;
	timezone: string;
	check: any;
	inline: boolean;
	inWizard: boolean;
	formName: string;
	dispatch: any;
	array: any;
	hasSecondary?: boolean;
} & ReduxInput;

const Tickets = ({
	customCapacity,
	changeFieldValue,
	ticketTypes,
	secondaryTicketTypes,
	currentEvent,
	touch,
	timezone,
	check,
	inline = true,
	inWizard,
	formName,
	dispatch,
	array,
	hasSecondary
}: TicketsProps) => {
	const [showModal, setShowModal] = useState(false);
	const [showPriceModal, setShowPriceModal] = useState(false);

	const showTemplateModal = () => setShowModal(true);
	const hideTemplateModal = () => setShowModal(false);
	const togglePriceModal = () => setShowPriceModal((current) => !current);

	useEffect(() => {
		if (ticketTypes && currentEvent) {
			calculateTotalCapacity();
		}
	}, [ticketTypes, currentEvent, customCapacity]);

	const toggleCustomCapacity = () => changeFieldValue("customCapacity", !customCapacity);

	const calculateTotalCapacity = () => {
		if (customCapacity) {
			return;
		}

		const ticketTypesToCalculateCapacity = ticketTypes?.concat(secondaryTicketTypes);

		const isEveryTicketWithMappedSeatsAlsoAutomatched = ticketTypesToCalculateCapacity?.every(
			(ticket: any) => Number(ticket.mappedSeats || 0) > 0 && ticket.isMappedToSeatingMap
		);

		let totalCapacity = 0;
		if (isEveryTicketWithMappedSeatsAlsoAutomatched) {
			totalCapacity =
				ticketTypesToCalculateCapacity?.reduce((total: number, ticket: any) => {
					if (!ticket.isDonation && !ticket.deleted && !ticket.isMappedToSeatingMap && ticket.quantity) {
						return total + Number(ticket.quantity);
					}
					return total;
				}, currentEvent?.event?.totalMappedSeats || 0) || 0;
		} else {
			totalCapacity =
				ticketTypesToCalculateCapacity?.reduce((total: number, ticket: any) => {
					if (!ticket.isDonation && !ticket.deleted && ticket.quantity) {
						return total + Number(ticket.quantity);
					}
					return total;
				}, 0) || 0;
		}
		changeFieldValue("totalCapacity", totalCapacity);
	};

	const handleTemplateClicked = (templateData: any) => {
		const { push } = array ?? {};
		templateData.forEach((t: TicketType) => push("ticketTypes", t));
		hideTemplateModal();
	};

	const packagedTickets = currentEvent?.event?.packagedTickets;
	const ticketGroups = currentEvent?.event?.ticketGroups ?? [];
	const location = currentEvent?.event?.location || "AU";

	const primaryTickets = (
		<>
			<Flex justify="flex-end">
				<Button aria-label="Import template" onClick={showTemplateModal} variant="ghost">
					Import template
				</Button>
			</Flex>
			<Spacer size="sm" />
			{check && (!ticketTypes || !ticketTypes.length) ? (
				<div className="has-error">
					<div className="ant-form-explain">Please create at least one ticket</div>
				</div>
			) : null}
			<div style={{ clear: "both" }} className="ticket-table">
				<FieldArray
					name="ticketTypes"
					component={TicketsTable}
					ticketTypes={ticketTypes}
					touch={touch}
					isFreeOnly={currentEvent?.event?.isFreeOnly}
					changeFieldValue={changeFieldValue}
					timezone={timezone}
					packagedTickets={packagedTickets}
					ticketGroups={ticketGroups}
					additionalQuestions={currentEvent?.event?.additionalQuestions}
					formName={formName}
					dispatch={dispatch}
					location={location}
					primaryTicketTypes={ticketTypes}
				/>
			</div>
		</>
	);

	const items = [
		{ label: "Primary Tickets", key: "primary-tickets", children: primaryTickets }, // remember to pass the key prop
		{
			label: "Secondary Tickets",
			key: "secondary-tickets",
			children: (
				<div style={{ clear: "both" }} className="ticket-table">
					<FieldArray
						name="secondaryTicketTypes"
						component={TicketsTable}
						ticketTypes={secondaryTicketTypes || []}
						touch={touch}
						isFreeOnly={currentEvent?.event?.isFreeOnly}
						changeFieldValue={changeFieldValue}
						timezone={timezone}
						packagedTickets={packagedTickets}
						ticketGroups={ticketGroups}
						additionalQuestions={currentEvent?.event?.additionalQuestions}
						formName={formName}
						dispatch={dispatch}
						location={location}
						isSecondary
						primaryTicketTypes={ticketTypes}
					/>
				</div>
			)
		}
	];
	return (
		<ContentBlock>
			<div className={styles.wrapper(inline)}>
				<H2>Tickets</H2>
				{inline ? <HelpButton buttonText="Help" title="Help - Images" link={HelpIds.ticketTypes} /> : null}
				{hasSecondary ? <Tabs items={items} /> : primaryTickets}

				<Row style={{ borderTop: "1px solid #eee", paddingTop: 20 }}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<LegacyButton
							icon={{ left: true, name: "preview" }}
							type="action"
							flavor="minimal"
							ariaLabel="Preview total prices"
							onClick={togglePriceModal}
						>
							Total prices for buyer
						</LegacyButton>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: "right" }}>
						<span style={{ display: "inline-block", fontWeight: 500 }}>
							<span className={styles.asterisk}>* </span> Total Event Capacity{" "}
							<span className={styles.required}> (required)</span>
						</span>

						<div style={{ display: "inline-block", width: 100 }}>
							<Field
								name="totalCapacity"
								style={{ display: "inline-block", margin: "-20px 0 0 10px" }}
								component={InputNumberField}
								noMargin
								validate={totalRequired}
								disabled={!customCapacity}
								onDrop={(e: any) => {
									e.preventDefault();
								}}
								withRef
								allowsNullValue={true}
							/>
						</div>

						<IconButton
							icon="edit"
							onClick={toggleCustomCapacity}
							style={{ display: "inline-block", verticalAlign: "middle" }}
							ariaLabel="Edit"
						/>
					</Col>
				</Row>
				<hr />
				{inWizard ? (
					<Field
						label="Collect full name for each attendee"
						id="defaultAdditionalQuestion"
						name="defaultAdditionalQuestion"
						component={CheckboxField}
						inline
						labelAfter
						tooltip="By default, only the ticket buyers name will be collected."
						tooltipIcon
					/>
				) : null}
				<TemplateModal
					onTemplateChosen={handleTemplateClicked}
					handleCancel={hideTemplateModal}
					visible={showModal}
					templateType="ticketTypes"
				/>
				<Modal
					open={showPriceModal}
					title="Ticket total price preview"
					destroyOnClose
					onCancel={togglePriceModal}
					footer={false}
				>
					<PreviewPrice />
				</Modal>
			</div>
		</ContentBlock>
	);
};

export default connect(
	(state: Partial<GlobalReduxState>) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(Tickets);
