import { css } from "@emotion/css";
import documentIcon from "../../assets/dashboard/icon-document.svg";
import { H3, H4 } from "../../components/text/Text";

export default function FeaturedHelpResource() {
	const container = css({
		marginTop: "0.375rem",
		marginBottom: "0.75rem",
		background: "#FFF4EF",
		boxShadow: "0 1px 10px rgba(9, 30, 66, 0.13)",
		borderRadius: "0.25rem",
		boxSizing: "border-box",
		padding: "1.5rem"
	});

	const questionsBodyContainer = css({
		display: "flex",
		marginTop: "1rem"
	});

	const helpLogoContainer = css({
		marginRight: "0.75rem"
	});

	const helpLogoBox = css({
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "40px",
		height: "40px",
		background: "#FFB18F",
		borderRadius: "3.2px"
	});

	const questionsBodyWrapper = css({
		display: "flex",
		flexDirection: "column"
	});

	const questionsParagraph = css({
		marginBottom: "0.5em"
	});

	return (
		<div className={container}>
			<H3>Questions? Find answers here</H3>
			<div className={questionsBodyContainer}>
				<div className={helpLogoContainer}>
					<div className={helpLogoBox}>
						<img src={documentIcon} alt="document" />
					</div>
				</div>
				<a href="https://humanitix.com/support">
					<div className={questionsBodyWrapper}>
						<H4 style={{ marginBottom: "0.125rem" }}>Help Center &rarr;</H4>
						<p className={questionsParagraph}>Browse our comprehensive tutorials, guides and tips</p>
					</div>
				</a>
			</div>
		</div>
	);
}
