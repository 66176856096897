import { GatewayEnabledWrapper, PaymentCard } from "@/events/event/payments/components/gateways/GatewayComponents";
import styled from "@emotion/styled";
import { FC } from "react";

const ImageContainer = styled.div`
	height: 70px;
	margin: 12px auto 12px auto;
`;

const ImageSubtitle = styled.p`
	text-align: center;
	font-size: 20px;
`;

type PaymentInfoProps = {
	description: JSX.Element;
	id: string;
	image: string;
	imageSubtitle?: string;
	name: string;
	selected: string;
};

const PaymentInfo: FC<PaymentInfoProps> = ({ children, description, id, image, imageSubtitle, name, selected }) => {
	return (
		<PaymentCard>
			<ImageContainer>
				<img alt={name} width="auto" height={imageSubtitle ? 30 : 50} src={image} />
				<ImageSubtitle>{imageSubtitle}</ImageSubtitle>
			</ImageContainer>
			<div>{description}</div>
			<div style={{ marginTop: "auto", width: "100%" }}>
				{selected === id ? (
					<GatewayEnabledWrapper>
						<div>{`${name} selected`}</div>
					</GatewayEnabledWrapper>
				) : null}
				{children}
			</div>
		</PaymentCard>
	);
};

export default PaymentInfo;
