function hardRefresh() {
	try {
		window.cache.delete().then(() => {
			window.location.reload(true);
		});
	} catch (err) {
		window.location.reload(true);
	}
}

export function findAncestor(el, cls) {
	while ((el = el.parentElement) && !el.classList.contains(cls));
	return el;
}

export function findPos(obj) {
	let top = 0;
	if (obj.offsetParent) {
		do {
			top += obj.offsetTop;
		} while ((obj = obj.offsetParent));
		return top;
	}
	return top;
}

export const focusFirstInput = (element) => {
	try {
		element.querySelector("input").focus();
	} catch (err) {
		return;
	}
};

export { hardRefresh };
