import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import sanityClient, { imageBuilder } from "../../clients/sanity";
import LoadErrorView from "../../components/LoadErrorView";
import { H2, P } from "../../components/text/Text";

interface SanityContent {
	image: {
		source: string;
		alt: string;
	};
	href: string;
	title: string;
	description: string;
	linkText: string;
}

const container = css({
	marginBottom: "0.75rem",
	background: "#FFF4EF",
	boxShadow: "0 1px 10px rgba(9, 30, 66, 0.13)",
	borderRadius: "0.25rem",
	boxSizing: "border-box",
	padding: "1.5rem"
});

const image = css({
	width: "100%",
	marginBottom: "0.5rem",
	borderRadius: "0.25rem"
});

const link = css({
	textDecorationLine: "underline",
	color: "#353337"
});

const DashboardArticle = ({ articleType }: { articleType: string }) => {
	const [loading, setLoading] = useState(false);
	const [content, setContent] = useState<SanityContent>();

	useEffect(() => {
		let isMounted = true;
		const fetch = async () => {
			const data = await sanityClient.fetch(
				`*[_type == "${articleType}"] | order(_createdAt desc) {
          image,
          title,
          description,
          href,
          linkText
        }[0]`
			);
			if (isMounted && data) {
				setContent(data);
				setLoading(false);
			}
		};

		setLoading(true);
		fetch().catch(console.error);
		return () => {
			isMounted = false;
		};
	}, []);

	const src = content?.image ? imageBuilder(content.image).width(542).height(306).url() : "";

	return (
		<LoadErrorView loading={loading} error={null}>
			{content && (
				<div className={container}>
					<a href={content.href}>
						<img className={image} alt={content.image.alt} src={src} />
					</a>
					<H2>{content.title}</H2>
					<P>{content.description}</P>
					<a className={link} href={content.href}>
						{content.linkText}
					</a>{" "}
					&rarr;
				</div>
			)}
		</LoadErrorView>
	);
};
export default DashboardArticle;
