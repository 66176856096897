import { Select } from "@/ui/antd";
import { useEffect, useState } from "react";
import { linkedInProfileTypes } from "../../utils/socials/linkedIn";

const { Option } = Select;

const LinkedInProfileTypes = ({
	key,
	selected,
	options = [linkedInProfileTypes.DEFAULT, linkedInProfileTypes.COMPANY, linkedInProfileTypes.SCHOOL],
	callback
}: {
	key: string;
	selected: string;
	options?: string[];
	callback: (input: string) => void;
}) => {
	const [current, setCurrent] = useState(selected);

	useEffect(() => {
		setCurrent(selected);
	}, [selected]);

	return (
		<Select
			key={key}
			value={current}
			onChange={(value) => {
				setCurrent(value);
				callback(value);
			}}
		>
			{options.map((option) => (
				<Option value={option}>{option}</Option>
			))}
		</Select>
	);
};

export default LinkedInProfileTypes;
