import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { LoadingSpinner } from "@/components/AppBlocks";
import { hidePreviewEmail } from "@/state/actions/emailCampaignsActions";

class PreviewModel extends Component {
	render() {
		const { hidePreviewEmail, emailCampaigns } = this.props;
		const { previewModeVisible, campaignPreview } = emailCampaigns;
		const html = campaignPreview.html;

		return (
			<Modal
				width={740}
				height={600}
				title="Preview"
				open={previewModeVisible}
				onCancel={hidePreviewEmail}
				footer={null}
			>
				{campaignPreview.loading ? (
					<div style={{ textAlign: "center" }}>
						<LoadingSpinner />
					</div>
				) : (
					<div style={{ margin: 10 }}>
						{/* TODO: This is all a bit dumb. It's not very safe and the preview picks up styles from the Console. We should put it in an iFrame at least */}
						{html && <div style={{ body: "#fff" }} dangerouslySetInnerHTML={{ __html: campaignPreview.html }} />}
					</div>
				)}
			</Modal>
		);
	}
}

export default connect(
	(state) => {
		return {
			emailCampaigns: state.emailCampaigns
		};
	},
	{ hidePreviewEmail }
)(PreviewModel);
