import { HumaniDate } from "@hx/dates";
import { Modal, Table, Tag } from "@/ui/antd";
import LocationService from "@/services/LocationService";

const statusConversions = {
	pending: {
		label: "Scheduled",
		color: "#f4ad42"
	},
	sending: {
		label: "Sending...",
		color: "#1ab87e"
	},
	sent: {
		label: "Sent",
		color: "#1ab87e"
	},
	"requires-approval": {
		label: "requires approval",
		color: "#ff0707c2"
	},
	failed: {
		label: "Failed",
		color: "#c0212c"
	}
};

const columns = [
	{
		title: "Status",
		dataIndex: "status",
		render: (value) => <Tag color={statusConversions[value].color}>{statusConversions[value].label}</Tag>
	},
	{
		title: "Event",
		dataIndex: "eventName",
		render: (_, queue) => {
			const event = queue.events?.find((e) => e._id === queue.eventId);
			return event ? event.name : "-";
		}
	},
	{
		title: "Event date",
		dataIndex: "",
		render: (_, queue) => {
			const eventDate = queue.events
				?.find((e) => e._id === queue.eventId)
				?.dates?.find((d) => d._id === queue.eventDateIds[0]);
			return eventDate?.startDate
				? new HumaniDate(eventDate?.startDate, "guess", LocationService.getLocation(true)).formatting.dateTime()
				: "-";
		}
	},
	{
		title: "Send At",
		dataIndex: "sendAt",

		render: (value) =>
			value ? new HumaniDate(value, "guess", LocationService.getLocation(true)).formatting.dateTime() : "-"
	},
	{
		title: "Sent At",
		dataIndex: "sentAt",
		render: (value) =>
			value ? new HumaniDate(value, "guess", LocationService.getLocation(true)).formatting.dateTime() : "-"
	},
	{
		title: "Emails sent",
		dataIndex: "emailsSent",
		render: (value, document) => (document.status === "sent" ? value : "-")
	}
];

const DetailsModal = ({ isOpen, closeAction, campaign, events }) => {
	return (
		<Modal width={740} height={600} title="Campaign details" open={isOpen} onCancel={closeAction} footer={null}>
			{campaign ? (
				<Table
					columns={columns}
					dataSource={campaign?.queues?.map((q) => ({ ...q, events }))}
					rowKey={(dataPoint) => dataPoint._id}
					scroll={{ x: 600 }}
				/>
			) : null}
		</Modal>
	);
};

export default DetailsModal;
