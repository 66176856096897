import { css } from "@emotion/css";
import { connect } from "react-redux";
import LinkButton from "../../components/buttons/LinkButton";
import { H2, H4, P } from "../../components/text/Text";
import { openTicketSaleStatusModal } from "../../state/actions/ticketSaleStatusActions";
import { max } from "../../utils/CssUtils";
import Widget from "./Widget";

const wrapperStyle = css({
	padding: "24px 20px",
	[max(768)]: {
		padding: "16px 12px"
	}
});

const titleStyle = css({
	display: "flex",
	justifyContent: "space-between",
	minWidth: "100%"
});

const contentStyle = css({
	display: "flex",
	justifyContent: "space-between",
	[max(768)]: {
		flexDirection: "column"
	}
});

const dynamicInfoStyle = css({
	width: "50%",
	paddingRight: 20,
	textAlign: "center",
	[max(768)]: {
		width: "100%",
		paddingRight: 0
	}
});

const numebrOfPeopleStyle = css({
	borderRadius: 4,
	backgroundColor: "#f9f9fa",
	padding: "12px 0px 5px 0px",
	p: {
		padding: 0,
		margin: "0px auto"
	}
});

const messageStyle = css({
	width: "50%",
	maxHeight: 66,
	borderLeft: "1px solid #dedede",
	padding: "14px 20px 5px",
	P: {
		marginBottom: 0
	},
	[max(768)]: {
		width: "100%",
		borderLeft: "none",
		borderTop: "1px solid #dedede",
		marginTop: 16,
		maxHeight: "auto",
		p: {
			width: "100%"
		}
	}
});

const WaitlistStatusCard = ({ event, openTicketSaleStatusModal, handleNavigationToWaitlist }) => {
	const { waitlistSettings, totalPeopleOnWaitlist } = event;
	const { releaseStrategy, enabled } = waitlistSettings || {};

	if (!totalPeopleOnWaitlist && !enabled) return null;

	const isAutoMode = releaseStrategy === "automatic";
	const statusColor = enabled ? "#157e77" : "#c0212c";

	const title = (
		<div className={titleStyle}>
			<H2>Waitlist status</H2>
			<div
				style={{
					display: "flex"
				}}
			>
				<span
					style={{
						backgroundColor: statusColor,
						height: 8,
						width: 8,
						borderRadius: "50%",
						marginRight: 4,
						marginTop: 5
					}}
				/>
				<P style={{ color: statusColor }}>Waitlist {enabled ? "on" : "off"}</P>
			</div>
		</div>
	);

	const dynamicSection = (
		<div className={dynamicInfoStyle}>
			<div className={numebrOfPeopleStyle}>
				<P>People waiting for tickets</P>
				<H4>{totalPeopleOnWaitlist}</H4>
			</div>

			<LinkButton
				onClick={openTicketSaleStatusModal}
				style={{
					marginTop: 16,
					marginBottom: 0,
					textDecoration: "underline",
					color: "#196097",
					textAlign: "center"
				}}
			>
				Check ticket status
			</LinkButton>
		</div>
	);

	const message = isAutoMode ? (
		<P>
			Your waitlist is in auto mode. People on the waitlist will be offered tickets automatically when there are tickets
			available.
		</P>
	) : (
		<div>
			<P marginBottom={0}>
				Your waitlist is in manual mode. Offer tickets manually to people on{" "}
				<LinkButton
					style={{ color: "#196097", textDecoration: "underline", paddingLeft: 0 }}
					onClick={handleNavigationToWaitlist}
				>
					the waitlist.
				</LinkButton>
			</P>
		</div>
	);

	return (
		<Widget style={{ padding: 0 }}>
			<div className={wrapperStyle}>
				{title}
				<div className={contentStyle}>
					{dynamicSection}
					<div className={messageStyle}>{message}</div>
				</div>
			</div>
		</Widget>
	);
};

export default connect(
	() => {
		return {};
	},
	{ openTicketSaleStatusModal }
)(WaitlistStatusCard);
