import {
	DELETE_EVENT_CANCEL,
	DELETE_EVENT_ERROR,
	DELETE_EVENT_REQUEST,
	DELETE_EVENT_SUCCESS
} from "../actions/eventActions";

const initialState = {
	loading: false,
	error: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case DELETE_EVENT_CANCEL:
			return initialState;
		case DELETE_EVENT_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case DELETE_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};

		case DELETE_EVENT_ERROR:
			return {
				loading: false,
				error: {
					title: "Error",
					message: action.error
				}
			};
		default:
			return state;
	}
};
