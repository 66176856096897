import { loadScripts } from "../../utils/Load";

//redactor
export const LOAD_REDACTOR_REQUEST = "LOAD_REDACTOR_REQUEST";
export const LOAD_REDACTOR_SUCCESS = "LOAD_REDACTOR_SUCCESS";
export const LOAD_REDACTOR_ERROR = "LOAD_REDACTOR_ERROR";

export const loadRedactor = () => {
	return (dispatch) => {
		dispatch({ type: LOAD_REDACTOR_REQUEST });
		loadScripts([
			`/frontend/redactor-3_1_5/redactor/redactor.min.js`,
			`/frontend/redactor-3_1_5/redactor/_plugins/table/table.min.js`,
			`/frontend/redactor-3_1_5/redactor/_plugins/fontcolor/fontcolor.min.js`,
			`/frontend/redactor-3_1_5/redactor/_plugins/video/video.min.js`,
			`/frontend/redactor-3_1_5/redactor/_plugins/fullscreen/fullscreen.min.js`,
			`/frontend/redactor-custom-plugins/addImage.js`,
			`/frontend/redactor-custom-plugins/clearFormating.js`,
			`/frontend/redactor-3_1_5/redactor/_plugins/handle/handle.js`
		])
			.then(() => {
				dispatch({ type: LOAD_REDACTOR_SUCCESS });
			})
			.catch(() => {
				dispatch({ type: LOAD_REDACTOR_ERROR });
			});
	};
};
