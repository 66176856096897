import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { SelectField, SwitchField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { constructFieldMapping } from "../utils/MapUtils";

const ButtonGroup = Button.Group;
const fieldRequired = required("This column needs to be mapped to a field or ignored.");

class MapFields extends Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0
		};
	}

	upload = ({ fields }) => {
		const props = this.props;
		props.onNextStep({ fields });
	};

	render() {
		const index = this.state.index;
		const { handleSubmit, fieldMapping, csv, currentEvent, examples } = this.props;
		const relevantAdditionalQuestions = currentEvent.event.additionalQuestions.filter(
			(addQ) => !addQ.disabled && !addQ.deleted
		);
		const additionalQuestions = relevantAdditionalQuestions.map((addQ) => {
			return {
				value: addQ._id,
				label: addQ.question
			};
		});
		const additionalQuestionLookup = relevantAdditionalQuestions.reduce((map, addQ) => {
			map[addQ._id] = addQ.question;
			return map;
		}, {});
		const headers = csv[0];
		const firstRow = examples[index];
		return (
			<div>
				<form onSubmit={handleSubmit(this.upload)}>
					<ContentBlock>
						<FieldArray
							name="fields"
							component={FieldRow}
							fieldMapping={fieldMapping || []}
							headers={headers}
							additionalQuestions={additionalQuestions}
							changeFieldValue={this.changeFieldValue}
						/>
						{fieldMapping ? (
							<div>
								<h2>Example</h2>
								<div
									style={{
										border: "1px",
										borderStyle: "solid",
										borderColor: "#dededede",
										padding: 16
									}}
								>
									{examples.length > 1 ? (
										<ButtonGroup style={{ float: "right" }}>
											<Button
												disabled={index === 0}
												onClick={() => this.setState({ index: index - 1 })}
												icon={<LeftOutlined />}
												ariaLabel="Back"
											/>
											<Button
												disabled={index === examples.length - 1}
												onClick={() => this.setState({ index: index + 1 })}
												icon={<RightOutlined />}
												ariaLabel="Forward"
											/>
										</ButtonGroup>
									) : null}
									{fieldMapping
										.filter((fieldMap) => !fieldMap.ignore)
										.map((fieldMap) => {
											let field, value;
											if (fieldMap.requiredHeader) {
												field = fieldMap.requiredHeader;
												value = firstRow[fieldMap.index];
											} else {
												field = additionalQuestionLookup[fieldMap.fieldId];
												value = firstRow[fieldMap.index];
											}
											return (
												<div style={{ display: "table" }}>
													<div style={{ float: "left" }}>
														<h3>{`${field || "Missing Field"}:`}</h3>
													</div>
													<div
														style={{
															float: "left",
															marginTop: 3,
															marginLeft: 8
														}}
													>
														{value}
													</div>
												</div>
											);
										})}
								</div>
							</div>
						) : null}
					</ContentBlock>
					<FormBar sticky={false}>
						<Button
							key="submit"
							type="primary"
							htmlType="submit"
							ariaLabel="Save"
							style={{
								float: "right"
							}}
						>
							Next
						</Button>
					</FormBar>
				</form>
			</div>
		);
	}
}

const FieldRow = ({ fieldMapping, headers, additionalQuestions }) => {
	const fieldStyle = css({
		width: "50%",
		"@media(max-width:800px)": {
			width: "auto",
			marginTop: 20,
			marginBottom: 20
		}
	});
	return fieldMapping.map((row, i) => {
		const disableChange = row.requiredHeader;
		const rowStyle = css({
			border: "1px",
			borderStyle: "solid",
			borderColor: "#dededede",
			borderRadius: 4,
			padding: 8,
			marginBottom: 8,
			background: disableChange ? "#dedede" : "white",
			display: "flex",
			flexWrap: "wrap",
			"@media(max-width:800px)": {
				display: "block"
			},
			justifyContent: "space-between"
		});
		return (
			<div className={rowStyle}>
				<div>
					<h3>Column {i + 1}</h3>
					<div>{headers[i]}</div>
				</div>
				<div className={fieldStyle}>
					<h3>Field</h3>
					<Field
						name={`fields[${i}].fieldId`}
						component={SelectField}
						options={additionalQuestions}
						disabled={disableChange}
						validate={!row.ignore ? fieldRequired : null}
					/>
				</div>
				<div>
					<Field
						label="Ignore column"
						name={`fields[${i}].ignore`}
						tooltip="Column wont be used in order generation"
						component={SwitchField}
						disabled={disableChange}
						style={{ float: "left" }}
					/>
				</div>
			</div>
		);
	});
};

const formName = "MapFields";
MapFields = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(MapFields);

const selector = formValueSelector(formName);

MapFields = connect((state, ownProps) => {
	const fieldMapping = constructFieldMapping(ownProps.csv, state.currentEvent.event.additionalQuestions);
	return {
		initialValues: {
			fields: fieldMapping
		},
		fieldMapping: selector(state, "fields")
	};
})(MapFields);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(MapFields);
