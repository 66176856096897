import { Field } from "redux-form";
import { Alert, Popconfirm, Table, Tooltip } from "@/ui/antd";
import { ColumnType } from "antd/lib/table";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { CheckboxField, InputNumberField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import { truncateString } from "@/utils/Format";
import P from "@/components/text/P";
import { css } from "@emotion/css";

type PackagedTicket = {
	_id: string;
	name: string;
	tickets: { quantity: number; ticketTypeId: string; name: string } & unknown[];
	mappedSeats: number;
	mappedTables: number;
	isMappedToSeatingMap: boolean;
	quantity: number;
	index: number;
} & any;

type BasicNumberMatch = { [key: string]: number };

interface ManagePackagedTicketsCapacityTableProps {
	packagedTickets: PackagedTicket[];
	handleAutoMatchingCapacities: (
		ticketOrPackageId: string,
		type: "TOGGLE_MATCH_AUTOMATICALLY_FOR_PACKAGED_TICKETS" | "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_PACKAGED_TICKETS"
	) => void;
	isLoadingOnSave: boolean;
	packageTicketColors: BasicNumberMatch;
}

const quantityRequired = required("Quantity is required");

const convertToHexString = (num: number): string => {
	return ("000000" + num.toString(16)).substr(-6);
};

const ManagePackagedTicketsCapacityTable = ({
	packagedTickets,
	handleAutoMatchingCapacities,
	isLoadingOnSave,
	packageTicketColors
}: ManagePackagedTicketsCapacityTableProps) => {
	const isAllMappedPackagesOnAutoMatch = packagedTickets
		.filter((t) => t.mappedTables)
		.every((ticket) => ticket.isMappedToSeatingMap);

	const columns: ColumnType<any>[] = [
		{
			title: "Type",
			dataIndex: "name",
			key: "name",
			width: 230,
			render: (_, { name, _id }: Pick<PackagedTicket, "name" | "_id">) => {
				const packageTicketColor = packageTicketColors[String(_id)];

				return (
					<div style={{ width: 230, display: "flex", alignItems: "center" }}>
						{packageTicketColor ? (
							<div
								style={{
									width: 14,
									height: 14,
									background: `#${convertToHexString(packageTicketColor)}`,
									marginRight: 8
								}}
							/>
						) : null}
						{name.length > 20 ? (
							<Tooltip title={name}>{truncateString(name, 20)}</Tooltip>
						) : (
							<P marginBottom={0}>{name}</P>
						)}
					</div>
				);
			}
		},
		{
			title: "Capacity",
			dataIndex: "quantity",
			key: "quantity",
			width: 130,
			render: (_, packageTicket: any) => {
				return (
					<div style={{ alignItems: "center" }}>
						<Field
							name={`packagedTickets[${packageTicket.index}].quantity`}
							dataCy={`packagedTickets[${packageTicket.index}].quantity`}
							component={InputNumberField}
							validate={quantityRequired}
							placeholder="Quantity"
							disabled={packageTicket.isMappedToSeatingMap}
							min={0}
							allowsNullValue={true}
						/>
					</div>
				);
			}
		},
		{
			title: "Tables mapped",
			dataIndex: "mappedTables",
			key: "mappedTables",
			width: 130,
			render: (_, { mappedTables, quantity }: Pick<PackagedTicket, "mappedTables" | "quantity">) => {
				let warningColor;
				if (mappedTables > quantity) {
					warningColor = "#C0212C";
				} else if (mappedTables < quantity) {
					warningColor = "#EAA200";
				}

				const warningDot = (
					<span
						style={{
							backgroundColor: warningColor,
							height: 8,
							width: 8,
							borderRadius: "50%",
							marginLeft: 4,
							marginTop: 6
						}}
					/>
				);

				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: 100,
							paddingLeft: 10
						}}
					>
						<div style={{ display: "flex" }}>
							<P style={{ color: warningColor }} marginBottom={0}>
								{mappedTables}
							</P>
							{warningColor && warningDot}
						</div>
					</div>
				);
			}
		},
		{
			title: "Auto match",
			dataIndex: "isMappedToSeatingMap",
			key: "isMappedToSeatingMap",
			width: 130,
			render: (_, { index, _id, tickets, isMappedToSeatingMap }: PackagedTicket) => {
				const commonStyle = css({
					display: "flex",
					flexDirection: "column",
					width: 130,
					paddingLeft: 10
				});

				const checkboxField = (
					<Field
						name={`packagedTickets[${index}].isMappedToSeatingMap`}
						dataCy={`packagedTickets[${index}].isMappedToSeatingMap`}
						component={CheckboxField}
						onChange={(e: any) => {
							if (isMappedToSeatingMap) {
								handleAutoMatchingCapacities(String(_id), "TOGGLE_MATCH_AUTOMATICALLY_FOR_PACKAGED_TICKETS");
							}
							e.preventDefault();
						}}
						validate={quantityRequired}
						placeholder="Quantity"
						allowsNullValue={true}
					/>
				);

				if (isMappedToSeatingMap) {
					return <div className={commonStyle}>{checkboxField}</div>;
				} else {
					return (
						<div className={commonStyle}>
							<Popconfirm
								title={
									<div style={{ display: "flex", flexDirection: "column", maxWidth: "300px", overflowY: "auto" }}>
										<P marginBottom={8}>
											This will also update the {tickets.length > 1 ? "capacities" : "capacity"} of{" "}
											{tickets.length > 1 ? "these" : "this"} <b>{tickets.length}</b> underlying ticket
											{tickets.length > 1 ? "s" : ""}:
										</P>
										<div style={{ marginBottom: 16 }}>
											{tickets.map((t: any, index: number) => (
												<P key={`underlying-ticket-names-${index}`} style={{ display: "inline" }}>
													<b>{t.name}</b>
													{index + 1 === tickets.length ? "" : ", "}
												</P>
											))}
										</div>
										<P marginBottom={5}>Do you wish to continue?</P>
									</div>
								}
								onConfirm={() =>
									handleAutoMatchingCapacities(String(_id), "TOGGLE_MATCH_AUTOMATICALLY_FOR_PACKAGED_TICKETS")
								}
								okText="Yes"
								cancelText="No"
							>
								<div style={{ height: 16 }}>{checkboxField}</div>
							</Popconfirm>
						</div>
					);
				}
			}
		}
	];
	return (
		<div style={{ minWidth: "100%", overflowX: "auto" }}>
			{isAllMappedPackagesOnAutoMatch ? null : (
				<Alert
					showIcon
					type="warning"
					message={
						<>
							Please note, If the number of seats you've mapped doesn't align with your capacity settings, we can adjust
							it for you. Selecting '<strong>Auto match</strong>' will set the new capacity based on the number of
							'Tables mapped'.
						</>
					}
				/>
			)}
			<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 24, marginBottom: 12 }}>
				<Field
					name="select_all_packaged_tickets"
					dataCy="select_all_packaged_tickets"
					component={CheckboxField}
					onChange={() => handleAutoMatchingCapacities("all", "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_PACKAGED_TICKETS")}
				/>
				<P style={{ marginLeft: 8 }} marginBottom={0}>
					Auto match all
				</P>
			</div>
			<Table
				columns={columns}
				dataSource={packagedTickets
					.map((p: PackagedTicket, index) => ({ ...p, index }))
					//note: filter should be after injecting index, as updates to the form rely on indexes.
					.filter((p) => p.mappedTables > 0)}
				rowKey="index"
				pagination={false}
				expandable={{
					expandRowByClick: true,
					expandIcon: ({ expanded }) => (expanded ? <DownOutlined ref={null} /> : <RightOutlined ref={null} />),
					expandedRowRender: (p: PackagedTicket) => (
						<div style={{ maxWidth: "100%" }}>
							{p.tickets.map((ticket: any, index: number) => {
								return (
									<P key={`package-manage-capacity-expandable-row-${index}`}>
										{ticket.quantity} X {truncateString(ticket.name, 69)}
									</P>
								);
							})}
						</div>
					)
				}}
				loading={isLoadingOnSave}
			/>
		</div>
	);
};

export default ManagePackagedTicketsCapacityTable;
