import { useViewport } from "@/hooks/useViewport";
import { FC, ReactNode } from "react";

type MobileOnlyProps = {
	children: ReactNode;
};

export const MobileOnly: FC<MobileOnlyProps> = ({ children }) => {
	const { isMobile } = useViewport();

	return (isMobile ? children : null) as JSX.Element;
};
