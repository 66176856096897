const RectangleTable = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0H24V24H0z" />
				<path
					id="ele-id-stroke"
					stroke="#67717E"
					strokeWidth="1.146"
					d="M14.75 19.1c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zm-5.5 0c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zM17.5 6.5v11h-11v-11h11zM3.2 13.05c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zm17.6 0c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zM3.2 7.55c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zm17.6 0c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zM9.25 1.5c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7zm5.5 0c.939 0 1.7.761 1.7 1.7s-.761 1.7-1.7 1.7-1.7-.761-1.7-1.7.761-1.7 1.7-1.7z"
				/>
			</g>
		</svg>
	);
};

export default RectangleTable;
