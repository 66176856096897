import type { MenuItem } from "../services/menuService/MenuService";
import SideMenu from "../components/SideMenu";
import SideMenuBg from "../components/SideMenuBg";
import { FC } from "react";

type ReportMenuProps = {
	menuItems: MenuItem[];
	title: string;
};

const ReportsMenu: FC<ReportMenuProps> = ({ menuItems, title = "" }) => {
	return (
		<SideMenuBg>
			<SideMenu title={title} menuItems={menuItems} />
		</SideMenuBg>
	);
};

export default ReportsMenu;
