import {
	GET_ORDER_REQ_ERROR,
	GET_ORDER_REQ_REQUEST,
	GET_ORDER_REQ_SUCCESS,
	GET_TICKETS_ERROR,
	GET_TICKETS_REQUEST,
	GET_TICKETS_SUCCESS,
	ORDER_CONTAINS_SEATING_MAP,
	SAVE_ORDER_DATA_ERROR,
	SAVE_ORDER_DATA_REQUEST,
	SAVE_ORDER_DATA_SUCCESS,
	TIME_EXPIRED
} from "../actions/newOrderActions";

import { GET_ORDER_SUCCESS } from "../actions/orderActions";

const initialState = {
	order: null,
	tickets: null,
	timeExpired: false,
	seatingMap: false,
	ticketsRequest: {
		loading: false,
		error: false
	},
	orderRequest: {
		loading: false,
		error: false
	},
	detailsRequest: {
		loading: false,
		error: false
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TIME_EXPIRED:
			return {
				...state,
				timeExpired: true
			};
		case GET_TICKETS_REQUEST:
			return {
				...state,
				tickets: [],
				timeExpired: false,
				ticketsRequest: {
					loading: true,
					error: false
				}
			};
		case GET_TICKETS_SUCCESS:
			return {
				...state,
				timeExpired: false,
				tickets: action.tickets.map((ticket) => {
					ticket.quantity = 0;
					return ticket;
				}),
				ticketsRequest: {
					loading: false,
					error: false
				}
			};
		case GET_TICKETS_ERROR:
			return {
				...state,
				tickets: [],
				ticketsRequest: {
					loading: false,
					error: action.error
				}
			};
		case GET_ORDER_REQ_REQUEST:
			return {
				...state,
				order: null,
				orderRequest: {
					loading: true,
					error: false
				}
			};
		case GET_ORDER_SUCCESS:
		case GET_ORDER_REQ_SUCCESS: {
			const order = action.order;
			// sort tickets
			order.tickets = order.tickets.sort((a, b) => a.ticketTypeName > b.ticketTypeName);
			order.tickets = order.tickets.map((t) => {
				t.label = `${t.number}. ${t.ticketTypeName}`;
				return t;
			});
			// label tickets
			return {
				...state,
				order,
				timeExpired: false,
				orderRequest: {
					loading: false,
					error: false
				}
			};
		}
		case GET_ORDER_REQ_ERROR:
			return {
				...state,
				order: null,
				orderRequest: {
					loading: false,
					error: action.error
				}
			};
		case SAVE_ORDER_DATA_REQUEST:
			return {
				...state,
				detailsRequest: {
					loading: true,
					error: false
				}
			};

		case ORDER_CONTAINS_SEATING_MAP:
			return {
				...state,
				seatingMap: action.seatingMapId
			};
		case SAVE_ORDER_DATA_SUCCESS:
			return {
				...state,
				order: action.order,
				detailsRequest: {
					loading: false,
					error: false
				}
			};
		case SAVE_ORDER_DATA_ERROR:
			return {
				...state,
				detailsRequest: {
					loading: false,
					error: action.error
				}
			};
		default:
			return state;
	}
};
