import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import P from "@/components/text/P";
import { hideTestEmail, sendTestEmail } from "@/state/actions/emailCampaignsActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { email, required } from "@/utils/Validators";

const formName = "test-email-campaign";

class TestEmailModel extends Component {
	constructor() {
		super();
		this.send = this.send.bind(this);
	}

	testToEmail = email("A must be a valid email address");
	testToRequired = required("An email address is required");

	send() {
		const { emailCampaigns, sendTestEmail } = this.props;
		sendTestEmail(emailCampaigns.testCampaign);
	}

	render() {
		const { hideTestEmail, handleSubmit, emailCampaigns, to } = this.props;
		const { testModalVisible } = emailCampaigns;
		const { testEmail } = emailCampaigns;
		return (
			<form onSubmit={handleSubmit(this.send)}>
				<Modal
					title="Send test email"
					open={testModalVisible}
					onCancel={hideTestEmail}
					footer={[
						<ButtonsBar footer>
							<LegacyButton key="cancel" onClick={hideTestEmail} htmlType="button" ariaLabel="Cancel">
								Cancel
							</LegacyButton>
							<LegacyButton
								onClick={handleSubmit(this.send)}
								disabled={testEmail.loading}
								key="submit"
								type="secondary"
								htmlType="submit"
								ariaLabel="Send"
							>
								Send
							</LegacyButton>
						</ButtonsBar>
					]}
				>
					<P>Send a test email to {to}</P>
				</Modal>
			</form>
		);
	}
}

// Decorate with redux-form
TestEmailModel = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(TestEmailModel);

export default connect(
	(state) => {
		return {
			to: state.auth.user?.email,
			emailCampaigns: state.emailCampaigns
		};
	},
	{ hideTestEmail, sendTestEmail }
)(TestEmailModel);
