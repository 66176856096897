import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../../components/AppBlocks";
import Breadcrumb from "../../components/Breadcrumb";
import LoadErrorView from "../../components/LoadErrorView";
import PageHeading from "../../components/PageHeading";
import Zapier from "../../components/Zapier";
import { getUser } from "../../state/actions/userActions";
import { HelpIds } from "../../utils/Help";

class ZapierIntegrations extends Component {
	constructor(props) {
		super(props);
		this.loadUser = this.loadUser.bind(this);
	}

	componentDidMount() {
		const { userId, getUser } = this.props;
		getUser(userId);
	}

	loadUser() {
		const { userId, getUser } = this.props;
		getUser(userId);
	}

	render() {
		const { currentUser } = this.props;
		return (
			<div>
				<Breadcrumb prevPath="/console/account/advanced/integrations" prevTitle="Integrations" currTitle="Zapier" />
				<PageHeading
					title="Zapier"
					helpButton={{
						link: HelpIds.zapier,
						title: "Help - Integrations"
					}}
				/>
				<LoadErrorView
					loading={!currentUser.user && currentUser.loading}
					error={currentUser.error}
					retryAction={() => this.loadUser()}
				>
					<ContentBlock>
						<Zapier />
					</ContentBlock>
				</LoadErrorView>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return { currentUser: state.currentUser };
	},
	{ getUser }
)(ZapierIntegrations);
