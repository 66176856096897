import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import { updateGateways } from "@/state/actions/gatewayActions";
import { GatewayOptions } from "./components/gateways/GatewayOptions";

class Gateways extends Component {
	save = (updateObj) => {
		const { currentEvent, updateGateways } = this.props;
		updateGateways("event", currentEvent.event._id, updateObj.paymentOptions);
	};

	render() {
		const { gateways, currentEvent } = this.props;
		const { loading, error } = gateways;
		return (
			<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
				<H1>Gateways</H1>
				<GatewayOptions
					type="event"
					id={currentEvent.event._id}
					save={this.save}
					orgPaymentOptions={currentEvent.event.paymentOptions}
					paymentOptionsStr="paymentOptions"
					location={currentEvent.event.location}
					paymentGatewayOptions={currentEvent.event.paymentGatewayOptions}
					invoicePayLinkAllowed={
						currentEvent.event.location === "AU" && currentEvent.event.settings.invoicePayLinkOptIn
					}
				/>
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		gateways: state.gateways
	}),
	{ updateGateways }
)(Gateways);
