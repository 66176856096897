// import { Button, Icon, Input } from "@/ui/antd";
import { locationDataByCountryCode } from "@hx/locations";
import { Input } from "@/ui/antd";
import { useEffect, useState } from "react";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { SearchOutlined } from "@ant-design/icons";

let autocomplete;

const LocationSearch = ({ hidden, address, googleMaps, changeFieldValue, currentLocation, currency }) => {
	const [focused, setFocused] = useState(false);
	const [internalValue, setValue] = useState("");
	const styles = hidden
		? {
				opacity: 0,
				height: 0,
				overflow: "hidden"
		  }
		: {
				marginBottom: 16
		  };

	useEffect(() => {
		createAutoComplete();
	}, []);

	const createAutoComplete = () => {
		if (googleMaps) {
			autocomplete = new googleMaps.places.Autocomplete(document.getElementById("addressSearch"), {
				location: {
					lat: -33.865143,
					lng: 151.2099
				}
			});
			autocomplete.addListener("place_changed", onPlaceChanged);
		} else {
			setTimeout(createAutoComplete, 300);
		}
	};

	const getLocationField = (place) => {
		const countryData = place.address_components.find((address) => {
			return address.types.find((type) => type === "country");
		});
		const stateData = place.address_components.find((address) => {
			return address.types.find((type) => type === "administrative_area_level_1");
		});
		const country = countryData.short_name;
		const isCountryWithStateAvailable = country === "AU" || country === "US";
		const state = stateData?.short_name;
		const location = country || "AU";
		const result = {
			location,
			state
		};
		if (!isCountryWithStateAvailable) {
			delete result.state;
		}
		return result;
	};

	const onPlaceChanged = () => {
		const place = autocomplete.getPlace();
		const { location, state } = getLocationField(place);
		if (place && place.geometry && place.geometry.location) {
			const latLng = [place.geometry.location.lat(), place.geometry.location.lng()];
			const eventLocation = {
				type: "address",
				venueName: place.name,
				address: place.formatted_address,
				latLng,
				addressComponents: place.address_components,
				placeId: place.place_id
			};
			if (!currentLocation) {
				changeFieldValue("location", location);
				changeFieldValue("state", state);
			}
			if (!currency) {
				changeFieldValue("currency", locationDataByCountryCode[location]);
			}
			changeFieldValue("eventLocation", eventLocation);
		}
	};

	const displayValue = focused ? internalValue : address;
	return (
		<div style={styles}>
			<InputLabel label="Address" id="addressSearch" required />
			<Input
				onFocus={() => {
					setFocused(true);
				}}
				onBlur={() => {
					setFocused(false);
				}}
				onKeyUp={(evt) => {
					setValue(evt.target.value);
				}}
				onChange={(evt) => {
					setValue(evt.target.value);
				}}
				id="addressSearch"
				placeholder="Search for a venue or address"
				autoComplete="nope"
				value={displayValue}
				suffix={<SearchOutlined />}
			/>
		</div>
	);
};

export default LocationSearch;
