import { State } from "@hx/seating-map";
import { Connectors } from "@hx/seating-map-toolbar";
import { Alert } from "@/ui/antd";
import { connect } from "react-redux";
import Icon from "@/components/Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3, P } from "@/components/text/Text";
import { moveIndividual, movePackage, unAssign } from "@/state/actions/seatingMapActions";
const { ConnectTicketManagement } = Connectors;
const { AssigningManager } = State.Assigning;

let AssigningPackage = (props) => {
	const { organiser, packageAssigningComplete, movePackage, ticketPackage } = props;
	const { displayName } = ticketPackage;
	const { error } = organiser;
	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row-reverse",
					padding: "13px 17px",
					cursor: "pointer"
				}}
				onClick={() => AssigningManager.reset()}
			>
				<P style={{ fontWeight: 600, marginBottom: 0, marginTop: 2 }}>Back</P>
				<Icon style={{ marginRight: 12 }} icon="left_small" />
			</div>
			{error ? <Alert type="error" message={error} /> : null}
			<div style={{ background: "white" }}>
				<div style={{ padding: "16px 12px" }}>
					<H3>Attendee info</H3>
					<P style={{ fontWeight: 600, marginBottom: 2 }}>{`${displayName}`}</P>
					{/* <P>{ticketTypeName}</P> */}
					{/* <SeatingInfo name={fromName} /> */}
				</div>
				<div style={{ height: 1, border: "solid 1px #dedede" }} />
				<div style={{ padding: "16px 12px" }}>
					<div style={{ width: "100%" }}>
						<LegacyButton
							onClick={movePackage}
							disabled={!packageAssigningComplete}
							type="secondary"
							style={{ width: "100%", marginBottom: 8 }}
						>
							Save
						</LegacyButton>
					</div>
					<div style={{ width: "100%" }}>
						<LegacyButton onClick={() => AssigningManager.reset()} style={{ width: "100%" }}>
							Cancel
						</LegacyButton>
					</div>
				</div>
			</div>
		</div>
	);
};

AssigningPackage = ConnectTicketManagement({
	ticketPackage: "package",
	packageAssigningComplete: "packageAssigningComplete"
})(AssigningPackage);

export default connect(
	(state) => ({
		organiser: state.seatingMap.organiser
	}),
	{ moveIndividual, unAssign, movePackage }
)(AssigningPackage);
