import { Modal } from "@/ui/antd";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField } from "../components/Fields";
import { required } from "../utils/Validators";

const reasonRequired = required("A reason is required");

let UserDisableModal = ({ open, toggle, handleSubmit, currentUser = {}, disableUser, enableUser }) => {
	const { user = {} } = currentUser;
	const firebaseEnabled = user && user.firebaseEnabled;
	const formSubmitHandler = handleSubmit((values) => {
		const { reason } = values;
		firebaseEnabled ? disableUser(user.id, reason) : enableUser(user.id, reason);
		toggle(false);
	});

	return (
		<Modal
			title={firebaseEnabled ? "Disable User" : "Enable User"}
			open={open}
			onCancel={() => toggle(false)}
			okText={firebaseEnabled ? "Disable" : "Enable"}
			onOk={formSubmitHandler}
			destroyOnClose={true}
		>
			<form onSubmit={formSubmitHandler}>
				<Field name="reason" label="Reason" component={LegacyInputField} required validate={reasonRequired} />
			</form>
		</Modal>
	);
};

UserDisableModal = reduxForm({
	form: "user-disable-modal",
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(UserDisableModal);

export default UserDisableModal;
