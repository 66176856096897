import { Field } from "redux-form";

import { ImageUploadField, RadioField, SelectField, SwitchField, TextAreaField } from "@/components/Fields";
import DateRangeFields from "@/components/fields/DateRangeFields";
import TicketDateRange from "../../TicketDateRange";
import TicketQuantity from "../../TicketQuantity";

import withViewport, { type IWithViewPortProps } from "@/hoc/withViewport";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";

interface ISettingsProps {
	hiddenOptions: any;
	timezone: string;
	touch: any;
	packageIndex: number;
	packagedTickets: any;
	ticketTypes: any;
	formName: string;
	fieldName: string;
	primaryPackages: any[];
}

type SettingsInput = ISettingsProps & IWithViewPortProps;

const Settings = ({
	hiddenOptions,
	timezone,
	touch,
	packageIndex,
	packagedTickets,
	ticketTypes,
	isMobile,
	formName,
	fieldName,
	primaryPackages
}: SettingsInput) => {
	const packagedTicket = packagedTickets[packageIndex];
	return (
		<div style={{ background: "#f9f9fa", padding: 12, marginBottom: 12 }}>
			<TicketQuantity
				touch={touch}
				packageQuantity
				minPath={`${fieldName}[${packageIndex}].minTickets`}
				maxPath={`${fieldName}[${packageIndex}].maxTickets`}
				multiplesPath={`${fieldName}[${packageIndex}].multiplesOf`}
			/>
			<TicketDateRange
				ticket={packagedTicket}
				index={packageIndex}
				touch={touch}
				timezone={timezone}
				ticketTypes={ticketTypes}
				packagedTickets={packagedTickets}
				path={fieldName}
				formName={formName}
			/>

			<Field
				label="Description"
				name={`${fieldName}[${packageIndex}].description`}
				component={TextAreaField}
				rows={2}
				placeholder="Package description"
			/>

			<Field
				label="Hidden"
				name={`${fieldName}[${packageIndex}].hiddenOptions.hidden`}
				component={SwitchField}
				description="Hide package, and decide who can see and purchase"
			/>
			{hiddenOptions && hiddenOptions.hidden ? (
				<Field
					name={`${fieldName}[${packageIndex}].hiddenOptions.when`}
					label="Hide when"
					component={RadioField}
					button={!isMobile}
					defaultValue="always"
					options={[
						{
							label: "Always",
							value: "always"
						},
						{
							label: "When not on sale",
							value: "notOnSale"
						},
						{
							label: "For a custom date range",
							value: "customDate"
						},
						{
							label: "When sold out",
							value: "soldOut"
						},
						...(packagedTicket.isSecondary
							? [
									{
										label: "Conditional on ticket/package selection",
										value: "conditional"
									}
							  ]
							: [])
					]}
				/>
			) : null}
			{hiddenOptions && hiddenOptions.hidden && hiddenOptions.when === "customDate" ? (
				<DateRangeFields
					touch={touch}
					startLabel="Hide From"
					endLabel="Hide Before"
					timezone={timezone}
					startField={`${fieldName}[${packageIndex}].hiddenOptions.startDate`}
					endField={`${fieldName}[${packageIndex}].hiddenOptions.endDate`}
					formName={formName}
				/>
			) : null}
			{packagedTicket.hiddenOptions?.hidden && packagedTicket.hiddenOptions?.when === "conditional" ? (
				<Field
					label="Show if"
					description="This package will only appear when at least one of these tickets/packages have been selected"
					name={`${fieldName}[${packageIndex}].hiddenOptions.ticketIds`}
					mode="multiple"
					component={SelectField}
					options={primaryPackages
						.concat(ticketTypes)
						.filter((tt) => !tt.deleted && !tt.isSecondary && !tt.isDonation)
						.map((tt) => ({
							value: tt._id,
							label: tt.name
						}))}
					placeholder="Show if"
					allowsNullValue
				/>
			) : null}
			<Field
				label="Sold out override"
				description="Package will appear as sold out disregarding the number of tickets sold"
				name={`${fieldName}[${packageIndex}].markedAsSoldOut`}
				component={SwitchField}
			/>

			<Field
				name={`${fieldName}[${packageIndex}].image`}
				label="Image"
				description={
					<>
						Use an image with a 2:1 ratio (e.g. 1200px by 600px).{" "}
						<a onClick={OpenHelpHandler(HelpIds.ticketImages)}>Learn more</a>
					</>
				}
				component={ImageUploadField}
				aspectRatio={2}
				imageStyleOverride={{
					width: "100%",
					maxHeight: "230px",
					margin: "auto",
					paddingTop: undefined,
					aspectRatio: "2"
				}}
			/>
		</div>
	);
};

export default withViewport(Settings);
