import { SelectField } from "@/components/Fields";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { PreviewCols } from "@/events/event/widgets/PreviewCols";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { findTags } from "@/state/actions/tagActions";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";

const Controls = ({ tags }) => {
	const { hasTagAccess } = useCurrentUser();
	tags = tags.map((tag) => {
		return { label: tag.name, value: tag._id };
	});
	return (
		<div>
			<P>A search widget that allows visitors to quickly find one of your events, right on your own website</P>

			{hasTagAccess ? (
				<Field
					label="Select tags you want to search via"
					name="selectTags"
					mode="multiple"
					component={SelectField}
					options={tags}
				/>
			) : null}
		</div>
	);
};

const Preview = ({ fullUrl }) => {
	const wrap = css`
		max-width: 320px;
		width: 100%;
		height: 375px;
		display: inline;
		padding: 0;
		overflow: hidden;
		@media (max-width: 640px) {
			overflow: scroll;
		}
	`;

	const center = css({
		width: "auto",
		margin: "0 auto"
	});

	const iframe = css`
		width: 1200px;
		height: 750px;
		text-align: center;
		border: 0;
		-ms-transform: scale(0.35);
		-moz-transform: scale(0.35);
		-o-transform: scale(0.35);
		-webkit-transform: scale(0.35);
		transform: scale(0.35);
		-ms-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		@media (max-width: 1078px) {
			margin-left: 0;
		}
	`;

	return (
		<div className={wrap}>
			<div className={center}>
				<iframe title="Events Search" src={fullUrl} frameBorder="0" className={iframe} />
			</div>
		</div>
	);
};

/**
 * Display event listing on widget
 *
 * @class EventListing
 * @extends {Component}
 */
class EventSearch extends Component {
	componentWillMount() {
		this.load();
	}
	load() {
		this.props.findTags({});
	}
	render() {
		const { tags, selectedTags, auth } = this.props;
		const userId = auth.user?.id;
		let fullUrl = `${getConfig("WEBSITE_URL")}/au/search?w=true&userid=${userId}`;
		if (selectedTags) {
			const tagsList = tags.tags.filter((tag) => {
				if (selectedTags.indexOf(tag._id) !== -1) {
					return true;
				}
				return false;
			});
			fullUrl += `&tags=${encodeURIComponent(
				JSON.stringify(tagsList.map((tag) => ({ _id: tag._id, name: tag.name })))
			)}&hidetags=true`;
		}
		const code = `<iframe id="iframe-container" src="${fullUrl}" width="100%" height="600px" frameborder="0"></iframe>
                    <script>
                        window.addEventListener('message', function (e) {
                            if (!e.origin.endsWith(".humanitix.com")){
                                return;
                            }     
                            var messageData = e.data;
                            var iframeEl = document.getElementById('iframe-container');
                            if (iframeEl && messageData && !isNaN(messageData.scrollHeight)){
                                iframeEl.style.height = messageData.scrollHeight + 'px';
                            }
                        }, false);
                    </script>
                    `;

		return (
			<div>
				<PreviewCols controls={<Controls tags={tags.tags} />} preview={<Preview fullUrl={fullUrl} />} code={code} />
			</div>
		);
	}
}

const formName = "widgetEventSearch";
const selector = formValueSelector(formName);
// Decorate with redux-form
EventSearch = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true
})(EventSearch);

export default connect(
	(state) => {
		return {
			auth: state.auth,
			tags: state.tags,
			currentEvent: state.currentEvent,
			selectedTags: selector(state, "selectTags")
		};
	},
	{ findTags }
)(EventSearch);
