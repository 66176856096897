import { Field } from "redux-form";
import { HumaniDate } from "@hx/dates";
import { LegacyInputField, InputNumberField, PasswordField, RadioField, SelectField } from "@/components/Fields";
import { H3, P } from "@/components/text/Text";
import LocationService from "@/services/LocationService";
import { getCountryOptions } from "@/utils/Countries";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { allowCharacters, checkLength, email, getValueFromObjString, onlyNumbers, required } from "@/utils/Validators";
import { Alert } from "@/ui/antd";

const MAX_NAME_LENGTH = 50;
const nameMaxLength = (value: string) =>
	value?.length > MAX_NAME_LENGTH ? `Must be ${MAX_NAME_LENGTH} characters or less` : undefined;
const nameRequired = required("Name is required (As shown on your income tax return)");
const addressRequired = required("Address is required");
const countryRequired = required("Country is required");
const cityRequired = required("City is required");
const emailRequired = required("Email is required to receive your 1099-K form");
const validEmail = email("Please enter a valid email");

const tinLength = checkLength("TIN must be 9 digits", 9, "==");
const tinCharacters = onlyNumbers("TIN must be numbers only");

export const fieldMatch = (msg: any, field: any) => (value: any, allValues: any) => {
	const originalValue = getValueFromObjString(field, allValues);
	return value === originalValue ? undefined : msg;
};
const tinTypeRequired = required("Must select a TIN type");
const tinMatch = fieldMatch(`Previous field must match`, "usTaxpayerInfo.tin");

const allowedCharacters = allowCharacters([
	"a",
	"b",
	"c",
	"d",
	"e",
	"f",
	"g",
	"h",
	"i",
	"j",
	"k",
	"l",
	"m",
	"n",
	"o",
	"p",
	"q",
	"r",
	"s",
	"t",
	"u",
	"v",
	"w",
	"x",
	"y",
	"z",
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
	"-",
	"_",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"0"
]);

const signatureRequired = required("Signature is required (Typing your name acts as your signature)");
const NOT_APPLICABLE = "Not applicable";

const taxNumberRequiredValidator = (_: any, formValues: any) => {
	if (formValues.usTaxpayerInfo.tin || formValues.usTaxpayerInfo.ftin) {
		return undefined;
	}
	return "Foreign tax identification number, social security number, or international tax identification number required";
};

const tinChecks = [taxNumberRequiredValidator, tinLength, tinCharacters];

export const W8BENForm = ({ formValues }: any) => {
	const formattedSignatureDate = new HumaniDate(
		formValues.signatureDate,
		"guess",
		LocationService.getLocation(true)
	).formatting.dateTime();
	return (
		<>
			<H3>IRS Substitute Form W-8BEN</H3>
			<P>This tax form is required by US tax law. Please note that we are unable to offer any tax advice.</P>
			<Alert
				showIcon
				message="Need help filling in the form?"
				description={
					<>
						See:
						<ul style={{ paddingLeft: 20, marginBottom: 0 }}>
							<li>
								Our <a onClick={OpenHelpHandler(HelpIds.irsW8Form)}>FAQ</a>
							</li>
							<li>
								<a href="https://www.irs.gov/pub/irs-pdf/iw8ben.pdf" target="_blank" rel="noreferrer">
									IRS Form W-8BEN Instructions
								</a>
							</li>
						</ul>
					</>
				}
				type="info"
				style={{ marginBottom: 32 }}
			/>

			<P>
				Please fill out the form in English. If you don't have a tax treaty benefit to claim, select "Not Applicable" as
				your country.
			</P>
			<br />
			<br />
			<H3>Identification of Beneficial Owner</H3>
			<Field
				name="usTaxpayerInfo.name"
				label="Name"
				required
				component={LegacyInputField}
				validate={[nameRequired, nameMaxLength]}
				maxLength={MAX_NAME_LENGTH}
				placeholder="As shown on your income tax return"
			/>
			<Field
				name="usTaxpayerInfo.country"
				label="Country"
				required
				component={SelectField}
				options={getCountryOptions().filter((option) => option.label !== "United States")}
				placeholder="Country of citizenship"
				validate={countryRequired}
			/>
			<Field
				name="usTaxpayerInfo.address.address"
				label="Address"
				placeholder="Street address"
				required
				validate={addressRequired}
				component={LegacyInputField}
			/>
			<Field
				label="Address 2"
				name="usTaxpayerInfo.address.addressTwo"
				component={LegacyInputField}
				placeholder="Apt, suite, unit, building, floor, etc"
			/>
			<Field
				label="City"
				name="usTaxpayerInfo.address.city"
				component={LegacyInputField}
				placeholder="City"
				required
				validate={[cityRequired]}
			/>

			<Field
				required
				name="usTaxpayerInfo.tinType"
				label="Taxpayer identification number"
				component={RadioField}
				button
				validate={tinTypeRequired}
				options={[
					{
						label: "SSN",
						value: "SSN"
					},
					{
						label: "ITIN",
						value: "ITIN"
					},
					{
						label: "FTIN",
						value: "FTIN"
					}
				]}
			/>
			{formValues.tinType === "SSN" || formValues.tinType === "ITIN" ? (
				<>
					<Field
						description={`Enter your ${
							formValues.tinType === "SSN"
								? "social security number (SSN)"
								: "international tax identification number (ITIN)"
						}`}
						name="usTaxpayerInfo.tin"
						component={PasswordField}
						placeholder={`Enter your ${formValues.tinType} number`}
						type="password"
						validate={tinChecks}
					/>

					<Field
						name="usTaxpayerInfo.tinCheck"
						component={PasswordField}
						validate={tinMatch}
						placeholder={`Enter your ${formValues.tinType} number again`}
						type="password"
						description={`We need to verify your ${formValues.tinType} number. Please enter it again.`}
						preventPaste
					/>
				</>
			) : null}

			{formValues.tinType === "FTIN" ? (
				<Field
					description="Enter your foreign tax identification number (FTIN)"
					name="usTaxpayerInfo.ftin"
					component={PasswordField}
					placeholder="Enter your foreign tax identifying number"
					validate={[taxNumberRequiredValidator]}
					type="password"
				/>
			) : null}

			<H3>Claim of Tax Treaty Benefits</H3>
			<Field
				name="usTaxpayerInfo.taxTreatyCountry"
				label="Country"
				validate={countryRequired}
				required
				component={SelectField}
				options={[{ value: NOT_APPLICABLE, label: NOT_APPLICABLE }, ...getCountryOptions()].filter(
					(option) => option.label !== "United States"
				)}
				placeholder="Country of citizenship"
			/>
			{formValues.taxTreatyCountry && formValues.taxTreatyCountry !== NOT_APPLICABLE ? (
				<>
					<Field
						name="usTaxpayerInfo.article"
						label="Article"
						required
						component={LegacyInputField}
						placeholder="Specific treaty article"
						validate={allowedCharacters}
					/>
					<Field
						name="usTaxpayerInfo.paragraph"
						label="Paragraph"
						required
						component={LegacyInputField}
						placeholder="Specific treaty paragraph"
						validate={allowedCharacters}
					/>
					<Field
						name="usTaxpayerInfo.witholdingRate"
						label="Witholding Rate"
						required
						component={InputNumberField}
						precision={2}
						min={0}
						max={100}
					/>
					<Field
						name="usTaxpayerInfo.incomeType"
						label="Type of income to apply the witholding"
						required
						component={LegacyInputField}
					/>
					<Field
						name="usTaxpayerInfo.additionalConditions"
						label="Additional conditions"
						description="Explain the additional conditions in the Article and paragraph the beneficial owner meets to be eligible for the rate of withholding:"
						component={LegacyInputField}
					/>
				</>
			) : null}
			<Field
				name="usTaxpayerInfo.email"
				label="Email"
				required
				component={LegacyInputField}
				validate={[emailRequired, validEmail]}
				maxLength={MAX_NAME_LENGTH}
				placeholder="Email address that you want to receive the 1099-K form"
			/>

			<div style={{ padding: 16, backgroundColor: "rgba(27, 27, 27, 0.06)", borderRadius: 4 }}>
				<P>
					Under penalties of perjury, I declare that I have examined the information on this form and to the best of my
					knowledge and belief it is true, correct, and complete. I further certify under penalties of perjury that:
				</P>
				<ol style={{ fontWeight: 600 }}>
					<li>
						I am the individual that is the beneficial owner (or am authorized to sign for the individual that is the
						beneficial owner) of all the income to which this form relates or am using this form to document myself for
						chapter 4 purposes,
					</li>
					<li>The person named on line 1 of this form is not a U.S. person,</li>
					<li>
						The income to which this form relates is: (a) not effectively connected with the conduct of a trade or
						business in the United States, (b) effectively connected but is not subject to tax under an applicable
						income tax treaty, or (c) the partner´s share of a partnership's effectively connected income,
					</li>
					<li>
						The person named on line 1 of this form is a resident of the treaty country listed on line 9 of the form (if
						any) within the meaning of the income tax treaty between the United States and the country, and
					</li>
					<li>
						For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in
						the instructions.
					</li>
				</ol>
				<P>
					Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or
					custody of the income of which I am the beneficial owner or any withholding agent that can disburse or make
					payments of the income of which I am the beneficial owner. I agree that I will submit a new form within 30
					days if any certification made on this form becomes incorrect. The Internal Revenue Service does not require
					your consent to any provisions of this document other than the certifications required to establish your
					status as a non-U.S. individual and, if applicable, obtain a reduced rate of withholding.
				</P>
				<Alert
					showIcon
					message="Note: Exemption from FATCA reporting code (if any) is not applicable"
					type="warning"
					style={{ marginBottom: 12 }}
				/>
				<Field
					name="usTaxpayerInfo.signature"
					label="Signature"
					required
					component={LegacyInputField}
					validate={[signatureRequired]}
					placeholder="Typing your name acts as your signature"
				/>
				<Field
					name="usTaxpayerInfo.capacity"
					label="Capacity in which acting (if form is not signed by beneficial owner)"
					component={LegacyInputField}
				/>
				{formValues.signatureDate ? (
					<P style={{ color: "#808080", marginTop: "-10px" }}>Signed on {formattedSignatureDate}</P>
				) : null}
			</div>
		</>
	);
};
