import styled from "@emotion/styled";
import { Radio } from "antd";
import { FC } from "react";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;

const OptionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	padding: 8px 16px;
	cursor: pointer;
`;

type CustomerTypeOptionsProps = {
	options: { label: string; value: string }[];
	onChange?: (value: string) => void;
	value?: string;
};

export const CustomerTypeOptions: FC<CustomerTypeOptionsProps> = ({ options = [], onChange, value }) => {
	return (
		<Wrapper>
			{options.map((option, idx) => {
				return (
					<OptionContainer key={`option-${idx}`} onClick={() => onChange?.(option.value)}>
						<span style={{ fontWeight: 600 }}>{option.label}</span>
						<Radio
							checked={value === option.value}
							style={{
								marginRight: "-4px"
							}}
							aria-label={option.label}
						/>
					</OptionContainer>
				);
			})}
		</Wrapper>
	);
};
