import { ContentBlock } from "@/components/AppBlocks";
import FeesInput from "@/components/FeesInput";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2 } from "@/components/text/Text";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import withSaveCheck from "@/hoc/withSaveCheck";
import { clearUser, getUser, saveUser } from "@/state/actions/userActions";
import { ReduxState } from "@/state/store";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { locationDataByCountryCode } from "@hx/locations";
import { Tabs } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";

/**
 * Note: In this file, Location refers to the react-router hook and selectedLocation refers to the location of the user (AU, US, etc.)
 */

type Fees = {
	_id: string;
	amexFee: number;
	donation: { _id: string; amount: number; type: "percent" };
	donationFees: { _id: string; fixed: number; percent: number };
	fixed: number;
	invoiceFees: { _id: string; enabled: false };
	paymentFeePercent: number;
	percent: number;
	rebates: { _id: string; excludedGateways: string[]; fixed: number; percent: number };
	type: "donation" | "notForProfit" | "charity" | "education" | "atCost";
};

type FeesFormProps = {
	change?: any;
	clearUser?: any;
	currentUser: any;
	fees: Fees;
	getUser: any;
	handleSubmit: any;
	loadUser: any;
	selectedLocation: string;
	saveUser: any;
	userId: string;
};

type FeesFormState = {
	editFees: boolean;
};

class FeesForm extends Component<FeesFormProps, FeesFormState> {
	constructor(props: FeesFormProps) {
		super(props);
		this.state = {
			editFees: false
		};
	}

	componentWillMount() {
		const { userId } = this.props;
		this.loadUser(userId);
	}

	loadUser(id: string) {
		const { getUser } = this.props;
		getUser(id);
	}

	saveUserFees = (values: { fees: Fees }) => {
		const { userId, saveUser, selectedLocation, currentUser } = this.props;
		saveUser(userId, {
			fees: { ...currentUser.user.fees, [selectedLocation]: values.fees }
		});
	};

	toggleFees = () => {
		const { editFees } = this.state;
		this.setState({ editFees: !editFees });
	};

	deleteFees = () => {
		const { userId, saveUser, selectedLocation, currentUser } = this.props;
		saveUser(userId, {
			fees: { ...currentUser.user.fees, [selectedLocation]: null }
		});
	};

	render() {
		const { currentUser, handleSubmit, fees, change, selectedLocation } = this.props;
		const { editFees } = this.state;

		const hasFees =
			currentUser.user &&
			currentUser.user.fees &&
			currentUser.user.fees[selectedLocation] &&
			!isNaN(currentUser.user.fees[selectedLocation].fixed);

		return (
			<LoadErrorView
				error={currentUser.error}
				loading={currentUser.loading}
				retryAction={() => this.loadUser(this.props.userId)}
			>
				<form onSubmit={handleSubmit(this.saveUserFees)}>
					<ContentBlock>
						{!hasFees ? (
							<LegacyButton
								ariaLabel={editFees ? "Cancel" : "Add Fees"}
								onClick={this.toggleFees}
								style={{ float: "right" }}
								type="action"
							>
								{editFees ? "Cancel" : "Add fees"}
							</LegacyButton>
						) : null}
						<H2>Fees</H2>

						{hasFees || editFees ? (
							<FeesInput currentEvent={null} fees={fees} change={change} location={selectedLocation} />
						) : null}
					</ContentBlock>
					<FormBar>
						{hasFees ? (
							<LegacyButton
								ariaLabel="Delete Fees"
								onClick={this.deleteFees}
								size="large"
								style={{ float: "left" }}
								type="warning"
							>
								Delete fees
							</LegacyButton>
						) : null}
						<LegacyButton ariaLabel="Save" htmlType="submit" size="large" style={{ float: "right" }} type="primary">
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "feesForm";
const FeesFormWithRedux = reduxForm({
	enableReinitialize: true,
	form: formName,
	onSubmitFail: () => scrollToErrors(),
	touchOnBlur: true,
	touchOnChange: true
})(FeesForm as any);

type ConnectedFeesFormProps = {
	form: string;
	selectedLocation: string;
	userId: string;
};

const ConnectedFeesForm = connect(
	(state: ReduxState, ownProps: ConnectedFeesFormProps) => {
		const form = getFormValues(ownProps.form)(state) as any;
		const userExistsAndHasFees = state.currentUser.user && state.currentUser.user.fees;

		return {
			currentUser: state.currentUser,
			initialValues: userExistsAndHasFees ? { fees: state.currentUser.user.fees[ownProps.selectedLocation] } : {},
			fees: form ? form.fees : {}
		};
	},
	{ getUser, clearUser, saveUser }
)(withSaveCheck(withRouterHooks(FeesFormWithRedux as any), formName));

type FeesFormWrapperProps = {
	userId: string;
};

type FeesFormWrapperState = {
	tabSelection: string;
};

class FeesFormWrapper extends Component<FeesFormWrapperProps, FeesFormWrapperState> {
	constructor(props: FeesFormWrapperProps) {
		super(props);
		this.state = {
			tabSelection: "AU"
		};
	}

	render() {
		const { tabSelection } = this.state;

		return (
			<div>
				<Tabs
					defaultActiveKey="AU"
					onChange={(newSelection) => this.setState({ tabSelection: newSelection })}
					items={Object.values(locationDataByCountryCode).map((option) => {
						return {
							children: (
								<ConnectedFeesForm
									key={option.location}
									form={`fees-${option.location}`}
									selectedLocation={tabSelection}
									userId={this.props.userId}
								/>
							),
							key: option.location,
							label: option.country
						};
					})}
				/>
			</div>
		);
	}
}
export default FeesFormWrapper;
