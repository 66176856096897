import { Select } from "@/ui/antd";
import { Component } from "react";
import { change } from "redux-form";
import InputWrapper from "./helpers/InputWrapper";

class MultipleSelectField extends Component {
	constructor() {
		super();
		this.changeValue = this.changeValue.bind(this);
	}
	changeValue(selectedValues) {
		const { meta, input, options } = this.props;
		const lastElement = selectedValues.pop();

		if (typeof lastElement === "number") {
			const option = options[lastElement];
			if (selectedValues.indexOf(option.value) === -1) {
				selectedValues.push(option.value);
			}
		} else {
			selectedValues.push(lastElement);
		}
		meta.dispatch(change(meta.form, input.name, selectedValues.join("{|}")));
	}
	onDeselect = (deSelectedValue) => {
		const { meta, input } = this.props;
		let value =
			input.value && input.value.indexOf("{|}") !== -1 ? input.value.split("{|}") : input.value ? input.value : [];
		value.splice(value.indexOf(deSelectedValue), 1);
		meta.dispatch(change(meta.form, input.name, value.join("{|}")));
		return;
	};
	render() {
		const {
			input,
			label,
			description,
			required,
			inline,
			placeholder,
			options,
			style,
			trackQuantity,
			tooltip,
			tooltipIcon = false,
			tooltipIconOnClick,
			meta: { touched, error }
		} = this.props;
		const value =
			input.value && input.value.indexOf("{|}") !== -1 ? input.value.split("{|}") : input.value ? input.value : [];
		return (
			<InputWrapper
				label={label}
				description={description}
				touched={touched}
				error={error}
				id={input.name}
				inline={inline}
				required={required}
				tooltip={tooltip}
				tooltipIcon={tooltipIcon}
				tooltipIconOnClick={tooltipIconOnClick}
			>
				<input type="hidden" id={input.name} {...input} value={value} />
				<Select
					placeholder={placeholder}
					popupMatchSelectWidth={false}
					mode="multiple"
					value={value}
					style={style}
					onChange={this.changeValue}
					options={options.map((option, index) => {
						const label = option.label ? option.label : option.value;
						const quantityLeft = option.quantityLeft || 0;
						const disabled = !(!trackQuantity || quantityLeft > 0);

						return {
							value: index,
							disabled: disabled,
							label: (
								<>
									{label} {quantityLeft > 0 && quantityLeft < 11 && <span>({quantityLeft} available)</span>}
									{trackQuantity && disabled && <span>(Not available)</span>}
								</>
							)
						};
					})}
				/>
			</InputWrapper>
		);
	}
}

export default MultipleSelectField;
