import EmailAttachment from "../EmailAttachment";
import InputWrapper from "./helpers/InputWrapper";

const EmailAttachmentField = (props) => {
	const {
		input,
		label,
		description,
		inline,
		required,
		touched,
		error,
		attachments,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		...rest
	} = props;
	return (
		<InputWrapper
			label={label}
			description={description}
			inline={inline}
			touched={touched}
			error={error}
			id={input.name}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<EmailAttachment {...input} {...rest} attachments={attachments} />
		</InputWrapper>
	);
};

export default EmailAttachmentField;
