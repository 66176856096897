import { Alert, Button } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { ReportFilters } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getFeesDetailsReport,
	getFeesDetailsReportCsv,
	resetFeesDetailsReport
} from "@/state/actions/adminReports/financeActions";
import DatesSelector from "../../DatesSelector";
import DayEndsAt from "../DayEndsAt";
import FundLocationFilters from "../FundLocationFilters";

class FeesDetailsReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}

	load(values) {
		if (!values.startDate || !values.endDate) {
			return;
		}
		const { getFeesDetailsReportCsv } = this.props;
		getFeesDetailsReportCsv(values.startDate, values.endDate, values.dayEndsAt, values.fundsLocation);
	}
	retry() {
		const { resetFeesDetailsReport } = this.props;
		resetFeesDetailsReport();
	}

	downloadCsv() {
		const { startDate, endDate, getFeesDetailsReportCsv, dayEndsAt, fundsLocation } = this.props;
		if (!startDate || !endDate) {
			return;
		}
		getFeesDetailsReportCsv(startDate, endDate, dayEndsAt, fundsLocation);
	}

	render() {
		const { startDate, endDate, touch, handleSubmit, feesDetailsReport, feesDetailsCsvReport } = this.props;
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Fees Details Report">
					<LocationSelection componentName="adminReportsFinance" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<LoadErrorView
					loading={feesDetailsReport.loading || feesDetailsCsvReport.loading}
					error={feesDetailsReport.error || feesDetailsCsvReport.error}
					retryAction={this.retry}
				>
					<ReportFilters>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<DatesSelector touch={touch} changeFieldValue={this.changeFieldValue} />
								<DayEndsAt />
							</div>
						</div>
						<FundLocationFilters />
						<Button type="primary" onClick={this.downloadCsv} disabled={!startDate || !endDate} ariaLabel="Export">
							Export
						</Button>
						<Alert
							style={{ margin: "12px 0" }}
							message={
								<table style={{ width: "100%" }}>
									<tr>
										<th>
											<b>Fees</b>
										</th>
										<td>The total gross fees</td>
									</tr>
									<tr>
										<th>
											<b>Refunds</b>
										</th>
										<td>The total amount of refunded fees</td>
									</tr>
									<tr>
										<th>
											<b>Amex</b>
										</th>
										<td>The net amex fee, amex fees are included in the fees total</td>
									</tr>
									<tr>
										<th>
											<b>Rebates</b>
										</th>
										<td>The net amount of rebates</td>
									</tr>
									<tr>
										<th>
											<b>Taxes</b>
										</th>
										<td>The net amount of tax on our booking fees</td>
									</tr>
									<tr>
										<th>
											<b>Total</b>
										</th>
										<td>Fees - ( Refunds + Rebates )</td>
									</tr>
								</table>
							}
							type="info"
						/>
					</ReportFilters>
				</LoadErrorView>
			</form>
		);
	}
}
const formName = "feesDetailsReport";
const selector = formValueSelector(formName);
FeesDetailsReport = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(FeesDetailsReport);

export default connect(
	(state) => ({
		initialValues: {
			fundsLocation: "all",
			dayEndsAt: "midnight",
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate
		},
		feesDetailsCsvReport: state.adminReports.finance.feesDetailsCsvReport,
		feesDetailsReport: state.adminReports.finance.feesDetailsReport,
		startDate: selector(state, "startDate"),
		endDate: selector(state, "endDate"),
		fundsLocation: selector(state, "fundsLocation"),
		dayEndsAt: selector(state, "dayEndsAt")
	}),
	{ getFeesDetailsReport, getFeesDetailsReportCsv, resetFeesDetailsReport }
)(FeesDetailsReport);
