import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import csvExampleFile from "@/assets/csv/access-codes-example.csv";
import {
	FileUploadField,
	LegacyInputField,
	InputNumberField,
	RadioField,
	SelectField,
	SwitchField
} from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import FileService from "@/services/FileService";
import { saveAccessCodes } from "@/state/actions/accessCodesActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { getTicketDisplayName } from "@/utils/Ticket";
import { required, restrictCharacters } from "@/utils/Validators";

const codeRequired = required("Code required");
const appliesToRequired = required("Applies to required");
const percentRestricted = restrictCharacters(["%"]);
class AccessCodeModal extends Component {
	save = (values) => {
		const { saveAccessCodes, currentEvent } = this.props;
		saveAccessCodes(currentEvent.event._id, values);
	};

	downloadFile = () => {
		FileService.downloadFile(csvExampleFile, "access-codes-example.csv");
	};

	render() {
		const { currentEvent, accessCodes, type, visible, handleCancel, handleSubmit } = this.props;
		const packagedTickets = currentEvent.event.packagedTickets
			? currentEvent.event.packagedTickets.filter((pt) => !pt.isSecondary)
			: [];
		const tickets = currentEvent.event.ticketTypes.filter((tt) => !tt.isSecondary).concat(packagedTickets);
		const ticketOptions = [{ value: "all", label: "All ticket types" }].concat(
			tickets
				.filter((t) => !t.deleted)
				.map((ticket) => {
					return { value: ticket._id, label: getTicketDisplayName(ticket) };
				})
		);
		return (
			<Modal
				open={visible}
				title="Access Code"
				destroyOnClose
				onCancel={handleCancel}
				footer={
					<ButtonsBar footer>
						<LegacyButton
							key="cancel"
							onClick={handleCancel}
							htmlType="button"
							disabled={accessCodes.saveRequest.loading}
							ariaLabel="Cancel"
						>
							Cancel
						</LegacyButton>
						<LegacyButton
							onClick={handleSubmit(this.save)}
							key="submit"
							type="primary"
							disabled={accessCodes.saveRequest.loading}
							htmlType="submit"
							ariaLabel="Save"
						>
							Save
						</LegacyButton>
					</ButtonsBar>
				}
			>
				<form onSubmit={handleSubmit(this.save)}>
					<LoadErrorView loading={accessCodes.saveRequest.loading} retryAction={this.save}>
						{!accessCodes.editingCode ? (
							<Field
								name="type"
								label="Code type"
								component={RadioField}
								button
								options={[
									{
										label: "Single Code",
										value: "single"
									},
									{
										label: "CSV Upload",
										value: "upload"
									}
								]}
							/>
						) : null}
						{type === "single" || accessCodes.editingCode ? (
							<div>
								<Field
									name="code"
									placeholder="Code"
									label="Code (% not allowed)"
									required
									component={LegacyInputField}
									validate={[codeRequired, percentRestricted]}
									style={{ marginBottom: 24 }}
								/>
								<Field
									name="quantity"
									placeholder="No limit"
									label="Quantity (leave blank for unlimited)"
									component={InputNumberField}
									allowsNullValue
									min={1}
									style={{ marginBottom: 24 }}
								/>
							</div>
						) : (
							<div>
								<div style={{ marginBottom: 24 }}>
									CSV example file: {/*eslint-disable-next-line*/}
									<a download="access-codes-example" onClick={this.downloadFile}>
										Download
									</a>
								</div>
								<Field
									name="csvFile"
									label="Upload a CSV"
									description="File must be a csv or txt file containing one code per line, duplicate codes will be ignored"
									component={FileUploadField}
									validate={codeRequired}
									style={{ marginBottom: 24 }}
								/>
							</div>
						)}
						<Field
							mode="multiple"
							name="appliesTo"
							label="Applies to"
							placeholder="Please select"
							required
							component={SelectField}
							options={ticketOptions}
							style={{ marginBottom: 24 }}
							validate={appliesToRequired}
						/>
						<Field name="enabled" label="Enabled" component={SwitchField} style={{ marginBottom: 24 }} />
					</LoadErrorView>
				</form>
			</Modal>
		);
	}
}
const formName = "accessCode";
AccessCodeModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(AccessCodeModal);

const selector = formValueSelector(formName);
export default connect(
	(state) => {
		const initialValues = state.accessCodes.editingCode
			? state.accessCodes.editingCode
			: {
					eventId: state.currentEvent.event._id,
					enabled: true,
					type: "single"
			  };
		return {
			initialValues,
			currentEvent: state.currentEvent,
			accessCodes: state.accessCodes,
			type: selector(state, "type")
		};
	},
	{ saveAccessCodes }
)(AccessCodeModal);
