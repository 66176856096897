import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Alert as AntdAlert, AlertProps as AntdAlertProps } from "antd";
import { AlertRef } from "antd/lib/alert/Alert";
import { FC, useEffect, useRef, useState } from "react";

type AlertProps = AntdAlertProps & {
	collapsed?: boolean;
};

const StyledAlert = styled(AntdAlert)<{ maxHeight: string; collapsed: boolean; type: string }>`
	${({ collapsed, type, maxHeight }) => css`
		background: var(--${type}-subtle);
		border-radius: var(--rounded-md);
		border: none;

		/* Animation */
		filter: ${collapsed ? "blur(5px)" : "blur(0)"};
		max-height: ${collapsed ? "0" : maxHeight};
		opacity: ${collapsed ? 0 : 1};
		overflow: hidden;
		padding: ${collapsed ? "0 var(--spacing-md)" : "var(--spacing-md)"};
		transition: all 0.3s ease-in-out;

		.ant-alert-icon {
			color: var(--${type});
		}

		.ant-alert-message {
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 0;
		}

		.ant-alert-icon {
			align-self: flex-start;
			font-size: 14px;
			margin-top: var(--spacing-xxs);
			margin-inline-end: var(--spacing-sm);

			svg {
				height: 18px;
			}
		}
	`}
`;

export const Alert: FC<AlertProps> = ({ collapsed = false, type = "info", ...props }) => {
	const [maxHeight, setMaxHeight] = useState<string>("0");
	const alertRef = useRef<AlertRef & { scrollHeight: number }>(null);

	useEffect(() => {
		if (alertRef.current) {
			setMaxHeight(`${alertRef.current.scrollHeight}px`);
		}
	}, [collapsed, props.children]);

	return <StyledAlert ref={alertRef} {...props} collapsed={collapsed} maxHeight={maxHeight} type={type} />;
};
