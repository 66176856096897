import {
	CREATE_SCHEDULE_ERROR,
	CREATE_SCHEDULE_REQUEST,
	CREATE_SCHEDULE_SUCCESS,
	DELETE_SCHEDULE_ERROR,
	DELETE_SCHEDULE_REQUEST,
	DELETE_SCHEDULE_SUCCESS,
	LOAD_DATES_ERROR,
	LOAD_DATES_REQUEST,
	LOAD_DATES_SUCCESS,
	RESCHEDULE_SUCCESS,
	RESET_SCHEDULE_RESULT
} from "../actions/schedulerActions";

const initialState = {
	dates: [],
	error: false,
	loading: false,
	deleteResult: {
		numberDeleted: 0,
		unsuccessfulDates: []
	},
	rescheduleResult: {
		changedDatesWithOrders: [],
		numberOfChangedDates: 0
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case RESCHEDULE_SUCCESS:
			return {
				...state,
				rescheduleResult: {
					changedDatesWithOrders: action.changedDatesWithOrders,
					numberOfChangedDates: action.numberOfChangedDates
				}
			};
		case CREATE_SCHEDULE_REQUEST:
			return {
				...state,
				loading: true
			};
		case CREATE_SCHEDULE_SUCCESS:
			return {
				...state,
				loading: false
			};
		case CREATE_SCHEDULE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case LOAD_DATES_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case LOAD_DATES_REQUEST:
			return {
				...state,
				loading: true
			};
		case LOAD_DATES_SUCCESS:
			return {
				...state,
				dates: action.dates,
				loading: false
			};
		case DELETE_SCHEDULE_REQUEST:
			return {
				...state,
				loading: true
			};
		case DELETE_SCHEDULE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case DELETE_SCHEDULE_SUCCESS:
			return {
				...state,
				loading: false,
				deleteResult: {
					numberDeleted: action.numberDeleted,
					unsuccessfulDates: action.unsuccessfulDates
				}
			};
		case RESET_SCHEDULE_RESULT:
			return {
				...state,
				deleteResult: {
					numberDeleted: 0,
					unsuccessfulDates: []
				},
				rescheduleResult: {
					changedDatesWithOrders: [],
					numberOfChangedDates: 0
				}
			};
		default:
			return state;
	}
};
