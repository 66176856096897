import {
	GET_SEATING_MAPS_ERROR,
	GET_SEATING_MAPS_REQUEST,
	GET_SEATING_MAPS_SUCCESS,
	GET_SEATING_MAP_ERROR,
	GET_SEATING_MAP_REQUEST,
	GET_SEATING_MAP_SUCCESS,
	SAVE_TICKET_MAPPING_CONFLICT,
	SAVE_TICKET_MAPPING_ERROR,
	SAVE_TICKET_MAPPING_REQUEST,
	SAVE_TICKET_MAPPING_SUCCESS
} from "../actions/seatingMapTicketActions";

const initialState = {
	selectedSeatingMap: false,
	seatingMaps: [],
	loading: false,
	error: false,
	orderContainsMap: false,
	save: {
		loading: false,
		error: false,
		conflicts: []
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SEATING_MAP_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_SEATING_MAP_SUCCESS:
			return {
				...state,
				orderContainsMap: true,
				loading: false,
				error: false,
				selectedSeatingMap: action.seatingMap
			};
		case GET_SEATING_MAP_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case GET_SEATING_MAPS_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case GET_SEATING_MAPS_SUCCESS:
			return {
				...state,
				orderContainsMap: true,
				loading: false,
				error: false,
				seatingMaps: action.seatingMaps
			};
		case GET_SEATING_MAPS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case SAVE_TICKET_MAPPING_REQUEST:
			return {
				...state,
				save: {
					loading: true,
					error: false,
					conflicts: []
				}
			};
		case SAVE_TICKET_MAPPING_SUCCESS:
			return {
				...state,
				save: {
					loading: false,
					error: false,
					conflicts: []
				}
			};
		case SAVE_TICKET_MAPPING_CONFLICT:
			return {
				...state,
				save: {
					loading: false,
					error: false,
					conflicts: action.conflicts
				}
			};
		case SAVE_TICKET_MAPPING_ERROR:
			return {
				...state,
				save: {
					loading: false,
					error: true,
					conflicts: []
				}
			};
		default:
			return state;
	}
};

export default reducer;
