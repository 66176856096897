import { Radio, Select, Spin, Tabs } from "@/ui/antd";
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "../components/AppBlocks";
import LoadErrorView from "../components/LoadErrorView";
import { H1, Label } from "../components/text/Text";
import { findEvents, getEventsById } from "../state/actions/eventActions";
import { setSearch, updateSearch } from "../state/actions/searchActions";
import { isEventFree, isEventNfpOrCharity } from "../utils/Events";
import { getSearchStateFromUrl } from "../utils/Urls";
import { IsUserNfpOrCharity } from "../utils/Users";
import PressRelease from "./PressRelease";
import SocialMedia from "./SocialMedia";
import { withRouterHooks } from "@/hoc/withRouterHooks";
const { Option } = Select;

//ORGANISER_TYPES
const NFP_OR_CHARITY = "NFP_OR_CHARITY";
const HAS_ONLY_FREE_EVENT = "HAS_ONLY_FREE_EVENT";
const HAS_NO_EVENT = "HAS_NO_EVENT";
const HAS_LIVE_EVENT = "HAS_LIVE_EVENT";

class PromotionalHub extends Component {
	constructor(props) {
		super(props);
		this.state = {
			radioKey: "all",
			tabKey: "social-media",
			selectedEvent: []
		};
		this.debouncedFindEvents = this.debouncedFindEvents.bind(this);
		this.handleEventOnSearch = this.handleEventOnSearch.bind(this);
		this.handleEventOnChange = this.handleEventOnChange.bind(this);
	}

	defaultSearch = {
		query: "",
		sortOrder: "relevant",
		filter: "all"
	};

	componentDidMount() {
		const { setSearch, getEventsById } = this.props;
		const search = getSearchStateFromUrl(this.defaultSearch);
		setSearch(search);
		if (search.eventIds && search.eventIds.length) {
			getEventsById(search.eventIds);
			if (search.tab && search.tab === "individual") {
				this.setState({ radioKey: "individual" });
			}
		} else {
			this.debouncedFindEvents();
		}
	}

	debouncedFindEvents(query = "") {
		clearTimeout(this.searchEventTimeout);
		this.searchEventTimeout = setTimeout(() => {
			this.props.findEvents(1, { ...this.defaultSearch, query });
		}, 200);
	}

	handleEventOnSearch(query) {
		if (query.length > 2) {
			this.debouncedFindEvents(query);
		}
	}

	handleEventOnChange(value) {
		//removing all items except last item (to avoid multiple selection of events)
		const eventIds = value.length ? value.slice(value.length - 1) : [];
		const { updateSearch } = this.props;
		updateSearch({ eventIds }, true);
	}

	tabChange = (value) => {
		const { navigate } = this.props;
		navigate(value);
	};

	whatTypeIsOrganiser = (events = [], selectedEvent) => {
		const { radioKey } = this.state;
		const { currentUser } = this.props;
		if (IsUserNfpOrCharity(currentUser.user)) {
			return NFP_OR_CHARITY;
		} else if (radioKey === "individual" && isEventNfpOrCharity(selectedEvent)) {
			return NFP_OR_CHARITY;
		} else if (events.filter((e) => isEventFree(e)).length === events.length) {
			return HAS_ONLY_FREE_EVENT;
		} else if (events.filter((e) => e.published).length > 0) {
			//either partnered or non partnered
			return HAS_LIVE_EVENT;
		}
		return HAS_NO_EVENT;
	};

	handleChangeRadio = (value) => {
		this.setState({
			radioKey: value.target.value
		});
	};

	handleChangeTab = (value) => {
		this.setState({
			tabKey: value
		});
	};

	render() {
		const { eventSearchState, search } = this.props;
		const { radioKey, tabKey } = this.state;
		const selectedEventFound =
			search.eventIds && search.eventIds.length && eventSearchState.events.length
				? eventSearchState.events.find((e) => e._id === search.eventIds[0])
				: false;

		return (
			<div>
				<H1>Promotional hub</H1>
				<LoadErrorView>
					<ContentBlock>
						<Radio.Group value={radioKey} buttonStyle="solid" onChange={this.handleChangeRadio}>
							<Radio.Button value="all">All events</Radio.Button>
							<Radio.Button value="individual">Individual event</Radio.Button>
						</Radio.Group>

						{radioKey === "individual" ? (
							<div style={{ marginTop: 16, maxWidth: 320 }}>
								<Label>Select event</Label>
								<Select
									onChange={this.handleEventOnChange}
									onSearch={this.handleEventOnSearch}
									style={{ width: "100%" }}
									dropdownMatchSelectWidth={false}
									filterOption={false}
									mode="multiple"
									notFoundContent={eventSearchState.loading ? <Spin size="small" /> : null}
									loading={eventSearchState.loading}
									value={search.eventIds}
									placeholder="Search and select an event"
								>
									{eventSearchState.events.map((item) => {
										return (
											<Option value={item._id} key={item._id}>
												{item.name}
											</Option>
										);
									})}
								</Select>
							</div>
						) : null}
						{radioKey === "individual" ? (
							<div style={{ marginTop: 10 }}>
								{selectedEventFound ? (
									<Tabs
										activeKey={tabKey}
										onChange={this.handleChangeTab}
										items={[
											{
												children: <SocialMedia tab={radioKey} selectedEvent={search.eventIds} />,
												label: "Social media",
												key: "social-media"
											},
											{
												children: <PressRelease event={selectedEventFound} />,
												label: "Press release",
												key: "press-release"
											}
										]}
									/>
								) : null}
							</div>
						) : (
							<Fragment>
								<div style={{ marginTop: 41 }} />
								<SocialMedia tab={radioKey} selectedEvent={search.eventIds} />
							</Fragment>
						)}
					</ContentBlock>
				</LoadErrorView>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentUser: state.auth,
			eventSearchState: state.events,
			search: state.search
		};
	},
	{
		getEventsById,
		findEvents,
		updateSearch,
		setSearch
	}
)(withRouterHooks(PromotionalHub));
