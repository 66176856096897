import { getConfig } from "@/config";
import { getAuth, getIdToken } from "firebase/auth";
import { useEffect, useState } from "react";

type UseCollectionUrlParams = {
	collectionSlug: string;
	isPreview: boolean;
};

const collectionRootURL = `${getConfig("COLLECTIONS_URL")}`;

export const useCollectionURL = ({ collectionSlug, isPreview }: UseCollectionUrlParams) => {
	const defaultURL = new URL(`${collectionRootURL}/${collectionSlug}`);
	const [resolvedUrl, setResolvedUrl] = useState<string>(defaultURL.toString());

	useEffect(() => {
		const constructURL = async () => {
			const url = new URL(defaultURL.toString());

			if (isPreview) {
				const auth = getAuth();
				const userJWT = auth.currentUser ? await getIdToken(auth.currentUser) : null;

				if (userJWT) {
					url.searchParams.append("token", userJWT);
				}
			}

			setResolvedUrl(url.toString());
		};
		constructURL();
	}, [collectionSlug, isPreview]);

	return resolvedUrl;
};
