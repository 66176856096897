import { Alert } from "@/ui/antd";
import H4 from "@/components/text/H4";
import iconReference from "@/services/IconReference";
import { getBankWarning } from "./BankWarning";
import { getStripeWarning } from "./StripeWarning";
import { getUSTaxInfoWarning } from "./USTaxInfoWarning";

const icon = iconReference["warning"];

const Warnings = ({ event, currentUser }) => {
	const warnings = [getStripeWarning(event), getBankWarning(event), getUSTaxInfoWarning(event, currentUser)].filter(
		(warning) => warning !== null
	);

	return warnings.length ? (
		<Alert
			style={{ marginBottom: 12 }}
			icon={<img src={icon} alt="warning icon" width="20" style={{ top: 8, left: 12 }} />}
			message={
				<>
					<H4>Warnings</H4>
					{warnings?.map((warning, index) => (
						<div key={index}>{warning}</div>
					))}
				</>
			}
			type="warning"
			showIcon
		/>
	) : null;
};

export default Warnings;
