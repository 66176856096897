// There is an accompanying type definition file for the color-contrast package. libs/console-frontend/src/types/color-contrast.d.ts
import colorContrast from "color-contrast";

export const getTextColor = (backgroundColor: string, colorRatio = 4.5): string => {
	const colorCode = ["#323232", "#FFFFFF"];
	const textColor = colorCode.find((color) => {
		if (colorContrast(backgroundColor, color) >= colorRatio) {
			return color;
		}
		return undefined;
	});
	if (!textColor) {
		return getTextColor(backgroundColor, colorRatio - 0.2);
	}
	return textColor;
};
