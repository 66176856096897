import { clearToken, setToken } from "@/state/slices/auth";
import { AppDispatch } from "@/state/store";
import { getAuth, getIdToken } from "firebase/auth";
import axios from "./AxiosWrapper";

const TOKEN_REFRESH_INTERVAL_MINUTES = 15;

class TokenService {
	private static instance: TokenService | null = null;
	private token: string | null;
	private tokenRefreshDate: Date;

	private constructor() {
		this.token = null;
		this.tokenRefreshDate = new Date();
	}

	public static getInstance(): TokenService {
		if (!TokenService.instance) {
			TokenService.instance = TokenService.createInstance();
		}
		return TokenService.instance;
	}

	private static createInstance(): TokenService {
		return new TokenService();
	}

	public async checkRefresh(): Promise<string | null> {
		const now = new Date();
		const diffMs = now.getTime() - TokenService.getInstance().tokenRefreshDate.getTime();
		const diffMins = ((diffMs % 86400000) % 3600000) / 60000;
		if (diffMins > TOKEN_REFRESH_INTERVAL_MINUTES) {
			return TokenService.getInstance().refresh();
		}
		return TokenService.getInstance().getToken();
	}

	public async refresh(): Promise<string> {
		const user = getAuth().currentUser;
		if (!user) throw new Error("no user logged in");

		const token = await getIdToken(user, true);
		TokenService.getInstance().setTokenToHeaders(token);
		return token;
	}

	public setTokenToHeaders(token: string): void {
		TokenService.getInstance().token = token;
		TokenService.getInstance().tokenRefreshDate = new Date();
		axios.defaults.headers.common["x-token"] = token;
		axios.defaults.headers.post["x-token"] = token;
		return;
	}

	public persistToken = (token: string, dispatch: AppDispatch): void => {
		TokenService.getInstance().setTokenToHeaders(token);
		dispatch(setToken(token));
		return;
	};

	public clearToken = (dispatch: AppDispatch): void => {
		TokenService.getInstance().token = null;
		TokenService.getInstance().tokenRefreshDate = new Date();
		delete axios.defaults.headers.common["x-token"];
		delete axios.defaults.headers.post["x-token"];
		dispatch(clearToken());
		return;
	};

	public getToken = (): string | null => this.token;
}

export default TokenService.getInstance();
