import { SearchActionTypes } from "@/state/actions/searchActions";

export type SearchReducerState = {
	[key: string]: string | number | string[];
};

const initialState: SearchReducerState = {
	page: 1
};

type SearchReducerAction = {
	type: SearchActionTypes;
	search?: {
		[key: string]: any;
	};
};

export default (state = initialState, action: SearchReducerAction) => {
	switch (action.type) {
		case SearchActionTypes.SET_SEARCH:
			if (action.search?.page) action.search.page = parseInt(action.search.page);
			return {
				...initialState,
				...action.search
			};
		case SearchActionTypes.UPDATE_SEARCH: {
			const newState: SearchReducerState = { ...state };
			if (action.search) {
				Object.keys(action.search).forEach((k) => {
					if (action.search?.[k]) {
						newState[k] = action.search[k];
					} else {
						delete newState[k];
					}
				});
			}
			if (!action.search?.page) {
				newState.page = 1;
			} else if (newState.page) {
				newState.page = typeof newState.page === "string" ? parseInt(newState.page) : newState.page;
			}
			return newState;
		}
		case SearchActionTypes.RESET_SEARCH:
			return initialState;
		default:
			return state;
	}
};
