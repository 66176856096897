import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import PageHeading from "@/components/PageHeading";
import H3 from "@/components/text/H3";
import { getUser } from "@/state/actions/userActions";
import { ISelfServiceSetting } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { DateFormats, HumaniDate } from "@hx/dates";
import { Alert, Modal, Table } from "@/ui/antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NewThreshold from "./NewThreshold";

const currentSettingsTable = css`
	tr {
		td:first-of-type {
			font-weight: bold;
			vertical-align: text-top;
			padding-right: 8px;
			white-space: nowrap;
		}
		td:last-of-type {
			width: 100%;
		}
	}
`;

const getColumns = (setFocussedThreshold: (thresholdToFocus: ISelfServiceSetting) => void) => [
	{
		title: "Date added",
		dataIndex: "createdAt",
		render: (value: string) => new HumaniDate(value).formatting.date(DateFormats.Normal)
	},
	{
		title: "Percent",
		dataIndex: "percent",
		render: (value: number) => `${value}%`
	},
	{
		title: "Cap",
		dataIndex: "cap",
		render: (value: number) => (value ? `${currency(value)}` : "None")
	},
	{
		title: "Author",
		dataIndex: "authorName"
	},
	{
		title: "Notes",
		dataIndex: "note"
	},
	{
		title: "Action",
		render: (threshold: ISelfServiceSetting) => {
			return <IconButton onClick={() => setFocussedThreshold(threshold)} icon="preview" ariaLabel="view-threshold" />;
		}
	}
];

const ThresholdBreakdown = ({ threshold }: { threshold: ISelfServiceSetting }) => (
	<table className={currentSettingsTable}>
		<tbody>
			<tr>
				<td>Date added</td>
				<td>{new HumaniDate(threshold.createdAt).formatting.date(DateFormats.Normal)}</td>
			</tr>
			<tr>
				<td>Payout percent</td>
				<td>{threshold.percent}% / event occurrence</td>
			</tr>
			<tr>
				<td>Payout cap</td>
				<td>{threshold.cap ? `${currency(threshold.cap)} / event occurrence` : "None"}</td>
			</tr>
			<tr>
				<td>Author</td>
				<td>{threshold.authorName}</td>
			</tr>
			<tr>
				<td>Notes</td>
				<td>{threshold.note}</td>
			</tr>
		</tbody>
	</table>
);

const SelfServePayoutSettings = (props: IInjectedProps & IProps & IInjectedFunctions) => {
	const [focussedThreshold, setFocussedThreshold] = React.useState<ISelfServiceSetting | null>(null);

	const { userSelfServicePayoutsSettings, eventSelfServicePayoutsSettings, userId, getUser } = props;

	const selfServicePayoutsSettings =
		props.level === "user" ? userSelfServicePayoutsSettings : eventSelfServicePayoutsSettings;
	const history = selfServicePayoutsSettings?.history || [];

	useEffect(() => {
		getUser(userId);
	}, []);

	return (
		<>
			<PageHeading title="Self-serve payout settings">
				<div>
					<NewThreshold {...props} />
				</div>
			</PageHeading>

			<Modal
				title="Threshold details"
				open={!!focussedThreshold}
				destroyOnClose
				onCancel={() => setFocussedThreshold(null)}
				footer={null}
			>
				{focussedThreshold && <ThresholdBreakdown threshold={focussedThreshold} />}
			</Modal>
			{props.level === "event" && userSelfServicePayoutsSettings && (
				<Alert
					type="warning"
					showIcon
					message={
						<>
							Self-serve payouts have been enabled at a user level{" "}
							<b>
								({userSelfServicePayoutsSettings?.currentSettings.percent}% /{" "}
								{userSelfServicePayoutsSettings?.currentSettings.cap
									? `${currency(userSelfServicePayoutsSettings?.currentSettings.cap)}`
									: "No cap "}
								/ per occurrence)
							</b>
							. <Link to={`/console/admin/users/${userId}/self-serve-payout-settings`}>See user settings</Link>
						</>
					}
				/>
			)}

			{selfServicePayoutsSettings?.currentSettings ? (
				<ContentBlock>
					<H3>Settings</H3>
					<ThresholdBreakdown threshold={selfServicePayoutsSettings.currentSettings} />
				</ContentBlock>
			) : null}

			<ContentBlock>
				<H3>History</H3>
				<Table
					dataSource={history.sort((a, b) =>
						new HumaniDate(a.createdAt).dayJs.isBefore(new HumaniDate(b.createdAt).dayJs) ? 1 : -1
					)}
					columns={getColumns(setFocussedThreshold)}
				/>
			</ContentBlock>
		</>
	);
};

interface ISelfServicePayoutsSettings {
	enabled: boolean;
	currentSettings: ISelfServiceSetting;
	history: ISelfServiceSetting[];
}

interface IEventLevelProps {
	level: "event";
	eventId: string;
}

interface IUserLevelProps {
	level: "user";
	userId: string;
}

type IProps = IUserLevelProps | IEventLevelProps;

interface IInjectedProps {
	userSelfServicePayoutsSettings?: ISelfServicePayoutsSettings;
	eventSelfServicePayoutsSettings?: ISelfServicePayoutsSettings;
	userId: string;
}

interface IInjectedFunctions {
	getUser: (id: string) => void;
}

export default connect(
	(state: ReduxState): IInjectedProps => {
		return {
			userSelfServicePayoutsSettings: state.currentUser?.user?.selfServicePayoutsSettings,
			eventSelfServicePayoutsSettings: state.currentEvent?.event?.selfServicePayoutsSettings,
			userId: state.currentUser?.user?.id || state.currentEvent?.event?.userId || ""
		};
	},
	{ getUser }
)(SelfServePayoutSettings);
