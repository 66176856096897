import { P } from "@/components/text/Text";
import { Button } from "@/ui";

const NoResults = ({
	title,
	message,
	actionTxt,
	action,
	noTitle = false
}: {
	title: string;
	message?: string;
	actionTxt?: string;
	action?: () => void;
	noTitle?: boolean;
}) => {
	const heading = title ? title : "No Results";
	const mgs = message ? message : "";
	return (
		<div>
			{!noTitle ? <P>{heading}</P> : null}
			<P>{mgs}</P>
			{action ? (
				<Button variant="tertiary" onClick={action} aria-label={actionTxt} dataCy={actionTxt}>
					{actionTxt}
				</Button>
			) : null}
		</div>
	);
};

export default NoResults;
