import Papa from "papaparse/papaparse.min";

export const cleanValue = (value) => value.replace(/ /g, "").replace(/\r/g, "").toLowerCase();

export const parseCsv = (data) => {
	const result = Papa.parse(data);
	if (result.errors && result.errors.length > 0) {
		console.error(result.errors);
	}
	const table = result.data;

	//remove the last row if its empty
	if (table && table[table.length - 1].length <= 1) {
		table.pop();
	}
	return table;
};

export const loadCsv = async (file) => {
	return new Promise((res) => {
		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			res(parseCsv(fileReader.result));
		};
		fileReader.readAsText(file);
	});
};
