import { css } from "@emotion/css";
import { P } from "@/components/text/Text";

const Footer = ({ handleSkip }) => {
	const footerStyle = css({
		cursor: "pointer",
		marginBottom: 24,
		p: {
			textDecoration: "underline",
			color: "#196097"
		}
	});

	return (
		<div
			className={footerStyle}
			tabIndex={0}
			role="button"
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					handleSkip();
				}
			}}
			onClick={() => {
				handleSkip();
			}}
		>
			<P>Skip to dashboard</P>
		</div>
	);
};

export default Footer;
