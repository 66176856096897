import { State } from "@hx/seating-map";
import { Connectors } from "@hx/seating-map-toolbar";
import { Alert } from "@/ui/antd";
import { connect } from "react-redux";
import ArrowTailDown from "@/assets/arrowDownTail";
import Icon from "@/components/Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3, P } from "@/components/text/Text";
import { moveIndividual, unAssign } from "@/state/actions/seatingMapActions";
const { ConnectTicketManagement } = Connectors;
const { AssigningManager } = State.Assigning;

const SeatingInfo = ({ name }) => {
	if (!name) {
		return null;
	}
	return (
		<div style={{ borderRadius: 4, border: "solid 1px #dedede" }}>
			<div style={{ margin: "auto", textAlign: "center", paddingTop: 12 }}>
				<P>{name}</P>
			</div>
		</div>
	);
};

const Arrow = ({ isSwap }) => {
	return (
		<div style={{ width: "100%", marginTop: 4 }}>
			<div
				style={{
					margin: "auto",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center"
				}}
			>
				<div style={{ width: 24 }}>
					<ArrowTailDown rotate={0} />
				</div>
				<div style={{ width: 24 }}>{isSwap ? <ArrowTailDown rotate={180} /> : null}</div>
			</div>
		</div>
	);
};

let AssigningIndividual = ({ individual, moveIndividual, organiser, unAssign }) => {
	const { loading, error } = organiser;
	const { from, to, isUnassign } = individual;
	const { name, firstName, lastName, ticketTypeName, selectedObject } = from;
	const fromName = selectedObject ? selectedObject.getFullName() : name;
	const secondSeatSelected = !!to;
	let toName = false;
	let isSwap = false;
	if (secondSeatSelected) {
		isSwap = !!to.ticketId;
		toName = to.selectedObject.getFullName();
	}
	const isCurrentlyAssigned = !!selectedObject;
	let actionName = "Assign";
	if (isCurrentlyAssigned) {
		actionName = isSwap ? "Swap" : "Move";
	}
	if (isUnassign) {
		isSwap = true;
		toName = "Unassigned";
		actionName = "Unassign";
	}

	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row-reverse",
					padding: "13px 17px",
					cursor: "pointer"
				}}
				onClick={() => AssigningManager.reset()}
			>
				<P style={{ fontWeight: 600, marginBottom: 0, marginTop: 2 }}>Back</P>
				<Icon style={{ marginRight: 12 }} icon="left_small" />
			</div>
			{error ? <Alert type="error" message={error} /> : null}
			<div style={{ background: "white" }}>
				<div style={{ padding: "16px 12px" }}>
					<H3>Attendee info</H3>
					<P style={{ fontWeight: 600, marginBottom: 2 }}>{`${firstName} ${lastName}`}</P>
					<P>{ticketTypeName}</P>
					<SeatingInfo name={fromName} />
					{!secondSeatSelected && !isUnassign ? (
						<Alert style={{ marginTop: 4 }} message="Select a new seat by clicking on it." type="info" showIcon />
					) : isCurrentlyAssigned ? (
						<Arrow isSwap={isSwap} />
					) : (
						false
					)}
					<SeatingInfo name={toName} />
				</div>
				<div style={{ height: 1, border: "solid 1px #dedede" }} />
				<div style={{ padding: "16px 12px" }}>
					<LegacyButton
						loading={loading}
						disabled={!secondSeatSelected && !isUnassign}
						style={{ width: "100%" }}
						ariaLabel="move"
						type="secondary"
						onClick={() => {
							isUnassign ? unAssign() : moveIndividual();
						}}
					>
						{actionName}
					</LegacyButton>
					<LegacyButton
						style={{ width: "100%", marginTop: 8 }}
						ariaLabel="cancel"
						type="action"
						onClick={() => AssigningManager.reset()}
					>
						Cancel
					</LegacyButton>
				</div>
			</div>
		</div>
	);
};

AssigningIndividual = ConnectTicketManagement({
	individual: "individual"
})(AssigningIndividual);

export default connect(
	(state) => ({
		organiser: state.seatingMap.organiser
	}),
	{ moveIndividual, unAssign }
)(AssigningIndividual);
