import Duplicate from "@/assets/ic_duplicate.svg";
import { CodeBlock, Svg } from "@/ui";
import { Alert, Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type SvgProps = {
	fill?: string;
	margin?: string;
	opacity?: string;
	padding?: string;
	size?: string;
	src?: any;
	strokeColour?: string;
	strokeWidth?: string;
};`;

const usage = `import Duplicate from "@/assets/ic_duplicate.svg";
<Svg size="20px" src={Duplicate} />
<Svg size="24px" src={Duplicate} />`;

export const SvgPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<CodeBlock language="js" codeString={props} title="props" />
			<CodeBlock language="js" codeString={usage} title="sample.tsx" />
			<br />
			<Alert
				message="Prefer Antd icons, then phosphor, else add the svg to the assets folder and use the Svg component."
				showIcon
				type="info"
			/>
			<br />
			<h2>Default</h2>
			{Array.from({ length: 5 }, (_, i) => i * 4 + 20).map((size) => (
				<Svg key={size} margin="0 0 0 0" size={`${size}px`} src={Duplicate} />
			))}
		</Flex>
	);
};
