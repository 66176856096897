import { TaxSettings } from "@hx/utilities";
import { Divider, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField, LegacyInputField, RadioField, TextAreaField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageActions from "@/components/PageActions";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, H2, H4 } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { getEventForOrderEdit } from "@/state/actions/eventActions";
import { getOrder, saveOrder, sendOrder } from "@/state/actions/orderActions";
import { removeHubspot } from "@/utils/Hubspot";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { email, mobile, mobileOrEmpty, required } from "@/utils/Validators";
import OrderActions from "@/events/event/order/OrderActions";
import OrderQuestions from "@/events/event/order/OrderQuestions";
import { ResendOrderModal } from "@/events/event/order/ResendOrderModal";
import TicketsQuestions from "@/events/event/order/TicketsQuestions";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class EditOrder extends Component {
	constructor() {
		super();
		this.save = this.save.bind(this);
		this.actionClicked = this.actionClicked.bind(this);
	}

	componentWillMount() {
		this.load();
	}

	load() {
		const { params, order, token, getOrder, getEventForOrderEdit } = this.props;
		const orderId = params?.orderId;
		const eventId = params?.eventId;
		if (!order.order || orderId !== order.order._id) {
			getOrder(orderId, eventId, token);
		}
		getEventForOrderEdit(eventId, orderId);
		if (token) {
			removeHubspot();
		}
	}

	save(values) {
		const { params, saveOrder, order, token, eventId } = this.props;
		const orderId = params?.orderId;
		const tickets = order.order.tickets;
		// merge ticket additional fields
		const emailAttendees = !!values.emailAttendees;
		const resendOrder = !!values.resendOrder;
		const emailTo = resendOrder ? values.emailTo : "false";
		const ticketLookUp = {};
		let ticketIds = [];
		tickets.forEach((ticket, index) => {
			ticketLookUp[ticket._id] = index;
		});
		if (values.tickets) {
			Object.keys(values.tickets).map((key) => {
				let ticket = tickets[ticketLookUp[key]];
				ticket.additionalFields = mapAdditionalFields(values.tickets[key]);
				return ticket;
			});
		}
		if (values.emailAttendeeTickets) {
			Object.keys(values.emailAttendeeTickets).map((id) => {
				if (values.emailAttendeeTickets[id].toString() === "true") ticketIds.push(id);
			});
		}
		const orderData = {
			eventId: values.eventId,
			firstName: values.firstName,
			lastName: values.lastName,
			mobile: values.mobile,
			email: values.email,
			businessPurpose: values.businessPurpose,
			businessTaxId: values.businessTaxId,
			businessName: values.businessName,
			additionalFields: mapAdditionalFields(values.additionalFields),
			tickets,
			notes: values.notes
		};
		if (
			order.order.payments.length &&
			order.order.payments[0].invoice &&
			order.order.payments[0].invoice.invoiceTo &&
			order.order.payments[0].invoice.invoiceTo.organisation
		) {
			orderData["payments.0.invoice.invoiceTo.organisation"] = values.businessName;
		}
		saveOrder(orderId, orderData, eventId, token, resendOrder, emailAttendees, emailTo, ticketIds, resendOrder);
	}

	actionClicked(event, order) {
		const { sendOrder } = this.props;
		switch (event.key) {
			case "send-order":
				sendOrder(order._id);
				break;
			default:
				break;
		}
	}

	render() {
		const {
			currentEvent,
			token,
			businessPurpose,
			businessTaxId,
			businessName,
			buyerData,
			emailAttendees,
			resendOrder
		} = this.props;
		let { orderAdditionalQuestions = [], ticketsAdditionalQuestions } = this.props;
		const { order, loading, error } = this.props.order;
		const convertMultipleChoice = (questions) => {
			for (let i in questions) {
				if (typeof questions[i] === "object" && Object.keys(questions[i]).length) {
					for (let j in questions[i]) {
						if (questions[i][j] && typeof questions[i][j] === "string" && questions[i][j].indexOf("{|}") !== -1) {
							questions[i][j] = questions[i][j].split("{|}");
						}
					}
				} else if (questions[i] && typeof questions[i] === "string" && questions[i].indexOf("{|}") !== -1) {
					questions[i] = questions[i].split("{|}");
				}
			}
			return { ...questions };
		};

		orderAdditionalQuestions = convertMultipleChoice(orderAdditionalQuestions);
		ticketsAdditionalQuestions = convertMultipleChoice(ticketsAdditionalQuestions);
		return (
			<LoadErrorView
				loading={!currentEvent.eventWithOrderInfo || loading || !order || currentEvent.save.loading}
				error={error}
				retryAction={() => this.load()}
			>
				{currentEvent.eventWithOrderInfo && order && order.tickets ? (
					<div>
						{!token ? (
							<PageActions>
								<div style={{ float: "right" }}>
									<OrderActions
										additionalItems={
											order.buyerManageUrl
												? [
														{
															key: "view-as-attendee",
															label: (
																<a href={order.buyerManageUrl} rel="noreferrer" target="_blank">
																	Edit as buyer
																</a>
															)
														}
												  ]
												: []
										}
										eventId={currentEvent.eventWithOrderInfo._id}
										event={currentEvent.eventWithOrderInfo}
										order={order}
										useButton
										hideEdit
									/>
									<ResendOrderModal />
								</div>
							</PageActions>
						) : null}
						<H1>Edit Order: {order.orderName}</H1>

						{currentEvent.eventWithOrderInfo && order && !loading ? (
							<OrderForm
								save={this.save}
								order={order}
								event={currentEvent.eventWithOrderInfo}
								detailsOnly={!!token}
								orderAdditionalQuestions={orderAdditionalQuestions}
								ticketsAdditionalQuestions={ticketsAdditionalQuestions}
								businessPurpose={businessPurpose}
								businessTaxId={businessTaxId}
								businessName={businessName}
								buyerData={buyerData}
								emailAttendees={emailAttendees}
								resendOrder={resendOrder}
							/>
						) : null}
					</div>
				) : null}
			</LoadErrorView>
		);
	}
}

const firstNameRequired = required("Please input a first name");
const lastNameRequired = required("Please input a last name");
const emailRequired = required("Please input an email");
const validEmail = email("Please enter a valid email");
const mobileRequired = required("Please input a mobile");
const validMobile = mobile("Please enter a valid mobile");
const validMobileOrEmpty = mobileOrEmpty("Please enter a valid mobile or leave empty");

class OrderForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	render() {
		const {
			save,
			handleSubmit,
			order,
			event,
			detailsOnly,
			orderAdditionalQuestions,
			ticketsAdditionalQuestions,
			businessPurpose,
			businessTaxId,
			businessName,
			buyerData,
			change,
			resendOrder,
			emailAttendees,
			dispatch
		} = this.props;
		const { showModal } = this.state;
		const businessPurposeOptions = [
			{
				label: "Individual",
				value: false
			},
			{
				label: "Business",
				value: true
			}
		];
		const taxIdName = TaxSettings.getGlobalTaxInfo(event.location).taxIdName;
		this.businessNameOrTaxIdRequired = required(
			`Please input either business name or ${taxIdName || "business tax id"}`
		);
		const isMobileRequired = event.settings.mobileRequired !== false;
		const changeFieldValue = (field, value) => {
			change(field, value);
			return true;
		};
		const handleButtonClick = (e) => {
			e.preventDefault();
			this.setState({ showModal: true });
		};
		const handleModalClose = () => {
			this.setState({ showModal: false });
		};
		const selectAll = () => {
			const newValues = order.tickets.reduce((obj, t) => {
				obj[t._id] = "true";
				return obj;
			}, {});
			dispatch(change("emailAttendeeTickets", newValues));
		};
		const deselectAll = () => {
			dispatch(change("emailAttendeeTickets", {}));
		};
		let ticketsRequireEmail = false;
		if (ticketsAdditionalQuestions) {
			const emailQuestions = event.additionalQuestions?.filter(
				(question) => question.type === "email" && !question.perOrder
			);
			if (emailQuestions && emailQuestions.length) {
				ticketsRequireEmail = true;
			}
		}
		const filteredAddQ = event.additionalQuestions?.filter((addQ) => !addQ.deleted && !addQ.disabled) ?? [];
		const sendingEmail = !!(resendOrder || emailAttendees);
		return (
			<>
				<form onSubmit={sendingEmail ? handleButtonClick : handleSubmit(save)}>
					<ContentBlock>
						<H2>Buyer Information</H2>
						<Field
							name="firstName"
							label="First Name"
							component={LegacyInputField}
							required
							validate={firstNameRequired}
						/>
						<Field
							name="lastName"
							label="Last Name"
							component={LegacyInputField}
							required
							validate={lastNameRequired}
						/>

						<Field
							name="email"
							label="Email"
							disabled={detailsOnly}
							component={LegacyInputField}
							required
							validate={[emailRequired, validEmail]}
						/>

						<Field
							component={LegacyInputField}
							label="Mobile"
							name="mobile"
							required={isMobileRequired}
							validate={isMobileRequired ? [mobileRequired, validMobile] : validMobileOrEmpty}
						/>

						<Field
							label="Are you purchasing as a business or individual?"
							name="businessPurpose"
							component={RadioField}
							defaultValue={false}
							inline
							options={businessPurposeOptions}
						/>

						{businessPurpose === true && (
							<div style={{ background: "#f9f9fa", padding: 12, marginBottom: 10 }}>
								<Field
									label={taxIdName}
									type="text"
									name="businessTaxId"
									component={LegacyInputField}
									validate={businessPurpose === true && !businessName ? this.businessNameOrTaxIdRequired : null}
								/>
								<Divider style={{ marginTop: 12, marginBottom: 15 }}>or</Divider>
								<Field
									label="Business Name"
									type="text"
									name="businessName"
									component={LegacyInputField}
									validate={businessPurpose === true && !businessTaxId ? this.businessNameOrTaxIdRequired : null}
								/>
							</div>
						)}

						<OrderQuestions
							tickets={order.tickets ?? []}
							additionalQuestions={filteredAddQ}
							orderAdditionalQuestions={orderAdditionalQuestions}
							changeFieldValue={changeFieldValue}
						/>

						<TicketsQuestions
							tickets={order.tickets}
							ticketTypes={event.ticketTypes ?? []}
							additionalQuestions={filteredAddQ}
							ticketsAdditionalQuestions={ticketsAdditionalQuestions}
							changeFieldValue={changeFieldValue}
							buyerData={buyerData}
						/>

						{detailsOnly ? null : (
							<Field name="notes" label="Notes" description="Your notes about this order" component={TextAreaField} />
						)}
						<div>
							<Field label="Resend Order" name="resendOrder" component={CheckboxField} inline labelAfter />
						</div>
						{ticketsRequireEmail ? (
							<div>
								<Field
									label="Email tickets to each attendee"
									name="emailAttendees"
									component={CheckboxField}
									inline
									labelAfter
								/>
							</div>
						) : null}
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							ariaLabel="Update"
							size="large"
						>
							{sendingEmail ? "Update and send" : "Update"}
						</LegacyButton>
					</FormBar>
				</form>
				{showModal && (
					<Modal
						title="Resend Tickets"
						open={showModal}
						onOk={handleSubmit(save)}
						onCancel={handleModalClose}
						okText="Send"
					>
						{resendOrder && (
							<>
								<H4>Resend Order To:</H4>
								<Field
									name="emailTo"
									label="Email"
									component={LegacyInputField}
									required
									validate={[emailRequired, validEmail]}
								/>
							</>
						)}
						{emailAttendees && (
							<>
								<div>
									<a style={{ marginRight: 10, cursor: "pointer" }} onClick={selectAll}>
										Select all
									</a>
									<a style={{ cursor: "pointer" }} onClick={deselectAll}>
										Deselect all
									</a>
								</div>
								{order.tickets.map((ticket) => {
									return (
										<Field
											key={ticket._id}
											name={`emailAttendeeTickets.${ticket._id}`}
											label={[`${ticket.firstName} ${ticket.lastName}`, ticket.email, ticket.ticketTypeName].join(", ")}
											component={CheckboxField}
											inline
											labelAfter
											checked={true}
										/>
									);
								})}
							</>
						)}
					</Modal>
				)}
			</>
		);
	}
}

const formName = "editOrder";

OrderForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: false,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(OrderForm);

const mapAdditionalFields = (fieldsObject) => {
	return Object.keys(fieldsObject).map((key) => {
		const value =
			fieldsObject[key] && Array.isArray(fieldsObject[key])
				? fieldsObject[key].join("{|}")
				: fieldsObject[key]
				? fieldsObject[key]
				: "";

		return {
			questionId: key,
			value
		};
	});
};

function getOrderValues(order, formOrder) {
	if (order && formOrder) {
		return formOrder;
	}
	if (!order) {
		return null;
	}
	let mappedAdditionalFields = {};
	if (order.additionalFields) {
		order.additionalFields.forEach((field) => {
			mappedAdditionalFields[field.questionId] = field.value;
		});
	}

	const result = { ...order, additionalFields: mappedAdditionalFields };
	return result;
}

function getTicketValues(order, formTickets) {
	if (order && formTickets) {
		return formTickets;
	}
	if (!order || !order.tickets || !order.tickets.length) {
		return null;
	}
	let mappedTicketValues = {};
	order.tickets.forEach((ticket) => {
		mappedTicketValues[ticket._id] = {};
		ticket.additionalFields.forEach((field) => {
			if (field.value?.details) {
				const details = field.value.details;
				const addressParts = [
					details.street,
					details.suburb,
					details.state,
					details.postalCode,
					details.country
				].filter(Boolean);
				mappedTicketValues[ticket._id][field.questionId] = {
					details: field.value.details,
					location: addressParts.join(", ")
				};
			} else {
				mappedTicketValues[ticket._id][field.questionId] = field.value;
			}
		});
	});
	return mappedTicketValues;
}

const selector = formValueSelector(formName);

const orderInitFunction = (state) => {
	let orderForm = {};
	if (selector(state, "firstName")) {
		orderForm.firstName = selector(state, "firstName");
	}
	if (selector(state, "lastName")) {
		orderForm.lastName = selector(state, "lastName");
	}
	if (selector(state, "mobile")) {
		orderForm.mobile = selector(state, "mobile");
	}
	if (selector(state, "email")) {
		orderForm.email = selector(state, "email");
	}
	if (selector(state, "businessPurpose")) {
		orderForm.businessPurpose = selector(state, "businessPurpose");
	}
	if (selector(state, "businessTaxId")) {
		orderForm.businessTaxId = selector(state, "businessTaxId");
	}
	if (selector(state, "businessName")) {
		orderForm.businessName = selector(state, "businessName");
	}
	if (selector(state, "additionalFields")) {
		orderForm.additionalFields = selector(state, "additionalFields");
	}
	if (selector(state, "eventId")) {
		orderForm.eventId = selector(state, "eventId");
	}
	if (selector(state, "notes")) {
		orderForm.notes = selector(state, "notes");
	}
	if (!Object.keys(orderForm).length) {
		orderForm = false;
	}
	return orderForm;
};

OrderForm = connect((state) => {
	const orderForm = orderInitFunction(state);
	return {
		initialValues: {
			...getOrderValues(state.order?.order, orderForm),
			tickets: getTicketValues(state.order?.order, selector(state, "tickets")),
			emailTo: state.order?.order?.email,
			emailAttendeeTickets: state.order?.order?.tickets?.reduce((obj, t) => {
				obj[t._id] = "true";
				return obj;
			}, {})
		}
	};
})(OrderForm);

export default connect(
	(state) => ({
		order: state.order,
		currentEvent: state.currentEvent,
		orderAdditionalQuestions: selector(state, "additionalFields"),
		ticketsAdditionalQuestions: selector(state, "tickets"),
		businessPurpose: selector(state, "businessPurpose"),
		businessTaxId: selector(state, "businessTaxId"),
		businessName: selector(state, "businessName"),
		emailAttendees: selector(state, "emailAttendees"),
		resendOrder: selector(state, "resendOrder"),
		buyerData: {
			firstName: selector(state, "firstName"),
			lastName: selector(state, "lastName"),
			mobile: selector(state, "mobile"),
			email: selector(state, "email")
		}
	}),
	{ getOrder, sendOrder, saveOrder, getEventForOrderEdit }
)(withSaveCheck(withRouterHooks(EditOrder), formName));
