import { dayjs } from "@/libs/dayjs";
import axios from "./AxiosWrapper";

const getHeaders = (location) => {
	return {
		headers: {
			"x-override-location": location
		}
	};
};

class AdminReportService {
	static instance = null;

	static createInstance() {
		let object = new AdminReportService();
		return object;
	}

	static getInstance() {
		if (!AdminReportService.instance) {
			AdminReportService.instance = AdminReportService.createInstance();
		}
		return AdminReportService.instance;
	}

	getTimezone(location) {
		switch (location.toUpperCase()) {
			case "US":
				return "America/Denver";
			case "NZ":
				return "Pacific/Auckland";
			default:
				return "Australia/Sydney";
		}
	}

	convertDates(startDate, endDate, dayEndsAt, location) {
		let startDateStart;
		let endDateEnd;
		const timezome = this.getTimezone(location);
		if (dayEndsAt === "midnight") {
			startDateStart = dayjs(startDate).tz(timezome).startOf("day");
			endDateEnd = dayjs(endDate).tz(timezome).endOf("day");
		} else {
			const timeParts = dayEndsAt.split(":").map((t) => Number(t));
			const hours = timeParts[0];
			const mintues = timeParts[1];
			startDateStart = dayjs(startDate).tz(timezome).subtract(1, "days").hours(hours).minutes(mintues).seconds(0);
			endDateEnd = dayjs(endDate).tz(timezome).hours(hours).minutes(mintues).seconds(0);
		}
		return {
			startDate: startDateStart.toISOString(),
			endDate: endDateEnd.toISOString()
		};
	}

	convertDate(date, dayEndsAt, location) {
		let newDate;
		const timezome = this.getTimezone(location);
		if (dayEndsAt === "midnight") {
			newDate = dayjs(date).tz(timezome).endOf("day");
		} else {
			const timeParts = dayEndsAt.split(":").map((t) => Number(t));
			const hours = timeParts[0];
			const minutes = timeParts[1];
			newDate = dayjs(date).tz(timezome).hours(hours).minutes(minutes).seconds(0);
		}
		return newDate.toISOString();
	}

	async getGeneratedReports(userId, location) {
		const response = await axios.get(`admin/reports/users/${userId}/uploads`, {
			...getHeaders(location)
		});
		return response.data;
	}

	async downloadGeneratedReport(userId, uploadId) {
		const response = await axios.get(`admin/reports/users/${userId}/uploads/${uploadId}/download`, {
			responseType: "blob"
		});
		return response.data;
	}

	async getBoardReport(startDate, endDate, location) {
		const options = {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, "midnight", location)
		};
		const response = await axios.get(`admin/reports/board`, options);
		return response.data;
	}

	async getMoneyHeldReport(soldBefore, heldAt, dayEndsAt, page, location) {
		const response = await axios.get(`admin/reports/money-held`, {
			params: {
				soldBefore: this.convertDate(soldBefore, dayEndsAt, "midnight", location),
				heldAt: this.convertDate(heldAt, dayEndsAt, "midnight", location),
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getMoneyHeldNowReport(location) {
		const response = await axios.get(`admin/reports/money-held-now`, {
			...getHeaders(location)
		});
		return response.data;
	}

	async getGiftCardsHeldReport(heldAt, dayEndsAt, page, location) {
		const response = await axios.get(`admin/reports/gift-cards-held`, {
			params: {
				heldAt: this.convertDate(heldAt, dayEndsAt, true),
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getGiftCardsHeldReportCsv(heldAt, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/gift-cards-held/csv`, {
			responseType: "blob",
			...getHeaders(location),
			params: { heldAt: this.convertDate(heldAt, dayEndsAt, true) }
		});
		return response.data;
	}

	async getControlReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/control-report`, {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}

	async getTicketSalesSummary(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/ticket-sales-summary`, {
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getTicketSalesSummaryCsv(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/ticket-sales-summary/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getRefundsReport(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/refunds`, {
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getRefundsReportCsv(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/refunds/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getFeeCrystalisationReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/fee-crystalisation`, {
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location)
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getFeesReport(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/fees`, {
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getFeesReportCsv(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/fees/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getFeesDetailsReport(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/fees/details`, {
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getFeesDetailsReportCsv(startDate, endDate, dayEndsAt, fundsLocation, location) {
		const response = await axios.get(`admin/reports/fees/details/csv/`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, dayEndsAt, location),
				fundsLocation
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getAdjustmentsReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/adjustments`, {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}
	async getAdjustmentsReportCsv(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/adjustments/csv`, {
			responseType: "blob",
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}

	async getAdjustmentsDetailsReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/adjustments/details`, {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}
	async getAdjustmentsDetailsReportCsv(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/adjustments/details/csv`, {
			responseType: "blob",
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}

	async getPayoutsReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/payouts`, {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}
	async getPayoutsReportCsv(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/payouts/csv`, {
			responseType: "blob",
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}

	async getPayoutsDetailsReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/payouts/details`, {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}
	async getPayoutsDetailsReportCsv(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/payouts/details/csv`, {
			responseType: "blob",
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}

	async getPayoutsReversedReport(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/payouts/reversed`, {
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}
	async getPayoutsReversedReportCsv(startDate, endDate, dayEndsAt, location) {
		const response = await axios.get(`admin/reports/payouts/reversed/csv`, {
			responseType: "blob",
			...getHeaders(location),
			params: this.convertDates(startDate, endDate, dayEndsAt, location)
		});
		return response.data;
	}

	async getTopPurchasers(startDate, endDate, sortOn, page, location) {
		const response = await axios.get(`admin/reports/attendees/top-purchasers`, {
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				sortOn,
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getTopPurchasersCsv(startDate, endDate, sortOn, location) {
		const response = await axios.get(`admin/reports/attendees/top-purchasers/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				sortOn
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getTopUsers(startDate, endDate, sortOn, page, location) {
		const response = await axios.get(`admin/reports/users/top`, {
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				sortOn,
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getTopUsersCsv(startDate, endDate, sortOn, location) {
		const response = await axios.get(`admin/reports/users/top/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				sortOn
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getTopEvents(startDate, endDate, sortOn, page, location) {
		const response = await axios.get(`admin/reports/events/top`, {
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				sortOn,
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getTopEventsCsv(startDate, endDate, sortOn, location) {
		const response = await axios.get(`admin/reports/events/top/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				sortOn
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getTopForecastEvents(sortOn, page, location) {
		const response = await axios.get(`admin/reports/events/top-forecasted`, {
			params: {
				sortOn,
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getTopForecastEventsCsv(sortOn, location) {
		const response = await axios.get(`admin/reports/events/top-forecasted/csv`, {
			responseType: "blob",
			params: {
				sortOn
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getUserActivity(startDate, endDate, published, page, location) {
		const response = await axios.get(`admin/reports/user/activity`, {
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				published,
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getUserActivityCsv(startDate, endDate, published, location) {
		const response = await axios.get(`admin/reports/user/activity/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, "midnight", location),
				published
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getEventSalesByLocation(startDate, endDate, location) {
		const response = await axios.get(`admin/reports/events/sales-by-location`, {
			params: {
				...this.convertDates(startDate, endDate, "midnight", location)
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getEventSalesByLocationCsv(startDate, endDate, location) {
		const response = await axios.get(`admin/reports/events/sales-by-location/csv`, {
			responseType: "blob",
			params: {
				...this.convertDates(startDate, endDate, "midnight", location)
			},
			...getHeaders(location)
		});
		return response.data;
	}
	async getReferralPayoutReport(sortBy, page, location) {
		const response = await axios.get(`admin/reports/referral-payout`, {
			params: {
				sortBy,
				page
			},
			...getHeaders(location)
		});
		return response.data;
	}

	async getMailchimpOverviewReport() {
		const response = await axios.get(`admin/reports/integrations/mailchimp`);
		return response.data;
	}

	async comparePayments(csv, fields) {
		const response = await axios.post(`admin/reports/compare-payments`, {
			csv,
			fields
		});
		return response.data;
	}
}

export default AdminReportService.getInstance();
