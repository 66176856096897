import axios from "./AxiosWrapper";

const getAnalytics = async (eventId: string, timezone: string, dateRange: string) => {
	const response = await axios.get(`analytics/${eventId}`, {
		params: {
			timezone,
			dateRange
		}
	});
	return response.data;
};

export default {
	getAnalytics
};
