import AuthorizationService from "@/services/AuthorizationService";
import { AppDispatch, ReduxState } from "@/state/store";
import { notification } from "@/ui/antd";

export enum AuthorizationActionTypes {
	SEND_AUTHORIZATION_REQUEST = "SEND_AUTHORIZATION_REQUEST",
	SEND_AUTHORIZATION_ERROR = "SEND_AUTHORIZATION_ERROR",
	SEND_AUTHORIZATION_SUCCESS = "SEND_AUTHORIZATION_SUCCESS",

	GET_EVENTS_AUTH_REQUEST = "GET_EVENTS_AUTH_REQUEST",
	GET_EVENTS_AUTH_SUCCESS = "GET_EVENTS_AUTH_SUCCESS",
	GET_EVENTS_AUTH_ERROR = "GET_EVENTS_AUTH_ERROR",

	GET_AUTHORIZATIONS_REQUEST = "GET_AUTHORIZATIONS_REQUEST",
	GET_AUTHORIZATIONS_SUCCESS = "GET_AUTHORIZATIONS_SUCCESS",
	GET_AUTHORIZATIONS_ERROR = "GET_AUTHORIZATIONS_ERROR",

	LOAD_AUTHORIZATION_REQUEST = "LOAD_AUTHORIZATION_REQUEST",
	LOAD_AUTHORIZATION_SUCCESS = "LOAD_AUTHORIZATION_SUCCESS",
	LOAD_AUTHORIZATION_ERROR = "LOAD_AUTHORIZATION_ERROR",

	UPDATE_AUTHORIZATION_REQUEST = "UPDATE_AUTHORIZATION_REQUEST",
	UPDATE_AUTHORIZATION_SUCCESS = "UPDATE_AUTHORIZATION_SUCCESS",
	UPDATE_AUTHORIZATION_ERROR = "UPDATE_AUTHORIZATION_ERROR",

	DELETE_AUTHORIZATION_REQUEST = "DELETE_AUTHORIZATION_REQUEST",
	DELETE_AUTHORIZATION_SUCCESS = "DELETE_AUTHORIZATION_SUCCESS",
	DELETE_AUTHORIZATION_ERROR = "DELETE_AUTHORIZATION_ERROR",

	GET_PERMISSION_QR_CODE_REQUEST = "GET_PERMISSION_QR_CODE_REQUEST",
	GET_PERMISSION_QR_CODE_SUCCESS = "GET_PERMISSION_QR_CODE_SUCCESS",
	GET_PERMISSION_QR_CODE_ERROR = "GET_PERMISSION_QR_CODE_ERROR",

	GET_SHARED_ACCOUNTS_REQUEST = "GET_SHARED_ACCOUNTS_REQUEST",
	GET_SHARED_ACCOUNTS_SUCCESS = "GET_SHARED_ACCOUNTS_SUCCESS",
	GET_SHARED_ACCOUNTS_ERROR = "GET_SHARED_ACCOUNTS_ERROR",

	RESEND_AUTHORIZATION_EMAIL_REQUEST = "RESEND_AUTHORIZATION_EMAIL_REQUEST",
	RESEND_AUTHORIZATION_EMAIL_SUCCESS = "RESEND_AUTHORIZATION_EMAIL_SUCCESS",
	RESEND_AUTHORIZATION_EMAIL_ERROR = "RESEND_AUTHORIZATION_EMAIL_ERROR",

	CLEAR_AUTHORIZATION = "CLEAR_AUTHORIZATION"
}

export const sendAuthorization = (authData: any) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.SEND_AUTHORIZATION_REQUEST, authData });
		AuthorizationService.add(authData)
			.then((event) => {
				if (!event.conflict) {
					notification.success({
						message: "Sent",
						description: "Your team permission has been sent"
					});
					dispatch({ type: AuthorizationActionTypes.SEND_AUTHORIZATION_SUCCESS, authData });
				} else {
					notification.error({
						message: "Error",
						description: event.conflict
					});
					dispatch({ type: AuthorizationActionTypes.SEND_AUTHORIZATION_SUCCESS });
				}
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Your team permission failed to send"
				});
				dispatch({ type: AuthorizationActionTypes.SEND_AUTHORIZATION_ERROR, error: err });
			});
	};
};

export const resendEmail = (id: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_REQUEST });
		AuthorizationService.reissueEmail(id)
			.then(() => {
				notification.success({
					message: "Sent",
					description: "Your team permission has been re-sent"
				});
				dispatch({ type: AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_SUCCESS });
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Your team permission failed to re-send"
				});
				dispatch({ type: AuthorizationActionTypes.RESEND_AUTHORIZATION_EMAIL_ERROR, error: err });
			});
	};
};

export const getQrCodeAccess = (eventId: string) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.GET_PERMISSION_QR_CODE_REQUEST });
		AuthorizationService.getPermissionQrCode(eventId)
			.then((code) => {
				dispatch({ type: AuthorizationActionTypes.GET_PERMISSION_QR_CODE_SUCCESS, qr: code.qr });
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Failed to load QR code"
				});
				dispatch({ type: AuthorizationActionTypes.GET_PERMISSION_QR_CODE_ERROR, error: err });
			});
	};
};

export const loadAuthorization = (id: any, unregistered: boolean) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.LOAD_AUTHORIZATION_REQUEST });
		AuthorizationService.loadAuthorization(id, unregistered)
			.then((authorization) => {
				dispatch({
					type: AuthorizationActionTypes.LOAD_AUTHORIZATION_SUCCESS,
					authorization
				});
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Failed to load team member info"
				});
				dispatch({ type: AuthorizationActionTypes.LOAD_AUTHORIZATION_ERROR, error: err });
			});
	};
};

export const deleteAuthorization = (id: any, unregistered: boolean) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.DELETE_AUTHORIZATION_REQUEST });
		AuthorizationService.deleteAuthorization(id, unregistered)
			.then(() => {
				dispatch({ type: AuthorizationActionTypes.DELETE_AUTHORIZATION_SUCCESS });
			})
			.catch((err) => {
				notification.success({
					message: "Error",
					description: "Failed to removed user permission"
				});
				dispatch({ type: AuthorizationActionTypes.DELETE_AUTHORIZATION_ERROR, error: err });
			});
	};
};

export const updateAuthorization = (id: any, authorization: any, permissionListingFor: any) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.UPDATE_AUTHORIZATION_REQUEST });
		AuthorizationService.update(id, authorization, permissionListingFor)
			.then((authorization) => {
				dispatch({
					type: AuthorizationActionTypes.UPDATE_AUTHORIZATION_SUCCESS,
					authorization
				});
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: "Failed to update team member permissions"
				});
				dispatch({ type: AuthorizationActionTypes.UPDATE_AUTHORIZATION_ERROR, error: err });
			});
	};
};

export const getEvents = ({
	page,
	issueFor,
	query,
	toAppend = false
}: {
	page: number;
	issueFor: string;
	query?: string;
	toAppend?: boolean;
}) => {
	return (dispatch: AppDispatch, getState: () => ReduxState) => {
		const searchState = getState().search;
		page = page || Number(searchState.page);

		dispatch({ type: AuthorizationActionTypes.GET_EVENTS_AUTH_REQUEST });
		AuthorizationService.getValidEvents(page, issueFor, query)
			.then((events) => {
				dispatch({ type: AuthorizationActionTypes.GET_EVENTS_AUTH_SUCCESS, events, toAppend });
			})
			.catch(() => {
				dispatch({ type: AuthorizationActionTypes.GET_EVENTS_AUTH_ERROR });
			});
	};
};

export const getAuthorizations = (provided: any, permissionListingFor: any) => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.GET_AUTHORIZATIONS_REQUEST });
		AuthorizationService.getAuthorizations(provided, permissionListingFor)
			.then((permissions) => {
				dispatch({
					type: AuthorizationActionTypes.GET_AUTHORIZATIONS_SUCCESS,
					authorizations: permissions
				});
			})
			.catch((err) => {
				dispatch({ type: AuthorizationActionTypes.GET_AUTHORIZATIONS_ERROR, error: err });
			});
	};
};

export const getAuthorizationsForUser = (
	userId: string,
	userLocation: string,
	provided: any,
	permissionListingFor: any
) => {
	return async (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.GET_AUTHORIZATIONS_REQUEST });

		try {
			const permissions = await AuthorizationService.getAuthorizationsForUser(
				userId,
				userLocation,
				provided,
				permissionListingFor
			);
			dispatch({
				type: AuthorizationActionTypes.GET_AUTHORIZATIONS_SUCCESS,
				authorizations: permissions
			});

			const sharedAccounts = await AuthorizationService.getSharedForUser(userId, userLocation);

			dispatch({
				type: AuthorizationActionTypes.GET_SHARED_ACCOUNTS_SUCCESS,
				sharedAccounts
			});
		} catch (err) {
			dispatch({ type: AuthorizationActionTypes.GET_AUTHORIZATIONS_ERROR, error: err });
		}
	};
};

export const getSharedAccounts = () => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.GET_SHARED_ACCOUNTS_REQUEST });
		AuthorizationService.getSharedAccounts()
			.then((sharedAccounts) => {
				dispatch({
					type: AuthorizationActionTypes.GET_SHARED_ACCOUNTS_SUCCESS,
					sharedAccounts
				});
			})
			.catch((err) => {
				dispatch({ type: AuthorizationActionTypes.GET_SHARED_ACCOUNTS_ERROR, error: err });
			});
	};
};

export const clearAuthorization = () => {
	return (dispatch: AppDispatch) => {
		dispatch({ type: AuthorizationActionTypes.CLEAR_AUTHORIZATION });
	};
};
