import { css } from "@emotion/css";
import React from "react";
import H2 from "@/components/text/H2";

const wraper = css({
	display: "flex",
	flexDirection: "row",
	minHeight: 450,
	h3: {
		fontSize: "16px"
	}
});

const barsCol = css({
	flex: 10,
	h2: {
		margin: "0 0 6px 0"
	}
});

const barCol = css({
	position: "relative",
	height: "28px",
	marginBottom: "2px",
	h4: {
		padding: 4,
		position: "absolute",
		left: 0,
		top: 0,
		zIndex: 1,
		height: 27,
		margin: "0 0 6px 0",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	}
});

const bar = css({
	position: "absolute",
	left: 0,
	top: 0,
	height: "100%",
	backgroundColor: "#ffa179"
});

const valuesCol = css({
	flexShrink: 2,
	height: 28,
	marginBottom: 2,
	padding: 4,
	textAlign: "right",
	h3: {
		marginBottom: 6
	}
});

const value = css({
	height: 28,
	marginBottom: 2,
	padding: 4
});

export interface BarData {
	label: string;
	value: number;
	valueFormated?: string;
}

interface BarTableProps {
	colLabel?: string;
	valueLabel?: string;
	data: BarData[];
}

const BarTable = ({ colLabel, valueLabel, data }: BarTableProps) => {
	if (!data || !data.length) {
		return <p style={{ textAlign: "center" }}>No results found</p>;
	}
	const totalValue = data.reduce((acc, row) => acc + row.value, 0);
	const onePercent = totalValue / 100;

	return (
		<div className={wraper}>
			<div className={barsCol}>
				{colLabel && valueLabel ? <H2>{colLabel}</H2> : null}
				{data.map((row) => (
					<div className={barCol} key={row.label}>
						<h4>{row.label}</h4>
						<div className={bar} style={{ width: `${row.value / onePercent}%` }}></div>
					</div>
				))}
			</div>
			<div className={valuesCol}>
				{colLabel && valueLabel ? <h3>{valueLabel}</h3> : null}
				{data.map((row) => (
					<h4 key={row.label} className={value}>
						{row.valueFormated || row.value}
					</h4>
				))}
			</div>
		</div>
	);
};

export default BarTable;
