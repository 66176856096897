import ColourPicker from "../ColourPicker";
import InputWrapper from "./helpers/InputWrapper";

const ColourField = (props) => {
	const customProps = ({ input: { onChange, value }, displayFormat }) => {
		return {
			onChange: (e) => {
				onChange(e.hex);
			},
			value,
			format: displayFormat
		};
	};

	props = {
		...props,
		...customProps(props)
	};

	const {
		input,
		label,
		description,
		inline,
		required,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		meta: { touched, error },
		...rest
	} = props;
	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltip}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<ColourPicker id={input.name} value={input.value} onChange={input.onChange} {...rest} />
		</InputWrapper>
	);
};

export default ColourField;
