import { css } from "@emotion/css";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import confettiIcon from "@/assets/ic_confetti.svg";
import { H1, H3 } from "@/components/text/Text";
import { closeEnabledModal } from "@/state/actions/virtualEventHubActions";

const wrapper = css({
	textAlign: "center",
	padding: "0 0 48px 0"
});

const congrats = css({
	background: `#fff url(${confettiIcon}) no-repeat`,
	backgroundSize: "250px auto",
	backgroundPosition: "center",
	padding: "46px 0 0 0",
	"@media(max-width: 600px)": {
		backgroundSize: "200px auto",
		padding: "38px 0 0 0"
	}
});

const msgWrapper = css({
	padding: "10px 0 24px"
});

class EnabledModal extends Component {
	close = () => {
		const { closeEnabledModal } = this.props;
		closeEnabledModal();
	};

	render() {
		const { virtualEventHub } = this.props;
		return (
			<Modal open={virtualEventHub.enabledModalOpen} title="" onCancel={this.close} destroyOnClose footer={null}>
				<div className={wrapper}>
					<div className={congrats}>
						<H1>Congratulations</H1>
					</div>
					<div className={msgWrapper}>
						<H3>
							Your virtual event hub is <em>live!</em>
							<br />
							Ticket holders will have access via a <em>unique link</em> on their ticket.
						</H3>
					</div>
				</div>
			</Modal>
		);
	}
}

export default connect(
	(state) => ({
		virtualEventHub: state.virtualEventHub
	}),
	{ closeEnabledModal }
)(EnabledModal);
