import { CodeBlock, Spacer } from "@/ui";
import { Flex, Switch } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, useState } from "react";

const StyledSpacer = styled(Spacer)<{
	showSpacers: boolean;
}>`
	background-color: ${({ showSpacers }) => (showSpacers ? "royalblue" : "transparent")};
`;

const props = `type SpacerProps = {
	horizontal?: boolean;
	size: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | string;
};`;

const sample = `import { Spacer } from "@/ui";

<Spacer size="sm" />
<Spacer size="md" horizontal/>
<Spacer size="200px" horizontal/>
<Spacer size="10%" horizontal/>`;

const StyledSpan = styled.span`
	background-color: #f0f0f0;
	padding: 10px;
	border-radius: 5px;
`;

export const SpacerPage: FC = () => {
	const [showSpacers, setShowSpacers] = useState(false);

	return (
		<Flex gap="lg" vertical>
			<Flex gap="sm">
				<b>Reveal spacers</b>
				<Switch checked={showSpacers} onChange={setShowSpacers} />
			</Flex>
			<b>Vertical</b>
			<Flex vertical>
				<StyledSpan>Between this and below is a "sm" spacer</StyledSpan>
				<StyledSpacer showSpacers={showSpacers} size="sm" />
				<StyledSpan>Between this and below is an "xxl" spacer</StyledSpan>
				<StyledSpacer showSpacers={showSpacers} size="xxl" />
				<StyledSpan>Between this and below is a "200px" spacer</StyledSpan>
				<StyledSpacer showSpacers={showSpacers} size="200px" />
				<StyledSpan>Between this and below is a "10%" spacer</StyledSpan>
				<StyledSpacer showSpacers={showSpacers} size="10%" />
				<StyledSpan>Ok, no more</StyledSpan>
			</Flex>
			<b>Horizontal</b>
			<Flex>
				<StyledSpan>Between these spans</StyledSpan>
				<StyledSpacer showSpacers={showSpacers} size="xxl" horizontal />
				<StyledSpan>is an "xxl" spacer</StyledSpan>
			</Flex>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
