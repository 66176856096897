import { Field } from "redux-form";
import { ImageUploadField, LegacyInputField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import ElementHeader from "../ElementHeader";

const nameRequired = required("a name is required");
const imageRequired = required("A image is required");
const VEHImage = (props) => {
	const { fieldPath } = props;
	return (
		<>
			<ElementHeader {...props} name="Image" />
			<div>
				<Field name={`${fieldPath}.name`} label="Name" component={LegacyInputField} required validate={nameRequired} />
				<div style={{ maxWidth: 450 }}>
					<Field
						name={`${fieldPath}.file`}
						label="Image"
						component={ImageUploadField}
						required
						validate={[imageRequired]}
						aspectRatio={2}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default VEHImage;
