import { Input, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import LoadErrorView from "@/components/LoadErrorView";
import SimplePagination from "@/components/SimplePagination";
import { getReport } from "@/state/actions/globalReportActions";
import { updateSearch } from "@/state/actions/searchActions";

class SelectAttendees extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.handleQueryChange = this.handleQueryChange.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	load(page = 1) {
		this.setState({ error: false, loading: true });
		const { getReport, updateSearch, currentEvent } = this.props;
		updateSearch({ eventIds: [currentEvent.event._id] });
		getReport("attendee", page);
	}

	loadPage(page) {
		this.load(page);
	}

	handleQueryChange(e) {
		const query = e.target.value;
		const { updateSearch } = this.props;
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			updateSearch({ query }, false);
			this.load();
		}, 500);
	}

	render() {
		const { selectedAttendeeIds, attendeeSelected } = this.props;
		let { results, loading, error, page } = this.props.reportState;
		const messages = loading ? { emptyText: "Loading" } : { emptyText: "No Attendees" };

		let columns = [
			{
				title: "Order Id",
				dataIndex: "orderId",
				key: "orderId"
			},
			{
				title: "Name",
				dataIndex: "firstName",
				key: "firstName",
				render: (_, attendee) => `${attendee.firstName} ${attendee.lastName}`
			},
			{
				title: "Date",
				dataIndex: "createdAt",
				key: "createdAt"
			},
			{
				title: "Ticket Type",
				dataIndex: "ticketTypeName",
				key: "ticketTypeName"
			}
		];

		const rowSelection = {
			selectedRowKeys: selectedAttendeeIds,
			onChange: attendeeSelected,
			preserveSelectedRowKeys: true
		};
		return (
			<LoadErrorView loading={loading} error={error} retryAction={this.load}>
				<Input.Search onChange={this.handleQueryChange} />
				<Table
					rowSelection={rowSelection}
					columns={columns}
					dataSource={results}
					rowKey="_id"
					locale={messages}
					pagination={false}
				/>
				<SimplePagination pageAction={this.loadPage} currentPage={Number(page || 1)} />
			</LoadErrorView>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		reportState: state.globalReportState
	}),
	{ getReport, updateSearch }
)(SelectAttendees);
