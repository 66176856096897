import { Alert, Modal, Tooltip } from "@/ui/antd";

const CancelSchedulesModal = ({ isModalOpen, save, toggleModalOpen, summarySentence }) => {
	if (!isModalOpen) {
		return null;
	}

	return (
		<Modal title="Cancel schedules" open={isModalOpen} onOk={save} onCancel={toggleModalOpen} okText="Cancel schedules">
			<Alert
				message={
					<>
						"Cancel schedules" will remove all
						<br />
						<Tooltip title={summarySentence}>
							<a style={{ cursor: "help" }}>pending schedules</a>
						</Tooltip>{" "}
						and return the campaign to a draft state.
					</>
				}
				type="warning"
				showIcon={true}
			/>
		</Modal>
	);
};

export default CancelSchedulesModal;
