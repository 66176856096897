import { Field } from "redux-form";
import { Alert } from "@/ui/antd";
import { InputNumberField, SelectField, SwitchField, TextAreaField } from "@/components/Fields";
import { percentFormat, percentParse } from "@/utils/InputFormatters";
import { required } from "@/utils/Validators";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";

const allowBeforeDaysRequired = required("Number of days is required");
const percentAllowedRequired = required("Refund percent is required");
const refundPolicyOptions = [
	{
		value: "",
		label: "No specified policy on refunds"
	},
	{
		value: "Refunds are available up to 1 day prior to the event",
		label: "Refunds are available up to 1 day prior to the event",
		allowBeforeDays: 1
	},
	{
		value: "Refunds are available up to 7 days prior to the event",
		label: "Refunds are available up to 7 days prior to the event",
		allowBeforeDays: 7
	},
	{
		value: "Refunds are available up to 14 days prior to the event",
		label: "Refunds are available up to 14 days prior to the event",
		allowBeforeDays: 14
	},
	{
		value: "Refunds are available up to 30 days prior to the event",
		label: "Refunds are available up to 30 days prior to the event",
		allowBeforeDays: 30
	},
	{ value: "No refunds", label: "No refunds" },
	{ value: "Custom", label: "Custom" }
];

const refundPolicyOptionsLookUp = refundPolicyOptions.reduce((lookup, policy) => {
	lookup[policy.value] = policy;
	return lookup;
}, {});

const RefundSettingsForm = ({ refundSettings, fromPath = "", changeFieldValue }) => {
	const { refundPolicy, allowAttendeeRefund, allowBeforeDays } = refundSettings ? refundSettings : {};
	const noRefundPolicy = !refundPolicy || refundPolicy === "No refunds";
	return (
		<div>
			<Field
				label="Refund Policy"
				name={`${fromPath}refundSettings.refundPolicy`}
				component={SelectField}
				style={{ maxWidth: 415, width: "100%" }}
				options={refundPolicyOptions}
				onChange={(_, value) => {
					const policy = refundPolicyOptionsLookUp[value];
					if (policy.allowBeforeDays) {
						changeFieldValue(`${fromPath}refundSettings.allowBeforeDays`, policy.allowBeforeDays);
					}
					if (value === "No refunds") {
						changeFieldValue(`${fromPath}refundSettings.allowAttendeeRefund`, false);
					}
				}}
			/>
			{refundPolicy === "Custom" && (
				<Field
					label="Custom policy"
					name={`${fromPath}refundSettings.customRefundPolicy`}
					component={TextAreaField}
					style={{ maxWidth: 415, width: "100%" }}
				/>
			)}
			{noRefundPolicy ? null : (
				<>
					<Field
						id="allowAttendeeRefund"
						name={`${fromPath}refundSettings.allowAttendeeRefund`}
						component={SwitchField}
						label="Enable self-service ticket refund and cancellations"
						description="Buyers can automatically be refunded when they request refunds or ticket cancellations"
					/>
					<Alert
						showIcon
						message={
							<>
								<div>
									Buyers can action a self-service refund or cancellation using the <b>Manage Order</b> button on their
									order confirmation email, or by requesting a refund via <b>Contact Host</b>.
								</div>
								<div>
									<a onClick={OpenHelpHandler(HelpIds.selfServiceRefund)}>Learn more</a>
								</div>
							</>
						}
						type="success"
						style={{ margin: "12px 0 12px 0", maxWidth: 700 }}
					/>
				</>
			)}

			{allowAttendeeRefund && refundPolicy === "Custom" ? (
				<Field
					id="allowBeforeDays"
					name={`${fromPath}refundSettings.allowBeforeDays`}
					component={InputNumberField}
					label={`Allow cancellations & refunds ${allowBeforeDays || 0} days prior`}
					description="How many days before the event starts that customers are allowed to cancel and refund their tickets?"
					button
					validate={allowBeforeDaysRequired}
					min={0}
					max={60}
				/>
			) : null}
			{allowAttendeeRefund ? (
				<Field
					id="percentAllowed"
					name={`${fromPath}refundSettings.percentAllowed`}
					component={InputNumberField}
					label="Refundable amount"
					description="Set a fixed percentage of the ticket price that customers can refund themselves."
					formatter={percentFormat}
					parser={percentParse}
					validate={percentAllowedRequired}
					min={0}
					max={100}
				/>
			) : null}
		</div>
	);
};
export default RefundSettingsForm;
