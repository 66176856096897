import { css } from "@emotion/css";
import { Table, Tag } from "@/ui/antd";
import { Field } from "redux-form";
import { CheckboxField, InputNumberField } from "@/components/Fields";
import { H3, P } from "@/components/text/Text";
import { currency } from "@/utils/Format";

const tableWrapper = css`
	margin-bottom: 12px;
	.ant-form-item {
		margin-bottom: 0 !important;
	}
`;

const MerchandiseTable = ({
	merchandiseToCancel,
	allMerchandiseSelected,
	selectAllMerchandise,
	fundsAvailable,
	maximumRefund,
	setRefundAmount
}) => {
	if (!merchandiseToCancel) return null;
	const merchandise = Object.values(merchandiseToCancel);
	if (!merchandise.length) return null;

	const columns = [
		{
			title: "Add-on",
			key: "merchandiseDetails",
			fixed: "left",
			render: (_, merchandise) => {
				return (
					<P>
						{merchandise.merchandiseTypeName}: {merchandise.firstName} {merchandise.lastName}
					</P>
				);
			}
		},
		{
			title: (
				<CheckboxField
					id="merchandiseCancelAll"
					name="merchandiseCancelAll"
					label="Cancel all"
					labelAfter
					input={{ value: allMerchandiseSelected, onChange: selectAllMerchandise, name: "merchandiseCancelAll" }}
					meta={{}}
					inline
				/>
			),
			key: "refundMerchandise",
			fixed: "right",
			render: (_, merchandise) => {
				if (merchandise.status === "cancelled") return <Tag color="#5A626C">Cancelled</Tag>;
				return (
					<Field
						id={`merchandiseToCancel[${merchandise._id}].cancel`}
						name={`merchandiseToCancel[${merchandise._id}].cancel`}
						component={CheckboxField}
						label="Cancel"
						inline
						labelAfter
						onChange={(_, checked) => {
							setRefundAmount(
								"merchandiseToCancel",
								merchandise._id,
								checked,
								Math.min(maximumRefund + merchandise.amount, fundsAvailable[merchandise._id])
							);
						}}
					/>
				);
			}
		},
		{
			title: "Remaining",
			key: "fundsAvailable",
			fixed: "right",
			render: (_, merchandise) => currency(fundsAvailable[merchandise._id] - merchandise.amount)
		},
		{
			title: "Refund amount",
			fixed: "right",
			key: "refundAmount",
			render: (_, merchandise) => {
				return (
					<Field
						id={`merchandiseToCancel[${merchandise._id}].amount`}
						name={`merchandiseToCancel[${merchandise._id}].amount`}
						component={InputNumberField}
						precision={2}
						min={0}
						max={Math.min(fundsAvailable[merchandise._id], maximumRefund + merchandise.amount) || 0}
						disabled={fundsAvailable[merchandise._id] === 0}
					/>
				);
			}
		}
	];

	return (
		<div className={tableWrapper}>
			<H3>Add-ons</H3>
			<Table dataSource={merchandise} columns={columns} pagination={false} />
		</div>
	);
};

export default MerchandiseTable;
