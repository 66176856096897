// A browser-compatible SHA256 hashing function
// This function relies on window.crypto.subtle, which is supported by 98% of users' browsers according to CanIUse July 2024.
// Despite good browser support, you should still take care to catch and handle any errors gracefully when using it.
// Taken from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function sha256(message: string) {
	// encode as (utf-8) Uint8Array
	const msgUint8 = new TextEncoder().encode(message);

	// hash the message - crypto.subtle is only available in secure contexts (HTTPS) or localhost
	const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8);

	// convert buffer to byte array
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	// convert bytes to hex string
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
	return hashHex;
}
