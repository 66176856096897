import Logo from "@/assets/logos/logo-icon-peach.svg";
import styled from "@emotion/styled";
import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";

const StyledQRContainer = styled.div<{ size: number }>`
	width: ${(props) => props.size}px;
	position: fixed;
	top: 1000vh; // Move way off so not visible in any modal animation

	canvas {
		width: 100%;
	}
`;

const qrCodeDownloadable = (url: string, size: number) =>
	new QRCodeStyling({
		backgroundOptions: {
			color: "#ffffff"
		},
		data: url,
		dotsOptions: {
			color: "#353337",
			type: "rounded"
		},
		height: size,
		image: Logo,
		imageOptions: {
			crossOrigin: "anonymous",
			margin: 8
		},
		type: "canvas",
		width: size
	});

type useQRDownloadProps = {
	fileName: string;
	size: number;
	url: string;
};

export const useQRDownload = ({ fileName, size, url }: useQRDownloadProps) => {
	const downloadableRef = useRef(null);
	const qrCode = qrCodeDownloadable(url, size ?? 500);

	useEffect(() => {
		if (downloadableRef.current) {
			qrCode.append(downloadableRef.current);
		}
	}, [downloadableRef.current, url, qrCode]);

	const handleDownload = async () => {
		await qrCode.download({
			name: fileName
		});
	};

	return {
		renderElement: <StyledQRContainer ref={downloadableRef} size={500} />,
		download: handleDownload
	};
};
