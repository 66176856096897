import { MenuItem } from "@/services/menuService/MenuService";
import store from "@/state/store";

const adminOnlyMenu: MenuItem[] = [
	{
		label: "Hosts",
		path: "/console/admin/hosts",
		key: "admin-hosts"
	}
];

export const hostsMenu = (): MenuItem[] => {
	const { auth } = store.getState();
	const isAdmin = auth?.user?.isAdmin;

	return isAdmin ? adminOnlyMenu : [];
};
