import { Empty } from "@/ui/antd";

const ReportEmptyText = ({
	hasLoadedOnce,
	onClickCallback
}: {
	hasLoadedOnce: boolean;
	onClickCallback: () => void;
}) => {
	const getReportButton = (
		<a role="button" style={{ display: "inline" }} onClick={onClickCallback}>
			"Get report"
		</a>
	);

	const desc = !hasLoadedOnce ? (
		<div style={{ display: "inline" }}>Click {getReportButton} to generate</div>
	) : (
		<span>No results</span>
	);
	return <Empty imageStyle={{ height: 60 }} description={desc} />;
};

export default ReportEmptyText;
