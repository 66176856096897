export const required = (msg) => (value) => {
	msg = msg ? msg : `This field is required`;
	//result could be a boolean value, check explicitly for false value
	value = typeof value === "string" || value instanceof String ? value.trim() : value;

	if (typeof value === "object" && value !== null) {
		if (value.location || value.details) {
			const details = value.details || {};
			return details.country && details.postalCode && details.state && details.street && details.suburb
				? undefined
				: msg;
		}
	}
	const result = value || value === false || value === 0 ? undefined : msg;
	return result;
};

export const checkLength = (msg, len, symbol) => (value) => {
	msg = msg ? msg : `Maximum length is ${len}`;

	switch (symbol) {
		case "==":
			return value && value.length === len ? undefined : msg;
		case ">=":
			return value && value.length >= len ? undefined : msg;
		case "<=":
			return value && value.length <= len ? undefined : msg;
		case ">":
			return value && value.length > len ? undefined : msg;
		case "<":
			return value && value.length < len ? undefined : msg;
		case "!=":
			return value && value.length !== len ? undefined : msg;
		default:
			return undefined;
	}
};

export const emptyOrCheck = (checkFunc) => (value, allValues, props, name) => {
	if (!value || value === "") {
		return undefined;
	}
	const checkFuncReturn = checkFunc(value, allValues, props, name);
	return checkFuncReturn;
};

export const zeroOrCheck = (checkFunc) => (value, allValues, props, name) => {
	if (value === 0) {
		return undefined;
	}
	const checkFuncReturn = checkFunc(value, allValues, props, name);
	return checkFuncReturn;
};

export const checkNumber = (msg, number, symbol) => (value) => {
	msg = msg ? msg : `Maximum is ${number}`;
	switch (symbol) {
		case "==":
			return value === number ? undefined : msg;
		case ">=":
			return value >= number ? undefined : msg;
		case "<=":
			return value <= number ? undefined : msg;
		case ">":
			return value > number ? undefined : msg;
		case "<":
			return value < number ? undefined : msg;
		case "!=":
			return value !== number ? undefined : msg;
		default:
			return undefined;
	}
};

export const onlyNumbers = (msg) => (value) => {
	const numbers = /^[0-9]+$/;
	msg = msg ? msg : `Only numbers allowed`;
	return value && value.match(numbers) ? undefined : msg;
};

export const oneRequired = (msg, fields) => (_, allValues) => {
	const fieldsLabels = fields.map((f) => f.label).join(" or ");
	msg = msg ? msg : `This one of ${fieldsLabels} is required`;
	//result could be a boolean value, check explicitly for false value
	const validFields = fields.filter((field) => {
		let fieldValue = getValueFromObjString(field.path, allValues);
		fieldValue = typeof fieldValue === "string" || fieldValue instanceof String ? fieldValue.trim() : fieldValue;
		return !!(fieldValue || fieldValue === 0);
	});
	const result = validFields.length ? undefined : msg;
	return result;
};
export const getValueFromObjString = (path, object) => {
	if (!object || !path) {
		return undefined;
	}
	function index(obj, i) {
		if (!obj) {
			return undefined;
		}
		return obj[i];
	}
	return path.split(".").reduce((newObj, p) => index(newObj, p), object);
};

export const email = (msg) => (value) => {
	msg = msg ? msg : `Invalid email address`;

	/*eslint no-useless-escape: 0*/
	return value &&
		!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
			value
		)
		? msg
		: undefined;
};

export const mobile = (msg) => (value) => {
	// Should be a number. May include dashes, spaces, and parentheses.
	// Should not include more than 1 space in a row.
	// Should not include more than 1 hyphen in a row.
	msg = msg ? msg : "Invalid mobile number";

	if (!value || value === "") {
		return msg;
	}

	return /^[0-9+]+([ -]?[0-9()]*)*$/.test(value) ? undefined : msg;
};

export const mobileOrEmpty = (msg) => (value) => {
	// Can be empty or should be a number. May include dashes, spaces and parentheses.
	// Should not include more than 1 space in a row.
	// Should not include more than 1 hyphen in a row.
	msg = msg ? msg : "Invalid mobile number";

	if (!value || value === "") {
		return undefined;
	}

	return /^[0-9+]+([ -]?[0-9()]*)*$/.test(value) ? undefined : msg;
};

export const url = (msg) => (value) => {
	if (!value || value === "") {
		return undefined;
	}
	let pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i"
	); // fragment locator
	if (pattern.test(value)) {
		return undefined;
	}
	return msg;
};

export const mustBeUnique = (msg, id, arrayOfItems) => (value) => {
	msg = msg ? msg : `This field has to be unique`;

	if (arrayOfItems) {
		var result = arrayOfItems.find((item) => {
			return item._id !== id && item.code === value;
		});
	}
	result = result ? msg : undefined;
	return result;
};

export const validateGAId = (value) => {
	if (!value || value === "") {
		return undefined;
	}
	if (/(G|UA|YT|MO)-[0-9|A-Z]+/i.test(value)) {
		return undefined;
	}

	return "Not a valid Google Analytics ID";
};

export const validateFBId = (value) => {
	if (!value || value === "") {
		return undefined;
	}
	if (/[a-zA-Z0-9]{15}/i.test(value)) {
		return undefined;
	}
	return "Not a valid Facebook Pixel";
};

export const validateTTId = (value) => {
	if (!value || value === "") {
		return undefined;
	}
	if (/^[a-zA-Z0-9]{20}\b/i.test(value)) {
		return undefined;
	}
	return "Not a valid TikTok Pixel ID";
};

export const validateGoogleTagManger = (value) => {
	if (!value || value === "") {
		return undefined;
	}
	if (/^GTM-[A-Z|0-9]+$/i.test(value)) {
		return undefined;
	}
	return "Not a valid Google Tag Manager Id";
};

export const restrictCharacters = (characters) => (value) => {
	if (characters.some((character) => value.includes(character))) {
		return `Must not contain characters ${characters.join(",")}`;
	}
	return undefined;
};

export const allowCharacters = (characters) => (value) => {
	const notAllowedCharacters = value?.split("")?.filter((character) => !characters.includes(character));

	if (notAllowedCharacters?.length) {
		return `Must not contain characters ${notAllowedCharacters.join(",")}`;
	}
	return undefined;
};

export const mustBeGreaterThanZero = (value) => {
	if (!value || value <= 0) return "Must be greater than zero";

	return undefined;
};

export const mustNotBeZero = (value) => {
	if (!value || value === 0) return "Must not be zero";

	return undefined;
};

export const mustBeLessThanOrEqualTo = (max) => (value) => {
	if (value > max) return `Must be less than or equal to ${max}`;

	return undefined;
};

const customTaxNameRegex = /^[\w\s]+$/;
export const customTaxNameValidator = (value) => {
	if (customTaxNameRegex.test(value)) return undefined;

	return "Must only contain letters, numbers and spaces";
};

const noAltTextImageRegex = /<img(?![^>]*\balt=)[^>]*?>/;
export const accessibleImagesCheck = (value) => {
	if (noAltTextImageRegex.test(value)) {
		return "Accessibility warning: One or more images do not have an image description. Please click on the image and edit it to add a descriptive title.";
	}

	return undefined;
};

export const maxArrayLength = (numLong) => (value) => {
	if (Array.isArray(value) && value.length > numLong) return "Maximum limit reached";
	return undefined;
};
