import { Component } from "react";
import { Field } from "redux-form";
import { InputNumberField } from "@/components/Fields";
import { Label } from "@/components/text/Text";
import { getNested } from "@/utils/Object";

class TicketQuantity extends Component {
	constructor(props) {
		super(props);
		const { minPath, maxPath } = props;
		this.MinField = () => (
			<Field
				label="Min"
				name={minPath}
				component={InputNumberField}
				placeholder="Min"
				min={0}
				max={100000}
				validate={this.checkMin}
				allowsNullValue={true}
			/>
		);

		this.MaxField = () => (
			<Field
				label="Max"
				name={maxPath}
				component={InputNumberField}
				placeholder="Max"
				min={0}
				max={100000}
				validate={this.checkMax}
				allowsNullValue={true}
			/>
		);
	}

	componentDidMount() {
		null;
	}

	checkMin = () => {
		const { touch, maxPath } = this.props;
		touch(maxPath);
		return undefined;
	};

	checkMax = (maxTickets, allValues) => {
		const { minPath } = this.props;
		const minTickets = getNested(allValues, minPath);
		if (maxTickets === null) {
			return undefined;
		}
		if (maxTickets === 0) {
			return "Maximum must be greater than 0";
		}
		return Number(maxTickets) < Number(minTickets) ? `Maximum must be greater than minimum` : undefined;
	};

	render() {
		const { multiplesPath } = this.props;
		const MaxField = this.MaxField;
		const MinField = this.MinField;

		const MultiplierField = () => (
			<Field
				label="Restrict to multiples of"
				name={multiplesPath}
				component={InputNumberField}
				allowsNullValue
				min={2}
				max={10}
			/>
		);

		return (
			<div>
				<Label bold>Sales per order</Label>
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: 10 }}>
						<MinField />
					</div>
					<div style={{ marginRight: 10 }}>
						<MaxField />
					</div>
					<div>
						<MultiplierField />
					</div>
				</div>
			</div>
		);
	}
}
export default TicketQuantity;
