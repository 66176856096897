import IconReference from "@/services/IconReference";
import { Flex } from "@/ui/antd";
import styled from "@emotion/styled";
import { Location, locationDataByCountryCode } from "@hx/locations";
import { FC } from "react";

const StyledLocationLabel = styled(Flex)`
	font-size: 14px;
	font-weight: 500;
`;

type CurrencyLabelProps = {
	location: Location;
	noLabel?: boolean;
	noCurrency?: boolean;
};

export const CurrencyLabel: FC<CurrencyLabelProps> = ({ location, noCurrency, noLabel }) => {
	const currency = locationDataByCountryCode[location.toUpperCase() as Location]?.currency;
	const icon = String(IconReference[`${location.toLowerCase()}_flag`]);

	return (
		<StyledLocationLabel align="center" gap="sm">
			{!noLabel && <span>Currency:</span>}
			<img height="16" src={icon} alt={`${location} flag`} />
			{!noCurrency && <span>{currency}</span>}
		</StyledLocationLabel>
	);
};
