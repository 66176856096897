import { HumaniDate } from "@hx/dates";
import { Format } from "@hx/utilities";
import { Tag, Tooltip } from "@/ui/antd";
import LocationService from "@/services/LocationService";

const WaitlistStatus = ({ status, person }) => {
	let color = "#323232";
	let bgColor = "#788391";
	let tooltip = "User is waiting for tickets";
	let statusMsg = Format.camelCaseToSentenceCase(status);
	switch (status) {
		case "waiting": {
			color = "#ffffff";
			break;
		}
		case "notified": {
			color = "#ffffff";
			bgColor = "#217bc0";
			tooltip = "The user has been notified";

			const numberOfOffers = (person.offers && person.offers.length) || 0;
			if (numberOfOffers) {
				let tooltipStart = "Notified";
				if (numberOfOffers > 1) {
					tooltipStart = "Last notified";
					statusMsg = Format.camelCaseToSentenceCase(`${status} (${numberOfOffers})`);
				}

				const latestOffer = person.offers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
				tooltip = `${tooltipStart} on ${new HumaniDate(
					latestOffer.createdAt,
					"guess",
					LocationService.getLocation(true)
				).formatting.dateTime()}`;
			}
			break;
		}
		case "interested": {
			bgColor = "#ceb8eb";
			tooltip = "The user started to get tickets, but did not complete the order.";
			break;
		}
		case "passed": {
			bgColor = "#f4ad42";
			tooltip = "The user is no longer interested in buying tickets";
			break;
		}
		case "purchased": {
			bgColor = "#1ab87e";
			tooltip = "The user has purchased tickets";
			const purchasedOffer =
				person.offers && person.offers.length ? person.offers.find((offer) => offer.orderName) : null;
			if (purchasedOffer) {
				tooltip = `${tooltip}. Order number: ${purchasedOffer.orderName}`;
			}
			break;
		}
		default:
		// do nothing
	}

	return (
		<Tooltip title={tooltip}>
			<Tag color={bgColor} style={{ color }}>
				{statusMsg}
			</Tag>
		</Tooltip>
	);
};
export default WaitlistStatus;
