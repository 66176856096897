const SeatingArea = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
			<g fill="none" fillRule="evenodd">
				<path d="M0 0L20 0 20 20 0 20z" />
				<path
					fill="#67717E"
					d="M3.333 15v2.5h2.5V15h8.334v2.5h2.5v-5H3.333V15zm12.5-6.667h2.5v2.5h-2.5v-2.5zm-14.166 0h2.5v2.5h-2.5v-2.5zm12.5 2.5H5.833V4.167c0-.917.75-1.667 1.667-1.667h5c.917 0 1.667.75 1.667 1.667v6.666z"
				/>
			</g>
		</svg>
	);
};
export default SeatingArea;
