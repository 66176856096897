import {
	CLEAR_APP_MESSAGE,
	CREATE_APP_MESSAGE_ERROR,
	CREATE_APP_MESSAGE_REQUEST,
	CREATE_APP_MESSAGE_SUCCESS,
	DELETE_APP_MESSAGE_ERROR,
	DELETE_APP_MESSAGE_REQUEST,
	DELETE_APP_MESSAGE_SUCCESS,
	GET_APP_MESSAGE_ERROR,
	GET_APP_MESSAGE_REQUEST,
	GET_APP_MESSAGE_SUCCESS,
	LIST_APP_MESSAGE_ERROR,
	LIST_APP_MESSAGE_REQUEST,
	LIST_APP_MESSAGE_SUCCESS,
	SAVE_APP_MESSAGE_ERROR,
	SAVE_APP_MESSAGE_REQUEST,
	SAVE_APP_MESSAGE_SUCCESS
} from "../actions/scanningMessageActions";

const initialState = {
	appMessages: [],
	appMessage: false,
	success: false,
	error: false,
	loading: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_APP_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				appMessage: action.appMessage
			};
		case GET_APP_MESSAGE_REQUEST:
			return {
				...state,
				loading: true
			};
		case GET_APP_MESSAGE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case SAVE_APP_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case SAVE_APP_MESSAGE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_APP_MESSAGE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case CREATE_APP_MESSAGE_SUCCESS:
			return {
				...state,
				loading: false,
				error: false
			};
		case CREATE_APP_MESSAGE_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case CREATE_APP_MESSAGE_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			};

		case LIST_APP_MESSAGE_SUCCESS:
			return {
				...state,
				appMessages: action.messages,
				loading: false,
				error: false
			};
		case LIST_APP_MESSAGE_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};
		case LIST_APP_MESSAGE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		case DELETE_APP_MESSAGE_SUCCESS:
			return {
				...state,
				appMessages: action.appMessages,
				loading: false,
				error: false
			};
		case DELETE_APP_MESSAGE_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};
		case DELETE_APP_MESSAGE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case CLEAR_APP_MESSAGE:
			return {
				...state,
				appMessage: false
			};
		default:
			return state;
	}
};
