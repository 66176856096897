import { ContentBlock } from "@/components/AppBlocks";
import { H1 } from "@/components/text/Text";
import { useAppSelector } from "@/state/hooks";
import { Alert, Tabs } from "@/ui/antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { HistoryTab } from "./history/HistoryTab";
import { RefundTab } from "./RefundTab";

export const MassRefundHome: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const eventId = currentEvent.event?._id ?? "";

	return (
		<>
			<H1>Mass Refunds</H1>
			<Alert
				type="info"
				showIcon
				message={
					<>
						For individual order refunds, please navigate to the{" "}
						<Link to={`/console/my-events/${eventId}/orders`}>Orders page</Link>
					</>
				}
				style={{ marginBottom: "24px" }}
			/>
			<ContentBlock>
				<Tabs
					activeKey={location?.pathname}
					items={[
						{
							children: <RefundTab />,
							key: `/console/my-events/${eventId}/mass-refunds/refunds`,
							label: "Refund/Cancel"
						},
						{
							children: <HistoryTab />,
							key: `/console/my-events/${eventId}/mass-refunds/refund-history`,
							label: "History"
						}
					]}
					onChange={(value) => navigate({ pathname: value })}
				/>
			</ContentBlock>
		</>
	);
};
