import { dayjs } from "@/libs/dayjs";
import { css } from "@emotion/css";
import { Component } from "react";
import { Field } from "redux-form";
import DateField from "./DateField";
import DateTimeField from "./DateTimeField";

class DateRangeFields extends Component {
	checkStartDate = () => {
		const { touch, fieldPath, startField = "startDate" } = this.props;
		const startPath = fieldPath ? `${fieldPath}.${startField}` : startField;
		touch(startPath);
		return undefined;
	};

	checkEndDate = (endDate, allValues) => {
		const { fieldPath, startField = "startDate", allowEqual, maxDayRange } = this.props;
		const startDate = fieldPath ? allValues[fieldPath][startField] : allValues[startField];
		if (!startDate || !endDate) {
			return undefined;
		}
		let valid = !(endDate < startDate);
		if (allowEqual) {
			const startDateStr = dayjs(startDate).minute(0).second(0).millisecond(0).toISOString();
			const endDateStr = dayjs(endDate).minute(0).second(0).millisecond(0).toISOString();
			valid = endDateStr >= startDateStr;
		}
		if (maxDayRange) {
			const dayRange = Math.abs(
				dayjs.duration(dayjs(startDate).startOf("day").diff(dayjs(endDate).endOf("day"))).asDays()
			);
			if (dayRange > maxDayRange) {
				return `The dates must be no greater than ${maxDayRange} days apart`;
			}
		}
		const result = !valid ? `Must be after your start date` : undefined;
		return result;
	};

	render() {
		const {
			timezone,
			fieldPath,
			startLabel,
			endLabel,
			startDescription,
			endDescription,
			startField = "startDate",
			endField = "endDate",
			hideTime = false,
			tooltip,
			formName
		} = this.props;
		const fieldType = hideTime ? DateField : DateTimeField;
		const startPath = fieldPath ? `${fieldPath}.${startField}` : startField;
		const endPath = fieldPath ? `${fieldPath}.${endField}` : endField;
		const wrapper = css({
			display: "flex",
			justifyContent: "space-between",
			".ant-calendar-picker": {
				maxWidth: "108px !important"
			},
			"@media(max-width: 900px)": {
				display: "block"
			}
		});

		return (
			<div className={wrapper}>
				<div style={{ marginRight: 12 }}>
					<Field
						label={startLabel}
						description={startDescription}
						name={startPath}
						component={fieldType}
						tooltip={tooltip}
						placeholder="Start Date"
						timeOptions={{
							placeholder: "Start Time"
						}}
						timezone={timezone}
						displayFormat="Do MMM YYYY"
						validate={this.checkStartDate}
						formName={formName}
					/>
				</div>
				<div style={{ marginRight: 6 }}>
					<Field
						label={endLabel}
						description={endDescription}
						name={endPath}
						component={fieldType}
						tooltip={tooltip}
						placeholder="End Date"
						timeOptions={{
							placeholder: "End Time"
						}}
						timezone={timezone}
						displayFormat="Do MMM YYYY"
						validate={this.checkEndDate}
						formName={formName}
					/>
				</div>
			</div>
		);
	}
}
export default DateRangeFields;
