import { Modal } from "@/ui/antd";
import { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import DeleteScheduleForm, { formName } from "../forms/DeleteScheduleForm";

let DeleteButton = ({ handleSubmit, saveDetails }) => {
	return (
		<LegacyButton
			style={{
				float: "right"
			}}
			form={formName}
			key="done"
			type="warning"
			htmlType="button"
			ariaLabel="Done"
			onClick={handleSubmit(saveDetails)}
		>
			Delete
		</LegacyButton>
	);
};

DeleteButton = reduxForm({
	form: formName
})(DeleteButton);

const RemoveDateModal = (props) => {
	const [modalState, setModalState] = useState({ open: false, eventDate: {} });
	useEffect(() => {
		const open = props.open;
		const eventDate = props.eventDate;
		setModalState(() => ({ open, eventDate }));
	}, [props.open, props.eventDate]);
	const eventDate = modalState.eventDate || {};
	return (
		<Modal
			title="Delete schedule"
			open={modalState.open}
			destroyOnClose
			onCancel={props.onModalClose}
			footer={
				<>
					<LegacyButton
						style={{
							float: "left"
						}}
						key="cancel"
						onClick={props.onModalClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Cancel
					</LegacyButton>

					<DeleteButton eventDate={eventDate} saveDetails={props.onDeleteSchedule} />
				</>
			}
		>
			<DeleteScheduleForm onSubmit={props.onDeleteSchedule} eventDate={eventDate} />
		</Modal>
	);
};

export default RemoveDateModal;
