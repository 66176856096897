import { CaretDownOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table } from "@/ui/antd";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import Breadcrumb from "@/components/Breadcrumb";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import LinkButton from "@/components/buttons/LinkButton";
import TableActions from "@/components/table/TableActions";
import { H1, P } from "@/components/text/Text";
import { getTicketTypeTemplates, removeTicketTypeTemplate } from "@/state/actions/templateActions";
import EventSelectionModal from "../EventSelectionModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class TicketTypeTemplates extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.createTicketTypeTemplate = this.createTicketTypeTemplate.bind(this);
		this.deleteTicketTypeTemplate = this.deleteTicketTypeTemplate.bind(this);
		this.editTicketTypeTemplate = this.editTicketTypeTemplate.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.eventSelected = this.eventSelected.bind(this);
		this.state = {
			visible: false
		};
	}

	componentDidMount() {
		this.load();
	}

	load() {
		const { getTicketTypeTemplates, currentUser } = this.props;
		getTicketTypeTemplates(currentUser.user.id, true);
	}

	createTicketTypeTemplate(evt) {
		const { navigate } = this.props;
		if (evt.key === "new") {
			navigate(`/console/account/advanced/templates/ticket-type-templates/new`), { state: { isNew: true } };
		} else {
			this.setState({ visible: true });
		}
	}

	deleteTicketTypeTemplate(userId, templateTicketType) {
		this.props.removeTicketTypeTemplate(userId, templateTicketType._id);
	}

	editTicketTypeTemplate(templateTicketType) {
		this.props.navigate(`/console/account/advanced/templates/ticket-type-templates/${templateTicketType._id}`, {
			state: { isExisting: true }
		});
	}

	openModal() {
		this.setState({ visible: true });
	}

	closeModal() {
		this.setState({ visible: false });
	}

	eventSelected(event) {
		const { navigate } = this.props;
		navigate(`/console/account/advanced/templates/ticket-type-templates/${event}`, { state: { isFromEvent: true } });
	}

	render() {
		const { currentTemplates, currentUser, userEvents } = this.props;
		const { loading, error, templates } = currentTemplates;
		const title = "Ticket Type Template";
		return (
			<LoadErrorView loading={loading} error={error}>
				<Breadcrumb prevPath="/console/account/advanced/templates" prevTitle="Templates" currTitle={title} />
				<H1>{title}</H1>
				<ContentBlock>
					{!loading ? (
						<div>
							<TicketTypeTemplateTable
								ticketTypeTemplate={templates}
								createTicketTypeTemplate={this.createTicketTypeTemplate}
								currentUser={currentUser}
								deleteTicketTypeTemplate={this.deleteTicketTypeTemplate}
								editTemplateTicketType={this.editTicketTypeTemplate}
								load={this.load}
							/>
							<EventSelectionModal
								visible={this.state.visible}
								handleCancel={this.closeModal}
								handleSubmit={this.eventSelected}
								userEvents={userEvents.events || []}
							/>
						</div>
					) : null}
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

class TicketTypeTemplateTable extends Component {
	render() {
		const {
			ticketTypeTemplate,
			createTicketTypeTemplate,
			currentUser,
			deleteTicketTypeTemplate,
			editTemplateTicketType
		} = this.props;
		const dropdownMenu = {
			items: [
				{
					key: "new",
					label: "New Ticket Type Template"
				},
				{
					key: "From Event",
					label: "From Existing Event"
				}
			],
			onClick: createTicketTypeTemplate
		};

		const columns = [
			{
				title: "Template Name",
				dataIndex: "name",
				key: "name",
				render: (value, templateTicketType) => {
					return (
						<LinkButton
							style={{ color: "#353337" }}
							onClick={() => {
								editTemplateTicketType(templateTicketType);
							}}
							ariaLabel={value}
						>
							{value}
						</LinkButton>
					);
				}
			},
			{
				title: "Number of Ticket Types",
				dataIndex: "number",
				key: "number",
				render: (value) => {
					return <span>{value}</span>;
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, templateTicketType) => (
					<TableActions>
						<IconButton
							icon="edit"
							onClick={() => editTemplateTicketType(templateTicketType)}
							tooltip="Edit"
							showTooltip
							ariaLabel="Edit"
						/>

						<IconButton
							icon="delete"
							onClick={() => deleteTicketTypeTemplate(currentUser.user.id, templateTicketType)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				)
			}
		];
		return (
			<div>
				<P>
					Save time when building future events by creating a template for your ticket types! Include names of tickets,
					pricing and capacity. When creating your next event simply click import template in the ticket section to
					import this template.
				</P>
				<If condition={!ticketTypeTemplate || !ticketTypeTemplate.length}>
					<Then>
						<Dropdown menu={dropdownMenu}>
							<Button
								style={{
									border: "1px solid #353337",
									color: "#353337"
								}}
								icon={<PlusOutlined />}
								ariaLabel="New template"
							>
								New template
								<CaretDownOutlined style={{ fontSize: 12 }} />
							</Button>
						</Dropdown>
					</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<Table
									columns={columns}
									scroll={{ x: 800 }}
									dataSource={ticketTypeTemplate}
									rowKey="_id"
									pagination={false}
								/>
								<ButtonsBar label="" stackButtons>
									<Dropdown menu={dropdownMenu}>
										<Button
											style={{
												border: "1px solid #353337",
												color: "#353337"
											}}
											icon={<PlusOutlined />}
											ariaLabel="New Template"
										>
											New template
											<CaretDownOutlined style={{ fontSize: 12 }} />
										</Button>
									</Dropdown>
								</ButtonsBar>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.auth,
		userEvents: state.userEvents,
		currentTemplates: state.templates
	}),
	{
		getTicketTypeTemplates,
		removeTicketTypeTemplate
	}
)(withRouterHooks(TicketTypeTemplates));
