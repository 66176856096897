import { connect } from "react-redux";
import P from "@/components/text/P";
import { Link } from "react-router-dom";

const RecurringDatePrompt = ({ newEvent, dateCount, eventId }) => {
	if (!newEvent) {
		return (
			<div>
				<P>
					Your event has <b>{dateCount}</b> occurrences. You can manage them on your{" "}
					<Link to={`/console/my-events/${eventId}/info/recurring-dates`}>schedule</Link>
				</P>
			</div>
		);
	}
	return (
		<P>
			You will set up your recurring event dates in the next step after clicking <b>“Save & continue”</b>. Event details
			and ticket types will apply to all occurrences.
		</P>
	);
};

export default connect(
	(state) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(RecurringDatePrompt);
