import InputWrapper from "@/components/fields/helpers/InputWrapper";
import { Flex, Input, InputRef, Tag, Tooltip } from "@/ui/antd";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { WrappedFieldArrayProps } from "redux-form";

const outerWrapperStyle: CSSProperties = {
	display: "flex",
	flexDirection: "column",
	gap: 24
};

const baseItemStyle: CSSProperties = {
	height: 36,
	fontSize: 14,
	lineHeight: "32px",
	borderRadius: 4,
	marginInlineEnd: 8,
	verticalAlign: "top",
	padding: "0 12px"
};

const inputWrapperStyle: CSSProperties = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "8px"
};

const inputStyle: CSSProperties = {
	...baseItemStyle,
	maxWidth: 120,
	padding: undefined
};

const addItemStyle = {
	...baseItemStyle,
	backgroundColor: "#ffb18f",
	border: "none",
	lineHeight: "34px",
	cursor: "pointer",
	":hover": {
		backgroundColor: "#F9A783"
	}
};

const disabledAddItemStyle = {
	...addItemStyle,
	backgroundColor: "#ebebeb",
	color: "#838e9c",
	":hover": {
		backgroundColor: "#ebebeb"
	}
};

const itemStyle: CSSProperties = {
	...baseItemStyle,
	userSelect: "none"
};

type KeywordsProps = {
	keywords?: string[];
	disabled?: boolean;
	maxLength?: number;
	maxCharacterLength?: number;
	onDeleteCallback: (index: number) => void;
	onInsertCallback: (keyword: string) => void;
	onEditCallback: (index: number, updatedKeyword: string) => void;
	format?: (value: string) => string;
};

const Keywords = ({
	keywords = [],
	disabled,
	maxLength,
	maxCharacterLength,
	onDeleteCallback,
	onInsertCallback,
	onEditCallback,
	format = (value: string) => value
}: KeywordsProps) => {
	const [inputVisible, setInputVisible] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [editInputIndex, setEditInputIndex] = useState(-1);
	const [editInputValue, setEditInputValue] = useState("");
	const inputRef = useRef<InputRef>(null);
	const editInputRef = useRef<InputRef>(null);

	const limitReached = maxLength && keywords.length >= maxLength;
	const allowAddItems = !limitReached && !disabled;

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	useEffect(() => {
		editInputRef.current?.focus();
	}, [editInputValue]);

	const _handleRemoveItem = (removedItem: string) => {
		const deleteItemIndex = keywords.indexOf(removedItem);
		onDeleteCallback(deleteItemIndex);
		setInputVisible(false);
	};

	const showInput = () => {
		setInputVisible(true);
		setTimeout(() => {
			inputRef.current?.focus();
		}, 200);
	};

	const formatInput = (input: string) => {
		const truncated = maxCharacterLength ? input.substring(0, maxCharacterLength) : input;
		return format(truncated);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(formatInput(e.target.value));
	const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setEditInputValue(formatInput(e.target.value));

	const handleInputConfirm = (e?: React.KeyboardEvent<HTMLInputElement> | undefined) => {
		e?.preventDefault();
		if (inputValue && keywords.indexOf(inputValue) === -1) {
			onInsertCallback(inputValue);
		}
		setInputVisible(false);
		setInputValue("");
	};

	const handleEditInputConfirm = (e?: React.KeyboardEvent<HTMLInputElement> | undefined) => {
		e?.preventDefault();
		if (editInputValue && keywords.indexOf(editInputValue) === -1) {
			onEditCallback(editInputIndex, editInputValue);
		}
		setEditInputIndex(-1);
		setEditInputValue("");
	};

	const newItemButton = (
		<Tag
			className={css(allowAddItems ? (addItemStyle as any) : (disabledAddItemStyle as any))}
			onClick={() => allowAddItems && showInput()}
		>
			<PlusOutlined /> New keyword
		</Tag>
	);

	return (
		<div style={outerWrapperStyle}>
			<Flex align="center" style={{ marginTop: "12px" }}>
				{keywords?.map((item, index) => {
					if (editInputIndex === index) {
						return (
							<div style={inputWrapperStyle}>
								<Input
									ref={editInputRef}
									key={item}
									size="small"
									style={inputStyle}
									value={editInputValue}
									onChange={handleEditInputChange}
									onBlur={() => handleEditInputConfirm()}
									onPressEnter={handleEditInputConfirm}
								/>
								<span>
									{editInputValue.length}/{maxCharacterLength}
								</span>
							</div>
						);
					}

					const isLongTag = item.length > 20;
					const tagElem = (
						<Tag key={item} closable style={itemStyle} onClose={() => _handleRemoveItem(item)}>
							<span
								onDoubleClick={(e) => {
									e.preventDefault();
									setEditInputIndex(index);
									setEditInputValue(item);
								}}
							>
								{isLongTag ? `${item.slice(0, 20)}...` : item}
							</span>
						</Tag>
					);
					return isLongTag ? (
						<Tooltip title={item} key={item}>
							{tagElem}
						</Tooltip>
					) : (
						tagElem
					);
				})}
				{inputVisible && allowAddItems ? (
					<>
						<Input
							ref={inputRef}
							type="text"
							size="small"
							style={inputStyle}
							value={inputValue}
							onChange={handleInputChange}
							onBlur={() => handleInputConfirm()}
							onPressEnter={(e) => {
								handleInputConfirm(e);
								showInput();
							}}
						/>
						<span>
							{inputValue.length}/{maxCharacterLength}
						</span>
					</>
				) : (
					<>{!allowAddItems ? <Tooltip title="Maximum limit reached">{newItemButton}</Tooltip> : newItemButton}</>
				)}
			</Flex>
			{maxLength && (
				<span>
					{keywords.length}/{maxLength} keywords
				</span>
			)}
		</div>
	);
};

type IKeywordsFieldInput = WrappedFieldArrayProps & {
	label: string;
	description: string;
	parentValidate?: (values: string[]) => string | undefined;
	childValidate?: (value: string) => string | undefined;
	meta?: {
		dirty?: any;
		error?: string | undefined;
	};
	required?: boolean;
	maxLength?: number;
	maxCharacterLength?: number;
	changeFieldValue: (key: string, value: string) => void;
	format?: (value: string) => string;
};

const KeywordsFieldWrapper = ({
	fields,
	label,
	description,
	meta,
	required,
	maxLength = 10,
	maxCharacterLength = 25,
	changeFieldValue,
	format
}: IKeywordsFieldInput) => {
	const { dirty, error } = meta ?? {};

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<InputWrapper
			label={label}
			description={description}
			touched={dirty}
			error={error}
			id={fields.name}
			required={required}
		>
			<Keywords
				disabled={!!error}
				onDeleteCallback={(index: number) => fields.remove(index)}
				onInsertCallback={(keyword: string) => fields.push(keyword)}
				onEditCallback={(index: number, updatedKeyword: string) => {
					const newKeywords: string[] = [...fields.getAll()];
					newKeywords[index] = updatedKeyword;
					changeFieldValue(`${fields.name}[${index}]`, updatedKeyword);
				}}
				keywords={fields.getAll()}
				maxLength={maxLength}
				maxCharacterLength={maxCharacterLength}
				format={format}
			/>
		</InputWrapper>
	);
};

export default KeywordsFieldWrapper;
