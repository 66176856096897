import ButtonsBar from "@/components/buttons/ButtonsBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { dayjs } from "@/libs/dayjs";
import { Modal } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import ScheduleForm, { formName } from "../forms/ScheduleForm";

let SaveButton = ({ handleSubmit, saveDetails }) => {
	return (
		<LegacyButton
			form={formName}
			dataCy="schedule-save"
			key="done"
			type="primary"
			htmlType="button"
			ariaLabel="Done"
			onClick={handleSubmit(saveDetails)}
		>
			Save
		</LegacyButton>
	);
};

SaveButton = reduxForm({
	form: formName
})(SaveButton);

const NewScheduleModal = (props) => {
	const [modalState, setModalState] = useState({
		open: false,
		date: new Date()
	});
	useEffect(() => {
		const open = props.open;
		const date = props.date;
		setModalState(() => ({ open, date }));
	}, [props.open, props.date]);

	const { timezone } = props.currentEvent.event;

	const startEndDateData = props.onDayGridMonth
		? {
				startDate: dayjs(modalState.date).add(1, "hour").tz(timezone, true).set("h", 19),
				endDate: dayjs(modalState.date).add(1, "hour").tz(timezone, true).set("h", 22)
		  }
		: {
				startDate: dayjs(modalState.date).tz(timezone, true),
				endDate: dayjs(modalState.date).tz(timezone, true).add(3, "hours")
		  };
	return (
		<Modal
			open={modalState.open}
			title="Add dates"
			destroyOnClose
			onCancel={props.onModalClose}
			// onOk={onClose}
			footer={
				<ButtonsBar footer>
					<LegacyButton
						style={{
							float: "left"
						}}
						key="cancel"
						onClick={props.onModalClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Cancel
					</LegacyButton>
					<SaveButton saveDetails={props.onNewSchedule} />
				</ButtonsBar>
			}
		>
			<ScheduleForm
				onSubmit={props.onNewSchedule}
				scheduleValues={{
					type: "ONCE",
					skip: 1,
					exactPosition: true,
					...startEndDateData
				}}
			/>
		</Modal>
	);
};

export default connect((state) => {
	return {
		currentEvent: state.currentEvent
	};
}, {})(NewScheduleModal);
