import MerchandiseService from "@/services/MerchandiseService";
import NewOrderService, { AvailableSteps } from "@/services/NewOrderService";
import { css } from "@emotion/css";
import { Alert, Checkbox, Input, InputNumber, Modal, Select, Tooltip, message } from "@/ui/antd";
import React, { useEffect, useState } from "react";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getConfig } from "@/config";
import { getCurrencySymbol } from "@/utils/Format";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "@/ui";

type TicketType = {
	name: string;
	_id: string;
	price: number;
	type: "fixed" | "package";
	priceOverride?: number;
	isDonation: boolean;
	deleted: boolean;
	disabled: boolean;
};
type Occurrence = {
	_id: string;
	startDate: string;
	display: { combined: string };
	disabled: boolean;
	deleted: boolean;
};
type Occurrences = Occurrence[];
type EventProp = {
	_id: string;
	slug: string;
	dates: Occurrences;
	ticketTypes: TicketType[];
	packagedTickets: TicketType[];
	name: string;
	userId: string;
};
type Props = {
	event: EventProp;
};

type AddOnType = {
	addOnTypeId: string;
	itemName: string;
	description: string;
	options: {
		addOnOptionId: string;
		maxAmountPerOrder: number;
		optionName: string;
		price: number;
		soldOut: boolean;
	}[];
};

const formStyle = css(`
    background: white;
    max-width: 800px;
    min-width: 400px;
    margin: 0 auto;
    padding: 16px;
    label {
      display: block;
      padding-top: 4px;
    }
`);

const buttonBarStyle = css(`
  display: flex;
  flex-direction: column;
  > button {
    align-self: flex-end;
  }
`);

const ticketTypeStyle = css(`
  display: flex;
  padding: 4px 4px 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 4px 0;

   > div {
    display: flex;
    white-space: nowrap;
    
    .ant-input-number-group-wrapper {
      vertical-align: middle;
      > div {
        max-width: 120px;
      }
    }

    > label {
      margin-left: 10px;
    }
  }
`);

const settingsStyles = css(`
  > label {
    display: flex;
    flex-direction: column;
  }
`);

const checkboxLabelStyle = css(`
  display: flex;
  align-items: baseline;
  gap:8px;
  flex-direction: row !important;
  
  > label {
    display: inline-flex;
  }
`);

async function createManualOrder(eventId: string, formData: FormData) {
	const formValues = Object.fromEntries(formData.entries());
	const { eventDateId, paymentType, note, skipEmails, ...ticketTypes } = formValues;
	//round 1 looks like { "5f9b1b7b1c9d440000d1e2a1_quantity": "1", "5f9b1b7b1c9d440000d1e2a1_price": "10", ...}
	let clientDonation = 0;
	const requestedTickets = Object.entries(
		//round 2 ticketTypes looks like { "5f9b1b7b1c9d440000d1e2a1": { quantity: "1", price: "10" }}
		Object.entries(ticketTypes).reduce((selection, [id_key, value]) => {
			const [ticketTypeId, type, key] = id_key.split("_");
			if (ticketTypeId === "donation") {
				clientDonation = Number(value);
				return selection;
			}
			return {
				...selection,
				[ticketTypeId]: { type, ...selection[ticketTypeId], [key]: value }
			};
		}, {} as { [key: string]: any })

		//remove any tickets with a quantity of 0, probs not necessary but make the request easier to read
	).flatMap(([id, selection]) =>
		selection.quantity === "0"
			? []
			: [
					{
						...selection,
						quantity: Number(selection.quantity),
						[selection.type === "fixed" ? "ticketTypeId" : "packageTypeId"]: id
					}
			  ]
	);

	const orderResponse = await NewOrderService.createManualOrder(
		eventId,
		eventDateId.toString(),
		requestedTickets,
		clientDonation,
		paymentType.toString(),
		note.toString(),
		skipEmails === "on"
	);

	return orderResponse;
}

function addOnManualOrder(userId: string, orderId: string, formData: FormData) {
	const { ...addOns } = Object.fromEntries(formData.entries());

	const requestedAddOns = Object.entries(
		Object.entries(addOns).reduce((selection, [id_key, value]) => {
			const [addOnTypeKey, key] = id_key.split("_");

			const [addOnTypeId, addOnOptionId] = addOnTypeKey.split("-");
			return {
				...selection,
				[addOnTypeKey]: { ...selection[addOnTypeKey], [key]: Number(value), addOnTypeId, addOnOptionId }
			};
		}, {} as { [key: string]: any })
	).flatMap(([, selection]) => (selection.quantity === 0 ? [] : [{ ...selection }]));

	return MerchandiseService.addAddOnsToManualOrder(userId, orderId, requestedAddOns);
}

const TicketRow = (props: TicketType) => {
	const [price, setPrice] = useState(props.priceOverride ?? props.price);
	useEffect(() => {
		setPrice(props.priceOverride ?? props.price);
	}, [props.priceOverride]);
	return (
		<fieldset className={ticketTypeStyle}>
			<strong>{props.name}</strong>
			<div>
				<label>
					Quantity <InputNumber name={`${props._id}_${props.type}_quantity`} defaultValue={0} min={0} max={200} />
				</label>
				<label>
					Price{" "}
					<InputNumber
						name={`${props._id}_${props.type}_price`}
						min={0}
						defaultValue={props.priceOverride || props.price}
						addonBefore={getCurrencySymbol()}
						value={price}
						onChange={(num) => setPrice(num || 0)}
					/>
				</label>
			</div>
		</fieldset>
	);
};

const AddOnRow = (props: AddOnType) => {
	return (
		<fieldset className={ticketTypeStyle}>
			<strong>{props.itemName}</strong>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<strong>Options</strong>
				<div style={{ display: "flex", flexDirection: "column", marginLeft: 16 }}>
					{props.options.map((option) => {
						const keyPrefix = `${props.addOnTypeId}-${option.addOnOptionId}`;
						return (
							<div style={{ flexDirection: "column", whiteSpace: "nowrap" }}>
								<strong>{option.optionName}</strong>
								<div style={{ display: "flex", flexDirection: "row", marginLeft: 16 }}>
									<label>
										Quantity <InputNumber name={`${keyPrefix}_quantity`} min={0} max={200} defaultValue={0} />
									</label>
									<label style={{ marginLeft: 10 }}>
										Price{" "}
										<InputNumber
											name={`${keyPrefix}_price`}
											min={0}
											defaultValue={option.price}
											addonBefore={getCurrencySymbol()}
										/>
									</label>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</fieldset>
	);
};

export const TicketListingForm = ({
	ticketTypes,
	occurrences,
	packagedTickets,
	onSubmit
}: {
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
	ticketTypes: TicketType[];
	occurrences: Occurrences;
	packagedTickets: TicketType[];
}) => {
	const [priceOverride, setPriceOverride] = useState<undefined | number>(undefined);
	const [dateId, setDateId] = useState<string>(occurrences[0]._id);
	const donation = ticketTypes.find((tt) => tt.isDonation);
	return (
		<form onSubmit={onSubmit}>
			<div className={formStyle}>
				<input type="hidden" name="eventDateId" id="eventDateId" value={dateId} />
				<div className={settingsStyles}>
					<label>
						<strong>Payment type *</strong>
						<input type="hidden" name="paymentType" id="paymentType" value="cash" />
						<Select
							defaultValue="cash"
							onChange={(value: string) => {
								//antd lol https://github.com/ant-design/ant-design/issues/36489
								const paymentTypeInput = document.querySelector<HTMLInputElement>("#paymentType");
								if (paymentTypeInput) {
									paymentTypeInput.value = value;
									if (value === "complimentary") setPriceOverride(0);
								}
							}}
							options={[
								{ value: "cash", label: "Cash" },
								{ value: "complimentary", label: "Complimentary" },
								{ value: "voucher", label: "Voucher" },
								{ value: "bankDeposit", label: "Bank Deposit" },
								{ value: "eftpos", label: "EFTPOS" },
								{ value: "payPal", label: "PayPal" },
								{ value: "noPaymentNecessary", label: "No payment necessary" },
								{ value: "other", label: "Other" }
							]}
						/>
					</label>
				</div>
				<label>
					<strong>Event date</strong>
					<Select
						onChange={(value: string) => {
							//antd lol https://github.com/ant-design/ant-design/issues/36489
							const eventDateIdInput = document.querySelector<HTMLInputElement>("#eventDateId");
							if (eventDateIdInput) setDateId(value);
						}}
						style={{ width: "100%" }}
						defaultValue={occurrences?.[0]._id}
						options={occurrences.map((date) => {
							return { value: date._id, label: date.display.combined };
						})}
					/>
				</label>
				<label>
					<strong>Tickets</strong>
					{ticketTypes
						.filter((ticketType) => !ticketType.isDonation && !ticketType.disabled)
						.map((ticketType) => (
							<TicketRow priceOverride={priceOverride} key={ticketType._id} {...ticketType} type="fixed" />
						))}
				</label>
				{packagedTickets.length > 0 && (
					<label>
						<strong>Packages</strong>
						{packagedTickets
							.filter((packagedTicket) => !packagedTicket.deleted && !packagedTicket.disabled)
							.map((packagedTicket) => (
								<TicketRow priceOverride={priceOverride} key={packagedTicket._id} {...packagedTicket} type="package" />
							))}
					</label>
				)}
				{donation && (
					<label>
						<strong>Donation</strong>
						<fieldset className={ticketTypeStyle}>
							<strong>{donation.name}</strong>
							<InputNumber name="donation_fixed_price" min={0} defaultValue={0} addonBefore={getCurrencySymbol()} />
						</fieldset>
					</label>
				)}
				<div className={settingsStyles}>
					<label className={checkboxLabelStyle}>
						<strong>Skip emails</strong>
						<Checkbox name="skipEmails" />
					</label>
					<label>
						<strong>Notes</strong>
						<Input.TextArea name="note" />
					</label>
				</div>
				<div className={buttonBarStyle}>
					<Alert
						style={{ marginTop: 12, marginBottom: 12 }}
						message="Warning"
						description="Manual orders ignore any capacity and date limits so it is possible to overbook your event"
						type="warning"
						showIcon
					/>
					<LegacyButton htmlType="submit">Continue manual order</LegacyButton>
				</div>
			</div>
		</form>
	);
};

const AddOnListingForm = ({
	addOnTypes,
	onSubmit
}: {
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
	addOnTypes: AddOnType[];
}) => {
	return (
		<form onSubmit={onSubmit}>
			<div className={formStyle}>
				<label>
					<strong>Add ons</strong>
					{addOnTypes.map((addOnType) => (
						<AddOnRow key={addOnType.addOnTypeId} {...addOnType} />
					))}
				</label>
				<div className={buttonBarStyle}>
					<Alert
						style={{ marginTop: 12, marginBottom: 12 }}
						message="Warning"
						description="Manual orders ignore any capacity and date limits so it is possible to overbook your event"
						type="warning"
						showIcon
					/>
					<LegacyButton htmlType="submit">Continue manual order</LegacyButton>
				</div>
			</div>
		</form>
	);
};

const getIframeUrl = (
	{ slug, location, orderId }: { slug: string; location: string; orderId: string },
	availableSteps: AvailableSteps
) => {
	if (availableSteps.includes("seatingMaps")) {
		return `${getConfig("TICKETING_URL")}/${slug}/${location.toLowerCase()}/${orderId}/seating`;
	}
	return `${getConfig("TICKETING_URL")}/${slug}/${location.toLowerCase()}/${orderId}/details`;
};

const hasAllZeroQuantities = (values: { [k: string]: FormDataEntryValue }) => {
	return Object.keys(values)
		.filter((key) => key.includes("quantity"))
		.every((key) => values[key] === "0");
};

const NewManualOrder = (props: Props) => {
	const [open, setOpen] = useState(false);

	const promptManualOrder = async () => {
		setOpen(true);
	};
	const createOrder = async (formData: FormData) => {
		const res = await createManualOrder(props.event._id, formData);
		if (res.success) {
			if (!res.success) message.error("Error creating order");
			if (res.availableSteps.includes("addOns")) {
				setManualOrderStepState({
					step: "addOnSelection",
					addOns: await MerchandiseService.getAddOnListing(props.event.userId, res.orderId),
					location: res.location,
					orderId: res.orderId
				});
			} else {
				setManualOrderStepState({
					step: "iframe",
					iframeSrc: getIframeUrl(
						{
							orderId: res.orderId,
							location: res.location,
							slug: props.event.slug
						},
						res.availableSteps
					)
				});
			}
		}
	};

	const [manualOrderState, setManualOrderStepState] = useState<
		| { step: "ticketSelection" }
		| { step: "addOnSelection"; addOns: AddOnType[]; location: string; orderId: string }
		| { step: "iframe"; iframeSrc: string }
	>({ step: "ticketSelection" });
	const [quantityWarn, setQuantityWarn] = useState(false);
	const [orderFormData, setOrderFormData] = useState<FormData>();
	const occurrences = props.event.dates.filter((date) => !date.deleted && !date.disabled);
	if (!occurrences.length) {
		return (
			<Tooltip title="Event is in the past. Cannot make manual orders">
				<Button variant="tertiary" aria-label="New Manual Order Disabled" disabled={true} iconLeft={<PlusOutlined />}>
					New manual order
				</Button>
			</Tooltip>
		);
	}
	const bodyHeight = "calc(100vh - 115px)";
	const bodyStyle = {
		padding: 0,
		height: bodyHeight,
		"overflow-y": "scroll"
	};
	const eventName = props.event.name;
	const onCancel = () => {
		setManualOrderStepState({ step: "ticketSelection" });
		setOpen(false);
	};
	const onReset = () => setManualOrderStepState({ step: "ticketSelection" });
	const footer =
		manualOrderState.step !== "iframe" ? (
			<LegacyButton onClick={onCancel} ariaLabel="Close">
				Close
			</LegacyButton>
		) : (
			<LegacyButton style={{ float: "left" }} onClick={onReset} ariaLabel="Reset">
				Reset
			</LegacyButton>
		);
	return (
		<>
			<Button variant="tertiary" aria-label="New Manual Order" iconLeft={<PlusOutlined />} onClick={promptManualOrder}>
				New manual order
			</Button>
			{quantityWarn && (
				<Modal
					title={
						<div>
							<ExclamationCircleOutlined /> Warning
						</div>
					}
					open={quantityWarn}
					onCancel={() => setQuantityWarn(false)}
					onOk={() => {
						if (orderFormData) createOrder(orderFormData);
						setQuantityWarn(false);
					}}
					style={{ width: "300px", height: "500px" }}
				>
					<div>No tickets in this manual order have a quantity. Are you sure you want to proceed?</div>
				</Modal>
			)}

			{open && (
				<Modal
					title={`New manual order for ${eventName}`}
					style={{ minWidth: "100%", top: 0, height: bodyHeight }}
					open={open}
					footer={footer}
					bodyStyle={bodyStyle}
					onCancel={onCancel}
				>
					{manualOrderState.step === "iframe" && (
						<iframe src={manualOrderState.iframeSrc} style={{ width: "100%", height: "100%" }} />
					)}

					{manualOrderState.step === "ticketSelection" && (
						<TicketListingForm
							occurrences={occurrences}
							{...props.event}
							onSubmit={async (e) => {
								e.preventDefault();
								const formData = new FormData(e.nativeEvent.target as HTMLFormElement);
								const formValues = Object.fromEntries(formData.entries());
								setOrderFormData(formData);
								const allEmpty = hasAllZeroQuantities(formValues);
								if (allEmpty) {
									setQuantityWarn(true);
									return;
								}
								createOrder(formData);
							}}
						/>
					)}

					{manualOrderState.step === "addOnSelection" && (
						<AddOnListingForm
							addOnTypes={manualOrderState.addOns}
							onSubmit={async (e) => {
								e.preventDefault();
								const formData = new FormData(e.nativeEvent.target as HTMLFormElement);
								const result = await addOnManualOrder(props.event.userId, manualOrderState.orderId, formData);
								setManualOrderStepState({
									step: "iframe",
									iframeSrc: getIframeUrl(
										{ orderId: manualOrderState.orderId, location: manualOrderState.location, slug: props.event.slug },
										result.availableSteps
									)
								});
							}}
						/>
					)}
				</Modal>
			)}
		</>
	);
};

export default NewManualOrder;
