import { Field } from "redux-form";
import { LegacyInputField } from "@/components/Fields";

interface IRenameValuesProps {
	questionIndex: number;
}

/**
 * Rename the question (Only used in terms and condition)
 *
 * @param {string} { questionIndex }
 * @returns rename field
 */
const RenameValues = ({ questionIndex }: IRenameValuesProps) => {
	return (
		<div>
			<Field
				name={`additionalQuestions[${questionIndex}].rename`}
				label="Rename Question"
				component={LegacyInputField}
				description="Rename 'Terms & Conditions'"
				style={{ width: "100%" }}
				size="small"
			/>
		</div>
	);
};

export default RenameValues;
