import { useAppDispatch } from "@/state/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { FullPageWrap, LoadingSpinner } from "../../components/AppBlocks";
import { H2 } from "../../components/text/Text";
import { authenticate } from "../../state/actions/salesforceActions";
import { getUrlParameter } from "../../utils/Urls";

export const ConnectSalesforce = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const code = getUrlParameter("code");
	const state = getUrlParameter("state")?.split(":");

	useEffect(() => {
		const userId = state?.[0];
		const isSandboxAccount = state?.[1];

		if (code) {
			dispatch(authenticate(userId, code, isSandboxAccount, navigate));
		}
	}, [code, state]);

	return (
		<FullPageWrap>
			<div>
				<H2 style={{ textAlign: "center" }}>Connecting Salesforce</H2>
				<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
			</div>
		</FullPageWrap>
	);
};
