import { BasicTab } from "./BasicTab";
import { SliderTab } from "./SliderTab";
import { ToggleTab } from "./ToggleTab";

export enum TabTypes {
	BASIC_TAB = "BASIC_TAB",
	TOGGLE_TAB = "TOGGLE_TAB",
	SLIDER_TAB = "SLIDER_TAB"
}

export class TabFactory {
	static constructTab(tabType: TabTypes, props: TabProps) {
		if (tabType === TabTypes.BASIC_TAB) {
			return <BasicTab {...props} key={props.key} />;
		}
		if (tabType === TabTypes.TOGGLE_TAB) {
			return <ToggleTab {...props} key={props.key} />;
		}
		if (tabType === TabTypes.SLIDER_TAB) {
			return <SliderTab {...props} key={props.key} />;
		}
		return <BasicTab {...props} key={props.key} />;
	}
}

export type TabProps = {
	key: string;
	icon: Icon;
	name: string;
	first: boolean;
	last: boolean;
	onClick?: () => void;
	selected?: boolean;
	methodName: string;
	endMethodName?: string;
};
