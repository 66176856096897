import { Field } from "redux-form";
import { SelectField } from "@/components/Fields";

const DayEndsAt = () => {
	return (
		<Field
			name="dayEndsAt"
			displayFormat="HH:mm"
			label="Day ends at"
			component={SelectField}
			options={[
				{ value: "midnight", label: "Midnight" },
				{ value: "17:00", label: "5:00pm" },
				{ value: "17:30", label: "5:30pm" },
				{ value: "18:00", label: "6:00pm" },
				{ value: "18:30", label: "6:30pm" },
				{ value: "19:00", label: "7:00pm" },
				{ value: "19:30", label: "7:30pm" },
				{ value: "20:00", label: "8:00pm" }
			]}
		/>
	);
};

export default DayEndsAt;
