import LoadErrorView from "@/components/LoadErrorView";
import SearchRefinements from "@/components/SearchRefinements";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import {
	deleteAllDiscountCodes,
	deleteDiscountCodes,
	duplicateDiscountCode,
	editDiscountCode,
	getDiscountCodes
} from "@/state/actions/discountCodesActions";
import { updateSearch } from "@/state/actions/searchActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Button, CopyField, Spacer } from "@/ui";
import { ColumnProps, Flex, Modal, Popconfirm, Table, Tooltip } from "@/ui/antd";
import { currency } from "@/utils/Format";
import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	TeamOutlined
} from "@ant-design/icons";
import { FC } from "react";
import { Else, If, Then } from "react-if";
import { Link, useNavigate } from "react-router-dom";

const confirm = Modal.confirm;

type DiscountCode = {
	_id?: string;
	appliesTo: string[];
	code: string;
	createdAt: string;
	discount: number;
	discountType: string;
	enabled: boolean;
	eventId: string;
	key: string;
	location: string;
	quantity: number;
	type: string;
	updatedAt: string;
};

export const DiscountCodeTable: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const search = useAppSelector((state) => state.search);
	const discountCodes = useAppSelector((state) => state.discountCodes);

	const ticketingUrl = getConfig("TICKETING_URL");

	if (!currentEvent.event) return null;

	const handleDeleteDiscountCodeClicked = (eventId: string | undefined, discountCode: DiscountCode) => {
		dispatch(deleteDiscountCodes(eventId, discountCode));
	};

	const handleDuplicateDiscountCodeClicked = ({ _id, ...rest }: DiscountCode) => {
		rest.type = "single";
		dispatch(duplicateDiscountCode(currentEvent.event?._id, rest));
	};

	const handleEditDiscountCodeClicked = (discountCode: DiscountCode) => {
		dispatch(editDiscountCode(discountCode));
		navigate(`/console/my-events/${currentEvent.event?._id}/discounts/codes/${discountCode._id}`);
	};

	const handleCreateCodeClicked = () => {
		navigate(`/console/my-events/${currentEvent.event?._id}/discounts/codes/new`);
	};

	const handleLoad = (page = 1) => {
		dispatch(getDiscountCodes(currentEvent.event?._id, page, search.query));
	};

	const handleLoadPage = (page: number) => {
		dispatch(updateSearch({ page }, true));
	};

	const handleDeleteAllClicked = () => {
		confirm({
			title: "Delete All?",
			content: "Are you sure you wish to delete all your discount codes?",
			onOk: () => dispatch(deleteAllDiscountCodes(currentEvent.event?._id)),
			onCancel: () => null
		});
	};

	const columns: ColumnProps<DiscountCode>[] = [
		{
			dataIndex: "code",
			key: "code",
			title: "Code",
			render: (value, discountCode) => (
				<a aria-label={value} onClick={() => handleEditDiscountCodeClicked(discountCode)}>
					{value}
				</a>
			)
		},
		{
			title: "Link",
			dataIndex: "code",
			key: "link",
			width: 300,
			render: (value) => {
				const link = `${ticketingUrl}/${currentEvent.event?.slug}?discountcode=${encodeURIComponent(value)}`;
				return <CopyField displayValue={link} />;
			}
		},
		{
			title: "Discount",
			dataIndex: "discount",
			key: "discount",
			width: 120,
			render: (value, discountCode) => {
				return discountCode.discountType === "percent" ? <span>{value}%</span> : <span>{currency(value)}</span>;
			}
		},
		{
			title: "Enabled",
			dataIndex: "enabled",
			key: "enabled",
			width: 120,
			render: (value) => (
				<div style={{ marginLeft: "16px" }}>
					{value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f70e0e" />}
				</div>
			)
		},

		{
			title: "Action",
			dataIndex: "",
			key: "actions",
			fixed: "right",
			width: 200,
			render: (_, discountCode) => (
				<Flex gap="sm">
					<Tooltip title="Edit">
						<Button
							aria-label="Edit"
							dataCy={`discount-${discountCode.code}-edit`}
							iconOnly
							onClick={() => handleEditDiscountCodeClicked(discountCode)}
							variant="text"
						>
							<EditOutlined style={{ fontSize: 18 }} />
						</Button>
					</Tooltip>
					<Popconfirm
						title="Are you sure you want to delete this discount code?"
						onConfirm={() => handleDeleteDiscountCodeClicked(currentEvent.event?._id, discountCode)}
						okText="Yes"
						cancelText="No"
					>
						<Tooltip title="Delete">
							<Button aria-label="Delete" iconOnly variant="text" dataCy={`discount-${discountCode.code}-delete`}>
								<DeleteOutlined style={{ fontSize: 18 }} />
							</Button>
						</Tooltip>
					</Popconfirm>
					<Link
						to={`/console/my-events/${currentEvent.event?._id}/attendees?query=discountcode%253A${discountCode.code}`}
					>
						<Tooltip title="View attendees">
							<Button aria-label="View attendees" iconOnly variant="text" dataCy={`discount-${discountCode.code}-view`}>
								<TeamOutlined style={{ fontSize: 18 }} />
							</Button>
						</Tooltip>
					</Link>
					<Tooltip title="Duplicate">
						<Button
							aria-label="Duplicate"
							iconOnly
							variant="text"
							dataCy={`discount-${discountCode.code}-duplicate`}
							onClick={() => handleDuplicateDiscountCodeClicked(discountCode)}
						>
							<CopyOutlined style={{ fontSize: 18 }} />
						</Button>
					</Tooltip>
				</Flex>
			)
		}
	];

	return (
		<LoadErrorView
			loading={currentEvent.save.loading}
			error={currentEvent.save.error ?? discountCodes.getRequest.error}
		>
			<div>
				{discountCodes.codes && discountCodes.codes.length ? (
					<>
						<Flex justify="flex-end">
							<Button aria-label="Delete all" onClick={handleDeleteAllClicked} outlined variant="danger">
								Delete all
							</Button>
						</Flex>
						<Spacer size="sm" />
					</>
				) : null}
				<P>
					Discount codes allow your attendees to access a discount via a code. Create a discount code and assign it to
					ticket type(s).
				</P>
				<SearchRefinements load={handleLoad} />
				<If condition={!discountCodes.codes || !discountCodes.codes.length}>
					<Then>
						<NoResults
							action={handleCreateCodeClicked}
							actionTxt="Create code"
							message=""
							title="No discount codes found"
						/>
					</Then>
					<Else>
						<div>
							<div style={{ overflowX: "auto" }}>
								<Table
									columns={columns}
									dataSource={discountCodes.codes}
									rowKey="key"
									pagination={{
										current: discountCodes.page || 1,
										onChange: handleLoadPage,
										pageSize: 20,
										showSizeChanger: false,
										total: discountCodes.count
									}}
									scroll={{ x: 800 }}
								/>
								<ButtonsBar>
									<Button
										aria-label="Add code"
										dataCy="Create code"
										iconLeft={<PlusOutlined />}
										onClick={handleCreateCodeClicked}
										variant="tertiary"
									>
										Add code
									</Button>
								</ButtonsBar>
							</div>
						</div>
					</Else>
				</If>
			</div>
		</LoadErrorView>
	);
};
