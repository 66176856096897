import { LoadingSpinner } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import { H2, P } from "@/components/text/Text";
import Widget from "@/components/widgets/Widget";
import {
	getDashboardOverview,
	updateDashboardChannel,
	updateDashboardCustomDateRange,
	updateDashboardCustomDateRangePicked,
	updateDashboardPeriod
} from "@/state/actions/metricActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Col, DatePicker, Row, Select } from "@/ui/antd";
import { css } from "@emotion/css";
import React, { useCallback, useEffect } from "react";
import { currency } from "../../utils/Format";
import { useCurrentUser } from "@/hooks/useCurrentUser";

const RangePicker = DatePicker.RangePicker;

const labelStyle = css({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: 16,
	h2: {
		display: "inline",
		"&:nth-child(2)": {
			color: "#353337"
		}
	}
});

const headerWrapper = css({
	display: "flex",
	flexDirection: "column"
});

const earningsFilterWrapper = css({
	display: "flex",
	justifyContent: "flex-end",
	flexWrap: "wrap",
	gap: 8
});

export const OverviewTable: React.FC<{
	title?: string;
}> = ({ title }) => {
	const dispatch = useAppDispatch();
	const metrics = useAppSelector((state) => state.dashboardMetrics);
	const location = useAppSelector((state) => state.locations.overrideLocation);
	const { user, isLoading: isUserLoading } = useCurrentUser();

	const { overview, loading } = metrics.overviewByDate || {};
	const {
		ticketsSold,
		ticketsCancelled,
		ticketOrders,
		ticketSales,
		addOnSales,
		additionalDonations,
		feeRebates,
		absorbedFees,
		absorbedHumanitixFees,
		absorbedZipFees,
		absorbedAfterpayFees,
		refunds,
		salesTaxHeld,
		totalEarnings
	} = overview || {};

	useEffect(() => {
		if (totalEarnings === undefined && user && !isUserLoading) {
			dispatch(getDashboardOverview(metrics.period, metrics.channel));
		}
	}, [getDashboardOverview, user, isUserLoading]);

	const onDatePickerChange = useCallback(
		(range: any) => {
			dispatch(getDashboardOverview(range, metrics.channel));
			dispatch(updateDashboardCustomDateRange(range));
		},
		[metrics.channel, getDashboardOverview, updateDashboardCustomDateRange]
	);

	const handleChannelChange = useCallback(
		(channel: string) => {
			dispatch(getDashboardOverview(metrics.period, channel));
			dispatch(updateDashboardChannel(channel));
		},
		[metrics.period, getDashboardOverview, updateDashboardChannel]
	);

	const handlePeriodChange = useCallback(
		(period: string) => {
			const isCustomRangePicked = period === "customDate";
			dispatch(updateDashboardPeriod(period));
			dispatch(updateDashboardCustomDateRangePicked(isCustomRangePicked));

			if (!isCustomRangePicked) {
				dispatch(getDashboardOverview(period, metrics.channel));
			}
		},
		[metrics.channel, getDashboardOverview, updateDashboardPeriod, updateDashboardCustomDateRangePicked]
	);

	const rowBorderStyle = "solid 1px #dedede";

	const netSaleTitle = (
		<div className={headerWrapper}>
			<div className={labelStyle}>
				<div>
					<H2>Your earnings: </H2>
					<H2>{currency(totalEarnings || 0)}</H2>
				</div>
				<div className={earningsFilterWrapper}>
					<Select
						style={{ fontSize: 14, width: 140 }}
						value={metrics.channel}
						onChange={handleChannelChange}
						options={[
							{ label: "All channels", value: "all" },
							{ label: "Online sales", value: "online" },
							{ label: "Manual sale", value: "manual" },
							{ label: "Box Office", value: "boxOffice" }
						]}
					/>
					<Select
						style={{ fontSize: 14, width: 140 }}
						value={metrics.period}
						onChange={handlePeriodChange}
						options={[
							{ label: "Today", value: "today" },
							{ label: "Yesterday", value: "yesterday" },
							{ label: "Last 7 days", value: "week" },
							{ label: "Last 30 days", value: "month" },
							{ label: "Custom Date", value: "customDate" }
						]}
					/>
				</div>
			</div>
			<div>
				{metrics.isCustomRangePicked && (
					<RangePicker
						onChange={onDatePickerChange}
						value={metrics.customDateRange}
						style={{ marginBottom: 10, paddingBottom: 10 }}
					/>
				)}
			</div>
		</div>
	);

	return (
		<Widget title={title} customTitle={netSaleTitle}>
			{loading ? (
				<LoadingSpinner size={30} />
			) : (
				<div style={{ height: "100%" }}>
					<Row gutter={8} style={{ marginBottom: 16 }} key="3boxes">
						<Col className="headerColBackground" span={8} key="tickets">
							<div>
								<P>Tickets</P>
								<P
									style={{
										marginTop: "2px",
										fontWeight: 600,
										fontSize: "18px"
									}}
								>
									{ticketsSold}
								</P>
							</div>
						</Col>
						<Col className="headerColBackground" span={8} key="orders">
							<div>
								<P>Orders</P>
								<P
									style={{
										marginTop: "2px",
										fontWeight: 600,
										fontSize: "18px"
									}}
								>
									{ticketOrders}
								</P>
							</div>
						</Col>
						<Col className="headerColBackground" span={8} key="cancelled">
							<div>
								<P>Cancelled</P>
								<P
									style={{
										marginTop: "2px",
										fontWeight: 600,
										fontSize: "18px"
									}}
								>
									{ticketsCancelled}
								</P>
							</div>
						</Col>
					</Row>
					<Row className="tableRowBackground">
						<Col span={12}>
							<P style={{ textAlign: "left" }}>Ticket sales{location === "US" ? " (incl. sales tax)" : ""}</P>
						</Col>
						<Col span={12}>
							<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(ticketSales || 0)}</P>
						</Col>
					</Row>
					{location !== "US" ? (
						<Row className="tableRowBackground">
							<Col span={12}>
								<P style={{ textAlign: "left" }}>Add-on sales</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(addOnSales || 0)}</P>
							</Col>
						</Row>
					) : null}
					<Row className="tableRowBackground">
						<Col span={12}>
							<P style={{ textAlign: "left" }}>Additional donations</P>
						</Col>
						<Col span={12}>
							<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(additionalDonations || 0)}</P>
						</Col>
					</Row>
					{feeRebates ? (
						<Row className="tableRowBackground">
							<Col span={12}>
								<P style={{ textAlign: "left" }}>Fee rebates</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(feeRebates || 0)}</P>
							</Col>
						</Row>
					) : null}
					<Row className="tableRowBackground" style={{ borderTop: rowBorderStyle }}>
						<Col span={12}>
							{absorbedZipFees || absorbedAfterpayFees ? (
								<P>
									Absorbed fees{" "}
									<IconButton
										icon="info_hover"
										tooltip={
											<div>
												<b>Fee breakdown:</b>
												<br />
												<b>Humanitix fees:</b> {currency(absorbedHumanitixFees || 0)}
												<br />
												<b>Zip fees:</b> {currency(absorbedZipFees || 0)}
												<br />
												<b>Afterpay fees:</b> {currency(absorbedAfterpayFees || 0)}
												<br />
											</div>
										}
										showTooltip
										small
										ariaLabel="Fee breakdown"
									/>
								</P>
							) : (
								<P>Absorbed fees</P>
							)}
						</Col>
						<Col span={12}>
							<P>
								<b>{currency(absorbedFees || 0)}</b>
							</P>
						</Col>
					</Row>
					<Row className="tableRowBackground">
						<Col span={12}>
							<P style={{ textAlign: "left" }}>Refunds</P>
						</Col>
						<Col span={12}>
							<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(refunds || 0)}</P>
						</Col>
					</Row>
					{salesTaxHeld ? (
						<Row className="tableRowBackground">
							<Col span={12}>
								<P style={{ textAlign: "left" }}>Sales Tax Held</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(salesTaxHeld || 0)}</P>
							</Col>
						</Row>
					) : null}
					<Row className="tableRowBackground" style={{ borderTop: rowBorderStyle, borderBottom: rowBorderStyle }}>
						<Col span={12}>
							<P style={{ textAlign: "left", fontWeight: 600 }}>Total earnings</P>
						</Col>
						<Col span={12}>
							<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(totalEarnings || 0)}</P>
						</Col>
					</Row>
				</div>
			)}
		</Widget>
	);
};
