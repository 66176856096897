import { css } from "@emotion/css";
import CopyButton from "../components/CopyButton";
import { P } from "../components/text/Text";
import { getConfig } from "@/config";
import { currency } from "../utils/Format";

const contentWrapper = css({
	marginTop: 16,
	border: "solid 1px #dedede",
	minHeight: 645,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	borderRadius: 4,
	paddingLeft: 4,
	paddingRight: 4,
	"#textContent": {
		marginTop: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		textAlign: "center"
	},
	Button: {
		marginTop: "auto",
		marginBottom: 16
	}
});

const PressRelease = ({ event = {} }) => {
	const {
		name: eventName = "@EventName",
		eventLocation: { venueName = "@EventLocation" },
		dates,
		slug: eventSlug,
		ticketTypes
	} = event;

	const lowestTicket =
		ticketTypes.length > 0 ? ticketTypes.reduce((a, b) => (a.price < b.price ? a : b)).price : "@LowPrice";
	const ticketingUrl = `${getConfig("TICKETING_URL")}/`;
	const eventUrl = eventSlug ? `${ticketingUrl}${eventSlug}` : "@EventURL";
	const today = new Date().toDateString();

	let activeDates = dates.filter((date) => !date.disabled);
	activeDates = activeDates.filter((date) => date.startDate > new Date().toISOString());
	let datesInOrder;
	let date;
	let dateStr;
	if (activeDates) {
		datesInOrder = activeDates.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
		date = datesInOrder[0];
		dateStr = date && date.display ? `${date.display.date.split("-")[0]},${date.display.time.split("-")[0]}` : "";
	} else {
		dateStr = "@EventDate";
	}

	const tipContent = {
		plainText: `
    [Put your logo here]
    Headline: ${eventName} is an event with impact
    [Advice: Your headline should capture journalist’s attention. What makes your event special. Is it the partnership with Humanitix? The people involved? The style of event?]
    FOR IMMEDIATE RELEASE: ${today}
    [Advice: This is the day you send out the release, not the day of your event]
    ${eventName} is coming to ${venueName} on ${dateStr} [and here’s the most important reason it’s special.]
    [Write a paragraph or two on what makes the event unique]
    The event has impact in more ways than one, with ticket booking fees supporting children's charities, thanks to a partnership with Humanitix.

    Humanitix is the not-for-profit ticketing platform that gives events impact, putting booking fees toward closing the education gap. Humanitix’s programs include meals for disadvantaged school kids, education programs for girls and scholarships for indigenous students.
    Tickets are now available from ${currency(lowestTicket)} through ${eventUrl}
    For interviews or more information, contact: @eventOrganiser
    @OrganiserPhoneNumber or @OrganiserEmail
    [Imbed at least one picture that journalists can publish, with a link to other high resolution images.]
    `,

		htmlContent: (
			<>
				<P desktopMarginBottom={2} mobileMarginBottom={2}>
					[Put your logo here]
				</P>
				<P>Headline: {eventName} is an event with impact</P>
				<P>
					[Advice: Your headline should capture journalist’s attention. What makes your event special. Is it the
					partnership with Humanitix? The people involved? The style of event?]
				</P>
				<P desktopMarginBottom={2} mobileMarginBottom={2}>
					FOR IMMEDIATE RELEASE: {today}
				</P>
				<P>[Advice: This is the day you send out the release, not the day of your event]</P>
				<P>
					{eventName} is coming to {venueName} on {dateStr} [and here’s the most important reason it’s special.]
				</P>
				<P>[Write a paragraph or two on what makes the event unique]</P>
				<P>
					The event has impact in more ways than one, with ticket booking fees supporting children's charities, thanks
					to a partnership with Humanitix.
				</P>
				<P>
					Humanitix is the not-for-profit ticketing platform that gives events impact, putting booking fees toward
					closing the education gap. Humanitix’s programs include meals for disadvantaged school kids, education
					programs for girls and scholarships for indigenous students.
				</P>
				<P>
					Tickets are now available from ${lowestTicket} through&nbsp;
					<a href={eventUrl} target="_blank" rel="noopener noreferrer">
						{eventName}
					</a>
				</P>
				<P desktopMarginBottom={2} mobileMarginBottom={2}>
					For interviews or more information, contact: @eventOrganiser
				</P>
				<P>@OrganiserPhoneNumber or @OrganiserEmail</P>
				<P>[Imbed at least one picture that journalists can publish, with a link to other high resolution images.]</P>
			</>
		)
	};

	return (
		<>
			<P desktopMarginBottom={4} mobileMarginBottom={4}>
				<b>Press release</b>
			</P>
			<P>
				Let the media know how great your event is with our press release template. Fill in the blanks and it’s ready to
				send to journalists.{" "}
			</P>
			<P desktopMarginBottom={4} mobileMarginBottom={4}>
				<b>What to do with press releases</b>
			</P>
			<ul>
				<li>
					<P desktopMarginBottom={2} mobileMarginBottom={2}>
						Research journalists or websites that have written about similar events. Often, either their contact details
						are available online, or an online submission form will be available.
					</P>
				</li>
				<li>
					<P desktopMarginBottom={2} mobileMarginBottom={2}>
						Where possible, call journalists to tell them about your event
					</P>
				</li>
				<li>
					<P desktopMarginBottom={0} mobileMarginBottom={0}>
						Publish press releases on PRWire
					</P>
				</li>
			</ul>
			<div className={contentWrapper}>
				<div id="textContent">{tipContent.htmlContent}</div>
				<CopyButton toCopy={tipContent.plainText} ariaLabel="tips_Copy_to_clipboard" />
			</div>
		</>
	);
};

export default PressRelease;
