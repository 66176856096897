import { getConfig } from "@/config";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { Col, Row } from "@/ui/antd";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import NoImage from "../components/NoImage";
import Picture from "../components/Picture";
import { P } from "../components/text/Text";

const styles = {
	row: css`
		padding: 16px 20px 16px 20px;
		position: relative;
		background-color: white;
		margin-bottom: 12px;
		border-radius: 8px;

		@media (max-width: 700px) {
			margin-bottom: 20px;
			padding: 0px 0px 0px 0px;
		}
	`,
	imgDiv: css`
		display: inline-block;
		margin-right: 10px;
		vertical-align: top;
		@media (max-width: 700px) {
			margin-bottom: 10px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	`,
	statusCol: css`
		position: absolute;
		text-align: right;
		top: 20px;
		right: 20px;
		@media (max-width: 700px) {
			bottom: -12px;
			left: 0;
		}
	`,
	navigationLinks: css`
		display: inline-block;
		font-weight: 300;
	`,
	title: css`
		margin: 0px 0px 8px 0px;
		font-size: 18px;
		white-space: pre-wrap;
		width: 500px;
		font-weight: 600;
		overflow-x: hidden;
		text-overflow: ellipsis;
		@media (max-width: 700px) {
			margin-bottom: 8px;
			width: 200px;
		}
	`,
	date: css`
		margin-bottom: 8px;
		@media (max-width: 700px) {
			margin-bottom: 4px;
		}
	`,
	textWrapper: css`
		display: inline-block;
		vertical-align: top;
		color: #323232;
		width: calc(100% - 210px);
		@media (max-width: 700px) {
			width: 100%;
			margin-bottom: 55px;
			padding: 0px 12px;
		}
	`,
	navigationLinksWrapper: css`
		width: 300px;
		position: absolute;
		bottom: -80px;
		right: 0px;
		@media (max-width: 700px) {
			bottom: 25px;
			right: 0px;
		}
		p {
			display: inline-block;
			margin: 0px 7px;
			color: #dedede;
		}
	`,
	imgWrapper: css`
		width: 200px;
		height: 100px;
		@media (max-width: 700px) {
			width: 100%;
			height: calc((100vw - 28px) / 2);
		}
	`
};

const TourRow = (props) => {
	const { token: userToken } = useCurrentUser();
	const { name, eventsDates, bannerImage, featureImage, socialImage, published, _id, slug } = props;

	const img = bannerImage ? bannerImage : socialImage ? socialImage : featureImage;
	const windowExists = window !== "undefined";
	let imageBorderRadii = {
		topLeft: 4,
		topRight: 4,
		bottomLeft: 0,
		bottomRight: 0
	};
	let defaultWidth = 600;
	let defaultHeight = 300;
	if (windowExists && window.innerWidth > 600) {
		imageBorderRadii = {
			topLeft: 4,
			topRight: 4,
			bottomLeft: 4,
			bottomRight: 4
		};
		defaultWidth = 200;
		defaultHeight = 100;
	}
	const publishLabel = published ? "Published" : "Draft";

	const customStyles = {
		publishedLabel: css`
			color: ${published ? "#353337" : "#5A626C"};
			@media (max-width: 700px) {
				position: absolute;
				bottom: 25px;
				left: 10px;
			}
		`
	};

	return (
		<Row gutter={2} className={styles.row}>
			<Col xs={24} sm={24} md={24} lg={20} xl={20} style={{ padding: "0px" }}>
				<Link key={_id} to={`/console/promote/tours/${_id}`}>
					<div className={styles.imgDiv}>
						<div className={styles.imgWrapper}>
							{img ? (
								<Picture
									handle={img.handle}
									alt={name}
									borderRadii={imageBorderRadii}
									defaultWidth={defaultWidth}
									defaultHeight={defaultHeight}
								/>
							) : (
								<NoImage borderRadii={imageBorderRadii} />
							)}
						</div>
					</div>
					<div className={styles.textWrapper}>
						<div className={styles.title}>{name}</div>
						<div className={styles.date}>
							{eventsDates.startDate} - {eventsDates.endDate}
						</div>
					</div>
				</Link>
			</Col>
			<div className={styles.statusCol}>
				<div className={customStyles.publishedLabel}>{publishLabel}</div>
				<div className={styles.navigationLinksWrapper}>
					<Link to={`/console/promote/tours/${_id}`} className={styles.navigationLinks}>
						EDIT
					</Link>
					<P>|</P>
					<a href={`${getConfig("TICKETING_URL")}/tours/${slug}?token=${userToken}`} className={styles.navigationLinks}>
						VIEW
					</a>
				</div>
			</div>
		</Row>
	);
};

export default TourRow;
