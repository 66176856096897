import { Modal } from "@/ui/antd";
import { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import EditScheduleForm, { formName } from "../forms/EditScheduleForm";
import { dayjs } from "@/libs/dayjs";

const formatResult = (eventDate, onSubmit) => (rescheduleData) => {
	const startDateIncrease = dayjs(rescheduleData.startDate).diff(dayjs(eventDate.startDate));
	const endDateIncrease = dayjs(rescheduleData.endDate).diff(dayjs(eventDate.endDate));

	onSubmit({
		...rescheduleData,
		startDateIncrease,
		endDateIncrease
	});
};

let SaveButton = ({ handleSubmit, saveDetails, eventDate }) => {
	return (
		<LegacyButton
			style={{
				float: "right"
			}}
			form={formName}
			key="done"
			type="primary"
			htmlType="button"
			ariaLabel="Done"
			onClick={handleSubmit(formatResult(eventDate, saveDetails))}
		>
			Save
		</LegacyButton>
	);
};

SaveButton = reduxForm({
	form: formName
})(SaveButton);

const EditEventModal = (props) => {
	const [modalState, setModalState] = useState({ open: false, eventDate: {} });
	useEffect(() => {
		const open = props.open;
		const eventDate = props.eventDate;
		setModalState(() => ({ open, eventDate }));
	}, [props.open, props.eventDate]);
	const eventDate = modalState.eventDate || {};

	return (
		<Modal
			title="Edit schedule"
			open={modalState.open}
			destroyOnClose
			onCancel={props.onModalClose}
			footer={
				<>
					<LegacyButton
						style={{
							float: "left"
						}}
						key="cancel"
						onClick={props.onModalClose}
						htmlType="button"
						ariaLabel="Cancel"
					>
						Cancel
					</LegacyButton>

					<SaveButton eventDate={eventDate} saveDetails={props.onEditSchedule} />
				</>
			}
		>
			<EditScheduleForm onSubmit={props.onEditSchedule} eventDate={eventDate} />
		</Modal>
	);
};

export default EditEventModal;
