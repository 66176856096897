import IconReference from "@/services/IconReference";
import locationMap from "@/utils/LocationMap";
import { Currency, locationDataByCountryCode } from "@hx/locations";
import { Form, Select } from "antd";
import { FC } from "react";

const LocationField: FC<{ country?: keyof typeof locationMap }> = ({ country }) => {
	const countries = Object.values(locationDataByCountryCode)
		.filter((option) => {
			// JEN-12857 filter out MYR if it is not already accessible to the user
			return !(locationDataByCountryCode[option.location].currency === Currency.MYR);
		})
		.map((option) => {
			return {
				value: option.location,
				label: option.country
			};
		});

	const selectOptions = countries.map(({ label, value }) => {
		const iconName = `${value.toLowerCase()}_flag`;
		const icon = IconReference[iconName];
		return {
			value,
			dataCy: value,
			label: (
				<div
					style={{
						background: `url(${icon}) no-repeat left center`,
						paddingLeft: 30
					}}
				>
					{label}
				</div>
			)
		};
	});

	const state = locationMap[country || "AU"] as {
		label: string;
		value: string;
	}[];
	const showState = state?.length > 0;
	return (
		<>
			<Form.Item
				name="location"
				label={<b>Region</b>}
				required
				rules={[{ min: 1, required: true, type: "string", message: "Please choose a location" }]}
			>
				<Select options={selectOptions} />
			</Form.Item>

			{showState ? (
				<Form.Item name="state" label={<b>State</b>} required rules={[{ min: 1, required: true, type: "string" }]}>
					<Select options={state} />
				</Form.Item>
			) : null}
		</>
	);
};

export default LocationField;
