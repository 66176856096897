import { css } from "@emotion/css";
import { ReactNode } from "react";

interface IH3Props {
	children: ReactNode;
	style?: Record<string, unknown>;
}

const H3 = ({ children, style }: IH3Props) => {
	const customTextStyle = css({
		fontWeight: 600,
		fontSize: 18,
		margin: 0,
		marginBottom: 8,
		lineHeight: "24px",
		"@media(max-width: 600px)": {
			fontSize: 16,
			marginBottom: 8,
			lineHeight: "22px"
		},
		...style
	});
	return <h3 className={customTextStyle}>{children}</h3>;
};

export default H3;
