import { dayjs } from "@/libs/dayjs";
import { message } from "@/ui/antd";
import FileSaver from "file-saver";
import axios from "./AxiosWrapper";

const getHeaders = (location) => {
	const headers = {};
	if (location) {
		headers["x-override-location"] = location;
	}
	return {
		headers
	};
};

const getReportRoute = (reportName) => {
	switch (reportName) {
		case "attendee":
		case "check-in":
		case "guest-list":
		case "invoice":
		case "order":
			return `reports-orders/${reportName}`;
		default:
			return `reports/${reportName}`;
	}
};

class GlobalReportService {
	static instance = null;

	static createInstance() {
		let object = new GlobalReportService();
		return object;
	}

	static getInstance() {
		if (!GlobalReportService.instance) {
			GlobalReportService.instance = GlobalReportService.createInstance();
		}
		return GlobalReportService.instance;
	}

	async getReport(reportName, queryBy, page, location) {
		const response = await axios.get(getReportRoute(reportName), {
			params: { queryBy, page },
			...getHeaders(location)
		});
		return response.data;
	}

	async getReportCsv(reportName, queryBy, location) {
		const fileName = `${reportName}-report-(exported-${dayjs()
			.toISOString(true)
			.replaceAll("/", "-")
			.split(".")[0]
			.replace("T", "@")
			.replaceAll(":", ".")}).csv`;

		message.loading({
			content: `Downloading ${fileName}`,
			key: reportName,
			duration: 0
		});

		const response = await axios.get(`${getReportRoute(reportName)}/csv`, {
			responseType: "blob",
			onDownloadProgress: (progressEvent) => {
				const i = ~~(Math.log2(progressEvent.loaded) / 10);
				const unit = ["Bytes", "KB", "MB"][i];
				const loaded = progressEvent.loaded / Math.pow(1024, i);
				message.loading({
					content: `${loaded.toFixed(2)}${unit}s downloaded`,
					key: reportName,
					duration: 0
				});
			},
			params: { queryBy },
			...getHeaders(location)
		});

		FileSaver.saveAs(response.data, fileName);

		message.success({
			content: `${fileName} complete`,
			key: reportName,
			duration: 4
		});
	}

	async getReportPdf(reportName, queryBy, location) {
		const response = await axios.get(`${getReportRoute(reportName)}/pdf`, {
			responseType: "blob",
			params: { queryBy: { ...queryBy, location } },
			...getHeaders(location)
		});
		return response.data;
	}
}

export default GlobalReportService.getInstance();
