import { HumaniDate } from "@hx/dates";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import LocationSelection from "../../components/LocationSelection";
import PageHeading from "../../components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import LocationService from "../../services/LocationService";
import { find, requestMassPayout } from "../../state/actions/massPayoutActions";
import { currency } from "../../utils/Format";
import PayoutActions from "./PayoutActions";
import PayoutStatus from "./PayoutStatus";

class MassPayouts extends Component {
	constructor(props) {
		super(props);
		this.loadPage = this.loadPage.bind(this);
	}

	componentDidMount() {
		const { find } = this.props;
		find(1);
	}

	loadPage(page) {
		const { find } = this.props;
		find(page);
	}

	render() {
		const { massPayouts, requestMassPayout, find } = this.props;
		let columns = [
			{
				title: "Mass payout ID",
				dataIndex: "_id",
				key: "_id",
				render: (value) => <Link to={`/console/admin/mass-payout/${value}`}>{value}</Link>
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: (value) => <PayoutStatus status={value} />
			},
			{
				title: "Payments logged",
				dataIndex: "count",
				key: "count"
			},
			{
				title: "Amount logged",
				dataIndex: "amount",
				key: "amount",
				render: (value) => currency(value)
			},
			{
				title: "Amount paid",
				dataIndex: "amountProcessed",
				key: "amountProcessed",
				render: (value) => currency(value)
			},
			{
				title: "Amount outstanding ",
				dataIndex: "",
				key: "",
				render: (_, record) => currency(record.amount - record.amountProcessed)
			},
			{
				title: "Generated at",
				dataIndex: "createdAtDisplay",
				key: "createdAtDisplay",
				render: (date) => new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.dateTime()
			},
			{
				title: "Generated by",
				dataIndex: "createdBy",
				key: "createdBy",
				render: (createdBy) => createdBy.name
			},
			{
				title: "Paid At",
				dataIndex: "paidAtDisplay",
				key: "paidAtDisplay",
				render: (date) =>
					date ? new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.dateTime() : "-"
			},
			{
				title: "Paid By",
				dataIndex: "paidByName",
				key: "paidByName"
			},
			{
				title: "Transaction Id",
				dataIndex: "transactionId",
				key: "transactionId"
			},
			{
				title: "Action",
				key: "action",
				fixed: "right",
				width: 100,
				render: (text, record) => {
					return <PayoutActions payout={record} />;
				}
			}
		];

		return (
			<>
				<LoadErrorView
					loading={massPayouts.payouts.loading}
					error={massPayouts.payouts.error}
					retryAction={() => find(1)}
				>
					<PageHeading title="Mass Payouts">
						<LocationSelection componentName="massPayouts" defaultLevel="user" onLocationChange={() => find(1)} />
					</PageHeading>
					<ContentBlock>
						<LegacyButton
							onClick={requestMassPayout}
							icon={{ name: "plus", left: true }}
							type="action"
							ariaLabel="New Mass Payout"
							style={{ float: "right", marginBottom: 12 }}
						>
							New mass payout
						</LegacyButton>
						<div style={{ minWidth: "100%", overflowX: "auto" }}>
							{!massPayouts.payouts.loading ? (
								<Table
									columns={columns}
									dataSource={massPayouts.payouts.payments}
									rowKey="_id"
									pagination={{
										showSizeChanger: false,
										total: massPayouts.payouts.count,
										current: massPayouts.payouts.page,
										onChange: (page) => this.loadPage(page)
									}}
									scroll={{ x: 1200 }}
								/>
							) : null}
						</div>
					</ContentBlock>
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => ({
		massPayouts: state.massPayouts
	}),
	{
		find,
		requestMassPayout
	}
)(MassPayouts);
