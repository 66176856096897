import { Alert, Popconfirm, Switch, Table } from "@/ui/antd";

import { css } from "@emotion/css";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ContentBlock } from "../components/AppBlocks";
import IconButton from "../components/IconButton";
import LoadErrorView from "../components/LoadErrorView";
import LocationSelection from "../components/LocationSelection";
import Page from "../components/Page";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "../components/buttons/ButtonsBar";
import NoResultsLarge from "../components/noResults/NoResultsLarge";
import ResponsiveTable from "../components/table/ResponsiveTable";
import TableActions from "../components/table/TableActions";
import { getGiftCardList, updateGiftCard } from "../state/actions/giftCardActions";
import { updateSearch } from "../state/actions/searchActions";
import { getIsGiftcardFeatureEnabled } from "../utils/GiftcardUtilties";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class GiftCards extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.onCreate = this.onCreate.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onDisable = this.onDisable.bind(this);
		this.loadExistingGiftCards = this.loadExistingGiftCards.bind(this);
		this.onLocationChange = this.onLocationChange.bind(this);
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(prevProps) {
		const locationChanged = this.props.location.search !== prevProps.location.search;
		if (locationChanged) {
			this.load();
		}
	}

	load() {
		const { getGiftCardList, currentUser } = this.props;
		getGiftCardList(currentUser.user.id);
	}

	onCreate() {
		const { navigate } = this.props;
		navigate("/console/promote/gift-cards/new");
	}

	onRemove(giftCardData) {
		const { updateGiftCard, currentUser } = this.props;
		const userId = currentUser.user.id;
		const giftCardId = giftCardData._id;
		updateGiftCard(userId, giftCardId, { deleted: true });
	}

	onDisable(disabled, giftCardData) {
		const { updateGiftCard, currentUser } = this.props;
		const userId = currentUser.user.id;
		const giftCardId = giftCardData._id;
		updateGiftCard(userId, giftCardId, { disabled });
	}

	loadExistingGiftCards(giftCardData) {
		const giftCardId = giftCardData._id;
		const { navigate } = this.props;
		navigate(`/console/promote/gift-cards/${giftCardId}/update`);
	}

	onLocationChange(loc) {
		const { updateSearch } = this.props;
		updateSearch({ loc }, true);
	}

	render() {
		const { giftCard, isGiftCardFeatureEnabled } = this.props;
		const { loading, error, giftCardList } = giftCard;
		const locationSelection = (
			<LocationSelection componentName="giftCards" defaultLevel="user" onLocationChange={this.onLocationChange} />
		);
		const noResults = () => (
			<NoResultsLarge
				title="No gift cards found"
				locationSelection={locationSelection}
				action={() => this.onCreate()}
				actionTxt="Create a Gift Card"
			/>
		);
		const giftCardTable = !loading ? (
			<GiftCardTable
				giftCardList={giftCardList}
				onCreate={this.onCreate}
				onEdit={this.loadExistingGiftCards}
				onRemove={this.onRemove}
				onDisable={this.onDisable}
				noResults={noResults}
			/>
		) : null;
		const giftcardsDisabledForLocation = (
			<Alert
				showIcon
				message="Sorry, gift cards are not available in this region yet"
				description="Please select a different region"
				type="warning"
			/>
		);
		return (
			<Page pageName="Gift cards" sibling={locationSelection}>
				<LoadErrorView loading={loading} error={error} retryAction={this.save}>
					<ContentBlock>{isGiftCardFeatureEnabled ? giftCardTable : giftcardsDisabledForLocation}</ContentBlock>
				</LoadErrorView>
			</Page>
		);
	}
}

const headerStyle = css({
	display: "flex",
	marginBottom: 24,
	"@media(max-width: 600px)": {
		display: "block",
		paddingBottom: 16,
		borderBottom: "1px solid",
		borderColor: "#dedede",
		marginBottom: 0
	}
});
const buttonStyle = css({
	"@media(max-width: 600px)": {
		height: 36
	}
});

class GiftCardTable extends Component {
	render() {
		const { giftCardList, onEdit, onRemove, onCreate, onDisable, noResults } = this.props;
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (value, giftCard) => {
					const giftCardId = giftCard._id;
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<Link style={{ color: "#196097" }} to={`/console/promote/gift-cards/${giftCardId}/update`}>
								{value}
							</Link>
						</div>
					);
				}
			},
			{
				title: "Value",
				dataIndex: "price",
				key: "price",
				renderTitleMobile: false,
				render: (value, data, _, isMobile) => {
					if (isMobile) {
						const subcolumns = [
							{
								title: "Value",
								dataIndex: "price",
								key: "price"
							},
							{
								title: "Valid For",
								dataIndex: "validFor",
								key: "validFor",
								render: (value) => {
									return <div>{`${value} months`}</div>;
								}
							},
							{
								title: "Sold",
								dataIndex: "sold",
								key: "sold"
							}
						];
						return (
							<div style={{ minWidth: "85vw", marginTop: 16, marginBottom: 16 }}>
								<Table columns={subcolumns} dataSource={[data]} pagination={false} />
							</div>
						);
					}
					return <div>{value}</div>;
				}
			},
			{
				title: "Valid For",
				dataIndex: "validFor",
				key: "validFor",
				renderTitleMobile: false,
				render: (value) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									display: "none"
								}
							})}
						>{`${value} months`}</div>
					);
				}
			},
			{
				title: "Sold",
				dataIndex: "sold",
				key: "sold",
				renderTitleMobile: false,
				render: (value) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									display: "none"
								}
							})}
						>
							{value}
						</div>
					);
				}
			},
			{
				title: "Enabled",
				dataIndex: "disabled",
				key: "disabled",
				render: (value, data) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									minWidth: "35vw"
								}
							})}
						>
							<Switch checked={!value} onChange={(disabled) => onDisable(!disabled, data)} />
						</div>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, data) => (
					<div
						className={css({
							"@media(max-width: 600px)": {
								minWidth: "35vw"
							}
						})}
					>
						<TableActions>
							<IconButton icon="edit" onClick={() => onEdit(data)} tooltip="Edit" showTooltip ariaLabel="Edit" />
							<Popconfirm
								title="Are you sure you want to delete this gift card?"
								onConfirm={() => onRemove(data)}
								okText="Yes"
								cancelText="No"
							>
								<IconButton icon="delete" tooltip="Delete" showTooltip ariaLabel="Delete" />
							</Popconfirm>
						</TableActions>
					</div>
				)
			}
		];
		const isEmpty = giftCardList.length === 0;

		return (
			<div>
				<div className={headerStyle}>
					<div style={{ flexGrow: 1 }} />

					<div className={buttonStyle}>
						<ButtonsBar>
							<LegacyButton onClick={() => onCreate()} type="action" ariaLabel="Create a gift card">
								Create a Gift Card
							</LegacyButton>
						</ButtonsBar>
					</div>
				</div>
				<If condition={isEmpty}>
					<Then>{noResults}</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<ResponsiveTable
									columns={columns}
									scroll={{ x: 800 }}
									dataSource={giftCardList}
									rowKey="_id"
									noResults={noResults}
									pagination={false}
								/>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => {
		const isGiftCardFeatureEnabled = getIsGiftcardFeatureEnabled(state.locations.overrideLocation);
		return {
			giftCard: state.giftCard,
			userEvents: state.userEvents,
			currentUser: state.auth,
			isGiftCardFeatureEnabled
		};
	},
	{
		updateSearch,
		updateGiftCard,
		getGiftCardList
	}
)(withRouterHooks(GiftCards));
