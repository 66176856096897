import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import csvExampleFile from "@/assets/csv/discount-codes-example.csv";
import { ContentBlock } from "@/components/AppBlocks";
import {
	FileUploadField,
	LegacyInputField,
	InputNumberField,
	RadioField,
	SelectField,
	SwitchField
} from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import { getConfig } from "@/config";
import DiscountCodesDateRange from "@/events/event/discountCodes/DiscountCodesDateRange";
import FileService from "@/services/FileService";
import { getUserEvents } from "@/state/actions/eventActions";
import { getGlobalDiscountCode, saveGlobalDiscountCodes } from "@/state/actions/globalDiscountCodesActions";
import { getCurrencySymbol } from "@/utils/Format";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { mustBeGreaterThanZero, mustBeLessThanOrEqualTo, mustBeUnique, required } from "@/utils/Validators";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "discountCode";
const styles = {
	asterisk: css({
		color: "#c0212c"
	}),
	required: css({
		fontWeight: 500,
		"@media(max-width: 600px)": {
			display: "none"
		}
	}),
	codeField: css({
		width: 480
	}),
	appliesToField: css({
		maxWidth: 480
	})
};

class DiscountCodePage extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	componentWillMount() {
		const { discountCodes, params = {}, currentUser, getGlobalDiscountCode, getUserEvents } = this.props;
		const { discountCodeId, location } = params;
		if (!discountCodes.editingCode && discountCodeId !== "new") {
			getGlobalDiscountCode(currentUser.user.id, discountCodeId, location);
		}
		getUserEvents(currentUser.user.id, "name _id", false, location);
	}

	/**
	 * @param values: values of all the Fields of the form
	 */
	save(values) {
		const { saveGlobalDiscountCodes, currentUser, navigate } = this.props;
		saveGlobalDiscountCodes(currentUser.user.id, values);
		navigate(`/console/promote/global-discount-codes`);
	}

	/**
	 * Discard all changes and without checking for required fields push the history to promote/global-discount-codes
	 */
	cancel() {
		const { navigate } = this.props;
		navigate(`/console/promote/global-discount-codes`);
	}

	downloadFile = () => {
		FileService.downloadFile(csvExampleFile, "global-discount-codes-example.csv");
	};

	codeRequired = required("Code required");
	discountAmountRequired = required("Discount amount required");
	codeMustBeUnique = mustBeUnique(
		"Code with this name exists",
		this.props.params?.discountCodeId,
		this.props.discountCodes.codes
	);
	quantityRequired = required("Quantity required");
	percentageLessThanOneHundred = mustBeLessThanOrEqualTo(100);

	render() {
		const { discountCodes, type, touch, handleSubmit, userEvents } = this.props;

		let ticketOptions;
		if (userEvents.events) {
			ticketOptions = [{ value: "all", label: "All events" }].concat(
				userEvents.events
					.filter((t) => !t.isDonation && !t.deleted)
					.map((ticket) => {
						return { value: ticket._id, label: ticket.name };
					})
			);
		}
		const timezone = getConfig("TIMEZONE_DEFAULT");
		return (
			<form onSubmit={handleSubmit(this.save)}>
				<LoadErrorView
					loading={discountCodes.saveRequest.loading || !userEvents.events || userEvents.loading}
					retryAction={this.save}
				>
					<H1>Global discount codes</H1>
					<ContentBlock>
						<Field
							name="type"
							label="Code type"
							component={RadioField}
							button
							options={[
								{
									label: "Single Code",
									value: "single"
								},
								{
									label: "CSV Upload",
									value: "upload"
								}
							]}
						/>

						{type === "single" ? (
							<Field
								name="code"
								required
								placeholder="Code"
								label="Code"
								component={LegacyInputField}
								validate={[this.codeMustBeUnique, this.codeRequired]}
								style={{
									maxWidth: 480
								}}
							/>
						) : (
							<div>
								<div style={{ marginBottom: 24 }}>
									CSV example file: {/*eslint-disable-next-line*/}
									<a onClick={this.downloadFile} download="discount-codes-example">
										Download
									</a>
								</div>
								<Field
									name="csvFile"
									label="Upload a CSV"
									description="File must be a csv or txt file containing one code per line, duplicate codes will be ignored"
									component={FileUploadField}
									validate={this.codeRequired}
								/>
							</div>
						)}

						<div
							className={css({
								position: "relative",
								".ant-row": {
									margin: "0 !important"
								}
							})}
						>
							<Field
								label="Discount per ticket"
								required
								name="discount"
								component={InputNumberField}
								placeholder="0"
								min={0}
								precision={2}
								max={this.props.discountType === "percent" ? 100 : undefined}
								validate={[
									this.discountAmountRequired,
									mustBeGreaterThanZero,
									...((this.props.discountType === "percent" && [this.percentageLessThanOneHundred]) || [])
								]}
								style={{
									width: 70
								}}
							/>
							<Field
								inline
								name="discountType"
								component={RadioField}
								button
								placeholder="discountType"
								style={{
									position: "absolute",
									top: 23,
									left: 76
								}}
								options={[
									{ value: "percent", label: "%" },
									{ value: "amount", label: getCurrencySymbol() }
								]}
							/>
						</div>
						<div className={styles.appliesToField}>
							{ticketOptions ? (
								<Field
									mode="multiple"
									name="appliesTo"
									label="Applies to"
									placeholder="Please select"
									required
									component={SelectField}
									options={ticketOptions}
									style={{ width: "100%" }}
								/>
							) : null}
						</div>
						<Field
							label="Quantity"
							name="quantity"
							required
							description="Total amount of tickets that can be discounted"
							component={InputNumberField}
							validate={this.quantityRequired}
							min={0}
						/>
						<Field
							label="Maximum use per order"
							name="maximumUsePerOrder"
							description="Amount of tickets that can be discounted per order"
							component={InputNumberField}
							allowsNullValue
							min={0}
						/>
						<DiscountCodesDateRange touch={touch} timezone={timezone} formName={formName} />
						<Field name="enabled" label="Enabled" component={SwitchField} style={{ marginBottom: 24 }} />
					</ContentBlock>
				</LoadErrorView>

				<FormBar>
					<LegacyButton
						style={{
							float: "right",
							width: "100px"
						}}
						onClick={handleSubmit(this.save)}
						type="primary"
						disabled={discountCodes.saveRequest.loading}
						ariaLabel="Save"
					>
						Save
					</LegacyButton>
					<LegacyButton
						style={{
							float: "left",
							width: "100px"
						}}
						onClick={this.cancel}
						disabled={discountCodes.saveRequest.loading}
						ariaLabel="Cancel"
					>
						Cancel
					</LegacyButton>
				</FormBar>
			</form>
		);
	}
}

DiscountCodePage = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(DiscountCodePage);

const selector = formValueSelector(formName);
export default withRouterHooks(
	connect(
		(state, ownProps) => {
			const { params } = ownProps;
			const { discountCodeId, location } = params;
			let initialData = {
				userId: state.auth.user?.id,
				enabled: true,
				discountType: "percent",
				type: "single",
				location
			};

			let initialValues;
			if (discountCodeId === "new") {
				initialValues = initialData;
			} else {
				// is edit
				if (state.discountCodes.editingCode) {
					initialValues = state.discountCodes.editingCode;
					initialValues.type = "single";
				}
			}
			return {
				initialValues,
				currentUser: state.auth,
				userEvents: state.userEvents,
				discountCodes: state.discountCodes,
				type: selector(state, "type"),
				discountType: selector(state, "discountType")
			};
		},
		{
			saveGlobalDiscountCodes,
			getGlobalDiscountCode,
			getUserEvents
		}
	)(DiscountCodePage)
);
