import { useState, useEffect } from "react";
import { Button, Modal, Tabs } from "@/ui/antd";
import { SeatingMap, Views } from "@hx/seating-map";

const { SeatingConstruction } = Views;

const TabSwitch = ({
	saving,
	error,
	loading,
	onSwitch,
	onSave,
	loadOrg,
	seatingMapUndo,
	location,
	view,
	isManagerView,
	seatingMapCapacity
}) => {
	// const [showSectionView, setShowSectionView] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [viewToSwitch, setViewToSwitch] = useState("");
	const [seatingMap, setSeatingMap] = useState(null);
	const [seatingConstruction, setSeatingConstruction] = useState(null);

	useEffect(() => {
		if (!loading) {
			setSeatingMap(SeatingMap.getCurrentMap());
			setSeatingConstruction(SeatingConstruction.getInstance());
		}
	}, [loading]);

	useEffect(() => {
		if (!saving && !error) {
			setShowModal(false);
		}
	}, [saving, error]);

	useEffect(() => {
		if (!seatingMap) {
			setSeatingMap(SeatingMap.getCurrentMap());
		}
		if (!seatingConstruction) {
			setSeatingConstruction(SeatingConstruction.getInstance());
		}
	}, [seatingMap, seatingConstruction]);

	// const onSectionAdded = () => setShowSectionView(true);

	const handleSwitch = (key) => {
		if (key === "seatingView") {
			if (seatingMapUndo[seatingMapUndo.view].undoActions.length() > 0) {
				setShowModal(true);
				setViewToSwitch("seatingView");
			} else {
				seatingMap.switchToSeatView();
				onSwitch("seatingView");
			}
		} else if (key === "sectionView") {
			seatingMap.switchToSectionView();
			onSwitch("sectionView");
		} else if (key === "ticketMapping") {
			if (seatingMapUndo[seatingMapUndo.view].undoActions.length() > 0) {
				setShowModal(true);
				setViewToSwitch("ticketMapping");
			} else {
				seatingMap.switchToTicketMappingView();
				onSwitch("ticketMapping");
			}
		} else if (key === "managerView") {
			if (seatingMapUndo[seatingMapUndo.view].undoActions.length() > 0) {
				setShowModal(true);
				setViewToSwitch("managerView");
			} else {
				loadOrg();
				onSwitch("managerView");
			}
		}
	};

	const handleOk = () => {
		onSave(viewToSwitch);
		onSwitch(viewToSwitch);
	};

	const handleCancel = () => {
		if (viewToSwitch === "managerView") {
			loadOrg();
			onSwitch(viewToSwitch);
		} else if (viewToSwitch === "seatingView") {
			seatingMap.switchToSeatView();
			onSwitch(viewToSwitch);
		} else if (viewToSwitch === "ticketMapping") {
			seatingMap.switchToTicketMappingView();
			onSwitch(viewToSwitch);
		}
		setShowModal(false);
	};

	const newMap = location.state && location.state.state && location.state.state.newMap;

	let tabItems = [];
	if (isManagerView) {
		tabItems.push({
			label: "Assigned Seats",
			key: "managerView",
			disabled: newMap
		});
	} else {
		tabItems.push(
			{
				label: "Layout",
				key: "seatingView"
			},
			{
				label: "Mapping",
				key: "ticketMapping",
				disabled: newMap || seatingMapCapacity === 0
			}
		);
	}

	// if (showSectionView) {
	// 	tabItems.push({
	// 		label: "Sections Layout",
	// 		key: "sectionView",
	// 		disabled: !showSectionView
	// 	});
	// }

	return (
		<div
			style={{
				unselectable: "on",
				verticalAlign: "top",
				WebkitTouchCallout: "none",
				WebkitUserSelect: "none",
				KhtmlUserSelect: "none",
				MozUserSelect: "none",
				MsUserSelect: "none",
				userSelect: "none"
			}}
		>
			<Tabs
				activeKey={view}
				centered
				defaultActiveKey="seatingView"
				tabPosition="top"
				animated={{ tabPane: false, inkBar: true }}
				onChange={handleSwitch}
				size="small"
				style={{ marginBottom: 0, paddingTop: 2 }}
				items={tabItems}
			/>
			<SaveCheckModal visible={showModal} loading={saving} handleOk={handleOk} handleCancel={handleCancel} />
		</div>
	);
};

export default TabSwitch;

const SaveCheckModal = ({ visible, loading, handleOk, handleCancel }) => {
	return (
		<Modal
			open={visible}
			title="Save Changes"
			onOk={handleOk}
			onCancel={handleCancel}
			closable={false}
			footer={[
				<Button key="back" onClick={handleCancel} ariaLabel="Cancel">
					Cancel
				</Button>,
				<Button key="submit" type="primary" loading={loading} onClick={handleOk} ariaLabel="Save">
					Save
				</Button>
			]}
		>
			<p>Do you wish to save your changes to seating allocation?</p>
		</Modal>
	);
};
