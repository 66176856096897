import InputLabel from "../../../components/InputLabel";
import StepInput from "../../../components/StepInput";

export default ({ element, performAction }) => {
	const { numberOfRows, changeNumberOfRows } = element;
	return (
		<div style={{ overflow: "auto", paddingTop: 12 }}>
			<InputLabel style={{ float: "left" }}>Number of rows</InputLabel>
			<StepInput
				value={numberOfRows}
				min={1}
				onChange={(value) => performAction([changeNumberOfRows], [[value]])}
				style={{ float: "right" }}
			/>
		</div>
	);
};
