import React, { useEffect, useState } from "react";
import ErrorMsg from "@/components/ErrorMsg";
import { hardRefresh } from "@/utils/Browser";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { LottiePlayer } from "@/ui";

type StyledLoadingWrapperProps = {
	backgroundColor?: string;
	fullscreen?: boolean;
};

const StyledLoadingWrapper = styled.div<StyledLoadingWrapperProps>`
	${({ backgroundColor, fullscreen }) => css`
		align-items: center;
		background-color: ${backgroundColor};
		display: flex;
		height: 100%;
		justify-content: center;
		left: ${fullscreen ? "0" : ""};
		position: ${fullscreen ? "fixed" : "relative"};
		top: ${fullscreen ? "0" : ""};
		width: 100%;
		z-index: ${fullscreen ? Number.MAX_SAFE_INTEGER : "auto"};
	`}
`;

type WinkLoaderProps = {
	backgroundColor?: string;
	error?: Error | null;
	fullscreen?: boolean;
	loading?: boolean;
	minTime?: number;
};

export const WinkLoader: React.FC<WinkLoaderProps> = ({
	backgroundColor,
	error = null,
	fullscreen = false,
	loading = true,
	minTime = 2100
}) => {
	const [hasTimedOut, setHasTimedOut] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setHasTimedOut(true);
		}, minTime);

		return () => clearTimeout(timer);
	}, [minTime]);

	if (error) {
		console.error(error);
		return <ErrorMsg title="Error" message="Sorry there was an error loading" retryAction={hardRefresh} />;
	}

	const stopClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	if (hasTimedOut && !loading) return null;

	return (
		<StyledLoadingWrapper
			backgroundColor={backgroundColor ?? fullscreen ? "var(--surface1)" : "transparent"}
			fullscreen={fullscreen}
			onClick={stopClick}
		>
			<LottiePlayer animationKey="wink" speed="2" height="300px" width="300px" />
		</StyledLoadingWrapper>
	);
};
