var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/lib/location.ts
import z from "zod";
var locations = [
  "AU",
  "NZ",
  "US",
  "FJ",
  "CA",
  "GB",
  "SG",
  "DE",
  "MY",
  "MX"
];
var locationSchema = z.union([
  z.literal(locations[0]),
  z.literal(locations[1]),
  z.literal(locations[2]),
  z.literal(locations[3]),
  z.literal(locations[4]),
  z.literal(locations[5]),
  z.literal(locations[6]),
  z.literal(locations[7]),
  z.literal(locations[8]),
  z.literal(locations[9])
]);

// src/lib/ISOMaps.ts
var countryISO3toISO2Map = {
  AFG: "AF",
  ALA: "AX",
  ALB: "AL",
  DZA: "DZ",
  ASM: "AS",
  AND: "AD",
  AGO: "AO",
  AIA: "AI",
  ATA: "AQ",
  ATG: "AG",
  ARG: "AR",
  ARM: "AM",
  ABW: "AW",
  AUS: "AU",
  AUT: "AT",
  AZE: "AZ",
  BHS: "BS",
  BHR: "BH",
  BGD: "BD",
  BRB: "BB",
  BLR: "BY",
  BEL: "BE",
  BLZ: "BZ",
  BEN: "BJ",
  BMU: "BM",
  BTN: "BT",
  BOL: "BO",
  BES: "BQ",
  BIH: "BA",
  BWA: "BW",
  BVT: "BV",
  BRA: "BR",
  VGB: "VG",
  IOT: "IO",
  BRN: "BN",
  BGR: "BG",
  BFA: "BF",
  BDI: "BI",
  KHM: "KH",
  CMR: "CM",
  CAN: "CA",
  CPV: "CV",
  CYM: "KY",
  CAF: "CF",
  TCD: "TD",
  CHL: "CL",
  CHN: "CN",
  HKG: "HK",
  MAC: "MO",
  CXR: "CX",
  CCK: "CC",
  COL: "CO",
  COM: "KM",
  COG: "CG",
  COD: "CD",
  COK: "CK",
  CRI: "CR",
  CIV: "CI",
  HRV: "HR",
  CUB: "CU",
  CUW: "CW",
  CYP: "CY",
  CZE: "CZ",
  DNK: "DK",
  DJI: "DJ",
  DMA: "DM",
  DOM: "DO",
  ECU: "EC",
  EGY: "EG",
  SLV: "SV",
  GNQ: "GQ",
  ERI: "ER",
  EST: "EE",
  ETH: "ET",
  FLK: "FK",
  FRO: "FO",
  FJI: "FJ",
  FIN: "FI",
  FRA: "FR",
  GUF: "GF",
  PYF: "PF",
  ATF: "TF",
  GAB: "GA",
  GMB: "GM",
  GEO: "GE",
  DEU: "DE",
  GHA: "GH",
  GIB: "GI",
  GRC: "GR",
  GRL: "GL",
  GRD: "GD",
  GLP: "GP",
  GUM: "GU",
  GTM: "GT",
  GGY: "GG",
  GIN: "GN",
  GNB: "GW",
  GUY: "GY",
  HTI: "HT",
  HMD: "HM",
  VAT: "VA",
  HND: "HN",
  HUN: "HU",
  ISL: "IS",
  IND: "IN",
  IDN: "ID",
  IRN: "IR",
  IRQ: "IQ",
  IRL: "IE",
  IMN: "IM",
  ISR: "IL",
  ITA: "IT",
  JAM: "JM",
  JPN: "JP",
  JEY: "JE",
  JOR: "JO",
  KAZ: "KZ",
  KEN: "KE",
  KIR: "KI",
  PRK: "KP",
  KOR: "KR",
  KWT: "KW",
  KGZ: "KG",
  LAO: "LA",
  LVA: "LV",
  LBN: "LB",
  LSO: "LS",
  LBR: "LR",
  LBY: "LY",
  LIE: "LI",
  LTU: "LT",
  LUX: "LU",
  MKD: "MK",
  MDG: "MG",
  MWI: "MW",
  MYS: "MY",
  MDV: "MV",
  MLI: "ML",
  MLT: "MT",
  MHL: "MH",
  MTQ: "MQ",
  MRT: "MR",
  MUS: "MU",
  MYT: "YT",
  MEX: "MX",
  FSM: "FM",
  MDA: "MD",
  MCO: "MC",
  MNG: "MN",
  MNE: "ME",
  MSR: "MS",
  MAR: "MA",
  MOZ: "MZ",
  MMR: "MM",
  NAM: "NA",
  NRU: "NR",
  NPL: "NP",
  NLD: "NL",
  ANT: "AN",
  NCL: "NC",
  NZL: "NZ",
  NIC: "NI",
  NER: "NE",
  NGA: "NG",
  NIU: "NU",
  NFK: "NF",
  MNP: "MP",
  NOR: "NO",
  OMN: "OM",
  PAK: "PK",
  PLW: "PW",
  PSE: "PS",
  PAN: "PA",
  PNG: "PG",
  PRY: "PY",
  PER: "PE",
  PHL: "PH",
  PCN: "PN",
  POL: "PL",
  PRT: "PT",
  PRI: "PR",
  QAT: "QA",
  REU: "RE",
  ROU: "RO",
  RUS: "RU",
  RWA: "RW",
  BLM: "BL",
  SHN: "SH",
  KNA: "KN",
  LCA: "LC",
  MAF: "MF",
  SPM: "PM",
  VCT: "VC",
  WSM: "WS",
  SMR: "SM",
  STP: "ST",
  SAU: "SA",
  SEN: "SN",
  SRB: "RS",
  SYC: "SC",
  SLE: "SL",
  SGP: "SG",
  SXM: "SX",
  SVK: "SK",
  SVN: "SI",
  SLB: "SB",
  SOM: "SO",
  ZAF: "ZA",
  SGS: "GS",
  SSD: "SS",
  ESP: "ES",
  LKA: "LK",
  SDN: "SD",
  SUR: "SR",
  SJM: "SJ",
  SWZ: "SZ",
  SWE: "SE",
  CHE: "CH",
  SYR: "SY",
  TWN: "TW",
  TJK: "TJ",
  TZA: "TZ",
  THA: "TH",
  TLS: "TL",
  TGO: "TG",
  TKL: "TK",
  TON: "TO",
  TTO: "TT",
  TUN: "TN",
  TUR: "TR",
  TKM: "TM",
  TCA: "TC",
  TUV: "TV",
  UGA: "UG",
  UKR: "UA",
  ARE: "AE",
  GBR: "GB",
  USA: "US",
  UMI: "UM",
  URY: "UY",
  UZB: "UZ",
  VUT: "VU",
  VEN: "VE",
  VNM: "VN",
  VIR: "VI",
  WLF: "WF",
  ESH: "EH",
  YEM: "YE",
  ZMB: "ZM",
  ZWE: "ZW",
  XKX: "XK"
};

// src/lib/locations.ts
var Currency;
(function(Currency2) {
  Currency2["AUD"] = "AUD";
  Currency2["NZD"] = "NZD";
  Currency2["USD"] = "USD";
  Currency2["FJD"] = "FJD";
  Currency2["GBP"] = "GBP";
  Currency2["CAD"] = "CAD";
  Currency2["SGD"] = "SGD";
  Currency2["MYR"] = "MYR";
  Currency2["EUR"] = "EUR";
  Currency2["MXN"] = "MXN";
})(Currency || (Currency = {}));
var locationDataByCountryCode = {
  AU: {
    currency: Currency.AUD,
    location: "AU",
    symbol: "$",
    country: "Australia"
  },
  US: {
    currency: Currency.USD,
    location: "US",
    symbol: "$",
    country: "United States"
  },
  CA: {
    currency: Currency.CAD,
    location: "CA",
    symbol: "$",
    country: "Canada"
  },
  GB: {
    currency: Currency.GBP,
    location: "GB",
    symbol: "\xA3",
    country: "United Kingdom"
  },
  NZ: {
    currency: Currency.NZD,
    location: "NZ",
    symbol: "$",
    country: "New Zealand"
  },
  DE: {
    currency: Currency.EUR,
    location: "DE",
    symbol: "\u20AC",
    country: "Europe"
  },
  SG: {
    currency: Currency.SGD,
    location: "SG",
    symbol: "$",
    country: "Singapore"
  },
  MY: {
    currency: Currency.MYR,
    location: "MY",
    symbol: "RM",
    country: "Malaysia"
  },
  FJ: {
    currency: Currency.FJD,
    location: "FJ",
    symbol: "$",
    country: "Fiji"
  },
  MX: {
    currency: Currency.MXN,
    location: "MX",
    symbol: "$",
    country: "Mexico"
  }
};
var locations2 = Object.values(locationDataByCountryCode).map((_loc) => _loc.location);
var locationDataByCountryName = {
  Australia: locationDataByCountryCode.AU,
  UnitedStates: locationDataByCountryCode.US,
  Canada: locationDataByCountryCode.CA,
  UnitedKingdom: locationDataByCountryCode.GB,
  NewZealand: locationDataByCountryCode.NZ,
  Europe: locationDataByCountryCode.DE,
  Singapore: locationDataByCountryCode.SG,
  Malaysia: locationDataByCountryCode.MY,
  Fiji: locationDataByCountryCode.FJ,
  Mexico: locationDataByCountryCode.MX
};
var locationDataByCurrency = {
  AUD: locationDataByCountryCode.AU,
  USD: locationDataByCountryCode.US,
  CAD: locationDataByCountryCode.CA,
  GBP: locationDataByCountryCode.GB,
  NZD: locationDataByCountryCode.NZ,
  EUR: locationDataByCountryCode.DE,
  SGD: locationDataByCountryCode.SG,
  MYR: locationDataByCountryCode.MY,
  FJD: locationDataByCountryCode.FJ,
  MXN: locationDataByCountryCode.MX
};
var countryISO3toISO2 = /* @__PURE__ */ __name((countryCode) => {
  return countryISO3toISO2Map[countryCode];
}, "countryISO3toISO2");
export {
  Currency,
  countryISO3toISO2,
  locationDataByCountryCode,
  locationDataByCountryName,
  locationDataByCurrency,
  locationSchema,
  locations2 as locations
};
