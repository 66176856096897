import SuccessGif from "@/assets//success.gif";
import { CheckboxField, RichTextAreaField, SwitchField, TextAreaField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import type { PreviewMassRefundResponse } from "@hx/console/massRefunds";
import { Alert, Modal, notification } from "@/ui/antd";
import { FC } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Field, InjectedFormProps, formValueSelector, reduxForm } from "redux-form";

const formName = "massRefundConfirmationModal";
const selector = formValueSelector(formName);

type MassRefundConfirmationFormValues = {
	customMessage?: string;
	reason?: string;
	sendEmails: boolean;
	termsAndConditions: boolean;
};

type MassRefundConfirmationModalProps = InjectedFormProps & {
	close: () => void;
	isModalOpen: boolean;
	totalNumberOfItemsAffected: number;
	massRefundData?: PreviewMassRefundResponse | null;
};

const MassRefundConfirmationModalComponent: FC<MassRefundConfirmationModalProps> = ({
	close,
	handleSubmit,
	isModalOpen,
	totalNumberOfItemsAffected,
	massRefundData
}) => {
	const navigate = useNavigate();
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const sendEmails = useAppSelector((state) => selector(state, "sendEmails"));
	const termsAndConditions = useAppSelector((state) => selector(state, "termsAndConditions"));

	const { event } = currentEvent;

	if (!event) {
		return null;
	}

	const { _id: eventId } = event;

	const { mutate, isLoading, isSuccess } = trpc.massRefunds.startMassRefund.useMutation({
		onError: (error) => {
			notification.error({
				description: error.message ?? "Failed to start mass refund",
				message: "Error"
			});
		}
	});

	const refund = (values: any) => {
		if (!massRefundData?.options) {
			return;
		}

		const { options } = massRefundData;
		mutate({
			eventId: eventId!,
			options: {
				...options,
				sendEmails: values.sendEmails,
				reason: values.reason,
				customMessage: values.customMessage
			}
		});
	};

	const handleDone = () => {
		navigate(`/console/my-events/${event._id}/mass-refunds/refund-history`);
		close();
	};

	return (
		<Modal
			title={`Refund ${totalNumberOfItemsAffected} items`}
			open={isModalOpen}
			onCancel={isSuccess ? handleDone : close}
			footer={
				isSuccess ? (
					<LegacyButton type="primary" ariaLabel="Done" style={{ float: "right" }} onClick={handleDone}>
						Done
					</LegacyButton>
				) : (
					<>
						<LegacyButton
							ariaLabel="Submit refund"
							disabled={!termsAndConditions || isLoading}
							onClick={handleSubmit(refund)}
							style={{ float: "right" }}
							type="primary"
						>
							Submit refund
						</LegacyButton>
						<LegacyButton ariaLabel="Cancel" onClick={close}>
							Cancel
						</LegacyButton>
					</>
				)
			}
		>
			{isSuccess ? (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						textAlign: "center"
					}}
				>
					<img src={SuccessGif} alt="success" height={120} width={120} style={{ marginBottom: 20 }} />
					<P>
						<b>Success!</b>
					</P>
					<P style={{ color: "grey" }}>Your refund request is now being processed.</P>
					<P style={{ color: "grey" }}>You'll receive a confirmation via email once the transaction is complete.</P>
				</div>
			) : (
				<LoadErrorView loading={isLoading}>
					<Alert
						message={
							<div style={{ paddingLeft: 20 }}>
								<b>Note: Cancelled tickets are excluded from all email campaigns.</b> Consider notifying your ticket
								buyers before initiating this cancellation.
								<br />
								<Link to="/console/comms/email-campaigns" target="_blank" rel="noopener noreferrer">
									Click here to notify all buyers in advance
								</Link>
							</div>
						}
						showIcon
						style={{ marginBottom: 24 }}
						type="warning"
					/>
					<form onSubmit={handleSubmit(refund)}>
						<Field
							component={SwitchField}
							description="Enable ‘Send a confirmation email’ to send an email detailing refunded items and finances to all refunded orders."
							label="Send refund confirmation email"
							name="sendEmails"
						/>
						<hr />
						{sendEmails && (
							<Field
								basicEditor
								component={RichTextAreaField}
								description="You can include a custom message as part of the refund confirmation email."
								label="Include a custom message"
								name="customMessage"
							/>
						)}
						<Field
							cols={2}
							component={TextAreaField}
							description="For internal reference only."
							label="Reason for refund"
							name="reason"
							placeholder="Provide a brief explanation for this mass refund."
						/>
						<hr />
						<Field
							component={CheckboxField}
							description={
								<>
									I acknowledge that refund requests once submitted are final and <b>cannot be cancelled.</b>
								</>
							}
							label="Acknowledgement"
							name="termsAndConditions"
							required
						/>
					</form>
				</LoadErrorView>
			)}
		</Modal>
	);
};

const MassRefundConfirmationModal = reduxForm<MassRefundConfirmationFormValues, any, MassRefundConfirmationModalProps>({
	form: formName,
	initialValues: {
		customMessage: undefined,
		reason: undefined,
		sendEmails: false,
		termsAndConditions: false
	},
	onSubmitFail: () => null,
	touchOnBlur: true,
	touchOnChange: true
})(MassRefundConfirmationModalComponent);

export default MassRefundConfirmationModal;
