import { Link } from "react-router-dom";

const StripeWarning = ({ event }) => {
	return (
		<div>
			<b>Please connect your Stripe account</b> You can connect in{" "}
			<Link to={`/console/my-events/${event._id}/payments/gateways`}>Payment Gateways</Link>
		</div>
	);
};

export default StripeWarning;

export const getStripeWarning = (event) => {
	if (!event.stripeConnectRequired) return null;
	return <StripeWarning event={event} />;
};
