import { getConfig } from "@/config";
import { upload } from "../../services/ImageService";
import { load } from "../../utils/Load";

export const LOAD_CANVA_REQUEST = "LOAD_CANVA_REQUEST";
export const LOAD_CANVA_SUCCESS = "LOAD_CANVA_SUCCESS";
export const LOAD_CANVA_ERROR = "LOAD_CANVA_ERROR";
export const UPLOAD_IMG_REQUEST = "UPLOAD_IMG_REQUEST";
export const UPLOAD_IMG_SUCCESS = "UPLOAD_IMG_SUCCESS";
export const UPLOAD_IMG_ERROR = "UPLOAD_IMG_ERROR";

export const loadCanva = () => {
	return (dispatch, getState) => {
		const state = getState();
		if (state.canva.script.loading || state.canva.script.loaded) {
			return;
		}
		dispatch({ type: LOAD_CANVA_REQUEST });
		load("https://sdk.canva.com/designbutton/v2/api.js")
			.then(() => {
				const CANVA_API_KEY = getConfig("CANVA_API_KEY");
				window.Canva.DesignButton.initialize({ apiKey: CANVA_API_KEY })
					.then(function (api) {
						window.canvaApi = api;
						dispatch({ type: LOAD_CANVA_SUCCESS });
					})
					.catch((err) => {
						console.log("canva error", err);
					});
			})
			.catch((err) => {
				console.log("canva load error", err);
				dispatch({ type: LOAD_CANVA_ERROR });
			});
	};
};

export const uploadImage = (imageUrl, canvaId, designId) => {
	return async (dispatch) => {
		try {
			dispatch({ type: UPLOAD_IMG_REQUEST, canvaId });
			const image = await upload(imageUrl);
			image.canvaDesignId = designId;
			dispatch({
				type: UPLOAD_IMG_SUCCESS,
				canvaId,
				imageUrl,
				image,
				designId
			});
		} catch (err) {
			dispatch({ type: UPLOAD_IMG_ERROR, canvaId, err });
		}
	};
};
