import { FIND_USER_ERROR, FIND_USER_REQUEST, FIND_USER_SUCCESS } from "../actions/userActions";

const initialState = {
	results: [],
	loading: false,
	error: false,
	query: "",
	page: 1
};
export default (state = initialState, action) => {
	switch (action.type) {
		case FIND_USER_REQUEST:
			return {
				...state,
				loading: true,
				error: false,
				page: action.page
			};
		case FIND_USER_SUCCESS:
			return {
				...state,
				loading: false,
				results: action.users
			};
		case FIND_USER_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};
