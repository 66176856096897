import axios from "./AxiosWrapper";

const EmailLogsService = {
	async get(search, page) {
		const response = await axios.get(`admin/email-logs`, {
			params: { search, page }
		});
		return response;
	},
	async find(id, emailType) {
		const response = await axios.get(`admin/email-logs/${id}/${emailType}`);
		return response;
	},
	async resend(id, emailType) {
		const response = await axios.post(`admin/email-logs/resend/${id}/${emailType}`);
		return response;
	}
};
export default EmailLogsService;
