import { RadioField } from "@/components/Fields";
import { Field } from "redux-form";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { H2, H4, P } from "@/components/text/Text";
import { W9Form } from "./usTaxpayerForms/W9form";
import { W8BENForm } from "./usTaxpayerForms/W8BENform";
import { W8BENEForm } from "./usTaxpayerForms/W8BENEform";
import { required } from "@/utils/Validators";
import { Alert } from "@/ui/antd";

const selectedTaxTypeIsRequired = required("Please select a taxpayer type");

type USTaxPayerFormProps = {
	formValues: any;
	clearTaxpayerInfoFields: () => void;
};
export const USTaxPayerForm = ({ formValues, clearTaxpayerInfoFields }: USTaxPayerFormProps) => {
	let formComponent;

	switch (formValues?.type) {
		case "w-9":
			formComponent = <W9Form formValues={formValues} />;
			break;

		case "w-8-ben":
			formComponent = <W8BENForm formValues={formValues} />;
			break;

		case "w-8-ben-e":
			formComponent = <W8BENEForm formValues={formValues} />;
			break;
	}

	return (
		<div>
			<H2>Taxpayer info</H2>
			<P style={{ maxWidth: "none" }}>
				If you run events in the US, your taxpayer information is required by US tax law and ensures you receive the
				right documents during tax season. Plus, adding it makes sure you get paid on time. We know tax forms aren't
				exactly fun, but we promise it's worth it.
			</P>

			<P style={{ maxWidth: "none" }}>
				If you're a nonprofit, you still need to provide your taxpayer information. Humanitix is required to collect
				taxpayer information for all paid events regardless of an organization's tax status.
			</P>

			<Alert
				showIcon
				message="Provide the taxpayer information that corresponds with the bank account connected to your event payouts"
				description={
					<>
						See{" "}
						<a
							href="https://www.irs.gov/individuals/international-taxpayers/classification-of-taxpayers-for-us-tax-purposes"
							target="_blank"
							rel="noreferrer"
						>
							IRS definitions
						</a>{" "}
						and <a onClick={OpenHelpHandler(HelpIds.usTaxPayer)}>Humanitix US tax guide</a>.
					</>
				}
				type="warning"
			/>
			<hr style={{ margin: "40px 0px" }} />

			<H4>Select the option that best describes your situation:</H4>

			<div style={{ width: "100%" }}>
				<Field
					name="usTaxpayerInfo.type"
					component={RadioField}
					onChange={clearTaxpayerInfoFields}
					button
					required
					validate={selectedTaxTypeIsRequired}
					options={[
						{
							label: "US individual or entity",
							value: "w-9"
						},
						{
							label: "Non-US individual",
							value: "w-8-ben"
						},
						{
							label: "Non-US entity or business",
							value: "w-8-ben-e"
						}
					]}
				/>
			</div>
			{formComponent ? (
				<div style={{ border: "solid 1px #f0f0f0", padding: "24px", borderRadius: "4px", marginTop: 26 }}>
					{formComponent}
				</div>
			) : null}
		</div>
	);
};
