import CancelSchedulesModal from "@/account/globalEmailCampaigns/CancelSchedulesModal";
import DetailsModal from "@/account/globalEmailCampaigns/DetailsModal";
import SelectOrders from "@/account/globalEmailCampaigns/SelectOrders";
import getSummarySentence from "@/account/globalEmailCampaigns/SummarySentence";
import TestEmailModel from "@/account/globalEmailCampaigns/TestEmailModel";
import { ContentBlock } from "@/components/AppBlocks";
import {
	CheckboxField,
	EmailAttachmentField,
	InputNumberField,
	LegacyInputField,
	RadioField,
	RichTextAreaField,
	SelectField
} from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import PersonalisationInformation from "@/components/PersonalisationInformation";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import EventSearch from "@/components/fields/EventSearchField";
import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";
import { H3 } from "@/components/text/Text";
import { dayjs } from "@/libs/dayjs";
import {
	cancelScheduledEmails,
	getCampaign,
	getCampaignSummary,
	saveAndEnqueue,
	saveDraft,
	showPreviewEmail,
	showTestEmail,
	approveCampaign,
	disapproveCampaign
} from "@/state/actions/emailCampaignsActions";
import { findEvents, getEvent, getEventsById } from "@/state/actions/eventActions";
import { Spacer } from "@/ui";
import { Alert, Tooltip } from "@/ui/antd";
import { getEmailPersonalisations, getTicketEmailPersonalisations } from "@/utils/Personalisations";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { email, required } from "@/utils/Validators";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, getFormValues, isValid, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";
import VerificationModal from "./VerificationModal";

const formName = "emailCampaign";

class CampaignForm extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.approve = this.approve.bind(this);
		this.disapprove = this.disapprove.bind(this);
		this.orderSelected = this.orderSelected.bind(this);
		this.handleEventOnChange = this.handleEventOnChange.bind(this);
		this.state = {
			isVerificationModalOpen: false,
			isCancelSchedulesOpen: false,
			detailsModalOpen: false
		};
		this.props.findEvents(1, {
			query: "",
			sortOrder: "relevant",
			filter: "notPast"
		});
		this.debouncedSearchEventNames = debounce(200, (query) => {
			this.props.findEvents(1, {
				query,
				sortOrder: "relevant",
				filter: "notPast"
			});
		});
	}

	componentDidMount() {
		const { getEventsById, getEvent, emailCampaigns } = this.props;
		const eventIds = emailCampaigns.campaign.data?.eventIds?.filter((id) => id !== "all");
		if (eventIds) {
			getEventsById(emailCampaigns.campaign.data.eventIds);
			if (eventIds.length === 1) getEvent(eventIds[0]);
		}
	}

	save(values, isDraft = false) {
		const { saveDraft, saveAndEnqueue, navigate, currentUser } = this.props;
		values.isDraft = isDraft;
		const userId = currentUser.user.id;
		if (isDraft) saveDraft(userId, values);
		else saveAndEnqueue(userId, values, navigate);
	}

	cancelScheduledEmails = () => {
		const { cancelScheduledEmails, emailCampaigns, currentUser } = this.props;
		const emailCampaignId = emailCampaigns.campaign.data._id;
		const userId = currentUser.user.id;
		cancelScheduledEmails(userId, emailCampaignId);
	};

	close = () => {
		const { navigate } = this.props;

		navigate("/console/comms/email-campaigns");
	};

	openDetailsModal = () => {
		const { getEventsById, campaign } = this.props;
		const eventIds = campaign?.queues?.map((q) => q.eventId);
		if (eventIds) {
			getEventsById(eventIds);
			this.setState({
				detailsModalOpen: true
			});
		}
	};

	closeDetailsModal = () => {
		this.setState({
			detailsModalOpen: false
		});
	};

	duplicate = () => {
		const { campaign } = this.props;
		window.location.href = `/console/comms/email-campaigns/new/${campaign._id}`;
	};

	approve() {
		const { campaign, approveCampaign } = this.props;
		approveCampaign(campaign._id);
	}

	disapprove() {
		const { campaign, disapproveCampaign } = this.props;
		disapproveCampaign(campaign._id);
	}
	onLocationChange = () => {
		const { eventIds } = this.props;
		//TODO: This was to reset any chosen events, but it is triggered
		//on first load clearing out saved values, so ¯\_(ツ)_/¯
		// const { dispatch, change } = this.props;
		// dispatch(change("eventIds", ["all"]));

		// reset the search results tho
		this.handleEventOnChange(eventIds);
	};

	handleEventOnChange(eventIds) {
		const { dispatch, getEvent } = this.props;

		// update new ids
		dispatch(change(formName, "eventIds", eventIds));
		if (eventIds && eventIds.length === 1) {
			getEvent(eventIds[0]);
		} else {
			//clear old data
			dispatch(change(formName, "eventDateIds", []));
			dispatch(change(formName, "to.type", "all"));
		}
	}

	orderSelected(evt) {
		const { dispatch } = this.props;
		dispatch(change(formName, "to.orderIds", evt));
	}

	nameRequired = required("Name is required");
	subjectRequired = required("Subject is required");
	messageRequired = (value, props) => {
		const message = this.trimmedMessage(value);
		if (!message) return "Message is required";
		if (props.to.include === "all" && message.indexOf("@EditOrderLink") > -1)
			return 'The @EditOrderLink can not be sent to attendees. Send to "Buyers only", or remove @EditOrderLink from your message';
	};

	futureDateRequired = (value) => {
		if (new HumaniDate(value).isPast()) return "Date must be in the future";
		return undefined;
	};

	replyToRequired = required("A reply email address is required");
	replyToEmail = email("Must be a valid email address");
	scheduleDateRequired = required("Scheduled date is required");

	trimmedMessage = (value) => {
		const div = document.createElement("div");
		div.innerHTML = value;
		const message = div.innerText.trim();
		return message;
	};

	toggleVerificationModalOpen = () => {
		const { getCampaignSummary, campaign, currentUser } = this.props;
		const userId = currentUser.user.id;
		if (!this.state.isVerificationModalOpen) getCampaignSummary(userId, campaign);
		this.setState({ isVerificationModalOpen: !this.state.isVerificationModalOpen });
	};
	toggleCancelSchedulesOpen = () => {
		this.setState({ isCancelSchedulesOpen: !this.state.isCancelSchedulesOpen });
	};

	render() {
		const {
			emailCampaigns,
			handleSubmit,
			currentEvent,
			toType,
			campaign,
			scheduleType,
			showTestEmail,
			orderIds,
			eventDateIds,
			showPreviewEmail,
			currentUser,
			userEvents,
			eventIds,
			ticketTypeIds,
			include,
			scheduleDate,
			attachments,
			searchEvents,
			isFormValid
		} = this.props;

		const isAdmin = currentUser?.user?.isAdmin;
		const { isVerificationModalOpen, isCancelSchedulesOpen, detailsModalOpen } = this.state;
		const singleEvent = eventIds?.filter((id) => id !== "all")?.length === 1;
		const eventLoading = userEvents.loading;
		const eventError = currentEvent.error || userEvents.error;
		const isEmailSent = emailCampaigns?.campaign?.data?.queues?.some((e) => e.status === "sent") || false;
		const requiresApproval =
			emailCampaigns?.campaign?.data?.queues?.some((e) => e.status === "requires-approval") || false;
		const approved = emailCampaigns?.campaign?.data?.approved || false;
		const hasQueues = emailCampaigns?.campaign?.data?.queues?.length > 0 || false;
		const areAllQueuesSent = emailCampaigns?.campaign?.data?.queues?.every((e) => e.status === "sent") || false;
		let disabledMessage = isEmailSent ? "Editing is not allowed once emails have been sent" : null;
		disabledMessage = approved ? "Editing is not allowed once emails have been approved" : null;

		const previewButton = {
			marginRight: 8,
			"@media (max-width: 600px)": {
				width: "100%",
				marginTop: 6,
				marginBottom: 12,
				justifyContent: "center"
			}
		};
		const sendButton = {
			"@media (max-width: 600px)": {
				width: "100%",
				justifyContent: "center"
			}
		};

		const dates = currentEvent?.event?.dates || [];
		//only filter out "passed dates" if it'
		const filteredDates = isEmailSent
			? dates
			: dates.filter((d) => {
					return new Date(d.endDate) > new Date(Date.now() - 12096e5); // two weeks ago
			  });

		const dateOptions = filteredDates.map((date) => {
			const dateStr = `${date.display.date}, ${date.display.time}`;
			return {
				value: date._id,
				label: dateStr
			};
		});

		const userId = currentUser.user.id;
		const toOptions = [
			{
				value: "all",
				label: "All orders"
			}
		];

		if (singleEvent) {
			toOptions.push({
				value: "byOrder",
				label: "Selected orders"
			});
			toOptions.push({
				value: "byTicketType",
				label: "Orders of ticket type"
			});
		}

		const ticketTypes = currentEvent.event && currentEvent.event.ticketTypes ? currentEvent.event.ticketTypes : [];
		const ticketTypeOptions = ticketTypes
			.filter((t) => !t.deleted && !t.disabled && !t.isDonation)
			.map((ticket) => {
				return { value: ticket._id, label: ticket.name };
			});

		const includeOptions = [
			{
				value: "orders",
				label: "Buyers only"
			},
			{
				value: "all",
				label: "All ( Buyers and attendees )"
			}
		];
		if (include === "attendees") {
			includeOptions.push({
				value: "attendees",
				label: "Attendees only"
			});
		}
		const personalisationsObject =
			campaign?.to?.include === "all" ? getTicketEmailPersonalisations() : getEmailPersonalisations();
		const sibling = (
			<LocationSelection componentName="email-campaigns" defaultLevel="user" onLocationChange={this.onLocationChange} />
		);

		const summarySentence = getSummarySentence(
			eventIds,
			scheduleType,
			toType,
			orderIds,
			ticketTypeIds,
			scheduleDate,
			include
		);

		const adminEventMsg =
			eventIds?.[0] === "all" && isAdmin
				? '👋 Hey admin, for you, "All events" only selects events you have created'
				: "";

		return (
			<div>
				<form>
					<PageHeading title={campaign?.name !== "" ? campaign?.name : `Email campaign`} children={sibling} />
					{disabledMessage && (
						<Alert
							style={{ marginBottom: 12 }}
							message="Editing disabled"
							description={`${disabledMessage}. You can duplicate this campaign from the Actions menu in the Campaigns list.`}
							type="warning"
							showIcon
						/>
					)}
					{requiresApproval && (
						<Alert
							style={{ marginBottom: 12 }}
							message="Requires approval"
							description="This campaign requires approval before it can be sent. Please contact support to approve this campaign."
							type="warning"
							showIcon
						/>
					)}

					<ContentBlock allowOverflow>
						<LoadErrorView loading={eventLoading} error={eventError} retryAction={() => null} renderChildren={false}>
							<Field
								name="name"
								label="Campaign name"
								required
								description="This helps you identify what this campaign is about and for"
								component={LegacyInputField}
								validate={this.nameRequired}
							/>
							<Field
								name="replyTo"
								label="Reply To"
								description="The email address to which replies will be sent"
								component={LegacyInputField}
								style={{ width: "100%", minWidth: "none" }}
								validate={[this.replyToRequired, this.replyToEmail]}
							/>
							<Spacer size="xxl" />

							<H3>Recipients</H3>
							<hr />
							<div
								className={css({
									'[title="All events"] ': {
										paddingRight: 10,
										".anticon-close": { display: "none" }
									}
								})}
							>
								<EventSearch
									eventSearchState={searchEvents}
									label="Select event(s)"
									description="Which event or events is this campaign about"
									handleEventOnChange={this.handleEventOnChange}
									placeholder="All events"
									selectedValues={eventIds}
									input={{ name: "eventIds" }}
									styleOverride={{ width: "100%", minWidth: "none", float: "none" }}
								/>
								<Alert
									style={{ marginBottom: 12 }}
									showIcon
									message={
										singleEvent
											? "You can select future dates, and dates that are up to 2 weeks in the past"
											: "You can select events that are live and that ended less than 2 weeks ago"
									}
									type="info"
								/>
							</div>
							{adminEventMsg && <p>{adminEventMsg}</p>}

							{singleEvent ? (
								<Field
									name="eventDateIds"
									label="Event date(s)"
									description="For which event date(s)"
									component={SelectField}
									placeholder="All dates"
									options={dateOptions}
									style={{ width: "100%", minWidth: "none" }}
									mode="multiple"
								/>
							) : null}
							<Field
								name="to.include"
								label="Include"
								description="Email attendees that provided an email address"
								component={RadioField}
								button
								style={{ width: "100%", minWidth: "none" }}
								options={includeOptions}
							/>
							<Alert
								style={{ marginBottom: 12 }}
								showIcon
								message={
									<>
										Note: You can only send emails to orders with valid tickets. Orders with donation only will not
										receive any emails. <br />
										Additionally, attendees can only be contacted if an email address was collected per ticket via
										checkout questions.
									</>
								}
								type="warning"
							/>

							<div style={singleEvent ? {} : { display: "none" }}>
								<Field
									name="to.type"
									label="Filter recipients"
									description={`Send an email to ${include === "orders" ? "buyer" : "buyer and attendee"} emails from `}
									component={RadioField}
									button
									options={toOptions}
								/>
							</div>

							{toType === "byTicketType" ? (
								<Field
									label="Select ticket types"
									mode="multiple"
									name="to.ticketTypeIds"
									placeholder="All ticket types"
									component={SelectField}
									options={ticketTypeOptions}
								/>
							) : null}
							{toType === "byOrder" ? (
								<SelectOrders
									orderSelected={this.orderSelected}
									selectedOrderIds={orderIds}
									eventDateIds={eventDateIds}
								/>
							) : null}

							<H3>Design</H3>
							<hr />
							<PersonalisationInformation personalisationList={personalisationsObject} />
							<Field
								name="subject"
								label="Subject"
								required
								description="The subject line of the email"
								component={LegacyInputField}
								style={{ width: "100%", minWidth: "none" }}
								validate={this.subjectRequired}
							/>
							<Field
								label="Include event banner image"
								id="showBanner"
								name="showBanner"
								component={CheckboxField}
								inline
								labelAfter
							/>
							<br />
							<Field
								label="Include event location and date"
								id="showEventInfo"
								name="showEventInfo"
								component={CheckboxField}
								inline
								labelAfter
							/>
							<Field
								label="Message"
								id="template"
								name="template"
								required
								mentionOptions={["firstName", "lastName", "email", "editLink"]}
								component={RichTextAreaField}
								customAdvancedPlugins={["addimage", "table"]}
								validate={this.messageRequired}
								rows={10}
								placeholder="This is the best event ever because ..."
								personalisations={personalisationsObject}
							/>

							<Field
								label="Attachments"
								name="attachments"
								attachments={attachments}
								component={EmailAttachmentField}
							/>

							<Spacer size="xxl" />

							<H3>Schedule</H3>
							<hr />
							<Field
								label="Send"
								name="schedule.type"
								component={RadioField}
								defaultValue="now"
								button
								options={[
									{
										label: "Now",
										value: "now"
									},
									{
										label: "At",
										value: "at"
									},
									{
										label: "Before",
										value: "before"
									},
									{
										label: "After",
										value: "after"
									}
								]}
								size="default"
							/>
							{scheduleType === "at" ? (
								<Field
									component={ReduxDateTimePicker}
									description={`The date your campaign will be sent (${dayjs().tz(dayjs.tz.guess()).format("z")})`}
									displayFormat="Do MMM YYYY"
									formName={formName}
									label="Date"
									name="schedule.date"
									placeholder="Date"
									size="default"
									showTime
									timeOptions={{ placeholder: "Start Time" }}
									timezone={dayjs.tz.guess()}
									type="text"
									validate={[this.scheduleDateRequired, this.futureDateRequired]}
								/>
							) : null}

							{scheduleType === "before" || scheduleType === "after" ? (
								<div>
									{" "}
									<Field
										label="Days and"
										labelAfter
										name="schedule.days"
										placeholder="0"
										width={100}
										type="text"
										component={InputNumberField}
										size="default"
									/>
									<Field
										label={`hours ${scheduleType} the event`}
										labelAfter
										name="schedule.hours"
										width={100}
										placeholder="0"
										type="text"
										component={InputNumberField}
										style={{ display: "inline-block" }}
										size="default"
									/>
								</div>
							) : null}

							<Spacer size="xxl" />

							<H3>Campaign summary</H3>
							<hr />
							<Alert style={{ marginBottom: 12 }} description={summarySentence} type="info" showIcon />
							<div
								className={css({
									display: "flex",
									justifyContent: "flex-start",
									marginTop: 18,
									"@media(max-width:600px)": { display: "block" }
								})}
							>
								<LegacyButton
									disabled={!isFormValid}
									htmlType="button"
									style={previewButton}
									onClick={() => {
										showPreviewEmail(campaign);
									}}
									ariaLabel="Preview"
								>
									Preview
								</LegacyButton>

								<LegacyButton
									disabled={!isFormValid}
									htmlType="button"
									onClick={() => {
										showTestEmail(campaign);
									}}
									style={sendButton}
									ariaLabel="Send test email"
								>
									Send test email
								</LegacyButton>
							</div>
						</LoadErrorView>
					</ContentBlock>

					<FormBar height="100">
						<Tooltip title={disabledMessage}>
							<LegacyButton
								style={{
									float: "right"
								}}
								size="large"
								type="primary"
								htmlType="button"
								onClick={this.toggleVerificationModalOpen}
								disabled={isEmailSent || !isFormValid}
								ariaLabel={scheduleType === "now" ? "Verify & send" : `Verify & ${hasQueues ? "update" : "schedule"}`}
							>
								{scheduleType === "now" ? "Verify & send" : `Verify & ${hasQueues ? "update" : "schedule"}`}
							</LegacyButton>
						</Tooltip>

						{hasQueues ? (
							<LegacyButton
								disabled={hasQueues ? areAllQueuesSent : isEmailSent}
								style={{ float: "right", marginRight: 10 }}
								onClick={this.toggleCancelSchedulesOpen}
								ariaLabel={`Cancel ${hasQueues && !areAllQueuesSent ? "remaining" : ""} schedules`}
								type="warning"
								size="large"
							>
								Cancel {hasQueues && !areAllQueuesSent ? "remaining" : ""} schedules
							</LegacyButton>
						) : (
							<LegacyButton
								disabled={isEmailSent || !isFormValid}
								style={{ float: "right", marginRight: 10 }}
								onClick={handleSubmit((values) => this.save(values, true))}
								ariaLabel="Save draft"
								type="default"
								size="large"
							>
								Save draft
							</LegacyButton>
						)}
						{hasQueues ? (
							<LegacyButton
								style={{ float: "right", marginRight: 10 }}
								onClick={this.openDetailsModal}
								ariaLabel="details"
								size="large"
							>
								Details
							</LegacyButton>
						) : null}
						<LegacyButton
							style={{ float: "right", marginRight: 10 }}
							onClick={this.duplicate}
							ariaLabel="duplicate"
							size="large"
						>
							Duplicate
						</LegacyButton>
						{isAdmin && !approved ? (
							<LegacyButton
								style={{ float: "right", marginRight: 10 }}
								onClick={this.approve}
								ariaLabel="approve"
								size="large"
							>
								Approve
							</LegacyButton>
						) : null}

						{isAdmin && approved ? (
							<LegacyButton
								style={{ float: "right", marginRight: 10 }}
								onClick={this.disapprove}
								ariaLabel="disapprove"
								size="large"
							>
								Disapprove
							</LegacyButton>
						) : null}

						<LegacyButton
							htmlType="button"
							style={{
								display: "inline-block"
							}}
							onClick={this.close}
							ariaLabel="Close"
							size="large"
						>
							Close
						</LegacyButton>
					</FormBar>
					<VerificationModal
						isModalOpen={isVerificationModalOpen}
						save={handleSubmit((values) => this.save(values, false))}
						toggleModalOpen={this.toggleVerificationModalOpen}
						campaign={campaign}
						scheduleType={scheduleType}
						scheduleDate={scheduleDate}
						campaignSummary={emailCampaigns.campaignSummary}
					/>
					<CancelSchedulesModal
						isModalOpen={isCancelSchedulesOpen}
						save={() => {
							this.cancelScheduledEmails();
							this.toggleCancelSchedulesOpen();
							this.props.getCampaign(userId, emailCampaigns.campaign.data._id);
						}}
						toggleModalOpen={this.toggleCancelSchedulesOpen}
						summarySentence={summarySentence}
					/>
				</form>
				<TestEmailModel userId={userId} />
				{hasQueues ? (
					<DetailsModal
						isOpen={detailsModalOpen}
						campaign={campaign}
						events={searchEvents.events}
						closeAction={this.closeDetailsModal}
					/>
				) : null}
			</div>
		);
	}
}

// Decorate with redux-form
CampaignForm = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(CampaignForm);

const selector = formValueSelector(formName);

export default connect(
	(state, ownProps) => {
		return {
			initialValues: ownProps.initialValues,
			currentUser: state.auth,
			userEvents: state.userEvents,
			currentEvent: state.currentEvent,
			emailCampaigns: state.emailCampaigns,
			campaign: getFormValues(formName)(state),
			toType: selector(state, "to.type"),
			scheduleType: selector(state, "schedule.type"),
			orderIds: selector(state, "to.orderIds"),
			ticketTypeIds: selector(state, "to.ticketTypeIds"),
			include: selector(state, "to.include"),
			eventIds: selector(state, "eventIds"),
			eventDateIds: selector(state, "eventDateIds"),
			scheduleDate: selector(state, "schedule.date"),
			limitedToOneEvent: selector(state, "limitedToOneEvent"),
			attachments: selector(state, "attachments"),
			searchEvents: state.events,
			isFormValid: isValid(formName)(state)
		};
	},
	{
		saveDraft,
		saveAndEnqueue,
		showTestEmail,
		getFormValues,
		showPreviewEmail,
		cancelScheduledEmails,
		getCampaignSummary,
		findEvents,
		getEventsById,
		getEvent,
		getCampaign,
		approveCampaign,
		disapproveCampaign
	}
)(CampaignForm);
