import { css } from "@emotion/css";
import { ReactNode } from "react";

interface IH5Props {
	children: ReactNode;
	style?: Record<string, unknown>;
	bold?: boolean;
}

const H5 = ({ bold = true, children, style = {} }: IH5Props) => {
	const customStyle = css({
		fontWeight: bold ? 600 : 400,
		fontSize: 14,
		margin: 0,
		marginBottom: 6,
		...style
	});
	return <h5 className={customStyle}>{children}</h5>;
};

export default H5;
