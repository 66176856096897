import {
	CLOSE_INVOICING_MODAL,
	GATEWAYS_UPDATE_ERROR,
	GATEWAYS_UPDATE_REQUEST,
	GATEWAYS_UPDATE_SUCCESS,
	OPEN_INVOICING_MODAL
} from "../actions/gatewayActions";

const initialState = {
	invoicingModalOpen: false,
	loading: false,
	error: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case OPEN_INVOICING_MODAL: {
			return {
				...state,
				invoicingModalOpen: true
			};
		}
		case CLOSE_INVOICING_MODAL: {
			return {
				...state,
				invoicingModalOpen: false
			};
		}
		case GATEWAYS_UPDATE_REQUEST: {
			return {
				...state,
				loading: true
			};
		}
		case GATEWAYS_UPDATE_ERROR: {
			return {
				...state,
				loading: false
			};
		}
		case GATEWAYS_UPDATE_SUCCESS: {
			return {
				...state,
				loading: false,
				invoicingModalOpen: false
			};
		}
		default:
			return state;
	}
};
