import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField, RadioField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton as HumaniButton } from "@/components/buttons/LegacyButton";
import { H2, P } from "@/components/text/Text";
import LocationService from "@/services/LocationService";
import { findEvents, getEventsById } from "@/state/actions/eventActions";
import { getAudiences, testConnection } from "@/state/actions/mailchimpActions";
import { saveUser } from "@/state/actions/userActions";
import { Alert } from "@/ui/antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { HumaniDate } from "@hx/dates";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { debounce } from "throttle-debounce";

class MailchimpSettings extends Component {
	constructor(props) {
		super(props);
		this.debouncedSearchEventNames = debounce(200, (query) =>
			this.props.findEvents(1, {
				query,
				sortOrder: "relevant",
				filter: "all"
			})
		);
	}

	handleEventSearch = (searchString) => {
		if (searchString.length > 2) {
			this.debouncedSearchEventNames(searchString);
		}
	};

	handleGetAudiences = () => {
		const { userId, getAudiences } = this.props;
		getAudiences(userId);
	};

	save = (values) => {
		const { userId, saveUser } = this.props;

		// Clears lastSyncedAt, so any changes are picked up by next sync
		saveUser(userId, {
			mailchimpSettings: { ...values, lastSyncedAt: undefined }
		});
	};

	load = () => {
		if (this.props.currentUser.user.mailchimpSettings.selectedEvents.length)
			this.props.getEventsById(this.props.currentUser.user.mailchimpSettings.selectedEvents);
		this.props.testConnection(this.props.currentUser.user.id);
	};

	componentDidMount() {
		this.load();
	}

	render() {
		const { handleSubmit, syncAll, currentUser, searchEvents } = this.props;
		const mailchimpSettings = currentUser && currentUser.user ? currentUser.user.mailchimpSettings : {};
		const lastSyncHasErrors = mailchimpSettings.lastSyncError && mailchimpSettings.lastSyncError.length > 0;
		const lastSyncDate = mailchimpSettings.lastSyncedAt
			? new HumaniDate(mailchimpSettings.lastSyncedAt, "guess", LocationService.getLocation(true)).formatting.dateTime()
			: null;
		const searchEventValues =
			searchEvents && searchEvents.events && Array.isArray(searchEvents.events)
				? searchEvents.events.map((e) => ({
						value: e._id,
						label: e.name
				  }))
				: [];

		const audienceLoading = this.props.mailchimp.audienceLoading;

		return (
			<LoadErrorView
				loading={!currentUser.user && currentUser.loading}
				error={currentUser.error}
				retryAction={() => {
					this.load();
				}}
			>
				{lastSyncDate ? (
					lastSyncHasErrors ? (
						<Alert
							message={`Last Sync Failed at ${lastSyncDate}`}
							description={mailchimpSettings.lastSyncError}
							type="error"
							showIcon
							style={{ marginBottom: 12 }}
						/>
					) : (
						<Alert
							message={`Last Sync Successful at ${lastSyncDate}`}
							type="success"
							showIcon
							style={{ marginBottom: 12 }}
						/>
					)
				) : null}
				<form onSubmit={handleSubmit(this.save)}>
					<ContentBlock>
						<H2>Settings</H2>
						<Field
							style={{ maxWidth: 600 }}
							label="Choose events to sync"
							description="Which events would you like to be synced to your Mailchimp audience?"
							id="syncAll"
							name="syncAll"
							vertical
							component={RadioField}
							options={[
								{
									label: "All",
									value: true
								},
								{
									label: "Selected Events",
									value: false
								}
							]}
						/>{" "}
						{syncAll === false && (
							<Field
								name="selectedEvents"
								label="Select Events"
								component={SelectField}
								showSearch
								onSearch={this.handleEventSearch}
								options={searchEventValues}
								mode="multiple"
							/>
						)}
						<hr />
						<Field
							label="Sync contacts who haven't opted-in"
							id="syncNonSubscribed"
							name="syncNonSubscribed"
							component={CheckboxField}
							inline
							labelAfter
						/>
						<p style={{ lineHeight: "24px", marginTop: -18 }}>
							<InfoCircleOutlined /> If checked, customers that don't opt-in will be synced as{" "}
							<a
								target="_blank"
								href="https://mailchimp.com/help/about-your-contacts/#heading+non-subscribed"
								rel="noopener noreferrer"
							>
								non-subscribed contacts
							</a>
							. See{" "}
							<a target="_blank" href="https://mailchimp.com/pricing/" rel="noopener noreferrer">
								https://mailchimp.com/pricing/
							</a>{" "}
							for how having more contacts impacts your Mailchimp billing.
						</p>
						<hr />
						<div style={{ position: "relative" }}>
							{mailchimpSettings.audiences ? (
								<Field
									label="Audience"
									description="Which Mailchimp audience will be populated with your contacts?"
									id="selectedAudienceId"
									name="selectedAudienceId"
									component={SelectField}
									options={mailchimpSettings.audiences.map((a) => {
										return { value: a.id, label: a.name };
									})}
									style={{ width: 240, height: 40 }}
								/>
							) : (
								<>
									<P>
										It looks like <strong>{mailchimpSettings.accountName}</strong> doesn't have any audiences.
									</P>
									<P>
										You'll need to{" "}
										<a href="https://mailchimp.com/help/create-audience/">create at least one audience</a> to sync to.
									</P>
								</>
							)}
							{audienceLoading ? (
								// <Icon
								//   type="loading"
								//   style={{
								//     color: "#353337",
								//     position: "absolute",
								//     top: mailchimpSettings.audiences ? "57px" : "35px",
								//     left: mailchimpSettings.audiences ? "259px" : "359px"
								//   }}
								// />
								<></>
							) : (
								<IconButton
									style={{
										position: "absolute",
										top: mailchimpSettings.audiences ? "45px" : "23px",
										left: mailchimpSettings.audiences ? "250px" : "350px"
									}}
									icon="refresh"
									onClick={this.handleGetAudiences}
									ariaLabel="Refresh audiences"
									showTooltip
									tooltip="Check for more audiences"
								/>
							)}
						</div>
					</ContentBlock>
					<FormBar>
						<HumaniButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							size="large"
							ariaLabel="Save"
						>
							Save
						</HumaniButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "mailchimpSettings";
const selector = formValueSelector(formName);
MailchimpSettings = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(MailchimpSettings);

export default connect(
	(state) => {
		const initState =
			state.currentUser && state.currentUser.user && state.currentUser.user.mailchimpSettings
				? state.currentUser.user.mailchimpSettings
				: {
						syncAll: true
				  };
		return {
			initialValues: initState,
			currentUser: state.currentUser,
			searchEvents: state.events,
			mailchimp: state.mailchimp,
			syncAll: selector(state, "syncAll")
		};
	},
	{ saveUser, getAudiences, findEvents, getEventsById, testConnection }
)(MailchimpSettings);
