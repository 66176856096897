import {
	DateTimeField,
	ImageUploadField,
	InputNumberField,
	LegacyInputField,
	RadioField,
	SelectField,
	SwitchField,
	TextAreaField
} from "@/components/Fields";
import IconButton from "@/components/IconButton";
import InputError from "@/components/fields/helpers/InputError";
import InputLabel from "@/components/fields/helpers/InputLabel";
import TableActions from "@/components/table/TableActions";
import { Label } from "@/components/text/Text";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import iconReference from "@/services/IconReference";
import type { TicketType } from "@/state/reducers/eventReducer";
import { Button } from "@/ui/antd";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { required } from "@/utils/Validators";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { Field, FieldArray } from "redux-form";
import TicketDateRange from "../../TicketDateRange";
import TicketHiddenDateRange from "../../TicketHiddenDateRange";
import TicketQuantity from "../../TicketQuantity";

const downArrow = iconReference["arrow_down"];

const valueRequired = required("Option is required");

const styles = {
	fieldContainer: css`
		display: inline-block;
		width: 100%;
		max-width: 88px;
		@media (max-width: 576px) {
			max-width: 90%;
		}
	`,
	field: css`
		min-width: 100%;
		width: 100%;
		max-width: 246px;
		margin-bottom: 6px;
	`
};

interface IOptionsProps {
	fields: any;
	options: any;
	meta: any;
}

const Options = ({ fields, options = [], meta }: IOptionsProps) => {
	const { error } = meta ?? {};
	if (fields && fields.length === 0) {
		fields.push({});
	}

	return (
		<div style={{ marginBottom: 24 }}>
			<InputLabel label="Options" description="Type amount" />
			<InputError touched error={error} />
			{options.map((_: any, index: number) => (
				<div key={index}>
					<div className={styles.fieldContainer}>
						<Field
							name={`${fields.name}[${index}].value`}
							placeholder="Amount"
							component={InputNumberField}
							required
							validate={[valueRequired]}
							min={0}
							max={100000}
							precision={2}
							size="small"
							className={styles.field}
						/>
					</div>

					{index ? (
						<div style={{ display: "inline-block", position: "relative", height: 26 }}>
							<TableActions
								containerStyle={{
									position: "absolute",
									left: 0,
									top: 0,
									paddingLeft: 10
								}}
							>
								<IconButton
									icon="delete"
									onClick={() => fields.remove(index)}
									tooltip="Delete"
									showTooltip
									ariaLabel="Delete"
								/>
							</TableActions>
						</div>
					) : null}
				</div>
			))}

			<Button
				size="small"
				icon={<PlusOutlined />}
				onClick={() => {
					fields.push({});
				}}
				aria-label="Add"
			>
				Add
			</Button>
		</div>
	);
};

interface ITicketAdvancedSettingsProps {
	ticket: any;
	index: number;
	touch: any;
	timezone: string;
	ticketTypes: TicketType[];
	packagedTickets: any;
	formName: string;
	location: string;
	fieldName: string;
	primaryTicketTypes: TicketType[];
}

const _checkDates = (_value: Date, allValues: Record<string, any>, _obj: never, label: string) => {
	const index = label.match(/\[([^<]+)]/)?.[1];
	if (!index) return;
	const ticketType = allValues.ticketTypes[index];
	const startDate = ticketType && ticketType.overrides ? ticketType.overrides.startDate : null;
	if (!startDate) return undefined;
	const endDate = ticketType && ticketType.overrides ? ticketType.overrides.endDate : null;
	if (!endDate) return undefined;
	if (startDate > endDate) return `End date must be after start date`;
	return undefined;
};

const TicketAdvancedSettings = ({
	ticket,
	index,
	touch,
	timezone,
	ticketTypes,
	packagedTickets,
	formName,
	location,
	fieldName,
	primaryTicketTypes = []
}: ITicketAdvancedSettingsProps) => {
	const [advancedOpen, setAdvancedOpen] = useState(false);

	const _toggleAdvanced = () => setAdvancedOpen((current) => !current);

	const [US_TAX_OPTIONS, setUSTaxOptions] = useState<{ label: string; value: string }[]>([]);
	const { isAdmin } = useCurrentUser();

	useEffect(() => {
		import("./taxJarCodes.json").then((data) => {
			setUSTaxOptions(data.US_TAX_OPTIONS);
		});
	}, []);

	return (
		<div key={ticket.key}>
			{ticket && ticket.priceRange && ticket.priceRange.enabled ? (
				<Field
					name={`${fieldName}[${index}].priceOptions.enabled`}
					label="Price range"
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					component={RadioField}
					button
					defaultValue={false}
					options={[
						{
							label: "Any amount",
							value: false
						},
						{
							label: "List of prices",
							value: true
						}
					]}
				/>
			) : null}
			{ticket.priceRange?.enabled && ticket.priceOptions?.enabled ? (
				<div style={{ marginBottom: 12 }}>
					<FieldArray
						name={`${fieldName}[${index}].priceOptions.options`}
						component={Options}
						options={ticketTypes[index].priceOptions.options}
						validate={(options: any) => {
							const validValues = !options
								? []
								: options.filter((option: any) => {
										if (!isNaN(option.value) && Number(option.value) !== 0) {
											return true;
										}
										return false;
								  });
							if (!validValues || !validValues.length) {
								return "At least one option must be entered";
							}
							return undefined;
						}}
					/>
				</div>
			) : null}
			{ticket.priceRange?.enabled && !ticket.priceOptions?.enabled ? (
				<div style={{ display: "flex" }}>
					<div style={{ marginRight: 16 }}>
						<Field
							label="Minimum Price"
							name={`${fieldName}[${index}].priceRange.min`}
							component={InputNumberField}
							placeholder="Min"
							min={0}
							max={100000}
							precision={2}
							allowsNullValue
						/>
					</div>
					<Field
						label="Maximum Price"
						name={`${fieldName}[${index}].priceRange.max`}
						component={InputNumberField}
						placeholder="Max"
						min={0}
						max={100000}
						precision={2}
						allowsNullValue
					/>
				</div>
			) : null}
			<TicketDateRange
				ticket={ticket}
				index={index}
				touch={touch}
				timezone={timezone}
				ticketTypes={ticketTypes}
				packagedTickets={packagedTickets}
				path={ticket.isSecondary ? "secondaryTicketTypes" : "ticketTypes"}
				formName={formName}
			/>
			<Field
				label="Description"
				name={`${fieldName}[${index}].description`}
				component={TextAreaField}
				rows={2}
				placeholder={ticket.isDonation ? "Donation description" : "Ticket description"}
			/>
			<Field
				label="Hidden"
				name={`${fieldName}[${index}].hiddenOptions.hidden`}
				component={SwitchField}
				description={
					<>
						Hide ticket, and decide who can see and purchase.{" "}
						<a onClick={OpenHelpHandler(HelpIds.accessCodes)}>Learn more</a> about hidden tickets & access codes.
					</>
				}
			/>
			{ticket.hiddenOptions?.hidden ? (
				<Field
					name={`${fieldName}[${index}].hiddenOptions.when`}
					label="Hide"
					component={RadioField}
					button
					defaultValue="always"
					options={[
						{
							label: "Always",
							value: "always"
						},
						{
							label: "When not on sale",
							value: "notOnSale"
						},
						{
							label: "When sold out",
							value: "soldOut"
						},
						{
							label: "For a custom date range",
							value: "customDate"
						},
						...(ticket.isSecondary
							? [
									{
										label: "Conditional on ticket selection",
										//This ticket will only appear when at least one of these tickets are in the basket
										value: "conditional"
									}
							  ]
							: [])
					]}
				/>
			) : null}
			{ticket.hiddenOptions?.hidden && ticket.hiddenOptions?.when === "customDate" ? (
				<TicketHiddenDateRange index={index} touch={touch} timezone={timezone} formName={formName} />
			) : null}
			{ticket.hiddenOptions?.hidden && ticket.hiddenOptions?.when === "conditional" ? (
				<Field
					label="Show if"
					description="This ticket will only appear when at least one of these tickets have been selected"
					name={`${fieldName}[${index}].hiddenOptions.ticketIds`}
					mode="multiple"
					component={SelectField}
					options={primaryTicketTypes
						.filter((tt) => !tt.isSecondary && !tt.deleted && !tt.isDonation)
						.concat(
							packagedTickets.filter((pt: { isSecondary: boolean; deleted: boolean }) => !pt.isSecondary && !pt.deleted)
						)
						.map((tt) => ({ label: tt.name, value: tt._id }))}
					placeholder="Show if"
					allowsNullValue
				/>
			) : null}

			<Field
				name={`${fieldName}[${index}].image`}
				label="Image"
				description={
					<>
						Use an image with a 2:1 ratio (e.g. 1200px by 600px).{" "}
						<a onClick={OpenHelpHandler(HelpIds.ticketImages)}>Learn more</a>
					</>
				}
				component={ImageUploadField}
				aspectRatio={2}
				imageStyleOverride={{
					width: "100%",
					maxHeight: "230px",
					margin: "auto",
					paddingTop: undefined,
					aspectRatio: "2"
				}}
			/>
			<hr />
			<div>
				<button
					onClick={_toggleAdvanced}
					type="button"
					className={css({
						border: "none",
						background: "transparent",
						display: "flex",
						alignItems: "center",
						marginBottom: 16,
						cursor: "pointer",
						img: {
							marginLeft: 8,
							transform: `rotate(${advancedOpen ? "180" : "0"}deg)`
						},
						label: {
							cursor: "pointer"
						}
					})}
				>
					<Label>Advanced settings</Label>
					<img src={String(downArrow)} alt="Down arrow" />
				</button>
				{advancedOpen ? (
					<div>
						{!ticket.isDonation ? (
							<TicketQuantity
								ticket={ticket}
								touch={touch}
								minPath={`${fieldName}[${index}].minTickets`}
								maxPath={`${fieldName}[${index}].maxTickets`}
								multiplesPath={`${fieldName}[${index}].multiplesOf`}
							/>
						) : null}

						{ticketTypes[index].date || ticketTypes[index].location ? (
							<>
								<Field
									label="Date and time override"
									description="The date that will be shown on this ticket, overrides the event date"
									name={`${fieldName}[${index}].date`}
									component={LegacyInputField}
									placeholder="e.g. 11th November, 930am - 130pm"
								/>
								<Field
									label="Location override"
									description="The location that will be shown on this ticket, overrides the event location"
									name={`${fieldName}[${index}].location`}
									component={LegacyInputField}
									placeholder="e.g. Building 1, 7 Mary Street, Brisbane"
								/>
							</>
						) : (
							<>
								<InputLabel
									label="Date and time override"
									description="The date that will be shown on this ticket, overrides the event date"
								/>
								<Field
									label="Start date"
									name={`${fieldName}[${index}].overrides.startDate`}
									component={DateTimeField}
									timezone={timezone}
									formName={formName}
									validate={[_checkDates]}
								/>
								<Field
									label="End date"
									name={`${fieldName}[${index}].overrides.endDate`}
									component={DateTimeField}
									timezone={timezone}
									validate={[_checkDates]}
									formName={formName}
								/>
								<Field
									label="Location override"
									description="The location that will be shown on this ticket, overrides the event location"
									name={`${fieldName}[${index}].overrides.location`}
									component={LegacyInputField}
								/>
							</>
						)}
						<Field
							label="Sold out override"
							description="Ticket will appear as sold out disregarding the number of tickets sold"
							name={`${fieldName}[${index}].markedAsSoldOut`}
							component={SwitchField}
						/>
						{isAdmin && location === "US" ? (
							<>
								<hr />
								<Label bold>Admin only</Label>
								<Field
									label="Custom tax code"
									name={`${fieldName}[${index}].taxJarCodeOverwrite`}
									component={SelectField}
									options={US_TAX_OPTIONS}
									showSearch
									filterOption={(input: string, option: { label: string; value: string }) =>
										(option.label ?? "").toLowerCase().includes(input.toLowerCase())
									}
									optionFilterProp="children"
								/>
							</>
						) : null}
					</div>
				) : null}
			</div>
			<hr />
		</div>
	);
};

export default TicketAdvancedSettings;
