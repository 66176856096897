import { CodeBlock, FormLabel } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type FormLabelProps = {
	children: ReactNode;
	noMargin?: boolean;
	placement?: InfoTooltipProps["placement"];
	required?: boolean;
	size?: number;
	tooltip?: InfoTooltipProps["title"];
};`;

const sample = `import { FormLabel } from "@/ui";

<FormLabel>Form item</FormLabel>
<FormLabel tooltip="This is a tooltip">Form item</FormLabel>
<FormLabel required>Form item</FormLabel>
<FormLabel size={20}>Form item</FormLabel>
<FormLabel noMargin>Form item</FormLabel>
`;

export const FormLabelPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<FormLabel>Form item</FormLabel>
			<hr />
			<b>With tooltip</b>
			<FormLabel tooltip="This is a tooltip">Form item</FormLabel>
			<hr />
			<b>With required</b>
			<FormLabel required>Form item</FormLabel>
			<hr />
			<b>With size</b>
			<FormLabel size={20}>Form item</FormLabel>
			<hr />
			<b>With no margin</b>
			<FormLabel noMargin>Form item</FormLabel>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
