import { css } from "@emotion/css";
import emojiOne from "../assets/promotionalHub/emoji1.svg";
import emojiTwo from "../assets/promotionalHub/emoji2.svg";
import eventAnnouncementTemplate from "../assets/promotionalHub/event-announcement.png";
import eventCountdownTemplate from "../assets/promotionalHub/event-countdown.png";
import eventPostersTemplate from "../assets/promotionalHub/event-posters-templates.png";
import facebookTemplate from "../assets/promotionalHub/facebook-templates.png";
import instagramStoryTemplate from "../assets/promotionalHub/instagram-story-template.png";
import postGridImage from "../assets/promotionalHub/posts-grid-bg@3x.webp";
import socialMediaTemplate from "../assets/promotionalHub/social-media-templates.png";
import tabletMockupImage from "../assets/promotionalHub/tablet-mockup@3x.png";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3, P } from "../components/text/Text";

import personInYellow from "../assets/promotionalHub/genericCharities/people_in_yellow.png";
import personInGreen from "../assets/promotionalHub/genericCharities/person_in_green.png";
import personInPink from "../assets/promotionalHub/genericCharities/person_in_pink.png";
import personInRed from "../assets/promotionalHub/genericCharities/person_in_red.png";
import personWithBlackBg from "../assets/promotionalHub/genericCharities/person_with_blackboard_background.png";
import personWithHat from "../assets/promotionalHub/genericCharities/person_with_hat.png";
import personWithSandBg from "../assets/promotionalHub/genericCharities/person_with_sand_bg.png";
import personWithoutHat from "../assets/promotionalHub/genericCharities/person_without_hat.png";

const promoteYourEventTemplateList = [
	{
		key: "eventAnnouncement",
		image: eventAnnouncementTemplate,
		title: "Event announcement",
		link: "https://www.canva.com/design/DAEDUqrQX9M/UuAMx9o3R_tALRbuBG7Ybw/view?utm_content=DAEDUqrQX9M&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
	},
	{
		key: "eventCountdown",
		image: eventCountdownTemplate,
		title: "Event countdown",
		link: "https://www.canva.com/design/DAEDx7_k3W8/3wjcUzVaYLhoWeKXXHh22A/view?utm_content=DAEDx7_k3W8&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
	}
];

const moreSocialMediaTemplateList = [
	{
		key: "socialMediaTemplate",
		image: socialMediaTemplate,
		title: "Social media templates",
		link: "https://www.canva.com/templates/search/instagram-posts/"
	},
	{
		key: "facebookTemplate",
		image: facebookTemplate,
		title: "Facebook templates",
		link: "https://www.canva.com/templates/search/facebook-posts/"
	},
	{
		key: "eventPostersTemplate",
		image: eventPostersTemplate,
		title: "Event poster templates",
		link: "https://www.canva.com/templates/search/posters/"
	},
	{
		key: "instagramStoryTemplate",
		image: instagramStoryTemplate,
		title: "Instagram story templates",
		link: "https://www.canva.com/templates/search/instagram-stories/"
	}
];

const GenericCharityImages = [
	personWithoutHat,
	personWithSandBg,
	personWithHat,
	personWithBlackBg,
	personInRed,
	personInPink,
	personInGreen,
	personInYellow
];

const CANVA_URL =
	"https://www.canva.com/design/DAEDbYI9wfY/jav9kzw8JfUebX2MPFiM0Q/view?utm_content=DAEDbYI9wfY&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview";

const templateWrapper = css({
	display: "flex",
	flexWrap: "wrap"
});

const templateCard = css({
	width: 190,
	marginRight: 23,
	marginBottom: 16,
	"@media(max-width:600px)": {
		width: 130,
		marginRight: 8
	},
	":last-child": {
		marginRight: 0
	},
	img: {
		maxHeight: 150,
		"@media(max-width:600px)": {
			maxHeight: 103
		}
	},
	P: {
		color: "#196097",
		marginTop: 8,
		textAlign: "center"
	}
});

const templateCardImgWrapper = css({
	width: 190,
	minWidth: 190,
	minHeight: 190,
	heigh: 190,
	borderRadius: 4,
	border: "solid 1px #dedede",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	"@media(max-width:600px)": {
		width: 130,
		minWidth: 130,
		minHeight: 130,
		heigh: 130
	},
	"&:hover": {
		border: "solid 1px #ffb18f"
	}
});

const postGridWrapper = css({
	width: "100%",
	height: 620,
	background: `url(${postGridImage}) center`,
	backgroundSize: "cover",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "32px 0px 58px 0px",
	H3: {
		color: "#ffffff",
		textAlign: "center"
	},
	H5: {
		lineHeight: "14px"
	},
	P: {
		color: "#ffffff",
		maxWidth: 500,
		textAlign: "center"
	},
	Button: {},

	"@media(max-width:556px)": {
		padding: "24px 10px"
	}
});

const tabletMockupWrapper = css({
	display: "flex",
	"@media(max-width:556px)": {
		img: {
			display: "none"
		}
	}
});

const canvaButtonContainer = css({
	marginTop: 24,
	"@media(max-width:556px)": {
		marginTop: 16
	}
});

const tabletMockup = css({
	height: 370,
	width: 258,
	background: `url(${tabletMockupImage}) center`,
	backgroundSize: "cover",
	margin: "10px 8px 0px",
	padding: "34px 17px 0px 17px"
});

const SocialMedia = ({ tab, selectedEvent = [] }) => {
	const randomImageIndex = Math.floor(Math.random() * GenericCharityImages.length);
	const randomImage = GenericCharityImages[randomImageIndex];

	const instaImage = css({
		height: 224,
		width: 224,
		background: `url(${randomImage}) center`,
		backgroundSize: "cover"
	});

	const impactOnImage = css({
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		H4: {
			marginBottom: 0,
			marginTop: 0,
			color: "#323232"
		},
		P: {
			marginTop: 0,
			marginBottom: 0,
			color: "#323232",
			fontSize: 12,
			maxWidth: 191
		}
	});

	if (tab === "individual" && !selectedEvent.length) return null;

	return (
		<>
			<div className={postGridWrapper}>
				{tab === "all" ? (
					<>
						<H3>
							It’s time to spread the word and sell some tickets!{" "}
							<span role="img" aria-label="celebrate">
								🎉
							</span>
						</H3>
						<P>
							Use Canva templates to promote your event or choose from our pre-made templates to share the incredible
							impact you're having by teaming up with Humanitix!{" "}
							<span role="img" aria-label="wink">
								😉
							</span>
						</P>
					</>
				) : (
					<>
						<H3>
							Here’s how to get more people to your event
							<span role="img" aria-label="celebrate">
								🎉
							</span>
						</H3>
						<P>
							These tools and templates will help you sell more tickets. We want to make your event big, so our impact
							on education can be big too.{" "}
							<span role="img" aria-label="wink">
								😉
							</span>
						</P>
					</>
				)}

				<div className={tabletMockupWrapper}>
					<img src={emojiOne} alt="emojis1" />
					<div className={tabletMockup}>
						<div className={instaImage} />
						<div className={impactOnImage} />
					</div>
					<img src={emojiTwo} alt="emojis 2" />
				</div>

				<div className={canvaButtonContainer}>
					<a href={CANVA_URL} target="_blank" key="canva_button" rel="noopener noreferrer">
						<LegacyButton type="primary" ariaLabel="Use Canva templates">
							Use Canva templates
						</LegacyButton>
					</a>
				</div>
			</div>
			<hr style={{ marginTop: 24, marginBottom: 17 }} />
			{tab === "individual" ? (
				<>
					<H3>Promote your event</H3>
					<P desktopMarginBottom={24} mobileMarginBottom={16}>
						Get started with pre-built templates, customise them by adding your event image and text.
					</P>
					<div className={templateWrapper}>
						{promoteYourEventTemplateList.map((item) => {
							return (
								<a
									href={item.link}
									target="_blank"
									className={templateCard}
									key={`promoHub_templateCard_${item.key}`}
									rel="noopener noreferrer"
								>
									<div className={templateCardImgWrapper}>
										<img src={item.image} alt={item.key} />
									</div>
									<P desktopMarginBottom={0} mobileMarginBottom={0}>
										<b>{item.title}</b>
									</P>
								</a>
							);
						})}
					</div>
					<hr style={{ marginTop: 2, marginBottom: 17 }} />
				</>
			) : null}

			<H3>More social media templates</H3>
			<P desktopMarginBottom={24} mobileMarginBottom={16}>
				Create eye-catching promotional materials with Canva.
			</P>
			<div className={templateWrapper}>
				{moreSocialMediaTemplateList.map((item) => {
					return (
						<a
							href={item.link}
							target="_blank"
							className={templateCard}
							key={`promoHub_templateCard_${item.key}`}
							rel="noopener noreferrer"
						>
							<div className={templateCardImgWrapper}>
								<img src={item.image} alt={item.key} />
							</div>
							<P desktopMarginBottom={0} mobileMarginBottom={0}>
								<b>{item.title}</b>
							</P>
						</a>
					);
				})}
			</div>
		</>
	);
};

export default SocialMedia;
