import { notification } from "@/ui/antd";
import AppMessageService from "../../services/AppMessageService";

export const GET_APP_MESSAGE_REQUEST = "GET_APP_MESSAGE_REQUEST";
export const GET_APP_MESSAGE_SUCCESS = "GET_APP_MESSAGE_SUCCESS";
export const GET_APP_MESSAGE_ERROR = "GET_APP_MESSAGE_ERROR";

export const CREATE_APP_MESSAGE_REQUEST = "CREATE_APP_MESSAGE_REQUEST";
export const CREATE_APP_MESSAGE_SUCCESS = "CREATE_APP_MESSAGE_SUCCESS";
export const CREATE_APP_MESSAGE_ERROR = "CREATE_APP_MESSAGE_ERROR";

export const SAVE_APP_MESSAGE_REQUEST = "SAVE_APP_MESSAGE_REQUEST";
export const SAVE_APP_MESSAGE_SUCCESS = "SAVE_APP_MESSAGE_SUCCESS";
export const SAVE_APP_MESSAGE_ERROR = "SAVE_APP_MESSAGE_ERROR";

export const LIST_APP_MESSAGE_REQUEST = "LIST_APP_MESSAGE_REQUEST";
export const LIST_APP_MESSAGE_SUCCESS = "LIST_APP_MESSAGE_SUCCESS";
export const LIST_APP_MESSAGE_ERROR = "LIST_APP_MESSAGE_ERROR";

export const DELETE_APP_MESSAGE_REQUEST = "DELETE_APP_MESSAGE_REQUEST";
export const DELETE_APP_MESSAGE_SUCCESS = "DELETE_APP_MESSAGE_SUCCESS";
export const DELETE_APP_MESSAGE_ERROR = "DELETE_APP_MESSAGE_ERROR";

export const CLEAR_APP_MESSAGE = "CLEAR_APP_MESSAGE";

export const getAppMessage = (messageId) => {
	return (dispatch) => {
		dispatch({ type: GET_APP_MESSAGE_REQUEST });
		AppMessageService.get(messageId)
			.then((message) => {
				dispatch({ type: GET_APP_MESSAGE_SUCCESS, appMessage: message });
			})
			.catch((err) => {
				dispatch({ type: GET_APP_MESSAGE_ERROR, error: err });
			});
	};
};

export const deleteAppMessage = (messageId) => {
	return (dispatch) => {
		dispatch({ type: DELETE_APP_MESSAGE_REQUEST });
		AppMessageService.delete(messageId)
			.then((messages) => {
				dispatch({ type: DELETE_APP_MESSAGE_SUCCESS, appMessages: messages });
			})
			.catch((err) => {
				dispatch({ type: DELETE_APP_MESSAGE_ERROR, error: err });
			});
	};
};

export const updateAppMessage = (messageId, message) => {
	return (dispatch) => {
		dispatch({ type: SAVE_APP_MESSAGE_REQUEST });
		AppMessageService.update(messageId, message)
			.then(() => {
				dispatch({ type: SAVE_APP_MESSAGE_SUCCESS });
			})
			.catch((err) => {
				dispatch({ type: SAVE_APP_MESSAGE_ERROR, error: err });
			});
	};
};

export const createAppMessage = (eventId, message) => {
	return (dispatch) => {
		dispatch({ type: CREATE_APP_MESSAGE_REQUEST });
		AppMessageService.create(eventId, message)
			.then(() => {
				notification.success({
					message: "Success",
					description: "App scanning message successfully created"
				});
				dispatch({ type: CREATE_APP_MESSAGE_SUCCESS });
			})
			.catch((err) => {
				notification.success({
					message: "Error",
					description: "Failed to create app scanning message"
				});
				dispatch({ type: CREATE_APP_MESSAGE_ERROR, error: err });
			});
	};
};

export const listAppMessage = (eventId) => {
	return (dispatch) => {
		dispatch({ type: LIST_APP_MESSAGE_REQUEST });
		AppMessageService.find(eventId)
			.then((messages) => {
				dispatch({ type: LIST_APP_MESSAGE_SUCCESS, messages });
			})
			.catch((err) => {
				dispatch({ type: LIST_APP_MESSAGE_ERROR, error: err });
			});
	};
};

export const clearAppMessage = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_APP_MESSAGE });
	};
};
