const Separator = () => {
	return (
		<div
			style={{
				marginTop: "16px",
				marginBottom: 16,
				borderBottom: "solid 1px",
				borderColor: "#dedede"
			}}
		/>
	);
};

export default Separator;
