import { Flex, Modal } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import history from "@/history";
import { checkInWizard } from "./utils/eventCreationUtils";

function FinishLater(props) {
	const { saveFunction, handleSubmit, currentEvent } = props;
	const [isOpen, setIsOpen] = useState(false);
	const [display, setDisplay] = useState(false);
	useEffect(() => {
		const inWizard = checkInWizard(currentEvent.event);
		setDisplay(inWizard);
	}, []);

	if (!display) return null;
	const saveLaterFunction = handleSubmit
		? () => {
				handleSubmit((values) => {
					saveFunction({ ...values, finishLater: true });
				})();
				setIsOpen(false);
		  }
		: saveFunction;
	return (
		<>
			<Modal
				title="Unsaved changes"
				open={isOpen}
				onCancel={() => setIsOpen(false)}
				footer={
					<Flex justify="space-between" gap="sm">
						<LegacyButton
							ariaLabel="cancel finish later"
							dataCy="cancel-finish-later"
							htmlType="button"
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</LegacyButton>
						<Flex gap="sm">
							<LegacyButton
								ariaLabel="save finish later"
								dataCy="save-finish-later"
								htmlType="button"
								onClick={saveLaterFunction}
								type="primary"
							>
								Save
							</LegacyButton>
							<LegacyButton
								ariaLabel="dont save finish later"
								dataCy="dont-save-finish-later"
								htmlType="button"
								onClick={() => history.push("/console/dashboard")}
							>
								Don't save
							</LegacyButton>
						</Flex>
					</Flex>
				}
			>
				Would you like to save changes before exiting? Any unsaved change will be lost.
			</Modal>
			<LegacyButton
				htmlType="button"
				style={{
					float: "left"
				}}
				ariaLabel="finish later"
				dataCy="finish-later"
				onClick={() => setIsOpen(true)}
			>
				Finish later
			</LegacyButton>
		</>
	);
}

export default connect((state) => {
	return {
		currentEvent: state.currentEvent
	};
}, {})(FinishLater);
