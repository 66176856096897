const Info: Icon = ({ color = "#ffffff" }: IconPropType) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path
					fill={color}
					d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"
				/>
				<path d="M0 0L24 0 24 24 0 24z" />
			</g>
		</svg>
	);
};
export default Info;
