import React, { useEffect } from "react";

const debounce = (func: () => void, wait: number) => {
	let timeout: NodeJS.Timeout;
	return () => {
		clearTimeout(timeout);
		timeout = setTimeout(func, wait);
	};
};

export const useViewport = () => {
	const debounceTime = 50;
	const [width, setWidth] = React.useState(window.innerWidth);
	const [height, setHeight] = React.useState(window.innerHeight);

	const isMobile = width < 600;
	const isTablet = width >= 600 && width < 1024;
	const isDesktop = width >= 1024;

	useEffect(() => {
		const handleWindowResize = debounce(() => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		}, debounceTime);

		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, []);

	return { width, height, isMobile, isTablet, isDesktop };
};
