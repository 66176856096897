import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import IconButton from "@/components/IconButton";
import { H3 } from "@/components/text/Text";
import Settings from "./Settings";

import fileIcon from "@/assets/virtualEventHub/ic-file.svg";
import imageIcon from "@/assets/virtualEventHub/ic-image.svg";
import livestreamIcon from "@/assets/virtualEventHub/ic-livestream.svg";
import presentersIcon from "@/assets/virtualEventHub/ic-presentor-info.svg";
import textIcon from "@/assets/virtualEventHub/ic-text.svg";
import videoIcon from "@/assets/virtualEventHub/ic-video.svg";
import webinarIcon from "@/assets/virtualEventHub/ic-webinar.svg";

const icons = {
	file: fileIcon,
	image: imageIcon,
	livestream: livestreamIcon,
	presenters: presentersIcon,
	text: textIcon,
	video: videoIcon,
	webinar: webinarIcon
};

class ElementHeader extends Component {
	constructor(props) {
		super(props);
		this.state = { settingOpen: false };
	}
	toggleSettings = () => {
		const { settingOpen } = this.state;
		this.setState({ settingOpen: !settingOpen });
	};
	render = () => {
		const { fieldPath, element, name, index, totalElements, move, deleteElement, hubSettings } = this.props;
		const elementName = name ? name : element.name;
		const inputType = element.inputType ? element.inputType : elementName.toLowerCase();
		const { settingOpen } = this.state;

		return (
			<div style={{ marginBottom: 12 }}>
				<div style={{ display: "flex" }}>
					<div
						style={{
							flex: 1,
							background: `url(${icons[inputType]}) no-repeat left center`,
							backgroundSize: 32,
							padding: "6px 0 0 36px"
						}}
					>
						<H3>{elementName}</H3>
					</div>
					<div style={{ display: "flex" }}>
						<IconButton
							icon="arrow_down"
							ariaLabel="Move down"
							disabled={index === totalElements - 1}
							onClick={() => {
								move(index, index + 1);
							}}
						/>
						<IconButton
							icon="arrow_up"
							ariaLabel="Move up"
							disabled={index === 0}
							onClick={() => {
								move(index, index - 1);
							}}
						/>
						<IconButton icon="settings" ariaLabel="Settings" onClick={this.toggleSettings} expanded={settingOpen} />
						<IconButton
							icon="delete"
							ariaLabel="Delete"
							onClick={() => {
								deleteElement(element, index);
							}}
						/>
					</div>
				</div>
				<Settings
					fieldPath={`${fieldPath}.settings`}
					visible={settingOpen}
					settings={element.settings}
					hubSettings={hubSettings}
				/>
			</div>
		);
	};
}

const selector = formValueSelector("virtualEventsHub");

export default connect(
	(state) => ({
		body: selector(state, "body"),
		hubSettings: selector(state, "settings")
	}),
	{}
)(ElementHeader);
