import { Field } from "redux-form";
import { Alert, Table, Tooltip } from "@/ui/antd";
import { ColumnType } from "antd/lib/table";
import { CheckboxField, InputNumberField } from "@/components/Fields";
import { required } from "@/utils/Validators";
import P from "@/components/text/P";
import { truncateString } from "@/utils/Format";

type TicketType = {
	_id: string;
	name: string;
	deleted: boolean;
	disabled: boolean;
	isDonation: boolean;
	mappedSeats: number;
	isMappedToSeatingMap: boolean;
	quantity: number;
	index: number;
} & any;

type BasicNumberMatch = { [key: string]: number };
interface ManageTicketCapacityTableProps {
	ticketTypes: TicketType[];
	handleAutoMatchingCapacities: (
		ticketOrPackageId: string,
		type: "TOGGLE_MATCH_AUTOMATICALLY_FOR_TICKET_TYPES" | "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_TICKET_TYPES"
	) => void;
	isLoadingOnSave: boolean;
	ticketTypeColors: BasicNumberMatch;
}

const quantityRequired = required("Quantity is required");

const convertToHexString = (num: number): string => {
	return ("000000" + num.toString(16)).substr(-6);
};

const ManageTicketCapacityTable = ({
	ticketTypes,
	handleAutoMatchingCapacities,
	isLoadingOnSave,
	ticketTypeColors
}: ManageTicketCapacityTableProps) => {
	const isAllMappedTicketsOnAutoMatch = ticketTypes
		.filter((t) => t.mappedSeats)
		.every((ticket) => ticket.isMappedToSeatingMap);
	const columns: ColumnType<any>[] = [
		{
			title: "Type",
			dataIndex: "name",
			key: "name",
			width: 230,
			render: (_, { name, _id }: Pick<TicketType, "name" | "_id">) => {
				const ticketTypeColor = ticketTypeColors[String(_id)];
				return (
					<div style={{ width: 230, display: "flex", alignItems: "center" }}>
						{ticketTypeColor ? (
							<div
								style={{
									width: 14,
									height: 14,
									background: `#${convertToHexString(ticketTypeColor)}`,
									marginRight: 8
								}}
							/>
						) : null}
						{name.length > 20 ? (
							<Tooltip title={name}>{truncateString(name, 20)}</Tooltip>
						) : (
							<P marginBottom={0}>{name}</P>
						)}
					</div>
				);
			}
		},
		{
			title: "Capacity",
			dataIndex: "quantity",
			key: "quantity",
			width: 130,
			render: (_, ticket: Pick<TicketType, "index" | "isMappedToSeatingMap">) => {
				return (
					<div style={{ alignItems: "center" }}>
						<Field
							name={`ticketTypes[${ticket.index}].quantity`}
							dataCy={`ticketTypes[${ticket.index}].quantity`}
							component={InputNumberField}
							validate={quantityRequired}
							placeholder="Quantity"
							disabled={ticket.isMappedToSeatingMap}
							min={0}
							allowsNullValue={true}
						/>
					</div>
				);
			}
		},
		{
			title: "Seats mapped",
			dataIndex: "mappedSeats",
			key: "mappedSeats",
			width: 130,
			render: (_, { mappedSeats, quantity }: Pick<TicketType, "mappedSeats" | "quantity">) => {
				let warningColor;
				if (mappedSeats > quantity) {
					warningColor = "#C0212C";
				} else if (mappedSeats < quantity) {
					warningColor = "#EAA200";
				}

				const warningDot = (
					<span
						style={{
							backgroundColor: warningColor,
							height: 8,
							width: 8,
							borderRadius: "50%",
							marginLeft: 4,
							marginTop: 6
						}}
					/>
				);

				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: 100,
							paddingLeft: 10
						}}
					>
						<div style={{ display: "flex" }}>
							<P style={{ color: warningColor }} marginBottom={0}>
								{mappedSeats}
							</P>
							{warningColor && warningDot}
						</div>
					</div>
				);
			}
		},
		{
			title: "Auto match",
			dataIndex: "isMappedToSeatingMap",
			key: "isMappedToSeatingMap",
			width: 130,
			render: (_, { index, _id }: Pick<TicketType, "index" | "_id">) => {
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							paddingLeft: 10
						}}
					>
						<Field
							name={`ticketTypes[${index}].isMappedToSeatingMap`}
							dataCy={`ticketTypes[${index}].isMappedToSeatingMap`}
							component={CheckboxField}
							onChange={() => handleAutoMatchingCapacities(String(_id), "TOGGLE_MATCH_AUTOMATICALLY_FOR_TICKET_TYPES")}
						/>
					</div>
				);
			}
		}
	];

	return (
		<>
			{isAllMappedTicketsOnAutoMatch ? null : (
				<Alert
					showIcon
					message={
						<>
							Please note, If the number of seats you've mapped doesn't align with your capacity settings, we can adjust
							it for you. Selecting '<strong>Auto match</strong>' will set the new capacity based on the number of
							'Seats mapped'.
						</>
					}
					type="warning"
				/>
			)}
			<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 24, marginBottom: 12 }}>
				<Field
					name="select_all_ticket_types"
					dataCy="select_all_ticket_types"
					component={CheckboxField}
					onChange={() => handleAutoMatchingCapacities("all", "TOGGLE_MATCH_AUTOMATICALLY_FOR_ALL_TICKET_TYPES")}
				/>
				<P style={{ marginLeft: 8 }} marginBottom={0}>
					Auto match all
				</P>
			</div>
			<Table
				columns={columns}
				dataSource={ticketTypes
					.map((ticket: TicketType, index) => ({ ...ticket, index }))
					//note: filter should be after injecting index, as updates to the form rely on indexes.
					.filter((tt) => tt.mappedSeats > 0)}
				rowKey="index"
				locale={{ emptyText: "No results" }}
				scroll={{ x: "max-content" }}
				pagination={false}
				loading={isLoadingOnSave}
			/>
		</>
	);
};

export default ManageTicketCapacityTable;
