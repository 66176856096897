import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";

import { ContentBlock } from "@/components/AppBlocks";
import FeesInput from "@/components/FeesInput";
import FormBar from "@/components/FormBar";
import { H1 } from "@/components/text/Text";

import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";

import withSaveCheck from "@/hoc/withSaveCheck";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { adminSaveEvent } from "@/state/actions/eventActions";
import getTicketPriceBounding from "@/utils/RebateTicketPriceBounds";
import { scrollToErrors } from "@/utils/ScrollToErrors";

class EventFees extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.deleteFees = this.deleteFees.bind(this);
	}
	save(values) {
		const { params, adminSaveEvent } = this.props;
		const eventId = params?.eventId;
		adminSaveEvent(eventId, { fees: values.fees });
	}

	deleteFees() {
		const { params, adminSaveEvent } = this.props;
		const eventId = params?.eventId;
		adminSaveEvent(eventId, { fees: null });
	}

	render() {
		const { currentEvent, handleSubmit, fees, change } = this.props;
		return (
			<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
				<form onSubmit={handleSubmit(this.save)}>
					<H1>Fees</H1>

					<ContentBlock>
						<FeesInput fees={fees} currentEvent={currentEvent} change={change} />
					</ContentBlock>

					<FormBar>
						<ButtonsBar footer>
							<LegacyButton onClick={this.deleteFees} type="warning" ariaLabel="Delete Fees" size="large">
								Delete fees
							</LegacyButton>
							<LegacyButton type="primary" htmlType="submit" id="submitButton" ariaLabel="Save" size="large">
								Save
							</LegacyButton>
						</ButtonsBar>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "fees";

// Decorate with redux-form
EventFees = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(EventFees);

const selector = formValueSelector(formName);
EventFees = connect((state) => {
	return {
		initialValues: state.currentEvent.event,
		fees: selector(state, "fees"),
		ticketPriceBounds: getTicketPriceBounding(selector(state, "fees"))
	};
})(EventFees);

export default withRouterHooks(
	connect(
		(state) => ({
			currentEvent: state.currentEvent
		}),
		{ adminSaveEvent }
	)(withSaveCheck(EventFees, formName))
);
