import { css } from "@emotion/css";
import { Alert, Collapse, Modal, Select, Tag } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import icArrow from "@/assets/ic_arrow_down.svg";
import peopleIcon from "@/assets/icon_descriptive_people.svg";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2, H4, H5, P } from "@/components/text/Text";
import { closeTicketSaleStatusModal, getTicketsSaleStatus } from "@/state/actions/ticketSaleStatusActions";
import { getPublishMessage } from "@/utils/Events";

const { Panel } = Collapse;

const WaitlistMode = {
	Automatic: "automatic",
	Manual: "manual"
};

const totalOnWaitlistStyle = css({
	display: "flex",
	alignItems: "center",
	marginTop: 10,
	h5: {
		marginLeft: 8,
		marginBottom: 0
	},
	p: { marginLeft: 4, marginBottom: 0 }
});

const eventStatusMessageStyle = css({
	display: "flex",
	color: "#5a626c",
	flexDirection: "row",
	justifyContent: "flex-end",
	span: {
		height: 8,
		width: 8,
		borderRadius: "50%",
		marginRight: 4,
		marginTop: 5,
		backgroundColor: "#5a626c"
	}
});

const TicketsStatusModal = ({
	ticketSaleStatusState,
	getTicketsSaleStatus,
	currentEvent,
	closeTicketSaleStatusModal
}) => {
	const { modalOpen, loading, error, ticketSaleStatus } = ticketSaleStatusState;
	const { _id: eventId, dates: eventDates, seatingMaps = [] } = currentEvent.event;
	const activeDates = eventDates.filter((date) => !date.disabled && !date.deleted);
	const [activeEventDateId, updateDate] = useState(activeDates.length ? activeDates[0]._id : null);
	useEffect(() => {
		if (modalOpen && activeEventDateId) {
			getTicketsSaleStatus(eventId, activeEventDateId);
		}
	}, [modalOpen, activeEventDateId]);

	const CloseButton = (
		<LegacyButton
			onClick={closeTicketSaleStatusModal}
			key="submit"
			htmlType="submit"
			ariaLabel="Close"
			style={{
				float: "right",
				"@media(max-width:768px)": {
					display: "none"
				}
			}}
		>
			Close
		</LegacyButton>
	);
	const ModalBodyStyle = { padding: "16px 20px 12px", maxHeight: 600, overflow: "auto" };

	if (!activeDates.length) {
		return (
			<>
				<Modal
					open={modalOpen}
					destroyOnClose
					onCancel={closeTicketSaleStatusModal}
					title={<H2 style={{ marginBottom: 0 }}>Tickets status</H2>}
					footer={CloseButton}
					bodyStyle={ModalBodyStyle}
				>
					<p>You have no active event dates.</p>
				</Modal>
			</>
		);
	}

	let eventStatusMessage = getPublishMessage(currentEvent.event);

	const DateSelector = (
		<Select
			defaultValue={activeEventDateId}
			onChange={(e) => updateDate(e)}
			style={{ width: 320 }}
			options={activeDates.map((date) => {
				return { value: date._id, label: date.display.combined };
			})}
		/>
	);
	const soldOutIds = ticketSaleStatus.filter((ticketType) => ticketType.soldOut).map((ticketType) => ticketType._id);

	return (
		<>
			<Modal
				open={modalOpen}
				destroyOnClose
				onCancel={closeTicketSaleStatusModal}
				title={<H2 style={{ marginBottom: 0 }}>Tickets status</H2>}
				footer={CloseButton}
				bodyStyle={ModalBodyStyle}
			>
				<LoadErrorView
					loading={loading}
					error={error}
					retryAction={() => getTicketsSaleStatus(eventId, activeEventDateId)}
					renderChildren={false}
				>
					{eventStatusMessage ? (
						<div className={eventStatusMessageStyle}>
							<span />
							<P>{eventStatusMessage}</P>
						</div>
					) : null}
					<Alert
						showIcon
						message={
							<>
								Before making any offers, please check your ticket capacities below to make sure they are &nbsp;
								<Tag color="#217bc0">Ready to offer</Tag>
							</>
						}
						type="warning"
					/>
					<br />
					{seatingMaps.length > 0 && (
						<>
							<Alert
								showIcon
								message={
									"This event has a seating map. If increasing ticket capacity don't forget to increase seating map capacity."
								}
								type="info"
							/>
							<br />
						</>
					)}
					{activeDates.length > 1 && (
						<div style={{ width: "100%", marginBottom: 16 }}>
							<H4>Select event date</H4>
							{DateSelector}
						</div>
					)}
					<Collapse
						bordered={false}
						expandIconPosition="right"
						defaultActiveKey={soldOutIds}
						expandIcon={({ isActive }) => (
							<div
								className={css({
									img: {
										transform: `rotate(${isActive ? "180" : "0"}deg)`
									}
								})}
							>
								<img src={icArrow} alt="" />
							</div>
						)}
						style={{ background: "#ffffff" }}
					>
						{ticketSaleStatus.map(
							({
								_id,
								name,
								hidden,
								showWaitlist,
								totalPeopleOnWaitlist,
								waitlistMode = WaitlistMode.Manual,
								soldOut,
								markedAsSoldOut,
								dateError,
								availabilityOption,
								soldOutDueTo = [],
								readyToOffer,
								isPackage
							}) => {
								const isOnSale = !soldOut && !markedAsSoldOut && !dateError;
								const header = (
									<div>
										<H5>{name}</H5>
										{hidden && (
											<Tag color="#e2dfe5" style={{ marginTop: 5, color: "#323232" }}>
												Hidden
											</Tag>
										)}
										{isOnSale && (
											<Tag color="#157e77" style={{ marginTop: 5 }}>
												On sale
											</Tag>
										)}
										{dateError && <P style={{ color: "#5A626C", marginTop: 5 }}>{dateError}</P>}
										{soldOut && !markedAsSoldOut && (
											<Tag color="#c0212c" style={{ marginTop: 5 }}>
												Sold out
											</Tag>
										)}
										{markedAsSoldOut && (
											<Tag color="#c0212c" style={{ marginTop: 5 }}>
												Marked as sold out
											</Tag>
										)}

										{showWaitlist && (
											<Tag color="#ffffff" style={{ color: "#217bc0", border: "solid 1px #217bc0", marginTop: 5 }}>
												Waitlist on
											</Tag>
										)}
										{waitlistMode === WaitlistMode.Manual && readyToOffer && (
											<Tag style={{ marginTop: 5 }} color="#217bc0">
												Ready to offer
											</Tag>
										)}

										{waitlistMode === WaitlistMode.Automatic && readyToOffer && (
											<Tag style={{ marginTop: 5 }} color="#217bc0">
												Auto-offering
											</Tag>
										)}
									</div>
								);

								return (
									<Panel header={header} key={_id} style={{ marginBottom: 16, background: "#f9f9fa" }}>
										{totalPeopleOnWaitlist > 0 ? (
											<div className={totalOnWaitlistStyle}>
												<img src={peopleIcon} alt="People on waitlist icon" />
												<H5>People on the waitlist:</H5>
												<P>{totalPeopleOnWaitlist}</P>
											</div>
										) : null}
										{availabilityOption ? (
											<div style={{ marginTop: 10 }}>
												{isPackage ? "Packages" : "Tickets"} sold: {availabilityOption.sold} /
												{availabilityOption.quantity}
											</div>
										) : null}
										{soldOutDueTo.length ? (
											<div style={{ marginTop: 12 }}>
												<H5>Sold out due to:</H5>
												<ul>
													{soldOutDueTo.map((item, index) =>
														item.isSoldOutDueToSeatingMap ? (
															<li>Capacity is determined by the seating map</li>
														) : (
															<li key={index}>
																{item.name}: {item.sold}/{item.quantity}
															</li>
														)
													)}
												</ul>
											</div>
										) : null}
									</Panel>
								);
							}
						)}
					</Collapse>
				</LoadErrorView>
			</Modal>
		</>
	);
};

export default connect(
	(state) => ({
		ticketSaleStatusState: state.ticketSaleStatus,
		currentEvent: state.currentEvent
	}),
	{ closeTicketSaleStatusModal, getTicketsSaleStatus }
)(TicketsStatusModal);
