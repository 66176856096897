import { ContentBlock } from "@/components/AppBlocks";
import Breadcrumb from "@/components/Breadcrumb";
import FormBar from "@/components/FormBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import withSaveCheck from "@/hoc/withSaveCheck";
import { openDeleteModal, publishOrUnpublish, saveTour, updateTour } from "@/state/actions/tourActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Tour } from "@/state/reducers/tourReducer";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { Tabs, Tooltip } from "@/ui/antd";
import { FC } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useNavigate } from "react-router";
import { InjectedFormProps, formValueSelector, getFormValues, reduxForm } from "redux-form";
import TourDeleteModal from "./TourDeleteModal";
import TourGroups from "./TourGroups";
import { TourHeader } from "./TourHeader";
import TourPage from "./TourPage";
import TourSettings from "./TourSettings";
import TourStyling from "./styling/TourStyling";

const formName = "tour";

const defaultTourTheme = {
	type: "default",
	primaryColor: "#353337",
	linkColor: "#353337",
	footerColor: "#637184",
	primaryTextColor: "#fff",
	backgroundTextColor: "#323232",
	footerTextColor: "#fff",
	ticketButtonDisplay: "filled",
	background: { type: "color", color: "#fff" }
};

const selector = formValueSelector(formName);

const tabBarTitle = (title: string, isNewTour: boolean) => {
	if (isNewTour) {
		return (
			<Tooltip placement="top" title={`Please Save Tour page to enable ${title}.`}>
				{title}
			</Tooltip>
		);
	}
	return title;
};

type TourFormComponentProps = PropsFromRedux &
	InjectedFormProps & {
		tourId: string;
	};

const TourFormComponent: FC<TourFormComponentProps> = ({ handleSubmit, tourId }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const tourState = useAppSelector((state) => state.tours.tour);
	const userEvents = useAppSelector((state) => state.userEvents?.events ?? []);

	const formValues = useAppSelector((state) => getFormValues(formName)(state));
	const tourName = useAppSelector((state) => selector(state, "name"));
	const slug = useAppSelector((state) => selector(state, "slug"));
	const events = useAppSelector((state) => selector(state, "events") ?? (formValues as any)?.events);
	const groups = useAppSelector((state) => selector(state, "groups"));
	const pageLayout = useAppSelector((state) => selector(state, "settings.pageLayout"));
	const showName = useAppSelector((state) => selector(state, "settings.showName"));
	const themeType = useAppSelector((state) => selector(state, "theme.type"));
	const previewTheme = useAppSelector((state) => selector(state, "theme"));

	const { publishLoading, data: tour } = tourState ?? {};

	const selectedTheme = tour?.theme ?? false;
	const published = !!tour?.published;

	const isNewTour = tourId === "new";

	const _saveForm = (values: Partial<Tour>) => {
		if (!values.slug || values.slug === "") {
			const slug = values.name.replace(/ /g, "-");
			values.slug = slug;
		}
		if (values._id) {
			dispatch(updateTour(values._id, values));
		} else {
			values.theme = defaultTourTheme;
			dispatch(saveTour(values, navigate));
		}
	};

	const _deleteTour = () => {
		dispatch(openDeleteModal());
	};

	const _publishOrUnpublish = (published: boolean, tourId: string) => {
		dispatch(publishOrUnpublish(tourId, published));
	};

	return (
		<form onSubmit={handleSubmit(_saveForm)}>
			{!isNewTour ? (
				<div>
					<Breadcrumb prevPath="/console/promote/tours" prevTitle="Event Tour Page" currTitle={tourName} />
					<TourHeader
						tourSlug={slug}
						tourName={tourName}
						published={published}
						publishOrUnpublish={() => _publishOrUnpublish(published, tourId)}
						publishLoading={!!publishLoading}
					/>
				</div>
			) : null}

			<ContentBlock style={{ position: "relative" }}>
				<TourDeleteModal history={history} />
				<Tabs
					defaultActiveKey="tourPage"
					items={[
						{
							key: "tourPage",
							label: "Tour page",
							children: (
								<TourPage
									dispatch={dispatch}
									formName={formName}
									userEvents={userEvents}
									tourName={tourName}
									slug={slug}
									events={events}
									tourId={tourId}
								/>
							)
						},
						{
							key: "groups",
							label: tabBarTitle("Tour groups", isNewTour),
							disabled: isNewTour,
							children: (
								<TourGroups
									formName={formName}
									dispatch={dispatch}
									userEvents={userEvents}
									events={events}
									groups={groups}
								/>
							)
						},
						{
							key: "settings",
							label: tabBarTitle("Settings", isNewTour),
							disabled: isNewTour,
							children: (
								<TourSettings formName={formName} dispatch={dispatch} showName={showName} pageLayout={pageLayout} />
							)
						},
						{
							key: "tourStyling",
							label: tabBarTitle("Styling", isNewTour),
							disabled: isNewTour,
							children: (
								<TourStyling
									formName={formName}
									dispatch={dispatch}
									themeType={themeType}
									selectedTheme={selectedTheme}
									previewTheme={previewTheme}
								/>
							)
						}
					]}
				/>
			</ContentBlock>
			<FormBar>
				{!isNewTour ? (
					<LegacyButton
						ariaLabel="Close"
						htmlType="button"
						size="large"
						onClick={_deleteTour}
						type="warning"
						style={{ display: "inline-block" }}
					>
						Delete
					</LegacyButton>
				) : null}
				<LegacyButton
					style={{
						float: "right"
					}}
					type="primary"
					htmlType="submit"
					id="submitButton"
					ariaLabel="Save"
					size="large"
				>
					Save
				</LegacyButton>
			</FormBar>
		</form>
	);
};

const mapStateToProps = (
	state: GlobalReduxState,
	ownProps: {
		tourId: string;
	}
) => {
	const { tourId } = ownProps;
	const isNewTour = tourId === "new";

	const initialValues = isNewTour ? {} : state.tours.tour.data;

	if (!initialValues.settings || !initialValues.settings.eventListingLayout) {
		initialValues.settings = {
			pageLayout: "tiles",
			eventListingLayout: "eventTitle",
			listViewType: "image"
		};
	}
	if (!initialValues.theme || !initialValues.theme.type) {
		initialValues.theme = {
			type: "default"
		};
	}
	return {
		initialValues
	};
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const FormWithRedux = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(TourFormComponent);

const TourForm = connector(withSaveCheck(FormWithRedux, formName));

export { TourForm };
