import { getConfig } from "@/config";
import TokenService from "@/services/TokenService";
import UsersService from "@/services/UsersService";
import { useAppDispatch } from "@/state/hooks";
import { trpc } from "@/trpc";
import ax from "axios";
import { User as FirebaseUser, getAuth, signInWithCustomToken } from "firebase/auth";

export type SignInWithUtsSSOParams = {
	code: string;
	redirect_uri: string;
	nonce: string;
	state: string | null;
};

export type SignInWithUtsSSOResponse = {
	isNewUser: boolean;
	user: FirebaseUser;
};

export const useSingleSignOn = () => {
	const { refetch: fetchUtsSsoConfig } = trpc.authorization.utsSsoConfig.useQuery(undefined, { enabled: false });
	const dispatch = useAppDispatch();

	const launchMicrosoftSignIn = async (email: string): Promise<void> => {
		const { data: utsSsoConfigData } = await fetchUtsSsoConfig();

		if (!utsSsoConfigData) {
			throw new Error("Failed to get single sign-on configuration");
		}

		const { tenant, client_id } = utsSsoConfigData ?? {};

		const consoleUrl = getConfig("CONSOLE_URL");
		const redirectUrl = encodeURIComponent(`${consoleUrl}/sso/microsoft`);
		const params = new URLSearchParams({
			client_id,
			response_type: "code",
			redirect_uri: redirectUrl,
			response_mode: "query",
			state: email,
			login_hint: email
		});
		const loginUrl = new URL(`https://login.microsoftonline.com/${tenant}/oauth2/authorize`);
		loginUrl.search = params.toString();
		window.location.href = loginUrl.toString();
	};

	const signInWithUtsSSO = async (params: SignInWithUtsSSOParams): Promise<SignInWithUtsSSOResponse> => {
		const response = await ax.post("/public-api/microsoft/authorise", params);
		const userData = response.data;
		const auth = getAuth();
		const firebaseResponse = await signInWithCustomToken(auth, userData.token);
		TokenService.persistToken(userData.token, dispatch);

		const isNew = userData.matchedUserIds.indexOf(firebaseResponse.user.uid) === -1;

		if (isNew) {
			const config = { ...userData.config };
			delete userData.config;
			delete userData.matchedUserIds;
			const userDetails = {
				...userData,
				...config.userSetting,
				id: firebaseResponse.user.uid,
				role: "user",
				location: "AU"
			};

			delete userDetails.token;

			// create the user
			await UsersService.create(userDetails.uid, userDetails);
		}

		return { isNewUser: userData.isNew, user: firebaseResponse.user };
	};

	return {
		launchMicrosoftSignIn,
		signInWithUtsSSO
	};
};
