import { dayjs } from "@/libs/dayjs";
import { HumaniDate } from "@hx/dates";
import ax from "axios";
import axios from "./AxiosWrapper";
import LocationService from "./LocationService";

class EventsService {
	static instance = null;
	static createInstance() {
		let object = new EventsService();
		return object;
	}

	static getInstance() {
		if (!EventsService.instance) {
			EventsService.instance = EventsService.createInstance();
		}
		return EventsService.instance;
	}

	covertToSave(event) {
		const eventData = { ...event };
		if (eventData.dates) {
			eventData.dates = event.dates.map(function (range) {
				return {
					...range,
					startDate: dayjs(range.startDate).toISOString(),
					endDate: dayjs(range.endDate).toISOString()
				};
			});
		}
		if (eventData.ticketTypes) {
			eventData.ticketTypes = event.ticketTypes.map(function (ticket) {
				ticket.startSaleDate = ticket.startSaleDate ? dayjs(ticket.startSaleDate).toISOString() : null;
				ticket.endSaleDate = ticket.endSaleDate ? dayjs(ticket.endSaleDate).toISOString() : null;
				return ticket;
			});
		}
		if (eventData.discountCodes) {
			eventData.discountCodes = event.discountCodes.map(function (code) {
				code.startDate = code.startDate ? dayjs(code.startDate).toISOString() : null;
				code.endDate = code.endDate ? dayjs(code.endDate).toISOString() : null;
				return code;
			});
		}
		return eventData;
	}

	covertFromServer(event) {
		const eventData = { ...event };
		if (eventData.ticketTypes) {
			eventData.ticketTypes = event.ticketTypes.map(function (ticket) {
				ticket.startSaleDate = ticket.startSaleDate ? dayjs(ticket.startSaleDate) : null;
				ticket.endSaleDate = ticket.endSaleDate ? dayjs(ticket.endSaleDate) : null;
				return ticket;
			});
		}
		return eventData;
	}

	async scrape(url) {
		const response = await axios.get(`events/scrape`, { params: { url } });
		return response.data;
	}

	async create(event) {
		const duplicatedEvent = JSON.parse(JSON.stringify(event));
		const eventForSave = this.covertToSave(duplicatedEvent);
		const { location } = eventForSave;
		const response = await axios.put(`events/`, eventForSave, {
			headers: {
				"x-event-level-location": location
			}
		});
		return response.data;
	}

	async delete(eventId) {
		const response = await axios.delete(`events/delete/${eventId}`);
		return response.data;
	}

	async update(id, event) {
		event = this.covertToSave(event);
		const response = await axios.post(`events/${id}`, event);
		const savedEvent = this.covertFromServer(response.data);
		return savedEvent;
	}

	async updatePaymentOptions(id, paymentOptions) {
		const response = await axios.post(`events/${id}/payment-options`, paymentOptions);
		const savedEvent = this.covertFromServer(response.data);
		return savedEvent;
	}

	async updateSlug(id, slug) {
		const response = await axios.post(`events/update-slug/${id}`, { slug });
		return response.data;
	}

	async duplicate(id, newEventName) {
		const response = await axios.post(`events/${id}/duplicate`, {
			newEventName
		});
		return response.data;
	}

	async updateAdmin(id, event) {
		event = this.covertToSave(event);
		const response = await axios.post(`admin/events/${id}`, event);
		const savedEvent = this.covertFromServer(response.data);
		return savedEvent;
	}

	async publishCheck(id) {
		const response = await axios.get(`events/${id}/publish-check/`);
		return response.data;
	}

	async publish(id, scheduledPublishDate, notifyFollowers, recaptchaVerificationCode) {
		const response = await axios.post(`events/${id}/publish`, {
			scheduledPublishDate,
			notifyFollowers,
			recaptchaVerificationCode
		});
		const savedEvent = this.covertFromServer(response.data);
		return savedEvent;
	}

	async unpublish(id) {
		const response = await axios.post(`events/${id}/unpublish`);
		const savedEvent = this.covertFromServer(response.data);
		return savedEvent;
	}

	async notifyFollowers(id) {
		const response = await axios.post(`events/${id}/notify-followers`);
		return response.data;
	}

	async get(id, orderId, token) {
		const params = { params: { orderId } };
		const response = token
			? await ax.get(`/public-api/edit-order/event/${orderId}/${id}/${token}`)
			: await axios.get(`events/${id}`, params);
		const event = this.covertFromServer(response.data);
		return event;
	}

	async getFeeStructure(id) {
		const response = await axios.get(`events/${id}/fee-structure`);
		return response.data;
	}

	async getUserEvents(userId, fields, userSpecific = false, location) {
		let options = {};
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}
		const params = { params: {}, ...options };
		if (fields) {
			params.params = { fields };
		}
		params.params.userSpecific = userSpecific;
		const response = await axios.get(`events/user/${userId}`, params);
		const events = response.data;
		if (fields) {
			return events;
		}
		return events.map((e) => this.covertFromServer(e));
	}

	async getUserTicketTypes(userId, eventIds, location) {
		let options = {};
		if (location) {
			options = {
				headers: {
					"x-override-location": location
				}
			};
		}
		const params = { params: { userSpecific: true, eventIds }, ...options };
		const response = await axios.get(`events/ticket-types/${userId}`, params, options);
		const events = response.data;
		return events;
	}

	async getEventTotal(id) {
		const response = await axios.get(`events/${id}/total`);
		return response.data;
	}

	async find(queryBy, page, location) {
		let params = {
			...queryBy,
			page
		};

		if (queryBy.userId) {
			params.requestedUserId = queryBy.userId;
		}
		params.date = new HumaniDate(undefined, "guess", LocationService.getLocation(true)).formatting.dateTime();
		const response = await axios.get(`events/search`, {
			params,
			headers: { "x-override-location": location }
		});
		const results = response.data;
		results.events =
			results.events && results.events.length > 0
				? results.events.map((e) => this.covertFromServer(e))
				: results.events;
		return results;
	}

	async getEventsById(eventIds, location) {
		const response = await axios.get(`events/get-events-by-id`, {
			params: { eventIds },
			headers: { "x-override-location": location }
		});
		const events = response.data.map((e) => this.covertFromServer(e));
		return events;
	}

	async getRecentlyUpdated(number, payoutStatus, location) {
		let params = {
			number
		};
		params.date = new HumaniDate(undefined, "guess", LocationService.getLocation(true)).formatting.dateTime();
		const response = await axios.get(`events/recently-updated`, {
			params,
			headers: { "x-override-location": location }
		});
		let results = response.data;
		results.events =
			results.events && results.events.length > 0
				? results.events.map((e) => this.covertFromServer(e))
				: results.events;
		return results;
	}

	async getEventReport(queryBy, userId, page) {
		const response = await axios.get(`events/reports/${userId}`, {
			params: { queryBy, page }
		});
		return response.data;
	}

	async connectStripe(type, id, authCode, location) {
		const response = await axios.post(`stripe/connect/${type}/${id}`, {
			authCode,
			location
		});
		return response.data;
	}

	async cacheAdditionalQuestions(eventId, previewQuestions) {
		const response = await axios.post(`events/additionalQuestionsPreview/${eventId}`, {
			previewQuestions
		});
		return response.data;
	}

	async deleteAdditionalQuestion(eventId, additionalQuestionId) {
		const response = await axios.delete(`events/delete/${eventId}/${additionalQuestionId}`);
		return response.data;
	}

	async cacheCompleteMessage(eventId, completeMessage) {
		const response = await axios.post(`events/completeMessagePreview/${eventId}`, {
			completeMessage
		});
		return response.data;
	}

	getTicketsLookup(event) {
		const tickets = event.ticketTypes.concat(event.packagedTickets);
		const ticketNameLookUp = tickets.reduce((lookup, ticket) => {
			lookup[ticket._id] = ticket;
			return lookup;
		}, {});
		return ticketNameLookUp;
	}

	getDatesLookup(event) {
		return event.dates.reduce((lookup, date) => {
			lookup[date._id] = date;
			return lookup;
		}, {});
	}

	async pricePreview(eventId) {
		const response = await axios.get(`events/price-preview/${eventId}`);
		return response.data;
	}

	async transferEvent(eventId, eventLocation, toUserId, toUserLocation) {
		const response = await axios.post(`admin/events/${eventId}/transfer-event`, {
			eventLocation,
			toUserId,
			toUserLocation
		});
		return response.data;
	}

	async applyToAllEvents(applyToAllEventsSettings, userId) {
		applyToAllEventsSettings.locationToApplyTo = applyToAllEventsSettings.isLocationNeeded
			? LocationService.getLocation(false)
			: "all";
		const response = await axios.post(`/events/apply-to`, {
			applyToAllEventsSettings,
			userId
		});
		return response.data;
	}

	async updateEventBasics(eventId, eventData) {
		const response = await axios.post(`/events/update-event-basics/${eventId}`, {
			eventData
		});
		return response.data;
	}

	async updateEventDetails(eventId, eventData) {
		const response = await axios.post(`/events/update-event-details/${eventId}`, {
			eventData
		});
		return response.data;
	}

	async updateEventTicketTypes(eventId, eventData) {
		const response = await axios.post(`/events/update-event-ticket-types/${eventId}`, {
			eventData
		});
		return response.data;
	}

	async updateEventSettings(eventId, eventData) {
		const response = await axios.post(`/events/update-event-settings/${eventId}`, {
			eventData
		});
		return response.data;
	}

	async updateEventLinkedInSettings(eventId, eventData) {
		const response = await axios.post(`/events/update-event-linked-in-settings/${eventId}`, {
			eventData
		});
		return response.data;
	}

	async completeEventSchedule(eventId) {
		const response = await axios.post(`/events/complete-event-schedule/${eventId}`);
		return response.data;
	}

	async hubspotSync(eventId) {
		const response = await axios.post(`/events/hubspotSync/${eventId}`);
		return response.data;
	}

	async archiveEvent(eventId) {
		const response = await axios.post(`/events/archive/${eventId}`);
		return response.data;
	}

	async unarchiveEvent(eventId) {
		const response = await axios.post(`/events/unarchive/${eventId}`);
		return response.data;
	}

	async addSelfServicePayoutThreshold(id, threshold) {
		const response = await axios.post(`/events/${id}/add-self-service-payout-threshold`, {
			threshold
		});
		return response.data;
	}

	async updateResaleSetting(id, newSettingData) {
		const response = await axios.post(`/events/${id}/update-resale-setting`, {
			newSettingData
		});
		return response.data;
	}
}

export default EventsService.getInstance();
