export default function getWindow(): any {
	if (typeof window !== "undefined") {
		return window;
	}
	return {
		addEventListener: function (...args: any[]) {
			return args;
		}
	};
}
