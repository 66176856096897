import { Radio } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { ManagerTab, setManagerTab } from "@/state/actions/seatingMapActions";
import TicketSearch from "./components/TicketSearch";
import UnassignedAttendees from "./components/UnassignedAttendees";
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Default extends Component {
	setView = ({ target: { value } }) => {
		this.props.setManagerTab(value);
	};

	determineView = () => {
		const {
			seatingMap: { organiser }
		} = this.props;
		const { tab } = organiser;
		if (tab === ManagerTab.ASSIGNED) {
			return <TicketSearch />;
		}
		if (tab === ManagerTab.UNASSIGNED) {
			return <UnassignedAttendees />;
		}
	};

	render() {
		const {
			seatingMap: { organiser }
		} = this.props;
		const { tab } = organiser;
		return (
			<div
				style={{
					backgroundColor: "white",
					display: "flex",
					flexDirection: "column",
					height: "100%"
				}}
			>
				<div style={{ padding: "16px 12px" }}>
					<RadioGroup onChange={this.setView} style={{ width: "100%" }} value={tab} size="large" buttonStyle="solid">
						<RadioButton style={{ width: "50%", textAlign: "center" }} value="assigned">
							Assigned
						</RadioButton>
						<RadioButton style={{ width: "50%", textAlign: "center" }} value="unassigned">
							Unassigned
						</RadioButton>
					</RadioGroup>
				</div>
				{this.determineView()}
			</div>
		);
	}
}

export default connect(
	(state) => ({
		seatingMap: state.seatingMap,
		currentEvent: state.currentEvent
	}),
	{
		setManagerTab
	}
)(Default);
