export const removeHubspot = () => {
	if (window.HubSpotConversations) {
		window.HubSpotConversations.widget.remove();
	}
};

export const addHubspot = () => {
	if (window.HubSpotConversations) {
		window.HubSpotConversations.widget.load();
	}
};

export const onConversationsAPIReady = (user) => {
	if (window.HubSpotConversations && user) {
		let _hsq = (window._hsq = window._hsq || []);
		_hsq.push([
			"identify",
			{
				email: user.email,
				id: user.hubspotContactId
			}
		]);
		_hsq.push(["trackPageView"]);
		setTimeout(() => {
			window.HubSpotConversations.widget.refresh();
		}, 1000);
	} else if (user) {
		setTimeout(() => {
			onConversationsAPIReady(user);
		}, 1000);
	}
};
