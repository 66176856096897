import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { getEventPayoutPage } from "@/state/actions/newPayoutActions";
import { EventPayout } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { HelpIds } from "@/utils/Help";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import CreditsOrAdjustments from "./eventPayout/sections/CreditsOrAdjustments";
import DateBreakdown from "./eventPayout/sections/DateBreakdown";
import Header from "./eventPayout/sections/Header";
import Notes from "./eventPayout/sections/Notes";
import Payments from "./eventPayout/sections/Payments";
import ReconciliationTable from "./eventPayout/sections/ReconciliationTable";
import SelfServicePayouts from "./eventPayout/sections/SelfServicePayouts";

const EventPayoutPage = ({ getEventPayoutPage, eventPayout }: IPayoutPageProps & IPayoutPageFunctions) => {
	const params = useParams<{ eventId: string }>();
	const errorMessage = eventPayout?.payoutInformation?.errorMsg;
	if (errorMessage) {
		eventPayout.error = new Error(errorMessage.replace(/{.*}\s/g, " - ").replace(/,\s{.*}/g, ""));
		eventPayout.error.title = "Add Payment Error";
	}

	useEffect(() => {
		load();
	}, []);

	const load = () => {
		const eventId = params?.eventId ?? "";
		getEventPayoutPage(eventId);
	};

	return (
		<>
			<PageHeading
				title="Payouts"
				helpButton={{
					link: HelpIds.payouts,
					title: "Help - Payouts"
				}}
			/>
			<LoadErrorView
				loading={eventPayout.loading}
				error={eventPayout.error}
				retryAction={() => load()}
				renderChildren={false}
			>
				<ContentBlock>
					<Header />
					<ReconciliationTable />
					<SelfServicePayouts />
				</ContentBlock>
				<Notes />
				<DateBreakdown />
				<CreditsOrAdjustments />
				<Payments />
			</LoadErrorView>
		</>
	);
};

interface IPayoutPageProps {
	eventPayout: EventPayout;
}

interface IPayoutPageFunctions {
	getEventPayoutPage: (eventId: string) => void;
}

export default connect(
	(state: ReduxState): IPayoutPageProps => ({
		eventPayout: state.payouts.eventPayout
	}),
	{ getEventPayoutPage }
)(EventPayoutPage);
