import * as React from "react";
import Store from "../utils/Store";
import { BasicTab } from "./BasicTab";
import { TabProps } from "./index";

export class ToggleTab extends React.Component<TabProps, { selected: boolean }> {
	constructor(props: TabProps) {
		super(props);
		this.state = {
			selected: false
		};
	}

	toggle = () => {
		if (!this.state.selected) {
			const { methodName } = this.props;
			Store.callInteractionManagerMethod(methodName);
		} else {
			const { endMethodName } = this.props;
			if (endMethodName) {
				Store.callInteractionManagerMethod(endMethodName);
			}
		}
		this.setState({
			selected: !this.state.selected
		});
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	render() {
		return <BasicTab {...this.props} onClick={this.toggle} selected={this.state.selected} />;
	}
}
