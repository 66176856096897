import { Dispatch, SetStateAction, useEffect, useState } from "react";

export enum StorageKeys {
	COLLECTIONS_LOCATION = "humanitix.collections_location"
}

export const useLocalStorage = <T>(
	key: StorageKeys | string,
	defaultValue: T
): [T, Dispatch<SetStateAction<T>>, () => void] => {
	const [value, setValue] = useState<T>(() => {
		let currentValue;

		try {
			currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
		} catch (error) {
			currentValue = defaultValue;
		}

		return currentValue;
	});

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [value, key]);

	const refetch = () => {
		const currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue));
		setValue(currentValue);
	};

	return [value, setValue, refetch];
};
