import { Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ReportContent, ReportFilters } from "@/components/AppBlocks";
import ExportBar from "@/components/ExportBar";
import { SelectField } from "@/components/Fields";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import {
	getTopForecastEvents,
	getTopForecastEventsCsv,
	resetTopForecastEvents
} from "@/state/actions/adminReports/eventsActions";
import { currency } from "@/utils/Format";
const formName = "topForecastedEvents";

class topForecastedEvents extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.retry = this.retry.bind(this);
		this.downloadCsv = this.downloadCsv.bind(this);
		this.changeFieldValue = this.changeFieldValue.bind(this);
	}

	load(values) {
		const { getTopForecastEvents } = this.props;
		getTopForecastEvents(values.sortOn, 1);
	}

	loadPage(page) {
		const { getTopForecastEvents, sortOn } = this.props;
		getTopForecastEvents(sortOn, page);
	}

	retry() {
		const { resetTopForecastEvents } = this.props;
		resetTopForecastEvents();
	}

	downloadCsv() {
		const { sortOn, getTopForecastEventsCsv } = this.props;
		getTopForecastEventsCsv(sortOn);
	}
	componentDidUpdate(preProps) {
		const props = ["sortOn"];
		const changed = props.reduce((changed, prop) => {
			if (this.props[prop] !== preProps[prop]) {
				return true;
			}
			return changed;
		}, false);
		if (changed) {
			this.runLoad();
		}
	}

	runLoad = () => {
		const { sortOn } = this.props;
		this.load({ sortOn });
	};

	changeFieldValue(field, value) {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	}
	render() {
		const { handleSubmit, topForecastedEvents } = this.props;
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (email, record) => <Link to={`/console/my-events/${record._id}`}>{email}</Link>
			},
			{
				title: "Date count",
				dataIndex: "datesCount",
				key: "datesCount"
			},
			{
				title: "Capacity",
				dataIndex: "capacity",
				key: "capacity"
			},
			{
				title: "Event Capacity",
				dataIndex: "eventCapacity",
				key: "eventCapacity"
			},
			{
				title: "Average price",
				dataIndex: "avgPrice",
				key: "avgPrice",
				render: (value) => currency(value)
			},
			{
				title: "Potential Sales",
				dataIndex: "potentialSales",
				key: "potentialSales",
				render: (value) => currency(value)
			}
		];
		return (
			<form onSubmit={handleSubmit(this.load)}>
				<PageHeading title="Forecasted Top Events">
					<LocationSelection componentName="adminReportEvents" defaultLevel="user" onLocationChange={this.runLoad} />
				</PageHeading>
				<div>
					<LoadErrorView
						loading={topForecastedEvents.loading}
						error={topForecastedEvents.error}
						retryAction={this.retry}
					>
						<ReportFilters>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<div style={{ display: "flex" }}>
									<Field
										label="Sort On"
										name="sortOn"
										id="sortOn"
										component={SelectField}
										options={[
											{ label: "Potential Sales", value: "potentialSales" },
											{ label: "Event Capacity", value: "eventCapacity" },
											{ label: "Capacity", value: "capacity" },
											{ label: "Average Price", value: "avgPrice" }
										]}
									/>
								</div>
								{topForecastedEvents.data ? (
									<IconButton
										icon="export"
										type="background"
										showTooltip
										tooltip="Export"
										onClick={this.downloadCsv}
										ariaLabel="Export"
									/>
								) : null}
							</div>
						</ReportFilters>
						<ReportContent>
							{topForecastedEvents.data ? (
								<div>
									<Table
										columns={columns}
										dataSource={topForecastedEvents.data}
										rowKey="_id"
										scroll={{ x: 800 }}
										pagination={{
											showSizeChanger: false,
											pageSize: 30,
											total: topForecastedEvents.count,
											current: topForecastedEvents.page,
											onChange: (page) => this.loadPage(page)
										}}
									/>
									<ExportBar buttonText="Export" buttonClick={this.downloadCsv} />
								</div>
							) : null}
						</ReportContent>
					</LoadErrorView>
				</div>
			</form>
		);
	}
}

const selector = formValueSelector(formName);
topForecastedEvents = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(topForecastedEvents);

export default connect(
	(state) => ({
		initialValues: {
			startDate: state.adminReports.dates.startDate,
			endDate: state.adminReports.dates.endDate,
			sortOn: "potentialSales"
		},
		topForecastedEvents: state.adminReports.events.topForecastedEvents,
		sortOn: selector(state, "sortOn")
	}),
	{ getTopForecastEvents, resetTopForecastEvents, getTopForecastEventsCsv }
)(topForecastedEvents);
