import { CodeBlock, LottiePlayer } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type LottiePlayerProps = {
  animationKey: "wink";
  width?: string;
  height?: string;
  speed?: string;
};`;

const sample = `import { LottiePlayer } from "@/ui";

<LottiePlayer animationKey="wink" />`;

export const LottiePlayerPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<LottiePlayer animationKey="wink" />
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
