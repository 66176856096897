import { Flex } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

export const LinkWrapper = styled.div`
	a {
		color: #000;
		text-decoration: underline;
		font-weight: 400;

		:hover {
			color: #000;
		}
	}
`;

const LinkToSignIn: FC<{ copy: "Sign in" | "Sign up" }> = ({ copy }) => {
	return (
		<LinkWrapper>
			<Link to="/signin">{copy}</Link>
		</LinkWrapper>
	);
};

export const AlreadyHaveAccount: FC = () => {
	return (
		<Flex gap="0.25rem" justify="center">
			<span>Already have an account?</span>
			<LinkToSignIn copy="Sign in" />
		</Flex>
	);
};

export const NeedANewAccount: FC = () => {
	return (
		<Flex gap="0.25rem">
			<span>Need a new account?</span>
			<LinkToSignIn copy="Sign up" />
		</Flex>
	);
};

export const BackToSignin: FC = () => {
	return (
		<Flex gap="0.25rem">
			<span>Go back to</span>
			<LinkToSignIn copy="Sign in" />
		</Flex>
	);
};
