const HelpIds = {
	accessCodes: 8950844,
	addons: 8913363,
	additionalQuestions: 8950849,
	affiliateTracking: 8896945,
	analysingEventTraffic: 8896966,
	apiDocs: 8888275,
	bankAccounts: 8893221,
	basicInfo: 8889132,
	bulkUpload: 8893198,
	collections: 9858072,
	contactEmail: 8888557,
	customDesign: 8951375,
	discountCodes: 8892971,
	duplicateEvent: 8913672,
	emailCampaigns: 8888873,
	eventNotifications: 8906221,
	facebookEvent: 8897156,
	groupedCapacity: 8905674,
	images: 8892493,
	mailchimp: 8888537,
	myTeam: 8892751,
	orders: 8896945,
	organisers: 8888557,
	packagedTickets: 8893185,
	paymentOptionsAndFees: 8889188,
	payouts: 8889219,
	privacy: 8950908,
	quickCreationGuide: 8889132,
	reoccurringEvents: 8893218,
	setUpTickets: 8893229,
	selfServiceRefund: 8897185,
	ticketGroups: 8905619,
	ticketTypes: 8893229,
	virtualEventsHub: 8896988,
	waitlist: 8892176,
	scheduledReports: 8897151,
	ticketImages: 8914139,
	irsW9Form: 8913814,
	irsW8Form: 8914361,
	advanceWidgetGuide: 8950947,
	hubspotMore: 8906207,
	usTaxPayer: 8906163,
	verifyToPublish: 8893220,
	seatingMaps: 8893240,
	tax: 8905558,
	eventAnalytics: 9425001,
	reportingGroups: 9503868,
	zapier: 8906254
};

const HelpUrls = {
	eventGuides: "https://help.humanitix.com/en/collections/8383472-event-guides",
	faqs: "https://help.humanitix.com/en/",
	integrations: "https://help.humanitix.com/en/collections/8383307-integrations",
	support: "https://help.humanitix.com/en/"
};

const OpenHelp = (helpId: number) => {
	if (window.Intercom) {
		window.Intercom("showArticle", helpId);
		return;
	}
	window.open(`https://help.humanitix.com/en/articles/${helpId}`, "_blank", "noreferrer");
};

const OpenHelpHandler = (helpId: number) => () => OpenHelp(helpId);

const OpenHelpUrlHandler = (url: string) => () => window.open(url, "_blank", "noreferrer");

export { HelpIds, HelpUrls, OpenHelp, OpenHelpHandler, OpenHelpUrlHandler };
