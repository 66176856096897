import { CaretDownOutlined, PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Button, Dropdown, Table } from "@/ui/antd";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import Breadcrumb from "@/components/Breadcrumb";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import LinkButton from "@/components/buttons/LinkButton";
import TableActions from "@/components/table/TableActions";
import { H1, P } from "@/components/text/Text";
import { getStylingTemplates, removeStylingTemplate } from "@/state/actions/templateActions";
import EventSelectionModal from "../EventSelectionModal";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const defaultLabelStyling = css({
	fontsize: 12,
	color: "#5a626c",
	marginLeft: 8
});

class StylingTemplates extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.createStylingTemplate = this.createStylingTemplate.bind(this);
		this.deleteStylingTemplate = this.deleteStylingTemplate.bind(this);
		this.editStylingTemplate = this.editStylingTemplate.bind(this);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.eventSelected = this.eventSelected.bind(this);
		this.state = {
			visible: false
		};
	}

	componentDidMount() {
		this.load();
	}

	load() {
		const { getStylingTemplates, currentUser } = this.props;
		getStylingTemplates(currentUser.user.id, true);
	}

	createStylingTemplate(evt) {
		const { navigate } = this.props;
		if (evt.key === "new") {
			navigate("/console/account/advanced/templates/styling-templates/new", { state: { isNew: true } });
		} else {
			this.setState({ visible: true });
		}
	}

	deleteStylingTemplate(userId, templateStyling) {
		this.props.removeStylingTemplate(userId, templateStyling._id);
	}

	editStylingTemplate(templateStyling) {
		this.props.navigate(`/console/account/advanced/templates/styling-templates/${templateStyling._id}`, {
			state: { isExisting: true }
		});
	}

	openModal() {
		this.setState({ visible: true });
	}

	closeModal() {
		this.setState({ visible: false });
	}

	eventSelected(event) {
		this.props.navigate(`/console/account/advanced/templates/styling-templates/${event}`, {
			state: { isFromEvent: true }
		});
	}

	render() {
		const { currentTemplates, currentUser, userEvents } = this.props;
		const { loading, error, templates } = currentTemplates;
		const title = "Styling Template";
		return (
			<LoadErrorView loading={loading} error={error}>
				<Breadcrumb prevPath="/console/account/advanced/templates" prevTitle="Templates" currTitle={title} />
				<H1>{title}</H1>
				<ContentBlock>
					{!loading ? (
						<div>
							<StylingTemplateTable
								stylingTemplate={templates}
								createStylingTemplate={this.createStylingTemplate}
								currentUser={currentUser}
								deleteStylingTemplate={this.deleteStylingTemplate}
								editTemplateStyling={this.editStylingTemplate}
								load={this.load}
							/>
							<EventSelectionModal
								visible={this.state.visible}
								handleCancel={this.closeModal}
								handleSubmit={this.eventSelected}
								userEvents={userEvents.events || []}
							/>
						</div>
					) : null}
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

class StylingTemplateTable extends Component {
	render() {
		const { stylingTemplate, createStylingTemplate, currentUser, deleteStylingTemplate, editTemplateStyling } =
			this.props;

		const dropdownMenu = {
			items: [
				{
					key: "new",
					label: "New Styling Template"
				},
				{
					key: "From Event",
					label: "From Existing Event"
				}
			],
			onClick: createStylingTemplate
		};

		const columns = [
			{
				title: "Template name",
				dataIndex: "name",
				key: "name",
				render: (value, templateStyling) => {
					return (
						<>
							<LinkButton
								style={{ color: "#353337" }}
								onClick={() => {
									editTemplateStyling(templateStyling);
								}}
								ariaLabel={value}
							>
								{value}
							</LinkButton>
							{templateStyling.isDefaultTheme ? <span className={defaultLabelStyling}>Default</span> : null}
						</>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				fixed: "right",
				width: 100,
				render: (_, templateStyling) => (
					<TableActions>
						<IconButton
							icon="edit"
							onClick={() => editTemplateStyling(templateStyling)}
							tooltip="Edit"
							showTooltip
							ariaLabel="Edit"
						/>

						<IconButton
							icon="delete"
							onClick={() => deleteStylingTemplate(currentUser.user.id, templateStyling)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				)
			}
		];
		return (
			<div>
				<P>
					Save time when building future events by creating a template for your event styling! When creating your next
					event simply click import template in the styling section to import this template.
				</P>
				<If condition={!stylingTemplate || !stylingTemplate.length}>
					<Then>
						<Dropdown menu={dropdownMenu}>
							<Button
								style={{
									border: "1px solid #353337",
									color: "#353337"
								}}
								icon={<PlusOutlined />}
								ariaLabel="New Template"
							>
								New template
								<CaretDownOutlined style={{ fontSize: 12 }} />
							</Button>
						</Dropdown>
					</Then>
					<Else>
						{() => (
							<div style={{ overflowX: "auto" }}>
								<Table
									columns={columns}
									scroll={{ x: 800 }}
									dataSource={stylingTemplate}
									rowKey="key"
									pagination={false}
								/>
								<ButtonsBar label="" stackButtons>
									<Dropdown menu={dropdownMenu}>
										<Button
											style={{
												border: "1px solid #353337",
												color: "#353337"
											}}
											icon={<PlusOutlined />}
											ariaLabel="New Template"
										>
											New template
											<CaretDownOutlined style={{ fontSize: 12 }} />
										</Button>
									</Dropdown>
								</ButtonsBar>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.auth,
		userEvents: state.userEvents,
		currentTemplates: state.templates
	}),
	{
		getStylingTemplates,
		removeStylingTemplate
	}
)(withRouterHooks(StylingTemplates));
