import { notification } from "@/ui/antd";
import OrganiserService from "../../services/OrganiserService";
import { HelpIds, OpenHelpHandler } from "../../utils/Help";

export const SAVE_ORGANISER_REQUEST = "SAVE_ORGANISER_REQUEST";
export const SAVE_ORGANISER_SUCCESS = "SAVE_ORGANISER_SUCCESS";
export const SAVE_ORGANISER_ERROR = "SAVE_ORGANISER_ERROR";

export const GET_ORGANISER_REQUEST = "UPDATE_ORGANISER_REQUEST";
export const GET_ORGANISER_SUCCESS = "UPDATE_ORGANISER_SUCCESS";
export const GET_ORGANISER_ERROR = "UPDATE_ORGANISER_ERROR";

export const UPDATE_ORGANISER_REQUEST = "UPDATE_ORGANISER_REQUEST";
export const UPDATE_ORGANISER_SUCCESS = "UPDATE_ORGANISER_SUCCESS";
export const UPDATE_ORGANISER_ERROR = "UPDATE_ORGANISER_ERROR";

export const OPEN_DELETE_MODEL = "OPEN_DELETE_MODEL";
export const CLOSE_DELETE_MODEL = "CLOSE_DELETE_MODEL";
export const EVENTS_WITH_ORGANISER = "EVENTS_WITH_ORGANISER";

export const DELETE_ORGANISER_REQUEST = "DELETE_ORGANISER_REQUEST";
export const DELETE_ORGANISER_SUCCESS = "DELETE_ORGANISER_SUCCESS";
export const DELETE_ORGANISER_ERROR = "DELETE_ORGANISER_ERROR";

export const FIND_ORGANISER_REQUEST = "FIND_ORGANISER_REQUEST";
export const FIND_ORGANISER_SUCCESS = "FIND_ORGANISER_SUCCESS";
export const FIND_ORGANISER_ERROR = "FIND_ORGANISER_ERROR";

export const FETCH_ORGANISER_REQUEST = "FETCH_ORGANISER_REQUEST";
export const FETCH_ORGANISER_SUCCESS = "FETCH_ORGANISER_SUCCESS";
export const FETCH_ORGANISER_ERROR = "FETCH_ORGANISER_ERROR";

export const OPEN_CREATE_MODAL = "OPEN_CREATE_MODAL";
export const CLOSE_CREATE_MODAL = "CLOSE_CREATE_MODAL";
export const CREATE_ORGANISER_ERROR = "CREATE_ORGANISER_ERROR";

export const ORGANISER_RESET = "ORGANISER_RESET";

export const openDeleteModel = (organiserId) => {
	return (dispatch) => {
		dispatch({ type: OPEN_DELETE_MODEL });
		OrganiserService.findAssociatedEvents(organiserId)
			.then((events) => {
				dispatch({ type: EVENTS_WITH_ORGANISER, events });
			})
			.catch(() => {
				dispatch({ type: CLOSE_DELETE_MODEL });
				notification.error({
					message: "Error",
					description: `Failed to check host`
				});
			});
	};
};

export const closeDeleteModel = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_DELETE_MODEL });
	};
};

export const openOrganiserCreateModal = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_CREATE_MODAL });
	};
};

export const closeCreateModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_CREATE_MODAL });
	};
};

export const createOrganiser = (organiserData) => {
	return (dispatch) => {
		dispatch({ type: SAVE_ORGANISER_REQUEST, organiser: organiserData });
		OrganiserService.create(organiserData)
			.then(({ data }) => {
				if (data.disabled) {
					notification.warning({
						message: "Content flagged",
						description: (
							<>
								The host content has been flagged and will not be visible to the public. Please{" "}
								<a onClick={OpenHelpHandler(HelpIds.verifyToPublish)}>contact support</a> for more information.
							</>
						)
					});
				} else {
					notification.success({
						message: "Saved",
						description: `The host ${organiserData.name} has been created`
					});
				}
				dispatch({
					type: SAVE_ORGANISER_SUCCESS,
					organiser: data
				});
			})
			.catch((err) => {
				dispatch({ type: CREATE_ORGANISER_ERROR, error: err.response?.data?.message });
			});
	};
};

export const updateOrganiser = (organiserId, organiserData) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_ORGANISER_REQUEST, organiser: organiserData });
		OrganiserService.update(organiserId, organiserData)
			.then((organiser) => {
				notification.success({
					message: "Updated",
					description: `Your host ${organiserData.name} has been updated`
				});
				dispatch({ type: UPDATE_ORGANISER_SUCCESS, organiser: organiser });
			})
			.catch((err) => {
				const errorMsg = err?.response?.data?.message ? err.response.data.message : "Failed to update host";
				notification.error({
					message: "Error",
					description: errorMsg
				});
				dispatch({ type: UPDATE_ORGANISER_ERROR, error: errorMsg });
			});
	};
};

export const getOrganiser = (organiserId) => {
	return (dispatch) => {
		dispatch({ type: GET_ORGANISER_REQUEST });
		OrganiserService.get(organiserId)
			.then((organiser) => {
				dispatch({ type: GET_ORGANISER_SUCCESS, organiser: organiser });
			})
			.catch((err) => {
				dispatch({ type: GET_ORGANISER_ERROR, error: err });
			});
	};
};

export const removeOrganiser = (organiserId, navigate) => {
	return (dispatch) => {
		OrganiserService.delete(organiserId)
			.then(() => {
				dispatch({ type: DELETE_ORGANISER_SUCCESS, id: organiserId });
				notification.success({
					message: "Delete",
					description: `The host has been deleted`
				});
				navigate("/console/account/hosts");
			})
			.catch((err) => {
				console.error("err", err);
				const errorMsg = err?.response?.data?.message ? err.response.data.message : "Failed to delete host";
				dispatch({ type: DELETE_ORGANISER_ERROR, error: errorMsg });
				notification.error({
					message: "Error",
					description: "Failed to delete host"
				});
			});
	};
};

export const organiserReset = () => {
	return (dispatch) => {
		dispatch({ type: ORGANISER_RESET });
	};
};

export const findOrganisers = (query, page, pageSize, eventUserId, eventOrganiserId, adminView = false) => {
	return (dispatch) => {
		dispatch({ type: FETCH_ORGANISER_REQUEST });
		OrganiserService.find(query, page, pageSize, eventUserId, eventOrganiserId, adminView)
			.then((response) => {
				dispatch({
					type: FETCH_ORGANISER_SUCCESS,
					organiser: response.organisers,
					count: response.count,
					page: page
				});
			})
			.catch((err) => {
				dispatch({ type: FETCH_ORGANISER_ERROR, error: err });
			});
	};
};
