import { LegacyInputField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H2 } from "@/components/text/Text";
import { getConfig } from "@/config";
import { DownloadQRCode } from "@/events/event/DownloadQRCode";
import { updateSlug } from "@/state/actions/eventActions";
import { useAppSelector } from "@/state/hooks";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { Button } from "@/ui";
import { Card } from "@/ui";
import { CopyField } from "@/ui";
import { getEventUrls } from "@/utils/Events";
import { urlSafe } from "@/utils/Format";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { EditOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { Alert, Flex } from "@/ui/antd";
import { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

const editBar = css({
	flex: 1,
	marginRight: 12,
	"@media(max-width:600px)": {
		".ant-input-group-addon": {
			display: "none"
		}
	}
});

const Header: FC = () => (
	<div className={css({ "@media(max-width: 600px)": { marginBottom: 20 } })}>
		<H2>Share event</H2>
	</div>
);

const pickDataFromState = (state: Partial<GlobalReduxState>) => ({
	initialValues: state.currentEvent?.event
});

type LocalisedReduxState = ReturnType<typeof pickDataFromState>;

interface ReduxActions {
	updateSlug: (eventId: string, slug: string) => void;
}

interface FormData {
	slug: string;
}

type ReduxInput = LocalisedReduxState & ReduxActions;
type ShareEventProps = ReduxInput;

const ShareEvent: FC<ShareEventProps & InjectedFormProps<FormData, ShareEventProps>> = ({
	handleSubmit,
	updateSlug
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const eventSlugRef = useRef<string | undefined>(currentEvent?.event?.slug);

	const { eventPage, selectTicketsPage } = getEventUrls(currentEvent?.event, true);
	const ticketingUrl = `${getConfig("TICKETING_URL")}/`;
	const isEventPublished = !!currentEvent?.event?.published;

	const _closeEditIfSlugUpdated = () => {
		if (eventSlugRef.current !== currentEvent?.event?.slug) {
			setIsEditing((curr) => !curr);
			eventSlugRef.current = currentEvent?.event?.slug;
		}
	};

	useEffect(_closeEditIfSlugUpdated, [currentEvent?.event?.slug]);

	const _handleSave = (values: any) => {
		if (currentEvent?.event?._id) {
			updateSlug(currentEvent?.event?._id, values.slug);
		}
	};

	return !isEditing ? (
		<Card>
			<Header />
			<Flex vertical gap={8}>
				<Flex gap={8}>
					<CopyField
						displayValue={eventPage}
						dropdownOptions={[
							{ label: "For event page", value: eventPage },
							{ label: "For select tickets page", value: selectTicketsPage }
						]}
					/>
					<Button aria-label="Edit event url" onClick={() => setIsEditing((curr) => !curr)} iconOnly variant="text">
						<EditOutlined style={{ fontSize: 18 }} />
					</Button>
				</Flex>
				{isEventPublished ? (
					<Flex justify="flex-start">
						<DownloadQRCode event={currentEvent?.event} />
					</Flex>
				) : null}
			</Flex>
		</Card>
	) : (
		<form onSubmit={handleSubmit(_handleSave)}>
			<LoadErrorView loading={!!currentEvent?.loading} error={currentEvent?.error}>
				<Card>
					<Header />
					<Alert
						message="Warning"
						description="Updating your event url may break any existing marketing links you have set up."
						type="warning"
						showIcon
						style={{ margin: "0 0 12px 0" }}
					/>
					<Flex gap={8}>
						<Flex style={{ width: "100%" }}>
							<div className={editBar}>
								<Field
									name="slug"
									component={LegacyInputField}
									addonBefore={ticketingUrl}
									normalize={urlSafe}
									style={{
										borderRadius: 0
									}}
								/>
							</div>
							<LegacyButton
								style={{
									float: "right",
									height: 36,
									borderRadius: "0 4px 4px 0",
									minWidth: 73,
									marginLeft: -16
								}}
								type="primary"
								htmlType="submit"
								ariaLabel="Save"
							>
								Save
							</LegacyButton>
						</Flex>

						<Button aria-label="Edit event url" onClick={() => setIsEditing((curr) => !curr)} iconOnly variant="text">
							<EditOutlined style={{ fontSize: 18 }} />
						</Button>
					</Flex>
				</Card>
			</LoadErrorView>
		</form>
	);
};

const formName = "eventUrl";

const ReduxConnectedComponent = reduxForm<FormData, ReduxInput>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(ShareEvent);

export default connect(pickDataFromState, { updateSlug })(ReduxConnectedComponent);
