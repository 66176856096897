import { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { CheckboxField, LegacyInputField, InputNumberField, TextAreaField } from "@/components/Fields";
import { H4 } from "@/components/text/Text";
import { required } from "@/utils/Validators";

/**
 * Used on referral payout. This is a payout form
 *
 * @class PayoutForm
 * @extends {Component}
 */
class PayoutForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			warning: "",
			warningIssued: false
		};
		this.save = this.save.bind(this);
	}

	save() {
		const { save, remainingAmount, editForm } = this.props;
		const paymentInfo = editForm.values;
		if (paymentInfo.amount > remainingAmount) {
			this.setState({
				warning: "The amount entered is larger than the remaining amount"
			});
		} else {
			save();
			this.setState({ warning: null });
		}
	}

	render() {
		const { payment, closed, remainingAmount, editForm } = this.props;
		const paymentInfo = editForm ? editForm.values : null;
		const { warning } = this.state;
		return (
			<Form
				paymentInfo={paymentInfo}
				payment={payment}
				save={this.save}
				remainingAmount={remainingAmount}
				closed={closed}
				warningMessage={warning}
			/>
		);
	}
}

let transactionIdRequired = required("A transaction Id is required");
let amountRequired = required("An amount is required");
let Form = ({ save, handleSubmit, warningMessage }) => {
	return (
		<form onSubmit={handleSubmit(save)} id="paymentForm">
			<div>
				<Field
					name="transactionId"
					label="Transaction Id"
					component={LegacyInputField}
					validate={transactionIdRequired}
				/>
				<Field name="amount" label="Amount" component={InputNumberField} validate={amountRequired} precision={2} />
				<Field name="notes" label="Notes" component={TextAreaField} />
				<Field inline name="sendEmails" label="Send Payment Emails" component={CheckboxField} />
			</div>
			<H4 style={{ color: "red" }}>{warningMessage}</H4>
		</form>
	);
};

const formName = "addPayment";

// Decorate with redux-form

Form = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true
})(Form);

Form = connect((state, props) => {
	const initialValues = props.payment
		? {
				...props.payment,
				checkFinal: false,
				sendEmails: false
		  }
		: {
				sendEmails: true,
				checkFinal: true
		  };
	return {
		initialValues,
		currentEvent: state.currentEvent
	};
})(Form);

export default connect((state) => ({
	editForm: state.form.addPayment,
	currentEvent: state.currentEvent
}))(PayoutForm);
