import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import fbIcon from "@/assets/ic_facebook_btn.svg";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import { H2, P } from "@/components/text/Text";

import { connectFacebook } from "@/state/actions/facebookActions";

const connectWrapper = css({
	paddingRight: "50%",
	backgroundSize: "300px auto",
	backgroundPosition: "right center",
	minHeight: 200,
	"@media(max-width:1000px)": {
		paddingRight: 0,
		background: "#fff"
	}
});

const facebookBtn = css({
	color: "#fff",
	background: `#1877f3 url(${fbIcon}) no-repeat`,
	backgroundSize: "8px 18px",
	backgroundPosition: "12px center",
	border: "none",
	cursor: "pointer",
	borderRadius: 4,
	fontSize: 14,
	fontWeight: 600,
	padding: "8px 16px 8px 32px",
	"@hover": {
		background: "#1877f0"
	}
});

class ConnectFacebook extends Component {
	connectFacebook = () => {
		const { currentEvent, connectFacebook } = this.props;
		connectFacebook(currentEvent.event.userId);
	};

	render() {
		const { facebook, currentEvent, pageAccess } = this.props;
		const facebookEvent =
			currentEvent.event.facebookEvents && currentEvent.event.facebookEvents.length
				? currentEvent.event.facebookEvents[0]
				: false;
		const pageName = facebookEvent && facebookEvent.pageName ? facebookEvent.pageName : null;

		let message = pageName ? (
			<div>
				<P>
					An existing{" "}
					<a
						href={`https://www.facebook.com/events/${facebookEvent.facebookId}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Facebook event
					</a>{" "}
					has been created for this account.
				</P>
				<P>Please connect an account that has access to the page: {pageName}.</P>
				{!pageAccess && (
					<P>Please ask your facebook Administrator for access to this page or connect a different account.</P>
				)}
			</div>
		) : (
			<div>
				<P>
					Gain a bigger audience by connecting your Humanitix account to Facebook at no extra cost! Post your Humanitix
					events directly to your Facebook page, without having to replicate your event on Facebook. It's as easy as 1,
					2, 3!{" "}
				</P>
				<P>Note: you must have a public Facebook page to use this feature.</P>
			</div>
		);

		return (
			<ContentBlock>
				<LoadErrorView loading={facebook.connect.loading}>
					<div className={connectWrapper}>
						<H2>Connect your account to Facebook</H2>
						<P>{message}</P>
						<button
							onClick={this.connectFacebook}
							type="action"
							ariaLabel="Connect to Facebook"
							className={facebookBtn}
						>
							Connect to Facebook
						</button>
					</div>
				</LoadErrorView>
			</ContentBlock>
		);
	}
}

export default connect(
	(state) => {
		return {
			facebook: state.facebook,
			currentEvent: state.currentEvent
		};
	},
	{ connectFacebook }
)(ConnectFacebook);
