import Account from "@/account/Account";
import { Admin } from "@/admin/Admin";
import Comms from "@/comms/Comms";
import { Loading } from "@/components/AppBlocks";
import Steps from "@/components/Steps";
import { Dashboard } from "@/dashboard/Dashboard";
import { Event } from "@/events/event/Event";
import Basics from "@/events/event/eventCreation/basics/Basics";
import { getStepsForEventCreation } from "@/events/event/eventCreation/utils/eventCreationUtils";
import Events from "@/events/Events";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import NotAllowed from "@/NotAllowed";
import NotFound from "@/NotFound";
import Organisers from "@/organisers/Organisers";
import Promote from "@/promote/Promote";
import Reports from "@/reports/Reports";
import { ShaksSpecialPlaceDashboard } from "@/shaksPlace/ShaksPlaceDashboard";
import UserContainer from "@/users/UserContainer";
import Users from "@/users/Users";
import styled from "@emotion/styled";
import { FC, Suspense } from "react";
import { Navigate, Route, Routes as RouterRoutes } from "react-router";

const Content = styled.div`
	display: block;
	flex: 1;
	overflow: auto;
	background-color: var(--surface3);
	height: 100%;
`;

const Routes: FC = () => {
	const { isAdmin } = useCurrentUser();
	return (
		<Content className="scrollEl">
			<Suspense fallback={<Loading />}>
				<RouterRoutes>
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/my-events/*">
						<Route path="" element={<Events />} />
						<Route path=":eventId/*" element={<Event />} />
					</Route>
					<Route path="/reports/*" element={<Reports />} />
					<Route path="/promote/*" element={<Promote />} />
					<Route path="/comms/*" element={<Comms />} />
					<Route path="/account/*" element={<Account />} />
					{isAdmin ? (
						<Route path="/admin/*">
							<Route path="*" element={<Admin />} />
							<Route path="users/:userId/*" element={<UserContainer />} />
							<Route path="users" element={<Users />} />
							<Route path="hosts" element={<Organisers adminView={true} />} />
						</Route>
					) : (
						<Route path="/admin/*" element={<NotAllowed />} />
					)}
					<Route path="/shaks-place/*" element={<ShaksSpecialPlaceDashboard />} />
					<Route path="/unauthorised" element={<NotAllowed />} />
					<Route path="/my-events/new/*">
						<Route
							path="basics"
							element={
								<>
									<Steps steps={getStepsForEventCreation()} />
									<Basics />
								</>
							}
						/>
						<Route path="event" element={<Navigate to="/console/my-events/new/basics" />} />
					</Route>
					<Route path="*" element={<NotFound />} />
				</RouterRoutes>
			</Suspense>
		</Content>
	);
};

export default Routes;
