import { Field } from "redux-form";
import { RadioField } from "@/components/Fields";

import SplitOptions from "./SplitOptions";
import TipOptions from "./TipOptions";

const PerEventFeeSettings = ({ path = "feeChargeSettings", feeStructure, feeChargeSettings, changeFieldValue }) => {
	const defaultFeeChargeSettings = {
		type: "passOn",
		feesSetByTicketType: false,
		absorbFeesByTicketType: [],
		splitAmounts: {
			fixed: { passOn: 100, absorb: 0 },
			percent: { passOn: 100, absorb: 0 },
			payment: { passOn: 100, absorb: 0 }
		},
		tipPercent: 100
	};

	const feeChargeSettingsSplit = feeChargeSettings ? feeChargeSettings.splitAmounts : {};

	const useSeeChargeSettings = {
		...defaultFeeChargeSettings,
		...feeChargeSettings,
		splitAmounts: {
			...defaultFeeChargeSettings.splitAmounts,
			...feeChargeSettingsSplit
		}
	};

	const { type } = useSeeChargeSettings;
	return (
		<>
			<Field
				name={`${path}.type`}
				component={RadioField}
				button
				options={[
					{
						label: "Pass on fees",
						value: "passOn"
					},
					{
						label: "Absorb fees",
						value: "absorb"
					},
					{
						label: "Split fees",
						value: "split"
					},
					{
						label: "Tip fees",
						value: "tip"
					}
				]}
			/>
			{type === "split" ? (
				<SplitOptions
					changeFieldValue={changeFieldValue}
					path={path}
					feeStructure={feeStructure}
					feeChargeSettings={useSeeChargeSettings}
				/>
			) : null}
			{type === "tip" ? <TipOptions path={path} /> : null}
		</>
	);
};

export default PerEventFeeSettings;
