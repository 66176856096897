import GlobalDiscountCodePage from "@/account/globalDiscountCodes/GlobalDiscountCodePage";
import GlobalDiscountCodes from "@/account/globalDiscountCodes/GlobalDiscountCodes";
import Merchandise from "@/addOns/Merchandise";
import MerchandiseForm from "@/addOns/MerchandiseForm";
import MenuLayout from "@/components/MenuLayout";
import Page from "@/components/Page";
import GlobalRefund from "@/events/event/order/refunds/GlobalRefund";
import { CollectionsHome } from "@/features/collections/CollectionsHome";
import GiftCardOrder from "@/giftCards/GiftCardOrder";
import GiftCards from "@/giftCards/GiftCards";
import GiftCardForm from "@/giftCards/GiftCardsForm";
import GiftCardsPage from "@/giftCards/page/GiftCardPage";
import PromoteMenu from "@/promote/PromoteMenu";
import PromotionalHub from "@/promotionalHub/PromotionalHub";
import Tour from "@/tour/Tour";
import { Tours } from "@/tour/Tours";
import { FC } from "react";
import { Route, Routes } from "react-router";

const Promote: FC = () => {
	return (
		<MenuLayout>
			<PromoteMenu />
			<Page type="details">
				<Routes>
					<Route path="collections/*" element={<CollectionsHome />} />

					<Route path="promotional-hub/:tab" element={<PromotionalHub />} />

					<Route path="global-discount-codes" element={<GlobalDiscountCodes />} />
					<Route path="global-discount-codes/:discountCodeId/:location" element={<GlobalDiscountCodePage />} />

					<Route path="tours" element={<Tours />} />
					<Route path="tours/:tourId" element={<Tour />} />

					<Route path="gift-cards" element={<GiftCards />} />
					<Route path="gift-cards/:id/update" element={<GiftCardForm />} />
					<Route path="gift-cards/new" element={<GiftCardForm />} />
					<Route path="gift-cards/page" element={<GiftCardsPage />} />
					<Route path="gift-cards/order/:orderId" element={<GiftCardOrder />} />
					<Route path="gift-cards/order/:orderId/refund" element={<GlobalRefund />} />

					<Route path="add-ons" element={<Merchandise />} />
					<Route path="add-ons/:id/update" element={<MerchandiseForm />} />
					<Route path="add-ons/new" element={<MerchandiseForm />} />
				</Routes>
			</Page>
		</MenuLayout>
	);
};

export default Promote;
