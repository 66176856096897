import axios from "./AxiosWrapper";

const OrganiserService = {
	async find(query, page, pageSize, eventUserId, eventOrganiserId, adminView) {
		const response = await axios.get(`organisers/`, {
			params: {
				query: query,
				userId: eventUserId,
				organiserId: eventOrganiserId,
				page: page,
				pageSize: pageSize,
				adminView: adminView
			}
		});
		return response.data;
	},
	async get(organiserId) {
		const response = await axios.get(`organisers/${organiserId}`);
		return response.data;
	},
	async create(organiser) {
		return axios.put(`organisers/`, organiser);
	},
	async update(organiserId, organiserData) {
		const response = await axios.post(`organisers/${organiserId}`, organiserData);
		return response.data;
	},
	async findAssociatedEvents(organiserId) {
		const response = await axios.get(`organisers/associatedEvents/${organiserId}`);
		return response.data;
	},
	async delete(organiserId) {
		const response = await axios.delete(`organisers/${organiserId}`);
		return response.data;
	}
};
export default OrganiserService;
