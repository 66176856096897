function IsUserNfpOrCharity(user) {
	if (user && user.fees) {
		var result = Object.keys(user.fees).map((key) => {
			const { type } = user.fees[key] ? user.fees[key] : {};
			if (type === "charity" || type === "notForProfit") {
				return true;
			}
		})[0];
	}

	return !!result;
}

export { IsUserNfpOrCharity };
