import React from "react";

class TableActions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: this.props.expanded
		};
	}
	render() {
		const { children, containerStyle = null } = this.props;
		const styles = {
			container: {
				maxHeight: 36,
				width: children.constructor === Array && children.length * 40
			}
		};
		return <div style={{ ...styles.container, ...containerStyle }}>{children}</div>;
	}
}

export default TableActions;
