import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Field, InjectedFormProps, formValueSelector, reduxForm } from "redux-form";
import { useCurrentUser } from "@/hooks/useCurrentUser";

import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, RichTextAreaField } from "@/components/Fields";
import FilestackPrecache from "@/components/FilestackPrecache";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import H2 from "@/components/text/H2";
import P from "@/components/text/P";

import Hosts from "./Hosts";
import Tags from "./Tags";

import OrganiserModal from "@/organisers/OrganiserModal";
import BannerImage from "../../design/Banner";
import RefundSettingsForm from "../../payments/RefundSettingsForm";
import FinishLater from "../FinishLater";
import StepBackButton from "../StepBackButton";

import { updateEventDetails } from "@/state/actions/eventActions";
import { findOrganisers, openOrganiserCreateModal } from "@/state/actions/organiserActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";

import withSaveCheck from "@/hoc/withSaveCheck";

import { accessibleImagesCheck, checkLength, emptyOrCheck } from "@/utils/Validators";
import { wrapperMenu, wrapperNoMenu } from "../utils/commonStyles";
import { checkInWizard } from "../utils/eventCreationUtils";

const formName = "details";

const maxSharingDescriptionLength = emptyOrCheck(checkLength("Sharing description character limit is 160", 160, "<="));

const pickDataFromState = (state: Partial<GlobalReduxState>) => {
	const loading = state.currentEvent?.loading;
	const event = state.currentEvent?.event ?? { dates: [] };

	const refundSettings = selector(state, "paymentOptions.refundSettings");
	if (refundSettings && refundSettings.allowAttendeeRefund === undefined) {
		refundSettings.allowAttendeeRefund = false;
	}

	const initialValues = {
		description: event.description,
		sharingDescription: event.sharingDescription,
		bannerImage: event.bannerImage,
		logoImage: event.logoImage,
		organiserId: event.organiserId,
		refundPolicy: event.refundPolicy,
		customRefundPolicy: event.customRefundPolicy,
		tagIds: event.tagIds,
		paymentOptions: event.paymentOptions
	};
	return {
		initialValues: initialValues,
		event,
		loading,
		refundSettings: selector(state, "paymentOptions.refundSettings"),
		bannerImage: selector(state, "bannerImage"),
		isOrganisersCreateModalOpen: state.organisers?.create?.open
	};
};

interface ReduxActions {
	findOrganisers: (
		query?: any,
		page?: number,
		pageSize?: number,
		eventUserId?: string,
		eventOrganiserId?: string
	) => void;
	openOrganiserCreateModal: () => void;
	updateEventDetails: (eventId: string, data: any) => void;
}

type LocalisedReduxState = ReturnType<typeof pickDataFromState>;

type ReduxInput = Partial<GlobalReduxState> & ReduxActions & LocalisedReduxState;
type FormProps = InjectedFormProps & {
	dispatch: Dispatch<any, any>;
};

type DetailsProps = ReduxInput & FormProps;

const Details = ({
	event,
	handleSubmit,
	dispatch,
	change,
	findOrganisers,
	updateEventDetails,
	loading,
	refundSettings,
	isOrganisersCreateModalOpen,
	openOrganiserCreateModal,
	bannerImage
}: DetailsProps) => {
	const { hasTagAccess } = useCurrentUser();
	const inWizard = checkInWizard(event);

	useEffect(() => {
		findOrganisers(null, null, null, event.userId, event.organiserId);
	}, []);

	const save = (values: any) => {
		updateEventDetails(event._id, values);
	};

	const changeFieldValue = (field: string, value: any) => {
		dispatch(change(field, value));
	};

	return (
		<form onSubmit={handleSubmit(save)}>
			<OrganiserModal forEvent visible={isOrganisersCreateModalOpen} changeFieldValue={changeFieldValue} />
			<div className={inWizard ? wrapperNoMenu : wrapperMenu}>
				<LoadErrorView loading={!!loading}>
					<ContentBlock allowOverflow>
						<H2>Details</H2>
						<P>Great! Let's add some more details to your event page</P>
						<H2>Banner artwork</H2>
						<BannerImage />
						{bannerImage && <FilestackPrecache imageHandle={bannerImage.handle} />}
						<hr />
						<Field
							label="Description"
							description="Tell people about your event"
							id="description"
							name="description"
							component={RichTextAreaField}
							rows={10}
							placeholder="This is the best event ever because ..."
							warn={accessibleImagesCheck}
							topPosition={60}
						/>
						{!isOrganisersCreateModalOpen ? (
							<Hosts
								setIsOrganiserModalOpen={openOrganiserCreateModal}
								isOrganiserModalOpen={isOrganisersCreateModalOpen}
							/>
						) : null}
						<hr />

						<Field
							name="sharingDescription"
							label="Sharing description"
							description="Displayed when your event link is shared on social media or messaging platforms"
							component={LegacyInputField}
							validate={maxSharingDescriptionLength}
						/>
						<hr />

						{hasTagAccess ? (
							<>
								<Tags />
								<hr />
							</>
						) : null}

						<H2>Refund settings</H2>
						<RefundSettingsForm
							refundSettings={refundSettings}
							changeFieldValue={changeFieldValue}
							fromPath="paymentOptions."
						/>
					</ContentBlock>
					<FormBar>
						<FinishLater saveFunction={save} handleSubmit={handleSubmit} />
						<StepBackButton inWizard={inWizard} />
						<LegacyButton
							htmlType="submit"
							style={{
								float: "right"
							}}
							type="primary"
							ariaLabel="Save"
							dataCy="next-step"
						>
							Save &#38; continue
						</LegacyButton>
					</FormBar>
				</LoadErrorView>
			</div>
		</form>
	);
};

const selector = formValueSelector(formName);

// Decorate with redux-form
const ReduxConnectedComponent = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Details);

export default connect(pickDataFromState, {
	findOrganisers,
	openOrganiserCreateModal,
	updateEventDetails
})(withSaveCheck(ReduxConnectedComponent, formName));
