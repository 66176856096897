import axios from "./AxiosWrapper";

const EmailCampaignsService = {
	async get(userId, id, template, location) {
		const response = await axios.get(`global/email-campaigns/${userId}/${id}?template=${template}`, {
			headers: { "x-override-location": location }
		});
		return response.data;
	},
	async saveDraft(userId, emailData, location) {
		const response = await axios.put(`global/email-campaigns/${userId}`, emailData, {
			headers: { "x-override-location": location }
		});
		return response.data;
	},
	async saveAndEnqueue(userId, emailData, location) {
		const response = await axios.put(`global/email-campaigns/${userId}/save-enqueue`, emailData, {
			headers: { "x-override-location": location }
		});
		return response.data;
	},

	async approve(campaignId, location) {
		const response = await axios.post(
			`/global/email-campaigns/approve/${campaignId}`,
			{
				campaign: campaignId
			},
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	},

	async disapprove(campaignId, location) {
		const response = await axios.post(
			`/global/email-campaigns/disapprove/${campaignId}`,
			{
				campaign: campaignId
			},
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	},
	async list(userId, page = 1, status = "all", query = "", eventIds = [], location) {
		const response = await axios.get(`global/email-campaigns/${userId}`, {
			params: {
				page,
				status,
				query,
				eventIds
			},
			headers: { "x-override-location": location }
		});
		return response.data;
	},
	async remove(userId, id, location) {
		const response = await axios.delete(`global/email-campaigns/${userId}/${id}`, {
			headers: { "x-override-location": location }
		});
		return response.data;
	},
	async sendTestEmail(campaign, location) {
		const response = await axios.post(
			`/global/email-campaigns/send-test`,
			{
				campaign
			},
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	},
	async getPreview(campaign, location) {
		const response = await axios.post(
			`/global/email-campaigns/preview`,
			{
				campaign
			},
			{ headers: { "x-override-location": location } }
		);
		return response.data;
	},

	async cancelScheduledEmails(userId, campaignId, location) {
		const response = await axios.delete(`global/email-campaigns/${userId}/scheduled-emails/${campaignId}`, {
			headers: { "x-override-location": location }
		});
		return response.data;
	},
	async getCampaignSummary(userId, campaign, location) {
		const response = await axios.post(`global/email-campaigns/${userId}/campaign-summary`, campaign, {
			headers: { "x-override-location": location }
		});
		return response.data;
	},
	async getUnsubscribers(userId, page, query, eventIds) {
		const response = await axios.get(`global/email-campaigns/${userId}/unsubscribers`, {
			params: {
				page,
				query,
				eventIds
			}
		});
		return response.data;
	}
};

export default EmailCampaignsService;
