import LocationService from "../services/LocationService";
import { HumaniDate } from "@hx/dates";

type TimeScale = [any, any] | "today" | "yesterday" | "week" | "month";

export const getDateRange = (
	timeScale: TimeScale
): { start: string; end: string; timeScale: TimeScale; timezone: string } => {
	const nowDate = new HumaniDate(undefined, "guess", LocationService.getLocation(true));
	const utcOffset = nowDate.dayJs.utcOffset();
	if (Array.isArray(timeScale) && timeScale.length === 2) {
		const [unparsedStartDate, unparsedEndDate] = timeScale;
		const { startDate, endDate } = getDatesWithDSTConsidered(unparsedStartDate, unparsedEndDate);

		return {
			start: new HumaniDate(startDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
			end: new HumaniDate(endDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
			timeScale,
			timezone: HumaniDate.getBrowserTimezone()
		};
	}

	switch (timeScale) {
		case "today": {
			const { startDate, endDate } = nowDate.canned.dayStartEnd(utcOffset);

			return {
				start: new HumaniDate(startDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				end: new HumaniDate(endDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				timeScale,
				timezone: HumaniDate.getBrowserTimezone()
			};
		}
		case "yesterday": {
			const { startDate, endDate } = nowDate.canned.yesterdayStartEnd(utcOffset);

			return {
				start: new HumaniDate(startDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				end: new HumaniDate(endDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				timeScale,
				timezone: HumaniDate.getBrowserTimezone()
			};
		}
		case "week": {
			const { startDate, endDate } = nowDate.canned.lastWeekStartEnd(utcOffset);
			return {
				start: new HumaniDate(startDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				end: new HumaniDate(endDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				timeScale,
				timezone: HumaniDate.getBrowserTimezone()
			};
		}
		case "month":
		default: {
			const { startDate, endDate } = nowDate.canned.lastMonthStartEnd(utcOffset);
			return {
				start: new HumaniDate(startDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				end: new HumaniDate(endDate).dayJs.format("ddd, DD MMM YYYY HH:mm:ss ZZ"),
				timeScale,
				timezone: HumaniDate.getBrowserTimezone()
			};
		}
	}
};

// we have multiple dates. We need to round them off to the nearest day (accounting for daylight savings)
const getDatesWithDSTConsidered = (unparsedStartDate: any, unparsedEndDate: any) => {
	const startDate = new HumaniDate(unparsedStartDate.startOf("day").toISOString(), "guess", "AU");
	const endDate = new HumaniDate(unparsedEndDate.endOf("day").toISOString(), "guess", "AU");

	if (endDate.isDST() && !startDate.isDST()) {
		return {
			startDate: startDate.canned.subtractDaylightSavings(),
			endDate: endDate.toDate()
		};
	} else if (!endDate.isDST() && startDate.isDST()) {
		return {
			startDate: startDate.canned.addDaylightSavings(),
			endDate: endDate.toDate()
		};
	} else {
		return { startDate: startDate.toDate(), endDate: endDate.toDate() };
	}
};
