import { Button, Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField, InputNumberField, SelectField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { add } from "@/state/actions/waitlistActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { email, required } from "@/utils/Validators";

const eventDateRequired = required("Event date is required");
const firstNameRequired = required("First name is required");
const lastNameRequired = required("Last name is required");
const emailValid = email("An vaild email is required");
const emailRequired = required("An email address is required");

let AddToWaitlistModal = ({ add, currentEvent, waitlist, handleSubmit, visible, handleClose }) => {
	const save = (values) => {
		add(String(currentEvent.event._id), values, handleClose);
	};

	const tickets = currentEvent.event.ticketTypes.concat(currentEvent.event.packagedTickets);
	const ticketOptions = tickets
		.filter((t) => !t.deleted && !t.isDonation)
		.map((ticketType) => {
			return {
				label: ticketType.name,
				value: ticketType._id
			};
		});
	const dateOptions = currentEvent.event.dates.reduce((options, date) => {
		options.push({
			label: `${date.display.date}, ${date.display.time}`,
			value: date._id
		});
		return options;
	}, []);

	return (
		<Modal
			open={visible}
			title="Add to Waitlist"
			destroyOnClose
			onCancel={handleClose}
			footer={[
				<Button
					style={{
						float: "left"
					}}
					key="cancel"
					onClick={handleClose}
					htmlType="button"
					ariaLabel="Cancel"
				>
					Cancel
				</Button>,
				<Button onClick={handleSubmit(save)} key="submit" type="primary" htmlType="submit" ariaLabel="Add">
					Add
				</Button>
			]}
		>
			<LoadErrorView loading={waitlist.add.loading} error={waitlist.add.error} retryAction={save}>
				<form onSubmit={handleSubmit(save)}>
					{currentEvent.event.dates.length > 1 ? (
						<Field
							label="Event Date"
							name="eventDateIds"
							component={SelectField}
							mode="multiple"
							required
							validate={eventDateRequired}
							options={dateOptions}
						/>
					) : null}
					<Field
						label="First name"
						type="text"
						name="firstName"
						component={LegacyInputField}
						required
						validate={firstNameRequired}
					/>
					<Field
						label="Last name"
						type="text"
						name="lastName"
						component={LegacyInputField}
						required
						validate={lastNameRequired}
					/>
					<Field
						label="Email"
						type="email"
						name="email"
						component={LegacyInputField}
						required
						validate={[emailValid, emailRequired]}
					/>
					<Field label="Mobile" type="text" name="mobile" component={LegacyInputField} />
					<Field
						label="Ticket types they are interested in?"
						mode="multiple"
						name="ticketIds"
						component={SelectField}
						options={ticketOptions}
					/>
					<Field
						label="How many tickets are they looking for?"
						type="number"
						name="ticketsRequested"
						component={InputNumberField}
						min={0}
						max={100}
					/>
				</form>
			</LoadErrorView>
		</Modal>
	);
};

const formName = "addToWaitList";

AddToWaitlistModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(AddToWaitlistModal);

export default connect(
	(state) => {
		return {
			initialValues: {
				eventDateIds: state.currentEvent.event.dates.length === 1 ? [state.currentEvent.event.dates[0]._id] : []
			},
			currentEvent: state.currentEvent,
			waitlist: state.waitlist
		};
	},
	{ add }
)(AddToWaitlistModal);
