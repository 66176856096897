import { InputNumberField, RadioField, SelectField } from "@/components/Fields";
import InputLabel from "@/components/fields/helpers/InputLabel";
import { required } from "@/utils/Validators";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { ReduxDateTimePicker } from "@/components/fields/ReduxDateTimePicker";

const startAtRequired = required("A start at day is required");

const Settings = ({
	fieldPath,
	visible,
	settings = {},
	currentEvent,
	hubSettings,
	style = {
		background: "#f9f9fa",
		border: "1px solid #dedede",
		padding: 12,
		marginBottom: 12
	},
	formName
}) => {
	const tickets = currentEvent.event.ticketTypes
		.filter((t) => {
			return hubSettings?.availableFor?.length ? hubSettings?.availableFor?.indexOf(String(t._id)) !== -1 : true;
		})
		.concat(currentEvent.event.packagedTickets)
		.filter((ticket) => !(ticket.disabled || ticket.deleted || ticket.isDonation));
	const ticketOptions = [].concat(
		tickets.map((ticket) => {
			return { value: ticket._id, label: ticket.name };
		})
	);
	const validDates = currentEvent.event.dates.filter((t) => {
		const isDateAvailable = hubSettings?.datesAvailableFor?.length
			? hubSettings?.datesAvailableFor?.includes(String(t._id))
			: true;
		const isDateValid = !t.disabled && !t.deleted;
		return isDateAvailable && isDateValid;
	});

	const datesOptions = [].concat(
		validDates.map((date) => {
			return { value: date._id, label: date.display.combined };
		})
	);
	const timezone = currentEvent.event.timezone;

	const atMessageString = (before) => {
		const daysField = "days";
		const hoursField = "hours";

		const daysValue = settings.show && settings.show[daysField] ? settings.show[daysField] : 0;
		const hoursValue = settings.show && settings.show[hoursField] ? settings.show[hoursField] : 0;
		const oneHour = 1 / 24;
		const valueInDays = daysValue + hoursValue * oneHour;
		const noValue = valueInDays === null || valueInDays === undefined;
		const startStr = `Show content `;

		if (valueInDays === 0) {
			return `${startStr} when the event starts`;
		}

		if (noValue) {
			// No value return defaults
			const endStr = before ? "now" : "at the end of the event";
			return `${startStr} ${endStr}`;
		}
		const daysPlural = daysValue > 1 ? "s" : "";
		const hoursPlural = hoursValue > 1 ? "s" : "";
		const timeParts = [];
		if (daysValue) {
			timeParts.push(`${daysValue} day${daysPlural}`);
		}
		if (hoursValue) {
			timeParts.push(`${hoursValue} hour${hoursPlural}`);
		}
		const timeStr = timeParts.join(" & ");
		return `${startStr} ${timeStr} before the event`;
	};

	if (!visible) {
		return null;
	}

	console.log("timezone", timezone);

	return (
		<div style={style}>
			<Field
				name={`${fieldPath}.showWhen`}
				label="When can this content be accessed"
				component={RadioField}
				button
				defaultValue="always"
				options={[
					{ label: "Always", value: "always" },
					{ label: "At", value: "at" },
					{ label: "Before", value: "before" }
				]}
			/>
			{settings.showWhen === "at" ? (
				<Field
					component={ReduxDateTimePicker}
					displayFormat="Do MMM YYYY"
					formName={formName}
					label="Show date & time"
					name={`${fieldPath}.show.date`}
					placeholder="Show Date"
					showTime
					timeOptions={{ placeholder: "Show Time" }}
					timezone={timezone}
					validate={startAtRequired}
				/>
			) : null}

			{settings.showWhen === "before" ? (
				<div>
					<InputLabel label="Start at" description={atMessageString(true)} />
					<div style={{ display: "flex" }}>
						<Field
							name={`${fieldPath}.show.days`}
							component={InputNumberField}
							placeholder="Days"
							allowsNullValue
							min={0}
							style={{ marginRight: 8 }}
						/>
						<Field
							name={`${fieldPath}.show.hours`}
							component={InputNumberField}
							placeholder="Hours"
							allowsNullValue
							min={0}
							max={23.99}
						/>
					</div>
				</div>
			) : null}
			<Field
				mode="multiple"
				name={`${fieldPath}.availableFor`}
				label="Ticket types available for"
				description="Leave blank for all tickets"
				component={SelectField}
				options={ticketOptions}
			/>
			<Field
				mode="multiple"
				name={`${fieldPath}.datesAvailableFor`}
				label="Event dates available for"
				description="Leave blank for all dates"
				component={SelectField}
				options={datesOptions}
			/>
		</div>
	);
};

export default connect((state) => {
	return {
		currentEvent: state.currentEvent
	};
}, {})(Settings);
