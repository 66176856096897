import iconReference from "../../services/IconReference";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3 } from "../text/Text";
import NoResultsInCurrency from "./NoResultsInCurrency";

const emptyIcon = iconReference["no_data"];

const NoEventsFound = ({ title, img, action, actionTxt, locationSelection }) => {
	const noResultsImg = img ? img : { src: emptyIcon, alt: "No Results" };
	return (
		<div style={{ textAlign: "center", marginTop: 28 }}>
			<img src={noResultsImg.src} alt={noResultsImg.alt} />
			<NoResultsInCurrency locationSelection={locationSelection}>
				<div>
					<H3>{title}</H3>

					{action ? (
						<LegacyButton type="primary" onClick={action} ariaLabel={actionTxt} style={{ margin: "0 auto" }}>
							{actionTxt}
						</LegacyButton>
					) : null}
				</div>
			</NoResultsInCurrency>
		</div>
	);
};

export default NoEventsFound;
