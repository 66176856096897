import { Modal } from "@/ui/antd";
import { connect } from "react-redux";
import Smile from "../../assets/logos/faces/Smile";
import { H3, P } from "../../components/text/Text";
import { closeFeeAppliedModal } from "../../state/actions/userActions";

const FeeAppliedModal = ({ visible, closeFeeAppliedModal }) => {
	return (
		<Modal
			open={visible}
			destroyOnClose
			onCancel={closeFeeAppliedModal}
			footer={null}
			width={600}
			height={202}
			bodyStyle={{
				paddingTop: 38,
				paddingBottom: 32,
				textAlign: "center",
				margin: "0px auto"
			}}
		>
			<Smile color="#353337" style={{ width: 36, marginBottom: 5 }} />
			<H3 style={{ marginBottom: 16 }}>
				Our not-for-profit / schools pricing has now been applied to your account{" "}
				<span role="img" aria-label="Ok hand symbol">
					👌
				</span>
			</H3>
			<P style={{ margin: "0px auto" }}>
				We love to support fellow not-for-profits. Humanitix itself is a charity and we use our fees to fund education
				for disadvantaged kids. One of the team will be in touch shortly to confirm a few details. In the meantime you
				can get started on creating beautiful events{" "}
				<span role="img" aria-label="smiley face">
					🙂
				</span>
			</P>
		</Modal>
	);
};

export default connect(
	(state) => ({
		visible: state.currentUser.showFeeAppliedModal
	}),
	{ closeFeeAppliedModal }
)(FeeAppliedModal);
