"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  removeHTML: () => removeHTML,
  removeURLStrings: () => removeURLStrings,
  sanitise: () => sanitise,
  sanitiseTemplatedHtml: () => sanitiseTemplatedHtml,
  sanitize: () => sanitize
});
module.exports = __toCommonJS(src_exports);
var import_sanitize_html = __toESM(require("sanitize-html"));

// src/injectNoFollow.ts
var injectNoFollow = /* @__PURE__ */ __name((html) => {
  const searchStr = '<a rel="nofollow" ';
  return html.replace(/<a /g, searchStr);
}, "injectNoFollow");

// src/index.ts
function sanitise(html, extraAllowedTags) {
  if (!html) {
    return void 0;
  }
  return sanitize(html, extraAllowedTags);
}
__name(sanitise, "sanitise");
function sanitize(html, extraAllowedTags = []) {
  const sanitizedHtml = (0, import_sanitize_html.default)(html, {
    allowedTags: [
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "a",
      "ul",
      "ol",
      "nl",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "del",
      "strike",
      "span",
      "code",
      "hr",
      "br",
      "div",
      "table",
      "thead",
      "caption",
      "tbody",
      "tr",
      "th",
      "td",
      "pre",
      "img",
      "iframe",
      "figure",
      "figcaption",
      ...extraAllowedTags
    ],
    allowedAttributes: {
      a: [
        "href",
        "name",
        "target",
        "data-children-count"
      ],
      img: [
        "src",
        "width",
        "height",
        "alt",
        "data-children-count"
      ],
      iframe: [
        "src",
        "width",
        "height",
        "alt",
        "allowfullscreen",
        "data-children-count"
      ],
      figure: [
        "style",
        "data-children-count"
      ],
      p: [
        "style",
        "data-children-count"
      ],
      span: [
        "style",
        "data-children-count"
      ],
      div: [
        "style",
        "data-children-count"
      ],
      h3: [
        "style",
        "data-children-count"
      ],
      h4: [
        "style",
        "data-children-count"
      ],
      h5: [
        "style",
        "data-children-count"
      ]
    },
    allowedIframeHostnames: [
      "www.youtube.com",
      "open.spotify.com",
      "bandcamp.com",
      "w.soundcloud.com",
      "www.airmeet.com",
      "player.vimeo.com",
      "embed.ted.com",
      "events.humanitix.com",
      "fast.wistia.net",
      "www.facebook.com",
      "www.google.com",
      "outlook.office365.com",
      "web.microsoftstream.com",
      "biteable.com",
      "giphy.com",
      "players.brightcove.net",
      "streamable.com",
      "sandbox.elfsightcdn.com",
      "www.podbean.com",
      "omny.fm",
      "cdn.embedly.com",
      "player.captivate.fm",
      "drive.google.com",
      "www.dropbox.com",
      "brandfolder.com",
      "airtable.com",
      "killerplayer.com",
      "videopress.com",
      "osasmediaflix.dotcompal.com",
      "intermedia.wistia.com",
      "rumble.com",
      "vimeo.com",
      "www.youtube-nocookie.com",
      "www.canva.com"
    ]
  });
  const injectedNoFollow = injectNoFollow(sanitizedHtml);
  return injectNoFollow(injectedNoFollow);
}
__name(sanitize, "sanitize");
function removeHTML(string) {
  return (0, import_sanitize_html.default)(string, {
    allowedTags: [],
    allowedAttributes: {}
  });
}
__name(removeHTML, "removeHTML");
function removeURLStrings(str) {
  return str.replace(/((http|ftp|https):\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gim, "").trim();
}
__name(removeURLStrings, "removeURLStrings");
function sanitiseTemplatedHtml(html, reapplyStyleTag = true) {
  const style = reapplyStyleTag ? html.match(/<style>([\s\S]*?)<\/style>/g) : "";
  return style + (0, import_sanitize_html.default)(html, {
    allowedAttributes: {
      "*": [
        "class"
      ],
      img: [
        "src",
        "srcset",
        "alt",
        "title",
        "width",
        "height"
      ],
      a: [
        "href",
        "target"
      ]
    },
    allowedSchemes: [
      "data",
      "https",
      ...process.env.NODE_ENV === "local" ? [
        "http"
      ] : []
    ],
    allowedTags: import_sanitize_html.default.defaults.allowedTags.concat([
      "img"
    ])
  });
}
__name(sanitiseTemplatedHtml, "sanitiseTemplatedHtml");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  removeHTML,
  removeURLStrings,
  sanitise,
  sanitiseTemplatedHtml,
  sanitize
});
