import { styleTokens } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

export const GlobalStylesPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Tokens</b>
			{Object.entries(styleTokens).map(([key, value]) => (
				<Flex key={key} gap="sm">
					<div>{key}</div>
					{value.startsWith("#") ? (
						<div
							style={{
								backgroundColor: value,
								border: "1px solid #e8e8e8",
								borderRadius: styleTokens["rounded-sm"],
								height: "16px",
								width: "16px"
							}}
						/>
					) : null}
					<div>{value}</div>
				</Flex>
			))}
		</Flex>
	);
};
