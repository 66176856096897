import axios from "./AxiosWrapper";

export async function loadEventPayoutPage(eventId) {
	const response = await axios.get(`events/payouts/${eventId}`);
	return response.data;
}

export async function calculateTrackRecord(eventId) {
	const response = await axios.post(`events/payouts/${eventId}/calculate-track-record`);
	return response.data;
}

export async function calculateFraudCheck(eventId) {
	const response = await axios.post(`events/payouts/${eventId}/calculate-fraud-check`);
	return response.data;
}

export async function toggleAutoPayoutForEvent(eventId) {
	const response = await axios.patch(`events/payouts/${eventId}/toggle-auto-payout`);
	return response.data;
}

export async function toggleFlaggedForEvent(eventId) {
	const response = await axios.patch(`events/payouts/${eventId}/toggle-flagged`);
	return response.data;
}

export async function addNote(eventId, note) {
	const response = await axios.post(`events/payouts/${eventId}/add-note`, { note });
	return response.data;
}

export async function deleteNote(eventId, noteId) {
	const response = await axios.delete(`events/payouts/${eventId}/delete-note/${noteId}`);
	return response.data;
}

export async function updateStatus(eventId, eventDateId, status) {
	const response = await axios.patch(`events/payouts/${eventId}/update-status/${eventDateId}/${status}`);
	return response.data;
}

export async function addPayments(eventId, paymentsData) {
	const response = await axios.post(`events/payouts/${eventId}/add-payments`, { paymentsData });
	return response.data;
}

export async function addSelfServePayments(eventId, paymentsData) {
	const response = await axios.post(`events/payouts/${eventId}/add-self-serve-payments`, { paymentsData });
	return response.data;
}

export async function addCredit(eventId, creditData) {
	const response = await axios.post(`events/payouts/${eventId}/add-credit`, { creditData });
	return response.data;
}

export async function addAdjustment(eventId, adjustmentData) {
	const response = await axios.post(`events/payouts/${eventId}/add-adjustment`, { adjustmentData });
	return response.data;
}

//payment is ambiguous here, however credits, adjustments and payments are all under
//the payments schema, beware if you edit lmao
export async function editPayment(eventId, paymentId, paymentData) {
	const response = await axios.patch(`events/payouts/${eventId}/edit-payment/${paymentId}`, { paymentData });
	return response.data;
}

export async function deletePayment(eventId, paymentId, reverseData) {
	const response = await axios.delete(`events/payouts/${eventId}/delete-payment/${paymentId}`, {
		data: { reverseData }
	});
	return response.data;
}

export async function downloadPaymentReceipt(eventId, eventDateId, paymentId) {
	const response = await axios.get(`events/payouts/${eventId}/download-payment-receipt/${eventDateId}/${paymentId}`, {
		responseType: "blob"
	});
	return response.data;
}

export async function toggleFraudCheck(eventId, key, setTo) {
	const response = await axios.patch(`events/payouts/${eventId}/toggle-fraud-check/${key}/${setTo}`);
	return response.data;
}
