import EmbeddedButtonGif from "@/assets/widgets/embedded_button.gif";
import EmbeddedButtonPng from "@/assets/widgets/embedded_button.png";
import EmbeddedCheckoutGif from "@/assets/widgets/embedded_checkout.gif";
import EmbeddedCheckoutPng from "@/assets/widgets/embedded_checkout.png";
import { ContentBlock } from "@/components/AppBlocks";
import { H1, P } from "@/components/text/Text";
import { CheckoutWidgetBuilderConfig } from "@/events/event/widgets/CheckoutWidgetBuilderConfig";
import { CheckoutWidgetBuilderPreview } from "@/events/event/widgets/CheckoutWidgetBuilderPreview";
import { PopupWidgetBuilderConfig } from "@/events/event/widgets/PopupWidgetBuilderConfig";
import { PopupWidgetBuilderPreview } from "@/events/event/widgets/PopupWidgetBuilderPreview";
import { WidgetBuilderModal } from "@/events/event/widgets/WidgetBuilderModal";
import { WidgetCard } from "@/events/event/widgets/WidgetCard";
import { useAppSelector } from "@/state/hooks";
import { Grid } from "@/ui";
import { PreviewMode } from "@/ui";
import { FC, useState } from "react";
import { useNavigate } from "react-router";

type ModalType = "createPopup" | "createCheckout";

export const WidgetsSection: FC = () => {
	const navigate = useNavigate();
	const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
	const [previewMode, setPreviewMode] = useState<PreviewMode>("desktop");
	const currentEvent = useAppSelector((state) => state.currentEvent);

	const handleCloseModal = () => setCurrentModal(null);

	const handleOpenModal = (modal: ModalType) => () => setCurrentModal(modal);

	const handleRedirectToLegacyWidgets = () => {
		navigate(`/console/my-events/${currentEvent?.event?._id}/legacy-widgets`);
	};

	return (
		<section>
			<H1>Embedded widgets</H1>
			<P style={{ maxWidth: "100%" }}>
				Add our widgets to your site and give your attendees the ability to book tickets directly.
			</P>
			<ContentBlock>
				<Grid columnMin="240px">
					<WidgetCard
						buttonText="Create embedded button"
						description="Embed a pop-up within an element on your site where customers can buy tickets."
						imgSrc={EmbeddedButtonPng}
						imgSrcOnHover={EmbeddedButtonGif}
						onCTAClick={handleOpenModal("createPopup")}
						title="Pop-up"
					/>
					<WidgetCard
						buttonText="Create embedded checkout"
						description="List of tickets customers can buy from your website."
						imgSrc={EmbeddedCheckoutPng}
						imgSrcOnHover={EmbeddedCheckoutGif}
						onCTAClick={handleOpenModal("createCheckout")}
						title="Embedded Checkout"
					/>
					{/* 
					TODO: Once Collections feature is implemented
					<WidgetCard
						buttonText="Set up Collections"
						description="Looking to embed a gallery of events on your page instead?"
						onCTAClick={handleRedirectToCollections}
					/> */}
					<WidgetCard
						buttonText="Other widgets"
						description="Looking for Event listing and Custom search widgets? You'll find these here."
						onCTAClick={handleRedirectToLegacyWidgets}
					/>
				</Grid>
			</ContentBlock>

			<WidgetBuilderModal
				configContents={<PopupWidgetBuilderConfig />}
				isOpen={currentModal === "createPopup"}
				onCancel={handleCloseModal}
				previewContents={<PopupWidgetBuilderPreview />}
				previewMode={previewMode}
				setPreviewMode={setPreviewMode}
				title="Pop-up widget"
			/>

			<WidgetBuilderModal
				configContents={<CheckoutWidgetBuilderConfig />}
				isOpen={currentModal === "createCheckout"}
				noGutters
				onCancel={handleCloseModal}
				previewContents={<CheckoutWidgetBuilderPreview />}
				previewMode={previewMode}
				setPreviewMode={setPreviewMode}
				title="Embedded Checkout"
			/>
		</section>
	);
};
