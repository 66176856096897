import { CodeBlock, CopyButton } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type CopyButtonProps = Partial<ButtonProps> & {
	noIcon?: boolean;
	toCopy: string;
};`;

const sample = `import { CopyButton } from "@/ui";

<CopyButton toCopy="Joel: {sCheeEeeMA}\\nJake: lol" />`;

export const CopyButtonPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<CopyButton toCopy={`Joel: {sCheeEeeMA}\nJake: lol`} />
			<textarea
				style={{
					border: "2px solid #e8e8e8",
					borderRadius: "4px",
					height: "100px",
					marginBottom: "16px",
					padding: "8px",
					width: "100%"
				}}
			/>
			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
