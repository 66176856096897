import { css } from "@emotion/css";
import { Input, Select } from "antd";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import React, { FC, useEffect, useState } from "react";
import countries from "../../utils/countryCodes.json";

const { Option } = Select;

const countrySelect = css({
	transition: "width 0.2s ease-in-out",
	"&.ant-select": {
		width: 80,
		minWidth: "unset"
	},
	"&.ant-select-open": {
		width: "200px !important"
	}
});

interface SelectBeforeProps {
	country: string;
	onChange: (value: string) => void;
}

const SelectBefore: React.FC<SelectBeforeProps> = ({ country, onChange }) => {
	return (
		<Select
			showSearch
			value={country}
			onChange={onChange}
			optionLabelProp="label"
			optionFilterProp="children"
			popupMatchSelectWidth={false}
			className={countrySelect}
		>
			{countries.map((country) => (
				<Option
					value={country.code}
					label={country.code}
					key={country.code}
				>{`${country.code} ${country.country}`}</Option>
			))}
		</Select>
	);
};

type InternationalPhoneFieldProps = {
	value?: string;
	onChange?: (value: string) => void;
	defaultCountry?: string;
	placeholder?: string;
	prefix?: React.ReactNode;
	autoFocus?: boolean;
	disabled?: boolean;
	style?: React.CSSProperties;
	maxLength?: number;
	size?: "large" | "small";
	dataCy?: string;
};

export const InternationalPhoneField: FC<InternationalPhoneFieldProps> = ({
	value = "",
	onChange,
	defaultCountry = "AU",
	placeholder,
	prefix,
	autoFocus,
	disabled,
	style,
	maxLength = 10000,
	size,
	dataCy
}) => {
	const [country, setCountry] = useState(defaultCountry);
	const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

	useEffect(() => {
		if (!selectedCountry) {
			setCountry(defaultCountry);
		}
	}, [defaultCountry, selectedCountry]);

	const handleCountryChange = (country: string) => {
		setCountry(country);
		setSelectedCountry(country);

		if (value) {
			const orgPhoneNumber = parsePhoneNumberFromString(String(value));
			const nationalNumber = orgPhoneNumber ? orgPhoneNumber.nationalNumber : String(value);
			const phoneNumber = parsePhoneNumberFromString(nationalNumber, country as CountryCode);
			const newValue = phoneNumber ? phoneNumber.formatInternational() : value;
			onChange?.(newValue);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		onChange?.(inputValue);
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		const phoneNumber = value ? parsePhoneNumberFromString(inputValue, country as CountryCode) : "";
		const newValue = phoneNumber ? phoneNumber.formatInternational() : inputValue;
		onChange?.(newValue);
	};

	return (
		<Input
			addonBefore={<SelectBefore country={selectedCountry || defaultCountry} onChange={handleCountryChange} />}
			prefix={prefix}
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			style={style}
			disabled={disabled}
			size={size}
			maxLength={maxLength}
			autoFocus={autoFocus}
			data-cy={dataCy}
			placeholder={placeholder}
		/>
	);
};
