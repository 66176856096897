import {
	BULK_UPLOAD_ERROR,
	BULK_UPLOAD_REQUEST,
	BULK_UPLOAD_RESET,
	BULK_UPLOAD_SUCCESS
} from "../actions/bulkUploadActions";
import {
	INVOICE_REFUND_UNPAID_ERROR,
	INVOICE_REFUND_UNPAID_REQUEST,
	INVOICE_REFUND_UNPAID_SUCCESS,
	INVOICE_UPDATE_ERROR,
	INVOICE_UPDATE_REQUEST,
	INVOICE_UPDATE_SUCCESS
} from "../actions/invoiceActions";
import {
	CLEAR_REFUND,
	CLEAR_REQUEST_REFUND,
	CLEAR_SWAP,
	CLOSE_RESEND_ORDER,
	CLOSE_SWAP_MODAL,
	EVENT_DATE_CAPACITY_CLEAR,
	EVENT_DATE_CAPACITY_ERROR,
	EVENT_DATE_CAPACITY_REQUEST,
	EVENT_DATE_CAPACITY_SUCCESS,
	FIND_ANSWERED_ADDITIONAL_QUESTIONS_ERROR,
	FIND_ANSWERED_ADDITIONAL_QUESTIONS_REQUEST,
	FIND_ANSWERED_ADDITIONAL_QUESTIONS_SUCCESS,
	FIND_ORDER_ERROR,
	FIND_ORDER_REQUEST,
	FIND_ORDER_SUCCESS,
	GET_GLOBAL_REFUND_AVAILABLE_FUNDS_ERROR,
	GET_GLOBAL_REFUND_AVAILABLE_FUNDS_REQUEST,
	GET_GLOBAL_REFUND_AVAILABLE_FUNDS_SUCCESS,
	GET_ORDER_CSV_ERROR,
	GET_ORDER_CSV_REQUEST,
	GET_ORDER_CSV_SUCCESS,
	GET_ORDER_ERROR,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_REFUND_AVAILABLE_FUNDS_ERROR,
	GET_REFUND_AVAILABLE_FUNDS_REQUEST,
	GET_REFUND_AVAILABLE_FUNDS_SUCCESS,
	INVOICE_DOWNLOAD_ERROR,
	INVOICE_DOWNLOAD_REQUEST,
	INVOICE_DOWNLOAD_SUCCESS,
	OPEN_RESEND_ORDER,
	OPEN_SWAP_MODAL_REQUEST,
	OPEN_SWAP_MODAL_SUCCESS,
	REFUND_ORDER_ERROR,
	REFUND_ORDER_REQUEST,
	REFUND_ORDER_SUCCESS,
	REQUEST_REFUND_ORDER_ERROR,
	REQUEST_REFUND_ORDER_REQUEST,
	REQUEST_REFUND_ORDER_SUCCESS,
	SAVE_ORDER_ERROR,
	SAVE_ORDER_REQUEST,
	SAVE_ORDER_SUCCESS,
	SEND_ATTENDEE_TICKETS_REQUEST,
	SEND_ATTENDEE_TICKETS_COMPLETE,
	SEND_ORDER_ERROR,
	SEND_ORDER_REQUEST,
	SEND_ORDER_SUCCESS,
	SWAP_TICKET_ERROR,
	SWAP_TICKET_REQUEST,
	SWAP_TICKET_SUCCESS
} from "../actions/orderActions";

const initialState = {
	order: {},
	soldTickets: [],
	orders: [],
	resendOrder: {
		orderEmail: null,
		orderId: null,
		modalOpen: false
	},
	refundModalOpen: {
		open: false,
		loading: false,
		error: false
	},
	bulkUpload: {
		loading: false,
		error: false,
		success: false
	},
	fundsAvailable: {
		event: 0,
		order: 0,
		tickets: {},
		merchandise: {},
		donation: 0
	},
	refund: {
		loading: false,
		error: false
	},
	refundRequest: {
		loading: false,
		error: false
	},
	actionsLoading: [],
	loading: false,
	error: false,
	saved: false,

	csv: {
		loading: false,
		error: false
	},
	swapModalOpen: {
		open: false,
		loading: false,
		error: false
	},
	swapState: {
		loading: false,
		error: false
	}
};
export default (state = initialState, action) => {
	switch (action.type) {
		case OPEN_RESEND_ORDER:
			return {
				...state,
				resendOrder: {
					orderEmail: action.order.email,
					orderId: action.order._id,
					modalOpen: true
				}
			};
		case CLOSE_RESEND_ORDER:
			return {
				...state,
				resendOrder: {
					modalOpen: false
				}
			};
		case GET_ORDER_ERROR || SAVE_ORDER_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case GET_ORDER_REQUEST:
			return {
				...state,
				order: null,
				loading: true,
				error: false
			};
		case GET_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				order: action.order
			};
		case GET_GLOBAL_REFUND_AVAILABLE_FUNDS_ERROR:
			return {
				...state,
				loading: false,
				globalFundsAvailable: 0,
				error: action.error
			};
		case GET_GLOBAL_REFUND_AVAILABLE_FUNDS_REQUEST:
			return {
				...state,
				loading: true,
				globalFundsAvailable: 0
			};
		case GET_GLOBAL_REFUND_AVAILABLE_FUNDS_SUCCESS:
			return {
				...state,
				loading: false,
				globalFundsAvailable: action.globalFundsAvailable
			};
		case GET_REFUND_AVAILABLE_FUNDS_ERROR:
			return {
				...state,
				loading: false,
				fundsAvailable: {
					event: 0,
					order: 0,
					tickets: {},
					merchandise: {},
					donation: 0
				},
				error: action.error
			};
		case GET_REFUND_AVAILABLE_FUNDS_REQUEST:
			return {
				...state,
				loading: true,
				fundsAvailable: {
					event: 0,
					order: 0,
					tickets: {},
					merchandise: {},
					donation: 0
				}
			};
		case GET_REFUND_AVAILABLE_FUNDS_SUCCESS:
			return {
				...state,
				loading: false,
				fundsAvailable: action.fundsAvailable
			};
		case CLEAR_REFUND:
			return {
				...state,
				refund: {
					loading: false,
					error: false
				}
			};
		case REFUND_ORDER_REQUEST:
			return {
				...state,
				refund: {
					loading: true,
					error: false
				}
			};
		case REFUND_ORDER_SUCCESS:
			return {
				...state,
				refund: {
					loading: false,
					error: false
				}
			};
		case REFUND_ORDER_ERROR: {
			let message = (
				<>
					<p>
						This means the ticket buyer's card is no longer valid (blocked, expired, cancelled etc) and their card
						issuer will not accept a credit (refund).
					</p>
					<p>Please contact the ticket buyer directly to confirm and/or rectify their card status.</p>
					<p>
						Humanitix can only process refunds to the original card used. If their card is no longer valid, you must
						arrange a refund outside Humanitix. E.g. via a direct bank transfer.
					</p>
					<p>These ticket sales will still be paid to you and other ticket revenues.</p>
				</>
			);
			if (action.error.message.includes("TillTerminal refund not supported")) {
				message = (
					<>
						<p>
							A Humanitix Admin must process box office refunds. Please get in touch with the team via
							<a href="mailto:hello@hx.com"> hello@hx.com</a> and include the following:
						</p>
						<ul>
							<li>the URL of this current page</li>
							<li>detail of which tickets and the $value you would like to refund</li>
							<li>a custom message to be included in the confirmation email (optional)</li>
						</ul>
						<br />
						<p>Alternatively, you could contact the Buyer directly and arrange a bank transfer</p>
					</>
				);
			} else if (action.error.message.includes("Cannot refund tickets that have a resale")) {
				message = <p>{action.error.message}</p>;
			} else if (action.error.message.includes("Cannot refund orders that are paid via Invoice link.")) {
				message = <p>{action.error.message}</p>;
			}
			return {
				...state,
				refund: {
					loading: false,
					error: {
						title: "Refund cannot be completed",
						message
					}
				}
			};
		}
		case CLEAR_REQUEST_REFUND:
			return {
				...state,
				refund: {
					loading: false,
					error: false
				}
			};
		case REQUEST_REFUND_ORDER_REQUEST:
			return {
				...state,
				refundRequest: {
					loading: true,
					error: false
				}
			};
		case REQUEST_REFUND_ORDER_SUCCESS:
			return {
				...state,
				refundRequest: {
					loading: false,
					error: false
				}
			};
		case REQUEST_REFUND_ORDER_ERROR:
			return {
				...state,
				refundRequest: {
					loading: false,
					error: action.error
				}
			};
		case SAVE_ORDER_REQUEST:
			return {
				...state,
				loading: true,
				error: false
			};
		case SAVE_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				saved: true,
				order: action.order
			};
		case SEND_ATTENDEE_TICKETS_REQUEST:
		case INVOICE_UPDATE_REQUEST:
		case SEND_ORDER_REQUEST:
			return {
				...state,
				actionsLoading: state.actionsLoading.concat([action.orderId])
			};

		case FIND_ORDER_REQUEST:
			return {
				...state,
				orders: [],
				loading: true,
				error: false
			};
		case FIND_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				orders: action.orders
			};
		case FIND_ORDER_ERROR:
			return {
				...state,
				loading: false,
				orders: [],
				error: action.error
			};
		case FIND_ANSWERED_ADDITIONAL_QUESTIONS_REQUEST:
			return {
				...state,
				answeredQuestions: [],
				loading: true,
				error: false
			};
		case FIND_ANSWERED_ADDITIONAL_QUESTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				answeredQuestions: action.answeredQuestions
			};
		case FIND_ANSWERED_ADDITIONAL_QUESTIONS_ERROR:
			return {
				...state,
				loading: false,
				answeredQuestions: [],
				error: action.error
			};
		case INVOICE_UPDATE_SUCCESS:
		case SEND_ORDER_SUCCESS:
		case INVOICE_UPDATE_ERROR:
		case SEND_ATTENDEE_TICKETS_COMPLETE:
		case SEND_ORDER_ERROR: {
			const order = action.order ? action.order : state.order;
			return {
				...state,
				order,
				actionsLoading: state.actionsLoading.filter((id) => id !== action.orderId)
			};
		}
		case GET_ORDER_CSV_ERROR:
			return {
				...state,
				csv: {
					loading: false,
					error: true
				}
			};
		case GET_ORDER_CSV_SUCCESS:
			return {
				...state,
				csv: {
					loading: false,
					error: false
				}
			};
		case GET_ORDER_CSV_REQUEST:
			return {
				...state,
				csv: {
					loading: true,
					error: false
				}
			};
		case BULK_UPLOAD_REQUEST:
			return {
				...state,
				bulkUpload: {
					loading: true,
					error: false,
					success: false
				}
			};
		case BULK_UPLOAD_RESET:
			return {
				...state,
				bulkUpload: {
					...initialState.bulkUpload
				}
			};
		case BULK_UPLOAD_ERROR:
			return {
				...state,
				bulkUpload: {
					loading: false,
					error: action.error,
					success: false
				}
			};
		case BULK_UPLOAD_SUCCESS:
			return {
				...state,
				bulkUpload: {
					loading: false,
					error: false,
					success: true
				}
			};
		case OPEN_SWAP_MODAL_REQUEST:
			return {
				...state,
				swapModalOpen: {
					open: false,
					loading: true,
					error: false
				}
			};
		case OPEN_SWAP_MODAL_SUCCESS:
			return {
				...state,
				swapModalOpen: {
					open: true,
					loading: false,
					error: false
				}
			};
		case CLOSE_SWAP_MODAL:
			return {
				...state,
				swapModalOpen: {
					...state.swapModalOpen,
					open: false
				}
			};
		case SWAP_TICKET_REQUEST:
			return {
				...state,
				swapState: {
					loading: true,
					error: false
				}
			};
		case SWAP_TICKET_SUCCESS:
			return {
				...state,
				swapState: {
					loading: false,
					error: false
				},
				order: action.order
			};
		case SWAP_TICKET_ERROR:
			return {
				...state,
				swapState: {
					loading: false,
					error: action.error
				}
			};

		case EVENT_DATE_CAPACITY_REQUEST:
			return {
				...state,
				swapState: {
					loading: true,
					error: false
				}
			};
		case EVENT_DATE_CAPACITY_SUCCESS:
			return {
				...state,
				swapState: {
					...state.swapState,
					loading: false,
					eventDateCapacity: action.eventDateCapacity
				}
			};
		case EVENT_DATE_CAPACITY_ERROR:
			return {
				...state,
				swapState: {
					loading: false,
					error: action.error
				}
			};

		case EVENT_DATE_CAPACITY_CLEAR:
			return {
				...state,
				swapState: {
					loading: false,
					error: false
				}
			};

		case CLEAR_SWAP:
			return {
				...state,
				swapState: {
					loading: false,
					error: false
				}
			};
		case INVOICE_DOWNLOAD_REQUEST:
			return {
				...state,
				actionsLoading: state.actionsLoading.concat([action.orderId])
			};
		case INVOICE_DOWNLOAD_SUCCESS:
		case INVOICE_DOWNLOAD_ERROR:
			return {
				...state,
				actionsLoading: state.actionsLoading.filter((id) => id !== action.orderId)
			};
		case INVOICE_REFUND_UNPAID_ERROR:
			return {
				...state,
				actionsLoading: state.actionsLoading.filter((id) => id !== action.orderId)
			};
		case INVOICE_REFUND_UNPAID_SUCCESS:
			return {
				...state,
				actionsLoading: state.actionsLoading.filter((id) => id !== action.orderId)
			};
		case INVOICE_REFUND_UNPAID_REQUEST:
			return {
				...state,
				actionsLoading: state.actionsLoading.concat([action.orderId])
			};
		default:
			return state;
	}
};
