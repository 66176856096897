import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";
import withReportProvider from "@/hoc/withReportProvider";

class Discounts extends Component {
	render() {
		const { results, loading, error, count, page, csvLoading, hasLoadedOnce } = this.props.reportState;
		const { load, loadPage, downloadCsv, local } = this.props;

		const columns = [
			...(!local
				? [
						{
							title: "Event Name",
							dataIndex: "eventName",
							key: "eventName",
							render: (eventName, record) => {
								return <Link to={`/console/my-events/${record.eventId}`}>{eventName}</Link>;
							}
						}
				  ]
				: []),
			{
				title: "Code",
				dataIndex: "code",
				key: "code",
				render: (code, record) => {
					return (
						<Link to={`/console/my-events/${record.eventId}/attendees?query=discountcode%253A${code}`}>{code}</Link>
					);
				}
			},
			{
				title: "Quantity",
				dataIndex: "quantity",
				key: "quantity"
			},
			{
				title: "Used",
				dataIndex: "used",
				key: "used"
			},
			{
				title: "Cancelled",
				dataIndex: "cancelled",
				key: "cancelled"
			},
			{
				title: "Remaining",
				dataIndex: "remaining",
				key: "remaining"
			},
			{
				title: "Discounted amount",
				dataIndex: "discount",
				key: "discount"
			}
		];

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						searchPlaceholder="Discount code"
						selectEvent={!local}
						getReportButtonText="Get report"
					/>
					<ReportContent>
						<div
							style={{
								marginBottom: 16,
								textAlign: "right",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							{results.length > 0 && (
								<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
									{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
								</LegacyButton>
							)}
						</div>
						<Table
							columns={columns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={{
								showSizeChanger: false,
								total: count,
								current: page,
								onChange: loadPage
							}}
						/>
					</ReportContent>
				</ContentBlock>
			</LoadErrorView>
		);
	}
}

export const EventDiscountCodesReport = withReportProvider(Discounts, "Discount codes", "discount", null, true);
export const GlobalDiscountCodesReport = withReportProvider(Discounts, "Discounts", "discount");
