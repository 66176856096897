import { css } from "@emotion/css";
import { Alert, Tabs } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "../../components/AppBlocks";
import { ColourField, ImageUploadField, LegacyInputField, TextAreaField } from "../../components/Fields";
import FormBar from "../../components/FormBar";
import LoadErrorView from "../../components/LoadErrorView";
import Page from "../../components/Page";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "../../components/text/Text";
import { getGiftCardPage, updateGiftCardPage } from "../../state/actions/giftCardActions";
import { getTextColor } from "../../utils/Colours";
import { getIsGiftcardFeatureEnabled } from "../../utils/GiftcardUtilties";
import { required } from "../../utils/Validators";
import PagePreview from "./PagePreview";
import PageUrl from "./PageUrl";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const giftCardPageStyling = css({
	display: "flex",
	flexWrap: "wrap",
	"@media(max-width:600px)": {
		flexWrap: "wrap"
	}
});

const formInputHalfScreen = css({
	width: "50%",
	"@media(max-width:600px)": {
		width: "100%"
	}
});

const previewStyle = css({
	flexGrow: 1,
	"@media(max-width:600px)": {
		width: 268
	}
});

const { TabPane } = Tabs;
const formName = "giftCardPageForm";
const nameRequired = required("Please input a gift card name");
const companyNameRequired = required("Please input a company name");

class GiftCardPageForm extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.isUpdate = props.location.pathname.includes("/update");
	}
	componentWillMount() {
		const { currentUser, getGiftCardPage } = this.props;
		const userId = currentUser.user.id;
		getGiftCardPage(userId);
	}

	save(data) {
		const { updateGiftCardPage, currentUser, giftCard } = this.props;
		const { giftCardPage } = giftCard;
		let giftCardId = "new";
		if (giftCardPage && giftCardPage._id) {
			giftCardId = giftCardPage._id;
		}
		const userId = currentUser.user.id;
		data.style.headerTextColor = getTextColor(data.style.headerColor);
		updateGiftCardPage(userId, giftCardId, data);
	}

	onCancel() {
		this.props.navigate("/console/promote/gift-cards");
	}

	render() {
		const { giftCard, style, companyLogo, slug, isExisting, isGiftCardFeatureEnabled } = this.props;
		const { loading, error } = giftCard;
		if (!isGiftCardFeatureEnabled) {
			return (
				<Page pageName="Gift cards">
					<ContentBlock>
						<Alert
							showIcon
							message="Sorry, gift cards are not available in this region yet"
							description="Please select a different region"
							type="warning"
						/>
					</ContentBlock>
				</Page>
			);
		}
		return (
			<Page pageName="Gift cards">
				<Form
					loading={loading}
					error={error}
					save={this.save}
					onCancel={this.onCancel}
					style={style}
					companyLogo={companyLogo}
					slug={slug}
					isExisting={isExisting}
				/>
			</Page>
		);
	}
}

class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colourPickerOpen: false
		};
	}

	onColourPickerClicked = () => {
		this.setState({ colourPickerOpen: !this.state.colourPickerOpen });
	};

	render() {
		const { save, handleSubmit, loading, error, onCancel, slug, isExisting, style, companyLogo, dispatch } = this.props;
		const { colourPickerOpen } = this.state;
		return (
			<LoadErrorView loading={loading} error={error}>
				<form onSubmit={handleSubmit(save)} id={formName}>
					<ContentBlock>
						<Tabs defaultActiveKey="1" style={{ minHeight: colourPickerOpen ? 900 : 0 }}>
							<TabPane tab="Settings" key="1">
								<GiftCardPageSettings slug={slug} isExisting={isExisting} />
							</TabPane>
							<TabPane tab="Styling" key="2">
								<GiftCardPageStyling
									style={style}
									onColourPickerClicked={this.onColourPickerClicked}
									companyLogo={companyLogo}
									dispatch={dispatch}
								/>
							</TabPane>
						</Tabs>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "left"
							}}
							type="secondary"
							id="cancelButton"
							size="large"
							onClick={onCancel}
							ariaLabel="Close"
						>
							Close
						</LegacyButton>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							size="large"
							ariaLabel="Save"
						>
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const GiftCardPageSettings = ({ slug, isExisting }) => {
	return (
		<div>
			{isExisting ? <PageUrl slug={slug} /> : null}
			<div className={formInputHalfScreen}>
				<Field
					name="name"
					label="Gift cards page name"
					required
					description="This name will appear at the top right corner of the gift card page"
					component={LegacyInputField}
					validate={nameRequired}
				/>
				<Field
					name="companyName"
					label="Company Name"
					required
					description="The name of the company who the gift card is from"
					component={LegacyInputField}
					validate={companyNameRequired}
				/>
				<Field
					name="companyLogo"
					label="Company Logo"
					component={ImageUploadField}
					uploadLabel="Upload image"
					aspectRatio={1}
					style={{ width: "100%" }}
				/>
			</div>
			<Field name="description" label="Description" component={TextAreaField} style={{ height: 72 }} />
		</div>
	);
};

const updatePrimaryTextColor = (dispatch, e, primaryColor, field) => {
	const primaryTextColor = getTextColor(primaryColor);
	dispatch(change(formName, field, primaryTextColor));
};

const GiftCardPageStyling = ({ style, onColourPickerClicked, dispatch }) => {
	return (
		<div className={giftCardPageStyling}>
			<div>
				<P>Personalize your gift card page. Choose a header and background colour and upload banner image.</P>
				<div style={{ maxWidth: 430, marginTop: 18 }}>
					<Field
						name="style.banner"
						label="Banner image"
						component={ImageUploadField}
						uploadLabel="Upload image"
						aspectRatio={2}
						style={{ width: 240, height: 120 }}
					/>
				</div>

				<Field
					label="Header & search bar colour"
					name="style.headerColor"
					component={ColourField}
					marginTop={16}
					onClick={onColourPickerClicked}
					large
					//style={{ width: 60, height: 36 }}
				/>
				<Field
					label="Background Colour"
					name="style.backgroundColor"
					component={ColourField}
					marginTop={16}
					onClick={onColourPickerClicked}
					large
					//style={{ width: 60, height: 36 }}
				/>
				<Field
					label="Primary Colour"
					name="style.primaryColor"
					component={ColourField}
					marginTop={16}
					onClick={onColourPickerClicked}
					large
					onChange={(e, primaryColor) => updatePrimaryTextColor(dispatch, e, primaryColor, "style.primaryTextColor")}
					//style={{ width: 60, height: 36 }}
				/>
			</div>
			<div style={{ display: "flex", flexGrow: 1 }}>
				<div className={previewStyle}>
					<PagePreview style={style} />
				</div>
			</div>
		</div>
	);
	//</Radio>return
};

// Decorate with redux-form

Form = reduxForm({
	form: formName,
	destroyOnUnmount: true,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Form);
const selector = formValueSelector(formName);

Form = connect((state) => {
	const initialValues =
		state.giftCard && state.giftCard.giftCardPage
			? state.giftCard.giftCardPage
			: {
					style: {
						headerColor: "#5a626c",
						backgroundColor: "#dadada",
						primaryColor: "#353337",
						primaryTextColor: "#ffffff"
					}
			  };
	return {
		initialValues
	};
})(Form);

export default connect(
	(state) => {
		const isGiftCardFeatureEnabled = getIsGiftcardFeatureEnabled(state.locations.overrideLocation);
		return {
			style: selector(state, "style"),
			companyLogo: selector(state, "companyLogo"),
			slug: selector(state, "slug"),
			name: selector(state, "name"),
			isExisting: selector(state, "_id"),
			giftCard: state.giftCard,
			userEvents: state.userEvents,
			currentUser: state.auth,
			isGiftCardFeatureEnabled
		};
	},
	{
		updateGiftCardPage,
		getGiftCardPage
	}
)(withRouterHooks(GiftCardPageForm));
