import P from "@/components/text/P";
import { Field } from "redux-form";
import { RadioField } from "@/components/Fields";

const AutoSelfServicePayoutSetting = ({
	formPath = "",
	isVisible,
	autoSelfServe
}: {
	formPath: string;
	isVisible?: boolean;
	autoSelfServe: "disabled" | "weekly" | "fortnightly" | "monthly";
}) => {
	if (!isVisible) return null;

	let additionalDescriptionText = <P>Automatic payments are disabled</P>;

	switch (autoSelfServe) {
		case "fortnightly":
			additionalDescriptionText = <P>Paid every second Wednesday at 12:00 pm GMT</P>;
			break;
		case "weekly":
			additionalDescriptionText = <P>Paid every Wednesday at 12:00 pm GMT</P>;
			break;
		case "monthly":
			additionalDescriptionText = <P>Paid on the first Wednesday of the month at 12:00 pm GMT</P>;
			break;
	}

	return (
		<>
			<Field
				name={`${formPath}autoSelfServe`}
				description="When should early payouts automatically be processed?"
				label="Automatic early payouts"
				component={RadioField}
				button
				options={[
					{
						label: "Disabled",
						value: "disabled"
					},
					{
						label: "Weekly",
						value: "weekly"
					},
					{
						label: "Fortnightly",
						value: "fortnightly"
					},
					{
						label: "Monthly",
						value: "monthly"
					}
				]}
			/>
			{additionalDescriptionText}
		</>
	);
};

export default AutoSelfServicePayoutSetting;
