import AdminReportService from "@/services/AdminReportService";
import FileDownload from "@/utils/FileDownload";
import { getLocationFromState } from "../locationActions";
import { REPORT_DATE_CHANGE } from "./datesActions";

export const TOP_PURCHASERS_REQUEST = "TOP_PURCHASERS_REQUEST";
export const TOP_PURCHASERS_SUCCESS = "TOP_PURCHASERS_SUCCESS";
export const TOP_PURCHASERS_ERROR = "TOP_PURCHASERS_ERROR";
export const TOP_PURCHASERS_RESET = "TOP_PURCHASERS_RESET";

export const resetTopPurchasers = () => {
	return (dispatch) => {
		dispatch({ type: TOP_PURCHASERS_RESET });
	};
};

export const getTopPurchasers = (startDate, endDate, sortOn, page) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({
			type: REPORT_DATE_CHANGE,
			startDate,
			endDate
		});
		dispatch({ type: TOP_PURCHASERS_REQUEST, page });
		AdminReportService.getTopPurchasers(startDate, endDate, sortOn, page, location)
			.then((data) => {
				dispatch({
					type: TOP_PURCHASERS_SUCCESS,
					results: data.results,
					count: data.count
				});
			})
			.catch((err) => {
				dispatch({ type: TOP_PURCHASERS_RESET, error: err });
			});
	};
};

export const getTopPurchasersCsv = (startDate, endDate, sortOn) => {
	return () => {
		const location = getLocationFromState(true);
		const filename = `top-purchasers-${String(startDate).split("T")[0]}-${String(endDate).split("T")[0]}.csv`;
		AdminReportService.getTopPurchasersCsv(startDate, endDate, sortOn, location).then((data) => {
			FileDownload.saveAs(data, filename);
		});
	};
};
