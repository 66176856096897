import { css } from "@emotion/css";
import { Views } from "@hx/seating-map";
import { Button, Tabs } from "@/ui/antd";
import { Component } from "react";
import H3 from "@/components/text/H3";
import {
	AreaType,
	BookByTable,
	DisableSeat,
	ElementName,
	EndSeatNumber,
	IconPicker,
	LabelOptions,
	MultipleElementsSelected,
	NumberOfRows,
	PrivateElement,
	RowEditor,
	RowNumber,
	SeatNote,
	SeatNumber,
	SeatTextOverride,
	SectionLock,
	SizeSlider,
	TicketMapping
} from "./Items";
import ColorPicker from "./items/ColorPicker";
import SociallyDistance from "./items/SociallyDistance";
const { SeatingConstruction } = Views;
const nestedElements = ["moveableText", "moveableIcon"];

export default class ElementEditor extends Component {
	constructor(props) {
		super(props);
		props.elements.forEach((element) => {
			element.setOnChange(this.forceRerender);
			nestedElements.forEach((subEle) => {
				if (element[subEle]) {
					element[subEle].setOnChange(this.forceRerender);
				}
			});
		});
	}

	forceRerender = () => {
		this.forceUpdate();
	};

	componentWillReceiveProps(nextProps) {
		this.props.elements.forEach((element) => {
			element.setOnChange(false);
			nestedElements.forEach((subEle) => {
				if (element[subEle]) {
					element[subEle].setOnChange(false);
				}
			});
		});
		nextProps.elements.forEach((element) => {
			element.setOnChange(this.forceRerender);
			nestedElements.forEach((subEle) => {
				if (element[subEle]) {
					element[subEle].setOnChange(this.forceRerender);
				}
			});
		});
	}

	determineElementComponents(elements, type) {
		const action = performAction(this.props.addUndoAction, this.forceRerender);
		if (elements.length < 2) {
			const element = elements[0];
			if (element.basicType === "table") {
				return <SingleTable table={element} performAction={action} />;
			} else if (element.basicType === "section") {
				return <Section section={element} performAction={action} />;
			} else if (element.basicType === "object") {
				return <MapObject object={element} performAction={action} />;
			} else if (element.basicType === "seat") {
				return <Seats seats={elements} performAction={action} />;
			} else if (element.basicType === "section-sectionView") {
				return <SectionView section={element} performAction={action} />;
			} else if (element.basicType === "object-sectionView") {
				return <SectionObject object={element} performAction={action} />;
			} else if (element.basicType === "text") {
				return <MoveableText moveableText={element} performAction={action} />;
			} else if (element.basicType === "icon") {
				return <MoveableIcon moveableIcon={element} performAction={action} />;
			}
		} else if (type === "table") {
			return <MultipleTables tables={elements} performAction={action} />;
		} else if (type === "seat") {
			return <Seats seats={elements} performAction={action} />;
		}
	}

	render() {
		const { elements, type } = this.props;
		return (
			<div style={{ paddingTop: 8, margin: 0, width: "100%" }}>{this.determineElementComponents(elements, type)}</div>
		);
	}
}

const performAction = (addUndoAction, onChange) => (actionFunctionCalls, actionParams) => {
	// call change actions on seating map and return a single undo function
	const { type, functionCalls, params } = actionFunctionCalls
		.map((funcCall, i) => {
			//expecting any action to return the undo/redo structure
			return funcCall(...actionParams[i]);
		})
		// reduce to undo action to one single func call
		.reduce(
			(undoObj, functionCallValues) => {
				if (!functionCallValues) {
					return undoObj;
				}
				if (!undoObj.type) {
					undoObj.type = functionCallValues.type;
				}
				undoObj.functionCalls.push(functionCallValues.functionCall);
				undoObj.params.push(functionCallValues.params);
				return undoObj;
			},
			{ type: false, functionCalls: [], params: [] }
		);
	// add undo to stack
	if (functionCalls.length > 0) {
		addUndoAction(type, functionCalls, params);
	}
	// trigger state change
	onChange();
};

const SingleTable = ({ table, performAction }) => {
	switch (table.type) {
		case "row":
			return (
				<ViewWithTicketMapping>
					<div>
						<NumberOfRows performAction={performAction} element={table} />
						<RowNumber performAction={performAction} element={table} />
						<Separator performAction={performAction} />
						<DoneButton />
					</div>
					<div>
						<RowEditor performAction={performAction} element={table} />
						<Separator />
						<SeatNote performAction={performAction} elements={[table]} />
						<Separator />
						<SociallyDistance performAction={performAction} elements={[table]} />
						<Separator />
						<DoneButton />
					</div>
				</ViewWithTicketMapping>
			);
		case "squareTable":
			return (
				<div>
					<ViewWithTicketMapping>
						<ElementName performAction={performAction} element={table} typeLabel="Table" nameType="Type" />
						<div>
							<SeatNumber performAction={performAction} element={table} />
							<EndSeatNumber performAction={performAction} element={table} />
							<Separator />
							<DoneButton />
						</div>
						<div>
							<LabelOptions performAction={performAction} element={table} />
							<Separator performAction={performAction} />
							<SeatNote performAction={performAction} elements={[table]} />
							<PrivateElement performAction={performAction} element={[table]} label="Make this table private" />
							<Separator />
							<DoneButton />
						</div>
					</ViewWithTicketMapping>
				</div>
			);
		case "area":
			return (
				<div style={{ padding: "16px 12px", backgroundColor: "white" }}>
					<ElementName performAction={performAction} element={table} typeLabel="Area" nameType="name" />
					<div>
						<AreaType performAction={performAction} element={table} />
						<Separator />
						<SeatNumber labelOverride="Area Capacity" performAction={performAction} element={table} />
						<Separator />
						<SeatNote performAction={performAction} elements={[table]} />
						<Separator />
						<DoneButton />
					</div>
				</div>
			);
		default:
			return (
				<div>
					<ViewWithTicketMapping>
						<ElementName performAction={performAction} element={table} typeLabel="Table" nameType="Type" />
						<div>
							<SeatNumber performAction={performAction} element={table} />

							<Separator />
							<DoneButton />
						</div>
						<div>
							<LabelOptions performAction={performAction} element={table} />
							<Separator performAction={performAction} />
							<SeatNote performAction={performAction} elements={[table]} />
							<PrivateElement performAction={performAction} element={[table]} label="Make this table private" />
							<Separator />
							<DoneButton />
						</div>
					</ViewWithTicketMapping>
				</div>
			);
	}
};

const MultipleTables = ({ tables, performAction }) => {
	return (
		<div style={{ padding: "16px 12px" }}>
			<MultipleElementsSelected elementLength={tables.length} />
			<Separator />
			<SeatNote performAction={performAction} elements={tables} />
			<PrivateElement performAction={performAction} element={tables} label="Make these tables private" />
			<Separator />
			<DoneButton />
		</div>
	);
};

const Section = ({ section, performAction }) => {
	return (
		<ViewWithTicketMapping>
			<div>
				<ElementName performAction={performAction} element={section} typeLabel="Section" nameType="Type" />
				<BookByTable performAction={performAction} element={[section]} />
				<Separator />
				<DoneButton />
			</div>
			<TicketMapping performAction={performAction} elements={[section]} />
		</ViewWithTicketMapping>
	);
};

const MoveableText = ({ moveableText, performAction }) => {
	return (
		<div style={{ background: "white", margin: 2, padding: 10 }}>
			<ElementName performAction={performAction} element={moveableText} typeLabel="Text" nameType="" />
			<SizeSlider type="text" performAction={performAction} element={moveableText} />
			<Separator />
			<DoneButton />
		</div>
	);
};

const MoveableIcon = ({ moveableIcon, performAction }) => {
	return (
		<div style={{ background: "white", margin: 2, padding: 10 }}>
			<IconPicker performAction={performAction} element={moveableIcon} isIndependentIcon />
			<SizeSlider type="icon" performAction={performAction} element={moveableIcon} />
		</div>
	);
};

const MapObject = ({ object, performAction }) => {
	return (
		<div style={{ background: "white", margin: 2, padding: 10 }}>
			<ElementName performAction={performAction} element={object} typeLabel="Object" nameType="Name" />
			<SizeSlider type="text" performAction={performAction} element={object.moveableText} />
			<IconPicker performAction={performAction} element={object} />
			<SizeSlider type="icon" performAction={performAction} element={object.moveableIcon} />
			<Separator />
			<DoneButton />
		</div>
	);
};

const Seats = ({ seats, performAction }) => {
	return (
		<div style={{ background: "white", margin: 2, padding: 10 }}>
			<H3>Individual Seats</H3>
			<SeatTextOverride performAction={performAction} elements={seats} />
			<Separator />
			<SeatNote performAction={performAction} elements={seats} />
			<PrivateElement performAction={performAction} element={seats} label="Hide seat" />
			<DisableSeat performAction={performAction} element={seats} />
			<Separator />
			<DoneButton />
		</div>
	);
};

const SectionView = ({ section, performAction }) => {
	return (
		<div style={{ background: "white", margin: 2, padding: 10 }}>
			<ElementName performAction={performAction} element={section} typeLabel="Section" nameType="Name" />
			<SectionLock performAction={performAction} element={section} />
			<SizeSlider performAction={performAction} type="icon" element={section.moveableText} />
			Section Color:
			<ColorPicker
				performAction={performAction}
				onColorChange={section.changeColor}
				startingColor={section.boundsColor}
			/>
			Text Color:
			<ColorPicker
				performAction={performAction}
				onColorChange={section.changeTextColor}
				startingColor={section.getTextColor()}
			/>
			Border Color:
			<ColorPicker
				performAction={performAction}
				onColorChange={section.changeBorderColor}
				startingColor={section.borderColor}
			/>
		</div>
	);
};

const SectionObject = ({ object, performAction }) => {
	return (
		<div style={{ background: "white", margin: 2, padding: 10 }}>
			<ElementName performAction={performAction} element={object} typeLabel="Object" nameType="Name" />
			<SizeSlider performAction={performAction} type="text" element={object.moveableText} />
			<SizeSlider performAction={performAction} type="icon" element={object.moveableIcon} />
			<SectionLock performAction={performAction} element={object} />
			<IconPicker performAction={performAction} element={object} />
			<Separator />
			<DoneButton />
		</div>
	);
};

const ViewWithTicketMapping = ({ children }) => {
	const tabsStyle = css({
		".ant-tabs-nav.ant-tabs-nav-animated": {
			width: "100%"
		},
		".ant-tabs-tab": {
			margin: "0 !important",
			marginRight: "0 !important",
			textAlign: "center",
			width: "50%",
			padding: "8px 16px !important"
		},
		".ant-tabs-ink-bar.ant-tabs-ink-bar-animated": {
			height: 3
		}
	});
	let first, second, third;
	if (children.length > 2) {
		first = children[0];
		second = children[1];
		third = children[2];
	} else {
		second = children[0];
		third = children[1];
	}
	return (
		<div className={tabsStyle}>
			{first ? (
				<div id="test" style={{ background: "white" }}>
					<div style={{ margin: 2, padding: 10 }}>{first}</div>
				</div>
			) : null}
			<Tabs
				defaultActiveKey="1"
				style={{ borderBottom: "none", background: "#f9f9fa", width: "100%" }}
				tabBarGutter={0}
				tabBarStyle={{
					width: "100%",
					borderBottom: "none",
					textAlign: "center",
					margin: 0
				}}
				items={[
					{
						children: (
							<div id="test" style={{ background: "white" }}>
								<div style={{ margin: 2, padding: 10 }}>{second}</div>
							</div>
						),
						key: "1",
						label: "Info",
						style: { float: "left", width: "100%" }
					},
					{
						children: (
							<div style={{ background: "white" }}>
								<div style={{ margin: 2, padding: 10 }}>{third}</div>
							</div>
						),
						key: "2",
						label: "Settings"
					}
				]}
			/>
		</div>
	);
};

const Separator = () => {
	return (
		<div
			style={{
				marginTop: "14px",
				borderBottom: "solid 1px",
				borderColor: "#dedede"
			}}
		/>
	);
};

const DoneButton = () => {
	return (
		<Button
			style={{
				marginTop: "14px",
				width: "100%"
			}}
			type="primary"
			size="large"
			onClick={() => {
				SeatingConstruction.getInstance().unselect();
			}}
			ariaLabel="Done"
		>
			Done
		</Button>
	);
};
