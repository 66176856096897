import { ContentBlock } from "../../components/AppBlocks";
import PageHeading from "../../components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import H3 from "../../components/text/H3";
import P from "../../components/text/P";
import AdminUtilitiesService from "../../services/AdminUtilitiesService";
import EventFraudCheckRunner from "./EventFraudCheckRunner";

const Utilities = () => {
	return (
		<>
			<PageHeading title="Admin Utilities" />
			<ContentBlock>
				<P>
					This is the admin utilities page for the console. Please do not touch things in here unless you've either been
					specifically told to or are aware of the impact of the utility.
				</P>
				<P>
					This is still very much a new and untested feature, please be aware that spamming clicks or incorrect usage
					can be problematic.
				</P>
				<hr />
				<H3>Fraud check bulk run</H3>
				<LegacyButton ariaLabel="Run fraud checks" type="primary" onClick={AdminUtilitiesService.runFraudCheck}>
					Run fraud checks
				</LegacyButton>
				<hr />
				<EventFraudCheckRunner />
			</ContentBlock>
		</>
	);
};

export default Utilities;
