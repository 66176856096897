import SideMenu from "../components/SideMenu";
import SideMenuBg from "../components/SideMenuBg";
import MenuService from "../services/menuService/MenuService";
import { FC } from "react";

const PromoteMenu: FC = () => {
	const menuItems = MenuService.getItemChildren("promote") ?? [];
	return (
		<SideMenuBg>
			<SideMenu menuItems={menuItems} />
		</SideMenuBg>
	);
};

export default PromoteMenu;
