import axios from "./AxiosWrapper";

class InvoiceService {
	static instance = null;

	static createInstance() {
		let object = new InvoiceService();
		return object;
	}

	static getInstance() {
		if (!InvoiceService.instance) {
			InvoiceService.instance = InvoiceService.createInstance();
		}
		return InvoiceService.instance;
	}

	async markPaid(id) {
		const response = await axios.post(`invoices/${id}/paid`);
		return response.data;
	}
	async removePayment(id) {
		const response = await axios.post(`invoices/${id}/remove-payment`);
		return response.data;
	}
	async refundUnpaidInvoice(id) {
		const response = await axios.post(`invoices/${id}/refund-unpaid`);
		return response.data;
	}
	async markSent(id) {
		const response = await axios.post(`invoices/${id}/sent`);
		return response.data;
	}
	async removeSent(id) {
		const response = await axios.post(`invoices/${id}/remove-sent`);
		return response.data;
	}
}

export default InvoiceService.getInstance();
