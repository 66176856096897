import TicketTypeLegend from "./TicketTypeLegend";
import Toolbar from "./Toolbar";
import { UtilityBar } from "./UtilityBar";
import ConnectTicketManagement from "./connectors/ConnectTicketManagement";
import ConnectTicketingReservation from "./connectors/ConnectTicketingReservation";

const Connectors = {
	ConnectTicketingReservation,
	ConnectTicketManagement
};
export { Connectors, TicketTypeLegend, Toolbar, UtilityBar };
