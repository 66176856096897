import { State } from "@hx/seating-map";
import Icon from "@/components/Icon";
import { H3 } from "@/components/text/Text";
import Attendee from "./components/Attendee";
const { AssigningManager, AssigningState } = State.Assigning;

const AttendeeInfo = () => {
	const { name, firstName, lastName, ticketTypeName } = AssigningState.selectedObject.getTicketData();
	const selectedObjectId = AssigningState.selectedObject.id;
	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row-reverse",
					padding: "13px 17px",
					cursor: "pointer"
				}}
				onClick={() => AssigningManager.reset()}
			>
				<Icon icon="close_large" />
			</div>
			<div style={{ backgroundColor: "white", paddingTop: 16 }}>
				<div style={{ marginLeft: "12px" }}>
					<H3>Seat info</H3>
				</div>
				<Attendee attendeeInfo={{ name, firstName, lastName, ticketTypeName, selectedObjectId }} />
			</div>
		</div>
	);
};

export default AttendeeInfo;
