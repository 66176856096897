import { DndContext } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/css";
import { Table } from "@/ui/antd";
import React, { useEffect, useState } from "react";
import { change } from "redux-form";
import iconReference from "../services/IconReference";

const dragIcon = iconReference["drag"];

const dragDiv = css({
	width: 24,
	height: 24,
	touchAction: "none",
	cursor: "move"
});

const Row = ({ children, ...props }) => {
	const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, active } = useSortable({
		id: props["data-row-key"]
	});
	const style = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && {
				...transform,
				scaleY: 1
			}
		)?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
		transition: active ? transition : null
	};
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if (child.key === "sort") {
					return React.cloneElement(child, {
						children: <img ref={setActivatorNodeRef} className={dragDiv} {...listeners} src={dragIcon} />
					});
				}
				return child;
			})}
		</tr>
	);
};
const DraggableTable = (props) => {
	const [dataSource, setDataSource] = useState(
		props.dataSource
			? props.dataSource.map((dataPoint, index) => ({ ...dataPoint, key: `${dataPoint.key || index}` }))
			: []
	);

	useEffect(() => {
		setDataSource(
			props.dataSource
				? props.dataSource.map((dataPoint, index) => ({ ...dataPoint, key: `${dataPoint.key || index}` }))
				: []
		);
	}, [props.dataSource]);

	const onDragEnd = ({ active, over }) => {
		if (!active || !over) return;
		const [fromIndex, toIndex] = [active.id, over.id];

		if (props.draggable.customAction) {
			return props.draggable.customAction(fromIndex, toIndex);
		}
		const currentItems = [...dataSource];
		if (toIndex >= currentItems.length) {
			var k = toIndex - currentItems.length + 1;
			while (k--) {
				currentItems.push(undefined);
			}
		}
		currentItems.splice(toIndex, 0, currentItems.splice(fromIndex, 1)[0]);
		props.draggable.dispatch(change(props.draggable.formName, props.draggable.fieldName, currentItems));
		setDataSource(currentItems);
	};

	return (
		<DndContext onDragEnd={onDragEnd}>
			<SortableContext
				// rowKey array
				items={dataSource.map((i) => i.key)}
				strategy={verticalListSortingStrategy}
			>
				<Table
					{...props}
					components={{
						body: {
							row: Row
						}
					}}
					rowKey="key"
					columns={[{ key: "sort", width: 24 }].concat(props.columns)}
					dataSource={dataSource}
					expandIconAsCell={false}
					expandIconColumnIndex={-1}
				/>
			</SortableContext>
		</DndContext>
	);
};

export default DraggableTable;
