import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Col, DatePicker, Row, Select } from "@/ui/antd";
import { css } from "@emotion/css";
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	getDashboardOverview,
	getDashboardSummary,
	updateDashboardChannel,
	updateDashboardCustomDateRange,
	updateDashboardCustomDateRangePicked,
	updateDashboardPeriod
} from "../../state/actions/metricActions";
import { getCurrencyFromLocation } from "../../utils/Format";
import { LoadingSpinner } from "../AppBlocks";
import EventRow from "../EventRow";
import { H2, H5, P } from "../text/Text";
import Widget from "./Widget";

const RangePicker = DatePicker.RangePicker;

const labelStyle = css({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: 16,
	h2: {
		display: "inline",
		"&:nth-child(2)": {
			color: "#353337"
		}
	}
});

const headerWrapper = css({
	display: "flex",
	flexDirection: "column"
});

const overviewFilterWrapper = css({
	display: "flex",
	justifyContent: "flex-end",
	flexWrap: "wrap",
	gap: 8
});

const overviewSummaryTiles = css({
	backgroundColor: "#ffb18f",
	boxShadow: "0px 2px 4px rgba(9, 30, 66, 0.13)",
	borderRadius: 4,
	height: 170,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center"
});

const overviewSummaryTileContainer = css({
	backgroundColor: "transparent",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center"
});

const numberStyling = css({
	fontSize: "2rem",
	fontWeight: "700",
	marginBottom: 0
});

export const OverviewSummary: React.FC = () => {
	const dispatch = useAppDispatch();

	const metrics = useAppSelector((state) => state.dashboardMetrics);
	const user = useAppSelector((state) => state.auth.user);
	const { overviewByDate, summary, isCustomRangePicked, customDateRange, period, channel } = metrics;

	useEffect(() => {
		if (user) {
			dispatch(getDashboardSummary(period));
		}
	}, [dispatch, period, user]);

	const handleDatePickerChange = useCallback(
		(dates: any) => {
			dispatch(getDashboardSummary(period));
			dispatch(getDashboardOverview(period, channel));
			dispatch(updateDashboardCustomDateRange(dates));
		},
		[dispatch, period, channel]
	);

	const handleChannelChange = useCallback(
		(selectedChannel: string) => {
			dispatch(getDashboardSummary(period));
			dispatch(getDashboardOverview(period, selectedChannel));
			dispatch(updateDashboardChannel(selectedChannel));
		},
		[dispatch, period]
	);

	const handlePeriodChange = useCallback(
		(selectedPeriod: string) => {
			const isCustomRange = selectedPeriod === "customDate";
			dispatch(updateDashboardPeriod(selectedPeriod));
			dispatch(updateDashboardCustomDateRangePicked(isCustomRange));

			if (!isCustomRange) {
				dispatch(getDashboardSummary(selectedPeriod));
				dispatch(getDashboardOverview(selectedPeriod, channel));
			}
		},
		[dispatch, channel]
	);

	const numberFormatter = new Intl.NumberFormat(undefined, {
		style: "currency",
		currency: getCurrencyFromLocation(),
		notation: "compact",
		currencyDisplay: "narrowSymbol"
	});

	const yourOverviewTitleRow = (
		<div className={headerWrapper}>
			<div className={labelStyle}>
				<div>
					<H2>Your overview</H2>
				</div>
				<div className={overviewFilterWrapper}>
					<Select
						style={{ fontSize: 14, width: 140 }}
						defaultValue={channel}
						onChange={(value) => handleChannelChange(value)}
						options={[
							{ label: "All channels", value: "all" },
							{ label: "Online sales", value: "online" },
							{ label: "Manual sale", value: "manual" },
							{ label: "Box Office", value: "boxOffice" }
						]}
					/>
					<Select
						style={{ fontSize: 14, width: 140 }}
						defaultValue={period}
						onChange={(value) => handlePeriodChange(value)}
						options={[
							{ label: "Today", value: "today" },
							{ label: "Yesterday", value: "yesterday" },
							{ label: "Last 7 days", value: "week" },
							{ label: "Last 30 days", value: "month" },
							{ label: "Custom Date", value: "customDate" }
						]}
					/>
				</div>
			</div>
			<div>
				{isCustomRangePicked && (
					<RangePicker onChange={handleDatePickerChange} value={customDateRange} style={{ paddingBottom: 10 }} />
				)}
			</div>
		</div>
	);

	const viewAllEventsUrl = `/console/my-events`;

	return (
		<div>
			<div>
				{yourOverviewTitleRow}
				<Row gutter={8} style={{ marginBottom: 16 }} key="3boxes">
					<Col span={8} key="events">
						<div className={overviewSummaryTiles} style={{ position: "relative" }}>
							<div className={overviewSummaryTileContainer}>
								{summary?.numberOfUpcomingEvents === undefined || overviewByDate?.loading ? (
									<LoadingSpinner size={48} />
								) : (
									<p className={numberStyling}>{summary.numberOfUpcomingEvents}</p>
								)}
								<P>Upcoming events</P>
							</div>
							<div style={{ position: "absolute", bottom: 0 }}>
								<Link to={viewAllEventsUrl}>
									<P style={{ textDecoration: "underline" }}>View all events</P>
								</Link>
							</div>
						</div>
					</Col>
					<Col span={8} key="tickets">
						<div className={overviewSummaryTiles}>
							<div className={overviewSummaryTileContainer}>
								{overviewByDate?.loading ? (
									<LoadingSpinner size={48} />
								) : (
									<p className={numberStyling}>{overviewByDate.overview?.ticketsSold}</p>
								)}
								<P>Tickets sold</P>
							</div>
						</div>
					</Col>
					<Col span={8} key="earnings">
						<div className={overviewSummaryTiles}>
							<div className={overviewSummaryTileContainer}>
								{overviewByDate?.loading ? (
									<LoadingSpinner size={48} />
								) : (
									<p className={numberStyling}>{numberFormatter.format(overviewByDate.overview?.totalEarnings || 0)}</p>
								)}
								<P>Total earnings</P>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			{summary?.bestSellingEvent && (
				<Widget>
					<div>
						<H5 style={{ marginBottom: "0.625rem" }}>Best selling event in the last 7 days:</H5>
						<EventRow {...summary.bestSellingEvent} isPrivate={!summary.bestSellingEvent.public} minimal />
					</div>
				</Widget>
			)}
			{summary?.latestDraftEvent && (
				<Widget>
					<div>
						<H5 style={{ marginBottom: "0.625rem" }}>Continue building your latest draft:</H5>
						<EventRow {...summary.latestDraftEvent} isPrivate={!summary.latestDraftEvent.public} minimal />
					</div>
				</Widget>
			)}
		</div>
	);
};
