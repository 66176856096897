import { State } from "@hx/seating-map";
import { Input, Spin, Tabs } from "@/ui/antd";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import ScrollComponent from "@/components/ScrollComponent";
import { searchTickets } from "@/state/actions/seatingMapActions";
import Attendee from "./Attendee";
const InputSearch = Input.Search;
const { MODES } = State.Assigning;

const TicketSearch = ({ currentEvent, seatingMap, searchTickets }) => {
	const [tab, setTab] = useState(MODES.ASSIGN_INDIVIDUAL);
	const [page, setPage] = useState(1);

	const load = (searchQuery) => {
		searchTickets({
			eventId: String(currentEvent.event._id),
			seatingMapId: String(seatingMap.seatingMap._id),
			searchQuery: searchQuery,
			type: tab,
			page: page
		});
	};
	const debounceLoad = debounce(500, load);

	useEffect(() => {
		if (page > 1) {
			load();
		}
	}, [page]);

	useEffect(() => {
		load();
	}, [tab]);

	const onTabChange = (newTab) => {
		setPage(1);
		setTab(newTab);
	};

	const onSearch = useCallback(
		(e) => {
			setPage(1);
			debounceLoad(e.target.value);
		},
		[debounceLoad]
	);

	const pageUp = useCallback(() => {
		setPage((prevPage) => prevPage + 1);
	}, []);
	const debouncePageUp = debounce(1000, pageUp);

	return (
		<ScrollComponent
			style={{ marginTop: 16, marginBottom: 4, padding: "0px 12px 16px 12px" }}
			onReachedBottom={debouncePageUp}
		>
			<Tabs
				activeKey={tab}
				onChange={onTabChange}
				items={[
					{ key: MODES.ASSIGN_INDIVIDUAL, label: "Attendees" },
					{ key: MODES.ASSIGN_ORDER, label: "Buyer" }
				]}
			/>
			<p>
				Search by {tab === MODES.ASSIGN_INDIVIDUAL ? "" : "attendee "} name, company, order ID, ticket type, email,
				mobile or seat number
			</p>
			<InputSearch
				onChange={onSearch}
				enterButton
				placeholder={`Search for ${tab === MODES.ASSIGN_INDIVIDUAL ? "attendees" : "buyer"}`}
			/>
			<div style={{ marginTop: 16, marginBottom: 16 }}>
				{seatingMap.organiser.tickets.map((ticketData, index) => {
					return (
						<Attendee
							attendeeInfo={{
								...ticketData
							}}
							key={index}
						/>
					);
				})}
				{seatingMap.organiser.loading && (
					<div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
						<Spin size="small" />
					</div>
				)}
			</div>
		</ScrollComponent>
	);
};

export default connect(
	(state) => {
		return {
			seatingMap: state.seatingMap,
			currentEvent: state.currentEvent
		};
	},
	{ searchTickets }
)(TicketSearch);
