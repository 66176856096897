import { Tag } from "@/ui/antd";

const PayoutStatus = ({ status }) => {
	let color;
	switch (status) {
		case "paid":
			color = "#1ab87e";
			break;
		case "partially-paid":
			color = "#f4ad42";
			break;
		case "pending":
			color = "#f5222d";
			break;
		default:
			color = "#67717E";
	}

	return <Tag color={color}>{status}</Tag>;
};
export default PayoutStatus;
