import { useViewport } from "@/hooks/useViewport";
import { FC, ReactNode } from "react";

type DesktopOnlyProps = {
	children: ReactNode;
};

export const DesktopOnly: FC<DesktopOnlyProps> = ({ children }) => {
	const { isMobile } = useViewport();

	return (isMobile ? null : children) as JSX.Element;
};
