import { css } from "@emotion/css";
import { H3, P } from "../../components/text/Text";

import appIllustration from "../../assets/app_illustration.svg";
import appleAppStore from "../../assets/app_store_badge.svg";
import googlePlayStore from "../../assets/google_play_badge.svg";

const imageWrapper = css({
	a: {
		"&:first-of-type": {
			marginRight: 12
		}
	}
});
const appWrapper = css({
	textAlign: "center",
	p: {
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: 16
	}
});

const DownloadApp = () => {
	return (
		<div className={appWrapper}>
			<img src={appIllustration} alt="App on phone" style={{ marginBottom: 24 }} />
			<H3>Reduce wait time at the door</H3>
			<P>
				Scan tickets fast and welcome your attendees with our free scanning app. Manage your event on the day from the
				app.
			</P>
			<div className={imageWrapper}>
				<a href="https://itunes.apple.com/au/app/humanitix-console/id1363849196?mt=8">
					<img src={appleAppStore} alt="Apple app store" />
				</a>
				<a href="https://play.google.com/store/apps/details?id=com.humanitix.organiser">
					<img src={googlePlayStore} alt="Google app store" />
				</a>
			</div>
		</div>
	);
};

export default DownloadApp;
