import { css } from "@emotion/css";
import { InputNumber } from "@/ui/antd";
import InputLabel from "./InputLabel";

const numberInputStyle = css({
	".ant-input-number-handler-wrap": {
		opacity: 1,
		borderLeft: "none",
		borderRadius: 4,
		minWidth: "24px"
	},
	".ant-input-number": {
		minWidth: 0,
		width: 24,
		overflow: "hidden"
	}
});

const StepInput = ({ style, onChange, value, min }) => {
	return (
		<div style={style} className={numberInputStyle}>
			<div style={{ display: "flex" }}>
				<InputLabel
					style={{
						marginTop: "auto",
						marginBottom: "auto",
						marginRight: 8,
						fontWeight: 500,
						fontSize: 14
					}}
				>
					{value}
				</InputLabel>
				<InputNumber value={value} onChange={onChange} min={min} />
			</div>
		</div>
	);
};

export default StepInput;
