import { Alert } from "@/ui/antd";

const PersonalisationInformation = ({ personalisationList }) => {
	return (
		<Alert
			style={{ marginBottom: 12 }}
			message="Add a personal touch"
			description={
				<div>
					Use the following codes to personalise your subject or message.
					<br />
					{Object.keys(personalisationList).map((personalisation) => (
						<div key={personalisationList[personalisation].item}>
							<b>{personalisationList[personalisation].item}</b> {personalisationList[personalisation].info} <br />
						</div>
					))}
				</div>
			}
			type="info"
			showIcon
		/>
	);
};

export default PersonalisationInformation;
