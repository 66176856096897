import { Tag } from "@/ui/antd";

const InvoiceStatus = ({ order }) => {
	if (
		!order?.paymentGateway ||
		(order.paymentGateway.toLowerCase() !== "invoice" && order.paymentGateway.toLowerCase() !== "invoice pay link")
	) {
		return null;
	}

	if (order.financialStatus === "Not sent") {
		return <Tag color="#f5222d">Not sent</Tag>;
	}

	if (order.financialStatus === "Requires payment") {
		return <Tag color="#f5222d">Requires payment</Tag>;
	}

	if (order.financialStatus === "Paid") {
		return <Tag color="#1ab87e">Paid</Tag>;
	}

	return <Tag color="#1ab87e">{order.financialStatus}</Tag>;
};

export default InvoiceStatus;
