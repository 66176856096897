import { LegacyButton } from "@/components/buttons/LegacyButton";
import { NumberRange } from "@/components/NumberRange";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { dayjs } from "@/libs/dayjs";
import LocationService from "@/services/LocationService";
import { resetGlobalReports } from "@/state/actions/globalReportActions";
import { resetSearch, setSearch, updateSearch } from "@/state/actions/searchActions";
import { Button, Dropdown, Responsive } from "@/ui";
import { DatePicker, Flex, Input, Radio, Select, Spin, Tooltip } from "@/ui/antd";
import { currency } from "@/utils/Format";
import { getSearchStateFromUrl, pushSearchStateIntoQuerystring } from "@/utils/Urls";
import { css } from "@emotion/css";
import { DateFormats, HumaniDate } from "@hx/dates";
import { DownloadSimple, SlidersHorizontal } from "@phosphor-icons/react";
import { Component } from "react";
import { connect } from "react-redux";

const wrapper = css({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: 12,
	"@media(max-width:900px)": {
		marginBottom: 12,
		display: "block"
	}
});

const refinementsWrapper = css({
	display: "flex",
	flexWrap: "wrap"
});

const filterLine = css({
	display: "block",
	fontWeight: "bold",
	width: "100%",
	overflow: "hidden",
	marginBottom: 12
});

const selectField = css({
	display: "block",
	fontWeight: "normal",
	marginTop: 4
});

const radioStyle = css({
	height: "30px",
	lineHeight: "30px",
	fontWeight: 200
});

const pickerStyler = css({
	marginTop: 6,
	".ant-calendar-picker": {
		width: 140,
		marginRight: 12,
		"&:last-of-type": {
			marginRight: 0
		}
	},
	".ant-input-number": {
		width: 108,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		"&:last-of-type": {
			marginRight: 0
		}
	},
	".ant-input-group": {
		float: "left",
		width: "auto",
		marginRight: 12,
		"&:last-of-type": {
			marginRight: 0
		}
	},
	"@media(max-width:600px)": {
		".ant-calendar-picker": {
			width: 108,
			marginRight: 8,
			"&:last-of-type": {
				marginRight: 0
			}
		},
		".ant-input-group": {
			marginRight: 8
		},
		".ant-input-number": {
			marginRight: 8,
			width: 76,
			"&:last-of-type": {
				marginRight: 0
			}
		},
		".ant-input": {
			fontSize: 12
		}
	}
});

class SearchRefinements extends Component {
	constructor(props) {
		super(props);
		this.handleOnQueryChange = this.handleOnQueryChange.bind(this);
		this.getRefinementText = this.getRefinementText.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.getInitialSearch = this.getInitialSearch.bind(this);
	}

	state = {
		loading: true
	};

	componentDidMount() {
		const { setSearch, load, defaultSearch, localStorageSearchKey } = this.props;
		const initialSearch = this.getInitialSearch(defaultSearch, localStorageSearchKey, true);
		const search = getSearchStateFromUrl(initialSearch);
		setSearch(search, false);
		if (load instanceof Function) {
			load(search.page || 1, search);
		}
		this.setState({ loading: false });
	}

	componentDidUpdate(prevProps) {
		const { location, load, setSearch, defaultSearch, localStorageSearchKey } = this.props;
		const initialSearch = this.getInitialSearch(defaultSearch, localStorageSearchKey);
		const locationChanged = location.search !== prevProps.location.search;
		if (locationChanged) {
			const search = getSearchStateFromUrl(initialSearch);
			setSearch(search, false, localStorageSearchKey);
			if (load instanceof Function) {
				load(search.page || 1, search);
			}
			this.setState({ loading: false });
		}
	}

	componentWillUnmount() {
		const { resetSearch, resetGlobalReports } = this.props;
		resetSearch();
		resetGlobalReports();
	}

	handleFilterChange(key, value) {
		const { updateSearch } = this.props;
		clearTimeout(this.filterChangeTimeout);
		this.filterChangeTimeout = window.setTimeout(() => updateSearch({ [key]: value }), 200);
	}

	getInitialSearch(defaultSearch, localStorageSearchKey, pushToURL = false) {
		if (!localStorageSearchKey) {
			return defaultSearch;
		}
		try {
			const savedSearchOnLocalStorage = JSON.parse(localStorage.getItem(localStorageSearchKey));
			if (savedSearchOnLocalStorage) {
				if (pushToURL) {
					pushSearchStateIntoQuerystring(savedSearchOnLocalStorage);
				}
				return savedSearchOnLocalStorage;
			}
			return defaultSearch;
		} catch (err) {
			console.err(err);
			return defaultSearch;
		}
	}

	removeFilter = (key) => {
		const { updateSearch } = this.props;
		updateSearch({ [key]: undefined }, true);
	};

	removeFilterEntry(key, value) {
		const { search, updateSearch } = this.props;
		const filtered = search[key].filter((entry) => entry !== value);
		updateSearch({ [key]: filtered }, true);
	}

	dayjsify(value) {
		if (dayjs.isDayjs(value)) {
			return value;
		}
		return value ? dayjs(value) : null;
	}

	formatDateToIso(value) {
		const date = this.dayjsify(value);
		return date ? date.toISOString() : undefined;
	}

	formatDate(value) {
		const date = this.dayjsify(value);
		return date
			? new HumaniDate(date.toDate(), "guess", LocationService.getLocation(true)).formatting.date(DateFormats.Pretty)
			: "";
	}

	getRefinementText(key, value) {
		if (key.endsWith("StartDate")) return `After ${this.formatDate(value)}`;
		if (key.endsWith("EndDate")) return `Before ${this.formatDate(value)}`;
		if (key.endsWith("Input")) return value;
		if (key.startsWith("min")) return `Above ${currency(value)}`;
		if (key.startsWith("max")) return `Below ${currency(value)}`;

		const { filterOptions } = this.props;
		const filterOption = filterOptions.find((o) => o.key === key);
		if (!filterOption) return undefined;
		const option = filterOption.options.find((o) => o.value === value);
		if (option) {
			if (option.name === "Yes" || option.name === "No") return `${filterOption.label}: ${option.name}`;
			return option.name;
		}
		return value;
	}

	constructFilter(filter) {
		const { search } = this.props;
		switch (filter.kind) {
			case "text":
				return (
					<label key={`filter-${filter.key}`} className={filterLine}>
						{filter.label}
						<br />
						<Input
							allowClear
							placeholder="Search"
							defaultValue={search[filter.key]}
							value={search[filter.key]}
							onChange={(e) => this.handleFilterChange(filter.key, e.target.value)}
						/>
					</label>
				);
			case "radio":
				return (
					<label key={`filter-${filter.key}`} className={filterLine}>
						{filter.label}
						<br />

						<Radio.Group
							style={{ marginTop: 6 }}
							value={search[filter.key] || filter.options[0].value}
							buttonStyle="solid"
							onChange={(e) => this.handleFilterChange(filter.key, e.target.value)}
						>
							{filter.options.map((o) => (
								<Radio
									{...(o.cy ? { "data-cy": o.cy } : {})}
									value={o.value}
									key={`${filter.key}-${o.value}`}
									className={radioStyle}
								>
									{o.name}
								</Radio>
							))}
						</Radio.Group>
					</label>
				);
			case "dateRange": {
				const startKey = `${filter.key}StartDate`;
				const endKey = `${filter.key}EndDate`;
				return (
					<label key={`filter-${filter.key}DateRange`} className={filterLine}>
						{filter.label}
						<div className={pickerStyler}>
							<DatePicker
								value={this.dayjsify(search[startKey])}
								placeholder="Start date"
								onChange={(value) => {
									this.handleFilterChange(startKey, this.formatDateToIso(value));
								}}
								style={{ marginRight: 12 }}
							/>
							<DatePicker
								value={this.dayjsify(search[endKey])}
								placeholder="End date"
								onChange={(value) => {
									this.handleFilterChange(endKey, this.formatDateToIso(value));
								}}
							/>
						</div>
					</label>
				);
			}
			case "numberRange": {
				return (
					<NumberRange
						key={`filter-${filter.key}NumberRange`}
						filter={filter}
						search={search}
						handleFilterChange={this.handleFilterChange}
					/>
				);
			}
			//TODO: Only allows for the picking of a single date. Needs rethinking
			// case "eventDatePicker": {
			//   const selectedDateIds = search[filter.key] || [];
			//   const firstSelectedDateId = selectedDateIds[0];
			//   const selectedDate = filter.options.find(
			//     (o) => o._id === firstSelectedDateId
			//   );
			//   return (
			//     <EventDatePicker
			//       key="EventDatePicker"
			//       dates={filter.options}
			//       selectedDate={selectedDate}
			//       onDatePicked={(value) => {
			//         this.handleFilterChange(filter.key, value);
			//       }}
			//     />
			//   );
			// }
			default:
				return (
					<label key={`filter-${filter.key}`} className={filterLine}>
						{filter.label}
						<Select
							className={selectField}
							mode={filter.mode || "default"}
							onChange={(value) => this.handleFilterChange(filter.key, value)}
							optionFilterProp="name"
							value={
								search[filter.key]
									? search[filter.key]
									: filter.mode === "multiple"
									? undefined
									: filter.options[0].value
							}
							placeholder={
								filter.placeholder ? filter.placeholder : filter.mode === "multiple" ? "Type to find" : undefined
							}
							options={filter.options.map((o) => ({ value: o.value, label: o.name }))}
						/>
					</label>
				);
		}
	}

	constructRefinementsList() {
		const { search, defaultSearch = {} } = this.props;
		const refinements = [];
		const keys = Object.keys(search);
		keys.forEach((key) => {
			//Don't show defaultSearch values
			if (JSON.stringify(defaultSearch[key]) === JSON.stringify(search[key])) return;

			if (key.endsWith("Ids")) {
				refinements.push(
					...search[key]
						.map((id) => {
							const text = this.getRefinementText(key, id);
							return (
								text && {
									key: id,
									text,
									action: () => this.removeFilterEntry(key, id)
								}
							);
						})
						.filter((r) => r)
				);
			} else {
				const text = this.getRefinementText(key, search[key]);
				if (text) {
					refinements.push({
						key,
						text,
						action: () => this.removeFilter(key)
					});
				}
			}
		});
		return refinements;
	}

	handleOnQueryChange(e) {
		const { updateSearch } = this.props;
		const value = e.target.value;
		clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => updateSearch({ query: value }, true), 400);
	}

	handleSearch() {
		const { load, search } = this.props;
		clearTimeout(this.queryTimeout);
		load(search.page || 1);
	}

	render() {
		const {
			search,
			updateSearch,
			dropdownId,
			filterOptions,
			download,
			extras,
			tooltip,
			hideSearch,
			showRefinements,
			placeholder,
			openDirection,
			wrapperMarginBottom = 24,
			fullWidth = false
		} = this.props;

		if (this.state.loading) return <Spin />;

		const refinements = showRefinements ? this.constructRefinementsList() : [];

		return (
			<div style={{ marginBottom: wrapperMarginBottom }}>
				<div className={wrapper}>
					<Flex flex={1} gap="sm" justify="space-between">
						<Flex gap="sm" wide>
							{!hideSearch && (
								<Input.Search
									allowClear
									defaultValue={search.query}
									enterButton
									onChange={this.handleOnQueryChange}
									onSearch={() => setTimeout(this.handleSearch, 500)}
									placeholder={placeholder || "Search"}
									style={fullWidth ? { maxWidth: "100%", width: "100%" } : null}
								/>
							)}
							{dropdownId && (
								<Dropdown
									aria-label="search refinements dropdown"
									items={<div>{filterOptions.map((filterOption) => this.constructFilter(filterOption))}</div>}
									onClose={() => updateSearch({}, true)}
									placement={openDirection === "left" ? "bottomLeft" : "bottomRight"}
									title="Search refinements"
									persist
									maxWidth={300}
								>
									<Tooltip title={tooltip || "Refine search"}>
										<Button iconOnly variant="text">
											<SlidersHorizontal size={20} />
										</Button>
									</Tooltip>
								</Dropdown>
							)}
						</Flex>
						{extras || download ? (
							<Flex gap="sm">
								{extras && <div>{extras}</div>}
								{download && (
									<Responsive
										desktop={
											<Tooltip hidden={!download.tooltip} title={download.tooltip}>
												<Button
													aria-label="download csv"
													iconOnly
													isLoading={download.loading}
													onClick={download.download}
													variant="text"
												>
													<DownloadSimple size={18} />
												</Button>
											</Tooltip>
										}
									/>
								)}
							</Flex>
						) : null}
					</Flex>
				</div>

				<div className={refinementsWrapper}>
					{refinements.map((refinement) => {
						return (
							<LegacyButton
								key={refinement.key}
								onClick={refinement.action}
								type="import"
								icon={{
									name: refinement.icon ? refinement.icon : "close",
									right: true
								}}
								style={{ marginRight: 8 }}
								ariaLabel={refinement.text}
							>
								{refinement.text}
							</LegacyButton>
						);
					})}
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		search: state.search
	}),
	{
		setSearch,
		updateSearch,
		resetSearch,
		resetGlobalReports
	}
)(withRouterHooks(SearchRefinements));
