import { css } from "@emotion/css";
import * as React from "react";
import ToolTip from "../components/ToolTip";
import Store from "../utils/Store";
import { TabProps } from "./index";

const tabStyle = css({
	width: 40,
	height: 40,
	paddingTop: 6,
	backgroundColor: "#4e4757",
	border: "none",
	outline: "none",
	"&:hover": {
		backgroundColor: "#70697a"
	}
});

const selectedTabStyle = css({
	width: 40,
	height: 40,
	paddingTop: 6,
	backgroundColor: "#ffffff",
	border: "none",
	outline: "none"
});

export class BasicTab extends React.Component<TabProps, { active: boolean }> {
	constructor(props: TabProps) {
		super(props);
		this.state = {
			active: false
		};
	}

	componentWillUnmount() {
		const { endMethodName } = this.props;
		if (endMethodName) {
			Store.callInteractionManagerMethod(endMethodName);
		}
	}

	onUp = () => {
		this.setState({ active: false });
	};

	ondown = () => {
		this.setState({ active: true });
	};

	onClick = () => {
		const { onClick, methodName } = this.props;
		if (onClick) {
			onClick();
		} else {
			Store.callInteractionManagerMethod(methodName);
		}
	};

	render() {
		const { name, icon, first, last, selected } = this.props;
		const extraStyling: React.CSSProperties = {};
		if (first) {
			extraStyling.borderTopLeftRadius = 4;
			extraStyling.borderBottomLeftRadius = 4;
		}
		if (last) {
			extraStyling.borderTopRightRadius = 4;
			extraStyling.borderBottomRightRadius = 4;
		}
		const iconColor = selected || this.state.active ? "#ffb18f" : "#ffffff";
		return (
			<div style={{ position: "relative", display: "inline-block", cursor: "pointer" }}>
				<ToolTip message={name}>
					<button
						onMouseDown={this.ondown}
						onClick={this.onClick}
						onMouseLeave={this.onUp}
						onMouseUp={this.onUp}
						className={selected || this.state.active ? selectedTabStyle : tabStyle}
						style={{
							...extraStyling,
							cursor: "pointer"
						}}
					>
						{icon({ color: iconColor })}
					</button>
				</ToolTip>
			</div>
		);
	}
}
