import { Dropdown, message } from "@/ui/antd";
import { Button } from "@/ui/Button";
import styled from "@emotion/styled";
import { Clipboard } from "@phosphor-icons/react";
import copy from "copy-text-to-clipboard";
import { FC } from "react";

const StyledCopyField = styled.div`
	align-items: center;
	background-color: var(--surface1);
	border-radius: var(--rounded-sm);
	border: var(--border1);
	display: flex;
	justify-content: space-between;
	padding-left: var(--spacing-sm);
	width: 100%;

	a {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

const StyledDisabledLabel = styled.span`
	cursor: default;
	opacity: 0.5;
`;

type CopyFieldProps = {
	disabled?: boolean;
	displayValue: string;
	dropdownOptions?: { label: string; value: string }[];
};

export const CopyField: FC<CopyFieldProps> = ({ disabled = false, displayValue, dropdownOptions }) => {
	const hasMultipleOptions = dropdownOptions?.length;

	const handleCopyClicked = (value: string) => {
		message.success("Copied to clipboard");
		copy(value);
	};

	const copyButton = (
		<Button
			disabled={disabled}
			iconOnly
			onClick={() => !hasMultipleOptions && handleCopyClicked(displayValue)}
			style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
			variant="text"
		>
			<Clipboard size={18} />
		</Button>
	);

	return (
		<StyledCopyField>
			{disabled ? (
				<StyledDisabledLabel>{displayValue}</StyledDisabledLabel>
			) : (
				<a href={displayValue} target="_blank" rel="noopener noreferrer">
					{displayValue}
				</a>
			)}
			{hasMultipleOptions ? (
				<Dropdown
					disabled={disabled}
					menu={{
						items: dropdownOptions?.map((option) => ({
							key: option.value,
							label: option.label,
							onClick: () => handleCopyClicked(option.value)
						}))
					}}
					placement="bottomRight"
				>
					{copyButton}
				</Dropdown>
			) : (
				copyButton
			)}
		</StyledCopyField>
	);
};
