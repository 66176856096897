import AuditLogsService from "../../services/AuditLogsService";

export const AUDIT_LOG_LIST_REQUEST = "AUDIT_LOG_LIST_REQUEST";
export const AUDIT_LOG_LIST_SUCCESS = "AUDIT_LOG_LIST_SUCCESS";
export const AUDIT_LOG_LIST_ERROR = "AUDIT_LOG_LIST_ERROR";

export const AUDIT_LOG_GET_REQUEST = "AUDIT_LOG_GET_REQUEST";
export const AUDIT_LOG_GET_SUCCESS = "AUDIT_LOG_GET_SUCCESS";
export const AUDIT_LOG_GET_ERROR = "AUDIT_LOG_GET_ERROR";

export const listLogs = (page) => {
	return (dispatch, getState) => {
		const search = getState().search;
		dispatch({ type: AUDIT_LOG_LIST_REQUEST, page });
		AuditLogsService.list(search, page)
			.then((response) => {
				if (response && response.data && response.data.error) {
					dispatch({ type: AUDIT_LOG_LIST_ERROR, error: response.data.details });
				} else {
					dispatch({ type: AUDIT_LOG_LIST_SUCCESS, data: response.data });
				}
			})
			.catch(() => {
				dispatch({ type: AUDIT_LOG_LIST_ERROR });
			});
	};
};
