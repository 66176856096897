import { useAuth } from "@/hooks/useAuth";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { css } from "@emotion/css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import iconRecentEvents from "../../assets/menu/ic_edit.svg";
import ImportModal from "../../events/ImportModal";
import type { MenuItem as MenuItemType } from "../../services/menuService/MenuService";
import { clearImport, getRecentlyUpdatedEvents, openImport, resetEvent } from "../../state/actions/eventActions";
import type { ReduxState as GlobalReduxState } from "../../state/store";
import { getEventSearchLink } from "../../utils/Events";
import { Navbar, Topbar } from "./components";

const wrapper = css({
	"z-index": 120
});

export interface IMenuItemWithHighlights extends MenuItemType {
	highlights?: MenuItemType;
}

interface IReduxActions {
	openImport: () => void;
	clearImport: () => void;
	resetEvent: () => void;
	getRecentlyUpdatedEvents: () => void;
}

const pickDataFromState = (state: Partial<GlobalReduxState>) => ({
	recentlyUpdatedEvents: state.events?.recentlyUpdatedEvents
});

type LocalisedReduxState = ReturnType<typeof pickDataFromState>;
type ReduxInput = LocalisedReduxState & IReduxActions;

interface IAppHeaderProps {
	openSideBar: () => void;
	menu: IMenuItemWithHighlights[];
}
type AppHeaderInput = IAppHeaderProps & ReduxInput;

const AppHeader = ({
	openSideBar,
	openImport,
	clearImport,
	menu,
	resetEvent,
	getRecentlyUpdatedEvents,
	recentlyUpdatedEvents
}: AppHeaderInput) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { assumingIdentity } = useAuth();
	const { isAdmin, hasEventImportAccess } = useCurrentUser();

	useEffect(() => {
		if (!recentlyUpdatedEvents?.loaded) {
			getRecentlyUpdatedEvents();
		}
	}, []);

	if (recentlyUpdatedEvents?.events) {
		const eventsMenu = menu.find((menu) => menu.key === "events");
		if (eventsMenu) {
			const highlights: IMenuItemWithHighlights = {
				label: "Recently updated events",
				icon: iconRecentEvents,
				path: "",
				key: "recently-updated-events",
				children: recentlyUpdatedEvents.events.map(
					(event: any): MenuItemType => ({
						label: event.name,
						path: getEventSearchLink(event._id, event.eventCreationSteps),
						key: event._id
					})
				)
			};
			eventsMenu.highlights = highlights;
		}
	}

	const _handleCreateEventClicked = () => {
		clearImport();
		resetEvent();
		navigate("/console/my-events/new/basics");
	};

	return (
		<>
			<ImportModal />
			<header id="console_header" className={wrapper}>
				<Topbar assumingIdentity={assumingIdentity} openSideBar={openSideBar} />
				<Navbar
					menu={menu}
					handleCreateEventClicked={_handleCreateEventClicked}
					handleImportEventClicked={() => openImport()}
					showImportEvent={isAdmin || hasEventImportAccess}
					showCreateEvent={location.pathname !== "/console/my-events/new/basics"}
				/>
			</header>
		</>
	);
};

export default connect(pickDataFromState, { openImport, clearImport, resetEvent, getRecentlyUpdatedEvents })(AppHeader);
