import { H3, Note } from "@/components/text/Text";
import feesService from "@/services/FeesService";
import { getCurrencySymbol } from "@/utils/Format";
import { currencyFormat, currencyParse, percentFormat, percentParse } from "@/utils/InputFormatters";
import { required } from "@/utils/Validators";
import { ChangeEvent, FC, useState } from "react";
import { Field } from "redux-form";
import { InputNumberField, RadioField, SelectField, SwitchField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";

const fixedRequired = required("A fixed fee required");
const percentRequired = required("A percent fee required");
const donationTypeRequired = required("A donation type is required");
const donationAmountRequired = required("A donation amount is required");
const typeRequired = required("A type is required");
const amexFeeRequired = required("A amex fee is required");

const feeTypes = {
	donation: { label: "Donation", value: "donation" },
	charity: { label: "Charity", value: "charity" },
	notForProfit: { label: "Not For Profit", value: "notForProfit" },
	education: { label: "Education", value: "education" },
	atCost: { label: "No Donation", value: "atCost" }
};

type FeeType = keyof typeof feeTypes;

type FeesInputProps = {
	change: any;
	fees: any;
	currentEvent: any;
	location: any;
};

type ChangeFeeEvent = {
	value: FeeType;
};

const FeesInput: FC<FeesInputProps> = ({ change, fees, currentEvent, location }) => {
	const [loading, setState] = useState(false);

	const handleChangeFeeStructure = async (event: ChangeEvent<ChangeFeeEvent>) => {
		const feeType = event.target.value;
		const locationLookup = location ? location : currentEvent.event.location;
		setState(true);
		const typeFeeStructure = await feesService.getDefaultFee(
			feeType === "donation" ? "default" : feeType,
			locationLookup
		);
		setState(false);
		change("fees", {
			...fees,
			...typeFeeStructure
		});
	};

	const invoiceFees = fees && fees.invoiceFees ? fees.invoiceFees : null;

	const symbol =
		fees && fees.donation && fees.donation.type && fees.donation.type === "percent" ? "%" : getCurrencySymbol();

	const educationStructure = fees && fees.type === "education";

	const donationTypes = [
		{
			label: "Percent",
			value: "percent"
		}
	];

	if (!educationStructure) {
		donationTypes.push({
			label: "Fixed",
			value: "fixed"
		});
	}
	return (
		<LoadErrorView loading={loading}>
			<div>
				<div style={{ marginBottom: 12 }}>
					<Field
						button
						component={RadioField}
						defaultValue="donation"
						label="Type"
						name="fees.type"
						onChange={handleChangeFeeStructure as any}
						options={Object.values(feeTypes)}
						validate={typeRequired}
					/>
				</div>

				<Field name="fees.taxExclusive" label="Excludes tax" component={SwitchField as any} defaultValue={false} />

				<Field
					label="Booking fee fixed"
					description="The fixed fee charged per ticket"
					name="fees.fixed"
					component={InputNumberField}
					precision={2}
					min={0}
					validate={fixedRequired}
					formatter={currencyFormat}
					parser={currencyParse}
				/>

				<Field
					label="Booking fee percent"
					name="fees.percent"
					description="The percent fee charged per ticket"
					component={InputNumberField}
					precision={2}
					min={0}
					max={100}
					validate={percentRequired}
					formatter={percentFormat}
					parser={percentParse}
				/>
				<Field
					label="Booking fee cap"
					description="The maximum booking fee per ticket ( percent + fixed )"
					name="fees.bookingFeeCap"
					component={InputNumberField}
					precision={2}
					allowsNullValue
					min={0}
					formatter={currencyFormat}
					parser={currencyParse}
				/>

				<Field
					label="Payment fee percent"
					description="A payment fee which is uncapped"
					name="fees.paymentFeePercent"
					component={InputNumberField}
					precision={2}
					allowsNullValue
					formatter={percentFormat}
					parser={percentParse}
					min={0}
					max={100}
				/>

				<Field
					label="Amex fee surcharge percent"
					name="fees.amexFee"
					component={InputNumberField}
					precision={2}
					min={0}
					max={100}
					validate={amexFeeRequired}
					formatter={percentFormat}
					parser={percentParse}
				/>
				<div style={{ marginBottom: 12 }}>
					<Field
						label="Donation type"
						name="fees.donation.type"
						component={RadioField}
						defaultValue="percent"
						button
						options={donationTypes}
						validate={donationTypeRequired}
					/>
				</div>

				<Field
					label="Donation Amount"
					name="fees.donation.amount"
					component={InputNumberField}
					precision={2}
					formatter={(value: any) => {
						if (symbol === "%") {
							return percentFormat(value);
						}
						return currencyFormat(value);
					}}
					parser={(value: any) => {
						if (symbol === "%") {
							return percentParse(value);
						}
						return currencyParse(value);
					}}
					min={0}
					max={100}
					validate={donationAmountRequired}
				/>

				<H3>Donation fees</H3>
				<Note>Fees charged on client donation tickets</Note>
				<hr />
				<Field
					label="Donation fee fixed"
					description="The fixed fee charged per client donation"
					name="fees.donationFees.fixed"
					component={InputNumberField}
					precision={2}
					min={0}
					validate={fixedRequired}
					formatter={currencyFormat}
					parser={currencyParse}
				/>

				<Field
					label="Donation fee percent"
					name="fees.donationFees.percent"
					description="The percent fee charged per client donation"
					component={InputNumberField}
					precision={2}
					min={0}
					max={100}
					validate={percentRequired}
					formatter={percentFormat}
					parser={percentParse}
				/>

				<H3>Invoice fees</H3>
				<Note>The fee setting when customers pay by invoice</Note>
				<hr />
				<Field
					name="fees.invoiceFees.enabled"
					label="Enable invoice fees"
					component={SwitchField as any}
					defaultValue={false}
				/>
				{invoiceFees && invoiceFees.enabled ? (
					<div>
						<Field
							label="Booking fee fixed"
							description="The fixed fee charged per ticket"
							name="fees.invoiceFees.fixed"
							component={InputNumberField}
							precision={2}
							min={0}
							validate={fixedRequired}
							formatter={currencyFormat}
							parser={currencyParse}
						/>

						<Field
							label="Booking fee percent"
							name="fees.invoiceFees.percent"
							description="The percent fee charged per ticket"
							component={InputNumberField}
							precision={2}
							min={0}
							max={100}
							validate={percentRequired}
							formatter={percentFormat}
							parser={percentParse}
						/>
					</div>
				) : null}

				<H3>Rebate fees</H3>
				<hr />

				<Field
					label="Rebate fixed"
					description="A fixed rebate added on top of the fixed fee"
					name="fees.rebates.fixed"
					component={InputNumberField}
					precision={2}
					formatter={currencyFormat}
					parser={currencyParse}
					min={0}
				/>

				<Field
					label="Rebate percent"
					name="fees.rebates.percent"
					description="The percent charged on top of the fee percentage"
					component={InputNumberField}
					precision={2}
					formatter={percentFormat}
					parser={percentParse}
					min={0}
					max={100}
				/>

				<Field
					mode="multiple"
					name="fees.rebates.excludedGateways"
					label="Excluded Gateways"
					placeholder="None"
					component={SelectField}
					options={[
						{ value: "afterpay", label: "Afterpay" },
						{ value: "invoice", label: "Invoice" },
						{ value: "zipmoney", label: "Zip" }
					]}
				/>
			</div>
		</LoadErrorView>
	);
};
export default FeesInput;
