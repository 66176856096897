import { getConfig } from "@/config";
import { Dayjs, dayjs } from "@/libs/dayjs";
import { TimePicker } from "@/ui/antd";
import InputWrapper from "./helpers/InputWrapper";
import { FC } from "react";

type TimeFieldProps = {
	input: any;
	label: string;
	description: string;
	inline: boolean;
	required: boolean;
	tooltip: string;
	tooltipIcon: boolean;
	tooltipIconOnClick: any;
	meta: any;
	disabled: boolean;
	format: string;
	placeholder: string;
	onChange: any;
	timezone: string;
	value: any;
};

const TimeField: FC<TimeFieldProps> = ({
	input,
	label,
	description,
	inline,
	required,
	tooltip,
	tooltipIcon = false,
	tooltipIconOnClick,
	meta: { touched, error },
	disabled,
	placeholder,
	timezone
}) => {
	const tooltipObj = { tooltip };

	const tz = timezone || getConfig("TIMEZONE_DEFAULT");
	const fieldValue = input.value !== "" ? dayjs(input.value).tz(tz) : null;

	const handleDateChange = (time: Dayjs | Dayjs[]) => {
		if (Array.isArray(time)) {
			time = time[0];
		}

		const newTime = time ? dayjs(time).toISOString() : "";
		input.onChange?.(newTime);
	};

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			required={required}
			tooltip={tooltipObj}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<TimePicker
				id={input.name}
				onCalendarChange={handleDateChange}
				disabled={disabled}
				format="h:mm a"
				placeholder={placeholder}
				use12Hours={true}
				value={fieldValue}
				minuteStep={5}
			/>
		</InputWrapper>
	);
};

export default TimeField;
