import { locationDataByCountryCode } from "@hx/locations";
import { Button } from "@/ui/antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { sentenceCase } from "@/utils/Format";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";

const locationOptions = Object.values(locationDataByCountryCode).map((option) => {
	return {
		value: option.location,
		label: option.location
	};
});

const gateways = ["worldpay", "till", "stripe", "zipmoney", "stripe-payments", "afterpay", "pin", "braintree"];

const locationRequired = required("A location is required");
const gatewayRequired = required("A gateway is required");
const idRequired = required("This order id needs to be mapped to a field.");
const amountRequired = required("The amout needs to be mapped to a field.");
const formName = "MapFields";

let MapFields = ({ onNextStep, data, handleSubmit, dispatch }) => {
	const upload = (fields) => {
		onNextStep({ fields });
	};
	const { csv } = data;
	const headers = csv[0];
	const firstRow = csv[1];

	useEffect(() => {
		const idField = headers.findIndex((header) => header === "Merchant_TX_ID");
		const amountField = headers.findIndex((header) => header === "TransactionAmount");
		const settledDateField = headers.findIndex((header) => header === "SettledDate");
		dispatch(change(formName, "idField", idField));
		dispatch(change(formName, "amountField", amountField));
		dispatch(change(formName, "settlementField", settledDateField));
	}, []);

	const fieldOptions = headers.map((header, index) => {
		return {
			value: index,
			label: (
				<>
					{header} <span style={{ color: "grey", fontSize: 12 }}> ({firstRow[index]})</span>
				</>
			)
		};
	});

	return (
		<div>
			<form onSubmit={handleSubmit(upload)}>
				<ContentBlock>
					<Field
						name="Loction"
						label="location"
						placeholder="Select"
						required
						component={SelectField}
						options={locationOptions}
						style={{ width: "100%" }}
						validate={locationRequired}
					/>
					<Field
						name="gateway"
						label="Gateway"
						placeholder="Select"
						required
						component={SelectField}
						options={gateways.map((gateway) => {
							return { value: gateway, label: sentenceCase(gateway) };
						})}
						style={{ width: "100%" }}
						validate={gatewayRequired}
					/>
					<Field
						name="idField"
						label="Order Id Field"
						placeholder="Select"
						required
						component={SelectField}
						options={fieldOptions}
						style={{ width: "100%" }}
						validate={idRequired}
					/>
					<Field
						name="amountField"
						label="Amount Field"
						placeholder="Select"
						required
						component={SelectField}
						options={fieldOptions}
						style={{ width: "100%" }}
						validate={amountRequired}
					/>
					<Field
						name="settlementField"
						label="Settlement Date"
						placeholder="Select"
						required
						component={SelectField}
						options={fieldOptions}
						style={{ width: "100%" }}
						validate={amountRequired}
					/>
				</ContentBlock>
				<FormBar sticky={false}>
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						ariaLabel="Save"
						style={{
							float: "right"
						}}
					>
						Upload
					</Button>
				</FormBar>
			</form>
		</div>
	);
};

MapFields = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(MapFields);

const selector = formValueSelector(formName);

MapFields = connect((state) => {
	const fieldMapping = [];

	return {
		initialValues: {
			fields: fieldMapping,
			gateway: "braintree",
			location: "AU"
		},
		fieldMapping: selector(state, "fields")
	};
})(MapFields);

export default connect(
	(state) => ({
		currentEvent: state.currentEvent
	}),
	{}
)(MapFields);
