import { LegacyButton } from "@/components/buttons/LegacyButton";
import { css } from "@emotion/css";

import { defaultDeviceViewWrapperStyle, deviceActionButtonStyle } from "./shared";

const wrapperStyling = css({
	width: 375,
	height: "100%",
	position: "relative"
});

const iframeStyling = css({
	width: "100%",
	height: "100%",
	position: "absolute",
	left: 0,
	top: 0,
	border: "none"
});

const MobileDevice = ({ url }: { url: string }) => {
	return (
		<div className={wrapperStyling}>
			<iframe className={iframeStyling} src={url} />
		</div>
	);
};

export const MobileView = ({ url, onShowQRCodesClicked }: { url: string; onShowQRCodesClicked: () => void }) => {
	return (
		<div className={defaultDeviceViewWrapperStyle}>
			<div
				style={{
					alignSelf: "flex-end",
					background: "white",
					borderRadius: 4
				}}
			>
				<LegacyButton
					className={deviceActionButtonStyle}
					ariaLabel="View mobile options"
					onClick={onShowQRCodesClicked}
				>
					View on mobile
				</LegacyButton>
			</div>
			<MobileDevice url={url} />
		</div>
	);
};
