import { Input } from "@/ui/antd";
import { Component } from "react";
import InputLabel from "../../../components/InputLabel";
export default class SeatTextOverride extends Component {
	seatTextChange = (e) => {
		const value = e.target.value;
		const { performAction, elements } = this.props;
		performAction(
			elements.map((element) => element.overrideText),
			elements.map(() => [value])
		);
	};

	render() {
		const overrideText = this.props.elements[0].textOverride;
		const allTheSame = this.props.elements.reduce((isTrue, element) => {
			return isTrue && element.textOverride === overrideText;
		}, true);
		const value = allTheSame ? overrideText : "";
		return (
			<div style={{ overflow: "auto" }}>
				<InputLabel style={{ float: "left", paddingTop: 5 }}>Seat override</InputLabel>
				<Input value={value} onChange={this.seatTextChange} style={{ float: "right", width: "50%" }} />
			</div>
		);
	}
}
