import LocationSelection from "@/components/LocationSelection";
import Page from "@/components/Page";
import { getUserReferral } from "@/state/actions/referralActions";
import { locationDataByCountryCode } from "@hx/locations";
import { Tabs } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import Payment from "./Payment";
import Payout from "./Payout";
import ReferralProgram from "./ReferralProgram";

const locationOptions = Object.values(locationDataByCountryCode).map((option) => {
	return {
		value: option.location,
		label: option.currency
	};
});

class ReferralProgramLandingPage extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.state = {
			activeKey: "program"
		};
	}

	componentWillMount() {
		this.load();
	}

	load() {
		const { getUserReferral, auth } = this.props;
		const userId = auth?.user?.id;
		getUserReferral(userId);
	}

	render() {
		const { activeKey } = this.state;

		const locationSelector = (
			<LocationSelection defaultLevel="user" onLocationChange={this.load} overrideLocations={locationOptions} />
		);
		return (
			<Page pageName="Ambassador program" sibling={locationSelector}>
				<Tabs
					activeKey={activeKey}
					onChange={(activeKey) => this.setState({ activeKey })}
					items={[
						{
							label: "Program",
							key: "program",
							children: <ReferralProgram />
						},
						{
							label: "Payment settings",
							key: "payment-settings",
							children: <Payment />
						},
						{
							label: "Referral payouts",
							key: "referral-payouts",
							children: <Payout />
						}
					]}
				/>
			</Page>
		);
	}
}

export default connect(
	() => (state) => ({
		auth: state.auth
	}),
	{
		getUserReferral
	}
)(ReferralProgramLandingPage);
