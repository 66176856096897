import NoImage from "../components/NoImage";
import Picture from "../components/Picture";

const OrgniserImage = ({ name, logo }) => {
	const imageSize = 80;

	return (
		<>
			{logo ? (
				<Picture
					handle={logo.handle}
					width={imageSize}
					height={imageSize}
					alt={name}
					borderRadii={{ topLeft: imageSize, topRight: imageSize, bottomLeft: imageSize, bottomRight: imageSize }}
				/>
			) : (
				<NoImage
					width={imageSize}
					height={imageSize}
					borderRadii={{ topLeft: imageSize, topRight: imageSize, bottomLeft: imageSize, bottomRight: imageSize }}
				/>
			)}
		</>
	);
};

export default OrgniserImage;
