import { css } from "@emotion/css";
import IconButton from "../IconButton";

const InfoTitle = (props) => {
	const { title, tooltip, onClick, bold = false } = props;

	const textStyle = css(
		props.style || {
			margin: "8px 0 0 !important",
			fontWeight: bold ? "600" : "400"
		}
	);

	return (
		<div
			style={{
				display: "inline-block"
			}}
		>
			<div style={{ display: "flex" }}>
				<span className={textStyle}>{title}</span>
				<IconButton
					icon="info_hover"
					onClick={onClick}
					tooltip={tooltip}
					showTooltip
					style={{ float: "left", marginLeft: 3 }}
					small
					ariaLabel="Info title"
				/>
			</div>
		</div>
	);
};

export default InfoTitle;
