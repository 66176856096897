import { Modal } from "@/ui/antd";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { LegacyInputField, TextAreaField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";

const titleRequired = required("Title required");
const messageRequired = required("Message required");

let CustomInfoModal = ({ editingIndex, visible, handleSubmit, handleCancel, handleSave }) => {
	const newMessage = editingIndex === null || editingIndex < 0;
	const saveMsg = newMessage ? "Add" : "Update";

	const save = (values, editingIndex) => {
		handleSave(values, editingIndex);
	};

	return (
		<Modal
			open={visible}
			title="Add Message"
			destroyOnClose
			onCancel={handleCancel}
			width={700}
			footer={
				<ButtonsBar footer>
					<LegacyButton key="cancel" onClick={handleCancel} htmlType="button" ariaLabel="Cancel">
						Cancel
					</LegacyButton>
					<LegacyButton
						onClick={handleSubmit((values) => save(values, editingIndex))}
						key="submit"
						type="primary"
						htmlType="submit"
						ariaLabel={saveMsg}
					>
						{saveMsg}
					</LegacyButton>
				</ButtonsBar>
			}
		>
			{visible ? (
				<form onSubmit={handleSubmit((values) => save(values, editingIndex))}>
					<Field
						label="Title"
						name="title"
						placeholder="e.g. Wheelchair access info"
						component={LegacyInputField}
						validate={titleRequired}
						required
					/>
					<Field
						label="Message"
						name="message"
						placeholder="e.g. The venue has three wheelchair accessible entrances"
						component={TextAreaField}
						minHeight={150}
						validate={messageRequired}
						required
					/>
				</form>
			) : null}
		</Modal>
	);
};
const formName = "customInfoModal";
CustomInfoModal = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	destroyOnUnmount: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(CustomInfoModal);

export default connect((state, ownProps) => {
	const { customInfo, editingIndex } = ownProps;
	const initialValues =
		editingIndex === null || editingIndex < 0 ? { title: "", message: "" } : customInfo[editingIndex];

	return {
		initialValues,
		currentEvent: state.currentEvent,
		destroyOnUnmount: true,
		enableReinitialize: true
	};
}, {})(CustomInfoModal);
