import { css } from "@emotion/css";
import { Views } from "@hx/seating-map";
import { Component } from "react";
import { ObjectElement, SeatingElement, Text } from "@/assets/seatingMap";
import ElementButton from "../../components/ElementButton";
import { ObjectSelection, TableSelection } from "./../Menus";
const { SeatingConstruction } = Views;
//Upload floorplan image as a guide. This wont be visible to customers.
const options = [
	{
		id: "seating",
		elementImage: SeatingElement,
		name: "Seating",
		toolTip: "Add seating"
	},
	{
		id: "object",
		elementImage: ObjectElement,
		name: "Object",
		toolTip: "Add object"
	},
	{ id: "text", elementImage: Text, name: "Text", toolTip: "Add text" }
	// {
	//   id: "uploadGuide",
	//   elementImage: UploadGuides,
	//   name: "Guides",
	//   toolTip:
	//     "Upload floorplan image as a guide. This wont be visible to customers."
	// }
];

export default class ElementSelector extends Component {
	constructor(props) {
		super(props);
		this.tableMap = SeatingConstruction.getInstance();
		this.onOptionClick = this.onOptionClick.bind(this);
		this.state = {
			selectedMenu: 0,
			tablesSelected: false
		};
	}

	onOptionClick(id) {
		this.props.onElementSelection();
		this.tableMap.unselect();
		if (id === "text") {
			this.tableMap.addText();
		}
		this.setState({ selectedMenu: id });
	}

	getMenu() {
		const elementId = this.state.selectedMenu;
		let menu = null;
		switch (elementId) {
			case "seating":
				menu = <TableSelection />;
				break;
			case "object":
				menu = <ObjectSelection />;
				break;
			// case "text":
			//   menu = <TextSelection />;
			//   break;
			default:
				break;
		}
		if (menu) {
			return (
				<div
					style={{
						margin: 0,
						background: "#70697a",
						width: 120,
						height: "max-content",
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4
					}}
				>
					{menu}
				</div>
			);
		}
	}

	render() {
		const { showSelection } = this.props;
		const elementId = this.state.selectedMenu;
		const locationOfSelectedElement = options.findIndex((props) => elementId === props.id);
		return (
			<div
				style={{
					margin: "auto",
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row"
				}}
			>
				<div
					style={{
						width: 120,
						overflow: "auto",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#70697a"
					}}
				>
					{options.map((props, i) => (
						<Option
							topOfSelected={locationOfSelectedElement - 1 === i}
							bottomOfSelected={locationOfSelectedElement + 1 === i}
							{...props}
							isSelected={elementId === props.id}
							key={i}
							index={i}
							onOptionClick={this.onOptionClick}
						/>
					))}
					<div
						style={{
							flexGrow: 1,
							backgroundColor: "#4e4757",
							borderTopRightRadius: locationOfSelectedElement === options.length - 1 ? 4 : 0
						}}
					/>
				</div>
				{/* <SeatingElement type={}/> */}
				{showSelection ? this.getMenu() : null}
			</div>
		);
	}
}

const elementWrapperStyle = css({
	paddingTop: 10,
	"&:firstChild": {
		paddingTop: 20
	},
	paddingLeft: 24,
	paddingRight: 24,
	paddingBottom: 10,
	backgroundColor: "#4e4757"
});

const Option = ({
	id,
	name,
	elementImage,
	onOptionClick,
	isSelected,
	index,
	topOfSelected,
	bottomOfSelected,
	toolTip
}) => {
	const extraStyle = {};
	if (index === 0) {
		extraStyle.paddingTop = 20;
	}
	if (isSelected) {
		extraStyle.background = "#70697a";
	}
	if (topOfSelected) {
		extraStyle.borderBottomRightRadius = "4px";
	}
	if (bottomOfSelected) {
		extraStyle.borderTopRightRadius = "4px";
	}
	return (
		<div className={elementWrapperStyle} style={extraStyle}>
			<ElementButton
				id={id}
				name={name}
				elementImage={elementImage}
				onClick={() => onOptionClick(id)}
				toolTip={toolTip}
				isSelected={isSelected}
			/>
		</div>
	);
};
