export const EventSkeleton = () => {
	return (
		<svg viewBox="0 0 1200 2923" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: "block" }}>
			<path fill="var(--colorv3ConsoleEventStylingPreview-background-base)" d="M0 0h1200v2923H0z" />
			<rect
				x="40"
				y="16"
				width="216"
				height="40"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<path fill="#7F7F7F" d="M0 72h1200v291H0z" />
			<rect
				x="80"
				y="591"
				width="1040"
				height="188"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<rect
				x="771"
				y="427"
				width="349"
				height="124"
				rx="4"
				stroke="var(--colorv3ConsoleEventStylingPreview-sunken-base)"
			/>
			<rect
				x="787"
				y="479"
				width="317"
				height="56"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="80"
				y="499"
				width="595"
				height="52"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="467"
				width="595"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="907"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="907"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="1824"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="2168"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="2016"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="619"
				y="2016"
				width="133"
				height="24"
				rx="4"
				stroke="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="760"
				y="2016"
				width="133"
				height="24"
				rx="4"
				stroke="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="1219"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="1395"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="907"
				width="642"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="1443"
				width="224"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="567"
				y="1837"
				width="224"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="80"
				y="1465"
				width="163"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="567"
				y="1859"
				width="163"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="478"
				y="955"
				width="513"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="606"
				y="1075"
				width="513"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="606"
				y="1944"
				width="513"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="879"
				y="931"
				width="241"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="931"
				width="302"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="786"
				y="931"
				width="86"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="998"
				y="955"
				width="122"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="478"
				y="1076"
				width="122"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="478"
				y="1944"
				width="122"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="698"
				y="1003"
				width="87"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="973"
				y="1099"
				width="86"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="973"
				y="1968"
				width="86"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="1099"
				width="268"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="1968"
				width="268"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="979"
				width="642"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="1051"
				width="642"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="1920"
				width="642"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="478"
				y="1003"
				width="214"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="752"
				y="1099"
				width="214"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="752"
				y="1968"
				width="214"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-primary-base)"
			/>
			<rect
				x="80"
				y="427"
				width="133"
				height="24"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="787"
				y="443"
				width="56"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="960"
				y="443"
				width="144"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<path
				stroke="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
				d="M80.5 842.5h1039M80.5 1178.5h1039M80.5 1330.5h1039M80.5 1759.5h1039M80.5 2103.5h1039"
			/>
			<circle cx="96" cy="1275" r="16" fill="var(--colorv3ConsoleEventStylingPreview-primary-base)" />
			<circle cx="144" cy="1275" r="16" fill="var(--colorv3ConsoleEventStylingPreview-primary-base)" />
			<circle cx="192" cy="1275" r="16" fill="var(--colorv3ConsoleEventStylingPreview-primary-base)" />
			<circle cx="240" cy="1275" r="16" fill="var(--colorv3ConsoleEventStylingPreview-primary-base)" />
			<circle cx="288" cy="1275" r="16" fill="var(--colorv3ConsoleEventStylingPreview-primary-base)" />
			<rect x="478" y="1395" width="642" height="301" rx="4" fill="#7F7F7F" />
			<rect
				x="479"
				y="1824"
				width="64"
				height="64"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<path fill="#333" d="M0 2614h1200v309H0z" />
			<rect
				x="80"
				y="2218"
				width="248"
				height="330"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<path
				d="M80 2508.3h248v37.7a4 4 0 0 1-4 4H84a4 4 0 0 1-4-4v-37.8Z"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<rect
				x="237"
				y="2508"
				width="75.2"
				height="29.8"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="92"
				y="2508"
				width="40"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="92"
				y="2524"
				width="64"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<path d="M80 2222a4 4 0 0 1 4-4h240a4 4 0 0 1 4 4v119.3H80V2222Z" fill="#7F7F7F" />
			<rect
				x="92"
				y="2359"
				width="208"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="92"
				y="2443"
				width="220"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium7)"
			/>
			<rect
				x="92"
				y="2389"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="92"
				y="2413"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="344"
				y="2218"
				width="248"
				height="330"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<path
				d="M344 2508.3h248v37.7a4 4 0 0 1-4 4H348a4 4 0 0 1-4-4v-37.8Z"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<rect
				x="501"
				y="2508"
				width="75.2"
				height="29.8"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="356"
				y="2508"
				width="40"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="356"
				y="2524"
				width="64"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<path d="M344 2222a4 4 0 0 1 4-4h240a4 4 0 0 1 4 4v119.3H344V2222Z" fill="#7F7F7F" />
			<rect
				x="356"
				y="2359"
				width="208"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="356"
				y="2443"
				width="220"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="356"
				y="2389"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="356"
				y="2413"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="608"
				y="2218"
				width="248"
				height="330"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<path
				d="M608 2508.3h248v37.7a4 4 0 0 1-4 4H612a4 4 0 0 1-4-4v-37.8Z"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<rect
				x="765"
				y="2508"
				width="75.2"
				height="29.8"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="620"
				y="2508"
				width="40"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="620"
				y="2524"
				width="64"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<path d="M608 2222a4 4 0 0 1 4-4h240a4 4 0 0 1 4 4v119.3H608V2222Z" fill="#7F7F7F" />
			<rect
				x="620"
				y="2359"
				width="208"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="620"
				y="2443"
				width="220"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="620"
				y="2389"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="620"
				y="2413"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="872"
				y="2216"
				width="248"
				height="330"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<path
				d="M872 2506.3h248v37.7a4 4 0 0 1-4 4H876a4 4 0 0 1-4-4v-37.8Z"
				fill="var(--colorv3ConsoleEventStylingPreview-raised-base)"
			/>
			<rect
				x="1029"
				y="2506"
				width="75.2"
				height="29.8"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="884"
				y="2506"
				width="40"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="884"
				y="2522"
				width="64"
				height="14"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<path d="M872 2220a4 4 0 0 1 4-4h240a4 4 0 0 1 4 4v119.3H872V2220Z" fill="#7F7F7F" />
			<rect
				x="884"
				y="2357"
				width="208"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="884"
				y="2441"
				width="220"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-medium)"
			/>
			<rect
				x="884"
				y="2387"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
			<rect
				x="884"
				y="2411"
				width="200"
				height="16"
				rx="4"
				fill="var(--colorv3ConsoleEventStylingPreview-background-text-high)"
			/>
		</svg>
	);
};
