import IconReference from "@/services/IconReference";
import { Flex, Select } from "@/ui/antd";
import { Location, locationDataByCountryCode } from "@hx/locations";
import { FC } from "react";

const locationItems = Object.values(locationDataByCountryCode).map((option) => {
	return {
		value: option.location,
		label: option.currency
	};
});

type LocationSelectProps = {
	defaultValue?: Location;
	locations?: Location[];
	onChange?: (value: Location) => void;
	placeholder?: string;
	value?: Location;
	width?: string;
};

export const LocationSelect: FC<LocationSelectProps> = ({
	defaultValue,
	locations,
	onChange,
	placeholder,
	value,
	width = "170px"
}) => {
	const allowableLocations = locations
		? locationItems.filter((option) => locations.includes(option.value))
		: locationItems;

	const locationOptions = allowableLocations.map((option) => {
		const icon = String(IconReference[`${option.value.toLowerCase()}_flag`]);

		return {
			value: option.value,
			label: (
				<Flex gap="sm">
					<img src={icon} alt={`${location} flag`} />
					<span>{option.label}</span>
				</Flex>
			)
		};
	});

	return (
		<Select
			defaultValue={defaultValue}
			onChange={(value) => onChange?.(value as Location)}
			options={locationOptions}
			placeholder={placeholder}
			style={{ minWidth: width }}
			value={value}
		/>
	);
};
