import { State } from "@hx/seating-map";
import { Connectors } from "@hx/seating-map-toolbar";
import { Alert } from "@/ui/antd";
import { connect } from "react-redux";
import Icon from "@/components/Icon";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { Note, P } from "@/components/text/Text";
import { moveBulk, moveIndividual, unAssign } from "@/state/actions/seatingMapActions";
const { ConnectTicketManagement } = Connectors;
const { AssigningManager } = State.Assigning;

let AssigningBulk = ({ order, organiser, focus, orderAssigningComplete, moveBulk }) => {
	const { error } = organiser;
	const { displayName, orderName, tickets, assigningData } = order;
	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "row-reverse",
					padding: "13px 17px",
					cursor: "pointer"
				}}
				onClick={() => AssigningManager.reset()}
			>
				<P style={{ fontWeight: 600, marginBottom: 0, marginTop: 2 }}>Back</P>
				<Icon style={{ marginRight: 12 }} icon="left_small" />
			</div>
			{error ? <Alert type="error" message={error} /> : null}
			<div
				style={{
					background: "white",
					padding: "16px 12px",
					flex: "1 1 auto",
					overflowY: "auto"
				}}
			>
				<P style={{ fontWeight: 600, marginBottom: 2 }}>{displayName}</P>
				<P style={{ marginBottom: 2 }}>{`Order: ${orderName}`}</P>
				<P>{`${tickets.length} tickets`}</P>
				<div style={{ display: "flex", flexDirection: "column" }}>
					{tickets.map((ticket, i) => {
						return <TicketCard {...ticket} index={i} active={focus === i} to={assigningData[i].to} />;
					})}
				</div>
			</div>
			<div style={{ borderTop: "solid 1px #dedede", padding: "16px 12px" }}>
				<div style={{ width: "100%" }}>
					<LegacyButton
						onClick={moveBulk}
						disabled={!orderAssigningComplete}
						type="secondary"
						style={{ width: "100%", marginBottom: 8 }}
					>
						Save
					</LegacyButton>
				</div>
				<div style={{ width: "100%" }}>
					<LegacyButton onClick={() => AssigningManager.reset()} style={{ width: "100%" }}>
						Cancel
					</LegacyButton>
				</div>
			</div>
		</>
	);
};

const TicketCard = ({ index, ticketTypeName, active, to }) => {
	return (
		<div
			onClick={() => AssigningManager.changeFocus(index)}
			style={{
				cursor: "pointer",
				borderRadius: 4,
				border: "solid 1px #dedede",
				marginBottom: 8,
				position: "relative"
			}}
		>
			<div style={{ padding: 12 }}>
				<div style={{ display: "flex" }}>
					<div style={{ flexGrow: 1 }}>
						<P style={{ fontWeight: 600, marginBottom: 2 }}>{`Ticket ${index + 1}`}</P>
						<Note bold={false}>{ticketTypeName}</Note>
					</div>
					{to ? <Icon icon="tick" /> : null}
				</div>
				<div style={{ border: "solid 1px #dedede" }} />
				<P style={{ marginBottom: 0 }}>{to ? to.selectedObject.getFullName() : "Unassigned"}</P>
			</div>
			<div
				style={{
					display: active ? "inherit" : "none",
					position: "absolute",
					height: 3,
					backgroundColor: "#353337",
					borderRadius: 100,
					bottom: 0,
					width: "100%"
				}}
			/>
		</div>
	);
};

AssigningBulk = ConnectTicketManagement({
	order: "order",
	focus: "focus",
	orderAssigningComplete: "orderAssigningComplete"
})(AssigningBulk);

export default connect(
	(state) => ({
		organiser: state.seatingMap.organiser
	}),
	{ moveIndividual, unAssign, moveBulk }
)(AssigningBulk);
