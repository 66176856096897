import { SelectField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { getEvents } from "@/state/actions/authorizationActions";
import { ReduxState as GlobalReduxState } from "@/state/store";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

type ReduxActions = {
	getEvents: ({
		page,
		issueFor,
		toAppend,
		query
	}: {
		page: number;
		issueFor: string;
		toAppend?: boolean;
		query?: string;
	}) => void;
};

type ReduxInput = GlobalReduxState & ReduxActions;

interface IEventSelectionProps {
	issueFor: string;
}

type EventSelectionInput = IEventSelectionProps & ReduxInput;
let searchEventTimeout: ReturnType<typeof setTimeout>;

const EventSelection = ({ authorization, getEvents, issueFor, search }: EventSelectionInput) => {
	const [loading, setLoading] = useState(false);
	const { events, eventsLoading, eventsError } = authorization ?? {};

	const debouncedFindEvents = ({
		page = 1,
		toAppend = false,
		query
	}: {
		page?: number;
		toAppend?: boolean;
		query?: string;
	}) => {
		clearTimeout(searchEventTimeout);
		searchEventTimeout = setTimeout(() => {
			getEvents({ page, issueFor, toAppend, query });
			setLoading(false);
		}, 200);
	};

	useEffect(() => {
		setLoading(true);
		debouncedFindEvents({});
	}, []);

	const _handleSearch = (query: string) => {
		if (query.trim().length > 2) {
			debouncedFindEvents({ page: 1, query });
		}
	};

	const _handleBlur = () => debouncedFindEvents({ page: 1, query: "" });

	const _handleScroll = (e: any) => {
		const page = (search?.page as number) ?? 1;
		const target = e.target;

		if (loading) return;

		if (Math.round(target.scrollTop + target.offsetHeight) >= target.scrollHeight) {
			const nextPage = Math.round(page + 1);
			debouncedFindEvents({ page: nextPage, toAppend: true });
			target.scrollTo(0, target.scrollHeight);
		}
	};

	return (
		<LoadErrorView loading={!!eventsLoading} error={eventsError}>
			<Field
				style={{ width: "100%" }}
				mode="multiple"
				name="events"
				placeholder="All Events"
				component={SelectField}
				notFoundContentLoading={loading}
				showSearch
				onSearch={_handleSearch}
				onBlur={_handleBlur}
				onPopupScroll={_handleScroll}
				options={(events?.[issueFor] || []).map((event: any) => ({
					value: event._id,
					label: event.name
				}))}
			/>
		</LoadErrorView>
	);
};

export default connect(
	(state: GlobalReduxState) => ({
		authorization: state.authorization
	}),
	{ getEvents }
)(EventSelection);
