import { CodeBlock, InfoTooltip } from "@/ui";
import { Flex } from "@/ui/antd";
import { FC } from "react";

const props = `type InfoTooltipProps = {
	marginLeft?: string;
	marginRight?: string;
	placement?: TooltipProps["placement"];
	title: TooltipProps["title"];
};`;

const sample = `import { InfoTooltip } from "@/ui";

<span>
  Form item
  <InfoTooltip title="This is a tooltip" />
</span>
`;

export const InfoTooltipPage: FC = () => {
	return (
		<Flex gap="lg" vertical>
			<b>Default</b>
			<span>
				Form item
				<InfoTooltip title="This is a tooltip" />
			</span>

			<br />
			<CodeBlock codeString={props} title="props" />
			<CodeBlock codeString={sample} title="sample" />
		</Flex>
	);
};
