import ColourPicker from "@/components/ColourPicker";
import { useAppSelector } from "@/state/hooks";
import { FormItemRow, FormLabel, HR, RadioGroup, Spacer } from "@/ui";
import { Checkbox, Col, Flex, Form, FormInstance, Space, Switch } from "@/ui/antd";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import { FC } from "react";

type ConfigPanelLayoutProps = {
	form: FormInstance;
	open: boolean;
};

export const ConfigPanelLayout: FC<ConfigPanelLayoutProps> = ({ form, open }) => {
	const formValues = useWatch<Collection>([], form);
	const user = useAppSelector((state) => state.auth.user);

	if (!formValues) return null;

	return (
		<div style={{ display: open ? "block" : "none" }}>
			<Space direction="vertical" size={12} style={{ width: "100%" }}>
				{/* STACK RECURRING EVENTS */}
				<FormItemRow>
					<FormLabel
						noMargin
						tooltip="Show recurring events on a single card with the latest event date and total count of occurrences."
					>
						Stack recurring events
					</FormLabel>
					<Form.Item name={["layout", "stackRecurringEvents"]} noStyle valuePropName="checked">
						<Switch style={{ margin: 0 }} />
					</Form.Item>
				</FormItemRow>
				{/* SHOW CALENDAR */}
				<FormItemRow>
					<FormLabel noMargin>Show calendar</FormLabel>
					<Form.Item name={["layout", "showCalendar"]} noStyle valuePropName="checked">
						<Switch style={{ margin: 0 }} />
					</Form.Item>
				</FormItemRow>
				{/* SHOW SEARCH BAR */}
				<FormItemRow>
					<FormLabel noMargin>Show search bar</FormLabel>
					<Form.Item name={["layout", "showSearchBar"]} noStyle valuePropName="checked">
						<Switch style={{ margin: 0 }} />
					</Form.Item>
				</FormItemRow>
				{/* SHOW FILTERS */}
				<Flex vertical>
					<FormLabel>Show filters</FormLabel>
					<Form.Item
						hidden={!user.isTagsEnabled}
						name={["layout", "filters", "showTags"]}
						noStyle
						valuePropName="checked"
					>
						<Checkbox>Tags</Checkbox>
					</Form.Item>
					<Spacer size="sm" />
					<Form.Item name={["layout", "filters", "showHostProfiles"]} noStyle valuePropName="checked">
						<Checkbox>Host profiles</Checkbox>
					</Form.Item>
					<Spacer size="sm" />
					<Form.Item name={["layout", "filters", "showAccessibility"]} noStyle valuePropName="checked">
						<Checkbox>Accessibility</Checkbox>
					</Form.Item>
				</Flex>
				<HR />
				{/* PRIMARY COLOUR */}
				<Col>
					<FormLabel>Primary colour</FormLabel>
					<FormItemRow>
						<p style={{ margin: 0 }}>Primary colours change the colour of the button & links</p>
						<Form.Item name={["layout", "theme", "primaryColor"]} noStyle>
							<ColourPicker
								onChange={(color) => form.setFieldsValue({ layout: { theme: { primaryColor: color.hex } } })}
							/>
						</Form.Item>
					</FormItemRow>
				</Col>
				{/* THEME MODE */}
				<Col>
					<FormLabel>Light/Dark mode</FormLabel>
					<p>Choose Your Look: Light, Dark, or Adaptive Mode (Adapts to visitor's device preference)</p>
					<Form.Item name={["layout", "theme", "colorScheme"]} noStyle>
						<RadioGroup
							options={[
								{ label: "Light", value: "light" },
								{ label: "Dark", value: "dark" },
								{ label: "Adaptive", value: "auto" }
							]}
						/>
					</Form.Item>
				</Col>
			</Space>
		</div>
	);
};
