import {
	CLEAR_VIRTUAL_EVENT,
	CLOSE_ENABLED_MODAL,
	CLOSE_PREVIEW_MODAL,
	CREATE_VIRTUAL_EVENT_ERROR,
	CREATE_VIRTUAL_EVENT_REQUEST,
	CREATE_VIRTUAL_EVENT_SUCCESS,
	GET_VIRTUAL_EVENT_ERROR,
	GET_VIRTUAL_EVENT_REQUEST,
	GET_VIRTUAL_EVENT_SUCCESS,
	OPEN_ENABLED_MODAL,
	OPEN_PREVIEW_MODAL,
	SAVE_VIRTUAL_EVENT_ERROR,
	SAVE_VIRTUAL_EVENT_REQUEST,
	SAVE_VIRTUAL_EVENT_SUCCESS
} from "../actions/virtualEventHubActions";

const initialState = {
	data: false,
	loading: false,
	error: false,
	saved: false,
	previewModalOpen: false,
	enabledModalOpen: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_VIRTUAL_EVENT: {
			return initialState;
		}
		case OPEN_PREVIEW_MODAL:
			return {
				...state,
				previewModalOpen: true
			};
		case CLOSE_PREVIEW_MODAL:
			return {
				...state,
				previewModalOpen: false
			};
		case OPEN_ENABLED_MODAL:
			return {
				...state,
				enabledModalOpen: true
			};
		case CLOSE_ENABLED_MODAL:
			return {
				...state,
				enabledModalOpen: false
			};
		case GET_VIRTUAL_EVENT_REQUEST:
			return {
				...state,
				loading: true,
				data: null,
				error: false,
				saved: false
			};
		case GET_VIRTUAL_EVENT_SUCCESS:
			return {
				...state,
				data: action.virtualEventHub,
				loading: false,
				error: false
			};
		case GET_VIRTUAL_EVENT_ERROR:
			return {
				data: false,
				loading: false,
				error: action.error
			};
		case SAVE_VIRTUAL_EVENT_REQUEST:
		case CREATE_VIRTUAL_EVENT_REQUEST:
			return {
				...state,
				loading: true,
				data: action.virtualEventHub,
				error: false
			};
		case SAVE_VIRTUAL_EVENT_SUCCESS:
		case CREATE_VIRTUAL_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.virtualEventHub,
				error: false,
				saved: true
			};
		case SAVE_VIRTUAL_EVENT_ERROR:
		case CREATE_VIRTUAL_EVENT_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		default:
			return state;
	}
};
