import successTick from "@/assets/success-tick.gif";
import { H1, P } from "@/components/text/Text";
import { useConfetti } from "@/hooks/useConfetti";
import { closePublishedModal } from "@/state/actions/eventActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Modal } from "@/ui/antd";
import { css } from "@emotion/css";
import { FC, useEffect } from "react";
import { EventSharingOptions } from "./EventSharingButton";

const contentWrapper = css({
	marginBottom: 24,
	textAlign: "center"
});

const subTitle = {
	fontSize: 18
};

export const PostPublishModal: FC = () => {
	const fireConfetti = useConfetti();
	const dispatch = useAppDispatch();
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const isModalOpen = currentEvent?.publish?.publishedModalOpen;

	useEffect(() => {
		if (isModalOpen) {
			fireConfetti();
		}
	}, [isModalOpen]);

	return (
		<Modal
			open={currentEvent?.publish?.publishedModalOpen}
			title=""
			onCancel={() => dispatch(closePublishedModal())}
			destroyOnClose
			footer={null}
		>
			<div className={contentWrapper} data-cy="published-modal">
				<img
					src={successTick}
					alt="Success tick"
					height={50}
					width={50}
					style={{
						margin: 16
					}}
				/>
				<div>
					<H1>Your event is live!</H1>
					<P style={subTitle}>Let's start promoting it</P>
				</div>
			</div>

			<EventSharingOptions event={currentEvent.event} onNavigating={() => dispatch(closePublishedModal())} />
		</Modal>
	);
};
