import { getConfig } from "@/config";
import { WidgetAddToSiteButton } from "@/events/event/widgets/WidgetAddToSiteButton";
import { useAppSelector } from "@/state/hooks";
import { CodeBlock } from "@/ui";
import { Alert, Input, Switch } from "@/ui/antd";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

const Subheading = styled.h3`
	font-size: 16px;
	font-weight: 600;
	margin: 0 0 12px;
`;

const createSampleCode = (
	eventsDomain: string,
	options: { eventSlug: string; widgetEnv: string; queryParamsAttr: string }
) => `<!-- Add the following to your website's HTML. -->
<script src="${eventsDomain}/scripts/widgets/inline.js" type="module"></script>
<iframe data-checkout="${options.eventSlug}"${options.widgetEnv}${options.queryParamsAttr}></iframe>`;

export const CheckoutWidgetBuilderConfig: FC = () => {
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const [disableAnalytics, setDisableAnalytics] = useState(false);
	const [accessCode, setAccessCode] = useState("");
	const [discountCode, setDiscountCode] = useState("");
	const [affiliateTracking, setAffiliateTracking] = useState("");

	const queryParams = new URLSearchParams();
	if (accessCode) queryParams.set("accessCode", accessCode);
	if (affiliateTracking) queryParams.set("c", affiliateTracking);
	if (discountCode) queryParams.set("discountCode", discountCode);
	if (disableAnalytics) queryParams.set("da", "true");

	const queryParamsAttr = queryParams.size > 0 ? ` data-params="${queryParams.toString()}"` : "";
	const eventsUrl = getConfig("EVENTS_URL") ?? "https://events.humanitix.com";
	const sampleCode = createSampleCode(eventsUrl, {
		eventSlug: currentEvent.event?.slug ?? "",
		queryParamsAttr,
		widgetEnv: ""
	});

	return (
		<>
			<Alert
				message={
					<div>
						Please note that the styling of the Embedded Checkout is based on the styles configured for Checkout on the
						Styling page. To customise the appearance of your Embedded Checkout widget, go to the{" "}
						<Link to={`/console/my-events/${currentEvent.event?._id}/styling`}>Styling page</Link>.
					</div>
				}
				showIcon
				type="info"
			/>
			<br />
			<Subheading>Disable analytics integrations</Subheading>
			<p>
				When active, any analytics integrations associated with this event will be disabled within the widget itself.{" "}
				<Link to="https://help.humanitix.com/en/articles/8950947">Learn more</Link>.
			</p>
			<Switch checked={disableAnalytics} onChange={(checked) => setDisableAnalytics(checked)} />
			<br />
			<Subheading>Discount code</Subheading>
			<p>To apply a discount code to the tickets widget, enter the code below.</p>
			<Input placeholder="Discount code" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
			<br />
			<Subheading>Access code</Subheading>
			<p>To apply an access code to the tickets widget, enter the code below.</p>
			<Input placeholder="Access code" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
			<br />
			<Subheading>Affiliate tracking</Subheading>
			<p>When active, the widget will track the affiliate code in the URL and apply it to the checkout page.</p>
			<Input
				placeholder="Affiliate code"
				value={affiliateTracking}
				onChange={(e) => setAffiliateTracking(e.target.value)}
			/>
			<br />
			<Subheading>Configure embedded checkout</Subheading>
			<p>
				To embed the checkout on your site, take the code from the Add to site button below and add it to your website.
			</p>
			<WidgetAddToSiteButton>
				<Alert
					message="If you are using a website builder, you may need to add the code directly to the HTML of your page rather than using a
					visual editor component."
					showIcon
					type="info"
				/>
				<br />
				<CodeBlock codeString={sampleCode} language="htmlbars" title="HTML" />
			</WidgetAddToSiteButton>
		</>
	);
};
