import { Global, css } from "@emotion/react";

export const AntdOverrides = () => (
	<Global
		styles={css`
			#root,
			.ant-layout {
				height: 100%;
			}

			a {
				color: #196097;
				text-decoration: none;
			}

			a:hover {
				color: #157ac7;
			}

			th {
				text-align: start;
			}

			.ant-layout-content {
				border-radius: var(--rounded-sm);
				box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
			}

			.ant-layout {
				background: var(--surface1);
			}

			.ant-form-item-label label:after {
				content: " ";
			}

			.ant-empty-description {
				max-width: 100% !important;
			}

			.ant-empty-image {
				height: 50px !important;
			}

			.ant-modal-close {
				color: #67717e;
			}

			.ant-collapse-content-box {
				background-color: var(--surface1);
			}

			.ant-input,
			.ant-input-number,
			.ant-time-picker-input {
				min-width: 70px;
				border-radius: var(--rounded-sm);
			}

			.ant-table {
				font-size: 14px;
				line-height: normal;
			}

			.ant-table-content {
				border: var(--border1);
				border-radius: var(--rounded-md);
				overflow: hidden;
			}

			.ant-table-wrapper .ant-table-tbody > tr > td {
				border: none;
			}

			.ant-menu-light.ant-menu-root.ant-menu-inline {
				border: none;
			}

			.ant-table-thead > tr {
				background: var(--surface2);
			}

			.ant-table-thead > tr > th {
				font-weight: 600;
				background: var(--surface2);
				line-height: normal;
			}

			.ant-table-row td {
				vertical-align: middle;
				line-height: normal;
			}

			.ant-table-button-icon {
				cursor: pointer !important;
			}

			.ant-table-button-icon:hover {
				content: url(../ic_settings_hover.svg);
			}

			.ant-table-expanded-row td {
				border: none;
			}

			.ant-table-expanded-row-no-border td {
				border-bottom: none !important;
			}

			.ant-table-expanded-row-no-border:hover td {
				background: white !important;
			}

			.ant-table-row-expand-icon {
				border-radius: 32px;
				width: 32px;
				height: 32px;
			}

			.ant-table-row-expand-icon-cell {
				vertical-align: top;
			}

			.ant-pagination .ant-pagination-item-active {
				border: var(--border1);

				:hover {
					border: var(--border1-hover);
				}

				a {
					color: var(--on-surface1);
					:hover {
						color: var(--on-surface1);
					}
				}
			}

			.ant-btn {
				border-color: #8e99a8;
				border-radius: var(--rounded-sm);
			}

			.ant-btn-dangerous {
				border-color: #c0212c !important;
				color: #c0212c !important;
				background: transparent !important;
			}

			.ant-btn-dangerous:hover {
				background: #ebb9bc !important;
			}

			.ant-btn-primary a {
				color: var(--surface1);
			}

			.ant-btn-primary[disabled],
			.ant-btn-primary.disabled:hover,
			.ant-btn-primary[disabled]:hover,
			.ant-btn-primary.disabled:focus,
			.ant-btn-primary[disabled]:focus,
			.ant-btn-primary.disabled:active,
			.ant-btn-primary[disabled]:active,
			.ant-btn-primary.disabled.active,
			.ant-btn-primary[disabled].active {
				color: rgba(0, 0, 0, 0.25);
				background: var(--surface3);
				border: 1px solid #8d949e;
			}

			.ant-btn .anticon.anticon-plus {
				font-weight: 900;
				font-size: 18px;
			}

			.input-number-select .ant-select {
				position: absolute;
			}

			.input-number-select input {
				border-radius: var(--rounded-sm) 0 0 4px;
			}

			.input-number-select .ant-select-selection {
				border-radius: 0 var(--rounded-sm) var(--rounded-sm) 0;
				border-left: none;
			}

			.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
				border-top-left-radius: var(--rounded-sm);
				border-bottom-left-radius: var(--rounded-sm);
			}

			.ant-input-group-addon {
				border-radius: var(--rounded-md);
			}

			.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
				border-radius: 0 var(--rounded-sm) var(--rounded-sm) 0;
				height: 36px;
			}

			.ant-radio-button-wrapper {
				height: 36px;
				line-height: 36px;
			}

			.ant-radio-button-wrapper:first-child {
				border-radius: var(--rounded-sm) 0 0 4px;
			}

			.ant-radio-button-wrapper:last-child {
				border-radius: 0 4px 4px 0;
			}

			.ant-form-item-control {
				display: block;
				line-height: 1;
				width: 100%;
			}

			.ant-form-explain {
				color: red;
				margin-top: 8px;
			}

			.ant-progress-status-success .ant-progress-bg {
				background: #57cfc7 !important;
			}

			.ant-progress-status-success .ant-progress-text {
				color: #57cfc7 !important;
			}

			.ant-progress-success-bg,
			.ant-progress-bg {
				background: #57cfc7;
			}

			.ant-progress-circle-path {
				stroke: #57cfc7;
				stroke: linear-gradient(135deg, #57cfc7 0%, #14b9ae 100%);
			}

			.ant-progress-status-success .ant-progress-circle-path {
				stroke: #57cfc7;
			}

			.ant-progress-circle.ant-progress-status-success .ant-progress-text {
				color: #57cfc7;
			}

			.ant-table-wrapper .ant-table-thead > tr > th,
			.ant-table-wrapper .ant-table-tbody > tr > td {
				word-break: normal;
				min-height: 44px;
				padding: 10px 10px;
			}

			.ant-menu-item:has(:focus-visible) {
				outline: 1px solid black;
			}

			.ant-menu:not(.ant-menu-horizontal) .ant-menu-item,
			.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title {
				border-radius: var(--rounded-md);
				margin-bottom: var(--spacing-sm);
			}

			.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
				background-color: var(--surface3);
				filter: brightness(0.95);
			}

			.ant-btn-primary {
				border: none;
				background: var(--on-surface1);
			}

			.ant-btn-primary:hover {
				background: var(--on-surface1);
			}

			.ant-row-background {
				background: var(--surface2);
			}

			.ant-tooltip-inner {
				padding: 8px;
				text-align: center;
				font-size: 12px;
			}

			.ant-tabs-nav .ant-tabs-tab-active {
				font-weight: 600;
			}

			.ant-tabs-nav .ant-tabs-tab-active:hover {
				color: var(--on-surface1);
			}

			.ant-tabs-nav .ant-tabs-tab:hover {
				color: var(--on-surface1);
			}

			.ant-tabs-nav .ant-tabs-tab {
				margin-right: 0px;
				padding: 12px 16px;
			}

			.ant-row .ant-switch {
				margin-top: 7px;
				max-width: 20px;
			}

			.ant-switch {
				background-color: #c6c1cd;
			}

			.ant-switch.ant-switch-checked {
				background-color: #1dada3;
			}

			.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
				background-color: #1dada3;
			}

			/* stuff jake added */
			.ant-table-placeholder {
				color: #5a626c;
				font-weight: 600;
			}

			.ant-table-thead > tr > th {
				min-height: 44px;
				padding: 10px 10px;
			}

			.ant-table-thead
				> tr
				> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
					[colspan]
				)::before {
				display: none;
				content: none;
			}

			.ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
				top: 18px;
			}

			.ant-dropdown-link {
				color: #5a626c;
			}

			.ant-dropdown-menu-item button {
				display: block;
				margin: -5px -12px;
				padding: 5px 12px;
				color: var(--on-surface1);
				transition: all 0.3s;
			}

			.ant-dropdown-menu-item:hover {
				background: var(--surface2);
			}

			.ant-dropdown-menu > li > a {
				color: var(--on-surface1);
			}

			.ant-dropdown-menu > li > a:hover {
				color: #196097;
			}

			/* Large Size */
			.ant-input-search-large {
				height: 40px;
			}

			.ant-input-search-large .ant-input-suffix .ant-btn-lg {
				height: 40px;
			}

			/* small Size */
			.ant-input-affix-wrapper {
				height: 36px;
				border-radius: var(--rounded-sm);
			}

			.ant-input-affix-wrapper-lg {
				height: 40px;
			}

			.ant-input-sm {
				height: 36px;
			}

			.ant-input-search-small {
				height: 36px;
			}

			.ant-input-search-small .ant-input-suffix .ant-btn-sm {
				height: 36px;
			}

			.ant-btn-sm {
				height: 32px;
			}

			.ant-picker {
				border-radius: var(--rounded-sm);
			}

			.ant-calendar-range .ant-calendar-in-range-cell:before {
				background-color: var(--surface3);
			}

			.ant-calendar-range .ant-calendar-selected-day:before {
				background-color: var(--on-surface1);
			}

			.ant-calendar-selected-day .ant-calendar-date {
				color: var(--surface1) !important;
			}

			.ant-modal-footer:after {
				content: "";
				display: block;
				clear: both;
			}

			.ant-input-search {
				max-width: 420px;
			}

			.ant-radio-button-wrapper-checked {
				color: var(--on-surface1) !important;
			}

			.ant-radio-button-wrapper:hover {
				color: var(--on-surface1) !important;
				background-color: #ffdccd !important;
			}

			.ant-radio-group-small .ant-radio-button-wrapper {
				height: 32px;
				padding: 0 12px;
				line-height: 30px;
			}

			.ant-slider-track {
				background-color: #57cfc7;
			}

			.ant-slider-handle {
				border: none;
				background: var(--on-surface1);
			}

			tr.ant-table-expanded-row > td,
			tr.ant-table-expanded-row:hover > td {
				background: var(--surface2);
				cursor: default;
			}

			/*this handles inputs inside tables, its gross but it works*/
			td > div > div {
				margin-bottom: 0px !important;
			}

			.ant-table-expanded-row .ant-row.ant-form-item {
				margin-bottom: 16px !important;
			}

			a .ant-radio-wrapper {
				display: block;
				margin: 7px 0px;
			}

			.ant-radio-wrapper {
				white-space: nowrap;
			}

			.ant-radio-inner {
				border-color: #67717e;
				border-width: 2px;
				width: 18px;
				height: 18px;
			}

			.highlight-row {
				background-color: var(--surface2);
			}

			.ant-checkbox-inner {
				border: 2px solid #67717e;
			}

			.ant-checkbox-wrapper + .ant-checkbox-wrapper {
				margin-left: 0;
			}

			@media (max-width: 600px) {
				.ant-radio-group-large .ant-radio-button-wrapper {
					height: 32px;
					line-height: 30px;
					font-size: 14px;
					padding: 0 12px;
				}

				.ant-modal-body {
					padding: 16px;
				}

				.ant-steps-item-title {
					font-size: 14px;
					line-height: 20px;
				}

				.ant-steps-item-icon {
					width: 24px;
					height: 24px;
				}
			}

			.ant-input {
				color: #3f3e41;
				min-width: 0;
				padding: 6px 11px;
				width: 100% !important;
			}

			/* AntD menu and tab components */
			.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
			.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
			.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
				color: var(--on-surface1);
			}
		`}
	/>
);
